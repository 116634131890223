import { DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { useParams } from "react-router";
import { CarrierNavBar } from "./CarrierNavBar";
import { Title } from "utils/title";
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useRef, useState } from "react";
import Moment from 'moment-timezone';
import { debounce } from 'ts-debounce';
import { fetchApi } from "services/api";
import useAlert from '../../components/useAlert';
import { DateInput, DateInputProps, DateRangePicker, SelectionRange } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridItemChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import CenterDivPanel from 'components/CenterDivPanel';
import DateCell from 'components/cells/DateCell';
import { Link } from 'react-router-dom';
import TripSummary from 'views/Track/TripSummary';
import YesNoCell from 'components/cells/YesNoCell';

type RouteComponentParams = {
  carrierId: string;
};

const PickupDateRangeStartDate = Moment().subtract(1, "months").startOf('day').toDate();
const PickupDateRangeEndDate = Moment().startOf('day').toDate();

const resetDataState = {
  skip: 0,
  take: 50,
  sort: [{
    field: "PickupDateTime", dir: "desc"
  }],
  filter: {
    logic: 'and', filters: [
      { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
      { field: 'PickupDateTime', operator: 'gte', value: PickupDateRangeStartDate },
      { field: 'PickupDateTime', operator: 'lte', value: PickupDateRangeEndDate }
    ]
  }
} as GridState;

const CustomDateInput = (props: DateInputProps) => {
  return (
    <DateInput {...props} label={undefined} width={92} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
  );
};

const CarrierExceptions = () => {

  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();

  const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
  const totalRecords = useRef(0);
  const DataState_Take = 50;

  const { alert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [showTripSummary, setShowTripSummary] = useState(0);

  const [filterByPickupDateRange, setFilterByPickupDateRange] = useState<SelectionRange>({
    start: PickupDateRangeStartDate,
    end: PickupDateRangeEndDate,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((func: () => void) => {
      func();
    }, 750), []);

  const fetchRecords = useCallback((append: boolean) => {

    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      document.querySelector('.ServiceExceptions-andFilter .k-grid-content').scrollTop = 0;
    }

    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/Asset/CarrierServiceExceptions/${carrierIdParam}/?${queryStr}`, {}, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setData(Data);
        } else {
          setData(prevData => prevData.concat(Data));
        }
        setLoading(false);
        //  handleResize(null); //need this if using hierarchy filters
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          await alert('Error fetching data.');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
      });
  }, [alert, dataState, carrierIdParam]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (data.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item.PayID === event.dataItem.PayID
        ? { ...item, [field]: event.value }
        : item
    );
    setData(newData);
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...resetDataState.filter } });
  };

  const addDateRangeFilter = (field: string, dateRange: SelectionRange) => {
    (dataState.filter.filters as FilterDescriptor[]).push({ field: field, operator: 'gte', value: dateRange.start });
    (dataState.filter.filters as FilterDescriptor[]).push({ field: field, operator: 'lte', value: dateRange.end });
    setDataState({ ...dataState });
  };

  const removeFilter = (field: string) => {
    var hasFilter = (dataState.filter.filters as FilterDescriptor[]).some(x => x.field === field);
    if (hasFilter) {
      dataState.filter.filters = (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field !== field);
      setDataState({ ...dataState });
    }
  }

  useEffect(() => {
    fetchRecords(false);
  }, [fetchRecords, carrierIdParam, dataState.filter.filters]);


  const dataView = () => <div className="mt-2" style={{ position: "relative" }}>
    <Grid
      className="ServiceExceptions-andFilter"
      style={{
        maxHeight: `${window.innerHeight * .80}px`,
      }}
      data={data}
      {...dataState}
      onDataStateChange={dataStateChange}
      total={totalRecords.current}
      onScroll={scrollHandler}
      resizable={true}
      onFilterChange={(e) => onGridFilterChange(e)}
      onItemChange={itemChange}
    >
      <GridToolbar>
        <label className="mt-1">Pickup Date Range:</label>
        <DateRangePicker
          style={{ width: 195, gap: "0.3em" }}
          startDateInput={CustomDateInput}
          endDateInput={CustomDateInput}
          value={filterByPickupDateRange}
          onChange={(e) => {
            setFilterByPickupDateRange(e.value);
            if (e.value && e.value.start && e.value.end) {
              debounceSearchInput(() => {
                debounceSearchInput(() => {
                  removeFilter('PickupDateTime');
                  addDateRangeFilter('PickupDateTime', e?.value);
                });
              });
            }
          }}
        />
      </GridToolbar>
      <Column field="FailureReasonName" title="Type" cell={(props) => <td>
        <Link style={{ color: "#007bff" }} to={`/Order/${props.dataItem.OrderID}/ServiceException/${props.dataItem.OrderServiceExceptionID}`}>{props.dataItem.FailureReasonName}</Link>
      </td>} />
      <Column field="OrderNumber" title="Order #" cell={(props) => <td onClick={() => setShowTripSummary(props.dataItem.OrderID)} style={{ cursor: "pointer", color: "#007bff" }}>{props.dataItem.OrderNumber}</td>} />
      <Column field="ExcludeFromReporting" title="Exclude" cell={YesNoCell} />
      <Column field="PickupDateTime" title="Pickup" cell={DateCell} />
      <Column field="PickupCityName" title="Pickup City" cell={(props) => <td>{props.dataItem.PickupCityName}, {props.dataItem.PickupCityState}</td>} />
      <Column field="DeliveryDateTime" title="Delivery" cell={DateCell} />
      <Column field="DeliveryCityName" title="Delivery City" cell={(props) => <td>{props.dataItem.DeliveryCityName}, {props.dataItem.DeliveryCityState}</td>} />
      <Column field="TotalDistance" title="Distance" format="{0:n0} mi" />
    </Grid>
  </div>;

  return (<>
    <CarrierNavBar id={carrierIdParam} currentTab="ServiceExceptions" />
    <Title string="Service Exceptions" />
    {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
    </CenterDivPanel>}
    {dataView()}
    {showTripSummary > 0 && <TripSummary orderId={showTripSummary} close={() => setShowTripSummary(0)} />}
</>);
}

export default CarrierExceptions;
