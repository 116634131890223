import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Checkbox } from '@progress/kendo-react-inputs';
import * as React from 'react';

interface Props {
  CloseDialog: () => void;
  CreateUrl: (data: { ShowRate: boolean }) => void;
}

type State = {
  ShowRate: boolean;
}

export class PersonalizedUrl extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      ShowRate: true
    }
  }

  public render() {
    return (
      <Dialog title="Create Personalized URL" onClose={this.props.CloseDialog}>
        <Checkbox 
          value={this.state.ShowRate}
          onChange={(e) => this.setState({ ShowRate: e.value })}
          label={'Show Rate to Customer'}
        />
        <DialogActionsBar>
          <button className="btn btn-primary" type="button" onClick={() => this.props.CreateUrl({ ShowRate: this.state.ShowRate })}>Create URL</button>
        </DialogActionsBar>
      </Dialog>
    );
  }
}
