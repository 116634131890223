import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class TheadIDCell extends React.Component<GridCellProps> {
  public render() {
    if (!this.props.dataItem.ThreadID) {
      return <td />
    }

    return (
      <td>
        {(this.props.dataItem.ThreadID.startsWith("cnv_") || this.props.dataItem.ThreadID.startsWith("msg_")) ? <a
          target="_blank"
          style={{ color: '#007bff' }}
          href={`https://app.frontapp.com/open/${this.props.dataItem.ThreadID}`}
        >
          {this.props.dataItem.ThreadID}
        </a> : this.props.dataItem.ThreadID}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.ThreadID !== this.props.dataItem.ThreadID;
  }
}
