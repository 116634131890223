import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { ILink } from '../../types/link';
import { BooleanFilter, Filter, FilterChangeEvent, NumericFilter, Operators, TextFilter } from '@progress/kendo-react-data-tools';
import { Title } from '../../utils/title';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import AddBrokerDialog from './AddBrokerDialog';
import { plusIcon } from '@progress/kendo-svg-icons';

export type CustomsBrokerViewModel = {
    BrokerID: number;
    BrokerName: string;
    AddressLine1: string;
    City: string;
    State: string;
    Zip: string;
    Active: boolean;
    Phone1: string;
    Hash: string;
    Links: ILink[];
}

const BrokerIDCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: CustomsBrokerViewModel = props.dataItem;
    var profile = dataItem.Links.find((x: ILink) => x.Name === 'CustomsBrokerProfile');
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style}>
            {profile &&
                <Link
                    to={profile.Link}
                    style={{ color: '#007bff' }}
                >
                    {dataItem.BrokerID}
                </Link>}
        </td>
    );
};

const CustomsBrokerGrid = () => {
    const resetDataState = {
        skip: 0,
        take: 50,
        sort: [{
            field: "BrokerName",
            dir: "asc"
        }]
    } as GridState;

    const defaultAndFilter = {
        logic: 'and', filters: [
            { field: 'active', operator: 'eq', value: true },
        ]
    } as CompositeFilterDescriptor;

    const sessionStorageAndFilter = sessionStorage.getItem("CustomsBrokerGrid-andFilter");
    let andFilter: CompositeFilterDescriptor = null;
    if (sessionStorageAndFilter) {
        andFilter = JSON.parse(sessionStorageAndFilter);
    } else {
        andFilter = { ...defaultAndFilter };
    }
    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<CustomsBrokerViewModel[]>([]);
    const [showAddCustomsBrokerDialog, setShowAddCustomsBrokerDialog] = useState(false);
    const totalRecords = useRef(0);
    const DataState_Take = 50;

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const fetchRecords = useCallback((append: boolean) => {
        sessionStorage.setItem("CustomsBrokerGrid-andFilter", JSON.stringify(dataState.filter));
        setLoading(true);
        const dataGridState = { ...dataState };
        if (!append) {
            dataGridState.take = DataState_Take;
            dataGridState.skip = 0;
            window.scrollTo(0, 0);
            document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
        }
        const queryStr = `${toDataSourceRequestString(dataGridState)}`;
        fetchApi(`/api/Customer/GetCustomsBrokers?${queryStr}`, {}, 'POST')
            .then(({ Data, Total }) => {
                totalRecords.current = Total;
                if (!append) {
                    setRecords(Data);
                } else {
                    setRecords(prevData => prevData.concat(Data));
                }
                setLoading(false);
                handleResize(null);
            }).catch(() => {
                setLoading(false);
            });
    }, [dataState]);

    const handleResize = (e: UIEvent) => {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
        }
    }

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 2) {
            if (records.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
                return;

            dataState.skip = dataState.skip + dataState.take;
            fetchRecords(true);
        }
    }

    const search = useDebouncedCallback(useCallback(() => {
        fetchRecords(false);
    }, [fetchRecords]), 750);

    useEffect(() => {
        search();
    }, [dataState.filter, dataState.sort, search]);

    const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        setDataState(changeEvent.dataState);
    }

    const onFilterChange = (event: FilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter });
    };

    const onGridFilterChange = (event: GridFilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
    };

    const onGridSortChange = (event: GridSortChangeEvent) => {
        setDataState({ ...dataState, sort: event.sort });
    };

    return (
        <div className="container-fluid" style={{ position: "relative" }}>
            <Title string="Customs Brokers Listing" />
            {showAddCustomsBrokerDialog && <AddBrokerDialog
                CloseDialog={() => setShowAddCustomsBrokerDialog(false)}
            />}
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div>
                <Filter
                    className="kendoFilterControl"
                    value={dataState.filter}
                    onChange={onFilterChange}
                    fields={[
                        {
                            name: "BrokerID",
                            label: "Broker ID",
                            filter: NumericFilter,
                            operators: Operators.numeric,
                        },
                        {
                            name: "active",
                            label: "Active",
                            filter: BooleanFilter,
                            operators: Operators.boolean
                        },
                        {
                            name: "BrokerName",
                            label: "Name",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "AddressLine1",
                            label: "AddressLine",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "City",
                            label: "City",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "State",
                            label: "State",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "Zip",
                            label: "Zip",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "Phone1",
                            label: "Phone Number",
                            filter: TextFilter,
                            operators: Operators.text,
                        }
                    ]}
                />
                {/*<label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>*/}
                {/*    <Button*/}
                {/*        themeColor="primary"*/}
                {/*        onClick={() => {*/}
                {/*            fetchRecords(false);*/}
                {/*        }}*/}
                {/*    >Search*/}
                {/*    </Button>*/}
                {/*</label>*/}
            </div>
            <Grid
                {...dataState}
                onDataStateChange={dataStateChange}
                data={records}
                resizable={true}
                reorderable={true}
                sortable={true}
                total={totalRecords.current}
                onScroll={scrollHandler}
                filterable={true}
                onFilterChange={(e) => onGridFilterChange(e)}
                onSortChange={(e) => onGridSortChange(e)}
            >
                <GridToolbar>
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        themeColor="primary"
                        onClick={() => setShowAddCustomsBrokerDialog(true)}
                    > Broker
                    </Button>
                </GridToolbar>
                <Column field="BrokerID" title="Broker ID" filter="numeric" cell={BrokerIDCell} />
                <Column field="BrokerName" title="Name" />
                <Column field="AddressLine1" title="Address" />
                <Column field="City" />
                <Column field="State" />
                <Column field="Zip" />
                <Column field="Phone1" title="Phone" />
            </Grid>
            {
                totalRecords.current > 0 &&
                <div className="k-pager k-pager-md k-grid-pagert">
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
                    </div>
                </div>


            }
        </div>
    )
}

export default CustomsBrokerGrid;