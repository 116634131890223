import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from '@progress/kendo-react-labels';
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper } from "@progress/kendo-react-form";
import { fetchApi } from '../../services/api';
import { ILink } from "../../types/link";

type Props = {
  userId: number;
  close: () => void;
  refresh: () => void;
}

type ApiResponse = {
  UserName: string;
  WeeklyBookedCount: number | null;
  WeeklyDispatchedCount: number | null;
  WeeklyTotalCharges: number | null;
  Links: ILink[];
}

const UserPerformanceGoalDialog = ({ userId, close, refresh }: Props) => {

  const [saving, setSaving] = useState(false);
  const [data, setData] = useState<ApiResponse>(null);
  const [weeklyBookedCount, setWeeklyBookedCount] = useState<number>(null);
  const [weeklyDispatchedCount, setWeeklyDispatchedCount] = useState<number>(null);
  const [weeklyTotalCharges, setWeeklyTotalCharges] = useState<number>(null);

  // Update internal data
  useEffect(() => {
    fetchApi(`/api/User/PerformanceGoal/${userId}`)
      .then((data: ApiResponse) => {
        setData(data);
        setWeeklyBookedCount(data.WeeklyBookedCount);
        setWeeklyDispatchedCount(data.WeeklyDispatchedCount);
        setWeeklyTotalCharges(data.WeeklyTotalCharges);
      });
  }, [userId]);

  const update = () => {
    setSaving(true);
    const payload = {
      WeeklyBookedCount: weeklyBookedCount,
      WeeklyDispatchedCount: weeklyDispatchedCount,
      WeeklyTotalCharges: weeklyTotalCharges
    }
    const link = data.Links.find(x => x.Name == "Update");
    fetchApi(link.Link, payload, link.Method)
      .then(() => {
        refresh();
        close();
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to set goal.');
      });
  }

  const dataView = () => {
    return <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <Label>Weekly Orders Booked Count Goal</Label>
        <NumericTextBox
          value={weeklyBookedCount}
          min={1}
          max={1000}
          placeholder="Leave blank for no goal"
          onChange={(e) => setWeeklyBookedCount(e.value)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Weekly Orders Dispatched Count Goal</Label>
        <NumericTextBox
          value={weeklyDispatchedCount}
          min={1}
          max={1000}
          placeholder="Leave blank for no goal"
          onChange={(e) => setWeeklyDispatchedCount(e.value)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Weekly Total Charges Goal</Label>
        <NumericTextBox
          value={weeklyTotalCharges}
          min={1}
          max={500000}
          format="c2"
          placeholder="Leave blank for no goal"
          onChange={(e) => setWeeklyTotalCharges(e.value)}
        />
      </FieldWrapper>
    </form>
  }

  const loadingView = () => {
    return <div className="k-pos-absolute k-top-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <Dialog
      title={`Set ${data?.UserName}'s Goals`} className="dialog-w525"
      onClose={close}
    >
      {data === null ? loadingView() : dataView()}
      <DialogActionsBar>
        <Button disabled={saving} onClick={close}>
          Cancel
        </Button>
        {data?.Links.find(x => x.Name == "Update") && <Button type="button" disabled={saving} themeColor="primary" onClick={update}>
          Update
        </Button>}
      </DialogActionsBar>
    </Dialog>
}

export default UserPerformanceGoalDialog;