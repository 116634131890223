import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { DriverNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { GridLoadingPanel } from "components/GridLoadingPanel";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import DateOnlyCell from "components/cells/DateOnlyCell";
import { arrowRotateCwIcon, plusIcon } from "@progress/kendo-svg-icons";
import NoteEditUI from "./NoteEditUI";
import moment from "moment-timezone";

type RouteComponentParams = {
  driverId: string;
};

type Response = {
  Notes: DriverPerformanceNote[],
  NoteTypes: IDNameViewModel[],
  Links: ILink[];
}

type DriverPerformanceNote = {
  DriverNoteID: number;
  ModifiedByFullName: string;
  ModifiedDateTime: Date;
  Note: string;
  OccurredDate: string;
  NoteTypeID: number;
  NoteTypeName: string;
}

export type NewDriverPerformanceNote = {
  DriverNoteID: number;
  Note: string;
  OccurredDate: Date | null;
  NoteType: number;
}

const DriverPerformance = () => {

  const { driverId: driverIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<DriverPerformanceNote[]>([]);
  const [noteTypes, setNoteTypes] = useState<IDNameViewModel[]>([]);
  const [filterNoteTypes, setFilterNoteTypes] = useState<number[]>([]);
  const [note, setNote] = useState<NewDriverPerformanceNote>();

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/DriverPerformance/${driverIdParam}`)
      .then((data: Response) => {
        setLoading(false);
        setNoteTypes(data.NoteTypes);
        setNotes(data.Notes);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [driverIdParam]);

  useEffect(() => {
    refresh();
  }, [driverIdParam, refresh]);

  const addNote = (newNote: NewDriverPerformanceNote) => {
    setLoading(true);
    fetchApi(`/api/Asset/AddDriverNote/${driverIdParam}`, newNote, 'POST')
      .then((response: { DriverNoteID: number }) => {
        setNote({
          ...newNote,
          DriverNoteID: response.DriverNoteID
        });
        refresh();
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('An error occurred while saving the note.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (<>
    <DriverNavBar id={driverIdParam} currentTab="Performance" />
    <Title string="Performance" />
    <br />
    {note && <NoteEditUI
      loading={loading}
      performanceNote={note}
      noteTypes={noteTypes}
      cancel={() => setNote(undefined)}
      save={addNote}
    />}
    {loading && <GridLoadingPanel />}
    <Grid
      data={notes.filter((note) => filterNoteTypes.length === 0 || filterNoteTypes.includes(note.NoteTypeID))}
      dataItemKey="DriverNoteID"
      onRowClick={(e) => setNote({
        DriverNoteID: e.dataItem.DriverNoteID,
        Note: e.dataItem.Note,
        OccurredDate: e.dataItem.OccurredDate ? moment.utc(e.dataItem.OccurredDate).toDate() : null,
        NoteType: e.dataItem.NoteTypeID
      })}
    >
      <GridToolbar>
        <Button onClick={refresh} icon="refresh" svgIcon={arrowRotateCwIcon} title="Refresh" />
        <Button
          icon="plus"
          svgIcon={plusIcon}
          themeColor="primary"
          disabled={loading}
          onClick={() => setNote({
            DriverNoteID: 0,
            Note: '',
            OccurredDate: null,
            NoteType: 1
          })}
        > Performance Note
        </Button>
        <ButtonGroup>
          {noteTypes.map((noteType) => <Button
            key={noteType.ID}
            togglable={true}
            selected={filterNoteTypes.includes(noteType.ID)}
            onClick={() => {
              if (filterNoteTypes.includes(noteType.ID)) {
                setFilterNoteTypes(filterNoteTypes.filter((id) => id !== noteType.ID));
              } else {
                setFilterNoteTypes([...filterNoteTypes, noteType.ID]);
              }
            }}
          >
            {noteType.Name}
          </Button>)}
        </ButtonGroup>
      </GridToolbar>
      <Column field="Note" title="Note" width={window.innerWidth / 3} />
      <Column field="NoteTypeName" title="Type" />
      <Column field="OccurredDate" cell={DateOnlyCell} title="Occurred" />
      <Column field="ModifiedDateTime" cell={DateCell} title="Entered" />
      <Column field="ModifiedByFullName" title="Entered By" />
    </Grid>
  </>);
}

export default DriverPerformance;