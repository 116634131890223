import { useEffect, useState } from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Moment from 'moment-timezone';
import { Button } from "@progress/kendo-react-buttons";
import Documents, { DocumentEntityType } from "../Documents/Documents";
import { AssetEntityType } from "./AssetPanel";
import { ILink } from "../../types/link";
import { DocumentType } from "TypeGen/Documents/document-type";
import { AssetComplianceTypeID } from 'TypeGen/Assets/asset-compliance-type-id';
import { trashIcon } from "@progress/kendo-svg-icons";

export type ComplianceViewModel = {
  TypeID: AssetComplianceTypeID;
  Active: boolean;
  AssetID: number;
  Name: string;
  Start: Date | null;
  Expiry: Date | null;
  ReferenceNumber: string;
  Links: ILink[];
}

type Props = {
  assetEntityType: AssetEntityType
  data: ComplianceViewModel;
  close: () => void;
  save: (newData: ComplianceViewModel) => Promise<void>;
  remove: (newData: ComplianceViewModel) => Promise<void>;
}

const CompliancePopup = (props: Props) => {
  const { close } = props;

  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(props.data);

  // Update internal data
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const save = async () => {
    setSaving(true);
    await props.save(data);
    setSaving(false);
    props.close();
  }

  const remove = async () => {
    setSaving(true);
    await props.remove(data);
    setSaving(false);
    props.close();
  }

  const getDocumentType = (): DocumentType => {
    switch (data.TypeID) {
      case AssetComplianceTypeID.HazMat:
        return DocumentType.HazMat;
      case AssetComplianceTypeID.FAST:
        return DocumentType.FAST;
      case AssetComplianceTypeID.CSA:
        return DocumentType.CSA;
      case AssetComplianceTypeID.TWIC:
        return DocumentType.TWIC;
      case AssetComplianceTypeID.DOTInspection:
        return DocumentType.DOTInspection;
      case AssetComplianceTypeID.MaintenanceCheck:
        return DocumentType.MaintenanceCheck;
      case AssetComplianceTypeID.COVIDVaccine1:
        return DocumentType.COVIDVaccine1;
      case AssetComplianceTypeID.COVIDVaccine2:
        return DocumentType.COVIDVaccine2;
      case AssetComplianceTypeID.COVIDVaccine3:
        return DocumentType.COVIDVaccine3;
      case AssetComplianceTypeID.TSA:
        return DocumentType.TSA;
      case AssetComplianceTypeID.CaliforniaCTC:
        return DocumentType.CaliforniaCTC;
      case AssetComplianceTypeID.OregonPermit:
        return DocumentType.OregonPermit;
      case AssetComplianceTypeID.NewYorkPermit:
        return DocumentType.NewYorkPermit;
      case AssetComplianceTypeID.NewMexicoPermit:
        return DocumentType.NewMexicoPermit;
      case AssetComplianceTypeID.KentuckyPermit:
        return DocumentType.KentuckyPermit;
      case AssetComplianceTypeID.CarrierAgreement:
        return DocumentType.CarrierBrokerageAgreement;
      case AssetComplianceTypeID.NOA:
        return DocumentType.NOA;
      case AssetComplianceTypeID.Pet:
        return DocumentType.Pet;
      default:
        const exhaustiveCheck: never = data.TypeID;
    }
  }

  const getDocumentEntityType = (): DocumentEntityType => {
    switch (props.assetEntityType) {
      case AssetEntityType.Vehicle:
        return DocumentEntityType.Vehicle;
      case AssetEntityType.Driver:
        return DocumentEntityType.Driver;
    }
    throw new Error('Asset entity type not supported');
  }

  return (
    <Window
      title={data.Name}
      style={{ position: 'fixed' }}
      initialWidth={Math.min(850, window.innerWidth)}
      initialHeight={Math.min(550, window.innerHeight)}
      onClose={close}
    >
      <form className="k-form k-form-md">
        {data.TypeID != AssetComplianceTypeID.HazMat && <div className="k-form-field">
          <label htmlFor="ReferenceNumber">Reference / Note</label>
          <Input
            disabled={saving}
            maxLength={51}
            id="ReferenceNumber"
            value={data.ReferenceNumber}
            onChange={(e) => {
              setData({ ...data, ReferenceNumber: e.target.value.toString() });
            }}
          />
        </div>}
        <div className="k-form-field">
          <label htmlFor="EffectiveStart">Effective Start/Last</label>
          <DatePicker
            disabled={saving}
            id="EffectiveStart"
            format="MM/dd/yyyy"
            value={data.Start ? Moment(data.Start).toDate() : null}
            min={new Date(1753, 1, 1)}
            max={new Date(2999, 11, 31)}
            formatPlaceholder="formatPattern"
            onChange={(e) => {
              setData({ ...data, Start: e.value });
            }}
          />
        </div>
        <div className="k-form-field">
          <label htmlFor="EffectiveExpiry">Effective End/Next</label>
          <DatePicker
            disabled={saving}
            id="EffectiveExpiry"
            format="MM/dd/yyyy"
            value={data.Expiry ? Moment(data.Expiry).toDate() : null}
            min={new Date(1753, 1, 1)}
            max={new Date(2999, 11, 31)}
            formatPlaceholder="formatPattern"
            onChange={(e) => {
              setData({ ...data, Expiry: e.value });
            }}
          />
        </div>
      </form>
      <br />
      <Documents
        EntityType={getDocumentEntityType()}
        EntityId={data.AssetID}
        DocumentType={getDocumentType()}
      />
      <WindowActionsBar>
        <Button icon="delete" svgIcon={trashIcon} disabled={saving} type="button" themeColor="error" onClick={remove}>
          Delete
        </Button>
        <Button type="button" disabled={saving} className="ml-auto" onClick={close}>
          Cancel
        </Button>
        <Button
          type="button"
          themeColor="primary"
          disabled={saving}
          onClick={save}
        >
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
}

export default CompliancePopup;