import { ViewModel } from "TypeGen/Trip/DeadHead/view-model";
import { useEffect, useState } from "react";
import Moment from 'moment-timezone';
import { fetchApi } from "services/api";
import { Title } from "../../../utils/title";
import { AutoComplete, AutoCompleteChangeEvent, DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { VehicleViewModel } from "TypeGen/Trip/DeadHead/vehicle-view-model";
import { ApiLink } from "TypeGen/api-link";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { useHotkeys } from "react-hotkeys-hook";
import { ISingleSearchLocation, singleSearch } from "services/pcmiler";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { JsonResponse } from "TypeGen/json-response";
import { useHistory } from 'react-router-dom';

const CreateDeadHead = () => {

  const history = useHistory();
  const [vehicles, setVehicles] = useState<VehicleViewModel[]>([]);
  const [vehiclesFilter, setVehiclesFilter] = useState<FilterDescriptor>();
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Options
  const [vehicle, setVehicle] = useState<VehicleViewModel>(null);

  const [startLocationOptions, setStartLocationOptions] = useState<ISingleSearchLocation[]>([]);
  const [startLocationValue, setStartLocationValue] = useState<string>('');
  const [startLocationLoading, setStartLocationLoading] = useState(false);
  const [startDateTime, setStartDateTime] = useState<Date>();

  const [endLocationOptions, setEndLocationOptions] = useState<ISingleSearchLocation[]>([]);
  const [endLocationValue, setEndLocationValue] = useState<string>('');
  const [endLocationLoading, setEndLocationLoading] = useState(false);
  const [endDateTime, setEndDateTime] = useState<Date>();

  useEffect(() => {
    fetchApi("/api/Trip/CreateDeadHead")
      .then((data: ViewModel) => {
        setVehicles(data.Vehicles);
        setStartDateTime(Moment.utc(data.DeadHeadStartDateTime).toDate());
        setEndDateTime(Moment.utc(data.DeadHeadEndDateTime).toDate());
        setLinks(data.Links);
        setLoading(false);
      });
  }, []);

  const save = () => {
    setSaving(true);
    const data = {
      VehicleID: vehicle?.VehicleID,
      StartLocation: startLocationValue,
      StartDateTime: startDateTime,
      EndLocation: endLocationValue,
      EndDateTime: endDateTime,
    }
    const link = links.find(l => l.Name === 'Save');
    fetchApi(link.Link, data, link.Method)
      .then((data: JsonResponse) => {
        var link = data.Links.find(l => l.Name === 'Trip');
        if (link) history.push(link.Link);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error saving DeadHead Trip');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const renderVehicle = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <>{itemProps.dataItem.VehicleNumber} <span style={{ opacity: 0.75 }}>{itemProps.dataItem.PositionCityState}</span></>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const renderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const updateStartLocationsDebounced =  useDebouncedCallback((searchTerm: string) => {
    setStartLocationLoading(true);
    singleSearch(searchTerm)
      .then((response) => {
        setStartLocationLoading(false);
        setStartLocationOptions(response.Locations);
      })
      .catch(() => {
        setStartLocationLoading(false);
        setStartLocationOptions([]);
      })
  }, 350);

  const updateEndLocationsDebounced =  useDebouncedCallback((searchTerm: string) => {
    setEndLocationLoading(true);
    singleSearch(searchTerm)
      .then((response) => {
        setEndLocationLoading(false);
        setEndLocationOptions(response.Locations);
      })
      .catch(() => {
        setEndLocationLoading(false);
        setEndLocationOptions([]);
      })
  }, 350);

  useHotkeys('ctrl+s', (e, handler) => {
    switch (handler.keys.join('')) {
      case 's':
      save();
      e.preventDefault();
      break;
    }
  }, [save]);

  useEffect(() => {
    if (vehicle) setStartLocationValue(vehicle.PositionCityState);
  }, [vehicle]);

  return (
    <div className="container">
      <Title string="Create DeadHead" />
      <h1 className="text-center">Create DeadHead Trip</h1>
      <DropDownList
        required
        style={{ width: '100%' }}
        data={filterBy(vehicles, vehiclesFilter)}
        textField="VehicleNumber"
        filterable={true}
        onFilterChange={(e) => setVehiclesFilter(e.filter)}
        onChange={(e) => setVehicle(e.target.value)}
        defaultValue={{ VehicleNumber: 'Select Vehicle' }}
        itemRender={renderVehicle}
        loading={loading}
      />
      <div className="card my-2">
        <div className="card-body">
        <div className="form-group row">
            <label className="col-form-label form-check-label col-md-3" htmlFor="location">Start City, State</label>
            <div className="col-md-9">
              <AutoComplete
                data={startLocationOptions}
                value={startLocationValue}
                loading={startLocationLoading}
                placeholder={`Enter Start Location`}
                itemRender={renderLocation}
                onChange={(event: AutoCompleteChangeEvent) => {

                  // Update Location
                  let location = event.target.value;
                  if (typeof event.target.value === 'object') {
                    const place = event.target.value as ISingleSearchLocation;
                    location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                  }

                  setStartLocationValue(location);

                  // Update Autocomplete Suggestions
                  if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                    updateStartLocationsDebounced(event.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="startTime" className="col-form-label col-md-3">Start Date/Time</label>
            <div className="col-md-9">
              <DateTimePicker
                required
                width={210}
                format="MM/dd/yyyy HH:mm"
                id="startTime"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card my-2">
        <div className="card-body">
          <div className="form-group row">
            <label className="col-form-label form-check-label col-md-3" htmlFor="location">End City, State</label>
            <div className="col-md-9">
              <AutoComplete
                data={endLocationOptions}
                value={endLocationValue}
                loading={endLocationLoading}
                placeholder={`Enter End Location`}
                itemRender={renderLocation}
                onChange={(event: AutoCompleteChangeEvent) => {

                  // Update Location
                  let location = event.target.value;
                  if (typeof event.target.value === 'object') {
                    const place = event.target.value as ISingleSearchLocation;
                    location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                  }

                  setEndLocationValue(location);

                  // Update Autocomplete Suggestions
                  if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                    updateEndLocationsDebounced(event.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="EndTime" className="col-form-label col-md-3">End Date/Time</label>
            <div className="col-md-9">
              <DateTimePicker
                required
                width={210}
                format="MM/dd/yyyy HH:mm"
                id="EndTime"
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        {links.find(x => x.Name === 'Save') && <Button
          themeColor="primary"
          className="ml-2"
          disabled={saving}
          onClick={() => save()}
        >
          Create DeadHead
        </Button>}
      </div>
    </div>
  );
}

export default CreateDeadHead;