import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridPageChangeEvent, GridNoRecords } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DateCell from "../../../components/cells/DateCell";
import { fetchApi } from "../../../services/api";
import VehicleNumberCell from "./Cells/VehicleNumber";
import { PlanningContext } from ".";
import { ILink } from "../../../types/link";
import { openWindow } from "../../../services/openWindow";
import PhoneCell from "../../../components/cells/PhoneCell";
import carrierTierCell from "../../Assets/CarrierTierCell";
import { AssetCarrierViewModel } from "TypeGen/Assets/Carriers/List/asset-carrier-view-model";
import { CarrierTierValue } from "TypeGen/carrier-tier-value";
import { Page } from "@progress/kendo-react-dropdowns";
import CarrierNameCell from "./Cells/CarrierName";
import LoadingPanel from "components/LoadingPanel";

type RouteComponentParams = {
  orderId: string;
};

export type VehicleSuggestion = {
  VehicleID: number;
  VehicleNumber: string;
  DriverNumber1: string;
  TrailerNumber1: string;
  VehicleTypeID: number;
  VehicleTypeName: string;
  ComplianceDays: number;
  PayLoad: number;
  Dims: string;
  LastAvailable: number;
  ServiceStatus: string;
  StatusColor: string;
  AvailableLocation: string;
  AvailableDateTime: Date;
  HasAcceptedOffer: boolean;

  Ranking: number;

  Links: ILink[];
}

export type CarrierSuggestion = {
  CarrierID: number;
  CarrierName: string;
  MCNumber1: string;
  City: string;
  State: string;
  Phone1: string;
  CarrierTier: CarrierTierValue;
  HasAcceptedOffer: boolean;

  Ranking: number;

  Links: ILink[];
}

enum VehiclesCarriers {
  Vehicles = 0,
  Carriers = 1
}

const initialPageState: Page = { skip: 0, take: 25 };

const OrderPlanningSuggestions = () => {
  const history = useHistory();
  const { vehiclesCarriers, setVehiclesCarriers, toggleMap } = useContext(PlanningContext);
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const [loadingVehicleSuggestions, setLoadingVehicleSuggestions] = useState(false);
  const [loadingCarrierSuggestions, setLoadingCarrierSuggestions] = useState(false);
  const [page, setPage] = useState(initialPageState);

  // Vehicle suggestions
  const [vehicles, setVehicles] = useState<VehicleSuggestion[]>([]);
  const [vehiclesFilter, setVehiclesFilter] = useState<CompositeFilterDescriptor>({ logic: "or", filters: [] });

  // Carrier suggestions
  const [carriers, setCarriers] = useState<CarrierSuggestion[]>([]);
  const [carriersFilter, setCarriersFilter] = useState<CompositeFilterDescriptor>({ logic: "or", filters: [] });

  const refreshVehicleSuggestions = useCallback(() => {
    setLoadingVehicleSuggestions(true);
    fetchApi(`/api/Asset/VehicleSuggestions/${orderIdParam}`)
      .then((response: { Vehicles: VehicleSuggestion[] }) => {
        setLoadingVehicleSuggestions(false);
        setVehicles(response.Vehicles);
      })
      .catch(err => {
        alert(err);
        setLoadingVehicleSuggestions(false);
      });
  }, [orderIdParam]);

  const refreshCarrierSuggestions = useCallback(() => {
    setLoadingCarrierSuggestions(true);
    fetchApi(`/api/Asset/CarrierSuggestions/${orderIdParam}`)
      .then((response: { Carriers: CarrierSuggestion[] }) => {
        setLoadingCarrierSuggestions(false);
        setCarriers(response.Carriers);
      })
      .catch(err => {
        alert(err);
        setLoadingCarrierSuggestions(false);
      });
  }, [orderIdParam]);

  const selectVehicle = (vehicle: VehicleSuggestion) => {
    if (vehicle.Links.find(l => l.Name === "AddProTO")) {
      openWindow(vehicle.Links.find(l => l.Name === "AddProTO").Link);
    } else {
      history.push(`/Order/Planning/${orderIdParam}/Vehicle/${vehicle.VehicleID}`);
    }

    // TODO: AddProTO in TMS Mode
  }

  const selectCarrier = (carrier: AssetCarrierViewModel) => {
    history.push(`/Order/Planning/${orderIdParam}/Carrier/${carrier.CarrierID}`)
  }

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  useEffect(() => {
    if (vehiclesCarriers === VehiclesCarriers.Vehicles) {
      refreshVehicleSuggestions();
    } else if (vehiclesCarriers === VehiclesCarriers.Carriers) {
      refreshCarrierSuggestions();
    }
  }, [vehiclesCarriers, refreshVehicleSuggestions, refreshCarrierSuggestions]);

  const vehiclesFiltered = filterBy(vehicles, vehiclesFilter);
  const carriersFiltered = filterBy(carriers, carriersFilter);

  return <>
      <div className="input-group flex-nowrap">
        <ButtonGroup className="mb-2">
          <Button selected={vehiclesCarriers === VehiclesCarriers.Vehicles} onClick={() => setVehiclesCarriers(0)} togglable>Vehicles</Button>
          <Button selected={vehiclesCarriers === VehiclesCarriers.Carriers} onClick={() => setVehiclesCarriers(1)} togglable>Carriers</Button>
        </ButtonGroup>
        <Input
          autoFocus
          placeholder="Search..."
          className="mb-2 ml-2"
          onChange={e => {
            setPage(initialPageState);
            if (vehiclesCarriers === VehiclesCarriers.Vehicles)
            {
              setVehiclesFilter({
                logic: "or",
                filters: [
                  {
                    field: "VehicleNumber",
                    operator: "startswith",
                    value: e.value
                  },
                  {
                    field: "DriverNumber1",
                    operator: "startswith",
                    value: e.value
                  },
                  {
                    field: "TrailerNumber1",
                    operator: "startswith",
                    value: e.value
                  },
                  {
                    field: "VehicleTypeName",
                    operator: "startswith",
                    value: e.value
                  }
                ]
              });
            } else {
              setCarriersFilter({
                logic: "or",
                filters: [
                  {
                    field: "CarrierName",
                    operator: "startswith",
                    value: e.value
                  },
                  {
                    field: "MCNumber1",
                    operator: "startswith",
                    value: e.value
                  }
                ]
              });
            }
          }}
        />
        <Button className="mb-2 ml-2" onClick={toggleMap}>Map</Button>
      </div>
      {vehiclesCarriers === VehiclesCarriers.Vehicles && <>
        {loadingVehicleSuggestions
          ? <LoadingPanel />
          : <Grid
            scrollable="none"
            data={vehiclesFiltered}
            dataItemKey="VehicleID"
            onRowClick={e => selectVehicle(e.dataItem)}
          >
            <GridNoRecords>
              Filter by Unit #, Driver #, Trailer #, Size
            </GridNoRecords>
            <Column field="VehicleNumber" title="Unit #" cell={VehicleNumberCell} />
            <Column field="DriverNumber1" title="Driver #" />
            <Column field="TrailerNumber1" title="Trailer #" />
            <Column field="VehicleTypeName" title="Size" />
            <Column field="PayLoad" title="Payload" format="{0:n0}" />
            <Column field="Dims" title="LxWxH" />
            <Column field="AvailableLocation" title="Available" />
            <Column field="AvailableDateTime" title="Date Time" cell={DateCell} />
            <Column field="LastAvailable" title="LA" width={40} />
          </Grid>}
      </>}
      {vehiclesCarriers === VehiclesCarriers.Carriers && <>
        {loadingCarrierSuggestions
          ? <LoadingPanel />
          : <Grid
            pageable
            skip={page.skip}
            take={page.take}
            total={carriersFiltered.length}
            scrollable="none"
            dataItemKey="CarrierID"
            onPageChange={pageChange}
            data={carriersFiltered.slice(page.skip, page.take + page.skip)}
            onRowClick={e => selectCarrier(e.dataItem)}
          >
            <GridNoRecords>
              Filter by Carrier Name or MC Number
            </GridNoRecords>
            <Column field="CarrierName" title="Carrier Name" cell={CarrierNameCell} />
            <Column field="MCNumber1" title="MC Number" />
            <Column field="City" />
            <Column field="State" />
            <Column field="Phone1" title="Phone" sortable={false} cell={PhoneCell} />
            <Column field="CarrierTier" title="Tier" cell={carrierTierCell(() => {})} sortable={false} filterable={false} />
          </Grid>}
        </>}
    </>;
}

export default OrderPlanningSuggestions;