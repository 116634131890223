import * as React from 'react';
import Moment from 'moment-timezone';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { Checkbox, Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { formatPhone } from '../../utils/utils';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { VehicleTypes } from 'views/AssetVehicles/Profile';
import { IDName } from 'types/idname';
import { CreatingField } from 'components/CreatingField';

type TrackingData = {
  PhoneNumber: string;
  CreatedByFullName: string;
  CreatedDateTime: string | null;
  LastLocation: string | null;
  CreateCarrierVehicle: boolean;
  Devices: Array<{
    DeviceName: string;
    CreatedDateTime: string | null;
    LastRefreshDateTime: string | null;
  }>;
  Links: ILink[];
}

type SetupRequest = {
  PhoneNumber: string;
  VehicleNumber: string;
  VehicleTypeID: number;
  Payload: number | undefined;
  BoxLength: number | undefined;
  BoxWidth: number | undefined;
  BoxHeight: number | undefined;
}

type Props = {
  Link: ILink;
  CloseDialog: (refresh: boolean) => void;
}

type State = {
  loading: boolean;
  sending: boolean;
  createVehicle: boolean;
  data: null | TrackingData;
  request: SetupRequest;
}

export default class AppTracking extends React.Component<Props, State> {

  private phoneInput: React.RefObject<MaskedTextBox>;

  constructor(props: Props) {
    super(props);

    this.phoneInput = React.createRef();

    this.state = {
      loading: true,
      sending: false,
      createVehicle: false,
      data: null,
      request: {
        PhoneNumber: '',
        VehicleNumber: '',
        VehicleTypeID: 4,
        Payload: undefined,
        BoxLength: undefined,
        BoxWidth: undefined,
        BoxHeight: undefined,
      }
    }

    this.refresh = this.refresh.bind(this);
    this.setup = this.setup.bind(this);
    this.remove = this.remove.bind(this);
    this.resend = this.resend.bind(this);
  }

  public componentDidMount() {
    this.refresh();
  }

  private refresh() {
    this.setState({ loading: true });
    fetchApi(this.props.Link.Link, {}, this.props.Link.Method)
      .then((data: TrackingData) => {
        this.setState({ data, loading: false }, () => {
          if (this.phoneInput.current) {
            this.phoneInput.current.focus();
          }
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <Dialog title="Loading..." onClose={() => this.props.CloseDialog(false)} className='dialog-w800' />
    }
    const setup = this.state.data.Links.find(x => x.Name == "Remove") || this.state.data.Links.find(x => x.Name == "Retire");
    return (
      <Dialog title="Load One App Tracking Setup" onClose={() => this.props.CloseDialog(false)} className='dialog-w800'>
        {this.state.data.Links.find(x => x.Name == "Setup") && <>
          <div className="form-group row">
            <label className="col-md-4 col-form-label">Driver's Cell Phone</label>
            <div className="col-md-8">
              <MaskedTextBox
                ref={this.phoneInput}
                required={true}
                label=""
                validationMessage="Please enter a valid phone number!"
                mask="(000) 000-0000"
                className="flex-fill"
                value={this.state.request.PhoneNumber}
                onChange={(e) => e.value === '(___) ___-____' ? this.updateRequest({ PhoneNumber: '' }) : this.updateRequest({ PhoneNumber: e.value })}
              />
            </div>
          </div>
          {!this.state.data.CreateCarrierVehicle && <div className="form-group row">
            <label className="col-md-12 col-form-label">
              <Checkbox
                label="Continue Tracking After Trip Completed"
                checked={this.state.createVehicle}
                onChange={(e) => {
                  if (!e.value) this.updateRequest({ VehicleNumber: '' });
                  this.setState({ createVehicle: e.value });
                }}
              />
            </label>
          </div>}
          {(this.state.createVehicle || this.state.data.CreateCarrierVehicle) && <>
            <div className="form-group row">
              <label className="col-md-4 col-form-label">Vehicle Number</label>
              <div className="col-md-8">
                <Input
                  required
                  maxLength={30}
                  className="flex-fill"
                  value={this.state.request.VehicleNumber}
                  onChange={(e) => this.updateRequest({ VehicleNumber: e.value })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 col-form-label">Vehicle Type</label>
              <div className="col-md-8">
                <DropDownList
                  required
                  textField="Name"
                  dataItemKey="ID"
                  data={VehicleTypes}
                  value={VehicleTypes.find(x => x.ID == this.state.request.VehicleTypeID)}
                  onChange={(e) => this.updateRequest({ VehicleTypeID: (e.value as IDName).ID })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 col-form-label">Payload (lbs)</label>
              <div className="col-md-8">
                <CreatingField
                  dataType='number'
                  data={this.state.request.Payload}
                  onChange={(data) => this.updateRequest({ Payload: data })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="BoxDims" className="col-md-4 col-form-label" title='LxWxH'>Dims</label>
              <div className="col-md-8">
                <CreatingField
                  data={{ BoxLength: this.state.request.BoxLength, BoxWidth: this.state.request.BoxWidth, BoxHeight: this.state.request.BoxHeight }}
                  renderEditable={(data, onChange) => <>
                    <input required className="form-control" placeholder="Box L (inches)" type="number" min={0} value={data.BoxLength} onChange={(e) => onChange({ BoxLength: parseInt(e.target.value), BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })} />
                    <input required className="form-control" placeholder="Door W (inches)" type="number" min={0} value={data.BoxWidth} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: parseInt(e.target.value), BoxHeight: data.BoxHeight })} />
                    <input required className="form-control" placeholder="Door H (inches)" type="number" min={0} value={data.BoxHeight} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: parseInt(e.target.value) })} />
                  </>}
                  onChange={(data) => this.updateRequest({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })}
                />
              </div>
            </div>
          </>}
        </>}
        {setup && <>
          <p>
            Driver's Phone <b><a href={`tel:${this.state.data.PhoneNumber}`}>{formatPhone(this.state.data.PhoneNumber)}</a></b><br />
            Setup by <b>{this.state.data.CreatedByFullName}</b> at <b>{Moment.utc(this.state.data.CreatedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}</b><br />
            Last Position Update <b>{this.state.data.LastLocation ? Moment.utc(this.state.data.LastLocation).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss') : 'N/A'}</b>
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Device Name</th>
                <th>Created/Login</th>
                <th>Last Contact</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.Devices.map((x, i) => <tr key={i}>
                <td>{x.DeviceName}</td>
                <td>{x.CreatedDateTime ? Moment.utc(x.CreatedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss') : 'N/A'}</td>
                <td>{x.LastRefreshDateTime ? Moment.utc(x.LastRefreshDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss') : 'N/A'}</td>
              </tr>)}
            </tbody>
          </table>
        </>}
        <DialogActionsBar layout="end">
          <Button onClick={() => this.props.CloseDialog(false)}>Cancel</Button>
          {this.state.data.Links.find(x => x.Name == "Resend") && <Button themeColor="primary" onClick={this.resend} disabled={this.state.sending}>Resend Link</Button>}
          {this.state.data.Links.find(x => x.Name == "Setup") && <Button themeColor="primary" onClick={this.setup} disabled={this.state.sending}>Setup</Button>}
          {this.state.data.Links.find(x => x.Name == "Remove") && <Button themeColor="error" onClick={this.remove} disabled={this.state.sending}>Stop Tracking</Button>}
          {this.state.data.Links.find(x => x.Name == "Retire") && <Button themeColor="error" onClick={this.remove} disabled={this.state.sending}>Retire Vehicle</Button>}
        </DialogActionsBar>
      </Dialog>
    );
  }

  private updateRequest(value: Partial<SetupRequest>) {
    const request = Object.assign({}, this.state.request, value);
    this.setState({ request });
  }

  private resend() {
    const link = this.state.data.Links.find(x => x.Name == "Resend") as ILink;
    this.setState({ sending: true });
    fetchApi(link.Link, { PhoneNumber: this.state.data.PhoneNumber }, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog(true);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unknown Error Occurred!");
      })
      .finally(() => this.setState({ sending: false }));
  }

  private setup() {
    if (this.phoneInput.current.validity.valid === false) {
      alert("Please enter a valid phone number.");
      return;
    }
    const link = this.state.data.Links.find(x => x.Name == "Setup") as ILink;
    this.setState({ sending: true });
    fetchApi(link.Link, this.state.request, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog(true);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unknown Error Occurred!");
      })
      .finally(() => this.setState({ sending: false }));
  }

  private remove() {
    const link = this.state.data.Links.find(x => x.Name == "Remove") || this.state.data.Links.find(x => x.Name == "Retire");    
    this.setState({ sending: true });
    fetchApi(link.Link, {}, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog(true);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unknown Error Occurred!");
      })
      .finally(() => this.setState({ sending: false }));
  }
}
