import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import CenterDivPanel from "../../components/CenterDivPanel";
import { fetchApi } from '../../services/api';
import { JsonResponse } from "TypeGen/json-response";
import { ApiCommand } from "TypeGen/api-command";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import Moment from 'moment-timezone';
import { useTrack } from ".";
import DrivingBreakHistoryPopup from "./DrivingBreakHistoryPopup";

type Props = {
    tripId: number;
    onClose: () => void;
}

const DrivingBreakPopup = (props: Props) => {
    const track = useTrack();
    const [showHistory, setShowHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resumeDrivingDateTime, setResumeDrivingDateTime] = useState<Date | null>(null);
    const [comment, setComment] = useState('');
    const [doNotDisturb, setDoNotDisturb] = useState(false);
    const [links, setLinks] = useState<ApiCommand[]>([]);

    useEffect(() => {
        fetchApi(`/api/Track/GetDrivingBreak/${props.tripId}`)
            .then((response: { ResumeDrivingDateTime: string, Comment: string, DoNotDisturb: boolean, Links: ApiCommand[] }) => {
                setResumeDrivingDateTime(Moment.utc(response.ResumeDrivingDateTime).toDate());
                setComment(response.Comment);
                setDoNotDisturb(response.DoNotDisturb);
                setLinks(response.Links);
            }).catch((e) => {
                // If not problem details
                if (!e?.status) alert(e);
            });
    }, [props.tripId]);

    const _save = () => {
        setLoading(true);

        const data = {
          ResumeDrivingDateTime: resumeDrivingDateTime,
          Comment: comment,
          DoNotDisturb: doNotDisturb,
        }

        fetchApi(`/api/Track/EnterDrivingBreak/${props.tripId}`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    track?.refresh();
                    props.onClose();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                // If not problem details
                if (!e?.status) alert(e);
            })
            .finally(() => setLoading(false));
    }

    return <>
        <Dialog
            className="dialog-w800"
            title={`Driving Breaks`}
            onClose={props.onClose}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <div className="k-form k-form-md p-0">

                <FieldWrapper>
                    <Label>Resume Driving Date Time</Label>
                    <DateTimePicker
                      required
                      format="MM/dd/yyyy HH:mm"
                      formatPlaceholder="formatPattern"
                      value={resumeDrivingDateTime}
                      onChange={(e) => setResumeDrivingDateTime(e.value)}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Comment</Label>
                    <Input value={comment} onChange={(e) => setComment(e.value)} />
                </FieldWrapper>

                <FieldWrapper>
                    <Label>Do Not Disturb (Can only be set by driver)</Label>
                    <Checkbox disabled checked={doNotDisturb} onChange={(e) => setDoNotDisturb(e.value)} />
                    {doNotDisturb && <div className="text-danger">This will skip offering them loads until the resume driving date & time.</div>}
                </FieldWrapper>

            </div>

            <DialogActionsBar layout={"end"}>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={() => setShowHistory(true)}>History</Button>
                <Button themeColor="primary" onClick={_save}>
                    Save Driving Break
                </Button>
            </DialogActionsBar>
        </Dialog>
        {showHistory && <DrivingBreakHistoryPopup tripId={props.tripId} onClose={() => setShowHistory(false)} />}
    </>

}
export default DrivingBreakPopup;