import * as React from 'react';
import { Grid, GridColumn as Column, GridCellProps } from "@progress/kendo-react-grid";
import { MapReportLines } from './Map';
import { RatingCalculationHeader } from '.';
import Moment from 'moment-timezone';
import { TransitLocationValue } from './StopTransitData';
import { RightAlignHeaderCell } from '../../components/cells/RightAlignHeaderCell';
import { timeSpanToHHmmFormat } from '../../utils/utils';
import { formatNumber } from '@progress/kendo-intl';
import QuotePanel, { QuoteLineItemValue, QuotePanelStateValue } from './QuotePanel';
import { Popup } from '@progress/kendo-react-popup';
import { useBoolean } from 'usehooks-ts';
import { SmartbidValue, WinPercentageChartValue } from 'views/Quote/Quote';

type Props = {
  quoteId: number;
  dataItem: RatingCalculationHeader;
  createQuote: (allInRateData: QuoteLineItemValue[], rateComment: string, tariff: boolean) => void;
  getSmartbidData: (linehaul: number) => Promise<SmartbidValue | null>;
  getWinPercentageData: (linehaul: number) => Promise<WinPercentageChartValue | null>;
  useSmartbidRate: (rate: number, rateChange: number, currentWin: number, newWin: number) => void;
  quotePanelStateValue: QuotePanelStateValue;
}

export type GroupInfo = {
  TransitLocation: TransitLocationValue
  MapReportLine: MapReportLines,
}

export const BlindBidQuoteDetailRow = (props: Props) => {

  const mapReportLines = props.dataItem.MapReportLines;
  const data: GroupInfo[] = [];

  mapReportLines.forEach((element, idx) => {
    var tl = idx > 0 ? props.dataItem.TransitValue.Locations[idx - 1] : null;
    var newData = {
      TransitLocation: tl,
      MapReportLine: element,
    }
    data.push(newData);
  });

  return <React.Fragment>
    <Grid data={data}>
      <Column field="MapReportLine.Stop.Address.City" title="Stop" cell={CityCell} />
      <Column field="TransitLocation.SuggestedDateTime" title="Single" cell={SuggestedDateTimeCell} />
      <Column field="TransitLocation.SuggestedDateTimeTeam" title="Team" cell={SuggestedDateTimeTeamCell} />
      <Column field="MapReportLine.LMiles" title="Miles" cell={MilesCell} />
      <Column field="MapReportLine.LTolls" title="Tolls" cell={TollsCell} headerCell={RightAlignHeaderCell} />
      <Column field="MapReportLine.LMiles" title="Rate" cell={PerMileCell} headerCell={RightAlignHeaderCell} />
    </Grid>
    <QuotePanel
      dataItem={props.dataItem}
      quoteId={props.quoteId}
      createQuote={props.createQuote}
      getSmartbidData={props.getSmartbidData}
      getWinPercentageData={props.getWinPercentageData}
      useSmartbidRate={props.useSmartbidRate}
      quotePanelStateValue={props.quotePanelStateValue}
    />
  </React.Fragment>
}

const CityCell = (props: GridCellProps) => {
  const e = props.dataItem.MapReportLine as MapReportLines;
  return <td>{e.Stop.Address.Zip} {e.Stop.Address.City}, {e.Stop.Address.State} {e.Stop.Address.CountryAbbreviation}</td>;
}

const MilesCell = (props: GridCellProps) => {
  const e = props.dataItem.MapReportLine as MapReportLines;
  return <td>{formatNumber(e.LMiles, "n0")}</td>;
}

const TollsCell = (props: GridCellProps) => {
  const e = props.dataItem.MapReportLine as MapReportLines
  return <td style={{ textAlign: "right" }}>{formatNumber(e.LTolls, "c")}</td>;
}

const SuggestedDateTimeCell = (props: GridCellProps) => {
  const e = props.dataItem.TransitLocation as TransitLocationValue;
  const { value: show, setTrue: showPopop, setFalse: hidePopup } = useBoolean(false);
  const anchor = React.useRef<HTMLButtonElement>(null);
  if (e == null) return <td />;
  return <td>
    {timeSpanToHHmmFormat(e.TransitTime.Total)}<br />
    <button
      ref={anchor}
      className="btn btn-link p-0"
      onMouseEnter={(e) => showPopop()}
      onMouseLeave={() => hidePopup()}
    >
      <span>
        {Moment.utc(e.SuggestedDateTime).format("MM/DD/YYYY HH:mm")}
      </span>
      <Popup
        anchor={anchor.current}
        show={show}
        className={'wrapper'}
        popupClass={'inner-wrapper'}
      >
        <span className="px-2" dangerouslySetInnerHTML={{ __html: e.TransitTime.HtmlFormatted }} />
      </Popup>
    </button>
  </td>;
}

const SuggestedDateTimeTeamCell = (props: GridCellProps) => {
  const e = props.dataItem.TransitLocation as TransitLocationValue;
  const { value: show, setTrue: showPopop, setFalse: hidePopup } = useBoolean(false);
  const anchor = React.useRef<HTMLButtonElement>(null);
  if (e == null) return <td />;
  return <td>
    {timeSpanToHHmmFormat(e.TransitTimeTeam.Total)}<br />
    <button
      ref={anchor}
      className="btn btn-link p-0"
      onMouseEnter={(e) => showPopop()}
      onMouseLeave={() => hidePopup()}
    >
      <span>
        {Moment.utc(e.SuggestedDateTimeTeam).format("MM/DD/YYYY HH:mm")}
      </span>
      <Popup
        anchor={anchor.current}
        show={show}
        className={'wrapper'}
        popupClass={'inner-wrapper'}
      >
        <span className="px-2" dangerouslySetInnerHTML={{ __html: e.TransitTimeTeam.HtmlFormatted }} />
      </Popup>
    </button>
  </td>;
}

const PerMileCell = (props: GridCellProps) => {
  const tlv = props.dataItem.TransitLocation as TransitLocationValue
  if (tlv == null)
    return <td style={{ textAlign: "right" }}></td>;

  const mrl = props.dataItem.MapReportLine as MapReportLines
  if (props.dataItem.PerMile > 0)
    return (
      <td style={{ textAlign: "right" }}>
        ${props.dataItem.PerMile}/mi<br />
        {formatNumber(Math.round(mrl.LMiles) * props.dataItem.PerMile, "c")}
      </td>
    );
  else if (props.dataItem.Flat > 0) {
    if (props.dataItem.LastStop) {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem.Flat, "c")} Flat<br />
        </td>
      );
    } else {
      return (
        <td style={{ textAlign: "right" }}>
        </td>
      );
    }
  }
  else
    return <td style={{ textAlign: "right" }}>$0.00</td>;
}
