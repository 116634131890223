import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from 'react';
import CenterDivPanel from "../../components/CenterDivPanel";
import { fetchApi } from '../../services/api';
import Moment from 'moment-timezone';
import { dateFormatter } from "utils/utils";

type Props = {
    tripId: number;
    onClose: () => void;
}

type DrivingBreakViewModel = {
    DrivingBreakID: number;
    EnteredDateTime: string;
    EnteredBy: string;
    ModifiedDateTime: string;
    ModifiedBy: string;
    ResumeDrivingDateTime: string;
    Comment: string;
    DoNotDisturb: boolean;
    Duration: number;
}

const DrivingBreakHistoryPopup = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [drivingBreaks, setDrivingBreaks] = useState<DrivingBreakViewModel[]>([]);

    useEffect(() => {
        fetchApi(`/api/Track/GetDrivingBreakHistory/${props.tripId}`)
            .then((response: { DrivingBreaks: DrivingBreakViewModel[] }) => {
                setDrivingBreaks(response.DrivingBreaks);
            }).catch((e) => {
                // If not problem details
                if (!e?.status) alert(e);
            });
    }, [props.tripId]);

    return <>
        <Dialog
            className="dialog-w800"
            title={`Driving Breaks`}
            onClose={props.onClose}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Entered By</th>
                        <th>Updated By</th>
                        <th>Break Duration</th>
                        <th>Comment</th>
                        <th>Resume Driving</th>
                        <th>Do Not Disturb</th>
                    </tr>
                </thead>
                <tbody>
                    {drivingBreaks.length === 0 && <tr>
                        <td colSpan={6} className="text-center">No driving breaks found</td>
                    </tr>}
                    {drivingBreaks.map((drivingBreak, index) => {
                        return <tr key={index}>
                            <td title={dateFormatter(drivingBreak.EnteredDateTime)}>{drivingBreak.EnteredBy}</td>
                            <td title={dateFormatter(drivingBreak.ModifiedDateTime)}>{drivingBreak.ModifiedBy}</td>
                            <td>{Moment.duration(drivingBreak.Duration, "minutes").humanize()}</td>
                            <td>{drivingBreak.Comment}</td>
                            <td>{dateFormatter(drivingBreak.ResumeDrivingDateTime)}</td>
                            <td>{drivingBreak.DoNotDisturb ? "Yes" : "No"}</td>
                        </tr>
                    })}
                </tbody>
            </table>

            <DialogActionsBar layout={"end"}>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActionsBar>
        </Dialog>
    </>

}
export default DrivingBreakHistoryPopup;