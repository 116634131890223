import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon, hyperlinkOpenIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string;
    currentTab: ContactTabs;
}

export type ContactTabs = "Profile";

export const CarrierContactNavBar = (props: Props) => {

    const [fullName, setFullName] = useState("");
    const [carrierNumber, setCarrierNumber] = useState("");
    const [carrierID, setCarrierID] = useState(0);
    const [links, setLinks] = useState<ILink[]>([]);

    const refresh = (contactId: string) => {
        setLinks([]);
        fetchApi(`/api/Asset/CarrierContactNavBar/${contactId}`)
            .then((data: { FullName: string, CarrierID: number, CarrierNumber: string, Links: ILink[] }) => {
                setFullName(data.FullName);
                setCarrierID(data.CarrierID);
                setCarrierNumber(data.CarrierNumber);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this contact?")) {
            return;
        }

        fetchApi(`/api/Asset/ReactivateCarrierContact/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    const portal = () => {
        fetchApi(`/api/Asset/ImpersonateCarrierContact/${props.id}`, {}, 'POST')
        .then((response: JsonResponse) => {
            if (response.Success) {
                window.open(response.Links[0].Link, '_blank');
            } else {
                alert(response.ErrorMessage);
            }
        })
        .catch(() => {
            alert("Unable to login carrier portal");
        });
    }

    const retire = () => {
        if (!window.confirm('Retire Contact: Are you sure?')) {
            return;
        }

        fetchApi(`/api/Asset/RetireCarrierContact/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to retire");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    return <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Assets/Carriers">Carriers</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    <Link to={`/Assets/Carrier/${carrierID}`}>{carrierNumber}</Link>
                </span>
                <SvgIcon icon={chevronRightIcon} className="text-muted" />
                <Link to={`/Assets/Carrier/${carrierID}/Contacts`}>Contacts</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {fullName}
                </span>

            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#contactNav" aria-controls="contactNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="contactNav">
                <ul className="nav navbar-nav ml-auto">
                    {links.find((x: ILink) => x.Name === 'Impersonate') && <Button icon="hyperlink-open" svgIcon={hyperlinkOpenIcon} themeColor="secondary" className="mr-2" onClick={portal}>Portal</Button>}
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={retire} themeColor="primary">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={reactivate} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}