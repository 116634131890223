import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../types/link';
import { Link } from 'react-router-dom';

export default class TrailerNumber extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;

        const link = this.props.dataItem.Links.filter((x: ILink) => x.Name === 'TrailerProfile');

        return (
            <td>
                {link.length > 0 && <Link
                    to={link[0].Link}
                    style={{ color: '#007bff' }}
                >
                    {this.props.dataItem.TrailerNumber}
                </Link>}
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
