import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import CenterDivPanel from "components/CenterDivPanel";
import { useCallback, useEffect, useState } from "react";
import { ViewModel } from "TypeGen/Track/PlannedOrders/view-model";
import { fetchApi } from "services/api";
import { Title } from 'utils/title';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import { Link } from "react-router-dom";

type Props = {
  vehicleId: number;
  onClose: () => void;
}

const PlannedOrdersVehicle = ({ vehicleId, onClose }: Props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ViewModel>();

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/ViewPlannedVehicle/${vehicleId}`)
      .then((data: ViewModel) => {
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [vehicleId]);

  useEffect(() => {
      refresh();
  }, [vehicleId, refresh]);

  const dataView = () => {
    if (loading || !data) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }
    return <Grid data={data.PlannedOrders}>
      <Column field="OrderNumber" title="Order #" cell={(props) => <td>
        <Link style={{ color: "#007bff" }} to={`/Order/${props.dataItem.OrderID}`}>{props.dataItem.OrderNumber}</Link>
      </td>} />
      <Column field="DispatchStatus" title="Status" />
      <Column field="Pickup_DateTime" title="Pickup Date" cell={DateCell} />
      <Column field="Delivery_DateTime" title="Delivery Date" cell={DateCell} />
      <Column field="Pickup_Location" title="Pickup Location" />
      <Column field="Delivery_Location" title="Delivery Location" />
      <Column field="TotalDistance" title="Distance" format="{0:n0} mi" />
    </Grid>
  }

  return (<Dialog
    className="dialog-w1140"
    title={`Planned Orders for Vehicle # ${data?.VehicleNumber || ''}`}
    onClose={onClose}
  >
    <Title string="Planned Orders" />
    {dataView()}
  </Dialog>);
}

export default PlannedOrdersVehicle;
