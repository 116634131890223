import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridToolbar } from '@progress/kendo-react-grid';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { Title } from '../../utils/title';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '@progress/kendo-react-buttons';
import { useParams } from 'react-router-dom';
import { VehicleNavBar } from './NavBar';
import ServiceStatusDialog from './ServiceStatusDialog';
import DateCell from '../../components/cells/DateCell';
import Moment from 'moment-timezone';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { JsonResponse } from 'TypeGen/json-response';
import { AssetServiceStatusViewModel } from 'TypeGen/Assets/ServiceStatuses/asset-service-status-view-model';
import { plusIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
    vehicleId: string;
};

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: AssetServiceStatusViewModel = props.dataItem;
    return (
        <td colSpan={props.colSpan} style={props.style}>
            <div>{dataItem.ModifiedByUserValue}</div>
            <div>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
        </td>
    );
};

interface EditCommandCellProps extends GridCellProps {
    enterEdit: (item: AssetServiceStatusViewModel) => void;
    enterDelete: (item: AssetServiceStatusViewModel) => void;
}

const EditCommandCell = (props: EditCommandCellProps) => {
    let dataItem: AssetServiceStatusViewModel = props.dataItem;
    return (
        <td>
            {dataItem.ShowEditButton && <Button themeColor="primary" size="small"
                onClick={() => props.enterEdit(dataItem)}
            >
                Edit
            </Button>}
            {dataItem.ShowDeleteButton && <Button themeColor='error' size="small" className="ml-1"
                onClick={() => props.enterDelete(dataItem)}
            >
                Delete
            </Button>}
        </td>
    );
};

const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "StatusDateTime",
        dir: "desc"
    }]
} as GridState;

const defaultAndFilter = {
    logic: 'and', filters: [
        {} as FilterDescriptor,
    ] as FilterDescriptor[]
} as CompositeFilterDescriptor;

const ServiceStatusGrid = () => {

    const sessionStorageAndFilter = sessionStorage.getItem("ServiceStatusGrid-andFilter");
    let andFilter: CompositeFilterDescriptor = null;
    if (sessionStorageAndFilter) {
        andFilter = JSON.parse(sessionStorageAndFilter);
    } else {
        andFilter = { ...defaultAndFilter };
    }

    const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<AssetServiceStatusViewModel[]>([]);
    const [serviceStatusDialog, setServiceStatusDialog] = useState<{ show: boolean, assetServiceStatusID: number }>({ show: false, assetServiceStatusID: 0 });
    const totalRecords = useRef(0);
    const DataState_Take = 50;

    const _confirmServiceStatus = (dataItem: AssetServiceStatusViewModel) => {
        setLoading(true);

        const data = {
            AssetServiceStatusID: dataItem.AssetServiceStatusID,
            VehicleID: vehicleIdParam,
            IsConfirmed: !dataItem.IsConfirmed
        }

        fetchApi(`/api/Asset/ConfirmServiceStatus`, data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    fetchRecords(false);
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch(() => {
                setLoading(false);
                alert('Unable to confirm service status');
            });
    }

    const _deleteServiceStatus = (dataItem: AssetServiceStatusViewModel) => {
        setLoading(true);

        const data = {
            VehicleID: vehicleIdParam,
            AssetServiceStatusID: dataItem.AssetServiceStatusID
        }

        fetchApi(`/api/Asset/DeleteServiceStatus`, data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    fetchRecords(false);
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch(() => {
                setLoading(false);
                alert('Unable to delete service status');
            });
    }

    const ServiceStatusCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: AssetServiceStatusViewModel = props.dataItem;
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <div style={{ color: dataItem.StatusColor, whiteSpace: 'nowrap' }}>
                    {dataItem.StatusValue}
                    {dataItem.ShowConfirmButton && <Button themeColor={dataItem.IsConfirmed ? 'warning' : 'success'} size="small" className='ml-2'
                        onClick={() => {
                            if (window.confirm(`${dataItem.IsConfirmed ? 'Unconfirm' : 'Confirm'} service status record?`))
                                _confirmServiceStatus(dataItem)
                        }}
                    >
                        {dataItem.IsConfirmed ? 'Unconfirm' : 'Confirm'}
                    </Button>}
                </div>
                <div>{isNullOrWhiteSpace(dataItem.Comments) ? '' : dataItem.Comments}</div>
            </td>
        );
    };

    const enterEdit = (dataItem: AssetServiceStatusViewModel) => {
        setServiceStatusDialog({ show: true, assetServiceStatusID: dataItem.AssetServiceStatusID });
    };

    const enterDelete = (dataItem: AssetServiceStatusViewModel) => {
        if (window.confirm('Delete service status record?'))
            _deleteServiceStatus(dataItem);
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props} enterEdit={enterEdit} enterDelete={enterDelete} />
    );

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const fetchRecords = useCallback((append: boolean) => {
        sessionStorage.setItem("ServiceStatusGrid-andFilter", JSON.stringify(dataState.filter));
        setLoading(true);
        const dataGridState = { ...dataState };
        if (!append) {
            dataGridState.take = DataState_Take;
            dataGridState.skip = 0;
            window.scrollTo(0, 0);
            document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
        }
        const queryStr = `${toDataSourceRequestString(dataGridState)}`;
        fetchApi(`/api/Asset/ServiceStatuses/${vehicleIdParam}?${queryStr}`, {}, 'POST')
            .then(({ Data, Total }) => {
                totalRecords.current = Total;
                if (!append) {
                    setRecords(Data);
                } else {
                    setRecords(prevData => prevData.concat(Data));
                }
                setLoading(false);
                handleResize(null);
            }).catch(() => {
                setLoading(false);
            });
    }, [vehicleIdParam, dataState]);

    const handleResize = (e: UIEvent) => {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
        }
    }

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 2) {
            if (records.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
                return;

            dataState.skip = dataState.skip + dataState.take;
            fetchRecords(true);
        }
    }

    const search = useDebouncedCallback(useCallback(() => {
        fetchRecords(false);
    }, [fetchRecords]), 750);

    useEffect(() => {
        search();
    }, [vehicleIdParam, dataState.filter, dataState.sort, search]);

    const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        setDataState(changeEvent.dataState);
    }

    return (
        <div className="container-fluid">
            <VehicleNavBar id={vehicleIdParam} currentTab="ServiceStatuses" />
            <Title string="Service Status Listing" />
            {serviceStatusDialog.show && <ServiceStatusDialog VehicleID={Number(vehicleIdParam)}
                AssetServiceStatusID={serviceStatusDialog.assetServiceStatusID}
                CloseDialog={(dataSaved: boolean) => { setServiceStatusDialog({ show: false, assetServiceStatusID: 0 }); dataSaved && fetchRecords(false); }}
            />}
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div>
                {/*filters go here*/}
            </div>
            <Grid
                {...dataState}
                onDataStateChange={dataStateChange}
                data={records}
                resizable={true}
                reorderable={true}
                total={totalRecords.current}
                onScroll={scrollHandler}
            >
                <GridToolbar>
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        disabled={records?.filter(x => Moment.utc(x.StatusDateTime) > Moment.utc().startOf('minute')).length === 2}
                        themeColor="primary"
                        onClick={() => setServiceStatusDialog({ show: true, assetServiceStatusID: 0 })}
                    > Service Status
                    </Button>
                </GridToolbar>
                {/* cell={BrokerIDCell} />*/}
                <Column field="StatusDateTime" title="Status Date" filter="date" cell={DateCell} />
                <Column field="StatusValue" title="Status" cell={ServiceStatusCell} />
                <Column field="CityStateValue" title="Location" />
                <Column field="ModifiedByUserValue" title="Modified By" cell={LastModifiedByCell} />
                <Column cell={MyEditCommandCell} width={150} />
            </Grid>
            {
                totalRecords.current > 0 &&
                <div className="k-pager k-pager-md k-grid-pagert">
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
                    </div>
                </div>
            }
        </div>
    )
}

export default ServiceStatusGrid;