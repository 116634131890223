import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CenterDivPanel from '../../components/CenterDivPanel';
import { EditableAddress } from '../../components/EditableAddress';
import { fetchApi } from '../../services/api';
import { AddressViewModel } from '../../TypeGen/Driver/Profile/address-view-model';
import { ILink } from '../../types/link';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
    CloseDialog: () => void;
}

const AddBrokerDialog = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [brokerName, setBrokerName] = useState('');
    const [address, setAddress] = useState<AddressViewModel>({ AddressLine1: '', AddressLine2: '', City: '', State: '', ZipCode: '', Coordinates: { Latitude: 0, Longitude: 0 } });
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');

    const history = useHistory();

    const hasAddress = address.AddressLine1.length > 0 && ((address.City.length > 0 && address.State.length > 0) || address.ZipCode.length > 0);
    const canSave = (hasAddress && isNullOrWhiteSpace(brokerName) === false);

    const _save = () => {
        if (canSave === false)
            return;

        setLoading(true);

        const data = {
            BrokerName: brokerName,
            Address: address,
            PrimaryPhoneNumber: primaryPhoneNumber
        }

        fetchApi('/api/Customer/AddCustomsBroker', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    var link = response.Links.find(l => l.Name === 'CustomsBrokerProfile');
                    if (link) history.push(link.Link);
                    props.CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                setLoading(false);
                // If not problem details
                if (!e?.status) alert('Unable to create broker');
            });
    }

    return <div className="container-fluid">
        <Dialog title={'Create Broker Profile'} onClose={props.CloseDialog} width={400} height={675}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="k-form k-form-md p-0">
                <div>
                    <FieldWrapper>
                        {/*<Label>Broker Name</Label>*/}
                        <Input
                            required
                            maxLength={30}
                            autoFocus
                            disabled={loading}
                            value={brokerName}
                            label="Broker Name"
                            onChange={(e) => setBrokerName(e.value || '')}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Broker Address</Label>
                        <EditableAddress
                            data={address}
                            title="Broker Address"
                            save={async (e) => setAddress(e)}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <MaskedTextBox
                            mask="(000) 000-0000"
                            value={primaryPhoneNumber}
                            disabled={loading}
                            label="Primary Phone Number"
                            onChange={(e) => setPrimaryPhoneNumber(e.value || '')}
                        />
                    </FieldWrapper>
                </div>
            </div>

            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
                    Create Broker
                </Button>
            </DialogActionsBar>
        </Dialog>
    </div>
}

export default AddBrokerDialog;

