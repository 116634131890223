import { Button } from "@progress/kendo-react-buttons";
import useConfirm from "components/useConfirm";

type Props = {
  disabled: boolean;
  rejectAll: () => void;
}

const RejectAllButton = (props: Props) => {

  const { ConfirmationDialog, confirm } = useConfirm({ 
    labels: { confirm: "Reject All", cancel: "Cancel" }, themeColor: "error"
  });

  return <>
    <Button
      size="small"
      themeColor="error"
      fillMode="outline"
      disabled={props.disabled}
      onClick={async (e) => await confirm('Reject All Carrier Bids') && props.rejectAll()}
    >
      Reject All Bids
    </Button>
    <ConfirmationDialog />
  </>;
}

export default RejectAllButton;