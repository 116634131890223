import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import CustomerInput from '../../../components/CustomerInput';
import useAlert from '../../../components/useAlert';
import { fetchApi } from '../../../services/api';
import { ISingleSearchLocation } from '../../../services/pcmiler';
import { isNullOrWhiteSpace } from '../../../utils/utils';
import SingleLocationAutoComplete from '../../PCMiler/SingleLocationAutoComplete';
import Moment from 'moment';
import { JsonResponse } from '../../../TypeGen/json-response';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';

type Props = {
    TrailerID: number,
    CloseDialog: () => void;
}

const RelocateTrailerDialog = (props: Props) => {
    const { alert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState(0);
    const [statusReason, setStatusReason] = useState(0);
    const [tripNumber, setTripNumber] = useState(0);
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [singleSearchLocation, setSingleSearchLocation] = useState<ISingleSearchLocation>();
    const [customer, setCustomer] = useState<{ CustomerID: number, CustomerNumber: string, AddressLine1: string, CustomerName: string }>();
    const [trailerDropDateTime, setTrailerDropDateTime] = useState<Date>();

    const disable = (loading || tripNumber > 0);
    const disable_Customer_Location_DT = disable || status === 1; //isNullOrWhiteSpace(vehicleNumber) === false;

    const statusOptions = [
        { text: 'Unhooked/Available', value: 0 },
        { text: 'Hooked', value: 1 },
        { text: 'Unhooked/Available, FOB', value: 3 },
        { text: 'Unhooked/Unavailable, FOB', value: 4 },
        { text: 'Unhooked/Unavailable', value: 2 }
    ];

    const statusReasonOptions = [
        { text: 'Select Reason...', value: 0 },
        { text: 'Needs Repair', value: 1 },
        { text: 'Needs PM', value: 2 }
    ];

    const renderHookInfo = () => {
        if (status === 1) {
            if (tripNumber > 0)
                return <div className="">
                    <span>Hooked on trip {tripNumber}</span>
                </div>;
            else
                return <div className="">
                    <span>Hooked on vehicle {vehicleNumber}</span>
                </div>;
        }
        return null;
    }

    useEffect(() => {
        setLoading(true);
        fetchApi(`/api/Asset/GetTrailerStatus/${props.TrailerID}`)
            .then((response: any) => {
                setLoading(false);
                if (response.TrailerCustomerNumber != null && response.TrailerCustomerNumber !== "0") {
                    setCustomer({
                        CustomerID: response.TrailerCustomerID,
                        CustomerNumber: response.TrailerCustomerNumber,
                        AddressLine1: response.AddressLine1,
                        CustomerName: response.TrailerCustomerName
                    });
                }

                if (isNullOrWhiteSpace(response.TrailerCity) === false) {
                    setSingleSearchLocation({
                        Address: { City: response.TrailerCity, State: response.TrailerState, Zip: response.TrailerZipCode }
                    } as ISingleSearchLocation)
                }

                setTrailerDropDateTime(Moment.utc(response.TrailerDropDateTime).toDate());
                setComment(response.Comment);
                setStatus(response.Status);
                setStatusReason(response.Reason);
                setTripNumber(response.TripNumber);
                setVehicleNumber(response.VehicleNumber);
            });
    }, [props.TrailerID]);

    const _save = () => {
        setLoading(true);

        const data = {
            TrailerID: props.TrailerID,
            Status: status,
            Comment: comment,
            Reason: statusReason,
            StatusReason: statusReason,
            TrailerDropDateTime: trailerDropDateTime,
            TrailerCustomerID: customer?.CustomerID,
            TrailerCity: singleSearchLocation?.Address.City,
            TrailerState: singleSearchLocation?.Address.State,
            TrailerZipCode: singleSearchLocation?.Address.Zip
        }

        fetchApi('/api/Asset/UpdateTrailerStatus', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) props.CloseDialog();
                else alert(response.ErrorMessage);
            })
            .catch(e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) alert('Unable to update trailer');
            });
    }

    return (
        <Dialog title={`Update Trailer Status`} onClose={props.CloseDialog} className="dialog-w525">
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="container">
                <div className="row mt-2">
                    <div className="col-md-6">
                        <div>
                            <span>Status:</span>
                            <DropDownList
                                disabled={disable}
                                data={status != 1 ? statusOptions.filter(x => x.value !== 1) : statusOptions}
                                textField="text"
                                dataItemKey="value"
                                value={statusOptions.find(x => x.value === status)}
                                onChange={(e) => { setStatus(e.target.value.value); }}
                            />
                            {renderHookInfo()}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <span>Status Reason:</span>
                            <DropDownList
                                disabled={disable || status === 1}
                                data={statusReasonOptions}
                                textField="text"
                                dataItemKey="value"
                                value={statusReasonOptions.find(x => x.value === statusReason)}
                                onChange={(e) => setStatusReason(e.target.value.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-6">
                        <DateTimePicker
                            required={loading ? false : true}
                            disabled={disable_Customer_Location_DT}
                            //min={minDropDateTime}
                            //valid={loading ? true : isValidDropDateTime}
                            label={`As Of Date/Time (EST)`}
                            format="MM/dd/yyyy HH:mm"
                            formatPlaceholder="formatPattern"
                            value={trailerDropDateTime}
                            onChange={(e) => setTrailerDropDateTime(e.value)}
                            width="100%"
                        />
                    </div>
                    <div className="col-md-6">
                        <div>
                            <span>Comment:</span>
                            <Input
                                maxLength={60}
                                disabled={disable_Customer_Location_DT}
                                value={comment}
                                onChange={(e) => setComment(e.value as string)}
                                placeholder="Comment"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <span>Location:</span>
                        <div className="input-group">
                            <CustomerInput
                                CustomerID={customer?.CustomerID}
                                CustomerNumber={customer?.CustomerNumber}
                                placeholder={`Enter Customer (Optional)`}
                                disabled={disable_Customer_Location_DT}
                                onChange={(e) => {
                                    if (e.CustomerID > 0) {
                                        setSingleSearchLocation({
                                            Address: {
                                                City: e.City,
                                                Zip: e.ZipCode,
                                                State: e.State
                                            }
                                        } as ISingleSearchLocation);
                                        setCustomer({
                                            CustomerID: e.CustomerID,
                                            CustomerNumber: e.CustomerNumber,
                                            CustomerName: e.CustomerName,
                                            AddressLine1: e.AddressLine1
                                        });
                                    } else {
                                        setSingleSearchLocation(undefined);
                                        setCustomer(undefined);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <SingleLocationAutoComplete
                            value={(singleSearchLocation?.Address.Zip || singleSearchLocation?.Address.City) && `${singleSearchLocation?.Address.Zip} ${singleSearchLocation?.Address.City}, ${singleSearchLocation?.Address.State}`}
                            autoCompleteProps={{ clearButton: true, placeholder: 'Enter Trailer City or Zip', disabled: disable_Customer_Location_DT }}
                            onSelected={(e) => {
                                setCustomer(undefined);
                                setSingleSearchLocation(e);
                            }}
                        />
                    </div>
                </div>
            </div>
            <DialogActionsBar layout={'end'}>
                <Button themeColor='primary' disabled={disable_Customer_Location_DT} onClick={_save}>Save</Button>
                <Button onClick={props.CloseDialog}>Cancel</Button>
            </DialogActionsBar>
        </Dialog>
    );
};

export default RelocateTrailerDialog;

