import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';

export class SpeedCell extends React.Component<GridCellProps> {
  public render() {
    return (
      <td>
        {this.props.dataItem.DeadHeadMiles} / {this.props.dataItem.LoadedMiles} mi<br />
        {this.props.dataItem.ETAMiles} mi<br />
        {this.props.dataItem.MinutesLate > 0 && <span style={{ color: 'red' }}>
          Late: {this.props.dataItem.MinutesLate} min.<br />
        </span>}
        {this.props.dataItem.TimeCriticalMinutes > 0 && <span style={{ color: 'red' }}>
          Critical: {this.props.dataItem.TimeCriticalMinutes} min.<br />
        </span>}
        {this.props.dataItem.RequiredSpeed > 0 && <span>({this.props.dataItem.RequiredSpeed} mph)</span>}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    if (nextProps.dataItem.Hash === this.props.dataItem.Hash) {
      return false;
    }

    return true;
  }
}
