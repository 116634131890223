import { GridCellProps } from '@progress/kendo-react-grid';
import Moment from 'moment-timezone';
import * as React from 'react';
import { Trip } from '.';
import DrivingBreakPopup from './DrivingBreakPopup';

const ETACell = (props: GridCellProps) => {
  const [showDrivingBreakPopup, setShowDrivingBreakPopup] = React.useState(false);
  const data = props.dataItem as Trip;

  return (
    <td>
      {showDrivingBreakPopup && <DrivingBreakPopup
        tripId={data.TripID}
        onClose={() => setShowDrivingBreakPopup(false)}
      />}
      {data.ETADateTime && <span style={{ color: data.IsRunningLate ? 'red' : undefined }}>
        {Moment.utc(data.ETADateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}
      </span>}
      <br />
      <span
        style={{ cursor: 'pointer' }}
        className={data.ResumeDrivingDateTime ? "badge badge-danger" : "badge badge-light"}
        onClick={() => setShowDrivingBreakPopup(true)}
      >
        {data.ResumeDrivingDateTime
          ? ((data.DrivingBreakDoNotDisturb ? 'DND: ' : 'DB: ') + Moment.utc(data.ResumeDrivingDateTime).tz("America/New_York").format('MM/DD HH:mm'))
          : "Add Break"}
      </span>
    </td>
  );
}

export default React.memo(ETACell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
