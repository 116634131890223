import 'bootstrap';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router, matchPath } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { store } from './store';
import * as RoutesModule from './routes';
import * as Sentry from "@sentry/react";
import {createBrowserHistory} from 'history';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import { TitleProvider } from './utils/title';
import { version } from './version';
import { RouteConfig } from '@sentry/react/build/types/reactrouter';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { AlertProvider } from 'components/useAlert';
import 'hammerjs'; // Required for kendo-react-charts

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
const defaultTheme = localStorage.getItem("darkmode") === "false" ? "light" : "dark";

const history = createBrowserHistory();
const routes: RouteConfig[] = Object.entries(RoutesModule.paths).map(x => { return { path: x[1] }});

// Configure TrimbleMaps
TrimbleMaps.APIKey = "87A1A88FC4D1F14CB0FAC557C2C792FF";

// Configure Sentry
Sentry.init({
  release: `loadone@${version}`,
  environment: window.location.hostname === "loadboard-staging.azurewebsites.net" ? "staging" : undefined,
  enabled: window.location.hostname !== "localhost",
  dsn: "https://406b2b1377cb40afad3672ae4b6eeb5c@o261143.ingest.sentry.io/1825006",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
      routes,
      matchPath
    }),
  ],

  // Only sample .5% of sessions
  tracesSampleRate: 0.005,
});

// Configure isTMS
window.isTMS = true;

function renderApp() {
  const container = document.getElementById('react-app');
  const root = createRoot(container);
  root.render(
    <SentryErrorBoundary>
      <Provider store={store}>
        <TitleProvider title="Load One LLC">
          <AlertProvider>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={defaultTheme}
              insertionPoint={document.getElementById('inject-styles-here')}
            >
              <Router history={history}>
                <CompatRouter children={RoutesModule.routes} />
              </Router>
            </ThemeSwitcherProvider>
          </AlertProvider>
        </TitleProvider>
      </Provider>
    </SentryErrorBoundary>
  );
}

renderApp();
