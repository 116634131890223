import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../../types/link';
import { Link } from 'react-router-dom';

export default class DriverNumber extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;

        const driverOwnerProfileLink = this.props.dataItem.Links.filter((x: ILink) => x.Name === 'OwnerProfile');
        const driverOwnerLink = driverOwnerProfileLink.length > 0 && <>
            <br />
            <Link
                to={driverOwnerProfileLink[0].Link}
                style={{ color: '#007bff' }}
            >
                {this.props.dataItem.OwnerNumber}
            </Link>
        </>;

        var driver1Profile = this.props.dataItem.Links.find((x: ILink) => x.Name === 'DriverProfile');
        return (
            <td>
                <Link
                  to={driver1Profile.Link}
                  style={{ color: '#007bff' }}
              >
                  {this.props.dataItem.DriverNumber}
              </Link>
                {driverOwnerLink}
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
