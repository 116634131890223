import { DropDownList, DropDownListProps } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';

export type PayPeriodViewModel = {
  PayPeriodID: number;
  PeriodEndDate: Date;
  Name: string;
  Division: number;
  DivisionName: string;
  PeriodClosed: boolean;
  Label: string;
};

type InnerProps = {
  onSelected: (payPeriod: PayPeriodViewModel) => void;
  selectedPayPeriodID?: number;
  payDivision?: number;
  fromPeriodEndingDate?: Date;
  defaultPayPeriodItem?: PayPeriodViewModel | { PayPeriodID: number, Label: string };
  includeOpenPayPeriods: boolean;
  includeClosedPayPeriods: boolean;
}

type Props = InnerProps & DropDownListProps

const PayPeriodDropDownList: React.FC<Props> = (props) => {
  const {
    onSelected,
    selectedPayPeriodID,
    includeOpenPayPeriods,
    includeClosedPayPeriods,
    payDivision,
    fromPeriodEndingDate,
    defaultPayPeriodItem,
    ...dropDownListProps
  } = props;

  const [loading, setLoading] = useState(false);
  const [payPeriods, setPayPeriods] = useState<PayPeriodViewModel[]>([]);
  const [filterByPayPeriod, setFilterByPayPeriod] = useState<number | null>(props.selectedPayPeriodID);
  const prevProps = useRef<InnerProps>();

  useEffect(() => {
    setFilterByPayPeriod(selectedPayPeriodID);
  }, [selectedPayPeriodID]);

  useEffect(() => {
    if (prevProps.current) {
      const periodEndingDateComparison = prevProps.current.fromPeriodEndingDate && props.fromPeriodEndingDate ?
        prevProps.current.fromPeriodEndingDate.getTime() === props.fromPeriodEndingDate.getTime() :
        prevProps.current.fromPeriodEndingDate === props.fromPeriodEndingDate;

      const hasNotChanged = prevProps.current.includeClosedPayPeriods === props.includeClosedPayPeriods &&
        prevProps.current.includeOpenPayPeriods === props.includeOpenPayPeriods &&
        prevProps.current.payDivision === props.payDivision &&
        //prevProps.current.selectedPayPeriodID === props.selectedPayPeriodID &&
        periodEndingDateComparison;

      if (hasNotChanged)
        return;
    }

    setLoading(true);

    let data = {};
    if (props.includeClosedPayPeriods || props.includeOpenPayPeriods) {
      if ((props.includeClosedPayPeriods && props.includeOpenPayPeriods) === false) {
        if (props.includeOpenPayPeriods)
          Object.assign(data, { IsOpen: true });
        else
          Object.assign(data, { IsOpen: false });
      }
    };

    if (props.payDivision > 0)
      Object.assign(data, { PayrollClass: props.payDivision });

    if (props.fromPeriodEndingDate)
      Object.assign(data, { FromDate: props.fromPeriodEndingDate });

    fetchApi('/api/Settlements/GetPayPeriods', data, 'POST')
      .then((response: any) => {
        let payperiodViewModels = response as PayPeriodViewModel[];
        setPayPeriods(payperiodViewModels);
        setLoading(false);
      });

    prevProps.current = { ...props };

  }, [props]);

  return <DropDownList
    {...dropDownListProps}
    style={{ width: '225px' }}
    loading={loading}
    data={payPeriods}
    textField="Label"
    dataItemKey="PayPeriodID"
    value={payPeriods.find((x) => x.PayPeriodID == filterByPayPeriod) || null}
    defaultItem={defaultPayPeriodItem}
    onChange={(e) => {
      setFilterByPayPeriod(e.target.value.PayPeriodID);
      onSelected(e.target.value);
    }}
  />
}

export default PayPeriodDropDownList;
