import * as React from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { Form, FormHandle, Field } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import PhoneCell from './cells/PhoneCell';
import { openWindow } from '../services/openWindow';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../services/api';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { hyperlinkOpenIcon, searchIcon } from '@progress/kendo-svg-icons';

type BrokerAutocomplete = {
    BrokerID: number;
    BrokerName: string;
    City: string;
    State: string;
}

export type BrokerInputValue = {
    BrokerID: number;
    BrokerName: string;
}

type BrokerSearchValue = {
    BrokerID: number;
    BrokerName: string;
    AddressLine1: string;
    City: string;
    State: string;
    Zip: string;
    Phone1: string;
    Fax: string;
}

type Props = {
    BrokerID: number;
    BrokerName: string;
    placeholder?: string;
    onChange: (response: BrokerInputValue) => void;
    focus?: boolean;
    prependGroup?: boolean;
}

type State = {
    data: BrokerAutocomplete[];
    searchData: BrokerSearchValue[];
    isLoadingAutoComplete: boolean;
    isLoadingSearchData: boolean;
    brokerSearch: boolean;
    ValidationRequestHash: string;
}

export default class BrokerInput extends React.Component<Props, State> {

    private autocomplete: React.RefObject<AutoComplete>;
    private searchForm: React.RefObject<FormHandle>;

    constructor(props: Props) {
        super(props);

        this.autocomplete = React.createRef();
        this.searchForm = React.createRef();

        this.openBroker = this.openBroker.bind(this);
        this.openBrokerSearch = this.openBrokerSearch.bind(this);
        this.updateBrokerAutocomplete = this.updateBrokerAutocomplete.bind(this);

        this.selectBroker = this.selectBroker.bind(this);

        this.state = {
            data: [],
            searchData: [],
            isLoadingAutoComplete: false,
            isLoadingSearchData: false,
            brokerSearch: false,
            ValidationRequestHash: ''
        }
    }

    public componentDidMount() {
        if (this.props.focus && this.autocomplete.current) {
            this.autocomplete.current.focus();
        }
    }

    public render() {
        return <>
            {this.state.brokerSearch && this.brokerSearchWindow()}
            <AutoComplete
                ref={this.autocomplete}
                data={this.state.data}
                value={this.props.BrokerName}
                style={this.props.prependGroup ? { flex: 1, width: '100%', borderRadius: 0 } : { flex: 1, width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                loading={this.state.isLoadingAutoComplete}
                placeholder={this.props.placeholder || 'Broker'}
                itemRender={this.itemRenderBroker}
                onChange={(event: AutoCompleteChangeEvent) => {
                    // Picked from auto complete
                    if (typeof event.target.value === 'object') {
                        const brokerObj = event.target.value as any;
                        this.props.onChange({
                            BrokerID: brokerObj.BrokerID, 
                            BrokerName: brokerObj.BrokerName,
                        });
                    } else {
                        this.props.onChange({
                            BrokerID: 0,
                            BrokerName: event.target.value,
                        });

                        // Update Autocomplete Suggestions
                        if (event.target.value.length > 2) {
                            this.setState({ ValidationRequestHash: '' }, () => {
                                this.updateBrokerAutocompleteDebounced(event.target.value);
                            });
                        }
                    }
                }}
            />
            {!this.props.BrokerID && <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={this.openBrokerSearch}>
                    <SvgIcon icon={searchIcon} />
                </button>
            </div>}
            {this.props.BrokerID > 0 && <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={this.openBroker}>
                    <SvgIcon icon={hyperlinkOpenIcon} />
                </button>
            </div>}
        </>
    }

    private itemRenderBroker = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = <span style={{ fontSize: ".75rem" }}>
            {itemProps.dataItem.BrokerName} ({itemProps.dataItem.City}, {itemProps.dataItem.State})
        </span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    private brokerSearchWindow() {
        return <Window 
            title="Broker Search"
            style={{ position: 'fixed' }}
            initialWidth={Math.min(850, window.innerWidth)}
            initialHeight={Math.min(550, window.innerHeight)}
            onClose={() => this.setState({ brokerSearch: false, searchData: [] })}
        >
            <Form
                ref={this.searchForm}
                onSubmit={(values, event) => this.searchBrokers(values)}
                render={(formRenderProps) => (
                    <form onSubmit={formRenderProps.onSubmit} className="k-form k-form-md p-0">
                        <div className="row">
                            <div className="col-md-4">
                                <Field name="BrokerName" component={Input} label="Name" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="City" component={Input} label="City" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="State" component={Input} label="State" maxLength={2} style={{ width: '100%' }} />
                            </div>
                        </div>
                        <br />
                        <div className="text-center">
                            <Button themeColor="primary" disabled={!formRenderProps.allowSubmit}>Search</Button>
                            &nbsp;&nbsp;
                            <Button themeColor="primary" onClick={this.createBroker}>Add Broker</Button>
                            &nbsp;&nbsp;
                            <Button onClick={() => this.searchForm.current.resetForm()}>Reset</Button>
                        </div>
                </form>
            )} />
            <br />
            {this.state.isLoadingSearchData && <span
                className="k-i-loading k-icon"
                style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
            />}
            <Grid
                data={this.state.searchData}
                dataItemKey="BrokerID"
                onRowClick={(e) => this.selectBroker(e.dataItem)}
            >
                <Column field="BrokerName" title="Name" cell={(props) => <td style={{ color: '#007bff' }} role="button">{props.dataItem.BrokerName}</td>} />
                <Column field="AddressLine1" title="Address" />
                <Column field="City" />
                <Column field="State" />
                <Column field="Zip"/>
                <Column field="Phone1" title="Phone" cell={PhoneCell} />
                <Column field="Fax" />
            </Grid>
        </Window>
    }

    private openBroker() {
        openWindow(`/CustomsBrokers/Broker/${this.props.BrokerID}`);
    }

    private createBroker() {
        openWindow(`/CustomsBrokers`);
    }

    private openBrokerSearch() {
        this.setState({ brokerSearch: true });
    }

    private updateBrokerAutocompleteDebounced = debounce(this.updateBrokerAutocomplete, 400);

    private updateBrokerAutocomplete(value: string) {
        const requestHash = Math.random().toString(36).substring(7);
        this.setState({ isLoadingAutoComplete: true, ValidationRequestHash: requestHash });
        const data = {
            Term: value.trim(),
        }
        fetchApi("/api/Broker/Autocomplete", data, "POST")
            .then((options: BrokerAutocomplete[]) => {
                if (this.state.ValidationRequestHash === requestHash) {
                    this.setState({ isLoadingAutoComplete: false, data: options });
                }
            });
    }

    private searchBrokers(values: { [name: string]: any; }) {
        this.setState({ isLoadingSearchData: true });
        fetchApi("/api/Broker/Search", values, "POST")
            .then((response: { Brokers: BrokerSearchValue[] }) => {
                this.setState({ isLoadingSearchData: false, searchData: response.Brokers });
            })
    }

    private selectBroker(dataItem: BrokerInputValue) {
        this.setState({
            searchData: [],
            brokerSearch: false,
        });
        this.props.onChange({
            BrokerID: dataItem.BrokerID,
            BrokerName: dataItem.BrokerName,
        });
    }
}