import { fetchApi } from '../services/api';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import Moment from 'moment';
import { SourceType } from '../views/LoadBoard/sourceType';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoadBoardBidState {
  data: Map<string, Partial<LoadBoardBidDetailState>>;
  shallowTrigger: Date;
};

export type LoadBoardBidDetailState = {
  loading: boolean;
  bid: GetBidResponse | null;
  show: boolean;
  showTeam: boolean;

  deadheadMiles: number;
  hoursofServiceRemaining: Date;

  // Bid Temp Fields
  bidAlt: boolean;
  vehicleSize: string;
  spotBid: number;
  aboveTarrifReason: number;
  milesToPickup: number;
  note: string;
  team: number;
  fast: boolean;
  csa: boolean;
  fastcsa: boolean;
  borderCrossings: ActiveAeroBidBorderCrossing[];
  lineItems: ExpeditorsBidLineItem[];
  carrierTimes: Array<Date|null>;
}

export type GetBidResponse = {
  ShipmentID: string;
  SourceType: SourceType;
  Note: string;
  CargoNote: string;
  HazMat: boolean;
  HazmatClass: string;
  HazmatUNNumber: string;
  DockHigh?: boolean;
  Stops: Stop[];
  Cargo: Cargo[];
  SuggestedBid: number;
  FuelSurcharge: number;
  RatePerMile: number;
  IncludeFuelSurchargeInBid: boolean;
  ETAShipper: Date;
  ETAConsignee: Date;
  SuggestedETAConsignee: Date;
  SuggestedETAConsigneeTeam: Date;
  TransitTime: TransitTime;
  TransitTimeTeam: TransitTime;
  Team: boolean;
  CSAShipment: boolean;
  FASTShipment: boolean;
  ReferenceNumber3: string;
  DefaultNote: string;
  Spare: string[];
  BorderCrossings: BorderCrossing[];
  LineItems: ExpeditorsLineItem[];
  ActiveAeroBorders: ActiveAeroBorder[];
}

type Cargo = {
  FromLocation: string;
  ToLocation: string;
  Pieces: number;
  Weight: number;
  DIMs: string;
  Stackable: boolean;
  ScheduledDateTime: Date;
}

type Stop = {
  LocationName: string;
  AddressLine1: string;
  AddressLine2: string;
  CityStateZip: string;
  ScheduledDateTime: Date;
  EarliestDateTime: Date;
  LatestDateTime: Date;
  OpenTime: string;
  CloseTime: string;
  DistanceFromPreviousStop: number;
}

type TransitTime = {
  DrivingTime: string;
  WaitTime: string;
  SleepTime: string;
  Total: string;
  HtmlFormatted: string;
}

type BorderCrossing = {
  ShipmentStopID: string;
}

type ExpeditorsLineItem = {
  SourceEntityID: string;
  Description: string;
  Amount: number;
}

type ActiveAeroBorder = {
  ID: string;
  Name: string;
}

type ActiveAeroBidBorderCrossing = {
  StopID: string;
  Name: string;
}

type ExpeditorsBidLineItem = {
  SourceEntityID: string;
  OverrideAmount: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestBidAction {
  type: 'REQUEST_BID';
  shipmentId: string;
}

interface UpdateBidAction {
  type: 'UPDATE_BID';
  shipmentId: string;
  data: Partial<LoadBoardBidDetailState>;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestBidAction | UpdateBidAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestBid: (shipmentId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (!getState().loadBoardBid.data.has(shipmentId)) {
      dispatch({ type: 'REQUEST_BID', shipmentId });

      fetchApi('/api/LoadBoard/Bid', { ShipmentID: shipmentId }, 'POST')
        .then((bid: GetBidResponse) => {
          bid.ETAConsignee = Moment(bid.ETAConsignee).toDate();
          bid.ETAShipper = Moment(bid.ETAShipper).toDate();
          bid.SuggestedETAConsignee = Moment(bid.SuggestedETAConsignee).toDate();
          bid.SuggestedETAConsigneeTeam = Moment(bid.SuggestedETAConsigneeTeam).toDate();
          const lineItems = bid.LineItems.map((x) => {
            return { SourceEntityID: x.SourceEntityID, OverrideAmount: null }
          });
          dispatch({
            type: 'UPDATE_BID',
            shipmentId: shipmentId,
            data: {
              loading: false,
              bid,
              borderCrossings: new Array(bid.BorderCrossings.length),
              lineItems,
              carrierTimes: new Array<Date|null>(bid.Spare.length),
              spotBid: bid.SuggestedBid,
              note: bid.DefaultNote
            }
          });
        });
    }
  },
  updateBid: (shipmentId: string, data: Partial<LoadBoardBidDetailState>): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_BID', shipmentId, data });
  },
  updateTransit: (shipmentId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const bid = getState().loadBoardBid.data.get(shipmentId);
    const data = {
      ShipmentID: bid.bid.ShipmentID,
      DeadHeadDistance: bid.deadheadMiles,
      HoursOfServiceRemaining: Moment(bid.hoursofServiceRemaining).format("HH:mm"),
      StartDateTime: bid.bid.ETAShipper
    };
    fetchApi("/api/LoadBoard/TransitTime", data, "POST")
      .then((response: { SuggestedDateTime: Date, SuggestedDateTimeTeam: Date, TransitTime: TransitTime, TransitTimeTeam: TransitTime }) => {
        response.SuggestedDateTime = Moment(response.SuggestedDateTime).toDate();
        response.SuggestedDateTimeTeam = Moment(response.SuggestedDateTimeTeam).toDate();
        const newBid = Object.assign({}, bid.bid, {
          SuggestedETAConsignee: response.SuggestedDateTime,
          SuggestedETAConsigneeTeam: response.SuggestedDateTimeTeam,
          TransitTime: response.TransitTime,
          TransitTimeTeam: response.TransitTimeTeam
        });
        dispatch({ type: 'UPDATE_BID', shipmentId, data: { bid: newBid } });
      });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LoadBoardBidState = { data: new Map(), shallowTrigger: new Date() };

export const reducer: Reducer<LoadBoardBidState> = (state: LoadBoardBidState, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_BID':
      return {
        data: state.data.set(action.shipmentId, {
          loading: true,
          bid: null,
          deadheadMiles: 0,
          hoursofServiceRemaining: new Date(0, 0, 0, 11, 0, 0),
          bidAlt: false,
          vehicleSize: '',
          spotBid: 0,
          aboveTarrifReason: 0,
          milesToPickup: 0,
          note: '',
          team: 0,
          fast: false,
          csa: false,
          fastcsa: false,
          borderCrossings: [],
          lineItems: []
        }),
        shallowTrigger: new Date()
      };
    case 'UPDATE_BID':
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      const newBid = Object.assign(state.data.get(action.shipmentId), action.data);
      return {
        data: state.data.set(action.shipmentId, newBid),
        shallowTrigger: new Date()
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};
