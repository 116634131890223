import { formatNumber } from '@progress/kendo-intl';
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import LocalDateOnlyCell from "../../components/cells/LocalDateOnlyCell";
import CenterDivPanel from '../../components/CenterDivPanel';
import { EditableField } from "../../components/EditableField";
import { EditableIDName } from "../../components/EditableIDName";
import { fetchApi } from "../../services/api";
import { IDName } from '../../types/idname';
import { StateCodes } from "../../utils/states";
import { Title } from '../../utils/title';
import DriverAutoComplete, { GetDriverAutoCompleteDisplayValue } from "../AssetDrivers/DriverAutoComplete";
import OwnerAutoComplete, { GetOwnerAutoCompleteDisplayValue } from "../AssetOwners/OwnerAutoComplete";
import { AssetEntityType, DriverValue } from "../Assets/AssetPanel";
import CompliancePopup, { ComplianceViewModel } from "../Assets/CompliancePopup";
import { OwnerViewModel } from "../Assets/OwnerGrid";
import TrailerAutoComplete, { GetTrailerAutoCompleteDisplayValue, TrailerValue } from "../AssetTrailers/TrailerAutoComplete";
import { VehicleNavBar } from "./NavBar";
import { JsonResponse } from 'TypeGen/json-response';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { Link } from 'react-router-dom';
import { AssetComplianceTypeID } from 'TypeGen/Assets/asset-compliance-type-id';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { plusIcon } from '@progress/kendo-svg-icons';

type Props = {
  isDialog?: boolean
  vehicleId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  vehicleId: string;
};

type VehicleProfileType = {
  Vehicle: VehicleType,
  Users: IDName[];
  XPOTruckTypes: IDName[];
}

export type VehicleType = {
  ModNum: number;
  VehicleNumber: string;
  Domicile: string;
  LicenseState: string;
  LicensePlate: string;
  VINNumber: string;
  Comment1: string;
  Comment2: string;
  Comment3: string;
  DoorType: string;
  ModelMake: string;
  Model: string;
  Color: string;
  InsuranceCarrier: string;
  FastTransponder: string;
  IFTAStickerNum: string;
  ReeferModel: string;
  Active: boolean;
  PayrollActive: boolean;
  HideFromDispatch: boolean;
  HideFromDispatchReason: string;
  CanCrossBorder: boolean;
  DockHigh: boolean;
  AirRide: boolean;
  TemperatureControl: boolean;
  LiftGate: boolean;
  Team: boolean;
  ETrac: boolean;
  BoxTruck: boolean;
  UseForAutoBidding: boolean;
  AutomaticallyPost: boolean;
  FastApproved: boolean;
  FuelTaxExempt: boolean;
  ComplianceDays: number;
  VehicleTypeID: number;
  VehicleClassID: number;
  DispatchBoard: number;
  PayrollClassID: number;
  RevenueClassID: number;
  ModelYear: number;
  AxleCount: number;
  InsuranceType: number;
  XPOTruckType: number;
  OwnedByType: number;
  Payload: number;
  GrossVehicleWeight: number;
  BoxHeight: number;
  BoxWidth: number;
  BoxLength: number;
  DoorH: number;
  DoorW: number;
  MPG: number;
  UnLadenWeight: number;
  PartialSpace: number;
  FuelCapacity: number;
  PurchaseCost: number;
  HiredByUserID: number | null;
  FuelSource: number;
  ApprovedPassenger: string;
  PowerOfAttorney: string;
  Authority: string;
  AuthorityDate: string | null;
  TripID: number;
  //LastMaintenanceDate: Date;
  //NextMaintenanceDate: Date;
  //LastInspectionDate: Date;
  //NextInspectionDate: Date;
  LicenseExpiryDate: Date;
  InsuranceExpiryDate: Date;
  PurchaseDate: Date;
  IFTAStickerExpDate: Date;
  HiredDate: Date;
  Driver1Info: DriverValue;
  Driver2Info: DriverValue;
  Driver3Info: DriverValue;
  OwnerInfo: OwnerViewModel;
  Trailer1Info: TrailerValue;
  //Trailer2Info: TrailerValue;
  AuditInfo: AuditInfoViewModel;
  Compliance: ComplianceViewModel[];
}

export const VehicleTypes = [
  { ID: 1, Name: 'CARGO VAN' },
  { ID: 2, Name: 'SMALL STRAIGHT' },
  { ID: 3, Name: 'LARGE STRAIGHT' },
  { ID: 4, Name: 'TRACTOR' },
  { ID: 5, Name: 'FLAT BED' },
  { ID: 6, Name: 'AIR FREIGHT' },
  { ID: 7, Name: 'REEFER' },
  { ID: 8, Name: 'SPECIALIZED' },
  { ID: 9, Name: 'FLATBED CURTAIN' },
  { ID: 10, Name: 'FLATBED W/TARPS' },
  { ID: 11, Name: 'STEP CURTAIN' },
  { ID: 12, Name: 'OPEN STEP' },
  { ID: 13, Name: 'OVERSIZE FLAT' },
  { ID: 14, Name: 'OVERSIZE STEP' },
  { ID: 15, Name: 'SPRINTER' },
  { ID: 16, Name: '53\'' },
  { ID: 17, Name: 'DOUBLE DROP RGN' },
  { ID: 18, Name: 'FLAT OR STEP RETRACTABLE' },
  { ID: 19, Name: 'DOUBLE DROP CURTAINSIDE' },
  { ID: 20, Name: 'AIR CHARTER' },
  { ID: 21, Name: 'CONTAINER' },
  { ID: 22, Name: 'LTL' },
  { ID: 23, Name: 'HOTSHOT' },
  { ID: 24, Name: 'HAND CARRY' }
];

export const PostingClasses = [
  { ID: 1, Name: 'Not Defined' },
  { ID: 10, Name: 'Car/PickUp' },
  { ID: 20, Name: 'Cargo Van' },
  { ID: 30, Name: 'Sprinter Van' },
  { ID: 35, Name: 'Cube Van' },
  { ID: 40, Name: 'Small Straight' },
  { ID: 50, Name: 'Large Straight' },
  { ID: 55, Name: 'Dump Trailer' },
  { ID: 60, Name: 'Truckload Dry Van' },
  { ID: 70, Name: 'Flatbed' },
  { ID: 80, Name: 'Reefer' },
  { ID: 90, Name: 'Power Only' },
  { ID: 100, Name: 'Intermodal' },
  { ID: 110, Name: 'Tanker' },
  { ID: 120, Name: 'Other' },
  { ID: 130, Name: 'LTL' },
  { ID: 140, Name: 'Airport Linehaul' },
  { ID: 150, Name: 'Reefer Tractor/Trailer' }
];

const DoorTypes = [{ ID: 'R', Name: 'Roll' }, { ID: 'B', Name: 'Barn' }, { ID: 'N', Name: 'N/A' }];
const Domiciles = [{ ID: 'U', Name: 'US' }, { ID: 'C', Name: 'CAN' }];
export const DispatchBoards = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'TRUCKLOAD' }, { ID: 2, Name: 'BROKERAGE' }, { ID: 3, Name: 'DEDICATED' }, { ID: 4, Name: 'EXPEDITE' }, { ID: 5, Name: 'SPECIALIZED' }];
const PayrollClasses = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'DO NOT USE' }, { ID: 2, Name: 'D-DRIVER' }, { ID: 3, Name: 'C-COMPANY' }];
const RevenueClasses = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'TRUCKLOAD-01' }, { ID: 2, Name: 'DEDICATED-03' }, { ID: 3, Name: 'EXPEDITE-04' }, { ID: 4, Name: 'SPECIAL/PDQ-05' }];
const FuelSources = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'Diesel' }, { ID: 2, Name: 'Gas' }, { ID: 3, Name: 'Jet Fuel' }, { ID: 4, Name: 'LPG' }];
const InsuranceTypes = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'In House' }, { ID: 2, Name: 'Owner Provided' }];
const OwnedByTypes = [{ ID: 0, Name: 'None' }, { ID: 1, Name: 'Company' }, { ID: 2, Name: 'Rented' }, { ID: 3, Name: 'Leased' }, { ID: 4, Name: 'Owner/Operator' }];
const HaulingCategories = [
  { ID: 0, Name: 'None' },
  { ID: 1, Name: 'Long-Haul Teams' },
  { ID: 2, Name: 'Long-Haul Singles' },
  { ID: 3, Name: 'Regional Teams/Singles' },
  { ID: 4, Name: 'Local Only' },
  { ID: 5, Name: 'Local or Long-Haul' },
];

const VehicleProfile = (props: Props) => {

  const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
  const [vehicleId, setVehicleId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<VehicleProfileType>();
  const [compliancePopup, setCompliancePopup] = useState<ComplianceViewModel>(null);
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const history = useHistory();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Asset/Vehicle/${vehicleId}`)
        .then(data => {
          setLoading(false);
          setProfile(data);
        })
        .catch(async e => {
          if (!e?.status)
            await alert('Error: Please see admin');
          else if (e.status !== 404) {
            await alert(e?.detail);
          }
          setLoading(false);
        });
    }
    fetchData();
  }, [alert, vehicleId]);

  useEffect(() => {
    if (props.vehicleId > 0)
      setVehicleId(props.vehicleId);
    else
      setVehicleId(Number(vehicleIdParam));
  }, [vehicleIdParam, props.vehicleId]);

  useEffect(() => {
    if (vehicleId > 0)
      fetchProfile();
  }, [vehicleId, fetchProfile]);

  const saveAsyncProfile = async (value: Partial<VehicleType>): Promise<void> => {
    let errorMsg = '';
    const shouldReload = !!value.HiredDate;
    const newProfile: VehicleType = Object.assign({}, profile.Vehicle, value);
    await fetchApi(`/api/Asset/Vehicle/${vehicleId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          const newProfile: VehicleType = Object.assign({}, profile.Vehicle, value);
          setProfile({ ...profile, Vehicle: { ...newProfile, ModNum: newProfile.ModNum + 1 } });
          if (shouldReload) fetchProfile();
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      await alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const removeCompliance = (compliance: ComplianceViewModel) => {
    return fetchApi(`/api/Asset/RemoveVehicleCompliance/${vehicleId}/${compliance.TypeID}`, {}, 'DELETE')
      .then(async (response: JsonResponse) => {
        if (response.Success) {
          return fetchProfile();
        } else {
          await alert(response.ErrorMessage);
        }
      })
      .catch(async err => {
        await alert(`Error: ${err}`);
      });
  }

  const updateCompliance = (compliance: ComplianceViewModel) => {
    return fetchApi(`/api/Asset/UpdateVehicleCompliance/${vehicleId}`, compliance, 'PUT')
      .then(async (response: JsonResponse) => {
        if (response.Success) {
          return fetchProfile();
        } else {
          await alert(response.ErrorMessage);
        }
      })
      .catch(async err => {
        await alert(`Error: ${err}`);
      });
  }


  const dataView = () => {
    if (loading || !profile) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return (<>
      {props.isDialog !== true && vehicleId > 0 && <VehicleNavBar id={vehicleId} currentTab="Profile" />}
      <Title string={profile.Vehicle.VehicleNumber} />
      <div className="row m-2">
        <div className="col-md-5">
          <div className="form-group row">
            <label htmlFor="VehicleNumber" className="col-sm-2 col-form-label">Vehicle ID</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 30 }}
                data={profile.Vehicle.VehicleNumber}
                save={async (data) => await saveAsyncProfile({ VehicleNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PayrollActive" className="col-sm-2 col-form-label">Payroll Active</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.PayrollActive}
                save={async (data) => await saveAsyncProfile({ PayrollActive: data })}
              />
            </div>
          </div>
          {profile.Vehicle.HideFromDispatch && <div className="form-group row">
            <label htmlFor="HideFromDispatchReason" className="col-sm-2 col-form-label">Reason Hidden</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Vehicle.HideFromDispatchReason}
                save={async (data) => await saveAsyncProfile({ HideFromDispatchReason: data })}
              />
            </div>
          </div>}
          <div className="form-group row">
            <label htmlFor="DockHigh" className="col-sm-2 col-form-label">Dock High</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.DockHigh}
                save={async (data) => await saveAsyncProfile({ DockHigh: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Payload" className="col-sm-2 col-form-label">Payload</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, max: 100000, format: '0' }}
                data={profile.Vehicle.Payload}
                save={async (data) => await saveAsyncProfile({ Payload: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="GrossVehicleWeight" className="col-sm-2 col-form-label">Gross Weight</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, max: 100000, format: '0' }}
                data={profile.Vehicle.GrossVehicleWeight}
                save={async (data) => await saveAsyncProfile({ GrossVehicleWeight: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="UnLadenWeight" className="col-sm-2 col-form-label">UnLaden Weight</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, max: 100000, format: '0' }}
                data={profile.Vehicle.UnLadenWeight}
                save={async (data) => await saveAsyncProfile({ UnLadenWeight: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="BoxDims" className="col-sm-2 col-form-label" title='LxWxH'>Box Dims</label>
            <div className="col-sm-10">
              <EditableField
                data={{ BoxLength: profile.Vehicle.BoxLength, BoxWidth: profile.Vehicle.BoxWidth, BoxHeight: profile.Vehicle.BoxHeight }}
                renderDisplay={(data) => `${data.BoxLength} x ${data.BoxWidth} x ${data.BoxHeight} in.`}
                renderEditable={(data, saving, onChange) => <>
                  <input autoFocus required disabled={saving} className="form-control" placeholder="Length (inches)" type="number" min={0} value={data.BoxLength} onChange={(e) => onChange({ BoxLength: parseInt(e.target.value), BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })} />
                  <input required disabled={saving} className="form-control" placeholder="Width (inches)" type="number" min={0} value={data.BoxWidth} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: parseInt(e.target.value), BoxHeight: data.BoxHeight })} />
                  <input required disabled={saving} className="form-control" placeholder="Height (inches)" type="number" min={0} value={data.BoxHeight} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: parseInt(e.target.value) })} />
                </>}
                save={async (data) => await saveAsyncProfile({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DoorDims" className="col-sm-2 col-form-label" title='WxH'>Door Dims</label>
            <div className="col-sm-10">
              <EditableField
                data={{ DoorW: profile.Vehicle.DoorW, DoorH: profile.Vehicle.DoorH }}
                renderDisplay={(data) => `${data.DoorW} x ${data.DoorH} in.`}
                renderEditable={(data, saving, onChange) => <>
                  <input autoFocus required disabled={saving} className="form-control" placeholder="Width (inches)" type="number" min={0} value={data.DoorW} onChange={(e) => onChange({ DoorW: parseInt(e.target.value), DoorH: data.DoorH })} />
                  <input required disabled={saving} className="form-control" placeholder="Height (inches)" type="number" min={0} value={data.DoorH} onChange={(e) => onChange({ DoorW: data.DoorW, DoorH: parseInt(e.target.value) })} />
                </>}
                save={async (data) => await saveAsyncProfile({ DoorW: data.DoorW, DoorH: data.DoorH })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DoorType" className="col-sm-2 col-form-label">Door Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.DoorType}
                renderDisplay={(data) => DoorTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                  {DoorTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ DoorType: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AirRide" className="col-sm-2 col-form-label">Air Ride</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.AirRide}
                save={async (data) => await saveAsyncProfile({ AirRide: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="TemperatureControl" className="col-sm-2 col-form-label">Temp Control</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.TemperatureControl}
                save={async (data) => await saveAsyncProfile({ TemperatureControl: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LiftGate" className="col-sm-2 col-form-label">Lift Gate</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.LiftGate}
                save={async (data) => await saveAsyncProfile({ LiftGate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LiftGate" className="col-sm-2 col-form-label">Requires Trailer</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={!profile.Vehicle.BoxTruck}
                save={async (data) => await saveAsyncProfile({ BoxTruck: !data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="CanCrossBorder" className="col-sm-2 col-form-label">Can Cross US/CAN Border</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.CanCrossBorder}
                save={async (data) => await saveAsyncProfile({ CanCrossBorder: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Team" className="col-sm-2 col-form-label">Team</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={true}
                dataType="boolean"
                data={profile.Vehicle.Team}
                save={async (data) => await saveAsyncProfile({ Team: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Domicile" className="col-sm-2 col-form-label">Domicile</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.Domicile}
                renderDisplay={(data) => Domiciles.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                  {Domiciles.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ Domicile: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="DispatchBoard" className="col-sm-2 col-form-label">Dispatch Board</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.DispatchBoard}
                renderDisplay={(data) => DispatchBoards.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {DispatchBoards.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ DispatchBoard: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ETrac" className="col-sm-2 col-form-label">E-Trac</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.ETrac}
                save={async (data) => await saveAsyncProfile({ ETrac: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="VehicleTypeID" className="col-sm-2 col-form-label">Vehicle Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.VehicleTypeID}
                renderDisplay={(data) => VehicleTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {VehicleTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ VehicleTypeID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="VehicleClassID" className="col-sm-2 col-form-label">Posting Class</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.VehicleClassID}
                renderDisplay={(data) => PostingClasses.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {PostingClasses.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ VehicleClassID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PayrollClassID" className="col-sm-2 col-form-label">Payroll Class</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.PayrollClassID}
                renderDisplay={(data) => PayrollClasses.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {PayrollClasses.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ PayrollClassID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="RevenueClassID" className="col-sm-2 col-form-label">Revenue Class</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.RevenueClassID}
                renderDisplay={(data) => RevenueClasses.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {RevenueClasses.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ RevenueClassID: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="UseForAutoBidding" className="col-sm-2 col-form-label">Use for Auto Bidding</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.UseForAutoBidding}
                save={async (data) => await saveAsyncProfile({ UseForAutoBidding: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="XPOTruckType" className="col-sm-2 col-form-label">XPO Truck Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.XPOTruckType}
                renderDisplay={(data) => profile.XPOTruckTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {profile.XPOTruckTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ XPOTruckType: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FuelCapacity" className="col-sm-2 col-form-label">Fuel Capacity</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N2' }}
                data={profile.Vehicle.FuelCapacity}
                save={async (data) => await saveAsyncProfile({ FuelCapacity: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="MPG" className="col-sm-2 col-form-label">Hauling Category</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.MPG}
                renderDisplay={(data) => HaulingCategories.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {HaulingCategories.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ MPG: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FuelSource" className="col-sm-2 col-form-label">Fuel Source</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.FuelSource}
                renderDisplay={(data) => FuelSources.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {FuelSources.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ FuelSource: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AutomaticallyPost" className="col-sm-2 col-form-label">Automatically Post</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.AutomaticallyPost}
                save={async (data) => await saveAsyncProfile({ AutomaticallyPost: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PartialSpace" className="col-sm-2 col-form-label">Partial Space</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'N2' }}
                data={profile.Vehicle.PartialSpace}
                renderDisplay={(data) => `${formatNumber(data, "n2")} ft.`}
                save={async (data) => await saveAsyncProfile({ PartialSpace: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ModelMake" className="col-sm-2 col-form-label">Make</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 5 }}
                data={profile.Vehicle.ModelMake}
                save={async (data) => await saveAsyncProfile({ ModelMake: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Model" className="col-sm-2 col-form-label">Model</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 30 }}
                data={profile.Vehicle.Model}
                save={async (data) => await saveAsyncProfile({ Model: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ModelYear" className="col-sm-2 col-form-label">Year</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, max: Moment().year() + 1, format: '0' }}
                data={profile.Vehicle.ModelYear}
                save={async (data) => await saveAsyncProfile({ ModelYear: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Color" className="col-sm-2 col-form-label">Color</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 8 }}
                data={profile.Vehicle.Color}
                save={async (data) => await saveAsyncProfile({ Color: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AxleCount" className="col-sm-2 col-form-label">Axle Count</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, max: 999, format: '0' }}
                data={profile.Vehicle.AxleCount}
                save={async (data) => await saveAsyncProfile({ AxleCount: data })}
              />
            </div>
          </div>

          {/*<div className="form-group row">*/}
          {/*    <label htmlFor="LastMaintenanceDate" className="col-sm-2 col-form-label">Last Maintenance Date</label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*        <EditableField*/}
          {/*            dataType="date"*/}
          {/*            data={Moment.utc(profile.Vehicle.LastMaintenanceDate).tz("America/New_York").toDate()}*/}
          {/*            save={async (data) => await saveAsyncProfile({ LastMaintenanceDate: data })}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<div className="form-group row">*/}
          {/*    <label htmlFor="NextMaintenanceDate" className="col-sm-2 col-form-label">Next Maintenance Date</label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*        <EditableField*/}
          {/*            dataType="date"*/}
          {/*            data={Moment.utc(profile.Vehicle.NextMaintenanceDate).tz("America/New_York").toDate()}*/}
          {/*            save={async (data) => await saveAsyncProfile({ NextMaintenanceDate: data })}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<div className="form-group row">*/}
          {/*    <label htmlFor="LastInspectionDate" className="col-sm-2 col-form-label">Last Inspection Date</label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*        <EditableField*/}
          {/*            dataType="date"*/}
          {/*            data={Moment.utc(profile.Vehicle.LastInspectionDate).tz("America/New_York").toDate()}*/}
          {/*            save={async (data) => await saveAsyncProfile({ LastInspectionDate: data })}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<div className="form-group row">*/}
          {/*    <label htmlFor="NextInspectionDate" className="col-sm-2 col-form-label">Next Inspection Date</label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*        <EditableField*/}
          {/*            dataType="date"*/}
          {/*            data={Moment.utc(profile.Vehicle.NextInspectionDate).tz("America/New_York").toDate()}*/}
          {/*            save={async (data) => await saveAsyncProfile({ NextInspectionDate: data })}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}



          <div className="form-group row">
            <label htmlFor="PurchaseCost" className="col-sm-2 col-form-label">Insurance Value</label>
            <div className="col-sm-10">
              <EditableField
                dataType="number"
                inputProps={{ min: 0, format: 'C2' }}
                data={profile.Vehicle.PurchaseCost}
                save={async (data) => await saveAsyncProfile({ PurchaseCost: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PurchaseDate" className="col-sm-2 col-form-label">Purchase Date</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Vehicle.PurchaseDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ PurchaseDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="OwnedByType" className="col-sm-2 col-form-label">Owned by</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.OwnedByType}
                renderDisplay={(data) => OwnedByTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {OwnedByTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ OwnedByType: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="ReeferModel" className="col-sm-2 col-form-label">Toll Transponder</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 12 }}
                data={profile.Vehicle.ReeferModel}
                save={async (data) => await saveAsyncProfile({ ReeferModel: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="Comment1" className="col-sm-2 col-form-label">Comments</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Vehicle.Comment1}
                save={async (data) => await saveAsyncProfile({ Comment1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Comment2" className="col-sm-2 col-form-label">Pay/Dispatch Note</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Vehicle.Comment2}
                save={async (data) => await saveAsyncProfile({ Comment2: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Comment3" className="col-sm-2 col-form-label">Availability Note</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 60 }}
                data={profile.Vehicle.Comment3}
                save={async (data) => await saveAsyncProfile({ Comment3: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="HiredDate" className="col-sm-2 col-form-label">Hired Date</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="date"
                data={Moment.utc(profile.Vehicle.HiredDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ HiredDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="HiredByUserID" className="col-sm-2 col-form-label">Hired By</label>
            <div className="col-sm-10">
              <EditableIDName
                data={profile.Vehicle.HiredByUserID > 0 ? profile.Users.find(x => x.ID === profile.Vehicle.HiredByUserID) : { ID: 0, Name: '' } as IDName}
                options={profile.Users}
                save={async (data) => await saveAsyncProfile({ HiredByUserID: data.ID })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="FastApproved" className="col-sm-2 col-form-label">Fast Approved</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.FastApproved}
                save={async (data) => await saveAsyncProfile({ FastApproved: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="FastTransponder" className="col-sm-2 col-form-label">Fast Transponder No.</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 25 }}
                data={profile.Vehicle.FastTransponder}
                save={async (data) => await saveAsyncProfile({ FastTransponder: data })}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="IFTAStickerNum" className="col-sm-2 col-form-label">IFTA Sticker No.</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 15 }}
                data={profile.Vehicle.IFTAStickerNum}
                save={async (data) => await saveAsyncProfile({ IFTAStickerNum: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="IFTAStickerExpDate" className="col-sm-2 col-form-label">IFTA StickerExpiry Date</label>
            <div className="col-sm-10">
              <EditableField
                dataType="date"
                data={Moment.utc(profile.Vehicle.IFTAStickerExpDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ IFTAStickerExpDate: data })}
              />
            </div>
          </div>



          <div className="form-group row">
            <label htmlFor="FuelTaxExempt" className="col-sm-2 col-form-label">Fuel Tax Exempt</label>
            <div className="col-sm-10">
              <EditableField
                dataType="boolean"
                data={profile.Vehicle.FuelTaxExempt}
                save={async (data) => await saveAsyncProfile({ FuelTaxExempt: data })}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="form-group row">
            <label htmlFor="AssignedDriver1" className="col-sm-2 col-form-label text-nowrap">Assigned Driver 1</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={profile.Vehicle.TripID > 0}
                data={profile.Vehicle.Driver1Info}
                renderDisplay={(data) => (data != null ? GetDriverAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <DriverAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove driver'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedDriverID={profile.Vehicle.Driver1Info?.DriverID}
                    setFocus={true}
                    onSelectedDriver={(e) => onChange(e)}
                    queryUnseatedDriversOnly={true}
                  />}
                open={profile.Vehicle.Driver1Info ? () => history.push(profile.Vehicle.Driver1Info.Links.find(x => x.Name == "DriverProfile").Link) : null}
                save={async (data) => {
                  if (data == null && await confirm("Remove Driver 1?") == false) {
                    return Promise.resolve();
                  }
                  return await saveAsyncProfile({ Driver1Info: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AssignedDriver2" className="col-sm-2 col-form-label text-nowrap">Assigned Driver 2</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={profile.Vehicle.TripID > 0}
                data={profile.Vehicle.Driver2Info}
                renderDisplay={(data) => (data != null ? GetDriverAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <DriverAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove driver'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedDriverID={profile.Vehicle.Driver2Info?.DriverID}
                    setFocus={true}
                    onSelectedDriver={(e) => onChange(e)}
                    queryUnseatedDriversOnly={true}
                  />}
                open={profile.Vehicle.Driver2Info ? () => history.push(profile.Vehicle.Driver2Info.Links.find(x => x.Name == "DriverProfile").Link) : null}
                save={async (data) => {
                  if (data == null && await confirm("Remove Driver 2?") == false) {
                    return Promise.resolve();
                  }
                  return await saveAsyncProfile({ Driver2Info: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AssignedDriver3" className="col-sm-2 col-form-label text-nowrap">Assigned Driver 3</label>
            <div className="col-sm-10">
              <EditableField
                readOnly={profile.Vehicle.TripID > 0}
                data={profile.Vehicle.Driver3Info}
                renderDisplay={(data) => (data != null ? GetDriverAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <DriverAutoComplete
                    disabled={saving}
                    placeholder='type an id, name or leave blank to remove driver'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedDriverID={profile.Vehicle.Driver3Info?.DriverID}
                    setFocus={true}
                    onSelectedDriver={(e) => onChange(e)}
                    queryUnseatedDriversOnly={true}
                  />}
                open={profile.Vehicle.Driver3Info ? () => history.push(profile.Vehicle.Driver3Info.Links.find(x => x.Name == "DriverProfile").Link) : null}
                save={async (data) => {
                  if (data == null && await confirm("Remove Driver 3?") == false) {
                    return Promise.resolve();
                  }
                  return await saveAsyncProfile({ Driver3Info: data });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="AssignedOwner" className="col-sm-2 col-form-label">Assigned Owner</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.OwnerInfo}
                renderDisplay={(data) => (data != null ? GetOwnerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <OwnerAutoComplete
                    disabled={saving}
                    placeholder='type an owner id, name or leave blank...'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedOwnerID={profile.Vehicle.OwnerInfo?.OwnerID}
                    setFocus={true}
                    onSelectedOwner={(e) => onChange(e)}
                  />}
                open={profile.Vehicle.OwnerInfo ? () => history.push(profile.Vehicle.OwnerInfo.Links.find(x => x.Name == "OwnerProfile").Link) : null}
                save={async (data) => {
                  if (data == null && await confirm("Remove Owner?") == false) {
                    return Promise.resolve();
                  }
                  return await saveAsyncProfile({ OwnerInfo: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ApprovedPassenger" className="col-sm-2 col-form-label">Approved Passenger</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                renderDisplay={(data) => (isNullOrWhiteSpace(data) ? "None" : data)}
                inputProps={{ maxLength: 50 }}
                data={profile.Vehicle.ApprovedPassenger}
                save={async (data) => await saveAsyncProfile({ ApprovedPassenger: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PowerOfAttorney" className="col-sm-2 col-form-label">Power of Attorney</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                renderDisplay={(data) => (isNullOrWhiteSpace(data) ? "None" : data)}
                inputProps={{ maxLength: 255 }}
                data={profile.Vehicle.PowerOfAttorney}
                save={async (data) => await saveAsyncProfile({ PowerOfAttorney: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="Authority" className="col-sm-2 col-form-label">Authority</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                renderDisplay={(data) => (isNullOrWhiteSpace(data) ? "None" : data)}
                inputProps={{ maxLength: 255 }}
                data={profile.Vehicle.Authority}
                save={async (data) => await saveAsyncProfile({ Authority: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AuthorityDate" className="col-sm-2 col-form-label">Load One Authority Start</label>
            <div className="col-sm-10">
              <EditableField
                dataType="dateonly"
                data={profile.Vehicle.AuthorityDate}
                save={async (data) => await saveAsyncProfile({ AuthorityDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AssignedTrailer1" className="col-sm-2 col-form-label text-nowrap">Assigned Trailer 1</label>
            <div className="col-sm-10">
              <EditableField
                //readOnly={profile.Vehicle.TripID > 0}
                data={profile.Vehicle.Trailer1Info}
                renderDisplay={(data) => (data != null ? GetTrailerAutoCompleteDisplayValue(data) : "None")}
                renderEditable={(data, saving, onChange) =>
                  <TrailerAutoComplete
                    disabled={saving}
                    placeholder='type an id, or leave blank to remove trailer'
                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    selectedTrailerID={profile.Vehicle.Trailer1Info?.TrailerID}
                    setFocus={true}
                    queryUnhookedTrailersOnly={true}
                    onSelectedTrailer={(e) => onChange(e)}
                  />}
                open={profile.Vehicle.Trailer1Info ? () => history.push(profile.Vehicle.Trailer1Info.Links.find(x => x.Name == "TrailerProfile").Link) : null}
                save={async (data) => {
                  if (data == null && await confirm("Remove Trailer 1?") == false) {
                    return Promise.resolve();
                  }
                  return await saveAsyncProfile({ Trailer1Info: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="VINNumber" className="col-sm-2 col-form-label">VIN</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Vehicle.VINNumber}
                save={async (data) => await saveAsyncProfile({ VINNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LicensePlate" className="col-sm-2 col-form-label">License Plate</label>
            <div className="col-sm-10">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 8 }}
                data={profile.Vehicle.LicensePlate}
                save={async (data) => await saveAsyncProfile({ LicensePlate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LicenseState" className="col-sm-2 col-form-label">License State</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.LicenseState}
                renderDisplay={(data) => StateCodes.find(x => x.ID == data)?.Name ?? data}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(e.target.value)}>
                  {StateCodes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ LicenseState: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="LicenseExpiryDate" className="col-sm-2 col-form-label">License Plate Expiry</label>
            <div className="col-sm-10">
              <EditableField
                dataType="date"
                data={Moment.utc(profile.Vehicle.LicenseExpiryDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ LicenseExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="InsuranceType" className="col-sm-2 col-form-label">Insurance Type</label>
            <div className="col-sm-10">
              <EditableField
                data={profile.Vehicle.InsuranceType}
                renderDisplay={(data) => InsuranceTypes.find(x => x.ID == data).Name}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  {InsuranceTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
                </select>}
                save={async (data) => await saveAsyncProfile({ InsuranceType: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="InsuranceExpiryDate" className="col-sm-2 col-form-label">Insurance Expiry Date</label>
            <div className="col-sm-10">
              <EditableField
                dataType="date"
                data={Moment.utc(profile.Vehicle.InsuranceExpiryDate).tz("America/New_York").toDate()}
                save={async (data) => await saveAsyncProfile({ InsuranceExpiryDate: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="InsuranceCarrier" className="col-sm-2 col-form-label">Insurance Carrier</label>
            <div className="col-sm-10">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 20 }}
                data={profile.Vehicle.InsuranceCarrier}
                save={async (data) => await saveAsyncProfile({ InsuranceCarrier: data })}
              />
            </div>
          </div>
          {/*<div className="form-group row">*/}
          {/*    <label htmlFor="AssignedTrailer2" className="col-sm-2 col-form-label">Assigned Trailer 2</label>*/}
          {/*    <div className="col-sm-10">*/}
          {/*        <EditableField*/}
          {/*            data={profile.Vehicle.Trailer2Info}*/}
          {/*            renderDisplay={(data) => (data != null ? GetTrailerAutoCompleteDisplayValue(data) : "None")}*/}
          {/*            renderEditable={(data, saving, onChange) =>*/}
          {/*                <TrailerAutoComplete*/}
          {/*                    autoCompleteProps={{ disabled: saving, clearButton: true, placeholder: 'type an id, or leave blank to remove trailer' }}*/}
          {/*                    style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}*/}
          {/*                    selectedTrailerID={profile.Vehicle.Trailer2Info?.TrailerID}*/}
          {/*                    setFocus={true}*/}
          {/*                    queryUnhookedTrailersOnly={true}*/}
          {/*                    onSelectedTrailer={(e) => onChange(e)}*/}
          {/*                />}*/}
          {/*            save={(data) => {*/}
          {/*                if (data == null && window.confirm("Remove Trailer 2?") == false) {*/}
          {/*                    return Promise.resolve();*/}
          {/*                }*/}
          {/*                return saveProfile({ Trailer2Info: data });*/}
          {/*            }}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          <h2>Compliance <span title="Minimum Days Of Compliance Left">({profile.Vehicle.ComplianceDays})</span></h2>
          <Grid
            data={profile.Vehicle.Compliance.filter(x => x.Active)}
            onRowDoubleClick={(row) => {
              setCompliancePopup(row.dataItem)
            }}
          >
            <GridToolbar>
              <DropDownButton
                themeColor="primary" icon="plus" svgIcon={plusIcon} text="Add"
                items={[
                  { TypeID: AssetComplianceTypeID.DOTInspection, Name: 'DOT INSPECTION' },
                  { TypeID: AssetComplianceTypeID.MaintenanceCheck, Name: 'PREVENTIVE MAINTENANCE' },
                  { TypeID: AssetComplianceTypeID.CaliforniaCTC, Name: 'CALIFORNIA CTC' },
                  { TypeID: AssetComplianceTypeID.OregonPermit, Name: 'OREGON PERMIT' },
                  { TypeID: AssetComplianceTypeID.NewYorkPermit, Name: 'NEW YORK PERMIT' },
                  { TypeID: AssetComplianceTypeID.NewMexicoPermit, Name: 'NEW MEXICO PERMIT' },
                  { TypeID: AssetComplianceTypeID.KentuckyPermit, Name: 'KENTUCKY PERMIT' },
                ]}
                textField="Name"
                onItemClick={(e) => {
                  setCompliancePopup(profile.Vehicle.Compliance.find(x => x.TypeID == e.item.TypeID) ?? {
                    ...e.item,
                    Start: "1900-01-01T05:00:00",
                    Expiry: "2999-12-31T00:00:00",
                  });
                }}
              />
            </GridToolbar>
            <Column field="Name" />
            <Column field="Start" title="Start/Last" cell={LocalDateOnlyCell} />
            <Column field="Expiry" title="End/Next" cell={LocalDateOnlyCell} />
          </Grid>
          {compliancePopup && <CompliancePopup
            assetEntityType={AssetEntityType.Vehicle}
            data={compliancePopup}
            close={() => setCompliancePopup(null)}
            remove={removeCompliance}
            save={updateCompliance}
          />}
          <br />
          <AuditInfo audit={profile.Vehicle.AuditInfo} />
        </div>
        <ConfirmationDialog />
      </div>
    </>);
  };

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/Assets/Vehicle/${props.vehicleId}`}>Vehicle Profile {profile?.Vehicle.VehicleNumber ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default VehicleProfile;