import { useEffect, useState } from 'react';
import { formatNumber } from '@progress/kendo-intl';
import { Link } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { QuoteLineItemValue, SmartbidValue, WinPercentageChartValue } from 'views/Quote/Quote';
import { caretAltDownIcon, caretAltUpIcon } from '@progress/kendo-svg-icons';
import { useDebouncedCallback } from 'use-debounce';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartSeries, ChartSeriesItem, ChartTooltip, ChartValueAxis, ChartValueAxisItem  } from '@progress/kendo-react-charts';

type Props = {
  rateComment: string;
  lineItems: QuoteLineItemValue[];

  getSmartbidData: (linehaul: number) => Promise<SmartbidValue | null>;
  getWinPercentageData: (linehaul: number) => Promise<WinPercentageChartValue | null>;
  useSmartbidRate: (rate: number, rateChange: number, currentWin: number, newWin: number) => void;

  quoteId: number;
  createQuote: () => void;
};

export const QuotePanelTable = ({ getSmartbidData, getWinPercentageData, ...props }: Props) => {

    const [selectedTab, setSelectedTab] = useState(0);
    const [smartbidData, setSmartbidData] = useState<SmartbidValue | null>(null);
    const [winPercentageData, setWinPercentageData] = useState<WinPercentageChartValue | null>(null);
    const totLH = props.lineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);

    const getSmartBidDataDebounced = useDebouncedCallback((linehaul: number) => {
      if (linehaul > 0 && selectedTab === 0) {
        getSmartbidData(linehaul)
          .then(data => {
            setSmartbidData(data);
          });
      } else {
        setSmartbidData(null);
      }
    }, 250);

    const getWinPercentageDataDebounced = useDebouncedCallback((linehaul: number) => {
      if (linehaul > 0 && selectedTab === 1) {
        getWinPercentageData(linehaul)
          .then(data => {
            setWinPercentageData(data);
          });
      } else {
        setWinPercentageData(null);
      }
    }, 250);

    useEffect(() => {
      getSmartBidDataDebounced(totLH);
      getWinPercentageDataDebounced(totLH);
    }, [totLH, selectedTab, getSmartBidDataDebounced, getWinPercentageDataDebounced]);

    const total = props.lineItems.reduce((a, b) => +a + +b.Amount, 0);
    const totFuel = props.lineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
    const total67 = total - ((totLH * 0.67) + totFuel);
    const total70 = total - ((totLH * 0.70) + totFuel);

    return <TabStrip
      selected={selectedTab}
      onSelect={(e) => setSelectedTab(e.selected)}
      style={{ overflowY: 'auto', height: '100%' }}
      className='mt-2'
      animation={false}
    >
    <TabStripTab title={props.rateComment}>
      <div className="row">
        <div className="col-lg-8">
          <h5 className="card-title mt-2">
            {formatNumber(total, "c")}
            <span style={{ fontWeight: 'normal', fontSize: '8pt' }}>
              &nbsp; <span style={{ color: 'orange' }}>67%: {formatNumber(total67, "c")} ({formatNumber(total67 / total, "p")})</span>
              &nbsp; <span style={{ color: 'green' }}>70%: {formatNumber(total70, "c")} ({formatNumber(total70 / total, "p")})</span>
            </span>
          </h5>
        </div>
        <div className="col-lg-4">
          {props.quoteId > 0
            ? <span style={{ float: "right", fontSize: "larger" }}>Quote ID:&nbsp;&nbsp;
                  <Link style={{ color: "#007bff", textDecoration: "underline" }} to={`/Quote/Index/${props.quoteId}`}>{props.quoteId}</Link>
            </span>
            : <Button themeColor="primary" style={{ float: "right" }} onClick={props.createQuote}>Reply w/ Rate</Button>}
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Line Item</th>
            <th className="text-muted text-right">Qty</th>
            <th className="text-muted text-right">Rate</th>
            <th className="text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.lineItems.map((lineItem, index) => {
            return <tr key={index}>
              <td>{lineItem.Name}</td>
              <td className="text-muted text-right">{formatNumber(lineItem.Quantity, 'n2')}</td>
              <td className="text-muted text-right">{formatNumber(lineItem.Rate, (lineItem.RateType == 84 || lineItem.RateType == 85) ? 'n5' : lineItem.RateType == 76 ? 'n3' : 'n2')}</td>
              <td className="text-right">{formatNumber(lineItem.Amount, "c")}</td>
            </tr>
          })}
        </tbody>
      </table>
      {smartbidData && <>
        {smartbidData.Current && <Button
          disabled
          size="small"
          className="mr-1"
          style={{ backgroundImage: `linear-gradient(to right, rgb(108, 117, 125) ${smartbidData.Current.Mean * 100}%, transparent ${smartbidData.Current.Mean * 100}%)` }}
        >
          {formatNumber(smartbidData.Current.RPM, 'c2')}/mi (Win {formatNumber(smartbidData.Current.Mean, 'p')})
        </Button>}
        {smartbidData.Next && smartbidData.Next.Increase === false && <Button
          size="small"
          className="mr-1"
          icon="arrow-s"
          svgIcon={caretAltDownIcon}
          style={{ backgroundImage: `linear-gradient(to right, rgba(108, 117, 125, .5) 0 ${smartbidData.Current.Mean * 100}%, rgb(25, 135, 84) ${smartbidData.Current.Mean * 100}% ${smartbidData.Next.Mean * 100}%, transparent ${smartbidData.Next.Mean * 100}%)` }}
          onClick={() => {
            props.useSmartbidRate(smartbidData.Next.LinehaulRPM, smartbidData.Next.LinehaulRPM - smartbidData.Current.LinehaulRPM, smartbidData.Current.Mean, smartbidData.Next.Mean);
          }}
        >
          {formatNumber(smartbidData.Next.RPM, 'c2')}/mi (Win +{formatNumber(smartbidData.Next.Mean - smartbidData.Current.Mean, 'p2')})
        </Button>}
        {smartbidData.Next && smartbidData.Next.Increase && <Button
          size="small"
          className="mr-1"
          icon="arrow-n"
          svgIcon={caretAltUpIcon}
          style={{ backgroundImage: `linear-gradient(to right, rgba(108, 117, 125, .5) 0 ${smartbidData.Next.Mean * 100}%, rgb(220, 53, 69) ${smartbidData.Current.Mean * 100}%, transparent ${smartbidData.Current.Mean * 100}%)` }}
          onClick={() => {
            props.useSmartbidRate(smartbidData.Next.LinehaulRPM, smartbidData.Next.LinehaulRPM - smartbidData.Current.LinehaulRPM, smartbidData.Current.Mean, smartbidData.Next.Mean);
          }}
        >
          {formatNumber(smartbidData.Next.RPM, 'c2')}/mi ({smartbidData.Next.Mean - smartbidData.Current.Mean === 0 ? "Same Win %" : "Win " + formatNumber(smartbidData.Next.Mean - smartbidData.Current.Mean, 'p2')})
        </Button>}
      </>}
    </TabStripTab>
    <TabStripTab title="Win %">
      {winPercentageData && <Chart
        transitions={false}
        style={{ height: 341 }}
        onSeriesClick={(e) => {
          setSelectedTab(0);
          props.useSmartbidRate(Math.round(e.dataItem.LinehaulRPM  * 100) / 100, (Math.round(e.dataItem.LinehaulRPM  * 100) / 100) - winPercentageData.Percentile1[winPercentageData.CurrentBidIndex].LinehaulRPM, winPercentageData.Percentile1[winPercentageData.CurrentBidIndex].Mean, e.stackValue);
        }}
      >
        <ChartSeries>
          <ChartSeriesItem
            color="rgb(25,135,84)"
            type="rangeArea"
            data={winPercentageData.Percentile1}
            fromField="Min"
            toField="Max"
            meanField="Mean"
            categoryField="RPM"
            opacity={.95}
          />
          <ChartSeriesItem
            color="#000000"
            type="line"
            data={winPercentageData.Percentile1}
            field="Mean"
            markers={{ visible: false }}
            opacity={1}
          >
            <ChartTooltip format='{0:p}' />
          </ChartSeriesItem>
        </ChartSeries>
        <ChartValueAxis>
          <ChartValueAxisItem labels={{ format: "{0:p}" }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={{ rotation: "auto", format: "{0:c}", step: 3 }} plotBands={[{ from: winPercentageData.CurrentBidIndex, to: winPercentageData.CurrentBidIndex, color: "#dc3545", label: { rotation: 90, text: "Current Bid", color: 'white', background: '#dc3545', padding: { left: 5, right: 5 } } }]} />
        </ChartCategoryAxis>
        <ChartTooltip format='{0:p} - {1:p}' />
      </Chart>}
    </TabStripTab>
     <TabStripTab title="Win % Statement">
      <p>The win rate % indicated is provided by a data science model which takes the set of attributes on this bid, compares them to historical LoadOne bid information, and determines our probability of winning based upon the per mile line haul fee. The model is being updated nightly based upon the prior 30 days of data.</p>
      <p>Dispatchers should leverage information provided by this model as another tool in their toolbox when determining a bid. The model does not know everything about the real world, so dispatchers have autonomy to trust their gut.</p>
     </TabStripTab>
  </TabStrip>;
}
