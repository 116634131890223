import * as React from 'react';
import Moment from 'moment';
import { formatNumber } from '@progress/kendo-intl';
import { EnterStopViewModel } from '.';

type Props = {
  last: boolean;
  stop: EnterStopViewModel;
  index: number;
  location: TransitLocationValue;
  distance: number;
  tolls: number;
  onChange: (newStop: EnterStopViewModel, changeType: string) => void;
};

export type TransitValue = {
  Distance: number;
  Tolls: number;

  Locations: TransitLocationValue[];
}

export type TransitLocationValue = {
  Distance: number;
  Tolls: number;
  SuggestedDateTime: Date;
  TransitTime: TransitTimeResult;
  SuggestedDateTimeTeam: Date;
  TransitTimeTeam: TransitTimeResult;
}

type TransitTimeResult = {
  DrivingTime: string;
  WaitTime: string;
  SleepTime: string;
  Total: string;
  HtmlFormatted: string;
}

class StopTransitData extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.setSingle = this.setSingle.bind(this);
    this.setTeam = this.setTeam.bind(this);
  }

  render() {
    return (
      <React.Fragment>
      <div className="form-row" style={{ marginTop: '-10px' }}>
        <div className="col-md-4" style={{ padding: '.375rem .75rem', fontWeight: (this.props.last && this.props.index == 1) ? 'bold' : undefined }}>
          {formatNumber(this.props.location.Distance, "n0")} Miles
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-link"
            onClick={this.setSingle}
            onMouseEnter={() => this.setState({ show: true })}
            onMouseLeave={() => this.setState({ show: false })}>
            Single: <span
              style={{ color: Moment.utc(this.props.location.SuggestedDateTime).isAfter(Moment.utc(this.props.stop.DateTime)) ? 'red' : undefined }}
            >
              {Moment.utc(this.props.location.SuggestedDateTime).format("MM/DD/YYYY HH:mm")}
            </span>
          </button>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-link"
            onClick={this.setTeam}
            onMouseEnter={() => this.setState({ showTeam: true })}
            onMouseLeave={() => this.setState({ showTeam: false })}>
            Team: <span
              style={{ color: Moment.utc(this.props.location.SuggestedDateTimeTeam).isAfter(Moment.utc(this.props.stop.DateTime)) ? 'red' : undefined }}
            >
              {Moment.utc(this.props.location.SuggestedDateTimeTeam).format("MM/DD/YYYY HH:mm")}
            </span>
          </button>
        </div>
      </div>
      {this.props.last && this.props.index > 1 && <div className="form-row">
        <div className="col-md-12" style={{ padding: '.375rem .75rem' }}>
        <b>
          Total: {formatNumber(this.props.distance, "n0")} Miles <span title="Total Toll Cost">({formatNumber(this.props.tolls, "c")})</span>
        </b>
        </div>
      </div>}
      </React.Fragment>
    );
  }

  private setSingle() {
    var newDateTime = Moment.utc(this.props.location.SuggestedDateTime).format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      const newStop = Object.assign({}, this.props.stop, { DateTime: newDateTime });
      this.props.onChange(newStop, "STOP_DATETIME");
    }
  }

  private setTeam() {
    var newDateTime = Moment.utc(this.props.location.SuggestedDateTimeTeam).format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      const newStop = Object.assign({}, this.props.stop, { DateTime: newDateTime });
      this.props.onChange(newStop, "STOP_DATETIME");
    }
  }
}

export default StopTransitData;
