import { Button } from '@progress/kendo-react-buttons';
import { Window, WindowActionsBar, Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, MaskedTextBox, RadioGroup } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useRef, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import { EditableAddress } from '../../components/EditableAddress';
import { fetchApi } from '../../services/api';
import { AddressViewModel } from '../../TypeGen/Driver/Profile/address-view-model';
import { isNullOrWhiteSpace, isNullOrWhiteSpaceValidator } from '../../utils/utils';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

type Props = {
    CloseDialog: (newCustomerId?: number) => void;

    // For EDI stop notes
    StopNote?: string;
    City?: string;
    State?: string;
    ZipCode?: string;
}

type DuplicateCustomer = {
    CustomerID: number;
    CustomerNumber: string;
    CustomerName: string;
    AddressLine1: string;
    CityStateZip: string;
    PhoneNumber: string;
}

const CopyCustomerDialog = (props: Props) => {
    const customerNameRef = useRef<Input>(null);
    const [createType, setCreateType] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [address, setAddress] = useState<AddressViewModel>({ AddressLine1: '', AddressLine2: '', City: props.City || '', State: props.State || '', ZipCode: props.ZipCode || '', Coordinates: { Latitude: 0, Longitude: 0 } });
    const [duplicateCustomers, setDuplicateCustomers] = useState<DuplicateCustomer[]>([]);

    // Copy
    const [copyCustomerNumber, setCopyCustomerNumber] = useState({ value: '', error: '' });

    // Create
    const [customerNumber, setCustomerNumber] = useState({ value: '', error: '' });
    const [customerName, setCustomerName] = useState({ value: '', error: '' });
    const [customerPhone, setCustomerPhone] = useState({ value: '', error: '' });

    const hasAddress = address.AddressLine1.length > 0 && ((address.City.length > 0 && address.State.length > 0) || address.ZipCode.length > 0);
    const copyCustomerNumberError = createType === 0 ? isNullOrWhiteSpaceValidator(copyCustomerNumber.value, 'Enter a Customer ID to copy.') : '';
    const customerNumberError = isNullOrWhiteSpaceValidator(customerNumber.value, 'Please enter a new Customer ID.');

    const canSave = (createType === 0 && isNullOrWhiteSpace(copyCustomerNumber.value) == false && isNullOrWhiteSpace(customerNumber.value) == false) ||
        (createType === 1 && hasAddress && isNullOrWhiteSpace(customerName.value) === false);

    useEffect(() => {
        if (props.StopNote) {
            const customerNameIndex = props.StopNote.indexOf('CUST:');
            const addressIndex = props.StopNote.indexOf('ADDR:');
            const phoneNumberIndex = props.StopNote.indexOf('PH:');
            const customerNumberIndex = props.StopNote.indexOf('CUST. CODE:');
            if (customerNameIndex > -1) setCustomerName({ value: props.StopNote.substring(customerNameIndex + 5, addressIndex > -1 ? addressIndex : props.StopNote.length).trim(), error: '' });
            if (addressIndex > -1) setAddress(x => ({ ...x, AddressLine1: props.StopNote.substring(addressIndex + 5, phoneNumberIndex > -1 ? phoneNumberIndex : props.StopNote.length).trim() }));
            if (phoneNumberIndex > -1) {
                let phone = props.StopNote.substring(phoneNumberIndex + 3, customerNumberIndex > -1 ? customerNumberIndex : props.StopNote.length).trim();
                // Remove any non-numeric characters
                phone = phone.replace(/[^0-9]/g, '');
                // if phone number is 11 digits and starts with 1, remove the 1
                if (phone.length === 11 && phone[0] === '1') phone = phone.substring(1, 11);
                // If phone number is 10 digits, format it
                if (phone.length === 10) {
                    setCustomerPhone({ value: `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`, error: '' });
                }
            }
        }
    }, [props.StopNote, setAddress, setCustomerName]);

      useEffect(() => {
        customerNameRef?.current.focus();
      }, [customerNameRef]);

    const _save = () => {
        if (canSave === false) {
            setCopyCustomerNumber({ ...copyCustomerNumber, error: copyCustomerNumberError });
            setCustomerNumber({ ...customerNumber, error: customerNumberError });
            setCustomerName({ ...customerName, error: '' });
            return;
        }

        setLoading(true);

        const data = {
            CopyCustomerNumber: createType === 0 ? copyCustomerNumber.value : null,
            CustomerNumber: customerNumber.value,
            CustomerName: customerName.value,
            Address: address,
            PhoneNumber: customerPhone.value,
            Force: duplicateCustomers.length > 0
        }

        fetchApi('/api/Customer/CopyCustomer', data, 'POST')
            .then((data: { CustomerID: number, DuplicateCustomers: DuplicateCustomer[] }) => {
                setLoading(false);
                setDuplicateCustomers(data.DuplicateCustomers);
                if (data.CustomerID > 0) props.CloseDialog(data.CustomerID);
            }
            ).catch((e) => {
                setLoading(false);
                // If not problem details
                if (!e?.status) alert('Unable to create customer');
            });
    }

    const options = [
        { label: "Copy Customer", value: 0 },
        { label: "Create Customer", value: 1 },
    ];

    return <Dialog title={`${createType === 0 ? 'Copy' : 'Create'} Customer Profile`} onClose={() => props.CloseDialog()} width={400} height={675}>
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        {duplicateCustomers.length > 0 && <Window title="Possible Duplicates" onClose={() => setDuplicateCustomers([])} width={800} height={600}>
            <Grid
                data={duplicateCustomers}
                scrollable='none'
                onRowClick={(e) => props.CloseDialog(e.dataItem.CustomerID)}
            >
                <Column field="CustomerNumber" title="My Code" cell={(props) => <td style={{ color: '#007bff' }} role="button">{props.dataItem.CustomerNumber}</td>} />
                <Column field="CustomerName" title="Name" />
                <Column field="AddressLine1" title="Address" />
                <Column field="CityStateZip" title="City, State Zip" />
                <Column field="PhoneNumber" title="Phone Number" />
            </Grid>
            <WindowActionsBar>
                <Button onClick={() => setDuplicateCustomers([])}>Close</Button>
                <Button themeColor='warning' disabled={canSave === false || loading} onClick={_save}>
                    {createType === 0 ? 'Copy' : 'Create'} Customer Anyway
                </Button>
            </WindowActionsBar>
        </Window>}
        <div className="k-form k-form-md p-0">
            <RadioGroup
                value={createType}
                onChange={(e) => setCreateType(e.value)}
                layout="horizontal"
                data={options}
            />
            {createType === 0 && <FieldWrapper>
                <Label>Copy Customer Number</Label>
                <Input
                    autoFocus
                    maxLength={15}
                    disabled={loading}
                    value={copyCustomerNumber.value}
                    onChange={(e) => setCopyCustomerNumber({ value: e.value || '', error: '' })}
                />
                <Error>{copyCustomerNumber.error}</Error>
            </FieldWrapper>}
            {/*<FieldWrapper>
                <Label>{createType === 0 ? 'New' : ''} Customer Number</Label>
                <Input
                    maxLength={15}
                    disabled={loading}
                    value={customerNumber.value}
                    onChange={(e) => setCustomerNumber({ value: e.value || '', error: '' })}
                />
                <Error>{customerNumber.error}</Error>
            </FieldWrapper>*/}

            {createType === 1 && <div>
                <FieldWrapper>
                    <Label>Customer Name</Label>
                    <Input
                        ref={customerNameRef}
                        required
                        maxLength={60}
                        autoFocus
                        disabled={loading}
                        value={customerName.value}
                        onChange={(e) => setCustomerName({ value: e.value || '', error: '' })}
                    />
                    <Error>{customerName.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Customer Address</Label>
                    <EditableAddress
                        title="Customer Address"
                        addressLineLength={50}
                        data={address}
                        save={async (e) => setAddress(e)}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <MaskedTextBox
                        mask="(000) 000-0000"
                        value={customerPhone.value}
                        disabled={loading}
                        label="Main Phone Number"
                        onChange={(e) => e.value === '(___) ___-____' ? setCustomerPhone({ value: '', error: '' }) : setCustomerPhone({ value: e.value || '', error: '' })}
                    />
                </FieldWrapper>
            </div>}
        </div>

        <DialogActionsBar>
            <Button onClick={() => props.CloseDialog()}>Cancel</Button>
            <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
                {createType === 0 ? 'Copy' : 'Create'} Customer
            </Button>
        </DialogActionsBar>
    </Dialog>
}

export default CopyCustomerDialog;

