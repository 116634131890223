import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { EnterRoutingRateViewModel, } from '.';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';

type State = {
    isLoading: boolean;
}

type Props = {
    data: EnterRoutingRateViewModel;
    onChange: (data: EnterRoutingRateViewModel) => void;
    disabledInputs: boolean;
};

class EnterRoutingRate extends React.Component<Props, State> {
    private vehicleTypes = [
        { value: 1, text: 'CARGO VAN (L96xW48xH48) 2000 lbs. 50 mph' },
        { value: 2, text: 'SMALL STRAIGHT (L216xW84xH84) 10000 lbs. 45 mph' },
        { value: 3, text: 'LARGE STRAIGHT (L288xW96xH96) 14000 lbs. 45 mph' },
        { value: 4, text: 'TRACTOR (L636xW102xH110) 44000 lbs. 45 mph' },
        { value: 5, text: 'FLAT BED (L636xW102xH102) 47000 lbs. 45 mph' },
        { value: 15, text: 'SPRINTER (L96xW48xH70) 3000 lbs. 50 mph' },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
        }

        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleFlatRateChange = this.handleFlatRateChange.bind(this);
        this.handlePerMileRateChange = this.handlePerMileRateChange.bind(this);
    }

    render() {
        return (
            <div className="form-row mt-2">
                <div className="col-auto">
                    <DropDownList
                        style={{ width: '400px' }}
                        defaultValue={0}
                        value={this.vehicleTypes.find(x => x.value == this.props.data.VehicleTypeID)}
                        popupSettings={{ height: 700, width: 500, animate: false }}
                        data={this.vehicleTypes}
                        textField="text"
                        onChange={this.handleVehicleTypeChange}
                    />
                </div>
                <div className="col-auto">
                    <div className="form-row align-items-center">
                        <div className="col-auto">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <NumericTextBox
                                    max={10000}
                                    min={0}
                                    className="form-control"
                                    value={this.props.data.PerMile}
                                    step={0.01}
                                    onChange={this.handlePerMileRateChange}
                                    disabled={this.props.disabledInputs}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">Per Mile</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            OR
                        </div>
                        <div className="col-auto">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <NumericTextBox
                                    max={1000000}
                                    min={0}
                                    className="form-control"
                                    value={this.props.data.Flat}
                                    step={25}
                                    onChange={this.handleFlatRateChange}
                                    disabled={this.props.disabledInputs}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">Flat</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleVehicleTypeChange(e: DropDownListChangeEvent) {
        const value = e.target.value.value;
        this.updateRateLineItem({ VehicleTypeID: value });
    }

    private handleFlatRateChange(e: NumericTextBoxChangeEvent) {
        const value = e.target.value;
        this.updateRateLineItem({ Flat: value, PerMile: 0 });
    }

    private handlePerMileRateChange(e: NumericTextBoxChangeEvent) {
        const value = e.target.value;
        this.updateRateLineItem({ PerMile: value, Flat: 0 })
    }

    private updateRateLineItem(value: Partial<EnterRoutingRateViewModel>) {
        const newRateLineItem = Object.assign({}, this.props.data, value);
        this.props.onChange(newRateLineItem);
    }
}

export default EnterRoutingRate;
