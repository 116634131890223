import { StopVerb } from "TypeGen/stop-verb";
import { QuoteStopVerb } from "TypeGen/Quote/quote-stop-verb";

export const getStopVerb = (stopVerb: StopVerb | QuoteStopVerb) => {
  switch(stopVerb)
  {
    case StopVerb.ASAP:
    case QuoteStopVerb.ASAP:
      return "ASAP";
    case StopVerb.DELIVER_DIRECT:
    case QuoteStopVerb.DeliverDirect:
      return "Deliver Direct";
    case StopVerb.PROTECT:
    case QuoteStopVerb.Protect:
      return "Protect";
    case StopVerb.OPEN:
    case QuoteStopVerb.Open:
      return "Open";
    case StopVerb.CLOSE:
    case QuoteStopVerb.Close:
      return "Close";
    case StopVerb.READY_NOW:
    case QuoteStopVerb.ReadyNow:
      return "Ready Now";
    case StopVerb.HOT:
    case QuoteStopVerb.Hot:
      return "Hot";
    case StopVerb.WINDOW:
    case QuoteStopVerb.Window:
      return "Window";
    default:
      const exhaustiveCheck: never = stopVerb;
  }
}
