import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import Moment from 'moment-timezone';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';

export type TrailerValue = {
    TrailerID: number;
    TrailerNumber: string;
    TrailerType: number;
    TrailerTypeName: string;
    TrailerStatusValue: string;
    LastLocationCityState: string;
    Active: boolean;
    Links: ILink[];
}

export type TrailerAutoCompleteViewModel = TrailerValue & { DisplayValue: string; };

type Props = {
    onSelectedTrailer: (trailer: TrailerValue) => void;
    selectedTrailerID?: number;
    setFocus?: boolean;
    queryUnhookedTrailersOnly?: boolean;
    includeRecentlyRetiredTrailers?: boolean;
} & AutoCompleteProps;

export const GetTrailerAutoCompleteDisplayValue = (trailer: TrailerValue): string => {
    if (trailer.TrailerTypeName && trailer.TrailerStatusValue)
        return `${trailer.TrailerNumber} - ${trailer.TrailerTypeName} - ${trailer.TrailerStatusValue} (${trailer.LastLocationCityState})`;
    return `${trailer.TrailerNumber}`;
}

const TrailerAutoComplete: React.FC<Props> = ({
    onSelectedTrailer,
    selectedTrailerID,
    queryUnhookedTrailersOnly,
    includeRecentlyRetiredTrailers,
    setFocus,
    ...autoCompleteProps
}) => {
    const inputAC = useRef<AutoComplete>(null);
    const [loading, setLoading] = useState(false);
    const [trailerDropDownListing, setTrailerDropDownListing] = useState<TrailerAutoCompleteViewModel[]>([]);
    const [trailerAutoCompleteValue, setTrailerAutoCompleteValue] = useState('');
    const _selectedTrailer = useRef(0);
    //const [preSelectedCustomerInitialized, setPreSelectedCustomerInitialized] = useState(false);
    const _requestHash = useRef('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: string, requestHash: string) => {
            if (val.length > 2 && _selectedTrailer.current === 0) {
                getTrailerListing(generateDataStateByName(val), requestHash);
            }
        }, 750), []);

    const generateDataStateByName = (val: string): GridState => {

        let gs = {
            skip: 0,
            take: 12,
            sort: [{
                field: "TrailerNumber",
                dir: "asc"
            }],
            filter: {
                logic: 'and', filters: [
                    {
                        logic: 'or', filters: [
                            { field: 'TrailerNumber', operator: 'contains', value: val }
                        ]
                    }
                ]
            }
        } as GridState;

        if (queryUnhookedTrailersOnly) {
            gs.filter.filters.unshift({
                logic: 'and', filters: [
                    { field: 'VehicleID', operator: 'isnull' },
                    {
                        logic: 'or', filters: [
                            { field: 'TrailerStatus', operator: 'eq', value: 0 },
                            { field: 'TrailerStatus', operator: 'eq', value: 3 }
                        ]
                    }
                ]
            });
        }

        if (includeRecentlyRetiredTrailers) {
            gs.filter.filters.unshift({
                logic: 'or', filters: [
                    { field: 'Active', operator: 'eq', value: true },
                    {
                        logic: 'and', filters: [
                            { field: 'Active', operator: 'eq', value: false },
                            { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(6, "months").toDate() }
                        ]
                    }
                ]
            });
        } else {
            gs.filter.filters.unshift({ field: 'Active', operator: 'eq', value: true });
        }

        return gs;
    }

    const generateDataStateByID = (val: number): GridState => {
        return {
            skip: 0,
            take: 1,
            sort: null,
            filter: {
                logic: 'and', filters: [
                    { field: 'TrailerID', operator: 'eq', value: val }
                ] as any
            }
        } as GridState;
    }

    const getTrailerListing = (dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
        setLoading(true);

        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/GetAssetTrailersDataState?${queryStr}`, {}, 'POST')
            .then(({ Data }) => {
                let trailerData: TrailerAutoCompleteViewModel[] = Data;
                setLoading(false);
                if (_requestHash.current === requestHash) {
                    trailerData.forEach(x => {
                        x.DisplayValue = GetTrailerAutoCompleteDisplayValue(x);
                    });
                    setTrailerDropDownListing(trailerData);
                    if (populateLabel) {
                        setTrailerAutoCompleteValue(GetTrailerAutoCompleteDisplayValue(trailerData[0]));
                        //setPreSelectedCustomerInitialized(true);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            })
    };

    const onTrailerListingClose = (event: AutoCompleteCloseEvent) => {
        const value = event.target.value;
        const selectedTrailer = trailerDropDownListing.find(x => x.DisplayValue === value);
        setTrailerAutoCompleteValue(selectedTrailer != null ? value : '');
        _selectedTrailer.current = selectedTrailer?.TrailerID;
        onSelectedTrailer(selectedTrailer ?? null);
    };

    useEffect(() => {
        if (selectedTrailerID !== _selectedTrailer.current) {
            if (selectedTrailerID > 0) {
                _selectedTrailer.current = selectedTrailerID;
                _requestHash.current = Math.random().toString(36).substring(7);
                getTrailerListing(generateDataStateByID(selectedTrailerID), _requestHash.current, true);
            } else {
                _selectedTrailer.current = 0;
                setTrailerAutoCompleteValue('');
            }
            if (setFocus) {
                inputAC.current.focus();
            }
        }
    }, [selectedTrailerID, setFocus]);

    //useEffect(() => {
    //    if (preSelectedCustomerInitialized) {
    //        inputAC.current.focus();
    //        (inputAC.current.element.querySelector('input') as HTMLInputElement).select();
    //    }
    //}, [preSelectedCustomerInitialized])

    return <AutoComplete
        ref={inputAC}
        {...autoCompleteProps}
        placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Trailer')}
        loading={loading}
        data={trailerDropDownListing}
        value={trailerAutoCompleteValue}
        textField="DisplayValue"
        onChange={(e) => {
            _selectedTrailer.current = 0;
            if (!e.value) onSelectedTrailer(null);
            setTrailerDropDownListing([]);
            setTrailerAutoCompleteValue(e.value);
            _requestHash.current = Math.random().toString(36).substring(7);
            debounceSearchInput(e.value, _requestHash.current);
        }}
        onClose={(e) => onTrailerListingClose(e)}
    />
}

export default TrailerAutoComplete;
