import * as React from 'react';
import { GridDetailRow, GridDetailRowProps } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import LoadBoardBid from './Bid';
import LoadBoardVehicles from './Vehicles';
import LoadBoardAllianceVehicles from './AllianceVehicles';
import LoadBoardNetworkVehicles from './NetworkVehicles';
import LoadBoardDecline from './Decline';
import LoadBoardMap from './Map';
import LoadBoardCombinedRateAnalysis from './CombinedRateAnalysis';
import LoadBoardPayRateAnalysis from './PayRateAnalysis';
import LoadBoardCustomerRateAnalysis from './CustomerRateAnalysis';

export default class LoadBoardDetailRow extends GridDetailRow {
  private selectedIndex: number;

  constructor(props: GridDetailRowProps) {
    super(props);

    this.selectedIndex = 0;

    this.handleSelect = this.handleSelect.bind(this);
  }

  render() {
      const dataItem = this.props.dataItem;
      const props = { shipmentId: dataItem.ShipmentID };
      return (
        <TabStrip selected={this.selectedIndex} onSelect={this.handleSelect} animation={false}>
          <TabStripTab title="Bid">
            <LoadBoardBid {...props} />
          </TabStripTab>
          <TabStripTab title="Vehicles">
            <LoadBoardVehicles shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Sylectus">
            <LoadBoardAllianceVehicles shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Full Circle">
            <LoadBoardNetworkVehicles shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Decline">
            <LoadBoardDecline shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Map">
            <LoadBoardMap shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Revenue and Pay">
            <LoadBoardCombinedRateAnalysis shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Pay Rates">
            <LoadBoardPayRateAnalysis shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
          <TabStripTab title="Customer Rates">
            <LoadBoardCustomerRateAnalysis shipmentId={dataItem.ShipmentID} />
          </TabStripTab>
        </TabStrip>
      );
  }

  private handleSelect(e: TabStripSelectEventArguments) {
    this.selectedIndex = e.selected;
    this.forceUpdate();
  }
}
