import AssetPanel from '../Assets/AssetPanel';

const AssetOwners = () => {
  return <AssetPanel
    searchTerm=""
    tabIndex={4}
  />
}

export default AssetOwners;
