import { useCallback, useContext, useEffect, useState } from "react";
import Moment from 'moment';
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Order/TransactionLogs/view-model";
import JsonPretty from "components/JsonPretty";
import { OrderContext } from "./Details";

type RouteComponentParams = {
  orderId: string;
}

const TransactionLogs = () => {
    const { orderId: orderIdParam } = useParams<RouteComponentParams>();
    const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState<ViewModel>();

    const refreshLogs = useCallback(() => {
      setLoading(true);
      fetchApi(`/api/Order/TransactionLogs/${orderIdParam}`)
        .then((data: ViewModel) => {
          setLoading(false);
          setLogs(data);
        })
        .catch(e => {
          setLoading(false);
          // If not problem details
          if (!e?.status) alert(e);
        });
      }, [orderIdParam]);

    useEffect(() => {
      setActiveStopIds([]);
      setDeleteStopIds([]);
      refreshLogs();
    }, [refreshLogs, setActiveStopIds, setDeleteStopIds]);

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
      <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Date Time</th>
          <th>User</th>
          <th>Order Stop</th>
          <th>Trip</th>
          <th>Trip Stop</th>
          <th>Action</th>
          <th>Old</th>
          <th>New</th>
        </tr>
      </thead>
      <tbody>
        {!logs.Logs.length && <tr>
          <td colSpan={8} className="text-center">No Logs.</td>
        </tr>}
        {logs.Logs.map(log => <tr key={log.TransactionLogID}>
          <td>{Moment.utc(log.ModifiedDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
          <td>{log.ModifiedByFullName}</td>
          <td>{log.OrderStopSequence}</td>
          <td>{log.TripNumber}</td>
          <td>{log.TripStopSequence}</td>
          <td>{log.TypeName}</td>
          <td><JsonPretty json={log.OldData} /></td>
          <td><JsonPretty json={log.NewData} /></td>
        </tr>)}
      </tbody>
    </table>

    return (<>
      <Title string="Logs" />
      <br />
      {loading || !logs ? loadingView : dataView()}
    </>);
}

export default TransactionLogs;
