import { Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import Moment from 'moment-timezone';
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from 'react-router-dom';
import { fetchApi } from "services/api";
import { JsonResponse } from "TypeGen/json-response";
import CarrierAutoComplete from "views/AssetCarriers/CarrierAutoComplete";
import DriverAutoComplete from "views/AssetDrivers/DriverAutoComplete";
import VehicleAutoComplete from "views/AssetVehicles/VehicleAutoComplete";
import KendoWindow from "../../../components/KendoWindow";
import { Title } from "../../../utils/title";

type CreatePayTripProps = {
  tripId?: number | null;
  closeDialog?: () => void;
};

const CreatePayTrip = (props: CreatePayTripProps) => {

  const history = useHistory();
  const [saving, setSaving] = useState(false);

  // Options
  const [vehicleId, setVehicleId] = useState<number>(null);
  const [driverId, setDriverId] = useState<number>(null);
  const [carrierId, setCarrierId] = useState<number>(null);
  const [dateTime, setDateTime] = useState<Date>(Moment().toDate());
  const [orderNumber, setOrderNumber] = useState<number>(null);

  const save = () => {
    setSaving(true);
    const data = {
      VehicleID: vehicleId,
      DriverID: driverId,
      CarrierID: carrierId,
      DateTime: dateTime,
      TripID: props.tripId,
      OrderNumber: orderNumber
    }
    fetchApi("/api/Trip/CreatePayTrip", data, "POST")
      .then((e: JsonResponse) => {
        if (e.Success) {
          var link = e.Links.find(l => l.Name === 'Trip');
          if (link) history.push(link.Link);
          if (props.closeDialog)
            props.closeDialog();
        } else alert(e.ErrorMessage);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error saving Pay Trip');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  useHotkeys('ctrl+s', (e, handler) => {
    switch (handler.keys.join('')) {
      case 's':
        save();
        e.preventDefault();
        break;
    }
  }, [save]);

  const dataView = () => {
    return (
      <div className="container">
        <Title string={props.closeDialog ? 'Pay Another Asset' : 'Create Additional Pay Trip'} />
        <h2 className="text-center">{props.closeDialog ? 'Pay Another Asset' : 'Create Additional Pay Trip'}</h2>
        <div className="card my-2">
          <div className="card-body">
            <div className="form-group row">
              <label className="col-form-label form-check-label col-md-3">Vehicle</label>
              <div className="col-md-9">
                <VehicleAutoComplete
                  selectedVehicleID={vehicleId}
                  style={{ width: '350px' }}
                  onSelectedVehicle={(e) => {
                    setVehicleId(e?.VehicleID ?? null);
                    setCarrierId(null);
                  }}
                  formatDisplay={(e) => `${e.VehicleNumber} - ${e.Driver1Number}`}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label form-check-label col-md-3">Driver</label>
              <div className="col-md-9">
                <DriverAutoComplete
                  selectedDriverID={driverId}
                  style={{ width: '350px' }}
                  onSelectedDriver={(e) => {
                    setDriverId(e?.DriverID ?? null);
                    setCarrierId(null);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label form-check-label col-md-3">Carrier</label>
              <div className="col-md-9">
                <CarrierAutoComplete
                  selectedCarrierID={carrierId}
                  style={{ width: '350px' }}
                  onSelectedCarrier={(e) => {
                    setCarrierId(e?.CarrierID ?? null);
                    setVehicleId(null);
                    setDriverId(null);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="startTime" className="col-form-label col-md-3">Date/Time</label>
              <div className="col-md-9">
                <DateTimePicker
                  required
                  width={210}
                  format="MM/dd/yyyy HH:mm"
                  id="startTime"
                  value={dateTime}
                  onChange={(e) => setDateTime(e.value)}
                />
              </div>
            </div>
            {props.tripId == null &&
              <div className="form-group row">
                <label htmlFor="startTime" className="col-form-label col-md-3">Link to (optional) Order Number:</label>
                <div className="col-md-9">
                  <Input
                    style={{ width: '210px' }}
                    type="number"
                    placeholder="Optional Order Number"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.value ? Number(e.value) : null)}
                  />
                </div>
              </div>}
          </div>
        </div>
        <div className="text-center">
          <Button
            themeColor="primary"
            className="ml-2"
            disabled={saving}
            onClick={() => save()}
          >
            {props.closeDialog ? 'Pay Another Asset' : 'Create Additional Pay Trip'}
          </Button>
        </div>
      </div>
    );
  };

  //title={'Pay Another Asset'} 
  if (props.closeDialog) {
    return <KendoWindow onClose={props.closeDialog} autoSizePercentage={50}>
      {dataView()}
    </KendoWindow>
  }

  return dataView();
}

export default CreatePayTrip;