import { UploadOnStatusChangeEvent } from "@progress/kendo-react-upload";
import { dispatchProblemDetails } from "../services/api";

export function drawRefreshButtonOnGridFooter(onclick: any): void {
  const div = document.querySelector(`span.k-pager-info`) as HTMLDivElement;
  div.classList.remove("k-pager-info");
  div.style.marginLeft = "auto";
  div.style.display = "flex";

  const btn = document.createElement('button');
  btn.title = "Refresh";
  btn.className = "k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md k-icon-button";
  btn.style.marginLeft = "1%";
  btn.style.display = "flex";
  btn.onclick = onclick;

  const span = document.createElement('span');
  span.className = "k-button-icon k-icon k-i-refresh";

  btn.appendChild(span);
  div.parentNode.insertBefore(btn, div.nextSibling);
}

export const kendoUploadOnStatusChangeEventHandler = (e: UploadOnStatusChangeEvent, onSuccess?: () => void) => {
  if (e.response && e.response.request !== null) {
    if (e.response.request.status === 200 && e.response.request.status < 300) {
      onSuccess && onSuccess();
    } else if (e.response.request.status !== 500 && e.response.response.headers['content-type'].indexOf("application/problem+json") > -1) {
        dispatchProblemDetails(e.response.response.data);
    } else {
      alert("An unexpected error has occurred. Do not try to reupload the file. See support.");
    }
  }
}