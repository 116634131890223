import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { DriverValue, SelectedRowValues, MenuOption } from './AssetPanel';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';

export default function driverCardNumberCell(
    LoadDialogScreen: (SelectedRowValues: SelectedRowValues, SelectedDrivers: DriverValue[], SelectedVehicles: AssetVehicleViewModel[], SelectedMenuOption: MenuOption) => void
) {
    return class DriverCardNumber extends React.Component<GridCellProps> {
        render() {
            return (
                <td>
                    <a
                        href="#"
                        style={{ color: '#007bff' }}
                        title={this.props.dataItem.CardNumber}
                        onClick={(e) => {
                            LoadDialogScreen({
                                DriverID: this.props.dataItem.DriverID,
                                DriverNumber: this.props.dataItem.DriverNumber,
                                DriverName: `${this.props.dataItem.FirstName} ${this.props.dataItem.LastName}`,
                                VehicleID: this.props.dataItem.VehicleID,
                                VehicleNumber: this.props.dataItem.VehicleNumber,
                                OwnerID: this.props.dataItem.OwnerID,
                                OwnerName: this.props.dataItem.OwnerName,
                                IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                                IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                            } as SelectedRowValues, [], [], MenuOption.CardMaintenance);
                            e.preventDefault();
                            this.forceUpdate();
                        }}
                    >{this.props.dataItem.CardNumber}
                    </a>
                </td>
            )
        }

    }
}
