import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent, ColorPicker, ColorPickerChangeEvent, InputPrefix, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';
import { CustomerNavBar } from './NavBar';

type RouteComponentParams = {
    customerId: string;
};

type CustomerSpecialInstructionViewModel = {
    CustomerSpecialInstructionID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;
    InstructionType: number;
    Display: number;
    CopyToTrip: boolean;
    Instruction: string;
    inEdit?: boolean | string;
    isNew?: boolean;
};

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: CustomerSpecialInstructionViewModel = props.dataItem;
    return (
        <td colSpan={props.colSpan} style={props.style}>
            <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
        </td>
    );
};

const InstructionTypeCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: CustomerSpecialInstructionViewModel = props.dataItem;

    const InstructionTypes = [{ ID: 83, Name: 'Shipper' }, { ID: 67, Name: 'Consignee' }, { ID: 66, Name: 'Bill To Party' }, { ID: 76, Name: 'Logistics Provider' }, { ID: 65, Name: 'Authorized Co.' }] as IDName[];

    const handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.ID,
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {dataItem.inEdit ? <DropDownList
                data={InstructionTypes}
                textField="Name"
                dataItemKey="ID"
                value={InstructionTypes.find(x => x.ID == dataItem.InstructionType)}
                onChange={handleChange}
            /> :
                <span>{InstructionTypes.find(x => x.ID == dataItem.InstructionType).Name}</span>
            }
        </td>
    );
};

const CopyToTripCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: CustomerSpecialInstructionViewModel = props.dataItem;

    const handleChange = (e: CheckboxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {dataItem.inEdit ? <Checkbox size={'medium'} rounded={'medium'} value={dataItem.CopyToTrip} onChange={handleChange} /> :
                <span>{dataItem.CopyToTrip ? 'Yes' : 'No'}</span>
            }
        </td>
    );
};

const InstructionCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    //78 = N for Note as per old TMS
    const palettes = [
        localStorage["darkmode"] !== 'false' ?
            { ColorASCII: 78, rgba: 'rgba(255, 255, 255, 1)', ColorDescription: 'white' } :
            { ColorASCII: 78, rgba: 'rgba(0, 0, 0, 1)', ColorDescription: 'black' },
        { ColorASCII: 66, rgba: 'rgba(0, 123, 255, 1)', ColorDescription: 'blue' },
        { ColorASCII: 71, rgba: 'rgba(40, 167, 69, 1)', ColorDescription: 'green' },
        { ColorASCII: 82, rgba: 'rgba(220, 53, 69, 1)', ColorDescription: 'red' },
        { ColorASCII: 89, rgba: 'rgba(255, 193, 7, 1)', ColorDescription: 'yellow' }
    ]

    const handleColorPickerChange = (e: ColorPickerChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: 'Display',
                syntheticEvent: e.syntheticEvent,
                value: palettes.find(x => x.rgba == e.value).ColorASCII
            });
        }
    };

    const handleChange = (e: TextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    let dataItem: CustomerSpecialInstructionViewModel = props.dataItem;
    let defaultColor = palettes.find(x => x.ColorASCII === dataItem.Display).rgba;

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {dataItem.inEdit ?
                <TextBox
                    value={dataItem.Instruction}
                    onChange={handleChange}
                    placeholder="Type your message here"
                    prefix={() => (
                        <>
                            <InputPrefix>
                                <ColorPicker paletteSettings={{
                                    palette: palettes.map(x => x.rgba),
                                    columns: 6,
                                    tileSize: 30
                                }} view="palette" defaultValue={defaultColor} onChange={handleColorPickerChange} />
                            </InputPrefix>
                            {/*<InputSeparator />*/}
                        </>
                    )}
                    style={{ width: '100%', color: defaultColor }}
                /> :
                <span style={{ color: defaultColor }}>{dataItem.Instruction}</span>
            }
        </td>
    );
};

interface EditCommandCellProps extends GridCellProps {
    edit: (item: CustomerSpecialInstructionViewModel) => void;
    remove: (item: CustomerSpecialInstructionViewModel) => void;
    add: (item: CustomerSpecialInstructionViewModel) => void;
    discard: () => void;
    update: (item: CustomerSpecialInstructionViewModel) => void;
    cancel: (item: CustomerSpecialInstructionViewModel) => void;
    editField: string;
}

const CustomerSpecialInstructions = () => {
    const { customerId: customerIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [CSIDataCopy, setCSIDataCopy] = useState<CustomerSpecialInstructionViewModel[]>([]);
    const [CustomerSpecialInstructions, setCustomerSpecialInstructions] = useState<CustomerSpecialInstructionViewModel[]>([]);


    const EditCommandCell = (props: EditCommandCellProps) => {
        //const { dataItem } = props;
        //const inEdit = dataItem[props.editField];
        const inEdit = props.dataItem[props.editField];
        let dataItem: CustomerSpecialInstructionViewModel = props.dataItem;
        const isNewItem = dataItem.isNew; //dataItem.CustomerSpecialInstructionID === undefined;

        return inEdit ? (
            <td className="k-command-cell">
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
                    disabled={dataItem.InstructionType === 0 || dataItem.Instruction.length === 0}
                    onClick={() =>
                        isNewItem ? props.add(dataItem) : props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </button>
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
                    onClick={() =>
                        isNewItem ? props.discard() : props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </button>
            </td>
        ) : (
            <td className="k-command-cell">
                <button
                    disabled={CustomerSpecialInstructions.some(x => x.inEdit === true || x.isNew === true)}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                    onClick={() => props.edit(dataItem)}
                >
                    Edit
                </button>
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
                    onClick={() =>
                        window.confirm("Confirm deleting: " + dataItem.Instruction) && props.remove(dataItem)
                    }
                >
                    Delete
                </button>
            </td>
        );
    };

    const refresh = useCallback(() => {
        setLoading(true);

        // const queryStr = `${toDataSourceRequestString(dataState)}`;
        //fetchApi(`/api/Customer/CustomerSpecialInstructions?${queryStr}`, data, 'POST')
        fetchApi(`/api/Customer/GetCustomerSpecialInstructions/${customerIdParam}`)
            .then(data => {
                setLoading(false);
                setCustomerSpecialInstructions(data.SpecialInstructions);
                setCSIDataCopy(data.SpecialInstructions);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [customerIdParam]);

    useEffect(() => {
        refresh();
    }, [customerIdParam, refresh]);

    const enterEdit = (dataItem: CustomerSpecialInstructionViewModel) => {
        if (dataItem.inEdit === true)
            return;

        setCustomerSpecialInstructions(
            CustomerSpecialInstructions.map((item) =>
                item.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID ? { ...item, inEdit: true } : item
            )
        );
    };

    const deleteSpecialInstructions = (dataItem: CustomerSpecialInstructionViewModel) => {
        setLoading(true);
        fetchApi(`/api/Customer/DeleteCustomerSpecialInstruction/${dataItem.CustomerSpecialInstructionID}`, {}, 'DELETE')
            .then(() => {
                setLoading(false);
                let index = CustomerSpecialInstructions.findIndex((record) => record.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID);
                CustomerSpecialInstructions.splice(index, 1)
                setCustomerSpecialInstructions(CustomerSpecialInstructions);
                setCSIDataCopy(CustomerSpecialInstructions);
            })
            .catch(() => {
            })
            .finally(() => {
            });
    }

    //redundant todo: remove
    const remove = (dataItem: CustomerSpecialInstructionViewModel) => {
        deleteSpecialInstructions(dataItem);
    };
    //const remove = (dataItem: CustomerSpecialInstructionViewModel) => {
    //    let index = CustomerSpecialInstructions.findIndex((record) => record.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID);
    //    CustomerSpecialInstructions.splice(index, 1)
    //    setCustomerSpecialInstructions(CustomerSpecialInstructions);
    //    setCSIDataCopy(CustomerSpecialInstructions);
    //};

    const updateSpecialInstructions = (dataItem: CustomerSpecialInstructionViewModel) => {
        setLoading(true);
        const data = { ...dataItem }
        fetchApi(`/api/Customer/CustomerSpecialInstruction/${customerIdParam}`, data, 'POST')
            .then(() => {
                refresh();
            })
            .catch(() => {
                alert('An error occurred while saving the instruction.');
                setLoading(false);
            });
    }

    //redundant todo: remove
    const add = (dataItem: CustomerSpecialInstructionViewModel) => {
        updateSpecialInstructions(dataItem);
    };

    //redundant todo: remove
    const update = (dataItem: CustomerSpecialInstructionViewModel) => {
        updateSpecialInstructions(dataItem);
    };

    //const add = (dataItem: CustomerSpecialInstructionViewModel) => {
    //    if (dataItem.InstructionType === 0) {
    //        alert('Please enter an entity type');
    //        return;
    //    } else if (dataItem.Instruction.length === 0) {
    //        alert('Please enter an instruction');
    //        return;
    //    }
    //    dataItem.CustomerSpecialInstructionID = 0;
    //    dataItem.inEdit = false;
    //    setCustomerSpecialInstructions(CustomerSpecialInstructions);
    //    setCSIDataCopy(CustomerSpecialInstructions);
    //};

    //const update = (dataItem: CustomerSpecialInstructionViewModel) => {
    //    dataItem.inEdit = false;
    //    let index = CustomerSpecialInstructions.findIndex((record) => record.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID);
    //    CustomerSpecialInstructions[index] = dataItem;
    //    setCustomerSpecialInstructions(CustomerSpecialInstructions);
    //    setCSIDataCopy(CustomerSpecialInstructions);
    //};

    const discard = () => {
        const newData = [...CustomerSpecialInstructions];
        newData.splice(0, 1);
        setCustomerSpecialInstructions(newData);
    };

    const cancel = (dataItem: CustomerSpecialInstructionViewModel) => {
        const originalItem = CSIDataCopy.find(
            //const originalItem = CustomerSpecialInstructions.find(
            (p) => p.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID
        );
        const newData = CustomerSpecialInstructions.map((item) =>
            item.CustomerSpecialInstructionID === originalItem.CustomerSpecialInstructionID ? originalItem : item
        );

        setCustomerSpecialInstructions(newData);
    };

    const generateId = () =>
        CustomerSpecialInstructions.reduce((acc, current) => Math.max(acc, current.CustomerSpecialInstructionID), 0) + 1;

    const addNew = () => {
        const newDataItem = {
            inEdit: true, CustomerSpecialInstructionID: generateId(), isNew: true,
            Display: 78, ModifiedByFullName: 'N/A', CopyToTrip: false, InstructionType: 0, Instruction: '',
        } as CustomerSpecialInstructionViewModel;
        setCustomerSpecialInstructions([newDataItem, ...CustomerSpecialInstructions]);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const dataItem = event.dataItem as CustomerSpecialInstructionViewModel;
        const newData = CustomerSpecialInstructions.map((item) =>
            item.CustomerSpecialInstructionID === dataItem.CustomerSpecialInstructionID
                ? { ...item, [event.field || ""]: event.value }
                : item
        );

        setCustomerSpecialInstructions(newData);
    }

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    return (
        <div className="container-fluid">
            <CustomerNavBar id={customerIdParam} currentTab="SpecialInstructions" />
            <Title string="Special Instructions" />
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <Grid
                data={CustomerSpecialInstructions}
                //data={CustomerSpecialInstructions.map((item) => ({
                //    ...item,
                //    ['Selected']: selectedState[idGetter(item)],
                //}))}
                onItemChange={itemChange}
                editField={editField}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => { enterEdit(e.dataItem); }}
            //onKeyDown={onKeyDown}
            //dataItemKey={'CustomerSpecialInstructionID'}
            //selectedField={'Selected'}
            //selectable={{
            //    enabled: true,
            //    drag: false,
            //    cell: true,
            //    mode: 'single',
            //}}
            //onSelectionChange={() => {}}
            >
                <GridToolbar>
                    <button
                        disabled={CustomerSpecialInstructions.some(x => x.inEdit === true || x.isNew === true)}
                        title="Add new"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={addNew}
                    >
                        Add new
                    </button>
                </GridToolbar>
                <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} />
                <Column field="CopyToTrip" title="Driver Visible" cell={CopyToTripCell} />
                <Column field="InstructionType" title="Entity Type" cell={InstructionTypeCell} />
                <Column field="Instruction" title="Instructions (displayed in quote/order entry)" cell={InstructionCell} />
                <Column cell={MyEditCommandCell} width={150} />
            </Grid>
        </div>
    )
}

export default CustomerSpecialInstructions;