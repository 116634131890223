import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { TextArea } from '@progress/kendo-react-inputs';
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { Command } from "TypeGen/Customer/Notes/command";

type Props = {
  loading: boolean;
  customerId: string;
  orderNote: Command;
  save: (note: Command) => void;
  cancel: () => void;
};

export const priorityTypes = [
  { ID: 0, Name: 'N/A' },
  { ID: 1, Name: '1 - Very High' },
  { ID: 2, Name: '2' },
  { ID: 3, Name: '3' },
  { ID: 4, Name: '4' },
  { ID: 5, Name: '5' },
  { ID: 6, Name: '6' },
  { ID: 7, Name: '7' },
  { ID: 8, Name: '8' },
  { ID: 9, Name: '9' },
  { ID: 10, Name: '10 - Very Low' },
];

export const noteTypes = [
  { ID: 0, Name: 'N/A' },
  { ID: 1, Name: 'Telemarketing New' },
  { ID: 2, Name: 'Telemarketing Revisit' },
  { ID: 3, Name: 'Telemarketing Maintenance' },
  { ID: 4, Name: 'In Person New' },
  { ID: 5, Name: 'In Person Revisit' },
  { ID: 6, Name: 'In Person Maintenance' },
  { ID: 7, Name: 'Billing' },
  { ID: 8, Name: 'Operations' },
  { ID: 10, Name: 'Email' },
  { ID: 9, Name: 'Other' },
];

const NoteEditUI: React.FC<Props> = ({ loading, customerId, orderNote, cancel, save, ...props }) => {
  const [note, setNote] = useState<Command>(orderNote);
  const [contacts, setContacts] = useState<IDNameViewModel[]>([]);

  const fetchContacts = (customerId: string) => {
    fetchApi('/api/Quote/CustomerContacts', { CustomerID: customerId }, 'POST')
      .then((response: { PerferredContactID: number, Contacts: IDNameViewModel[] }) => {
        setContacts([{ ID: 0, Name: 'N/A' }, ...response.Contacts]);
      });
  }

  useEffect(() => {
    fetchContacts(customerId);
  }, [customerId]);

  return <Dialog
    title={'Add Note'}
    className="dialog-w800"
    onClose={cancel}
  >
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <TextArea
          autoFocus
          placeholder="Note"
          value={note.Note}
          onChange={e => setNote({ ...note, Note: e.target.value })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DropDownList
          label="Contact"
          data={contacts}
          textField="Name"
          dataItemKey="ID"
          value={contacts.find(x => x.ID == note.CustomerContactID)}
          onChange={e => setNote({ ...note, CustomerContactID: e.target.value.ID })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DropDownList
          label="Priority"
          data={priorityTypes}
          textField="Name"
          dataItemKey="ID"
          value={priorityTypes.find(x => x.ID == note.Priority)}
          onChange={e => setNote({ ...note, Priority: e.target.value.ID })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DropDownList
          label="Event Type"
          data={noteTypes}
          textField="Name"
          dataItemKey="ID"
          value={noteTypes.find(x => x.ID == note.NoteType)}
          onChange={e => setNote({ ...note, NoteType: e.target.value.ID })}
        />
      </FieldWrapper>
    </form>
    <DialogActionsBar>
      <Button disabled={loading} onClick={cancel}>Cancel</Button>
      <Button
        disabled={loading}
        themeColor={'success'}
        onClick={() => save(note)}
      >
        Add Note
      </Button>
    </DialogActionsBar>
  </Dialog>
}

export default NoteEditUI;