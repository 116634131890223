import { Popup } from '@progress/kendo-react-popup';
import { GridCellProps } from "@progress/kendo-react-grid";
import * as React from 'react';
import { DriverValue, SelectedRowValues, MenuOption } from './AssetPanel';
import OutsideAlerter from '../../components/OutsideAlerter';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';
import { Button } from '@progress/kendo-react-buttons';
import { gearIcon } from '@progress/kendo-svg-icons';

export default function assetActionCell(
  SelectedDrivers: DriverValue[],
  SelectedVehicles: AssetVehicleViewModel[],
  LoadDialogScreen: (SelectedRowValues: SelectedRowValues, SelectedDrivers: DriverValue[], SelectedVehicles: AssetVehicleViewModel[], SelectedMenuOption: MenuOption) => void,
) {
  return class ActionPopup extends React.Component<GridCellProps> {

    private showMenu = false;
    private someSelected = (SelectedDrivers.length > 0 || SelectedVehicles.length > 0);
    private someDriversSelected = SelectedDrivers.length > 0 || this.props.dataItem.PhysicalExpiring != null;
    private someVehiclesSelected = SelectedVehicles.length > 0 || this.props.dataItem.ServiceStatus != null;
    private anchor: React.RefObject<Button>;

    private isSingleDriverRowSelected = (SelectedVehicles.length == 0 && (SelectedDrivers.length == 1 || (SelectedDrivers.length == 0 && this.props.dataItem.PhysicalExpiring != null)));
    private isSingleVehicleRowSelected = (SelectedDrivers.length == 0 && (SelectedVehicles.length == 1 || (SelectedVehicles.length == 0 && this.props.dataItem.PreventiveMaintenanceExpiringDays != null)));

    constructor(props: GridCellProps) {
      super(props);

      this.anchor = React.createRef();
    }

    render() {
      return (
        <td>
          <Button
            togglable
            selected={this.showMenu}
            size="small"
            svgIcon={gearIcon}
            disabled={this.someSelected && !this.props.dataItem.Selected} //allow active button if none are selected or only for the ones selected
            onClick={(e) => {
              this.showMenu = !this.showMenu;
              this.forceUpdate();
              e.preventDefault();
            }}
            ref={this.anchor}
          />
          <div>
            {this.anchor.current && <Popup anchor={this.anchor.current.element} show={this.showMenu} popupClass={'popup-content'}>
              <OutsideAlerter toggle={() => { this.showMenu = !this.showMenu; this.forceUpdate(); }} style={{ display: 'contents' }}>
                <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen({
                      DriverID: this.props.dataItem.DriverID,
                      DriverNumber: this.props.dataItem.DriverNumber,
                      VehicleID: this.props.dataItem.VehicleID,
                      VehicleNumber: this.props.dataItem.VehicleNumber,
                      OwnerID: this.props.dataItem.OwnerID,
                      OwnerName: this.props.dataItem.OwnerName,
                      IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                      IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                    } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.RoboCall);
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Send Message</a>
                {this.someDriversSelected && <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen({
                      DriverID: this.props.dataItem.DriverID,
                      DriverNumber: this.props.dataItem.DriverNumber,
                      VehicleID: this.props.dataItem.VehicleID,
                      VehicleNumber: this.props.dataItem.VehicleNumber,
                      OwnerID: this.props.dataItem.OwnerID,
                      OwnerName: this.props.dataItem.OwnerName,
                      IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                      IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                    } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.DriverRequestSignature);
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Request Signature</a>}
                {this.someVehiclesSelected && <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen({
                      DriverID: this.props.dataItem.DriverID,
                      DriverNumber: this.props.dataItem.DriverNumber,
                      VehicleID: this.props.dataItem.VehicleID,
                      VehicleNumber: this.props.dataItem.VehicleNumber,
                      OwnerID: this.props.dataItem.OwnerID,
                      OwnerName: this.props.dataItem.OwnerName,
                      IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                      IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                    } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.VehicleRequestSignature);
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Request Signature</a>}
                {this.someVehiclesSelected && this.isSingleVehicleRowSelected == false && <>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        VehicleID: this.props.dataItem.VehicleID,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.HideFromDispatch);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Hide From Dispatch</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        VehicleID: this.props.dataItem.VehicleID,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.ShowToDispatch);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Show To Dispatch</a>
                </>}
                {this.isSingleDriverRowSelected && <>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.DriverDocuments);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Documents</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.HealthForms);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >COVID-19 Self Assessment</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        DriverName: `${this.props.dataItem.FirstName} ${this.props.dataItem.LastName}`,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.CardMaintenance);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Card Maintenance</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        DriverName: `${this.props.dataItem.FirstName} ${this.props.dataItem.LastName}`,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.MoneyCode);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Issue Money Code</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        DriverName: `${this.props.dataItem.FirstName} ${this.props.dataItem.LastName}`,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.AccidentReport);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Accident/Incident</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        DriverName: `${this.props.dataItem.FirstName} ${this.props.dataItem.LastName}`,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.DriverPayRateMaster);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Set Pay Rate Structure</a>
                </>}
                {this.isSingleVehicleRowSelected && <>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        DriverID: this.props.dataItem.DriverID,
                        DriverNumber: this.props.dataItem.DriverNumber,
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                        OwnerID: this.props.dataItem.OwnerID,
                        OwnerName: this.props.dataItem.OwnerName,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, [], [], MenuOption.VehicleDocuments);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Documents</a>
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        VehicleID: this.props.dataItem.VehicleID,
                        VehicleNumber: this.props.dataItem.VehicleNumber,
                      } as SelectedRowValues, [], [], MenuOption.VehicleLastAvailable);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Adjust Last Available</a>
                  {this.props.dataItem.HideFromDispatch ? <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        VehicleID: this.props.dataItem.VehicleID,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.ShowToDispatch);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Show To Dispatch</a> :
                    <a
                      href="#"
                      style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                      onClick={(e) => {
                        this.showMenu = false;
                        LoadDialogScreen({
                          VehicleID: this.props.dataItem.VehicleID,
                          IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                          IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                        } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.HideFromDispatch);
                        e.preventDefault();
                        this.forceUpdate();
                      }}
                    >Hide From Dispatch</a>}
                  <a
                    href="#"
                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      this.showMenu = false;
                      LoadDialogScreen({
                        VehicleID: this.props.dataItem.VehicleID,
                        IsDriverRow: this.props.dataItem.PhysicalExpiring != null,
                        IsVehicleRow: this.props.dataItem.PreventiveMaintenanceExpiringDays != null
                      } as SelectedRowValues, SelectedDrivers, SelectedVehicles, MenuOption.VehiclePayRateMaster);
                      e.preventDefault();
                      this.forceUpdate();
                    }}
                  >Set Pay Rate Structure</a>
                </>}
              </OutsideAlerter>
            </Popup>}
          </div>
        </td>
      );
    }
  }
}
