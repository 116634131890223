import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { fetchApi } from '../../services/api';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { isNullOrWhiteSpace } from '../../utils/utils';
import { Button } from '@progress/kendo-react-buttons';
import { CustomerContactType } from './CustomerContactProfile';

type Props = {
  CustomerId: number;
  CloseDialog: (newCustomerContactId?: number) => void;
}

const AddCustomerContactDialog = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<CustomerContactType>(null);

  const canSave = (isNullOrWhiteSpace(contact?.FirstName) === false && isNullOrWhiteSpace(contact?.LastName) === false);

  const _save = () => {
    if (canSave === false)
      return;

    setLoading(true);

    const data = {
      ...contact, CustomerID: props.CustomerId
    } as CustomerContactType


    fetchApi('/api/Customer/AddCustomerContact', data, 'POST')
      .then((data: { CustomerContactID: number }) => {
        setLoading(false);
        props.CloseDialog(data.CustomerContactID);
      }
      ).catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to create contact');
      });
  }

  return <Dialog title={'Create Customer Contact'} onClose={() => props.CloseDialog()} width={400} height={600}>
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <div className="k-form k-form-md p-0">
      <div>
        <FieldWrapper>
          <Input
            required
            maxLength={20}
            autoFocus
            disabled={loading}
            value={contact?.FirstName}
            label="First Name"
            onChange={(e) => setContact({ ...contact, FirstName: e.value })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            required
            maxLength={20}
            disabled={loading}
            value={contact?.LastName}
            label="Last Name"
            onChange={(e) => setContact({ ...contact, LastName: e.value })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            type="email"
            maxLength={80}
            disabled={loading}
            label="E-Mail"
            value={contact?.EmailAddress}
            onChange={(e) => setContact({ ...contact, EmailAddress: e.value })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            maxLength={50}
            disabled={loading}
            value={contact?.Title}
            label="Title"
            onChange={(e) => setContact({ ...contact, Title: e.value })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <div className="row">
            <div className="col-8 pr-1">
              <MaskedTextBox
                mask="(000) 000-0000"
                value={contact?.PhoneNumber}
                disabled={loading}
                label="Office Number"
                onChange={(e) => setContact({ ...contact, PhoneNumber: e.value })}
              />
            </div>
            <div className="col-4 pl-1">
              <Input
                maxLength={10}
                value={contact?.PhoneNumberExtension}
                disabled={loading}
                label="Ext."
                onChange={(e) => setContact({ ...contact, PhoneNumberExtension: e.value })}
              />
            </div>
          </div>
        </FieldWrapper>
        <FieldWrapper>
          <MaskedTextBox
            mask="(000) 000-0000"
            value={contact?.CellNumber}
            disabled={loading}
            label="Cell Number"
            onChange={(e) => setContact({ ...contact, CellNumber: e.value })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Checkbox
            label={"Receive E-mail Invoices"}
            onChange={(e) => setContact({ ...contact, EmailInvoices: e.value })}
          />
        </FieldWrapper>
      </div>
    </div>
    <DialogActionsBar>
      <Button onClick={() => props.CloseDialog()}>Cancel</Button>
      <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
        Create Contact
      </Button>
    </DialogActionsBar>
  </Dialog>
}

export default AddCustomerContactDialog;

