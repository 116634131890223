import * as React from 'react';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';

type Props = {
  shipmentId: string;
}

type State = {
  loading: boolean;
  rates: CustomerRateAnalysisViewModel[]
}

type GetCustomerRateAnalysisResponse = {
  ShipmentID: string;
  Rates: CustomerRateAnalysisViewModel[];
}

type CustomerRateAnalysisViewModel = {
    VehicleTypeID: number;
    VehicleType: string;
    AverageLinehaulFSC: number;
    AveragePerMile: number;
    MinLinehaul: number;
    AverageLinehaul: number;
    MaxLinehaul: number;
    MinFuel: number;
    AverageFuel: number;
    MaxFuel: number;
    MinDistance: number;
    AverageDistance: number;
    MaxDistance: number;
    OrderCount: number;
}

export default class LoadBoardCustomerRateAnalysis extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      rates: [],
    }

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
        return <LoadingPanel />
    }

    return <Grid data={this.state.rates}>
        <GridNoRecords>
          No Rates Found!
        </GridNoRecords>
        <Column field="VehicleType" title="Vehicle Type" />
        <Column field="AverageLinehaulFSC" title="AVG LH+FSC" filter="numeric" format="{0:c}" />
        <Column field="AveragePerMile" title="AVG $/MILE" filter="numeric" format="{0:c}" />
        <Column field="MinLinehaul" title="Min LH" filter="numeric" format="{0:c}" />
        <Column field="AverageLinehaul" title="Avg LH" filter="numeric" format="{0:c}" />
        <Column field="MaxLinehaul" title="Max LH" filter="numeric" format="{0:c}" />
        <Column field="MinFuel" title="Min FSC" filter="numeric" format="{0:c}" />
        <Column field="AverageFuel" title="Avg FSC" filter="numeric" format="{0:c}" />
        <Column field="MaxFuel" title="Max FSC" filter="numeric" format="{0:c}" />
        <Column field="MinDistance" title="Min Miles" filter="numeric" format="{0:n0}" />
        <Column field="AverageDistance" title="Avg Miles" filter="numeric" format="{0:n0}" />
        <Column field="MaxDistance" title="Max Miles" filter="numeric" format="{0:n0}" />
        <Column field="OrderCount" title="Order Count" filter="numeric" />
    </Grid>;
  }

  private fetch() {
    fetchApi('/api/LoadBoard/CustomerRateAnalysis', { ShipmentID: this.props.shipmentId }, 'POST')
      .then((response: GetCustomerRateAnalysisResponse) => {
        this.setState({ loading: false, rates: response.Rates });
      })
  }
}
