import { formatNumber } from '@progress/kendo-intl';
import { Push } from "@progress/kendo-react-animation";
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridDetailRowProps, GridExpandChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import DateOnlyCell from "../../components/cells/DateOnlyCell";
import CenterDivPanel from '../../components/CenterDivPanel';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { PayDivision } from '../../TypeGen/pay-division';
import { IDName } from "../../types/idname";
import { PayDivisions } from "../../utils/utils";

type ReadyToPostPaySummary = {
  PayGroups: PayGroup[];
  PayPeriodGroups: PayPeriodGroup[];
}

type PayPeriodGroup = {
  Name: string;
  Division: number;
  PeriodEndDate: Date;
  PaperworkDeadline: Date;
  PayDate: Date;
  PayCount: number;
  PayPeriodID: number;
  PayTotal: number;

  Pays: Pay[];
  Expanded: boolean;
};

type PayGroup = {
  UserID: number;
  UserName: string;
  PayCount: number;
  Pays: Pay[];

  Expanded: boolean;
};

type Pay = {
  PayNumber: string;
  Amount: number;
  ShipDateTime: Date;
  DriverNumber: string;
  CarrierName: string;
}

interface GeneratePostBatchCommandCellProps extends GridCellProps {
  generatePayPostBatch: (item: PayPeriodGroup) => void;
}

const PostPayBatch = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<ReadyToPostPaySummary>();
  const [message, setMessage] = useState('');
  const { ConfirmationDialog, confirm } = useConfirm({});

  const refreshBatchDetails = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Settlements/GetReadyToPostPay`)
      .then((response: ReadyToPostPaySummary) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
        setLoading(false);
      });
  }, []);

  const generatePayPostBatch = (dataItem: PayPeriodGroup) => {
    setLoading(true);
    fetchApi(`/api/Settlements/PostPayBatch`, { PayPeriodID: dataItem.PayPeriodID }, 'POST')
      .then((message: string) => {
        setMessage(message);
        refreshBatchDetails();
      })
      .catch(e => {
        if (!e?.status)
          alert('Error: Please see admin');
        setLoading(false);
      });
  }

  useEffect(() => {
    refreshBatchDetails();
  }, [refreshBatchDetails]);

  const expandPayPeriodChange = (event: GridExpandChangeEvent) => {
    event.dataItem.Expanded = !event.dataItem.Expanded;

    var invoiceGroup = details.PayPeriodGroups.find(x => x.PayPeriodID === event.dataItem.PayPeriodID);
    invoiceGroup.Expanded = event.dataItem.Expanded;

    setDetails({ ...details, PayPeriodGroups: [...details.PayPeriodGroups] });
  };

  const expandPayGroupChange = (event: GridExpandChangeEvent) => {
    event.dataItem.Expanded = !event.dataItem.Expanded;

    var invoiceGroup = details.PayGroups.find(x => x.UserID === event.dataItem.UserID);
    invoiceGroup.Expanded = event.dataItem.Expanded;

    setDetails({ ...details, PayGroups: [...details.PayGroups] });
  };

  const detailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem as PayGroup;
    return (
      <section style={{ maxHeight: '300px', overflow: 'auto' }}>
        <div className="card-body">
          <h5 className="card-title text-left">Trips</h5>
          <table className="table table-hover table-striped mb-0">
            <thead>
              <tr>
                <th>Pay No.</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Ship Date</th>
              </tr>
            </thead>
            <tbody>
              {dataItem.Pays.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.PayNumber}</td>
                  <td>{invoice.DriverNumber ?? invoice.CarrierName}</td>
                  <td>{formatNumber(invoice.Amount, "c")}</td>
                  <td>{Moment.utc(invoice.ShipDateTime).tz("America/New_York").format("MM/DD/YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  };

  const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
      <div>{props.title}</div>
      {props.children}
    </a>);
  };

  const RightAlignCell = (props: GridCellProps) => {
    return <td colSpan={props.colSpan} style={{ ...props.style, textAlign: "right" }}>
      {props.dataItem[props.field]}
    </td>;
  };

  const DivisionCell = (props: GridCellProps) => {
    let dataItem: PayPeriodGroup = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <div>{dataItem.Division > 0 ? PayDivisions.find((x: IDName) => x.ID == dataItem.Division).Name : 'N/A'}</div>
      </td>
    );
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <GeneratePostBatchCommandCell {...props}
      generatePayPostBatch={generatePayPostBatch}
    />
  );

  const getPPPayDateDifferenceInDays = (dataItem: PayPeriodGroup): number => {
    if (dataItem.PayPeriodID > 0) {
      const today = Moment().tz("America/New_York");
      const payDate = Moment.utc(dataItem.PayDate).tz("America/New_York");
      return payDate.diff(today, 'days');
    }
    return 0;
  }

  const getPPConfirmationInfo = (dataItem: PayPeriodGroup): string => {
    if (dataItem.PayPeriodID > 0) {
      const payDate = Moment.utc(dataItem.PayDate).tz("America/New_York");
      const periodEndDate = Moment.utc(dataItem.PeriodEndDate).tz("America/New_York");
      const differenceInDays = getPPPayDateDifferenceInDays(dataItem);

      return (`${PayDivision[dataItem.Division]} pay ending on ${periodEndDate.format("MM/DD/YYYY")} is set to be paid on ` +
        `${payDate.format("MM/DD/YYYY")}, which is in ${differenceInDays} day(s) from today.` +
        `\n\nWARNING: Pay date is ${differenceInDays} day(s) out.` +
        `\n\nPost/Close pay period ${dataItem.Name} for ${PayDivisions.find(x => x.ID === dataItem.Division).Name}?`);
    } else {
      return ('Post Brokered Loads?');
    }
  };

  const GeneratePostBatchCommandCell = (_props: GeneratePostBatchCommandCellProps) => {

    let dataItem: PayPeriodGroup = _props.dataItem;

    return <td className="k-command-cell">
      <Button themeColor={"primary"}
        disabled={getPPPayDateDifferenceInDays(dataItem) >= 3 || dataItem.PayCount === 0}
        onClick={async () => {
          if (!await confirm(`${getPPConfirmationInfo(dataItem)}`, 'Confirm'))
            return;
          _props.generatePayPostBatch(dataItem);
        }}>
        {dataItem.PayPeriodID > 0 ? `Post ${dataItem.Name} (${dataItem.PayPeriodID}) Pay Period` : 'Post Brokered Loads'}
      </Button>
    </td>
  };

  return (
    <div style={{ position: "relative", height: 'auto' }} className="mt-4">
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <h4 className="text-left">Post Pay Batch</h4>
      <Push style={{ zIndex: 0, width: '100%' }}>
        {message && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
          <Notification style={{ padding: "8px", width: '100%' }} type={{ style: "success", icon: true }}>
            <div>{message}</div>
          </Notification>
        </NotificationGroup>}
      </Push>

      <div className="mb-4">
        <Grid
          data={details?.PayPeriodGroups}
          detail={detailComponent}
          expandField="Expanded"
          onExpandChange={expandPayPeriodChange}
        >
          <Column field="PayPeriodID" title="Pay Period ID" />
          <Column field="Name" title="Pay Period Name" />
          <Column field="Division" title="Division" cell={DivisionCell} filterable={false} />
          <Column field="PeriodEndDate" title="Trips Up To" cell={DateOnlyCell} minResizableWidth={160} width={160} />
          <Column field="PaperworkDeadline" title="Paperwork Checked In By" cell={DateOnlyCell} minResizableWidth={160} width={160} />
          <Column field="PayDate" title="Pay Date" cell={DateOnlyCell} minResizableWidth={160} width={160} />
          <Column field="PayCount" title="Pay Count" headerCell={RightAlignHeaderCell} cell={RightAlignCell} minResizableWidth={160} width={160} />
          <Column cell={MyEditCommandCell} width={210} filterable={false} />
        </Grid>
      </div>

      <div>
        <Grid
          data={details?.PayGroups}
          detail={detailComponent}
          expandField="Expanded"
          onExpandChange={expandPayGroupChange}
        >
          <Column field="UserName" title="User ID" />
          <Column field="PayCount" title="Pay Count" headerCell={RightAlignHeaderCell} cell={RightAlignCell} />
        </Grid>
      </div>
      <ConfirmationDialog />
    </div >
  );
};

export default PostPayBatch;
