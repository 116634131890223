import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';

const RateUoMCell = (props: GridCellProps) => {
  const localizedData = [
      { text: 'Dollars', value: 8 },
      { text: 'Flat', value: 1 },
      { text: 'Miles', value: 2 },
      { text: 'Percent', value: 3 },
      { text: 'Units', value: 4 },
      { text: 'Each', value: 5 },
      { text: '15 Minutes', value: 6 },
  ];

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value
      });
    }
  }

  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? <DropDownList
        onChange={handleChange}
        value={localizedData.find(c => c.value === dataValue)}
        data={localizedData}
        textField="text"
      />: localizedData.find(c => c.value === dataValue)?.text}
    </td>
  );
}

export default RateUoMCell;
