import * as React from 'react';
import { Window, WindowProps } from "@progress/kendo-react-dialogs";

type Props = {
    autoSizePercentage?: number;
} & WindowProps;

const KendoWindow: React.FC<Props> = ({
    autoSizePercentage,
    ...windowProps
}) => {
    if (autoSizePercentage) {
        windowProps.initialWidth = window.innerWidth * (autoSizePercentage / 100);
        windowProps.initialHeight = window.innerHeight * (autoSizePercentage / 100);
        windowProps.initialTop = (window.innerHeight - windowProps.initialHeight) / 2 + window.scrollY;
        windowProps.initialLeft = (window.innerWidth - windowProps.initialWidth) / 2;
    }

    return <Window {...windowProps} />;
}

export default KendoWindow;