import * as React from 'react';
import Moment from 'moment-timezone';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import realFormatter from '../../services/formatting/number';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import LoadingPanel from '../LoadingPanel';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { ViewModel as ArriveData } from 'TypeGen/Order/Arrive/view-model';
import { Command as ArriveRequest } from 'TypeGen/Order/Arrive/command';
import CenterDivPanel from '../CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { JsonResponse } from 'TypeGen/json-response';
import { useTrack } from 'views/Track';
import { useEffect, useState } from 'react';

type Props = {
    Link: ILink;
    CloseDialog: () => void;
}

const trailerOptions = [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes, No FOB' },
    { value: 2, text: 'Yes, FOB' },
]

const Arrive = ({ Link, CloseDialog}: Props) => {

    const track = useTrack();
    const [data, setData] = useState<ArriveData | null>(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [dateTime, setDateTime] = useState<Date>(Moment().toDate());
    const [reasonLate, setReasonLate] = useState<number>(0);
    const [unhookTrailer, setUnhookTrailer] = useState<boolean>(false);
    const [trailerFreightOnBoard, setTrailerFreightOnBoard] = useState<boolean>(false);

    const arrive = () => {
        setSaving(true);
        const link = data.Links.find(x => x.Name == "Arrive") as ILink;
        const request = {
            DateTime: dateTime,
            ReasonLate: reasonLate,
            UnhookTrailer: unhookTrailer,
            TrailerFreightOnBoard: trailerFreightOnBoard,
        } as ArriveRequest;
        fetchApi(link.Link, request, link.Method)
            .then((response: JsonResponse) => {
                if (response.Success) {
                    track?.refresh();
                    CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('Unable to arrive');
            })
            .finally(() => setSaving(false));
    }

    useEffect(() => {
        fetchApi(Link.Link, {}, Link.Method)
            .then((data: ArriveData) => {
                setData(data);
                setDateTime(Moment.utc(data.DateTime).toDate());
                setLoading(false);
            });
    }, [Link]);

    if (loading) {
        return <React.Fragment>
            <div className="k-overlay" style={{ zIndex: 10002, opacity: 0.5 }} />
            <LoadingPanel />
        </React.Fragment>
    }
    return (
        <Dialog
            title={`Arrive (${data.Sequence} of ${data.StopCount}) - Pro # ${data.OrderNumber}`}
            onClose={CloseDialog}
            width="90%"
            height="90%"
        >
            {saving && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            {data.Notes.map((note, index) => {
                return (<div key={index} className="alert alert-info" role="alert">
                    <strong>Stop Note</strong> {note}
                </div>);
            })}

            <div className="row">
                <div className="col-md-6">
                    <p>
                        {data.LocationName !== null && <>{data.LocationName.toUpperCase()}<br /></>}
                        {data.AddressLine1 !== null && <>{data.AddressLine1.toUpperCase()}<br /></>}
                        {data.City}, {data.State} {data.ZipCode}
                    </p>
                </div>
                <div className="col-md-6">
                    <p className="mb-0">
                        Scheduled {data.TypeName}
                        <button
                            className="btn btn-link"
                            onClick={() => setDateTime(Moment.utc(data.ScheduledDateTime).toDate())}
                        >
                            {Moment.utc(data.ScheduledDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                        </button>
                    </p>
                    {data.ArrivalDateTime && <p className="mb-0">
                        Arrival
                        <button
                            className="btn btn-link"
                            onClick={() => setDateTime(Moment.utc(data.ArrivalDateTime).toDate())}
                        >
                            {Moment.utc(data.ArrivalDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                        </button>
                    </p>}
                    {data.DepartDateTime && <p className="mb-0">
                        Departure {Moment.utc(data.DepartDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                    </p>}
                    {data.Miles > 0 && <p>
                        {realFormatter(data.Miles)} Stop Miles
                    </p>}
                </div>
            </div>

            <div className="form-group row">
                <label className="col-md-4 col-form-label">Arrival Date/Time (EST)</label>
                <div className="col-md-8">
                    <DateTimePicker
                        required
                        format="MM/dd/yyyy HH:mm"
                        formatPlaceholder="formatPattern"
                        value={dateTime}
                        onChange={(e) => setDateTime(e.value)}
                        width="100%"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-md-4 col-form-label">Reason Late</label>
                <div className="col-md-8">
                    <DropDownList
                        defaultValue={0}
                        value={data.ReasonsLate.find(x => x.ID == reasonLate)}
                        data={data.ReasonsLate}
                        textField="Name"
                        style={{ width: '100%' }}
                        onChange={(e) => setReasonLate(e.target.value.ID)}
                    />
                </div>
            </div>

            {data.TrailerNumber && <div className="form-group row">
                <label className="col-md-4 col-form-label">Unhook/Drop Trailer {data.TrailerNumber} ?</label>
                <div className="col-md-8">
                    <DropDownList
                        defaultValue={0}
                        value={trailerOptions.find(x => x.value == (unhookTrailer ? (trailerFreightOnBoard ? 2 : 1) : 0))}
                        data={trailerOptions}
                        textField="text"
                        onChange={(e) => {
                            setUnhookTrailer(e.target.value.value !== 0);
                            setTrailerFreightOnBoard(e.target.value.value === 2);
                        }}
                    />
                </div>
            </div>}
            <DialogActionsBar layout="end">
                <Button onClick={CloseDialog} disabled={saving}>Cancel</Button>
                <Button themeColor="primary" onClick={arrive} disabled={saving}>Arrive</Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default Arrive;
