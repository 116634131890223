import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { JsonResponse } from "TypeGen/json-response";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import CenterDivPanel from "../../components/CenterDivPanel";
import { EditableField } from "../../components/EditableField";
import { fetchApi } from "../../services/api";
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';

type Props = {
  isDialog?: boolean
  vendorId?: number;

  onClose?: () => void;
}

type RouteComponentParams = {
  vendorId: string;
};

type VendorProfileType = {
  Vendor: VendorType,
  Users: IDName[];
}

type VendorType = {
  PurchaseOrderVendorID: number,
  VendorName: string;
  AddressLine1: string;
  City: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  VendorID: string;
  Active: boolean;
  PaymentTerms: number;
  AuditInfo: AuditInfoViewModel;
}

const VendorProfile = (props: Props) => {

  const { vendorId: vendorIdParam } = useParams<RouteComponentParams>();
  const [vendorId, setVendorId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<VendorProfileType>();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/PurchaseOrder/Vendor/${vendorId}`)
        .then(data => {
          setLoading(false);
          setProfile(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
    fetchData();
  }, [vendorId]);

  useEffect(() => {
    if (props.vendorId > 0)
      setVendorId(props.vendorId);
    else
      setVendorId(Number(vendorIdParam));
  }, [vendorIdParam, props.vendorId]);

  useEffect(() => {
    if (vendorId > 0)
      fetchProfile();
  }, [vendorId, fetchProfile]);

  const saveAsyncProfile = async (value: Partial<VendorType>): Promise<void> => {
    let errorMsg = '';
    const newProfile: VendorType = Object.assign({}, profile.Vendor, value);
    await fetchApi(`/api/PurchaseOrder/Vendor/${vendorId}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          const newProfile: VendorType = Object.assign({}, profile.Vendor, value);
          setProfile({ ...profile, Vendor: { ...newProfile } });
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const dataView = () => {
    if (loading || !profile) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return (<>
      <Title string={profile.Vendor.VendorName} />
      <div className="row m-2 mt-4">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="VendorName" className="col-md-3 col-form-label">Vendor Name</label>
            <div className="col-md-9">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 40 }}
                data={profile.Vendor.VendorName}
                save={async (data) => await saveAsyncProfile({ VendorName: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="AddressLine1" className="col-md-3 col-form-label">Address</label>
            <div className="col-md-9">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 30 }}
                data={profile.Vendor.AddressLine1}
                save={async (data) => await saveAsyncProfile({ AddressLine1: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="City" className="col-md-3 col-form-label">City</label>
            <div className="col-md-9">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 30 }}
                data={profile.Vendor.City}
                save={async (data) => await saveAsyncProfile({ City: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="State" className="col-md-3 col-form-label">State</label>
            <div className="col-md-9">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 2 }}
                data={profile.Vendor.State}
                save={async (data) => await saveAsyncProfile({ State: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="ZipCode" className="col-md-3 col-form-label">Zip</label>
            <div className="col-md-9">
              <EditableField
                dataType="string"
                inputProps={{ maxLength: 10 }}
                data={profile.Vendor.ZipCode}
                save={async (data) => await saveAsyncProfile({ ZipCode: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="PhoneNumber" className="col-md-3 col-form-label">Phone Number</label>
            <div className="col-md-9">
              <EditableField
                dataType="phone"
                data={profile.Vendor.PhoneNumber}
                save={async (data) => await saveAsyncProfile({ PhoneNumber: data })}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="VendorID" className="col-md-3 col-form-label">Vendor ID</label>
            <div className="col-md-9">
              <EditableField
                required
                dataType="string"
                inputProps={{ maxLength: 30 }}
                data={profile.Vendor.VendorID}
                save={async (data) => await saveAsyncProfile({ VendorID: data })}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="PaymentTerms" className="col-md-3 col-form-label">Payment Terms</label>
            <div className="col-md-9">
              <EditableField
                data={profile.Vendor.PaymentTerms}
                renderDisplay={(data) => `NET ${data}`}
                renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
                  <option value={7}>NET 7</option>
                  <option value={15}>NET 15</option>
                  <option value={30}>NET 30</option>
                </select>}
                save={async (data) => await saveAsyncProfile({ PaymentTerms: data })}
              />
            </div>
          </div>

          <AuditInfo audit={profile.Vendor.AuditInfo} />
        </div>
      </div>
    </>);
  }

  if (props.isDialog) {
    return <Dialog
      className="dialog-w11/12"
      title={<Link to={`/PurchaseOrder/Vendor/${props.vendorId}`}>Vendor Profile {profile?.Vendor.VendorName}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default VendorProfile;