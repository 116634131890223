import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../types/link';
import { Link } from 'react-router-dom';

const OwnerNumberCell = (props: GridCellProps) => {

    if (!props.field || props.rowType !== 'data') return null;
    const profile = props.dataItem.Links.find((x: ILink) => x.Name === 'OwnerProfile');
    return (
        <td>
            <Link
                to={profile.Link}
                style={{ color: '#007bff' }}
            >
                {props.dataItem.OwnerNumber}
            </Link>
        </td>
    );
}

export default React.memo(OwnerNumberCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
