import * as React from 'react';
import { Title } from '../../utils/title';
import Moment from 'moment';
import TimeAgo from 'react-timeago';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import * as AccountNotificationState from '../../store/AccountNotifications';
import { fetchApi } from '../../services/api';
import { ApplicationState } from '../../store';
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';

const connector = connect(
  (state: ApplicationState) => state.accountNotifications, // Selects which state properties are merged into the component's props
  AccountNotificationState.actionCreators                 // Selects which action creators are merged into the component's props
)

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type State = {}

class AccountNotifications extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.dismissAllNotifications = this.dismissAllNotifications.bind(this);
  }

  public componentWillMount() {
    this.props.requestAccountNotifications();
  }

  public render() {
    if (!this.props.accountNotifications || this.props.isLoading) {
      return (
        <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%' }}
        />
      );
    }

    return (
      <div className="container" style={{ paddingTop: 10 }}>
        <Title string="Notifications" />
        {this.props.accountNotifications.map((notification, index) => {
          return (
            <div key={index.toString()} className="alert alert-info alert-dismissible fade show" role="alert">
              <h4 className="alert-heading">
                {notification.PopupLink ?
                  <Link to={notification.Link} onClick={(e) => openWindow(notification.PopupLink)} className="alert-link">{notification.Text}</Link>
                  : <Link to={notification.Link} className="alert-link">{notification.Text}</Link>}
              </h4>
              <p className="mb-0"><TimeAgo date={Moment.utc(notification.CreatedDateTime).toDate()} /></p>
              <button
                onClick={() => this.dismissNotification(notification.Links.find(x => x.Name === 'Dismiss'))}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          );
        })}
        {this.props.accountNotifications.length > 0 && <div className="d-flex justify-content-center">
          <button className="btn btn-danger" onClick={this.dismissAllNotifications}>Clear All Notifications</button>
        </div>}
      </div>
    );
  }

  private dismissNotification(link: ILink|undefined) {
    if (link) {
      fetchApi(link.Link, {}, link.Method);
    }
  }

  private dismissAllNotifications(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    this.props.accountNotifications.forEach((notification) => {
      const link = notification.Links.find(x => x.Name === 'Dismiss')
      if (link) {
        fetchApi(link.Link, {}, link.Method);
      }
    });
  }
}

export default connector(AccountNotifications);
