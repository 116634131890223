import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Card,
  CardBody, StackLayout
} from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import CenterDivPanel from "../../components/CenterDivPanel";
import { fetchApi } from "../../services/api";

interface Props {
  groupId: string;
  groupName: string;
  CloseDialog: () => void;
}

export type SecurityGroupUserViewModel = {
  Name: string;
  Id: string;
}

const SecurityGroupUsers: React.FC<Props> = ({ groupId, groupName, CloseDialog }) => {
  const [loading, setLoading] = useState(true);
  const [securityGroupUsers, setSecurityGroupUsers] = useState<SecurityGroupUserViewModel[]>();

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Account/SecurityGroupUsers/${groupId}`)
      .then((data: { SecurityGroupUsers: SecurityGroupUserViewModel[] }) => {
        setLoading(false);
        setSecurityGroupUsers(data.SecurityGroupUsers);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [groupId]);

  useEffect(() => {
    refresh();
  }, [groupId, refresh]);

  return (
    <Dialog title={groupName} onClose={CloseDialog} contentStyle={{ minWidth: 300, maxHeight: 750, minHeight: 100 }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <StackLayout
        orientation={'vertical'}
        gap={8}
      >
        {securityGroupUsers?.map((user) => (
          <Card key={user.Id}>
            <CardBody className="p-1">
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-4">
                  <img
                    className="rounded-circle"
                    title={user.Name}
                    height="34"
                    src={`/api/User/Photo/${user.Id}`}
                    alt={user.Name}
                  />
                </div>
                <div className="col-8">
                  {user.Name}
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </StackLayout>
    </Dialog>);
}

export default SecurityGroupUsers;