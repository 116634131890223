import * as React from 'react';
import Moment from 'moment-timezone';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import LoadingPanel from '../LoadingPanel';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { ViewModel as CompleteData } from 'TypeGen/Order/Complete/view-model';
import { Command as CompleteRequest } from 'TypeGen/Order/Complete/command';
import CenterDivPanel from '../CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { JsonResponse } from 'TypeGen/json-response';
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { ISingleSearchLocation, singleSearch } from 'services/pcmiler';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTrack } from 'views/Track';

type Props = {
    Link: ILink;
    CloseDialog: () => void;
}

const CompleteDeadHead = ({ Link, CloseDialog}: Props) => {

    const track = useTrack();
    const [data, setData] = useState<CompleteData | null>(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [endLocationOptions, setEndLocationOptions] = useState<ISingleSearchLocation[]>([]);
    const [endLocationValue, setEndLocationValue] = useState<string>('');
    const [endLocationLoading, setEndLocationLoading] = useState(false);
    const [endDateTime, setEndDateTime] = useState<Date>(Moment().toDate());

    const complete = () => {
        setSaving(true);
        const link = data.Links.find(x => x.Name == "Complete") as ILink;
        const request = {
            EndDateTime: endDateTime,
            EndLocation: endLocationValue
        } as CompleteRequest;
        fetchApi(link.Link, request, link.Method)
            .then((response: JsonResponse) => {
                if (response.Success) {
                    track?.refresh();
                   CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('Unable to complete');
            })
            .finally(() => setSaving(false));
    }

    const renderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    const updateEndLocationsDebounced =  useDebouncedCallback((searchTerm: string) => {
        setEndLocationLoading(true);
        singleSearch(searchTerm)
            .then((response) => {
                setEndLocationLoading(false);
                setEndLocationOptions(response.Locations);
            })
            .catch(() => {
                setEndLocationLoading(false);
                setEndLocationOptions([]);
            })
    }, 350);

    useEffect(() => {
        fetchApi(Link.Link, {}, Link.Method)
            .then((data: CompleteData) => {
                setData(data);
                setLoading(false);
                setEndLocationValue(data.LastLocation);
            });
    }, [Link]);

    if (loading) {
        return <React.Fragment>
            <div className="k-overlay" style={{ zIndex: 10002, opacity: 0.5 }} />
            <LoadingPanel />
        </React.Fragment>
    }
    return (
        <Dialog
            title={`Complete DeadHead Trip - ${data.VehicleNumber} (${data.DriverNumber})`}
            onClose={CloseDialog}
            className='dialog-w800'
        >
            {saving && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <div className="row">
                <div className="col-md-6">
                    <p>
                        From {data.StartLocation}
                    </p>
                </div>
                <div className="col-md-6">
                    <p className="mb-0">
                        Scheduled {Moment.utc(data.StartDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                    </p>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-6">
                    <p>
                        To&nbsp;
                        <a href="#" onClick={(e) => { e.preventDefault(); setEndLocationValue(data.EndLocation) }}>
                            {data.EndLocation}
                        </a>
                    </p>
                </div>
                <div className="col-md-6">
                    <p className="mb-0">
                        Scheduled&nbsp;
                        <a href="#" onClick={(e) => { e.preventDefault(); setEndDateTime(Moment.utc(data.EndDateTime).toDate()) }}>
                            {Moment.utc(data.EndDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                        </a>
                    </p>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-md-3 col-form-label">End Date/Time (EST)</label>
                <div className="col-md-9">
                    <DateTimePicker
                        required
                        format="MM/dd/yyyy HH:mm"
                        formatPlaceholder="formatPattern"
                        value={endDateTime}
                        onChange={(e) => setEndDateTime(e.value)}
                        width="100%"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-md-3 col-form-label">End Location</label>
                <div className="col-md-9">
                    <AutoComplete
                        data={endLocationOptions}
                        value={endLocationValue}
                        loading={endLocationLoading}
                        placeholder={`Enter End Location`}
                        itemRender={renderLocation}
                        onChange={(event: AutoCompleteChangeEvent) => {

                        // Update Location
                        let location = event.target.value;
                        if (typeof event.target.value === 'object') {
                            const place = event.target.value as ISingleSearchLocation;
                            location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                        }

                        setEndLocationValue(location);

                        // Update Autocomplete Suggestions
                        if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                            updateEndLocationsDebounced(event.target.value);
                        }
                        }}
                    />
                </div>
            </div>

            <DialogActionsBar layout="end">
                <Button onClick={CloseDialog} disabled={saving}>Cancel</Button>
                <Button themeColor="primary" onClick={complete} disabled={saving}>Complete</Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default CompleteDeadHead;