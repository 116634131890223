import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ReactNode, useState } from 'react';

export type useConfirmProps = {
    title?: string;
    message?: ReactNode;
    labels?: {
        confirm: string;
        cancel: string;
    };
    themeColor?: "primary" | "success" | "warning" | "error" | "secondary" | "tertiary" | "info" | "dark" | "light" | "inverse" | "base";
};

const useConfirm = ({
    title = "Please confirm",
    message = "Are you sure?",
    labels = {
        confirm: "OK",
        cancel: "Cancel",
    },
    themeColor = "primary",
}: useConfirmProps) => {
    const [promise, setPromise] = useState<{
        resolve: (value: unknown) => void;
    } | null>(null);
    const [_title, setTitle] = useState(title);
    const [_message, setMessage] = useState(message);

    const confirm = (message?: string, title?: string) => new Promise((resolve) => {
        setMessage(message ?? _message);
        setTitle(title ?? _title);
        setPromise({ resolve });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };

    const ConfirmationDialog = () => (promise !== null ?
        <Dialog title={_title} onClose={handleCancel} minWidth="350px">
            <p style={{ margin: '25px', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                <span style={{ fontSize: 'larger' }}>{_message}</span>
            </p>
            <DialogActionsBar layout={'end'}>
                <Button themeColor={themeColor} onClick={handleConfirm} autoFocus={true}>
                    {labels.confirm}
                </Button>
                <Button themeColor='base' onClick={handleCancel}>
                    {labels.cancel}
                </Button>
            </DialogActionsBar>
        </Dialog> : null);

    return {
        ConfirmationDialog,
        confirm
    };
};

export default useConfirm;

//const { ConfirmationDialog, confirm } = useConfirm({
//    title: 'Are you sure?',
//    message: 'Are you sure you want to delete',
//});

//OR

//const { ConfirmationDialog, confirm } = useConfirm({});



//const handleConfirmDialog = async () => {
//    const ans = await confirm()
//    if (ans) {/* ... */ }
//    else {/* ... */ }
//}

//OR

//const handleConfirmDialog = async () => {
//    const ans = await confirm('new message','new title')
//    if (ans) {/* ... */ }
//    else {/* ... */ }
//}



//... onClick = {handleConfirmDialog}}