import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { imageEditIcon } from '@progress/kendo-svg-icons';
import Moment from 'moment-timezone';
import * as React from 'react';
import LoadingPanel from '../../../../components/LoadingPanel';
import { fetchApi } from '../../../../services/api';
import { openWindow } from '../../../../services/openWindow';
import { IDName } from '../../../../types/idname';

interface Props {
  InvoiceID?: number;
  InvoiceNumber?: string;
  CustomerID: number;
  CustomerName: string;
  CloseDialog: () => void;
}

interface State {
  isLoading: boolean;
  InvoiceEmailSettingData: InvoiceEmailSettingViewModel;
  InvoiceEmailStatusData: InvoiceEmailStatusViewModel[];
  ContactEmailAddressData: string[];
  AttachmentOption: IDName;
}

type InvoiceEmailSettingViewModel = {
  InvoiceEmailSettingID: number;
  AttachmentOption: number;
  SubjectOption: number;
}

type InvoiceEmailStatusViewModel = {
  LogDateTime: Date;
  Comments: string;
  ThreadID: string;
  Status: string;
  UserName: string;
}

export class InvoiceEmailReviewDialog extends React.Component<Props, State> {

  private attachmentOption: IDName[] = [
    { Name: 'Include All Invoices On Single Email', ID: 0 },
    { Name: 'Include One Invoice Per Email', ID: 1 }
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      InvoiceEmailSettingData: null,
      InvoiceEmailStatusData: [],
      ContactEmailAddressData: [],
      AttachmentOption: this.attachmentOption[0]
    }

    this.getInvoiceEmailReviewStatus = this.getInvoiceEmailReviewStatus.bind(this);
    this.getCustomerInvoiceEmailSettings = this.getCustomerInvoiceEmailSettings.bind(this);
    this.saveInvoiceEmailSettings = this.saveInvoiceEmailSettings.bind(this);
  }

  public componentDidMount() {
    this.getInvoiceEmailReviewStatus();
  }

  public render() {
    return (
      <Dialog title="Customer Settings &amp; Invoice Status Logs" onClose={this.props.CloseDialog} width={1024}>
        {this.state.isLoading && <LoadingPanel />}
        <div className="container">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                  <Tooltip anchorElement="target" parentTitle={true} openDelay={850}>
                    <div className="container">

                      <div className="container">
                        <div className="row">
                          <div className="col-md-12" style={{ textAlign: "left" }}>
                            <span style={{ fontSize: "17px", fontWeight: 500 }}>Email Settings for {this.props.CustomerName}</span>
                          </div>
                        </div>
                        <div className="row" style={{ height: "8px" }}>
                          <div className="col">
                            &nbsp;
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12" style={{ textAlign: "left" }}>
                            <div style={{ fontWeight: 500 }}>Attachment Options:</div>
                            <DropDownList
                              data={this.attachmentOption}
                              textField="Name"
                              dataItemKey="ID"
                              style={{ minWidth: "275px" }}
                              defaultValue={this.state.AttachmentOption}
                              value={this.state.AttachmentOption}
                              onChange={(e) => { this.setState({ AttachmentOption: e.target.value }) }}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ paddingTop: "15px" }}>
                          <div className="col-md-12" style={{ textAlign: "left" }}>
                            <div style={{ display: "flex" }}>
                              <div style={{ fontWeight: 500 }}>E-mails Set to Receive Invoices:</div>
                              <div style={{ paddingLeft: "10px" }}>
                                <a
                                  href="#"
                                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                                  onClick={(e) => {
                                    openWindow(`/Customers/Customer/${this.props.CustomerID}/Contacts`);
                                    e.preventDefault();
                                  }}
                                >
                                  <SvgIcon icon={imageEditIcon} />
                                </a>
                              </div>
                            </div>

                            {this.state.ContactEmailAddressData.length > 0 ?
                              <div className="container">
                                {this.rowBuilder()}
                              </div> :
                              <span>NONE</span>
                            }

                          </div>
                        </div>

                        <div className="row" style={{ height: "42px" }}>
                          <div className="col">
                            &nbsp;
                          </div>
                        </div>

                        {this.props.InvoiceID > 0 &&
                          <>
                            <div className="row">
                              <div className="col-md-12" style={{ textAlign: "left" }}>
                                <span style={{ fontSize: "17px", fontWeight: 500 }}>Invoice Email Status Records for {this.props.InvoiceNumber}</span>
                              </div>
                            </div>
                            <div className="row" style={{ height: "8px" }}>
                              <div className="col">
                                &nbsp;
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="row" style={{ fontWeight: 500 }}>
                                  <div className="col-md-2" style={{ textAlign: "left" }}>
                                    Log Date & Time
                                  </div>
                                  <div className="col-md-2" style={{ textAlign: "left" }}>
                                    Current Status
                                  </div>
                                  <div className="col-md-2" style={{ textAlign: "left" }}>
                                    Front Thread ID
                                  </div>
                                  <div className="col-md-2" style={{ textAlign: "left" }}>
                                    User
                                  </div>
                                  <div className="col-md-4" style={{ textAlign: "left" }}>
                                    Comments
                                  </div>
                                </div>
                                {
                                  this.state.InvoiceEmailStatusData.length ?
                                    this.state.InvoiceEmailStatusData.map((item, index) => {
                                      return (<div className="row" key={index}>
                                        <div className="col-md-2" style={{ textAlign: "left" }}>
                                          {Moment.utc(item.LogDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "left" }}>
                                          {item.Status}
                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "left" }}>
                                          {(item.ThreadID) && ((item.ThreadID.startsWith("cnv_") || item.ThreadID.startsWith("msg_"))) ? <a
                                            target="_blank"
                                            style={{ color: '#007bff' }}
                                            href={`https://app.frontapp.com/open/${item.ThreadID}`}
                                          >
                                            {item.ThreadID}
                                          </a> : item.ThreadID}
                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "left" }}>
                                          {item.UserName.length > 0 ? item.UserName : "Processor"}
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "left" }}>
                                          {item.Comments}
                                        </div>
                                      </div>)
                                    }) :
                                    <div className="row">
                                      <div className="col-md-2" style={{ textAlign: "left" }}>
                                        {Moment.utc().tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                                      </div>
                                      <div className="col-md-2" style={{ textAlign: "left" }}>
                                        Not Sent
                                      </div>
                                      <div className="col-md-2" style={{ textAlign: "left" }}>
                                        N/A
                                      </div>
                                      <div className="col-md-2" style={{ textAlign: "left" }}>
                                        N/A
                                      </div>
                                      <div className="col-md-4" style={{ textAlign: "left" }}>
                                        N/A
                                      </div>
                                    </div>
                                }
                              </div>
                            </div>
                          </>}
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar layout="end">
          <Button themeColor="primary" onClick={this.saveInvoiceEmailSettings}>Save &amp; Exit</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }
  private cellBuilder(emailAddress: string, index: number): JSX.Element {
    return <React.Fragment key={index}>
      <div style={{ paddingRight: "1em" }}>
        {emailAddress};
      </div>
    </React.Fragment>
  }

  private rowBuilder(): JSX.Element[] {
    const rows: JSX.Element[] = [];
    let cells: JSX.Element[] = [];
    let keyIndex = 0;
    this.state.ContactEmailAddressData.forEach((emailAddress, index) => {
      keyIndex = index
      if (keyIndex > 0 && keyIndex % 5 === 0) {
        rows.push(
          <div className="row" key={keyIndex}>
            {cells}
          </div>);
        cells = [];
        cells.push(this.cellBuilder(emailAddress, keyIndex));
      } else {
        cells.push(this.cellBuilder(emailAddress, keyIndex));
      }
    });
    if (cells.length > 0) {
      rows.push(
        <div className="row" key={keyIndex + 1}>
          {cells}
        </div>);
    }
    return rows;
  }

  private saveInvoiceEmailSettings() {
    this.setState({ isLoading: true });

    const data = {
      CustomerID: this.props.CustomerID,
      AttachmentOption: this.state.AttachmentOption.ID
    };

    fetchApi(`/api/Billing/SaveInvoiceEmailSettings`, data, 'POST')
      .then(() => {
        this.props.CloseDialog();
      });
  }

  private getCustomerInvoiceEmailSettings() {
    this.setState({ isLoading: true });

    const data = {
      CustomerID: this.props.CustomerID
    };

    fetchApi(`/api/Billing/GetCustomerInvoiceEmailSettings`, data, 'POST')
      .then((response: any) => {
        const invoiceEmailSetting = response.InvoiceEmailSetting as InvoiceEmailSettingViewModel;
        this.setState({
          InvoiceEmailSettingData: invoiceEmailSetting,
          ContactEmailAddressData: response.ContactEmailAddresses,
          AttachmentOption: (invoiceEmailSetting) ? this.attachmentOption.filter(x => x.ID === invoiceEmailSetting.AttachmentOption)[0] : this.attachmentOption[0],
          isLoading: false
        });
      });
  }

  private getInvoiceEmailReviewStatus() {
    this.setState({ isLoading: true });

    const data = {
      InvoiceID: this.props.InvoiceID,
      CustomerID: this.props.CustomerID
    };

    fetchApi(`/api/Billing/GetInvoiceEmailReview`, data, 'POST')
      .then((response: any) => {
        const invoiceEmailSetting = response.InvoiceEmailSetting as InvoiceEmailSettingViewModel;
        this.setState({
          InvoiceEmailSettingData: invoiceEmailSetting,
          InvoiceEmailStatusData: response.InvoiceStatuses,
          ContactEmailAddressData: response.ContactEmailAddresses,
          AttachmentOption: (invoiceEmailSetting) ? this.attachmentOption.filter(x => x.ID === invoiceEmailSetting.AttachmentOption)[0] : this.attachmentOption[0],
          isLoading: false
        });
      });
  }
}
