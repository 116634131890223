import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import Moment from 'moment-timezone';
import { CardChangeLogViewItem } from './CardChangeLogGrid';
import { EFSCardTransactionHistoryViewModel } from './CardTransactionHistoryGrid';
import { DriverCardViewItem, EFSCardTransactionHistoryLineItemViewItem } from './DriverCardMaintenance';
import { Notification } from "@progress/kendo-react-notification";
import { formatNumber } from '@progress/kendo-intl';

type Props = {
    DriverID: number;
    className?: string
    Loaded: (HasNotification: boolean) => void;
    ShowCardNoteNotification?: boolean;
}

const TotalCashRequestedNotification = (props: Props) => {
    const [totalDriverTransactionAmount, setTotalDriverTransactionAmount] = useState(0);
    const [cardNote, setCardNote] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                //let lastSunday = Moment().startOf('week');
                //let startOfDay = (Moment.utc(Moment().tz("America/New_York").startOf('day')));
                let lastMonday = Moment().startOf('isoWeek');
                let startOfDay = (Moment.utc(lastMonday.tz("America/New_York").startOf('day')));

                var driverInternallyDoneTransactionLogs = await fetchApi(`/api/Asset/GetDriverCardTransactionLog`, { DriverID: props.DriverID, StartDate: startOfDay.format("YYYY-MM-DD HH:mm:ss") }, 'POST');
                var driverEFSTransactionLogs = await fetchApi(`/api/Asset/GetEFSCardTransactionHistory`, { DriverID: props.DriverID, StartDate: startOfDay.format("YYYY-MM-DD HH:mm:ss") }, 'POST');

                let driverCardInfo: DriverCardViewItem;
                if (props.ShowCardNoteNotification) {
                    driverCardInfo = await fetchApi(`/api/Asset/getDriverCardInfo`, { DriverID: props.DriverID }, 'POST');
                    if (driverCardInfo.Note)
                        setCardNote(driverCardInfo.Note);
                }

                let totalDriverTransactionAmount = (driverInternallyDoneTransactionLogs as CardChangeLogViewItem[]).filter(x => x.TransactionAmount > 0 &&
                    x.TransactionType == 5).reduce((accumulator: number, cardChangeLog: CardChangeLogViewItem) => {
                        return accumulator + cardChangeLog.TransactionAmount;
                    }, 0);

                totalDriverTransactionAmount += ((driverEFSTransactionLogs as any).TransactionHistoryValues as EFSCardTransactionHistoryViewModel[]).flatMap(x => x.LineItems).filter(x => x.Category === 'CADV')
                    .reduce((accumulator: number, cardChangeLog: EFSCardTransactionHistoryLineItemViewItem) => {
                        return accumulator + cardChangeLog.Amount;
                    }, 0);

                setTotalDriverTransactionAmount(totalDriverTransactionAmount);

                props.Loaded(totalDriverTransactionAmount > 0 || (props.ShowCardNoteNotification && driverCardInfo.Note.length > 0));
            } catch (err: any) {
                console.log(err);
                props.Loaded(false);
            }
        }

        fetchData()
            .catch(console.error);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DriverID]);

    return <div>
        {(totalDriverTransactionAmount > 0 || cardNote) &&
            <div className={props.className}>
                {totalDriverTransactionAmount > 0 && <Notification
                    style={{ padding: "10px", minWidth: "100%" }}
                    type={{ style: "error", icon: true }}
                >
                    {totalDriverTransactionAmount > 0 && <span>WARNING! Driver has been given {formatNumber(totalDriverTransactionAmount, "c")} in combined cash advances and money codes for the current pay week.
                        Please see admin or your supervisor for approval.</span>}
                </Notification>}
                {cardNote && <Notification
                    style={{ padding: "10px", minWidth: "100%" }}
                    type={{ style: "error", icon: true }}
                >
                    {cardNote && <span>{cardNote}</span>}
                </Notification>}
            </div>}
    </div>
}

export default TotalCashRequestedNotification;

