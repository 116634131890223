import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import moment from "moment-timezone";
import * as React from 'react';
import { fetchApi } from 'services/api';
import { ApiLink } from 'TypeGen/api-link';
import DateCell from '../../components/cells/DateCell';
import { RightAlignHeaderCell } from "../../components/cells/RightAlignHeaderCell";
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDateRangePicker from "../../components/SimpleDateRangePicker";
import { Title } from '../../utils/title';
import DriverAutoComplete from "../AssetDrivers/DriverAutoComplete";
import VehicleAutoComplete from "../AssetVehicles/VehicleAutoComplete";
import { downloadIcon } from "@progress/kendo-svg-icons";

interface DriverMetricViewModel {
  VehicleNumber: string;
  VehicleID: number;
  VehicleTypeID: number;
  VehicleType: string;
  DriverNumber: string;
  DriverID: number;
  TotalPay: number;
  TurnDowns: number;
  TurnDownsWon: number;
  OfferCount: number;
  LateCount: number;
  LastPUDate: Date | null;
  LastPUDateExcelString: Date | null;
  LateCountPU: number;
  LateCountDEL: number;
  StopCount: number;
  StopCountPU: number;
  StopCountDEL: number;
  Links: ApiLink[];
  readonly TurnDownPerc: number;
  readonly OnTimePerc: number;
  readonly OnTimeScore: number;
  readonly OnTimePUPerc: number;
  readonly OnTimeDELPerc: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

const vehicleTypes = [
  { ID: 1, Name: 'Cargo Van' },
  { ID: 15, Name: 'Sprinter' },
  { ID: 3, Name: 'Large Straight' },
  { ID: 4, Name: 'Tractor' },
];

const DriverMetrics = () => {
  const _export = React.useRef<ExcelExport | null>(null);

  const [loading, setLoading] = React.useState(true);

  const [driverMetrics, setDriverMetrics] = React.useState<DriverMetricViewModel[]>([]);
  const [data, setData] = React.useState<DriverMetricViewModel[]>();

  const [sort, setSort] = React.useState<Array<SortDescriptor>>([
    { field: "DriverNumber", dir: "asc" },
  ]);
  const [filter, setFilter] = React.useState(initialFilter);

  const [fromDate, setFromDate] = React.useState(moment.tz("America/New_York").startOf('week').toDate());
  const [toDate, setToDate] = React.useState(moment.tz("America/New_York").endOf('week').startOf('day').toDate());
  const [filterByDriverId, setFilterByDriverId] = React.useState<number | undefined | null>();
  const [filterByVehicleId, setFilterByVehicleId] = React.useState<number | undefined | null>();
  const [filterByVehicleTypeId, setFilterByVehicleTypeId] = React.useState<number | undefined | null>();

  const filterData = (filterByVehicleId: number | null, filterByDriverId: number | null, filterByVehicleTypeId: number | null) => {
    let filter = {
      logic: "and",
      filters: [],
    } as CompositeFilterDescriptor;

    if (filterByVehicleId > 0)
      filter.filters.push({ field: "VehicleID", operator: "eq", value: filterByVehicleId });
    else if (filterByDriverId > 0)
      filter.filters.push({ field: "DriverID", operator: "eq", value: filterByDriverId });
    else if (filterByVehicleTypeId > 0)
      filter.filters.push({ field: "VehicleTypeID", operator: "eq", value: filterByVehicleTypeId });

    setFilter(filter);
    setData(filterBy(orderBy(driverMetrics, sort), filter));
  };

  const sortChange = (event: GridSortChangeEvent) => {
    setData(filterBy(orderBy(driverMetrics, event.sort), filter));
    setSort(event.sort);
  };

  const fetchDashboard = React.useCallback(() => {
    if (!fromDate || !toDate) return;
    setLoading(true);
    const data = {
      FromDate: fromDate,
      ToDate: toDate
    }
    fetchApi('/api/Asset/DriverMetrics', data, 'POST')
      .then((response: any) => {
        const updatedMetrics = response.DriverMetrics.map((metric: DriverMetricViewModel) => {
          if (metric.LastPUDate) {
            return {
              ...metric,
              LastPUDateExcelString: moment.utc(metric.LastPUDate).tz("America/New_York").format('MM/DD/YYYY HH:mm')
            };
          }
          return metric;
        });
        setDriverMetrics(updatedMetrics);
      })
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);

  React.useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  React.useEffect(() => {
    setData(filterBy(orderBy(driverMetrics, sort), filter));
  }, [driverMetrics, filter, sort]);

  return <React.Fragment>
    <Title string="Driver Metrics" />
    <br />
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <div className="row">
      <div className="col-auto">
        <SimpleDateRangePicker
          inputProps={{ disabled: loading }}
          value={{ start: fromDate, end: toDate }}
          onChange={(e) => {
            setFromDate(e.start);
            setToDate(e.end);
          }}
        />
      </div>
      <div className="col-auto pl-0">
        <DriverAutoComplete
          selectedDriverID={filterByDriverId}
          includeRecentlyRetiredDrivers={true}
          style={{ width: '200px' }}
          onSelectedDriver={(e) => {
            setFilterByDriverId(e?.DriverID);
            filterData(filterByVehicleId, e?.DriverID, filterByVehicleTypeId);
          }}
        />
      </div>
      <div className="col-auto pl-0">
        <VehicleAutoComplete
          selectedVehicleID={filterByVehicleId}
          includeRecentlyRetiredVehicles={true}
          style={{ width: '200px' }}
          onSelectedVehicle={(e) => {
            setFilterByVehicleId(e?.VehicleID);
            filterData(e?.VehicleID, filterByDriverId, filterByVehicleTypeId);
          }}
        />
      </div>
    </div>
    <ExcelExport ref={_export} fileName={`Driver Metrics.xlsx`}>
      <ExportColumn field="VehicleNumber" title="Vehicle Number" />
      <ExportColumn field="DriverNumber" title="Driver ID" />
      <ExportColumn field="VehicleType" title="Vehicle Type" />
      <ExportColumn field="TotalPay" title="Total Pay" cellOptions={{ format: "$#,##0.00" }} />
      <ExportColumn field="OfferCount" title="Offer Count" />
      <ExportColumn field="TurnDowns" title="Turn Downs" />
      <ExportColumn field="TurnDownPerc" title="Turn Down %" cellOptions={{ format: "0%" }} />
      <ExportColumn field="TurnDownsWon" title="Turned Down But Won By Load1" />
      <ExportColumn field="OnTimePUPerc" title="On Time PU %" cellOptions={{ format: "0%" }} />
      <ExportColumn field="OnTimeDELPerc" title="On Time DEL %" cellOptions={{ format: "0%" }} />
      <ExportColumn field="OnTimePerc" title="On Time %" cellOptions={{ format: "0%" }} />
      <ExportColumn field="OnTimeScore" title="On Time Score" />
      <ExportColumn field="LateCountPU" title="Late Count - PU" />
      <ExportColumn field="LateCountDEL" title="Late Count - DEL" />
      <ExportColumn field="StopCount" title="Stop Count" />
      <ExportColumn field="LastPUDateExcelString" title="Last PU Date" />
    </ExcelExport>
    <Grid
      data={data}
      sortable={{
        allowUnsort: true,
        mode: "multiple"
      }}
      sort={sort}
      onSortChange={sortChange}
      className="mt-2"
    >
      <GridToolbar>
        <ButtonGroup>
          {vehicleTypes.map((vehicleType) => <Button
            key={vehicleType.ID}
            togglable
            selected={filterByVehicleTypeId === vehicleType.ID}
            onClick={() => {
              const newFilter = filterByVehicleTypeId === vehicleType.ID ? null : vehicleType.ID;
              setFilterByVehicleTypeId(newFilter);
              filterData(filterByVehicleId, filterByDriverId, newFilter);
            }}
          >
            {vehicleType.Name}
          </Button>)}
        </ButtonGroup>
        <Button
          icon="download"
          svgIcon={downloadIcon}
          onClick={() => _export.current.save(data)}
        >
          Excel
        </Button>
      </GridToolbar>
      <Column field="VehicleNumber" title="Vehicle Number" />
      <Column field="DriverNumber" title="Driver ID" />
      <Column field="VehicleType" title="Vehicle Type" />
      <Column field="TotalPay" title="Total Pay" format="{0:c2}" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="OfferCount" title="Offer Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="TurnDowns" title="Turn Downs" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="TurnDownPerc" title="Turn Down %" format="{0:p0}" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="TurnDownsWon" title="Turned Down But Won By Load1" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="OnTimePUPerc" title="On Time PU %" format="{0:p0}" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="OnTimeDELPerc" title="On Time DEL %" format="{0:p0}" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="OnTimePerc" title="On Time %" format="{0:p0}" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="OnTimeScore" title="On Time Score" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="LateCountPU" title="Late Count - PU" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="LateCountDEL" title="Late Count - DEL" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="StopCount" title="Stop Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="LastPUDate" title="Last PU Date" cell={DateCell} />
    </Grid>
  </React.Fragment>;
}

export default DriverMetrics;
