import { SelectionRange } from '@progress/kendo-react-dateinputs';
import * as React from 'react';
import SimpleDatePicker from './SimpleDatePicker';
import { TextBoxProps } from '@progress/kendo-react-inputs';

type Props = {
  value: SelectionRange;
  onChange: (value: SelectionRange) => void;
  valid?: boolean;
  min?: Date;
  max?: Date;
  inputProps?: TextBoxProps;
};

const SimpleDateRangePicker: React.FC<Props> = ({
  value,
  onChange,
  valid,
  min,
  max,
  inputProps,
}) => {
  return <span className="d-flex" style={{ gap: "6px", alignItems: "center" }}>
    <SimpleDatePicker
      required
      min={min}
      inputProps={{ valid: valid, ...inputProps }}
      value={value.start}
      onChange={(e) => onChange({ ...value, start: e })}
    />
    -
    <SimpleDatePicker
      required
      max={max}
      inputProps={{ valid: valid, ...inputProps }}
      value={value.end}
      onChange={(e) => onChange({ ...value, end: e })}
    />
  </span>
};


export default SimpleDateRangePicker;
