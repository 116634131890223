import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { SetupNotifications } from "./SetupNotifications";
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";

type Props = {
  id: string | number;
  currentTab: CustomerTabs;
}

export type CustomerTabs = "Profile" | "Notes" | "Logs" | "SpecialInstructions" | "Contacts" | "Devices" |  "RateStructures" | "SalesHistory";

export const CustomerNavBar = (props: Props) => {

  const [number, setNumber] = useState("");
  const [links, setLinks] = useState<ILink[]>([]);
  const [showCustomerNotificationsDialog, setShowCustomerNotificationsDialog] = useState(false);

  const refresh = (customerId: string | number) => {
    setLinks([]);
    fetchApi(`/api/Customer/CustomerNavBar/${customerId}`)
      .then((data: { Number: string, Links: ILink[] }) => {
        setNumber(data.Number);
        setLinks(data.Links);
      });
  }

  const reactivate = () => {
    if (!window.confirm("Are you sure you want to reactivate this customer?")) {
      return;
    }

    fetchApi(`/api/Customer/ReactivateCustomer/${props.id}`, {}, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          refresh(props.id);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to reactivate");
      });
  }

  const retire = () => {
    if (!window.confirm('Retire Customer: Are you sure?')) {
      return;
    }

    fetchApi(`/api/Customer/RetireCustomer/${props.id}`, {}, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          refresh(props.id);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to retire");
      });
  }

  useEffect(() => {
    refresh(props.id);
  }, [props.id]);

  return <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
      <span className="navbar-brand">
        <Link to="/Customers">Customers</Link>
        <span className="text-muted">
          <SvgIcon icon={chevronRightIcon} />
          {number}
        </span>
      </span>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#customerNav" aria-controls="customerNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="customerNav">
        <ul className="navbar-nav">
          {links.find((x: ILink) => x.Name === 'Customer') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}`}>Profile</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Contacts') && <li className={`nav-item ${props.currentTab === 'Contacts' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/Contacts`}>Contacts</Link>
          </li>}
          {links.length > 0 && <li className={`nav-item`}>
            <Link className="nav-link" to="#" onClick={() => { setShowCustomerNotificationsDialog(!showCustomerNotificationsDialog) }}>Contact Notifications</Link>
            {showCustomerNotificationsDialog && <SetupNotifications CustomerID={Number(props.id)} CloseDialog={() => setShowCustomerNotificationsDialog(false)} />}
          </li>}
          {<li className={`nav-item ${props.currentTab === 'SpecialInstructions' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/SpecialInstructions`}>Special Instructions</Link>
          </li>}
          {<li className={`nav-item ${props.currentTab === 'RateStructures' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/RateStructures`}>Rate Structures</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'SalesHistory') && <li className={`nav-item ${props.currentTab === 'SalesHistory' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/SalesHistory`}>Sales History</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/Notes`}>Notes</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Devices') && <li className={`nav-item ${props.currentTab === 'Devices' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/Devices`}>Logins</Link>
          </li>}
          {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
            <Link className="nav-link" to={`/Customers/Customer/${props.id}/Logs`}>Logs</Link>
          </li>}
        </ul>
        <ul className="nav navbar-nav ml-auto">
          {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => retire()} themeColor="primary">Retire</Button>}
          {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Reactivate</Button>}
        </ul>
      </div>
    </nav>
  </>;
}