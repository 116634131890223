import { getter } from "@progress/kendo-data-query";
import { Button, DropDownButton, DropDownButtonItem, Toolbar } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { getSelectedState, Grid, GridCellProps, GridColumn as Column, GridHeaderSelectionChangeEvent, GridItemChangeEvent, GridNoRecords, GridSelectionChangeEvent } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from '@progress/kendo-react-labels';
import { Upload as KendoUpload, UploadFileInfo } from "@progress/kendo-react-upload";
import DateCell from "components/cells/DateCell";
import LoadingPanel from "components/LoadingPanel";
import useConfirm from "components/useConfirm";
import Moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi, fetchApiFormData } from "services/api";
import { openWindowPerc } from "services/openWindow";
import { OrderImage } from "TypeGen/Order/Images/order-image";
import { ViewModel } from "TypeGen/Order/Images/view-model";
import { OrderContext } from "../Details";
import { CheckInPaperwork } from "./CheckInPaperwork";
import { EmailPaperwork } from "./EmailPaperwork";
import { SvgIcon } from "@progress/kendo-react-common";
import { caretAltDownIcon, downloadIcon } from "@progress/kendo-svg-icons";

type RouteComponentParams = {
  orderId: string;
};

const DATA_ITEM_KEY: string = "ImageID";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ImageDateCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  let dataItem: OrderImage = props.dataItem;

  //don't convert to local EST, already in EST
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ImageDate).format("MM/DD/YYYY")}</span>
    </td>
  );
};

const OrderImages = () => {
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<ViewModel>();
  const [showCheckInPaperwork, setShowCheckInPaperwork] = useState(false);
  const [showEmailSelected, setShowEmailSelected] = useState(false);

  // Selection
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const selectedIds = Object.entries(selectedState).filter(([, value]) => value === true).map(([key]) => parseInt(key));

  // Upload
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [paperworkType, setPaperworkType] = useState(0);
  const [printWithInvoice, setPrintWithInvoice] = useState<boolean | null>(null);
  const [uploading, setUploading] = useState(false);

  const fetchDetails = useCallback(async () => {
    setLoading(true);
    const response = await fetchApi(`/api/Order/Images/${orderIdParam}`);
    response.Images = response.Images.map((item: OrderImage) => ({ ...item, inEdit: true, Edited: false }))
    setDetails(response);
    setLoading(false);
  }, [orderIdParam]);

  useEffect(() => {
    ((document.getElementsByClassName("k-upload-button")[0] as HTMLElement).getElementsByTagName("span")[0] as HTMLElement).innerText = "Select file...";
    ((document.getElementsByClassName("k-dropzone")[0] as HTMLElement).getElementsByClassName("k-dropzone-hint")[0] as HTMLElement).innerText = "Drop file here to upload";

    setActiveStopIds([]);
    setDeleteStopIds([]);
    fetchDetails();
  }, [fetchDetails, setActiveStopIds, setDeleteStopIds]);

  const handleUpload = async () => {

    if (files.length == 0) {
      alert("Please select a file to upload.");
      return;
    }

    if (paperworkType == 0) {
      alert("Please select a paperwork type.");
      return;
    }

    setUploading(true);

    var formData = new FormData();
    formData.append("File", files[0].getRawFile());
    formData.append('FileNameExtension', files[0].extension);
    formData.append('PrintWithInvoice', printWithInvoice ? 'true' : 'false');
    formData.append('PaperworkType', paperworkType.toString());

    try {
      await fetchApiFormData(`/api/Image/Upload/${orderIdParam}`, formData, 'POST');

      setFiles([]);
    } catch (e) {
      alert(e);
      return;
    } finally {
      setUploading(false);
    }
    fetchDetails();
  }

  const handleCheckInPaperwork = async (bolIn: boolean, podIn: boolean, date: Date) => {
    setUploading(true);

    try {
      await fetchApi(`/api/Order/CheckInPaperwork/${orderIdParam}`, { BOLIn: bolIn, PODIn: podIn, Date: date }, 'POST');
    } catch (e) {
      alert(e);
      return;
    } finally {
      setUploading(false);
    }

    setShowCheckInPaperwork(false);
    fetchDetails();
  }

  const itemChange = (e: GridItemChangeEvent) => {
    const dataItem = e.dataItem as OrderImage;
    const newData = details?.Images.map((item) =>
      item.ImageID === dataItem.ImageID
        ? { ...item, Edited: true, [e.field || ""]: e.value }
        : item
    );

    setDetails({ ...details, Images: newData });
  }

  const updateItem = async (dataItem: OrderImage) => {
    const data = {
      TypeOfPW: dataItem.TypeOfPW,
      PrintFlag: dataItem.PrintFlag,
    };
    await fetchApi(`/api/Image/Update/${dataItem.ImageID}`, data, 'PUT');

    fetchDetails();
  }

  const deleteSelected = async () => {

    if (await confirm(`Are you sure you want to delete ${selectedIds.length} ${selectedIds.length === 1 ? 'image' : 'images'}?`) == false) {
      return;
    }

    setUploading(true);

    try {
      for (let i = 0; i < selectedIds.length; i++) {
        await fetchApi(`/api/Image/Delete/${selectedIds[i]}`, {}, 'DELETE');
      }
      setSelectedState({});
    } catch (e: any) {
      // If not problem details
      if (!e?.status) alert(e);
      return;
    } finally {
      setUploading(false);
    }

    fetchDetails();
  }

  const updateSelected = async (data: { TypeOfPW?: number, PrintFlag?: boolean }) => {

    if (await confirm(`Are you sure you want to update ${selectedIds.length} ${selectedIds.length === 1 ? 'image' : 'images'}?`) == false) {
      return;
    }

    setUploading(true);

    try {
      for (let i = 0; i < selectedIds.length; i++) {
        await fetchApi(`/api/Image/Update/${selectedIds[i]}`, data, 'PUT');
      }
      setSelectedState({});
    } catch (e: any) {
      // If not problem details
      if (!e?.status) alert(e);
      return;
    } finally {
      setUploading(false);
    }

    fetchDetails();
  }

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      if (event.startColIndex === 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {} as any;

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  return (
    <div className='py-2'>
      {showCheckInPaperwork && <CheckInPaperwork
        loading={uploading}
        save={handleCheckInPaperwork}
        onClose={() => setShowCheckInPaperwork(false)}
      />}
      {showEmailSelected && <EmailPaperwork
        orderId={orderIdParam}
        selectedIds={selectedIds}
        onClose={() => setShowEmailSelected(false)}
      />}
      {loading && <LoadingPanel />}
      <ConfirmationDialog />
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <KendoUpload
            batch={false}
            multiple={false}
            files={files}
            autoUpload={false}
            disabled={uploading}
            restrictions={{
              allowedExtensions: ['.jpeg', '.jpg', '.png', '.gif', '.pdf']
            }}
            onAdd={(e) => setFiles(e.newState)}
            onRemove={(e) => setFiles(e.newState)}
            showActionButtons={false}
          />
          <p className="p-1 mb-2">File types supported: .jpeg, .jpg, .png, .gif, .pdf</p>
          <FieldWrapper className="mb-3">
            <Label>Paperwork Type</Label>
            <DropDownList
              disabled={uploading}
              data={details?.PaperworkTypes}
              value={details?.PaperworkTypes.find(x => x.ID == paperworkType)}
              onChange={(e) => {
                const newPaperworkTypeId = e.target.value.ID
                setPaperworkType(newPaperworkTypeId);
                setPrintWithInvoice(newPaperworkTypeId == 1 || newPaperworkTypeId == 2 || newPaperworkTypeId == 3 || newPaperworkTypeId == 6);
              }}
              defaultItem={{ Name: "Select a Paperwork Type" }}
              valid={paperworkType > 0}
              textField="Name"
              dataItemKey="ID"
            />
          </FieldWrapper>
          <Checkbox
            disabled={uploading}
            value={printWithInvoice}
            onChange={(e) => setPrintWithInvoice(e.value)}
            className="mr-1 float-right"
            label="Print w/ Invoice"
          />
          <Button
            themeColor="primary"
            className="mb-3 center-block"
            disabled={uploading}
            onClick={handleUpload}
          >
            Upload Image
          </Button>
          {details?.Links.find(x => x.Name === "CheckInPaperwork") && files.length == 0 && <Button
            className="mb-3 ml-2 center-block"
            disabled={uploading}
            onClick={() => setShowCheckInPaperwork(true)}
          >
            Check In Paperwork
          </Button>}
        </div>
      </div>
      {selectedIds.length > 0 && <Toolbar>
        <Button onClick={() => setSelectedState({})}>Deselect</Button>
        <DropDownButton
          themeColor="primary"
          text="Print w/ Invoice"
          icon="arrow-60-down"
          svgIcon={caretAltDownIcon}
          onItemClick={(e) => updateSelected({ PrintFlag: e.itemIndex === 0 ? true : false })}
        >
          <DropDownButtonItem text="Print" />
          <DropDownButtonItem text="Do Not Print" />
        </DropDownButton>
        <DropDownButton
          themeColor="primary"
          text="Document Type"
          icon="arrow-60-down"
          svgIcon={caretAltDownIcon}
          items={details?.PaperworkTypes}
          textField="Name"
          onItemClick={(e) => updateSelected({ TypeOfPW: e.item.ID })}
        />
        <Button themeColor="primary" onClick={() => setShowEmailSelected(true)}>E-Mail</Button>
        <Button themeColor="error" onClick={deleteSelected}>Delete {selectedIds.length} {selectedIds.length === 1 ? 'Image' : 'Images'}</Button>
      </Toolbar>}
      <Grid
        data={details?.Images.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        }))}
        onItemChange={itemChange}
        editField="inEdit"
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridNoRecords>
          No images found.
        </GridNoRecords>
        <Column
          field={SELECTED_FIELD}
          width="50px"
          headerSelectionValue={
            details?.Images.findIndex((item) => !selectedState[idGetter(item)]) === -1
          }
        />
        <Column field="ImageID" title="Filename" editable={false} cell={(props) => (
          <td>
            <a
              style={{ color: '#007bff' }}
              href="#"
              onClick={(e) => {
                openWindowPerc(`/Image/View/${props.dataItem.ImageID}`, .9, .85, 'ClientApp-Image');
                e.preventDefault();
              }}
            >
              {props.dataItem.ImageID}-{props.dataItem.PageNum}
            </a>
            <a className="ml-2" href={`/Image/Download/${props.dataItem.ImageID}`}>
              <SvgIcon icon={downloadIcon} />
            </a>
          </td>
        )} />
        <Column field="TypeOfPW" title="Document Type" width={222} cell={(props) => (
          <td>
            <DropDownList
              data={details?.PaperworkTypes}
              value={details?.PaperworkTypes.find(x => x.ID == props.dataItem.TypeOfPW)}
              onChange={(e) => {
                props.onChange({
                  dataItem: props.dataItem,
                  dataIndex: props.dataIndex,
                  syntheticEvent: e.syntheticEvent,
                  field: props.field,
                  value: e.target.value.ID
                });
              }}
              textField="Name"
              dataItemKey="ID"
            />
          </td>
        )} />
        <Column field="PrintFlag" title="Print w/ Invoice" editable={true} editor="boolean" />
        <Column field="ImageDate" title="Indexed" editable={false} cell={ImageDateCell} />
        <Column field="ImageSourceName" title="Source" editable={false} />
        <Column field="ModifiedByUserNumber" title="Modified By" editable={false} />
        <Column field="ModifiedDateTime" title="Modified Date" editable={false} cell={DateCell} />
        <Column width={85} cell={(props) => (
          <td className="k-command-cell">
            <Button
              disabled={!props.dataItem.Edited}
              themeColor="primary"
              className="k-grid-edit-command"
              onClick={(e) => updateItem(props.dataItem)}
            >
              Update
            </Button>
          </td>
        )} />
      </Grid>
      {selectedIds.length} {selectedIds.length === 1 ? 'image' : 'images'} selected
      <div className="mt-2">
        <Button
          themeColor="secondary"
          disabled={uploading}
          onClick={(e) => {
            openWindowPerc(`/Image/${orderIdParam}/ImageViewer`, .9, .9, 'ClientApp-ImageViewer');
            e.preventDefault();
          }}
        >
          Open Image Viewer
        </Button>
      </div>
    </div >
  );
};

export default OrderImages;