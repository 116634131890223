import { createPortal } from 'react-dom';
import usePortal from '../utils/usePortal';

const BodyPortal = ({ id, children }: { id?: string, children: React.ReactNode }) => {
    const target = usePortal(id);
    return createPortal(
      children,
      target,
    );
};

export default BodyPortal;