import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';

export type PurchaseOrderVendorViewModel = {
  PurchaseOrderVendorID: number;
  VendorID: string;
  VendorName: string;
  AddressLine1: string;
  City: string;
  State: string;
  ZipCode: string;
  Active: boolean;
  Hash: string;
  Links: ILink[];
}

type DisplayPurchaseOrderVendorViewModel = {
  VendorID: string;
  VendorName: string;
  City: string;
  State: string;
  ZipCode: string;
  Active?: boolean;
}

type Props = {
  onSelectedPurchaseOrderVendor: (customer: PurchaseOrderVendorViewModel) => void;
  selectedPurchaseOrderVendorID?: number;
  setFocus?: boolean;
  includeRecentlyRetiredPurchaseOrderVendors?: boolean;
} & AutoCompleteProps

export type PurchaseOrderVendorAutoCompleteViewModel = PurchaseOrderVendorViewModel & { DisplayValue: string; };

export const GetPurchaseOrderVendorAutoCompleteDisplayValue = (customer: DisplayPurchaseOrderVendorViewModel, includeActiveLabel?: boolean): string => {
  const displayValue = `${customer.VendorID} - ${customer.VendorName} ${customer.City}, ${customer.State} ${customer.ZipCode}`.toUpperCase();
  if (includeActiveLabel) return `${displayValue} - ${customer.Active ? 'Active' : 'Retired'}`;
  return displayValue;
}

const PurchaseOrderVendorAutoComplete: React.FC<Props> = ({
  onSelectedPurchaseOrderVendor,
  selectedPurchaseOrderVendorID,
  includeRecentlyRetiredPurchaseOrderVendors,
  setFocus,
  ...autoCompleteProps
}) => {
  const inputAC = useRef<AutoComplete>(null);
  const [loading, setLoading] = useState(false);
  const [customerDropDownListing, setPurchaseOrderVendorDropDownListing] = useState<PurchaseOrderVendorAutoCompleteViewModel[]>([]);
  const [customerAutoCompleteValue, setPurchaseOrderVendorAutoCompleteValue] = useState('');
  const _selectedPurchaseOrderVendor = useRef(0);
  const _requestHash = useRef('');
  //const [preSelectedPurchaseOrderVendorInitialized, setPreSelectedPurchaseOrderVendorInitialized] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((val: string, requestHash: string) => {
      if (val.length > 2 && _selectedPurchaseOrderVendor.current === 0) {
        getPurchaseOrderVendorListing(generateDataStateByName(val), requestHash);
      }
    }, 750), []);

  const generateDataStateByName = (val: string): GridState => {
    let gs = {
      skip: 0,
      take: 12,
      sort: [{
        field: "VendorName",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          {
            logic: 'or', filters: [
              { field: 'VendorID', operator: 'contains', value: val },
              { field: 'VendorName', operator: 'contains', value: val }
            ] as any
          }
        ] as any
      }
    } as GridState;

    if (includeRecentlyRetiredPurchaseOrderVendors) {
      gs.filter.filters.unshift({
        logic: 'or', filters: [
          { field: 'active', operator: 'eq', value: true },
          {
            logic: 'and', filters: [
              { field: 'active', operator: 'eq', value: false },
              { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(4, "weeks").toDate() }
            ]
          }
        ]
      });
    } else {
      gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
    }

    return gs;
  }

  const generateDataStateByID = (val: number): GridState => {
    return {
      skip: 0,
      take: 1,
      sort: null,
      filter: {
        logic: 'and', filters: [
          { field: 'PurchaseOrderVendorID', operator: 'eq', value: val }
        ] as any
      }
    } as GridState;
  }

  const getPurchaseOrderVendorListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
    setLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/PurchaseOrder/GetVendors?${queryStr}`, {}, 'POST')
      .then(({ Data }) => {
        let customerData: PurchaseOrderVendorAutoCompleteViewModel[] = Data;
        setLoading(false);
        if (_requestHash.current === requestHash) {
          customerData.forEach(x => {
            x.DisplayValue = GetPurchaseOrderVendorAutoCompleteDisplayValue(x, includeRecentlyRetiredPurchaseOrderVendors);
          });
          setPurchaseOrderVendorDropDownListing(customerData);

          if (populateLabel) {
            setPurchaseOrderVendorAutoCompleteValue(`${customerData[0].VendorID} - ${customerData[0].VendorName}`);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
  }, [includeRecentlyRetiredPurchaseOrderVendors]);

  const onPurchaseOrderVendorListingClose = (event: AutoCompleteCloseEvent) => {
    const value = event.target.value;
    const selectedPurchaseOrderVendor = customerDropDownListing.find(x => x.DisplayValue === value);
    setPurchaseOrderVendorAutoCompleteValue(selectedPurchaseOrderVendor != null ? `${selectedPurchaseOrderVendor.VendorID} - ${selectedPurchaseOrderVendor.VendorName}` : '');
    _selectedPurchaseOrderVendor.current = selectedPurchaseOrderVendor?.PurchaseOrderVendorID;
    onSelectedPurchaseOrderVendor(selectedPurchaseOrderVendor ?? null);
  };

  useEffect(() => {
    if (selectedPurchaseOrderVendorID !== _selectedPurchaseOrderVendor.current) {
      if (selectedPurchaseOrderVendorID > 0) {
        _selectedPurchaseOrderVendor.current = selectedPurchaseOrderVendorID;
        _requestHash.current = Math.random().toString(36).substring(7);
        getPurchaseOrderVendorListing(generateDataStateByID(selectedPurchaseOrderVendorID), _requestHash.current, true);
      } else {
        _selectedPurchaseOrderVendor.current = 0;
        setPurchaseOrderVendorAutoCompleteValue('');
      }
      if (setFocus) {
        inputAC.current.focus();
      }
    }
  }, [selectedPurchaseOrderVendorID, getPurchaseOrderVendorListing, setFocus]);

  //useEffect(() => {
  //    if (preSelectedPurchaseOrderVendorInitialized) {
  //        inputAC.current.focus();
  //        (inputAC.current.element.querySelector('input') as HTMLInputElement).select();
  //    }
  //}, [preSelectedPurchaseOrderVendorInitialized])

  return <AutoComplete
    ref={inputAC}
    {...autoCompleteProps}
    placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Search Vendor')}
    loading={loading}
    data={customerDropDownListing}
    value={customerAutoCompleteValue}
    textField="DisplayValue"
    onChange={(e) => {
      _selectedPurchaseOrderVendor.current = 0;
      if (!e.value) onSelectedPurchaseOrderVendor(null);
      setPurchaseOrderVendorDropDownListing([]);
      setPurchaseOrderVendorAutoCompleteValue(e.value);
      _requestHash.current = Math.random().toString(36).substring(7);
      debounceSearchInput(e.value, _requestHash.current);
    }}
    onClose={(e) => onPurchaseOrderVendorListingClose(e)}
  />
}

export default PurchaseOrderVendorAutoComplete;
