import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { fetchApi } from '../../services/api';

interface Props {
  quoteId: number;
  onCreated: (data: { OrderID: number, OrderNumber: number, OrderStatus: number }) => void;
}

interface State {
  OrderStatus: number;
  RevenueType1: number;
  ServiceTeam: number;
  Reference1: string;
  Reference2: string;
  Reference3: string;

  visible: boolean;
  loading: boolean;
}

export class CreateOrder extends React.Component<Props, State> {

  private revenueAllocation = [
    { value: 0, text: 'Rev. Allocation' },
    { value: 1, text: 'TRUCKLOAD-01' },
    { value: 2, text: 'DEDICATED-03' },
    { value: 3, text: 'EXPEDITE-04' },
    { value: 4, text: 'BROKERAGE-02' },
    { value: 5, text: 'SPECIALIZED-05' },
    //{ value: 6, text: 'TL BROKERAGE-06' },
    { value: 7, text: 'CROSSDOCK/WH-08' },
  ];

  private serviceTeams = [
    { value: 0, text: 'Service Team' },
    { value: 1, text: '1-TRUCKLOAD' },
    { value: 2, text: '2-BROKERAGE' },
    { value: 3, text: '3-DEDICATED' },
    { value: 4, text: '4-EXPEDITE' },
    //{ value: 5, text: '5-TL BROKERAGE' },
    { value: 6, text: '6-SPECIALIZED' },
    { value: 7, text: '7-NOT COMMITTED' },
    { value: 8, text: '8-CROSSDOCK/WH' },
  ];

  private orderStatus = [
    { value: 100, text: 'Save As: Available' },
    { value: 120, text: 'Save As: Cross Dock Invoice' },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      OrderStatus: 100,
      RevenueType1: 0,
      ServiceTeam: 0,
      Reference1: ' ',
      Reference2: ' ',
      Reference3: ' ',

      visible: false,
      loading: true,
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.createOrder = this.createOrder.bind(this);
  }

  toggleDialog(e: any) {
    e.preventDefault();

    this.setState({
      visible: !this.state.visible
    }, () => {
      if (this.state.visible) {
        fetchApi(`/api/Quote/CreateOrder/${this.props.quoteId}`)
        .then((data) => {
          this.setState({
            OrderStatus: data.DefaultOrderStatus,
            RevenueType1: data.DefaultRevenueType1,
            ServiceTeam: data.DefaultServiceTeamID,
            Reference1: data.Reference1,
            Reference2: data.Reference2,
            Reference3: data.Reference3,
            loading: false,
          });
        });
      }
    });
  }

  closeDialog() {
    this.setState({
      visible: false
    });
  }

  render() {
    return (
      <>
        <Button themeColor="primary" onClick={this.toggleDialog}>Create Pro</Button>
        {this.state.visible && <Dialog title="Create Pro" onClose={this.closeDialog} className='dialog-w800'>
          <Input
            required
            style={{ width: '33%' }}
            label="Reference 1"
            value={this.state.Reference1}
            onChange={(e) => this.setState({ Reference1: e.target.value.toString() })}
          />
          <Input
            required
            style={{ width: '33%' }}
            label="Reference 2 / Thread"
            value={this.state.Reference2}
            onChange={(e) => this.setState({ Reference2: e.target.value.toString() })}
          />
          <Input
            required
            style={{ width: '33%' }}
            label="Reference 3 / Station"
            value={this.state.Reference3}
            onChange={(e) => this.setState({ Reference3: e.target.value.toString() })}
          />
          <br /><br />
          <DropDownList
            required
            valid={this.state.RevenueType1 > 0}
            style={{ width: '100%' }}
            value={this.revenueAllocation.find(x => x.value == this.state.RevenueType1)}
            data={this.revenueAllocation}
            onChange={(e) => this.setState({ RevenueType1: e.target.value.value })}
            textField="text"
          />
          <br /><br />
          <DropDownList
            required
            valid={this.state.ServiceTeam > 0}
            style={{ width: '100%' }}
            value={this.serviceTeams.find(x => x.value == this.state.ServiceTeam)}
            data={this.serviceTeams}
            onChange={(e) => this.setState({ ServiceTeam: e.target.value.value })}
            textField="text"
          />
          <br /><br />
          <DropDownList
            style={{ width: '100%' }}
            value={this.orderStatus.find(x => x.value == this.state.OrderStatus)}
            data={this.orderStatus}
            onChange={(e) => this.setState({ OrderStatus: e.target.value.value })}
            textField="text"
          />
          <br /><br />
          <DialogActionsBar>
            <Button themeColor="primary" disabled={this.state.loading} onClick={this.createOrder}>Create Pro</Button>
          </DialogActionsBar>
        </Dialog>}
      </>
    );
  }

  private createOrder(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    this.setState({ loading: true });

    const data = {
      QuoteID: this.props.quoteId,
      OrderStatus: this.state.OrderStatus,
      ServiceTeam: this.state.ServiceTeam,
      RevenueType1: this.state.RevenueType1,
      Reference1: this.state.Reference1,
      Reference2: this.state.Reference2,
      Reference3: this.state.Reference3,
    }
    fetchApi('/api/Quote/CreateOrder', data, 'POST')
      .then((response: { OrderID: number, OrderNumber: number, ErrorMessage: string }) => {
        if (response.ErrorMessage) {
          alert(response.ErrorMessage);
        } else {
          this.props.onCreated({ OrderID: response.OrderID, OrderNumber: response.OrderNumber, OrderStatus: this.state.OrderStatus });
          this.setState({
            visible: !this.state.visible
          });
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unable to create order!")
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
}
