import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { AllianceVehicleValue } from '../Vehicles';
import { CarrierTierValue } from 'TypeGen/carrier-tier-value';
import { SvgIcon } from "@progress/kendo-react-common";
import { starIcon, starOutlineIcon } from "@progress/kendo-svg-icons";

export default function allianceCarrierRating(openReviews: (data: AllianceVehicleValue) => void) {
    return class extends React.Component<GridCellProps> {
        render() {
          if (!this.props.field) return null;

          return <td onClick={() => openReviews(this.props.dataItem)}>
            {this.props.dataItem.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
              : this.props.dataItem.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
              : this.props.dataItem.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" />
              : <SvgIcon icon={starOutlineIcon} color="#9B9B9B" />}
          </td>
        }

        public shouldComponentUpdate(nextProps: GridCellProps) {
          return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
        }
    }
  }
