import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ProblemDetails } from "store/ProblemDetails";
import { version } from '../../version';

type Props = {
  problem: ProblemDetails;
  onClose: () => void;
}

const ProblemDialog = ({ problem, onClose }: Props) => {
  return <Dialog title={problem.title} onClose={onClose} className="dialog-w800">
    <div>
      {problem.type === "activedirectoryclaimexception"
        ? <p>Insufficient access. Click <a href={`mailto:app@load1.com
                        ?subject=${encodeURIComponent('AD Access Request: ' + problem.detail)}
                        &body=${encodeURIComponent('Please add me to the following AD security group: ' + problem.detail + '.')}`}>here</a> to
          contact app@load1.com to request access to {problem.detail}.</p>
        : <p>{problem.detail}</p>}
      {problem.errors && <pre>{JSON.stringify(problem.errors, null, 2)}</pre>}
      <small className="text-muted">
        {problem.instance && problem.instance + ' '}v{version}-http{problem.status}
      </small>
    </div>
    <DialogActionsBar>
      <Button onClick={onClose}>Okay</Button>
    </DialogActionsBar>
  </Dialog>;
}

export default ProblemDialog;