import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { DriverNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import Moment, { MomentInput } from 'moment-timezone';
import leftPad from "left-pad";
import { formatNumber } from '@progress/kendo-intl';
import { ViewModel } from 'TypeGen/Driver/HoursOfService/view-model';
import { OmnitracsDriverNumberViewModel } from 'TypeGen/Driver/HoursOfService/omnitracs-driver-number-view-model';
import { SamsaraDriverNumberViewModel } from "TypeGen/Driver/HoursOfService/samsara-driver-number-view-model";
import { CurrentHoursOfService } from 'TypeGen/Driver/HoursOfService/current-hours-of-service';
import { ILink } from "types/link";
import { Button } from "@progress/kendo-react-buttons";
import useConfirm from "components/useConfirm";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import React from "react";
import { SamsaraAccount } from "TypeGen/Assets/samsara-account";

type RouteComponentParams = {
  driverId: string;
};

const DriverDevices = () => {

  const { driverId: driverIdParam } = useParams<RouteComponentParams>();
  const { ConfirmationDialog, confirm } = useConfirm({ labels: { confirm: "Unassign", cancel: "Cancel" }, themeColor: "error" });
  const [loading, setLoading] = useState(true);
  const [omnitracsDriverNumbers, setOmnitracsDriverNumbers] = useState<OmnitracsDriverNumberViewModel[]>([]);
  const [omnitracsDriverNumbersFilter, setOmnitracsDriverNumbersFilter] = useState<FilterDescriptor>(null);
  const [omnitracsDriverNumber, setOmnitracsDriverNumber] = useState('');
  const [samsaraDriversNumbers, setSamsaraDriversNumbers] = useState<SamsaraDriverNumberViewModel[]>([]);
  const [samsaraDriversNumbersFilter, setSamsaraDriversNumbersFilter] = useState<FilterDescriptor>(null);
  const [samsaraAccount, setSamsaraAccount] = useState(SamsaraAccount.TriState);
  const [samsaraDriverNumber, setSamsaraDriverNumber] = useState('');
  const [hos, setHos] = useState<CurrentHoursOfService>();
  const [links, setLinks] = useState<ILink[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/HoursOfService/${driverIdParam}`)
      .then((response: ViewModel) => {
        setLoading(false);
        setOmnitracsDriverNumbers(response.OmnitracsDriverNumbers);
        setSamsaraDriversNumbers(response.SamsaraDriverNumbers);
        setHos(response.HoursOfService);
        setLinks(response.Links);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [driverIdParam]);

  useEffect(() => {
    refresh();
  }, [driverIdParam, refresh]);

  const handleRemove = async () => {
    if (!await confirm("Are you sure you want to unassign this device?", "Unassign Device")) return;
    setLoading(true);

    const link = links.find(x => x.Name === "Remove");
    fetchApi(link.Link, {}, link.Method)
      .then(() => {
        setLoading(false);
        refresh();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to unassign device');
      });
  }

  const handleAssigned = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);

    const data = { OmnitracsDriverNumber: omnitracsDriverNumber, SamsaraAccount: samsaraAccount, SamsaraDriverId: samsaraDriverNumber };
    const link = links.find(x => x.Name === "Assign");
    fetchApi(link.Link, data, link.Method)
      .then(() => {
        setLoading(false);
        refresh();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to assign device');
      });
  }

  const formatHours = (minutesLeft: number) => {
    if (minutesLeft === 0) return "(00:00)";
    const negative = minutesLeft < 0;
    const duration = Moment.duration(minutesLeft, 'minutes');
    const hours = negative ? Math.ceil(duration.asHours()) : Math.floor(duration.asHours());
    const minutesFormatted = '(' + (negative ? '-' : '') + leftPad(Math.abs(hours), 2, '0') + ':' + leftPad(Math.abs(duration.minutes()), 2, '0') + ')';
    return `${minutesFormatted} (${minutesLeft} min(s). ${formatNumber(duration.asHours(), 'n2')} hr(s))`;
  }

  const formatDate = (date: MomentInput) => {
    return Moment.utc(date).tz("America/New_York").format("MM/DD/YYYY HH:mm:ss [EST]")
  }

  const formatLabelData = (label: string, data: string, negative: boolean = false) => {
    return <>
      <span className="text-muted">{label}</span>
      <p className={`lead ${negative ? 'text-danger' : ''}`}>{data}</p>
    </>;
  }

  const loadingView = <div className="text-center">
    <Loader type="converging-spinner" />
  </div>;

  const emptyView = <div className="text-center">
    <p className="lead">No HOS device assigned currently.</p>
  </div>;

  const itemRenderOmnitracsDriverNumber = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span style={{ fontSize: ".75rem" }}>
        {itemProps.dataItem.OmnitracsDriverNumber} - {itemProps.dataItem.ExistingDriverNumber || 'Not Assigned'}
    </span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const itemRenderSamsaraDriverNumber = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span style={{ fontSize: ".75rem" }}>
        {itemProps.dataItem.Name} - {itemProps.dataItem.ExistingDriverNumber || 'Not Assigned'}
    </span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const assignView = () => {
    const existingOmnitracsDriver = omnitracsDriverNumbers.find(x => x.OmnitracsDriverNumber === omnitracsDriverNumber);
    return <div>
      <p className="lead">Assign HOS Device</p>
      {existingOmnitracsDriver?.ExistingDriverNumber && (
        <div className="alert alert-danger" role="alert">
          This device is already assigned to {existingOmnitracsDriver.ExistingDriverNumber}, assigning will override.
        </div>
      )}
      <div className="input-group flex-nowrap">
        <DropDownList
          defaultValue={{ OmnitracsDriverNumber: 'Omnitracs Drivers' }}
          filterable={true}
          onFilterChange={(e) => setOmnitracsDriverNumbersFilter(e.filter)}
          className="k-dropdown-wrap-append flex-fill"
          data={filterBy(omnitracsDriverNumbers, omnitracsDriverNumbersFilter)}
          value={omnitracsDriverNumbers.find(x => x.OmnitracsDriverNumber == omnitracsDriverNumber)}
          textField="OmnitracsDriverNumber"
          itemRender={itemRenderOmnitracsDriverNumber}
          onChange={(e) => {
            setSamsaraAccount(SamsaraAccount.TriState);
            setSamsaraDriverNumber('');
            setOmnitracsDriverNumber(e.target.value.OmnitracsDriverNumber);
          }}
        />
        <div className="input-group-append">
          <button className="btn btn-outline-primary" onClick={handleAssigned}>Assign</button>
        </div>
      </div>
      <div className="input-group flex-nowrap mt-3">
        <DropDownList
          defaultValue={{ Name: 'Samsara Drivers' }}
          filterable={true}
          onFilterChange={(e) => setSamsaraDriversNumbersFilter(e.filter)}
          className="k-dropdown-wrap-append flex-fill"
          data={filterBy(samsaraDriversNumbers, samsaraDriversNumbersFilter)}
          value={samsaraDriversNumbers.find(x => x.Id == samsaraDriverNumber)}
          textField="Name"
          itemRender={itemRenderSamsaraDriverNumber}
          onChange={(e) => {
            setOmnitracsDriverNumber('');
            setSamsaraAccount(e.target.value.SamsaraAccount);
            setSamsaraDriverNumber(e.target.value.Id);
          }}
        />
        <div className="input-group-append">
          <button className="btn btn-outline-primary" onClick={handleAssigned}>Assign</button>
        </div>
      </div>
    </div>;
  }

  const dataView = () => <div className="row">
      <div className="col-md-6 text-center">
        {formatLabelData("Driver ID", hos.OmnitracsDriverNumber || hos.SamsaraDriverId)}
        {formatLabelData("Last Updated", formatDate(hos.LastRecalculated))}
        {formatLabelData("Last Recalculated", formatDate(hos.LastUpdated))}
        {formatLabelData("Duty Status", hos.Status)}
        {formatLabelData("Last Duty Change", formatDate(hos.ActivityStarted))}
        {formatLabelData("Today Available Driving Hours", formatHours(hos.DayDrive), hos.DayDrive < 0)}
        {formatLabelData("Today Available Duty Hours", formatHours(hos.DayDuty), hos.DayDuty < 0)}
        {formatLabelData("This Week Remaining Duty Hours", formatHours(hos.WeekDuty), hos.WeekDuty < 0)}
      </div>
      <div className="col-md-6 text-center">
        {formatLabelData("Today On Duty", formatHours(hos.TodayOnDuty), hos.TodayOnDuty < 0)}
        {formatLabelData("Today Off Duty", formatHours(hos.TodayOffDuty), hos.TodayOffDuty < 0)}
        {formatLabelData("Today Sleeper", formatHours(hos.TodaySleeper), hos.TodaySleeper < 0)}
        {formatLabelData("Today Drive", formatHours(hos.TodayDrive), hos.TodayDrive < 0)}
        {formatLabelData("Yesterday On Duty", formatHours(hos.YesterdayOnDuty), hos.YesterdayOnDuty < 0)}
        {formatLabelData("Yesterday Off Duty", formatHours(hos.YesterdayOffDuty), hos.YesterdayOffDuty < 0)}
        {formatLabelData("Yesterday Sleeper", formatHours(hos.YesterdaySleeper), hos.YesterdaySleeper < 0)}
        {formatLabelData("Yesterday Drive", formatHours(hos.YesterdayDrive), hos.YesterdayDrive < 0)}
      </div>
    </div>

  return (<>
    <DriverNavBar id={driverIdParam} currentTab="HoursOfService" />
    <Title string="Hours of Service" />
    <ConfirmationDialog />
    <br />
    {loading ? loadingView : null}
    {links.find(x => x.Name === "Assign") ? assignView() : loading || hos ? null : emptyView}
    {hos ? dataView() : null}
    {links.find(x => x.Name === "Remove") ? <div className="text-center">
      <Button themeColor="error" onClick={handleRemove}>Unassign Device</Button>
    </div> : null}
  </>);
}

export default DriverDevices;