import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class SecondsCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    return (
      <td>
        {(new Date(this.props.dataItem[this.props.field] * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
