import * as React from 'react';
import { ShipmentOffer } from './CoyoteOffers';
import Moment from 'moment';
import { formatNumber } from '@progress/kendo-intl';
import { SvgIcon } from '@progress/kendo-react-common';
import { arrowRightIcon } from '@progress/kendo-svg-icons';

type Props = {
    offer: ShipmentOffer;
    clear: (offerId: string) => void;
    accept: (shipmentId: string, offerId: string, amount: number) => void;
    decline: (offerId: string) => void;
};

type State = {};

export default class CoyoteOffer extends React.Component<Props, State> {
    public render() {
        return <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="p-1">
                            {this.props.offer.Pickup_CityName}, {this.props.offer.Pickup_StateCode}
                            <br />
                            {Moment.utc(this.props.offer.Pickup_ScheduledDateTime).local().format("MM/DD/YYYY HH:mm")}
                        </div>
                        <div className="p-1">
                            <SvgIcon icon={arrowRightIcon} />
                        </div>
                        <div className="p-1">
                            {this.props.offer.Delivery_CityName}, {this.props.offer.Delivery_StateCode}
                            <br />
                            {Moment.utc(this.props.offer.Delivery_ScheduledDateTime).local().format("MM/DD/YYYY HH:mm")}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <p className="text-right">{this.props.offer.VehicleType}</p>
                </div>
            </div>
            <div className="row">
                {this.props.offer.OfferStatus === 1 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been submitted.
                </span>}
                {this.props.offer.OfferStatus === 2 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been accepted.
                </span>}
                {this.props.offer.OfferStatus === 3 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been declined.
                </span>}
                {this.props.offer.OfferStatus === 4 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been countered at <strong>{formatNumber(this.props.offer.CounteredAmount, "c")}</strong>
                </span>}
                {this.props.offer.OfferStatus === 5 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been confirmed.
                </span>}
                {this.props.offer.OfferStatus === 6 && <span className="p-1">
                    Load <strong>{this.props.offer.LoadId}</strong> is no longer available.
                </span>}
                {this.props.offer.OfferStatus === 7 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been withdrawn.
                </span>}
                {this.props.offer.OfferStatus === 8 && <span className="p-1">
                    Your offer of <strong>{formatNumber(this.props.offer.OfferAmount, "c")}</strong> on load <strong>{this.props.offer.LoadId}</strong> has been declined.
                </span>}

            </div>
            <div className="row">
                {[5,6,7,8].indexOf(this.props.offer.OfferStatus) > -1 && <a className="p-1" href="#" onClick={(e) => {
                    e.preventDefault();
                    this.props.clear(this.props.offer.OfferId);
                }}>Clear</a>}
                {this.props.offer.OfferStatus === 4 && <React.Fragment>
                    <a href="#" className="p-1" onClick={(e) => {
                        e.preventDefault();
                        window.confirm(`Book at ${formatNumber(this.props.offer.CounteredAmount, 'c')}?`) && this.props.accept(this.props.offer.ShipmentID, this.props.offer.OfferId, this.props.offer.CounteredAmount);
                    }}>Book</a>
                    &nbsp;&nbsp;
                    <a href="#" className="p-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.decline(this.props.offer.OfferId);
                    }}>Decline</a>
                </React.Fragment>}
            </div>
        </React.Fragment>
    }
}