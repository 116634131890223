import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input } from '@progress/kendo-react-inputs';
import { Notification } from '@progress/kendo-react-notification';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import { AddressViewModel } from '../../TypeGen/Driver/Profile/address-view-model';
import { StateCodes } from '../../utils/states';
import { isNullOrWhiteSpace } from '../../utils/utils';
import CustomerAutoComplete from '../Customers/CustomerAutoComplete';

type RouteComponentParams = {
  customerId: string;
  locationId: string;
  invoiceId: string;
};

type EDIEntityIdentificationViewModel = {
  EntityIdentificationID: number;
  ExternalID: string;
  ExternalID2: string;
  Name: string;
  CustomerNumber: string;
  LocationName: string;
  Address: AddressViewModel;
  HasLocationGroup: boolean;
  HasEntityIdentification: boolean;
  CustomerID: number;
};

const UpdateEDIEntityIdentification = () => {
  const { customerId, locationId, invoiceId } = useParams<RouteComponentParams>();
  const [_customerId, _setCustomerId] = useState(Number(customerId));
  const inputRef = useRef<Input>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [rendered, setRendered] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const [EDIEntityIdentification, setEDIEntityIdentification] = useState<EDIEntityIdentificationViewModel>({} as EDIEntityIdentificationViewModel);

  const hasAddress = EDIEntityIdentification.Address
    && isNullOrWhiteSpace(EDIEntityIdentification.Address.AddressLine1) === false
    && isNullOrWhiteSpace(EDIEntityIdentification.Address.City) === false
    && isNullOrWhiteSpace(EDIEntityIdentification.Address.State) === false
    && isNullOrWhiteSpace(EDIEntityIdentification.Address.ZipCode) === false;

  const canSave = (isNullOrWhiteSpace(invoiceId) && ((hasAddress && isNullOrWhiteSpace(EDIEntityIdentification.Name) === false)
    && (isNullOrWhiteSpace(EDIEntityIdentification.ExternalID) === false || isNullOrWhiteSpace(EDIEntityIdentification.ExternalID2) === false)))
    || (isNullOrWhiteSpace(invoiceId) === false && _customerId > 0 && isNullOrWhiteSpace(EDIEntityIdentification.ExternalID) === false);

  const _update = (deleteProfile: boolean = false) => {
    if (canSave === false)
      return;

    setLoading(true);

    const data = {
      ...EDIEntityIdentification,
      DeleteProfile: deleteProfile
    }

    fetchApi(`/api/EDI/UpdateEDIEntityIdentification/${locationId}/${_customerId}/${invoiceId}`, data, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          if (deleteProfile === false) {
            setShowSuccessMessage(true);
            setShowDeleteMessage(false);
            setEDIEntityIdentification({ ...EDIEntityIdentification, HasEntityIdentification: true });
          }
          else {
            setShowSuccessMessage(false);
            setShowDeleteMessage(true);
            setEDIEntityIdentification({ ...EDIEntityIdentification, HasEntityIdentification: false, ExternalID: '', ExternalID2: '' });
            _setCustomerId(0);
            setRefreshCount(prevCount => prevCount + 1);
          }
        } else {
          alert(response.ErrorMessage);
        }
      }
      ).catch(() => {
        setLoading(false);
        alert('Unable to create entity identifier');
      });
  }

  useEffect(() => {
    if (_customerId === 0)
      return;

    setLoading(true);
    fetchApi(`/api/EDI/GetEDIEntityIdentification/${locationId}/${_customerId}/${invoiceId}`)
      .then((response: EDIEntityIdentificationViewModel) => {
        setEDIEntityIdentification(response);
        _setCustomerId(response.CustomerID);
        setLoading(false);
        setRendered(true);
      }).catch(() => {
        setLoading(false);
      });

  }, [_customerId, locationId, invoiceId, refreshCount]);

  useLayoutEffect(() => {
    if (inputRef.current && rendered) {
      inputRef.current.focus();
    }
  }, [rendered]);


  if (loading) {
    return <div className="container"><CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel></div>
  } else if (EDIEntityIdentification.HasLocationGroup === false) {
    return <div className="container">
      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <Notification type={{ style: "warning", icon: true }}>
          There is no EDI entity identification requirement for this location
        </Notification>
      </div>
    </div>
  } else if (isNullOrWhiteSpace(invoiceId) === false) {
    return <div className="container">
      <div className="k-form k-form-md mt-3">
        <h2>Add/Update EDI Entity Identification (Bill-To/3rd Party)</h2>
        <div>
          <FieldWrapper>
            <CustomerAutoComplete
              clearButton
              disabled={EDIEntityIdentification?.HasEntityIdentification || loading}
              label="Billing Customer To Use"
              placeholder='Billing Customer To Use'
              selectedCustomerID={_customerId}
              onSelectedCustomer={(e) => _setCustomerId(e?.CustomerID ?? 0)}
            />
            {_customerId > 0 && <span>{`${EDIEntityIdentification?.Name} - ${EDIEntityIdentification?.Address?.AddressLine1} - ${EDIEntityIdentification?.Address?.City}, ${EDIEntityIdentification?.Address?.State} ${EDIEntityIdentification?.Address?.ZipCode}`}</span>}
          </FieldWrapper>
          <FieldWrapper>
            <Input
              maxLength={80}
              placeholder="External ID/Sort Key/Billing Term Identifier"
              disabled={loading}
              value={EDIEntityIdentification?.ExternalID}
              label="External ID/Sort Key/Billing Term Identifier (Typically required by US Bank, Volvo, etc.)"
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, ExternalID: e.value })}
            />
          </FieldWrapper>
          <div className="k-form-buttons" style={{ display: 'block' }}>
            <Button onClick={() => { window.close(); }}>Close</Button>
            {<Button themeColor="primary" disabled={canSave === false || loading} className="ml-2"
              onClick={() => _update()}>
              Save Entity Identification
            </Button>}
            {EDIEntityIdentification?.HasEntityIdentification && <Button themeColor="error" disabled={loading} className="float-right"
              onClick={() => window.confirm('Are you sure?') && _update(true)}>
              Delete Entity Identification
            </Button>}
          </div>
        </div>
      </div>
    </div>
  }
  else {
    return <div className="container">
      <div className="k-form k-form-md mt-3">
        {showSuccessMessage && <Notification className="mb-3" type={{ style: "success", icon: true }} closable onClose={() => setShowSuccessMessage(false)}>
          Entity Identification saved for {EDIEntityIdentification.CustomerNumber}.<br />
          {EDIEntityIdentification.LocationName} will now use this profile for their EDI210s.
        </Notification>}
        {showDeleteMessage && <Notification className="mb-3" type={{ style: "error", icon: true }} closable onClose={() => setShowDeleteMessage(false)}>
          Entity Identification deleted for {EDIEntityIdentification.CustomerNumber}.
        </Notification>}
        <h2>Add/Update EDI Entity Identification ({EDIEntityIdentification.CustomerNumber})</h2>
        <div>
          <FieldWrapper>
            <Input
              ref={inputRef}
              autoFocus
              maxLength={80}
              placeholder="External ID/Sort Key/Billing Term Identifier"
              disabled={loading}
              value={EDIEntityIdentification?.ExternalID}
              label="External ID/Sort Key/Billing Term Identifier (Typically required by US Bank, Volvo, etc.)"
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, ExternalID: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              maxLength={80}
              placeholder="Plant/Location Code"
              disabled={loading}
              value={EDIEntityIdentification?.ExternalID2}
              label="Plant/Location Code (Optional for most, but typically required by US Bank)"
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, ExternalID2: e.value })}
            />
          </FieldWrapper>
          <br />
          <FieldWrapper>
            <Input
              required
              maxLength={60}
              disabled={loading}
              value={EDIEntityIdentification?.Name}
              label="Name"
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, Name: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              disabled={loading}
              maxLength={50}
              label="Address Line 1"
              value={EDIEntityIdentification.Address ? EDIEntityIdentification.Address.AddressLine1 : ''}
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, Address: { ...EDIEntityIdentification.Address, AddressLine1: e.value } })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              disabled={loading}
              maxLength={30}
              label="City"
              value={EDIEntityIdentification.Address ? EDIEntityIdentification.Address.City : ''}
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, Address: { ...EDIEntityIdentification.Address, City: e.value } })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <DropDownList
              required
              disabled={loading}
              data={StateCodes}
              label="State"
              textField="Name"
              dataItemKey="ID"
              value={EDIEntityIdentification.Address ? StateCodes.find(x => x.ID === EDIEntityIdentification.Address.State) : undefined}
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, Address: { ...EDIEntityIdentification.Address, State: e.target.value.ID } })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              disabled={loading}
              maxLength={15}
              label="Zip Code"
              value={EDIEntityIdentification.Address ? EDIEntityIdentification.Address.ZipCode : ''}
              onChange={(e) => setEDIEntityIdentification({ ...EDIEntityIdentification, Address: { ...EDIEntityIdentification.Address, ZipCode: e.value } })}
            />
          </FieldWrapper>
          <div className="k-form-buttons" style={{ display: 'block' }}>
            <Button onClick={() => { window.close(); }}>Close</Button>
            {showDeleteMessage === false && <Button themeColor="primary" disabled={canSave === false || loading} className="ml-2"
              onClick={() => _update()}>
              Save Entity Identification
            </Button>}
            {EDIEntityIdentification?.HasEntityIdentification && <Button themeColor="error" disabled={loading} className="float-right"
              onClick={() => window.confirm('Are you sure?') && _update(true)}>
              Delete Entity Identification
            </Button>}
          </div>
        </div>
      </div>
    </div>
  }
}

export default UpdateEDIEntityIdentification;

