import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { JsonResponse } from "TypeGen/json-response";
import { positionEventName, PositionsType, resetDataState } from "views/AssetVehicles/Positions";
import DateCell from "../../components/cells/DateCell";
import { fetchApi } from "../../services/api";
import { Title } from '../../utils/title';
import ManualPositionPopup, { NewPositionType } from "../AssetVehicles/ManualPositionPopup";
import { DataSourceFilter } from "./Positions";

type Props = {
  tripId: number;
  close: () => void;
}

const TripPositions = ({ tripId, close }: Props) => {

  const [loading, setLoading] = useState(true);
  const [showNewPosition, setShowNewPosition] = useState(false);
  const [positions, setPositions] = useState<PositionsType>();
  const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'and', filters: []
  });

  const refresh = useCallback(() => {
    setLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/Trip/Positions/${tripId}?${queryStr}`, {}, 'POST')
      .then(data => {
        setLoading(false);
        setPositions(data);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [tripId, dataState]);

  useEffect(() => {
    refresh();
  }, [tripId, refresh]);

  const savePosition = (newPosition: NewPositionType) => {
    return fetchApi(`/api/Trip/AddPosition/${tripId}`, newPosition, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setShowNewPosition(false);
          refresh();
        }
        else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unable to save position");
      });
  }

  return (<Dialog    
    title="Trip Positions"
    className="dialog-w11/12"
    onClose={close}
  >
    <Title string="Trip Positions" />
    {loading && <div className="k-pos-absolute k-top-center mt-5">
      <Loader type="converging-spinner" />
    </div>}
    {showNewPosition && <ManualPositionPopup
      close={() => setShowNewPosition(false)}
      save={savePosition}
    />}
    <div>
      <Filter
        className="kendoFilterControl"
        value={filter}
        onChange={(e) => {
          setFilter(e.filter);
        }}
        fields={[
          {
            name: "VehicleNumber",
            label: "Vehicle #",
            filter: TextFilter,
            operators: Operators.text,
          },
          {
            name: "TrailerNumber",
            label: "Trailer #",
            filter: TextFilter,
            operators: Operators.text,
          },
          {
            name: "DateTime",
            label: "Date",
            filter: DateFilter,
            operators: Operators.date,
          },
          {
            name: "City",
            label: "City",
            filter: TextFilter,
            operators: Operators.text,
          },
          {
            name: "State",
            label: "State",
            filter: TextFilter,
            operators: Operators.text,
          },
          {
            name: "Speed",
            label: "Speed",
            filter: NumericFilter,
            operators: Operators.numeric,
          },
          {
            name: "RepeatCount",
            label: "Repeated",
            filter: NumericFilter,
            operators: Operators.numeric
          },
          {
            name: "Distance",
            label: "Distance",
            filter: NumericFilter,
            operators: Operators.numeric
          },
          {
            name: "DataSource",
            label: "Source",
            filter: DataSourceFilter,
            operators: [{ text: 'filter.eqOperator', operator: 'eq' }]
          },
          {
            name: "UserNumber",
            label: "User",
            filter: TextFilter,
            operators: Operators.text,
          },
        ]}
      />
      <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
        <Button
          themeColor="primary"
          onClick={() => setDataState({ ...dataState, filter: filter })}
        >Search
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => setShowNewPosition(true)}
        >New Position
        </Button>
      </label>
    </div>
    {positions && <Grid
      {...dataState}
      data={positions.Data}
      total={positions.Total}
      sortable
      pageable
      onDataStateChange={(e) => setDataState(e.dataState)}
    >
      <Column field="VehicleNumber" title="Vehicle #" cell={(e) => <td>
        <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${e.dataItem.VehicleID}`}>{e.dataItem.VehicleNumber}</Link>
      </td>} />
      <Column field="TrailerNumber" title="Trailer #" cell={(e) => <td>
        <Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${e.dataItem.TrailerID}`}>{e.dataItem.TrailerNumber}</Link>
      </td>} />
      <Column field="DateTime" title="Date" filterable={false} filter="date" cell={DateCell} />
      <Column field="City" />
      <Column field="State" />
      <Column field="Speed" filter="numeric" format="{0:n0} mph" />
      <Column field="Direction" filter="numeric" />
      <Column field="Ignition" filter="boolean" cell={e => <td>{e.dataItem.Ignition ? "ON" : e.dataItem.Ignition === false ? "OFF" : ""}</td>} />
      <Column field="RepeatCount" title="Repeated" filter="numeric" />
      <Column field="Distance" filter="numeric" format="{0:n0} mi" />
      <Column field="DataSourceName" title="Source" />
      <Column field="UserNumber" title="User" />
      <Column field="Event" filterable={false} cell={(e) => <td>{positionEventName(e.dataItem.Event)}</td>} />
    </Grid>}
  </Dialog>);
}

export default TripPositions;