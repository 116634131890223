import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment-timezone';
import { FleetMessage } from '.';
import { memo } from 'react';

const ReadSentCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as FleetMessage;

  return (
      <td>
          {data.ReadSentDateTime ? Moment.utc(data.ReadSentDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm') : ''}
      </td>
  );
}

export default memo(ReadSentCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
