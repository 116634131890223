import React, { createContext, useCallback, useEffect, useReducer, useRef, useState, useContext } from 'react';
import { fetchApi } from '../../services/api';
import { DataSourceRequestState, filterBy, FilterDescriptor, orderBy, SortDescriptor, State as GridState } from '@progress/kendo-data-query';
import { Grid, GridToolbar, GridColumn as Column, GridSortChangeEvent, GridNoRecords, GridCellProps, GridHeaderCellProps, GridDetailRowProps, GridExpandChangeEvent, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import HeaderSortCell from '../../components/cells/HeaderSortCell';
import { Title } from '../../utils/title';
import Moment from 'moment-timezone';
import TrailerMovementHistory from './TrailerMovementHistory';
import { Input } from '@progress/kendo-react-inputs';
import { debounce } from 'ts-debounce';
import { ILink } from '../../types/link';
import { openWindow } from '../../services/openWindow';
import { Link } from 'react-router-dom';
import CopyTrailerDialog from './Trailer/CopyTrailerDialog';
import { Button } from '@progress/kendo-react-buttons';
import RelocateTrailerDialog from './Trailer/RelocateTrailerDialog';
import { arrowRotateCwIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import PositionCell from './Trailer/PositionCell';

export type TrailerViewItem = {
    TrailerID: number;
    TrailerNumber: string;

    VehicleID: number;
    VehicleNumber: string;

    OwnerNumber: string;

    TrailerTypeName: string;
    TrailerStatus: number;
    TrailerStatusValue: string;
    TrailerStatusReason: number;
    TrailerStatusReasonValue: string;

    LastDropPositionCity: string;
    LastDropPositionState: string;
    LastDropDateTime: Date;
    LastDropCustomerName: string;
    LastDropCustomerID: number;

    LastPositionCity: string;
    LastPositionState: string;
    LastPositionDateTime: Date;

    Payload: number;
    TripNumber: number;

    BoxW: string;
    BoxH: string;
    BoxLMin: string;
    BoxLMax: string;
    TrailerVIN: string;
    LicensePlate: string;

    Comment1: string;

    Active: boolean;

    Links: ILink[];
}

type AdvanceSearchParameters = {
    active: boolean;
    offsite: boolean;
    // refreshList: boolean;
}

export const isTrailerDateValid = (date: Date): boolean => {
    return Moment.utc(date).isAfter(Moment.utc("0001-01-01"));
}

export const getTrailerStatusColor = (trailerStatus: number): string => {
    switch (trailerStatus) {
        case 1: //Hooked
            return "text-warning d-block";
        case 2: //Unhooked_Unavailable
        case 4: //Unhooked_Unavailable_FOB
            return "text-info d-block";
        default: //0=Unhooked_Available //3=Unhooked_Available_FOB
            return "text-success d-block";
    }
}

const TrailerGridContext = createContext<{
    ShowRelocateTrailerDialog: (trailerId: number) => void,
}>({
    ShowRelocateTrailerDialog: null,
})

const TrailerGrid = () => {

    const defaultSort = { field: "TrailerNumber", dir: "asc" } as SortDescriptor;

    const resetFilterSortState = {
        active: true,
        offsite: false
    } as AdvanceSearchParameters;

    const resetDataState = {
        skip: 0,
        take: 50,
        sort: [{
            field: "TrailerNumber",
            dir: "asc"
        }]
    } as GridState;

    const sessionStorageAndFilter_DataState = sessionStorage.getItem("TrailerGrid-DataState-andFilter");
    const sessionStorageAndFilter_AdvanceState = sessionStorage.getItem("TrailerGrid-AdvanceState-andFilter");

    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: sessionStorageAndFilter_DataState ? JSON.parse(sessionStorageAndFilter_DataState) : null });
    const [advanceSearchParameters, setAdvanceSearchParameters] = useState<AdvanceSearchParameters>(sessionStorageAndFilter_AdvanceState ? JSON.parse(sessionStorageAndFilter_AdvanceState) : resetFilterSortState);
    const [loading, setLoading] = useState(false);
    const [trailers, setTrailers] = useState<TrailerViewItem[]>([]);
    const [showCopyTrailerDialog, setShowCopyTrailerDialog] = useState(false);
    const [showRelocateTrailerDialog, setShowRelocateTrailerDialog] = useState(0);
    const [searchInput, setSearchInput] = useState(sessionStorage.getItem("TrailerGrid-inputFilter") ?? "");
    const callRefreshList = useRef(true);
    const total = useRef(0);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const reset = () => {
        callRefreshList.current = true;
        setDataState(resetDataState);
        setSearchInput("");
        setAdvanceSearchParameters(resetFilterSortState);
        sessionStorage.removeItem("TrailerGrid-DataState-andFilter");
        sessionStorage.removeItem("TrailerGrid-AdvanceState-andFilter");
        sessionStorage.removeItem("TrailerGrid-inputFilter");
    }

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/GetAssetTrailers`, advanceSearchParameters, 'POST')
            .then(({ Data, Total }) => {
                setTrailers(Data);
                total.current = Total;
                setLoading(false);
            });
    }, [advanceSearchParameters])

    useEffect(() => {
        if (callRefreshList.current) {
            callRefreshList.current = false;
            setAdvanceSearchParameters({ ...advanceSearchParameters });
            sessionStorage.setItem("TrailerGrid-AdvanceState-andFilter", JSON.stringify(advanceSearchParameters));
            refreshList();
        }
    }, [refreshList, advanceSearchParameters]);

    const sortChange = (event: GridSortChangeEvent) => {
        if (event.sort.length === 0)
            event.sort = [{ ...defaultSort }];
        setDataState({ ...dataState, sort: event.sort, skip: 0 });
    }

    const onDataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    }

    const TrailerNumberHeaderCell = (props: GridHeaderCellProps) => {
        return (<HeaderSortCell gridHeaderProps={props} sort={dataState.sort}>
            <span className='k-column-title'>
                Trailer ID<br />
                Vehicle ID<br />
                Owner ID
            </span>
        </HeaderSortCell>);
    };

    const TrailerNumberCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerViewItem = props.dataItem;

        //const ownerProfileLink = 
        //props.dataItem[props.field]
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <Link
                    to={dataItem.Links.find((x: ILink) => x.Name === 'TrailerProfile')?.Link}
                    style={{ color: '#007bff' }}
                >
                    {dataItem.TrailerNumber}
                </Link><br />

                <a
                    href="#"
                    style={{ color: '#007bff' }}
                    onClick={(e) => {
                        openWindow(dataItem.Links.filter((x: ILink) => x.Name === 'VehicleProfile')[0].Link);
                        e.preventDefault();
                    }}
                >{dataItem.VehicleNumber}
                </a><br />

                <Link
                    to={dataItem.Links.find((x: ILink) => x.Name === 'OwnerProfile')?.Link}
                    style={{ color: '#007bff' }}
                >
                    {dataItem.OwnerNumber}
                </Link><br />
            </td>
        );
    };

    const HookStatusHeaderCell = (props: GridHeaderCellProps) => {
        return (<HeaderSortCell gridHeaderProps={props} sort={dataState.sort}>
           <span className='k-column-title'>
                Hook Status<br />
                Availability
            </span>
        </HeaderSortCell>);
    };

    const HookStatusCell = (props: GridCellProps) => {
        const context = useContext(TrailerGridContext);

        if (!props.field)
            return null;

        let dataItem: TrailerViewItem = props.dataItem;
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {dataItem.TripNumber === 0 ? <a className={getTrailerStatusColor(dataItem.TrailerStatus)} style={{ cursor: "pointer" }} onClick={(e) => {
                    if (window.isTMS) {
                        context.ShowRelocateTrailerDialog(dataItem.TrailerID);
                    } else {
                        openWindow(dataItem.Links.filter((x: ILink) => x.Name === 'MoveTrailer')[0].Link);
                    }
                    e.preventDefault();
                }}>{dataItem.TrailerStatusValue}</a> : <div className={getTrailerStatusColor(dataItem.TrailerStatus)}>{dataItem.TrailerStatusValue}</div>}

                {dataItem.TrailerStatusReason ? <div className="badge badge-light">{dataItem.TrailerStatusReasonValue.toUpperCase()}</div> : null}
            </td>
        );
    };

    const LastUnhookLocationHeaderCell = (props: GridHeaderCellProps) => {
        return (<HeaderSortCell gridHeaderProps={props} sort={dataState.sort}>
            <span className='k-column-title'>
                Last Unhook Location<br />
                Date/Time
            </span>
        </HeaderSortCell>);
    };

    const LastUnhookLocationCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerViewItem = props.dataItem;
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {isTrailerDateValid(dataItem.LastDropDateTime) && <>
                    {dataItem.LastDropCustomerName ? <div>{`${dataItem.LastDropCustomerName}`}</div> : ''}
                    <div>{`${dataItem.LastDropPositionCity}, ${dataItem.LastDropPositionState}`}</div>
                    <div>{Moment.utc(dataItem.LastDropDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
                </>}
            </td>
        );
    };

    const BoxDimHeaderCell = (props: GridHeaderCellProps) => {
        return (<HeaderSortCell gridHeaderProps={props} sort={dataState.sort}>
            <span className='k-column-title'>
                Box Dims<br />
                Vin No.<br />
                Plate No.
            </span>
        </HeaderSortCell>);
    };

    const BoxDimCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerViewItem = props.dataItem;
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <div>{`${dataItem.BoxLMax}x${dataItem.BoxW}x${dataItem.BoxH}`}</div>
                <div>{dataItem.TrailerVIN}</div>
                <div>{dataItem.LicensePlate}</div>
            </td>
        );
    };

    const LastKnownLocationHeaderCell = (props: GridHeaderCellProps) => {
        return (<HeaderSortCell gridHeaderProps={props} sort={dataState.sort}>
            <span className='k-column-title'>
                Last Known Location<br />
                Date/Time
            </span>
        </HeaderSortCell>);
    };

    const DetailComponent = (props: GridDetailRowProps) => {
        const dataItem: TrailerViewItem = props.dataItem;
        return (
            <TrailerMovementHistory TrailerID={dataItem.TrailerID} />
        );
    };

    const expandChange = (e: GridExpandChangeEvent) => {
        e.dataItem.expanded = !e.dataItem.expanded;
        forceUpdate();
    };

    const hasFilterDescriptor = (field: string): boolean => {
        if (dataState.filter)
            return (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field == field).length > 0;
        return false;
    }

    const updateFilter = (field: string, value: string) => {
        if (value.length > 0) {
            if (hasFilterDescriptor(field)) {
                (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field == field)[0].value = value;
                setDataState({ ...dataState });
            } else {
                (dataState.filter.filters as FilterDescriptor[]).push({
                    field: field,
                    value: value,
                    ignoreCase: true,
                    operator: "contains"
                } as FilterDescriptor)
                setDataState({ ...dataState });
            }
        } else {
            if (hasFilterDescriptor(field)) {
                dataState.filter.filters = (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field != field);
                setDataState({ ...dataState });
            }
        }
        sessionStorage.setItem("TrailerGrid-DataState-andFilter", JSON.stringify(dataState.filter));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((_value: string) => {
            if (!dataState.filter)
                dataState.filter = { logic: 'or', filters: [] as any };
            updateFilter("TrailerNumber", _value);
            updateFilter("VehicleNumber", _value);
            updateFilter("OwnerNumber", _value);
            updateFilter("TrailerStatusValue", _value);
            updateFilter("TrailerTypeName", _value);
            updateFilter("LastPositionCity", _value);
            updateFilter("LastDropPositionCity", _value);
            updateFilter("LastDropCustomerName", _value);
            updateFilter("TrailerVIN", _value);
            updateFilter("LicensePlate", _value);
            updateFilter("TrailerStatusReasonValue", _value);
        }, 750)
        , []);

    //const getTrailerStatusColor = (trailerStatus: number): string => {
    //    switch (trailerStatus) {
    //        case 1: //Hooked
    //            return "badge badge-warning";
    //        case 2: //Unhooked_Unavailable
    //            return "badge badge-info";
    //        case 3: //Unhooked_Available_FOB
    //            return "badge badge-success";
    //        case 4: //Unhooked_Unavailable_FOB
    //            return "badge badge-secondary";
    //        default: //Unhooked_Available
    //            return "badge badge-success";
    //    }
    //}

    const fetchData = () => {
        var filteredData = trailers;
        if (dataState.filter && dataState.filter.filters.length > 0) {
            filteredData = filterBy(filteredData, dataState.filter);
        }
        total.current = filteredData.length;
        return orderBy(filteredData, dataState.sort).slice(dataState.skip, dataState.take + dataState.skip);
    }

    return <React.Fragment>
        <Title string="Trailer Listing" />
        {showCopyTrailerDialog &&
            <CopyTrailerDialog
                CloseDialog={() => { setShowCopyTrailerDialog(false) }}
            />
        }
        {showRelocateTrailerDialog > 0 &&
            <RelocateTrailerDialog
                TrailerID={showRelocateTrailerDialog}
            CloseDialog={() => { setShowRelocateTrailerDialog(0); refreshList(); }}
            />
        }
        <TrailerGridContext.Provider value={{
            ShowRelocateTrailerDialog: (trailerId: number) => { setShowRelocateTrailerDialog(trailerId) },
        }}>
            {loading && <GridLoadingPanel />}
            <Grid
                style={{ maxHeight: '1000px' }}
                resizable={true}
                data={fetchData()}
                sortable={{ allowUnsort: true }}
                onSortChange={sortChange}
                total={total.current}
                {...dataState}
                pageable={{ pageSizes: [50, 100, 200] }}
                onDataStateChange={onDataStateChange}
                detail={DetailComponent}
                expandField="expanded"
                onExpandChange={expandChange}

            >
                <GridToolbar>
                    <Input
                        value={searchInput}
                        className="ml-1"
                        onChange={(e) => {
                            setSearchInput(e.target.value.toString());
                            debounceSearchInput(e.target.value.toString());
                            sessionStorage.setItem("TrailerGrid-inputFilter", e.target.value.toString());
                        }}
                        placeholder="Search..."
                    />
                    <Button
                        title="Clear All Filters and Sort"
                        icon="filter-clear"
                        svgIcon={filterClearIcon}
                        onClick={() => { reset() }}
                    />
                    <Button
                        title="Refresh"
                        icon="refresh"
                        svgIcon={arrowRotateCwIcon}
                        onClick={() => refreshList()}
                    />
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        themeColor="primary"
                        onClick={() => { setShowCopyTrailerDialog(true) }}
                    > Trailer
                    </Button>
                    <Button
                        togglable
                        selected={!advanceSearchParameters.active}
                        onClick={() => {
                            callRefreshList.current = true;
                            setAdvanceSearchParameters({
                                ...advanceSearchParameters,
                                active: !advanceSearchParameters.active,
                            });
                        }}
                    >{!advanceSearchParameters.active ? 'Retired' : 'Active'}
                    </Button>
                    <Button
                        togglable
                        selected={advanceSearchParameters.offsite}
                        onClick={() => {
                            callRefreshList.current = true;
                            setAdvanceSearchParameters({
                                ...advanceSearchParameters,
                                offsite: !advanceSearchParameters.offsite,
                            });
                        }}
                    >Offsite Trailers</Button>
                </GridToolbar>
                <GridNoRecords>
                    {loading == false && <span>No Trailers Found</span> || <span></span>}
                </GridNoRecords>
                <Column field="TrailerNumber" headerCell={TrailerNumberHeaderCell} cell={TrailerNumberCell} sortable={true} />
                <Column field="TrailerTypeName" title="Type" media="(min-width: 768px)" />
                <Column field="TrailerStatus" title="Hook Status" headerCell={HookStatusHeaderCell} cell={HookStatusCell} width={145} />
                <Column field="LastDropDateTime" media="(min-width: 768px)" title="Last Unhook Location" filter="date" headerCell={LastUnhookLocationHeaderCell} cell={LastUnhookLocationCell} />
                <Column field="LastPositionDateTime" title="Last Known Location" filter="date" headerCell={LastKnownLocationHeaderCell} cell={PositionCell} />
                <Column field="Payload" media="(min-width: 992px)" filter="numeric" format="{0:n0}"  />
                <Column field="TrailerVIN" media="(min-width: 992px)" title="Comments" headerCell={BoxDimHeaderCell} cell={BoxDimCell} />
                <Column field="Comment1" media="(min-width: 992px)" title="Comments" sortable={false} />
            </Grid>
        </TrailerGridContext.Provider>
    </React.Fragment>
}

export default TrailerGrid;