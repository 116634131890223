import * as React from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';

type SaveRequest = {
  ExternalUrl: string;
}

type Props = {
  TripID: number;
  ExternalUrl: string;
  CloseDialog: () => void;
}

type State = {
  loading: boolean;
  request: SaveRequest;
}

export default class ExternalUrl extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      request: {
        ExternalUrl: props.ExternalUrl || '',
      }
    }

    this.save = this.save.bind(this);
  }

  render() {
    if (this.state.loading) {
      return <React.Fragment>
        <div className="k-overlay" style={{ zIndex: 10002, opacity: 0.5 }} />
        <LoadingPanel />
      </React.Fragment>
    }
    return (
      <Dialog title="External Tracking URL" onClose={this.props.CloseDialog} className='dialog-w1140'>
        <Input
          autoFocus
          label="Tracking URL"
          placeholder='https://...'
          type="url"
          validationMessage="Please enter a valid url!"
          style={{ width: "100%" }}
          value={this.state.request.ExternalUrl}
          onChange={(e) => this.updateRequest({ ExternalUrl: e.value })}
        />
        <DialogActionsBar layout="end">
          <Button onClick={this.props.CloseDialog}>Cancel</Button>
          <Button themeColor="primary" onClick={this.save} disabled={this.state.loading}>Save</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  private updateRequest(value: Partial<SaveRequest>) {
    const request = Object.assign({}, this.state.request, value);
    this.setState({ request });
  }

  private save() {
    fetchApi(`/api/Track/SaveExternalUrl/${this.props.TripID}`, this.state.request, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to save the external url!');
      });
  }
}
