import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { IDName } from '../../types/idname';
import { Loader } from "@progress/kendo-react-indicators";
import { EditableField } from "../../components/EditableField";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import { CarrierContactNavBar } from "./CarrierContactNavBar";

type RouteComponentParams = {
    carrierContactId: string;
};

type CarrierProfileType = {
    CarrierContact: CarrierContactType,
    Users: IDName[];
}

export type CarrierContactType = {
    FirstName: string;
    LastName: string;
    Active: boolean;
    EmailAddress: string;
    PhoneNumber: string;
    PhoneNumberExtension: string;
    Title: string;
    SendTruckAvailability: boolean;
    SendOfferCancellations: boolean;

    AuditInfo: AuditInfoViewModel;
}

const CarrierContactProfile = () => {
    const { carrierContactId: carrierContactIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState<CarrierProfileType>();

    const fetchProfile = useCallback(() => {
        async function fetchData() {
            setLoading(true);
            await fetchApi(`/api/Asset/CarrierContact/${carrierContactIdParam}`)
                .then(data => {
                    setLoading(false);
                    setProfile(data);

                })
                .catch(err => {
                    alert(err);
                    setLoading(false);
                });
        }
        fetchData();
    }, [carrierContactIdParam]);


    useEffect(() => {
        fetchProfile();
    }, [carrierContactIdParam, fetchProfile]);


    const saveProfile = (value: Partial<CarrierContactType>): Promise<void> => {
        return fetchApi(`/api/Asset/CarrierContact/${carrierContactIdParam}`, updateProfileState(value), 'PUT');
    }

    const saveAsyncProfile = async (value: Partial<CarrierContactType>): Promise<void> => {
        const newProfile: CarrierContactType = Object.assign({}, profile.CarrierContact, value);
        return fetchApi(`/api/Asset/CarrierContact/${carrierContactIdParam}`, newProfile, 'PUT');
    }

    const updateProfileState = (value: Partial<CarrierContactType>): CarrierContactType => {
        const newProfile: CarrierContactType = Object.assign({}, profile.CarrierContact, value);
        setProfile({ ...profile, CarrierContact: newProfile });
        return newProfile;
    }

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <div className="row m-2">
        <div className="col-md-7">
            <div className="form-group row">
                <label htmlFor="CarrierName" className="col-sm-2 col-form-label">First Name</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="string"
                        inputProps={{ maxLength: 60 }}
                        data={profile.CarrierContact.FirstName}
                        save={(data) => saveProfile({ FirstName: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="CarrierName" className="col-sm-2 col-form-label">Last Name</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="string"
                        inputProps={{ maxLength: 60 }}
                        data={profile.CarrierContact.LastName}
                        save={(data) => saveProfile({ LastName: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="Title" className="col-sm-2 col-form-label">Title</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="string"
                        inputProps={{ maxLength: 50 }}
                        data={profile.CarrierContact.Title}
                        save={(data) => saveProfile({ Title: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="EmailAddress" className="col-sm-2 col-form-label">Email Address</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="string"
                        inputProps={{ type: 'email', maxLength: 50 }}
                        data={profile.CarrierContact.EmailAddress}
                        save={(data) => saveProfile({ EmailAddress: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="PhoneNumber" className="col-sm-2 col-form-label">Phone Number</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="intlphone"
                        data={profile.CarrierContact.PhoneNumber}
                        save={(data) => saveProfile({ PhoneNumber: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="PhoneNumberExtension" className="col-sm-2 col-form-label">Phone Ext</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="string"
                        inputProps={{ maxLength: 4 }}
                        data={profile.CarrierContact.PhoneNumberExtension}
                        save={(data) => saveProfile({ PhoneNumberExtension: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="SendTruckAvailability" className="col-sm-2 col-form-label">Receive Scorecard</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="boolean"
                        data={profile.CarrierContact.SendTruckAvailability}
                        save={(data) => saveProfile({ SendTruckAvailability: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="SendOfferCancellations" className="col-sm-2 col-form-label">Receive Awarding Another Carrier</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="boolean"
                        data={profile.CarrierContact.SendOfferCancellations}
                        save={(data) => saveProfile({ SendOfferCancellations: data })}
                    />
                </div>
            </div>
        </div>
        <div className="col-md-5">
            <br />
            <AuditInfo audit={profile.CarrierContact.AuditInfo} />
            <br />
        </div>
    </div>

    return (<>
        <CarrierContactNavBar id={carrierContactIdParam} currentTab="Profile" />
        {/*<Title string={profile?.CarrierContact?.CarrierNumber} />*/}
        {loading || !profile ? loadingView : dataView()}
    </>);
}

export default CarrierContactProfile;