import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string | number;
    currentTab: "Profile" | "Positions" | "Notes" | "Logs";
}

export const TrailerNavBar = (props: Props) => {

    const [number, setNumber] = useState("");
    const [links, setLinks] = useState<ILink[]>([]);
    const [showRetirePopup, setShowRetirePopup] = useState<boolean>(false);

    const refresh = (trailerId: string | number) => {
        setLinks([]);
        fetchApi(`/api/Asset/TrailerNavBar/${trailerId}`)
            .then((data: { Number: string, Links: ILink[] }) => {
                setNumber(data.Number);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this trailer?")) {
            return;
        }

        fetchApi(`/api/Asset/ReactivateTrailer/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    const retire = () => {
        if (!window.confirm('Retire Trailer: Are you sure?')) {
            return;
        }

        fetchApi(`/api/Asset/RetireTrailer/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to retire");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    const vehicleLink = links.find((x: ILink) => x.Name === 'VehicleProfile');

    return <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Assets/Trailers">Trailers</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {number}
                </span>
            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#driverNav" aria-controls="driverNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="driverNav">
                <ul className="navbar-nav">
                    {links.find((x: ILink) => x.Name === 'Trailer') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Trailer/${props.id}`}>Profile</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Positions') && <li className={`nav-item ${props.currentTab === 'Positions' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Trailer/${props.id}/Positions`}>Positions</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Trailer/${props.id}/Notes`}>Notes</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/ResourceLogs/${props.id}/5`}>Logs</Link>
                    </li>}
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    {vehicleLink && <Link className="btn btn-secondary mr-1" to={vehicleLink.Link}>Vehicle</Link>}
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => retire()} themeColor="error">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}