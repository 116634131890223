import * as React from 'react';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import PhoneCell from '../../../components/cells/PhoneCell';
import DateCell from '../../../components/cells/DateCell';

type Props = {
  shipmentId: string;
}

type State = {
  loading: boolean;
  vehicles: NetworkVehiclesViewModel[]
}

type GetNetworkVehiclesResponse = {
  ShipmentID: string;
  Vehicles: NetworkVehiclesViewModel[];
}

type NetworkVehiclesViewModel = {
    VehicleNumber: string;
    Status: string;
    VehicleType: string;
    PostingClass: string;
    PayLoad: number;
    Dimensions: string;
    Position: string;
    PositionDateTime: Date;
    CarrierName: string;
    CarrierPhone: string;
    Distance: number;
}

export default class LoadBoardNetworkVehicles extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      vehicles: [],
    }

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
        return <LoadingPanel />
    }

    return <Grid data={this.state.vehicles}>
        <GridNoRecords>
            No Full Circle Vehicles Found!
        </GridNoRecords>
        <Column field="VehicleNumber" title="Vehicle Number" />
        <Column field="VehicleType" title="Vehicle Type" />
        <Column field="PostingClass" title="Posting Class" />
        <Column field="PayLoad" />
        <Column field="Dimensions" title="DIMS (LxWxH)" />
        <Column field="Position" />
        <Column field="PositionDateTime" title="Position Date" filter="date" cell={DateCell} />
        <Column field="CarrierName" title="Carrier Name" />
        <Column field="CarrierPhone" title="Carrier Phone" cell={PhoneCell} />
        <Column field="Distance" format="{0:n0}" />
    </Grid>;
  }

  private fetch() {
    fetchApi('/api/LoadBoard/NetworkVehicles', { ShipmentID: this.props.shipmentId }, 'POST')
      .then((response: GetNetworkVehiclesResponse) => {
        this.setState({ loading: false, vehicles: response.Vehicles });
      })
  }
}
