import * as React from 'react';
import { GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';

export class TotalChargesCell extends React.Component<GridCellProps> {
    render() {        
        return <td style={{ textAlign: "right" }}>{formatNumber(this.props.dataItem.TotalCharges, "c")}</td>;
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
    }
}

export const TotalChargesHeaderCell = (props: GridHeaderCellProps) => {
    return (
        <a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
            <span>{props.title}</span>
            {props.children}
        </a>
    );
}