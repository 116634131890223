import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { plusIcon } from '@progress/kendo-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Title } from 'utils/title';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { JsonResponse } from '../../TypeGen/json-response';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { PurchaseOrderGeneralLedgerAccountViewModel } from './POGeneralLedgerAccountAutoComplete';

const GeneralLedgerAccountListing = () => {
  const render = useRef(true);
  const [loading, setLoading] = useState(true);
  const { alert } = useAlert();

  const [showAddGeneralLedgerAccountDialog, setShowAddGeneralLedgerAccountDialog] = useState(false);
  const [GLAccountCode, setGLAccountCode] = useState('');
  const [GLDescription, setGLDescription] = useState('');
  const [GeneralLedgerAccountID, setGeneralLedgerAccountID] = useState(0);

  const defaultAndFilter = {
    logic: 'and', filters: [
      // { field: 'Active', operator: 'eq', value: true },
    ]
  } as CompositeFilterDescriptor;

  //const sessionStorageAndFilter = sessionStorage.getItem("GeneralLedgerAccountsFilterV1");
  let sessionStorageAndFilter = null;
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "GLDescription",
      dir: "asc"
    }]
  } as GridState;
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });

  const totalRecords = useRef(0);
  const DataState_Take = 50;
  const [vendors, setGeneralLedgerAccounts] = useState<PurchaseOrderGeneralLedgerAccountViewModel[]>([]);

  const PurchaseOrderGeneralLedgerAccountIDCell = (props: GridCellProps) => {
    let dataItem: PurchaseOrderGeneralLedgerAccountViewModel = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
          e.preventDefault();

          setGeneralLedgerAccountID(dataItem.GeneralLedgerAccountID);
          setGLAccountCode(dataItem.GLAccountCode);
          setGLDescription(dataItem.GLDescription);

          setShowAddGeneralLedgerAccountDialog(true);
        }}>{dataItem.GeneralLedgerAccountID}</Link>
      </td>
    );
  };

  const _update = () => {

    let dataItem = {
      GeneralLedgerAccountID: GeneralLedgerAccountID,
      GLAccountCode: GLAccountCode,
      GLDescription: GLDescription,
      IsNew: GeneralLedgerAccountID === 0
    }

    setLoading(true);
    const data = { ...dataItem }
    fetchApi(`/api/PurchaseOrder/GeneralLedgerAccount/${GeneralLedgerAccountID}`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        } else {
          setDataState({
            ...resetDataState, filter: {
              logic: 'and', filters: [
                //{ field: 'GLAccountCode', operator: 'eq', value: GLAccountCode },
                { field: 'GLDescription', operator: 'eq', value: GLDescription },
              ]
            } as CompositeFilterDescriptor
          });
          //fetchRecords(false);
        }
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const fetchRecords = useCallback((append: boolean) => {
    sessionStorage.setItem("GeneralLedgerAccountsFilterV1", JSON.stringify(dataState.filter));
    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }

    const data = {};
    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/PurchaseOrder/GetGeneralLedgerAccounts?${queryStr}`, data, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setGeneralLedgerAccounts(Data);
        } else {
          setGeneralLedgerAccounts(prevData => prevData.concat(Data));
        }
        setLoading(false);
        setShowAddGeneralLedgerAccountDialog(false);
      }).catch(() => {
        setLoading(false);
      });
  }, [dataState]);

  const search = useDebouncedCallback(useCallback(() => {
    fetchRecords(false);
  }, [fetchRecords]), 750);

  useEffect(() => {
    search();
  }, [dataState.filter, dataState.sort, search]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (vendors.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
  };

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setDataState({ ...dataState, sort: event.sort });
  };

  const dataView = () => {
    return (<div className="mt-3" style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Title string="General Ledger Accounts" />
      <h4 className="text-left">General Ledger Accounts</h4>

      <Grid
        {...dataState}
        className='GeneralLedgerAccountListing-andFilter'
        style={{ maxHeight: `${window.innerHeight * .80}px` }}
        data={vendors}
        total={totalRecords.current}
        onScroll={scrollHandler}
        filterable={true}
        onFilterChange={(e) => onGridFilterChange(e)}
        onSortChange={(e) => onGridSortChange(e)}
        resizable={true}
        reorderable={true}
        sortable={true}
      >
        <GridToolbar>
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => {
              setGeneralLedgerAccountID(0);
              setGLAccountCode('');
              setGLDescription('');
              setShowAddGeneralLedgerAccountDialog(true);
            }}
          >Add GL Account</Button>
        </GridToolbar>
        <Column field="GeneralLedgerAccountID" title="ID" filter="numeric" cell={PurchaseOrderGeneralLedgerAccountIDCell} />
        <Column field="GLAccountCode" title="GL Account Code" />
        <Column field="GLDescription" title="Name" />
      </Grid>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }

      {showAddGeneralLedgerAccountDialog && <Dialog
        className='dialog-w525'
        title={GeneralLedgerAccountID > 0 ? 'Update GL Account' : 'Add GL Account'}
        onClose={() => setShowAddGeneralLedgerAccountDialog(false)}
      >
        <div className="k-form k-form-md p-0">
          <div>
            <FieldWrapper>
              <Input
                required
                maxLength={50}
                autoFocus
                disabled={loading}
                value={GLAccountCode}
                label="GL Account Code"
                onChange={(e) => setGLAccountCode(e?.value)}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Input
                required
                maxLength={100}
                disabled={loading}
                value={GLDescription}
                label="GL Description"
                onChange={(e) => setGLDescription(e?.value)}
              />
            </FieldWrapper>
          </div>
        </div>

        <DialogActionsBar>
          <Button onClick={() => setShowAddGeneralLedgerAccountDialog(false)}>Cancel</Button>
          <Button themeColor="primary" disabled={isNullOrWhiteSpace(GLAccountCode) || isNullOrWhiteSpace(GLDescription)} onClick={_update}>
            {GeneralLedgerAccountID > 0 ? 'Update' : 'Add'} GL Account
          </Button>
        </DialogActionsBar>
      </Dialog>}
    </div >);
  };

  return dataView();
}

export default GeneralLedgerAccountListing;
