import Moment from 'moment-timezone';
import { RevenueType1 } from '../TypeGen/Order/revenue-type1';
import { PayDivision } from '../TypeGen/pay-division';
import { PayStatus } from '../TypeGen/Pay/pay-status';
import { IDName } from '../types/idname';

export function getVehicleTypeIDName(id: number): string {
  switch (id) {
    case 1: return 'CARGO VAN';
    case 2: return 'SMALL STRAIGHT';
    case 3: return 'LARGE STRAIGHT';
    case 4: return 'TRACTOR';
    case 5: return 'FLAT BED';
    case 6: return 'AIR FREIGHT';
    case 7: return 'REEFER';
    case 8: return 'SPECIALIZED';
    case 9: return 'FLATBED CURTAIN';
    case 10: return 'FLATBED W/TARPS';
    case 11: return 'STEP CURTAIN';
    case 12: return 'OPEN STEP';
    case 13: return 'OVERSIZE FLAT';
    case 14: return 'OVERSIZE STEP';
    case 15: return 'SPRINTER';
    case 16: return '53\'';
    case 17: return 'DOUBLE DROP RGN';
    case 18: return 'FLAT OR STEP RETRACTABLE';
    case 19: return 'DOUBLE DROP CURTAINSIDE';
    case 20: return 'AIR CHARTER';
    case 21: return 'CONTAINER';
    case 22: return 'LTL';
    case 23: return 'HOTSHOT';
    case 24: return 'HAND CARRY';
    default: return 'N/A';
  }
}

export function timeSpanToHHmmFormat(timeSpan: string): string {
  var dur = Moment.duration(timeSpan);
  var daysToHrs = dur.days() * 24;
  var totHrs = (dur.hours() + daysToHrs);

  return `${prefixLeadingZero(totHrs)}:${prefixLeadingZero(dur.minutes())}`;
  //return `${prefixLeadingZero(dur.days())}:${prefixLeadingZero(dur.hours())}:${prefixLeadingZero(dur.minutes())}`;
}

export const dateFormatter = (date: Moment.MomentInput) => Moment.utc(date).tz("America/New_York").format('MM/DD/YYYY HH:mm');

export const dateWindowFormatter = (date: Moment.MomentInput, date2: Moment.MomentInput) => {
  if (!date2) {
    return Moment.utc(date).tz("America/New_York").format('MM/DD/YYYY HH:mm');
  }

  const scheduled = Moment.utc(date).tz("America/New_York");
  const latest = Moment.utc(date2).tz("America/New_York");

  // Same Date/Time
  if (scheduled.format('LLL') === latest.format('LLL')) {
    return scheduled.format('MM/DD/YYYY HH:mm');
  }

  // Same Date
  if (scheduled.format('L') === latest.format('L')) {
    return `${scheduled.format('MM/DD/YYYY HH:mm')}-${latest.format('HH:mm')}`;
  }

  // Difference Date
  return `${scheduled.format('MM/DD/YYYY HH:mm')}-${latest.format('MM/DD/YYYY HH:mm')}`;
}

export function prefixLeadingZero(n: number): string {
  return n < 10 ? '0' + n : '' + n;
}

export const formatPhone = (phoneNumber: string) => {
  if (phoneNumber.length == 11) return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1($2)$3-$4');
  else if (phoneNumber.length == 10) return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
  return phoneNumber;
}

export function generateHash(): string {
  return Math.random().toString(36).substring(7);
}

export const isNullOrEmptyValidator = (value: string, error: string) => {
  if (!value || value.length <= 0) return error;
  return '';
}

export const isNullOrWhiteSpace = (value: string): boolean => {
  return (!value || value.match(/^ *$/) !== null);
}

export const isNullOrWhiteSpaceValidator = (value: string, error: string) => {
  if (isNullOrWhiteSpace(value)) return error;
  return '';
}

export const isSorted = (arr: number[]) => arr.every((v, i, a) => !i || a[i - 1] <= v);

export const dimsUnitOfMeasureAbbreviation = (dimsUnitOfMeasure: number): string => {
  switch (dimsUnitOfMeasure) {
    case 0: return 'IN';
    case 1: return 'CM';
    case 2: return 'MM';
    default: throw new Error("Invalid Dims Type!");
  }
}

export const weightUnitOfMeasureAbbreviation = (weightUnitOfMeasure: number): string => {
  switch (weightUnitOfMeasure) {
    case 0: return 'LBS';
    case 1: return 'KGS';
    default: throw new Error("Invalid Weight Type!");
  }
}

export const dimsDisplayValue = (inches: number, dimsUnitOfMeasure: number): number => {
  const dimRound = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;
  switch (dimsUnitOfMeasure) {
    case 0: return dimRound(inches);
    case 1: return dimRound(inches * 2.54);
    case 2: return dimRound(inches * 25.4);
    default: throw new Error("Invalid Dims Type!");
  }
}

export const weightDisplayValue = (pounds: number, weightUnitOfMeasure: number): number => {
  switch (weightUnitOfMeasure) {
    case 0: return Math.round(pounds);
    case 1: return Math.round(pounds * 0.453592);
    default: throw new Error("Invalid Weight Type!");
  }
}

export const PayDivisions = [{ ID: PayDivision.Driver, Name: 'D-DRIVER' }, { ID: PayDivision.Company, Name: 'C-COMPANY' }] as IDName[];
export const PayStatuses = [
  { ID: 0, Name: 'Not Rated' },
  { ID: PayStatus.Rated, Name: 'Rated' },
  { ID: PayStatus.ReadyToPrint, Name: 'Ready To Print' },
  { ID: PayStatus.ReadyToPost, Name: 'Ready To Post' },
  { ID: PayStatus.Posted, Name: 'Posted' },
] as IDName[];

export const formatEnumNameWithSpaces = (name: string): string => {
  return name.replace(/([A-Z])/g, ' $1').trim(); //NotCommmitted -> Not Commmitted
};

export const enumToArrayOfIDName = <T>(enumType: T, getDescription?: (id: number) => string): IDName[] => {
  return Object.keys(enumType)
    .filter(key => isNaN(Number(key)))
    .map(key => {
      const id = Number(enumType[key as keyof typeof enumType]);
      let nameWithSpaces = getDescription ? getDescription(id) : "";

      nameWithSpaces = (nameWithSpaces == null || nameWithSpaces.trim() === "")
        ? formatEnumNameWithSpaces(key)
        : nameWithSpaces;

      return { ID: id, Name: nameWithSpaces };
    });
};

export const RevenueType1s = enumToArrayOfIDName(RevenueType1, (num: number) => { return num == RevenueType1.TLBrokerage ? "TL Brokerage" : "" });