import * as React from 'react';
import Moment from 'moment';
import { fetchApi } from '../../services/api';
import JsonPretty from '../../components/JsonPretty';
import { formatNumber } from '@progress/kendo-intl';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ViewModel } from 'TypeGen/Quote/Logs/view-model';

interface Props {
  CloseDialog: () => void;
  QuoteID: number,
}
const AuditLogs = (props: Props) => {

  const [logs, setLogs] = React.useState<ViewModel>({
    DeclinedReason: '',
    SourceName: '',
    SourceEntityID: '',
    SourceEntityReference: '',
    Logs: [],
    Bids: []
  });

  React.useEffect(() => {
    fetchApi('/api/Quote/Logs', { QuoteID: props.QuoteID }, 'POST')
      .then((response: ViewModel) => {
        setLogs(response);
      })
  }, [props.QuoteID]);

  return (
    <Dialog title={logs.SourceEntityID ? `Quote Logs - ${logs.SourceName} (${logs.SourceEntityID})` : `Quote Logs - ${logs.SourceName}`} onClose={props.CloseDialog} className='dialog-w1140'>
      {logs.DeclinedReason && <div className="alert alert-info">Declined Reason: {logs.DeclinedReason}</div>}
      <table className="table" style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
        <thead>
          <tr>
            <th style={{ width: '16.66%' }}>User</th>
            <th style={{ width: '16.66%' }}>Timestamp</th>
            <th style={{ width: '33.33%' }}>Old Values</th>
            <th style={{ width: '33.33%' }}>New Values</th>
          </tr>
        </thead>
        <tbody>
          {logs.Logs.map((log, index) => {
            return <tr key={index}>
              <td>{log.UserName}</td>
              <td>{Moment.utc(log.DateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
              <td><JsonPretty json={log.OldValues} /></td>
              <td><JsonPretty json={log.NewValues} /></td>
            </tr>
          })}
        </tbody>
      </table>
      {logs.Bids.length > 0 && <table className="table">
        <thead>
          <tr>
            <th>Bid Type</th>
            <th>Timestamp</th>
            <th>Bid Amount</th>
            <th>Successful</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>
          {logs.Bids.map((log, index) => {
            return <tr key={index}>
                  <td>{log.TypeName}</td>
                  <td>{Moment.utc(log.DateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                  <td>{formatNumber(log.Amount, "c")}</td>
                  <td>{log.Successful ? 'Yes' : 'No'}</td>
                  <td>{log.ServerResponse?.startsWith("msg_") ? <a
                      target="_blank"
                      style={{ color: '#007bff' }}
                      href={`https://app.frontapp.com/open/${log.ServerResponse}`}
                    >
                      {log.ServerResponse}
                    </a> : log.ServerResponse}
                  </td>
                </tr>
          })}
        </tbody>
      </table>}
    </Dialog>
  );
}

export default AuditLogs;
