import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import CenterDivPanel from "../../components/CenterDivPanel";
import { fetchApi } from '../../services/api';
import { JsonResponse } from "TypeGen/json-response";

type Props = {
    tripId: number;
    onClose: () => void;
}

const CarrierTripParameters = (props: Props) => {
    const [loading, setLoading] = useState(true);

    const [tripNumber, setTripNumber] = useState<number>(null);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [trailerNumber, setTrailerNumber] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const _save = () => {
        setLoading(true);

        const data = {
            VehicleNumber: vehicleNumber,
            TrailerNumber: trailerNumber,
            TrackingNumber: trackingNumber,
            PhoneNumber: phoneNumber
        }

        fetchApi(`/api/Track/CarrierTripParameters/${props.tripId}`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    props.onClose();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                // If not problem details
                if (!e?.status) alert(e);
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true);
        fetchApi(`/api/Track/CarrierTripParameters/${props.tripId}`)
            .then((response: { VehicleNumber: string, TrailerNumber: string, TrackingNumber: string, PhoneNumber: string, TripNumber: number }) => {
                setVehicleNumber(response.VehicleNumber);
                setTrailerNumber(response.TrailerNumber);
                setTrackingNumber(response.TrackingNumber);
                setPhoneNumber(response.PhoneNumber);
                setTripNumber(response.TripNumber);
                setLoading(false);
            });
    }, [props.tripId]);

    return <>
        <Dialog
            className="dialog-w800"
            title={`Carrier Trip Parameters ${tripNumber ?? ''}`}
            onClose={() => {
                props.onClose();
            }}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <div className="k-form k-form-md p-0">
                <FieldWrapper>
                    <Label>Vehicle Number</Label>
                    <Input
                        maxLength={30}
                        autoFocus
                        disabled={loading}
                        value={vehicleNumber ?? ''}
                        onChange={(e) => setVehicleNumber(e.value ?? '')}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Trailer Number</Label>
                    <Input
                        maxLength={30}
                        disabled={loading}
                        value={trailerNumber ?? ''}
                        onChange={(e) => setTrailerNumber(e.value ?? '')}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Tracking Number</Label>
                    <Input
                        maxLength={30}
                        disabled={loading}
                        value={trackingNumber ?? ''}
                        onChange={(e) => setTrackingNumber(e.value ?? '')}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Phone Number</Label>
                    <FieldWrapper>
                        <MaskedTextBox
                            mask="(000) 000-0000"
                            value={phoneNumber ?? ''}
                            disabled={loading}
                            onChange={(e) => setPhoneNumber(e.value ?? '')}
                        />
                    </FieldWrapper>
                </FieldWrapper>
            </div>

            <DialogActionsBar layout={"end"}>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}>
                    Save
                </Button>
            </DialogActionsBar>
        </Dialog>
    </>

}
export default CarrierTripParameters;