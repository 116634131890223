import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Moment from 'moment';
import { useParams } from 'react-router';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';

type Settings  = {
  IsLinkActive: boolean;
  ShipmentStatus: number;
  Message: string;
  LastSentBy: string;
  RefId: string;
  OrderID: string;
  OrderNo: string;
  Stops: Array<
  {
    CityStateZip: string,
    ScheduledDT: string,
    Sequence: string,
    Event: string,
    Name: string,
    Address: string,
    Pieces: string,
    Weight: string,
    TripStopStatus: string
  }>;
}

type PostParams = {
  Key: string;
  Notes: string;
  DateTimePicker: Date | null;
  CityState: string;
  SentBy: string;
  ShipmentStatus: number;
  TrackingUrl: string;
}

type RouteComponentParams = {
  key: string;
};

const CarrierUpdate = () => {
  const { key: keyParam } = useParams<RouteComponentParams>();
  const statuses = ["Enroute to Pickup", "Arrived at Pickup", "Enroute to Delivery", "Arrived at Delivery", "Completed"];
  const [formsubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<null | Settings>(null);
  const [postparams, setPostParams] = useState<PostParams>({
    Key: keyParam,
    Notes: '',
    DateTimePicker: Moment().toDate(),
    CityState: '',
    SentBy: localStorage.getItem('CarrierUpdate_SentBy') || '',
    ShipmentStatus: -1,
    TrackingUrl: ''
  });

  useEffect(() => {
    fetchApi('/api/Track/CarrierUpdate/' + keyParam)
      .then((response) => {
        setLoading(false);
        setSettings(response);
        setPostParams(prev => {
          return {
            ...prev,
            ShipmentStatus: response.ShipmentStatus
          }
        });
      });
  }, [keyParam]);

  const updateSettings = (value: Partial<PostParams>) => {
    setPostParams(prev => {
      return {
        ...prev,
        ...value
      }
    });
  }

  const saveSettings = () => {
    if (postparams.ShipmentStatus === -1 || postparams.CityState.trim().length === 0 || postparams.SentBy.trim().length === 0) {
      alert('Please fill out all required fields');
      return false;
    } else if (postparams.DateTimePicker === null) {
      alert('Invalid date entered');
      return false;
    }
    setLoading(true);
    fetchApi('/api/Track/CarrierUpdate', postparams, 'post')
      .then(() => {
        setLoading(false);
        setFormSubmitted(true);
      })
      .catch((e) => {
        setLoading(false);
        alert('Our apologies, but the server has failed to process your request.  Please try again later.');
      });

    return true;
  }

  if (loading || settings === null) {
    return (
      <span
        className="k-i-loading k-icon"
        style={{ fontSize: 64, position: 'absolute', left: '50%', top: 50 }}
      />
    );
  } else if (!settings.IsLinkActive) {
    return <div className="container pt-2">
      <div className="alert alert-danger" role="alert">
        {settings.Message}
      </div>
    </div>;
  } else if (formsubmitted) {
    return <div className="container pt-2">
      <div className="alert alert-success" role="alert">
        Update Submitted Successfully.  Thank You!
      </div>
    </div>;
  }

  return (
    <div className="container pt-2">
      <div className="row p-2">
        <div className="col-sm" style={{ textAlign: "left" }}><img src="/images/L1logo.png" height="90" width="225" /></div>
      </div>
      <h4 className="text-center">Trip Information</h4>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="text-right">
              Order No.
            </th>
            <th scope="col">Event</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">Scheduled</th>
            <th scope="col" className="text-right">
              PCS./WT.
            </th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {settings.Stops.map((item, index) => {
            return (
              <tr key={index}>
                <td className='text-right'>
                  {settings ? settings.OrderNo : ""}
                </td>
                <td>
                  {item.Event}
                </td>
                <td>
                  {item.Address}
                </td>
                <td>
                  {item.CityStateZip}
                </td>
                <td>
                  {item.ScheduledDT}
                </td>
                <td className='text-right'>
                  {item.Event === "Pickup" ? item.Pieces + "/" + item.Weight : ""}
                </td>
                <td>
                  {item.TripStopStatus}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Card className="mt-2">
        <CardBody>
          <Form render={() => {
            return <FormElement>
              <FieldWrapper>
                <span>Last Known Position of Truck</span>
                <Input
                  required
                  autoFocus
                  placeholder="City, State"
                  onChange={(event) => {
                    updateSettings({
                      CityState: event.value
                    });
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <span>As of</span>
                <DateTimePicker
                  required
                  value={postparams.DateTimePicker}
                  onChange={(value) => {
                    updateSettings({
                      DateTimePicker: value.value
                    });
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <span>Shipment Status</span>
                <DropDownList
                  valid={postparams.ShipmentStatus !== -1}
                  data={statuses}
                  value={statuses[postparams.ShipmentStatus]}
                  onChange={(event) => {
                    updateSettings({
                      ShipmentStatus: event.target.index
                    });
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <span>Tracking URL</span>
                <Input
                  value={postparams.TrackingUrl}
                  placeholder='https://...'
                  validationMessage="Please enter a valid url!"
                  type='url'
                  onChange={(event) => {
                    updateSettings({
                      TrackingUrl: event.value
                    });
                  }}
                />
              </FieldWrapper>

              <FieldWrapper>
                <TextArea
                  rows={4}
                  placeholder="Comments/Notes"
                  onChange={(event) => {
                    updateSettings({
                      Notes: event.value
                    });
                  }}
                />
              </FieldWrapper>

              <FieldWrapper style={{ width: 200 }}>
                <Input
                  required
                  placeholder="Your Name or Initials"
                  value={postparams.SentBy}
                  onChange={(e) => {
                    updateSettings({ SentBy: e.value });
                    localStorage.setItem('CarrierUpdate_SentBy', e.value);
                  }}
                />
              </FieldWrapper>

              <div className="k-form-buttons">
                <Button
                  onClick={saveSettings}
                  themeColor="primary"
                >
                  Send Trip Update
                </Button>
              </div>

              {settings.LastSentBy && settings.LastSentBy.trim().length > 0 &&
                <div className="k-form-field" style={{ marginTop: "1rem", marginBottom: 0 }}>
                  <div style={{ fontStyle: "italic" }}>{settings.LastSentBy}</div>
                </div>
              }
            </FormElement>
          }} />
        </CardBody>
      </Card>
    </div>
  );
}

export default CarrierUpdate;