import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string | number;
    currentTab: CarrierTabs;
}

export type CarrierTabs = "Profile" | "Contacts" | "Scorecard" | "Bids" | "ServiceExceptions" | "PayHistory" | "WorkloadHistory" | "Vehicles" | "Notes" | "Devices" | "Logs";

export const CarrierNavBar = (props: Props) => {

    const [number, setNumber] = useState("");
    const [links, setLinks] = useState<ILink[]>([]);

    const refresh = (carrierId: string | number) => {
        setLinks([]);
        fetchApi(`/api/Asset/CarrierNavBar/${carrierId}`)
            .then((data: { Number: string, Links: ILink[] }) => {
                setNumber(data.Number);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this carrier?")) {
            return;
        }

        fetchApi(`/api/Asset/ReactivateCarrier/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    const retire = () => {
        if (!window.confirm('Retire Carrier: Are you sure?')) {
            return;
        }

        fetchApi(`/api/Asset/RetireCarrier/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to retire");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    return <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Assets/Carriers"> Carriers</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {number}
                </span>
            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#CarrierNav" aria-controls="CarrierNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="CarrierNav">
                <ul className="navbar-nav">
                    {links.find((x: ILink) => x.Name === 'Carrier') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}`}>Profile</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Contacts') && <li className={`nav-item ${props.currentTab === 'Contacts' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Contacts`}>Contacts</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Scorecard') && <li className={`nav-item ${props.currentTab === 'Scorecard' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Scorecard`}>Scorecard</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Bids') && <li className={`nav-item ${props.currentTab === 'Bids' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Bids`}>Bids</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'ServiceExceptions') && <li className={`nav-item ${props.currentTab === 'ServiceExceptions' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/ServiceExceptions`}>Service Exceptions</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'PayHistory') && <li className={`nav-item ${props.currentTab === 'PayHistory' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/PayHistory`}>Pay History</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'WorkloadHistory') && <li className={`nav-item ${props.currentTab === 'WorkloadHistory' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/WorkloadHistory`}>Workload</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Vehicles') && <li className={`nav-item ${props.currentTab === 'Vehicles' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Vehicles`}>Vehicles</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Notes`}>Notes</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Devices') && <li className={`nav-item ${props.currentTab === 'Devices' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Carrier/${props.id}/Devices`}>Logins</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/ResourceLogs/${props.id}/1`}>Logs</Link>
                    </li>}
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => retire()} themeColor="primary">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}