import { GridCellProps } from '@progress/kendo-react-grid';
import Moment from 'moment-timezone';
import * as React from 'react';

export class DeliveryCell extends React.Component<GridCellProps> {
  public render() {
    return (
      <td>
        {this.props.dataItem.DeliveryLocation}<br />
        {Moment.utc(this.props.dataItem.DeliveryDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    if (nextProps.dataItem.Hash === this.props.dataItem.Hash) {
      return false;
    }

    return true;
  }
}
