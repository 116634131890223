import { Loader } from "@progress/kendo-react-indicators";
import JsonPretty from "components/JsonPretty";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Title } from '../../utils/title';

type RouteComponentParams = {
  carrierId: string;
}

const CarrierAssureReportCard = () => {
  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [carrierAssureData, setCarrierAssureData] = useState('');

  const refreshLogs = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/CarrierAssureReportCard/${carrierIdParam}`)
      .then((data: string) => {
        setLoading(false);
        setCarrierAssureData(data);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }, [carrierIdParam]);

  useEffect(() => {

    refreshLogs();
  }, [refreshLogs]);

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <div className="table table-striped table-hover">
    <JsonPretty json={carrierAssureData} />
  </div>

  return (<>
    <Title string="Logs" />
    <br />
    {loading || !carrierAssureData ? loadingView : dataView()}
  </>);
}

export default CarrierAssureReportCard;
