import React, { useEffect, useState } from 'react';

export enum TimerAction {
  Start = 'Start',
  Stop = 'Stop',
  Restart = 'Restart'
}

interface TimerProps {
  action: TimerAction;
}

const TimerComponent: React.FC<TimerProps> = ({ action }) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [internalAction, setInternalAction] = useState<TimerAction>(action);

  useEffect(() => {
    if (action === TimerAction.Restart) {
      setSeconds(0);
      setInternalAction(TimerAction.Start);
    } else {
      setInternalAction(action);
    }
  }, [action]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (internalAction === TimerAction.Start) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    if (internalAction === TimerAction.Stop) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [internalAction]);

  return (
    <>
      {seconds}
    </>
  );
};

export default TimerComponent;
