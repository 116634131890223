import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class PhoneCellAndExtension extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;


    const phoneNumber = this.props.dataItem.PhoneNumber;
    const ext = this.props.dataItem.PhoneNumberExtension;

    return (
      <td>
        <a href={`tel:${phoneNumber}`} className="text-primary">{phoneNumber}{ext && ` x${ext}`}</a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
