import { ComboBox } from '@progress/kendo-react-dropdowns';
import useLocalStorage from '../../../utils/useLocalStorage';
import { useState } from 'react';
import { fetchApi } from '../../../services/api';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  PurchaseOrderID: number;
  CloseDialog: () => void;
}

const EmailForm = (props: Props) => {

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useLocalStorage('PO-VendorEmails', []);

  const sendEmail = () => {
    setLoading(true);
    fetchApi(`/api/PurchaseOrder/Email/${props.PurchaseOrderID}`, { email }, 'POST')
      .then(() => {
        props.CloseDialog();
        setEmail('');
        setLoading(false);

        // Remember New Email Addresses
        if (emails.find((value) => value === email) === undefined) {
          setEmails([...emails, email]);
        }

        alert('Vendor Email Sent!');
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable send email!');
      });
  };

  return (
    <Dialog title="Email Vendor" onClose={props.CloseDialog}>
      <p>This will send a PDF Purchase Order form to the vendor from your email address.</p>
      <ComboBox
        required
        value={email}
        data={emails}
        onChange={(e) => setEmail(e.value)}
        allowCustom={true}
        placeholder="Vendor Email Address"
        style={{ width: "100%" }}
      />
      <DialogActionsBar layout="end">
        <Button themeColor="primary" disabled={!email || loading} onClick={sendEmail}>Send Email</Button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default EmailForm;