import * as React from 'react';

type Props = {
    children?: React.ReactNode
};

const CenterDivPanel: React.FC<Props> = props => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%'
        }}>
            {props.children}
        </div>
    );
}
export default CenterDivPanel;
