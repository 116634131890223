import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn as Column, GridDetailRowProps, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import Moment from 'moment-timezone';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';
import DateCell from '../../components/cells/DateCell';
import CenterDivPanel from '../../components/CenterDivPanel';
import KendoWindow from '../../components/KendoWindow';
import { getSplitBadgeOrder, getSplitBadgeOrderStop, getSplitLabel, getSplitTitle } from '../../components/SplitIcon';
import { fetchApi } from '../../services/api';
import { isNullOrWhiteSpace } from '../../utils/utils';

type Props = {
  OrderID: number;
  onClose: () => void;
};

type OrderSplitSummary = {
  Title: string;
  BillingOrder: Order;
  SplitOrders: Order[];

  //MenuActionItems: ActionItem[]; //dropdown items to display
  //Errors: string[];
  //Warnings: string[];
  //Links: ILink[];
}

type Order = {
  OrderID: number;
  OrderNumber: number;
  OrderStatusName: string;
  DispatchStatusName: string;
  BilledVehicleTypeName: string;
  TotalPieces: number;
  TotalWeight: number;
  HazMat: boolean;
  DockLevel: boolean;
  ShipDateTime: Date;
  DeliveryDateTime: Date;
  ShipCustomerName: string;
  DeliveryCustomerName: string;
  ShipLocation: string;
  DeliveryLocation: string;
  TotalMiles: number;
  ModNum: number;

  ForwardOrderID: number;
  BackwardOrderID: number;
  BillingOrderID: number;
  TripID: number;
  TripNumber: number;
  VehicleID: number | null;
  VehicleNumber: string;
  DriverID: number | null;
  DriverNumber: string;
  TrailerID: number | null;
  TrailerNumber: string;
  CarrierID: number | null;
  CarrierName: string;

  OrderStops: OrderStop[];

  Expanded: boolean;
}

type OrderStop = {
  OrderStopID: number;
  Sequence: number;
  Event: number;
  EventNumber: string;
  CustomerName: string;
  Location: string;
  ScheduledDateTime: Date;
  Distance: number;
  StopStatus: number;
  StopStatusNumber: string;
  IsSplitLocation: boolean;
}

const SplitOrderSummary = (props: Props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<OrderSplitSummary>();

  const refreshOrderDetails = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Order/OrderSplitSummary`, { OrderID: props.OrderID }, 'POST')
      .then((response: OrderSplitSummary) => {
        setDetails(response);
        setLoading(false);

        //if (response.Errors.length === 0 && (addOrderID > 0 || addOrderNumber > 0))
        //    setSelectedActionItemKey(ActionItemKeyType.AddOrder);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load split summary');
      });
  }, [props.OrderID]);

  useEffect(() => {
    refreshOrderDetails();
  }, [refreshOrderDetails]);

  // Hide on navigate
  useUpdateEffect(() => {
    props.onClose();
  }, [location]);

  return (
    <KendoWindow title={`Order Split Summary`} onClose={props.onClose} autoSizePercentage={85}>
      <div className="container-fluid mt-3">
        {loading && <CenterDivPanel>
          <Loader type="converging-spinner" />
        </CenterDivPanel>}

        {details &&
          <div>

            {details.SplitOrders.map((splitOrder, index) => (
              <div className="card my-2" key={index}>
                <div className="card-body">
                  <OrderView order={splitOrder} splitIndex={index} />
                </div>
              </div>
            ))}

            <div className="hr bg-secondary mt-4 mb-4" style={{ height: '2px' }}></div>

            <div className="card my-2">
              <div className="card-body">
                <OrderView order={details.BillingOrder} isBillingOrder={true} />
              </div>
            </div>

          </div>}
      </div >
      <WindowActionsBar layout={'end'}>
        <Button onClick={props.onClose}>Close</Button>
      </WindowActionsBar>
    </KendoWindow>
  );
};
export default SplitOrderSummary;

type OrderViewProps = {
  order: Order;
  isBillingOrder?: boolean;
  splitIndex?: number;
};

const OrderView: React.FC<OrderViewProps> = (props: OrderViewProps) => {
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  const detailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem as Order;
    //const orderStops = dataItem.OrderStops;

    return (
      <section>
        <div className="card my-1">
          <div className="card-body">
            <h5 className="card-title text-left">Order Stops</h5>
            <table className="table table-hover table-striped mb-0">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Distance</th>
                  <th>Date/Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dataItem.OrderStops.map((orderStop) => {
                  var splitBadge = getSplitBadgeOrderStop(orderStop.IsSplitLocation);
                  return (<tr key={orderStop.OrderStopID}>
                    <td>{orderStop.EventNumber}</td>
                    <td>{orderStop.CustomerName}</td>
                    <td>{orderStop.Location}{splitBadge && <><br />{splitBadge}</>}</td>
                    <td>{orderStop.Distance}</td>
                    <td>{Moment.utc(orderStop.ScheduledDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</td>
                    <td>{orderStop.StopStatusNumber}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  };

  const LocationHeaderCell = (props: any) => {
    //let splitShipperSuffix: JSX.Element;
    //let splitDeliverySuffix: JSX.Element;

    //if (props.isBillingOrder == null || props.isBillingOrder === false) {
    //    if (props.order.BackwardOrderID > 0)
    //        splitShipperSuffix = getSplitBadgeOrder(props.order.BackwardOrderID, props.order.ForwardOrderID);
    //    if (props.order.ForwardOrderID > 0)
    //        splitDeliverySuffix = getSplitBadgeOrder(props.order.BackwardOrderID, props.order.ForwardOrderID);
    //}

    //return (
    //    <span>
    //        {props.locationType === 'Shipper' ? <span>From&nbsp;{splitShipperSuffix}</span> : <span>To&nbsp;{splitDeliverySuffix}</span>}
    //    </span>
    //);

    return (
      <span>
        {props.locationType === 'Shipper' ? 'From' : 'To'}
      </span>
    );
  };

  const LocationCell = (props: any) => {
    const dataItem = props.dataItem as Order;

    let splitShipperSuffix: JSX.Element;
    let splitDeliverySuffix: JSX.Element;

    if (props.isBillingOrder == null || props.isBillingOrder === false) {
      if (dataItem.BackwardOrderID > 0)
        splitShipperSuffix = getSplitBadgeOrder(dataItem.BackwardOrderID, dataItem.ForwardOrderID);
      if (dataItem.ForwardOrderID > 0)
        splitDeliverySuffix = getSplitBadgeOrder(dataItem.BackwardOrderID, dataItem.ForwardOrderID);
    }

    return (
      <td>
        {props.locationType === 'Shipper' ? dataItem.ShipCustomerName : dataItem.DeliveryCustomerName}
        <br />
        {props.locationType === 'Shipper' ?
          <>{dataItem.ShipLocation}{splitShipperSuffix && <><br />{splitShipperSuffix}</>}</> :
          <>{dataItem.DeliveryLocation}{splitDeliverySuffix && <><br />{splitDeliverySuffix}</>}</>
        }
      </td>
    );
  };

  const MileCell = (props: any) => {
    const dataItem = props.dataItem as Order;

    return (
      <td>
        {dataItem.TotalMiles}
        <br />
        {dataItem.OrderStatusName}
      </td>
    );
  };

  const TripCell = (props: any) => {
    const dataItem = props.dataItem as Order;

    if (dataItem.TripNumber === 0)
      return (<td>{dataItem.DispatchStatusName}</td>);

    if (isNullOrWhiteSpace(dataItem.CarrierName)) {
      return (
        <td>
          <Link style={{ color: "#007bff" }} to={`/Trip/${dataItem.TripID}`}>{dataItem.TripNumber}</Link><br />
          <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${dataItem.VehicleID}`}>{dataItem.VehicleNumber}</Link><br />
          <Link style={{ color: "#007bff" }} to={`/Assets/Driver/${dataItem.DriverID}`}>{dataItem.DriverNumber}</Link>
          {isNullOrWhiteSpace(dataItem.TrailerNumber) === false && <>
            <br /><Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${dataItem.TrailerID}`}>{dataItem.TrailerNumber}</Link>
          </>}
          <br />{dataItem.DispatchStatusName}
        </td >
      );
    }

    return (
      <td>
        <Link style={{ color: "#007bff" }} to={`/Trip/${dataItem.TripID}`}>{dataItem.TripNumber}</Link><br />
        <Link style={{ color: "#007bff" }} to={`/Assets/Carrier/${dataItem.CarrierID}`}>{dataItem.CarrierName}</Link><br />
        {dataItem.DispatchStatusName}
      </td>
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    event.dataItem.Expanded = !event.dataItem.Expanded;
    setOrder({ ...event.dataItem });
  };

  return (
    <div>
      <h5 className="card-title text-left">
        {(props.isBillingOrder == null || props.isBillingOrder === false) ? `${props.splitIndex + 1} - ${getSplitTitle(order?.BackwardOrderID, order?.ForwardOrderID)}` : 'Original Billing Order'}
        &nbsp;
        {(props.isBillingOrder == null || props.isBillingOrder === false) && getSplitLabel(order?.BackwardOrderID, order?.ForwardOrderID)}
      </h5>
      <Grid
        data={order ? new Array(order) : undefined}
        detail={detailComponent}
        expandField="Expanded"
        onExpandChange={expandChange}
      >
        <Column field="OrderNumber" title="Order #" cell={(props) => <td>
          <Link style={{ color: "#007bff" }} to={`/Order/${props.dataItem.OrderID}`}>{props.dataItem.OrderNumber}</Link>
        </td>} />
        <Column field="ShipLocation"
          cell={(cellProps) => <LocationCell {...cellProps} locationType='Shipper' isBillingOrder={props.isBillingOrder} />}
          headerCell={(cellProps) => <LocationHeaderCell {...cellProps} {...props} locationType='Shipper' isBillingOrder={props.isBillingOrder} />} />
        <Column field="ShipDateTime" title="Pick-Up Date" cell={DateCell} />
        <Column field="DeliveryLocation" title="To" cell={(cellProps) => <LocationCell {...cellProps} locationType='Consignee' isBillingOrder={props.isBillingOrder} />}
          headerCell={(cellProps) => <LocationHeaderCell {...cellProps} {...props} locationType='Delivery' isBillingOrder={props.isBillingOrder} />} />
        <Column field="DeliveryDateTime" title="Delivery Date" cell={DateCell} />
        <Column field="TotalMiles" title="Miles/Status" cell={(cellProps) => <MileCell {...cellProps} />} />
        <Column field="TripNumber" title="Trip Info" cell={(cellProps) => <TripCell {...cellProps} />} />
      </Grid>
    </div>
  );
};