import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../../types/link';
import { VehicleValue } from '../Vehicles';

export default function vehicleReserveCell(
  reserve: (vehicleId: number) => void,
  open: (vehicleId: number) => void,
) {
  return class extends React.Component<GridCellProps> {
    render() {
      const vehicle = this.props.dataItem as VehicleValue;
      return (
        <td>
          <button
            disabled={vehicle.Links.filter((x: ILink) => x.Name === "VehicleReserve").length === 0}
            title={vehicle.IsReservedByMe ? 'Reserved by me' : ''}
            className={`btn btn-sm ${vehicle.IsReserved ?
              (vehicle.IsReservedByMe ? 'btn-danger' : 'btn-warning') :
              'btn-success'}`}
              onClick={(e) => {
                if (vehicle.IsReserved) {
                  open(vehicle.VehicleID);
                } else {
                  reserve(vehicle.VehicleID);
                }
                e.preventDefault();
              }}
            >{vehicle.IsReserved ? 'Reserved' : 'Reserve'}
            </button>
        </td>
      );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
      return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
  };
}
