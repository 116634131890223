import * as React from 'react';
import { AutoComplete, DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { EnterStopViewModel } from '.';
import { singleSearch, ISingleSearchLocation } from '../../services/pcmiler';
import moment from 'moment';
import { getStopVerb } from '../../views/Quote/GetStopVerb';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import SimpleTimePicker from '../../components/SimpleTimePicker';
import timezoneFormatter from '../../services/formatting/timezone';
import { InputPrefix, InputSuffix } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { CustomerAutocomplete } from '.';
import { filterBy } from '@progress/kendo-data-query';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { QuoteStopType } from 'TypeGen/Quote/quote-stop-type';
import { QuoteStopVerb } from 'TypeGen/Quote/quote-stop-verb';
import { getQuoteStopType } from 'views/Quote/GetStopType';
import { SvgIcon } from "@progress/kendo-react-common";
import { exclamationCircleIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';

type State = {
  isLoadingLocations: boolean;
  ValidationRequestHashLocation: string;
  CustomerFilter: string;
  LocationOptions: ISingleSearchLocation[];
}

type Props = {
  stop: EnterStopViewModel;
  index: number;
  location: EnterStopViewModel;
  customers: CustomerAutocomplete[]
  onChange: (newStop: EnterStopViewModel, changeType: string) => void;
};

const stopVerbs = [
  { text: "Protect", value: QuoteStopVerb.Protect },
  { text: "Ready Now", value: QuoteStopVerb.ReadyNow },
  { text: "Window", value: QuoteStopVerb.Window },
];

class EnterStop extends React.Component<Props, State> {

  private timeout: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoadingLocations: false,
      ValidationRequestHashLocation: '',
      LocationOptions: [],
      CustomerFilter: '',
    }

    this.setStopType = this.setStopType.bind(this);
    this.setStopVerb = this.setStopVerb.bind(this);
    this.setInputType = this.setInputType.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateDateTime = this.updateDateTime.bind(this);
    this.updateLatestDate = this.updateLatestDate.bind(this);
    this.updateLatestTime = this.updateLatestTime.bind(this);
    this.updateLatestDateTime = this.updateLatestDateTime.bind(this);
  }

  renderTimeInputGroup = () => {
    return <InputSuffix>
      <DropDownList
        style={{ width: 128 }}
        data={stopVerbs}
        value={stopVerbs.find(x => x.value === this.props.stop.StopVerb)}
        textField='text'
        onChange={(e) => this.setStopVerb(e.target.value.value as QuoteStopVerb)}
      />
    </InputSuffix>;
  }

  itemRenderLocation = (li:  React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span style={{ fontSize: ".75rem" }}>{itemProps.dataItem.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
 }

  itemRenderCustomer = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <span>
        <>{li.props.children} <span style={{ opacity: 0.75 }}>{itemProps.dataItem.AddressLine1} {itemProps.dataItem.CityName}, {itemProps.dataItem.State}</span></>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  listNoDataRenderCustomer = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
        <SvgIcon icon={exclamationCircleIcon} size="xxlarge" />
        <br />
        <br />
        No Recent Customers Found.
        <br />
        Use the City/Zip option instead.
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  render() {
    const dateTime = moment(this.props.stop.DateTime);
    const latestDateTime = this.props.stop.StopVerb === QuoteStopVerb.Window ? moment(this.props.stop.LatestDateTime) : undefined;
    const stopTypeName = getQuoteStopType(this.props.stop.StopType);
    const inputTypeName = this.props.stop.InputType === 0 ? 'City/Zip': 'Customer';
    const customers = this.state.CustomerFilter
      ? filterBy(this.props.customers, {
          logic: 'or',
          filters: [
            {
              field: 'CustomerName',
              ignoreCase: true,
              operator: 'contains',
              value: this.state.CustomerFilter
            },
            {
              field: 'AddressLine1',
              ignoreCase: true,
              operator: 'startswith',
              value: this.state.CustomerFilter
            },
            {
              field: 'CityName',
              ignoreCase: true,
              operator: 'startswith',
              value: this.state.CustomerFilter
            }]
        })
      : this.props.customers;

    return (
      <React.Fragment key={this.props.index}>
      <div className="form-row">
        <div className="col-md-1">
          <DropDownButton
            disabled
            className="w-100"
            buttonClass="w-100"
            themeColor="primary"
            text={`${stopTypeName} ${this.props.index + 1}`}
            onItemClick={(e) => {
              if (e.itemIndex === 0) {
                //this.props.addStop(this.props.stop.StopType === 0 ? 1 : 0);
              } else if (e.itemIndex === 1) {
                //this.props.addPair();
              } else if (e.itemIndex === 2) {
                //this.props.remove();
              }
            }}
          >
            <DropDownButtonItem text={this.props.stop.StopType === QuoteStopType.Pickup ? "Add Delivery" : "Add Pickup"} icon="plus" svgIcon={plusIcon} />
            <DropDownButtonItem text="Add Pair" icon="plus" svgIcon={plusIcon} />
            <DropDownButtonItem text="Remove Stop" icon="trash" svgIcon={trashIcon} />
          </DropDownButton>
        </div>
        <div className="form-group col-md-5">
          <div className="input-group">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{inputTypeName}</button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#" onClick={(e) => this.setInputType(e, 0)}>City/Zip</a>
                <a className="dropdown-item" href="#" onClick={(e) => this.setInputType(e, 1)}>Customer</a>
              </div>
            </div>
            {this.props.stop.InputType === 0 ?
              <AutoComplete
                data={this.state.LocationOptions}
                value={this.props.stop.Location}
                style={{ flex: 1, borderRadius: 0 }}
                loading={this.state.isLoadingLocations}
                placeholder='Enter Location'
                itemRender={this.itemRenderLocation}
                onChange={(event) => {

                  // Update Location
                  let location = event.target.value;
                  if (typeof event.target.value === 'object') {
                    const place = event.target.value as any;
                    location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                  }
                  this.updateStop({ Location: location, CustomerID: 0, CustomerNumber: '' }, (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION");

                  // Update Autocomplete Suggestions
                  if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      const requestHash = Math.random().toString(36).substring(7);
                      this.setState({
                        isLoadingLocations: true,
                        ValidationRequestHashLocation: requestHash
                      });
                      singleSearch(event.target.value)
                        .then((response) => {
                          if (this.state.ValidationRequestHashLocation === requestHash) {
                            this.setState({ isLoadingLocations: false, LocationOptions: response.Locations });
                          }
                        })
                        .catch(() => {
                          if (this.state.ValidationRequestHashLocation === requestHash) {
                            this.setState({ isLoadingLocations: false, LocationOptions: [] });
                          }
                        })
                    }, 350);
                  }
                }}
              />
            :
              <DropDownList
                filterable
                onFilterChange={(event) => this.setState({ CustomerFilter: event.filter.value })}
                className="k-dropdown-wrap-append k-dropdown-wrap-prepend flex-fill w-auto"
                data={customers}
                textField="CustomerName"
                dataItemKey="CustomerID"
                defaultValue={{
                  CustomerID: 0,
                  CustomerName: 'Select Customer'
                }}
                itemRender={this.itemRenderCustomer}
                listNoDataRender={this.listNoDataRenderCustomer}
                onChange={(e) => {
                  this.updateStop({ 
                    Location: e.value.ZipCode + ' ' + e.value.CityName + ', ' + e.value.State,
                    CustomerID: e.value.CustomerID,
                    CustomerNumber: e.value.CustomerNumber },
                    (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION");
                }}
              />}
            <div className="input-group-append">
              <span className="input-group-text">{timezoneFormatter(this.props.location ? this.props.location.Location.TimeZone : "")}</span>
            </div>
          </div>
        </div>
        <div className="form-group col-md-3">
          <SimpleDatePicker
            inputProps={{
            prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
            </InputPrefix>
            }}
            value={dateTime.toDate()}
            onChange={this.updateDate}
          />
        </div>
        <div className="form-group col-md-3">
          <SimpleTimePicker
            value={dateTime.toDate()}
            onChange={this.updateTime}
            inputProps={{
              suffix: this.renderTimeInputGroup
            }}
          />
        </div>
      </div>
      {this.props.stop.StopVerb === QuoteStopVerb.Window && <div className="form-row">
        <div className="form-group col-md-2 offset-md-4 text-right">
          <label className="col-form-label">Latest</label>
        </div>
        <div className="form-group col-md-3">
          <SimpleDatePicker
            value={latestDateTime?.toDate()}
            onChange={this.updateLatestDate}
            inputProps={{
              valid: dateTime.isSameOrBefore(latestDateTime, 'day'),
              prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
              </InputPrefix>
            }}
          />
        </div>
        <div className="form-group col-md-3">
          <SimpleTimePicker
            value={latestDateTime?.toDate()}
            onChange={this.updateLatestTime}
            inputProps={{ valid: dateTime.isSameOrBefore(latestDateTime, 'minute') }}
          />
        </div>
      </div>}
      </React.Fragment>
    );
  }

  private setStopType(e: any, StopType: QuoteStopType) {
    e.preventDefault();

    this.updateStop({ StopType }, "STOP_TYPE");
  }

  private setStopVerb(StopVerb: QuoteStopVerb) {
    this.updateStop({
      StopVerb,
      LatestDateTime: StopVerb === 8 ? this.props.stop.DateTime : undefined
    }, "STOP_VERB");
  }

  private setInputType(e: any, InputType: 0|1) {
    e.preventDefault();

    this.updateStop({ InputType }, "STOP_INPUT_TYPE");
  }

  private updateStop(value: Partial<EnterStopViewModel>, changeType: string) {
    const newStop = Object.assign({}, this.props.stop, value);
    this.props.onChange(newStop, changeType);
  }

  private updateDate(date: Date | null) {
    if (date == null) return;
    this.updateDateTime(date ? moment(`${date.toDateString()} ${new Date(this.props.stop.DateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateTime(time: Date | null) {
    if (time == null) return;
    this.updateDateTime(time ? moment(`${new Date(this.props.stop.DateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateLatestDate(date: Date | null) {
    if (date == null) return;
    this.updateLatestDateTime(date ? moment(`${date.toDateString()} ${new Date(this.props.stop.LatestDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateLatestTime(time: Date | null) {
    if (time == null) return;
    this.updateLatestDateTime(time ? moment(`${new Date(this.props.stop.LatestDateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateDateTime(value: moment.Moment | null) {
    if (value == null) {
      this.updateStop({ DateTime: undefined }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
      return;
    }
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      this.updateStop({ 
        DateTime: newDateTime,
        LatestDateTime: (this.props.stop.StopVerb !== QuoteStopVerb.Window || moment(newDateTime).isSameOrBefore(this.props.stop.LatestDateTime)) ? this.props.stop.LatestDateTime : newDateTime
      }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
    }
  }

  private updateLatestDateTime(value: moment.Moment | null) {
    if (value == null) {
      this.updateStop({ LatestDateTime: undefined }, "STOP_LATEST_DATETIME");
      return;
    }
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (this.props.stop.LatestDateTime !== newDateTime) {
      this.updateStop({ LatestDateTime: newDateTime }, "STOP_LATEST_DATETIME");
    }
  }
}

export default EnterStop;
