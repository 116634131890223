import { DropDownList, DropDownListChangeEvent, DropDownListProps } from '@progress/kendo-react-dropdowns';
import { GridColumnMenuFilterUIProps } from '@progress/kendo-react-grid';

interface CustomFilterUIDropdownProps extends GridColumnMenuFilterUIProps, DropDownListProps {
  operator: string;
}

export const CustomFilterUIDropdown = ({ operator, firstFilterProps, defaultItem, ...props }: CustomFilterUIDropdownProps) => {

    const onChange = (event: DropDownListChangeEvent) => {
        if (props.dataItemKey) {
          firstFilterProps.onChange({
            value: event.target.value[props.dataItemKey],
            operator: operator,
            syntheticEvent: event.syntheticEvent
          });
        } else {
          firstFilterProps.onChange({
              value: event.target.value,
              operator: operator,
              syntheticEvent: event.syntheticEvent
          });
        }
    }

    const getValue = () => {
      if (props.data && props.dataItemKey) {
        const dataItemKey = props.dataItemKey;
        return props.data.find(item => item[dataItemKey] === firstFilterProps.value);
      } else {
        return firstFilterProps.value;
      }
    }

    const value = getValue();

    return (
      <div>
        <DropDownList
          {...props}
          onChange={onChange}
          value={value || defaultItem}
        />
      </div>
    );
}