import * as React from 'react';
import { Title } from '../../utils/title';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import Moment from 'moment';
import { Chat, Message, User, Action, ChatMessageTemplateProps } from '@progress/kendo-react-conversational-ui';
import * as signalR from "@microsoft/signalr";
import { throttle } from "lodash";
import { fetchApi } from '../../services/api';
import { LoadOneReconnectPolicy } from '../../services/signalr/retryPolicy';
import LoadingPanel from '../../components/LoadingPanel';
import './style.css';
import DriverCard from './DriverCard';
import { MultiSelect, MultiSelectFilterChangeEvent, DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Checkbox } from '@progress/kendo-react-inputs';

type Props = RouteComponentProps<{
    sessionId?: string;
}>;

type State = {
    // Listing
    isLoadingListing: boolean;
    sessions: ChatSession[];
    allUsers: Array<{ ID: string, Name: string }>;
    filteredUsers: Array<{ ID: string, Name: string }>;

    // Current Session
    isLoadingDetails: boolean;
    currentSessionId: string;
    messages: Message[];
    participants: Array<{ ID: string, Name: string }>;
    newMessage: boolean;

    // Filter
    joinedOnly: boolean;
    groupOnly: boolean;

    // Other
    user: User | null;
    sounds: boolean;
}

type ChatSession = {
    ChatSessionID: string;
    StartedDateTime: Date;
    StartedByDriverID: number;
    DriverNumber: string;
    Status: ChatSessionStatus;
    MessagePreview: string;
    Participants: string;
    Subscribed: boolean;
    MyGroup: boolean;
}

enum ChatSessionStatus {
    Open,
    InProgress,
    Closed
}

export default class DriverChat extends React.Component<Props, State> {

    private connection: signalR.HubConnection|null;
    private typingTimeout!: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoadingListing: false,
            sessions: [],
            allUsers: [],
            filteredUsers: [],

            isLoadingDetails: false,
            currentSessionId: '',
            messages: [],
            participants: [],
            newMessage: false,

            joinedOnly: false,
            groupOnly: false,

            user: null,
            sounds: false,
        }

        this.filterChange = this.filterChange.bind(this);
        this.updateUsers = this.updateUsers.bind(this);
        this.createSignalRConnection = this.createSignalRConnection.bind(this);
        this.keydown = this.keydown.bind(this);
        this.typingIndicator = this.typingIndicator.bind(this);
        this.typingIndicatorThrottled = this.typingIndicatorThrottled.bind(this);
    }

    public componentDidMount() {
        const sessionId = this.props.match.params.sessionId;
        if (sessionId) {
            // Load Chat Session
            this.setState({ isLoadingDetails: true });
            this.fetchChatState(sessionId);
        }

        this.createSignalRConnection();
        this.fetchChatSessions();
        this.fetchAllUsers();

        document.addEventListener("keydown", this.keydown);
    }

    public componentWillUnmount() {
        document.removeEventListener("keydown", this.keydown);
        if (this.connection) {
            this.connection.stop();
        }
    }

    public componentWillReceiveProps(nextProps: Props) {
        // This method runs when incoming props (e.g., route params) change

        const sessionId = nextProps.match.params.sessionId;
        if (sessionId) {
            // Load Chat Session
            this.setState({ isLoadingDetails: true });
            this.fetchChatState(sessionId);
        } else {

            // Click Assets -> Driver Chat when one is loaded
            this.setState({ currentSessionId: '' });
        }
    }

    private keydown(e: KeyboardEvent) {
        var element = (e.target as HTMLElement);

        // Typing Notification
        if (element && element.tagName === "INPUT") {
            this.typingIndicatorThrottled();
            return;
        }

        // Change Channel Up/Down
        const currentSession = this.state.sessions.find(x => x.ChatSessionID == this.state.currentSessionId);
        switch(e.key) {
            case "ArrowUp":
                if (currentSession) {
                    const index = this.state.sessions.indexOf(currentSession);
                    if (index > 0 && this.state.sessions.length > 1) {
                        const nextSession = this.state.sessions[index-1];
                        this.props.history.push(`/Driver/Chat/${nextSession.ChatSessionID}`)
                    }
                }
                break;
            case "ArrowDown":
                if (currentSession) {
                    const index = this.state.sessions.indexOf(currentSession);
                    if (this.state.sessions.length > (index+1)) {
                        const nextSession = this.state.sessions[index+1];
                        this.props.history.push(`/Driver/Chat/${nextSession.ChatSessionID}`)
                    }
                }
                break;
        }
    }

    private async typingIndicator() {
        if (this.connection && this.state.currentSessionId) {
            const session = this.state.sessions.find(x => x.ChatSessionID == this.state.currentSessionId);
            if (session.Status != 2) {
                await this.connection.send('Typing', { ChatSessionID: this.state.currentSessionId, DriverID: session.StartedByDriverID });
            }
        }
    }

    private typingIndicatorThrottled = throttle(this.typingIndicator, 3000, { leading: true, trailing: true });

    public render() {
        const groups = [
            { ID: 'd7efed15-883c-4625-917d-d59dc07f3c58', Name: 'Pay Group' },
            { ID: '68b4da94-8d55-406f-a350-7bfb2f20ca1c', Name: 'Deduction Group' },
            { ID: 'ccab2d31-b680-422d-bd65-ffdda6939f6f', Name: 'Fleet Group' },
            { ID: '95589bd3-10b9-4784-b49f-a617e68a6bc0', Name: 'App Support Group' }
        ];
        const currentSession = this.state.sessions.find(x => x.ChatSessionID == this.state.currentSessionId);
        const chatFilter = { 
            logic: 'or',
            filters: []
        } as CompositeFilterDescriptor;
        if (this.state.joinedOnly) chatFilter.filters.push({ field: 'Subscribed', operator: 'eq', value: true });
        if (this.state.groupOnly) chatFilter.filters.push({ field: 'MyGroup', operator: 'eq', value: true });
        const sessions = filterBy(this.state.sessions, chatFilter);
        return <div className="row">
            <Title string="Driver Chat" />
            <div className="col-lg-4">
                <br />
                {this.state.isLoadingListing && <LoadingPanel />}
                <div className="clearfix">
                    <div className="float-left p-2">
                        <Checkbox
                            label="Chats For My Group"
                            value={this.state.groupOnly}
                            onChange={(e) => this.setState({ groupOnly: e.value })}
                        />
                    </div>
                    <div className="float-left p-2">
                        <Checkbox
                            label="Chats That I Joined"
                            value={this.state.joinedOnly}
                            onChange={(e) => this.setState({ joinedOnly: e.value })}
                        />
                    </div>
                </div>
                {!this.state.isLoadingListing && sessions.length === 0 && <h2>No Chat Sessions found.</h2>}
                {!this.state.isLoadingListing && <div className="list-group">
                    {sessions.map((session, index) => {
                        return <Link to={`/Driver/Chat/${session.ChatSessionID}`} className={`list-group-item list-group-item-action flex-column align-items-start${this.state.currentSessionId === session.ChatSessionID ? ' active' : (session.Status === ChatSessionStatus.Open ? ' chat-waiting' : '')}`} key={index}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{session.MessagePreview} - {this.getStatusName(session.Status)}</h5>
                                <small><TimeAgo date={Moment.utc(session.StartedDateTime).toDate()} /></small>
                            </div>
                            <p className="mb-1">{session.DriverNumber} - {session.Participants || "None"}</p>
                        </Link>
                    })}
                </div>}
            </div>
            <div className="col-lg-8">
                <br />
                {this.state.isLoadingDetails && <LoadingPanel />}
                {!this.state.isLoadingDetails && currentSession && <React.Fragment>
                    <div style={{ maxWidth: '500px', margin: 'auto', display: 'block', marginBottom: '16px' }}>
                        <div>Reassign:</div>
                        <DropDownList
                            style={{ width: '100%' }}
                            disabled={currentSession.Status === 2}
                            data={groups}
                            onChange={(e) => this.reassignGroup(e.target.value)}
                            textField="Name"
                        />
                    </div>
                    <div style={{ maxWidth: '500px', margin: 'auto', display: 'block', marginBottom: '16px' }}>
                        <div>Participants:</div>
                        <MultiSelect
                            style={{ width: '100%' }}
                            disabled={currentSession.Status === 2}
                            data={this.state.filteredUsers}
                            onChange={(e) => this.updateUsers(e.target.value)}
                            filterable={true}
                            onFilterChange={this.filterChange}
                            value={this.state.participants}
                            textField="Name"
                            dataItemKey='ID'
                        />
                    </div>
                    <DriverCard DriverID={currentSession.StartedByDriverID} />
                    <Chat
                        user={this.state.user}
                        messages={this.state.messages}
                        messageTemplate={this.messageTemplate}
                        onMessageSend={(e) => this.sendMessage(e.message.text)}
                        placeholder="Chat Message"
                        {...(currentSession.Status === 2 ? {messageBox: (props) => { return <span>Chat Session Ended</span> }} : {})}
                    />
                </React.Fragment>}
                {(this.state.sounds && this.state.newMessage) &&
                    <audio src="/sounds/NewChatMessage.mp3" onEnded={() => this.setState({ newMessage: false })} autoPlay/>}
            </div>
        </div>
    }

    private messageTemplate(message: ChatMessageTemplateProps) {
        return <div className="k-chat-bubble">
            <span dangerouslySetInnerHTML={{ __html: message.item.text}} /><br />
            <div style={{ width: '100%', textAlign: 'right' }}>
                <span style={{ fontSize: 'smaller' }}>{message.item.timestamp.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit' })}</span>
            </div>
        </div>
    }

    private filterChange(event: MultiSelectFilterChangeEvent) {
        this.setState({
            filteredUsers: filterBy(this.state.allUsers.slice(), event.filter)
        });
    }

    private updateUsers(newUsers: Array<{ ID: string, Name: string }>) {
        const data = {
            ChatSessionID: this.state.currentSessionId,
            ApplicationUserIDs: newUsers.map(x => x.ID),
        }
        fetchApi('/api/DriverChat/UpdateChatUsers', data, 'post')
            .then(() => {
                if (newUsers.length !== 0) {
                    this.setState({ participants: newUsers });
                }
            });
    }

    public reassignGroup(newGroup: { ID: string, Name: string }) {
        const data = {
            ChatSessionID: this.state.currentSessionId,
            GroupID: newGroup.ID,
            GroupName: newGroup.Name
        }
        fetchApi('/api/DriverChat/ReassignChat', data, 'post')
            .then(() => {
                alert('Successfully Reassigned!');
            });
    }

    private createSignalRConnection() {

        // Create Connection
        this.connection = new signalR.HubConnectionBuilder()
          .withAutomaticReconnect(new LoadOneReconnectPolicy())
          .withUrl("/driverChat")
          .configureLogging(signalR.LogLevel.Warning)
          .build();
    
        this.connection.onreconnected(() => {
            this.fetchChatSessions();
        });
    
        // New Session
        this.connection.on('start', () => {
            this.fetchChatSessions();
        });
    
        // Session Closed
        this.connection.on('end', (data: { ChatSessionID: string }) => {
            const session = this.state.sessions.find(x => x.ChatSessionID === data.ChatSessionID);
            if (session) {
                session.Status = 2;

                // Remove reply actions
                if (this.state.currentSessionId === data.ChatSessionID) {
                    this.state.messages.forEach((message) => {
                        if (message.suggestedActions) {
                            message.suggestedActions = message.suggestedActions.filter((action) => action.type !== 'reply');
                        }
                    });
                }
                this.forceUpdate();
            }
        });

        // Update to In-Progress
        this.connection.on('inprogress', (data: { ChatSessionID: string }) => {     
            const session = this.state.sessions.find(x => x.ChatSessionID === data.ChatSessionID);
            if (session) {
                session.Status = 1;
                this.forceUpdate();
            }
        });

        // New Message
        this.connection.on('message', (data: { ChatSessionID: string, ChatMessageID: string, SentByApplicationUserID: string }) => {
            if (this.state.currentSessionId === data.ChatSessionID) {
                if (this.state.user && this.state.user.id !== data.SentByApplicationUserID) {
                    this.setState({ newMessage: true });
                }
                this.fetchChatState(this.state.currentSessionId);
            } else {
                // Alert if Subscribed as well
                const session = this.state.sessions.find(x => x.ChatSessionID === data.ChatSessionID);
                if (session && session.Subscribed) {
                    // TODO: Flash row?
                    this.setState({ newMessage: true });
                }
            }
        });

        // New Session
        this.connection.on('typing', (data: { ChatSessionID: string, UserID: number|string }) => {
            if (this.state.currentSessionId === data.ChatSessionID) {
                clearTimeout(this.typingTimeout);

                // Add driving typing indicator
                let user = this.state.messages.map(x => x.author).find(x => x.id === data.UserID.toString());
                if (!user) {
                    user = this.state.allUsers.map(x => { return { id: x.ID, name: x.Name, avatarUrl: `/api/User/Photo/${x.ID}` } as User}).find(x => x.id === data.UserID.toString());
                }
                if (user) {
                    this.state.messages.push({ author: user, typing: true });
                    this.forceUpdate();
                }
                
                // Remove typing indicator
                this.typingTimeout = window.setTimeout(() => {
                    this.setState(prevState => ({ messages: prevState.messages.filter(x => !x.typing) }));
                }, 5000);
            }
        });
    
        // connect
        this.connection.start()
            .catch((error) => {
                console.error(error);
            });
    }

    private getStatusName(defectStatus: ChatSessionStatus) {
        switch(defectStatus)
        {
            case ChatSessionStatus.Open:
                return "Waiting";
            case ChatSessionStatus.InProgress:
                return "In-Progress";
            case ChatSessionStatus.Closed:
                return "Closed";
            default:
                return "";
        }
    }

    private fetchChatSessions() {
        this.setState({ isLoadingListing: true });
        fetchApi('/api/DriverChat/GetChatSessions')
            .then((response: { Sessions: ChatSession[], User: User, Sounds: boolean }) => {
                this.setState({
                    isLoadingListing: false,
                    sessions: response.Sessions,
                    user: response.User,
                    sounds: response.Sounds
                });
            })
    }

    private fetchAllUsers() {
        fetchApi('/api/User/UsersDropdown')
            .then((response: { Users: Array<{ ID: string, Name: string }> }) => {
                this.setState({ allUsers: response.Users, filteredUsers: response.Users.slice() });
            })
    }

    private fetchChatState(sessionId: string) {
        fetchApi('/api/DriverChat/GetChatState/' + sessionId)
            .then((response: { 
                Messages: Array<{ id: string, author: string, text: string, timestamp: Date, suggestedActions: Action[] }>,
                Users: Array<{ id: string, name: string, avatarUrl: string }>,
                Participants: Array<{ ID: string, Name: string }>,
            }) => {
                var messages = response.Messages.map(msg => {
                    var user = response.Users.find(x => x.id === msg.author) as User;
                    return {
                        id: msg.id,
                        text: msg.text,
                        timestamp: Moment.utc(msg.timestamp).toDate(),
                        author: user,
                        suggestedActions: msg.suggestedActions,
                    }
                }) as Message[];

                this.setState({
                    isLoadingDetails: false,
                    currentSessionId: sessionId,
                    messages,
                    participants: response.Participants,
                });
            })
    }

    private sendMessage(text: string) {
        fetchApi('/api/DriverChat/Send/' + this.state.currentSessionId, { Message: text }, 'post')
            .catch(() => {
                alert('Unable to send message!');
            });
    }
}