import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ILink } from '../../../types/link';
import { openWindow } from '../../../services/openWindow';

export class ContactCell extends React.Component<GridCellProps> {
  public render() {
    return (<td>
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindow(this.props.dataItem.Links.find((x: ILink) => x.Name === 'Contact').Link);
          e.preventDefault();
        }}
      >
        {this.props.dataItem.ContactName}
      </a>
    </td>)
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    if (nextProps.dataItem.Hash === this.props.dataItem.Hash) {
        return false;
    }

    return true;
  }
}
