import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon, graphIcon } from "@progress/kendo-svg-icons";

type Props = {
  id: string;
  currentTab: SalespersonTabs;
}

export type SalespersonTabs = "Profile";

export const SalespersonNavBar = (props: Props) => {
  const history = useHistory();
  const [number, setNumber] = useState("");
  const [links, setLinks] = useState<ILink[]>([]);

  const refresh = (salespersonId: string) => {
    setLinks([]);
    fetchApi(`/api/Customer/SalespersonNavBar/${salespersonId}`)
      .then((data: { Number: string, Links: ILink[] }) => {
        setNumber(data.Number);
        setLinks(data.Links);
      });
  }

  const reactivate = () => {
    if (!window.confirm("Are you sure you want to reactivate this salesperson?")) {
      return;
    }

    fetchApi(`/api/Customer/ReactivateSalesperson/${props.id}`, {}, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          refresh(props.id);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to reactivate");
      });
  }

  const retire = () => {
    if (!window.confirm('Retire Salesperson: Are you sure?')) {
      return;
    }

    fetchApi(`/api/Customer/RetireSalesperson/${props.id}`, {}, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          refresh(props.id);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unable to retire");
      });
  }

  useEffect(() => {
    refresh(props.id);
  }, [props.id]);

  const gotoDashboard = () => {
    history.push(`/SalesReports/${props.id}`);
  }

  return <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
      <span className="navbar-brand">
        <Link to="/Salespeople">Salespeople</Link>
        <span className="text-muted">
          <SvgIcon icon={chevronRightIcon} />
          {number}
        </span>
      </span>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#SalespersonNav" aria-controls="SalespersonNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="SalespersonNav">
        <ul className="nav navbar-nav ml-auto">
          {links.find((x: ILink) => x.Name === 'Dashboard') && <Button icon="graph" svgIcon={graphIcon} themeColor="secondary" className="mr-2" onClick={gotoDashboard}>Dashboard</Button>}
          {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={() => retire()} themeColor="primary">Retire</Button>}
          {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={() => reactivate()} themeColor="primary">Reactivate</Button>}
        </ul>
      </div>
    </nav>
  </>;
}