import { formatNumber } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import { ChargeType, QuoteValue } from "./Quote";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import realFormatter from "services/formatting/number";
import { fetchApi } from "services/api";
import CustomerAccessorialRatesPopup from "views/Order/Rate/CustomerAccessorialRatesPopup";
import { ApiLink } from "TypeGen/api-link";

type AccessorialRate = {
  Description: string;
  RateDescriptionID: number;
  Break01: number;
  Break02: number;
  Break03: number;
  Break04: number;
  Break15: number;
}

type AccessorialRateResponse = {
  CustomerNumber: string;
  Rates: AccessorialRate[];
}

type Props = {
  data: QuoteValue; // Existing Rate
  distance: number;
  vehicleTypeId: number;
  billingCustomerId: number;
  fuelRate: number;
  chargeTypes: ChargeType[];

  close: () => void;
  addCharge: (data: { ChargeType: ChargeType, Quantity: number, QuantityUoM: number, Rate: number, RateUoM: number, Amount: number }) => void;
}

const AddChargeDialog = (props: Props) => {

  const [chargeTypesFiltered, setChargeTypesFiltered] = useState<ChargeType[]>(props.chargeTypes);
  const [chargeRate, setChargeRate] = useState<number>(0);
  const [chargePercentage, setChargePercentage] = useState<number>(props.fuelRate * 100);
  const [chargeAmount, setChargeAmount] = useState<number>(0);
  const [chargeType, setChargeType] = useState<number>(0);
  const [ratingCustomerId, setRatingCustomerId] = useState<number>(0);
  const [showCustomerAccessorialRates, setShowCustomerAccessorialRates] = useState(false);
  const [accessorialRates, setAccessorialRates] = useState<AccessorialRate[]>([]);

  useEffect(() => {
    setChargePercentage(props.fuelRate * 100);
  }, [props.fuelRate]);

  useEffect(() => {
    if (props.billingCustomerId) {
      fetchApi('/api/Quote/RatingCustomerByBillingCustomer/' + (props.billingCustomerId))
        .then((response: { RatingCustomerID: number }) => {
          setRatingCustomerId(response.RatingCustomerID);
        })
        .catch(() => {
          setRatingCustomerId(0);
        });
    }

  }, [props.billingCustomerId]);

  useEffect(() => {
    if (ratingCustomerId) {
      fetchApi('/api/Quote/CustomerAccessorialRates/' + ratingCustomerId)
        .then((response: AccessorialRateResponse) => {
          setAccessorialRates(response.Rates);
        })
        .catch(() => {
          setAccessorialRates([]);
        });
    } else {
      setAccessorialRates([]);
    }

  }, [ratingCustomerId]);

  const getRateType = (): string => {
    if (chargeType === 100 || chargeType === 5000) return 'mileage';
    if (chargeType === 5001) return 'percentlh';
    if (chargeType === 50) return 'percentfuel';
    return 'flat';
  }

  const filterChargeTypeChange = (event: ComboBoxFilterChangeEvent) => {
    setChargeTypesFiltered(filterChargeTypeData(event.filter));
  }

  const filterChargeTypeData = (filter: FilterDescriptor) => {
    const data = props.chargeTypes.slice();
    return filterBy(data, filter);
  }

  const handleChargeTypeChangeEvent = (e: ComboBoxChangeEvent) => {
    if (e.target.value) {
      setChargeType(e.target.value.RateDescriptionID);
      const contractRate = accessorialRates.filter(x => x.RateDescriptionID === e.target.value.RateDescriptionID)[0];
      if (props.data && e.target.value.RateDescriptionID === 5001) {
        setChargeAmount((chargePercentage / 100) * props.data.LineHaulAmount);
      } else if (contractRate && props.vehicleTypeId) {
          const breakKey = `Break${props.vehicleTypeId.toString().padStart(2, '0')}` as keyof AccessorialRate;
          if (contractRate[breakKey] !== undefined) setChargeAmount(contractRate[breakKey] as number);
      } else {
        setChargeAmount(0);
      }
    }
  }

  const resetFilterChangeTypes = (e: ComboBoxCloseEvent) => {
    setChargeTypesFiltered(props.chargeTypes);
  }

  const addCharge = () => {

    if (!chargeAmount) {
      alert("Please enter a charge amount");
      return;
    }
    if (!chargeType) {
      alert("Please select a charge type");
      return;
    }

    const chargeTypeObj = props.chargeTypes.filter(x => x.RateDescriptionID === chargeType)[0];

    const rateType = getRateType();
    if (rateType === 'mileage') {
      props.addCharge({
        ChargeType: chargeTypeObj,
        Quantity: props.distance,
        QuantityUoM: 2,
        Rate: chargeRate,
        RateUoM: 8,
        Amount: chargeAmount,
      });
    } else if (rateType === 'percentlh') {
      props.addCharge({
        ChargeType: chargeTypeObj,
        Quantity: props.data.LineHaulAmount,
        QuantityUoM: 3,
        Rate: chargePercentage / 100,
        RateUoM: 3,
        Amount: chargeAmount,
      });
    } else if (rateType === "percentfuel") {
      var linehaul = chargeAmount / (1 + (chargePercentage / 100));
      props.addCharge({
        ChargeType: chargeTypeObj,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: linehaul,
        RateUoM: 8,
        Amount: linehaul
      });
      if (chargePercentage > 0) {
        props.addCharge({
          ChargeType: props.chargeTypes.filter(x => x.RateDescriptionID === 5001)[0],
          Quantity: linehaul,
          QuantityUoM: 3,
          Rate: chargePercentage / 100,
          RateUoM: 3,
          Amount: chargeAmount - linehaul
        });
      }
    } else {
      props.addCharge({
        ChargeType: chargeTypeObj,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: chargeAmount,
        RateUoM: 8,
        Amount: chargeAmount,
      });
    }

    props.close();
  }

  const rateType = getRateType();

  return <Dialog title="Add Charge" onClose={props.close} className='dialog-w800'>
    <div className="form-group">
      <ComboBox
        style={{ width: '100%' }}
        data={chargeTypesFiltered}
        value={props.chargeTypes.find(x => x.RateDescriptionID === chargeType)}
        textField="Description"
        filterable={true}
        onFilterChange={filterChargeTypeChange}
        placeholder="Select Charge Type..."
        onClose={resetFilterChangeTypes}
        onChange={handleChargeTypeChangeEvent}
      />
    </div>
    {rateType === 'mileage' && <div className="form-group">
      <label>Rate&nbsp;</label>
      <NumericTextBox
        defaultValue={chargeRate}
        format="c2"
        min={0}
        width={120}
        spinners={false}
        value={chargeRate}
        onChange={(e) => {
          if (e.value) {
            setChargeRate(e.value);
            setChargeAmount(e.value * props.distance);
          }
        }}
      /> x {realFormatter(props.distance)} miles
      <br /><br />
      Total: {chargeAmount !== null && formatNumber(chargeAmount, 'c')}
    </div>}
    {rateType === 'percentlh' && <div className="form-group">
      <label>Rate&nbsp;</label>
      <NumericTextBox
        min={0}
        max={100}
        width={120}
        value={chargePercentage}
        onChange={(e) => {
          if (e.value !== null && props.data) {
            setChargePercentage(e.value);
            setChargeAmount((e.value / 100) * props.data.LineHaulAmount);
          }
        }}
      /> x {formatNumber(props.data.LineHaulAmount, "c")} line haul
      <br /><br />
      Total: {chargeAmount !== null && formatNumber(chargeAmount, 'c')}
    </div>}
    {rateType === 'percentfuel' && <div className="form-group">
      <label>Amount:</label>
      <NumericTextBox
        defaultValue={chargeAmount}
        format="c2"
        min={0}
        width={120}
        spinners={false}
        value={chargeAmount}
        onChange={(e) => setChargeAmount(e.value)}
      />
      &nbsp;- Fuel:&nbsp;
      <NumericTextBox
        min={0}
        max={100}
        width={120}
        value={chargePercentage}
        onChange={(e) => {
          if (e.value !== null && props.data) {
            setChargePercentage(e.value);
          }
        }}
      />%
      <br /><br />
      Total: {chargeAmount !== null && formatNumber(chargeAmount, 'c')}
    </div>}
    {rateType === 'flat' && <div className="form-group">
      <label>Amount: </label>
      <NumericTextBox
        defaultValue={chargeAmount}
        format="c2"
        min={0}
        width={120}
        spinners={false}
        value={chargeAmount}
        onChange={(e) => setChargeAmount(e.value)}
      />
    </div>}
    {showCustomerAccessorialRates && <CustomerAccessorialRatesPopup
      Link={{ Link: '/api/Quote/CustomerAccessorialRates/' + ratingCustomerId, Method: 'GET' } as ApiLink}
      SelectAccessorial={(RateDescriptionID, Rate) => {
        setChargeType(RateDescriptionID);
        setChargeAmount(Rate);
        setShowCustomerAccessorialRates(false);
      }}
      CloseDialog={() => setShowCustomerAccessorialRates(undefined)}
    />}
    <DialogActionsBar>
      <Button disabled={ratingCustomerId === 0} onClick={() => setShowCustomerAccessorialRates(true)}>Accessorial Rates</Button>
      <Button themeColor="primary" onClick={addCharge}>Add Charge</Button>
    </DialogActionsBar>
  </Dialog>
}

export default AddChargeDialog;