import { Popup } from '@progress/kendo-react-popup';
import { GridCellProps } from "@progress/kendo-react-grid";
import * as React from 'react';
import { MenuOption } from '.';
import { openWindow } from '../../services/openWindow';
import OutsideAlerter from '../../components/OutsideAlerter';
import { DataSourceCustomerResult } from 'TypeGen/Customer/List/data-source-customer-result';
import { Link } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { fileIcon, gearIcon } from '@progress/kendo-svg-icons';

export default function customerActionCell(SelectedRecords: DataSourceCustomerResult[],
  LoadDialogScreen: (SelectedRowValues: DataSourceCustomerResult, SelectedMenuOption: MenuOption) => void) {
  return class ActionPopup extends React.Component<GridCellProps> {

    private showMenu: boolean;
    private someSelected: boolean = SelectedRecords.length > 0;
    private anchor: React.RefObject<Button>;

    constructor(props: GridCellProps) {
      super(props);

      this.anchor = React.createRef();

      this.showMenu = false;
    }

    render() {
      return (
        <td>
          <Button
            togglable
            selected={this.showMenu}
            size="small"
            svgIcon={gearIcon}
            disabled={this.someSelected && !this.props.dataItem.Selected} //allow active button if none are selected or only for the ones selected
            onClick={(e) => {
              this.showMenu = !this.showMenu;
              this.forceUpdate();
              e.preventDefault();
            }}
            ref={this.anchor}
          />
          {this.props.dataItem.HasDocuments && <Button
            size="small"
            themeColor='primary'
            className='ml-2'
            svgIcon={fileIcon}
            onClick={(e) => {
              this.showMenu = false;
              LoadDialogScreen(
                {
                  CustomerID: this.props.dataItem.CustomerID,
                  CustomerNumber: this.props.dataItem.CustomerNumber
                } as DataSourceCustomerResult,
                MenuOption.Documents
              );
              e.preventDefault();
              this.forceUpdate();
            }}
          />}
          <div>
            {this.anchor.current && <Popup anchor={this.anchor.current.element} show={this.showMenu} popupClass={'popup-content'}>
              <OutsideAlerter toggle={() => { this.showMenu = !this.showMenu; this.forceUpdate(); }} style={{ display: 'contents' }}>
                <Link style={{ color: "#007bff" }} to={`/Customers/Customer/${this.props.dataItem.CustomerID}`}>Profile</Link>
                <Link style={{ color: "#007bff" }} to={`/Customers/Customer/${this.props.dataItem.CustomerID}/Contacts`}>Contacts</Link>
                <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen(
                      {
                        CustomerID: this.props.dataItem.CustomerID,
                        CustomerNumber: this.props.dataItem.CustomerNumber
                      } as DataSourceCustomerResult,
                      MenuOption.Documents
                    );
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Documents</a>
                <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen(
                      {
                        CustomerID: this.props.dataItem.CustomerID,
                        CustomerNumber: this.props.dataItem.CustomerNumber,
                        CustomerName: this.props.dataItem.CustomerName
                      } as DataSourceCustomerResult,
                      MenuOption.InvoiceEmailSettings
                    );
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Inv. Email Options</a>
                <a
                  href="#"
                  style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                  onClick={(e) => {
                    this.showMenu = false;
                    LoadDialogScreen(
                      {
                        CustomerID: this.props.dataItem.CustomerID,
                        CustomerNumber: this.props.dataItem.CustomerNumber
                      } as DataSourceCustomerResult,
                      MenuOption.SetupNotifications
                    );
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >Setup Notifications</a>
                <Link style={{ color: "#007bff" }} to={`/Customers/Customer/${this.props.dataItem.CustomerID}/RateStructures`}>Customer Rates</Link>
              </OutsideAlerter>
            </Popup>}
          </div>
        </td>
      );
    }
  }
}
