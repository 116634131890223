import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { formatNumber } from '@progress/kendo-intl';
import { PurchaseOrderLineItem, PurchaseOrderStatus } from ".";
import PurchaseOrderGeneralLedgerAccountAutoComplete from "./POGeneralLedgerAccountAutoComplete";
import { isNullOrWhiteSpace } from "../../utils/utils";

type Props = {
  status: PurchaseOrderStatus;
  lineItem: PurchaseOrderLineItem;
  index: number;
  onChange: (newLineItem: PurchaseOrderLineItem, index: number) => void;
};

export const EnterLineItem = (props: Props) => {

  const updateLineItem = (value: Partial<PurchaseOrderLineItem>) => {
    const newLineItem = Object.assign({}, props.lineItem, value);
    props.onChange(newLineItem, props.index);
  }

  return <div className="form-row">
    {props.status >= PurchaseOrderStatus.Approved &&
      <div className="form-group col-md-4">
        <label className="col-form-label">GL Code</label>
        <PurchaseOrderGeneralLedgerAccountAutoComplete
          placeholder={'Enter GL Code'}
          required //={props.status === PurchaseOrderStatus.Approved}
          selectedPurchaseOrderGeneralLedgerAccountID={props.lineItem.GLCode}
          style={{ width: '100%' }}
          onSelectedPurchaseOrderGeneralLedgerAccount={(e) => {            
            updateLineItem({ GLCode: e?.GeneralLedgerAccountID ?? 0 })
            if (e?.GeneralLedgerAccountID > 0 && isNullOrWhiteSpace(props.lineItem.Description))
              updateLineItem({ Description: e?.GLDescription });
          }}
        />
      </div>}
    <div className="form-group col-md-4">
      <label className="col-form-label">Description</label>
      <Input
        required
        maxLength={200}
        className="form-control"
        value={props.lineItem.Description}
        onChange={(e) => updateLineItem({ Description: e.target.value.toString() })}
      />
    </div>
    <div className="form-group col-md-2">
      <label className="col-form-label">Quantity</label>
      <NumericTextBox
        valid={props.lineItem.Quantity > 0}
        min={0}
        spinners={false}
        format="n0"
        value={props.lineItem.Quantity}
        onChange={(e) => updateLineItem({ Quantity: e.value || 0 })}
        width="100%"
      />
    </div>
    <div className="form-group col-md-2">
      <label className="col-form-label">Unit Price</label>
      <div className="input-group flex-nowrap">
        <NumericTextBox
          required
          valid={props.lineItem.UnitPrice > 0}
          format="c2"
          className="k-numeric-wrap-append flex-fill"
          placeholder="$0.00"
          onChange={e => updateLineItem({ UnitPrice: e.value || 0 })}
          value={props.lineItem.UnitPrice}
          spinners={false}
          step={0}
          min={0}
        />
        <div className="input-group-append">
          <span className="input-group-text">{formatNumber(props.lineItem.Quantity * props.lineItem.UnitPrice, "c")}</span>
        </div>
      </div>
    </div>
  </div>
}