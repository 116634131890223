import * as React from 'react';
import moment from 'moment';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { SelectedRowValues } from './AssetPanel';
import TimeAgo from 'react-timeago';
import { Dialog } from '@progress/kendo-react-dialogs';

interface Props {
  SelectedRowValues: SelectedRowValues,
  CloseDialog: () => void;
}

interface State {
  isLoading: boolean;
  forms: HealthForm[];
}

type HealthForm = {
  Id: number;
  CompletedDateTime: Date;
  Travel: boolean;
  Contact: boolean;
  TravelContact: boolean;
  Symptoms: boolean;
}

export class HealthFormsDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      forms: [],
    }
  }

  public componentWillMount() {
    fetchApi(`/api/Asset/DriverHealthForms/${this.props.SelectedRowValues.DriverID}`)
      .then((forms: HealthForm[]) => {
        this.setState({ isLoading: false, forms });
      })
      .catch(() => {
        this.setState({ isLoading: false});
        alert('Unable to Load Assessment Forms');
      });
  }

  public render() {
    return (
      <Dialog title={`COVID-19 Self Assessment - ${this.props.SelectedRowValues.DriverNumber}`} onClose={this.props.CloseDialog}>
        {this.state.isLoading && <LoadingPanel />}
        <table className="table">
          <thead>
            <tr>
              <th>Submitted</th>
              <th>Travel</th>
              <th>Contact</th>
              <th>Travel Contact</th>
              <th>Symptoms</th>
            </tr>
          </thead>
          <tbody>
            {this.state.forms.map((form) => {
              return <tr>
                <td>
                  <a
                    target="_blank"
                    href={`/api/Asset/DriverHealthForm/${form.Id}`}
                  >
                    <TimeAgo date={moment.utc(form.CompletedDateTime).toDate()} />
                  </a>
                </td>
                <td>{form.Travel ? 'YES' : 'NO'}</td>
                <td>{form.Contact ? 'YES' : 'NO'}</td>
                <td>{form.TravelContact ? 'YES' : 'NO'}</td>
                <td>{form.Symptoms ? 'YES' : 'NO'}</td>
              </tr>
            })}
          </tbody>
        </table>
        {this.state.forms.length == 0 && <p>No Self Assessments Submitted</p>}
      </Dialog>
    );
  }
}
