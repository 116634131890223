import * as React from 'react';
import { formatNumber } from '@progress/kendo-intl';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class ColorPercentCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;
    const value = this.props.dataItem[this.props.field];

    return (
      <td className={(value > 0) ? 'text-success' : (value < 0) ? 'text-danger' : ''}>
        {formatNumber(value, "p")}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
