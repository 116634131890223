import * as React from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import NavBar from './NavBar';
import PopupNavBar from './PopupNavBar';

type Props = {
  children?: React.ReactNode
};

export const Layout: React.FC<Props> = props => {
  const { status } = useThemeSwitcher();

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  return (<>
    <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
      <linearGradient id="platinum-carrier-gradient" x2="1" y2="1">
        <stop offset="0%" stopColor="#ef5350" />
        <stop offset="12.5%" stopColor="#f48fb1" />
        <stop offset="25%" stopColor="#7e57c2" />
        <stop offset="37.5%" stopColor="#2196f3" />
        <stop offset="50%" stopColor="#26c6da" />
        <stop offset="62.5%" stopColor="#43a047" />
        <stop offset="75%" stopColor="#eeff41" />
        <stop offset="87.5%" stopColor="#f9a825" />
        <stop offset="100%" stopColor="#ff5722" />
      </linearGradient>
    </svg>
    {window.opener ? <PopupNavBar /> : <NavBar />}
    <div className="container-fluid body-content px-1 px-md-2 px-lg-3">
      {props.children}
      <hr />
      <footer>
        <p>&copy; {new Date().getFullYear()} - Load One LLC</p>
      </footer>
    </div>
  </>);
}

export const CustomerLayout: React.FC<Props> = props => {
  const { status } = useThemeSwitcher();

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  return <>
    <PopupNavBar />
    <div className="container-fluid body-content px-1 px-md-2 px-lg-3">
      {props.children}
      <hr />
      <footer>
        <p>&copy; {new Date().getFullYear()} - Load One LLC</p>
      </footer>
    </div>
  </>;
}
