import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';

export default class DriverNumber extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td>
        <a
          href="#"
          style={{ color: '#007bff' }}
          title={this.props.dataItem.DispatchNote}
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'DriverProfile')[0].Link);
            e.preventDefault();
          }}
        >
          {this.props.dataItem.DriverNumber} {this.props.dataItem.IsNewHire && <span className="badge badge-warning">NEW</span>}
        </a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
