import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { EditableField } from "../../components/EditableField";
import AuditInfo from "../../components/AuditInfo";
import { EditableAddress } from "../../components/EditableAddress";
import { SalespersonNavBar } from "./SalespersonNavBar";
import { ViewModel as SalespersonProfileType } from "TypeGen/Salesperson/Profile/view-model";
import { SalespersonViewModel as SalespersonType } from "TypeGen/Salesperson/Profile/salesperson-view-model";

type RouteComponentParams = {
  salespersonId: string;
};

const SalespersonProfile = () => {

  const { salespersonId: salespersonIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<SalespersonProfileType>();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Customer/Salesperson/${salespersonIdParam}`)
        .then(data => {
          setLoading(false);
          setProfile(data);

        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
    fetchData();
  }, [salespersonIdParam]);


  useEffect(() => {
    fetchProfile();
  }, [salespersonIdParam, fetchProfile]);


  const saveProfile = (value: Partial<SalespersonType>): Promise<void> => {
    return fetchApi(`/api/Customer/Salesperson/${salespersonIdParam}`, updateProfileState(value), 'PUT');
  }

  const updateProfileState = (value: Partial<SalespersonType>): SalespersonType => {
    const newProfile: SalespersonType = Object.assign({}, profile.Salesperson, value);
    setProfile({ ...profile, Salesperson: newProfile });
    return newProfile;
  }

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <div className="row m-2">
    <div className="col-md-6">
      <div className="form-group row">
        <label htmlFor="FirstName" className="col-sm-2 col-form-label">First Name</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ maxLength: 20 }}
            data={profile.Salesperson.FirstName}
            save={(data) => saveProfile({ FirstName: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="LastName" className="col-sm-2 col-form-label">Last Name</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ maxLength: 25 }}
            data={profile.Salesperson.LastName}
            save={(data) => saveProfile({ LastName: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="TerritoryID" className="col-sm-2 col-form-label">Territory No.</label>
        <div className="col-sm-10">
          <EditableField
            required
            inputProps={{ min: 1 }}
            dataType="number"
            data={profile.Salesperson.TerritoryID}
            save={(data) => saveProfile({ TerritoryID: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="EmailAddress" className="col-sm-2 col-form-label">Email Address</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ type: 'email', maxLength: 50 }}
            data={profile.Salesperson.EmailAddress}
            save={async (data) => await saveProfile({ EmailAddress: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Address" className="col-sm-2 col-form-label">Address</label>
        <div className="col-sm-10">
          <EditableAddress
            title="Address"
            data={profile.Salesperson.Address}
            save={(data) => saveProfile({ Address: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Address" className="col-sm-2 col-form-label">Send Quote Emails</label>
        <div className="col-sm-10">
          <EditableField
            dataType="boolean"
            data={profile.Salesperson.SendQuoteEmail}
            save={(data) => saveProfile({ SendQuoteEmail: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Address" className="col-sm-2 col-form-label">Send Book Emails</label>
        <div className="col-sm-10">
          <EditableField
            dataType="boolean"
            data={profile.Salesperson.SendBookEmail}
            save={(data) => saveProfile({ SendBookEmail: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Address" className="col-sm-2 col-form-label">Send Turndown Emails</label>
        <div className="col-sm-10">
          <EditableField
            dataType="boolean"
            data={profile.Salesperson.SendTurndownEmail}
            save={(data) => saveProfile({ SendTurndownEmail: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Address" className="col-sm-2 col-form-label">Send Note Emails</label>
        <div className="col-sm-10">
          <EditableField
            dataType="boolean"
            data={profile.Salesperson.SendMyCustomerNotesEmail}
            save={(data) => saveProfile({ SendMyCustomerNotesEmail: data })}
          />
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <AuditInfo audit={profile.Salesperson.AuditInfo} />
    </div>
  </div>

  return (<>
    <SalespersonNavBar id={salespersonIdParam} currentTab="Profile" />
    <Title string={profile?.Salesperson?.FirstName} />
    {loading || !profile ? loadingView : dataView()}
  </>);
}

export default SalespersonProfile;