import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';

export type PurchaseOrderGeneralLedgerAccountViewModel = {
  GeneralLedgerAccountID: number;
  GLAccountCode: string;
  GLDescription: string;
  Hash: string;
  Links: ILink[];
}

type DisplayPurchaseOrderGeneralLedgerAccountViewModel = {
  GLAccountCode: string;
  GLDescription: string;
}

type Props = {
  onSelectedPurchaseOrderGeneralLedgerAccount: (customer: PurchaseOrderGeneralLedgerAccountViewModel) => void;
  selectedPurchaseOrderGeneralLedgerAccountID?: number;
  setFocus?: boolean;
} & AutoCompleteProps

export type PurchaseOrderGeneralLedgerAccountAutoCompleteViewModel = PurchaseOrderGeneralLedgerAccountViewModel & { DisplayValue: string; };

export const GetPurchaseOrderGeneralLedgerAccountAutoCompleteDisplayValue = (customer: DisplayPurchaseOrderGeneralLedgerAccountViewModel): string => {
  const displayValue = `${customer.GLAccountCode} - ${customer.GLDescription}`.toUpperCase();
  return displayValue;
}

const PurchaseOrderGeneralLedgerAccountAutoComplete: React.FC<Props> = ({
  onSelectedPurchaseOrderGeneralLedgerAccount,
  selectedPurchaseOrderGeneralLedgerAccountID,
  setFocus,
  ...autoCompleteProps
}) => {
  const inputAC = useRef<AutoComplete>(null);
  const [loading, setLoading] = useState(false);
  const [customerDropDownListing, setPurchaseOrderGeneralLedgerAccountDropDownListing] = useState<PurchaseOrderGeneralLedgerAccountAutoCompleteViewModel[]>([]);
  const [customerAutoCompleteValue, setPurchaseOrderGeneralLedgerAccountAutoCompleteValue] = useState('');
  const _selectedPurchaseOrderGeneralLedgerAccount = useRef(0);
  const _requestHash = useRef('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((val: string, requestHash: string) => {
      if (val.length > 2 && _selectedPurchaseOrderGeneralLedgerAccount.current === 0) {
        getPurchaseOrderGeneralLedgerAccountListing(generateDataStateByName(val), requestHash);
      }
    }, 750), []);

  const generateDataStateByName = (val: string): GridState => {
    let gs = {
      skip: 0,
      take: 12,
      sort: [{
        field: "GLDescription",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          {
            logic: 'or', filters: [
              { field: 'GLAccountCode', operator: 'contains', value: val },
              { field: 'GLDescription', operator: 'contains', value: val }
            ] as any
          }
        ] as any
      }
    } as GridState;

    return gs;
  }

  const generateDataStateByID = (val: number): GridState => {
    return {
      skip: 0,
      take: 1,
      sort: null,
      filter: {
        logic: 'and', filters: [
          { field: 'GeneralLedgerAccountID', operator: 'eq', value: val }
        ] as any
      }
    } as GridState;
  }

  const getPurchaseOrderGeneralLedgerAccountListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
    setLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/PurchaseOrder/GetGeneralLedgerAccounts?${queryStr}`, {}, 'POST')
      .then(({ Data }) => {
        let customerData: PurchaseOrderGeneralLedgerAccountAutoCompleteViewModel[] = Data;
        setLoading(false);
        if (_requestHash.current === requestHash) {
          customerData.forEach(x => {
            x.DisplayValue = GetPurchaseOrderGeneralLedgerAccountAutoCompleteDisplayValue(x);
          });
          setPurchaseOrderGeneralLedgerAccountDropDownListing(customerData);

          if (populateLabel) {
            setPurchaseOrderGeneralLedgerAccountAutoCompleteValue(`${customerData[0].GLAccountCode} - ${customerData[0].GLDescription}`);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
  }, []);

  const onPurchaseOrderGeneralLedgerAccountListingClose = (event: AutoCompleteCloseEvent) => {
    const value = event.target.value;
    const selectedPurchaseOrderGeneralLedgerAccount = customerDropDownListing.find(x => x.DisplayValue === value);
    setPurchaseOrderGeneralLedgerAccountAutoCompleteValue(selectedPurchaseOrderGeneralLedgerAccount != null ? `${selectedPurchaseOrderGeneralLedgerAccount.GLAccountCode} - ${selectedPurchaseOrderGeneralLedgerAccount.GLDescription}` : '');
    _selectedPurchaseOrderGeneralLedgerAccount.current = selectedPurchaseOrderGeneralLedgerAccount?.GeneralLedgerAccountID;
    onSelectedPurchaseOrderGeneralLedgerAccount(selectedPurchaseOrderGeneralLedgerAccount ?? null);
  };

  useEffect(() => {
    if (selectedPurchaseOrderGeneralLedgerAccountID !== _selectedPurchaseOrderGeneralLedgerAccount.current) {
      if (selectedPurchaseOrderGeneralLedgerAccountID > 0) {
        _selectedPurchaseOrderGeneralLedgerAccount.current = selectedPurchaseOrderGeneralLedgerAccountID;
        _requestHash.current = Math.random().toString(36).substring(7);
        getPurchaseOrderGeneralLedgerAccountListing(generateDataStateByID(selectedPurchaseOrderGeneralLedgerAccountID), _requestHash.current, true);
      } else {
        _selectedPurchaseOrderGeneralLedgerAccount.current = 0;
        setPurchaseOrderGeneralLedgerAccountAutoCompleteValue('');
      }
      if (setFocus) {
        inputAC.current.focus();
      }
    }
  }, [selectedPurchaseOrderGeneralLedgerAccountID, getPurchaseOrderGeneralLedgerAccountListing, setFocus]);

  return <AutoComplete
    ref={inputAC}
    {...autoCompleteProps}
    placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Search GL Code')}
    loading={loading}
    data={customerDropDownListing}
    value={customerAutoCompleteValue}
    textField="DisplayValue"
    onChange={(e) => {
      _selectedPurchaseOrderGeneralLedgerAccount.current = 0;
      if (!e.value) onSelectedPurchaseOrderGeneralLedgerAccount(null);
      setPurchaseOrderGeneralLedgerAccountDropDownListing([]);
      setPurchaseOrderGeneralLedgerAccountAutoCompleteValue(e.value);
      _requestHash.current = Math.random().toString(36).substring(7);
      debounceSearchInput(e.value, _requestHash.current);
    }}
    onClose={(e) => onPurchaseOrderGeneralLedgerAccountListingClose(e)}
  />
}

export default PurchaseOrderGeneralLedgerAccountAutoComplete;
