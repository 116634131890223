import * as React from 'react';
import moment from "moment-timezone";
import { Title } from '../utils/title';
import { fetchApi } from '../services/api';
import { ViewModel as HomeViewModel } from 'TypeGen/Home/Get/view-model';
import { Button, FloatingActionButton } from '@progress/kendo-react-buttons';
import Feedback from 'components/Feedback';
import { useBoolean } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom-v5-compat';
import { formatNumber } from '@progress/kendo-intl';
import { commentIcon, graphIcon } from '@progress/kendo-svg-icons';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [salespersonID, setSalespersonID] = React.useState<number | null>(null);
  const [hasUserGoal, setHasUserGoal] = React.useState<boolean>(false);
  const { value: feedback, setTrue: showFeedback, setFalse: hideFeedback } = useBoolean(false);
  const [state, setState] = React.useState<Omit<HomeViewModel, "FeedbackPrompt"|"SalespersonID"|"HasUserGoal">>({
    MTDOrders: 0,
    MTDRevenue: 0,
    MTDRanking: 0,
    MTDColor: "bg-secondary",
    WTDOrders: 0,
    WTDRevenue: 0,
    WTDRanking: 0,
    WTDColor: "bg-secondary",
    TodayOrders: 0,
    TodayRevenue: 0,
    TodayRanking: 0,
    TodayColor: "bg-secondary",
  });

  React.useEffect(() => {
    fetchApi("/api/User/Home")
      .then((response: HomeViewModel) => {
        setState({
          MTDOrders: response.MTDOrders,
          MTDRevenue: response.MTDRevenue,
          MTDRanking: response.MTDRanking,
          MTDColor: response.MTDColor,
          WTDOrders: response.WTDOrders,
          WTDRevenue: response.WTDRevenue,
          WTDRanking: response.WTDRanking,
          WTDColor: response.WTDColor,
          TodayOrders: response.TodayOrders,
          TodayRevenue: response.TodayRevenue,
          TodayRanking: response.TodayRanking,
          TodayColor: response.TodayColor,
        });
        if (response.FeedbackPrompt) showFeedback();
        setSalespersonID(response.SalespersonID);
        setHasUserGoal(response.HasUserGoal);
      })
      .finally(() => setLoading(false));
  }, [showFeedback]);

  const ordinal = (rank: number) => {
    if (rank === 0) return "N/A";
    return <React.Fragment>{rank}<sup>{["st","nd","rd"][((rank+90)%100-10)%10-1]||"th"}</sup></React.Fragment>;
  }

  const placeholderCard = () => {
    return <div className="col-md-4" aria-hidden>
      <div className="card text-center text-white" >
        <h5 className="card-header placeholder-glow">
          <span className="placeholder col-4" />
        </h5>
        <div className="card-body">
          <h1 className="card-title display-4 placeholder-glow">
            <span className="placeholder col-1" />
          </h1>
          <h1 className="card-title placeholder-glow">
            <span className="placeholder col-4" />
          </h1>
          <p className="lead placeholder-glow">
            <span className="placeholder col-3" />
          </p>
        </div>
      </div>
    </div>
  }

  var currentTime = moment();
  return <React.Fragment>
    <Title string="Home" />
    <br />
    <div className="container">
      <div className="jumbotron">
        <h1 className="display-4">Welcome to Transcend One!</h1>
        <p className="lead">Choose an item from the top menu to get started.</p>
        {salespersonID && <Button themeColor="primary" className='mr-2' onClick={() => navigate(`/SalesReports`)} icon="graph" svgIcon={graphIcon}>View your personal sales dashboard</Button>}
        {hasUserGoal && <Button themeColor="primary" onClick={() => navigate("/UserReports")} icon="graph" svgIcon={graphIcon}>View performance dashboard</Button>}
      </div>
      {loading ? <div className="row">
        {placeholderCard()}
        {placeholderCard()}
        {placeholderCard()}
      </div> : <div className="row">
        <div className="col-md-4">
          <div className={`card text-center text-white ${state.TodayColor}`}>
            <h5 className="card-header">{ordinal(state.TodayRanking)} Overall</h5>
            <div className="card-body">
              <h1 className="card-title display-4" title="Booked Orders">{formatNumber(state.TodayOrders, { useGrouping: true })}</h1>
              <h1 className="card-title" title="Revenue">{formatNumber(state.TodayRevenue, "c")}</h1>
              <p className="lead">{currentTime.format("dddd")}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={`card text-center text-white ${state.WTDColor}`}>
            <h5 className="card-header">{ordinal(state.WTDRanking)} Overall</h5>
            <div className="card-body">
              <h1 className="card-title display-4" title="Booked Orders">{formatNumber(state.WTDOrders, { useGrouping: true })}</h1>
              <h1 className="card-title" title="Revenue">{formatNumber(state.WTDRevenue, "c")}</h1>
              <p className="lead">Week</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={`card text-center text-white ${state.MTDColor}`}>
            <h5 className="card-header">{ordinal(state.MTDRanking)} Overall</h5>
            <div className="card-body">
              <h1 className="card-title display-4" title="Booked Orders">{formatNumber(state.MTDOrders, { useGrouping: true })}</h1>
              <h1 className="card-title" title="Revenue">{formatNumber(state.MTDRevenue, "c")}</h1>
              <p className="lead">{currentTime.format("MMMM")}</p>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {feedback && <Feedback onClose={hideFeedback} />}
    <FloatingActionButton
      icon="comment"
      svgIcon={commentIcon}
      text="Feedback"
      themeColor="primary"
      onClick={showFeedback}
    />
  </React.Fragment>;
}

export default Home;