import * as React from 'react';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DriverValue, SelectedRowValues } from '../AssetPanel';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PolicyLimitsGrid from './PolicyLimitsGrid';
import InfoPromptsGrid from './InfoPromptsGrid';
import CashHistoryGrid from './CashHistoryGrid';
import CardTransactionHistoryGrid from './CardTransactionHistoryGrid';
import { Fade } from "@progress/kendo-react-animation";
import IssueMoneyCodeDialog from './IssueMoneyCodeDialog';
import AdvanceCashDialog from './AdvanceCashDialog';
import OverrideCardDialog from './OverrideCardDialog';
import CardChangeLogGrid from './CardChangeLogGrid';
//import { Tooltip } from '@progress/kendo-react-tooltip';
import AddProductLimitDialog from './AddProductLimitDialog';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import MoneyCodeHistoryGrid from './MoneyCodeHistoryGrid';
import { Notification } from "@progress/kendo-react-notification";
import { FieldWrapper } from '@progress/kendo-react-form';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Label } from '@progress/kendo-react-labels';
import Moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import { arrowRotateCwIcon, bringToFrontIcon, commentIcon, dollarIcon, jsIcon } from '@progress/kendo-svg-icons';

type Props = {
    SelectedRowValues: SelectedRowValues,
    SelectedDrivers: DriverValue[] | null,
    CloseDialog: () => void,
    RefreshParent: () => void
}

type EFSPrerequisitesViewItem = {
    PolicyDescriptions: PolicyDescription[],
    UnassignedCards: string[],
    AssignedCard: EFSCardViewItem,
    AssignedCardPolicy: EFSPolicyViewItem,
    //CardCashHistory: EFSCardCashHistoryViewItem[],
    //CardTransactionHistory: EFSCardTransactionHistoryViewItem[],
}

export type EFSCardTransactionHistoryViewItem = {
    FundedTotal: number,
    TransactionDate: Date,
    POSDate: Date,
    TransactionId: number,
    AuthCode: string,
    Invoice: string,
    LocationId: number,
    LineItems: EFSCardTransactionHistoryLineItemViewItem[],
    Expanded: boolean //for grid
}

export type EFSCardTransactionHistoryLineItemViewItem = {
    Amount: number,
    Category: string,
    Quantity: number
}

export type EFSCardCashHistoryViewItem = {
    Amount: number,
    CashId: string,
    Name: string,
    RefNumber: string,
    Date: Date
}

type PolicyDescription = {
    ContractId: number,
    Description: string,
    PolicyNumber: number
}

type EFSCardViewItem = {
    CardNumber: string,
    Header: EFSCardHeaderViewItem,
    Limits: EFSPolicyLimitsViewItem[]
    Info: EFSInfoPromptsViewItem[]
}

type EFSPolicyViewItem = {
    Header: EFSPolicyHeaderViewItem,
    PolicyNumber: number,
    Limits: EFSPolicyLimitsViewItem[],
    Infos: EFSInfoPromptsViewItem[]
}

type EFSPolicyHeaderViewItem = {
    HandEnter: string,
    Description: string,
    ContractId: number
}

export type EFSPolicyLimitsViewItem = {
    Hours: number,
    Limit: number,
    LimitId: string,
    MinHours: number,
    AutoRollMap: number,
    AutoRollMax: number,
    PolicySource: string, //set locally
}

export type EFSInfoPromptsViewItem = {
    InfoId: string, //PPIN        
    ValidationType: string //EXACT_MATCH REPORT_ONLY
    PolicySource: string, //set locally
    ReportValue: string, //only one of the 4 below will ever be populated
    MatchValue: string,
    //NumericMatchValue: string,
    Value: string
}

type EFSCardHeaderViewItem = {
    PolicyNumber: number,
    LimitSource: string, //BOTH OR POLICY
    LocationSource: string,
    Status: string,
    HandEnter: string,
    Override: boolean
}

export type DriverCardViewItem = {
    CardNumber: string,
    PersonalIdentificationNumber: string,
    Note: string
    NoteExpiryDateTime: Date
}

type DriverCardExtensionViewItem = {
    Note: string
    NoteExpiryDateTime: Date
}

enum GenericPromptType {
    None,
    UnassignCard,
    SaveCardError,
    CancelOverrides,
    RemoveCardProductLimit,
    DeleteCard,
    UnlinkCard
}

const DriverCardMaintenance = (props: Props) => {
    const defaultEFSPrerequisitesState = useRef<EFSPrerequisitesViewItem>({ PolicyDescriptions: [], UnassignedCards: [], AssignedCard: null, AssignedCardPolicy: null });
    const [genericPromptType, setGenericPromptType] = useState<GenericPromptType>(GenericPromptType.None);
    const [isCurrentlyAssignedCardInDatabase, setIsCurrentlyAssignedCardInDatabase] = useState(false);
    const [showTABStrip, setShowTABstrip] = useState(false);
    const [dropdownCardValue, setDropdownCardValue] = useState('');
    const [dropdownPolicyValue, setDropdownPolicyValue] = useState<PolicyDescription>({} as PolicyDescription);
    const [personalIdentificationNumber, setPersonalIdentificationNumber] = useState('');
    const [dropdownCardStatusValue, setDropdownCardStatusValue] = useState('');
    const [dropdownCardHandEnterValue, setDropdownCardHandEnterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadedWithoutErrors, setLoadedWithoutErrors] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const allPolicyLimits = useRef<EFSPolicyLimitsViewItem[]>();
    const allInfoPrompts = useRef<EFSInfoPromptsViewItem[]>();
    const [EFSPrerequisites, setEFSPrerequisites] = useState<EFSPrerequisitesViewItem>(defaultEFSPrerequisitesState.current);
    const [driverCard, setDriverCard] = useState<DriverCardViewItem>(null);
    const [driverCardExtension, setDriverCardExtension] = useState<DriverCardExtensionViewItem>(null);
    const [selectedTAB, setSelectedTAB] = useState(0);
    const [tabRefreshIteration, iterateTABRefresh] = useReducer(x => x + 1, 0);
    const activeCardOptions = ['Active', 'Inactive', 'Hold', 'Deleted']; //modifying options here has implications on web service side.  See setcard documentation.
    const [handEnterCardOptions, setHandEnterCardOptions] = React.useState(['Allow', 'Disallow']); //modifying options here has implications on web service side.  See setcard documentation.
    const [UnassignedCards, setUnassignedCards] = useState<string[]>([]);

    const [showIssueMoneyCodeDialog, setShowIssueMoneyCodeDialog] = useState(false);
    const [showAdvanceCashDialog, setShowAdvanceCashDialog] = useState(false);
    const [showOverrideDialog, setShowOverrideDialog] = useState(false);
    const [showAddProductLimitDialog, setShowAddProductLimitDialog] = useState(false);
    const [showMoneyCodeHistoryDialog, setShowMoneyCodeHistoryDialog] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);


    const toCamelCase = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    const handEnterTranslate = (value: any): string => {
        if (value === false || value === true)
            return handEnterGetPolicyVerbiage(value);
        return toCamelCase(value); //ALLOW =Allow
    };

    //const handEnterAddOptionBasedOnPolicy = useCallback((value: any) => {
    //    //if (value === false || value === true)
    //    if (value != null) {
    //        //handEnterCardOptions.push(handEnterGetPolicyVerbiage(value));
    //        setHandEnterCardOptions(['Allow', 'Disallow', handEnterGetPolicyVerbiage(value)]);
    //    }
    //}, [handEnterCardOptions]);

    const handEnterGetPolicyVerbiage = (value: any): string => { return `Follows Policy (${value === true ? "Allow" : "Disallow"})`; }

    const getEFSPrerequisites = useCallback((CardNumber: string) => {
        //if (driverCard) {
        setLoading(true);
        setShowTABstrip(false);
        setSelectedTAB(0);
        fetchApi(`/api/Asset/GetEFSPrerequisites`, { CardNumber: CardNumber }, 'POST')
            .then((response: EFSPrerequisitesViewItem) => {
                response.PolicyDescriptions = response.PolicyDescriptions.map(x => ({ ContractId: x.ContractId, PolicyNumber: x.PolicyNumber, Description: `${x.PolicyNumber}-${x.Description}` }));
                if (CardNumber) {
                    if (response.UnassignedCards.filter(x => x === CardNumber).length === 0) {
                        response.UnassignedCards.push(CardNumber);
                    }
                }
                setUnassignedCards(response.UnassignedCards);
                if (response.AssignedCardPolicy) {
                    response.AssignedCardPolicy.Limits.forEach((limit) => { limit.PolicySource = "Group" });
                    allPolicyLimits.current = response.AssignedCardPolicy.Limits;
                    response.AssignedCardPolicy.Infos.forEach((info) => { info.PolicySource = "Group" });
                    allInfoPrompts.current = response.AssignedCardPolicy.Infos;
                    //handEnterAddOptionBasedOnPolicy(response.AssignedCardPolicy.Header.HandEnter);

                    if (response.AssignedCardPolicy.Header.HandEnter != null)
                        setHandEnterCardOptions(['Allow', 'Disallow', handEnterGetPolicyVerbiage(response.AssignedCardPolicy.Header.HandEnter)]);

                    setDropdownCardHandEnterValue(handEnterTranslate(response.AssignedCardPolicy.Header.HandEnter));
                }
                if (response.AssignedCard) {
                    setDropdownPolicyValue(response.PolicyDescriptions.find(x => x.PolicyNumber === response.AssignedCard.Header.PolicyNumber));
                    setDropdownCardStatusValue(toCamelCase(response.AssignedCard.Header.Status));
                    if (response.AssignedCard.Header.HandEnter !== "POLICY")
                        setDropdownCardHandEnterValue(handEnterTranslate(response.AssignedCard.Header.HandEnter));

                    //setDropdownCardHandEnterValue(response.AssignedCard.Header.Status.charAt(0).toUpperCase() + response.AssignedCard.Header.Status.slice(1).toLowerCase());

                    if (response.AssignedCard.Limits && response.AssignedCard.Limits.length > 0) {
                        response.AssignedCard.Limits.forEach((limit) => { limit.PolicySource = "Card" });
                        allPolicyLimits.current = allPolicyLimits.current.concat(response.AssignedCard.Limits);
                    }

                    if (response.AssignedCard.Info && response.AssignedCard.Info.length > 0) {
                        response.AssignedCard.Info.forEach((info) => { info.PolicySource = "Card" });
                        //Add all card prompts and only policy prompts not already contained in the card prompt array (EXCEPT perfromed on allInfoPrompts/policy prompts)
                        allInfoPrompts.current = response.AssignedCard.Info.concat(allInfoPrompts.current.filter(x => !response.AssignedCard.Info.some(y => x.InfoId === y.InfoId)));
                    }
                }
                //}
                setEFSPrerequisites(response);
                setLoadedWithoutErrors(true);
                if (CardNumber)
                    setShowTABstrip(true);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to get EFS account prerequisites');
            });
        //} else
        //  setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDriverCardInfo = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/getDriverCardInfo`, { DriverID: props.SelectedRowValues.DriverID }, 'POST')
            .then((response: DriverCardViewItem) => {
                setDriverCard(response);
                setDriverCardExtension(response);
                setPersonalIdentificationNumber(response.PersonalIdentificationNumber || '');
                if (response.CardNumber) {
                    setDropdownCardValue(response.CardNumber);
                    setIsCurrentlyAssignedCardInDatabase(true);
                }
            }).catch(e => {
                if (!e?.status) alert('Unable to get driver card info');
            });
    }, [props.SelectedRowValues.DriverID]);

    const refreshEFSPrerequisites = useCallback((CardNumber: string) => {
        getEFSPrerequisites(CardNumber);
    }, [getEFSPrerequisites]);

    const saveCardChanges = useCallback((refreshParent: boolean) => {
        if (!personalIdentificationNumber || !dropdownCardValue) {
            setGenericPromptType(GenericPromptType.SaveCardError);
            return;
        }

        setLoading(true);
        fetchApi(`/api/Asset/UpdateEFSCard`, {
            CardNumber: dropdownCardValue,
            DriverNumber: props.SelectedRowValues.DriverNumber,
            DriverID: props.SelectedRowValues.DriverID,
            DriverName: props.SelectedRowValues.DriverName,
            PersonalIdentificationNumber: personalIdentificationNumber,
            HandEnter: dropdownCardHandEnterValue,
            Policy: dropdownPolicyValue.PolicyNumber,
            Status: dropdownCardStatusValue
        }, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (!response.Success) {
                    alert(response.ErrorMessage);
                } else {
                    setIsCurrentlyAssignedCardInDatabase(true);
                    getEFSPrerequisites(dropdownCardValue);
                    setHasChanges(false);
                    iterateTABRefresh();
                    if (refreshParent)
                        props.RefreshParent();
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.SelectedRowValues.DriverNumber, props.SelectedRowValues.DriverID, props.SelectedRowValues.DriverName,
        personalIdentificationNumber, dropdownCardValue, dropdownCardHandEnterValue, dropdownPolicyValue.PolicyNumber,
        dropdownCardStatusValue, getEFSPrerequisites, iterateTABRefresh]);

    useEffect(() => {
        getDriverCardInfo();
    }, [getDriverCardInfo]);

    useEffect(() => {
        if (driverCard)
            getEFSPrerequisites(driverCard.CardNumber);
    }, [driverCard, getEFSPrerequisites]);

    const handleSelect = (e: any) => {
        setSelectedTAB(e.selected);
    };

    const unasignCard = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/UpdateEFSCard`, {
            CardNumber: dropdownCardValue,
            DriverID: props.SelectedRowValues.DriverID,
            UnassignCard: true
        }, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (!response.Success) {
                    alert(response.ErrorMessage); //TODO: add to generic prompt
                } else {
                    props.RefreshParent();
                    props.CloseDialog();
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownCardValue, props.SelectedRowValues.DriverID]);

    const unlinkCard = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/UpdateEFSCard`, {
            CardNumber: dropdownCardValue,
            DriverID: props.SelectedRowValues.DriverID,
            FraudCardUnlinkDriver: true
        }, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (!response.Success) {
                    alert(response.ErrorMessage); //TODO: add to generic prompt
                } else {
                    props.RefreshParent();
                    props.CloseDialog();
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownCardValue, props.SelectedRowValues.DriverID]);

    const deleteCard = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/UpdateEFSCard`, {
            CardNumber: dropdownCardValue,
            DriverID: props.SelectedRowValues.DriverID,
            DeleteCard: true
        }, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (!response.Success) {
                    alert(response.ErrorMessage); //TODO: add to generic prompt
                } else {
                    props.RefreshParent();
                    props.CloseDialog();
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownCardValue, props.SelectedRowValues.DriverID]);

    const removeCardOverrides = () => {
        setLoading(true);

        const data = {
            DriverID: props.SelectedRowValues.DriverID,
            RemoveCardOverrides: true
        }
        fetchApi(`/api/Asset/SetEFSCardOverride`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    //iterateTABRefresh();
                    //setEFSPrerequisitesAssignedCardHeaderOverrideValue(false);
                    refreshEFSPrerequisites(dropdownCardValue);
                } else {
                    setLoading(false);
                    alert(response.ErrorMessage);
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
    }

    const setDriverCardNote = () => {
        setLoading(true);

        const data = {
            DriverID: props.SelectedRowValues.DriverID,
            Note: driverCardExtension.Note,
            NoteExpiryDateTime: driverCardExtension.NoteExpiryDateTime
        }
        fetchApi(`/api/Asset/SetDriverCardNote`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    setShowNoteDialog(false);
                    setDriverCard({ ...driverCard, Note: driverCardExtension.Note, NoteExpiryDateTime: driverCardExtension.NoteExpiryDateTime })
                } else {
                    setLoading(false);
                    alert(response.ErrorMessage);
                }
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to update card');
            });
    }

    //const setEFSPrerequisitesAssignedCardHeaderOverrideValue = (value: boolean) => {
    //    setEFSPrerequisites({ ...EFSPrerequisites, AssignedCard: { ...EFSPrerequisites.AssignedCard, Header: { ...EFSPrerequisites.AssignedCard.Header, Override: value } } });
    //}

    const acceptGenericPromptType = () => {
        switch (genericPromptType) {
            case GenericPromptType.UnassignCard:
                unasignCard();
                break;
            case GenericPromptType.DeleteCard:
                deleteCard();
                break;
            case GenericPromptType.CancelOverrides:
                removeCardOverrides();
                break;
            case GenericPromptType.UnlinkCard:
                unlinkCard();
                break;

        }
        setGenericPromptType(GenericPromptType.None);
    }

    const getConfirmationWindowMessage = (): string => {
        switch (genericPromptType) {
            case GenericPromptType.UnassignCard:
                return `Unassign Card ${dropdownCardValue}?\n\nUse this option if the card was assigned by mistake.\n\nIf card is lost, broken or driver is no longer working at L1, use the 'delete card' option instead.\n\nWarning: This option will mark the card as inactive.`
            case GenericPromptType.SaveCardError:
                return `Card and Personal Identification Number must be entered`;
            case GenericPromptType.CancelOverrides:
                return `WARNING: Overrides exist for card.\n\nClick OK to remove or set new overrides.\n\nSee change log to review existing overrides.`;
            case GenericPromptType.RemoveCardProductLimit:
                return `Remove Card Product Limit?`;
            case GenericPromptType.DeleteCard:
                return `Delete Card ${dropdownCardValue}?\n\nUse this option if the card is lost, broken or the driver is no longer working at L1.`;
            case GenericPromptType.UnlinkCard:
                return `Unlink Card ${dropdownCardValue}?\n\nA card in fraud status cannot be modified.  Your only option is to unlink the card from the Load One driver.\n\n
                    Be sure to inform EFS immediately of this card's status.`;
        }
        return "N/A";
    }

    const filterData = (filter: FilterDescriptor) => {
        const data = EFSPrerequisites.UnassignedCards.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event: ComboBoxFilterChangeEvent) => {
        setUnassignedCards(filterData(event.filter));
    };

    return <>
        <Window
            title={`Card Maintenance: ${props.SelectedRowValues.DriverNumber}`} modal
            onClose={() => props.CloseDialog()}
            initialWidth={window.innerWidth / 1.25}
            initialHeight={window.innerHeight / 1.25}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <div>
                            <label>Card Number</label>
                        </div>
                        <div>
                            <ComboBox
                                style={{ width: "100%" }}
                                disabled={loading || isCurrentlyAssignedCardInDatabase || !loadedWithoutErrors}
                                data={UnassignedCards}
                                value={dropdownCardValue}
                                placeholder="No Card Assigned"
                                filterable={true}
                                clearButton={false}
                                onFilterChange={filterChange}
                                onChange={(e) => {
                                    setDropdownCardValue(e.value); setHasChanges(true);
                                    if (isCurrentlyAssignedCardInDatabase == false) {
                                        //getEFSPrerequisites(e.value);
                                        refreshEFSPrerequisites(e.value);
                                    }
                                }} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <label>Group Policy</label>
                        </div>
                        <div>
                            <ComboBox
                                style={{ width: "100%" }}
                                clearButton={false}
                                disabled={loading || !dropdownCardValue || !loadedWithoutErrors}
                                data={EFSPrerequisites.PolicyDescriptions}
                                value={dropdownPolicyValue}
                                placeholder="None"
                                textField="Description"
                                dataItemKey="PolicyNumber"
                                allowCustom={true}
                                onChange={(e) => { setDropdownPolicyValue(e.value); setHasChanges(true); }} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <label>Personal Identification #</label>
                        </div>
                        <div>
                            <Input
                                style={{ width: "100%" }}
                                disabled={loading || !loadedWithoutErrors}
                                value={personalIdentificationNumber}
                                placeholder="Required"
                                maxLength={10}
                                onChange={(e) => { setPersonalIdentificationNumber(e.value); setHasChanges(true); }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4">
                        <div>
                            <label>Card Status</label>
                        </div>
                        <div>
                            <ComboBox
                                clearButton={false}
                                style={{ width: "100%" }}
                                disabled={loading || !dropdownCardValue || !loadedWithoutErrors}
                                data={activeCardOptions}
                                value={dropdownCardStatusValue}
                                placeholder="-"
                                onChange={(e) => { setDropdownCardStatusValue(e.value); setHasChanges(true); }} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <label>Hand Enter</label>
                        </div>
                        <div>
                            <ComboBox
                                style={{ width: "100%" }}
                                disabled={loading || !dropdownCardValue || !loadedWithoutErrors}
                                clearButton={false}
                                data={handEnterCardOptions}
                                value={dropdownCardHandEnterValue}
                                placeholder="-"
                                onChange={(e) => { setDropdownCardHandEnterValue(e.value); setHasChanges(true); }} />
                        </div>
                    </div>
                    {/*TODO: Add animations to buttons below*/}
                    {isCurrentlyAssignedCardInDatabase && loadedWithoutErrors &&
                        <>
                            <div className="col-4">
                                <div className="row">
                                    <div className="col-6 align-self-end pr-1">
                                        <Button disabled={loading}
                                            themeColor={'primary'}
                                            className="float-left" style={{ width: "100%" }}
                                            svgIcon={bringToFrontIcon}
                                            onClick={() => setShowIssueMoneyCodeDialog(true)}>Issue Money Code</Button>
                                    </div>
                                    <div className="col-6 align-self-end pl-1">
                                        <Button disabled={loading}
                                            themeColor={'primary'}
                                            className="float-right" style={{ width: "100%" }}
                                            svgIcon={jsIcon}
                                            onClick={() => setShowMoneyCodeHistoryDialog(true)}>Money Code History</Button>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-6 align-self-end pr-1">
                                        <Button disabled={loading}
                                            themeColor={'primary'}
                                            className="float-left" style={{ width: "100%" }}
                                            svgIcon={dollarIcon}
                                            onClick={() => setShowAdvanceCashDialog(true)}>Advance Cash</Button>
                                    </div>
                                    <div className="col-6 align-self-end pl-1">
                                        {/* <Tooltip openDelay={500} position="bottom" anchorElement="target">*/}
                                        <Button disabled={loading} title={EFSPrerequisites.AssignedCard.Header.Override ? "See change log to review existing overrides or click this button to remove them" : ""}
                                            themeColor={EFSPrerequisites.AssignedCard.Header.Override ? 'warning' : 'primary'}
                                            className={"float-right"} style={{ width: "100%" }}
                                            svgIcon={arrowRotateCwIcon}
                                            onClick={() => {
                                                if (EFSPrerequisites.AssignedCard.Header.Override) {
                                                    setGenericPromptType(GenericPromptType.CancelOverrides);
                                                } else
                                                    setShowOverrideDialog(true);
                                            }}>Overrides: {EFSPrerequisites.AssignedCard.Header.Override ? 'On' : 'Off'}</Button>
                                        {/*  </Tooltip>*/}
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    {driverCard?.Note ?
                                        <div className="col-12 d-flex flex-column flex-grow" onClick={() => setShowNoteDialog(true)}>
                                            <Notification
                                                style={{ padding: "10px", marginTop: "-4px", float: "right", minWidth: "100%", cursor: "pointer" }}
                                                type={{ style: "warning", icon: true }}
                                            >
                                                <span>{driverCard.Note}</span>
                                            </Notification>
                                        </div> :
                                        <>
                                            <div className="col-6 align-self-end pr-1">
                                            </div>
                                            <div className="col-6 align-self-end pl-1">
                                                <Button disabled={loading}
                                                    themeColor={'primary'}
                                                    className="float-right" style={{ width: "100%" }}
                                                    svgIcon={commentIcon}
                                                    onClick={() => setShowNoteDialog(true)}>Add Note</Button>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </>}
                </div>



                {/*{isCurrentlyAssignedCardInDatabase && driverCard?.Note && <div className="text-right mt-1">*/}
                {/*    <Notification*/}
                {/*        style={{ padding: "12px" }}*/}
                {/*        type={{ style: "error", icon: true }}*/}
                {/*    >*/}
                {/*        <span>{driverCard.Note}</span>*/}
                {/*    </Notification>*/}
                {/*</div>}*/}

                {/*<button type="button" className="k-button mr-2" onClick={() => props.CloseDialog()}>Cancel</button>*/}
                <div className="text-center mt-3">
                    <Fade style={{ zIndex: "1" }} transitionEnterDuration={800}>
                        {isCurrentlyAssignedCardInDatabase && loadedWithoutErrors && dropdownCardStatusValue !== "Fraud" &&
                            <>
                                <Button disabled={loading} themeColor={'primary'} onClick={() => setGenericPromptType(GenericPromptType.DeleteCard)}>Delete Card</Button>
                                <Button disabled={loading} themeColor={'primary'} className="ml-2" onClick={() => setGenericPromptType(GenericPromptType.UnassignCard)}>Unassign Card</Button>
                            </>}
                    </Fade>
                    <Fade style={{ zIndex: "1" }} transitionEnterDuration={800} className="ml-2">
                        {hasChanges && loadedWithoutErrors && dropdownCardStatusValue !== "Fraud" &&
                            <>
                                <Button disabled={loading} themeColor={'primary'} onClick={() => saveCardChanges(isCurrentlyAssignedCardInDatabase === false)}>
                                    {isCurrentlyAssignedCardInDatabase ? 'Update' : 'Assign'} Card
                                </Button>
                            </>}
                    </Fade>
                    <Fade style={{ zIndex: "1" }} transitionEnterDuration={800}>
                        {isCurrentlyAssignedCardInDatabase && loadedWithoutErrors && dropdownCardStatusValue === "Fraud" &&
                            <>
                                <Button disabled={loading} themeColor={'primary'} className="ml-2" onClick={() => setGenericPromptType(GenericPromptType.UnlinkCard)}>Unlink Card</Button>
                            </>}
                    </Fade>
                </div>

                {(showTABStrip) && <div className="mt-2">
                    <TabStrip selected={selectedTAB} onSelect={handleSelect} keepTabsMounted={true}
                        tabContentStyle={{ width: '100%' }} style={{ width: '100%' }} animation={false}>
                        <TabStripTab title="Limits">
                            <PolicyLimitsGrid PolicyLimits={allPolicyLimits.current} DriverID={props.SelectedRowValues.DriverID}
                                CardInOverrideState={EFSPrerequisites.AssignedCard ? EFSPrerequisites.AssignedCard.Header.Override : false}
                                //RemoveProductLimit={() => setGenericPromptType(GenericPromptType.RemoveCardProductLimit)}
                                OpenAddProductLimitDialog={() => setShowAddProductLimitDialog(true)} />
                        </TabStripTab>
                        <TabStripTab title="Prompts">
                            <InfoPromptsGrid InfoPrompts={allInfoPrompts.current} />
                        </TabStripTab>
                        <TabStripTab title="Advance Cash History">
                            <CashHistoryGrid DriverID={props.SelectedRowValues.DriverID} IsGridInFocus={selectedTAB === 2} TABRefreshIteration={tabRefreshIteration} />
                        </TabStripTab>
                        <TabStripTab title="Transaction History">
                            <CardTransactionHistoryGrid DriverID={props.SelectedRowValues.DriverID} IsGridInFocus={selectedTAB === 3} TABRefreshIteration={tabRefreshIteration} />
                        </TabStripTab>
                        <TabStripTab title="Change Log">
                            <CardChangeLogGrid DriverID={props.SelectedRowValues.DriverID} IsGridInFocus={selectedTAB === 4} TABRefreshIteration={tabRefreshIteration} />
                        </TabStripTab>
                    </TabStrip>
                </div>}
            </div>

            {showIssueMoneyCodeDialog && <IssueMoneyCodeDialog DriverID={props.SelectedRowValues.DriverID} DriverNumber={props.SelectedRowValues.DriverNumber} DriverName={props.SelectedRowValues.DriverName}
                CloseDialog={() => { setShowIssueMoneyCodeDialog(false); iterateTABRefresh(); }} />}
            {showAdvanceCashDialog && <AdvanceCashDialog DriverID={props.SelectedRowValues.DriverID} DriverNumber={props.SelectedRowValues.DriverNumber} DriverName={props.SelectedRowValues.DriverName}
                CardNumber={dropdownCardValue} CloseDialog={() => { setShowAdvanceCashDialog(false); iterateTABRefresh(); }} />}
            {showOverrideDialog && <OverrideCardDialog DriverID={props.SelectedRowValues.DriverID} DriverNumber={props.SelectedRowValues.DriverNumber} DriverName={props.SelectedRowValues.DriverName}
                CardNumber={dropdownCardValue} PolicyLimits={allPolicyLimits.current} CloseDialog={(hasChanges) => {
                    setShowOverrideDialog(false);
                    if (hasChanges)
                        refreshEFSPrerequisites(dropdownCardValue);
                }} />}
            {showAddProductLimitDialog && <AddProductLimitDialog DriverID={props.SelectedRowValues.DriverID} PolicyLimits={allPolicyLimits.current} CloseDialog={(hasChanges) => {
                setShowAddProductLimitDialog(false);
                if (hasChanges)
                    refreshEFSPrerequisites(dropdownCardValue);
            }} />}

            {genericPromptType != GenericPromptType.None && <Dialog
                title={[GenericPromptType.SaveCardError].find(x => x === genericPromptType) ? 'Error' : 'Confirm'}
                onClose={() => setGenericPromptType(GenericPromptType.None)} width={315}
                height={[GenericPromptType.UnassignCard, GenericPromptType.DeleteCard, GenericPromptType.CancelOverrides, GenericPromptType.UnlinkCard].find(x => x === genericPromptType) ? 400 : 345}>
                <p style={{ margin: "15px", textAlign: "center", fontSize: "larger" }}>
                    {getConfirmationWindowMessage().split('\n').map((item, i) => <p key={i}>{item}</p>)}
                </p>
                <DialogActionsBar>
                    {[GenericPromptType.UnassignCard, GenericPromptType.DeleteCard, GenericPromptType.CancelOverrides, GenericPromptType.UnlinkCard].find(x => x === genericPromptType) ? <>
                        <Button onClick={() => setGenericPromptType(GenericPromptType.None)}>
                            Cancel
                        </Button>
                        <Button themeColor="primary" onClick={() => acceptGenericPromptType()}>
                            OK
                        </Button>
                    </> : <>
                        <Button themeColor="primary" onClick={() => setGenericPromptType(GenericPromptType.None)}>
                            OK
                        </Button>
                    </>}
                </DialogActionsBar>
            </Dialog>}
            {showMoneyCodeHistoryDialog && <Dialog title={"Money Code History"} onClose={() => setShowMoneyCodeHistoryDialog(false)}
                width={window.innerWidth / 1.05}
                height={window.innerHeight / 1.5}>
                <div>
                    <MoneyCodeHistoryGrid />
                </div>
                <DialogActionsBar>
                    <Button onClick={() => setShowMoneyCodeHistoryDialog(false)}>Close</Button>
                </DialogActionsBar>
            </Dialog>}
            {showNoteDialog && <Dialog title={"Driver Card Note"} onClose={() => setShowNoteDialog(false)}
                width={350}
                height={350}>
                <form className="k-form k-form-md p-0">
                    <FieldWrapper>
                        <Label>Note</Label>
                        <TextArea
                            maxLength={300}
                            rows={4}
                            placeholder="Enter note or leave blank..."
                            value={driverCardExtension?.Note}
                            onChange={(e: any) => setDriverCardExtension({ ...driverCardExtension, Note: e.value })}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Note Expires On</Label>
                        <DatePicker
                            min={Moment().local().add(1, 'days').toDate()}
                            format="MM/dd/yyyy"
                            defaultValue={Moment(driverCardExtension?.NoteExpiryDateTime).isValid() ? Moment(driverCardExtension?.NoteExpiryDateTime).toDate() : new Date()}
                            onChange={(e) => { setDriverCardExtension({ ...driverCardExtension, NoteExpiryDateTime: e.value ?? new Date() }) }}
                        />
                    </FieldWrapper>
                </form>
                <DialogActionsBar>
                    <Button onClick={() => setShowNoteDialog(false)}>Cancel</Button>
                    <Button themeColor="primary" onClick={() => setDriverCardNote()}>Save/Update</Button>
                </DialogActionsBar>
            </Dialog>}
        </Window>
    </>
}

export default DriverCardMaintenance;

