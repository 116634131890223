import { GridCellProps } from "@progress/kendo-react-grid";
import { memo } from "react";
import { Link } from 'react-router-dom';

const OrderNumberCell = ({ dataItem }: GridCellProps) => {
  if (!dataItem.OrderID) return <td />;
  return <td>
    <Link
      style={{ color: '#007bff' }}
      title={dataItem.OrderStatus}
      to={`/Order/${dataItem.OrderID}`}
    >
      {dataItem.OrderNumber || "View"}
    </Link>
  </td>
}

export default memo(OrderNumberCell, (prev, next) => prev.dataItem.OrderID === next.dataItem.OrderID);
