import { GridCellProps } from "@progress/kendo-react-grid";
import { FleetMessage, FleetMessageStatus } from '.';
import { memo } from 'react';

const StatusCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as FleetMessage;

  const getStatusCell = (status: FleetMessageStatus) => {
    switch(status)
    {
      case FleetMessageStatus.Unknown: return "Unknown";
      case FleetMessageStatus.Unread: return <span className="badge badge-warning">Unread</span>;
      case FleetMessageStatus.Read: return "Read";
      case FleetMessageStatus.Unsent: return <span className="badge badge-warning">Unsent</span>;
      case FleetMessageStatus.Sent: return "Sent";
      case FleetMessageStatus.Error: return <span className="badge badge-danger">Unsent/Error</span>;
      default: {
        const exhaustiveCheck: never = status;
        throw new Error(exhaustiveCheck);
      }
    }
  }

  return (
    <td>
      {getStatusCell(data.Status)}
    </td>
  );
}

export default memo(StatusCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
