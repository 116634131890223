import './ActionPopup.css';
import { Popup } from '@progress/kendo-react-popup';
import * as React from 'react';
import { Trip } from '.';
import { ILink } from '../../types/link';
import { ComposeEmail } from './ComposeEmail';
import { SetupNotifications } from './SetupNotifications';
import { openWindow } from '../../services/openWindow';
import { ComposeCarrierEmail } from './ComposeCarrierEmail';
import { OrderAuditLogGrid } from './OrderAuditLogGrid';
import AppTracking from './AppTracking';
import ExternalUrl from './ExternalUrl';
import { fetchApi } from '../../services/api';
import CarrierReviews from '../CarrierReviews';
import OutsideAlerter from '../../components/OutsideAlerter';
import Arrive from '../../components/Milestones/Arrive';
import Load from '../../components/Milestones/Load';
import Empty from '../../components/Milestones/Empty';
import Complete from '../../components/Milestones/Complete';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';

interface Props {
    dataItem: Trip;
}

interface State {
    show: boolean;
    loading: boolean;
    links: ILink[];
    composeEmail: boolean;
    setupNotifications: boolean;
    externalUrl: boolean;
    composeCarrierEmail: boolean;
    carrierReviews: boolean;
    orderAuditLog: boolean;
    setupLink: null | ILink;
    orderAuditLink: null | ILink;
    trackingLink: null | ILink;
    arriveLink: null | ILink;
    loadLink: null | ILink;
    emptyLink: null | ILink;
    completeLink: null | ILink;
}

export class ActionPopup extends React.Component<Props, State> {

    private anchor: React.RefObject<Button>;

    constructor(props: Props) {
        super(props);
        this.state = {
            show: false,
            loading: true,
            links: [],
            composeEmail: false,
            setupNotifications: false,
            externalUrl: false,
            composeCarrierEmail: false,
            carrierReviews: false,
            orderAuditLog: false,
            setupLink: null,
            orderAuditLink: null,
            trackingLink: null,
            arriveLink: null,
            loadLink: null,
            emptyLink: null,
            completeLink: null,
        };

        this.anchor = React.createRef();

        this.createQuote = this.createQuote.bind(this);
        this.viewQuote = this.viewQuote.bind(this);
        this.loadLinks = this.loadLinks.bind(this);
    }

    public render() {
        return (
            <React.Fragment>
                <Button
                    togglable
                    selected={this.state.show}
                    style={{ float: 'left', marginRight: '5px' }}
                    onClick={(e) => {
                        this.setState({ show: !this.state.show }, this.loadLinks);
                        e.preventDefault();
                    }}
                    ref={this.anchor}
                >
                    Action
                </Button>
                {this.props.dataItem.HasNotificationsSetup &&
                    <div style={{ float: 'left' }}><a
                        href="#"
                        className="en-badge"
                        onClick={(e) => {
                            this.setState({ setupNotifications: true });
                            e.preventDefault();
                        }}
                    >EN</a></div>}
                <Popup anchor={this.anchor.current?.element} show={this.state.show && this.state.loading == false} popupClass='popup-content'>
                    <OutsideAlerter toggle={() => this.setState({ show: false })} style={{ display: 'contents' }}>
                        {this.state.links.find((x: ILink) => x.Name === 'OrderPlanning') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                openWindow((this.state.links.find((x: ILink) => x.Name === 'OrderPlanning') as ILink).Link);
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Dispatch</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'OrderPlanning2') && <Link
                            to={(this.state.links.find((x: ILink) => x.Name === 'OrderPlanning2') as ILink).Link}
                        >Dispatch</Link>}
                        {this.state.links.find((x: ILink) => x.Name === 'GetArrive') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                const arriveLink = this.state.links.find((x: ILink) => x.Name === 'GetArrive') as ILink;
                                this.setState({ show: !this.state.show, arriveLink });
                                e.preventDefault();
                            }}
                        >Arrive
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'GetLoad') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                const loadLink = this.state.links.find((x: ILink) => x.Name === 'GetLoad') as ILink;
                                this.setState({ show: !this.state.show, loadLink });
                                e.preventDefault();
                            }}
                        >Load
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'GetEmpty') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                const emptyLink = this.state.links.find((x: ILink) => x.Name === 'GetEmpty') as ILink;
                                this.setState({ show: !this.state.show, emptyLink });
                                e.preventDefault();
                            }}
                        >Empty
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'GetComplete') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                const completeLink = this.state.links.find((x: ILink) => x.Name === 'GetComplete') as ILink;
                                this.setState({ show: !this.state.show, completeLink });
                                e.preventDefault();
                            }}
                        >Complete
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'TripUpdate') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                openWindow((this.state.links.find((x: ILink) => x.Name === 'TripUpdate') as ILink).Link);
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Update Trip
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'VehicleMessage') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                openWindow((this.state.links.find((x: ILink) => x.Name === 'VehicleMessage') as ILink).Link);
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Sat Message
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'UploadAnImage2') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                openWindow((this.state.links.find((x: ILink) => x.Name === 'UploadAnImage2') as ILink).Link);
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Documents
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'CarrierForm') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                openWindow((this.state.links.find((x: ILink) => x.Name === 'CarrierForm') as ILink).Link, 920, 1100);
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Carrier Form
                        </a>}
                        {this.props.dataItem.CarrierPhone && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ carrierReviews: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Carrier Reviews
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'MarkCritical') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setCritical(this.state.links.find((x: ILink) => x.Name === 'MarkCritical'));
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Mark Critical</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'UnMarkCritical') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setCritical(this.state.links.find((x: ILink) => x.Name === 'UnMarkCritical'));
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Un-Mark Critical</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'CarrierUpdateEmail') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ composeCarrierEmail: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Request Update</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'AppTracking') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                const trackingLink = this.state.links.find((x: ILink) => x.Name === 'AppTracking') as ILink;
                                this.setState({ show: !this.state.show, trackingLink });
                                e.preventDefault();
                            }}
                        >Setup Tracking
                        </a>}
                        {this.state.links.find((x: ILink) => x.Name === 'StatusUpdateEmail') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ composeEmail: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Send Status Update</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'OrderNotifications') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ setupNotifications: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Setup Notifications</a>}
                        <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ externalUrl: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Add Tracking URL</a>
                        {this.state.links.find((x: ILink) => x.Name === 'OrderAuditLogs') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.setState({ orderAuditLog: true, show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Audit Logs</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'ViewQuote') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.viewQuote();
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Open Quote</a>}
                        {this.state.links.find((x: ILink) => x.Name === 'CreateQuote') && <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                this.createQuote();
                                this.setState({ show: !this.state.show });
                                e.preventDefault();
                            }}
                        >Create Quote</a>}
                    </OutsideAlerter>
                </Popup>

                {this.state.composeEmail && <ComposeEmail
                    CloseDialog={() => this.setState({ composeEmail: false })}
                    SetupNotifications={(setupLink) => this.setState({ composeEmail: false, setupLink })}
                    OrderAuditLogs={(orderAuditLink) => this.setState({ composeEmail: false, orderAuditLink })}
                    Link={(this.state.links.find((x: ILink) => x.Name === 'StatusUpdateEmail') as ILink)} />}
                {this.state.orderAuditLog && <OrderAuditLogGrid
                    CloseDialog={() => this.setState({ orderAuditLog: false })}
                    Link={(this.state.links.find((x: ILink) => x.Name === 'OrderAuditLogs') as ILink)} />}
                {this.state.composeCarrierEmail && <ComposeCarrierEmail
                    CloseDialog={() => this.setState({ composeCarrierEmail: false })}
                    Link={(this.state.links.find((x: ILink) => x.Name === 'CarrierUpdateEmail') as ILink)} />}
                {this.state.carrierReviews && <CarrierReviews
                    CloseDialog={() => this.setState({ carrierReviews: false })}
                    Name={this.props.dataItem.AssetName} PhoneNumber={this.props.dataItem.CarrierPhone} />}
                {this.state.setupNotifications && <SetupNotifications
                    CloseDialog={() => this.setState({ setupNotifications: false })}
                    Link={(this.props.dataItem.Links.find((x: ILink) => x.Name === 'OrderNotifications') as ILink)} />}
                {this.state.externalUrl && <ExternalUrl
                    CloseDialog={() => this.setState({ externalUrl: false })}
                    TripID={this.props.dataItem.TripID}
                    ExternalUrl={this.props.dataItem.ExternalUrl} />}
                {this.state.setupLink && <SetupNotifications
                    CloseDialog={() => this.setState({ setupLink: null })}
                    Link={this.state.setupLink} />}
                {this.state.orderAuditLink && <OrderAuditLogGrid
                    CloseDialog={() => this.setState({ orderAuditLink: null })}
                    Link={this.state.orderAuditLink} />}
                {this.state.trackingLink && <AppTracking
                    CloseDialog={() => this.setState({ trackingLink: null })}
                    Link={this.state.trackingLink} />}
                {this.state.arriveLink && <Arrive
                    CloseDialog={() => this.setState({ arriveLink: null })}
                    Link={this.state.arriveLink} />}
                {this.state.loadLink && <Load
                    CloseDialog={() => this.setState({ loadLink: null })}
                    Link={this.state.loadLink} />}
                {this.state.emptyLink && <Empty
                    CloseDialog={() => this.setState({ emptyLink: null })}
                    Link={this.state.emptyLink} />}
                {this.state.completeLink && <Complete
                    CloseDialog={() => this.setState({ completeLink: null })}
                    Link={this.state.completeLink} />}
            </React.Fragment>
        );
    }

    public componentDidUpdate(nextProps: Props) {
        if (nextProps.dataItem.Hash !== this.props.dataItem.Hash) {
            this.setState({
                show: false,
                loading: true,
                links: [],
                composeEmail: false,
                setupNotifications: false,
                composeCarrierEmail: false,
                carrierReviews: false,
                orderAuditLog: false,
                setupLink: null,
                orderAuditLink: null,
            });
        }
    }

    private loadLinks() {
        if (this.state.show && this.state.loading) {
            fetchApi('/api/Track/GridPopupLinks/', { OrderID: this.props.dataItem.OrderID ?? 0, TripID: this.props.dataItem.TripID }, 'POST')
                .then((response: { Links: ILink[] }) => {
                    this.setState({ loading: false, links: response.Links });
                }).catch(() => alert('Unable to load links'));
        }
    }

    private viewQuote() {
        window.open((this.state.links.find((x: ILink) => x.Name === 'ViewQuote') as ILink).Link, '_blank');
    }

    private createQuote() {
        fetchApi((this.state.links.find((x: ILink) => x.Name === 'CreateQuote') as ILink).Link, {}, 'POST')
            .then((response: { QuoteID: number }) => {
                window.open(`/Quote/Index/${response.QuoteID}`, '_blank');
                this.setState({ loading: true }, this.loadLinks);
            })
            .catch(() => {
                alert("Unable to create quote!")
            });
    }

    private setCritical(link: ILink) {
        fetchApi(link.Link, {}, 'POST')
            .catch(() => {
                alert("Unable to mark critical!")
            });
    }
}
