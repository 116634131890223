import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Moment from 'moment';
import { DropDownListChangeEvent, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { fetchApi } from '../../services/api';
import StopTransitData, { TransitValue } from './StopTransitData';
import { QuoteValue } from '../../views/QuoteCustomer/Quote';
import { debounce } from 'ts-debounce';
import MapPanel from '../../views/Quote/Map';
import { accessorialsFullDriverPay, accessorialsNoDriverPay } from 'views/Quote';
import { Button } from '@progress/kendo-react-buttons';
import Checkbox from '../../components/Checkbox';
import EnterFreight from '../../views/Quote/EnterFreight';
import EnterStop from './EnterStop';
import QuotePanel from './Quote';
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';
import { IDName } from '../../types/idname';
import { QuoteStopVerb } from 'TypeGen/Quote/quote-stop-verb';
import { QuoteStopType } from 'TypeGen/Quote/quote-stop-type';
import { checkIcon } from '@progress/kendo-svg-icons';

type Props = RouteComponentProps<{
  token?: string;
}>;

type State = {
  QuoteID: number;
  Emails: string[];
  AllEmails: string[];
  Stops: EnterStopViewModel[];
  Freight: EnterFreightViewModel[];
  HazMat: boolean;
  DockHigh: boolean;
  LiftGate: boolean;
  AirRide: boolean;
  VehicleTypeID: number;
  AuthorizationCustomerContactID: number;
  AuthorizationCustomerContacts: IDName[];
  Customers: CustomerAutocomplete[];
  Note: string;
  RemainingHoursofService: Date;
  DeadheadMiles: number;
  TransitData: TransitValue | null;
  TransitDataIsLoading: boolean;
  QuoteData: QuoteValue | null;
  QuoteDataIsLoading: boolean;
  QuoteDataShow: boolean;
  Distance: number;
  FuelRate: number;
  PendingChangeTypes: string[];
  ValidationRequestHash: string;
  ValidationRequestHashTransit: string;
  ValidationRequestHashRate: string;
  ValidationRequestHashFuelRate: string;
  IsSaving: boolean;
  Links: ILink[];
  Locations: EnterStopViewModel[];
}

export type EnterStopViewModel = {
  QuoteStopID?: number;
  StopType: QuoteStopType;
  StopVerb: QuoteStopVerb;
  CustomerID: number;
  CustomerNumber: string;
  Location: string | any;
  DateTime: string;
  LatestDateTime?: string;

  // Not Saved
  InputType: number;
}

export type EnterFreightViewModel = {
  QuoteFreightID?: number;
  PickupStopSequence: number;
  DeliveryStopSequence: number;
  Weight: number;
  WeightUnitOfMeasure: 0 | 1;
  Pieces: number;
  Length: number;
  Width: number;
  Height: number;
  DimsUnitOfMeasure: 0 | 1 | 2;
  Stackable: boolean;
  StackableLimit: number;
  Rotatable: boolean;
}

export type CustomerAutocomplete = {
  CustomerID: number;
  CustomerNumber: string;
  CustomerName: string;
  AddressLine1: string;
  CityName: string;
  State: string;
  ZipCode: string;
}

export default class CustomerQuote extends React.Component<Props, State> {

  private vehicleTypes = [
    { value: 0, text: 'Select Truck Size' },
    { value: 15, text: 'SPRINTER (L144xW52xH68) 3000 lbs. 50 mph' },
    { value: 2, text: 'SMALL STRAIGHT (L216xW84xH84) 10000 lbs. 45 mph' },
    { value: 3, text: 'LARGE STRAIGHT (L288xW96xH96) 14000 lbs. 45 mph' },
    { value: 4, text: 'TRACTOR (L636xW102xH110) 44000 lbs. 45 mph' },
  ];

  private readonly multiSelect: React.RefObject<MultiSelect>;

  constructor(props: Props) {
    super(props);

    this.state = this.emptyState();

    this.multiSelect = React.createRef();

    this.addEmail = this.addEmail.bind(this);
    this.addFreight = this.addFreight.bind(this);
    this.copyFreight = this.copyFreight.bind(this);
    this.removeFreight = this.removeFreight.bind(this);
    this.toggleHazMat = this.toggleHazMat.bind(this);
    this.toggleDockHigh = this.toggleDockHigh.bind(this);
    this.toggleLiftGate = this.toggleLiftGate.bind(this);
    this.toggleAirRide = this.toggleAirRide.bind(this);
    this.populateAuthorizationCustomerContacts = this.populateAuthorizationCustomerContacts.bind(this);
    this.populateCustomers = this.populateCustomers.bind(this);
    this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
    this.viewFit = this.viewFit.bind(this);
    this.addQuote = this.addQuote.bind(this);
  }

  public componentDidMount() {
    this.populateAuthorizationCustomerContacts();
    this.populateCustomers();
  }

  render() {
    return <>
      <br />
      <div className="form-row">
        <div className="col-md-2">
          <div className="input-group">
            <select
              className="custom-select"
              value={this.state.AuthorizationCustomerContactID}
              onChange={(e) => this.updateQuote({ AuthorizationCustomerContactID: parseInt(e.target.value) }, "CUSTOMERCONTACT")}
            >
              <option value="0">Select Contact</option>
              {this.state.AuthorizationCustomerContacts.map((contact, index) => {
                return <option key={index} value={contact.ID}>{contact.Name}</option>
              })}
            </select>
          </div>
        </div>
        <div className="col-md-8">
          <MultiSelect
            ref={this.multiSelect}
            allowCustom={true}
            data={this.state.AllEmails}
            style={{ width: '100%' }}
            value={this.state.Emails}
            onChange={(e) => this.addEmail(e.target.value)}
            placeholder="Other Carrier Emails"
          />
        </div>
        <div className="col-md-2">
          {this.state.QuoteID === 0
            ? <Button themeColor="primary" style={{ width: '100%' }} onClick={this.addQuote}>Request Coverage</Button>
            : <Button style={{ width: '100%' }} icon="check" svgIcon={checkIcon} disabled>Requested</Button>}
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="col-md-9">
          {this.state.Stops.map((stop, index) => {
            return <React.Fragment key={index}>
              <EnterStop
                stop={stop}
                index={index}
                location={this.state.Locations[index]}
                customers={this.state.Customers}
                onChange={(data, changeType) => this.updateStop(data, changeType, index)}
              />
              <div className="form-row">
                <div className="col-md-9">
                  {this.state.TransitData && this.state.TransitData.Locations[index - 1] &&
                    <StopTransitData
                      stop={stop}
                      index={index}
                      last={index === this.state.Stops.length - 1}
                      distance={this.state.TransitData.Distance}
                      tolls={this.state.TransitData.Tolls}
                      location={this.state.TransitData.Locations[index - 1]}
                      onChange={(data, changeType) => this.updateStop(data, changeType, index)}
                    />}
                </div>
              </div>
            </React.Fragment>
          })}
        </div>
        <div className="col-md-3">
          <textarea
            maxLength={240}
            className="form-control mb-2"
            style={{ height: '80px' }}
            placeholder="Note"
            value={this.state.Note}
            onChange={(e) => this.updateQuote({ Note: e.target.value }, "NOTE")} />
        </div>
      </div>
      <br />
      {this.state.Freight.map((freight, index) => {
        return <EnterFreight
          key={index}
          index={index}
          freight={freight}
          onChange={(data, changeType) => this.updateFreight(data, changeType, index)}
          add={(copy) => this.addFreight(index, copy)}
          copy={(above) => this.copyFreight(index, above)}
          remove={() => this.removeFreight(index)}
        />
      })}
      <div className="form-row">
        <div className="col-md-1 offset-md-3">
          <div className="form-check">
            <Checkbox id="hazmat" className="form-check-input" defaultValue={this.state.HazMat} value={this.state.HazMat.toString()} handleCheckboxChange={this.toggleHazMat} />
            <label className="form-check-label" htmlFor="hazmat">HazMat</label>
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-check">
            <Checkbox id="dockhigh" className="form-check-input" defaultValue={this.state.DockHigh} value={this.state.DockHigh.toString()} handleCheckboxChange={this.toggleDockHigh} />
            <label className="form-check-label" htmlFor="dockhigh">Dock High</label>
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-check">
            <Checkbox id="liftGate" className="form-check-input" defaultValue={this.state.LiftGate} value={this.state.LiftGate.toString()} handleCheckboxChange={this.toggleLiftGate} />
            <label className="form-check-label" htmlFor="liftGate">Lift Gate</label>
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-check">
            <Checkbox id="airRide" className="form-check-input" defaultValue={this.state.AirRide} value={this.state.AirRide.toString()} handleCheckboxChange={this.toggleAirRide} />
            <label className="form-check-label" htmlFor="airRide">Air Ride</label>
          </div>
        </div>
        <div className="col-md-5">
          <DropDownList
            defaultValue={0}
            value={this.vehicleTypes.find(x => x.value == this.state.VehicleTypeID)}
            popupSettings={{ height: 700, animate: false }}
            data={this.vehicleTypes}
            textField="text"
            onChange={this.handleVehicleTypeChange}
          />
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="col-md-6">
          <QuotePanel
            data={this.state.QuoteData}
            viewFit={this.viewFit}
            showRate={this.state.QuoteDataShow}
            loading={this.state.QuoteDataIsLoading}
          />
        </div>
        <div className="col-md-6">
          {this.state.Locations.length > 1 && <MapPanel locations={this.state.Locations.map(x => x.Location)} style={{ minHeight: 500 }} />}
        </div>
      </div>
    </>
  }

  private addEmail(values: string[]) {
    const newItem = values[values.length - 1];

    // Delete Last
    if (values.length === 0) {
      this.setState({ Emails: [] });
      localStorage.removeItem("Quote-CarrierEmails");
    }

    // Add New
    if (newItem) {
      var emails = newItem.match(/[A-z0-9_+.-]+@[A-z0-9]+.[A-z]{2,4}/g);
      if (emails) {
        values.pop();
        emails.forEach((email: string) => {
          if (values.find((value) => value === email) === undefined) {
            // Add to Dropdown
            values.push(email);

            // Add to localStorage
            var existingEmails = [...this.state.AllEmails];
            if (existingEmails.find((value) => value === email) === undefined) {
              localStorage.setItem("Quote-AllCarrierEmails", JSON.stringify([...existingEmails, email]));
              this.setState(prevState => ({ AllEmails: [...prevState.AllEmails, email] }));
            }
          }
        });

        this.setState({ Emails: values });
        localStorage.setItem("Quote-CarrierEmails", JSON.stringify([...values]));
      }
    }
  }

  private emptyState(): State {
    return {
      QuoteID: 0,
      Emails: JSON.parse(localStorage.getItem("Quote-CarrierEmails")) || [],
      AllEmails: JSON.parse(localStorage.getItem("Quote-AllCarrierEmails")) || [],
      Stops: [{
        StopType: QuoteStopType.Pickup,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(2, "hours").format("MM/DD/YYYY HH:mm"),
        InputType: 0,
      }, {
        StopType: QuoteStopType.Delivery,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(4, "hours").format("MM/DD/YYYY HH:mm"),
        InputType: 0,
      }],
      Freight: [{
        PickupStopSequence: 1,
        DeliveryStopSequence: 2,
        Weight: 0,
        WeightUnitOfMeasure: 0,
        Pieces: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        DimsUnitOfMeasure: 0,
        Stackable: false,
        StackableLimit: 0,
        Rotatable: false,
      }],
      HazMat: false,
      DockHigh: false,
      LiftGate: false,
      AirRide: false,
      VehicleTypeID: 0,
      AuthorizationCustomerContactID: 0,
      AuthorizationCustomerContacts: [],
      Customers: [],
      Note: '',
      DeadheadMiles: 0,
      RemainingHoursofService: new Date(0, 0, 0, 11, 0, 0),
      TransitData: null,
      TransitDataIsLoading: false,
      QuoteData: null,
      QuoteDataIsLoading: false,
      QuoteDataShow: true,
      Distance: 300,
      FuelRate: 0,
      ValidationRequestHash: '',
      ValidationRequestHashTransit: '',
      ValidationRequestHashRate: '',
      ValidationRequestHashFuelRate: '',
      PendingChangeTypes: [],
      IsSaving: false,
      Links: [],
      Locations: [],
    }
  }

  private updateQuote(data: any, changeType: string) {
    this.setState(prevState => ({
      ...data,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType],
    }), () => this.updatePanelsDebounced());
  }

  private updateStop(data: EnterStopViewModel, changeType: string, index: number) {
    const stops = this.state.Stops;
    const locations = this.state.Locations;
    stops[index] = data;
    // These are normally updated by Validate
    if (changeType === "STOP_TYPE" && locations[index]) {
      locations[index].StopType = data.StopType;
    }
    if (changeType === "STOP_VERB" && locations[index]) {
      locations[index].StopVerb = data.StopVerb;
    }
    if ((changeType === "FIRST_STOP_DATETIME" || changeType === "STOP_DATETIME") && locations[index]) {
      locations[index].DateTime = data.DateTime ? Moment(data.DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined;
    }
    if (changeType === "STOP_LATEST_DATETIME" && locations[index]) {
      locations[index].LatestDateTime = data.LatestDateTime ? Moment(data.LatestDateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined;
    }
    this.setState(prevState => ({
        Stops: stops,
        Locations: locations,
        QuoteID: 0,
        PendingChangeTypes: [...prevState.PendingChangeTypes, changeType]
    }), () => this.updatePanelsDebounced());
}

private addFreight(index: number, copy: boolean) {
  let freight = this.state.Freight;
  freight.push({
      PickupStopSequence: freight[index].PickupStopSequence,
      DeliveryStopSequence: freight[index].DeliveryStopSequence,
      Weight: copy ? freight[index].Weight : 0,
      WeightUnitOfMeasure: copy ? freight[index].WeightUnitOfMeasure : 0,
      Pieces: copy ? freight[index].Pieces : 0,
      Length: copy ? freight[index].Length : 0,
      Width: copy ? freight[index].Width : 0,
      Height: copy ? freight[index].Height : 0,
      DimsUnitOfMeasure: copy ? freight[index].DimsUnitOfMeasure : 0,
      Stackable: copy ? freight[index].Stackable : false,
      StackableLimit: copy ? freight[index].StackableLimit : 0,
      Rotatable: copy ? freight[index].Rotatable : false,
    });
    freight = freight.sort((a, b) => a.PickupStopSequence - b.PickupStopSequence || a.DeliveryStopSequence - b.DeliveryStopSequence);
    this.setState(prevState => ({
        Freight: freight,
        QuoteID: 0,
        PendingChangeTypes: [...prevState.PendingChangeTypes, "ADD_FREIGHT"]
    }), () => this.updatePanelsDebounced());
  }

  private copyFreight(index: number, above: boolean) {
    if (index === 0 && above) {
        alert("Cannot copy above the first freight item.");
        return;
    } else if (index === this.state.Freight.length - 1 && !above) {
        alert("Cannot copy below the last freight item.");
        return;
    }
    let freight = this.state.Freight;
    const offset = above ? index - 1 : index + 1;
    freight[index].Weight = freight[offset].Weight;
    freight[index].WeightUnitOfMeasure = freight[offset].WeightUnitOfMeasure;
    freight[index].Pieces = freight[offset].Pieces;
    freight[index].Length = freight[offset].Length;
    freight[index].Width = freight[offset].Width;
    freight[index].Height = freight[offset].Height;
    freight[index].DimsUnitOfMeasure = freight[offset].DimsUnitOfMeasure;
    freight[index].Stackable = freight[offset].Stackable;
    freight[index].StackableLimit = freight[offset].StackableLimit;
    freight[index].Rotatable = freight[offset].Rotatable;

    this.setState(prevState => ({
        Freight: freight,
        QuoteID: 0,
        PendingChangeTypes: [...prevState.PendingChangeTypes, "ADD_FREIGHT"]
    }), () => this.updatePanelsDebounced());
  }

  private removeFreight(index: number) {
    // Prevent removing the last freight item matching the pickup/delivery stop sequence
    const removeFreight = this.state.Freight[index];
    const freightCount = this.state.Freight.filter(x => x.PickupStopSequence === removeFreight.PickupStopSequence && x.DeliveryStopSequence === removeFreight.DeliveryStopSequence).length;
    if (freightCount === 1) {
        alert("You must have at least one freight item for each pickup/delivery stop sequence.");
        return;
    }

    // Remove the freight item
    let freight = this.state.Freight;
    freight.splice(index, 1);
    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "REMOVE_FREIGHT"]
    }), () => this.updatePanelsDebounced());
  }

  private updateFreight(data: EnterFreightViewModel, changeType: string, index: number) {
    const freight = this.state.Freight;
    freight[index] = data;
    this.setState(prevState => ({
      Freight: freight,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType]
    }), () => this.updatePanelsDebounced());
}

  private toggleHazMat = () => {
    this.setState(prevState => ({
      HazMat: !prevState.HazMat,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "HAZMAT"]
    }), () => this.updatePanelsDebounced());
}

private toggleDockHigh = () => {
    this.setState(prevState => ({
      DockHigh: !prevState.DockHigh,
      VehicleTypeID: (!prevState.DockHigh && prevState.VehicleTypeID < 3) ? 3 : prevState.VehicleTypeID,
      QuoteID: 0,
      PendingChangeTypes: [
          ...prevState.PendingChangeTypes,
          ...(!prevState.DockHigh && prevState.VehicleTypeID < 3) ? ["DOCKHIGH", "VEHICLETYPE"] : ["DOCKHIGH"]
      ]
    }), () => this.updatePanelsDebounced());
}

  private toggleLiftGate = () => {
    this.setState(prevState => ({
      LiftGate: !prevState.LiftGate,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "LIFTGATE"]
    }), () => this.updatePanelsDebounced());
  }

  private toggleAirRide = () => {
    this.setState(prevState => ({
      AirRide: !prevState.AirRide,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "AIRRIDE"]
    }), () => this.updatePanelsDebounced());
  }

  private handleVehicleTypeChange(e: DropDownListChangeEvent) {
    const vehicleTypeId = e.target.value.value;
    this.setState(prevState => ({
      VehicleTypeID: vehicleTypeId,
      QuoteID: 0,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "VEHICLETYPE"]
    }), () => this.updatePanelsDebounced());
  }

  private viewFit() {
    var dims = JSON.stringify(this.state.Freight.map(x => { return {
      Pieces: x.Pieces,
      Length: x.Length,
      Width: x.Width,
      Height: x.Height,
      Rotatable: x.Rotatable,
      Stackable: x.Stackable,
      StackableLimit: x.StackableLimit
    }}));
    openWindow('/Quote/Calculate3DFit?Dims=' + encodeURI(dims) + '&VehicleTypeID=' + this.state.VehicleTypeID, 900);    
  }

  private updatePanelsDebounced = debounce(this.updatePanels, 400);
  
  private updatePanels() {
    const data = {
        Stops: this.state.Stops,
        Freight: this.state.Freight,
        VehicleTypeID: this.state.VehicleTypeID,
        BillingCustomerNumber: '',
        AuthorizationCustomerNumber: ''
    }

    // What needs updating?
    const updatePanels = this.requireUpdate(this.state.PendingChangeTypes);
    if (updatePanels.length === 0) return;

    const validationHash = Math.random().toString(36).substring(7);
    const validationHashTransit = updatePanels.find(x => x === "TRANSIT") ? validationHash : this.state.ValidationRequestHashTransit;
    const validationHashRate = updatePanels.find(x => x === "RATE") ? validationHash : this.state.ValidationRequestHashRate;
    const validationHashFuelRate = updatePanels.find(x => x === "FUELRATE") ? validationHash : this.state.ValidationRequestHashFuelRate;

    this.setState({
      ValidationRequestHash: validationHash,
      ValidationRequestHashTransit: validationHashTransit,
      ValidationRequestHashRate: validationHashRate,
      ValidationRequestHashFuelRate: validationHashFuelRate,
      PendingChangeTypes: []
    });

    fetchApi("/api/CustomerQuote/Validate", data, 'POST')
      .then((response: { Stops: Array<{ CustomerID: number, CustomerNumber: string, Location: any }>, Links: ILink[] }) => {
          if (this.state.ValidationRequestHash !== validationHash) {
            return;
          }
          this.setState((prevState) => ({
            Links: response.Links,
            Stops: prevState.Stops.length == response.Stops.length ? response.Stops.map((x, index) => {
              return prevState.Stops[index];
            }) : prevState.Stops,
            Locations: response.Stops.map((x, index) => {
              return {
                StopType: this.state.Stops[index].StopType,
                StopVerb: this.state.Stops[index].StopVerb,
                CustomerID: x.CustomerID,
                CustomerNumber: x.CustomerNumber,
                Location: x.Location,
                DateTime: this.state.Stops[index].DateTime ? Moment(this.state.Stops[index].DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined,
                LatestDateTime: this.state.Stops[index].StopVerb == QuoteStopVerb.Window && this.state.Stops[index].LatestDateTime ? Moment(this.state.Stops[index].LatestDateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined,
                InputType: x.CustomerID > 0 ? 1 : 0,
              }
            })
        }), () => {

          if (this.state.Links.find((x) => x.Name === 'Rate') && this.state.QuoteID == 0 && updatePanels.find(x => x === "RATE") && !updatePanels.find(x => x === "TRANSIT") && this.state.TransitData) {
            this.setState({ QuoteDataIsLoading: true });
            const data = {
              Token: this.props.match.params.token,
              Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, DateTime: x.DateTime, CustomerID: x.CustomerID, CustomerNumber: x.CustomerNumber, City: x.Location.City, State: x.Location.State, Zip: x.Location.Zip } }),
              Distance: this.state.TransitData.Distance,
              Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0),
              VehicleTypeID: this.state.VehicleTypeID,
              HazMat: this.state.HazMat,
              LiftGate: this.state.LiftGate,
            };
            fetchApi((this.state.Links.find(x => x.Name === "Rate") as ILink).Link, data, 'POST')
              .then((data: QuoteValue) => {
                if (this.state.ValidationRequestHashRate === validationHashRate) {
                  data.Amount = data.LineItems.reduce((a, b) => +a + +b.Amount, 0);
                  data.LineHaulAmount = data.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);
                  data.FuelAmount = data.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
                  data.AccessorialAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0);
                  data.BonusAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0);
                  this.setState({ QuoteData: data, QuoteDataShow: data.ShowRate, QuoteDataIsLoading: false })
                }
              })
              .catch(() => {
                if (this.state.ValidationRequestHashRate === validationHashRate) {
                  this.setState({ QuoteData: null, QuoteDataIsLoading: false });
                }
              });
          }

          if (this.state.Links.find((x) => x.Name === 'Transit') && (updatePanels.find(x => x === "TRANSIT") || this.state.TransitData === null)) {
            this.setState({ TransitDataIsLoading: true });
            const data = {
                Locations: this.state.Locations.map(({ Location, DateTime }) => ({ Location, DateTime })),
                DeadheadMiles: this.state.DeadheadMiles,
                RemainingHoursofService: Moment(this.state.RemainingHoursofService).format("HH:mm"),
                VehicleTypeID: this.state.VehicleTypeID,
                Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0).toString()
            }

            fetchApi((this.state.Links.find(x => x.Name === "Transit") as ILink).Link, data, 'POST')
              .then((data: TransitValue) => {
                if (this.state.ValidationRequestHashTransit === validationHashTransit) {
                  this.setState({ TransitData: data, TransitDataIsLoading: false }, () => {
                    if (this.state.Links.find((x) => x.Name === 'Rate') && this.state.QuoteID == 0 && (updatePanels.find(x => x === "RATE") || this.state.QuoteData === null)) {
                      this.setState({ QuoteDataIsLoading: true });
                      const data = {
                        Token: this.props.match.params.token,
                        Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, DateTime: x.DateTime, City: x.Location.City, State: x.Location.State, Zip: x.Location.Zip } }),
                        Distance: this.state.TransitData.Distance,
                        Weight: this.state.Freight.reduce((a, b) => +a + +b.Weight, 0),
                        VehicleTypeID: this.state.VehicleTypeID,
                        HazMat: this.state.HazMat,
                        LiftGate: this.state.LiftGate,
                      };
                      fetchApi((this.state.Links.find(x => x.Name === "Rate") as ILink).Link, data, 'POST')
                        .then((data: QuoteValue) => {
                          if (this.state.ValidationRequestHashRate === validationHashRate) {
                            data.Amount = data.LineItems.reduce((a, b) => +a + +b.Amount, 0);
                            data.LineHaulAmount = data.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);
                            data.FuelAmount = data.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
                            data.AccessorialAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0);
                            data.BonusAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0);
                            this.setState({ QuoteData: data, QuoteDataShow: data.ShowRate, QuoteDataIsLoading: false })
                          }
                        })
                        .catch(() => {
                          if (this.state.ValidationRequestHashRate === validationHashRate) {
                            this.setState({ QuoteData: null, QuoteDataIsLoading: false });
                          }
                        });
                  }
                  });
                }
              });
          }
        });
      })
      .catch((err) => console.error(err));
  }

  private requireUpdate(pendingChangeTypes: string[]): string[] {
    var updatePanels = new Set<string>();

    pendingChangeTypes.forEach((changeType) => {
        switch (changeType) {
            case "STOP_TYPE":
            case "STOP_VERB":
            case "STOP_INPUT_TYPE":
            case "STOP_LATEST_DATETIME":
            case "ADD_STOP":
            case "ADD_FREIGHT":
            case "NOTE":
            case "FREIGHT_DIMS_TYPE":
            case "CUSTOMERCONTACT":
            case "DOCKHIGH":
            case "FREIGHT_PIECES":
            case "FREIGHT_LENGTH":
            case "FREIGHT_WIDTH":
            case "FREIGHT_HEIGHT":
            case "FREIGHT_STACKABLE":
            case "FREIGHT_ROTATABLE":
            case "AIRRIDE":
                break;
            case "STOP_DATETIME":
              updatePanels.add("TRANSIT");
              break;
            case "FIRST_STOP_DATETIME":
              updatePanels.add("TRANSIT");
              updatePanels.add("FUELRATE");
              break;
            case "FIRST_STOP_LOCATION":
              updatePanels.add("TRANSIT");
              updatePanels.add("RATE");
              break;
            case "STOP_LOCATION":
            case "REMOVE_STOP":
              updatePanels.add("TRANSIT");
              updatePanels.add("RATE");
              break;
            case "VEHICLETYPE":
              updatePanels.add("TRANSIT");
              updatePanels.add("RATE");
              break;
            case "REMOVE_FREIGHT":
            case "FREIGHT_WEIGHT":
              updatePanels.add("RATE"); // Not for Internal
              updatePanels.add("VEHICLES");
              break;
            case "HAZMAT":
            case "LIFTGATE":
                updatePanels.add("RATE");
                break;
            default:
                throw new Error(`Unhandled update: ${changeType}`);
        }
    });

    var updatePanelsArray: string[] = [];
    updatePanels.forEach(x => updatePanelsArray.push(x))
    return updatePanelsArray;
  }

  private populateAuthorizationCustomerContacts() {
    fetchApi('/api/CustomerQuote/CustomerContacts', { Token: this.props.match.params.token }, 'POST')
      .then((response: { PerferredContactID: number, Contacts: IDName[] }) => {
        this.setState({ AuthorizationCustomerContacts: response.Contacts, AuthorizationCustomerContactID: response.PerferredContactID || 0 });
      });
  }

  private populateCustomers() {
    fetchApi('/api/CustomerQuote/CustomerAutocomplete', { Token: this.props.match.params.token }, 'POST')
      .then((response: CustomerAutocomplete[]) => {
        this.setState({ Customers: response });
      })
  }

  private addQuote() {

    // Ensure all emails are added
    if (this.multiSelect.current.state.text) {
      this.addEmail([...this.state.Emails, this.multiSelect.current.state.text]);
      this.multiSelect.current.state.text = '';
      this.forceUpdate(() => this.addQuote());
      return;
    }

    if (this.state.AuthorizationCustomerContactID === 0) {
      alert("Please select your Contact");
      return;
    }

    if (this.state.VehicleTypeID === 0) {
      alert("Please select a Vehicle Type");
      return;
    }

    if (this.state.Locations.length !== this.state.Stops.length) {
      alert("Please verify locations entered");
      return;
    }

    if (!this.state.QuoteData || this.state.QuoteData.Amount === 0) {
      alert("Unabled to request coverage without a rate");
      return;
    }

    if (this.state.IsSaving) {
      return;
    }

    this.setState({ IsSaving: true });

    const data = {
      Token: this.props.match.params.token,
      ThreadID: '',
      RouteName: '',
      Source: 5,
      Emails: this.state.Emails,
      Stops: this.state.Locations,
      Freight: this.state.Freight,
      LineItems: this.state.QuoteData.LineItems,
      RateComment: this.state.QuoteData.RateComment,
      VehicleTypeID: this.state.VehicleTypeID,
      AuthorizationCustomerContactID: this.state.AuthorizationCustomerContactID,
      BookingSource: 1,
      DockHigh: this.state.DockHigh,
      LiftGate: this.state.LiftGate,
      AirRide: this.state.AirRide,
      HazMat: this.state.HazMat,
      Total: this.state.QuoteData.Amount,
      SearchRadius: 300,
      Note: this.state.Note
    }

    fetchApi('/api/CustomerQuote/Save', data, 'POST')
      .then((response: {
          QuoteID: number,
          CreatedByUserName: string
      }) => {
          this.setState({
            QuoteID: response.QuoteID,
            IsSaving: false,
          }, () => {
            this.updatePanels();
          });
      })
      .catch((e) => {
        this.setState({ IsSaving: false });
        // If not problem details
        if (!e?.status) alert("Unable to request coverage!")
      });
  }
}
