import * as React from 'react';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Document, DocumentEntityType } from './Documents';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DocumentType } from 'TypeGen/Documents/document-type';
import { Button } from '@progress/kendo-react-buttons';
import { hyperlinkOpenIcon, hyperlinkOpenSmIcon } from '@progress/kendo-svg-icons';

interface Props {
    Document: Document;
    EntityType: DocumentEntityType
    EntityId: number,
    CloseDocumentModal: (requestDocuments: boolean) => void;
}

type IDTextValue = {
    ID: number,
    Text: string
};

type State = {
    isLoading: boolean;
    document: Document;
    isNewEntry: boolean;
}

export default class DocumentEdit extends React.Component<Props, State> {

    private DocumentTypes: IDTextValue[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            document: { ...this.props.Document, DocumentType: (!this.props.Document.DocumentType) ? 0 : this.props.Document.DocumentType },
            isNewEntry: (!this.props.Document.DocumentType) ? true : false //if null
        }

        this.DocumentTypes = [{ ID: 0, Text: "Miscellaneous" }];
        switch (this.props.EntityType) {
            case DocumentEntityType.Customer:
                this.DocumentTypes.push.apply(this.DocumentTypes, [
                    { ID: DocumentType.CertificateOfInsurance, Text: "Certificate of Insurance" },
                    { ID: DocumentType.CarrierPackets, Text: "Carrier Packets" },
                    { ID: DocumentType.Rates, Text: "Rates" },
                    { ID: DocumentType.FSC, Text: "FSC" },
                    { ID: DocumentType.CustomerContract, Text: "Customer Contract" },
                    { ID: DocumentType.BillingDocument, Text: "Billing Document" }
            ]);
                break;
            case DocumentEntityType.Carrier:
                this.DocumentTypes.push.apply(this.DocumentTypes, [
                    { ID: DocumentType.CertificateOfInsurance, Text: "Certificate of Insurance" },
                    { ID: DocumentType.CarrierPackets, Text: "Carrier Packets" },
                    { ID: DocumentType.CarrierBrokerageAgreement, Text: "Carrier Brokerage Agreement" },
                    { ID: DocumentType.NOA, Text: "Notice of Assignment" },
                    { ID: DocumentType.CarrierProfile, Text: "Carrier Profile" }
                ]);
                break;
            case DocumentEntityType.Driver:
                this.DocumentTypes.push.apply(this.DocumentTypes, [
                    { ID: DocumentType.HazMat, Text: "HazMat" },
                    { ID: DocumentType.FAST, Text: "FAST" },
                    { ID: DocumentType.CSA, Text: "CSA" },
                    { ID: DocumentType.TWIC, Text: "TWIC" },
                    { ID: DocumentType.TSA, Text: "TSA" },
                    { ID: DocumentType.DriverPhoto, Text: "Driver Photo" },
                    { ID: DocumentType.Pet, Text: "Pet" }
                ]);
                break;
        }

        this.open = this.open.bind(this);
        this.delete = this.delete.bind(this);
        this.update = this.update.bind(this);
    }

    public render() {
        return (
            <Dialog
                width="95%"
                onClose={() => this.props.CloseDocumentModal(false)}
                title={`${(this.state.isNewEntry) ? "New " : ""}Document: ${this.props.Document.Title}`}
            >
                {this.state.isLoading && <LoadingPanel />}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="form-group" style={{ width: "100%" }}>
                                    <label>Name</label>
                                    <Input
                                        className="form-control"
                                        maxLength={100}
                                        value={this.state.document.Title} 
                                        onChange={(e) => this.setState({ document: { ...this.state.document, Title: e.target.value.toString() } })}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group" style={{ width: "100%" }}>
                                    <label>Document Type</label>
                                    <DropDownList
                                        style={{ width: '100%' }}
                                        data={this.DocumentTypes}
                                        textField="Text"
                                        value={this.DocumentTypes.filter(x => x.ID === this.state.document.DocumentType)[0]}
                                        onChange={(e) => { this.setState({ document: { ...this.state.document, DocumentType: e.target.value.ID } }) }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-inline" style={{ width: "100%" }}>
                                    <Button type="button" themeColor="error" className="float-left" onClick={this.delete}>Delete</Button>
                                    <Button type="button" themeColor="info" svgIcon={hyperlinkOpenSmIcon} className="mx-auto" onClick={this.open}>Open</Button>
                                    <Button type="button" themeColor="primary" className="float-right" onClick={this.update}>Save</Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 w-100">
                            {this.props.Document.CannotDisplay 
                                ? <span className="text-warning">Cannot display this document type. Click Open to download instead.</span>
                                : <embed src={`/api/Document/View/${this.props.Document.DocumentID}`} width="100%" height="100%" style={{ minHeight: '600px' }} />}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    private open() {
        window.open(`/api/Document/Download/${this.props.Document.DocumentID}`, "_blank");
    }

    private delete() {
        if (window.confirm(`Are you sure you want to delete this document?`)) {
            this.setState({ isLoading: true });
            fetchApi(`/api/Document/Delete/${this.props.Document.DocumentID}`, {}, 'DELETE')
                .then(() => {
                    this.props.CloseDocumentModal(true);
                })
                .catch(() => {
                    alert("Unknown Error Occurred Deleting Document!");
                    this.setState({ isLoading: false });
                })
                .finally(() => {
                });
        }
    }

    private update() {
        this.setState({ isLoading: true });

        let data = this.state.document;
        data.DocumentType = data.DocumentType === -1 ? null : data.DocumentType;

        fetchApi(`/api/Document/Update/${this.props.EntityType}/${this.props.EntityId}`, data, 'POST')
            .then(() => {
                this.props.CloseDocumentModal(true);
            })
            .catch(() => {
                alert("Unknown Error Occurred Updating Document!");
                this.setState({ isLoading: false });
            });
    }
}
