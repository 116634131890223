import { GridCellProps } from "@progress/kendo-react-grid";
import { Popover } from "@progress/kendo-react-tooltip";
import { Fragment, memo, useRef } from 'react';
import { useBoolean } from "usehooks-ts";
import { FleetMessage } from '.';

type Props = {
    onClick: (dataItem: FleetMessage) => void;
} & GridCellProps;

const MessageNameCell = (props: Props) => {
    const { value: show, setTrue: showPopop, setFalse: hidePopup } = useBoolean(false);
    const anchor = useRef<HTMLTableCellElement>(null);

    if (!props.field || props.rowType !== 'data') return null;

    const data = props.dataItem as FleetMessage;

    return <>
        <td
            ref={anchor}
            style={{ cursor: "pointer", color: '#007bff' }} 
            onMouseEnter={(e) => showPopop()}
            onMouseLeave={() => hidePopup()}
            onMouseUp={() => props.onClick(data)}
        >
            {data.MessageName}
        </td>
        <Popover
            show={show}
            anchor={anchor.current}
            callout={false}
            contentStyle={{ width: 350 }}
            position="right"
            title={data.MessageName}
        >
            {data.Error && <p style={{ color: 'red' }}>{data.Error}</p>}
            <p>{data.FormattedMessage.map((l, index) => {
                return <Fragment key={index}>{l}<br /></Fragment>;
            })}</p>
        </Popover>
    </>;
}

export default memo(MessageNameCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
