import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, NumericTextBox, RadioButton } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { ListBox } from "@progress/kendo-react-listbox";
import { Fade } from "@progress/kendo-react-animation";
import { EFSPolicyLimitsViewItem } from './DriverCardMaintenance';
import { PolicyAutoRollMapping, PolicyAutoRollMappings, PolicyLimitMappings, ProductLimitFormatNumber, recursiveWeekSchedule } from './PolicyLimitsGrid';
import {
    ListView,
    ListViewItemProps,
} from "@progress/kendo-react-listview";
import { Card, CardBody } from '@progress/kendo-react-layout';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { isEqual } from "lodash";
import { JsonResponse } from 'TypeGen/json-response';
import { arrowRightIcon, xIcon } from '@progress/kendo-svg-icons';

type Props = {
    DriverID: number,
    PolicyLimits: EFSPolicyLimitsViewItem[],
    CloseDialog: (HasChanges: boolean) => void;
}

type ProductLimitMappingViewModel = {
    Id: string,
    Name: string,
    Selected: boolean,
    OriginalAmount: number,
    Amount: number,
    UoM: string,
    SelectedServiceRadioOption: string,
    AutoDailyMaxAmount: number,
    AllowHours: number,
    AutoDay: boolean[],
    AutoDayCalc: number,
    PolicySource: string,
    DisplayName: string
}

const getDaysOfWeek = (efsProductLimitItem: EFSPolicyLimitsViewItem): boolean[] => {
    if (efsProductLimitItem == null || (efsProductLimitItem.PolicySource !== "Card" || efsProductLimitItem.AutoRollMap === 0))
        return [true, true, true, true, true, true, true];

    let daysOfWeekAutoDay: boolean[] = [false, false, false, false, false, false, false];

    let schedule: PolicyAutoRollMapping[] = [];
    recursiveWeekSchedule(efsProductLimitItem.AutoRollMap, PolicyAutoRollMappings.sort((a, b) => { return b.Id - a.Id }), 0, schedule);

    if (schedule.length === 1) {
        switch (schedule[0].DayOfWeek) {
            case -1:
            case 127:
                daysOfWeekAutoDay = [true, true, true, true, true, true, true]; //daily
                break;
            case 62:
                daysOfWeekAutoDay = [false, true, true, true, true, true, false]; //weekday
                break;
            case 65:
                daysOfWeekAutoDay = [true, false, false, false, false, false, true]; //weekend
                break;
        }
    } else {
        schedule.sort((a, b) => { return a.Id - b.Id }).forEach(x => {
            switch (x.DayOfWeek) {
                case 65:
                    daysOfWeekAutoDay[0] = true; //weekend
                    daysOfWeekAutoDay[6] = true;
                    return;
                default:
                    //random days
                    daysOfWeekAutoDay[x.DayOfWeek] = true;
                    break;
            }
        });
    }
    return daysOfWeekAutoDay;
}

const AddProductLimitDialog = (props: Props) => {
    //const [productLimitOverride, setProductLimitOverride] = useState(false);
    //const [handEnterOverride, setHandEnterOverride] = useState(false);
    //const [locationOverride, setLocationOverride] = useState(false);

    const [selectedRadioOption, setSelectedRadioOption] = useState('');
    const [allowHours, setAllowHours] = useState<number>(null);
    const [autoDailyMaxAmount, setAutoDailyMaxAmount] = useState<number>(null);
    const [autoDaySunday, setAutoDaySunday] = useState(true);
    const [autoDayMonday, setAutoDayMonday] = useState(true);
    const [autoDayTuesday, setAutoDayTuesday] = useState(true);
    const [autoDayWednesday, setAutoDayWednesday] = useState(true);
    const [autoDayThursday, setAutoDayThursday] = useState(true);
    const [autoDayFriday, setAutoDayFriday] = useState(true);
    const [autoDaySaturday, setAutoDaySaturday] = useState(true);

    const [onLoad, setOnLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    //Listview Override List
    const [productLimitOverrideListing, setProductLimitOverrideListing] = useState<ProductLimitMappingViewModel[]>([]);
    const [preLoadedCardProductLineItems, setPreLoadedCardProductLineItems] = useState<ProductLimitMappingViewModel[]>([]);

    const [listBoxProductLimits, setListBoxProductLimits] = useState<ProductLimitMappingViewModel[]>([]);

    const [showProductLimitInsertDialog, setShowProductLimitInsertDialog] = useState(false);

    //Listbox Selection List
    //const [originalProductLimitAmount, setOriginalProductLimitAmount] = useState(0);

    //const [productLimitName, setProductLimitName] = useState('');
    //const [selectedEFSPolicy, setSelectedEFSPolicy] = useState<EFSPolicyLimitsViewItem>(null); //or called policy.product limit
    const [productLimitAmount, setProductLimitAmount] = useState(0); //textbox
    const [selectedProductLimit, setSelectedProductLimit] = useState<ProductLimitMappingViewModel>(null); //Todo: Rename these






    const _save = () => {
        setLoading(true);

        //could be done on server level
        //let efsOverrideList: EFSPolicyLimitsViewItem[] = [];
        //const filteredArray = productLimitOverrideListing.forEach((productLimitOverrideItem) => {
        //    let efsProductItem = props.PolicyLimits.map(x => x).filter(x => x.LimitId === productLimitOverrideItem.Id)[0];
        //    efsProductItem.Limit = productLimitOverrideItem.Amount
        //    efsOverrideList.push(efsProductItem);
        //});

        const data = {
            DriverID: props.DriverID,
            PreLoadedProductLimitOverrideListing: preLoadedCardProductLineItems,
            ProductLimitOverrideListing: productLimitOverrideListing
        }
        fetchApi(`/api/Asset/AddEFSCardProductLimit`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success)
                    props.CloseDialog(true);
                else {
                    alert(response.ErrorMessage);
                }
                setLoading(false);
            }).catch((response: number) => {
                if (response === 401)
                    alert('Permissions error.  Please see admin to be added.  Once added, logout from Load One App.');
                else
                    alert(`Error: ${response}`);
                setLoading(false);
            });
    }

    //const addListBoxItemToListViewPanel = (listBoxProductLimit: ProductLimitMappingViewModel, autoDayCalc: number = 0) => {
    //    let productLimit = { ...listBoxProductLimit };
    //    productLimit.Amount = productLimitAmount;

    //    productLimit.SelectedServiceRadioOption = selectedRadioOption;
    //    productLimit.AllowHours = selectedRadioOption === "allow" ? allowHours ?? 0 : 0;
    //    productLimit.AutoDailyMaxAmount = selectedRadioOption === "auto" ? autoDailyMaxAmount ?? 0 : 0;

    //    if (autoDayCalc === 0) { //added via action
    //        productLimit.AutoDay = [autoDaySunday, autoDayMonday, autoDayTuesday, autoDayWednesday, autoDayThursday, autoDayFriday, autoDaySaturday]
    //        productLimit.AutoDayCalc = 0;
    //        productLimit.AutoDay.forEach((element, index) => { productLimit.AutoDayCalc += PolicyAutoRollMappings.find(x => x.DayOfWeek === index).Id })
    //    } else { //add via initial load
    //        productLimit.AutoDayCalc = autoDayCalc;
    //        let schedule: PolicyAutoRollMapping[] = [];
    //        recursiveWeekSchedule(productLimit.AutoDayCalc, PolicyAutoRollMappings.sort((a, b) => { return b.Id - a.Id }), 0, schedule);
    //        schedule.sort((a, b) => { return a.Id - b.Id }).forEach(x => {
    //            switch (x.DayOfWeek) {
    //                case -1:
    //                case 127:
    //                    productLimit.AutoDay = [true, true, true, true, true, true, true]; //daily
    //                    return;
    //                case 62:
    //                    productLimit.AutoDay = [false, true, true, true, true, true, false]; //weekday
    //                    return;
    //                case 65:
    //                    productLimit.AutoDay = [true, false, false, false, false, false, true]; //weekend
    //                    return;
    //                default:
    //                    //random days
    //                    productLimit.AutoDay[x.DayOfWeek] = true;
    //                    break;
    //            }
    //        });
    //    }

    //    setProductLimitOverrideListing(productLimitOverrideListing.concat(productLimit));
    //    setListBoxProductLimits(listBoxProductLimits.filter(x => x.Id !== productLimit.Id));
    //}

    const deleteItem = (productLimitOverrideItem: ProductLimitMappingViewModel) => {
        setProductLimitOverrideListing(productLimitOverrideListing.map(x => x).filter(x => x.Id !== productLimitOverrideItem.Id));
        const efsProductLimitItem = props.PolicyLimits.find(x => x.LimitId === productLimitOverrideItem.Id);
        productLimitOverrideItem.Amount = efsProductLimitItem == null ? 0 : efsProductLimitItem.Limit;
        productLimitOverrideItem.Selected = false;
        let pl = listBoxProductLimits.concat(productLimitOverrideItem)
        setListBoxProductLimits(pl.sort(function (a, b) {
            return a.Name.localeCompare(b.Name);
        }));
    };

    const formatAutoDay = (days: boolean[]): string => {

        if (days.every(x => x === true))
            return "Daily";
        if (isEqual([true, false, false, false, false, false, true], days))
            return "Weekend";
        if (isEqual([false, true, true, true, true, true, false], days))
            return "Weekday";

        let daysOfWeekValue = '';
        days.forEach((element, index) => { if (element === true) daysOfWeekValue += `${PolicyAutoRollMappings.find(x => x.DayOfWeek === index).ShortName} ` })
        return daysOfWeekValue;
    }

    const MyItemRender = (innerProps: ListViewItemProps) => {
        const productLimitItem = innerProps.dataItem as ProductLimitMappingViewModel;
        // const efsProductLimitItem = props.PolicyLimits.find(x => x.LimitId === productLimitItem.Id);
        return (
            <>
                <div className="row p-1 align-middle align-middle" style={{ margin: 0 }}>
                    <div className="col-2 d-flex justify-content-start pl-1">
                        <div className="text-right">
                            <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                                {productLimitItem.Id}
                            </h2>
                            <div style={{ fontSize: 12, color: "#a0a0a0" }}>{ProductLimitFormatNumber(productLimitItem.Id, productLimitItem.OriginalAmount)}</div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="text-right">
                            <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                                {productLimitItem.Name}
                            </h2>
                            <div style={{ fontSize: 12, color: "#a0a0a0" }}>{ProductLimitFormatNumber(productLimitItem.Id, productLimitItem.Amount)} {productLimitItem.UoM}</div>
                        </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end pr-1">
                        <Button
                            type="button"
                            themeColor="warning"
                            svgIcon={xIcon}
                            onClick={() => deleteItem(productLimitItem)}
                        />
                    </div>
                </div>
                <div className="row p-1 border-bottom align-middle align-middle" style={{ margin: 0 }}>
                    <div className="col-2 d-flex justify-content-start pl-1">
                        <div className="text-right">
                            <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                                Hours
                            </h2>
                            <div style={{ fontSize: 12, color: "#a0a0a0" }}>{productLimitItem.AllowHours}</div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="text-right">
                            <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                                Auto Roll Map
                            </h2>
                            <div style={{ fontSize: 12, color: "#a0a0a0" }}>{formatAutoDay(productLimitItem.AutoDay)}</div>
                        </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end pr-1">
                        <div className="text-right">
                            <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                                Daily Max $ Limit
                            </h2>
                            <div style={{ fontSize: 12, color: "#a0a0a0" }}>{productLimitItem.AutoDailyMaxAmount == null || productLimitItem.AutoDailyMaxAmount === 0 ? "Unlimited" : formatNumber(productLimitItem.AutoDailyMaxAmount, "c0")}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const resetAddCardLimitProperties = () => {
        setAutoDaySunday(true);
        setAutoDayMonday(true);
        setAutoDayTuesday(true);
        setAutoDayWednesday(true);
        setAutoDayThursday(true);
        setAutoDayFriday(true);
        setAutoDaySaturday(true);
        setAllowHours(null);
        setAutoDailyMaxAmount(null);
        setSelectedRadioOption('');
        setShowProductLimitInsertDialog(false);
        setProductLimitAmount(0);
    }

    useEffect(() => {
        if (onLoad === false) {
            setOnLoad(true);

            let currentCardProductLineItems: ProductLimitMappingViewModel[] = [];
            PolicyLimitMappings.forEach(x => {
                let defaultFromCurrentPolicyOrCardProductLimit = props.PolicyLimits.find(y => y.LimitId === x.Id && y.PolicySource === 'Card');
                if (defaultFromCurrentPolicyOrCardProductLimit != null) {
                    currentCardProductLineItems.push({
                        Id: x.Id,
                        Name: x.Description,
                        Selected: false,
                        PolicySource: defaultFromCurrentPolicyOrCardProductLimit.PolicySource,
                        OriginalAmount: defaultFromCurrentPolicyOrCardProductLimit == null ? 0 : defaultFromCurrentPolicyOrCardProductLimit.Limit,
                        UoM: x.UoM,
                        Amount: defaultFromCurrentPolicyOrCardProductLimit.Limit,
                        AllowHours: defaultFromCurrentPolicyOrCardProductLimit.Hours,
                        AutoDailyMaxAmount: defaultFromCurrentPolicyOrCardProductLimit.AutoRollMax,
                        SelectedServiceRadioOption: '',
                        AutoDay: getDaysOfWeek(defaultFromCurrentPolicyOrCardProductLimit),
                        AutoDayCalc: defaultFromCurrentPolicyOrCardProductLimit == null ? 0 : defaultFromCurrentPolicyOrCardProductLimit.AutoRollMap,
                        DisplayName: `${x.Description} (${x.Id})`
                    } as ProductLimitMappingViewModel);
                }
            });
            if (currentCardProductLineItems.length > 0) {
                setProductLimitOverrideListing(currentCardProductLineItems);
                setPreLoadedCardProductLineItems(currentCardProductLineItems.map(x => x));
            }
            console.log(props.PolicyLimits);

            //!=Card added to adjacent listview (as denoted above), so skip add in listbox (below)
            setListBoxProductLimits(PolicyLimitMappings.map(x => {
                let defaultFromCurrentPolicyOrCardProductLimit = props.PolicyLimits.find(y => y.LimitId === x.Id);
                if (defaultFromCurrentPolicyOrCardProductLimit == null || defaultFromCurrentPolicyOrCardProductLimit.PolicySource !== "Card") {
                    return {
                        Id: x.Id,
                        Name: x.Description,
                        Selected: false,
                        PolicySource: '',
                        OriginalAmount: defaultFromCurrentPolicyOrCardProductLimit == null ? 0 : defaultFromCurrentPolicyOrCardProductLimit.Limit,
                        UoM: x.UoM,
                        Amount: 0,
                        AllowHours: 0,
                        AutoDailyMaxAmount: 0,
                        SelectedServiceRadioOption: '',
                        AutoDay: [true, true, true, true, true, true, true],
                        AutoDayCalc: 0,
                        DisplayName: `${x.Description} (${x.Id})`
                    } as ProductLimitMappingViewModel;
                }
                return null;
            }).filter(x => x != null).sort(function (a, b) {
                return a.Name.localeCompare(b.Name);
            }));
        }
    }, [listBoxProductLimits, onLoad, productLimitOverrideListing, props.PolicyLimits, preLoadedCardProductLineItems]);

    return <div className="container-fluid">
        <Dialog title={"Manage Card (Non-Group Policy) Product Limits"} onClose={() => props.CloseDialog(false)} width={800} height={500}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <form className="k-form k-form-md p-0">
                <div className="row" style={{ minHeight: "100px" }}>
                    <div className="col-4">
                        <FieldWrapper>
                            <label>Product Limit Selection List</label>
                            <ListBox
                                style={{ height: 300, width: "100%" }}
                                data={listBoxProductLimits}
                                textField="DisplayName"
                                selectedField="Selected"
                                onItemClick={(e) => {
                                    let policyLimit = listBoxProductLimits.map(x => x).find(x => x.Id === (e.dataItem as ProductLimitMappingViewModel).Id);
                                    if (policyLimit.Selected === false) {
                                        listBoxProductLimits.forEach(x => { x.Selected = false; });
                                        policyLimit.Selected = true;
                                        //setOriginalProductLimitAmount(policyLimit.Amount);
                                        // setProductLimitAmount(policyLimit.Amount);
                                        //setProductLimitName(policyLimit.Name);

                                        setSelectedProductLimit(policyLimit);
                                        //setSelectedEFSPolicy(props.PolicyLimits.map(x => x).find(x => x.LimitId === (e.dataItem as ProductLimitMappingViewModel).Id));
                                    } else {
                                        listBoxProductLimits.forEach(x => { x.Selected = false; });
                                        policyLimit.Selected = false;
                                        //  setOriginalProductLimitAmount(0);
                                        // setProductLimitAmount(0);
                                        //  setProductLimitName('');

                                        setSelectedProductLimit(null);
                                        //setSelectedEFSPolicy(null);
                                    }
                                    setListBoxProductLimits(listBoxProductLimits.map(x => x));
                                }}
                            />
                        </FieldWrapper>
                    </div>
                    <div style={{ width: "40px" }}>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <Button
                                type="button"
                                themeColor="primary"
                                svgIcon={arrowRightIcon}
                                disabled={selectedProductLimit == null}
                                onClick={() => setShowProductLimitInsertDialog(true)}
                            />
                        </div>
                    </div>
                    <div className="col-7 pr-0">
                        <FieldWrapper>
                            <label>New Card Product Limit</label>
                            <ListView
                                data={productLimitOverrideListing}
                                item={MyItemRender}
                                style={{ width: "100%", height: 300 }}
                            //header={MyHeader}
                            />
                        </FieldWrapper>
                    </div>
                </div>
            </form>

            <DialogActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}
                    disabled={loading || (preLoadedCardProductLineItems.length === 0 && productLimitOverrideListing.length === 0)}
                >
                    Save Product Limit Changes
                    {/*{preLoadedCardProductLineItems.length > 0 && productLimitOverrideListing.length === 0 ? 'Save Changes' : 'Save/Update/Remove Product Limits'}*/}

                </Button>
            </DialogActionsBar>
        </Dialog>

        {showProductLimitInsertDialog && <Dialog title={"Card Product Limit"} onClose={() => resetAddCardLimitProperties()} width={320} height={575}>
            <FieldWrapper>
                <label>{selectedProductLimit.Name} ({selectedProductLimit.Id}) Amount</label>
                <div className="d-flex justify-content-between">
                    <NumericTextBox
                        width={'100%'}
                        defaultValue={selectedProductLimit.OriginalAmount}
                        // value={productLimitAmount}
                        onChange={(e) => setProductLimitAmount(e.value)}
                        format="n0"
                        min={selectedProductLimit.OriginalAmount}
                        step={25}
                    />
                    <label className="ml-1" style={{ margin: "auto" }}>{selectedProductLimit.UoM}</label>
                </div>
                {selectedProductLimit.OriginalAmount > 0 && <label>* Default policy limit is shown.  Amount must be greater than default.</label>}
            </FieldWrapper>
            <FieldWrapper>
                <div className="mt-3">
                    <RadioButton
                        disabled={productLimitAmount < 1 && selectedProductLimit.OriginalAmount < 1}
                        name="group1"
                        value="allow"
                        label="Allow"
                        onChange={(e) => setSelectedRadioOption(e.value)}
                    /><span style={{ fontSize: "10px" }} className="ml-1 align-middle">(Hour Reset)</span>
                    &emsp;
                    <RadioButton
                        disabled={productLimitAmount < 1 && selectedProductLimit.OriginalAmount < 1}
                        name="group1"
                        value="auto"
                        label="Auto Rollover"
                        onChange={(e) => setSelectedRadioOption(e.value)}
                    /><span style={{ fontSize: "10px" }} className="ml-1 align-middle">(Daily Reset)</span>
                </div>
            </FieldWrapper>
            <Fade style={{ zIndex: "1", width: "100%" }} transitionEnterDuration={800}>
                {selectedRadioOption && <div className="mt-3">
                    {selectedRadioOption == "allow" ?
                        <FieldWrapper>
                            <label>Hour(s):</label>
                            <NumericTextBox
                                width={'100%'}
                                onChange={(e) => setAllowHours(e.value)}
                                value={allowHours}
                                placeholder={'daily'}
                                format="n0"
                                step={1}
                                min={0}
                            />
                        </FieldWrapper>
                        :
                        <>
                            {selectedProductLimit.UoM !== "USD" && <FieldWrapper>
                                <label>Daily Max Dollar Amount</label>
                                <NumericTextBox
                                    width={'100%'}
                                    onChange={(e) => setAutoDailyMaxAmount(e.value)}
                                    value={autoDailyMaxAmount}
                                    placeholder={"unlimited"}
                                    format="n0"
                                    step={25}
                                    min={0}
                                />
                                {/* <label>* Default limit is shown.  Amount override must be greater than default limit.</label>*/}
                            </FieldWrapper>}
                            <FieldWrapper className="mt-3">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Sunday"} value={autoDaySunday} onChange={(e) => setAutoDaySunday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Monday"} value={autoDayMonday} onChange={(e) => setAutoDayMonday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Tuesday"} value={autoDayTuesday} onChange={(e) => setAutoDayTuesday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Wednesday"} value={autoDayWednesday} onChange={(e) => setAutoDayWednesday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Thursday"} value={autoDayThursday} onChange={(e) => setAutoDayThursday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Friday"} value={autoDayFriday} onChange={(e) => setAutoDayFriday(e.value)} />
                                            </FieldWrapper>
                                            <FieldWrapper className="mb-1">
                                                <Checkbox label={"Saturday"} value={autoDaySaturday} onChange={(e) => setAutoDaySaturday(e.value)} />
                                            </FieldWrapper>
                                        </div>
                                    </CardBody>
                                </Card>
                            </FieldWrapper>
                        </>
                    }
                </div>}
            </Fade>
            <DialogActionsBar layout="end">
                <button type="button" className="k-button" onClick={() => {
                    resetAddCardLimitProperties();
                }}>Cancel</button>
                <button type="button" className="k-button k-primary"
                    disabled={(productLimitAmount === 0) || (selectedProductLimit.OriginalAmount > 0 && productLimitAmount <= selectedProductLimit.OriginalAmount) ||
                        (!selectedRadioOption) ||
                        (selectedRadioOption === "allow" && allowHours < 0) || (selectedRadioOption === "auto" && autoDailyMaxAmount < 0) ||
                        (selectedRadioOption === "auto" && (autoDaySunday || autoDayMonday || autoDayTuesday || autoDayWednesday || autoDayThursday || autoDayFriday || autoDaySaturday) === false)
                    }
                    onClick={() => {

                        let productLimit = { ...selectedProductLimit };
                        productLimit.Amount = productLimitAmount;
                        productLimit.SelectedServiceRadioOption = selectedRadioOption;
                        productLimit.AllowHours = selectedRadioOption === "allow" ? allowHours ?? 0 : 0;
                        productLimit.AutoDailyMaxAmount = selectedRadioOption === "auto" ? autoDailyMaxAmount ?? 0 : 0;
                        productLimit.AutoDay = [autoDaySunday, autoDayMonday, autoDayTuesday, autoDayWednesday, autoDayThursday, autoDayFriday, autoDaySaturday]
                        productLimit.AutoDayCalc = 0;
                        productLimit.AutoDay.forEach((element, index) => { if (element === true) productLimit.AutoDayCalc += PolicyAutoRollMappings.find(x => x.DayOfWeek === index).Id })
                        setProductLimitOverrideListing(productLimitOverrideListing.concat(productLimit));
                        setListBoxProductLimits(listBoxProductLimits.filter(x => x.Id !== productLimit.Id));

                        //addListBoxItemToListViewPanel(selectedProductLimit);
                        resetAddCardLimitProperties();
                        // setShowProductLimitInsertDialog(false);
                        // setProductLimitAmount(0);

                    }}>Add</button>
            </DialogActionsBar>
        </Dialog>
        }
    </div >
}

export default AddProductLimitDialog;

