import * as React from 'react';
import Moment from 'moment-timezone';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import realFormatter from '../../services/formatting/number';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import LoadingPanel from '../LoadingPanel';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { ViewModel as LoadData } from 'TypeGen/Order/Load/view-model';
import { Command as LoadRequest } from 'TypeGen/Order/Load/command';
import LoadFreight from './LoadFreight';
import CenterDivPanel from '../CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { JsonResponse } from 'TypeGen/json-response';
import { useTrack } from 'views/Track';
import { useEffect, useState } from 'react';
import { RequestFreightDetail } from 'TypeGen/Order/Load/request-freight-detail';
import { getTrailerStatusColor } from 'views/Assets/TrailerGrid';
import { Trailer } from 'TypeGen/Order/Load/trailer';

type Props = {
  Link: ILink;
  CloseDialog: () => void;
}

const trailerOptions = [
  { value: 0, text: 'No' },
  { value: 1, text: 'Yes, No FOB' },
  { value: 2, text: 'Yes, FOB' },
]

const Empty = ({ Link, CloseDialog}: Props) => {

  const track = useTrack();
  const [data, setData] = useState<LoadData | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [dateTime, setDateTime] = useState<Date>(Moment().toDate());
  const [reference1, setReference1] = useState('');
  const [reference2, setReference2] = useState('');
  const [reference3, setReference3] = useState('');
  const [reference4, setReference4] = useState('');
  const [unhookTrailer, setUnhookTrailer] = useState(false);
  const [trailerFreightOnBoard, setTrailerFreightOnBoard] = useState(false);
  const [newTrailerNumber, setNewTrailerNumber] = useState('');
  const [securedFreight, setSecuredFreight] = useState(false);
  const [freightDetails, setFreightDetails] = useState<RequestFreightDetail[]>([]);
  const [trailerFilter, setTrailerFilter] = useState<FilterDescriptor>(null);

  const load = () => {
    if (freightDetails.some(x => !x.BillOfLading)) {
      alert('Please enter the Bill of Lading');
      return;
    }

    setSaving(true);
    const link = data.Links.find(x => x.Name == "Load") as ILink;
    const request = {
      DateTime: dateTime,
      Reference1: reference1,
      Reference2: reference2,
      Reference3: reference3,
      Reference4: reference4,
      UnhookTrailer: unhookTrailer,
      TrailerFreightOnBoard: trailerFreightOnBoard,
      NewTrailerNumber: newTrailerNumber,
      FreightDetails: freightDetails,
      SecuredFreight: securedFreight,
  } as LoadRequest;
    fetchApi(link.Link, request, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          track?.refresh();
          CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to load');
      })
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    fetchApi(Link.Link, {}, Link.Method)
        .then((data: LoadData) => {
            setData(data);
            setDateTime(Moment.utc(data.DateTime).toDate());
            setReference1(data.Reference1);
            setReference2(data.Reference2);
            setReference3(data.Reference3);
            setReference4(data.Reference4);
            setFreightDetails(data.FreightDetails.map(x => ({
                OrderFreightDetailID: x.OrderFreightDetailID,
                BillOfLading: x.BillOfLading,
                InBondNumber: x.InBondNumber,
                Pieces: x.LoadedPieces,
                PiecesUnitOfMeasure: x.LoadedPiecesUnitOfMeasure,
                Weight: x.LoadedWeight,
                WeightUnitOfMeasure: x.LoadedWeightUnitOfMeasure,
                Length: x.LoadedLength,
                Width: x.LoadedWidth,
                Height: x.LoadedHeight,
                DimsUnitOfMeasure: x.LoadedDimsUnitOfMeasure,
                FreightValue: x.FreightValue,
            })));
            setLoading(false);
        });
  }, [Link]);

  const renderTrailer = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span className={getTrailerStatusColor(itemProps.dataItem.TrailerStatus)}>{itemProps.dataItem.TrailerNumber} - {itemProps.dataItem.TrailerStatusValue}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const renderTrailerValue = (span: React.ReactElement<HTMLSpanElement>, value: Trailer) => {
    const itemChildren = value ? <>{value.TrailerNumber} - {value.TrailerStatusValue}</> : undefined;
    return React.cloneElement(span, span.props, itemChildren);
  }

  if (loading) {
    return <React.Fragment>
      <div className="k-overlay" style={{ zIndex: 10002, opacity: 0.5 }} />
      <LoadingPanel />
    </React.Fragment>
  }

  const trailers = filterBy(data.AvailableTrailers, trailerFilter);
  return (
    <Dialog
      title={`Load (${data.Sequence} of ${data.StopCount}) - Pro # ${data.OrderNumber}`}
      onClose={CloseDialog}
      width="90%"
      height="90%"
    >
      {saving && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}

      {data.RequireBillOfLadingScan && <div className="alert alert-warning">
        Customer requires scanned Bill of Lading document
      </div>}

      <div className="row">
        <div className="col-md-6">
          <p>
            {data.LocationName !== null && <>{data.LocationName.toUpperCase()}<br /></>}
            {data.AddressLine1 !== null && <>{data.AddressLine1.toUpperCase()}<br /></>}
            {data.City}, {data.State} {data.ZipCode}
          </p>
        </div>
        <div className="col-md-6">
          <p className="mb-0">
            Scheduled {data.TypeName} {Moment.utc(data.ScheduledDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
          </p>
          {data.ArrivalDateTime && <p className="mb-0">
            Arrival
            <button
              className="btn btn-link"
              onClick={() => setDateTime(Moment.utc(data.ArrivalDateTime).add(1, 'minute').toDate())}
            >
              {Moment.utc(data.ArrivalDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} + 1 min
            </button>
          </p>}
          {data.DepartDateTime && <p className="mb-0">
            Departure
            <button
              className="btn btn-link"
              onClick={() => setDateTime(Moment.utc(data.DepartDateTime).toDate())}
            >
              {Moment.utc(data.DepartDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
            </button>
          </p>}
          {data.Miles > 0 && <p>
            {realFormatter(data.Miles)} Stop Miles
          </p>}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4 col-form-label">Loaded Date/Time (EST)</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={dateTime}
            onChange={(e) => setDateTime(e.value)}
            width="100%"
          />
        </div>
      </div>
      {data.FreightDetails.map((freight, index) => {
        return <LoadFreight
          piecesUnitOfMeasure={data.PiecesUnitOfMeasures}
          weightUnitOfMeasure={data.WeightUnitOfMeasures}
          dimsUnitOfMeasure={data.DimsUnitOfMeasures}
          data={freight}
          request={freightDetails[index]}
          updateRequest={(freight) => {
            setFreightDetails(freightDetails.map((x, i) => i === index ? { ...x, ...freight } : x));
          }}
        />;
      })}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Reference No. 1</label>
        <div className="col-md-8">
          <Input
            value={reference1}
            onChange={(e) => setReference1(e.value)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Reference No. 2</label>
        <div className="col-md-8">
          <Input
            value={reference2}
            onChange={(e) => setReference2(e.value)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Reference No. 3</label>
        <div className="col-md-8">
          <Input
            value={reference3}
            onChange={(e) => setReference3(e.value)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Reference No. 4</label>
        <div className="col-md-8">
          <Input
            value={reference4}
            onChange={(e) => setReference4(e.value)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {data.TrailerNumber && <div className="form-group row">
        <label className="col-md-4 col-form-label">Unhook/Drop Trailer {data.TrailerNumber}</label>
        <div className="col-md-8">
          <DropDownList
            defaultValue={0}
            value={trailerOptions.find(x => x.value == (unhookTrailer ? (trailerFreightOnBoard ? 2 : 1) : 0))}
            data={trailerOptions}
            textField="text"
            onChange={(e) => {
              setUnhookTrailer(e.target.value.value !== 0);
              setTrailerFreightOnBoard(e.target.value.value === 2);
              setNewTrailerNumber(e.target.value.value !== 0 ? newTrailerNumber : '');
            }}
          />
        </div>
      </div>}
      {unhookTrailer && <div className="form-group row">
        <label className="col-md-4 col-form-label">Hook Trailer</label>
        <div className="col-md-8">
          <DropDownList
            filterable={true}
            onFilterChange={(e) => setTrailerFilter(e.filter)}
            value={data.AvailableTrailers.find(x => x.TrailerNumber == newTrailerNumber)}
            data={trailers}
            textField="TrailerNumber"
            dataItemKey="TrailerID"
            itemRender={renderTrailer}
            valueRender={renderTrailerValue}
            onChange={(e) => setNewTrailerNumber(e.value.TrailerNumber)}
          />
        </div>
      </div>}
      <DialogActionsBar layout="end">
        <Button onClick={CloseDialog} disabled={saving}>Cancel</Button>
        <Button themeColor="primary" onClick={load} disabled={saving}>Load</Button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default Empty;
