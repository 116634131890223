import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { isTrailerDateValid } from '../TrailerGrid';

const PositionCell = (props: GridCellProps) => {
  if (!props.field) return null;

  return (
    <td>
      {isTrailerDateValid(props.dataItem.LastPositionDateTime) && <>
        <Link style={{ color: "#007bff" }} to={`/Assets/Trailer/${props.dataItem.TrailerID}/Positions`}>{props.dataItem.LastPositionCity}, {props.dataItem.LastPositionState}</Link>
        <div style={{ fontSize: '11px' }}>{moment.utc(props.dataItem.LastPositionDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
      </>}
    </td>
  );
}

export default PositionCell;
