export default function timezoneFormatter(TimeZoneID: string) {
  switch(TimeZoneID) {
    case "Hawaiian Standard Time":
        return "HST";
    case "Alaskan Standard Time":
        return "AKST";
    case "Pacific Standard Time":
        return "PST";
    case "Pacific Standard Time (Mexico)":
        return "PSTM";
    case "Mountain Standard Time":
        return "MST";
    case "Mountain Standard Time (Mexico)":
        return "MSTM";
    case "Central Standard Time":
        return "CST";
    case "Central Standard Time (Mexico)":
        return "CSTM";
    case "Eastern Standard Time":
        return "EST";
    case "Eastern Standard Time (Mexico)":
        return "ESTM"; // Not sure if this is correct
    case "Atlantic Standard Time":
        return "AST";
    case "Newfoundland Standard Time":
        return "NST";
    case "Greenland Standard Time":
        return "GST";
    default:
        return "N/A";
  }
}
