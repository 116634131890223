import { SvgIcon } from "@progress/kendo-react-common";
import { ComboBox, ListItemProps } from "@progress/kendo-react-dropdowns";
import { xIcon } from "@progress/kendo-svg-icons";
import React, { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

type Props = {
  autoFocus?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const statusNoteData = ["FOD"];

const OrderStatusNoteInput: React.FC<Props> = ({ autoFocus, value, onChange }) => {
  const inputRef = React.useRef<ComboBox>(null);
  const [customStatusNotes, setCustomStatusNotes] = useLocalStorage('OrderStatusNoteInput', []);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  const statusNoteItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <div className='w-100'>
      <span>{itemProps.dataItem}</span>
      {statusNoteData.indexOf(itemProps.dataItem) === -1 && <SvgIcon onClick={(e) => {
        e.stopPropagation();
        setCustomStatusNotes(customStatusNotes.filter((value) => value !== itemProps.dataItem));
        if (value === itemProps.dataItem) {
          onChange(null);
        }
      }} icon={xIcon} style={{ float: 'right' }} />}
    </div>
    return React.cloneElement(li, li.props, itemChildren);
  }

  return <ComboBox
    ref={inputRef}
    clearButton
    allowCustom
    data={[...statusNoteData, ...customStatusNotes]}
    value={value}
    itemRender={statusNoteItemRender}
    onChange={(e) => {
      const newValue = e.value?.substring(0, 25);
      onChange(newValue);
      if (newValue && statusNoteData.indexOf(newValue) === -1 && customStatusNotes.indexOf(newValue) === -1)
      {
        setCustomStatusNotes([...customStatusNotes, e.value]);
      }
    }}
    placeholder="FOD, etc."
  />
}

export default OrderStatusNoteInput;