import Moment from 'moment-timezone';

type Props = {
  audit: AuditInfoViewModel;
}

export type AuditInfoViewModel = {
  ModifiedByUserName: string;
  ModifiedByDateTime: string;
  CreatedByUserName: string;
  CreatedByDateTime: string;
  RetiredByUserName: string | null;
  RetiredByDateTime: string | null;
}

const AuditInfo = (props: Props) => {
  const audit = props.audit;
  return <>
    <h2>Audit</h2>
    <div className="form-group row">
      <label className="col-sm-4 text-right col-form-label">Created</label>
      <div className="col-sm-8">
        <p className="form-control-plaintext">{audit.CreatedByUserName} - {Moment.utc(audit.CreatedByDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}</p>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-sm-4 text-right col-form-label">Updated</label>
      <div className="col-sm-8">
        <p className="form-control-plaintext">{audit.ModifiedByUserName} - {Moment.utc(audit.ModifiedByDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}</p>
      </div>
    </div>
    {audit.RetiredByUserName && <div className="form-group row">
      <label className="col-sm-4 text-right col-form-label">Retired</label>
      <div className="col-sm-8">
        <p className="form-control-plaintext">{audit.RetiredByUserName} - {Moment.utc(audit.RetiredByDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}</p>
      </div>
    </div>}
  </>
}
export default AuditInfo;
