import { useCallback, useEffect, useRef, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { CarrierNavBar } from "./CarrierNavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Chart, ChartAxisDefaults, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import { formatNumber } from '@progress/kendo-intl';
import realFormatter from '../../services/formatting/number';
import { chevronLeftIcon, chevronRightIcon } from "@progress/kendo-svg-icons";

type RouteComponentParams = {
  carrierId: string;
};

type Response = {
  PeriodName: string;
  CarrierName: string;
  Reason: string;
  StopPerformance: StopPerformance;
  PositionPerformance: PositionPerformance;
  Revenue: Revenue;
  AppTracking: CarrierAppTracking;
}

type StopPerformance = {
  AvgLateMinutes: number;
  PercentOnTime: number;
  Labels: string[];
  OnTime: number[];
  LatePU: number[];
  LateDR: number[];
}

type PositionPerformance = {
  PercentPositionUpdates: number;
  Labels: string[];
  Provided: number[];
  Missing: number[];
}

type Revenue = {
  Total: number;
  TotalTrips: number;
  Labels: string[];
  Revenue: number[];
}

type CarrierAppTracking = {
  PercentAppAccepted: number;
  Labels: string[];
  Requested: number[];
  Accepted: number[];
}

const CarrierScorecard = () => {

    const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
    const pdfExportComponent = useRef<PDFExport>(null);
    const [period, setPeriod] = useState(0);
    const [loading, setLoading] = useState(true);

    const [periodName, setPeriodName] = useState("");
    const [carrierName, setCarrierName] = useState("");
    const [reason, setReason] = useState("");
    const [stopPerformance, setStopPerformance] = useState<StopPerformance>();
    const [positionPerformance, setPositionPerformance] = useState<PositionPerformance>();
    const [revenue, setRevenue] = useState<Revenue>();
    const [appTracking, setAppTracking] = useState<CarrierAppTracking>();

    const refresh = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/CarrierRating/Scorecard/${carrierIdParam}/${period}`)
            .then((data: Response) => {
                setLoading(false);
                setPeriodName(data.PeriodName);
                setCarrierName(data.CarrierName);
                setReason(data.Reason);
                setStopPerformance(data.StopPerformance);
                setPositionPerformance(data.PositionPerformance);
                setRevenue(data.Revenue);
                setAppTracking(data.AppTracking);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [carrierIdParam, period]);

    useEffect(() => {
        refresh();
    }, [carrierIdParam, refresh]);

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <>
        <ButtonGroup className="d-flex justify-content-center mt-2">
            <Button disabled>{periodName}</Button>
            <Button icon="arrow-chevron-left" svgIcon={chevronLeftIcon} onClick={() => setPeriod((prev) => prev-1)} />
            <Button icon="arrow-chevron-right" svgIcon={chevronRightIcon} onClick={() => setPeriod((prev) => prev+1)} />
        </ButtonGroup>
        {reason && <div className="alert alert-info mt-2" role="alert">{reason}</div>}
        <PDFExport ref={pdfExportComponent} fileName={`${carrierName} - ${periodName}.pdf`}>
            {stopPerformance && <Chart transitions={false}>
            {stopPerformance.PercentOnTime ? <ChartTitle position='top' text={`On-Time Performance (${formatNumber(stopPerformance.PercentOnTime, "p")} Trips On-Time)`} />
             : <ChartTitle position='top' text={`On-Time Performance (When Late, Averages ${realFormatter(stopPerformance.AvgLateMinutes)} mins. late)`} />}
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={stopPerformance.Labels} />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="column" color="#2ecc71" name="On-Time" data={stopPerformance.OnTime} stack />
                <ChartSeriesItem type="column" color="#ffc107" name="PU Late" data={stopPerformance.LatePU} />;
                <ChartSeriesItem type="column" color="#dc3545" name="DR Late" data={stopPerformance.LateDR} />;
            </ChartSeries>
            </Chart>}
            <hr />
            {positionPerformance && <Chart transitions={false}>
            <ChartTitle position='top' text={`Position Update Performance (${formatNumber(positionPerformance.PercentPositionUpdates, "p")} Trips Provided)`} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={positionPerformance.Labels} />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="column" color="#2ecc71" name="Provided" data={positionPerformance.Provided} stack />
                <ChartSeriesItem type="column" color="#dc3545" name="No Position Updates" data={positionPerformance.Missing} />;
            </ChartSeries>
            </Chart>}
            <hr />
            {revenue && <Chart transitions={false}>
            <ChartAxisDefaults
                labels={{
                format: "c0",
                }}
            />
            <ChartValueAxis>
                <ChartValueAxisItem crosshair={{ visible: true}} />
            </ChartValueAxis>
            <ChartTitle position='top' text={`Revenue (${revenue.TotalTrips} Trips @ ${formatNumber(revenue.Total, "c")})`} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={revenue.Labels} />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="column" color="#2ecc71" name="Daily Revenue" data={revenue.Revenue} />
            </ChartSeries>
            </Chart>}
            {appTracking && <Chart transitions={false}>
            <ChartTitle position='top' text={`App Tracking (${formatNumber(appTracking.PercentAppAccepted, "p")} Accepted)`} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={appTracking.Labels} />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="line" color="#dc3545" name="Requested" data={appTracking.Requested} />
                <ChartSeriesItem type="line" color="#2ecc71" name="Accepted" data={appTracking.Accepted} />
            </ChartSeries>
            </Chart>}
        </PDFExport>
        <Button className="mx-auto" onClick={() => pdfExportComponent.current.save()}>Export PDF</Button>
    </>

    return (<>
        <CarrierNavBar id={carrierIdParam} currentTab="Scorecard" />
        <Title string="Scorecard" />
        {loading ? loadingView : dataView()}
    </>);
}

export default CarrierScorecard;