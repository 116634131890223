import * as React from 'react';
import { fetchApi } from '../../services/api';
import { openWindow } from '../../services/openWindow';

type Props = {
    DriverID: number;
}

type State = {
    driver?: Driver;
}

type Driver = {
    DriverNumber: string;
    DriverName: string;
    Vehicle?: Vehicle;

    Links: any[];
}

type Vehicle = {
    VehicleNumber: string;
    ServiceStatus: number;
    TripNumber: number;

    Links: any[];
}

export default class DriverCard extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {

        }

        this.fetch = this.fetch.bind(this);
    }

    public render() {
        if (this.state.driver) {
            return <div style={{ maxWidth: '500px', margin: 'auto' }}>
                <div className="card-body">
                    <h5 className="card-title">
                        {this.state.driver.DriverName} (<a href="#" onClick={(e) => { e.preventDefault(); openWindow(this.state.driver.Links.find(x => x.Name == 'DriverProfile').Link)}}>{this.state.driver.DriverNumber}</a>) - <a href="#" onClick={(e) => { e.preventDefault(); openWindow(this.state.driver.Links.find(x => x.Name == 'WorkloadHistory').Link)}}>Workload History</a></h5>
                    {this.state.driver.Vehicle && <React.Fragment>
                        <p className="card-text">
                            <a href="#" onClick={(e) => { e.preventDefault(); openWindow(this.state.driver.Vehicle.Links.find(x => x.Name == 'VehicleProfile').Link)}}>{this.state.driver.Vehicle.VehicleNumber}</a>
                            &nbsp;
                            {this.renderStatus(this.state.driver.Vehicle)}
                        </p>
                    </React.Fragment>}
                </div>
            </div>
        }

        return null;
    }
    
    private renderStatus(vehicle: Vehicle) {
        switch (vehicle.ServiceStatus) {
            case 0:
                return <a href="#" onClick={(e) => { e.preventDefault(); openWindow(vehicle.Links.find(x => x.Name == 'TripProfile').Link)}} className='onaload'>{vehicle.TripNumber}</a>;
            case 1:
                return <a href="#" onClick={(e) => { e.preventDefault(); openWindow(vehicle.Links.find(x => x.Name == 'ServiceStatus').Link)}} className='inservice'>In Service</a>;
            case 2:
                return <a href="#" onClick={(e) => { e.preventDefault(); openWindow(vehicle.Links.find(x => x.Name == 'ServiceStatus').Link)}} className='outofservice'>Out Of Service</a>;
            case 3:
                return <a href="#" onClick={(e) => { e.preventDefault(); openWindow(vehicle.Links.find(x => x.Name == 'ServiceStatus').Link)}} className='unconfirmedinservice'>Unconfirmed In Service</a>;
          }
    }

    public componentWillMount() {
        this.fetch();
    }
    
    private fetch() {
        fetchApi(`/api/Asset/GetDriverCard/${this.props.DriverID}`)
            .then((driver: Driver) => {
                this.setState({ driver });
            });
    }
}