import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { SetupNotifications } from "./SetupNotifications";
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon, hyperlinkOpenIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string;
    currentTab: ContactTabs;
}

export type ContactTabs = "Profile" | "Contacts";

export const CustomerContactNavBar = (props: Props) => {

    const [fullName, setFullName] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [customerID, setCustomerID] = useState(0);
    const [links, setLinks] = useState<ILink[]>([]);
    const [showCustomerNotificationsDialog, setShowCustomerNotificationsDialog] = useState(false);

    const refresh = (contactId: string) => {
        setLinks([]);
        fetchApi(`/api/Customer/CustomerContactNavBar/${contactId}`)
            .then((data: { FullName: string, CustomerID: number, CustomerNumber: string, Links: ILink[] }) => {
                setFullName(data.FullName);
                setCustomerID(data.CustomerID);
                setCustomerNumber(data.CustomerNumber);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this contact?")) {
            return;
        }

        fetchApi(`/api/Customer/ReactivateCustomerContact/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    const portal = () => {
        fetchApi(`/api/Customer/ImpersonateCustomerContact/${props.id}`, {}, 'POST')
        .then((response: JsonResponse) => {
            if (response.Success) {
                window.open(response.Links[0].Link, '_blank');
            } else {
                alert(response.ErrorMessage);
            }
        })
        .catch(() => {
            alert("Unable to login customer portal");
        });
    }

    const retire = () => {
        if (!window.confirm('Retire Contact: Are you sure?')) {
            return;
        }

        fetchApi(`/api/Customer/RetireCustomerContact/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to retire");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    return <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Customers">Customers</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    <Link to={`/Customers/Customer/${customerID}`}>{customerNumber}</Link>
                </span>
                <SvgIcon icon={chevronRightIcon} className="text-muted" />
                <Link to={`/Customers/Customer/${customerID}/Contacts`}>Contacts</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {fullName}
                </span>
            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#contactNav" aria-controls="contactNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="contactNav">
                <ul className="navbar-nav">
                    {links.find((x: ILink) => x.Name === 'Contact') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Contacts/Contact/${props.id}`}>Profile</Link>
                    </li>}
                    {links.length > 0 && <li className={`nav-item`}>
                        <Link className="nav-link" to="#" onClick={() => { setShowCustomerNotificationsDialog(!showCustomerNotificationsDialog) }}>Contact Notifications</Link>
                        {showCustomerNotificationsDialog && <SetupNotifications CustomerID={customerID} ContactID={Number(props.id)}
                            CloseDialog={() => setShowCustomerNotificationsDialog(false)} />}
                    </li>}
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    {links.find((x: ILink) => x.Name === 'Impersonate') && <Button icon="hyperlink-open" svgIcon={hyperlinkOpenIcon} themeColor="secondary" className="mr-2" onClick={portal}>Portal</Button>}
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={retire} themeColor="primary">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={reactivate} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}