import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import CenterDivPanel from "components/CenterDivPanel";
import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IDNameViewModel } from "TypeGen/id-name-view-model";

type Props = {
  BookedByID: number;
  onClose: () => void;
  save: (userId: number) => void;
};

const Booked = ({ BookedByID, onClose, save }: Props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IDNameViewModel[]>();
  const [bookedByUserId, setBookedByUserId] = useState(BookedByID);

  const refreshUsers = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/User/SylectusUsersDropdown`)
      .then((response: { Users: IDNameViewModel[] }) => {
        setUsers(response.Users);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load users');
      });
  }, []);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  return <Dialog title={`Change Booked By User`} onClose={onClose} className="dialog-w525">
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <DropDownList
      data={users}
      value={users?.find(r => r.ID === bookedByUserId)}
      textField="Name"
      dataItemKey="ID"
      style={{ width: '100%' }}
      onChange={(e) => setBookedByUserId(e.target.value.ID)}
    />
    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button themeColor="primary" onClick={() => save(bookedByUserId)}>Save</Button>
    </DialogActionsBar>
  </Dialog>
}

export default Booked;