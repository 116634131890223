import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { OwnerViewModel } from '../Assets/OwnerGrid';

type Props = {
  onSelectedOwner: (owner: OwnerViewModel) => void;
  selectedOwnerID?: number;
  setFocus?: boolean;
  includeRecentlyRetiredOwners?: boolean;
} & AutoCompleteProps;

export type OwnerAutoCompleteViewModel = OwnerViewModel & { DisplayValue: string; };

export const GetOwnerAutoCompleteDisplayValue = (owner: OwnerViewModel, includeActiveLabel?: boolean): string => {
  let customLabel = "";
  if (includeActiveLabel === true)
    customLabel += ` ${owner.Active ? 'Active' : 'Retired'}`;

  return `${owner.OwnerNumber} (${owner.OwnerFullName})${customLabel}`;
}

const OwnerAutoComplete: React.FC<Props> = ({
  onSelectedOwner,
  selectedOwnerID,
  setFocus,
  includeRecentlyRetiredOwners,
  ...autoCompleteProps
}) => {
  const inputAC = useRef<AutoComplete>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [ownerDropDownListing, setOwnerDropDownListing] = useState<OwnerAutoCompleteViewModel[]>([]);
  const [ownerAutoCompleteValue, setOwnerAutoCompleteValue] = useState('');
  const _selectedOwner = useRef<number>(0);
  const _requestHash = useRef('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((val: string, requestHash: string) => {
      if (val.length > 2 && _selectedOwner.current === 0) {
        getOwnerListing(generateDataStateByName(val), requestHash);
      }
    }, 750), []);

  const generateDataStateByName = (val: string): GridState => {
    let gs = {
      skip: 0,
      take: 12,
      sort: [{
        field: "OwnerNumber",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          {
            logic: 'or', filters: [
              { field: 'OwnerNumber', operator: 'contains', value: val },
              { field: 'OwnerFullName', operator: 'contains', value: val }
            ] as any
          }
        ] as any
      }
    } as GridState;


    if (includeRecentlyRetiredOwners) {
      gs.filter.filters.unshift({
        logic: 'or', filters: [
          { field: 'active', operator: 'eq', value: true },
          {
            logic: 'and', filters: [
              { field: 'active', operator: 'eq', value: false },
              { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(6, "months").toDate() }
            ]
          }
        ]
      });
    } else {
      gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
    }

    return gs;
  }

  const generateDataStateByID = (val: number): GridState => {
    return {
      skip: 0,
      take: 1,
      sort: null,
      filter: {
        logic: 'and', filters: [
          { field: 'OwnerID', operator: 'eq', value: val }
        ] as any
      }
    } as GridState;
  }

  const getOwnerListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
    setLoading(true);

    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/Asset/GetAssetOwners?${queryStr}`, {}, 'POST')
      .then(({ Data }) => {
        let ownerData: OwnerAutoCompleteViewModel[] = Data;
        setLoading(false);
        if (_requestHash.current === requestHash) {
          ownerData.forEach(x => {
            x.DisplayValue = GetOwnerAutoCompleteDisplayValue(x, includeRecentlyRetiredOwners);
          });
          setOwnerDropDownListing(ownerData);
          if (populateLabel)
            setOwnerAutoCompleteValue(GetOwnerAutoCompleteDisplayValue(ownerData[0]));
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
  }, [includeRecentlyRetiredOwners]);

  const onOwnerListingClose = (event: AutoCompleteCloseEvent) => {
    const value = event.target.value;
    const selectedOwner = ownerDropDownListing.find(x => x.DisplayValue === value);
    setOwnerAutoCompleteValue(selectedOwner != null ? value : '');
    _selectedOwner.current = selectedOwner?.OwnerID;
    onSelectedOwner(selectedOwner ?? null);
  };

  useEffect(() => {
    if (selectedOwnerID !== _selectedOwner.current) {
      if (selectedOwnerID > 0) {
        _selectedOwner.current = selectedOwnerID;
        _requestHash.current = Math.random().toString(36).substring(7);
        getOwnerListing(generateDataStateByID(selectedOwnerID), _requestHash.current, true);
      } else {
        _selectedOwner.current = 0;
        setOwnerAutoCompleteValue('');
      }
      if (setFocus) {
        inputAC.current.focus();
      }
    }
  }, [selectedOwnerID, getOwnerListing, setFocus]);

  return <AutoComplete
    ref={inputAC}
    {...autoCompleteProps}
    loading={loading}
    placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Owner')}
    data={ownerDropDownListing}
    value={ownerAutoCompleteValue}
    textField="DisplayValue"
    onChange={(e) => {
      _selectedOwner.current = 0;
      if (!e.value)
        onSelectedOwner(null);
      setOwnerDropDownListing([]);
      setOwnerAutoCompleteValue(e.value);
      _requestHash.current = Math.random().toString(36).substring(7);
      debounceSearchInput(e.value, _requestHash.current);
    }}
    onClose={(e) => onOwnerListingClose(e)}
  />
}

export default OwnerAutoComplete;
