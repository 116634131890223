import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { dimsUnitOfMeasureAbbreviation, dimsDisplayValue } from 'utils/utils';
import { EnterFreightViewModel } from '.';
import WeightInput from '../../components/WeightInput';
import { caretAltDownIcon, caretAltUpIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';

type Props = {
  freight: EnterFreightViewModel;
  index: number;
  onChange: (newStop: EnterFreightViewModel, changeType: string) => void;
  add: (copy: boolean) => void;
  copy: (above: boolean) => void;
  remove: () => void;
};

class EnterFreight extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.updateFreight = this.updateFreight.bind(this);
    this.getStackableName = this.getStackableName.bind(this);
    this.onChangeStackable = this.onChangeStackable.bind(this);
  }

  render() {
    const peices = this.props.freight.Pieces > 0 ? this.props.freight.Pieces : '';
    const length = this.props.freight.Length > 0 ? dimsDisplayValue(this.props.freight.Length, this.props.freight.DimsUnitOfMeasure) : '';
    const width = this.props.freight.Width > 0 ? dimsDisplayValue(this.props.freight.Width, this.props.freight.DimsUnitOfMeasure) : '';
    const height = this.props.freight.Height > 0 ? dimsDisplayValue(this.props.freight.Height, this.props.freight.DimsUnitOfMeasure) : '';
    return (
      <div className="form-row" key={this.props.index}>
        <div className="col-md-1">
          <DropDownButton
              className="w-100"
              buttonClass="w-100"
              themeColor="primary"
              text={`Freight ${this.props.freight.PickupStopSequence}-${this.props.freight.DeliveryStopSequence}`}
              onItemClick={e => {
                if (e.itemIndex === 0) {
                  this.props.add(false);
                } else if (e.itemIndex === 1) {
                  this.props.add(true);
                } else if (e.itemIndex === 2) {
                  this.props.copy(true);
                } else if (e.itemIndex === 3) {
                  this.props.copy(false);
                } else {
                  this.props.remove();
                }
              }}
            >
              <DropDownButtonItem text="New - Empty" icon="plus" svgIcon={plusIcon} />
              <DropDownButtonItem text="New - Copy" icon="plus" svgIcon={plusIcon} />
              <DropDownButtonItem text="Copy From Above" icon="arrow-60-up" svgIcon={caretAltUpIcon} disabled={this.props.index === 0} />
              <DropDownButtonItem text="Copy From Below" icon="arrow-60-down" svgIcon={caretAltDownIcon} />
              <DropDownButtonItem text="Remove Freight" icon="trash" svgIcon={trashIcon} />
            </DropDownButton>
        </div>
        <div className="form-group col-md-2">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              placeholder="Pieces"
              value={peices}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.updateFreight({ Pieces: parseInt(e.target.value || '0') }, "FREIGHT_PIECES")}
            />
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="input-group">
            <WeightInput
              value={this.props.freight.Weight}
              type={this.props.freight.WeightUnitOfMeasure}
              onChange={(value) => this.updateFreight({ Weight: value.value, WeightUnitOfMeasure: value.type }, "FREIGHT_WEIGHT")}
            />
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              placeholder="L"
              value={length}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.updateFreight({ Length: this.onChangeLengthValue(e.target.value) }, "FREIGHT_LENGTH")}
            />
            <div className="input-group-prepend">
                <span className="input-group-text">X</span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder="W"
              value={width}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.updateFreight({ Width: this.onChangeLengthValue(e.target.value) }, "FREIGHT_WIDTH")}
            />
            <div className="input-group-prepend">
                <span className="input-group-text">X</span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder="H"
              value={height}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.updateFreight({ Height: this.onChangeLengthValue(e.target.value) }, "FREIGHT_HEIGHT")}
            />
            <div className="input-group-append">
              <button 
                type="button"
                title="Rotatable"
                onClick={() => this.updateFreight({ Rotatable: !this.props.freight.Rotatable }, "FREIGHT_ROTATABLE")}
                className="btn btn-outline-secondary"
                style={{ borderRadius: 0, paddingTop: 0,  paddingBottom: 0 }}
              >
                <svg aria-hidden="true" focusable="false" width="1.25rem" height="1.25rem" style={{ transform: 'rotate(360deg)' }} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                  <path fill={this.props.freight.Rotatable ? "#007bff" : "#6c757d"} d="M7.34 6.41L.86 12.9l6.49 6.48 6.49-6.48-6.5-6.49zM3.69 12.9l3.66-3.66L11 12.9l-3.66 3.66-3.65-3.66zm15.67-6.26A8.95 8.95 0 0 0 13 4V.76L8.76 5 13 9.24V6c1.79 0 3.58.68 4.95 2.05a7.007 7.007 0 0 1 0 9.9 6.973 6.973 0 0 1-7.79 1.44l-1.49 1.49C10.02 21.62 11.51 22 13 22c2.3 0 4.61-.88 6.36-2.64a8.98 8.98 0 0 0 0-12.72z" />
                </svg>
              </button>
              <button className="btn btn-outline-secondary dropdown-toggle" style={{ borderRadius: 0 }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{dimsUnitOfMeasureAbbreviation(this.props.freight.DimsUnitOfMeasure)}</button>
              <span>
                <div className="dropdown-menu">
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeLengthType(0)}>Inches</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeLengthType(1)}>Centimeters</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeLengthType(2)}>Millimeters</button>
                </div>
              </span>
              <span>
                <button className="btn btn-outline-secondary dropdown-toggle" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.getStackableName()}</button>
                <div className="dropdown-menu">
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(true)}>Stackable</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(false)}>Not Stackable</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(2)}>Stack 2</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(3)}>Stack 3</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(4)}>Stack 4</button>
                  <button type="button" className="dropdown-item" onClick={() => this.onChangeStackable(5)}>Stack 5</button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private updateFreight(value: Partial<EnterFreightViewModel>, changeType: string) {
    const newFreight = Object.assign({}, this.props.freight, value);
    this.props.onChange(newFreight, changeType);
  }

  private onChangeStackable(value: boolean | number) {
    if (typeof value === 'number') {
      this.updateFreight({ Stackable: true, StackableLimit: value }, "FREIGHT_STACKABLE");
    } else {
      this.updateFreight({ Stackable: value, StackableLimit: 0 }, "FREIGHT_STACKABLE");
    }
  }

  private getStackableName() {
    if (this.props.freight.StackableLimit) return `Stack ${this.props.freight.StackableLimit}`;
    return this.props.freight.Stackable ? "Stackable" : "Not Stackable";
  }

  private onChangeLengthType(type: 0|1|2) {
    this.updateFreight({ DimsUnitOfMeasure: type }, "FREIGHT_DIMS_TYPE");
  }

  private onChangeLengthValue(value: string): number {
    let parsedValue = parseFloat(value) || 0;
    if (this.props.freight.DimsUnitOfMeasure === 1) {
      parsedValue = parsedValue * 0.393701;
    }
    if (this.props.freight.DimsUnitOfMeasure === 2) {
      parsedValue = parsedValue * 0.0393701;
    }
    return parsedValue;
  }
}

export default EnterFreight;
