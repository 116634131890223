import { GridCellProps } from "@progress/kendo-react-grid";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon } from "@progress/kendo-svg-icons";
import { VehicleSuggestion } from "../Suggestions";

const VehicleNumberCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const dataItem: VehicleSuggestion = props.dataItem;
  return (
    <td role="button">
      <span style={{ color: dataItem.StatusColor, pointerEvents: 'none' }}>{dataItem.VehicleNumber}</span> ({dataItem.ComplianceDays})
      {dataItem.HasAcceptedOffer && <SvgIcon icon={checkIcon} color="green" />}
    </td>
  );
};

export default VehicleNumberCell;