import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { fetchApi } from "services/api";
import { JsonResponse } from "TypeGen/json-response";
import { Command as AddOrderPair } from "TypeGen/Order/AddPair/command";
import { ViewModel } from "TypeGen/Order/AddPair/view-model";
import { FreightEditViewModel } from "TypeGen/Order/freight-edit-view-model";
import { StopEditViewModel } from "TypeGen/Order/stop-edit-view-model";
import { StopVerb } from "TypeGen/stop-verb";
import { ILink } from "types/link";
import { OrderContext } from "./Details";
import FreightEditUI from "./Freight/Edit";
import StopEditUI from "./Stop/Edit";

type RouteComponentParams = {
  orderId: string;
  orderStopId?: string;
};

type Props = {
  orderId?: number;
  orderStopId?: number;
  onClose?: () => void;
  children?: React.ReactNode
};

const OrderAddPair: React.FC<Props> = props => {
  const { orderId: orderIdParam, orderStopId: orderStopIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pickup, setPickup] = useState<StopEditViewModel>();
  const [delivery, setDelivery] = useState<StopEditViewModel>();
  const [freight, setFreight] = useState<FreightEditViewModel>();
  const [links, setLinks] = useState<ILink[]>([]);
  const [orderId, setOrderId] = useState(0);
  const [orderStopId, setOrderStopId] = useState(0);

  const refreshOrderAddPair = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/AddPair/${orderId}/${orderStopId ?? 0}`)
      .then((response: ViewModel) => {
        // Parse dates
        response.PickupEdit.ScheduledDateTime = moment.utc(response.PickupEdit.ScheduledDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm");
        response.PickupEdit.EarliestStopDateTime = response.PickupEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.PickupEdit.EarliestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        response.PickupEdit.LatestStopDateTime = response.PickupEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.PickupEdit.LatestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        response.DeliveryEdit.ScheduledDateTime = moment.utc(response.DeliveryEdit.ScheduledDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm");
        response.DeliveryEdit.EarliestStopDateTime = response.DeliveryEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.DeliveryEdit.EarliestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        response.DeliveryEdit.LatestStopDateTime = response.DeliveryEdit.StopVerb === StopVerb.WINDOW ? moment.utc(response.DeliveryEdit.LatestStopDateTime).tz('America/New_York').format("MM/DD/YYYY HH:mm") : undefined;
        setPickup(response.PickupEdit);
        setDelivery(response.DeliveryEdit);
        setFreight(response.FreightEdit);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderId, orderStopId]);

  useEffect(() => {
    if (props.orderId > 0) {
      setOrderId(props.orderId);
      setOrderStopId(props.orderStopId);
    }
    else {
      setOrderId(Number(orderIdParam));
      setOrderStopId(Number(orderStopIdParam));
    }
  }, [orderIdParam, orderStopIdParam, props.orderId, props.orderStopId]);


  useEffect(() => {
    if (orderId > 0) {
      setActiveStopIds([orderStopId]);
      setDeleteStopIds([]);
      refreshOrderAddPair();
    }
  }, [orderId, orderStopId, refreshOrderAddPair, setActiveStopIds, setDeleteStopIds]);

  const save = () => {
    setLoading(true);
    const command = links.find(l => l.Name === 'Save');
    const data = {
      Pickup: {
        OrderStopID: pickup.OrderStopID,
        CustomerID: pickup.CustomerID,
        CustomerContactID: pickup.CustomerContactID,
        City: pickup.City,
        State: pickup.State,
        ZipCode: pickup.ZipCode,
        ScheduledDateTime: pickup.ScheduledDateTime,
        EarliestStopDateTime: pickup.EarliestStopDateTime,
        LatestStopDateTime: pickup.LatestStopDateTime,
        StopVerb: pickup.StopVerb,
      },
      Delivery: {
        OrderStopID: delivery.OrderStopID,
        CustomerID: delivery.CustomerID,
        CustomerContactID: delivery.CustomerContactID,
        City: delivery.City,
        State: delivery.State,
        ZipCode: delivery.ZipCode,
        ScheduledDateTime: delivery.ScheduledDateTime,
        EarliestStopDateTime: delivery.EarliestStopDateTime,
        LatestStopDateTime: delivery.LatestStopDateTime,
        StopVerb: delivery.StopVerb,
      },
      Freight: {
        OrderFreightDetailID: freight.OrderFreightDetailID,
        Pieces: freight.Pieces,
        PiecesUnitOfMeasure: freight.PiecesUnitOfMeasure,
        Weight: freight.Weight,
        WeightUnitOfMeasure: freight.WeightUnitOfMeasure,
        Length: freight.Length,
        Width: freight.Width,
        Height: freight.Height,
        DimsUnitOfMeasure: freight.DimsUnitOfMeasure,
        Stackable: freight.Stackable,
        CommodityID: freight.CommodityID,
        CommodityAltDescription: freight.CommodityAltDescription,
        BillOfLadingNumber: freight.BillOfLadingNumber,
        FreightValue: freight.FreightValue,
        ProofOfDeliverySignedBy: freight.ProofOfDeliverySignedBy,
      }
    } as AddOrderPair;
    fetchApi(command.Link, data, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          if (props.onClose)
            props.onClose();
          else {
            refreshOrder();
            history.push(`/Order/${orderId}`);
          }
        } else {
          alert(response.ErrorMessage);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to add stop');
      });
  }

  if (loading || !pickup || !delivery || !freight) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <div className='py-2'>
    <div className="row">
      <div className="col-md-6">
        <StopEditUI
          locationLabel="Pickup"
          orderStop={pickup}
          setOrderStop={setPickup}
        />
      </div>
      <div className="col-md-6">
        <StopEditUI
          locationLabel="Delivery"
          orderStop={delivery}
          setOrderStop={setDelivery}
        />
      </div>
    </div>
    <hr />
    <FreightEditUI orderFreight={freight} setOrderFreight={setFreight} />
    <div className="text-center mt-3">
      {props.onClose && <Button themeColor="secondary" onClick={props.onClose} className='mr-2'>Cancel</Button>}
      <Button themeColor="primary" onClick={save} disabled={loading}>Save</Button>
    </div>
  </div>
}

export default OrderAddPair;