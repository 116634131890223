import { Title } from '../../utils/title';
import moment from 'moment-timezone';
import { Loader } from "@progress/kendo-react-indicators";
import { Window } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import { Switch, Checkbox } from '@progress/kendo-react-inputs';
import { IDName } from '../../types/idname';
import { ViewModel } from 'TypeGen/LoadMap/UnitsInArea/view-model';
import realFormatter from 'services/formatting/number';
import { Coordinate } from 'TypeGen/coordinate';
import SingleLocationAutoComplete from 'views/PCMiler/SingleLocationAutoComplete';
import { AdvancedMarker, AdvancedMarkerAnchorPoint, APIProvider, InfoWindow, Map, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useState } from 'react';

type HeatMapFilter = {
    DriverID: number;
    VehicleTypeID: number;
    DaysAgo: number;
    Days: number;
    Today: boolean;
    MissedOpportunities: boolean;
    Trucks: boolean;
    TrucksOnALoad: boolean;
    TrucksOutOfService: boolean;
    AllianceTrucks: boolean;
    USDomesticLoads: boolean;
    InternationalLoads: boolean;
}

type HeatMapResponse = {
    VehiclePosition: VehiclePosition | null;
    Vehicles: VehicleMarker[];
    VehiclesOnALoad: VehicleMarker[];
    VehiclesOutOfService: VehicleMarker[];
    AllianceVehicles: ClusterMarker[];
    BookedOpportunities: ClusterMarker[];
    MissedOpportunities: ClusterMarker[];
}

type VehiclePosition = {
    DateTime: Date;
    Location: string;
    Coordinates: Coordinate;
    LastAvailableHours?: number;
    CallingCard: string | null;
    TruckingType: TruckingType;
}

type VehicleMarker = {
    Location: string;
    Coordinates: Coordinate;
    Team: boolean;
    LastAvailableHours: number;
    CallingCard: string | null;
    TruckingType: TruckingType;
}

enum TruckingType {
    NA,
    LongHaul,
    Regional,
    Local,
    LocalLongHaul,
}

type ClusterMarker = {
    Count: number;
    Location: string;
    Coordinates: Coordinate;
}

const LoadMapWrapper = () => {
    return <APIProvider apiKey='AIzaSyCDy2Rv9ng43qFzqsnzJ_R0t3WC209lvyA'>
        <LoadMap />
    </APIProvider>
}

const LoadMap = () => {
    const map = useMap();
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState<IDName[]>([]);
    const [vehicleTypes, setVehicleTypes] = useState<IDName[]>([]);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [vehicleFilter, setVehicleFilter] = useState<FilterDescriptor>();
    const [todayFilter, setTodayFilter] = useState<IDName[]>([]);
    const [filter, setFilter] = useState<HeatMapFilter>({
        DriverID: -1,
        VehicleTypeID: 0,
        DaysAgo: 0,
        Days: 2,
        Today: false,
        MissedOpportunities: false,
        Trucks: true,
        TrucksOnALoad: false,
        TrucksOutOfService: false,
        AllianceTrucks: false,
        USDomesticLoads: true,
        InternationalLoads: true
    });
    const [response, setResponse] = useState<HeatMapResponse | null>(null);
    const [zoom, setZoom] = useState(11);
    const [vehicle, setVehicle] = useState<google.maps.LatLngLiteral | google.maps.LatLng | null>({ lat: 42.225707, lng: -83.26349 });
    const [activeMarker, setActiveMarker] = useState<google.maps.LatLng | null>(null);
    const [activeMarkerTitle, setActiveMarkerTitle] = useState('');
    const [activeMarkerDescription, setActiveMarkerDescription] = useState('');
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showingVehicleInfoWindow, setShowingVehicleInfoWindow] = useState(true);
    const [searchPin, setSearchPin] = useState<google.maps.LatLngLiteral | null>(null);
    const [loadingUnitsInArea, setLoadingUnitsInArea] = useState(false);
    const [unitsInArea, setUnitsInArea] = useState<ViewModel | null>(null);

    const setFilterValue = (value: Partial<HeatMapFilter>) => {
        const newFilter = Object.assign({}, filter, value);
        setFilter(newFilter); // FETCH HERE
    }

    const exit = () => {
        window.history.go(-1);
    }

    const get = () => {
        fetchApi("/api/LoadBoard/HeatMap")
            .then((response: { Vehicles: IDName[], VehicleTypes: IDName[] }) => {
                setVehicles(response.Vehicles);
                setVehicleTypes(response.VehicleTypes);
                setLoadingFilter(false);
            });
    }

    useEffect(() => {
        // Hasn't selected a vehicle yet
        if (filter.DriverID == -1) {
            return;
        }
        setLoading(true);
        fetchApi("/api/LoadBoard/HeatMap", filter, 'POST')
            .then((data: HeatMapResponse) => {
                // Initial or new vehicle
                if (data.VehiclePosition)
                {
                    map?.setCenter({
                        lat: data.VehiclePosition.Coordinates.Latitude,
                        lng: data.VehiclePosition.Coordinates.Longitude
                    });
                    setVehicle({
                        lat: data.VehiclePosition.Coordinates.Latitude,
                        lng: data.VehiclePosition.Coordinates.Longitude
                    });
                }
                setResponse(data);
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('Unable to load map');
                console.error(e)
            })
            .finally(() => setLoading(false));
    }, [filter, map]);

    const fetchUnitsInArea = useCallback((lat: number, lng: number, radius: number = 75) => {
        if (filter.DriverID < 1) return;

        setLoadingUnitsInArea(true);
        const payload = {
            DriverID: filter.DriverID,
            Latitude: lat,
            Longitude: lng,
            Radius: radius
        }
        fetchApi("/api/LoadBoard/UnitsInArea", payload, 'POST')
            .then((response: ViewModel) => {
                setUnitsInArea(response);
            })
            .finally(() => setLoadingUnitsInArea(false));
    }, [filter.DriverID]);

    const onMarkerClick = (marker: google.maps.MapMouseEvent, title: string, description: string = '') => {
        setActiveMarker(marker.latLng);
        setActiveMarkerTitle(title);
        setActiveMarkerDescription(description);
        setShowingInfoWindow(true);
        setShowingVehicleInfoWindow(false);
    }

    const onVehicleMarkerClick = () => {
        setShowingVehicleInfoWindow(true);
        setShowingInfoWindow(false);
    }

    const getTruckerTypeName = (truckingType: TruckingType) => {
        switch(truckingType)
        {
            case TruckingType.LongHaul: return "Long Haul";
            case TruckingType.Regional: return "Regional";
            case TruckingType.Local: return "Local";
            case TruckingType.LocalLongHaul: return "Local/Long";
            default: return "Load1";
        }
    }

    useEffect(() => {
        const todayFilter: IDName[] = [];
        todayFilter.push({ ID: 0, Name: `Today`});
        todayFilter.push({ ID: 1, Name: `Last 2 Days`});
        todayFilter.push({ ID: 2, Name: `Last 7 Days`});
        todayFilter.push({ ID: 3, Name: `Last 14 Days`});
        todayFilter.push({ ID: 4, Name: `Last 30 Days`});
        for (let i = 1; i < 6; i++) {
            const date = moment().subtract(Math.abs(i - 8), 'days');
            const dateFormat = `${date.format('dddd')} - ${date.format('MM/DD/YYYY')}`;
            todayFilter.push({ ID: todayFilter.length, Name: `Last ${dateFormat}`});
        }
        setTodayFilter(todayFilter);

        get();
    }, []);

    useEffect(() => {
        if (!map) return;

        map.addListener('rightclick', (e: google.maps.MapMouseEvent) => {
            fetchUnitsInArea(e.latLng.lat(), e.latLng.lng());
        });
    
        // do something with the map instance
    }, [map, fetchUnitsInArea]);

    const filteredVehicles = filterBy(vehicles, vehicleFilter);
    return <div className="w-100 vh-100 d-flex">
        <Title string="Load Map" />
        <section className="col-3 p-2">
            <button type="button" className="btn btn-danger btn-lg btn-block" onClick={exit}>BACK</button>
            <br />
            <DropDownList
                style={{ width: '100%' }}
                data={filteredVehicles}
                value={filteredVehicles.find((value) => value.ID === filter.DriverID)}
                textField="Name"
                filterable={true}
                onFilterChange={(e) => setVehicleFilter(e.filter)}
                onChange={(e) => setFilterValue({
                    DriverID: e.target.value.ID,
                    VehicleTypeID: 0,
                    AllianceTrucks: e.target.value.ID === 0 ? false : filter.AllianceTrucks,
                    MissedOpportunities: e.target.value.ID === 0 ? false : filter.MissedOpportunities,
                })}
                defaultValue={{ Name: 'Select Vehicle' }}
                loading={loadingFilter}
            />
            <DropDownList
                style={{ width: '100%' }}
                className='mt-2'
                data={vehicleTypes}
                value={vehicleTypes.find((value) => value.ID === filter.VehicleTypeID)}
                textField="Name"
                onChange={(e) => setFilterValue({
                    DriverID: e.target.value.ID > 0 ? 0 : -1,
                    VehicleTypeID: e.target.value.ID,
                    AllianceTrucks: false,
                    MissedOpportunities: e.target.value.ID === 0 ? false : filter.MissedOpportunities,
                })}
            />
            <SingleLocationAutoComplete
                autoCompleteProps={{ clearButton: true, placeholder: 'Find City or Zip', disabled: filter.DriverID !== 0, className: 'mt-2' }}
                onSelected={(e) => {
                    const searchPin = e ? { lat: parseFloat(e.Coords.Lat), lng: parseFloat(e.Coords.Lon) } : null;
                    if (e) {
                        setShowingInfoWindow(false);
                        map.setCenter(searchPin);
                        setSearchPin(searchPin);
                    } else {
                        setSearchPin(null);
                    }
                }}
            />
            <br /><br />
            {filter.MissedOpportunities ? 'Missed' : 'Booked'} <Switch
                disabled={filter.DriverID === 0 && filter.VehicleTypeID === 0}
                checked={filter.MissedOpportunities}
                onChange={(e) => setFilterValue({ MissedOpportunities: e.value })}
            />
            <br /><br />
            <DropDownList 
                style={{ width: '100%' }}
                data={todayFilter}
                textField="Name"
                onChange={(e) => {
                    const buttonIndex = e.target.value.ID;
                    const days = (buttonIndex === 0) ? 0 :
                        (buttonIndex === 1) ? 2 :
                        (buttonIndex === 2) ? 7 :
                        (buttonIndex === 3) ? 14 :
                        (buttonIndex === 4) ? 30 : 1;
                    const daysAgo = (buttonIndex <= 1) ? 0 :
                        (buttonIndex === 2) ? 0 :
                        (buttonIndex === 3) ? 0 :
                        (buttonIndex === 4) ? 0 : buttonIndex - 12;
                    const todayFilter = buttonIndex === 0;
                    setFilterValue({  Today: todayFilter, Days: days, DaysAgo: daysAgo });
                }}
                defaultValue={{ ID: 1, Name: 'Last 2 Days' }}
            />
            <br /><br />
            <Checkbox 
                value={filter.Trucks}
                onChange={(e) => setFilterValue({ Trucks: e.value })}
                label={'Vehicles - In Service'}
            />
            <br /><br />
            <Checkbox 
                value={filter.TrucksOnALoad}
                onChange={(e) => setFilterValue({ TrucksOnALoad: e.value })}
                label={'Vehicles - On A Load'}
            />
            <br /><br />
            <Checkbox 
                value={filter.TrucksOutOfService}
                onChange={(e) => setFilterValue({ TrucksOutOfService: e.value })}
                label={'Vehicles - Out Of Service'}
            />
            <br /><br />
            <Checkbox 
                disabled={filter.DriverID === 0}
                value={filter.AllianceTrucks}
                onChange={(e) => setFilterValue({ AllianceTrucks: e.value })}
                label={'Alliance Trucks'}
            />
            <br /><br />
            <Checkbox 
                value={filter.USDomesticLoads}
                onChange={(e) => setFilterValue({ USDomesticLoads: e.value })}
                label={'U.S. Domestic Loads'}
            />
            <br /><br />
            <Checkbox 
                value={filter.InternationalLoads}
                onChange={(e) => setFilterValue({ InternationalLoads: e.value })}
                label={'International Loads'}
            />
            <br /><br />
            {filter.DriverID !== 0 && <p className="text-muted">*Tip: Right click map to see units in area</p>}
        </section>
        <section className="col-9 p-0">
            {loading && <span
                className="k-i-loading k-icon"
                style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
            />}
            <Map
                mapId='cb0766f9fb0b26ea'
                clickableIcons={true}
                zoom={zoom}
                onZoomChanged={(e) => setZoom(e.detail.zoom)}
                defaultCenter={{ lat: 42.225707, lng: -83.26349 }}
                style={{ height: "100%" }}
            >
                {response?.MissedOpportunities.map((missed, index) => {
                    const scaledSize = 16 + (Math.min(100, missed.Count) / 2);
                    const title = missed.Location;
                    return missed.Count == 1 ? <AdvancedMarker
                        key={'m1' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={title}
                        position={{lat: missed.Coordinates.Latitude, lng: missed.Coordinates.Longitude}}
                    >
                        <img src="/images/DriverApp/MapIconMissed@2x.png" width={27} height={27} />
                    </AdvancedMarker> : <AdvancedMarker
                        key={'m2' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={title}
                        position={{lat: missed.Coordinates.Latitude, lng: missed.Coordinates.Longitude}}
                    >
                        <div className="position-relative">
                            <p className='position-absolute top-50 start-50 translate-middle'>{missed.Count}</p>
                            <img src='/images/DriverApp/RedPin.png' width={scaledSize} height={scaledSize} />
                        </div>
                    </AdvancedMarker>
                })}
                {response?.BookedOpportunities.map((booked, index) => {
                    const scaledSize = 16 + (Math.min(100, booked.Count) / 2);
                    const title = booked.Location;
                    return booked.Count == 1 ? <AdvancedMarker
                        key={'b1' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={title}
                        position={{lat: booked.Coordinates.Latitude, lng: booked.Coordinates.Longitude}}
                    >
                        <img src='/images/DriverApp/MapIconBooked@2x.png' width={27} height={27} />
                    </AdvancedMarker> : <AdvancedMarker
                        key={'b2' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={title}
                        position={{lat: booked.Coordinates.Latitude, lng: booked.Coordinates.Longitude}}
                    >
                        <div className="position-relative">
                            <p className='position-absolute top-50 start-50 translate-middle'>{booked.Count}</p>
                            <img src='/images/DriverApp/GreenPin.png' width={scaledSize} height={scaledSize} />
                        </div>
                    </AdvancedMarker>
                })}
                {response?.AllianceVehicles.map((allianceVehicle, index) => {
                    const scaledSize = 16 + (Math.min(100, allianceVehicle.Count) / 2);
                    const title = allianceVehicle.Count == 1 
                        ? `Carrier Unit at ${allianceVehicle.Location.toUpperCase()}` : `Carrier Units at ${allianceVehicle.Location.toUpperCase()}`;
                    return allianceVehicle.Count == 1 ? <AdvancedMarker
                        key={'av1' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={allianceVehicle.Location.toUpperCase()}
                        position={{lat: allianceVehicle.Coordinates.Latitude, lng: allianceVehicle.Coordinates.Longitude}}
                    >
                        <img src='/images/DriverApp/MapIconAllianceTruck@2x.png' width={27} height={27} />
                    </AdvancedMarker> : <AdvancedMarker
                        key={'av2' + index}
                        onClick={(marker) => onMarkerClick(marker, title)}
                        title={allianceVehicle.Location.toUpperCase()}
                        position={{lat: allianceVehicle.Coordinates.Latitude, lng: allianceVehicle.Coordinates.Longitude}}
                    >
                        <div className="position-relative">
                            <p className='position-absolute top-50 start-50 translate-middle'>{allianceVehicle.Count}</p>
                            <img src='/images/DriverApp/OrangePin.png' width={scaledSize} height={scaledSize} />
                        </div>
                    </AdvancedMarker>
                })}
                {response?.Vehicles.map((vehicle, index) => {
                    const title = `${getTruckerTypeName(vehicle.TruckingType)} Unit at ${vehicle.Location.toUpperCase()}`;
                    const description = vehicle.CallingCard ? `INS for ${vehicle.LastAvailableHours} hr(s). ${vehicle.CallingCard}` : `INS for ${vehicle.LastAvailableHours} hr(s).`;
                    return <AdvancedMarker
                        key={'v' + index}
                        onClick={(marker) => onMarkerClick(marker, title, description)}
                        title={vehicle.Location.toUpperCase()}
                        position={{lat: vehicle.Coordinates.Latitude, lng: vehicle.Coordinates.Longitude}}
                    >
                        <img src={`/images/DriverApp/MapIconTruck${vehicle.Team ? 'Team' : ''}@2x.png`} width={30} height={30} />
                    </AdvancedMarker>
                })}
                {response?.VehiclesOnALoad.map((vehicle, index) => {
                    const title = `${getTruckerTypeName(vehicle.TruckingType)} Unit at ${vehicle.Location.toUpperCase()}`;
                    const description = vehicle.CallingCard ? `On A Load ${vehicle.CallingCard}` : `On A Load`;
                    return <AdvancedMarker
                        key={'v' + index}
                        onClick={(marker) => onMarkerClick(marker, title, description)}
                        title={vehicle.Location.toUpperCase()}
                        position={{lat: vehicle.Coordinates.Latitude, lng: vehicle.Coordinates.Longitude}}
                    >
                        <img src={`/images/DriverApp/MapIconTruckOnALoad${vehicle.Team ? 'Team' : ''}@2x.png`} width={30} height={30} />
                    </AdvancedMarker>
                })}
                {response?.VehiclesOutOfService.map((vehicle, index) => {
                    const title = `${getTruckerTypeName(vehicle.TruckingType)} Unit at ${vehicle.Location.toUpperCase()}`;
                    const description = vehicle.CallingCard ? `Out Of Service ${vehicle.CallingCard}` : `Out Of Service`;
                    return <AdvancedMarker
                        key={'v' + index}
                        onClick={(marker) => onMarkerClick(marker, title, description)}
                        title={vehicle.Location.toUpperCase()}
                        position={{lat: vehicle.Coordinates.Latitude, lng: vehicle.Coordinates.Longitude}}
                    >
                        <img src={`/images/DriverApp/MapIconTruckOutOfService${vehicle.Team ? 'Team' : ''}@2x.png`} width={30} height={30} />
                    </AdvancedMarker>
                })}
                {filter.DriverID > 0 && <AdvancedMarker
                    onClick={onVehicleMarkerClick}
                    position={vehicle}
                    zIndex={10000}
                    anchorPoint={AdvancedMarkerAnchorPoint.TOP_CENTER}
                >
                    <img src="/images/DriverApp/TruckRight@2x.png" width={60} height={60} />
                </AdvancedMarker>}
                {searchPin && <AdvancedMarker
                    position={searchPin}
                />}
                {activeMarker != null && showingInfoWindow && <InfoWindow position={activeMarker} pixelOffset={[0, -20]}>
                    <p className="text-dark font-weight-bold m-0">
                        {activeMarkerTitle}
                    </p>
                    {activeMarkerDescription && <p className="text-dark m-0">
                        {activeMarkerDescription}
                    </p>}
                </InfoWindow>}
                {showingVehicleInfoWindow && response?.VehiclePosition && <InfoWindow position={vehicle}>
                    <p className="text-dark m-0">
                        As Of: {moment.utc(response.VehiclePosition.DateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                    </p>
                    <p className="text-dark m-0">
                        At: {response.VehiclePosition.Location}
                    </p>
                    ${response.VehiclePosition.LastAvailableHours !== null &&
                        <p className="text-dark m-0">INS for ${response.VehiclePosition.LastAvailableHours} hrs.</p>}
                    ${response.VehiclePosition.CallingCard !== null &&
                        <p className="text-dark m-0">${response.VehiclePosition.CallingCard}</p> }
                </InfoWindow>}
            </Map>
            {(unitsInArea !== null || loadingUnitsInArea === true) && <Window
                title={unitsInArea !== null ? `Units In Area - ${unitsInArea.Location}` : 'Units In Area'}
                onClose={() => setUnitsInArea(null)}
                initialHeight={400}
                initialWidth={500}
            >
                {loadingUnitsInArea === true && <div className='text-center'>
                    <Loader size="large" />
                </div>}
                {unitsInArea != null && <div className="p-2 text-center">
                    <p>Your Position: {unitsInArea.Position}</p>
                    <p>{realFormatter(unitsInArea.Distance)} miles from current location</p>
                    {unitsInArea.Vehicles.map((value, index) => {
                        return <p className='mb-0' key={index}>{value.Name}: {value.Count}</p>
                    })}
                    <p className='mt-1'>75 miles radius</p>
                </div>}
            </Window>}
        </section>
    </div>;
}

export default LoadMapWrapper;