import * as React from 'react';

import TripCountByServiceTeam from './TripCountByServiceTeam';
import TripCountYesterday from './TripCountYesterday';
import TripCountTomorrow from './TripCountTomorrow';
import VehicleAverageLA from './VehicleAverageLA';
import { Title } from '../../utils/title';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useInterval, useUpdateEffect } from 'usehooks-ts';
import { filters } from '.';
import { Button } from '@progress/kendo-react-buttons';
import { arrowRotateCwIcon } from '@progress/kendo-svg-icons';

const Operations2Reports = () => {

  const { status } = useThemeSwitcher();
  const tripCountByServiceTeam = React.useRef<TripCountByServiceTeam>();
  const tripCountTomorrow = React.useRef<TripCountTomorrow>();
  const vehicleAverageLA = React.useRef<VehicleAverageLA>();
  const tripCountYesterday = React.useRef<TripCountYesterday>();
  const [filter, setFilter] = React.useState(filters[0]);

  const refresh = () => {
    tripCountByServiceTeam.current?.refreshData();
    tripCountTomorrow.current?.refreshData();
    vehicleAverageLA.current?.refreshData();
    tripCountYesterday.current?.refreshData();
  }

  useUpdateEffect(() => {
    refresh();
  }, [filter]);

  useInterval(() => {
    refresh();
  }, 5 * 60 * 1000);

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  return <React.Fragment>
      <Title string="Ops Reports #2" />
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="#">Ops Reports #2</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                {filter.Name}
              </a>
              <div className="dropdown-menu">
                {filters.map((x, index) => <a key={index} className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); setFilter(x); }}>{x.Name}</a>)}
              </div>
            </li>
          </ul>
        </div>
        <ul className="nav navbar-nav navbar-right">
          <Button
            title="Refresh"
            icon="refresh"
            svgIcon={arrowRotateCwIcon}
            onClick={refresh}
          />
        </ul>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <VehicleAverageLA ref={vehicleAverageLA} />
          <TripCountTomorrow ref={tripCountTomorrow} filter={filter} />
          <TripCountYesterday ref={tripCountYesterday} filter={filter} />
          <TripCountByServiceTeam ref={tripCountByServiceTeam} filter={filter} />
        </div>
      </div>
    </React.Fragment>
}

export default Operations2Reports;
