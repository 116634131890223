import * as React from 'react';
import { Title } from '../../utils/title';
import { Grid, GridColumn as Column, GridToolbar, GridItemChangeEvent, } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { DataSourceRequestState, filterBy, orderBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import MyCommandCell from './MyCommandCell';
import { Button } from '@progress/kendo-react-buttons';
import EditorTextCell from './EditorTextCell';
import { JsonResponse } from 'TypeGen/json-response';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';
import ModifiedCell from './ModifiedCell';
import AuditLogs from './AuditLogs';

type Props = {};

type State = {
  active: boolean;
  data: AgentNote[];
  originalData: AgentNote[];
  dataState: DataSourceRequestState;
  editID: null|number;
  logID: null|number;
  loading: boolean;
}

const resetDataState = {
  skip: 0,
  take: 50,
} as DataSourceRequestState;

export type AgentNote = {
  ID: number;
  Active: boolean;
  Domain: string;
  Note: string;
  Tag1: string;
  Tag2: string;
  IsTop: boolean;
  ModifiedDateTime: string | null;
  ModifiedByFullName: string | null;
  BillToCustomerNumber: string;

  inEdit: boolean;
}

export default class AgentNotes extends React.Component<Props, State> {

  private export: React.RefObject<ExcelExport>;
  private CommandCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.state = {
      active: true,
      data: [],
      originalData: [],
      dataState: resetDataState,
      editID: null,
      logID: null,
      loading: false,
    };

    this.export = React.createRef();

    this.reset = this.reset.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.enterInsert = this.enterInsert.bind(this);
    this.itemChange = this.itemChange.bind(this);

    const enterEdit = this.enterEdit.bind(this);
    const save = this.save.bind(this);
    const cancel = this.cancel.bind(this);
    const remove = this.remove.bind(this);
    const logs = (dataItem: AgentNote) => this.setState({ logID: dataItem.ID });
    this.CommandCell = MyCommandCell(enterEdit, remove, save, cancel, logs, "inEdit");
  }

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    const data = filterBy(this.state.data, this.state.dataState.filter);
    return (
      <React.Fragment>
        <Title string="Agent Notes" />
        {this.state.logID && <AuditLogs AgentNoteID={this.state.logID} CloseDialog={() => this.setState({ logID: null })} />}
        {this.state.loading && <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
        />}
        <ExcelExport ref={this.export}>
          <Grid
            {...this.state.dataState}
            resizable
            pageable
            filterable
            total={data.length}
            data={data.map((item) =>
              Object.assign({
                  inEdit: item.ID === this.state.editID
              }, item)
            ).slice(this.state.dataState.skip, this.state.dataState.take + this.state.dataState.skip)}
            onDataStateChange={(e) => this.setState({ dataState: e.dataState })}
            editField="inEdit"
            onItemChange={this.itemChange}
            dataItemKey="ID"
          >
            <GridToolbar>
              <Button
                title="Reset Filter"
                icon="filter-clear"
                svgIcon={filterClearIcon}
                onClick={this.reset}
              />
              <Button
                title="Refresh"
                icon="refresh"
                svgIcon={arrowRotateCwIcon}
                onClick={this.fetchData}
              />
              <Button
                togglable
                themeColor={this.state.active ? 'primary' : 'error'}
                onClick={() => { this.setState({ active: !this.state.active }, this.fetchData) } }
              >
                {this.state.active ? 'Active' : 'Deleted'}
              </Button>
              <Button
                onClick={() => this.export.current.save(this.state.data)}
              >Excel
              </Button>
              <Button
                themeColor="primary"
                onClick={this.enterInsert}
              >Add Domain
              </Button>
            </GridToolbar>
            <Column field="Domain" editor="text" width={200} cell={EditorTextCell}/>
            <Column field="Note" editor="text" cell={EditorTextCell} />
            <Column field="BillToCustomerNumber" editor="text" title="Bill To" width={150} cell={EditorTextCell} />
            <Column field="Tag1" editor="text" width={150} cell={EditorTextCell} />
            <Column field="Tag2" editor="text" width={150} cell={EditorTextCell} />
            <Column field="IsTop" title="Top" width={50} filter="boolean" editor="boolean" />
            <Column field="ModifiedDateTime" title="Modified" filter="date" editor="date" width={150} cell={ModifiedCell} />
            <Column cell={this.CommandCell} width={160} />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }

  private enterInsert() {
    // Only allow adding one at a time
    const index = this.state.data.findIndex(p => p.ID === 0);
    if (index === -1) {
      const dataItem = { ID: 0, Domain: '', Note: '', Tag1: '', Tag2: '', IsTop: false, ModifiedDateTime: null, ModifiedByFullName: null, BillToCustomerNumber: '', inEdit: true } as AgentNote;
      const newnotes = this.state.data.slice();
      newnotes.unshift(dataItem);
      this.update(newnotes, dataItem);
      this.setState({
          data: newnotes
      });
      this.reset();
    }
  }

  private enterEdit(dataItem: AgentNote) {
    this.update(this.state.data, dataItem).inEdit = true;
    this.setState({
        data: this.state.data.slice()
    });
  }

  private save(dataItem: AgentNote) {
    if (!dataItem.Domain || !dataItem.Note) {
      alert("Please enter a domain and note!");
      return;
    }

    this.setState({ loading: true });

    if (dataItem.ID) {
      const data = {
        AgentNoteID: dataItem.ID,
        Domain: dataItem.Domain,
        Note: dataItem.Note,
        Tag1: dataItem.Tag1,
        Tag2: dataItem.Tag2,
        IsTop: dataItem.IsTop,
        BillToCustomerNumber: dataItem.BillToCustomerNumber
      }
      fetchApi('/api/Quote/UpdateAgentNote', data, 'POST')
        .then((response: JsonResponse) => {
          if (response.Success) {
            dataItem.inEdit = undefined;
            this.fetchData();
          } else {
            this.setState({ loading: false });
            alert(response.ErrorMessage);
          }
        })
        .catch(() => {
          alert("Unable to update agent note");
          this.setState({ loading: false });
        });
    } else {
      const data = {
        Domain: dataItem.Domain,
        Note: dataItem.Note,
        Tag1: dataItem.Tag1,
        Tag2: dataItem.Tag2,
        IsTop: dataItem.IsTop,
        BillToCustomerNumber: dataItem.BillToCustomerNumber
      }
      fetchApi('/api/Quote/CreateAgentNote', data, 'POST')
        .then((response: JsonResponse) => {
          if (response.Success) {
            dataItem.inEdit = undefined;
            this.fetchData();
          } else {
            this.setState({ loading: false });
            alert(response.ErrorMessage);
          }
        })
        .catch(() => {
          alert("Unable to create agent note");
          this.setState({ loading: false });
        });
    }
  }

  private cancel(dataItem: AgentNote) {
    if (dataItem.ID) {
      let originalItem = this.state.originalData.find((p) => p.ID === dataItem.ID);
      originalItem.inEdit = undefined;
      this.update(this.state.data, originalItem);
    } else {
      this.update(this.state.data, dataItem, !dataItem.ID);
    }
    this.setState({
      data: this.state.data.slice()
    });
  }

  private remove(dataItem: AgentNote) {
    if (dataItem.ID) {
      const data = {
        AgentNoteID: dataItem.ID
      }
      fetchApi('/api/Quote/DeleteAgentNote', data, 'DELETE')
        .then(() => {

          // Remove from Grid
          dataItem.inEdit = undefined;
          this.update(this.state.data, dataItem, true);
          this.update(this.state.originalData, dataItem, true);
          this.setState({
              data: this.state.data.slice()
          });
        });
    } else {

      // Remove from Grid
      dataItem.inEdit = undefined;
      this.update(this.state.data, dataItem, true);
      this.update(this.state.originalData, dataItem, true);
      this.setState({
          data: this.state.data.slice()
      });
    }
  }

  private itemChange(event: GridItemChangeEvent) {
    const value = event.value;
    const name = event.field;
    if (!name) {
        return;
    }
    const updatedData = this.state.data.slice();
    const item = this.update(updatedData, event.dataItem);
    (item as any)[name] = value;
    this.setState({
        data: updatedData
    });
}

 private update(data: AgentNote[], item: AgentNote, remove: boolean = false): AgentNote {
    let updated;
    let index = data.findIndex(p => p === item || item.ID && p.ID === item.ID);
    if (index === -1) {
      index = data.findIndex(p => p.ID === 0);
    }
    if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
    }

    if (remove) {
        data = data.splice(index, 1);
    }

    return data[index];
  }

  private reset() {
    this.setState({ dataState: resetDataState });
  }

  private fetchData() {
    this.setState({ loading: true });

    fetchApi('/api/Quote/AgentNotes', { Active: this.state.active }, 'POST')
      .then((data: AgentNote[]) => {
        this.setState({ data, originalData: data });
      }).finally(() => {
        this.setState({ loading: false });
      })
  }
}
