export default function timezoneFormatterIANA(TimeZoneID: string) {
  switch(TimeZoneID) {
    case "Hawaiian Standard Time":
        return "Pacific/Honolulu";
    case "Alaskan Standard Time":
        return "America/Anchorage";
    case "Pacific Standard Time":
        return "America/Los_Angeles";
    case "Pacific Standard Time (Mexico)":
        return "America/Tijuana";
    case "Mountain Standard Time":
        return "America/Denver";
    case "Mountain Standard Time (Mexico)":
        return "America/Chihuahua";
    case "Central Standard Time":
        return "America/Chicago";
    case "Central Standard Time (Mexico)":
        return "America/Mexico_City";
    case "Eastern Standard Time":
        return "America/New_York";
    case "Eastern Standard Time (Mexico)":
        return "America/Cancun";
    case "Atlantic Standard Time":
        return "America/Halifax";
    case "Newfoundland Standard Time":
        return "America/St_Johns";
    default:
        return "America/New_York";
  }
}
