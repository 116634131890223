import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';
import Moment from 'moment-timezone';

export default class DocumentTitleCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td><Link to={`/document/${this.props.dataItem.DocumentID}`}>{this.props.dataItem.Title || "No Title"}</Link></td>
    );
  }
}

export class DocumentCreatedCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td><span>{Moment.utc(this.props.dataItem.CreatedDate).local().format('MM/DD/YYYY hh:mm a')}</span></td>
    );
  }
}
