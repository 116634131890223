import { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { fetchApi } from "../../services/api";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Title } from "../../utils/title";
import { ILink } from "../../types/link";
import OrderCell from "./cells/OrderCell";
import IdentCell from "./cells/IdentCell";
import CommandCell from "./cells/CommandCell";
import YesNoCell from "../../components/cells/YesNoCell";
import LocalDateOnlyCell from "../../components/cells/LocalDateOnlyCell";
import OriginCell from "./cells/OriginCell";
import DestinationCell from "./cells/DestinationCell";
import { JsonResponse } from "TypeGen/json-response";
import { arrowRotateCwIcon } from "@progress/kendo-svg-icons";

export type Alert = {
  AlertID: number;
  OrderNumber: number;
  Ident: string;
  Description: string;
  Origin: string;
  Destination: string;
  StartDate: Date | null;
  EndDate: Date | null;
  Arrival: boolean;
  Cancelled: boolean;
  Departure: boolean;
  Diverted: boolean;
  Filed: boolean;
  Links: ILink[];
}

const TrackFlights = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);

  // Add
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [newIdent, setNewIdent] = useState('');
  const [newOrigin, setNewOrigin] = useState('');
  const [newDestination, setNewDestination] = useState('');
  const [newOrderNumber, setNewOrderNumber] = useState(0);
  const [newArrival, setNewArrival] = useState(false);
  const [newCancelled, setNewCancelled] = useState(true);
  const [newDeparture, setNewDeparture] = useState(true);
  const [newDiverted, setNewDiverted] = useState(true);
  const [newFiled, setNewFiled] = useState(false);

  useEffect(() => {
    _refresh();
  } , []);

  const _refresh = () => {
    setLoading(true);
    fetchApi("/api/FlightAware/ListAlerts")
      .then((response: { Alerts: Alert[], Links: ILink[] }) => {
        setAlerts(response.Alerts);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert('Error fetching flight alerts!');
        setLoading(false);
      });
  }


  const _add = () => {

    if (!newIdent) {
      alert('Ident is required!');
      return;
    }

    if (newOrderNumber <= 1000) {
      alert('Order number is required!');
      return;
    }

    const data = {
      Ident: newIdent,
      Origin: newOrigin,
      Destination: newDestination,
      OrderNumber: newOrderNumber,
      Arrival: newArrival,
      Cancelled: newCancelled,
      Departure: newDeparture,
      Diverted: newDiverted,
      Filed: newFiled,
    }
    fetchApi("/api/FlightAware/AddAlert", data, "POST")
      .then((response: JsonResponse) => {
        if (response.Success) {
          setShowPopup(false);
          setNewIdent('');
          setNewOrigin('');
          setNewDestination('');
          setNewOrderNumber(0);
          _refresh();
        } else {
          alert(response.ErrorMessage);
        }
      }).catch(err => {
        alert('Error adding flight alert!');
      });
    }


  if (loading) return <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  return <>
    <Title string="Flights" />
    {showPopup && <Dialog title="Track on FlightAware" onClose={() => setShowPopup(false)}>
      <Input
        required
        autoFocus
        style={{ width: "100%" }}
        className="mb-2"
        value={newIdent}
        onChange={(e) => setNewIdent(e.value)}
        label="Ident"
        placeholder="Flight or Tail #"
      />
      <Input
        style={{ width: "100%" }}
        className="mb-2"
        value={newOrigin}
        onChange={(e) => setNewOrigin(e.value)}
        label="Origin Airport Code"
        placeholder="ICAO, IATA, or LID (Optional)"
      />
      <Input
        style={{ width: "100%" }}
        className="mb-2"
        value={newDestination}
        onChange={(e) => setNewDestination(e.value)}
        label="Destination Airport Code"
        placeholder="ICAO, IATA, or LID (Optional)"
      />
      <NumericTextBox
        required
        value={newOrderNumber}
        width="100%"
        className="mb-2"
        spinners={false}
        format="0"
        onChange={(e) => setNewOrderNumber(e.value)}
        label="Order Number"
        min={0}
      />
      <Checkbox checked={newArrival} onChange={(e) => setNewArrival(e.value)} label="Arrival" className="pr-2" />
      <Checkbox checked={newCancelled} onChange={(e) => setNewCancelled(e.value)} label="Cancelled" className="pr-2" />
      <Checkbox checked={newDeparture} onChange={(e) => setNewDeparture(e.value)} label="Departure" className="pr-2" />
      <Checkbox checked={newDiverted} onChange={(e) => setNewDiverted(e.value)} label="Diverted" className="pr-2" />
      <Checkbox checked={newFiled} onChange={(e) => setNewFiled(e.value)} label="Filed" className="pr-2" />
      <DialogActionsBar layout="end">
        <Button onClick={() => setShowPopup(false)}>Cancel</Button>
        <Button themeColor="primary" onClick={_add}>Track</Button>
      </DialogActionsBar>
    </Dialog>}
    <Grid
      data={alerts}
      dataItemKey="AlertID"
    >
      <GridToolbar>
        <Button
          themeColor="primary"
          icon="add"
          onClick={() => setShowPopup(true)}
        />
        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={_refresh}
        />
      </GridToolbar>
      <Column field="OrderNumber" title="Order" cell={OrderCell} />
      <Column field="Flight" cell={IdentCell} />
      <Column field="Description" />
      <Column field="Origin" cell={OriginCell} />
      <Column field="Destination" cell={DestinationCell} />
      <Column field="StartDate" title="Start" cell={LocalDateOnlyCell} />
      <Column field="EndDate" title="End" cell={LocalDateOnlyCell} />
      <Column field="Arrival" cell={YesNoCell} />
      <Column field="Departure" cell={YesNoCell} />
      <Column field="Cancelled" cell={YesNoCell} />
      <Column field="Diverted" cell={YesNoCell} />
      <Column field="Filed" cell={YesNoCell} />
      <Column field="AlertID" title="Action" cell={(props) => CommandCell(props, _refresh)} />
    </Grid>
  </>;
}

export default TrackFlights;
