import { useParams } from 'react-router';
import AssetPanel from '../Assets/AssetPanel';

type Params = {
  searchTerm: string;
};

const AssetDrivers = () => {
  const { searchTerm } = useParams<Params>();
  return <AssetPanel
    searchTerm={searchTerm}
    tabIndex={1}
  />
}

export default AssetDrivers;
