
import { Button } from '@progress/kendo-react-buttons';
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import { ViewModel } from "TypeGen/Trip/Attributes/view-model";
import LoadingPanel from "components/LoadingPanel";
import { fetchApi } from "services/api";
import { JsonResponse } from 'TypeGen/json-response';
import { TripNote } from 'TypeGen/Trip/Attributes/trip-note';
import { NoteEditUI } from 'views/Order/Notes/Edit';
import { SvgIcon } from "@progress/kendo-react-common";
import { infoCircleIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  tripId: string;
};

type Props = {
  children?: React.ReactNode
};

const TripAttributes: React.FC<Props> = props => {

  const { tripId: tripIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<ViewModel>();
  const [note, setNote] = useState<TripNote>(undefined);

  const refreshTripAttributes = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Trip/Attributes/${tripIdParam}`)
      .then((response: ViewModel) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [tripIdParam]);

  const newNote = () => {
    setNote({
      OrderNoteID: 0,
      ModifiedDateTime: '',
      ModifiedByFullName: '',
      IsCritical: false,
      Note: ''
    });
  }

  const addNote = (note: TripNote) => {
    const link = details.Links.find(l => l.Name === 'AddNote');
    setLoading(true);
    fetchApi(link.Link, note, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setNote(undefined);
          refreshTripAttributes();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  useEffect(() => {
    refreshTripAttributes();
  }, [tripIdParam, refreshTripAttributes]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return <div>
    {note && <NoteEditUI
      loading={loading}
      orderNote={note}
      cancel={() => setNote(undefined)}
      noteType="Trip"
      save={addNote}
    />}
    {details.Notes.length === 0
      ? <h4 className="text-center mt-2">No Trip Notes.</h4>
      : <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Trip Note</th>
              <th>Modified By</th>
              <th>Modified Date</th>
            </tr>
          </thead>
          <tbody>
            {details.Notes.map(note => {
             return <tr key={note.OrderNoteID} onClick={() => setNote(note)}>
                <td>
                  {note.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
                  {note.Note}
                </td>
                <td>{note.ModifiedByFullName}</td>
                <td>{moment.utc(note.ModifiedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
              </tr>})}
        </tbody>
      </table>}
    <div className="text-center mt-3">
      <Button
        onClick={newNote}
        themeColor="primary"
      >
        Add Note
      </Button>
    </div>
  </div>
}

export default TripAttributes;