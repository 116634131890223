import * as React from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { NumericTextBox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateTimePicker, TimePicker, DatePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { formatNumber } from '@progress/kendo-intl';
import { connect, ConnectedProps } from 'react-redux';
import Moment from 'moment';
import { debounce } from 'ts-debounce';
import { SourceType } from '../sourceType';
import LoadingPanel from '../../../components/LoadingPanel';
import { fetchApi } from '../../../services/api';
import Checkbox from '../../../components/Checkbox';
import { ApplicationState } from '../../../store';
import * as LoadBoardBidState from '../../../store/LoadBoardBid';
import YesNoCell from '../../../components/cells/YesNoCell';
import DateCell from '../../../components/cells/DateCell';
import { JsonResponse } from 'TypeGen/json-response';

const connector = connect(
  (state: ApplicationState) => state.loadBoardBid,
  LoadBoardBidState.actionCreators
)

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  shipmentId: string;
}

type State = {}

type ActiveAeroBid = {
  ShipmentID: string;
  QuoteAmount: number;
  UnitType: string;
  ETAPickup: Date;
  ETADelivery: Date;
  Team: number;
  FASTCSA: boolean | null;
  Note: string;

  BorderCrossing: ActiveAeroBidBorderCrossing[];
}

type ActiveAeroBidBorderCrossing = {
  StopID: string;
  Name: string;
}

type XPOBid = {
  ShipmentID: string;
  ETAShipper: Date;
  SpotBid: number;
  AboveTariffReason: number | null;
}

type XPOAltBid = {
  ShipmentID: string;
  ETAShipper: Date;
  ETAConsignee: Date;
  SpotBid: number;
  AboveTariffReason: number | null;
  FAST: boolean;
  CSA: boolean;
  Note: string;
  MilesToPickup: number;
}

type ExpeditorsBid = {
  ShipmentID: string;
  ETAPickup: Date;
  ETADelivery: Date;
  VehicleSize: string;
  Note: string;
  LineItems: ExpeditorsBidLineItem[];
}

type ExpeditorsBidLineItem = {
  SourceEntityID: string;
  OverrideAmount: number;
}

type RyderLMSBid = {
  ShipmentID: string;
  SpotBid: number;
  Note: string;
}

type CoyoteBid = {
  ShipmentID: string;
  SpotBid: number;
}

type CaterpillarBid = {
  ShipmentID: string;
}

type ShawMABid = {
  ShipmentID: string;
  SpotBid: number;
  Note: string;
}

type DakkotaBid = {
  ShipmentID: string;
  SpotBid: number;
  Note: string;
}

type PepsiBid = {
  ShipmentID: string;
  SpotBid: number;
}

class LoadBoardBid extends React.Component<Props, State> {

  private popupAnchor: React.RefObject<any>;
  private popupAnchorTeam: React.RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.popupAnchor = React.createRef();
    this.popupAnchorTeam = React.createRef();

    this.fetch = this.fetch.bind(this);
    this.bid = this.bid.bind(this);
    this.getAction = this.getAction.bind(this);
    this.setSingle = this.setSingle.bind(this);
    this.setTeam = this.setTeam.bind(this);
    this.updateOverrideAmount = this.updateOverrideAmount.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    const state = this.props.data.get(this.props.shipmentId);

    if (!state || state.loading) {
      return <LoadingPanel />
    }
    return <div className="container-fluid" data-role="validator">
      <div className="row">
        <div className="col-lg-8">
            <p>{state.bid.Note}</p>
            <div className="row">
                <div className="col-md-6"><b>Hazardous</b>: {state.bid.HazMat ? <span className='badge badge-danger'>Yes</span> : "No"}</div>
                {state.bid.DockHigh != null && <div className="col-md-6"><b>Dock High</b>: {state.bid.DockHigh ? "Yes" : "No"}</div>}
            </div>
            {state.bid.HazMat && <React.Fragment>
                <br />
                <div className="row">
                    <div className="col-md-6"><b>Hazardous Class</b>: {state.bid.HazmatClass}</div>
                    <div className="col-md-6"><b>Hazardous UN</b>: {state.bid.HazmatUNNumber}</div>
                </div>
            </React.Fragment>}
            <br />
            <Grid data={state.bid.Stops}>
              {state.bid.Stops.some(x => x.LocationName != null && x.LocationName.length > 0) && <Column field="LocationName" title="Name" />}
              {state.bid.Stops.some(x => x.AddressLine1 != null && x.AddressLine1.length > 0) && <Column field="AddressLine1" title="Address Line 1" />}
              {state.bid.Stops.some(x => x.AddressLine2 != null && x.AddressLine2.length > 0) && <Column field="AddressLine2" title="Address Line 2" />}
              <Column field="CityStateZip" title="Location" />
              <Column field="ScheduledDateTime" title="Scheduled" filter="date" cell={DateCell} />
              <Column field="EarliestDateTime" title="Earliest" filter="date" cell={DateCell} />
              <Column field="LatestDateTime" title="Latest" filter="date" cell={DateCell} />
              {state.bid.Stops.some(x => x.OpenTime != null) && <Column field="OpenTime" title="Open Time" />}
              {state.bid.Stops.some(x => x.CloseTime != null) && <Column field="CloseTime" title="Close Time" />}
              <Column field="DistanceFromPreviousStop" title="Miles" filter="numeric" format="{0:n0}" />
            </Grid>
            <br />
            <p>{state.bid.CargoNote}</p>
            <Grid data={state.bid.Cargo}>
              <Column field="FromLocation" title="From" />
              <Column field="ToLocation" title="To" />
              <Column field="Pieces" format="{0:n0}" />
              <Column field="Weight" title="Weight (Lbs)" />
              <Column field="DIMs" title="LxWxH (in.)" />
              <Column field="Stackable" cell={YesNoCell} />
            </Grid>
          </div>
          <form className="col-lg-4" onSubmit={this.bid}>
            {state.bid.SourceType == SourceType.ActiveAero && this.bidActiveAero()}
            {state.bid.SourceType == SourceType.XPO && this.bidXPO()}
            {state.bid.SourceType == SourceType.Sylectus && this.bidSylectus()}
            {state.bid.SourceType == SourceType.Expeditors && this.bidExpeditors()}
            {state.bid.SourceType == SourceType.RyderLMS && this.bidRyderLMS()}
            {state.bid.SourceType == SourceType.Coyote && this.bidCoyote()}
            {state.bid.SourceType == SourceType.Caterpillar && this.bidCaterpillar()}
            {state.bid.SourceType == SourceType.ShawMA && this.bidShawMA()}
            {state.bid.SourceType == SourceType.Dakkota && this.bidDakkota()}
            {state.bid.SourceType == SourceType.Pepsi && this.bidPepsi()}
            <div className="col-md-8 offset-md-4">
              <button type="submit" className="btn btn-success">
                {(state.bid.SourceType == SourceType.Coyote && state.bid.SuggestedBid && state.bid.SuggestedBid == state.spotBid) ? 'Book It' : 'Place Bid'}
              </button>
            </div>
          </form>
        </div>
      </div>
  }

  private bidActiveAero(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);
    const vehicleSizes = ["Cargo Van","Sprinter Van","12 ft Truck","14 ft Truck","16 ft Truck","18 ft Truck","20 ft Truck","22 ft Truck","24 ft Truck","26 ft Truck","28 ft Truck","48 ft Truck","53 ft Truck","Conestoga","Curtain Side","Drop Deck","Flatbed","Goose Neck","Power Only","Stepdeck"];
    const teams = [{ ID: 0, Name: "Single"}, { ID: 1, Name: "Team" }, { ID: 2, Name: "Relay" }];
    return <React.Fragment>
      <h2>Quote Active Aero</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Unit Type:</label>
        <div className="col-md-8">
          <DropDownList
            required
            data={vehicleSizes}
            defaultItem=" - Please Select - "
            style={{ width: "100%" }}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { vehicleSize: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA to Shipper:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAShipper}
            onChange={(e) => this.updateBid({ ETAShipper: e.value })}
            width="100%"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA to Consignee:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAConsignee}
            onChange={(e) => this.updateBid({ ETAConsignee: e.value })}
            width="100%"
          />
        </div>
      </div>
      {this.transitData()}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Transit:</label>
        <div className="col-md-8">
          <DropDownList
            value={teams.find(x => x.ID == state.team)}
            data={teams}
            textField="Name"
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { team: e.target.value.ID })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Note:</label>
        <div className="col-md-8">
          <Input
            value={state.note}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {state.bid.BorderCrossings.length > 0 && <div className="form-group row">
        <label className="col-md-4 col-form-label" htmlFor="FASTCSA">FAST/CSA:</label>
        <div className="col-md-8">
            <Checkbox
              id="FASTCSA"
              value={state.fastcsa.toString()}
              handleCheckboxChange={() => this.props.updateBid(this.props.shipmentId, { fastcsa: !state.fastcsa })}
            />
        </div>
      </div>}
      {state.bid.BorderCrossings.map((borderCrossing, index) => {
        return <div className="form-group row" key={index}>
          <label className="col-md-4 col-form-label">Border Crossing:</label>
          <div className="col-md-8">
            <DropDownList
              required
              data={state.bid.ActiveAeroBorders}
              onChange={(e) => {
                state.borderCrossings[index] = {
                  StopID: borderCrossing.ShipmentStopID,
                  Name: e.target.value.ID
                };
                this.forceUpdate();
                // TODO
              }}
              textField="Name"
              style={{ width: "100%" }}
            />
          </div>
        </div>;
      })}
    </React.Fragment>
  }

  private transitData(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);
    return <React.Fragment>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Suggested to Consignee:</label>
        <div className="col-md-8 col-form-label">
          <a
            ref={this.popupAnchor}
            onClick={this.setSingle}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => this.props.updateBid(this.props.shipmentId, { show: true })}
            onMouseLeave={() => this.props.updateBid(this.props.shipmentId, { show: false })}
          >
            {Moment(state.bid.SuggestedETAConsignee).format("MM/DD/YYYY HH:mm")}
            <Popup
              anchor={this.popupAnchor.current}
              show={state.show}
              className={'wrapper'}
              popupClass={'inner-wrapper'}
            >
              <span className="px-2" dangerouslySetInnerHTML={{ __html: state.bid.TransitTime.HtmlFormatted }} />
            </Popup>
          </a>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Suggested to Consignee  (Team):</label>
        <div className="col-md-8 col-form-label">
          <a
            ref={this.popupAnchorTeam}
            onClick={this.setTeam}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => this.props.updateBid(this.props.shipmentId, { showTeam: true })}
            onMouseLeave={() => this.props.updateBid(this.props.shipmentId, { showTeam: false })}
          >
            {Moment(state.bid.SuggestedETAConsigneeTeam).format("MM/DD/YYYY HH:mm")}
            <Popup
              anchor={this.popupAnchorTeam.current}
              show={state.showTeam}
              className={'wrapper'}
              popupClass={'inner-wrapper'}
            >
              <span className="px-2" dangerouslySetInnerHTML={{ __html: state.bid.TransitTimeTeam.HtmlFormatted }} />
            </Popup>
          </a>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Dead Head Miles:</label>
          <div className="col-md-8">
            <NumericTextBox
              min={0}
              spinners={false}
              format="n0"
              value={state.deadheadMiles}
              onChange={(e) => {
                this.props.updateBid(this.props.shipmentId, { deadheadMiles: e.value || 0 });
                this.updateTransitDebounced();
              }}
              width="100%"
            />
          </div>
      </div>
      <div className="form-group row">
          <label className="col-md-4 col-form-label">Hours Of Service Remaining:</label>
          <div className="col-md-8">
            <TimePicker
              format="HH:mm"
              formatPlaceholder="formatPattern"
              nowButton={false}
              value={state.hoursofServiceRemaining}
              onChange={(e) => {
                this.props.updateBid(this.props.shipmentId, { hoursofServiceRemaining: e.value });
                this.updateTransitDebounced();
              }}
              width="100%"
            />
          </div>
      </div>
    </React.Fragment>
  }

  private updateBid(value: Partial<LoadBoardBidState.GetBidResponse>,) {
    const state = this.props.data.get(this.props.shipmentId);
    const bid = Object.assign({}, state.bid, value);
    this.props.updateBid(this.props.shipmentId, { bid });
    if (value.ETAShipper) {
      this.updateTransitDebounced();
    }
  }

  private setSingle() {
    const state = this.props.data.get(this.props.shipmentId);
    this.updateBid({ ETAConsignee: state.bid.SuggestedETAConsignee });
  }

  private setTeam() {
    const state = this.props.data.get(this.props.shipmentId);
    this.updateBid({ ETAConsignee: state.bid.SuggestedETAConsigneeTeam });
  }

  private bidXPO(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);
    const aboveTarrifReasons = [{ ID: 1, Name: "Drop Trailer"},
      { ID: 2, Name: "Lay Over"},
      { ID: 3, Name: "Deadhead"},
      { ID: 4, Name: "Tolls"},
      { ID: 5, Name: "Outside Core Area"},
      { ID: 6, Name: "Holiday"},
      { ID: 7, Name: "Specialized Equipment"},
      { ID: 8, Name: "Team Service"},
      { ID: 9, Name: "Vehicle Class"},
      { ID: 10, Name: "Border Crossing"},
      { ID: 11, Name: "Driver Incentive"},
      { ID: 12, Name: "Weekend Charge"},
      { ID: 13, Name: "Out of Route Miles"},
      { ID: 14, Name: "Inadequate Fuel Surcharge"},
      { ID: 15, Name: "Other"}];
    return <React.Fragment>
      <h2>Bid XPO/NLM</h2>
      <div className="form-group row">
        <label className="col-lg-4 col-form-label">Bid Type</label>
        <div className="col-lg-8 col-form-label">
          <div className="form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              checked={!state.bidAlt}
              onChange={() => this.props.updateBid(this.props.shipmentId, { bidAlt: false })}
            />
            <label className="form-check-label">Bid</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              checked={state.bidAlt}
              onChange={() => this.props.updateBid(this.props.shipmentId, { bidAlt: true })}
            />
            <label className="form-check-label">Alternative Bid</label>
          </div>
        </div>
      </div>
      {!state.bidAlt && state.bid.CSAShipment && <div className="form-group row">
          <div className="col-md-8 offset-md-4">
              <div className="alert alert-warning" role="alert"><strong>Warning!</strong> You must have a FAST Driver and Equipment to Bid.</div>
          </div>
      </div>}

      {!state.bidAlt && state.bid.FASTShipment && <div className="form-group row">
          <div className="col-md-8 offset-md-4">
              <div className="alert alert-warning" role="alert"><strong>Warning!</strong> You must have a CSA Driver and Equipment to Bid.</div>
          </div>
      </div>}
      {state.bidAlt && state.bid.FASTShipment && <div className="form-group row">
          <label className="col-md-4 col-form-label" htmlFor="FAST">FAST Driver and Equipment?:</label>
          <div className="col-md-8">
            <Checkbox
              id="FAST"
              value={state.fast.toString()}
              handleCheckboxChange={() => this.props.updateBid(this.props.shipmentId, { fast: !state.fast })}
            />
          </div>
      </div>}

      {state.bidAlt && state.bid.CSAShipment && <div className="form-group row">
          <label className="col-md-4 col-form-label" htmlFor="CSA">CSA Driver and Equipment?:</label>
          <div className="col-md-8">
            <Checkbox
              id="CSA"
              value={state.csa.toString()}
              handleCheckboxChange={() => this.props.updateBid(this.props.shipmentId, { csa: !state.csa })}
            />
          </div>
      </div>}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA Pickup:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAShipper}
            onChange={(e) => this.updateBid({ ETAShipper: e.value })}
            width="100%"
          />
        </div>
      </div>
      {state.bidAlt && <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA Delivery:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAConsignee}
            onChange={(e) => this.updateBid({ ETAConsignee: e.value })}
            width="100%"
          />
        </div>
      </div>}
      {this.transitData()}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Spot Bid:</label>
        <div className="col-md-8">
          <NumericTextBox
            required
            format="c2"
            min={0}
            spinners={false}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
            width="100%"
          />
          <p className="help-block">{formatNumber(state.bid.FuelSurcharge, "c")} Est. Fuel Surcharge<br />{formatNumber(state.bid.RatePerMile, "c")} / Mile</p>
        </div>
      </div>
      {state.bidAlt && <div className="form-group row">
        <label className="col-md-4 col-form-label">Note:</label>
        <div className="col-md-8">
          <Input
            value={state.note}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>}
      {state.spotBid > state.bid.SuggestedBid && <div className="form-group row">
        <label className="col-lg-4 col-form-label">Above Tariff Reason:</label>
        <div className="col-lg-8">
          <DropDownList
            required
            data={aboveTarrifReasons}
            style={{ width: "100%" }}
            textField="Name"
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { aboveTarrifReason: e.target.value.ID })}
          />
        </div>
      </div>}
      {state.bidAlt && <div className="form-group row">
        <label className="col-md-4 col-form-label">Miles to Pickup:</label>
          <div className="col-md-8">
            <NumericTextBox
              min={0}
              spinners={false}
              format="n0"
              value={state.milesToPickup}
              width="100%"
              onChange={(e) => this.props.updateBid(this.props.shipmentId, { milesToPickup: e.target.value || 0})}
            />
          </div>
      </div>}
    </React.Fragment>
  }

  private bidSylectus(): React.ReactNode {
    return <React.Fragment>
      <h2>Sylectus Links</h2>
    </React.Fragment>
  }

  private bidExpeditors(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);
    const vehicleSizes = [
        "CRGO - Van, Cargo",
        "STRT12 - Straight Truck - 12 ft.",
        "STRT18 - Straight Truck - 18 ft.",
        "STRT24 - Straight Truck - 24 ft.",
        "TTRL - 53 ft Tractor Trailer",
    ];

    return <React.Fragment>
      <h2>Bid ECCO</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA Pickup:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAShipper}
            onChange={(e) => this.updateBid({ ETAShipper: e.value })}
            width="100%"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">ETA Delivery:</label>
        <div className="col-md-8">
          <DateTimePicker
            required
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={state.bid.ETAConsignee}
            onChange={(e) => this.updateBid({ ETAConsignee: e.value })}
            width="100%"
          />
        </div>
      </div>
      {this.transitData()}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Unit Type:</label>
        <div className="col-md-8">
          <DropDownList
            required
            data={vehicleSizes}
            defaultValue={state.bid.Spare[0]}
            style={{ width: "100%" }}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { vehicleSize: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Note:</label>
        <div className="col-md-8">
          <Input
            value={state.note}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <table className="k-grid">
        <thead>
          <tr>
            <th className="k-header border-0">Description</th>
            <th className="k-header border-0">Amount</th>
            <th className="k-header border-0">Override</th>
          </tr>
        </thead>
        <tbody>
          {state.bid.LineItems.map((lineItem, index) => {
            return <tr key={index}>
              <td>{lineItem.Description}</td>
              <td>{formatNumber(lineItem.Amount, "c")}</td>
              <td>
                <NumericTextBox
                  format="c2"
                  value={state.lineItems[index].OverrideAmount}
                  onChange={(e) => this.updateOverrideAmount(e.value, index)}
                  placeholder={'No Override'}
                  spinners={false}
                  step={0}
                  min={0}
                />
              </td>
            </tr>
          })}
        </tbody>
      </table>
      <br />
    </React.Fragment>
  }

  private bidRyderLMS(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);

    return <React.Fragment>
      <h2>Bid Ryder</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Comment:</label>
        <div className="col-md-8">
          <Input
            value={state.note}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {state.bid.Spare.map((_, index) => {
        return <div className="form-group row">
        {index === 0 && <label className="col-md-4 col-form-label">Carrier Times: (Optional)</label>}
        <div className={`col-md-8 ${index > 0 ? 'offset-md-4' : ''}`}>
          <DatePicker
            format="MM/dd/yyyy"
            value={state.carrierTimes[index]}
            onChange={(e) => this.updateCarrierTime(e.value, index)}
            formatPlaceholder="formatPattern"
            width="100%"
          />
        </div>
      </div>
      })}
    </React.Fragment>
  }

  private bidCoyote(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);

    return <React.Fragment>
      <h2>Bid Coyote</h2>
      {state.bid.Spare.length > 0 && <div className="form-group row">
          <div className="col-md-8 offset-md-4">
              <div className="alert alert-warning" role="alert"><strong>Special Attributes!</strong> Please review the attributes below.</div>
              {state.bid.Spare.map((attribute, index) => {
                return <span key={index} className="badge badge-pill badge-danger mr-1">{attribute}</span>
              })}
          </div>
      </div>}
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private bidCaterpillar(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);

    return <React.Fragment>
      <h2>Bid Caterpillar</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            disabled
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private bidShawMA(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);

    return <React.Fragment>
      <h2>Bid Shaw</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Note:</label>
        <div className="col-md-8">
          <Input
            value={state.note}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private bidDakkota(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);    
    return <React.Fragment>
      <h2>Bid Dakkota</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Quote Amount:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Note:</label>
        <div className="col-md-8">
          <Input
            type="text"
            value={state.note || ''}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { note: e.target.value.toString() })}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private bidPepsi(): React.ReactNode {
    const state = this.props.data.get(this.props.shipmentId);

    return <React.Fragment>
      <h2>Accept Pepsi</h2>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Rate:</label>
        <div className="col-md-8">
          <NumericTextBox
            format="c2"
            placeholder="$0.00"
            width="100%"
            spinners={false}
            step={0}
            min={0}
            value={state.spotBid}
            onChange={(e) => this.props.updateBid(this.props.shipmentId, { spotBid: e.value || 0 })}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private updateTransitDebounced = debounce(() => this.props.updateTransit(this.props.shipmentId), 250);

  private fetch() {
    this.props.requestBid(this.props.shipmentId);
  }

  private bid(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.updateBid(this.props.shipmentId, { loading: true });
    fetchApi(`/api/LoadBoard/${this.getAction()}`, this.getData(), 'POST')
      .then((response: JsonResponse) => {
        this.props.updateBid(this.props.shipmentId, { loading: false });
        if (response.Success === false) {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        this.props.updateBid(this.props.shipmentId, { loading: false });
        alert("Unhandled error occurred!");
      });
  }

  private getAction()
  {
    const state = this.props.data.get(this.props.shipmentId);
    switch(state.bid.SourceType)
    {
      case SourceType.ActiveAero:
        return "BidActiveAero";
      case SourceType.XPO:
        return state.bidAlt ? "BidAlternativeXPO" : "BidYesXPO";
      case SourceType.Expeditors:
        return "BidExpeditors";
      case SourceType.RyderLMS:
        return "BidRyderLMS";
      case SourceType.Coyote:
        return "BidCoyote";
      case SourceType.Caterpillar:
        return "BidCaterpillar";
      case SourceType.ShawMA:
        return "BidShawMA";
      case SourceType.Dakkota:
        return "BidDakkota";
      case SourceType.Pepsi:
        return "BidPepsi";
      default:
        throw new Error(`Invalid SourceType: ${state.bid.SourceType}`);
    }
  }

  private getData(): ActiveAeroBid | XPOBid | XPOAltBid | ExpeditorsBid | RyderLMSBid | CoyoteBid | CaterpillarBid | ShawMABid | DakkotaBid | PepsiBid
  {
    const state = this.props.data.get(this.props.shipmentId);
    switch(state.bid.SourceType)
    {
      case SourceType.ActiveAero:
        return {
          ShipmentID: state.bid.ShipmentID,
          QuoteAmount: state.spotBid,
          UnitType: state.vehicleSize,
          ETAPickup: state.bid.ETAShipper,
          ETADelivery: state.bid.ETAConsignee,
          Team: state.team,
          FASTCSA: state.fastcsa,
          Note: state.note,
          BorderCrossing: state.borderCrossings
        } as ActiveAeroBid;
      case SourceType.XPO:
        if (!state.bidAlt) {
          return {
            ShipmentID: state.bid.ShipmentID,
            ETAShipper: state.bid.ETAShipper,
            SpotBid: state.spotBid,
            AboveTariffReason: state.spotBid > state.bid.SuggestedBid ? state.aboveTarrifReason : null,
          } as XPOBid;
        } else {
          return {
            ShipmentID: state.bid.ShipmentID,
            ETAShipper: state.bid.ETAShipper,
            ETAConsignee: state.bid.ETAConsignee,
            SpotBid: state.spotBid,
            AboveTariffReason: state.spotBid > state.bid.SuggestedBid ? state.aboveTarrifReason : null,
            FAST: state.fast,
            CSA: state.csa,
            Note: state.note,
            MilesToPickup: state.milesToPickup
          } as XPOAltBid;
        }
      case SourceType.Expeditors:
        return {
          ShipmentID: state.bid.ShipmentID,
          ETAPickup: state.bid.ETAShipper,
          ETADelivery: state.bid.ETAConsignee,
          VehicleSize: state.vehicleSize || state.bid.Spare[0],
          Note: state.note,
          LineItems: state.lineItems
        } as ExpeditorsBid;
      case SourceType.RyderLMS:
        return {
          ShipmentID: state.bid.ShipmentID,
          SpotBid: state.spotBid,
          Note: state.note,
          CarrierTimes: state.carrierTimes.filter(x => x),
        } as RyderLMSBid
      case SourceType.Coyote:
        return {
          ShipmentID: state.bid.ShipmentID,
          SpotBid: state.spotBid,
        } as CoyoteBid
      case SourceType.Caterpillar:
        return {
          ShipmentID: state.bid.ShipmentID,
        } as CaterpillarBid
      case SourceType.ShawMA:
        return {
          ShipmentID: state.bid.ShipmentID,
          SpotBid: state.spotBid,
          Note: state.note
        } as ShawMABid
      case SourceType.Dakkota:
        return {
          ShipmentID: state.bid.ShipmentID,
          SpotBid: state.spotBid,
          Note: state.note
        } as DakkotaBid
      case SourceType.Pepsi:
        return {
          ShipmentID: state.bid.ShipmentID,
          SpotBid: state.spotBid,
        } as PepsiBid
      default:
        throw new Error(`Invalid SourceType: ${state.bid.SourceType}`);
    }
  }

  private updateOverrideAmount(value: number, index: number) {
    const state = this.props.data.get(this.props.shipmentId);
    state.lineItems[index].OverrideAmount = value;
    this.forceUpdate();
  }

  private updateCarrierTime(value: Date, index: number) {
    const state = this.props.data.get(this.props.shipmentId);
    state.carrierTimes[index] = value;
    this.forceUpdate();
  }
}

export default connector(LoadBoardBid);
