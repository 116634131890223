import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import { ChatResponseAnalyzedPage } from '../../TypeGen/Order/Images/chat-response-analyzed-page';
import { ChatResponseAnalyzedPageDocType } from '../../TypeGen/Order/Images/chat-response-analyzed-page-doc-type';

//enum DocType {
//  BOL,
//  CustomsInvoice,
//  CarrierAgreementForm,
//  CarrierInvoice,
//  Email
//}

//interface ChatResponseAnalyzedPage {
//  PageNumber: number;
//  DocType: DocType;
//  Confidence: number;
//}

interface ViewModel {
  TrainedVersion: string;
  Files: string[];
  ChatResponseAnalyzedPages: ChatResponseAnalyzedPage[];
}

const DisplayClassifiedLabel = (docType: ChatResponseAnalyzedPageDocType, Confidence: number) => {
  switch (docType) {
    case ChatResponseAnalyzedPageDocType.BillOfLading:
    case ChatResponseAnalyzedPageDocType.CustomsOrBorder:
    case ChatResponseAnalyzedPageDocType.PackList:
    case ChatResponseAnalyzedPageDocType.CartaDePorte:
    case ChatResponseAnalyzedPageDocType.CommercialInvoice:
    case ChatResponseAnalyzedPageDocType.DeliveryOrder:
    case ChatResponseAnalyzedPageDocType.PurchaseOrder:
      //if (Confidence >= 0.70)
      return (<span className="badge badge-success">ACCEPTED</span>);
    //return (<span className="badge badge-warning">REJECTED (LOW CONFIDENCE)</span>);    
    //return (<span className="badge badge-info">ACCEPTED/OTHER</span>);
    default:
      return (<span className="badge badge-warning">REJECTED</span>);
  }
}

const DownloadClassifiedEpayDocs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [epayTransactionId, setEpayTransactionId] = useState<number>(0);
  const [tripNumber, setTripNumber] = useState<number>(0);
  const [transactionStatusNote, setTransactionStatusNote] = useState('');

  const [viewModel, setViewModel] = useState<ViewModel>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const _classifyAndDownloadDocs = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId ?? 0,
      TripNumber: tripNumber ?? 0
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
        setViewModel(resp);
        //if (resp.blob.size > 0)
        //  downloadBlobAsFile(resp.blob, resp.filename);
        //else {
        //  alert('No file returned.');
        //}
      })
      .catch(e => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };

  const _approve = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId,
      EpayTransactionStatus: 124,
      TransactionStatusNote: '',
      TripNumber: tripNumber
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
      }
      ).catch((e) => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };

  const _reject = () => {
    setLoading(true);

    const data = {
      EpayTransactionId: epayTransactionId,
      EpayTransactionStatus: 122,
      TransactionStatusNote: transactionStatusNote,
      TripNumber: tripNumber
    };

    fetchApi(`/api/Settlements/DownloadClassifiedEpayDocs`, data, 'POST')
      .then((resp) => {
        setLoading(false);
      }
      ).catch((e) => {
        setLoading(false);
        if (!e?.status) alert('An error occurred.');
      });
  };

  return <div className="container-fluid">
    {loading && <div className="container"><CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel></div>}
    <h2 className="mt-4">Epay AI Document Classifier {viewModel?.TrainedVersion}</h2>
    <div className="row">
      <div className="col-4">
        <FieldWrapper className="mt-2">
          <NumericTextBox
            placeholder="Epay Transaction ID"
            min={0}
            format="0"
            disabled={loading}
            value={epayTransactionId}
            label="Epay Transaction ID"
            onChange={(e) => setEpayTransactionId(e.value)}
          />
        </FieldWrapper>
        <FieldWrapper className="mt-2">
          <NumericTextBox
            placeholder="Trip Number"
            min={0}
            format="0"
            disabled={loading}
            value={tripNumber}
            label="Trip Number"
            onChange={(e) => setTripNumber(e.value)}
          />
        </FieldWrapper>
        {/*<FieldWrapper>*/}
        {/*  <Label>Note</Label>*/}
        {/*  <TextArea*/}
        {/*    maxLength={300}*/}
        {/*    rows={4}*/}
        {/*    placeholder="If applicable, enter a rejection note..."*/}
        {/*    value={transactionStatusNote}*/}
        {/*    onChange={(e: any) => setTransactionStatusNote(e.value)}*/}
        {/*  />*/}
        {/*</FieldWrapper>*/}
        <div className="k-form-buttons mt-4" style={{ display: 'block' }}>
          <Button themeColor="primary" disabled={loading || (Number(epayTransactionId) <= 0 && Number(tripNumber) <= 0)} onClick={() => _classifyAndDownloadDocs()}>
            Analyze Docs
          </Button>
          {/*<Button themeColor="success" disabled={loading || epayTransactionId === 0} className="ml-4" onClick={() => _approve()}>*/}
          {/*  Mark as Approved*/}
          {/*</Button>*/}
          {/*<Button themeColor="error" disabled={loading || epayTransactionId === 0 || isNullOrWhiteSpace(transactionStatusNote)} className="ml-2" onClick={() => _reject()}>*/}
          {/*  Mark as Rejected*/}
          {/*</Button>*/}
        </div>
      </div>
      <div className="col-8">
        <div style={{ height: '80vh', overflowY: 'scroll', overflowX: 'hidden' }}>
          {viewModel?.Files?.length > 0 && viewModel?.ChatResponseAnalyzedPages?.length === 0 && <div className="alert alert-warning" style={{ width: '475px' }}>
            No classified docs have been returned. This could be indicative of a time-out.
          </div>}

          {viewModel?.Files?.length > 0 && viewModel?.ChatResponseAnalyzedPages?.length > 0 && viewModel?.Files?.map((base64String, index) => (
            <div key={index} className="row">
              <div className="col-auto">
                <img src={`data:image/jpeg;base64,${base64String}`} alt={`Document Page ${index + 1}`} width={600} style={{ marginTop: 6 }} />
              </div>
              <div className="col-auto p-0">
                <div>
                  <label className="mr-1">Page #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].PageNumber}</span>
                </div>
                <div>
                  <label className="mr-1">Type:</label>
                  <span>{ChatResponseAnalyzedPageDocType[viewModel?.ChatResponseAnalyzedPages[index].InferredDocumentType]}</span>
                </div>
                {viewModel?.ChatResponseAnalyzedPages[index].Pro && <div>
                  <label className="mr-1">Pro #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].Pro}</span>
                </div>}
                {viewModel?.ChatResponseAnalyzedPages[index].OrderNumber && <div>
                  <label className="mr-1">Order #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].OrderNumber}</span>
                </div>}
                {viewModel?.ChatResponseAnalyzedPages[index].Bol && <div>
                  <label className="mr-1">Bol #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].Bol}</span>
                </div>}
                {viewModel?.ChatResponseAnalyzedPages[index].ReferenceNumber && <div>
                  <label className="mr-1">Ref #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].ReferenceNumber}</span>
                </div>}
                {viewModel?.ChatResponseAnalyzedPages[index].Shipper && <>
                  <div>
                    <label className="mr-1">Shipper:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].Shipper}</span>
                  </div>
                  {viewModel?.ChatResponseAnalyzedPages[index].ShipperAddress && <div>
                    <label className="mr-1">Shipper Address:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].ShipperAddress}</span>
                  </div>}
                </>}
                {viewModel?.ChatResponseAnalyzedPages[index].Consignee && <>
                  <div>
                    <label className="mr-1">Consignee:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].Consignee}</span>
                  </div>
                  {viewModel?.ChatResponseAnalyzedPages[index].ConsigneeAddress && <div>
                    <label className="mr-1">Consignee Address:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].ConsigneeAddress}</span>
                  </div>}
                </>}

                {viewModel?.ChatResponseAnalyzedPages[index].Origin && <>
                  <div>
                    <label className="mr-1">Origin:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].Origin}</span>
                  </div>
                  {viewModel?.ChatResponseAnalyzedPages[index].OriginAddress && <div>
                    <label className="mr-1">Origin Address:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].OriginAddress}</span>
                  </div>}
                </>}
                {viewModel?.ChatResponseAnalyzedPages[index].Destination && <>
                  <div>
                    <label className="mr-1">Destination:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].Destination}</span>
                  </div>
                  {viewModel?.ChatResponseAnalyzedPages[index].DestinationAddress && <div>
                    <label className="mr-1">Destination Address:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].DestinationAddress}</span>
                  </div>}
                </>}

                {viewModel?.ChatResponseAnalyzedPages[index].BillTo && <>
                  <div>
                    <label className="mr-1">BillTo:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].BillTo}</span>
                  </div>
                  {viewModel?.ChatResponseAnalyzedPages[index].BillToAddress && <div>
                    <label className="mr-1">Bill-To Address:</label>
                    <span>{viewModel?.ChatResponseAnalyzedPages[index].BillToAddress}</span>
                  </div>}
                </>}
                {viewModel?.ChatResponseAnalyzedPages[index].InvoiceNumber && <div>
                  <label className="mr-1">Invoice #</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].InvoiceNumber}</span>
                </div>}
                {viewModel?.ChatResponseAnalyzedPages[index].InvoiceTotal > 0 && <div>
                  <label className="mr-1">Invoice Total:</label>
                  <span>{formatNumber(viewModel?.ChatResponseAnalyzedPages[index].InvoiceTotal, "c")}</span>
                </div>}
                <div>
                  <label className="mr-1">Has Handwriting/Signature:</label>
                  <span>{viewModel?.ChatResponseAnalyzedPages[index].HasHandWrittenText ? 'True' : 'False'}</span>
                </div>

                {/*<div>*/}
                {/*  <label className="mr-1">Confidence:</label>*/}
                {/*  <span>{Math.round(viewModel?.ChatResponseAnalyzedPages[index].Confidence * 100)}%</span>*/}
                {/*</div>*/}
                <div>
                  {DisplayClassifiedLabel(viewModel?.ChatResponseAnalyzedPages[index].InferredDocumentType, 0)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
}

export default DownloadClassifiedEpayDocs;
