import * as React from 'react';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { IDName } from '../../types/idname';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import EditNotifications from './Notifications/EditNotifications';
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon } from '@progress/kendo-svg-icons';

type Props = {
    CustomerID: number;
    ContactID?: number;
    CloseDialog: () => void;
}

type State = {
    OrderNotifications: OrderNotification[];
    editItem?: OrderNotification;
    Contacts: IDName[];
    Commands: ILink[];

    data: OrderNotification[];
    dataContacts: IDName[];
    openForm: boolean;
}

type OrderNotificationsResponse = {
    Contacts: IDName[];
    OrderNotifications: OrderNotification[];
    Commands: ILink[];
}

export type OrderNotification = {
    CustomerID: number;
    CustomerContactID: number;
    CustomerContactName: string;

    // Notification Rules
    SendPositionNotificationMinuteInterval: number;
    Consolidated: boolean;
    SendArrivedShipperNotification: boolean;
    SendLoadedNotification: boolean;
    SendArrivedConsigneeNotification: boolean;
    SendEmptiedNotification: boolean;
    SendArrivedBorderNotification: boolean;
    SendDepartedBorderNotification: boolean;

    // Notification Conditions
    SendPickupWaitTimeMinutesNotification: number;
    SendDeliveryWaitTimeMinutesNotification: number;
    OnlyIfShipper: boolean;
    OnlyIfConsignee: boolean;
    OnlyIfBillTo: boolean;
    OnlyIfAuthCompany: boolean;
    OnlyIfLogistics: boolean;
    OnlyIfAuthContact: boolean;

    // For Editing
    contacts: IDName[];
}

export class SetupNotifications extends React.Component<Props, State> {

    CommandCell: typeof React.Component;
    ContactDropDownCell: typeof React.Component;
    PositionDropDownCell: typeof React.Component;
    CheckboxCell: typeof React.Component;

    constructor(props: Props) {
        super(props);

        this.state = {
            Contacts: [],
            Commands: [],
            OrderNotifications: [],
            editItem: undefined,
            data: [],
            dataContacts: [],
            openForm: false
        };

        this.itemChange = this.itemChange.bind(this);

        function MyCommandCell(
            { edit, remove }: {
                edit: (dataItem: OrderNotification) => void,
                remove: (dataItem: OrderNotification) => void,
            }) {
            return class extends React.Component<GridCellProps> {
                render() {
                    const { dataItem } = this.props;
                    return <td className="k-command-cell">
                        <Button
                            themeColor="primary"
                            className="k-grid-edit-command"
                            onClick={() => edit(dataItem)}
                        >
                            Edit
                        </Button>
                        <Button
                            className="k-grid-remove-command"
                            onClick={() => window.confirm('Confirm deleting: ' + this.props.dataItem.CustomerContactName) &&
                                remove(dataItem)
                            }
                        >
                            Remove
                        </Button>
                    </td>
                }
            }
        };

        this.CommandCell = MyCommandCell({
            edit: this.enterEdit,
            remove: this.remove,
        });

        class ContactDropDownCell extends React.Component<GridCellProps> {

            public render() {
                if (!this.props.field) return null;

                const dataItem = this.props.dataItem as OrderNotification;

                if (dataItem.CustomerID && dataItem.contacts) {
                    return (
                        <td>
                            {dataItem.contacts.find(x => x.ID == dataItem.CustomerContactID)?.Name}
                        </td>
                    );
                }

                return (
                    <td>
                        {dataItem.CustomerContactName}
                    </td>
                );
            }
        }
        this.ContactDropDownCell = ContactDropDownCell;

        class PositionDropDownCell extends React.Component<GridCellProps> {

            public render() {
                if (!this.props.field) return null;
                const value = this.props.dataItem[this.props.field];
                return (
                    <td>
                        {(value === null) ? '' : this.intervalLabel(this.props.dataItem[this.props.field])}
                    </td>
                );
            }

            private intervalLabel(minuteInterval: number) {
                switch (minuteInterval) {

                    case 0:
                        return 'None';
                    case 60:
                        return '1 Hour'
                    case 120:
                        return '2 Hours';
                    case 240:
                        return '4 Hours';
                    default:
                        return minuteInterval + ' Mins'
                }
            }
        }
        this.PositionDropDownCell = PositionDropDownCell;

        class CheckboxCell extends React.Component<GridCellProps> {

            public render() {
                if (!this.props.field) return null;
                const value = this.props.dataItem[this.props.field];
                return (
                    <td className="text-center">
                        {(value === null || !this.props.dataItem[this.props.field]) ? '' : <SvgIcon icon={checkIcon} />}
                    </td>
                );
            }
        }
        this.CheckboxCell = CheckboxCell;
    }

    public componentWillMount() {
        this.fetch();
    }

    public render() {
        return (
            <Dialog title="Setup Notifications" onClose={this.props.CloseDialog} className='dialog-w1140'>
                <Grid
                    data={this.state.data}
                    onItemChange={this.itemChange}
                >
                    <GridToolbar>
                        <Button
                            themeColor="primary"
                            title="Add new"
                            onClick={this.addNew}
                            disabled={this.props.ContactID > 0 && this.state.data.some(x => x.CustomerContactID === this.props.ContactID)}
                        >
                            Add new
                        </Button>
                    </GridToolbar>
                    <Column field="CustomerContactID" title="Contact" cell={this.ContactDropDownCell} />
                    <Column title="Only If" cell={(props) => {
                        const rulesArray = Array.from([(props.dataItem.OnlyIfAuthCompany ? 'Auth Company' : ''), (props.dataItem.OnlyIfAuthContact ? 'Auth Contact' : ''), (props.dataItem.OnlyIfBillTo ? 'Bill To' : ''), (props.dataItem.OnlyIfConsignee ? 'Consignee' : ''), (props.dataItem.OnlyIfShipper ? 'Shipper' : ''), (props.dataItem.OnlyIfLogistics ? 'Logistics' : '')]).filter(Boolean);
                        if (rulesArray.length === 0) rulesArray.push('None');
                        return <td>{rulesArray.join(', ')}</td>;
                    }} />
                    <Column title="Milestones" cell={(props) => {
                        const notificationsArray = Array.from([(props.dataItem.SendArrivedShipperNotification ? 'Shipper' : ''), (props.dataItem.SendLoadedNotification ? 'Loaded' : ''), (props.dataItem.SendArrivedConsigneeNotification ? 'Consignee' : ''), (props.dataItem.SendEmptiedNotification ? 'Emptied' : ''), (props.dataItem.SendArrivedBorderNotification ? 'Arrived Border' : ''), (props.dataItem.SendDepartedBorderNotification ? 'Departed Border' : '')]).filter(Boolean);
                        if (notificationsArray.length === 0) notificationsArray.push('None');
                        return <td>{notificationsArray.join(', ')}</td>;
                    }} />
                    <Column field="SendPositionNotificationMinuteInterval" title="Check Calls" cell={this.PositionDropDownCell} />
                    <Column field="SendPickupWaitTimeMinutesNotification" title="Waiting Time" cell={(props) => <td>{props.dataItem.SendPickupWaitTimeMinutesNotification || 'N/A'} mins pu<br />{props.dataItem.SendDeliveryWaitTimeMinutesNotification || 'N/A'} mins dr</td>} />
                    <Column field="Consolidated" cell={this.CheckboxCell} />
                    <Column cell={this.CommandCell} width="160px" />
                </Grid>
                {this.state.openForm && <EditNotifications cancelEdit={this.discard} onSubmit={this.update} item={this.state.editItem} />}
            </Dialog>
        );
    }

    private enterEdit = (dataItem: OrderNotification) => {
        const editItem = this.state.data.find(item => item.CustomerContactID === dataItem.CustomerContactID);
        this.setState({
            openForm: true,
            editItem: { ...editItem, contacts: this.state.Contacts}
        });
    }

    private fetch = () => {
        fetchApi(`/api/Customer/OrderNotifications/${this.props.CustomerID}`)
            .then((response: OrderNotificationsResponse) => {
                const orderNotificationsData =
                    this.props.ContactID ? response.OrderNotifications.filter(x => x.CustomerContactID === this.props.ContactID) : response.OrderNotifications;

                const contacts = this.props.ContactID ? response.Contacts.filter(x => x.ID === this.props.ContactID) : response.Contacts;

                const data = orderNotificationsData.slice(0);
                const dataContacts = contacts.slice(0);
                this.setState({
                    Contacts: contacts,
                    Commands: response.Commands,
                    OrderNotifications: orderNotificationsData,
                    data,
                    dataContacts
                });
            });
    }

    private remove = (dataItem: OrderNotification) => {
        const data = [...this.state.data];
        const OrderNotifications = [...this.state.OrderNotifications];
        this.removeItem(data, dataItem);
        this.removeItem(OrderNotifications, dataItem);

        this.setState({ data, OrderNotifications });

        // Delete on server
        if (dataItem.CustomerContactID) {
            const command = this.state.Commands.find(x => x.Name === 'Delete') as ILink;
            fetchApi(command.Link, { CustomerContactID: dataItem.CustomerContactID }, command.Method);
        }
    }

    private discard = () => {
        this.setState({ openForm: false });
    }

    private update = (dataItem: OrderNotification) => {
        // Update on Server
        const command = this.state.Commands.find(x => x.Name === 'Update') as ILink;
        const { contacts, ...updateData } = dataItem;
        fetchApi(command.Link, updateData, command.Method)
            .then((response: JsonResponse) => {
                if (response.Success) {
                    const data = [...this.state.data];
                    const OrderNotifications = [...this.state.OrderNotifications];

                    const updatedItem = { ...dataItem, CustomerID: this.props.CustomerID } as OrderNotification;

                    this.updateItem(data, updatedItem);
                    this.updateItem(OrderNotifications, updatedItem);

                    this.setState({ openForm: false, data });
                    this.fetch();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('Unable to update!');
            });
    }

    private cancel = (dataItem: OrderNotification) => {
        const originalItem = this.state.OrderNotifications.find(p => p.CustomerContactID === dataItem.CustomerContactID);
        if (originalItem) {
            const data = this.state.data.map(item => item.CustomerContactID === originalItem.CustomerContactID ? originalItem : item);

            this.setState({ data });
        } else {
            this.discard();
        }
    }

    private updateItem = (data: OrderNotification[], item: OrderNotification) => {
        let index = data.findIndex(p => p === item || (item.CustomerContactID && p.CustomerContactID === item.CustomerContactID));
        if (index >= 0) {
            data[index] = { ...item };
        }
    }

    private itemChange = (event: GridItemChangeEvent) => {
        const data = this.state.data.map(item =>
            item.CustomerContactID === event.dataItem.CustomerContactID ?
                { ...item, [event.field]: event.value } : item
        );

        this.setState({ data });
    }

    private addNew = () => {
        const contactsWithNoSavedNotifications = this.state.dataContacts.filter(x => !this.state.OrderNotifications.map(y => y.CustomerContactID).includes(x.ID));
        const newDataItem = {
            CustomerID: 0,
            CustomerContactID: this.props.ContactID ?? 0,
            CustomerContactName: '',
            SendPositionNotificationMinuteInterval: 0,
            Consolidated: false,
            SendArrivedShipperNotification: true,
            SendArrivedConsigneeNotification: true,
            SendLoadedNotification: true,
            SendEmptiedNotification: true,
            SendArrivedBorderNotification: false,
            SendDepartedBorderNotification: false,
            SendPickupWaitTimeMinutesNotification: 60,
            SendDeliveryWaitTimeMinutesNotification: 60,
            OnlyIfShipper: false,
            OnlyIfConsignee: false,
            OnlyIfBillTo: false,
            OnlyIfAuthCompany: true,
            OnlyIfLogistics: false,
            OnlyIfAuthContact: false,
            contacts: contactsWithNoSavedNotifications
        } as OrderNotification;
        this.setState({
            openForm: true,
            editItem: newDataItem
        });
    }

    private removeItem(data: OrderNotification[], item: OrderNotification) {
        let index = data.findIndex(p => p === item || item.CustomerContactID && p.CustomerContactID === item.CustomerContactID);
        if (index >= 0) {
            data.splice(index, 1);
        }
    }
}
