import { Push } from "@progress/kendo-react-animation";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Loader } from '@progress/kendo-react-indicators';
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { useEffect, useRef, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import { downloadBlobAsFile, fetchByteData } from "../../services/api";
import { Title } from "utils/title";

const DownloadInvoiceBatch = () => {
  const numericTextBoxRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [businessUnitId, setBusinessUnitId] = useState(2610);
  const [messageContainer, setMessageContainer] = useState<{ message: string, style: string }>({ message: '', style: 'none' });
  const [invoicePostBatchNumber, setInvoicePostBatchNumber] = useState<number>();
  const [includePW, setFilterOnlyOpenPPCheckbox] = useState<boolean>(true);
  const { alert } = useAlert();

  useEffect(() => {
    if (numericTextBoxRef.current) {
      numericTextBoxRef.current.focus();
    }
  }, []);

  const downloadPDF = async () => {
    setLoading(true);
    setMessageContainer({ message: "The requested file is being generated.  Please wait...", style: 'info' });
    fetchByteData(`/api/Billing/DownloadInvoiceBatch/${businessUnitId}/${invoicePostBatchNumber}/${includePW}`)
      .then((resp) => {
        setLoading(false);
        downloadBlobAsFile(resp.blob, resp.filename);
        setMessageContainer({ message: "Your file is now ready!", style: 'success' });
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        setMessageContainer({ message: '', style: '' });
        if (!e?.status) await alert('An error occurred.');
      });
  };

  return (
    <>
      <Title string="Download Invoice Batch" />
      <h4 className="text-center mt-3">Download Invoice Batch</h4>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Push style={{ zIndex: 0, width: '100%' }}>
        {messageContainer.message.length > 0 && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
          <Notification style={{ padding: "8px", width: '100%' }} type={{ style: messageContainer.style as any, icon: true }}>
            <div>{messageContainer.message}</div>
          </Notification>
        </NotificationGroup>}
      </Push>
      <div className="" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px'
      }}>
        <div>Invoice Post Batch:</div>
        <div><NumericTextBox
          ref={numericTextBoxRef}
          disabled={loading}
          value={invoicePostBatchNumber}
          onChange={(e) => setInvoicePostBatchNumber(e.value)}
          format="#"
          min={0}
        /></div>
        <Checkbox
          className='align-self-center'
          disabled={loading}
          value={includePW}
          onChange={(e) => { setFilterOnlyOpenPPCheckbox(e.value); }}
          label={"Include paperwork?"}
        />
        <div>
          <ButtonGroup>
            <Button onClick={() => setBusinessUnitId(2610)} selected={businessUnitId === 2610} togglable={true}>Load One</Button>
            <Button onClick={() => setBusinessUnitId(2612)} selected={businessUnitId === 2612} togglable={true} themeColor={businessUnitId === 2612 ? 'warning' : 'base'}>ARC</Button>
          </ButtonGroup>
        </div>
        <div><Button disabled={loading || invoicePostBatchNumber == null || invoicePostBatchNumber === 0} themeColor="primary" onClick={() => downloadPDF()}>Download {businessUnitId === 2610 ? 'Load One' : 'ARC'} Invoice Batch</Button></div>
      </div>
    </>
  );
};

export default DownloadInvoiceBatch;
