
import { useHistory } from 'react-router-dom';
import { Title } from '../../utils/title';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnProps, GridDataStateChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { useCallback, useEffect, useState } from 'react';
import Moment from 'moment-timezone';
import { DataSourceRequestState, SortDescriptor, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import ColumnMenu from '../LoadBoard/BidHistory/ColumnMenu';
import YesNoCell from '../../components/cells/YesNoCell';
import { Link } from 'react-router-dom';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import DriverAccidentReportDialog from './Driver/DriverAccidentReportDialog';
import { arrowRotateCwIcon, filterClearIcon, plusCircleIcon } from '@progress/kendo-svg-icons';
import DateCell from 'components/cells/DateCell';

type DriverAccidentHistoryViewItem = {
    DriverAccidentID: number;
    ReportNumber: string;
    DriverID: number;
    DriverNumber: string;
    VehicleID: number | null;
    VehicleNumber: string;
    AccidentType: string;
    ClaimsTotal: number;
    CitationGiven: boolean | null;
    DOTRecordable: boolean | null;
    DateTimeOfAccident: Date;
    ReportingByName: string;
}

const defaultSort = [{
    field: "DriverAccidentID",
    dir: "desc"
}] as SortDescriptor[];

const DriverAccidents = () => {

    const [driverAccidents, setDriverAccidents] = useState<DriverAccidentHistoryViewItem[]>([]);
    const [total, setTotal] = useState(0);
    const [mine, setMine] = useState<boolean>();
    const [loading, setLoading] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [showAccidentDialog, setShowAccidentDialog] = useState(false);
    const [dataState, setDataState] = useState<DataSourceRequestState>({
        skip: 0,
        take: 50,
        sort: defaultSort,
        filter: { logic: 'and', filters: [] }
    });
    const history = useHistory();

    const refreshList = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;

        fetchApi(`/api/Asset/DriverAccidentHistory_Read?${queryStr}`, { Mine: mine, Cancelled: cancelled }, 'POST')
            .then(({ Data, Total }) => {
                const data = hasGroups ? translateDataSourceResultGroups(Data) : Data;
                setDriverAccidents(data);
                setTotal(Total);
            })
            .finally(() => setLoading(false));
    }, [mine, cancelled, dataState])

    useEffect(() => {
        refreshList();
    }, [refreshList, mine, dataState]);

    const onDataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    }

    const columnProps = (field: string): Partial<GridColumnProps> => {
        return {
            field: field,
            columnMenu: ColumnMenu,
            headerClassName: isColumnActive(field, dataState) ? 'active' : ''
        };
    }

    const isColumnActive = (field: string, dataState: any) => {
        return GridColumnMenuFilter.active(field, dataState.filter);
    }

    return <>
        <Title string='Driver Accidents' />
        <br />
        {showAccidentDialog &&
          <DriverAccidentReportDialog
            DriverID={0}
            CloseDialog={() => setShowAccidentDialog(false)}
          />
        }
        {loading && <GridLoadingPanel />}
        <Grid
            scrollable={'none'}
            sortable={{ allowUnsort: true }}
            pageable
            onDataStateChange={onDataStateChange}
            onRowDoubleClick={(event) => {
                history.push(`/Assets/DriverAccident/${event.dataItem.DriverAccidentID}`);
            }}
            data={driverAccidents}
            total={total}
            dataItemKey='DriverAccidentID'
            {...dataState}
        >
            <GridToolbar>
                <Button
                    icon="filter-clear"
                    svgIcon={filterClearIcon}
                    title="Clear Filters and Sort"
                    onClick={() => setDataState({
                        skip: 0,
                        take: 50,
                        sort: defaultSort,
                        filter: { logic: 'and', filters: [] }
                    })}
                />
                <Button
                    icon="refresh"
                    svgIcon={arrowRotateCwIcon}
                    title="Refresh"
                    onClick={refreshList}
                />
                <Button togglable
                    selected={mine == true}
                    onClick={() => setMine(mine ? undefined : true)}
                >
                    Mine
                </Button>
                <Button togglable
                    selected={cancelled}
                    onClick={() => setCancelled(!cancelled)}
                >
                    Cancelled
                </Button>
                <Button
                    icon="plus-circle"
                    svgIcon={plusCircleIcon}
                    themeColor='primary'
                    onClick={() => setShowAccidentDialog(true)}
                >
                    Add Accident/Incident
                </Button>
            </GridToolbar>
            <GridNoRecords>
                No Driver Accidents / Incidents Found
            </GridNoRecords>
            <Column {...columnProps("ReportNumber")} title="Report #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/DriverAccident/${e.dataItem.DriverAccidentID}`}>{e.dataItem.ReportNumber || e.dataItem.DriverAccidentID}</Link>
            </td>} />
            <Column {...columnProps("DateTimeOfAccident")} title="Date Time" filter="date" cell={DateCell} />
            <Column {...columnProps("DriverNumber")} title="Driver" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/Driver/${e.dataItem.DriverID}`}>{e.dataItem.DriverNumber}</Link>
            </td>} />
            <Column {...columnProps("VehicleNumber")} title="Vehicle" cell={(e) => <td>
                {e.dataItem.VehicleID ? <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${e.dataItem.VehicleID}`}>{e.dataItem.VehicleNumber}</Link> : "N/A"}
            </td>} />
            <Column {...columnProps("ClaimsTotal")} title="Claims" filter="numeric" format="{0:c2}" />
            <Column {...columnProps("CitationGiven")} title="Citation" cell={YesNoCell} filter="boolean" />
            <Column {...columnProps("DOTRecordable")} title="DOT" cell={YesNoCell} filter="boolean" />
            <Column {...columnProps("ReportingByName")} title="Reported By" />
        </Grid>
    </>
}

export default DriverAccidents;
