import { useEffect, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridCellProps, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Title } from '../../utils/title';
import { Loader } from "@progress/kendo-react-indicators";
import { ILink } from '../../types/link';
import { BooleanFilter, Filter, FilterChangeEvent, Operators, TextFilter } from '@progress/kendo-react-data-tools';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import EmailCell from '../../components/cells/EmailCell';
import PhoneCellAndExtension from '../../components/cells/PhoneCellAndExtension';

export type CustomerContactViewModel = {
  CustomerContactID: number;
  FullName: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  PhoneNumberExtension: string;
  EMailAddress: string;
  Title: string;
  Active: boolean;
  CustomerNumber: string;
  CustomerName: string;
  Hash: string;
  Links: ILink[];
}

export const CustomerContactCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: CustomerContactViewModel = props.dataItem;
  var customerContactProfile = dataItem.Links.find((x: ILink) => x.Name === 'CustomerContactProfile');
  return (
    <td colSpan={props.colSpan} style={props.style}>
      {customerContactProfile &&
        <Link
          to={customerContactProfile.Link}
          style={{ color: '#007bff' }}
        >
          {dataItem.FullName || "N/A"}
        </Link>}
    </td>
  );
};

const CustomerContactGrid = () => {
  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "FullName",
      dir: "asc"
    }]
  } as GridState;

  const defaultAndFilter = {
    logic: 'and', filters: [
      { field: 'Active', operator: 'eq', value: true },
    ]
  } as CompositeFilterDescriptor;

  const sessionStorageAndFilter = sessionStorage.getItem("CustomerContactGrid-andFilter");
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
  const [loading, setLoading] = useState(false);
  const [customerContacts, setCustomerContacts] = useState<CustomerContactViewModel[]>([]);
  //const [showAddContactDialog, setShowAddContactDialog] = useState(false);
  //const buttonAnchor = useRef<Button>(null);
  const totalRecords = useRef(0);
  const DataState_Take = 50;

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const fetchRecords = useCallback((append: boolean) => {
    sessionStorage.setItem("CustomerContactGrid-andFilter", JSON.stringify(dataState.filter));
    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }

    //Todo - Add as a top filter
    const data = {
      CompanyActive: true
    };
    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/Customer/CustomerContacts?${queryStr}`, data, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setCustomerContacts(Data);
        } else {
          setCustomerContacts(prevData => prevData.concat(Data));
        }
        setLoading(false);
        handleResize(null);
      }).catch(() => {
        setLoading(false);
      });
  }, [dataState]);

  const search = useDebouncedCallback(useCallback(() => {
    fetchRecords(false);
  }, [fetchRecords]), 750);

  useEffect(() => {
    search();
  }, [dataState.filter, dataState.sort, search]);

  //useEffect(() => {
  //    fetchRecords(false);
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);

  const handleResize = (e: UIEvent) => {
    if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
      ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
    }
  }

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (customerContacts.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  //const EmailAddressCell = (props: GridCellProps) => {
  //    if (!props.field)
  //        return null;

  //    let dataItem: CustomerContactViewModel = props.dataItem;
  //    //props.dataItem[props.field]
  //    return (
  //        <td colSpan={props.colSpan} style={props.style}>
  //            <a
  //                href={"mailto:" + dataItem.EMailAddress}
  //                style={{ color: '#007bff' }}
  //            >{dataItem.EMailAddress}
  //            </a>
  //        </td>
  //    );
  //};

  const CustomerCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: CustomerContactViewModel = props.dataItem;
    var customerProfile = dataItem.Links.find((x: ILink) => x.Name === 'CustomerProfile');
    //props.dataItem[props.field]
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {customerProfile &&
          <Link
            to={customerProfile.Link}
            style={{ color: '#007bff' }}
          >
            {dataItem.CustomerNumber}
          </Link>} - {dataItem.CustomerName}
      </td>
    );
  };

  //const PhoneCell = (props: GridCellProps) => {
  //    if (!props.field)
  //        return null;

  //    let dataItem: CustomerContactViewModel = props.dataItem;
  //    return (
  //        <td colSpan={props.colSpan} style={props.style}>
  //            <a href={`tel:${dataItem.PhoneNumber}`} style={{ color: '#007bff' }}>{formatPhone(dataItem.PhoneNumber)}</a>
  //        </td>
  //    );
  //};

  //const handleKey = (e: React.KeyboardEvent) => {
  //    if (e.key === "Enter") {
  //        fetchRecords(false);
  //    }
  //};

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const onFilterChange = (event: FilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter });
  };

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
  };

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setDataState({ ...dataState, sort: event.sort });
  };

  return (
    //<div className="container-fluid" onKeyDown={handleKey}>
    <div className="container-fluid" style={{ position: "relative" }}>
      <Title string="CustomerContact Listing" />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div>
        <Filter
          className="kendoFilterControl"
          value={dataState.filter}
          onChange={onFilterChange}
          fields={[
            {
              name: "Active",
              label: "Active",
              filter: BooleanFilter,
              operators: Operators.boolean
            },
            {
              name: "FullName",
              label: "Name",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "EMailAddress",
              label: "E-Mail",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "CustomerName",
              label: "Customer Name",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "CustomerNumber",
              label: "Customer ID",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "PhoneNumber",
              label: "Phone Number",
              filter: TextFilter,
              operators: Operators.text,
            },
          ]}
        />
        {/*<label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>*/}
        {/*    <Button themeColor="primary"*/}
        {/*        ref={buttonAnchor}*/}
        {/*        onClick={() => {*/}
        {/*            fetchRecords(false);*/}
        {/*        }}*/}
        {/*    >Search*/}
        {/*    </Button>*/}
        {/*</label>*/}
      </div>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        data={customerContacts}
        resizable={true}
        reorderable={true}
        sortable={true}
        total={totalRecords.current}
        onScroll={scrollHandler}
        filterable={true}
        onFilterChange={(e) => onGridFilterChange(e)}
        onSortChange={(e) => onGridSortChange(e)}
      >
        <Column field="FullName" title="Name" cell={CustomerContactCell} />
        <Column field="EMailAddress" title="E-Mail" cell={EmailCell} />
        <Column field="PhoneNumber" title="Phone" cell={PhoneCellAndExtension} />
        <Column field="Title" title="Title" />
        <Column field="CustomerNumber" title="Customer" cell={CustomerCell} />
      </Grid>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
    </div>
  )
}

export default CustomerContactGrid;