import { MaskedTextBox, MaskedTextBoxProps, MaskedTextBoxChangeEvent, MaskedTextBoxEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';

interface SimpleTimePickerProps {
    onChange: (value: Date | null) => void;
    value: Date | null;
    inputProps?: MaskedTextBoxProps;
}

const currentFormat = "HH:mm";

const getFormattedDate = (value: string): Moment.Moment | null => {
    const formats = ["HH:mm", "H:m", "H:mm", "HH:m", "HHmm"];

    if (Moment(value, formats, true).isValid()) {
        return Moment(value, currentFormat);
    }

    return null;
};

const SimpleTimePicker: React.FC<SimpleTimePickerProps> = ({ onChange, value, inputProps }) => {
    const anchor = React.useRef<MaskedTextBox | null>(null);
    const [inputValue, setInputValue] = useState<string>(value != null ? Moment(value).format(currentFormat) : '');

    const handleChange = (e: MaskedTextBoxChangeEvent) => {
        const val = e.value as string;
        setInputValue(val);
        const formattedDate = getFormattedDate(val);
        if (formattedDate != null) {
            onChange(formattedDate.toDate());
        } else {
            onChange(null);
        }
    }

    const handleBlur = (e: MaskedTextBoxEvent) => {
        const formattedDate = getFormattedDate(e.target.value);
        setInputValue(formattedDate != null ? Moment(formattedDate).format(currentFormat) : value != null ? Moment(value).format(currentFormat) : '');
    }

    React.useEffect(() => {
        if (anchor?.current?.element != document.activeElement)
        {
            setInputValue(value != null ? Moment(value).format(currentFormat) : '');
        }
    }, [value]);

    return <MaskedTextBox
        {...inputProps}
        valid={(inputValue?.length === 0 || getFormattedDate(inputValue) != null) && inputProps?.valid}
        value={inputValue}
        onChange={handleChange}
        ref={anchor}
        onBlur={handleBlur}
        mask={"00:00"}
    />
};

export default SimpleTimePicker;