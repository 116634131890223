import { fetchApi } from '../services/api';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { translateDataSourceResultGroups, toDataSourceRequestString, DataSourceRequestState, State } from '@progress/kendo-data-query';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoadBoardHistoryState {
  dataState: DataSourceRequestState;
  data: any[],
  total: number;
  isLoading: boolean;
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ExpandChangeAction {
  type: 'EXPAND_CHANGE';
  dataItem: any;
}

interface RefreshHistoryAction {
  type: 'REFRESH_HISTORY';
}

interface RequestHistoryAction {
  type: 'REQUEST_HISTORY';
  dataState: State;
}

interface ReceiveHistoryAction {
  type: 'RECEIVE_HISTORY';
  data: any[];
  total: number;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ExpandChangeAction | RefreshHistoryAction | RequestHistoryAction | ReceiveHistoryAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  expandChange: (dataItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'EXPAND_CHANGE', dataItem: dataItem });
  },
  requestHistory: (dataState: State): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'REQUEST_HISTORY', dataState: dataState });

    const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
    const hasGroups = dataState.group && dataState.group.length;

    fetchApi(`/api/LoadBoard/BidHistory_Read?${queryStr}`)
      .then(({ Data, Total }) => {
        dispatch({
          type: 'RECEIVE_HISTORY',
          data: hasGroups ? translateDataSourceResultGroups(Data) : Data,
          total: Total
        });
      });
  },
  refreshHistory: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const dataState = getState().loadBoardHistory.dataState;
    const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state
    const hasGroups = dataState.group && dataState.group.length;

    fetchApi(`/api/LoadBoard/BidHistory_Read?${queryStr}`)
      .then(({ Data, Total }) => {
        dispatch({
          type: 'RECEIVE_HISTORY',
          data: hasGroups ? translateDataSourceResultGroups(Data) : Data,
          total: Total
        });
      });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultDataState = {
  skip: 0,
  take: 20,
  sort: [{ field: "BidDateTime", dir: "desc" }],
  filter: { logic: 'and', filters: [{field: "Successful", operator: "eq", value: true}] }
} as DataSourceRequestState;
const unloadedState: LoadBoardHistoryState = { dataState: defaultDataState, data: [], total: 0, isLoading: false };

export const reducer: Reducer<LoadBoardHistoryState> = (state: LoadBoardHistoryState, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'EXPAND_CHANGE':
      return {
        ...state,
        data: state.data.map(item =>
          item === action.dataItem ? { ...item, expanded: !item.expanded } : item
        )
      };
    case 'REFRESH_HISTORY':
      return {
        ...state,
        isLoading: false
      }
    case 'REQUEST_HISTORY':
      return {
        ...state,
        dataState: action.dataState,
        isLoading: true
      };
    case 'RECEIVE_HISTORY':
      return {
        ...state,
        data: action.data.map(item => {
          const existing = state.data.find(i => i.ShipmentBidID === item.ShipmentBidID);
          return existing ? { ...item, expanded: existing.expanded } : item;
        }),
        total: action.total,
        isLoading: false
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};
