import * as React from 'react';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import { Checkbox, InputPrefix, InputSuffix, TextArea, TextBox } from '@progress/kendo-react-inputs';

interface Props {
  Link: ILink;
  CloseDialog: () => void;
}

interface State {
  sending: boolean;
  selectedEmails: string[];
  customEmailAddress: string;
  threadId: string;
  emails: Array<{ Name: string, Email: string }>;
  links: ILink[]; 
  note: string;
}

interface ComposeEmailResponse {
  CarrierThreadID: string;
  EmailAddresses: Array<{ Name: string, Email: string }>;
  Commands: ILink[];
}

export class ComposeCarrierEmail extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      sending: false,
      selectedEmails: [],
      customEmailAddress: '',
      threadId: '',
      emails: [],
      links: [],
      note: '',
    }

    this.sendEmail = this.sendEmail.bind(this);
    this.updateCarrierThread = this.updateCarrierThread.bind(this);
  }

  public componentWillMount() {
    fetchApi(this.props.Link.Link)
      .then((response: ComposeEmailResponse) => {
        this.setState({ emails: response.EmailAddresses, links: response.Commands, threadId: response.CarrierThreadID});
      })
  }

  public render() {

    const cannedResponses = [
      "Hello, can you please provide current ETA + location update on this load?",
      "Hello, can we please have a location update on this load? Customer is requesting current status...",
      "Hello, can you please advise if the truck has been loaded?",
      "Hello, can you please advise if the truck has been offloaded?",
      "Hello, can you please advise if driver is on-site at shipper?",
      "Hello, can you please advise if driver is on-site at consignee?",
      "Hello, can you please provide the drivers phone number for Macropoint tracking?"
    ];

    return (
      <Dialog title="Send Carrier a Request for Information" onClose={this.props.CloseDialog} className="dialog-w800">
          {this.state.emails.map((address, index) => {
            return <div className="form-group mb-1">
              <Checkbox
                key={index}
                id={"contact" + index}
                label={`${this.EmailBoxType(address.Email)}${address.Name} - ${address.Email}`}
                checked={this.state.selectedEmails.includes(address.Email)}
                onChange={() => this.toggleCheckbox(address.Email)}
              />
            </div>
          })}
          <TextBox
            className='my-1'
            placeholder="someone@company.com; someother@company.com"
            prefix={() => (<InputPrefix>
              <Button disabled>Additional Emails</Button>
            </InputPrefix>)}
            value={this.state.customEmailAddress}
            onChange={(e) => this.setState({ customEmailAddress: e.value.toString(), threadId: '' })}
          />
          <TextBox
            className='my-1'
            placeholder="cnv_123456"
            maxLength={17}
            prefix={() => (<InputPrefix>
              <Button disabled>Carrier Front ID</Button>
            </InputPrefix>)}
            suffix={() => (<InputSuffix>
              <Button
                disabled={!this.state.threadId}
                className='mr-1'
                onClick={() => {
                  const win = window.open(`https://app.frontapp.com/open/${this.state.threadId}`, '_blank');
                  win.focus();
                }}
              >
                Open
              </Button>
              <Button
                themeColor='primary'
                onClick={this.updateCarrierThread}
              >
                Update
              </Button>
            </InputSuffix>)}
            value={this.state.threadId}
            onChange={(e) => this.setState({ threadId: e.value.toString(), selectedEmails: [], customEmailAddress: '' })}
          />
          <DropDownList
            className='my-1'
            data={cannedResponses}
            defaultValue="Select Canned Response"
            value={undefined}
            onChange={(e) => this.setState({ note: e.target.value })}
          />
          <TextArea
            rows={2}
            className='mt-1'
            placeholder="Notes to share with the carrier"
            value={this.state.note}
            onChange={(event) => {
              this.setState({ note: event.target.value });
            }}
          />
          <DialogActionsBar layout="end">
            <Button onClick={this.props.CloseDialog}>Cancel</Button>
            <Button themeColor="primary" onClick={this.sendEmail} disabled={this.state.sending}>Request Update</Button>
          </DialogActionsBar>
      </Dialog>
    );
  }

  private EmailBoxType(Email: string) {
    if (Email.indexOf("@load1.com") > -1 || Email.indexOf("@appnouveau.com") > -1) {
      return "Cc: ";
    }
    return "";
  }

  private updateCarrierThread() {
    const dataItem = {
      ThreadID: this.state.threadId,
    };

    this.setState({ sending: true });
    const command = this.state.links.find(x => x.Name === 'UpdateCarrierThread') as ILink;
    fetchApi(command.Link, dataItem, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('There was an error sending request!');
      })
      .finally(() => {
        this.setState({ sending: false });
      });
  }

  private sendEmail() {

    const dataItem = {
      EmailAddresses: this.state.selectedEmails,
      AdditionalEmailAddresses: this.state.customEmailAddress,
      ThreadID: this.state.threadId,
      Note: this.state.note,
    };

    this.setState({ sending: true });
    const command = this.state.links.find(x => x.Name === 'Send') as ILink;
    fetchApi(command.Link, dataItem, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('There was an error sending request!');
      })
      .finally(() => {
        this.setState({ sending: false });
      });
  }

  private toggleCheckbox = (email: string) => {
    this.setState((prevState) => {
      if (prevState.selectedEmails.includes(email)) {
        return { selectedEmails: prevState.selectedEmails.filter(x => x !== email), threadId: '' };
      }
      return { selectedEmails: [...prevState.selectedEmails, email], threadId: '' };
    });
  }
}
