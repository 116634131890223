import * as React from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { GridEvent, GridFilterChangeEvent, Grid, GridToolbar, GridColumn as Column, GridSortChangeEvent, GridSelectionChangeEvent, GridCellProps } from '@progress/kendo-react-grid';
import { debounce } from 'ts-debounce';
import carrierTierCell from './CarrierTierCell';
import CarrierReviews from '../CarrierReviews';
import carrierActionPopupCell from './carrierActionPopupCell';
import { CarrierSupportedVehiclesDialog } from './CarrierSupportedVehiclesDialog';
import Documents, { DocumentEntityType } from '../Documents/Documents';
import { SendCarrierMessage } from './SendCarrierMessage';
import { SendBrokerageCarrierAgreementDialog } from './Carrier/SendBrokerageCarrierAgreementDialog';
import PhoneCell from '../../components/cells/PhoneCell';
import { ILink } from '../../types/link';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../utils/title';
import { Link } from 'react-router-dom';
import AddCarrierDialog from '../AssetCarriers/AddCarrierDialog';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { CarrierStatus } from 'TypeGen/carrier-status';
import { DropdownFilterCellIDName } from 'components/cells/DropdownFilterCellIDName';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { formatNumber } from '@progress/kendo-intl';
import { arrowRotateCwIcon, documentManagerIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';

export enum MenuOption {
    SupportedVehicles,
    Documents,
    BrokerageCarrierAgreement
}

export type SelectedCarrierValue = {
    CarrierID: number;
    CarrierName: string;
    Phone1: string;
}

type State = {
    dataState: GridState;
    totalRecords: number;
    isLoading: boolean;
    carrierData: AssetCarrierViewModel[];
    selectedCarrier: SelectedCarrierValue | null;
    selectedCarrierData: AssetCarrierViewModel | null;
    showCarrierReviewDialog: boolean;
    showSupportedVehiclesDialog: boolean;
    showSendMessageDialogScreen: boolean;
    showDocumentDialog: boolean;
    showBrokerageCarrierAgreementDialog: boolean;
    missingPaperwork: boolean;
    active: boolean;
    showAddCarrierDialog: boolean;
}

const CarrierMCNumber1Cell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: AssetCarrierViewModel = props.dataItem;
    var carrierProfile = dataItem.Links.find((x: ILink) => x.Name === 'CarrierProfile');

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {carrierProfile &&
                <Link
                    to={carrierProfile.Link}
                    style={{ color: '#007bff' }}
                >
                    {dataItem.MCNumber1 || 'N/A'}
                </Link>}
        </td>
    );
};

const carrierStatuses = [
    { ID: CarrierStatus.Active, Name: "Active" },
    { ID: CarrierStatus.Inactive, Name: "Inactive" },
    { ID: CarrierStatus.DoNotUse, Name: "DO NOT USE" }
];

export default class CarrierGrid extends React.Component<{}, State> {
    private CONST_TAKE: number = 50;

    private CONST_DATASTATE_DEFAULT = {
        skip: 0,
        take: this.CONST_TAKE,
        sort: [{ field: "CarrierName", dir: "asc" }]
    } as GridState;

    private defaultAndFilter = {
        logic: 'and',
        filters: []
    } as CompositeFilterDescriptor;

    constructor(props: {}) {
        super(props);

        this.state = {
            isLoading: true,
            carrierData: [],
            totalRecords: 0,
            dataState: { ...this.CONST_DATASTATE_DEFAULT, filter: sessionStorage.getItem("CarrierGrid-andFilter") ? JSON.parse(sessionStorage.getItem("CarrierGrid-andFilter")) : this.defaultAndFilter },
            selectedCarrier: null,
            selectedCarrierData: null,
            showCarrierReviewDialog: false,
            showSupportedVehiclesDialog: false,
            showSendMessageDialogScreen: false,
            showBrokerageCarrierAgreementDialog: false,
            showDocumentDialog: false,
            missingPaperwork: false,
            active: true,
            showAddCarrierDialog: false
        }

        this.handleResize = this.handleResize.bind(this);
        this.getAssetCarriers = this.getAssetCarriers.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.filterInputChange = this.filterInputChange.bind(this);
        this.loadCarrierReviewScreen = this.loadCarrierReviewScreen.bind(this);
        this.loadSupportedVehiclesDialogScreen = this.loadSupportedVehiclesDialogScreen.bind(this);
        this.loadSendMessageDialogScreen = this.loadSendMessageDialogScreen.bind(this);
        this.reset = this.reset.bind(this);
        this.headerSelectionChange = this.headerSelectionChange.bind(this);
    }

    private loadSendMessageDialogScreen(CarrierID: number) {
        this.setState({
            showSendMessageDialogScreen: true, selectedCarrierData: this.state.carrierData.filter(x => x.CarrierID === CarrierID)[0]
        });
    }

    private loadCarrierReviewScreen(SelectedCarrier: SelectedCarrierValue) {
        this.setState({
            showCarrierReviewDialog: true, selectedCarrier: SelectedCarrier
        });
    }

    private loadSupportedVehiclesDialogScreen(SelectedCarrier: SelectedCarrierValue, SelectedMenuOption: MenuOption) {
        switch (SelectedMenuOption) {
            case MenuOption.SupportedVehicles:
                this.setState({ showSupportedVehiclesDialog: true, selectedCarrier: SelectedCarrier });
                break;
            case MenuOption.Documents:
                this.setState({ showDocumentDialog: true, selectedCarrier: SelectedCarrier });
                break;
            case MenuOption.BrokerageCarrierAgreement:
                this.setState({ showBrokerageCarrierAgreementDialog: true, selectedCarrier: SelectedCarrier });
                break;
        }
    }

    private isHeaderSelected() {
        return (this.state.carrierData.findIndex(dataItem => dataItem.Selected === false) === -1);
    }

    private getAssetCarriers(append: boolean, active = this.state.active, missingPW = this.state.missingPaperwork) {
        sessionStorage.setItem("CarrierGrid-andFilter", JSON.stringify(this.state.dataState.filter));
        const missingpwState = { active, missingPaperwork: missingPW };

        this.setState({
            isLoading: true,
            ...missingpwState
        })

        const dataGridState = this.state.dataState;

        if (!append) {
            dataGridState.take = this.CONST_TAKE;
            dataGridState.skip = 0;
        }

        const data = {
            Active: active,
            MissingPaperwork: missingPW
        };

        const queryStr = toDataSourceRequestString(dataGridState);
        fetchApi(`/api/Asset/GetAssetCarriers?${queryStr}`, data, 'POST')
            .then(({ Data, Total }) => {
                if (!append)
                    this.setState({ carrierData: Data, totalRecords: Total, isLoading: false, dataState: dataGridState }, () => { this.handleResize(); })
                else {
                    if (this.isHeaderSelected()) { //ensure that additional records imported are also selected
                        Data = (Data as AssetCarrierViewModel[]).map(function (x) {
                            x.Selected = true;
                            return x
                        });
                    }
                    this.setState({ carrierData: this.state.carrierData.concat(Data), totalRecords: Total, isLoading: false, dataState: dataGridState }, () => { this.handleResize(); })
                }
            });
    }
    public componentDidMount() {
        this.getAssetCarriers(false);
        window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    public handleResize() {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 150) + "px";
        }
    }

    private filterChange = (event: GridFilterChangeEvent) => { //Add the filters to the existing filter list, but not in a specific AND | OR group.  These will be standalone and self managed by Kendo/Grid
        this.state.dataState.filter = event.filter;
        this.setState({ dataState: this.state.dataState }, () => { this.filterInputChangeDebounced() });
    }

    private filterInputChangeDebounced = debounce(this.filterInputChange, 500);
    private filterInputChange(): Promise<boolean> {
        this.getAssetCarriers(false);
        return Promise.resolve(false);
    }

    private sortChange = (event: GridSortChangeEvent) => {
        const dS = this.state.dataState;
        dS.sort = event.sort;
        this.setState({ dataState: dS }, () => { this.getAssetCarriers(false) });
    }

    private scrollHandler(event: GridEvent) {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.clientHeight) - 1) {
            if (this.state.carrierData.length === this.state.totalRecords)  //When auto-scrolling, ignore when we've hit max records
                return;

            this.state.dataState.skip = this.state.dataState.skip + this.state.dataState.take;

            this.getAssetCarriers(true);
        }
    }

    private reset() {
        this.getAssetCarriers(false);
    }

    private selectionChange = (event: GridSelectionChangeEvent) => {
        event.dataItem.Selected = !event.dataItem.Selected;
        //let index = this.find(this.props.driverData, event.dataItem);
        this.forceUpdate();
    }

    private headerSelectionChange = (event: any) => {
        const checked = event.syntheticEvent.target.checked;
        const carrierData = [...this.state.carrierData];
        carrierData.forEach(item => item.Selected = checked);
        this.setState({ carrierData });
    }

    public render() {
        return <React.Fragment>
            <Title string="Carrier Listing" />
            {this.state.showAddCarrierDialog && <AddCarrierDialog
                CloseDialog={() => this.setState({ showAddCarrierDialog: false })}
            />}
            {this.state.showCarrierReviewDialog && <CarrierReviews
                CloseDialog={() => {
                    this.setState({ showCarrierReviewDialog: false });
                }}
                Name={this.state.selectedCarrier.CarrierName} PhoneNumber={this.state.selectedCarrier.Phone1}
            />}
            {this.state.showSupportedVehiclesDialog && <CarrierSupportedVehiclesDialog
                CloseDialog={() => {
                    this.setState({ showSupportedVehiclesDialog: false });
                }}
                SelectedCarrier={this.state.selectedCarrier}
                CarrierValues={this.state.carrierData}
            />}
            {this.state.showDocumentDialog && <Documents
                    IsWindow
                    EntityType={DocumentEntityType.Carrier}
                    EntityId={this.state.selectedCarrier.CarrierID}
                    EntityNumber={this.state.selectedCarrier.CarrierName}
                    CloseDocumentModal={() => { this.setState({ showDocumentDialog: false }); }}
                />
            }
            {this.state.showBrokerageCarrierAgreementDialog && <SendBrokerageCarrierAgreementDialog
                CloseDialog={() => {
                    this.setState({ showBrokerageCarrierAgreementDialog: false });
                }}
                SelectedCarrier={this.state.selectedCarrier}
            />}
            {this.state.showSendMessageDialogScreen && <SendCarrierMessage
                CloseDialog={() => {
                    this.setState({ showSendMessageDialogScreen: false });
                }}
                SelectedCarrier={this.state.selectedCarrierData}
                SelectedCarriers={this.state.carrierData.filter(x => x.Selected)}
                AllCarriersSelected={this.state.carrierData.findIndex(item => item.Selected === false) === -1 && this.state.totalRecords !== this.state.carrierData.filter(x => x.Selected).length}
            />}
            {/*AllCarriersSelected involes the carriers on the other server pages that the end-user can't see*/}
            {this.state.isLoading && <GridLoadingPanel />}
            <Grid
                {...this.state.dataState}
                onDataStateChange={() => this.getAssetCarriers(false)}
                filterable
                onFilterChange={this.filterChange}
                onSortChange={this.sortChange}
                resizable={true}
                reorderable={true}
                sortable={{
                    allowUnsort: true,
                    mode: 'multiple'
                }}
                data={this.state.carrierData}
                total={this.state.totalRecords}
                onScroll={this.scrollHandler}
                selectedField="Selected"
                onSelectionChange={this.selectionChange}
                onHeaderSelectionChange={this.headerSelectionChange}
            >
                <GridToolbar>
                    <Button
                        title="Clear All Filters and Sort"
                        icon="filter-clear"
                        svgIcon={filterClearIcon}
                        onClick={this.reset}
                    />
                    <Button
                        title="Refresh"
                        icon="refresh"
                        svgIcon={arrowRotateCwIcon}
                        onClick={() => this.getAssetCarriers(false)}
                    />
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        themeColor="primary"
                        onClick={() => this.setState({ showAddCarrierDialog: true })}
                    > Carrier
                    </Button>

                    <Button
                        icon="document-manager"
                        svgIcon={documentManagerIcon}
                        onClick={() => this.setState({ showBrokerageCarrierAgreementDialog: true, selectedCarrier: null })}
                    >
                        Send Document
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        togglable
                        selected={!this.state.active}
                        onClick={() => this.getAssetCarriers(false, !this.state.active, this.state.missingPaperwork)}
                        title="Active/Retired"
                    >{this.state.active ? "Active" : "Retired"}
                    </Button>
                    <label style={{ fontSize: "12px", marginTop: "6px" }}>Missing:</label>
                    <Button
                        togglable
                        selected={this.state.missingPaperwork}
                        onClick={() => this.getAssetCarriers(false, this.state.active, !this.state.missingPaperwork)}
                        title="Paperwork"
                    >PW
                    </Button>
                </GridToolbar>
                <Column field="MCNumber1" title="MC Number" cell={CarrierMCNumber1Cell} />
                <Column field="CarrierName" title="Name" />
                <Column field="City" title="City" media="(min-width: 768px)" />
                <Column field="State" title="State" media="(min-width: 768px)" />
                <Column field="Phone1" title="Phone" media="(min-width: 992px)" sortable={false} cell={PhoneCell} />
                <Column field="CarrierTier" title=" " cell={carrierTierCell(this.loadCarrierReviewScreen)} width={34} sortable={false} filterable={false} />
                <Column
                    field="CarrierStatus"
                    title="Status"
                    filter='numeric'
                    cell={(props) => <td>{carrierStatuses.find(x => x.ID == props.dataItem.CarrierStatus)?.Name}</td>}
                    filterCell={(props) => <DropdownFilterCellIDName {...props} data={carrierStatuses} />}
                />
                <Column
                    field="Selected"
                    width={34}
                    media="(min-width: 768px)"
                    headerSelectionValue={
                        this.state.carrierData.findIndex(item => item.Selected === false) === -1
                    } sortable={false} filterable={false} />
                <Column cell={carrierActionPopupCell(this.state.carrierData, this.loadSupportedVehiclesDialogScreen, this.loadSendMessageDialogScreen)} width={48} sortable={false} filterable={false} />
            </Grid>
            {
                this.state.totalRecords > 0 &&
                <div className="k-pager k-pager-md k-grid-pagert">
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {this.state.dataState.skip + this.state.dataState.take > this.state.totalRecords ? this.state.totalRecords : this.state.dataState.skip + this.state.dataState.take} of {formatNumber(this.state.totalRecords, 'n0')} items
                    </div>
                </div>
            }


        </React.Fragment>
    }
}
