import * as React from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { Form, FormHandle, Field } from '@progress/kendo-react-form';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { fetchApi } from '../services/api';
import { Button } from '@progress/kendo-react-buttons';
import { VehicleTypes } from '../views/AssetVehicles/Profile';
import TheadIDCell from 'views/Quote/Recent/ThreadIDCell';
import { GridLoadingPanel } from './GridLoadingPanel';
import { IDName } from 'types/idname';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment-timezone';

type QuoteSearchValue = {
  QuoteID: number;
  Unassigned: boolean;
  AcceptedOfferCount: number;
  DeclinedOfferCount: number;
  ExpiredOfferCount: number;
  BillingCustomerNumber: string;
  ThreadID: string;
  RouteName: string;
  FromCityStateZip: string;
  ToCityStateZip: string;
  Distance: number;
  TotalPieces: number;
  TotalWeight: number;
  VehicleType: string;
  CreatedDateTime: string;
}

type QuoteSearchParams = {
  QuoteID: number | null;
  CreatedFromDate: Date | string | null;
  BillingCustomerNumber: string;
  ThreadID: string;
  RouteName: string;
  FromCity: string;
  FromState: string;
  FromZipCode: string;
  ToCity: string;
  ToState: string;
  ToZipCode: string;
  VehicleTypeID: null | IDName;
  BookUserID: null | IDName;
}

type Props = {
  OrderID: number;
  close: () => void;
  createQuote: () => void;
  linkQuote: (quoteID: number) => void;
}

type State = {
  users: IDName[];
  searchData: QuoteSearchValue[];
  isLoadingSearchData: boolean;
  quoteSearchData: QuoteSearchParams | null;
  ValidationRequestHash: string;
}

const VehicleTypesAll = [{ ID: 0, Name: 'ANY SIZE' }].concat(VehicleTypes);

export default class OrderLinkToQuote extends React.Component<Props, State> {

  private searchForm: React.RefObject<FormHandle>;

  constructor(props: Props) {
    super(props);

    this.searchForm = React.createRef();

    this.state = {
      users: [],
      searchData: [],
      isLoadingSearchData: true,
      quoteSearchData: null,
      ValidationRequestHash: '',
    }
  }

  public componentDidMount() {
    fetchApi("/api/Quote/OrderQuoteSearchDefaults/" + this.props.OrderID)
      .then((response: { Defaults: Omit<QuoteSearchParams, 'VehicleTypeID' | 'BookUserID'> & { VehicleTypeID: number, BookUserID: number }, Users: IDName[] }) => {
        this.setState({
          users: response.Users,
          quoteSearchData: {
            ...response.Defaults,
            CreatedFromDate: response.Defaults.CreatedFromDate ? moment.utc(response.Defaults.CreatedFromDate).toDate() : null,
            BookUserID: response.Users.find(u => u.ID === response.Defaults.BookUserID),
            VehicleTypeID: VehicleTypesAll.find(vt => vt.ID === response.Defaults.VehicleTypeID),
          }
        }, () => this.searchQuotes(this.state.quoteSearchData));
      });
  }

  public render() {
    return <Window
      title="Quote Search"
      style={{ position: 'fixed' }}
      initialWidth={Math.min(1024, window.innerWidth)}
      initialHeight={Math.min(650, window.innerHeight)}
      onClose={this.props.close}
    >
      {this.state.quoteSearchData && <Form
        ref={this.searchForm}
        onSubmit={(values, event) => this.searchQuotes(values)}
        initialValues={this.state.quoteSearchData}
        render={(formRenderProps) => (
          <form onSubmit={formRenderProps.onSubmit} className="k-form k-form-md p-0">
            <div className="row">
              <div className="col-md-4">
                <Field name="BillingCustomerNumber" component={Input} label="Bill To #" maxLength={15} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="ThreadID" component={Input} label="Thread" maxLength={15} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="RouteName" component={Input} label="Route" maxLength={50} style={{ width: '100%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Field name="FromCity" component={Input} label="From City" maxLength={50} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="FromState" component={Input} label="From State" maxLength={2} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="FromZipCode" component={Input} label="From Zip" maxLength={10} style={{ width: '100%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Field name="ToCity" component={Input} label="To City" maxLength={50} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="ToState" component={Input} label="To State" maxLength={2} style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="ToZipCode" component={Input} label="To Zip" maxLength={10} style={{ width: '100%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Field name="TotalPieces" component={NumericTextBox} label="Total Pieces" style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="TotalWeight" component={NumericTextBox} label="Total Weight" style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="VehicleTypeID" component={DropDownList} data={VehicleTypesAll} textField="Name" dataItemKey="ID" label="Vehicle Type" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Field name="CreatedFromDate" component={DatePicker} label="From Date" format="MM/dd/yyyy" style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="QuoteID" component={NumericTextBox} label="Quote #" format="0" style={{ width: '100%' }} />
              </div>
              <div className="col-md-4">
                <Field name="BookUserID" component={DropDownList} data={this.state.users} textField="Name" dataItemKey="ID" label="Created By" style={{ width: '100%' }} />
              </div>
            </div>
            <br />
            <div className="text-center">
              <Button themeColor="primary" type='submit'>Search</Button>
              &nbsp;&nbsp;
              <Button type="button" onClick={() => {
                this.setState({
                    quoteSearchData: {
                      QuoteID: null,
                      CreatedFromDate: null,
                      BillingCustomerNumber: '',
                      ThreadID: '',
                      RouteName: '',
                      FromCity: '',
                      FromState: '',
                      FromZipCode: '',
                      ToCity: '',
                      ToState: '',
                      ToZipCode: '',
                      VehicleTypeID: null,
                      BookUserID: null,
                    }
                }, this.searchForm.current.resetForm);
              }}>Reset</Button>
            </div>
          </form>
        )} />}
      <br />
      {this.state.isLoadingSearchData && <GridLoadingPanel />}
      <Grid
        scrollable='none'
        data={this.state.searchData}
        dataItemKey="QuoteID"
        onRowClick={(e) => this.props.linkQuote(e.dataItem.QuoteID)}
      >
        <GridNoRecords>
          No Quotes Found, Please Refine Your Search or Create Quote.
          <br /><br />
          <Button onClick={this.props.createQuote} themeColor="success">Create Quote</Button>
        </GridNoRecords>
        <Column field="QuoteID" title="Quote #" cell={(props) => 
          <td style={{ color: props.dataItem.Unassigned ? '#28a745' : '#007bff' }} role="button">
            {props.dataItem.QuoteID}
            {props.dataItem.DeclinedOfferCount > 0 && <div className="recent-red-circle">{props.dataItem.DeclinedOfferCount}</div>}
            {props.dataItem.ExpiredOfferCount > 0 && <div className="recent-orange-circle">{props.dataItem.ExpiredOfferCount}</div>}
            {props.dataItem.AcceptedOfferCount > 0 && <div className="recent-green-circle">{props.dataItem.AcceptedOfferCount}</div>}
          </td>} />
        <Column field="BillingCustomerNumber" title="Bill To" />
        <Column field="ThreadID" title="Thread" cell={TheadIDCell} />
        <Column field="RouteName" title="Route" />
        <Column field="FromCityStateZip" title="From" />
        <Column field="ToCityStateZip" title="To" />
        <Column field="Distance" title="Miles" filter="numeric" format="{0:n0}" />
        <Column field="TotalPieces" title="Pieces" filter="numeric" format="{0:n0}" />
        <Column field="TotalWeight" title="Weight" filter="numeric" format="{0:n0}" />
        <Column field="Total" title="Charges" filter="numeric" format="{0:c}" />
        <Column field="VehicleType" title="Type" />
      </Grid>
    </Window>
  }

  private searchQuotes(values: { [name: string]: any; }) {
    this.setState({ isLoadingSearchData: true });
    const data = {
      ...values,
      BookUserID: values.BookUserID?.ID || null,
      VehicleTypeID: values.VehicleTypeID?.ID || null,
    }
    fetchApi("/api/Quote/Search", data, "POST")
      .then((response: { Quotes: QuoteSearchValue[] }) => {
        this.setState({ isLoadingSearchData: false, searchData: response.Quotes });
      })
  }
}