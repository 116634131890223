import { fetchApi } from '../services/api';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ApiLink } from 'TypeGen/api-link';
import { ProblemDetails } from './ProblemDetails';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAccountNotification {
  Text: string;
  Link: string;
  PopupLink: string;
  CreatedDateTime: Date;
  Links: ApiLink[];
};

export interface AccountNotificationState {
  accountNotifications: IAccountNotification[];
  newVersion: boolean;
  isLoading: boolean;
  problem: ProblemDetails | null;
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAccountNotificationAction {
  type: 'REQUEST_ACCOUNT_NOTIFICATIONS';
}

interface ReceiveAccountNotificationAction {
  type: 'RECEIVE_ACCOUNT_NOTIFICATIONS';
  accountNotifications: IAccountNotification[];
}

interface NewVersionAction {
  type: 'NEW_VERSION';
}

interface ProblemDetailsAction {
  type: 'PROBLEM_DETAILS';
  problem: ProblemDetails | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAccountNotificationAction | ReceiveAccountNotificationAction | NewVersionAction | ProblemDetailsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestAccountNotifications: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    if (getState().accountNotifications) {
        let fetchTask = fetchApi('/api/Account/Notifications')
          .then((data: { Notifications: IAccountNotification[]}) => {
              dispatch({ type: 'RECEIVE_ACCOUNT_NOTIFICATIONS', accountNotifications: data.Notifications });
            });

        dispatch({ type: 'REQUEST_ACCOUNT_NOTIFICATIONS' });
    }
  },
  dismissProblem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'PROBLEM_DETAILS', problem: null });
  }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AccountNotificationState = { accountNotifications: [], newVersion: false, isLoading: false, problem: null };

export const reducer: Reducer<AccountNotificationState> = (state: AccountNotificationState, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_ACCOUNT_NOTIFICATIONS':
      return {
        ...state,
        isLoading: true
      };
    case 'RECEIVE_ACCOUNT_NOTIFICATIONS':
      return {
        ...state,
        accountNotifications: action.accountNotifications,
        isLoading: false
      };
    case 'NEW_VERSION':
      return {
        ...state,
        newVersion: true,
      }
    case 'PROBLEM_DETAILS':
      return {
        ...state,
        problem: action.problem
      }
    default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        const exhaustiveCheck: never = action;
  }

  return state || unloadedState;
};
