import { useResizeDetector } from 'react-resize-detector';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import * as React from 'react';

type Props = {
  lnglats: TrimbleMaps.LngLatLike[];
  style?: React.CSSProperties
};

const RouteMap = (props: Props) => {

  const map = React.useRef<TrimbleMaps.Map>(null);
  const routingLayer = React.useRef<TrimbleMaps.Route>(null);
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [mapStyleLoaded, setMapStyleLoaded] = React.useState(false);

  const onResize = React.useCallback(() => {
    if (map.current) {
      map.current.resize();
    }
  }, []);

  const { ref } = useResizeDetector({ onResize });

  const renderMap = React.useCallback((lnglats: TrimbleMaps.LngLatLike[]) => {
    if (!routingLayer.current) {
      routingLayer.current = new TrimbleMaps.Route({
        routeId: "ComponentRoute",
      });
    }

    // Map style isn't loaded yet
    if (!map.current.isStyleLoaded()) {
      // Retry after a second
      setTimeout(() => {
        renderMap(lnglats);
      }, 100);
      return;
    }

    if (!map.current.getLayer("route-layer-ComponentRoute")) {
      routingLayer.current.addTo(map.current);
    }

    // Add/Update Route
    routingLayer.current.update({
      stops: lnglats
    });
  }, [map, routingLayer]);

  React.useEffect(() => {
    if (map.current && mapLoaded && mapStyleLoaded) {
      if (props.lnglats.length > 1) {
        renderMap(props.lnglats);
      } else if (routingLayer.current) {
        routingLayer.current.remove();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapLoaded, mapStyleLoaded, JSON.stringify(props.lnglats), renderMap])

  React.useEffect(() => {
    const style = localStorage["darkmode"] === 'false' ? TrimbleMaps.Common.Style.TRANSPORTATION : TrimbleMaps.Common.Style.TRANSPORTATION_DARK;
    map.current = new TrimbleMaps.Map({
      container: "component-map",
      style: style,
      center: [-83.3083,42.2079],
      zoom: 12,
      scrollZoom: false,
      trackResize: false,
      attributionControl: false,
    });

    var navControl = new TrimbleMaps.NavigationControl();
    map.current.addControl(navControl, 'top-left');
    map.current.setDarkMode(style === TrimbleMaps.Common.Style.TRANSPORTATION_DARK);

    map.current.on('load', () => {
      setMapLoaded(true);
      map.current.setWeatherRadarVisibility(true);
    });

    map.current.on('style.load', () => {
      setMapStyleLoaded(true);
    })

    return () => {
      map.current.remove();
    }
  }, [renderMap]);

  return <div ref={ref} id="component-map" style={{ width: '100%', height: '100%', ...props.style }} />;
}

export default RouteMap;
