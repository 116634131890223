import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatPhone } from '../../utils/utils';

export default class DriverContactInfo extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;
        return (
            <td>
                <a
                    href={"mailto:" + this.props.dataItem.Email}
                    style={{ color: '#007bff' }}
                >{this.props.dataItem.Email}
                </a>
                <br />
                <a href={`tel:${this.props.dataItem.Mobile}`}>{formatPhone(this.props.dataItem.Mobile)}</a>
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
