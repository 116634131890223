import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { OwnerNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent } from "@progress/kendo-react-grid";
import DateCell from "../../components/cells/DateCell";
import YesNoCell from "../../components/cells/YesNoCell";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

type RouteComponentParams = {
  ownerId: string;
};

type DriverDeviceType = {
  DriverAppDeviceID: string;
  CreatedDateTime: Date;
  PhoneNumber: string;
  Activated: boolean;
  ActivationCode: number | null;
  DeviceName: string;
  IsOutdated: boolean;
  HasPushNotifications: boolean;
  LastRefreshDateTime: Date | null;
}

const OwnerDevices = () => {

  const { ownerId: ownerIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<DriverDeviceType[]>();
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'CreatedDateTime', dir: 'desc' }]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/OwnerDevices/${ownerIdParam}`)
      .then((data: { Devices: DriverDeviceType[] }) => {
        setLoading(false);
        setDevices(data.Devices);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [ownerIdParam]);

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setSort(event.sort);
  };

  useEffect(() => {
    refresh();
  }, [ownerIdParam, refresh]);

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <Grid
      sortable
      sort={sort}
      data={orderBy(devices, sort)}
      onSortChange={onGridSortChange}
      dataItemKey="DriverAppDeviceID"
    >
      <GridNoRecords>
          {loading == false && <span>No Devices Found</span>}
      </GridNoRecords>
      <Column field="CreatedDateTime" title="Created" cell={DateCell} />
      <Column field="PhoneNumber" title="Cell #" />
      <Column field="Activated" title="Activated" cell={(e) => <td>{e.dataItem.Activated ? 'Yes' : e.dataItem.ActivationCode}</td>} />
      <Column field="DeviceName" title="Device Name" />
      <Column field="IsOutdated" title="Old Version" cell={(e) => <td title={e.dataItem.AppVersion}>{e.dataItem.IsOutdated ? 'Yes' : 'No'}</td>} />
      <Column field="HasPushNotifications" title="Notifications" cell={YesNoCell} />
      <Column field="LastRefreshDateTime" title="Last Opened" cell={DateCell} />
    </Grid>

  return (<>
    <OwnerNavBar id={ownerIdParam} currentTab="Devices" />
    <Title string="Devices" />
    <br />
    {loading || !devices ? loadingView : dataView()}
  </>);
}

export default OwnerDevices;