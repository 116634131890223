import { useCallback, useEffect, useState } from 'react';
import { Title } from '../../utils/title';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Button, Chip } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridColumnMenuFilter, GridColumnProps, GridDataStateChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import { fetchApi } from 'services/api';
import ColumnMenu from 'views/LoadBoard/BidHistory/ColumnMenu';
import DateCell from 'components/cells/DateCell';
import { useParams } from 'react-router';
import { quoteIDCell } from 'views/Quote/Recent';
import { CarrierNavBar } from './CarrierNavBar';
import { arrowRotateCwIcon, checkIcon, filterClearIcon, starIcon, xIcon } from '@progress/kendo-svg-icons';
import { QuoteOfferStatus } from 'TypeGen/quote-offer-status';

type RouteComponentParams = {
  carrierId: string;
};


type ViewModel = {
  QuoteOfferID: number;
  QuoteID: number;
  CarrierID: number;
  CarrierContactID: number | null;
  PickupCity: string;
  PickupState: string;
  PickupDateTime: Date;
  PeliveryCity: string;
  PeliveryState: string;
  PeliveryDateTime: Date;
  TotalPieces: number;
  TotalWeight: number;
  VehicleType: string;
  Distance: number;
  OfferDateTime: Date;
  ResponseDateTime?: Date;
  AllInRate: number;
  Confirmed: boolean;
  Awarded: boolean;
  CarrierVehicleNumber: string;
  EmailAddress: string;
  Status: QuoteOfferStatus;
}

const Bids = () => {
  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [data, setData] = useState<ViewModel[]>([]);
  const [total, setTotal] = useState(0);
  const [filterStatus, setFilterStatus] = useState<QuoteOfferStatus | null>(null);
  const [dataState, setDataState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 50,
    sort: [{
      field: "ResponseDateTime",
      dir: "desc"
    }],
    filter: { logic: 'and', filters: [] }
  });

  const refreshList = useCallback(() => {
    const queryStr = `${toDataSourceRequestString(dataState)}`;

    fetchApi(`/api/Asset/CarrierBids/${carrierIdParam}?${queryStr}`, { Status: filterStatus }, 'POST')
      .then(({ Data, Total }: { Data: ViewModel[], Total: number }) => {
        setData(Data);
        setTotal(Total);
      });
    }, [carrierIdParam, filterStatus, dataState])

    useEffect(() => {
      refreshList();
    }, [refreshList, filterStatus, dataState]);

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  }

  const columnProps = (field: string, headerClassName: string = ''): Partial<GridColumnProps> =>{
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: isColumnActive(field, dataState) ? 'active ' + headerClassName : headerClassName
    };
  }

  const isColumnActive = (field: string, dataState: DataSourceRequestState) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  const statusCell = (props: GridCellProps) => {
    switch (props.dataItem.Status) {
      case 1:
        return <td><Chip text="Declined" themeColor="error" /></td>;
      case 2:
        return <td>
          <Chip
            themeColor="success"
            icon={props.dataItem.Awarded ? 'star' : props.dataItem.Confirmed ? 'check' : undefined}
            svgIcon={props.dataItem.Awarded ? starIcon : props.dataItem.Confirmed ? checkIcon : undefined}
            text={formatNumber(props.dataItem.AllInRate, 'c0')}
          />
        </td>;
      case 5:
        return <td title='Withdrawn'>
          <Chip
            themeColor="warning"
            svgIcon={xIcon}
            text={formatNumber(props.dataItem.AllInRate, 'c0')}
          />
        </td>;
      case 6:
        return <td><Chip text="Retracted" themeColor="error" /></td>;
      default:
        return <td>N/A</td>;
    }
  }

  return <>
    <CarrierNavBar id={carrierIdParam} currentTab="Bids" />
    <Title string="Bids" />
    <br />
    <Grid
      size="small"
      reorderable
      dataItemKey="QuoteOfferID"
      sortable={{ allowUnsort: true }}
      pageable={{ pageSizes: [50, 100, 500] }}
      scrollable={'none'}
      onDataStateChange={onDataStateChange}
      data={data}
      total={total}
      {...dataState}
    >
      <GridToolbar>
        <Button
          icon="filter-clear"
          svgIcon={filterClearIcon}
          title="Clear Filters and Sort"
          onClick={() => setDataState({
            skip: 0,
            take: 50,
            sort: [{
              field: "ResponseDateTime",
              dir: "desc"
            }],
            filter: { logic: 'and', filters: [] }
          })}
        />
        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          title="Refresh"
          onClick={refreshList}
        />
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Accepted ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Accepted ? null : QuoteOfferStatus.Accepted)}
        >
          Bid
        </Button>
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Declined ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Declined ? null : QuoteOfferStatus.Declined)}
        >
          Declined
        </Button>
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Retracted ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Retracted ? null : QuoteOfferStatus.Retracted)}
        >
          Retracted
        </Button>
      </GridToolbar>
      <GridNoRecords>
        No Bids Found
      </GridNoRecords>
      <Column {...columnProps("QuoteID")} title="Quote" filter="numeric" cell={quoteIDCell()} />
      <Column {...columnProps("PickupCity")} title="Pickup" />
      <Column {...columnProps("PickupDateTime")} title="PU Date" filter="date" cell={DateCell} />
      <Column {...columnProps("DeliveryCity")} title="Delivery" />
      <Column {...columnProps("DeliveryDateTime")} title="DEL Date" filter="date" cell={DateCell} />
      <Column
        {...columnProps("TotalPieces", 'align-right')}
        title="Pieces"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.TotalPieces, 'n0')}</td>}
      />
      <Column {...columnProps("TotalWeight")} title="Weight" filter="numeric" format="{0:n0} lbs" />
      <Column
        {...columnProps("Distance", 'align-right')}
        title="Miles"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.Distance, 'n0')}</td>}
      />
      <Column {...columnProps("VehicleType")} title="Equipment" />
      <Column {...columnProps("CarrierVehicleNumber")} title="Matched" />
      <Column {...columnProps("ResponseDateTime")} title="Bid Date" filter="date" cell={DateCell} />
      <Column {...columnProps("EMailAddress")} title="Bid By" />
      <Column {...columnProps("AllInRate")} title="All-In Rate" filter="numeric" cell={statusCell} />
    </Grid>
  </>
}

export default Bids;