import * as React from 'react';
import { Title } from '../../utils/title';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../services/api';
import { Input } from '@progress/kendo-react-inputs';
import { JsonResponse } from 'TypeGen/json-response';

interface Settings {
  Setup: boolean;
  UserNumber: string;
}

interface Props {}
interface State {
  loading: boolean;
  settings: null|Settings;
  UserPassword: string;
}

export default class SylectusLogin extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.saveSettings = this.saveSettings.bind(this);
    this.state = {
      loading: true,
      settings: null,
      UserPassword: '',
    };
  }

  public componentDidMount() {
    fetchApi('/api/Account/AddSylectusLogin')
    .then((response) => {
      this.setState({
          loading: false,
          settings: response,
      });
    })
    .catch(() => {
        alert('Unable to add Sylectus Login!');
    });
}

  public render() {
    if (this.state.loading || this.state.settings === null) {
      return (
        <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%' }}
        />
      );
    }

    return (
      <div className="container card-block k-form k-form-md">
        <Title string="Sylectus Login" />
        <div className="row">
          <div className="col">
            {this.state.settings.Setup && <div className="alert alert-info" role="alert">
              <h4 className="alert-heading">Login Saved</h4>
              Please update it here if you change it in Sylectus.
            </div>}
            <legend>Sylectus Login</legend>
            <div className="k-form-field">
              <span>Your Sylectus User</span>
              <p className="k-static-form-label">
                {this.state.settings.UserNumber}
              </p>
            </div>
            <div className="k-form-field">
              <span>Your Sylectus Password</span>
              <Input type="password" placeholder="Password" onChange={(event) => {
                  this.setState({ UserPassword: event.value });
                }}
              />
            </div>
          </div>
        </div>

        <Button onClick={this.saveSettings}>Save Sylectus Login</Button>
      </div>
    );
  }

  private setSettings(value: Partial<Settings>) {
    const settings = Object.assign({}, this.state.settings, value);
    this.setState({ settings });
  }

  private saveSettings() {

    if (!this.state.UserPassword) {
      alert('Please enter your Sylectus Password!')
    }

    this.setState({ loading: true });
    const data = {
      UserPassword: this.state.UserPassword
    }
    fetchApi('/api/Account/AddSylectusLogin', data, 'post')
    .then((response: JsonResponse) => {
      this.setState({ loading: false });
      if (response.Success) {
        this.setSettings({ Setup: true });
      } else {
        alert(response.ErrorMessage);
      }
    });
  }
}
