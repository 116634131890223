import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn as Column, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import moment from "moment-timezone";
import * as React from 'react';
import { fetchApi } from 'services/api';
import { ApiLink } from 'TypeGen/api-link';
import { RightAlignHeaderCell } from "../../components/cells/RightAlignHeaderCell";
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDateRangePicker from "../../components/SimpleDateRangePicker";
import { IDName } from "../../types/idname";
import { Title } from '../../utils/title';
import BillingUserMetricInvoiceListingDialog from "./BillingUserMetricInvoiceListingDialog";

interface BillingUserMetricViewModel {
  UserId: number;
  AssetsCount: number;
  BrokerageCount: number;
  NoPay_CrossDockCount: number;
  CreditMemosCount: number;
  UpdatedBillToCount: number;
  CreatedDeadheadTripCount: number;
  PayWithOrderCount: number;
  FullName: string;
  Links: ApiLink[];
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

const BillingUserMetrics = () => {
  const _export = React.useRef<ExcelExport | null>(null);

  const [loading, setLoading] = React.useState(true);

  const [userMetrics, setBillingUserMetrics] = React.useState<BillingUserMetricViewModel[]>([]);
  const [data, setData] = React.useState<BillingUserMetricViewModel[]>();

  const [sort, setSort] = React.useState<Array<SortDescriptor>>([
    { field: "FullName", dir: "asc" },
  ]);
  const [filter, setFilter] = React.useState(initialFilter);

  const [fromDate, setFromDate] = React.useState(moment.tz("America/New_York").startOf('week').toDate());
  const [toDate, setToDate] = React.useState(moment.tz("America/New_York").endOf('week').startOf('day').toDate());

  const [users, setUsers] = React.useState<IDName[]>();
  const [selectedUser, setSelectedUser] = React.useState<IDName[]>();

  const [showInvoiceListingDialog, setShowInvoiceListingDialog] = React.useState<ApiLink>();

  const filterData = (filterByUserId: number | null) => {
    let filter = {
      logic: "and",
      filters: [],
    } as CompositeFilterDescriptor;

    if (filterByUserId > 0)
      filter.filters.push({ field: "UserId", operator: "eq", value: filterByUserId });

    setFilter(filter);
    setData(filterBy(orderBy(userMetrics, sort), filter));
  };

  const sortChange = (event: GridSortChangeEvent) => {
    setData(filterBy(orderBy(userMetrics, event.sort), filter));
    setSort(event.sort);
  };

  const fetchDashboard = React.useCallback(() => {
    if (!fromDate || !toDate) return;
    setLoading(true);
    const data = {
      FromDate: fromDate,
      ToDate: toDate
    }
    fetchApi('/api/Billing/BillingUserMetrics', data, 'POST')
      .then((response: any) => {
        setBillingUserMetrics(response.BillingUserMetrics);
        setUsers(response.Users);
      })
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);

  React.useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  React.useEffect(() => {
    setData(filterBy(orderBy(userMetrics, sort), filter));
  }, [userMetrics, filter, sort]);

  const CreditMemoCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: BillingUserMetricViewModel = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style} className="text-right">
        <div>
          <a href="#" style={{ color: '#007bff' }}
            onClick={(e) => { e.preventDefault(); setShowInvoiceListingDialog(dataItem.Links.find(x => x.Name === 'UserCreditMemoInvoices')); }}>{dataItem.CreditMemosCount}
          </a>
        </div>
      </td>
    );
  };

  const UpdatedBillToCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: BillingUserMetricViewModel = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style} className="text-right">
        <div>
          <a href="#" style={{ color: '#007bff' }}
            onClick={(e) => { e.preventDefault(); setShowInvoiceListingDialog(dataItem.Links.find(x => x.Name === 'UserBillToUpdatedInvoices')); }}>{dataItem.UpdatedBillToCount}
          </a>
        </div>
      </td>
    );
  };

  return <React.Fragment>
    <Title string="User Billing Metrics" />
    <br />
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <div className="row">
      <div className="col-auto">
        <SimpleDateRangePicker
          inputProps={{ disabled: loading }}
          value={{ start: fromDate, end: toDate }}
          onChange={(e) => {
            setFromDate(e.start);
            setToDate(e.end);
          }}
        />
      </div>
      <div className="col-auto pl-0">
        <ComboBox
          data={users}
          textField="Name"
          dataItemKey="ID"
          value={selectedUser}
          onChange={(e) => {
            setSelectedUser(e.target.value);
            filterData(e.target.value?.ID ?? null);
          }}
          placeholder="Select User"
          style={{ width: "350px" }}
        />
      </div>
    </div>
    <ExcelExport ref={_export} fileName={`Billing User Metrics.xlsx`}>
      <ExportColumn field="FullName" title="Full Name" />
      <ExportColumn field="AssetsCount" title="Assets Count" />
      <ExportColumn field="BrokerageCount" title="Brokerage Count" />
      <ExportColumn field="NoPay_CrossDockCount" title="No Pay Cross Dock Count" />
      <ExportColumn field="CreditMemosCount" title="Credit Memos Count" />
      <ExportColumn field="UpdatedBillToCount" title="Updated Bill To Count" />
      <ExportColumn field="CreatedDeadheadTripCount" title="Created Deadhead Trip Count" />
      <ExportColumn field="PayWithOrderCount" title="Pay With Order Count" />
    </ExcelExport>
    <Grid
      data={data}
      sortable={{
        allowUnsort: true,
        mode: "multiple"
      }}
      sort={sort}
      onSortChange={sortChange}
      className="mt-2"
    >
      <GridToolbar>
        <Button onClick={() => _export.current.save(data)}>Excel</Button>
      </GridToolbar>
      <Column field="FullName" title="User Name" />
      <Column field="AssetsCount" title="Asset RTP Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="BrokerageCount" title="Brokerage RTP Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="NoPay_CrossDockCount" title="Cross Dock Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="CreditMemosCount" title="Credit Memos Count" headerCell={RightAlignHeaderCell} cell={CreditMemoCell} />
      <Column field="UpdatedBillToCount" title="Updated Bill-To Count" headerCell={RightAlignHeaderCell} cell={UpdatedBillToCell} />
      <Column field="CreatedDeadheadTripCount" title="Deadhead Count" className="text-right" headerCell={RightAlignHeaderCell} />
      <Column field="PayWithOrderCount" title="Pay RTP Count" className="text-right" headerCell={RightAlignHeaderCell} />
    </Grid>
    {showInvoiceListingDialog && <BillingUserMetricInvoiceListingDialog Link={showInvoiceListingDialog} CloseDialog={() => setShowInvoiceListingDialog(null)} />}
  </React.Fragment>;
}

export default BillingUserMetrics;
