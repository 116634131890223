import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import { ApiLink } from 'TypeGen/api-link';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { Grid, GridColumn as Column, GridNoRecords, GridCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';

type Props = {
    Link: ApiLink;
    SelectAccessorial?: (RateDescriptionID: number, Rate: number) => void;
    CloseDialog: () => void;
}

type AccessorialRate = {
    Description: string;
    RateDescriptionID: number;
    Break01: number;
    Break02: number;
    Break03: number;
    Break04: number;
    Break15: number;
}

type Response = {
    CustomerNumber: string;
    Rates: AccessorialRate[];
}

const CustomerAccessorialRatesPopup = ({ Link, SelectAccessorial, CloseDialog }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [customerNumber, setCustomerNumber] = useState<string>("Customer");
    const [accessorialRates, setAccessorialRates] = useState<AccessorialRate[]>([]);

    useEffect(() => {
        fetchApi(Link.Link, {}, Link.Method)
            .then((response: Response) => {
                setLoading(false);
                setCustomerNumber(response.CustomerNumber);
                setAccessorialRates(response.Rates);
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to load");
            });
    }, [Link]);

    const ClickableCell = (props: GridCellProps) => {
        return SelectAccessorial
            ? <td>
                <a href="#" style={{ color: '#007bff' }} onClick={(e) => { e.preventDefault(); SelectAccessorial(props.dataItem.RateDescriptionID, props.dataItem[props.field])} }>
                    {formatNumber(props.dataItem[props.field], 'c')}
                </a>
            </td>
            : <td>
                {formatNumber(props.dataItem[props.field], 'c')}
            </td>;
    }

    return <Dialog title={`${customerNumber} Accessorial Rates`} onClose={CloseDialog} className='dialog-w800'>
        {loading && <GridLoadingPanel />}
        <Grid
            data={accessorialRates}
            scrollable='none'
        >
            <GridNoRecords>No Accessorials Rates By Vehicle Type Found</GridNoRecords>
            <Column field="Description" title="Charge Type" />
            <Column field="Break01" title="CARGO VAN" cell={ClickableCell} />
            <Column field="Break15" title="SPRINTER" cell={ClickableCell} />
            <Column field="Break02" title="SMALL STRAIGHT" cell={ClickableCell} />
            <Column field="Break03" title="LARGE STRAIGHT" cell={ClickableCell} />
            <Column field="Break04" title="TRACTOR" cell={ClickableCell} />
        </Grid>
    </Dialog>
}

export default CustomerAccessorialRatesPopup;
