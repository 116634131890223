import * as React from 'react';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { Grid, GridColumn as Column, GridToolbar, GridCell, GridCellProps, GridNoRecords } from '@progress/kendo-react-grid';
import { ILink } from '../../../types/link';
import { InformedCell } from './InformedCell';
import { OccurredCell } from './OccurredCell';
import { ContactCell } from './ContactCell';
import { openWindow } from '../../../services/openWindow';
import UpdateWebPortal from './UpdateWebPortal';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';

type Props = { orderId: number; };

type State = {
  loading: boolean;
  portalPopup: ILink | null;
  filter: FetchFilter;
  notifications: EventNotification[];
}

type FetchFilter = {
  IsInformed?: boolean,
  HasOccurred?: boolean
}

type EventNotification = {
    OrderEventNotificationID: number;
    CustomerNumber: string;
    ContactName: string;
    Comment: string;
    EventType: string;
    HasOccurred: boolean;
    OccurredDateTime?: Date;
    NotificationMethod: string;
    IsInformed: boolean;
    InformedDateTime?: Date;
    InformedBy: string;
    Hash: string;
    Links: ILink[];
}

type GetEventNotificationsResponse = {
    Notifications: EventNotification[]
}

export class TrackEventNotifications extends React.Component<Props, State> {

  MarkInformedCell: typeof React.Component;
  WebPortalCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      portalPopup: null,
      filter: {
        HasOccurred: true
      },
      notifications: []
    }

    this.fetch = this.fetch.bind(this);

    const openWebPortal = this.openWebPortal.bind(this);
    const editWebPortal = this.editWebPortal.bind(this);
    const markInformed = this.markInformed.bind(this);

    class WebPortalCell extends React.Component<GridCellProps> {
      public render() {
        const link = (this.props.dataItem as EventNotification).Links.find(x => x.Name == "WebPortal");
        return <td>
            {link && <div className="btn-group">
              <button
                className="btn btn-sm btn-primary"
                onClick={(e) => openWebPortal(link)}
              >
                Portal
              </button>
              <button type="button" className="btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Dropdown</span>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" onClick={(e) => editWebPortal(link)}>Edit</a>
              </div>
            </div>}
          </td>
      }
    }

    this.WebPortalCell = WebPortalCell;

    class MarkInformedCell extends React.Component<GridCellProps> {
      public render() {
        const link = (this.props.dataItem as EventNotification).Links.find(x => x.Name == "MarkInformed");
        return <td>
            {link && <button
              className="btn btn-sm btn-primary"
              onClick={(e) => markInformed(link, this.props.dataItem.OrderEventNotificationID)}
            >
              Mark Informed
            </button>}
          </td>
      }
    }

    this.MarkInformedCell = MarkInformedCell;
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <LoadingPanel />
    }
    return <React.Fragment>
        {this.state.portalPopup && <UpdateWebPortal 
          link={this.state.portalPopup}
          done={() => this.setState({ portalPopup: null })}
        />}
        <Grid data={this.state.notifications}>
          <GridToolbar>
            <Button
              togglable
              selected={this.state.filter.IsInformed === undefined}
              onClick={() => this.setState(prevState => ({ filter: prevState.filter.IsInformed === undefined ? { IsInformed: false, HasOccurred: true } : { HasOccurred: true }}), () => this.fetch())}
            >Informed
            </Button>
          </GridToolbar>
          <GridNoRecords>
            <p>No Event Notifications Found</p>
          </GridNoRecords>
          <Column field="CustomerNumber" title="Customer" />
          <Column field="ContactName" title="Contact" cell={ContactCell} />
          <Column field="EventType" title="Event" />
          <Column field="HasOccurred" title="Occurred" cell={OccurredCell} />
          <Column field="IsInformed" title="Informed" cell={InformedCell} />
          <Column field="NotificationMethod" title="Method" />
          <Column field="InformedBy" title="By" />
          <Column cell={this.WebPortalCell} title="Web" width="120px" />
          <Column cell={this.MarkInformedCell} title="Inform" width="120px" />
        </Grid>
    </React.Fragment>
  }

  private fetch() {
    const data = {
      ...this.state.filter,
      OrderID: this.props.orderId,
    }
    fetchApi('/api/Track/OrderEventNotifications', data, 'POST')
      .then((response: GetEventNotificationsResponse) => {
        this.setState({ loading: false, notifications: response.Notifications });
      })
  }

  private openWebPortal(link: ILink) {
    fetchApi(link.Link)
      .then((response: { CustomerWebPortalID: string, WebPortalUrl: string }) => {
        if (response.WebPortalUrl) {
          openWindow(response.WebPortalUrl)
        } else {
          this.editWebPortal(link);
        }
      });
  }

  private editWebPortal(link: ILink) {
    this.setState({ portalPopup: link });
  }

  private markInformed(link: ILink, orderEventNotificationId: number) {
    const data = {
      OrderEventNotificationID: orderEventNotificationId,
      Comment: ''
    }
    fetchApi(link.Link, data, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          alert("Successfully marked as informed!");

          // TODO: Update state?
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert("Unknown Error Occurred!");
      });
  }
}
