import React, { useCallback, useEffect, useState } from "react";
import { formatNumber } from '@progress/kendo-intl';
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import Moment from 'moment-timezone';
import { Title } from '../../utils/title';
import { fetchApi } from "../../services/api";
import { ViewModel } from "TypeGen/Trip/Summary/view-model";
import CenterDivPanel from "components/CenterDivPanel";
import RateEditable from "views/Order/Rate/Editable";
import PayEditable from "views/Order/Planning/PayEditable";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import { Link } from "react-router-dom";
import { dateFormatter, dateWindowFormatter } from "utils/utils";
import { SvgIcon } from "@progress/kendo-react-common";
import { infoCircleIcon } from "@progress/kendo-svg-icons";
import { Tooltip } from "@progress/kendo-react-tooltip";

type Props = {
  orderId: number;
  close: () => void;
}

const TripSummary = ({ orderId, close }: Props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ViewModel>();

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Trip/Summary/${orderId}`)
      .then((data: ViewModel) => {
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    refresh();
  }, [orderId, refresh]);

  const dataView = () => {
    if (loading || !data) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return <div>
      <div className="row">
        {data.VehicleNumber && <div className="col-md-4">
          Vehicle: {data.VehicleNumber}
        </div>}
        {data.DriverNumbers && <div className="col-md-4">
          Driver: {data.DriverNumbers}
        </div>}
        {data.CarrierName && <div className="col-md-4">
          Carrier: {data.CarrierName}
        </div>}
      </div>
      <div className="row mt-2">
        <div className="col-md-4">
          Bill To: {data.BillTo.CustomerName}<br />
          {data.BillTo.AddressLine1}<br />
          {data.BillTo.AddressLine2 && <>{data.BillTo.AddressLine2}<br /></>}
          {data.BillTo.CityStateZip}<br />
          Terms: Third Party
        </div>
        <div className="col-md-4">
          Authorization 1: {data.ReferenceNumber1}<br />
          Authorization 2: {data.ReferenceNumber2}<br />
          Authorization 3: {data.ReferenceNumber3}<br />
          Vehicle Size: {data.BilledVehicleTypeName}
        </div>
        <div className="col-md-4">
          Distance: {formatNumber(data.TotalDistance, 'n0')} mi<br />
          Stops: {data.StopCount}
        </div>
      </div>
      {data.Stops.map((stop) => <div className="row mt-2" key={stop.OrderStopID}>
        <div className="col-md-4">
          {stop.Sequence}. {stop.Event == 1 ? "Pick-Up" : "Deliver"}<br />
          {stop.Customer.CustomerName}<br />
          {stop.Customer.AddressLine1 && <>{stop.Customer.AddressLine1}<br /></>}
          {stop.Customer.AddressLine2 && <>{stop.Customer.AddressLine2}<br /></>}
          {stop.Customer.CityStateZip}<br />
          Distance: {formatNumber(stop.DistanceFromPreviousStop, 'n0')} mi
        </div>
        <div className="col-md-4">
          <br />
          Scheduled: {dateWindowFormatter(stop.ScheduledDateTime, stop.LatestStopDateTime)} EST<br />
          {stop.OriginalScheduledDateTime && <>Original: {dateFormatter(stop.OriginalScheduledDateTime)} EST<br /></>}
          {stop.ReasonLate && <>Reason: {stop.ReasonLate}<br /></>}
          {stop.ArrivalDateTime
            ? <Tooltip>IN: <u title={`${stop.MinutesLate > 0 ? `Late: ${Math.abs(stop.MinutesLate)} mins.` : stop.MinutesLate < 0 ? `Early: ${Math.abs(stop.MinutesLate)} mins.` : 'On Time'}`}>
                {dateFormatter(stop.ArrivalDateTime)} EST
              </u></Tooltip>
            : <>IN:<br /></>}
          {stop.DepartDateTime
            ? <Tooltip>OUT: <u title={`Stopped: ${Moment(stop.DepartDateTime).diff(stop.ArrivalDateTime, 'minutes')} mins.`}>
                {dateFormatter(stop.DepartDateTime)} EST
              </u></Tooltip>
            : <>OUT:<br /></>}
        </div>
        <div className="col-md-4">
          <br />
          {stop.Freight.map((freight) => <React.Fragment key={freight.OrderFreightDetailID}>
            {stop.Event == 1 && <>
              Pieces: {formatNumber(freight.Pieces, 'n0')} {stop.OrderStopStatus == 120 ? ' / ' + formatNumber(freight.LoadedPieces, 'n0') : ''}<br />
              Weight: {formatNumber(freight.Weight, 'n0')} {freight.WeightUnitOfMeasure === 1 ? 'lbs' : 'kgs'} {stop.OrderStopStatus == 120 ? ' / ' + formatNumber(freight.LoadedWeight, 'n0') + ' ' + (freight.LoadedWeightUnitOfMeasure === 1 ? 'lbs' : 'kgs') : ''}<br />
              Dims (LxWxH): {formatNumber(freight.Length, 'n0')}x{formatNumber(freight.Width, 'n0')}x{formatNumber(freight.Height, 'n0')} {freight.DimsUnitOfMeasure === 2 ? 'in' : 'ft'}<br />
              Stackable ?: {freight.Stackable ? 'Yes' : 'No'}
            </>}
            {stop.Event == 2 && <>
              BOL: {freight.BillOfLadingNumber}<br />
              POD: {freight.ProofOfDeliverySignedBy}<br />
              Value: {formatNumber(freight.FreightValue, 'c2')}
            </>}
          </React.Fragment>)}
        </div>
      </div>)}
      <div className="row mt-2">
        <div className="col-md-4">
          Called in by: {data.AuthenticationCustomerContactName}<br />
          From Company: {data.AuthorizationCustomerName}<br />
          {data.SalespersonName && <>Sales Person: {data.SalespersonName}</>}
        </div>
        <div className="col-md-4 offset-md-4">
          Total Pieces: {formatNumber(data.TotalPieces, 'n0')}<br />
          Total Weight: {formatNumber(data.TotalWeight, 'n0')} lbs
        </div>
      </div>
      {data.CurrentRate && data.CurrentPay && <div className="row mt-2">
        <div className="col-md-4">
          Revenue: {formatNumber(data.CurrentRate.Total, 'c2')}
        </div>
        <div className="col-md-4">
          Costs: {formatNumber(data.CurrentPay.Total, 'c2')}
        </div>
        <div className="col-md-4">
          Gross Margin: {formatNumber(data.CurrentRate.Total - data.CurrentPay.Total, 'c2')} ({formatNumber((data.CurrentRate.Total - data.CurrentPay.Total) / data.CurrentRate.Total, 'p1')})
        </div>
      </div>}
      {data.CurrentRate && <RateEditable
        editable={false}
        data={data.CurrentRate}
        className="mt-2"
      />}
      {data.TripStops.length > 0 && <Grid data={data.TripStops} scrollable="none" className="mt-2">
        <Column field="Sequence" title="Trip Stop" />
        <Column field="OrderNumber" title="Order Number" />
        <Column field="EventName" title="Event" cell={(props) => {
          return <td>{props.dataItem.OrderStopSequence}-{props.dataItem.EventName}</td>
        }} />
        <Column field="CityStateZip" title="Location" />
        <Column field="ScheduledDateTime" title="Scheduled" cell={DateCell} />
        <Column field="DistanceFromPreviousStop" title="Distance" format="{0:n0} mi" />
        <Column field="StopStatusName" title="Status" />
      </Grid>}
      {data.CurrentPay && <PayEditable
        editable={false}
        data={data.CurrentPay}
        className="mt-2"
      />}
      {data.StopNotes.length > 0 && <Grid data={data.StopNotes} scrollable="none" className="mt-2">
        <Column field="StopSequence" title="Stop #" />
        <Column field="Note" title="Stop Note" cell={(props) => {
          return <td>
            {props.dataItem.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
            {props.dataItem.Note}
          </td>;
        }} />
        <Column field="Sharing" cell={(props) => {
          const sharingArray = Array.from([(props.dataItem.CarrierVisible ? 'Carrier' : ''), (props.dataItem.CustomerVisible ? 'Customer' : ''), (props.dataItem.DriverVisible ? 'Driver' : '')]).filter(Boolean);
          if (sharingArray.length === 0) sharingArray.push('None');
          return <td>{sharingArray.join(', ')}</td>;
        }} />
        <Column field="ModifiedByFullName" title="By" />
        <Column field="ModifiedDateTime" title="Date" cell={DateCell} />
      </Grid>
      }
      {data.GeneralNotes.length > 0 && <Grid data={data.GeneralNotes} scrollable="none" className="mt-2">
        <Column field="Note" title="General Note" cell={(props) => {
          return <td>
            {props.dataItem.IsCritical && <SvgIcon icon={infoCircleIcon} className='text-danger mr-1' />}
            {props.dataItem.Note}
          </td>;
        }} />
        <Column field="Sharing" cell={(props) => {
          const sharingArray = Array.from([(props.dataItem.CarrierVisible ? 'Carrier' : ''), (props.dataItem.CustomerVisible ? 'Customer' : ''), (props.dataItem.TurnoverVisible ? 'Turnover' : '')]).filter(Boolean);
          if (sharingArray.length === 0) sharingArray.push('None');
          return <td>{sharingArray.join(', ')}</td>;
        }} />
        <Column field="ModifiedByFullName" title="By" />
        <Column field="ModifiedDateTime" title="Date" cell={DateCell} />
      </Grid>
      }
    </div>;
  }

  return (<Dialog
    className="dialog-w1140"
    title={<Link to={`/Order/${orderId}`}>Trip Summary for Order # {data?.OrderNumber || ''} - (Booked by {data?.BookedByUserName || ''} at {data?.BookedDateTime ? Moment.utc(data.BookedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm") : ''} EST)</Link>}
    onClose={close}
  >
    <Title string="Trip Summary" />
    {dataView()}
  </Dialog>);
}

export default TripSummary;