import { Grid, GridCellProps, GridColumn as Column, GridDetailRowProps, GridExpandChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { EFSCardTransactionHistoryLineItemViewItem, EFSCardTransactionHistoryViewItem } from './DriverCardMaintenance';
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useReducer, useState } from 'react';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import { fetchApi } from '../../../services/api';

type Props = {
    DriverID: number,
    IsGridInFocus?: boolean,
    TABRefreshIteration?: number
}

const GetCategoryIdMappingValue = (Id: string): string => {
    switch (Id) {
        case "ADD": return "ADDITIVES";
        case "AGFL": return "AG FUEL";
        case "AGCD": return "AGRICULTURAL CAR DIESEL";
        case "FARM": return "AGRICULTURAL FUEL";
        case "TOLL": return "AMBASSADOR BRIDGE TOLL";
        case "ANFR": return "ANTI-FREEZE";
        case "AVGS": return "AVIATION GAS";
        case "BDSL": return "BIO DIESEL";
        case "BDSR": return "BIO DIESEL REEFER";
        case "BPRP": return "BOTTLED PROPANE";
        case "BRAK": return "BRAKES AND WHEELS";
        case "CDSL": return "CAR DIESEL";
        case "CADV": return "CASH ADVANCE";
        case "CLTH": return "CLOTHING";
        case "CNG": return "COMPRESSED NATURAL GAS";
        case "DELI": return "DELI FOODS";
        case "DSL1": return "DIESEL #1";
        case "DEF": return "DIESEL EXHAUST FLUID CONTAINER";
        case "DEFD": return "DIESEL EXHAUST FLUID DISPENSED";
        case "ELEC": return "ELECTRONICS";
        case "EUPR": return "ETHANOL UNLEADED PREMIUM";
        case "ETAX": return "EXEMPT TAX AMOUNT";
        case "FAX": return "FAX";
        case "WIFI": return "FLEET WIFI BILLING";
        case "FUEL": return "FUEL";
        case "FURN": return "FURNACE OIL";
        case "GASO": return "GASOHOL";
        case "GROC": return "GROCERIES";
        case "HARD": return "HARDWARE";
        case "HPRO": return "HEATING PROPANE";
        case "IDLE": return "IDLEAIRE";
        case "IDS2": return "INDUSTRIAL DIESEL #2";
        case "IPRO": return "INDUSTRIAL PROPANE";
        case "KERO": return "KEROSENE";
        case "LABR": return "LABOR";
        case "LNG": return "LIQUID NATURAL GAS";
        case "LMPR": return "LUMPER FEE";
        case "MDSL": return "MARKED DIESEL";
        case "MRFR": return "MARKED RFR";
        case "MULD": return "MARKED ULTRA LOW DIESEL";
        case "MERC": return "MERCHANDISE";
        case "NGAS": return "NATURAL GAS";
        case "OIL": return "OIL";
        case "OILC": return "OIL CHANGE";
        case "SPLT": return "OTHER METHOD OF PAYMENT";
        case "PNT": return "PAINT";
        case "PARK": return "PARKING";
        case "PHON": return "PHONE TIME";
        case "PROP": return "PROPANE";
        case "RECP": return "RECAP";
        case "LCNG": return "RECOVERED LNG";
        case "RFR": return "REEFER";
        case "RFR1": return "REEFER #1";
        case "ULSR": return "REEFER ULTRA LOW SULFUR";
        case "RFND": return "REFUND";
        case "REST": return "RESTAURANT";
        case "STAX": return "SALES TAX";
        case "SCLE": return "SCALES";
        case "COUP": return "SHELL COUPON";
        case "DISC": return "SHELL COUPON";
        case "SHWR": return "SHOWER";
        case "ATMD": return "STAR NETWORK DENIAL";
        case "ATMB": return "STAR NETWORK INQUIRY";
        case "MGAS": return "TAX EXEMPT GAS";
        case "TCHN": return "TIRE CHAINS";
        case "TIRE": return "TIRES";
        case "SCAN": return "TONS IMAGING";
        case "TRAL": return "TRAILER";
        case "TRPP": return "TRIP PERMIT";
        case "PART": return "TRUCK PARTS";
        case "REPR": return "TRUCK REPAIR";
        case "WASH": return "TRUCK/CAR WASH";
        case "ULSD": return "ULTRA LOW SULFUR DIESEL";
        case "UNCL": return "UNLEADED CARD LOCK GAS";
        case "UNPL": return "UNLEADED PLUS";
        case "UNPR": return "UNLEADED PREMIUM";
        case "UNRG": return "UNLEADED REGULAR";
        case "UREA": return "DIESEL EXHAUST FLUID CONTAINER";
        case "WWFL": return "WINDSHIELD WASHER FLUID";
    }
    return Id;
}

const CategoryIdCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSCardTransactionHistoryLineItemViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {GetCategoryIdMappingValue(dataItem.Category)}
        </td>
    );
};

const AmountCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "c")}
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
        </td>
    );
};

const QuantityCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "n3")}
        </td>
    );
};

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

type TransactionHistoryDataGroup = {
    TransactionHistoryValues: EFSCardTransactionHistoryViewModel[],
    TransactionLocationValues: EFSCardTransactionLocationViewItem[]
}

type EFSCardTransactionLocationViewItem = {
    Id: number,
    Name: string,
    City: string,
    State: string
}

export type EFSCardTransactionHistoryViewModel = EFSCardTransactionHistoryViewItem & { LocationName: string, LocationCity: string, LocationState: string };

const CardTransactionHistoryGrid = (props: Props) => {
    const [driverID, setDriverID] = useState(0);
    const [tabRefreshIteration, setTABRefreshIteration] = useState(0);
    const [loading, setLoading] = useState(false);
    const [transactionHistoryDataGroup, setTransactionHistoryDataGroup] = useState<TransactionHistoryDataGroup>({ TransactionHistoryValues: [], TransactionLocationValues: [] });
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const Refresh = useCallback((): boolean => {
        return props.TABRefreshIteration != null && props.TABRefreshIteration !== tabRefreshIteration;
    }, [props.TABRefreshIteration, tabRefreshIteration]);

    useEffect(() => {
        if (props.IsGridInFocus == null || props.IsGridInFocus === false)
            return;
        if (!Refresh()) {
            if (driverID === props.DriverID)
                return;
        } else {
            setTABRefreshIteration(props.TABRefreshIteration);
        }

        setDriverID(props.DriverID);
        setLoading(true);
        fetchApi(`/api/Asset/GetEFSCardTransactionHistory`, { DriverID: props.DriverID }, 'POST')
            .then((response: TransactionHistoryDataGroup) => {
                response.TransactionHistoryValues.forEach(x => {
                    const loc = response.TransactionLocationValues.find(y => y.Id === x.LocationId)
                    x.LocationName = loc.Name;
                    x.LocationCity = loc.City;
                    x.LocationState = loc.State;
                })
                setTransactionHistoryDataGroup(response);
                setLoading(false);
            }).catch(() => {
                alert('Error');
                setLoading(false);
            });
    }, [props.DriverID, props.IsGridInFocus, driverID, Refresh, props.TABRefreshIteration]);

    const DetailComponent = (props: GridDetailRowProps) => {
        const dataItem = props.dataItem as EFSCardTransactionHistoryViewItem;
        return (
            <Grid data={dataItem.LineItems}>
                <Column field="Category" title="Category" cell={CategoryIdCell} />
                <Column field="Quantity" title="Quantity" cell={QuantityCell} headerCell={RightAlignHeaderCell} className="text-right" />
                <Column field="Amount" title="Amount" cell={AmountCell} headerCell={RightAlignHeaderCell} className="text-right" />
            </Grid>
        );
    };

    const expandChange = (e: GridExpandChangeEvent) => {
        let dataItem = e.dataItem as EFSCardTransactionHistoryViewItem;
        dataItem.Expanded = !e.dataItem.Expanded;
        forceUpdate();
    };

    return <div className="container-fluid">
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <Grid data={transactionHistoryDataGroup.TransactionHistoryValues}
            expandField="Expanded"
            onExpandChange={expandChange}
            detail={DetailComponent}
            style={{ maxHeight: '600px' }}
        >
            <Column field="TransactionDate" title="Transaction Date" cell={DateCell} />
            <Column field="POSDate" title="POS Date" cell={DateCell} />
            <Column field="FundedTotal" title="Total Amount" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
            <Column field="TransactionId" title="Transaction ID" />
            <Column field="AuthCode" title="Authorization Code" />
            <Column field="Invoice" title="Invoice Number" />
            <Column field="LocationName" title="Location Name" />
            <Column field="LocationCity" title="Location City" />
            <Column field="LocationState" title="Location State" />
        </Grid>
    </div >
}

export default CardTransactionHistoryGrid;

