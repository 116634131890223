import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment';
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';
import { Link } from 'react-router-dom';

export default class ServiceStatus extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;

        return (
            <td>
                {this.props.dataItem.AvailableDateTime && this.props.dataItem.Links.filter((x: ILink) => x.Name === 'TripProfile').length > 0 ? <a
                    href="#"
                    style={{ color: this.props.dataItem.StatusColor }}
                    title={`Available: ${Moment.utc(this.props.dataItem.AvailableDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}`}
                    onClick={(e) => {
                        openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'TripProfile')[0].Link);
                        e.preventDefault();
                    }}
                >{this.props.dataItem.ServiceStatus}
                </a> : <Link
                    to={this.props.dataItem.Links.find((x: ILink) => x.Name === 'ServiceStatus').Link}
                    style={{ color: this.props.dataItem.StatusColor }}
                >
                    {this.props.dataItem.ServiceStatus}
                </Link>}
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
