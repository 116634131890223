
import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import ActionPopup from './ActionPopup';
import { OpenShipment } from '.';

export default function MyCommandCell(
  reserve?: (shipment: OpenShipment) => void,
  release?: (shipment: OpenShipment) => void,
  decline?: (shipment: OpenShipment) => void) {
  return class extends React.Component<GridCellProps> {
    render() {
      return <td><ActionPopup
        dataItem={this.props.dataItem}
        reserve={reserve}
        release={release}
        decline={decline}
      /></td>
    }
  }
};
