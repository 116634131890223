import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

const CustomerNameCell = (props: GridCellProps) => {
  if (!props.field) return null;

  return (
    <td style={{ color: props.dataItem.OnCreditHold ? 'red' : undefined }}>
      {props.dataItem.CustomerName}
    </td>
  );
}

export default React.memo(CustomerNameCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
