import { formatNumber } from '@progress/kendo-intl';
import { Button, DropDownButton, SplitButton, SplitButtonItem, SplitButtonItemClickEvent } from "@progress/kendo-react-buttons";
import { DragAndDrop } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Grid, GridCellProps, GridColumn as Column, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { Label } from '@progress/kendo-react-labels';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import LoadingPanel from "components/LoadingPanel";
import RouteMap from "components/RouteMap";
import Moment from 'moment-timezone';
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { openWindow } from "services/openWindow";
import { JsonResponse } from "TypeGen/json-response";
import { ActionItem } from "TypeGen/Trip/Profile/action-item";
import { ActionItemKeyType } from "TypeGen/Trip/Profile/action-item-key-type";
import { Order } from "TypeGen/Trip/Profile/order";
import { TripStop } from "TypeGen/Trip/Profile/trip-stop";
import { ViewModel as TripProfileSummary } from "TypeGen/Trip/Profile/view-model";
import { ILink } from "types/link";
import { useBoolean } from "usehooks-ts";
import TripSummary from "views/Track/TripSummary";
import DateCell from "../../../components/cells/DateCell";
import CenterDivPanel from "../../../components/CenterDivPanel";
import { DraggableGridRow, ReorderContext } from "../../../components/DraggableGridRow";
import KendoWindow from "../../../components/KendoWindow";
import Arrive from "../../../components/Milestones/Arrive";
import Empty from "../../../components/Milestones/Empty";
import Load from "../../../components/Milestones/Load";
import { getSplitBadgeOrder, getSplitBadgeOrderStop, getSplitLink } from "../../../components/SplitIcon";
import useConfirm from "../../../components/useConfirm";
import { fetchApi } from "../../../services/api";
import realFormatter from "../../../services/formatting/number";
import { Title } from "../../../utils/title";
import OrderAddPair from '../../Order/AddPair';
import EnterSplitStop from "../../Order/EnterSplitStop";
import SplitOrderSummary from "../../Order/SplitOrderSummary";
import OrderStop from "../../Order/Stop";
import OrderStopDelete from '../../Order/Stop/Delete';
import { SvgIcon } from "@progress/kendo-react-common";
import { SVGIcon, cancelOutlineIcon, chevronRightIcon, exclamationCircleIcon, loginIcon, pencilIcon, plusIcon, redoIcon, reorderIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  tripId: string;
};

type TripContextType = {
  refresh: () => void;
}

export const TripContext = createContext<TripContextType>({
  refresh: () => { },
});

type Props = {
  tripId?: number;
  addOrderID?: number;

  isDialog?: boolean;
  onClose?: () => void;

  children?: React.ReactNode;
}

export const DragHandleCell = (props: any) => {
  const dataItem = props.dataItem as TripStop;

  return (
    <td>
      {dataItem.IsDraggable ? <SvgIcon
        icon={reorderIcon}
        style={{ cursor: "move" }}
        data-drag-handle={true}
      /> : <span>-</span>}
    </td>
  );
};

const ActionCell = (props: any) => {
  if (props.showCancelSave === true)
    return (<td></td>);

  const dataItem = props.dataItem as TripStop;

  let links: ILink[] = [];

  const arriveLink = dataItem.Links.find((x: ILink) => x.Name === 'Arrive') as ILink;
  const loadLink = dataItem.Links.find((x: ILink) => x.Name === 'Load') as ILink;
  const emptyLink = dataItem.Links.find((x: ILink) => x.Name === 'Empty') as ILink;
  const undoArriveLink = dataItem.Links.find((x: ILink) => x.Name === 'Undo Arrive') as ILink;
  const undoLoadLink = dataItem.Links.find((x: ILink) => x.Name === 'Undo Loaded') as ILink;
  const undoEmptyLink = dataItem.Links.find((x: ILink) => x.Name === 'Undo Empty') as ILink;

  const splitLink = dataItem.Links.find((x: ILink) => x.Name === 'Insert Split') as ILink;
  const modifySplitLink = dataItem.Links.find((x: ILink) => x.Name === 'Modify Split') as ILink;
  const undoSplitLink = dataItem.Links.find((x: ILink) => x.Name === 'Undo Split') as ILink;

  const insertPickupLink = dataItem.Links.find((x: ILink) => x.Name === 'Add Pick-Up') as ILink;
  const insertDeliveryLink = dataItem.Links.find((x: ILink) => x.Name === 'Add Delivery') as ILink;
  const insertPairLink = dataItem.Links.find((x: ILink) => x.Name === 'Add Pair') as ILink;
  const removeStopLink = dataItem.Links.find((x: ILink) => x.Name === 'Delete Stop') as ILink;
  const modifyStopLink = dataItem.Links.find((x: ILink) => x.Name === 'Update Stop') as ILink;

  function getIcon(link: ILink, svg: true): SVGIcon;
  function getIcon(link: ILink, svg: false): string;
  function getIcon(link: ILink, svg: boolean): SVGIcon | string {
    switch (link.Name) {
      case 'Undo Arrive':
      case 'Undo Loaded':
      case 'Undo Empty':
      case 'Undo Split':
      case 'Delete Stop':
        return svg ? cancelOutlineIcon : "cancel-outline";
      case 'Modify Split':
      case 'Update Stop':
        return svg ? pencilIcon : "pencil";
      case 'Arrive':
      case 'Load':
      case 'Empty':
        return svg ? redoIcon : "redo";
      case 'Insert Split':
        return svg ? loginIcon : "login";
      case 'Add Pick-Up':
      case 'Add Delivery':
      case 'Add Pair':
        return svg ? plusIcon : "plus";
      default:
        throw new Error(`Unknown link name: ${link.Name}`);
    }
  }

  //Do not change order.  Sorted by importance or most accessed
  if (arriveLink)
    links.push(arriveLink);
  if (loadLink)
    links.push(loadLink);
  if (emptyLink)
    links.push(emptyLink);

  if (undoArriveLink)
    links.push(undoArriveLink);
  if (undoLoadLink)
    links.push(undoLoadLink);
  if (undoEmptyLink)
    links.push(undoEmptyLink);

  if (splitLink)
    links.push(splitLink);
  if (modifySplitLink)
    links.push(modifySplitLink);
  if (undoSplitLink)
    links.push(undoSplitLink);

  if (modifyStopLink)
    links.push(modifyStopLink);
  if (insertPickupLink)
    links.push(insertPickupLink);
  if (insertDeliveryLink)
    links.push(insertDeliveryLink);
  if (insertPairLink)
    links.push(insertPairLink);
  if (removeStopLink)
    links.push(removeStopLink);

  if (links.length > 1) {
    return (<td>
      <SplitButton text={links[0].Name} themeColor={"secondary"} icon={getIcon(links[0], false)} svgIcon={getIcon(links[0], true)}
        onButtonClick={() => props.setEventAction(links[0], dataItem)}
        onItemClick={(e: SplitButtonItemClickEvent) => {
          props.setEventAction(links.find(x => x.Name === e.item.text), dataItem)
        }}>
        {links.slice(1).map((link, index) => {
          return (<SplitButtonItem key={index} text={link.Name} icon={getIcon(link, false)} svgIcon={getIcon(link, true)} />);
        })}
      </SplitButton>
    </td>);
  } else if (links.length === 1) {
    return (<td><Button themeColor={"secondary"} icon={getIcon(links[0], false)} svgIcon={getIcon(links[0], true)} onClick={() => props.setEventAction(links[0], dataItem)}>{links[0].Name}</Button></td>);
  } else {
    return (<td></td>);
  }
};

const TripProfile = (props: Props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const addOrderIdParam = urlParams.get('addorder');
  const history = useHistory();

  const { tripId: tripIdParam } = useParams<RouteComponentParams>();
  const [tripId, setTripId] = useState(0);

  const [loading, setLoading] = useState(true);
  const { value: showMap, toggle: toggleMap } = useBoolean(false);
  const { value: showTripSummary, toggle: toggleTripSummary } = useBoolean(false);
  const [details, setDetails] = useState<TripProfileSummary>();
  const [activeItem, setActiveItem] = useState<any | null>(null);
  const [eventAction, setEventAction] = useState<{ Link: ILink, DataItem: TripStop } | null>(null);
  const [selectedActionItemKey, setSelectedActionItemKey] = useState<ActionItemKeyType | null>(null);
  const showCancelSave = selectedActionItemKey != null && [ActionItemKeyType.ResequenceStops, ActionItemKeyType.AddOrder].includes(selectedActionItemKey) && details?.Errors.length === 0;
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [showOrderSummaryOrderID, setShowOrderSummaryOrderID] = useState(0);
  const [showOrderNumberDialog, setShowOrderNumberDialog] = useState(false);
  const [orderNumberInput, setOrderNumberInput] = useState<number | undefined>(undefined);
  const disableAddOrderButton = orderNumberInput == undefined || orderNumberInput <= 0;
  const [dryRunOptionGroupValue, setDryRunOptionGroupValue] = useState(0);
  const [dryRunOptionNote, setDryRunOptionNote] = useState('');

  const OrderSplitSummary = details?.Orders.map(x => { return { OrderID: x.OrderID, ForwardOrderID: x.ForwardOrderID, BackwardOrderID: x.BackwardOrderID } as Order });

  const reorder = (dataItem: any) => {
    if (activeItem == null || dataItem == null)
      return;

    if (activeItem === dataItem)
      return;

    if (activeItem.IsDraggable === false || dataItem.IsDraggable === false)
      return;

    if (activeItem.OrderID === dataItem.OrderID)
      return;

    let reorderedData = details.Stops.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex = reorderedData.findIndex((p) => p === dataItem);

    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(
      nextIndex,
      0,
      activeItem || reorderedData[0]
    );

    if (reorderedData[1].Event !== 1) //PU
      return;
    if (reorderedData[details?.Stops.length - 1].Event !== 2) //DR
      return;

    setDetails({ ...details, Stops: reorderedData });
  };

  const dragStart = (dataItem: any) => {
    setActiveItem(dataItem);
  };

  const OrderNumberCell = (props: any) => {
    const dataItem = props.dataItem as TripStop;
    if (dataItem.OrderID === 0) return <td />; // for deadhead
    const orderSplitSummary = props.orderSplitSummary as Order[];
    const order = orderSplitSummary.find(x => x.OrderID === dataItem.OrderID);

    if (dataItem.TripSequence > 0)
      return (
        <td>
          <Link style={{ color: "#007bff" }} to={`/Order/${dataItem.OrderID}`}>{dataItem.OrderNumber}</Link>&nbsp;{getSplitLink(order.BackwardOrderID, order.ForwardOrderID, () => setShowOrderSummaryOrderID(order.OrderID))}
        </td>
      );
    else
      return (
        <td>
          {dataItem.OrderNumber}&nbsp;
        </td>
      );
  }

  const LocationCell = (props: GridCellProps) => {
    const dataItem = props.dataItem as TripStop;
    var splitBadge = getSplitBadgeOrderStop(dataItem.IsSplitLocation);
    return (
      <td>
        {dataItem.Location}{splitBadge && <><br />{splitBadge}</>}
      </td>
    );
  }

  const expandChange = useCallback((props: TripStop, link: ILink, expanded: boolean) => {

    //original - insert detailcomponent AFTER
    //new - insert detailcomponent BEFORE props/tripstop row
    props.ExpandedLink = link;
    ////var previousTripStop = details.Stops.slice(0, details.Stops.findIndex(function (element) { return element.TripSequence === props.TripSequence; })).pop();
    ////previousTripStop.Expanded = expanded;
    setDetails((prevState) => {
      return ({ ...prevState, Stops: prevState.Stops.map(x => x.TripSequence === props.TripSequence - 1 ? { ...x, Expanded: expanded } : x) });
    });
  }, []);

  const refreshOrderDetails = useCallback((addOrderID = 0, addOrderNumber = 0) => {
    setLoading(true);

    fetchApi(`/api/Trip/Profile`, { TripID: tripId, AddOrderID: addOrderID, AddOrderNumber: addOrderNumber }, 'POST')
      .then((response: TripProfileSummary) => {
        setDetails(response);
        setLoading(false);

        if (response.Errors.length === 0 && (addOrderID > 0 || addOrderNumber > 0))
          setSelectedActionItemKey(ActionItemKeyType.AddOrder);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load trip profile');
      });
  }, [tripId]);

  const saveChanges = (orderId = 0, actionItemKey?: ActionItemKeyType) => {
    setLoading(true);

    const data = {
      TripID: tripId,
      OrderID: orderId,
      ActionItemKey: actionItemKey ?? selectedActionItemKey,
      TripStops: details.Stops,
      OrderInfo: details?.Orders.map(x => { return { OrderID: x.OrderID, ModNum: x.ModNum } }),
      TripModNum: details.ModNum
    };

    fetchApi(`/api/Trip/SaveTripProfile`, data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          alert(response.ErrorMessage);
        }
        setSelectedActionItemKey(null);
        refreshOrderDetails();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to save trip profile');
      });
  };

  useEffect(() => {
    if (eventAction?.Link.Name.startsWith('Undo')) {
      setLoading(true);
      fetchApi(eventAction.Link.Link, {}, eventAction.Link.Method)
        .then((response: JsonResponse) => {
          if (response.Success) {
            if (eventAction?.Link.Name.toLowerCase().includes('split')) {
              //expandChange(eventAction.DataItem, false);
            }
          } else {
            alert(response.ErrorMessage);
          }
          setEventAction(null);
          refreshOrderDetails();
        })
        .catch(() => { setLoading(false); alert("Unknown Error Occurred!"); });
    } else if (eventAction?.Link.Name === 'Insert Split' || eventAction?.Link.Name === 'Modify Split') {
      expandChange(eventAction.DataItem, eventAction?.Link, true);
    }
  }, [eventAction, refreshOrderDetails, expandChange]);

  useEffect(() => {
    if (props.tripId > 0)
      setTripId(props.tripId);
    else
      setTripId(Number(tripIdParam));
  }, [tripIdParam, props.tripId]);

  useEffect(() => {
    if (tripId > 0)
      refreshOrderDetails(props.addOrderID || Number(addOrderIdParam));
  }, [tripId, props.addOrderID, addOrderIdParam, refreshOrderDetails]);

  const deleteTrip = () => {
    setLoading(true);
    fetchApi(`/api/Trip/DeleteTrip/${tripId}`, {}, 'DELETE')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          alert(response.ErrorMessage);
        } else {
          alert('Trip is now canceled');
          var orderNumber = details?.Orders[0].OrderNumber;
          if (window.opener) {
            window.opener.location.href = `/Track/Index/${orderNumber}`;
            window.close();
          }
          else {
            history.push(`/Track/Index/${orderNumber}`);
          }
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to cancel trip');
      });
  };

  const getDryRunPrepInfo = () => {
    setLoading(true);

    const data = {
      dryrunoption: dryRunOptionGroupValue
    };

    fetchApi(`/api/Trip/DryRunPrepInfo/${tripId}`, data, 'POST')
      .then((response: string) => {
        setDryRunOptionNote(response);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load trip profile');
      });
  };

  const dryRunTrip = () => {
    setLoading(true);

    const data = {
      modnum: details?.ModNum,
      dryrunoption: dryRunOptionGroupValue,
      dryrunoptionnote: dryRunOptionNote
    };

    fetchApi(`/api/Trip/DryRunTrip/${tripId}`, data, 'POST')
      .then((response: JsonResponse) => {
        setSelectedActionItemKey(null);
        if (response.Success === false) {
          alert(response.ErrorMessage);
        } else {
          var dispatchLink = response.Links.find(x => x.Name === "Dispatch");
          if (dispatchLink)
            history.push(dispatchLink.Link);
          else
            refreshOrderDetails();
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to dry run trip');
      });
  };

  const callRefreshOrderDetailsWithOrderNumberFromInput = () => {
    if (disableAddOrderButton === false) {
      setShowOrderNumberDialog(false);
      refreshOrderDetails(0, orderNumberInput);
    }
  }

  const detailComponent = (props: GridDetailRowProps) => {
    //nextRowTripStopDataItem.Links will contain the full list of links that the end-user can select from
    //nextRowTripStopDataItem.ExpandedLink will contain the actual link clicked by the end-user

    const expandedRowTripStopDataItem = props.dataItem as TripStop;

    var nextRowTripStopDataItem = details?.Stops.slice(details?.Stops.findIndex(function (element) {
      return element.TripSequence === expandedRowTripStopDataItem.TripSequence;
    }) + 1, details?.Stops.length)[0];

    const getSplitInfoLink = nextRowTripStopDataItem.Links.find((x: ILink) => x.Name === 'Get Split Info') as ILink;
    const requestedActionLink = nextRowTripStopDataItem.ExpandedLink as ILink;

    return (
      <section>
        <div className="card my-1">
          <div className="card-body">
            {(requestedActionLink?.Name === 'Insert Split' || requestedActionLink?.Name === 'Modify Split') && <EnterSplitStop
              onCancel={() => { setEventAction(null); expandChange(nextRowTripStopDataItem, null, false); }}
              onSave={() => { setEventAction(null); expandChange(nextRowTripStopDataItem, null, false); refreshOrderDetails(); }}
              GetSplitInfoLink={getSplitInfoLink}
            />}
            {/*{requestedActionLink?.Name === 'Update Stop' && <OrderStop orderId={nextRowTripStopDataItem.OrderID} orderStopId={nextRowTripStopDataItem.OrderStopID}*/}
            {/*  onClose={() => { setEventAction(null); expandChange(nextRowTripStopDataItem, null, false); refreshOrderDetails(); }} />}*/}
            {/*{requestedActionLink?.Name === 'Delete Stop' && <OrderStopDelete orderId={nextRowTripStopDataItem.OrderID} orderStopId={nextRowTripStopDataItem.OrderStopID}*/}
            {/*  onClose={() => { setEventAction(null); expandChange(nextRowTripStopDataItem, null, false); refreshOrderDetails(); }} />}*/}
          </div>
        </div>
      </section>
    );
  };

  const profile = () => {

    if (loading && !details) {
      return <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>;
    }

    return <div className={props.isDialog ? "container-fluid" : "container-xl"}>
      <Title string={`${details.TripNumber}`} />
      {loading && <LoadingPanel />}
      <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${!props.isDialog && "mt-3"}`}>
        <span className="navbar-brand">
          <Link to={`/Trip/${details?.TripID}`}>Trip # {details?.TripNumber}</Link>
          {details.Carrier && <>
            <SvgIcon icon={chevronRightIcon} className="text-muted" />
            <Link to={`/Assets/Carrier/${details.Carrier.ID}`}>{details.Carrier.Name}</Link>
          </>}
          {details.Vehicle && <>
            <SvgIcon icon={chevronRightIcon} className="text-muted" />
            <Link to={`/Assets/Vehicle/${details.Vehicle.ID}`}>{details.Vehicle.Name}</Link>&nbsp;
            (
            {details.Driver1 && <><Link to={`/Assets/Driver/${details.Driver1.ID}`}>{details.Driver1.Name}</Link></>}
            {details.Driver2 && <>, <Link to={`/Assets/Driver/${details.Driver2.ID}`}>{details.Driver2.Name}</Link></>}
            {details.Driver3 && <>, <Link to={`/Assets/Driver/${details.Driver3.ID}`}>{details.Driver3.Name}</Link></>}
            )
          </>}
          {details.Trailer1 && <>
            <SvgIcon icon={chevronRightIcon} className="text-muted" />
            <Link to={`/Assets/Trailer/${details.Trailer1.ID}`}>[{details.Trailer1.Name}]</Link>
            {details.Trailer2 && <>/<Link to={`/Assets/Trailer/${details.Trailer2.ID}`}>[{details.Trailer2.Name}]</Link></>}
          </>}
        </span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#orderNav" aria-controls="orderNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="orderNav">
          <ul className="navbar-nav">
          </ul>
          <ul className="nav navbar-nav ml-auto">
            <Button
              themeColor="success"
              onClick={() => {
                const link = details.Links.find(x => x.Name === 'Pay');
                history.push(link.Link);
              }}
            >
              Pay: {formatNumber(details.PayAmount, "c")}
            </Button>
            &nbsp;&nbsp;
            {details?.MenuActionItems.length > 0 &&
              <DropDownButton
                className="buttons-container-button"
                text="Actions"
                items={details?.MenuActionItems}
                textField="actionName"
                themeColor="secondary"
                disabled={showCancelSave || eventAction != null}
                onItemClick={async (e) => {
                  var actionKey = (e.item as ActionItem).actionKey;
                  if (actionKey === ActionItemKeyType.CancelTrip) {
                    if (!await confirm('Warning: All orders for this trip will be set to a status of available.', 'Cancel Trip?'))
                      return;
                    deleteTrip();
                    setSelectedActionItemKey(actionKey);
                  } else if (actionKey === ActionItemKeyType.DryRunTrip) {
                    setSelectedActionItemKey(actionKey);
                    getDryRunPrepInfo();
                  } else if (actionKey === ActionItemKeyType.AddOrder) {
                    setShowOrderNumberDialog(true);
                  } else if (actionKey === ActionItemKeyType.ToggleMap) {
                    toggleMap();
                  } else if (actionKey === ActionItemKeyType.TripSummary) {
                    toggleTripSummary();
                  } else if (actionKey === ActionItemKeyType.CarrierAgreement) {
                    openWindow(`/CarrierAgreement/View/${details?.TripID}`, 920, 1100);
                  } else if (actionKey === ActionItemKeyType.TransactionLogs) {
                    history.push(`/Trip/${details?.TripID}/TransactionLogs`);
                  } else {
                    setSelectedActionItemKey(actionKey);
                  }
                }}
              />}
          </ul>
        </div>
      </nav>
      {(details?.Errors.length > 0 || details?.Warnings.length > 0) && <NotificationGroup style={{ width: "100%", position: "relative", alignItems: "normal", zIndex: 0 }}>
        {details?.Errors.map((message, index) => (
          <Notification key={index} style={{ padding: "8px", width: "100%" }} type={{ style: "error" }}>
            <div>{message}</div>
          </Notification>
        ))}
        {details?.Warnings.map((message, index) => (
          <Notification key={index} style={{ padding: "8px", width: "100%" }} type={{ style: "warning" }}>
            <div>{message}</div>
          </Notification>
        ))}
      </NotificationGroup>}

      {details?.Orders.length > 0 && <div className="card mt-2 rounded-0">
        <h5 className="card-title text-center mt-3">Order Summary</h5>
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="border-top-0">Order Number</th>
              <th className="border-top-0">From</th>
              <th className="border-top-0">Pick-Up Date</th>
              <th className="border-top-0">To</th>
              <th className="border-top-0">Delivery Date</th>
              <th className="border-top-0">Miles</th>
              <th className="border-top-0">Status</th>
              {details?.Orders.length > 1 && showCancelSave === false && <th className="border-top-0" />}
            </tr>
          </thead>
          <tbody>
            {details?.Orders.map((order) => {
              var splitBadge = getSplitBadgeOrder(order.BackwardOrderID, order.ForwardOrderID);
              return (<tr key={order.OrderNumber}>
                <td><Link to={`/Order/${order.OrderID}`}>{order.OrderNumber}</Link>&nbsp;{getSplitLink(order.BackwardOrderID, order.ForwardOrderID, () => setShowOrderSummaryOrderID(order.OrderID))}</td>
                <td>{order.ShipCustomerName}<br />{order.ShipLocation}{order.BackwardOrderID > 0 && <><br />{splitBadge}</>}</td>
                <td>{Moment.utc(order.ShipDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</td>
                <td>{order.DeliveryCustomerName}<br />{order.DeliveryLocation}{order.ForwardOrderID > 0 && <><br />{splitBadge}</>}</td>
                <td>{Moment.utc(order.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</td>
                <td>{realFormatter(order.TotalMiles)}</td>
                <td>{order.OrderStatusName}</td>
                {details?.Orders.length > 1 && showCancelSave === false && <td>
                  <SvgIcon icon={cancelOutlineIcon} style={{ color: "red", cursor: "pointer" }} onClick={async () => {
                    if (!await confirm(`Remove order ${order.OrderNumber} from this trip?`, `Remove Order?`))
                      return;

                    setSelectedActionItemKey(ActionItemKeyType.RemoveOrder);
                    saveChanges(order.OrderID, ActionItemKeyType.RemoveOrder);
                  }}
                /></td>}
              </tr>)
            })}
          </tbody>
        </table>
      </div>}
      {showTripSummary && <TripSummary orderId={details?.Orders[0].OrderID} close={toggleTripSummary} />}
      {showMap && <div style={{ width: '100%', height: 500 }}>
        <RouteMap lnglats={details.Stops.map(x => [x.Longitude, x.Latitude])} />
      </div>}
      <h5 className="card-title text-center mt-2">Trip Summary</h5>
      <ReorderContext.Provider value={{ reorder: reorder, dragStart: dragStart }}>
        <DragAndDrop>
          <Grid
            sortable={false}
            data={details?.Stops}
            dataItemKey={"TripSequence"}
            detail={detailComponent}
            expandField={"Expanded"}
            //onExpandChange={expandChange}
            rowRender={(row, rowProps) => {
              return (
                <DraggableGridRow dataItemKey={"TripSequence"} dataItems={details?.Stops} elementProps={row.props} {...rowProps} />
              )
            }}
          >
            {/*<Column field="Expanded" title=' ' width="32px" cell={ExpandableColumn} />*/}
            {showCancelSave ? <Column title="" cell={DragHandleCell} /> :
              <Column field="TripSequence" title="Sequence" width="75px" />}
            <Column field="OrderNumber" title="Order Number" cell={(props) => <OrderNumberCell {...props}
              orderSplitSummary={OrderSplitSummary} />} />
            <Column field="EventNumber" title="Event" />
            <Column field="CustomerName" title="Name" />
            <Column field="Location" title="Location" cell={LocationCell} />
            <Column field="Distance" title="Distance" />
            <Column field="ScheduledDateTime" title="Date Time" cell={DateCell} />
            <Column field="StopStatusNumber" title="Status" />
            <Column field="StopStatusNumber" title=" " width="225px" cell={(props) => <ActionCell {...props}
              showCancelSave={showCancelSave}
              orderSplitSummary={OrderSplitSummary}
              setEventAction={async (link: ILink, dataItem: TripStop) => {
                if (link.Name.toLowerCase().startsWith('undo')) {
                  const eventDesc = link.Name.split(' ')[1];

                  if (await confirm(`Undo ${eventDesc} - Are you sure?`))
                    setEventAction({ Link: link, DataItem: dataItem });
                } else
                  setEventAction({ Link: link, DataItem: dataItem });
              }} />
            } />
          </Grid>
        </DragAndDrop>
      </ReorderContext.Provider>
      {showCancelSave && <NotificationGroup style={{ width: "100%", position: "relative", alignItems: "normal" }}>
        <Notification
          style={{ padding: "10px", width: "100%" }}
          type={{ style: "warning" }}
        >
          <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between' }}>

            <div className="mt-1">
              <SvgIcon icon={exclamationCircleIcon} style={{ marginBottom: '0.15em' }} />
              <span>&nbsp;WARNING! Unsaved Changes.</span>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button themeColor={'success'} onClick={() => saveChanges(details?.InsertedOrderID)}>Save Changes</Button>
              <Button themeColor={'error'} className="mr-2" onClick={() => { setSelectedActionItemKey(null); refreshOrderDetails(); }}>Cancel</Button>
            </div>
          </div>
        </Notification>
      </NotificationGroup>}
      <ConfirmationDialog />
      {showOrderNumberDialog && <Dialog
        title="Add Order To Trip"
        onClose={() => setShowOrderNumberDialog(false)}
        width={300}
        height={215}>
        <form className="k-form k-form-md p-0">
          <FieldWrapper>
            <Label>Order No.</Label>
            <Input
              autoFocus={true}
              type="number"
              maxLength={12}
              value={orderNumberInput}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  callRefreshOrderDetailsWithOrderNumberFromInput();
                }
              }}
              onChange={(e: any) => setOrderNumberInput(e.value)}
            />
          </FieldWrapper>
        </form>
        <DialogActionsBar layout="end">
          <Button themeColor="primary" disabled={disableAddOrderButton}
            onClick={() => {
              callRefreshOrderDetailsWithOrderNumberFromInput();
            }}>Add Order</Button>
          <Button onClick={() => setShowOrderNumberDialog(false)}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>}
      {showOrderSummaryOrderID > 0 && <SplitOrderSummary OrderID={showOrderSummaryOrderID} onClose={() => setShowOrderSummaryOrderID(0)} />}
      {eventAction && eventAction.Link.Name === 'Arrive' && <Arrive CloseDialog={() => { setEventAction(null); refreshOrderDetails(); }} Link={eventAction.Link} />}
      {eventAction && eventAction.Link.Name === 'Load' && <Load CloseDialog={() => { setEventAction(null); refreshOrderDetails(); }} Link={eventAction.Link} />}
      {eventAction && eventAction.Link.Name === 'Empty' && <Empty CloseDialog={() => { setEventAction(null); refreshOrderDetails(); }} Link={eventAction.Link} />}

      {(eventAction?.Link.Name === 'Add Pick-Up' || eventAction?.Link.Name === 'Add Delivery') && <KendoWindow autoSizePercentage={90}
        onClose={() => { setEventAction(null); refreshOrderDetails(); }} title={eventAction?.Link.Name === 'Add Pick-Up' ? 'Add Pick-Up Stop/Modify Delivery Stop Details' : 'Add Delivery Stop/Modify Pick-Up Stop Details'}>
        <div className="container-fluid mt-3">
          <OrderAddPair orderId={eventAction.DataItem.OrderID} orderStopId={eventAction.DataItem.OrderStopID} onClose={() => { setEventAction(null); refreshOrderDetails(); }} />
        </div>
      </KendoWindow>
      }

      {
        (eventAction?.Link.Name === 'Add Pair') && <KendoWindow autoSizePercentage={90}
          onClose={() => { setEventAction(null); refreshOrderDetails(); }} title={`Add Pick-Up and Delivery Stops`}>
          <div className="container-fluid mt-3">
            <OrderAddPair orderId={eventAction.DataItem.OrderID} orderStopId={0} onClose={() => { setEventAction(null); refreshOrderDetails(); }} />
          </div>
        </KendoWindow>
      }

      {
        (eventAction?.Link.Name === 'Update Stop') && <KendoWindow autoSizePercentage={70}
          onClose={() => { setEventAction(null); refreshOrderDetails(); }} title={`Update Stop`}>
          <div className="container-fluid mt-3">
            <OrderStop orderId={eventAction.DataItem.OrderID} orderStopId={eventAction.DataItem.OrderStopID} onClose={() => { setEventAction(null); refreshOrderDetails(); }} />
          </div>
        </KendoWindow>
      }

      {
        (eventAction?.Link.Name === 'Delete Stop') && <KendoWindow autoSizePercentage={70}
          onClose={() => { setEventAction(null); refreshOrderDetails(); }} title={`Delete Stop`}>
          <div className="container-fluid mt-3">
            <OrderStopDelete orderId={eventAction.DataItem.OrderID} orderStopId={eventAction.DataItem.OrderStopID} onClose={() => { setEventAction(null); refreshOrderDetails(); }} />
          </div>
        </KendoWindow>
      }

      {
        selectedActionItemKey === ActionItemKeyType.DryRunTrip && <Dialog title='Dry Run' onClose={() => setSelectedActionItemKey(null)} className='dialog-w800'>
          <p>
            <div >
              <div>
                <RadioButton
                  name="dryRunGroup"
                  label="Dry Run Order & Trip"
                  value={0}
                  checked={dryRunOptionGroupValue === 0}
                  onChange={(e) => setDryRunOptionGroupValue(e.value)}
                />
              </div>
              <div className="mt-2">
                <RadioButton
                  name="dryRunGroup"
                  label="Dry Run Trip Only and Redispatch Order"
                  value={1}
                  checked={dryRunOptionGroupValue === 1}
                  onChange={(e) => setDryRunOptionGroupValue(e.value)}
                />
              </div>
              <div className="mt-3">
                <label>Billing Note:</label>
                <TextArea
                  maxLength={300}
                  rows={4}
                  value={dryRunOptionNote}
                  onChange={(e) => setDryRunOptionNote(e.value)}
                />
              </div>
              <div className="font-italic">
                *Pay note amount reflects the current pay set for the trip. Ensure trip pay is accurate before clicking OK.<br />
                All pay adjustments must be made from the pay trip screen.
              </div>
            </div>
          </p>
          <DialogActionsBar layout={'end'}>
            <Button onClick={() => setSelectedActionItemKey(null)}>Cancel</Button>
            <Button themeColor={'primary'} disabled={loading} onClick={() => dryRunTrip()}>Dry Run</Button>
          </DialogActionsBar>
        </Dialog>
      }

      <TripContext.Provider value={{ refresh: refreshOrderDetails }}>
        {props.children}
      </TripContext.Provider>
    </div >
  }

  if (props.isDialog) {
    return <KendoWindow
      autoSizePercentage={85}
      resizable
      title={<Link to={`/Trip/${props.tripId}`}>Trip Profile {details?.TripNumber ?? ''}</Link>}
      onClose={() => {
        props.onClose();
      }}
    >
      {profile()}
    </KendoWindow>
  }

  return profile();
}

export default TripProfile;
