import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { DriverNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

type RouteComponentParams = {
    driverId: string;
};

type DriverNotesType = {
    Notes: DriverNoteType[],
    Links: ILink[];
}

type DriverNoteType = {
    DriverNoteID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;
    Note: string;
    FollowUpDate: string | null;
}

const DriverNotes = () => {

    const { driverId: driverIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const [newNote, setNewNote] = useState<string>('');
    const [notes, setNotes] = useState<DriverNotesType>();

    const refresh = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/DriverNotes/${driverIdParam}`)
            .then(data => {
                setLoading(false);
                setNotes(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [driverIdParam]);

    useEffect(() => {
        refresh();
    }, [driverIdParam, refresh]);

    const saveNote = () => {
        setLoading(true);
        const data = {
            Note: newNote
        }
        fetchApi(`/api/Asset/AddDriverNote/${driverIdParam}`, data, 'POST')
            .then(() => {
                setNewNote('');
                refresh();
            })
            .catch((e) => {
                // If not problem details
                if (!e?.status) alert('An error occurred while saving the note.');
            })
            .finally(() => setLoading(false));
    }

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <>
        <form onSubmit={saveNote}>
            <TextArea
                required
                value={newNote}
                style={{ width: "100%" }}
                className="my-2"
                placeholder="New Driver Note"
                onChange={(e) => setNewNote(e.value)}
            />
            <Button type="submit" themeColor="primary">Save Note</Button>
        </form>
        {notes.Notes.length === 0 && <h1 className="text-center">No Notes.</h1>}
        {
            notes.Notes.map(note => <div key={note.DriverNoteID} className="media m-2 border-bottom">
                <div className="media-body">
                    <h5 className="mt-0">{note.ModifiedByFullName}</h5>
                    {note.Note}
                </div>
                <p><small>{Moment.utc(note.ModifiedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}</small></p>
            </div>)
        }
    </>

    return (<>
        <DriverNavBar id={driverIdParam} currentTab="Notes" />
        <Title string="Notes" />
        {loading || !notes ? loadingView : dataView()}
    </>);
}

export default DriverNotes;