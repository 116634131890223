import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class Status extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td style={{ color: this.props.dataItem.StatusColor }}>
        {this.props.dataItem.Status}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
