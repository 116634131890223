import * as React from 'react';
import { useParams } from 'react-router';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { useState } from 'react';
import { isNullOrWhiteSpace } from '../../../utils/utils';
import { JsonResponse } from 'TypeGen/json-response';

type RouteComponentParams = {
    orderId: string;
};

type StatusResponse = {
    LoadTenderResponseAccepted: boolean | null;
    SentLoadTenderResponse: boolean;
    OrderNumber: number
}

type State = {
    isLoading: boolean;
    success: boolean;
    errorMessage: string;
    loadtenderStatusResponse: StatusResponse;
}

const LoadTenderResponse = () => {

    const { orderId: orderIdParam } = useParams<RouteComponentParams>();

    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadtenderStatusResponse, setLoadtenderStatusResponse] = useState<StatusResponse>(null);

    React.useEffect(() => {
        if (orderIdParam) {
            setLoading(true);
            fetchApi('/api/edi/loadtenderresponsestatus/' + orderIdParam)
                .then((response) => {
                    setLoadtenderStatusResponse(response);
                    setLoading(false);
                }).catch(() => {
                    setLoading(false);
                    setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again later.');
                });
        } else {
            setErrorMessage("Loading...");
        }
    }, [orderIdParam]);

    const setLoadTenderResponse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, loadTenderResponseAccepted: boolean) => {
        e.preventDefault();

        setLoading(true);

        const data = {
            LoadTenderResponseAccepted: loadTenderResponseAccepted,
        }

        fetchApi('/api/edi/LoadTenderResponse/' + orderIdParam, data, 'post')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    setLoading(false);
                    setSuccess(true);
                } else {
                    setLoading(false);
                    setErrorMessage(response.ErrorMessage);
                }
            })
            .catch(() => {
                setLoading(false);
                setErrorMessage('Our apologies, but the server has failed to process your request.  Please try again later.');
            });
    }

    if (loading === false && success) {
        return (<div className="container text-center">
            <br /><h2>Your response is being queued for processing.  You may now close this window.</h2>
        </div>)
    }

    if (loading === false && isNullOrWhiteSpace(errorMessage) === false) {
        return (<div className="container text-center">
            <br /><h2>{errorMessage}</h2>
        </div>)
    }

    if (!loading && loadtenderStatusResponse !== null &&
        !loadtenderStatusResponse.SentLoadTenderResponse &&
        loadtenderStatusResponse.LoadTenderResponseAccepted !== null) {
        return (<div className="container text-center">
            <br /><h2>Your response is being queued for processing.  You may now close this window.</h2>
        </div>)
    }

    if (!loading && loadtenderStatusResponse !== null &&
        loadtenderStatusResponse.SentLoadTenderResponse &&
        loadtenderStatusResponse.LoadTenderResponseAccepted !== null) {
        return (<div className="container text-center">
            <br /><h2>This load has already been {loadtenderStatusResponse.LoadTenderResponseAccepted ? "accepted" : "declined"}</h2>
        </div>)
    }

    return (
        <>
            {((loading || loadtenderStatusResponse === null) && <LoadingPanel />) ||
                <div className="container">
                    <br /><br />
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 text-center" >
                            <h3>Order Number: {loadtenderStatusResponse.OrderNumber}</h3>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 text-center">
                            <button className="btn btn-success" type="button" onClick={(e) => { setLoadTenderResponse(e, true) }}>Accept</button>
                            &emsp;&emsp;
                            <button className="btn btn-danger" type="button" onClick={(e) => { setLoadTenderResponse(e, false) }}>Decline</button>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            }
        </>
    );
}

export default LoadTenderResponse;
