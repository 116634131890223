import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { TripContext } from "../Profile";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Trip/PaySwitchAssets/view-model";
import { fetchApi } from "services/api";
import { ApiLink } from "TypeGen/api-link";
import VehicleAutoComplete from "views/AssetVehicles/VehicleAutoComplete";
import DriverAutoComplete from "views/AssetDrivers/DriverAutoComplete";
import { Button } from "@progress/kendo-react-buttons";
import CarrierAutoComplete from "views/AssetCarriers/CarrierAutoComplete";

type RouteComponentParams = {
    tripId: string;
    sequence: string;
    letter: string;
};

type Props = {
    tripId?: number;
    sequence?: number;
    letter?: string;
    onClose?: () => void;

    children?: React.ReactNode;
}

const TripPaySwitchAsset = (props: Props) => {
    const { tripId: tripIdParam, sequence: paySequenceParam, letter: payLetterParam } = useParams<RouteComponentParams>();
    const [tripId, setTripId] = useState(0);
    const [paySequence, setPaySequence] = useState(0);
    const [payLetter, setPayLetter] = useState("A");
    const { refresh: refreshTrip } = useContext(TripContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [carrierId, setCarrierId] = useState<number>(null);
    const [vehicleId, setVehicleId] = useState<number>(null);
    const [driverId1, setDriverId1] = useState<number>(null);
    const [driverId2, setDriverId2] = useState<number>(null);
    const [driverId3, setDriverId3] = useState<number>(null);
    const [links, setLinks] = useState<ApiLink[]>([]);

    const refreshTripSwitchAsset = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Trip/PaySwitchAssets/${tripId}/${paySequence}/${payLetter}`)
            .then((response: ViewModel) => {
                setLinks(response.Links);
                setCarrierId(response.CarrierID);
                setVehicleId(response.VehicleID);
                setDriverId1(response.DriverID1);
                setDriverId2(response.DriverID2);
                setDriverId3(response.DriverID3);
                setLoading(false);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [tripId, paySequence, payLetter]);

    const updateTrip = () => {
        const link = links.find(x => x.Name === "PaySwitchAssets");
        setLoading(true);
        const data = {
            CarrierID: carrierId,
            VehicleID: vehicleId,
            DriverID1: driverId1,
            DriverID2: driverId2,
            DriverID3: driverId3,
        };
        fetchApi(link.Link, data, link.Method)
            .then(() => {
                refreshTrip();
                history.push(`/Trip/${tripId}/Pay`);
            })
            .catch(e => {
                // If not problem details
                if (!e?.status) alert('Unable to update assets');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (props.tripId > 0)
        {
            setTripId(props.tripId);
            setPaySequence(props.sequence ?? 0);
            setPayLetter(props.letter ?? "A");
        }
        else
        {
            setTripId(Number(tripIdParam));
            setPaySequence(Number(paySequenceParam));
            setPayLetter(payLetterParam);
        }
    }, [tripIdParam, paySequenceParam, payLetterParam, props.tripId, props.sequence, props.letter]);

    useEffect(() => {
        if (tripId > 0)
            refreshTripSwitchAsset();
    }, [tripId, refreshTripSwitchAsset]);

    if (loading) {
        return <div className="d-flex justify-content-center mt-5">
            <Loader type="converging-spinner" />
        </div>;
    }

    return <div className="card my-2">
        <div className="card-body">
            <h5 className="card-title text-center">Switch Carrier/Vehicle/Drivers for Trip</h5>
            {carrierId !== null && <div className="form-group row">
                <label className="col-form-label form-check-label col-md-3">
                    Carrier
                </label>
                <div className="col-md-9 mt-1 pl-4">
                    <CarrierAutoComplete
                        selectedCarrierID={carrierId}
                        onSelectedCarrier={(e) => {
                            setCarrierId(e?.CarrierID ?? 0);
                        }}
                    />
                </div>
            </div>}
            {vehicleId !== null && <div className="form-group row">
                <label className="col-form-label form-check-label col-md-3">
                    Vehicle ID
                </label>
                <div className="col-md-9 mt-1 pl-4">
                    <VehicleAutoComplete
                        selectedVehicleID={vehicleId}
                        style={{ width: 200 }}
                        onSelectedVehicle={(e) => {
                            setVehicleId(e?.VehicleID ?? 0)
                        }}
                    />
                </div>
            </div>}
            {driverId1 !== null && <div className="form-group row">
                <label className="col-form-label form-check-label col-md-3">
                    Driver 1
                </label>
                <div className="col-md-9 mt-1 pl-4">
                    <DriverAutoComplete
                        selectedDriverID={driverId1}
                        style={{ width: 200 }}
                        placeholder='Select Driver 1'
                        onSelectedDriver={(e) => {
                            setDriverId1(e?.DriverID ?? 0)
                        }}
                    />
                </div>
            </div>}
            {driverId2 !== null && <div className="form-group row">
                <label className="col-form-label form-check-label col-md-3">
                    Driver 2
                </label>
                <div className="col-md-9 mt-1 pl-4">
                    <DriverAutoComplete
                        selectedDriverID={driverId2}
                        style={{ width: 200 }}
                        placeholder='Select Driver 2'
                        onSelectedDriver={(e) => {
                            setDriverId2(e?.DriverID ?? 0)
                        }}
                    />
                </div>
            </div>}
            {driverId3 !== null && <div className="form-group row">
                <label className="col-form-label form-check-label col-md-3">
                    Driver 3
                </label>
                <div className="col-md-9 mt-1 pl-4">
                    <DriverAutoComplete
                        selectedDriverID={driverId3}
                        style={{ width: 200 }}
                        placeholder='Select Driver 3'
                        onSelectedDriver={(e) => {
                            setDriverId3(e?.DriverID ?? 0)
                        }}
                    />
                </div>
            </div>}
            <div className="text-center mt-3">
                {links.find(x => x.Name == "PaySwitchAssets") && <Button themeColor="primary" onClick={updateTrip}>Update Trip</Button>}
            </div>
        </div>
    </div>
}

export default TripPaySwitchAsset;