import * as React from 'react';
import Moment from 'moment';
import { fetchApi } from '../../services/api';
import JsonPretty from '../../components/JsonPretty';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ViewModel } from 'TypeGen/AgentNote/Logs/view-model';

interface Props {
  CloseDialog: () => void;
  AgentNoteID: number,
}
const AuditLogs = (props: Props) => {

  const [loading, setLoading] = React.useState<boolean>(true);
  const [logs, setLogs] = React.useState<ViewModel>({
    Logs: [],
  });

  React.useEffect(() => {
    fetchApi(`/api/Quote/AgentNoteLogs/${props.AgentNoteID}`)
      .then((response: ViewModel) => {
        setLogs(response);
        setLoading(false);
      })
  }, [props.AgentNoteID]);

  return (
    <Dialog title="Agent Note Audit Logs" onClose={props.CloseDialog} className='dialog-w11/12'>
      {loading == false && logs.Logs.length === 0 && <div className="alert alert-info">No logs found</div>}
      {logs.Logs.length > 0 && <table className="table" style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
        <thead>
          <tr>
            <th style={{ width: '16.66%' }}>User</th>
            <th style={{ width: '16.66%' }}>Timestamp</th>
            <th style={{ width: '33.33%' }}>Old Values</th>
            <th style={{ width: '33.33%' }}>New Values</th>
          </tr>
        </thead>
        <tbody>
          {logs.Logs.map((log, index) => {
            return <tr key={index}>
              <td>{log.UserName}</td>
              <td>{Moment.utc(log.DateTime).local().format("YYYY-MM-DD HH:mm:ss")}</td>
              <td><JsonPretty json={log.OldValues} /></td>
              <td><JsonPretty json={log.NewValues} /></td>
            </tr>
          })}
        </tbody>
      </table>}
    </Dialog>
  );
}

export default AuditLogs;
