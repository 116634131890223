import * as React from 'react';
import Moment from 'moment-timezone';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class DateCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    const field = this.props.dataItem[this.props.field];

    return (
      <td>
        {field ? Moment.utc(field).tz("America/New_York").format("MM/DD/YYYY HH:mm") : ''}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
