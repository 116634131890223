import * as React from 'react';
import { Title } from '../../utils/title';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartYAxis,
    ChartYAxisItem,
    ChartXAxis,
    ChartXAxisItem,
    ChartTooltip
  } from '@progress/kendo-react-charts';
import { fetchApi } from '../../services/api';

type Metric = {
  Command: string;
  Count: number;
  FailureCount: number;
  AverageMilliseconds: number;
}

const Metrics = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const command = urlParams.get('command');
  const [data, setData] = React.useState<Metric[]>([]);

  React.useEffect(() => {
    fetchApi('/api/Metric/Metric', { command }, 'POST')
      .then((data: Metric[]) => {
        setData(data);
      });
  }, [command]);

  return <Chart>
    <Title string="Metrics" />
    <ChartTooltip format="{1:n0}" color="black" />
    <ChartYAxis>
      [<ChartYAxisItem color="rgb(2, 117, 216)" name="perf" title={{ text: 'Avg. Milliseconds' }} baseUnit="milliseconds" />,
      <ChartYAxisItem color="rgb(255, 210, 70)" name="count" title={{ text: 'Request Count' }} />,
      <ChartYAxisItem color="rgb(230, 35, 37)" name="failureCount" title={{ text: 'Failure Count' }} />]]
    </ChartYAxis>
    <ChartXAxis>
      <ChartXAxisItem name="Date" title={{ text: 'Date' }} type="date" />
    </ChartXAxis>
    <ChartSeries>
      [<ChartSeriesItem
        key="1"
        name="Avg. Milliseconds"
        color="rgb(2, 117, 216)"
        type="scatterLine"
        data={data}
        yAxis="perf"
        xField="Date"
        yField="AverageMilliseconds"
      />,
      <ChartSeriesItem
        key="2"
        name="Request Count"
        color="rgb(255, 210, 70)"
        type="scatterLine"
        data={data}
        yAxis="count"
        xField="Date"
        yField="Count"
      />,
      <ChartSeriesItem
        key="3"
        name="Failure Count"
        color="rgb(230, 35, 37)"
        type="scatterLine"
        data={data}
        yAxis="failureCount"
        xField="Date"
        yField="FailureCount"
      />]
    </ChartSeries>
  </Chart>
}

export default Metrics;
