import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'ts-debounce';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { openWindowPerc } from '../../services/openWindow';
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';
import DriverAutoComplete from '../AssetDrivers/DriverAutoComplete';
import OwnerAutoComplete from '../AssetOwners/OwnerAutoComplete';
import VehicleAutoComplete from '../AssetVehicles/VehicleAutoComplete';
import { DeductionType } from './DeductionCOAs';
import PayPeriodDropDownList from './PayPeriodDropDownList';
import Moment from 'moment-timezone';
import { filterClearIcon } from '@progress/kendo-svg-icons';

export enum DeductionScheduleType { Recurring = 1, Advance = 2, Amortized = 3 };
export const deductionScheduleTypeData = [
  { ID: DeductionScheduleType.Recurring, Name: DeductionScheduleType[DeductionScheduleType.Recurring] },
  { ID: DeductionScheduleType.Advance, Name: DeductionScheduleType[DeductionScheduleType.Advance] },
  { ID: DeductionScheduleType.Amortized, Name: DeductionScheduleType[DeductionScheduleType.Amortized] }] as IDName[];
export enum DeductionStatusType { Pending = 1, PaidComplete = 2, OnHold = 3, Canceled = 4 };
const statusData = [
  { ID: DeductionStatusType.Pending, Name: 'Pending' },
  { ID: DeductionStatusType.PaidComplete, Name: 'Paid/Complete' },
  { ID: DeductionStatusType.OnHold, Name: 'On Hold' },
  { ID: DeductionStatusType.Canceled, Name: 'Canceled' }] as IDName[];

export interface ScheduledDeduction {
  PayDeductionID: number;
  BusinessUnitID: number;

  DriverID: number;
  DriverNumber: string;
  VehicleID: number;
  VehicleNumber: string;
  ScheduleType: number;
  ScheduleTypeName: string;
  FirstPeriodID: number;
  FirstPayPeriodName: string;
  DeductionTypeID: number;
  DeductionTypeName: string;
  Status: number;
  StatusName: string;

  Comments: string

  TotalAmount: number;
  AmortizeOver: number;
  AmountPerPay: number;

  Paid: number;
  Balance: number;
  PaymentsRemaining: number;
  UnpaidFutureAmount: number;

  ModifiedByUserName: string;
  ModifiedDateTime: Date;

  inEdit?: boolean | string;
  isNew?: boolean;
  Delete?: boolean;
}

const ScheduledDeductionsContext = createContext<{
  DeductionTypes: DeductionType[],
}>({
  DeductionTypes: []
})

interface EditCommandCellProps extends GridCellProps {
  edit: (item: ScheduledDeduction) => void;
  remove: (item: ScheduledDeduction) => void;
  add: (item: ScheduledDeduction) => void;
  discard: () => void;
  update: (item: ScheduledDeduction) => void;
  cancel: (item: ScheduledDeduction) => void;
  editField: string;
}

const PayDeductionIDCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: ScheduledDeduction = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
      {props.dataItem.isNew === true ? <span>-</span> : <Link to="#" style={{ color: "#007bff" }}
        onClick={(e) => {
          openWindowPerc(`/Settlements/PendingDeductions?PayDeductionID=${dataItem.PayDeductionID}`, .9, .9);
          e.preventDefault();
        }}>{dataItem.PayDeductionID}</Link>}
    </td>
  );
}

const AssetCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: ScheduledDeduction = props.dataItem;

  const handleChange = (resourceID: number | null, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field, //props.field,
        syntheticEvent: null,
        value: resourceID ?? 0,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.isNew ? <DriverAutoComplete
        includeRecentlyRetiredDrivers={true}
        selectedDriverID={dataItem.DriverID}
        onSelectedDriver={(e) => handleChange(e?.DriverID, "DriverID")}
      /> :
        <div>{dataItem.DriverNumber}</div>
      }

      {dataItem.isNew ? <VehicleAutoComplete
        includeRecentlyRetiredVehicles={true}
        selectedVehicleID={dataItem.VehicleID}
        onSelectedVehicle={(e) => handleChange(e?.VehicleID, "VehicleID")}
      /> :
        <div>{dataItem.VehicleNumber}</div>
      }
    </td>
  );
};

const AssetHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick}>
    <div>Driver ID</div>
    <div>Vehicle ID</div>
    {props.children}
  </a>);
};

const ScheduleHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick}>
    <div>Deduction Type</div>
    <div>Schedule</div>
    <div>Starting Pay Period</div>
    {props.children}
  </a>);
};

const AmountHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    <div>Total Amount</div>
    {props.children}
  </a>);
};

const ScheduleCell = (props: GridCellProps) => {
  const context = useContext(ScheduledDeductionsContext);

  if (!props.field)
    return null;

  let dataItem: ScheduledDeduction = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent, value: number, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field, //props.field,
        syntheticEvent: e.syntheticEvent,
        value: value,
      });
    }
  };

  const handleChangePayPeriod = (value: number, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field, //props.field,
        syntheticEvent: undefined,
        value: value,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.isNew ? <DropDownList
        className="my-1"
        data={context.DeductionTypes}
        textField="Name"
        dataItemKey="PayDeductionTypeID"
        value={context.DeductionTypes.find((x: DeductionType) => x.PayDeductionTypeID == dataItem.DeductionTypeID)}
        onChange={(e) => handleChange(e, e.target.value.PayDeductionTypeID, 'DeductionTypeID')}
      /> :
        <div>{dataItem.DeductionTypeName}</div>
      }

      {dataItem.isNew ? <DropDownList
        className="my-1"
        data={deductionScheduleTypeData}
        textField="Name"
        dataItemKey="ID"
        value={deductionScheduleTypeData.find((x: IDName) => x.ID == dataItem.ScheduleType)}
        onChange={(e) => handleChange(e, e.target.value.ID, 'ScheduleType')}
      /> :
        <div>{dataItem.ScheduleTypeName}</div>
      }

      {dataItem.isNew ? <PayPeriodDropDownList
        className="my-1"
        onSelected={(e) => {
          handleChangePayPeriod(e.PayPeriodID, 'FirstPeriodID');
        }}
        selectedPayPeriodID={dataItem.FirstPeriodID}
        includeClosedPayPeriods={false}
        includeOpenPayPeriods={true}
      /> :
        <div>{dataItem.FirstPayPeriodName}</div>
      }
    </td>
  );
};

const StatusCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: ScheduledDeduction = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent, value: number, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: value,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={statusData}
        textField="Name"
        dataItemKey="ID"
        value={statusData.find((x: IDName) => x.ID == dataItem.Status)}
        onChange={(e) => handleChange(e, e.target.value.ID, 'Status')}
      /> :
        <div>{dataItem.StatusName}</div>
      }
    </td>
  );
};

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: ScheduledDeduction = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}<br />{dataItem.ModifiedByUserName}</span>
    </td>
  );
};

const AmountCell = (props: GridCellProps) => {
  //(e: DropDownListChangeEvent, value: number, field: string) => {
  const handleChange = (e: NumericTextBoxChangeEvent, field: string) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: e.value
      });
    }
  };

  let dataItem: ScheduledDeduction = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      <div>
        {dataItem.isNew ? <NumericTextBox format="n2" value={dataItem.TotalAmount} onChange={(e) => handleChange(e, 'TotalAmount')} inputStyle={{ textAlign: "right" }}
          placeholder={`Total Amount`} /> :
          <span>{formatNumber(dataItem.TotalAmount, "c2")}</span>
        }
      </div>
      <div>
        {dataItem.isNew && dataItem.ScheduleType === DeductionScheduleType.Amortized ?
          <div>
            <label className="mr-2">Amortize Over:</label>
            <NumericTextBox format="n0" value={dataItem.AmortizeOver} onChange={(e) => handleChange(e, 'AmortizeOver')} min={1} max={99}
              width={75}
              inputStyle={{ textAlign: "right" }}
              placeholder={`Amortize Over`} />
            <div className="mt-1"><label className="mr-2">Amortized Amount:</label>
              <span>{(dataItem.TotalAmount > 0 && dataItem.AmortizeOver > 0) ? formatNumber(dataItem.TotalAmount / dataItem.AmortizeOver, "c2") : '$0.00'}</span>
            </div>
          </div> : dataItem.ScheduleType === DeductionScheduleType.Amortized &&
          <div>
            <Tooltip openDelay={450} position="auto" anchorElement="target" parentTitle={true} tooltipStyle={{ fontSize: "small" }}>
              <div title={`Amount Deducted: ${formatNumber(dataItem.Paid, "c2")} | Outstanding Balance: ${formatNumber(dataItem.Balance, "c2")} | # of Payments Left: ${formatNumber(dataItem.PaymentsRemaining, "n0")} | Open Pay Period Pending Deductions Amt. Generated: ${formatNumber(dataItem.UnpaidFutureAmount, "c2")}`}>
                <span className="mr-2" >{formatNumber(dataItem.AmortizeOver, "n0")}</span>
                <span className="mr-2" >x</span>
                <span>{formatNumber(dataItem.AmountPerPay, "c2")}</span>
              </div>
            </Tooltip>
          </div>
        }
      </div>
    </td >
  );
};

export const textBoxCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  let dataItem: ScheduledDeduction = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ?
        <TextBox
          value={props.dataItem[props.field] ?? ''}
          onChange={handleChange}
          style={{ width: '100%' }}
        /> :
        <span>{props.dataItem[props.field]}</span>
      }
    </td>
  );
};


const ScheduledDeductions = () => {
  const render = useRef(true);
  const _requestHash = useRef('');

  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "PayDeductionID",
      dir: "desc"
    }]
  } as GridState;

  //const defaultAndFilter = {
  //    logic: 'and', filters: []
  //} as CompositeFilterDescriptor;

  const defaultAndFilter = {
    logic: 'and', filters: [
      { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
      { field: 'Status', operator: 'eq', value: 1 },
    ]
  } as CompositeFilterDescriptor;

  const SESSION_STORAGE_NAME = "ScheduledDeductionsFilterV1";
  const sessionStorageAndFilter = sessionStorage.getItem(SESSION_STORAGE_NAME);
  const sessionStorageOwnerFilter = sessionStorage.getItem("SchedDeductions-ownerFilter");
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
  const totalRecords = useRef(0);
  const DataState_Take = 50;

  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const editField: string = "inEdit";
  const [scheduledDeductions, setScheduledDeductions] = useState<ScheduledDeduction[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<ScheduledDeduction[]>([]);
  const [deductionTypes, setDeductionTypes] = useState<DeductionType[]>([]);

  const andFilterDescriptors = (andFilter.filters as FilterDescriptor[]);
  const [filterByDriverId, setFilterByDriverId] = useState<number | undefined | null>(andFilterDescriptors.find(x => x.field === 'DriverID')?.value);
  const [filterByVehicleId, setFilterByVehicleId] = useState<number | undefined | null>(andFilterDescriptors.find(x => x.field === 'VehicleID')?.value);
  const [filterByOwnerId, setFilterByOwnerId] = useState<number | undefined | null>(sessionStorageOwnerFilter ? Number(sessionStorageOwnerFilter) : null);
  const [filterByStatus, setFilterByStatus] = useState<number | null>(andFilterDescriptors.find(x => x.field === 'Status')?.value ?? 1);

  const [filterBySchedule, setFilterBySchedule] = useState<number | null>(andFilterDescriptors.find(x => x.field === 'ScheduleType')?.value ?? 0);
  const [filterByDeductionType, setFilterByDeductionType] = useState<number | null>(andFilterDescriptors.find(x => x.field === 'DeductionTypeID')?.value ?? 0);
  const [filterByPayPeriod, setFilterByPayPeriod] = useState<number | null>(andFilterDescriptors.find(x => x.field === 'FirstPeriodID')?.value ?? 0);

  const EditCommandCell = (_props: EditCommandCellProps) => {
    const inEdit = _props.dataItem[_props.editField];
    let dataItem: ScheduledDeduction = _props.dataItem;
    const isNewItem = dataItem.isNew;

    const disableSave = (dataItem.DriverID === 0 && dataItem.VehicleID === 0) || dataItem.DeductionTypeID === 0 || dataItem.ScheduleType === 0 || dataItem.FirstPeriodID === 0 ||
      (dataItem.TotalAmount === 0 || dataItem.TotalAmount === undefined) || (dataItem.ScheduleType === DeductionScheduleType.Amortized && dataItem.AmortizeOver < 1);

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disableSave}
          onClick={async () => {

            if (dataItem.TotalAmount < 0 && !await confirm(`Hit 'OK' to confirm negative deduction or 'Cancel' to correct.`))
              return;

            isNewItem ? _props.add(dataItem) : _props.update(dataItem)
          }}
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? _props.discard() : _props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={scheduledDeductions.some(x => x.inEdit === true)}
          onClick={() => _props.edit(dataItem)}>Edit</Button>
        {/*<Button themeColor={"secondary"}*/}
        {/*    disabled={scheduledDeductions.some(x => x.inEdit === true)}*/}
        {/*    onClick={async () => {*/}
        {/*        if (!await confirm(`Remove ?`))*/}
        {/*            return;*/}
        {/*        _props.remove(dataItem);*/}
        {/*    }}>Delete</Button>*/}
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const enterEdit = (dataItem: ScheduledDeduction) => {
    if (scheduledDeductions.some(x => x.inEdit === true))
      return;

    setScheduledDeductions(
      scheduledDeductions.map((item) =>
        item.PayDeductionID === dataItem.PayDeductionID ? { ...item, inEdit: true } : item
      )
    );
  };

  const remove = (dataItem: ScheduledDeduction) => {
    throw new Error("Delete not fully supported yet."); //todo: remove it from data array
    //updateScheduledDeduction({ ...dataItem, Delete: true });
  };

  const add = (dataItem: ScheduledDeduction) => {
    updateScheduledDeduction(dataItem);
  };

  const update = (dataItem: ScheduledDeduction) => {
    updateScheduledDeduction(dataItem);
  };

  const cancel = (dataItem: ScheduledDeduction) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.PayDeductionID === dataItem.PayDeductionID
    );
    const newData = scheduledDeductions.map((item) =>
      item.PayDeductionID === originalItem.PayDeductionID ? cloneObject(originalItem) : item
    );

    setScheduledDeductions(newData);
  };

  const generateId = () =>
    scheduledDeductions.reduce((acc, current) => Math.max(acc, current.PayDeductionID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, isNew: true, Delete: false,
      PayDeductionID: generateId(),
      AmortizeOver: 1,
      AmountPerPay: 0,
      Comments: '',
      DeductionTypeID: 0,
      DriverID: 0,
      VehicleID: 0,
      FirstPeriodID: 0,
      ScheduleType: 0,
      Status: DeductionStatusType.Pending,
      TotalAmount: undefined,
      Paid: 0,
      Balance: 0,
      PaymentsRemaining: 0,
      UnpaidFutureAmount: 0
    } as ScheduledDeduction;
    setScheduledDeductions([newDataItem, ...scheduledDeductions]);
  };

  const discard = () => {
    const newData = [...scheduledDeductions];
    newData.splice(0, 1);
    setScheduledDeductions(newData);
  };

  const SaveAndFetchPostDataState = (_dataState: DataSourceRequestState, _filterByOwnerID: number) => {
    setDataState(_dataState);

    setLoading(true);
    _requestHash.current = Math.random().toString(36).substring(7);
    debounceSearchInput(() => fetchRecords(false, _requestHash.current, _dataState, _filterByOwnerID));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((func: () => void) => {
      func();
    }, 750), []);

  //const fetchRecords = useCallback((append: boolean) => {
  const fetchRecords = useCallback((append: boolean, requestHash: string, _dataState: DataSourceRequestState = null, _filterByOwnerID: number | null = null) => {
    setLoading(true);
    const dataGridState = _dataState == null ? { ...dataState } : { ..._dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      document.querySelector('.SchedDeductions-andFilter .k-grid-content').scrollTop = 0;
      setDataState(dataGridState);
    }

    sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(dataGridState.filter));

    let queryStr = `${toDataSourceRequestString(dataGridState)}`;
    if (_filterByOwnerID == null) {
      if (filterByOwnerId > 0) {
        queryStr += `&OwnerID=${filterByOwnerId}`
      }
    } else {
      if (_filterByOwnerID > 0) {
        queryStr += `&OwnerID=${_filterByOwnerID}`
      }
    }

    fetchApi(`/api/Settlements/GetScheduledDeductions?${queryStr}`, {}, 'POST')
      .then(({ Data, Total }) => {
        if (_requestHash.current === requestHash) {
          totalRecords.current = Total;
          if (!append) {
            setScheduledDeductions(Data);
            setCSIDataCopy(Data);
          } else {
            setScheduledDeductions(prevData => prevData.concat(Data));
            setCSIDataCopy(prevData => prevData.concat(Data));
          }
        }
        setLoading(false);
        //  handleResize(null); //need this if using hierarchy filters
      }).catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [alert, dataState, filterByOwnerId]);

  useEffect(() => {
    if (render.current === true) {
      render.current = false;
    } else {
      return;
    }

    _requestHash.current = Math.random().toString(36).substring(7);
    fetchRecords(false, _requestHash.current);
  }, [fetchRecords]);

  useEffect(() => {
    fetchApi('/api/settlements/getdeductiontypes')
      .then((response: any) => {
        setDeductionTypes((response.DeductionTypes as DeductionType[]).filter(x => x.Active === true));
      });
  }, []);

  //need this if using hierarchy filters
  //const handleResize = (e: UIEvent) => {
  //    if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
  //        ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
  //    }
  //}

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (scheduledDeductions.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      _requestHash.current = Math.random().toString(36).substring(7);
      fetchRecords(true, _requestHash.current);
    }
  }

  const cloneObject = (obj: any): any => {
    return JSON.parse(JSON.stringify(obj));
  }

  const updateObject = (updatedObject: ScheduledDeduction, payDeductionID: number) => {
    const updatedObjects = scheduledDeductions.map((obj) =>
      obj.PayDeductionID === payDeductionID ? updatedObject : obj
    );
    setScheduledDeductions(updatedObjects);
    setCSIDataCopy(updatedObjects);
  };

  //use if calling fetching records and you wish update an array of objects at once.
  const updateObjects = (updatedObjectsArray: ScheduledDeduction[]) => {
    const updatedObjects = scheduledDeductions.map((obj) => {
      const updatedObject = updatedObjectsArray.find((updatedObj) => updatedObj.PayDeductionID === obj.PayDeductionID);
      return updatedObject ? updatedObject : obj;
    });
    setScheduledDeductions(updatedObjects);
  };

  const fetchAndUpdateObject = (dataItem: ScheduledDeduction) => {
    fetchApi(`/api/Settlements/GetScheduledDeductions?page=1&pageSize=1&sort=PayDeductionID-desc&filter=PayDeductionID~eq~${dataItem.PayDeductionID}`, {}, 'POST')
      .then(({ Data }) => {
        setLoading(false);
        updateObject(Data[0], dataItem.PayDeductionID);
      }).catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }

  const updateScheduledDeduction = (dataItem: ScheduledDeduction) => {
    setLoading(true);

    const data = { ...dataItem }
    fetchApi(`api/Settlements/ScheduledDeduction`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else {
          if (data.Delete !== true) {
            fetchAndUpdateObject(data);
          }
        }
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }


  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = scheduledDeductions.map((item) =>
      item.PayDeductionID === event.dataItem.PayDeductionID
        ? { ...item, [field]: event.value }
        : item
    );
    setScheduledDeductions(newData);
  }

  const removeFilter = (field: string) => {
    var hasFilter = (dataState.filter.filters as FilterDescriptor[]).some(x => x.field === field);
    if (hasFilter) {
      dataState.filter.filters = (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field !== field);
      SaveAndFetchPostDataState({ ...dataState }, filterByOwnerId);
      //setDataState({ ...dataState });
    }
  }

  const updateOrAddFilter = (filterDescriptor: FilterDescriptor) => {
    var hasFilter = (dataState.filter.filters as FilterDescriptor[]).some(x => x.field === filterDescriptor.field);

    if (hasFilter) {
      const updatedFilters = (dataState.filter.filters as FilterDescriptor[]).map((obj) =>
        obj.field === filterDescriptor.field ? filterDescriptor : obj
      );
      SaveAndFetchPostDataState({ ...dataState, filter: { ...dataState.filter, filters: updatedFilters } }, filterByOwnerId);
      //setDataState({ ...dataState, filter: { ...dataState.filter, filters: updatedFilters } });
    } else {
      (dataState.filter.filters as FilterDescriptor[]).push(filterDescriptor);
      SaveAndFetchPostDataState({ ...dataState }, filterByOwnerId);
      //setDataState({ ...dataState });
    }
  };

  const dataView = () => {
    return (<div className="mt-3" style={{ position: "relative" }}>
      <Title string="Scheduled Deductions" />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <h4 className="text-left">Scheduled Deductions</h4>
      <ScheduledDeductionsContext.Provider value={{ DeductionTypes: deductionTypes ?? [] }}>
        <Grid
          className='SchedDeductions-andFilter'
          style={{
            maxHeight: `${window.innerHeight * .80}px`,
          }}
          data={scheduledDeductions}
          {...dataState}
          total={totalRecords.current}
          onScroll={scrollHandler}
          resizable={true}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
          onItemChange={itemChange}
          editField={editField}
        >
          <GridToolbar>
            <Button themeColor={'primary'}
              disabled={scheduledDeductions.some(x => x.inEdit === true)}
              title="Add new deduction"
              onClick={addNew}
            >
              Add New Deduction
            </Button>

          </GridToolbar>
          <GridToolbar>
            <label className="mt-1">Filters:</label>
            <DriverAutoComplete
              selectedDriverID={filterByDriverId}
              includeRecentlyRetiredDrivers={true}
              style={{ width: '200px' }}
              onSelectedDriver={(e) => {
                if (e?.DriverID > 0) {
                  updateOrAddFilter({ field: 'DriverID', operator: 'eq', value: e?.DriverID });
                } else {
                  removeFilter('DriverID');
                }
                setFilterByDriverId(e?.DriverID);
              }}
            />

            <VehicleAutoComplete
              selectedVehicleID={filterByVehicleId}
              includeRecentlyRetiredVehicles={true}
              style={{ width: '200px' }}
              onSelectedVehicle={(e) => {
                if (e?.VehicleID > 0) {
                  updateOrAddFilter({ field: 'VehicleID', operator: 'eq', value: e?.VehicleID });
                } else {
                  removeFilter('VehicleID');
                }
                setFilterByVehicleId(e?.VehicleID);
              }}
            />

            <OwnerAutoComplete
              selectedOwnerID={filterByOwnerId}
              style={{ width: '200px' }}
              onSelectedOwner={(e) => {
                if (e?.OwnerID > 0)
                  sessionStorage.setItem("SchedDeductions-ownerFilter", e?.OwnerID.toString());
                else
                  sessionStorage.removeItem("SchedDeductions-ownerFilter");

                setFilterByOwnerId(e?.OwnerID);
                SaveAndFetchPostDataState(dataState, e?.OwnerID ?? 0);
              }}
            />

            <DropDownList
              style={{ width: '150px' }}
              data={[{ ID: 0, Name: 'Any Status' }].concat(statusData)}
              textField="Name"
              dataItemKey="ID"
              value={[{ ID: 0, Name: 'Any Status' }].concat(statusData).find((x: IDName) => x.ID == filterByStatus)}
              onChange={(e) => {
                setFilterByStatus(e.target.value.ID);
                if (e.target.value.ID > 0) {
                  updateOrAddFilter({ field: 'Status', operator: 'eq', value: e.target.value.ID });
                } else {
                  removeFilter('Status');
                }
              }}
            />

            <DropDownList
              style={{ width: '150px' }}
              data={[{ ID: 0, Name: 'Any Schedule' }].concat(deductionScheduleTypeData)}
              textField="Name"
              dataItemKey="ID"
              value={[{ ID: 0, Name: 'Any Schedule' }].concat(deductionScheduleTypeData).find((x: IDName) => x.ID == filterBySchedule)}
              onChange={(e) => {
                setFilterBySchedule(e.target.value.ID);
                if (e.target.value.ID > 0) {
                  updateOrAddFilter({ field: 'ScheduleType', operator: 'eq', value: e.target.value.ID });
                } else {
                  removeFilter('ScheduleType');
                }
              }}
            />

            <DropDownList
              style={{ width: '225px' }}
              data={[{ PayDeductionTypeID: 0, Name: 'Any Type' }].concat(deductionTypes)}
              textField="Name"
              dataItemKey="PayDeductionTypeID"
              value={[{ PayDeductionTypeID: 0, Name: 'Any Type' }].concat(deductionTypes).find((x) => x.PayDeductionTypeID == filterByDeductionType)}
              onChange={(e) => {
                setFilterByDeductionType(e.target.value.PayDeductionTypeID);
                if (e.target.value.PayDeductionTypeID > 0) {
                  updateOrAddFilter({ field: 'DeductionTypeID', operator: 'eq', value: e.target.value.PayDeductionTypeID });
                } else {
                  removeFilter('DeductionTypeID');
                }
              }}
            />

            <PayPeriodDropDownList
              style={{ width: 250 }}
              onSelected={(e) => {
                setFilterByPayPeriod(e.PayPeriodID);
                if (e.PayPeriodID > 0) {
                  updateOrAddFilter({ field: 'FirstPeriodID', operator: 'eq', value: e.PayPeriodID });
                } else {
                  removeFilter('FirstPeriodID');
                }
              }}
              selectedPayPeriodID={filterByPayPeriod}
              includeClosedPayPeriods={true}
              includeOpenPayPeriods={true}
              defaultPayPeriodItem={{ PayPeriodID: 0, Label: 'Any Pay Period' }}
            />

            <Button
              title="Clear All Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={() => {
                setFilterByDriverId(null);
                setFilterByVehicleId(null);
                setFilterByOwnerId(null);
                setFilterByStatus(1);
                setFilterByPayPeriod(0);
                setFilterBySchedule(0);
                setFilterByDeductionType(0);
                sessionStorage.removeItem("SchedDeductions-ownerFilter");

                //setDataState({ ...dataState, filter: { ...defaultAndFilter } });
                SaveAndFetchPostDataState({ ...dataState, filter: { ...defaultAndFilter } }, 0);
              }}
            />
          </GridToolbar>
          <Column field="PayDeductionID" title="ID" editable={false} cell={PayDeductionIDCell} width={65} filterable={false} />
          <Column field="DriverID" title="Driver/Vehicle" cell={AssetCell} headerCell={AssetHeaderCell} />
          <Column field="ScheduleType" title="Schedule" cell={ScheduleCell} headerCell={ScheduleHeaderCell} filterable={false} />
          <Column field="TotalAmount" title="Total Amount" cell={AmountCell} headerCell={AmountHeaderCell} filterable={false} />
          <Column field="Comments" title="Comments" cell={textBoxCell} filterable={false} />
          <Column field="Status" title="Status" cell={StatusCell} width={125} filterable={false} />
          <Column field="ModifiedByUserName" title="Modified By" width={125} cell={LastModifiedByCell} />
          <Column cell={MyEditCommandCell} width={160} filterable={false} />
        </Grid>
      </ScheduledDeductionsContext.Provider>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
      <ConfirmationDialog />
    </div>);
  };

  return dataView();
}

export default ScheduledDeductions;
