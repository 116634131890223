import * as React from 'react';
import moment from 'moment-timezone';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class LocalDateCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    const field = this.props.dataItem[this.props.field];

    return (
      <td>
        {field ? moment.utc(field).tz(moment.tz.guess()).format("MM/DD/YYYY HH:mm z") : ''}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
