import * as React from 'react';
import { Title } from '../../utils/title';
import { process, FilterDescriptor, DataSourceRequestState, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar, GridDataStateChangeEvent, GridColumnMenuFilter, GridColumnProps, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { Window } from '@progress/kendo-react-dialogs';
import LoadBoardDetailRow from './DetailRow';
import ColumnMenu from './BidHistory/ColumnMenu';
import { LoadOneReconnectPolicy } from '../../services/signalr/retryPolicy';
import MyCommandCell from './MyCommandCell';
import CountdownCell from './Countdown';
import CoyoteOffers from './CoyoteOffers';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import useForceUpdate from '../../utils/useForceUpdate';
import DateCell from '../../components/cells/DateCell';
import { Button } from '@progress/kendo-react-buttons';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';

export type OpenShipment = {
  ShipmentID: string;
  Reference: string;
  Customer: string;
  PickupLocation: string;
  PickupTime: Date;
  DeliveryLocation: string;
  DeliveryTime: Date;
  Weight: number;
  Pieces: number;
  Dimensions: string;
  VehicleType: string;
  Distance: number;
  DistanceOverride: number;
  Posted: Date;
  Expiry: Date | null;
  ReservedByUserID: string;
  IsSpecialized: boolean;

  expanded?: boolean;
}

export const LoadBoard = () => {

  let andFilter = [];
  const sessionStorageAndFilter = sessionStorage.getItem("LoadBoard-andFilter");
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  }
  const [dataState, setDataState] = React.useState<DataSourceRequestState>({
    skip: 0,
    take: 20,
    sort: [{
      field: "Posted",
      dir: "asc"
    }],
    filter: { logic: 'and', filters: andFilter }
  });

  const [andFilters, setAndFilters] = React.useState<CompositeFilterDescriptor[]>(andFilter);
  const [data, setData] = React.useState<OpenShipment[]>([]);
  const [connection, setConnection] = React.useState<HubConnection>(null);

  const refresh = React.useCallback(() => {
    if (connection.state == HubConnectionState.Connected) {
      connection.invoke('read')
        .then((shipments: OpenShipment[]) => {
          setData((prevState) => shipments.map(s => {
            const expanded = prevState.find(p => p.ShipmentID == s.ShipmentID)?.expanded;
            return { ...s, expanded };
          }));
        });
    }
  }, [connection]);

  React.useEffect(() => {
    const builder = new HubConnectionBuilder()
      .withAutomaticReconnect(new LoadOneReconnectPolicy())
      .withUrl("/loadboard")
      .configureLogging(LogLevel.Warning)
      .build();

    setConnection(builder)
  }, []);

  React.useEffect(() => {
    async function startConnection() {
      try {

        connection.on("create", (shipment: any) => {
          if (Array.isArray(shipment)) {
            setData((prevState) => ([...shipment, ...prevState]));

            // Filter
            const notifyShipments = localStorage.getItem('LoadBoard-NotifyWatchingOnly') ? process(shipment, dataState).data : shipment;

            // Show Notification
            if ('Notification' in window && notifyShipments.length) {
              Notification.requestPermission((status) => {
                  if (status !== 'denied') {
                    new Notification(notifyShipments.length + " New " + notifyShipments[0].Reference.split('-')[0] + " Shipments!")
                      .onclick = () => {
                        window.focus();
                      };
                  }
              });
            }
          } else {
            // Fancy code is to handle releasing a reserved shipment
            const index = data.findIndex(x => x.ShipmentID === shipment.ShipmentID);
            if (index > -1) {
              setData((prevState) => ([
                ...prevState.slice(0, prevState.findIndex(x => x.ShipmentID === shipment.ShipmentID)),
                shipment,
                ...prevState.slice(prevState.findIndex(x => x.ShipmentID === shipment.ShipmentID) + 1)]));
            } else {
              setData((prevState) => ([shipment, ...prevState]));
            }
          }
        });

        connection?.on('destroy', (shipment: any) => {
          if (Array.isArray(shipment)) {
            const shipmentIds = shipment.map(x => x.ShipmentID as string);
            setData((prevState) => (prevState.filter(x => shipmentIds.findIndex(i => i === x.ShipmentID) === -1)));
          } else {
            setData((prevState) => (prevState.filter(x => x.ShipmentID !== shipment.ShipmentID)));
          }
        });

        await connection?.start();

        refresh();
      } catch (error) {
        console.error(error)
      }
    }

    if (connection) {
      startConnection()
    }

    return () => {
      connection?.stop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, refresh]);

  const forceUpdate = useForceUpdate();

  const columnProps = (field: string): Partial<GridColumnProps> =>{
    return {
        field: field,
        columnMenu: ColumnMenu,
        headerClassName: isColumnActive(field, dataState) ? 'active' : ''
    };
  }

  const isColumnActive = (field: string, dataState: any) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  const hasFilterArray = (filters: Array<FilterDescriptor>): boolean => {
    return andFilters
      .filter(x => x.filters.length === filters.length)
      .map(m => m.filters as FilterDescriptor[])
      .filter(x => JSON.stringify(x) === JSON.stringify(filters))
      .length > 0;

  }

  const filterArray = (filters: FilterDescriptor[]) => {
    let newFilter: CompositeFilterDescriptor[] = [];
    if (hasFilterArray(filters)) {
      newFilter = andFilters.filter(x => JSON.stringify(x.filters) !== JSON.stringify(filters));
    } else {
      newFilter = [...andFilters, { logic: "and", filters } as CompositeFilterDescriptor];
    }
    dataStateChange({ dataState: {
      skip: 0,
      take: dataState.take,
      sort: dataState.sort,
      filter: { logic: 'or', filters: newFilter }
    } } as GridDataStateChangeEvent);
    setAndFilters(newFilter);
    sessionStorage.setItem("LoadBoard-andFilter", JSON.stringify(newFilter));
  }

  const reset = () => {
    setAndFilters([]);
    dataStateChange({ dataState: {
      skip: 0,
      take: 20,
      sort: [{field: "Posted", dir: "asc"}],
      filter: { logic: 'and', filters: [] }
    } } as GridDataStateChangeEvent);
    sessionStorage.removeItem("LoadBoard-andFilter");
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const expandChange = (e: GridExpandChangeEvent) => {
    e.dataItem.expanded = !e.dataItem.expanded;
    forceUpdate();
  }

  const reserve = (shipment: any) => {
    if (connection.state == HubConnectionState.Connected) {
      connection.invoke("Reserve", shipment);
    }
  }

  const release = (shipment: any) => {
    if (connection.state == HubConnectionState.Connected) {
      connection.invoke("Release", shipment);
    }
  }

  const decline = (shipment: any) => {
    if (connection.state == HubConnectionState.Connected) {
      connection.invoke("Destroy", shipment)
        .then(() => {
          setData(data.filter(x => x.ShipmentID !== shipment.ShipmentID));
        });
    }
  }

  const CommandCell = MyCommandCell(reserve, release, decline);

  const gridData = process(data, dataState);
  return (
    <React.Fragment>
      <Title string="Load Board" />
      <Grid sortable={{ allowUnsort: true }}
          pageable={{ pageSizes: [20, 50, 100, 500] }}
          scrollable={'none'}
          total={gridData.total}
          data={gridData.data}
          onDataStateChange={dataStateChange}
          detail={LoadBoardDetailRow}
          expandField="expanded"
          onExpandChange={expandChange}
          {...dataState}
        >
        <GridToolbar>
        <Button
          title="Clear Filters and Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={reset}
        />
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refresh}
        />
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'AA-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'AA-'}])}
        >AA
        </Button>
        <Button
          togglable
          selected={hasFilterArray([
            { field: 'Reference', operator: 'startswith', value: 'XPO-'},
            { field: "IsSpecialized", operator: "eq", value: false }
          ])}
          onClick={() => filterArray([
            { field: 'Reference', operator: 'startswith', value: 'XPO-'},
            { field: "IsSpecialized", operator: "eq", value: false }
          ])}
        >XPO
        </Button>
        <Button
          togglable
          selected={hasFilterArray([
            { field: 'Reference', operator: 'startswith', value: 'XPO-'},
            { field: "IsSpecialized", operator: "eq", value: true }
          ])}
          onClick={() => filterArray([
            { field: 'Reference', operator: 'startswith', value: 'XPO-'},
            { field: "IsSpecialized", operator: "eq", value: true }
          ])}
        >XPO-Specialized
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'SYL-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'SYL-'}])}
        >Sylectus
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'ECCO-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'ECCO-'}])}
        >ECCO
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'RYDR-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'RYDR-'}])}
        >Ryder
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'CYT-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'CYT-'}])}
        >Coyote
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'CAT-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'CAT-'}])}
        >Caterpillar
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'SHAW-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'SHAW-'}])}
        >Shaw
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'DAKK-' }])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'DAKK-' }])}
        >Dakkota
        </Button>
        <Button
          togglable
          selected={hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'PEPSI-'}])}
          onClick={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'PEPSI-'}])}
        >Pepsi
        </Button>
        </GridToolbar>
        <Column {...columnProps("Reference")} title="Reference" />
        <Column {...columnProps("Customer")} title="Customer" />
        <Column {...columnProps("PickupLocation")} title="Pickup" />
        <Column {...columnProps("PickupTime")}  title="Pickup Time" filter="date" cell={DateCell} />
        <Column {...columnProps("DeliveryLocation")} title="Delivery" />
        <Column {...columnProps("DeliveryTime")} title="Delivery Time" filter="date" cell={DateCell} />
        <Column {...columnProps("Pieces")} filter="numeric" />
        <Column {...columnProps("Weight")} title="Weight (lbs)" filter="numeric" format="{0:n}" />
        <Column {...columnProps("Dimensions")} title="LxWxH (in.)" />
        <Column {...columnProps("Distance")} title="Miles" filter="numeric" format="{0:n0}" />
        <Column {...columnProps("VehicleType")} title="Vehicle Type" />
        <Column {...columnProps("Posted")} filter="date" cell={DateCell} />
        <Column {...columnProps("Expiry")} filter="date" cell={CountdownCell} />
        <Column cell={CommandCell} />
      </Grid>
      {hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'CYT-'}]) &&
        <Window 
          title={<div className="k-window-title" style={{ color: '#41ff23' }}>Coyote My Offers</div>}
          onClose={() => filterArray([{ field: 'Reference', operator: 'startswith', value: 'CYT-'}])}
          style={{ position: 'fixed' }}
          initialHeight={350}
          initialWidth={550}
          initialLeft={window.innerWidth - 550}
          initialTop={window.innerHeight - 350}
        >
          <CoyoteOffers />
        </Window>}
    </React.Fragment>
  );
}

export default LoadBoard;
