import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { LaneChartPoint } from '.';

export default class PriceChartCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    return (
      <td style={{ padding: 0 }}>
        <svg version="1.1" height="34" fill="#175985" role="img">
            {Array.from({length: 52}, (value, key) => {
                var data = this.props.dataItem.Chart.find((x: LaneChartPoint) => x.Position == key);
                return <g key={key} transform={`translate(${key*2},0)`}>
                    {data ? <rect height={data.Price} y={34-data.Price} width="2" /> : <rect height="0" y="20" width="2" />}
                </g>
            })}
        </svg>
      </td>
    );
  }
}
