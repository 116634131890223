import React, { useCallback, useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridToolbar, GridColumn as Column, GridSortChangeEvent, GridNoRecords, GridPageChangeEvent, GridCellProps } from '@progress/kendo-react-grid';
import Moment from 'moment-timezone';
import { isTrailerDateValid } from './TrailerGrid';
import { GridLoadingPanel } from 'components/GridLoadingPanel';

type Props = {
    TrailerID: number;
}

type TrailerMovementViewItem = {
    TrailerNumber: string;
    VehicleNumber: string;
    CarrierName: string;
    AssignDateTime: Date;
    AssignAtCustomerNumber: string;
    AssignAtCustomerName: string;
    AssignAtPositionCity: string;
    AssignAtPositionState: string;
    RemoveDateTime: Date;
    RemoveAtCustomerNumber: string;
    RemoveAtCustomerName: string;
    RemoveAtPositionCity: string;
    RemoveAtPositionState: string;
    UserNumber: string;
    ModifiedDateTime: Date;
}

type AdvanceSearchParameters = {
    sortDescriptors: SortDescriptor[]
}

//function isValidDate(date: Date): boolean {
//    return Moment.utc(date).isAfter(Moment.utc("0001-01-01"));
//}

const TrailerMovementHistory = (props: Props) => {

    const resetFilterSortState = () => {
        return {
            trailerID: props.TrailerID,
            sortDescriptors: []
        } as AdvanceSearchParameters;
    }

    const [advanceSearchParameters, setAdvanceSearchParameters] = useState<AdvanceSearchParameters>(resetFilterSortState);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [trailerMovementHistoryDetail, setTrailerMovementHistoryDetail] = useState<TrailerMovementViewItem[]>([]);
    const initialDataState = {
        skip: 0,
        take: 50,
    };
    const [page, setPage] = useState(initialDataState);

    const defaultSort = [{ field: "TrailerNumber", dir: "asc" }] as SortDescriptor[];

    const refreshList = useCallback(() => {
        setLoading(true);
        //const _page = (append) ? this.state.advanceSearch.page + 1 : 1;
        //const data = { ...this.state.advanceSearch } as AdvanceSearchParameters;
        fetchApi(`/api/Asset/GetAssetTrailerMovements`, advanceSearchParameters, 'POST')
            .then(({ Data, Total }) => {
                setTrailerMovementHistoryDetail(Data);
                setTotal(Total);
                setLoading(false);
            });
    }, [advanceSearchParameters])

    useEffect(() => {
        refreshList();
    }, [refreshList, advanceSearchParameters]);

    const sortChange = (event: GridSortChangeEvent) => { //only support single sort currently      
        if (event.sort.length === 0)
            event.sort = [...defaultSort];
        setAdvanceSearchParameters({ ...advanceSearchParameters, sortDescriptors: event.sort });
    }

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    }

    const VehicleCarrierCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerMovementViewItem = props.dataItem;

        return (<td colSpan={props.colSpan} style={props.style}>
            {dataItem.VehicleNumber ? <div>{dataItem.VehicleNumber}</div> : <div>{dataItem.CarrierName}</div>}
        </td>);
    };

    const AssignAtCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerMovementViewItem = props.dataItem;

        return (<td colSpan={props.colSpan} style={props.style}>
            {dataItem.AssignAtCustomerNumber ? <div>{dataItem.AssignAtCustomerName} ({dataItem.AssignAtCustomerNumber})</div> : ""}
            {dataItem.AssignAtPositionCity ? <div>{`${dataItem.AssignAtPositionCity}, ${dataItem.AssignAtPositionState}`}</div> : ""}
            {isTrailerDateValid(dataItem.AssignDateTime) ? <div>{Moment.utc(dataItem.AssignDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div> : ""}
        </td>);
    };

    const RemoveAtCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerMovementViewItem = props.dataItem;

        return (<td colSpan={props.colSpan} style={props.style}>
            {dataItem.RemoveAtCustomerNumber ? <div>{dataItem.RemoveAtCustomerName} ({dataItem.RemoveAtCustomerNumber})</div> : ""}
            {dataItem.RemoveAtPositionCity ? <div>{`${dataItem.RemoveAtPositionCity}, ${dataItem.RemoveAtPositionState}`}</div> : ""}
            {isTrailerDateValid(dataItem.RemoveDateTime) ? <div>{Moment.utc(dataItem.RemoveDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div> : ""}
        </td>);
    };

    const UserBy = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: TrailerMovementViewItem = props.dataItem;

        return (<td colSpan={props.colSpan} style={props.style}>
            <div>{dataItem.UserNumber} - {Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
        </td>);
    };

    return <React.Fragment>
        {loading && <GridLoadingPanel />}
        <Grid
            style={{ maxHeight: '600px' }}
            data={trailerMovementHistoryDetail.slice(page.skip, page.take + page.skip)}
            resizable={true}
            skip={page.skip}
            take={page.take}
            sort={advanceSearchParameters.sortDescriptors}
            onSortChange={sortChange}
            sortable={true}
            total={total}
            pageable={{ pageSizes: [50, 100, 200] }}
            onPageChange={pageChange}
        >
            <GridToolbar>
            </GridToolbar>
            <GridNoRecords>
                {loading == false && <span>No History Found</span> || <span></span>}
            </GridNoRecords>
            <Column field="TrailerNumber" title="Trailer" />
            <Column field="VehicleNumber" title="Vehicle/Carrier" cell={VehicleCarrierCell} />
            <Column field="AssignAtPositionCity" title="In Hook Details" cell={AssignAtCell} />
            <Column field="RemoveAtPositionCity" title="Out Hook Details" cell={RemoveAtCell} />
            <Column field="DropComment" title="Comments" />
            <Column field="UserNumber" title="By" cell={UserBy} />
        </Grid>
    </React.Fragment>
}

export default TrailerMovementHistory;