import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CarrierNavBar } from "./CarrierNavBar";
import { EditableField } from "components/EditableField";
import { IDName } from "types/idname";
import { JsonResponse } from "TypeGen/json-response";
import useAlert from '../../components/useAlert';
import { ApiLink } from "TypeGen/api-link";
import { formatNumber } from "@progress/kendo-intl";
import { VehicleTypes } from "views/AssetVehicles/Profile";

type RouteComponentParams = {
  carrierId: string;
  vehicleId: string;
};

type CarrierVehicleResponse = {
  Vehicle: CarrierVehicle;
  Links: ApiLink[];
}

type CarrierVehicle = {
  CarrierVehicleID: number;
  VehicleNumber: string;
  Active: boolean;
  VehicleTypeID: number;
  Payload?: number | undefined;
  BoxLength?: number | undefined;
  BoxWidth?: number | undefined;
  BoxHeight?: number | undefined;
  AvailabilityNote: string;
}

type Props = {
  isDialog?: boolean;
  carrierId?: number;
  vehicleId?: number;

  onClose?: () => void;
}

const CarrierVehicleProfile = (props: Props) => {

  const { carrierId: carrierIdParam, vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
  const { alert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<CarrierVehicle>();
  const [links, setLinks] = useState<ApiLink[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchApi(`/api/Asset/CarrierVehicle/${vehicleIdParam}`)
      .then((data: CarrierVehicleResponse) => {
        setLoading(false);
        setProfile(data.Vehicle);
        setLinks(data.Links);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [alert, vehicleIdParam]);

  const saveAsyncProfile = async (value: Partial<CarrierVehicle>): Promise<void> => {
    let errorMsg = '';
    const newProfile: CarrierVehicle = Object.assign({}, profile, value);
    await fetchApi(`/api/Asset/CarrierVehicle/${vehicleIdParam}`, newProfile, 'PUT')
      .then((response: JsonResponse) => {
        if (response.Success === false) {
          errorMsg = response.ErrorMessage;
        } else {
          const newProfile: CarrierVehicle = Object.assign({}, profile, value);
          setProfile(newProfile);
        }
      }).catch((e) => {
        if (e?.status) {
          return Promise.reject(e);
        } else {
          errorMsg = "Unknown Error Occurred!";
        }
      });
    if (errorMsg.length) {
      await alert(errorMsg);
      return Promise.reject(errorMsg);
    }
    return Promise.resolve();
  }

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <div className="row m-2">
    <div className="col-md-5">
      <div className="form-group row">
        <label htmlFor="VehicleNumber" className="col-sm-2 col-form-label">Vehicle ID</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ maxLength: 30 }}
            data={profile.VehicleNumber}
            save={async (data) => await saveAsyncProfile({ VehicleNumber: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="VehicleTypeID" className="col-sm-2 col-form-label">Vehicle Type</label>
        <div className="col-sm-10">
          <EditableField
            data={profile.VehicleTypeID}
            renderDisplay={(data) => VehicleTypes.find(x => x.ID == data).Name}
            renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
              {VehicleTypes.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
            </select>}
            save={async (data) => await saveAsyncProfile({ VehicleTypeID: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Payload" className="col-sm-2 col-form-label">Payload</label>
        <div className="col-sm-10">
          <EditableField
            dataType="number"
            inputProps={{ min: 0, max: 100000, placeholder: "lbs.", format: 'n0' }}
            data={profile.Payload}
            renderDisplay={(data) => `${formatNumber(data, 'n0')} lbs.`}
            save={async (data) => await saveAsyncProfile({ Payload: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="BoxDims" className="col-sm-2 col-form-label" title='LxWxH'>Box Dims</label>
        <div className="col-sm-10">
          <EditableField
            data={{ BoxLength: profile.BoxLength, BoxWidth: profile.BoxWidth, BoxHeight: profile.BoxHeight }}
            renderDisplay={(data) => `${data.BoxLength} x ${data.BoxWidth} x ${data.BoxHeight} in.`}
            renderEditable={(data, saving, onChange) => <>
              <input autoFocus required disabled={saving} className="form-control" placeholder="Length (inches)" type="number" min={0} value={data.BoxLength} onChange={(e) => onChange({ BoxLength: parseInt(e.target.value), BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })} />
              <input required disabled={saving} className="form-control" placeholder="Width (inches)" type="number" min={0} value={data.BoxWidth} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: parseInt(e.target.value), BoxHeight: data.BoxHeight })} />
              <input required disabled={saving} className="form-control" placeholder="Height (inches)" type="number" min={0} value={data.BoxHeight} onChange={(e) => onChange({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: parseInt(e.target.value) })} />
            </>}
            save={async (data) => await saveAsyncProfile({ BoxLength: data.BoxLength, BoxWidth: data.BoxWidth, BoxHeight: data.BoxHeight })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="AvailabilityNote" className="col-sm-2 col-form-label">Availability Note</label>
        <div className="col-sm-10">
          <EditableField
            dataType="string"
            inputProps={{ maxLength: 60 }}
            data={profile.AvailabilityNote}
            save={async (data) => await saveAsyncProfile({ AvailabilityNote: data })}
          />
        </div>
      </div>
    </div>
  </div>;

  return (<>
    <CarrierNavBar id={carrierIdParam} currentTab="Vehicles" />
    <Title string={profile?.VehicleNumber || "Vehicles"} />
    <br />
    {loading || !profile ? loadingView : dataView()}
  </>);
}

export default CarrierVehicleProfile;