import { formatNumber } from '@progress/kendo-intl';
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { fetchApi } from "services/api";
import { JsonResponse } from "TypeGen/json-response";
import { OrderFreightDetail } from "TypeGen/Order/StopDelete/order-freight-detail";
import { OrderStop } from "TypeGen/Order/StopDelete/order-stop";
import { ViewModel } from "TypeGen/Order/StopDelete/view-model";
import { ILink } from "types/link";
import { getStopType } from "views/Quote/GetStopType";
import { getStopVerb } from "views/Quote/GetStopVerb";
import { OrderContext } from "../Details";

type RouteComponentParams = {
  orderId: string;
  orderStopId: string;
};

type Props = {
  orderId?: number;
  orderStopId?: number;
  onClose?: () => void;
  children?: React.ReactNode
};

const OrderStopDelete: React.FC<Props> = props => {
  const { orderId: orderIdParam, orderStopId: orderStopIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orderStops, setOrderStops] = useState<OrderStop[]>([]);
  const [freightDetails, setFreightDetails] = useState<OrderFreightDetail[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);
  const [orderId, setOrderId] = useState(0);
  const [orderStopId, setOrderStopId] = useState(0);

  const refreshOrderStop = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/StopDelete/${orderId}/${orderStopId}`)
      .then((response: ViewModel) => {
        setDeleteStopIds(response.Stops.map(x => x.OrderStopID));
        setOrderStops(response.Stops);
        setFreightDetails(response.FreightDetails);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderId, orderStopId, setDeleteStopIds]);

  useEffect(() => {
    if (props.orderId > 0) {
      setOrderId(props.orderId);
      setOrderStopId(props.orderStopId);
    }
    else {
      setOrderId(Number(orderIdParam));
      setOrderStopId(Number(orderStopIdParam));
    }
  }, [orderIdParam, orderStopIdParam, props.orderId, props.orderStopId]);

  useEffect(() => {
    if (orderId > 0 && orderStopId > 0) {
      refreshOrderStop();
      setDeleteStopIds([orderStopId]);
      setActiveStopIds([]);
    }
  }, [orderId, orderStopId, refreshOrderStop, setActiveStopIds, setDeleteStopIds]);

  const save = () => {
    setLoading(true);
    const command = links.find(l => l.Name === 'Delete');
    fetchApi(command.Link, {}, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          if (props.onClose)
            props.onClose();
          else {
            refreshOrder();
            history.push(`/Order/${orderId}`);
          }
        } else {
          alert(response.ErrorMessage);
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to delete stop(s)');
      });
  }

  return <div className='py-2'>
    <div className="row">
      <div className="col-md-7">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title text-center">
              {orderStops.length > 1 ? 'Deleting Stops' : 'Deleting  Stop'}
            </h5>
          </div>
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Location</th>
                <th>Address</th>
                <th>City</th>
                <th>Scheduled</th>
              </tr>
            </thead>
            <tbody>
              {orderStops.map((stop) => <tr key={stop.OrderStopID}>
                <td>{stop.Sequence}-{getStopType(stop.StopType)}</td>
                <td>{stop.LocationName}</td>
                <td>{stop.AddressLine1}</td>
                <td>{stop.City}, {stop.State} {stop.ZipCode}</td>
                <td>{moment.utc(stop.ScheduledDateTime).tz('America/New_York').format('MM/DD/YYYY HH:mm')} {getStopVerb(stop.StopVerb)}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-5">
        {freightDetails.map((freighDetail) => <div
          className="card mb-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/Order/${orderId}/Freight/${freighDetail.OrderFreightDetailID}`);
          }}
          onMouseEnter={() => {
            setActiveStopIds([freighDetail.PickupStopID, freighDetail.DeliveryStopID]);
          }}
          onMouseLeave={() => {
            setActiveStopIds([]);
          }}
        >
          <div className="card-body">
            <h5 className="card-title">
              {freighDetail.IsPickup ? 'Delivering to ' : 'Picking Up at '}{freighDetail.LocationName}
            </h5>
            {formatNumber(freighDetail.Pieces, 'n0')} Pieces
            <br />
            {formatNumber(freighDetail.Weight, 'n0')} {freighDetail.WeightUnitOfMeasure === 1 ? 'lbs' : 'kg'}
            <br />
            {freighDetail.Width} x {freighDetail.Height} x {freighDetail.Length} in
            <br />
            {freighDetail.Stackable ? 'Stackable' : 'Non-Stackable'}
            <br />
            {freighDetail.FreightValue > 0 && formatNumber(freighDetail.FreightValue, 'c2')}
          </div>
        </div>)}
      </div>
    </div>
    <div className="text-center mt-3">
      {props.onClose && <Button themeColor="secondary" onClick={props.onClose} className='mr-2'>Cancel</Button>}
      <Button themeColor="error" onClick={save} disabled={loading}>
        {orderStops.length > 1 ? 'Delete Stops' : 'Delete Stop'}
      </Button>
    </div>
  </div>
}

export default OrderStopDelete;