import * as React from 'react';
import { AutoComplete, AutoCompleteChangeEvent, DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { EnterStopViewModel } from '.';
import { singleSearch, ISingleSearchLocation } from '../../services/pcmiler';
import moment from 'moment';
import { getStopVerb } from './GetStopVerb';
import timezoneFormatter from '../../services/formatting/timezone';
import CustomerInput from '../../components/CustomerInput';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import { fetchApi } from '../../services/api';
import { Button, DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { QuoteStopVerb } from 'TypeGen/Quote/quote-stop-verb';
import { QuoteStopType } from 'TypeGen/Quote/quote-stop-type';
import { getQuoteStopType } from './GetStopType';
import SimpleTimePicker from 'components/SimpleTimePicker';
import { InputPrefix, InputSuffix } from '@progress/kendo-react-inputs';
import { plusIcon, trashIcon } from '@progress/kendo-svg-icons';

type State = {
  isLoadingLocations: boolean;
  ValidationRequestHashLocation: string,
  LocationOptions: ISingleSearchLocation[];
  Instructions: Array<{ Display: number, Instruction: string }>;
}

type Props = {
  stop: EnterStopViewModel;
  index: number;
  location: EnterStopViewModel;
  onChange: (newStop: EnterStopViewModel, changeType: string) => void;
  addPair: () => void;
  addStop: (stopType: QuoteStopType) => void;
  remove: () => void;
  children?: React.ReactNode;
};

const stopVerbs = [
  { text: "Protect", value: QuoteStopVerb.Protect },
  { text: "Ready Now", value: QuoteStopVerb.ReadyNow },
  { text: "Deliver Direct", value: QuoteStopVerb.DeliverDirect },
  { text: "ASAP", value: QuoteStopVerb.ASAP },
  { text: "Open", value: QuoteStopVerb.Open },
  { text: "Close", value: QuoteStopVerb.Close },
  { text: "Hot", value: QuoteStopVerb.Hot },
  { text: "Window", value: QuoteStopVerb.Window },
];

class EnterStop extends React.Component<Props, State> {

  private timeout: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoadingLocations: false,
      ValidationRequestHashLocation: '',
      LocationOptions: [],
      Instructions: [],
    }

    this.setStopType = this.setStopType.bind(this);
    this.setStopVerb = this.setStopVerb.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateDateTime = this.updateDateTime.bind(this);
    this.updateLatestDate = this.updateLatestDate.bind(this);
    this.updateLatestTime = this.updateLatestTime.bind(this);
    this.updateLatestDateTime = this.updateLatestDateTime.bind(this);
    this.updateCustomerSpecialInstructions = this.updateCustomerSpecialInstructions.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.stop.CustomerID > 0 && (this.props.stop.CustomerID !== prevProps.stop.CustomerID || this.props.stop.StopType !== prevProps.stop.StopType)) {
      this.updateCustomerSpecialInstructions(this.props.stop.CustomerID);
    } else if (this.props.stop.CustomerID == 0 && this.state.Instructions.length > 0) {
      this.setState({ Instructions: [] });
    }
  }

  renderTimeInputGroup = () => {
    return <InputSuffix>
      <DropDownList
        style={{ width: 128 }}
        data={stopVerbs}
        value={stopVerbs.find(x => x.value === this.props.stop.StopVerb)}
        textField='text'
        onChange={(e) => this.setStopVerb(e.target.value.value as QuoteStopVerb)}
      />
    </InputSuffix>;
  }

  itemRenderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
      const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
      return React.cloneElement(li, li.props, itemChildren);
  }

  render() {
    const dateTime = moment(this.props.stop.DateTime);
    const latestDateTime = this.props.stop.StopVerb === QuoteStopVerb.Window ? moment(this.props.stop.LatestDateTime) : undefined;
    const stopTypeName = getQuoteStopType(this.props.stop.StopType);

    return (
      <div className="k-card k-var--normal-background mb-2 px-2" style={{ overflow: 'visible' }}>
      <div className='py-2'>
      <div className="form-row">
        <div className="col-md-1">
          <DropDownButton
            className="w-100"
            buttonClass="w-100"
            themeColor="primary"
            text={`${stopTypeName} ${this.props.stop.Sequence}`}
            onItemClick={(e) => {
              if (e.itemIndex === 0) {
                this.props.addStop(this.props.stop.StopType === QuoteStopType.Pickup ? 1 : 0);
              } else if (e.itemIndex === 1) {
                this.props.addPair();
              } else if (e.itemIndex === 2) {
                this.props.remove();
              }
            }}
          >
            <DropDownButtonItem text={this.props.stop.StopType === QuoteStopType.Pickup ? "Add Delivery" : "Add Pickup"} icon="plus" svgIcon={plusIcon} />
            <DropDownButtonItem text="Add Pair" icon="plus" svgIcon={plusIcon} />
            <DropDownButtonItem text="Remove Stop" icon="trash" svgIcon={trashIcon} />
          </DropDownButton>
        </div>
        <div className="form-group col-md-5">
          <div className="input-group">
            <CustomerInput
              CustomerID={this.props.stop.CustomerID}
              CustomerNumber={this.props.stop.CustomerNumber}
              placeholder={`Enter ${stopTypeName} Customer (Optional)`}
              onChange={(e) => {
                if (e.CustomerID) {
                  this.updateStop({ 
                    Location: e.ZipCode + ' ' + e.City + ', ' + e.State,
                    CustomerID: e.CustomerID,
                    CustomerNumber: e.CustomerNumber },
                    (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION");
                } else {
                  this.updateStop({ 
                    Location: '',
                    CustomerID: e.CustomerID,
                    CustomerNumber: e.CustomerNumber },
                    (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION");
                }
              }}
            />
          </div>
        </div>
        <div className="form-group col-md-3">
          <SimpleDatePicker
            inputProps={{
              prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
              </InputPrefix>
            }}
            value={dateTime.toDate()}
            onChange={this.updateDate}
          />
        </div>
        <div className="form-group col-md-3">
          <SimpleTimePicker
            value={dateTime.toDate()}
            onChange={this.updateTime}
            inputProps={{
              suffix: this.renderTimeInputGroup
            }}
          />
        </div>
      </div>

      {this.props.stop.StopVerb === QuoteStopVerb.Window && <div className="form-row">
        <div className="form-group col-md-2 offset-md-4 text-right">
          <label className="col-form-label">Latest</label>
        </div>
        <div className="form-group col-md-3">
          <SimpleDatePicker
            value={latestDateTime?.toDate()}
            onChange={this.updateLatestDate}
            inputProps={{
              valid: dateTime.isSameOrBefore(latestDateTime, 'day'),
              prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
              </InputPrefix>
            }}
          />
        </div>
        <div className="form-group col-md-3">
          <SimpleTimePicker
            value={latestDateTime?.toDate()}
            onChange={this.updateLatestTime}
            inputProps={{ valid: dateTime.isSameOrBefore(latestDateTime, 'minute') }}
          />
        </div>
      </div>}

      <div className="form-row">
        <div className="col-md-12">
          <div className="input-group">
            {this.props.location && this.props.location.Location.StreetAddress && <div className="input-group-prepend">
              <span className="input-group-text">{this.props.location.Location.StreetAddress}</span>
            </div>}
            <AutoComplete
              data={this.state.LocationOptions}
              value={this.props.stop.Location}
              style={this.props.location && this.props.location.Location.StreetAddress ? { flex: 1, borderRadius: 0 } : { flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              loading={this.state.isLoadingLocations}
              placeholder={`Enter ${stopTypeName} Location`}
              itemRender={this.itemRenderLocation}
              onChange={(event: AutoCompleteChangeEvent) => {

                // Update Location
                let location = event.target.value;
                if (typeof event.target.value === 'object') {
                  const place = event.target.value as any;
                  location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                }
                this.updateStop({ Location: location, CustomerID: 0, CustomerNumber: '' }, (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION");

                // Update Autocomplete Suggestions
                if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                  clearTimeout(this.timeout);
                  this.timeout = setTimeout(() => {
                    const requestHash = Math.random().toString(36).substring(7);
                    this.setState({
                      isLoadingLocations: true,
                      ValidationRequestHashLocation: requestHash
                    });
                    singleSearch(event.target.value)
                      .then((response) => {
                        if (this.state.ValidationRequestHashLocation === requestHash) {
                          this.setState({ isLoadingLocations: false, LocationOptions: response.Locations });
                        }
                      })
                      .catch(() => {
                        if (this.state.ValidationRequestHashLocation === requestHash) {
                          this.setState({ isLoadingLocations: false, LocationOptions: [] });
                        }
                      })
                  }, 350);
                }
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">{timezoneFormatter(this.props.location ? this.props.location.Location.TimeZone : "")}</span>
            </div>
          </div>
        </div>
      </div>
      {this.renderInstructions()}
      </div>
      {this.props.children}
      </div>
    );
  }

  private renderInstructions() {
    if (this.state.Instructions.length === 0) return null;
    return <p>
        {this.state.Instructions.map((instruction, index) => {
            return <React.Fragment key={index}>
                <span {...this.instructionColor(instruction.Display)}>{this.props.stop.CustomerNumber}: {instruction.Instruction}</span>
                <br />
              </React.Fragment>;
        })}
    </p>
}
  
  private instructionColor(display: number) {
    switch(display) {
      case 82:
        return { className: "text-danger" };
      case 71:
        return { className: "text-success" };
      case 89:
        return { className: "text-warning" };
      case 66:
        return { className: "text-primary" };
    }
    return null;
  }

  private setStopType(e: any, StopType: QuoteStopType) {
    e.preventDefault();

    this.updateStop({ StopType }, "STOP_TYPE");
  }

  private setStopVerb(StopVerb: QuoteStopVerb) {
    this.updateStop({
      StopVerb,
      LatestDateTime: StopVerb === QuoteStopVerb.Window ? this.props.stop.DateTime : undefined
    }, "STOP_VERB");
  }

  private updateStop(value: Partial<EnterStopViewModel>, changeType: string) {
    const newStop = Object.assign({}, this.props.stop, value);
    this.props.onChange(newStop, changeType);
  }

  private updateDate(date: Date | null) {
    if (date == null) return;
    this.updateDateTime(date ? moment(`${date.toDateString()} ${new Date(this.props.stop.DateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateTime(time: Date | null) {
    if (time == null) return;
    this.updateDateTime(time ? moment(`${new Date(this.props.stop.DateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateLatestDate(date: Date | null) {
    if (date == null) return;
    this.updateLatestDateTime(date ? moment(`${date.toDateString()} ${new Date(this.props.stop.LatestDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateLatestTime(time: Date | null) {
    if (time == null) return;
    this.updateLatestDateTime(time ? moment(`${new Date(this.props.stop.LatestDateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
  }

  private updateDateTime(value: moment.Moment | null) {
    if (value == null) {
      this.updateStop({ DateTime: undefined }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
      return;
    }
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      this.updateStop({ 
        DateTime: newDateTime,
        LatestDateTime: (this.props.stop.StopVerb !== QuoteStopVerb.Window || moment(newDateTime).isSameOrBefore(this.props.stop.LatestDateTime)) ? this.props.stop.LatestDateTime : newDateTime
      }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
    }
  }

  private updateLatestDateTime(value: moment.Moment | null) {
    if (value == null) {
      this.updateStop({ LatestDateTime: undefined }, "STOP_LATEST_DATETIME");
      return;
    }
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (this.props.stop.LatestDateTime !== newDateTime) {
      this.updateStop({ LatestDateTime: newDateTime }, "STOP_LATEST_DATETIME");
    }
  }

  private updateCustomerSpecialInstructions(customerId: number) {
    fetchApi(`/api/Customer/SpecialInstructions/${customerId}`)
      .then((response: Array<{ Type: number, Display: number, Instruction: string }>) => {
        const Instructions = response
          .filter(x => (this.props.stop.StopType === QuoteStopType.Pickup && x.Type == 83) || (this.props.stop.StopType == QuoteStopType.Delivery && x.Type == 67))
          .map(function (x) { return { Display: x.Display, Instruction: x.Instruction }});
        this.setState({ Instructions });
      })
  }
}

export default EnterStop;
