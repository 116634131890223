import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../../types/link';
import { Link } from 'react-router-dom';

export default class DriverVehicleNumber extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;

        const vehicleLink = this.props.dataItem.Links.find((x: ILink) => x.Name === 'VehicleProfile');
        return (
            <td>
                {vehicleLink && <>
                    <Link
                        to={vehicleLink.Link}
                        style={{ color: '#007bff' }}
                    >
                        {this.props.dataItem.VehicleNumber}
                    </Link><br />
                    {this.props.dataItem.VehicleComment3}
                </>}
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
