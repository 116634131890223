import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';
import { RadialGauge } from '@progress/kendo-react-gauges';

type State = {
  loading: boolean;
  hours: number;
}

export default class TripCountYesterday extends React.Component<{}, State> {

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
      hours: 0,
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi('/api/Report/VehicleAverageLA')
      .then((hours: number) => {
        this.setState({
          loading: false,
          hours,
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    const max = Math.max(40, this.state.hours);

    return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
            <p>
              <span className="report-card-title">Average LA</span>
              <br />
              {formatNumber(this.state.hours, "n0")} Hours
            </p>
            <RadialGauge
              transitions={false}
              pointer={{ value: this.state.hours }}
              scale={{
                max: max,
                labels: {
                  format: "{0}",
                },
                ranges: [
                  { from: 0, to: 10, color: "#198754" },
                  { from: 10, to: 15, color: "#ffc107" },
                  { from: 15, to: max, color: "#dc3545" }
                ]
              }}
            />
          </div>
  }
}
