import * as React from 'react';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SvgIcon } from "@progress/kendo-react-common";
import { sortAscSmallIcon, sortDescSmallIcon } from '@progress/kendo-svg-icons';

const HeaderSortCell: React.FC<{ gridHeaderProps: GridHeaderCellProps, sort?: Array<SortDescriptor>, children?: React.ReactNode }> = ({ children, gridHeaderProps, sort }) => {
    if (!gridHeaderProps.field)
        return null;

    const sortField = sort.filter(x => x.field == gridHeaderProps.field);
    return (
        <span className="k-link" onClick={gridHeaderProps.onClick}>
            {children}
            <span className='k-sort-icon'>
                <SvgIcon icon={sortField.length > 0 ? (sortField[0].dir === 'asc' ? sortAscSmallIcon : sortDescSmallIcon) : undefined} />
            </span>
        </span>
    );
}
export default HeaderSortCell;