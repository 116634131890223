import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AccountNotificationState from '../../store/AccountNotifications';
import { ApplicationState } from '../../store';
import ProblemDialog from './ProblemDialog';

const connector = connect(
  (state: ApplicationState) => state.accountNotifications,
  AccountNotificationState.actionCreators
)

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

class NavBar extends React.Component<Props> {

  public render() {
    return <React.Fragment>
      {this.props.newVersion && <div className="p-3 bg-warning">
        <a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(); }}>
          <div className="container text-dark font-weight-bold">New Version Available. Click Here.</div>
        </a>
      </div>}
      {this.props.problem && <ProblemDialog problem={this.props.problem} onClose={this.props.dismissProblem} />}
    </React.Fragment>
  }
}

export default connector(NavBar);
