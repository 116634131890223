import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps } from "@progress/kendo-react-dateinputs";
import { DropDownList, DropDownListProps } from "@progress/kendo-react-dropdowns";
import { MaskedTextBox, MaskedTextBoxProps, NumericTextBox, NumericTextBoxProps } from "@progress/kendo-react-inputs";
import { useRef } from "react";
import { DefaultInputComponentProps } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";

interface BaseProps<T> {
  data: T;
  required?: boolean;
  onChange: (data: T) => void;
  renderEditable?: (data: T, onChange: (data: T) => void) => JSX.Element;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
}

interface PropsGeneric<T> extends BaseProps<T> {
  dataType?: never;
  inputProps?: never;
}

interface PropsString<T> extends BaseProps<T> {
  dataType: "string";
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

interface PropsNumber<T> extends BaseProps<T> {
  dataType: "number";
  inputProps?: NumericTextBoxProps;
}

interface PropsDate<T> extends BaseProps<T> {
  dataType: "date";
  inputProps?: DatePickerProps;
}

interface PropsDateTime<T> extends BaseProps<T> {
  dataType: "datetime";
  inputProps?: DateTimePickerProps;
}

interface PropsBoolean<T> extends BaseProps<T> {
  dataType: "boolean";
  inputProps?: DropDownListProps;
}

interface PropsPhone<T> extends BaseProps<T> {
  dataType: "phone";
  inputProps?: MaskedTextBoxProps;
}

interface PropsIntlPhone<T> extends BaseProps<T> {
  dataType: "intlphone";
  inputProps?: DefaultInputComponentProps;
}

type Props<T> = PropsGeneric<T> | PropsString<T> | PropsNumber<T> | PropsDate<T> | PropsDateTime<T> | PropsBoolean<T> | PropsPhone<T> | PropsIntlPhone<T>;

const yesNoOptions = [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }];

export const CreatingField = <T extends any>({ data, onChange: setData, ...props }: Props<T>) => {

  const phoneInput = useRef<MaskedTextBox>();

  const renderInputField = () => {
    switch(props.dataType) {
      case "string":
        return <input required={props.required} className="form-control" type="text" value={data as string} onChange={(e) => {
            setData(e.target.value as T);
          }} {...props.inputProps} />;
      case "number":
        return <NumericTextBox
            required={props.required}
            className="flex-fill"
            value={data as number}
            onChange={(e) => setData(e.value as T)}
            {...props.inputProps}
          />;
      case "boolean":
        return <DropDownList
            required={props.required}
            className="flex-fill"
            value={yesNoOptions.find(x => x.value === data.toString())}
            textField="text"
            dataItemKey="value"
            data={yesNoOptions}
            onChange={(e) => setData((e.target.value.value === 'true') as T)}
            {...props.inputProps}
          />;
      case "date":
        return <DatePicker
            defaultShow
            required={props.required}
            format="MM/dd/yyyy"
            className="flex-fill"
            value={data as Date}
            min={new Date(1753, 1, 1)}
            max={new Date(2999, 11, 31)}
            onChange={(e) => setData(e.value as T)}
            formatPlaceholder="formatPattern"
            {...props.inputProps}
          />;
      case "datetime":
        return <DateTimePicker
            required={props.required}
            format="MM/dd/yyyy HH:mm"
            className="flex-fill"
            value={data as Date}
            min={new Date(1753, 1, 1)}
            max={new Date(2999, 11, 31)}
            onChange={(e) => setData(e.value as T)}
            {...props.inputProps}
          />;
      case "phone":
        return <MaskedTextBox
            ref={phoneInput}
            required={props.required}
            validationMessage="Please enter a valid phone number!"
            mask="(000) 000-0000"
            className="flex-fill"
            value={data as string}
            onChange={(e) => e.value === '(___) ___-____' ? setData('' as T) : setData(e.value as T)}
            {...props.inputProps}
          />
        case "intlphone":
          return <PhoneInput
              required={props.required}
              className="form-control"
              placeholder="Use + for international numbers"
              value={data as any}
              defaultCountry="US"
              onChange={(value) => setData(value as T)}
              {...props.inputProps}
          />
    }
  }

  return <form className="input-group flex-nowrap">
    {props.prefix && <div className="input-group-prepend">{props.prefix}</div>}
    {props.renderEditable ? props.renderEditable(data, setData) : renderInputField()}
    {props.suffix && <div className="input-group-append">{props.suffix}</div>}
  </form>;
}