import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment';
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';

export default class Status extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    if (this.props.dataItem.AvailableDateTime && this.props.dataItem.Links.filter((x: ILink) => x.Name === 'TripProfile').length > 0) {
      return <td>
        <a
          href="#"
          style={{ color: this.props.dataItem.StatusColor }}
          title={`Available: ${Moment.utc(this.props.dataItem.AvailableDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}`}
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'TripProfile')[0].Link);
            e.preventDefault();
          }}
        >
          {this.props.dataItem.Status}
        </a>
      </td>;
    }

    if (this.props.dataItem.EstInServiceDateTime) {
      return <td title={`IN: ${Moment.utc(this.props.dataItem.EstInServiceDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}`}>
        <div className="container">
          <div className="row">
            <div className="col-xs-4">
              <a
                href="#"
                style={{ color: this.props.dataItem.StatusColor }}
                onClick={(e) => {
                  openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'ServiceStatus')[0].Link);
                  e.preventDefault();
                }}
              >
                {this.props.dataItem.Status}
              </a>
            </div>
            <div className="col-xs-8">
              {(this.props.dataItem.InServiceBackOn ? <span className="hos-green-circle" /> : <span className="hos-red-circle" />)}
            </div>
          </div>
        </div>
      </td>;
    }

    return <td>
      {this.props.dataItem.Links.find((x: ILink) => x.Name === 'ServiceStatus') ? <a
        href="#"
        style={{ color: this.props.dataItem.StatusColor }}
        onClick={(e) => {
          openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'ServiceStatus')[0].Link);
          e.preventDefault();
        }}
        >
          {this.props.dataItem.Status}
        </a> : <span style={{ color: this.props.dataItem.StatusColor }}>{this.props.dataItem.Status}</span>}
      </td>;
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash || nextProps.dataItem.InServiceBackOn !== this.props.dataItem.InServiceBackOn;
  }
}
