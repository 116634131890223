import { GridCellProps } from "@progress/kendo-react-grid";
import { FleetMessage } from '.';
import { memo } from 'react';
import { Link } from "react-router-dom";

const VehicleNumberCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as FleetMessage;

  const vehicleLink = data.Links.find(x => x.Name === 'VehicleProfile');

  return (
      <td>
          {vehicleLink && <Link
              to={vehicleLink.Link}
              style={{ color: '#007bff' }}
          >
              {data.VehicleNumber}
          </Link>}
      </td>
  );
}

export default memo(VehicleNumberCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
