import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { fetchApi } from '../../services/api';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { useHistory } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { StateCodes } from '../../utils/states';
import { PostingClasses, VehicleType, VehicleTypes } from './Profile';
import { IDName } from '../../types/idname';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
    CloseDialog: () => void;
}

const AddVehicleDialog = (props: Props) => {
    const [createType, setCreateType] = useState(1);
    // Copy
    const [copyVehicleNumber, setCopyVehicleNumber] = useState('');

    const [loading, setLoading] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<VehicleType>(null);

    const history = useHistory();

    const validVIN = vehicle?.VINNumber?.length === 17 && /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/.test(vehicle?.VINNumber);

    const _save = () => {
        setLoading(true);

        const data = {
            CopyVehicleNumber: createType === 0 ? copyVehicleNumber : null,
            ...vehicle,
        } as VehicleType

        fetchApi('/api/Asset/CopyVehicle', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    var link = response.Links.find(l => l.Name === 'VehicleProfile');
                    if (link) history.push(link.Link);
                    props.CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                setLoading(false);

                // If not problem details
                if (!e?.status) alert('Unable to create vehicle');
            });
    }

    const options = [
        { label: "Copy Vehicle", value: 0 },
        { label: "Create Vehicle", value: 1 },
    ];

    //add vehicle type
    //add hired by?

    return <div className="container-fluid">
        <Dialog title={`${createType === 0 ? 'Copy' : 'Create'} Vehicle Profile`} onClose={props.CloseDialog} width={400} height={createType === 0 ? 310 : 570}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="k-form k-form-md p-0">
                <div>
                    <RadioGroup
                        value={createType}
                        onChange={(e) => setCreateType(e.value)}
                        layout="horizontal"
                        data={options}
                    />
                    {createType === 0 && <FieldWrapper>
                        <Input
                            required
                            maxLength={30}
                            disabled={loading}
                            label="Copy Vehicle Number"
                            value={copyVehicleNumber}
                            onChange={(e) => setCopyVehicleNumber(e.value)}
                        />
                    </FieldWrapper>}
                    <FieldWrapper>
                        <Input
                            required
                            autoFocus
                            maxLength={30}
                            disabled={loading}
                            label={`${createType === 0 ? 'New' : ''} Vehicle Number`}
                            value={vehicle?.VehicleNumber}
                            onChange={(e) => setVehicle({ ...vehicle, VehicleNumber: e.value })}
                        />
                    </FieldWrapper>
                    {createType === 1 && <div>
                        <FieldWrapper>
                            <Input
                                required
                                maxLength={20}
                                disabled={loading}
                                value={vehicle?.VINNumber}
                                label="VIN"
                                valid={validVIN}
                                onChange={(e) => setVehicle({ ...vehicle, VINNumber: e.value })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Input
                                required
                                maxLength={8}
                                disabled={loading}
                                value={vehicle?.LicensePlate}
                                label="License Plate"
                                onChange={(e) => setVehicle({ ...vehicle, LicensePlate: e.value })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <DropDownList
                                required
                                disabled={loading}
                                data={StateCodes}
                                label="License State"
                                textField="Name"
                                dataItemKey="ID"
                                value={StateCodes.find(x => x.ID === vehicle?.LicenseState)}
                                onChange={(e) => setVehicle({ ...vehicle, LicenseState: e.target.value.ID })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <DropDownList
                                required
                                disabled={loading}
                                data={VehicleTypes}
                                label="Vehicle Type"
                                textField="Name"
                                dataItemKey="ID"
                                value={VehicleTypes.find(x => x.ID == vehicle?.VehicleTypeID)}
                                onChange={(e) => setVehicle({ ...vehicle, VehicleTypeID: (e.value as IDName).ID })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <DropDownList
                                required
                                disabled={loading}
                                data={PostingClasses}
                                label="Posting Class"
                                textField="Name"
                                dataItemKey="ID"
                                value={PostingClasses.find(x => x.ID == vehicle?.VehicleClassID)}
                                onChange={(e) => setVehicle({ ...vehicle, VehicleClassID: (e.value as IDName).ID })}
                            />
                        </FieldWrapper>
                    </div>}
                </div>
            </div>

            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" disabled={loading} onClick={_save}>
                    {createType === 0 ? 'Copy' : 'Create'} Vehicle
                </Button>
            </DialogActionsBar>
        </Dialog >
    </div >
}

export default AddVehicleDialog;

