import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ILink } from '../../../types/link';
import { fetchApi } from '../../../services/api';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
  link: ILink;
  done: () => void;
}

interface State {
  url: string;
}

export default class UpdateWebPortal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      url: '',
    };

    this.setPortalUrl = this.setPortalUrl.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <Dialog title="Setup Web Portal" onClose={this.props.done} minWidth="600px">
          <div className="input-group mb-3">
              <div className="input-group-prepend">
                  <span className="input-group-text">Website</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  value={this.state.url}
                  onChange={(e) => this.setState({ url: e.target.value })}
              />
          </div>
          <DialogActionsBar>
              <Button themeColor="primary" onClick={this.setPortalUrl}>Save</Button>
          </DialogActionsBar>
      </Dialog>
    );
  }

  private fetch() {
    fetchApi(this.props.link.Link)
        .then((response: { CustomerWebPortalID: string, WebPortalUrl: string }) => {
            if (response.WebPortalUrl) {
                this.setState({ url: response.WebPortalUrl });
            }
        });
  }

  private setPortalUrl() {
      const data ={
        WebPortalUrl: this.state.url
      }
      fetchApi(this.props.link.Link, data, 'POST')
        .then(() => {
            this.props.done();
        });
  }
}
