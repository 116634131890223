import { useContext, useEffect } from "react";
import EnterSplitStop from "../EnterSplitStop";
import { useHistory, useParams } from "react-router";
import { OrderContext } from "../Details";

type RouteComponentParams = {
  orderId: string;
  orderStopId: string;
};

type Props = {
  children?: React.ReactNode
};

const OrderStopSplit: React.FC<Props> = props => {

  const { orderId: orderIdParam, orderStopId: orderStopIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const history = useHistory();

  useEffect(() => {
    setActiveStopIds([parseInt(orderStopIdParam)]);
    setDeleteStopIds([]);
  }, [orderStopIdParam, setActiveStopIds, setDeleteStopIds]);
  
  return <div className='py-2'>
    <EnterSplitStop
      onCancel={() => history.push(`/order/${orderIdParam}`)}
      onSave={() => {
        refreshOrder();
        history.push(`/order/${orderIdParam}`);
      }}
      GetSplitInfoLink={{
        Name: 'Get Split Info',
        Link: `/api/Order/GetSplitInfo/${orderIdParam}/${orderStopIdParam}`,
        Method: 'GET',
      }}
    />
    </div>;
}

export default OrderStopSplit;