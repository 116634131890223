import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment';
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';

export default class PositionCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td>
        {this.props.dataItem.Links.find((x: ILink) => x.Name === 'PositionUpdate') ? <a
          href="#"
          style={{ color: '#007bff' }}
          title={Moment.utc(this.props.dataItem.PositionDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'PositionUpdate')[0].Link);
            e.preventDefault();
          }}
        >{this.props.dataItem.Position}
        </a> : this.props.dataItem.Position}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
