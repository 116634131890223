import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ActionPopup } from './ActionPopup';

export class ActionCell extends React.Component<GridCellProps> {
  public render() {
    return (
      <td>
        <ActionPopup dataItem={this.props.dataItem} />
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    if (nextProps.dataItem.Hash === this.props.dataItem.Hash) {
      return false;
    }

    return true;
  }
}
