import { Push } from "@progress/kendo-react-animation";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Upload as KendoUpload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import * as React from 'react';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from '../../services/api';
import { kendoUploadOnStatusChangeEventHandler } from "../../utils/kendo";
import { Title } from '../../utils/title';
import { PayPeriodViewModel } from "./PayPeriodDropDownList";
import { JsonResponse } from "TypeGen/json-response";

type Props = {}

type State = {
  isLoading: boolean;
  isAdmin: boolean;
  showNotificationBar: boolean;

  payPeriods: PayPeriodViewModel[];
  payPeriodID: number;
}

export default class UploadVehicleFuelDeductions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      isAdmin: false,
      showNotificationBar: false,

      payPeriods: [],
      payPeriodID: 0
    }

    this.onStatusChange = this.onStatusChange.bind(this);
  }

  public render() {
    return (<>
      <Title string="Upload Vehicle Fuel Deductions" />
      {this.state.isLoading && <LoadingPanel />}
      <div className="container" style={{
        marginTop: "30px", padding: "10px 10px 10px 10px", border: "1px solid #BFBFBF", borderRadius: "5px"
      }}>
        <div className="row">
          <div className="col">
            <div className="k-window-titlebar k-dialog-titlebar k-header">
              <span className="k-window-title k-dialog-title">Upload Vehicle Fuel Deductions</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="k-window-content k-dialog-content">
              <div className="row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-md-2 col-form-label" style={{ whiteSpace: "nowrap" }}>Pay Period:</label>
                    <div className="col-md-10">
                      <DropDownList
                        required
                        textField="Label"
                        dataItemKey="PayPeriodID"
                        data={this.state.payPeriods}
                        defaultValue={this.state.payPeriods.length > 0 ? this.state.payPeriods[0] : null}
                        style={{ width: "100%" }}
                        onChange={(e) => this.setState({ payPeriodID: (e.target.value as PayPeriodViewModel).PayPeriodID })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">
                  <KendoUpload
                    disabled={this.state.payPeriodID === 0 || !this.state.isAdmin}
                    batch={false}
                    multiple={false}
                    autoUpload={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    restrictions={{
                      allowedExtensions: ['.csv']
                    }}
                    saveUrl={`/api/Settlements/Upload/VehicleFuelDeductions?payperiodid=${this.state.payPeriodID}`}
                    saveMethod="POST"
                    onBeforeUpload={() => this.setState({ isLoading: true, showNotificationBar: false })}
                    onStatusChange={this.onStatusChange}
                  />
                  <p className="p-1">File type supported: .csv</p>
                </div>
              </div>
              <div className="row align-items-center">
                <Push style={{ zIndex: 0, width: '100%' }}>
                  {window.isTMS && this.state.showNotificationBar && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
                    <Notification style={{ padding: "8px", width: '100%' }} type={{ style: "success", icon: false }}>
                      <div>Your file has been uploaded and is currently being processed.</div>
                      <div>Depending on file size, please allow 10-45 mins. for this upload process to complete.</div>
                      <div>Verify its progress by reviewing it in Sylectus.</div>
                      <div>If any issues are encountered post-upload, please contact admin immediately.  Do not attempt to retry.</div>
                    </Notification>
                  </NotificationGroup>}
                </Push>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>);
  }

  private onStatusChange(e: UploadOnStatusChangeEvent) {
    this.setState({ isLoading: false, showNotificationBar: true });
    kendoUploadOnStatusChangeEventHandler(e);
  }

  public componentDidMount() {
    ((document.getElementsByClassName("k-upload-button")[0] as HTMLElement).getElementsByTagName("span")[0] as HTMLElement).innerText = "Select file...";
    ((document.getElementsByClassName("k-dropzone")[0] as HTMLElement).getElementsByClassName("k-dropzone-hint")[0] as HTMLElement).innerText = "Drop file here to upload";

    this.fetch();
  }

  private fetch() {
    this.setState({
      isLoading: true
    });

    fetchApi('/api/Settlements/IsAdmin')
      .then((response: JsonResponse) => {
        this.setState({ isLoading: false, isAdmin: response.Success }, () => {
          const data = {
            IsOpen: true,
            PayrollClass: 2 //Driver
          };
          fetchApi('/api/Settlements/GetPayPeriods', data, 'POST')
            .then((response: any) => {
              const payperiodViewModels = response as PayPeriodViewModel[];
              this.setState({
                isLoading: false,
                payPeriods: payperiodViewModels,
                payPeriodID: payperiodViewModels[0].PayPeriodID
              });
            });
        });
      })



  }

}