
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { TrackEventNotifications } from './EventNotifications';
import { Trip } from '../../Track';

const TrackDetailRow = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem as Trip;
    return <TrackEventNotifications orderId={dataItem.OrderID} />;
}

export default TrackDetailRow;