import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { ILink } from '../../../types/link';
import { Link } from 'react-router-dom';
import PlannedOrdersVehicle from 'views/Track/PlannedOrdersVehicle';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';

const VehicleNumber = (props: GridCellProps) => {
    const [showPlannedOrdersVehicle, setShowPlannedOrdersVehicle] = React.useState(false);
    const dataItem = props.dataItem as AssetVehicleViewModel;

    const vehicleOwnerProfileLink = dataItem.Links.filter((x: ILink) => x.Name === 'OwnerProfile');
    const vehicleOwnerLink = vehicleOwnerProfileLink.length > 0 && <>
        <br />
        <Link
            to={vehicleOwnerProfileLink[0].Link}
            style={{ color: '#007bff' }}
        >
            {dataItem.OwnerNumber}
        </Link>
    </>;

    const vehicleLink = dataItem.Links.find((x: ILink) => x.Name === 'VehicleProfile');
    const vehiclePlannedLink = dataItem.Links.find((x: ILink) => x.Name === 'VehiclePlanned');

    return (
        <td>
            {showPlannedOrdersVehicle && <PlannedOrdersVehicle vehicleId={dataItem.VehicleID} onClose={() => setShowPlannedOrdersVehicle(false)} />}
            {vehicleLink && <Link style={{ color: dataItem.HideFromDispatch ? '#dc3545' : '#007bff' }} title={dataItem.HideFromDispatchReason} to={vehicleLink.Link}>{dataItem.VehicleNumber}</Link>}
            &nbsp;
            {vehiclePlannedLink && <a
                href="#"
                style={{ color: '#dc3545' }}
                onClick={(e) => {
                    setShowPlannedOrdersVehicle(true);
                    e.preventDefault();
                }}
            >
                Planned
            </a>}
            {vehicleOwnerLink}
        </td>
    );
}
export default React.memo(VehicleNumber, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
