import { Title } from '../../utils/title';
import { Upload as KendoUpload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { kendoUploadOnStatusChangeEventHandler } from '../../utils/kendo';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle } from '@progress/kendo-react-layout';

const UploadBestpassTollReport = () => {

  const onStatusChange = (e: UploadOnStatusChangeEvent) => {
    kendoUploadOnStatusChangeEventHandler(e);
  }

  return (<div className="container">
    <Title string="Upload Bestpass Toll Report" />
    <br />
    <Card>
      <CardHeader>
        <CardTitle>Upload Bestpass Toll Report</CardTitle>
        <CardSubtitle>
          Open the toll report in Excel and Save As .csv file before uploading.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <KendoUpload
          batch={false}
          multiple={false}
          autoUpload={false}
          defaultFiles={[]}
          withCredentials={false}
          restrictions={{
            allowedExtensions: ['.csv']
          }}
          saveUrl={`/api/Asset/UploadBestpassTollReport`}
          saveMethod="POST"
          onStatusChange={onStatusChange}
        />
      </CardBody>
    </Card>
  </div>);
}

export default UploadBestpassTollReport;