import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { VehicleNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import YesNoCell from "components/cells/YesNoCell";
import { BooleanFilter, DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import { AssetTurndownHistoryView } from "TypeGen/Assets/Turndowns/asset-turndown-history-view";
import { quoteIDCell } from "views/Quote/Recent";

type RouteComponentParams = {
    vehicleId: string;
};

type TurndownsType = {
    Data: AssetTurndownHistoryView[],
    Total: number,
}

const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "AssetTurndownID",
        dir: "desc"
    }],
} as DataSourceRequestState;

const VehicleTurndowns = () => {
    const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();

    return (<>
        <VehicleNavBar id={vehicleIdParam} currentTab="Turndowns" />
        <Title string="Turndowns" />
        <VehicleTurndownsGrid vehicleId={parseInt(vehicleIdParam)} />
    </>);
}

type VehicleTurndownsGridProps = {
    vehicleId: number;
}

export const VehicleTurndownsGrid = ({ vehicleId }: VehicleTurndownsGridProps) => {

    const [loading, setLoading] = useState(true);
    const [turndowns, setTurndowns] = useState<TurndownsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({
        logic: 'and', filters: []
    });

    const refresh = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/VehicleTurndowns/${vehicleId}?${queryStr}`, {}, 'POST')
            .then(data => {
                setLoading(false);
                setTurndowns(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [vehicleId, dataState]);

    useEffect(() => {
        refresh();
    }, [vehicleId, refresh]);

    return (<>
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "QuoteID",
                        label: "Quote #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "TurndownDate",
                        label: "Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "DriverNumber",
                        label: "Driver #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "StopCount",
                        label: "Stop Count",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "LoadedDistance",
                        label: "Loaded",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "EmptyDistance",
                        label: "Empty",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "ResetLastAvailable",
                        label: "Reset LA",
                        filter: BooleanFilter,
                        operators: Operators.boolean
                    },
                    {
                        name: "Comment",
                        label: "Comment",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "ReasonLabel",
                        label: "Reason",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                      name: "CreatedByUserNumber",
                      label: "By",
                      filter: TextFilter,
                      operators: Operators.text,
                    },
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
            </label>
        </div>
        {turndowns && <Grid
            {...dataState}
            data={turndowns.Data}
            total={turndowns.Total}
            sortable
            pageable
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="QuoteID" title="Quote #" cell={quoteIDCell()} />
            <Column field="TurndownDate" title="Date" filter="date" cell={DateCell} />
            <Column field="DriverNumber" media="(min-width: 768px)" title="Driver" />
            <Column field="LoadedDistance" title="Loaded" filter="numeric" format="{0:n0} mi" />
            <Column field="EmptyDistance" title="Empty" filter="numeric" format="{0:n0} mi" />
            <Column field="ResetLastAvailable" media="(min-width: 768px)" title="Reset LA" filter="boolean" cell={YesNoCell} />
            <Column field="Comment" />
            <Column field="ReasonLabel" media="(min-width: 992px)" title="Reason" />
            <Column field="CreatedByUserNumber" title="By" />
        </Grid>}
    </>);
}

export default VehicleTurndowns;