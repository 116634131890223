import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { fetchApi } from '../../../services/api';
import { Alert } from '..';

const CommandCell = (props: GridCellProps, refresh: () => void) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as Alert;

  const _delete = () => {
    if (window.confirm('Are you sure you want to delete this alert?')) {
      const link = data.Links.find(x => x.Name === 'Delete');
      fetchApi(link.Link, {}, link.Method)
        .then(() => {
          refresh();
        })
        .catch(err => {
          alert('Error deleting flight alert!');
        });
    }
  }


  return (
    <td>
      <Button themeColor="error" className="k-grid-remove-command" onClick={_delete}>Delete</Button>
    </td>
  );
}

export default CommandCell;
