import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import CenterDivPanel from "components/CenterDivPanel";
import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IDNameViewModel } from "TypeGen/id-name-view-model";

type Props = {
  OrderID: string;
  onClose: () => void;
  save: (reason: number) => void;
};

type ViewModel = {
  Reasons: IDNameViewModel[];
}

const Turndown = ({ OrderID, onClose, save }: Props) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<ViewModel>();
  const [reason, setReason] = useState<number>(9);

  const refreshOrderTurndown = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Order/Turndown/${OrderID}`)
      .then((response: ViewModel) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load details');
      });
  }, [OrderID]);

  useEffect(() => {
    refreshOrderTurndown();
  }, [refreshOrderTurndown]);

  return <Dialog title={`Turndown Reason`} onClose={onClose} width={500}>
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <DropDownList
      data={details?.Reasons}
      value={details?.Reasons.find(r => r.ID === reason)}
      textField="Name"
      dataItemKey="ID"
      style={{ width: '100%' }}
      onChange={(e) => setReason(e.target.value.ID)}
    />
    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button themeColor="primary" onClick={() => save(reason)}>Save</Button>
    </DialogActionsBar>
  </Dialog>
}

export default Turndown;