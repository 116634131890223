import { GridCellProps } from "@progress/kendo-react-grid";
import { FleetMessage } from '.';
import { memo } from 'react';
import { Link } from "react-router-dom";

const DriverNumberCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as FleetMessage;

  const driverLink = data.Links.find(x => x.Name === 'DriverProfile');

  return (
      <td>
          {driverLink && <Link
              to={driverLink.Link}
              style={{ color: '#007bff' }}
          >
              {data.DriverNumber}
          </Link>}
      </td>
  );
}

export default memo(DriverNumberCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
