import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatNumber } from '@progress/kendo-intl';

const PayloadCell = (props: GridCellProps) => {
  if (!props.field) return null;

  return (
    <td>
      {formatNumber(props.dataItem.Payload, 'n0')}<br />
      {props.dataItem.Dims}
    </td>
  );
}

export default React.memo(PayloadCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
