import * as React from 'react';
import { Dialog, DialogActionsBar, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { formatNumber } from '@progress/kendo-intl';
import { fetchApi } from '../../services/api';
import Checkbox from '../../components/Checkbox';
import { Button } from '@progress/kendo-react-buttons';
import { openWindow } from 'services/openWindow';
import { carrierOfferExpiryMinutes } from './OfferMultiCarrier';
import { DropDownList } from '@progress/kendo-react-dropdowns';

interface Props {
  quoteID: number;
  orderStatus: number | null;
  rate: number;
  margin: number;
  note: string;
  refresh: () => void;
}

interface State {
  visible: boolean;
  groups: CarrierGroup[];
  note: string;
  confirmed: boolean;
  margin: number;
  expiresInMinutes: number;
}

type CarrierGroup = {
  GroupID: number;
  Name: string;
  VehicleTypeName: string;
}

export class OfferGroup extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      groups: [],
      note: props.note,
      confirmed: props.orderStatus == 100,
      margin: props.margin,
      expiresInMinutes: 15
    };

    this.getRate = this.getRate.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.offerGroup = this.offerGroup.bind(this);
  }

  public componentDidMount() {
    fetchApi("/api/CarrierGroups/GetGroups")
      .then((groups: CarrierGroup[]) => this.setState({ groups }))
      .catch(() => alert("Unable to load carrier groups!"));
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.note != prevProps.note) {
      this.setState({ note: this.props.note });
    }
  }

  render() {
    return (
      <li className="nav-item ml-0">
        <a href="#" className="nav-link border-0" onClick={this.toggleDialog}>Offer Group</a>
        {this.state.visible && <Dialog
          title={"Offer Group"}
          onClose={this.closeDialog}
          className="dialog-w800"
          height={600}
        >
          {this.props.orderStatus == 100 && <div className="form-check">
            <Checkbox
              id={"confirmed"}
              className="form-check-input"
              defaultValue={this.state.confirmed}
              value={this.state.confirmed.toString()}
              handleCheckboxChange={() => this.setState({ confirmed: !this.state.confirmed }) } />
            <label className="form-check-label" htmlFor="confirmed">Confirmed Load</label>
            <br /><br />
          </div>}
          <div className="form-group mb-0">
            <label htmlFor="margin">% of Revenue&nbsp;
              <NumericTextBox
                id="margin"
                format="p"
                value={this.state.margin}
                onChange={(e) => this.setState({ margin: e.value })}
                min={0}
                max={1}
                step={0.01}
              /> can pay <b>{formatNumber(this.getRate(), "c0")}</b>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="offerNote">Note</label>
            <textarea
              className="form-control"
              id="offerNote"
              rows={3}
              maxLength={240}
              placeholder="This note will be sent to the carriers with the request for quote... (Optional)"
              value={this.state.note}
              onChange={(e) => this.setState({ note: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Posting Expires In</label>
            <DropDownList
                style={{ width: '100%' }}
                value={carrierOfferExpiryMinutes.find(x => x.value == this.state.expiresInMinutes)}
                data={carrierOfferExpiryMinutes}
                textField="text"
                onChange={(e) => this.setState({ expiresInMinutes: e.target.value.value })}
            />
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Group</th>
                <th>Vehicle Type</th>
                <th style={{ textAlign: 'right' }}>Request Quote</th>
              </tr>
            </thead>
            <tbody>
              {this.state.groups.map((group, index) => {
                return <tr key={index}>
                  <td>{group.Name}</td>
                  <td>{group.VehicleTypeName}</td>
                  <td><button className="btn btn-primary float-right" onClick={() => this.offerGroup(group.GroupID)}>Offer Group</button></td>
                </tr>
              })}
            </tbody>
          </table>
          <DialogActionsBar>
            <Button onClick={() => openWindow("/Carrier/Groups")}>Manage Groups</Button>
            <Button themeColor="primary" onClick={this.closeDialog}>Close</Button>
          </DialogActionsBar>
        </Dialog>}
      </li>
    );
  }

  private getRate() {
    const rate = this.props.rate * this.state.margin;
    return Math.floor(rate / 5) * 5;
  }

  private offerGroup(groupID: number)
  {
    const data = {
      QuoteID: this.props.quoteID,
      GroupID: groupID,
      BookItRate: this.getRate(),
      Confirmed: this.state.confirmed,
      Note: this.state.note,
      ExpiresInMinutes: this.state.expiresInMinutes
    };

    if (data.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    fetchApi("/api/Quote/OfferCarrierGroup", data, 'POST')
      .then(() => {
        this.props.refresh();
        this.closeDialog();
      })
      .catch(() => alert("Unable to offer carrier group!"))
  }

  private toggleDialog(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();

    this.setState({
      visible: !this.state.visible
    });
  }

  private closeDialog() {
    this.setState({
      visible: false,
      note: ''
    });
  }
}
