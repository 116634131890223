import { formatNumber } from '@progress/kendo-intl';
import { Button, SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column, GridFooterCellProps } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { Card, CardBody, PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import DateCell from "components/cells/DateCell";
import useConfirm from "components/useConfirm";
import moment from "moment";
import { cloneElement, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { downloadBlobAsFile, fetchApi, fetchByteData } from "services/api";
import { ApiLink } from "TypeGen/api-link";
import { FreightBillCorrectionReason } from "TypeGen/freight-bill-correction-reason";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { JsonResponse } from "TypeGen/json-response";
import { InvoiceDropdown } from "TypeGen/Order/Rate/invoice-dropdown";
import { TripViewModel } from "TypeGen/Order/Rate/trip-view-model";
import { ViewModel } from "TypeGen/Order/Rate/view-model";
import { CalculateRateResult } from "TypeGen/Rate/calculate-rate-result";
import { useBoolean } from "usehooks-ts";
import useMessage from 'utils/useMessage';
import SimpleDatePicker from "../../../components/SimpleDatePicker";
import { StatusChangedByLog } from '../../../TypeGen/Order/Rate/status-changed-by-log';
import { RevenueType1 } from '../../../TypeGen/Order/revenue-type1';
import { IDName } from '../../../types/idname';
import { Title } from '../../../utils/title';
import { enumToArrayOfIDName, isNullOrWhiteSpace } from '../../../utils/utils';
import { OrderContext } from "../Details";
import PayEditable from "../Planning/PayEditable";
import BillTo from "./BillTo";
import RateEditable from "./Editable";
import CustomerAccessorialRatesPopup from './CustomerAccessorialRatesPopup';

type RouteComponentParams = {
  orderId: string;
  sequence: string;
  letter: string;
};

const FreightBillCorrectionReasonList = enumToArrayOfIDName(FreightBillCorrectionReason).sort((a, b) => a.Name.localeCompare(b.Name));

const OrderRate = () => {
  const { orderId: orderIdParam, sequence: invoiceSequenceParam, letter: invoiceLetterParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const { sendToParentIfExists } = useMessage('refresh');
  const history = useHistory();
  const { confirm, ConfirmationDialog } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [superceded, setSuperceded] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [bolIn, setBolIn] = useState<boolean>(false);
  const [podIn, setPodIn] = useState<boolean>(false);
  const { value: showBillTo, toggle: toggleBillTo } = useBoolean(false);
  const [billToCustomer, setBillToCustomer] = useState<IDNameViewModel>(null);
  const [referenceNumber1, setReferenceNumber1] = useState('');
  const [referenceNumber2, setReferenceNumber2] = useState('');
  const [referenceNumber3, setReferenceNumber3] = useState('');
  const [referenceNumber4, setReferenceNumber4] = useState('');
  const [isArcMinorityOrder, setIsArcMinorityOrder] = useState(false);
  const [arcMinorityCorrespondingLoadOneOrderNumber, setArcMinorityCorrespondingLoadOneOrderNumber] = useState(0);
  const [showArcDialog, setShowArcDialog] = useState(false);
  const [revenueType1, setRevenueType1] = useState(0);
  const [terms, setTerms] = useState(0);
  const [selectedPaperworkDate, setSelectedPaperworkDate] = useState<Date>();
  const [showPaperworkInDateDialog, setShowPaperworkInDateDialog] = useState(false);
  const [showCustomerAccessorialRates, setShowCustomerAccessorialRates] = useState<ApiLink>();
  const [autoRate, setAutoRate] = useState<CalculateRateResult>();
  const [manualRate, setManualRate] = useState<CalculateRateResult>();
  const [supercededRates, setSupercededRates] = useState<CalculateRateResult[]>([]);
  const [useManualRate, setUseManualRate] = useState("true");
  const [invoices, setInvoices] = useState<InvoiceDropdown[]>([]);
  const [trips, setTrips] = useState<TripViewModel[]>([]);
  const [links, setLinks] = useState<ApiLink[]>([]);
  const [auditedBy, setAuditedBy] = useState<StatusChangedByLog>();
  const [readyToPostBy, setReadyToPostBy] = useState<StatusChangedByLog>();
  const [postedBy, setPostedBy] = useState<StatusChangedByLog>();
  const [invoicePostBatchID, setInvoicePostBatchID] = useState(0);
  const [grossMargin, setGrossMargin] = useState("");
  const [balanceDue, setBalanceDue] = useState(0);
  const [showFreightBillCorrectionDialog, setShowFreightBillCorrectionDialog] = useState(false);
  const [freightBillCorrectionReason, setFreightBillCorrectionReason] = useState<null | number>(null);
  const [freightBillCorrectionNote, setFreightBillCorrectionNote] = useState('');

  const refreshOrderRate = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/Rate/${orderIdParam}/${invoiceSequenceParam}/${invoiceLetterParam}`)
      .then((response: ViewModel) => {
        setInvoiceNumber(response.InvoiceNumber);
        setSuperceded(response.Superceded);
        setInvoiceStatus(response.InvoiceStatus);
        setBolIn(response.BOLIn);
        setPodIn(response.PODIn);
        setTerms(response.Terms);
        setBillToCustomer(response.BillToCustomer);
        setReferenceNumber1(response.ReferenceNumber1);
        setReferenceNumber2(response.ReferenceNumber2);
        setReferenceNumber3(response.ReferenceNumber3);
        setReferenceNumber4(response.ReferenceNumber4);
        setRevenueType1(response.RevenueType1);
        setBalanceDue(response.BalanceDue);
        setIsArcMinorityOrder(response.IsArcMinorityOrder);
        setArcMinorityCorrespondingLoadOneOrderNumber(response.ArcMinorityCorrespondingLoadOneOrderNumber);
        setAuditedBy(response.AuditedBy);
        setReadyToPostBy(response.ReadyToPostBy);
        setPostedBy(response.PostedBy);
        setInvoicePostBatchID(response.InvoicePostBatchID);
        setGrossMargin(response.GrossMargin);
        setAutoRate(response.AutoRate);
        setManualRate(response.ManualRate);
        setSupercededRates(response.SupercededRates);
        if (response.ManualRate === null) setUseManualRate("false");
        setInvoices(response.Invoices);
        setTrips(response.Trips);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load order rate');
      });
  }, [orderIdParam, invoiceSequenceParam, invoiceLetterParam]);

  useEffect(() => {
    setActiveStopIds([]);
    setDeleteStopIds([]);
    refreshOrderRate();
  }, [refreshOrderRate, setActiveStopIds, setDeleteStopIds]);

  const saveRate = () => {
    const link = links.find(x => x.Name === "SetRate");
    setLoading(true);
    fetchApi(link.Link, { LineItems: useManualRate === "true" ? manualRate.LineItems : autoRate.LineItems }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrder();
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        } else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set rate');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const saveZeroRate = async () => {
    if (!await confirm('Are you sure you want to set the rate to zero?', 'Set Rate To Zero?')) return;

    const link = links.find(x => x.Name === "SetRate");
    setLoading(true);
    fetchApi(link.Link, { LineItems: [] }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrder();
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        } else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set rate');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setRatedInBilling = async () => {
    if (!await confirm('Are you sure you want to set rated in billing?', 'Set As Rated In Billing?')) return;

    const link = links.find(x => x.Name === "SetRatedInBilling");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set rated in billing');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setDiscrepancy = async () => {
    if (!await confirm('Are you sure you want to set discrepancy?', 'Set As Discrepancy?')) return;

    const link = links.find(x => x.Name === "SetDiscrepancy");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set discrepancy');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setReadyToPost = async () => {
    if (!await confirm('Are you sure you want to set ready to post?', 'Set As Ready To Post?')) return;

    const link = links.find(x => x.Name === "SetReadyToPost");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set ready to post');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setInvoicePayReadyToPost = async () => {

    const link = links.find(x => x.Name === "SetInvoicePayReadyToPost");
    setLoading(true);
    fetchApi(link.Link, { PaperworkCheckInDate: selectedPaperworkDate }, link.Method)
      .then((e: JsonResponse) => {
        if (e.Success) {
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        }
        else alert(e.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to set ready to post');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createFreightBillCorrection = () => {
    const link = links.find(x => x.Name === "CreateFreightBillCorrection");
    setLoading(true);
    fetchApi(link.Link, { Reason: freightBillCorrectionReason, Note: freightBillCorrectionNote }, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) history.push(response.Links.find(x => x.Name === "Self").Link);
        else alert(response.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to create freight bill correction');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createAccessorialInvoice = async () => {
    if (!await confirm('Are you sure you want to create an accessorial invoice?', 'Create Accessorial Invoice?')) return;

    const link = links.find(x => x.Name === "CreateAccessorialInvoice");
    setLoading(true);
    fetchApi(link.Link, {}, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) history.push(response.Links.find(x => x.Name === "Self").Link);
        else alert(response.ErrorMessage);
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to create accessorial invoice');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const updateArcCorrespondingLoadOneOrderNumber = () => {
    const link = links.find(x => x.Name === "UpdateArcCorrespondingLoadOneOrderNumber");
    setLoading(true);
    fetchApi(link.Link, { LoadOneOrderNumber: arcMinorityCorrespondingLoadOneOrderNumber }, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) refreshOrderRate();
        else {
          setArcMinorityCorrespondingLoadOneOrderNumber(0);
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to update');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const setBillTo = async (customerId: number, terms: number, updateSalespeople: boolean, revenueType1: number) => {
    const payload = {
      BillToCustomerID: customerId,
      Terms: terms,
      UpdateSalespeople: updateSalespeople,
      RevenueType1: revenueType1
    }
    const link = links.find(x => x.Name === "SetBillTo");
    setLoading(true);
    fetchApi(link.Link, payload, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          toggleBillTo();
          refreshOrder(); // Updated order modnum
          refreshOrderRate();
          sendToParentIfExists({ type: 'refresh', payload: null });
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to update bill to');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading || !autoRate) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  const setRateLink = links.find(x => x.Name === "SetRate") !== undefined;
  const setBillToLink = links.find(x => x.Name === "SetBillTo") !== undefined;
  const viewInvoiceLink = links.find(x => x.Name === "ViewInvoice") !== undefined;
  const customerAccessorialRatesLink = links.find(x => x.Name === "CustomerAccessorialRates") !== undefined;
  const setRatedInBillingLink = links.find(x => x.Name === "SetRatedInBilling") !== undefined;
  const setDiscrepancyLink = links.find(x => x.Name === "SetDiscrepancy") !== undefined;
  const setReadyToPostLink = links.find(x => x.Name === "SetReadyToPost") !== undefined;
  const setInvoicePayReadyToPostLink = links.find(x => x.Name === "SetInvoicePayReadyToPost") !== undefined;
  const createFreightBillCorrectionLink = links.find(x => x.Name === "CreateFreightBillCorrection") !== undefined;
  const createAccessorialInvoiceLink = links.find(x => x.Name === "CreateAccessorialInvoice") !== undefined;
  const updateArcCorrespondingLoadOneOrderNumberLink = links.find(x => x.Name === "UpdateArcCorrespondingLoadOneOrderNumber") !== undefined;

  const TripPayFooter = (props: GridFooterCellProps) => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {formatNumber(trips.reduce((total, item) => total + item.PayTotal, 0), 'c')}
      </td>
    );
  };

  const customItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: any) => {
    const dataItem = itemProps.dataItem as InvoiceDropdown;
    return cloneElement(
      li,
      {},
      <div>
        <span className="mr-2">{dataItem.InvoiceNumber}</span>
        <span>{formatNumber(dataItem.TotalCharges, { style: 'accounting' })}</span>
      </div>
    );
  };

  const downloadInvoicePDF = (apiLink: ApiLink) => {
    //setLoading(true);
    fetchByteData(apiLink.Link)
      .then((resp) => {
        //  setLoading(false);
        downloadBlobAsFile(resp.blob, resp.filename);
      })
      .catch(e => {
        //setLoading(false);
        // If not problem details
        if (!e?.status) alert('An error occurred.');
      });
  };

  const drawAutoRateSection = (): JSX.Element => {
    return (<><RadioButton
      className="my-2"
      name="existingRate"
      value="false"
      checked={useManualRate === "false"}
      label="Use Auto Rate"
      onChange={(e) => setUseManualRate(e.value)}
    />
      <br />
      <RateEditable
        editable={setRateLink && manualRate === null}
        data={autoRate}
        onChange={(x) => {
          setAutoRate(x);
          if (useManualRate === "true") setUseManualRate("false")
        }}
      /></>);
  }

  return <div>
    <Title string={`Order Rate View`} />
    <ConfirmationDialog />
    {showBillTo && <BillTo
      Terms={terms}
      BillToCustomer={billToCustomer}
      RevenueType1={revenueType1}
      onClose={toggleBillTo}
      save={(customerId, terms, updateSalespeople, revenueType1) => {
        setBillTo(customerId, terms, updateSalespeople, revenueType1);
      }}
    />}
    <Card>
      <CardBody className="py-1">
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1 py-0">
                {invoices.length === 1
                  ? <Link className="px-0 font-weight-bold" to={invoices[0].Link}>{invoiceNumber}</Link>
                  : <DropDownList
                    className="font-weight-bold"
                    style={{ width: "175px", marginLeft: "-12px" }}
                    textField="InvoiceNumber"
                    dataItemKey="InvoiceNumber"
                    data={invoices}
                    fillMode={'flat'}
                    defaultValue={invoices.find(x => x.InvoiceNumber === invoiceNumber)}
                    itemRender={customItemRender}
                    onChange={(e) => history.push((e.value as InvoiceDropdown).Link)}
                  />
                }
              </label>
              <div className="col-auto pl-1">
                <h5 className="mb-0"><span className="badge badge-info">{invoiceStatus}</span></h5>
              </div>
            </div>
          </div>

          {/*ApplyCorrespondingLoadOneOrderNumber()*/}
          {isArcMinorityOrder &&
            <div className="col-auto">
              <div className="row d-flex align-items-center">
                <h5 className="mb-0"><span role={updateArcCorrespondingLoadOneOrderNumberLink ? 'button' : ''} className={`badge ${updateArcCorrespondingLoadOneOrderNumberLink ? 'badge-danger' : 'badge-warning'}`} onClick={() => updateArcCorrespondingLoadOneOrderNumberLink && setShowArcDialog(true)}>ARC</span></h5>
              </div>
            </div>}

          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">BOL:</label>
              <div className="col-auto pl-1">
                {bolIn ? <h5 className="mb-0"><span className="badge badge-success">In</span></h5> : <h5 className="mb-0"><span className="badge badge-danger">Missing</span></h5>}
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label px-1">POD:</label>
              <div className="col-auto pl-1">
                {podIn ? <h5 className="mb-0"><span className="badge badge-success">In</span></h5> : <h5 className="mb-0"><span className="badge badge-danger">Missing</span></h5>}
              </div>
            </div>
          </div>

          <div className="col"></div>

          {billToCustomer && <div className="col-auto pr-1">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">Rev Allocation:</label>
              <div className="col-auto pl-1">
                <Button onClick={toggleBillTo} themeColor="primary" fillMode="flat" className="px-1 mr-1"
                  title="Rev Allocation" disabled={!setBillToLink}>
                  {RevenueType1[revenueType1]}
                </Button>
              </div>
            </div>
          </div>}

          <div className="col-auto pl-0">
            {billToCustomer && <Button onClick={toggleBillTo} title="Bill To" disabled={!setBillToLink}>
              {billToCustomer.Name}
            </Button>}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">Ref 1/Customer:</label>
              <div className="col-auto pl-1">
                <Link style={{ color: "#007bff" }} to={`/Order/${orderIdParam}`}>{referenceNumber1}</Link>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">Ref 2/Front:</label>
              <div className="col-auto pl-1">
                <Link style={{ color: "#007bff" }} to={`/Order/${orderIdParam}`}>{referenceNumber2}</Link>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">Ref 3/Location:</label>
              <div className="col-auto pl-1">
                <Link style={{ color: "#007bff" }} to={`/Order/${orderIdParam}`}>{referenceNumber3}</Link>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1">Ref 4/Other:</label>
              <div className="col-auto pl-1">
                <Link style={{ color: "#007bff" }} to={`/Order/${orderIdParam}`}>{referenceNumber4 ?? "-"}</Link>
              </div>
            </div>
          </div>

          <div className="col"></div>

          <div className="col-auto">
            <div className="align-items-center float-right">
              {createFreightBillCorrectionLink && <Button
                disabled={loading}
                className="ml-2 px-1 py-1"
                fillMode="flat"
                themeColor="primary"
                onClick={() => setShowFreightBillCorrectionDialog(true)}
              >
                Create Freight Bill Correction
              </Button>}
              {createAccessorialInvoiceLink && <Button
                disabled={loading}
                className="ml-2 px-1 py-1"
                themeColor="primary"
                fillMode="flat"
                onClick={createAccessorialInvoice}
              >
                Create Accessorial Invoice
              </Button>}
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          {auditedBy && <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1" title={moment.utc(auditedBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')}>
                Audited by {auditedBy.UserName} {moment.utc(auditedBy.LogDateTime).fromNow()}
              </label>
            </div>
          </div>}
          {readyToPostBy && <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1" title={moment.utc(readyToPostBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')}>
                Ready to Post by {readyToPostBy.UserName} {moment.utc(readyToPostBy.LogDateTime).fromNow()}
              </label>
            </div>
          </div>}
          {postedBy && <div className="col-auto">
            <div className="row d-flex align-items-center">
              <label className="col-auto col-form-label pr-1" title={`${moment.utc(postedBy.LogDateTime).local().format('MM/DD/YYYY HH:mm')} - Post Batch ID: ${invoicePostBatchID}`}>
                Posted by {postedBy.UserName} {moment.utc(postedBy.LogDateTime).fromNow()}
              </label>
            </div>
          </div>}

          <div className="col"></div>

          <div className="col-auto">
            <div className="align-items-center float-right">
              <label className="col-form-label">{grossMargin}</label>
            </div>
          </div>
        </div>

        {balanceDue > 0 && <div className="row align-items-center">
          <div className="col"></div>
          <div className="col-auto">
            <h4 className="mb-0"><span className="badge badge-danger" role='button' onClick={() => {
              const link = links.find(x => x.Name === "ViewBalanceDueInvoice");
              window.open(link.Link, "_blank", "menubar=no");
            }}>BALANCE DUE: {formatNumber(balanceDue, 'C')}</span></h4>
          </div>
        </div>}
      </CardBody>
    </Card>

    {
      useManualRate === "true" && autoRate?.Total > 0 ? <PanelBar animation={false} className="mt-2">
        <PanelBarItem title={`Auto Rate: ${autoRate?.Comment} ${formatNumber(autoRate?.Total, 'C')}`}>
          {drawAutoRateSection()}
        </PanelBarItem>
      </PanelBar> : (autoRate?.Total > 0 || manualRate === null) && drawAutoRateSection()
    }

    {
      manualRate && <>
        <RadioButton
          className="my-2"
          name="existingRate"
          value="true"
          checked={useManualRate === "true"}
          label="Use Existing Rate"
          onChange={(e) => setUseManualRate(e.value)}
        />
        <RateEditable
          editable={setRateLink}
          data={manualRate}
          onChange={(x) => {
            setManualRate(x);
            if (useManualRate === "false") setUseManualRate("true")
          }}
        />
      </>
    }
    <br />
    {
      supercededRates.length > 0 && <PanelBar animation={false}>
        {supercededRates.map((x, i) => <PanelBarItem key={i} expanded={superceded && i === 0} title={x.Comment}>
          <RateEditable
            data={x}
            editable={false}
            showComment={false}
          />
        </PanelBarItem>)}
      </PanelBar>
    }
    <Grid
      data={trips}
      className="mt-3"
      expandField="expanded"
      scrollable="none"
      detail={(e) => {
        const data = e.dataItem as TripViewModel;
        return <>
          {data.CurrentPayRates.map((x, index) => <PayEditable key={index} editable={false} data={x} />)}
        </>
      }}
      onExpandChange={(e) => {
        const data = e.dataItem as TripViewModel;
        data.expanded = !data.expanded;
        setTrips([...trips]);
      }}
    >
      <Column field="TripNumber" title="Trip #" cell={(e) => <td>
        <Link style={{ color: "#007bff" }} to={`/Trip/${e.dataItem.TripID}`}>{e.dataItem.TripNumber}</Link>
      </td>} />
      <Column field="CarrierName" title="Asset" cell={(e) => <td>
        {e.dataItem.CarrierID === 2046 ? <>
          {e.dataItem.VehicleNumber} ({e.dataItem.Driver1Number}{e.dataItem.Driver2Number && `, ${e.dataItem.Driver2Number}`})
        </> : e.dataItem.CarrierName}
      </td>} />
      <Column field="PickupDateTime" title="Pickup Date" cell={DateCell} />
      <Column field="PickupCityState" title="From" />
      <Column field="DeliveryDateTime" title="Drop Date" cell={DateCell} />
      <Column field="DeliveryCityState" title="To" />
      <Column
        field="PayTotal"
        title="Pay"
        format="{0:c}"
        cell={(e) => <td>
          <Link style={{ color: "#007bff" }} to={`/Trip/${e.dataItem.TripID}/Pay/${e.dataItem.PaySequence}/${e.dataItem.PayLetter}`}>{formatNumber(e.dataItem.PayTotal, "c")}</Link>
        </td>}
        footerCell={trips.length > 1 && TripPayFooter}
      />
    </Grid>
    <div className="text-center mt-3">
      {customerAccessorialRatesLink && <Button
        disabled={loading}
        themeColor="secondary"
        onClick={() => setShowCustomerAccessorialRates(links.find(x => x.Name === "CustomerAccessorialRates"))}
      >
        Accessorial Rates
      </Button>}
      {setRateLink && <SplitButton
        disabled={loading}
        className="ml-2"
        themeColor="primary"
        text="Set Rate"
        onButtonClick={saveRate}
        onItemClick={saveZeroRate}
      >
        <SplitButtonItem text="Set Zero Rate" />
      </SplitButton>}
      {setRatedInBillingLink && <Button
        disabled={loading}
        className="ml-2"
        themeColor="primary"
        onClick={setRatedInBilling}
      >
        Set Rated In Billing
      </Button>}
      {setDiscrepancyLink && <Button
        disabled={loading}
        className="ml-2"
        themeColor="primary"
        onClick={setDiscrepancy}
      >
        Set Discrepancy
      </Button>}
      {setReadyToPostLink && <Button
        disabled={loading}
        className="ml-2"
        themeColor="primary"
        onClick={setReadyToPost}
      >
        Set Ready To Post
      </Button>}
      {setInvoicePayReadyToPostLink && <Button
        disabled={loading}
        className="ml-2"
        themeColor="primary"
        onClick={() => { setSelectedPaperworkDate(new Date()); setShowPaperworkInDateDialog(true); }}
      >
        Set Invoice/Pay Ready To Post
      </Button>}
      {viewInvoiceLink && <SplitButton
        className="ml-2"
        themeColor="secondary"
        text="View Invoice"
        onButtonClick={() => {
          const link = links.find(x => x.Name === "ViewInvoice");
          window.open(link.Link, "_blank", "menubar=no");
        }}
        onItemClick={(e) => {
          if (e.itemIndex === 0) {
            const link = links.find(x => x.Name === "DownloadInvoice");
            downloadInvoicePDF(link);
          } else if (e.itemIndex === 1) {
            const link = links.find(x => x.Name === "DownloadInvoiceWithPaperwork");
            downloadInvoicePDF(link);
          } else if (e.itemIndex === 2) {
            const link = links.find(x => x.Name === "DownloadPaperworkOnly");
            downloadInvoicePDF(link);
          }
        }}
      >
        <SplitButtonItem text="Download" />
        <SplitButtonItem text="Download w/ Paperwork" />
        <SplitButtonItem text="Download Paperwork Only" />
      </SplitButton>}
    </div>
    {
      showPaperworkInDateDialog && <Dialog title="Set Invoice/Pay Ready To Post" autoFocus={true} onClose={() => setShowPaperworkInDateDialog(false)}>
        <label>Enter Paperwork Check In Date:</label>
        <SimpleDatePicker
          max={new Date()}
          value={moment.utc(selectedPaperworkDate).toDate()}
          onChange={(e) => setSelectedPaperworkDate(e)}
        />
        <DialogActionsBar layout={'end'}>
          <Button themeColor={'primary'} onClick={() => { setInvoicePayReadyToPost(); setShowPaperworkInDateDialog(false); }} disabled={selectedPaperworkDate == null}>Submit</Button>
          <Button themeColor={'base'} onClick={() => setShowPaperworkInDateDialog(false)}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>
    }

    {
      showFreightBillCorrectionDialog && <Dialog title={"Create F.B.C.?"} onClose={() => setShowFreightBillCorrectionDialog(false)}>
        <div className="row">
          <div className="col">
            <DropDownList
              defaultItem={{ Name: 'Select Reason...' }}
              data={FreightBillCorrectionReasonList}
              value={freightBillCorrectionReason ? FreightBillCorrectionReasonList.find((x: IDName) => x.ID === freightBillCorrectionReason) : null}
              onChange={(e) => {
                setFreightBillCorrectionReason(e.target.value.ID);
              }}
              textField="Name"
              dataItemKey="ID"
            />
          </div>
        </div>
        <div className="row pt-2">
          <div className="col">
            <label>Explanation:</label>
            <TextArea
              maxLength={300}
              rows={4}
              value={freightBillCorrectionNote}
              onChange={(e: any) => setFreightBillCorrectionNote(e.value)}
            />
          </div>
        </div>
        <DialogActionsBar layout={'end'}>
          <Button themeColor={'primary'} onClick={() => { createFreightBillCorrection(); setShowFreightBillCorrectionDialog(false); }} disabled={freightBillCorrectionReason == undefined ||
            (freightBillCorrectionReason === FreightBillCorrectionReason.Other && isNullOrWhiteSpace(freightBillCorrectionNote))}>Create F.B.C.?</Button>
          <Button themeColor={'base'} onClick={() => setShowFreightBillCorrectionDialog(false)}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>
    }

    {
      showArcDialog && <Dialog title={"ARC Dialog"} onClose={() => setShowArcDialog(false)}>
        <div className="row pt-2">
          <div className="col">
            <label>Enter Corresponding Load One Order Number:</label>
            <Input
              autoFocus={true}
              type="number"
              maxLength={12}
              value={arcMinorityCorrespondingLoadOneOrderNumber}
              onChange={(e: any) => setArcMinorityCorrespondingLoadOneOrderNumber(e.value)}
            />
          </div>
        </div>
        <DialogActionsBar layout={'end'}>
          <Button themeColor={'primary'} onClick={() => { updateArcCorrespondingLoadOneOrderNumber(); setShowArcDialog(false); }} disabled={arcMinorityCorrespondingLoadOneOrderNumber === 0}>Update</Button>
          <Button themeColor={'base'} onClick={() => { setShowArcDialog(false); setArcMinorityCorrespondingLoadOneOrderNumber(0); }}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>
    }
    {
      showCustomerAccessorialRates && <CustomerAccessorialRatesPopup Link={showCustomerAccessorialRates} CloseDialog={() => setShowCustomerAccessorialRates(undefined)} />
    }
  </div >
}

export default OrderRate;
