import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridToolbar } from '@progress/kendo-react-grid';
import { EFSPolicyLimitsViewItem } from './DriverCardMaintenance';
import { formatNumber } from '@progress/kendo-intl';
//import { Tooltip } from '@progress/kendo-react-tooltip';

type Props = {
    DriverID: number,
    PolicyLimits: EFSPolicyLimitsViewItem[],
    OpenAddProductLimitDialog: () => void;
    //RemoveProductLimit: (item: EFSPolicyLimitsViewItem) => void;
    CardInOverrideState: boolean;
}

export const ProductLimitFormatNumber = (Id: string, value: number): string => {
    return PolicyLimitMappings.find(x => x.Id === Id).UoM === "USD" ? formatNumber(value, "c0") : formatNumber(value, "n0")
}

const LimitIdCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSPolicyLimitsViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {`${PolicyLimitMappings.find(x => x.Id === dataItem.LimitId).Description} (${dataItem.LimitId})`}
        </td>
    );
};

const LimitCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const dataItem: EFSPolicyLimitsViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {ProductLimitFormatNumber(dataItem.LimitId, dataItem.Limit)}
        </td>
    );
};

const LimitMeasureCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const dataItem: EFSPolicyLimitsViewItem = props.dataItem;
    const policyLimitMapping = PolicyLimitMappings.find(x => x.Id === dataItem.LimitId);

    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {policyLimitMapping.UoM}
        </td>
    );
};

const LimitHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

export const recursiveWeekSchedule = (remainingValue: number, daysOfWeek: PolicyAutoRollMapping[], intDaysOfWeekIncrement: number, schedule: PolicyAutoRollMapping[]) => {
    var dayOfWeekValue = daysOfWeek[intDaysOfWeekIncrement];
    if (remainingValue < dayOfWeekValue.Id)
        recursiveWeekSchedule(remainingValue, daysOfWeek, ++intDaysOfWeekIncrement, schedule);
    else if (remainingValue === dayOfWeekValue.Id)
        schedule.push(dayOfWeekValue);
    else {
        remainingValue = remainingValue - dayOfWeekValue.Id;
        if (remainingValue > 0) {
            schedule.push(dayOfWeekValue);
            recursiveWeekSchedule(remainingValue, daysOfWeek, ++intDaysOfWeekIncrement, schedule);
        }
    }
}

const AutoRollMapCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const dataItem: EFSPolicyLimitsViewItem = props.dataItem;
    let schedule: PolicyAutoRollMapping[] = [];
    recursiveWeekSchedule(dataItem.AutoRollMap, PolicyAutoRollMappings.sort((a, b) => { return b.Id - a.Id }), 0, schedule);
    //const scheduleString = PolicyAutoRollMappings.filter(x=> x.Id )

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {/*{schedule.sort((a, b) => { return a.Id - b.Id }).map(x => x.Description + ' ' + x.Id).join(' ') + " " + dataItem.AutoRollMap}*/}
            {schedule.sort((a, b) => { return a.Id - b.Id }).map(x => x.ShortName).join(', ')}
        </td>
    );
};

const AutoRollMaxCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const dataItem: EFSPolicyLimitsViewItem = props.dataItem;

    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {dataItem.AutoRollMax === 0 ? "Unlimited" : formatNumber(dataItem.AutoRollMax, "n0")}
        </td>
    );
};

export type PolicyAutoRollMapping = {
    Id: number,
    ShortName: string,
    LongName: string
    DayOfWeek: number
};

export const PolicyAutoRollMappings = [
    { Id: 0, ShortName: "None", LongName: "None", DayOfWeek: -1 },
    { Id: 1, ShortName: "Sun", LongName: "Sunday", DayOfWeek: 0 },
    { Id: 2, ShortName: "Mon", LongName: "Monday", DayOfWeek: 1 },
    { Id: 4, ShortName: "Tue", LongName: "Tuesday", DayOfWeek: 2 },
    { Id: 8, ShortName: "Wed", LongName: "Wednesday", DayOfWeek: 3 },
    { Id: 16, ShortName: "Thu", LongName: "Thursday", DayOfWeek: 4 },
    { Id: 32, ShortName: "Fri", LongName: "Friday", DayOfWeek: 5 },
    { Id: 62, ShortName: "Weekday", LongName: "Weekday", DayOfWeek: 62 },
    { Id: 64, ShortName: "Sat", LongName: "Saturday", DayOfWeek: 6 },
    { Id: 65, ShortName: "Weekend", LongName: "Weekend", DayOfWeek: 65 },
    { Id: 127, ShortName: "Daily", LongName: "Daily", DayOfWeek: 127 },
] as PolicyAutoRollMapping[];

export const PolicyLimitMappings = [
    { Id: "ADD", Description: "Additives", UoM: "USD" },
    { Id: "AMDS", Description: "Aviation Merchandise", UoM: "USD" },
    { Id: "ANFR", Description: "Anti-Freeze", UoM: "USD" },
    { Id: "AVGS", Description: "Aviation Gas", UoM: "USD" },
    { Id: "BDSL", Description: "BioDiesel", UoM: "GAL" },
    { Id: "BRAK", Description: "Brakes and Wheels", UoM: "USD" },
    { Id: "CADV", Description: "Cash Advance", UoM: "USD" },
    { Id: "CLTH", Description: "Clothing", UoM: "USD" },
    { Id: "CNG", Description: "Compressed Natural Gas", UoM: "GAL" },
    { Id: "COUP", Description: "Coupon", UoM: "USD" },
    { Id: "DEF", Description: "Diesel Exhaust Fluid Bulk", UoM: "GAL" },
    { Id: "DEFC", Description: "Diesel Exhaust Fluid Container", UoM: "GAL" },
    { Id: "DELI", Description: "Restaurant/Deli", UoM: "USD" },
    { Id: "DSL", Description: "Diesel", UoM: "GAL" },
    { Id: "DSLM", Description: "Mexico Diesel", UoM: "GAL" },
    { Id: "ELEC", Description: "Electronics", UoM: "USD" },
    { Id: "FAX", Description: "Faxdefc", UoM: "USD" },
    { Id: "FURN", Description: "Furnace Oil", UoM: "GAL" },
    { Id: "GAS", Description: "Gasoline", UoM: "GAL" },
    { Id: "GASM", Description: "Mexico Gas Magna", UoM: "GAL" },
    { Id: "GASP", Description: "Premium Gas Mexico", UoM: "GAL" },
    { Id: "GROC", Description: "C-Store/Groceries", UoM: "USD" },
    { Id: "HARD", Description: "Hardware/Accessories", UoM: "USD" },
    { Id: "IDLE", Description: "IdleAire", UoM: "USD" },
    { Id: "JET", Description: "Jet Fuel", UoM: "GAL" },
    { Id: "KERO", Description: "Kerosene", UoM: "GAL" },
    { Id: "LABR", Description: "Labor", UoM: "USD" },
    { Id: "LMPR", Description: "Lumper", UoM: "USD" },
    { Id: "LNG", Description: "Liquid Natural Gas", UoM: "GAL" },
    { Id: "MDSL", Description: "Marked/Tax Exempt Diesel", UoM: "GAL" },
    { Id: "MERC", Description: "Miscellaneous Merchandise", UoM: "USD" },
    { Id: "MGAS", Description: "Marked/Tax Exempt Gas", UoM: "GAL" },
    { Id: "MRFR", Description: "Marked Reefer", UoM: "GAL" },
    { Id: "NGAS", Description: "Natural Gas", UoM: "GAL" },
    { Id: "OIL", Description: "Oil", UoM: "USD" },
    { Id: "OILC", Description: "Oil Change", UoM: "USD" },
    { Id: "PART", Description: "Parts", UoM: "USD" },
    { Id: "PHON", Description: "Phone Time", UoM: "USD" },
    { Id: "PNT", Description: "Paint", UoM: "USD" },
    { Id: "PROP", Description: "Propane", UoM: "GAL" },
    { Id: "RECP", Description: "Recap", UoM: "USD" },
    { Id: "REPR", Description: "Repair Service", UoM: "USD" },
    { Id: "REST", Description: "Restaurant", UoM: "USD" },
    { Id: "RFND", Description: "Refund", UoM: "USD" },
    { Id: "RFR", Description: "Reefer", UoM: "GAL" },
    { Id: "RFRM", Description: "Thermo", UoM: "GAL" },
    { Id: "SCAN", Description: "Tons Imaging", UoM: "USD" },
    { Id: "SCLE", Description: "Weigh Scale", UoM: "USD" },
    { Id: "SHWR", Description: "Shower", UoM: "USD" },
    { Id: "SPLT", Description: "Split/Other Method of Payment", UoM: "USD" },
    { Id: "STAX", Description: "Sales Tax", UoM: "USD" },
    { Id: "TIRE", Description: "Tires/Tire Repairs", UoM: "USD" },
    { Id: "TOLL", Description: "Ambassador Bridge Toll", UoM: "USD" },
    { Id: "TRAL", Description: "Trailer", UoM: "USD" },
    { Id: "TRPP", Description: "Trip Permit", UoM: "USD" },
    { Id: "ULSD", Description: "Ultra Low Sulfur Diesel", UoM: "GAL" },
    { Id: "WASH", Description: "Car Wash", UoM: "USD" },
    { Id: "WIFI", Description: "Fleet WiFi Billing", UoM: "USD" },
    { Id: "WWFL", Description: "Windshield Washer Fluid", UoM: "USD" }
];

const PolicyLimitsGrid = (props: Props) => {
    return <div className="container-fluid">
        <Grid
            data={props.PolicyLimits}
            style={{ maxHeight: '600px' }}>
            <GridToolbar>
                <button type="button"
                    title={props.CardInOverrideState ? "Override must be cancelled, prior to use" : ""}
                    disabled={props.CardInOverrideState}
                    className="btn btn-primary"
                    onClick={() => props.OpenAddProductLimitDialog()}>Manage Card (Non-Group Policy) Product Limits</button>

            </GridToolbar>
            <Column field="LimitId" title="ID" cell={LimitIdCell} />
            <Column field="PolicySource" title="Policy" />
            <Column field="Limit" title="Limit" cell={LimitCell} headerCell={LimitHeaderCell} className="text-right" />
            <Column field="Limit" title="Measure" cell={LimitMeasureCell} />
            <Column field="Hours" title="Hours" />
            <Column field="MinHours" title="Min. Hour" />
            <Column field="AutoRollMap" title="Auto Roll Map" cell={AutoRollMapCell} />
            <Column field="AutoRollMax" title="Daily Max Limit" cell={AutoRollMaxCell} />
            {/* <Column cell={CommandCell} width="52px" />*/}
        </Grid>
    </div >

}

export default PolicyLimitsGrid;

