import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import Checkbox from '../../components/Checkbox';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

export const carrierOfferExpiryMinutes = [
  { value: 5, text: '5 Minutes' },
  { value: 15, text: '15 Minutes (default)' },
  { value: 30, text: '30 Minutes' },
  { value: 60, text: '1 Hour' },
  { value: 120, text: '2 Hours' },
  { value: 240, text: '4 Hours' },
  { value: 480, text: '8 Hours' },
  { value: 720, text: '12 Hours' },
  { value: 960, text: '16 Hours' },
  { value: 1200, text: '20 Hours' },
  { value: 1440, text: '1 Day' },
  { value: 2880, text: '2 Days' },
]

interface Props {
  quoteID: number;
  orderStatus: number | null;
  rate: number;
  margin: number;
  note: string;
  carrierIDs: number[];
  refresh: () => void;
  onClose: () => void;
}

interface State {
  loading: boolean;
  note: string;
  confirmed: boolean;
  margin: number;
  expiresInMinutes: number;
}

export class OfferMultiCarrier extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      note: props.note,
      confirmed: props.orderStatus == 100,
      margin: props.margin,
      expiresInMinutes: 15
    };

    this.getRate = this.getRate.bind(this);
    this.offerCarrier = this.offerCarrier.bind(this);
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.note != prevProps.note) {
      this.setState({ note: this.props.note });
    }
  }

  render() {
    const plural = this.props.carrierIDs.length > 1 ? 's' : '';
    return <Dialog title={`Offer Carrier${plural}`} className='dialog-w800' onClose={this.props.onClose}>
      <div className="form-group mb-0">
        <label htmlFor="offerNote">
          {this.props.carrierIDs.length} Carrier{plural} Selected
        </label>
      </div>
      {this.props.orderStatus == 100 && <div className="form-check">
        <Checkbox
          id={"confirmed"}
          className="form-check-input"
          defaultValue={this.state.confirmed}
          value={this.state.confirmed.toString()}
          handleCheckboxChange={() => this.setState({ confirmed: !this.state.confirmed }) } />
        <label className="form-check-label" htmlFor="confirmed">Confirmed Load</label>
        <br /><br />
      </div>}
      <div className="form-group">
        <label htmlFor="margin">% of Revenue&nbsp;
          <NumericTextBox
            id="margin"
            format="p"
            value={this.state.margin}
            onChange={(e) => this.setState({ margin: e.value })}
            min={0}
            max={1}
            step={0.01}
          /> can pay <b>{formatNumber(this.getRate(), "c0")}</b>
        </label>
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          id="offerNote"
          rows={3}
          maxLength={240}
          placeholder="This note will be sent to the carrier with the request for quote... (Optional)"
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
        />
      </div>
      <div className="form-group mb-0">
        <label>Posting Expires In</label>
        <DropDownList
            style={{ width: '100%' }}
            value={carrierOfferExpiryMinutes.find(x => x.value == this.state.expiresInMinutes)}
            data={carrierOfferExpiryMinutes}
            textField="text"
            onChange={(e) => this.setState({ expiresInMinutes: e.target.value.value })}
        />
      </div>
      <DialogActionsBar>
        <Button disabled={this.state.loading} themeColor="primary" onClick={this.offerCarrier}>
          Offer Carrier{plural}
        </Button>
      </DialogActionsBar>
    </Dialog>;
  }

  private getRate() {
    const rate = this.props.rate * this.state.margin;
    return Math.floor(rate / 5) * 5;
  }

  private offerCarrier() {
    if (this.props.quoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    this.setState({ loading: true });

    const offerRequests = this.props.carrierIDs.map((carrier) => {
      const data = {
        QuoteID: this.props.quoteID,
        CarrierID: carrier,
        BookItRate: this.getRate(),
        Confirmed : this.state.confirmed,
        Note: this.state.note,
        ExpiresInMinutes: this.state.expiresInMinutes
      };
      return fetchApi("/api/Quote/OfferCarrier", data, 'POST');
    });

    Promise.all(offerRequests)
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(() => {
        alert("Unable to offer carrier!");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
}
