import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox, Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { ListBox } from "@progress/kendo-react-listbox";
import { Fade } from "@progress/kendo-react-animation";
import { EFSPolicyLimitsViewItem } from './DriverCardMaintenance';
import { PolicyLimitMappings, ProductLimitFormatNumber } from './PolicyLimitsGrid';
import { formatNumber } from '@progress/kendo-intl';
import {
    ListView,
    ListViewHeader,
    ListViewItemProps,
} from "@progress/kendo-react-listview";
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import { arrowRightIcon, xIcon } from '@progress/kendo-svg-icons';

type Props = {
    DriverID: number,
    DriverNumber: string,
    DriverName: string,
    CardNumber: string,
    PolicyLimits: EFSPolicyLimitsViewItem[],
    CloseDialog: (HasChanges: boolean) => void;
}

type ProductLimitMappingViewModel = {
    Id: string,
    Name: string,
    Selected: boolean,
    OriginalAmount: number,
    Amount: number,
    UoM: string,
    DisplayName: string
}

const OverrideCardDialog = (props: Props) => {
    const [productLimitOverride, setProductLimitOverride] = useState(false);
    const [handEnterOverride, setHandEnterOverride] = useState(false);
    const [locationOverride, setLocationOverride] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [listBoxProductLimits, setListBoxProductLimits] = useState<ProductLimitMappingViewModel[]>(PolicyLimitMappings.map(x => {
        let defaultFromCurrentPolicyOrCardProductLimit = props.PolicyLimits.find(y => y.LimitId === x.Id);
        let policyLimitMapping = PolicyLimitMappings.find(y => y.Id === x.Id);
        return {
            Id: policyLimitMapping.Id,
            Name: policyLimitMapping.Description,
            Selected: false,
            OriginalAmount: defaultFromCurrentPolicyOrCardProductLimit == null ? 0 : defaultFromCurrentPolicyOrCardProductLimit.Limit,
            UoM: policyLimitMapping.UoM,
            Amount: 0,
            DisplayName: `${policyLimitMapping.Description} (${policyLimitMapping.Id})`
        } as ProductLimitMappingViewModel;
    }).sort(function (a, b) {
        return a.Name.localeCompare(b.Name);
    }));

    const [showProductLimitInsertDialog, setShowProductLimitInsertDialog] = useState(false);

    //Listbox Selection List
    //const [originalProductLimitAmount, setOriginalProductLimitAmount] = useState(0);

    //const [productLimitName, setProductLimitName] = useState('');
    //const [selectedEFSPolicy, setSelectedEFSPolicy] = useState<EFSPolicyLimitsViewItem>(null); //or called policy.product limit
    const [productLimitAmount, setProductLimitAmount] = useState(0); //textbox
    const [selectedProductLimit, setSelectedProductLimit] = useState<ProductLimitMappingViewModel>(null); //Todo: Rename these

    //Listview Override List
    const [productLimitOverrideListing, setProductLimitOverrideListing] = useState<ProductLimitMappingViewModel[]>([]);


    const _save = () => {
        setLoading(true);

        //could be done on server level
        //let efsOverrideList: EFSPolicyLimitsViewItem[] = [];
        //const filteredArray = productLimitOverrideListing.forEach((productLimitOverrideItem) => {
        //    let efsProductItem = props.PolicyLimits.map(x => x).filter(x => x.LimitId === productLimitOverrideItem.Id)[0];
        //    efsProductItem.Limit = productLimitOverrideItem.Amount
        //    efsOverrideList.push(efsProductItem);
        //});

        const data = {
            DriverID: props.DriverID,
            HandEnterOverride: handEnterOverride,
            LocationOverride: locationOverride,
            ProductLimitOverride: productLimitOverride,
            ProductLimitOverrideListing: productLimitOverrideListing
            //EFSPolicyLimitOverride: selectedEFSPolicy, //contains IDs, not name like OIL
            //PolicyLimitOverrideName: productLimitName, //OIL
            //PolicyLimitOverrideAmount: productLimitAmount
        }
        fetchApi(`/api/Asset/SetEFSCardOverride`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success)
                    props.CloseDialog(true);
                else {
                    alert(response.ErrorMessage);
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to override card');
            });
    }

    const deleteItem = (productLimitOverrideItem: ProductLimitMappingViewModel) => {
        setProductLimitOverrideListing(productLimitOverrideListing.map(x => x).filter(x => x.Id !== productLimitOverrideItem.Id));
        const efsProductLimitItem = props.PolicyLimits.find(x => x.LimitId === productLimitOverrideItem.Id);
        productLimitOverrideItem.Amount = efsProductLimitItem == null ? 0 : efsProductLimitItem.Limit;
        productLimitOverrideItem.Selected = false;
        let pl = listBoxProductLimits.concat(productLimitOverrideItem)
        setListBoxProductLimits(pl.sort(function (a, b) {
            return a.Name.localeCompare(b.Name);
        }));
    };

    const MyItemRender = (innerProps: ListViewItemProps) => {
        const productLimitItem = innerProps.dataItem as ProductLimitMappingViewModel;
        // const efsProductLimitItem = props.PolicyLimits.find(x => x.LimitId === productLimitItem.Id);
        return (
            <div className="row p-1 border-bottom align-middle" style={{ margin: 0 }}>
                <div className="col-2" style={{ whiteSpace: "nowrap" }}>
                    <div className="text-right">
                        <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                            {productLimitItem.Id}
                        </h2>
                        <div style={{ fontSize: 12, color: "#a0a0a0" }}>{ProductLimitFormatNumber(productLimitItem.Id, productLimitItem.OriginalAmount)}</div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="text-right">
                        <h2 style={{ fontSize: 13, marginBottom: 0 }} className="text-uppercase text-secondary">
                            {productLimitItem.Name}
                        </h2>
                        <div style={{ fontSize: 12, color: "#a0a0a0" }}>{ProductLimitFormatNumber(productLimitItem.Id, productLimitItem.Amount)} {productLimitItem.UoM}</div>
                    </div>

                </div>
                <div className="col-2">
                    <Button
                        type="button"
                        themeColor="warning"
                        svgIcon={xIcon}
                        onClick={() => deleteItem(productLimitItem)}
                    />
                </div>
            </div>
        );
    };

    return <div className="container-fluid">
        <Dialog title={"Override Card"} onClose={() => props.CloseDialog(false)} width={825} height={520}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <form className="k-form k-form-md p-0">
                <h5>Single Use Override Request</h5>
                <FieldWrapper>
                    <Checkbox label={"Allow Hand Enter"} value={handEnterOverride} onChange={(e) => setHandEnterOverride(e.value)} />
                </FieldWrapper>
                <FieldWrapper>
                    <Checkbox label={"Allow All Locations"} value={locationOverride} onChange={(e) => setLocationOverride(e.value)} />
                </FieldWrapper>
                <FieldWrapper>
                    <Checkbox label={"Allow Product Limit Override"} value={productLimitOverride} onChange={(e) => setProductLimitOverride(e.value)} />
                </FieldWrapper>
                <Fade style={{ zIndex: "1", width: "100%" }} transitionEnterDuration={800}>
                    {productLimitOverride && <>
                        <div className="row" style={{ minHeight: "100px" }}>
                            <div className="col-4">
                                <FieldWrapper>
                                    <label>Product Limit Selection List</label>
                                    <ListBox
                                        style={{ height: 200, width: "100%" }}
                                        data={listBoxProductLimits}
                                        textField="DisplayName"
                                        selectedField="Selected"
                                        onItemClick={(e) => {
                                            let policyLimit = listBoxProductLimits.map(x => x).find(x => x.Id === (e.dataItem as ProductLimitMappingViewModel).Id);
                                            if (policyLimit.Selected === false) {
                                                listBoxProductLimits.forEach(x => { x.Selected = false; });
                                                policyLimit.Selected = true;
                                                //setOriginalProductLimitAmount(policyLimit.Amount);
                                                // setProductLimitAmount(policyLimit.Amount);
                                                //setProductLimitName(policyLimit.Name);

                                                setSelectedProductLimit(policyLimit);
                                                //setSelectedEFSPolicy(props.PolicyLimits.map(x => x).find(x => x.LimitId === (e.dataItem as ProductLimitMappingViewModel).Id));
                                            } else {
                                                listBoxProductLimits.forEach(x => { x.Selected = false; });
                                                policyLimit.Selected = false;
                                                //  setOriginalProductLimitAmount(0);
                                                // setProductLimitAmount(0);
                                                //  setProductLimitName('');

                                                setSelectedProductLimit(null);
                                                //setSelectedEFSPolicy(null);
                                            }
                                            setListBoxProductLimits(listBoxProductLimits.map(x => x));
                                        }}
                                    />
                                </FieldWrapper>
                            </div>
                            <div style={{ width: "40px" }}>
                                <div className="d-flex align-items-center justify-content-center h-100">
                                    <Button
                                        type="button"
                                        themeColor="primary"
                                        svgIcon={arrowRightIcon}
                                        disabled={selectedProductLimit == null}
                                        onClick={(e) => setShowProductLimitInsertDialog(true)}
                                    />
                                </div>
                            </div>
                            <div className="col-7">
                                <FieldWrapper>
                                    <label>Product Limit Overrides</label>
                                    <ListView
                                        data={productLimitOverrideListing}
                                        item={MyItemRender}
                                        style={{ width: "100%", height: 200 }}
                                    //header={MyHeader}
                                    />
                                </FieldWrapper>
                            </div>
                        </div>
                    </>}
                </Fade>

            </form>

            <DialogActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}
                    disabled={loading || (handEnterOverride === false && locationOverride === false && productLimitOverride === false) ||
                        (productLimitOverride === true && productLimitOverrideListing.length === 0)}
                >
                    Save Overrides
                </Button>
            </DialogActionsBar>
        </Dialog>
        {showProductLimitInsertDialog && <Dialog title={"Add Product Limit Override"} onClose={() => setShowProductLimitInsertDialog(false)} width={320} height={260}>
            <div className="row">
                <div className="col-12">
                    <FieldWrapper>
                        <label>{selectedProductLimit.Name} ({selectedProductLimit.Id}) Override Amount</label>
                        <div className="d-flex justify-content-between">
                            <NumericTextBox
                                width={'100%'}
                                defaultValue={selectedProductLimit.OriginalAmount}
                                // value={productLimitAmount}
                                onChange={(e) => setProductLimitAmount(e.value)}
                                format="n0"
                                min={selectedProductLimit.OriginalAmount}
                                step={25}
                            />
                            <label className="ml-1" style={{ margin: "auto" }}>{selectedProductLimit.UoM}</label>
                        </div>
                        <label>* Default limit is shown.  Amount override must be greater than default limit.</label>
                    </FieldWrapper>
                </div>
            </div>
            <DialogActionsBar layout="end">
                <button type="button" className="k-button" onClick={() => {
                    setShowProductLimitInsertDialog(false);
                    setProductLimitAmount(selectedProductLimit.OriginalAmount);
                }}>Cancel</button>
                <button type="button" className="k-button k-primary"
                    disabled={(productLimitAmount === 0) || (selectedProductLimit.OriginalAmount > 0 && productLimitAmount <= selectedProductLimit.OriginalAmount)}
                    onClick={() => {
                        let productLimit = { ...selectedProductLimit };
                        productLimit.Amount = productLimitAmount;
                        setProductLimitOverrideListing(productLimitOverrideListing.concat(productLimit));
                        setListBoxProductLimits(listBoxProductLimits.filter(x => x.Id !== productLimit.Id));
                        setShowProductLimitInsertDialog(false);
                        setProductLimitAmount(selectedProductLimit.OriginalAmount);
                    }}>Add</button>
            </DialogActionsBar>
        </Dialog>}
    </div>
}

export default OverrideCardDialog;

