import { SmartbidValue, WinPercentageChartValue } from 'views/Quote/Quote';
import { RatingCalculationHeader } from '.';
import { QuotePanelTable } from './QuotePanelTable';

export type QuotePanelStateValue = {
  quoteData: QuoteValue | null;
  allInRateData: QuoteLineItemValue[];
}

type Props = {
  quoteId: number;
  dataItem: RatingCalculationHeader | null;
  createQuote: (lineitems: QuoteLineItemValue[], rateComment: string, tariff: boolean) => void;
  getSmartbidData: (linehaul: number) => Promise<SmartbidValue | null>;
  getWinPercentageData: (linehaul: number) => Promise<WinPercentageChartValue | null>;
  useSmartbidRate: (rate: number, rateChange: number, currentWin: number, newWin: number) => void;
  quotePanelStateValue: QuotePanelStateValue;
};

export type QuoteValue = {
  Amount: number;
  LineHaulAmount: number;
  FuelAmount: number;
  AccessorialAmount: number;
  BonusAmount: number;
  RateComment: string;
  LineItems: QuoteLineItemValue[];
}

export type QuoteLineItemValue = {
  RateDescriptionID: number;
  RateType: number;
  Name: string;
  Quantity: number;
  QuantityUoM: number;
  Rate: number;
  RateUoM: number;
  Amount: number;
  IsAutoRated: boolean;
}

export const QuotePanel = (props: Props) => {

  const drawRateTableCard = () => {
    if (props.dataItem && props.quotePanelStateValue && props.quotePanelStateValue.quoteData && props.quotePanelStateValue.quoteData.Amount > 0) {
      return <QuotePanelTable
        rateComment={props.quotePanelStateValue.quoteData.RateComment}
        quoteId={props.quoteId}
        lineItems={props.quotePanelStateValue.quoteData.LineItems}
        createQuote={() => props.createQuote(props.quotePanelStateValue.quoteData.LineItems, props.quotePanelStateValue.quoteData.RateComment, true)}
        useSmartbidRate={props.useSmartbidRate}
        getSmartbidData={props.getSmartbidData}
        getWinPercentageData={props.getWinPercentageData}
      />
    } else {
      return (
        <div className="card mt-2">
          <div className="card-body">
            <h5 className="card-title">No Tariff/Customer Rates Found</h5>
          </div>
        </div>)
    }
  }

  const drawAllInRateTableCard = () => {
    if (props.quotePanelStateValue && props.quotePanelStateValue.allInRateData) {
      return <QuotePanelTable
        rateComment="My Rate"
        quoteId={props.quoteId}
        lineItems={props.quotePanelStateValue.allInRateData}
        createQuote={() => props.createQuote(props.quotePanelStateValue.allInRateData, "User Override", false)}
        useSmartbidRate={props.useSmartbidRate}
        getSmartbidData={props.getSmartbidData}
        getWinPercentageData={props.getWinPercentageData}

      />
    } else {
      return (
        <div className="card mt-2">
          <div className="card-body">
            <h5 className="card-title">My Rate</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              Enter per mile or flat rate
            </h6>
          </div>
        </div>)
    }
  }

  return <div>
    {drawRateTableCard()}
    {drawAllInRateTableCard()}
  </div>;
}

export default QuotePanel;
