import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { fetchApi } from "services/api";
import { ComboBox } from "@progress/kendo-react-dropdowns";

type Props = {
  onClose: () => void;
  orderId: string;
  selectedIds: number[];
};

export const EmailPaperwork = ({ onClose, orderId, selectedIds }: Props) => {

  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getEmailAddresses = async () => {
      const data = await fetchApi("/api/Order/EmailPaperwork/" + orderId);
      setEmailAddresses(data.EmailAddresses);
    }

    getEmailAddresses();
  }, [orderId]);

  const email = async () => {
    setLoading(true);

    const data = {
      ImageIDs: selectedIds,
      EmailAddresses: [emailAddress],
    };

    try {
      await fetchApi("/api/Order/EmailPaperwork", data, "POST");

      onClose();
    } catch (e: any) {
      // If not problem details
      if (!e?.status) alert(e);
    } finally {
      setLoading(false);
    }
  }

  return <Dialog title="E-Mail Paperwork" onClose={onClose} className="dialog-w525">
    <FieldWrapper>
      <ComboBox
        required
        disabled={loading}
        clearButton={!!emailAddress}
        allowCustom
        placeholder="person@company.com"
        data={emailAddresses}
        value={emailAddress}
        onChange={(e) => {
          setEmailAddress(e.target.value || '');
        }}
      />
    </FieldWrapper>
    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button
        themeColor="primary"
        disabled={loading}
        onClick={email}
      >
        Send
      </Button>
    </DialogActionsBar>
  </Dialog>
}
