import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class CallerCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    if (!this.props.dataItem.CallerName) {
        return <td>{this.props.dataItem.CallerNumber}</td>;
    }

    return <td title={this.props.dataItem.CallerNumber}>{this.props.dataItem.CallerName}</td>;
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
