import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ViewModel } from "TypeGen/Order/TransitTime/view-model";
import CenterDivPanel from "components/CenterDivPanel";
import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { useBoolean } from "usehooks-ts";
import Moment from 'moment-timezone';

type Props = {
  OrderID: string;
  onClose: () => void;
};

const TransitTime = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [transitTime, setTransitTime] = useState<ViewModel>();
  const { value: isTeam, toggle: toggleTeam } = useBoolean(false);

  const refreshOrderTransitTime = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Order/TransitTime/${props.OrderID}`)
      .then((response: ViewModel) => {
        setTransitTime(response);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load transit time');
      });
  }, [props.OrderID]);

  useEffect(() => {
    refreshOrderTransitTime();
  }, [refreshOrderTransitTime]);

  return <Dialog title={`Order Transit Time`} onClose={props.onClose}>
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    <Checkbox label="Team Driving?" value={isTeam} onClick={toggleTeam} />
    <br />
    <table className="table mt-2 mb-0">
      <tbody>
        <tr>
          <th>Event</th>
          <th>Location</th>
          <th>Driving</th>
          <th>Sleep</th>
          <th>Wait</th>
          <th>Schedule</th>
          <th>Estimated</th>
        </tr>
      </tbody>
      <tbody>
        {transitTime?.Stops.map((stop, index) => {
          const scheduled = Moment.utc(stop.ScheduledDateTime);
          const suggested = Moment.utc(isTeam ? stop.SuggestedDateTimeTeam : stop.SuggestedDateTime);

          return <tr key={index}>
            <td>{stop.EventName}</td>
            <td>{stop.LocationName}</td>
            <td>{isTeam ? stop.TransitTimeTeam.DrivingTime : stop.TransitTime.DrivingTime}</td>
            <td>{isTeam ? stop.TransitTimeTeam.SleepTime : stop.TransitTime.SleepTime}</td>
            <td>{isTeam ? stop.TransitTimeTeam.WaitTime : stop.TransitTime.WaitTime}</td>
            <td>
              <span className={scheduled.isBefore(suggested) ? "badge badge-danger" : "badge badge-success"}>
                {scheduled.tz("America/New_York").format("MM/DD/YYYY HH:mm")}
              </span>
            </td>
            <td>{suggested.tz("America/New_York").format("MM/DD/YYYY HH:mm")}</td>
          </tr>
        })}
      </tbody>
    </table>
    <DialogActionsBar layout="end">
      <Button onClick={props.onClose}>Close</Button>
    </DialogActionsBar>
  </Dialog>
}

export default TransitTime;