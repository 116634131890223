import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import Moment from 'moment-timezone';
import { VehicleValue } from '../Vehicles';

export default class AvailabilityNote extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    const vehicle = this.props.dataItem as VehicleValue;

    if (vehicle.Destination) {
        return (
            <td title={vehicle.AvailabilityNote}>
              {vehicle.Destination.Location} by {Moment.utc(vehicle.Destination.DateTime).tz("America/New_York").format("M/D HH:mm")}
            </td>
        );
    }

    return (
      <td>
        {vehicle.AvailabilityNote}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
