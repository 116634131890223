import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useState } from "react";
import { DriverAccidentClaim } from "TypeGen/Assets/DriverAccidents/driver-accident-claim";

type Props = {
  loading: boolean;
  accidentClaim: DriverAccidentClaim;
  save: (claim: DriverAccidentClaim) => void;
  cancel: () => void;
};

export const claimTypes = [
  { ID: 0, Name: 'Select' },
  { ID: 1, Name: 'Cargo' },
  { ID: 2, Name: 'Liability - Personal Injury' },
  { ID: 3, Name: 'Liability - Property damage' },
  { ID: 4, Name: 'Collision - Physical Damage - OO' },
  { ID: 5, Name: 'Comprehensive - OO' },
];

export const claimStatuses = [
  { ID: 0, Name: 'Select' },
  { ID: 1, Name: 'Open' },
  { ID: 2, Name: 'Closed' },
];

const DriverAccidentClaimEditUI: React.FC<Props> = ({ loading, accidentClaim, cancel, save, ...props }) => {
  const [claim, setClaim] = useState<DriverAccidentClaim>(accidentClaim);
  const claimName = `${claim.DriverAccidentClaimID == 0 ? 'Add ' : 'Update '} Claim`;

  return <Dialog
    title={claimName}
    className="dialog-w800"
    onClose={cancel}
  >
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <Label>Claim Number</Label>
        <Input
          autoFocus
          value={claim.ClaimNumber}
          onChange={e => setClaim({ ...claim, ClaimNumber: e.value })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Claim Type</Label>
        <DropDownList
          data={claimTypes.filter(x => x.ID !== 0 || claim.DriverAccidentClaimID === 0)}
          textField="Name"
          dataItemKey="ID"
          value={claimTypes.find(x => x.ID == claim.ClaimType)}
          onChange={e => setClaim({ ...claim, ClaimType: e.target.value.ID })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Claim Status</Label>
        <DropDownList
          data={claimStatuses.filter(x => x.ID !== 0 || claim.DriverAccidentClaimID === 0)}
          textField="Name"
          dataItemKey="ID"
          value={claimStatuses.find(x => x.ID == claim.ClaimStatus)}
          onChange={e => setClaim({ ...claim, ClaimStatus: e.target.value.ID })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Vendor Name</Label>
        <Input
          value={claim.VendorName}
          onChange={e => setClaim({ ...claim, VendorName: e.value })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Payout Amount</Label>
        <NumericTextBox
          min={0}
          spinners={false}
          format="c2"
          value={claim.PayoutAmount}
          onChange={e => setClaim({ ...claim, PayoutAmount: e.value })}
        />
      </FieldWrapper>
    </form>
    <DialogActionsBar>
      <Button disabled={loading} onClick={cancel}>Cancel</Button>
      <Button
        disabled={loading}
        themeColor={claim.DriverAccidentClaimID == 0 ? 'success' : 'primary'}
        onClick={() => save(claim)}
      >
        {claimName}
      </Button>
    </DialogActionsBar>
  </Dialog>
}

export default DriverAccidentClaimEditUI;