import moment from "moment-timezone";
import React, { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Link } from "react-router-dom";
import { formatNumber } from '@progress/kendo-intl';
import { Chart, ChartSeries, ChartSeriesItem, ChartSeriesLabels, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem } from "@progress/kendo-react-charts";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { fetchApi } from "services/api";
import { Title } from "utils/title";
import { useInterval } from "usehooks-ts";
import SimpleDateRangePicker from "components/SimpleDateRangePicker";
import PopupNavBar from "components/Layout/PopupNavBar";
import { IDName } from "types/idname";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { Drawer, DrawerItem } from "@progress/kendo-react-layout";
import { openWindow } from "services/openWindow";
import { Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import { GridLoadingPanel } from "components/GridLoadingPanel";
import UserPerformanceGoalDialog from "./UserPerformanceGoalDialog";
import { ApiLink } from "TypeGen/api-link";
import CustomerInput, { CustomerInputValue } from "components/CustomerInput";
import { SvgIcon } from "@progress/kendo-react-common";
import { arrowRotateCwIcon, caretAltUpIcon, caretAltDownIcon, downloadIcon } from '@progress/kendo-svg-icons';

type DashboardResponse = {
  Users: UserPerformance[];
  ShiftCategories: IDName[];
  Locations: IDName[];
}

type HeatmapResponse = {
  Cards: Card[];
  Heatmap: HeatmapChart;
}

type Card = {
  Title: string;
  FormattedValue: string;
  SubFormattedValue1: string;
  SubFormattedValue2: string;
  SubFormattedValue3: string;
  PercentageChange: number | null;
  Change: string;
}

type DetailCard = {
  Title: string;
  DateTime: string;
  TotalCharges: number;
  OrderID: number | null;
  QuoteID: number | null;
}

type DetailResponse = {
  Cards: DetailCard[];
  Title: string;
}

type UserPerformance = {
  UserID: number;
  UserName: string;
  LocationID: number;
  ShiftCategoryID: number;
  QuoteCount: number;
  OrderCount: number;
  DispatchCount: number;
  CustomerCount: number;
  AverageOrderRPM: number;
  AverageQuoteRPM: number;
  TotalOrderCharges: number;
  TotalPay: number;
  TotalQuoteCharges: number;
  BrokerPercentage: number;
  BlindBidPercentage: number;
  MarginPercentage: number;
  ConversionPercentage: number;
  GoalPercentage: number | null;
  GoalWeeklyBookedCount: number | null;
  GoalWeeklyDispatchedCount: number | null;
  GoalWeeklyTotalCharges: number | null;
  Me: boolean;
  Links: ApiLink[];
}

type HeatmapChart = {
  Title: string;
  FormatValue: string;
  Values: HeatmapChartValue[];
}

type HeatmapChartValue = {
  X: number;
  Y: number;
  Value: number;
}

enum HeatmapMetric {
  OrdersBooked,
  QuotesCreated,
  OrdersDispatched,
  OrderCharges,
  QuoteCharges,
  OrderRPM,
  QuoteRPM,
  MarginPercentage,
  ConversionPercentage,
}

const dayLabels: { [index: number]: string } = {
  1: "Sun",
  2: "Mon",
  3: "Tue",
  4: "Wed",
  5: "Thu",
  6: "Fri",
  7: "Sat",
};

const yAxisLabelContent = (e: any) => dayLabels[e.value] || "";

const xAxisLabelContent = (e: any) => {
  return `${e.value % 12 || 12}:00 ${e.value < 12 ? 'AM' : 'PM'}`;
};

const VehicleTypes = [
  { ID: 0, Name: 'All Vehicle Types' },
  { ID: 1, Name: 'Cargo Van' },
  { ID: 15, Name: 'Sprinter' },
  { ID: 2, Name: 'Small Straight' },
  { ID: 3, Name: 'Large Straight' },
  { ID: 4, Name: 'Tractor' },
];

const metrics = [
  { ID: HeatmapMetric.OrdersBooked, Name: 'Orders Booked' },
  { ID: HeatmapMetric.QuotesCreated, Name: 'Quotes Created' },
  { ID: HeatmapMetric.OrdersDispatched, Name: 'Orders Dispatched' },
  { ID: HeatmapMetric.OrderCharges, Name: 'Order Charges' },
  { ID: HeatmapMetric.QuoteCharges, Name: 'Quote Charges' },
  { ID: HeatmapMetric.OrderRPM, Name: 'Order RPM' },
  { ID: HeatmapMetric.QuoteRPM, Name: 'Quote RPM' },
  { ID: HeatmapMetric.MarginPercentage, Name: 'Margin %' },
  { ID: HeatmapMetric.ConversionPercentage, Name: 'Conversion %' }
];

const defaultCustomer: CustomerInputValue = {
  CustomerID: 0,
  CustomerNumber: ''
}

const UserReports = () => {
  const _export = useRef<ExcelExport | null>(null);
  const { status } = useThemeSwitcher();
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [heatmapLoading, setHeatmapLoading] = useState(false);

  const [fromDate, setFromDate] = useState(moment.tz("America/New_York").startOf('week').toDate());
  const [toDate, setToDate] = useState(moment.tz("America/New_York").endOf('week').startOf('day').toDate());
  const [vehicleTypeId, setVehicleTypeId] = useState(0);
  const [billingCustomer, setBillingCustomer] = useState<CustomerInputValue>(defaultCustomer);
  const [userId, setUserId] = useState(0);
  const [metric, setMetric] = useState(HeatmapMetric.OrdersBooked);
  const [cards, setCards] = useState<Card[]>([]);
  const [users, setUsers] = useState<UserPerformance[]>([]);
  const [sylectusUsers, setSylectusUsers] = useState<IDName[]>([]);
  const [userFilter, setUserFilter] = useState<FilterDescriptor>(null);
  const [heatmap, setHeatmap] = useState<HeatmapChart>({ Title: '', FormatValue: '', Values: [] });
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<DetailResponse>(null);
  const [sort, setSort] = useState<SortDescriptor[]>([
    { field: 'UserName', dir: 'asc' }
  ]);
  const [editUserId, setEditUserId] = useState<number | null>(null);

  const [locations, setLocations] = useState<IDName[]>([]);
  const [locationFilter, setLocationFilter] = useState<number | null>(null);
  const [shiftCategories, setShiftCategories] = useState<IDName[]>([]);
  const [shiftCategoryFilter, setShiftCategoryFilter] = useState<number | null>(null);
  const [allUserFilter, setAllUserFilter] = useState(false);

  const fetchDashboard = useCallback(() => {
    if (!fromDate || !toDate) return;
    setDashboardLoading(true);
    const data = {
      UserID: userId,
      VehicleTypeID: vehicleTypeId,
      BillingCustomerID: billingCustomer?.CustomerID,
      FromDate: fromDate,
      ToDate: toDate
    }
    fetchApi('/api/User/Dashboard', data, 'POST')
      .then((response: DashboardResponse) => {
        setUsers(response.Users);
        setShiftCategories(response.ShiftCategories);
        setLocations(response.Locations);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error fetching dashboard');
      })
      .finally(() => setDashboardLoading(false));
  }, [userId, vehicleTypeId, billingCustomer?.CustomerID, fromDate, toDate]);

  const fetchHeatmap = useCallback(() => {
    if (!fromDate || !toDate) return;
    setHeatmapLoading(true);
    const data = {
      UserID: userId,
      VehicleTypeID: vehicleTypeId,
      BillingCustomerID: billingCustomer?.CustomerID,
      Metric: metric,
      FromDate: fromDate,
      ToDate: toDate,
    };
    fetchApi('/api/User/Heatmap', data, 'POST')
      .then((response: HeatmapResponse) => {
        setCards(response.Cards);
        setHeatmap(response.Heatmap);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error fetching heatmap');
      })
      .finally(() => setHeatmapLoading(false));
  }, [userId, vehicleTypeId, billingCustomer?.CustomerID, metric, fromDate, toDate]);

  const fetchHeatmapDetails = (hour: number, weekday: number) => {
    setShowDetails(true);
    setHeatmapLoading(true);
    const data = {
      UserID: userId,
      VehicleTypeID: vehicleTypeId,
      BillingCustomerID: billingCustomer?.CustomerID,
      Metric: metric,
      FromDate: fromDate,
      ToDate: toDate,
      Weekday: weekday,
      Hour: hour,
    };
    fetchApi('/api/User/HeatmapDetails', data, 'POST')
      .then((response: DetailResponse) => {
        setDetails(response);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error fetching heatmap details');
        setShowDetails(false);
      })
      .finally(() => setHeatmapLoading(false));
  };

  useEffect(() => {
    fetchApi('/api/User/SylectusUsersDropdown')
    .then((data: { Users: Array<{ ID: number, Name: string }> }) => {
      setSylectusUsers([{ ID: 0, Name: 'All Users' }, ...data.Users]);
    });
  }, [setSylectusUsers]);

  const refresh = () => {
    fetchDashboard();
    fetchHeatmap();
  }

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setSort(event.sort);
  };

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  useEffect(() => {
    fetchHeatmap();
  }, [fetchHeatmap]);

  useInterval(() => {
    fetchHeatmap();
  }, 15 * 60 * 1000);

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  const loading = dashboardLoading || heatmapLoading;

  const usersFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
      { field: 'LocationID', operator: 'eq', value: locationFilter },
      { field: 'ShiftCategoryID', operator: 'eq', value: shiftCategoryFilter },
      { field: 'GoalPercentage', operator: 'neq', value: allUserFilter ? -1 : null }
    ]
      .filter(x => x.field === 'LocationID' ? x.value !== null : true)
      .filter(x => x.field === 'ShiftCategoryID' ? x.value !== null : true)
  };

  return <React.Fragment>
    <PopupNavBar />
    <Title string="Performance Dashboard" />
    {editUserId && <UserPerformanceGoalDialog userId={editUserId} close={() => setEditUserId(null)} refresh={fetchDashboard} />}
    <nav className="navbar navbar-dark bg-dark">
      <Link to="/" className="navbar-brand">PERFORMANCE DASHBOARD {loading && <Loader type="pulsing" className="p-0" />}</Link>
      <ul className="nav navbar-nav navbar-right">
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          disabled={loading}
          onClick={refresh}
        />
      </ul>
    </nav>
    <Drawer
      expanded={showDetails}
      position="end"
      mode="overlay"
      animation={{ duration: 400 }}
      width={300}
      onOverlayClick={() => {
        setShowDetails(false);
        setDetails(null);
      }}
      items={details?.Cards || []}
      item={(props) => {
        return <>
          {props.index === 0 && <>
            <li className="k-drawer-item k-disabled font-weight-bold">{details?.Title}</li>
            <DrawerItem separator />
          </>}
          <li className="k-drawer-item justify-content-between" onClick={() => props.onSelect(null, props.index)}>
            {props.Title}
            <span className="text-success">{formatNumber(props.TotalCharges,'C2')}</span>
          </li>
        </>
      }}
      onSelect={(e) => {
        const dataItem = details?.Cards[e.itemIndex];
        if (dataItem?.OrderID) openWindow(`/Order/${dataItem.OrderID}`);
        if (dataItem?.QuoteID) openWindow(`/Quote/Index/${dataItem.QuoteID}`);
      }}
    />
    <div className="container-fluid">
      <div className="row pt-2">
        <div className="col-sm-3">
          <SimpleDateRangePicker
            inputProps={{ disabled: loading }}
            value={{ start: fromDate, end: toDate }}
            onChange={(e) => {
              setFromDate(e.start);
              setToDate(e.end);
            }}
          />
        </div>
        <div className="col-sm-3">
          <DropDownList
            disabled={loading}
            data={VehicleTypes}
            textField="Name"
            dataItemKey="ID"
            value={VehicleTypes.find(x => x.ID == vehicleTypeId)}
            onChange={(e) => setVehicleTypeId(e.value.ID)}
          />
        </div>
        <div className="col-sm-3 input-group">
            <CustomerInput
              placeholder="Select Billing Customer"
              CustomerID={billingCustomer?.CustomerID}
              CustomerNumber={billingCustomer?.CustomerNumber}
              onChange={(customer) => setBillingCustomer(customer)}
            />
        </div>
        <div className="col-sm-3">
          <DropDownList
            disabled={loading}
            data={filterBy(sylectusUsers, userFilter)}
            filterable={true}
            onFilterChange={(e) => setUserFilter(e.filter)}
            value={sylectusUsers?.find(r => r.ID === userId)}
            textField="Name"
            dataItemKey="ID"
            style={{ width: '100%' }}
            onChange={(e) => setUserId(e.target.value.ID)}
          />
        </div>
      </div>
      {heatmap.Values.length > 0 && <div className="row mt-2">
        <div className="col-md-9">
          <Chart style={{ height: 600 }} onSeriesClick={(e) => fetchHeatmapDetails(e.dataItem.Y, e.dataItem.X)}>
            <ChartSeries>
              <ChartSeriesItem
                type="heatmap"
                data={heatmap.Values}
                xField="X"
                yField="Y"
                field="Value"
                color="#175985"
                size={30}
                markers={{ type: "roundedRect", border: { width: 2 } }}
              >
                <ChartSeriesLabels format={heatmap.FormatValue} />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartXAxis>
              <ChartXAxisItem labels={{ content: yAxisLabelContent }} />
            </ChartXAxis>
            <ChartYAxis>
              <ChartYAxisItem
                reverse={true}
                line={{ visible: false }}
                labels={{ content: xAxisLabelContent }}
              />
            </ChartYAxis>
          </Chart>
        </div>
        <div className="col-md-3">
          <DropDownList
            disabled={loading}
            data={metrics}
            value={metrics.find(r => r.ID === metric)}
            textField="Name"
            dataItemKey="ID"
            style={{ width: '100%' }}
            onChange={(e) => setMetric(e.target.value.ID)}
          />
          {cards.map((card, index) => {
            return <div key={index} className="col-md mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="text-muted fw-normal mt-0">{card.Title}</h5>
                  <h3 className="mt-3 mb-3">{card.FormattedValue}</h3>
                  {card.SubFormattedValue1 && <p className="mb-1">{card.SubFormattedValue1}</p>}
                  {card.SubFormattedValue2 && <p className="mb-1">{card.SubFormattedValue2}</p>}
                  {card.SubFormattedValue3 && <p className="mb-1">{card.SubFormattedValue3}</p>}
                  <p className="mb-0 text-muted">
                    {card.PercentageChange !== null && <span className={`mr-2 ${(card.PercentageChange > 0 ? "text-success" : card.PercentageChange < 0 ? "text-danger" : "")}`}>
                      <SvgIcon icon={card.PercentageChange > 0 ? caretAltUpIcon : card.PercentageChange < 0 ? caretAltDownIcon : undefined} /> {formatNumber(card.PercentageChange, 'p2')}
                    </span>}
                    <span className="text-nowrap">{card.Change}</span>
                  </p>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>}
      {dashboardLoading && <GridLoadingPanel />}
      <ExcelExport ref={_export} fileName={`Performance Dashboard ${moment(fromDate).format('MM-DD-YYYY')} to ${moment(toDate).format('MM-DD-YYYY')}`}>
        <ExportColumn field="UserName" title="User" />
        <ExportColumn field="QuoteCount" title="Quoted" cellOptions={{ format: "#,##0" }} />
        <ExportColumn field="OrderCount" title="Booked" cellOptions={{ format: "#,##0" }} />
        <ExportColumn field="DispatchCount" title="Dispatched" cellOptions={{ format: "#,##0" }} />
        <ExportColumn field="CustomerCount" title="Customers" cellOptions={{ format: "#,##0" }}  />
        <ExportColumn field="AverageOrderRPM" title="Order RPM" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="AverageQuoteRPM" title="Quote RPM" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="TotalOrderCharges" title="Order Charges" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="TotalPay" title="Trip Pay" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="TotalQuoteCharges" title="Quote Charges" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="BrokerPercentage" title="Broker %" cellOptions={{ format: "0.00%" }} />
        <ExportColumn field="BlindBidPercentage" title="Blind Bid %" cellOptions={{ format: "0.00%" }} />
        <ExportColumn field="MarginPercentage" title="Avg. Margin" cellOptions={{ format: "0.00%" }} />
        <ExportColumn field="ConversionPercentage" title="Conversion %" cellOptions={{ format: "0.00%" }} />
        <ExportColumn field="GoalPercentage" title="Week Goal" cellOptions={{ format: "0%" }} />
        <ExportColumn field="GoalWeeklyBookedCount" title="Weekly Booked Goal" cellOptions={{ format: "#,##0" }} />
        <ExportColumn field="GoalWeeklyDispatchedCount" title="Weekly Dispatched Goal" cellOptions={{ format: "#,##0" }} />
        <ExportColumn field="GoalWeeklyTotalCharges" title="Weekly Total Charges Goal" cellOptions={{ format: "$#,##0.00" }} />
      </ExcelExport>
      <Grid
        sortable
        sort={sort}
        data={orderBy(filterBy(users, usersFilter), sort)}
        onSortChange={onGridSortChange}
        className="mt-2"
        style={{ minWidth: 1140 }}
        selectedField="Me"
      >
        <GridToolbar>
          <Button
            togglable
            selected={allUserFilter}
            onClick={() => setAllUserFilter(prev => !prev)}
          >
            All Users
          </Button>
          <ButtonGroup>
            {locations.map((location) => <Button
              key={location.ID}
              togglable
              selected={locationFilter === location.ID}
              onClick={() => setLocationFilter(prev => prev === location.ID ? null : location.ID)}
            >
              {location.Name}
            </Button>)}
          </ButtonGroup>
          <ButtonGroup>
            {shiftCategories.map((shiftCategory) => <Button
              key={shiftCategory.ID}
              togglable
              selected={shiftCategoryFilter === shiftCategory.ID}
              onClick={() => setShiftCategoryFilter(prev => prev === shiftCategory.ID ? null : shiftCategory.ID)}
            >
              {shiftCategory.Name}
            </Button>)}
          </ButtonGroup>
          <Button
            icon="download"
            svgIcon={downloadIcon}
            onClick={() => _export.current.save(orderBy(users, sort))}
          >
            Excel
          </Button>
        </GridToolbar>
        <Column field="UserName" title="User" cell={(props) => {
          const data = props.dataItem as UserPerformance;
          const link = data.Links.find((x) => x.Name === 'UpdateGoal');
          return <td>
            {link ? <a href="#" style={{ color: "#007bff" }} onClick={(e) => { e.preventDefault(); setEditUserId(data.UserID); }}>{data.UserName}</a> : data.UserName}
          </td>}} />
        <Column field="QuoteCount" title="Quoted" format="{0:n0}" />
        <Column field="OrderCount" title="Booked" format="{0:n0}" />
        <Column field="DispatchCount" title="Dispatched" format="{0:n0}" />
        <Column field="CustomerCount" title="Customers" format="{0:n0}" />
        <Column field="AverageOrderRPM" title="Order RPM" format="{0:c2}" />
        <Column field="AverageQuoteRPM" title="Quote RPM" format="{0:c2}" />
        <Column field="TotalOrderCharges" title="Order Charges" format="{0:c2}" />
        <Column field="TotalPay" title="Trip Pay" format="{0:c2}" />
        <Column field="TotalQuoteCharges" title="Quote Charges" format="{0:c2}" />
        <Column field="BrokerPercentage" title="Broker %" format="{0:p2}" />
        <Column field="BlindBidPercentage" title="Blind Bid %" format="{0:p2}" />
        <Column field="MarginPercentage" title="Avg. Margin" format="{0:p2}" />
        <Column field="ConversionPercentage" title="Conversion %" format="{0:p2}" />
        <Column field="GoalPercentage" title="Week Goal" cell={(props) => <td>
          {props.dataItem.GoalPercentage !== null ? formatNumber(props.dataItem.GoalPercentage, 'p') : <span className="text-danger">No Goal</span>}
        </td>} />
      </Grid>
      <br />
    </div>
  </React.Fragment>;
}

export default UserReports;

