import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import { ViewModel } from 'TypeGen/Order/ChargeTypes/view-model';

const ChargeType = (props: GridCellProps, chargeTypes: ViewModel[]) => {
  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value.RateDescriptionID
      });
    }
  }

  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? <DropDownList
        data={chargeTypes}
        textField="Description"
        dataItemKey="RateDescriptionID"
        value={chargeTypes.find(x => x.RateDescriptionID === dataValue)}
        onChange={handleChange}
      /> : chargeTypes.find(x => x.RateDescriptionID === dataValue)?.Description}
    </td>
  );
}

export default ChargeType;
