import { useEffect, useRef, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { ISingleSearchLocation, singleSearch } from "services/pcmiler";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { fetchApi } from "services/api";
import Moment from 'moment-timezone';

export type NewPositionType = {
  DateTime: Date;
  City: string;
  State: string;
  ZipCode: string;
}

type Props = {
  close: () => void;
  save: (data: NewPositionType) => Promise<void>;
}

const ManualPositionPopup = ({ close, save }: Props) => {

  const [saving, setSaving] = useState(false);
  const [newPosition, setNewPosition] = useState<NewPositionType>({ DateTime: Moment().toDate(), City: '', State: '', ZipCode: '' });

  // Location AutoComplete
  const locationInputRef = useRef<AutoComplete>(null);
  const [locationOptions, setLocationOptions] = useState<ISingleSearchLocation[]>([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationValue, setLocationValue] = useState('');

  const savePosition = (data: NewPositionType) => {
    setSaving(true);
    save(data)
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    locationInputRef.current?.focus();

    // Get Current Server Time
    fetchApi('api/User/DateTimeUtcNow')
      .then((datetime: string) => {
        setNewPosition(prev => ({
          ...prev,
          DateTime: Moment.utc(datetime).toDate()
        }));
      });
  }, []);

  const renderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const updateLocationsDebounced =  useDebouncedCallback((searchTerm: string) => {
    setLocationLoading(true);
    singleSearch(searchTerm)
      .then((response) => {
        setLocationLoading(false);
        setLocationOptions(response.Locations);
      })
      .catch(() => {
        setLocationLoading(false);
        setLocationOptions([]);
      });
} , 350);

  return <Dialog
    title="Manual Position Update"
    className="dialog-w800"
    onClose={close}
  >
    <div className="k-form k-form-md">
      <DateTimePicker
        required
        format="MM/dd/yyyy HH:mm"
        formatPlaceholder="formatPattern"
        value={newPosition.DateTime}
        onChange={(e) => setNewPosition({ ...newPosition, DateTime: e.value })}
      />
      <br /><br />
      <AutoComplete
        ref={locationInputRef}
        data={locationOptions}
        value={locationValue}
        loading={locationLoading}
        placeholder={`Enter New Position`}
        itemRender={renderLocation}
        onChange={(event: AutoCompleteChangeEvent) => {
          // Update Location
          let location = event.target.value;
          if (typeof event.target.value === 'object') {
            const place = event.target.value as ISingleSearchLocation;
            location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
            setNewPosition({ ...newPosition, ZipCode: place.Address.Zip, City: place.Address.City, State: place.Address.State });
          }

          setLocationValue(location);

          // Update Autocomplete Suggestions
          if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
            updateLocationsDebounced(event.target.value);
          }
        }}
      />
    </div>
    <DialogActionsBar>
      <Button onClick={close}>Close</Button>
      <Button themeColor="primary" disabled={saving} onClick={() =>savePosition(newPosition)}>Save Position</Button>
    </DialogActionsBar>
  </Dialog>
}

export default ManualPositionPopup;