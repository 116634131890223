import { useEffect, useState } from 'react';
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnProps, GridDataStateChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { ViewModel } from 'TypeGen/Quote/LoadBoard/view-model';
import { process, CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor } from '@progress/kendo-data-query';
import CountdownCell from '../LoadBoard/Countdown';
import ColumnMenu from 'views/LoadBoard/BidHistory/ColumnMenu';
import { fetchApi } from 'services/api';
import LocalDateCell from 'components/cells/LocalDateCell';
import { quoteIDCell } from 'views/Quote/Recent';
import { UpdateQuoteAssigned, UpdateQuoteOfferCount } from 'views/Quote';
import { Title } from '../../utils/title';
import _ from 'lodash';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { LoadOneReconnectPolicy } from 'services/signalr/retryPolicy';
import { useDebouncedCallback } from 'use-debounce';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';

const CarrierLoadBoard = () => {
  const [data, setData] = useState<ViewModel[]>([]);
  const [connection, setConnection] = useState<HubConnection>(null);

  const processFilters = (filters: Array<FilterDescriptor>): CompositeFilterDescriptor => {
    // Group filters by field
    const groupedFilters = _.groupBy(filters, filter => filter.field);
    // Create a filter for each group
    const compositeFilters = Object.keys(groupedFilters).map(key => {
      const filters = groupedFilters[key];
      // If there is only one filter for the field, return it
      if (filters.length === 1) {
        return filters[0];
      }
      // Otherwise, return a composite filter
      return {
        logic: 'or',
        filters
      } as CompositeFilterDescriptor;
    }).filter(x => x !== undefined);

    return {
      logic: 'and',
      filters: compositeFilters
    } as CompositeFilterDescriptor;
  }

  let andFilter = [];
  const sessionStorageAndFilter = sessionStorage.getItem("CarrierLoadBoard-andFilter");
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  }
  const [andFilters, setAndFilters] = useState<FilterDescriptor[]>(andFilter);
  const [dataState, setDataState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 20,
    sort: [{ field: "ExpiryDateTime", dir: "asc" }],
    filter: processFilters(andFilter)
  });

  const refresh = useDebouncedCallback(() => {
    fetchApi("api/Quote/CarrierLoadBoard")
      .then((response: ViewModel[]) => {
        setData(response);
      });
  }, 250);

  useEffect(() => {
    async function startConnection() {
      try {
        connection.onreconnected(() => {
          refresh();
        })
        connection.on('refreshCarrierQuoteOffers', (update: UpdateQuoteOfferCount) => {
          setData((prevData) => {
            const quote = prevData.find(x => x.QuoteID == update.QuoteID);
            if (quote) {
              // Expired
              if (update.ExpiredOfferCount > quote.ExpiredOfferCount) {
                refresh();
              }
              quote.ExpiredOfferCount = update.ExpiredOfferCount;
              quote.DeclinedOfferCount = update.DeclinedOfferCount;
              quote.AcceptedOfferCount = update.AcceptedOfferCount;
            } else {
              // New posting
              refresh();
            }
            return [...prevData];
          });
        });
        connection.on('quoteAssigned', (response: UpdateQuoteAssigned) => {
          setData((prevData) => {
            const quote = prevData.find(x => x.QuoteID == response.QuoteID);
            if (quote) {
              quote.Unassigned = false;
            }
            return [...prevData];
          });
        });

        await connection?.start();

      } catch (error) {
        console.error(error)
      }
    }

    if (connection) {
      startConnection()
    }

    return () => {
      connection?.stop()
    }
  }, [connection, refresh]);

  useEffect(() => {
    refresh();

    const builder = new HubConnectionBuilder()
      .withAutomaticReconnect(new LoadOneReconnectPolicy())
      .withUrl("/quoteOffers")
      .configureLogging(LogLevel.Warning)
      .build();

    setConnection(builder);
  }, [refresh]);

  const columnProps = (field: string): Partial<GridColumnProps> =>{
    return {
        field: field,
        columnMenu: ColumnMenu,
        headerClassName: isColumnActive(field, dataState) ? 'active' : ''
    };
  }

  const isColumnActive = (field: string, dataState: any) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  const hasFilterArray = (filter: FilterDescriptor): boolean => {
    return andFilters
      .filter(x => JSON.stringify(x) === JSON.stringify(filter))
      .length > 0;

  }

  const filterArray = (newFilter: FilterDescriptor) => {
    let newFilters: FilterDescriptor[] = [];
    if (hasFilterArray(newFilter)) {
      // Remove existing
      newFilters = andFilters.filter(x => JSON.stringify(x) !== JSON.stringify(newFilter));
    } else {
      // Add new
      newFilters = [...andFilters, newFilter];
    }
    dataStateChange({ dataState: {
      skip: 0,
      take: dataState.take,
      sort: dataState.sort,
      filter: processFilters(newFilters)
    } } as GridDataStateChangeEvent);
    setAndFilters(newFilters);
    sessionStorage.setItem("CarrierLoadBoard-andFilter", JSON.stringify(newFilters));
  }

  const reset = () => {
    setAndFilters([]);
    dataStateChange({ dataState: {
      skip: 0,
      take: 20,
      sort: [{field: "ExpiryDateTime", dir: "asc"}],
      filter: { logic: 'and', filters: [] } as CompositeFilterDescriptor
    } } as GridDataStateChangeEvent);
    sessionStorage.removeItem("CarrierLoadBoard-andFilter");
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const gridData = process(data, dataState);

  return <>
    <Title string="Carrier Load Board" />
    <br />
    <Grid
      reorderable
      //onColumnReorder={saveColumnOrder}
      dataItemKey="QuoteOfferID"
      sortable={{ allowUnsort: true }}
      pageable={{ pageSizes: [20, 50, 100, 500] }}
      scrollable="none"
      total={gridData.total}
      data={gridData.data}
      onDataStateChange={dataStateChange}
      {...dataState}
    >
      <GridNoRecords>
        No active carrier offers.
      </GridNoRecords>
      <GridToolbar>
        <Button
          title="Clear Filters and Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={reset}
        />
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refresh}
        />
        <Button
          togglable
          selected={hasFilterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'CARGO VAN'})}
          onClick={() => filterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'CARGO VAN'})}
        >CARGO VAN
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'SPRINTER'})}
          onClick={() => filterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'SPRINTER'})}
        >SPRINTER
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'STRAIGHT'})}
          onClick={() => filterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'STRAIGHT'})}
        >STRAIGHT
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'TRACTOR'})}
          onClick={() => filterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'TRACTOR'})}
        >TRACTOR
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'FLATBED'})}
          onClick={() => filterArray({ field: 'VehicleTypeFilter', operator: 'eq', value: 'FLATBED'})}
        >FLATBED
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'LocationType', operator: 'eq', value: 1})}
          onClick={() => filterArray({ field: 'LocationType', operator: 'eq', value: 1})}
        >US Domestic
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'LocationType', operator: 'eq', value: 3})}
          onClick={() => filterArray({ field: 'LocationType', operator: 'eq', value: 3})}
        >Mexico
        </Button>
        <Button
          togglable
          selected={hasFilterArray({ field: 'LocationType', operator: 'eq', value: 2})}
          onClick={() => filterArray({ field: 'LocationType', operator: 'eq', value: 2})}
        >Canada
        </Button>
      </GridToolbar>
      <Column {...columnProps("QuoteID")} title="Quote" cell={quoteIDCell()} />
      <Column {...columnProps("PickupLocation")} title="Pickup" />
      <Column {...columnProps("PickupDateTime")} title="Pickup Date" filter="date" cell={LocalDateCell} />
      <Column {...columnProps("DeliveryLocation")} title="Delivery" />
      <Column {...columnProps("DeliveryDateTime")} title="Delivery Date" filter="date" cell={LocalDateCell} />
      <Column {...columnProps("TotalPieces")} title="Pieces" filter="numeric" format="{0:n0}" />
      <Column {...columnProps("TotalWeight")} title="Weight" filter="numeric" format="{0:n0} lbs" />
      <Column {...columnProps("Distance")} title="Miles" filter="numeric" format="{0:n0}" />
      <Column {...columnProps("VehicleType")} title="Equipment" />
      <Column {...columnProps("CarrierID")} filterable={false} title="Offered" cell={(e) => <td>{e.dataItem.CarrierID === 0 ? "Load Board" : "Direct"}</td>} />
      <Column {...columnProps("ExpiryDateTime")} title="Expiry" filterable={false} filter="date" cell={CountdownCell} />
    </Grid>
  </>
}

export default CarrierLoadBoard;