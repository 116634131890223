import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { JsonResponse } from 'TypeGen/json-response';
import { IDName } from 'types/idname';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';

type Props = {
    VehicleID: string | number;
    CloseDialog: () => void;
    Refresh: () => void;
}

const RetirePopup = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reasons, setReasons] = useState<IDName[]>([]);
    const [reason, setReason] = useState({ value: 0, error: '' });
    const [date, setDate] = useState<Date | null>(new Date());
    const [comment, setComment] = useState('');

    useEffect(() => {
        fetchApi(`/api/Asset/RetireVehicle/${props.VehicleID}`)
            .then((response: { Reasons: IDName[] }) => {
                setReasons(response.Reasons);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [props.VehicleID]);

    const _save = () => {
        const reasonError = reason.value === 0 ? 'Please select a reason.' : '';

        if (reasonError) {
            setReason({ ...reason, error: reasonError });
            return;
        }
        setLoading(true);
        const data = {
            RetireReasonID: reason.value,
            Comment: comment,
            RetiredDate: date
        }
        fetchApi(`/api/Asset/RetireVehicle/${props.VehicleID}`, data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                  props.CloseDialog();
                  props.Refresh();
                } else {
                  alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                setLoading(false);
                alert("Unable to retire");
            });
    }

    return <div className="container-fluid">
        <Dialog title="Retire Vehicle" onClose={props.CloseDialog} className="dialog-w525" height={460}>
            {loading && <div className="k-pos-absolute k-top-center mt-5">
                <Loader type="converging-spinner" />
            </div>}
            <div className="row">
                <div className="col">
                    <form className="k-form k-form-md p-0">
                        <FieldWrapper>
                            <Label>Reason</Label>
                            <DropDownList
                                required
                                data={reasons}
                                textField="Name"
                                dataItemKey="ID"
                                value={reasons.find(x => x.ID === reason.value)}
                                onChange={(e) => setReason({ value: e.target.value.ID, error: '' })}
                            />
                            <Error>{reason.error}</Error>
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Comment</Label>
                            <Input
                                maxLength={60}
                                value={comment}
                                onChange={(e) => setComment(e.value || '')}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Retired Date</Label>
                            <DatePicker
                                format="MM/dd/yyyy"
                                min={new Date(1753, 1, 1)}
                                max={new Date()}
                                formatPlaceholder="formatPattern"
                                value={date}
                                onChange={(e) => {
                                    setDate(e.value);
                                }}
                            />
                        </FieldWrapper>
                    </form>
                </div>
            </div>
            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}>Retire Vehicle</Button>
            </DialogActionsBar>
        </Dialog>
    </div>
}

export default RetirePopup;
