import Moment from 'moment';
import * as React from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import { fetchApi } from '../../services/api';

export interface LoadPosting {
  CarrierID: number;
  QuotePostingID: number;
  AllInRate: number;
  CarrierName: string;
  AcceptedDateTime: Date | null;
  LastModifiedDateTime: Date;
  LastModifiedUserID: string;
  LoadBoard: LoadBoard;
}

export enum LoadBoard {
  //Newtrul = 0,
  FullCircle = 1,
  TruckerPath = 2,
  Parade = 3,
  Sylectus = 4,
  LoadOne = 5,
  All = 50
}

type State = {
  isLoading: boolean;
  loadPostings: LoadPosting[];
}

type Props = {
  quoteId: number;
  onPostRequest: (deletePosting: boolean, loadPosting: LoadPosting) => void;
  onClose: () => void;
};

export default class PostLoadOption extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      loadPostings: []
    }

    this.postquote = this.postquote.bind(this);
  }

  FetchFullCircle(obj: LoadPosting) {
    if (obj) {
      let title = `All In Rate: $${String(obj.AllInRate)}` +
        `\nLast Updated On: ${Moment.utc(obj.LastModifiedDateTime).local().format("MM/DD/YYYY HH:mm")}` +
        `\nLast Updated By: ${obj.LastModifiedUserID}`;

      return (<>
        <h6 className="dropdown-header">Full Circle</h6>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { this.props.onPostRequest(false, obj) }}
        >
          Update Full Circle w/Latest Changes
        </button>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { if (window.confirm("Remove posting from Full Circle?")) { this.props.onPostRequest(true, obj) } }}>
          Remove Full Circle Posting
        </button>
      </>);
    }
    return (<>
      <h6 className="dropdown-header">Full Circle</h6>
      <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(false, { LoadBoard: LoadBoard.FullCircle } as LoadPosting) }}>Post to Full Circle</button>
    </>);
  }

  FetchTruckerPath(obj: LoadPosting) {
    if (obj) {
      let title = `All In Rate: $${String(obj.AllInRate)}` +
        `\nLast Updated On: ${Moment.utc(obj.LastModifiedDateTime).local().format("MM/DD/YYYY HH:mm")}` +
        `\nLast Updated By: ${obj.LastModifiedUserID}`;

      return (<>
        <h6 className="dropdown-header">Trucker Path</h6>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { this.props.onPostRequest(false, obj) }}
        >
          Update Trucker Path w/Latest Changes
        </button>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { if (window.confirm("Remove posting from Trucker Path?")) { this.props.onPostRequest(true, obj) } }}>
          Remove Trucker Path Posting
        </button>
      </>);
    }
    return (<>
      <h6 className="dropdown-header">Trucker Path</h6>
      <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(false, { LoadBoard: LoadBoard.TruckerPath } as LoadPosting) }}>Post to Trucker Path</button>
    </>);
  }

  FetchParade(obj: LoadPosting) {
    if (obj) {
      let title = `All In Rate: $${String(obj.AllInRate)}` +
        `\nLast Updated On: ${Moment.utc(obj.LastModifiedDateTime).local().format("MM/DD/YYYY HH:mm")}` +
        `\nLast Updated By: ${obj.LastModifiedUserID}`;

      return (<>
        <h6 className="dropdown-header">Parade</h6>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { this.props.onPostRequest(false, obj) }}
        >
          Update Parade w/Latest Changes
        </button>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { if (window.confirm("Remove posting from Parade?")) { this.props.onPostRequest(true, obj) } }}>
          Remove Parade Posting
        </button>
      </>);
    }
    return (<>
      <h6 className="dropdown-header">Parade</h6>
      <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(false, { LoadBoard: LoadBoard.Parade } as LoadPosting) }}>Post to Parade</button>
    </>);
  }

  FetchSylectus(obj: LoadPosting) {
    if (obj) {
      let title = `All In Rate: $${String(obj.AllInRate)}` +
        `\nLast Updated On: ${Moment.utc(obj.LastModifiedDateTime).local().format("MM/DD/YYYY HH:mm")}` +
        `\nLast Updated By: ${obj.LastModifiedUserID}`;

      return (<>
        <h6 className="dropdown-header">Sylectus</h6>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { this.props.onPostRequest(false, obj) }}
        >
          Update Sylectus w/Latest Changes
        </button>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { if (window.confirm("Remove posting from Sylectus?")) { this.props.onPostRequest(true, obj) } }}>
          Remove Sylectus Posting
        </button>
      </>);
    }
    return (<>
      <h6 className="dropdown-header">Sylectus</h6>
      <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(false, { LoadBoard: LoadBoard.Sylectus } as LoadPosting) }}>Post to Sylectus</button>
    </>);
  }

  FetchLoadOne(obj: LoadPosting) {
    if (obj) {
      let title = `All In Rate: $${String(obj.AllInRate)}` +
        `\nLast Updated On: ${Moment.utc(obj.LastModifiedDateTime).local().format("MM/DD/YYYY HH:mm")}` +
        `\nLast Updated By: ${obj.LastModifiedUserID}`;

      return (<>
      <div className="dropdown-divider"></div>
        <h6 className="dropdown-header">Load One</h6>
        <button
          className="dropdown-item"
          title={title}
          onClick={() => { if (window.confirm("Remove posting from Load One?")) { this.props.onPostRequest(true, obj) } }}>
          Remove Load One Posting
        </button>
      </>);
    }
    return null;
  }

  public render() {
    if (this.state.isLoading)
      return null;

    if (!this.state.loadPostings.length) {
      return <>
        {this.FetchFullCircle(null)}
        <div className="dropdown-divider"></div>
        {this.FetchTruckerPath(null)}
        <div className="dropdown-divider"></div>
        {this.FetchParade(null)}
        <div className="dropdown-divider"></div>
        {this.FetchSylectus(null)}
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(false, { LoadBoard: LoadBoard.All } as LoadPosting) }}>Post to All</button>
      </>;
    } else {
      const fullCircle = this.state.loadPostings.find(x => x.LoadBoard === LoadBoard.FullCircle);
      const truckerPath = this.state.loadPostings.find(x => x.LoadBoard === LoadBoard.TruckerPath);
      const parade = this.state.loadPostings.find(x => x.LoadBoard === LoadBoard.Parade);
      const sylectus = this.state.loadPostings.find(x => x.LoadBoard === LoadBoard.Sylectus);
      const loadOne = this.state.loadPostings.find(x => x.LoadBoard === LoadBoard.LoadOne);
      return <>
        {this.FetchFullCircle(fullCircle)}
        <div className="dropdown-divider"></div>
        {this.FetchTruckerPath(truckerPath)}
        <div className="dropdown-divider"></div>
        {this.FetchParade(parade)}
        <div className="dropdown-divider"></div>
        {this.FetchSylectus(sylectus)}
        {this.FetchLoadOne(loadOne)}
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" disabled={false} onClick={() => { this.props.onPostRequest(true, { LoadBoard: LoadBoard.All } as LoadPosting) }}>Remove All Postings</button>
      </>
    }
  }

  public postquote(loadPosting: LoadPosting, deletePosting: boolean = false) {
    this.setState({ isLoading: true });

    const data = {
      QuoteID: this.props.quoteId,
      DeletePostOrder: deletePosting,
      AllInRate: loadPosting.AllInRate
    };

    fetchApi(`/api/PostLoad/${LoadBoard[loadPosting.LoadBoard]}/PostQuote`, data, "POST")
      .then((response: JsonResponse) => {
        if (!response.Success) {
          alert(response.ErrorMessage);
        }
      }).catch(() => {
        this.setState({
          isLoading: false
        }, () => { alert('Load posting failed.  Please contact your administrator.'); });
      });
  }

  public getPostedLoadInfo() {
    this.setState({
      isLoading: true,
    });

    fetchApi(`/api/PostLoad/Get/${this.props.quoteId}`)
      .then((response: LoadPosting[]) => {
        this.setState({ isLoading: false, loadPostings: response });
      });
  }
}