import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';

const QuantityUoMCell = (props: GridCellProps) => {
  const localizedData = [
    { text: 'Flat', value: 1 },
    { text: 'Miles', value: 2 },
    { text: 'Dollars', value: 3 },
    { text: 'Units', value: 4 },
    { text: 'Minutes', value: 6 },
    { text: 'Pounds', value: 9 },
    { text: 'Tons', value: 10 },
    { text: 'Hours', value: 15 },
    { text: 'Stops', value: 16 },
  ];

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value
      });
    }
  }

  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? <DropDownList
        onChange={handleChange}
        value={localizedData.find(c => c.value === dataValue)}
        data={localizedData}
        textField="text"
      /> : localizedData.find(c => c.value === dataValue)?.text}
    </td>
  );
}

export default QuantityUoMCell;
