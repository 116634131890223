import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from '@progress/kendo-react-labels';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { checkIcon, thumbDownIcon, thumbUpIcon, xIcon } from "@progress/kendo-svg-icons";
import { set } from "lodash";
import { useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { isNullOrWhiteSpaceValidator } from "utils/utils";

type Props = {
  tin: null | number;
  name: string;
  CloseDialog: () => void;
}

type TINCheckStatusResponse = {
  Status: number;
  CallsRemaining: string;
}

type TINCheckReportResponse = {
  TINNAME_CODE: number;
  TINNAME_DETAILS: string;
  DMF_CODE: number;
  DMF_DETAILS: string;
  DMF_DATA: string;
  EIN_CODE: number;
  EIN_DETAILS: string;
  EIN_DATA: string;
  GIIN_CODE: number;
  GIIN_DETAILS: string;
  GIIN_DATA: string;
  STATUS: TINCheckStatusResponse;
}

const TINCheckDialog = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [tin, setTIN] = useState<{ value: null | number, error: string }>({ value: props.tin, error: '' });
  const [name, setName] = useState({ value: props.name, error: '' });
  const [status, setStatus] = useState<TINCheckStatusResponse | null>(null);
  const [report, setReport] = useState<TINCheckReportResponse | null>(null);

  useEffect(() => {
    fetchApi("/api/Asset/TINCheckStatus")
      .then((data: TINCheckStatusResponse) => {
        setStatus(data);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to connect to TINCheck service.');
      });
  }, []);

  const _search = () => {

    const tinError = tin.value ? '' : 'Value cannot be empty.';
    const nameError = isNullOrWhiteSpaceValidator(name.value, 'Value cannot be empty.');

    if (tinError || nameError) {
      setTIN({ ...tin, error: tinError });
      setName({ ...name, error: nameError });
      return;
    }

    const payload = {
      TIN: tin.value,
      Name: name.value
    }
    setLoading(true);
    fetchApi("/api/Asset/TINCheck", payload, 'POST')
      .then((data: TINCheckReportResponse) => {
        // setStatus(data.STATUS); // Not accurate if cached
        setReport(data);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to connect to TINCheck service.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return <Dialog title="TINCheck - Real-time check" onClose={props.CloseDialog} className='dialog-w800'>
    {status && <div className="alert alert-info">TINCheck: {status.CallsRemaining} calls remaining.</div>}
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <Label>TIN (SSN/EIN):</Label>
        <NumericTextBox
          min={0}
          max={999999999}
          step={0}
          spinners={false}
          format="0"
          disabled={loading}
          value={tin.value}
          onChange={(e) => setTIN({ value: e.value, error: '' })}
        />
        <Error>{tin.error}</Error>
      </FieldWrapper>
      <FieldWrapper>
        <Label>Full name / Company name</Label>
        <Input
          disabled={loading}
          value={name.value}
          onChange={(e) => setName({ value: e.value || '', error: '' })}
        />
        <Error>{name.error}</Error>
      </FieldWrapper>
    </form>
    {report && <PanelBar className="mt-2">
        <PanelBarItem
          selected={false}
          disabled={report.TINNAME_CODE === -1}
          svgIcon={report.TINNAME_CODE === 1 || report.TINNAME_CODE === 6 || report.TINNAME_CODE === 7 || report.TINNAME_CODE === 8 ? checkIcon
            : report.TINNAME_CODE === 0 || report.TINNAME_CODE === 5 || report.TINNAME_CODE === 13 ? xIcon
            : undefined}
          headerClassName={report.TINNAME_CODE === 0 || report.TINNAME_CODE === 5 || report.TINNAME_CODE === 13 ? 'bg-danger text-white' : ''}
          title="IRS TIN and name check"
        >
          <div className="p-3">{report.TINNAME_DETAILS}</div>
        </PanelBarItem>
        <PanelBarItem
          disabled={report.EIN_CODE === -1}
          svgIcon={report.EIN_CODE === 1 ? checkIcon : undefined}
          title="EIN name check"
        >
          <div className="p-3">
            {report.EIN_DETAILS}
            {report.EIN_DATA && <pre className="mb-0">{report.EIN_DATA}</pre>}
          </div>
        </PanelBarItem>
        <PanelBarItem
          disabled={report.DMF_CODE === -1}
          svgIcon={report.DMF_CODE === 0 ? checkIcon : report.DMF_CODE === 1 ? xIcon : undefined}
          headerClassName={report.DMF_CODE === 1 ? 'bg-danger text-white' : ''}
          title="Death Master File check"
        >
          <div className="p-3">
            {report.DMF_DETAILS}
            {report.DMF_DATA && <pre className="mb-0">{report.DMF_DATA}</pre>}
          </div>
        </PanelBarItem>
        <PanelBarItem
          disabled={report.GIIN_CODE === -1}
          title="GIIN check"
        >
          <div className="p-3">
            {report.GIIN_DETAILS}
            {report.GIIN_DATA && <pre className="mb-0">{report.GIIN_DATA}</pre>}
          </div>
        </PanelBarItem>
    </PanelBar>}
    <DialogActionsBar>
      <Button onClick={props.CloseDialog}>Cancel</Button>
      <Button
        themeColor="primary"
        disabled={loading}
        onClick={_search}
      >
        {loading ? 'Searching...' : 'Run Check'}
      </Button>
    </DialogActionsBar>
  </Dialog >
}

export default TINCheckDialog;
