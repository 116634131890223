import * as React from 'react';
import { formatNumber } from '@progress/kendo-intl';
import LoadingPanel from '../../components/LoadingPanel';

export type ChargeType = {
  RateDescriptionID: number;
  RateType: number;
  Description: string;
}

type Props = {
  data: QuoteValue | null;
  viewFit: () => void;
  showRate: boolean;
  loading: boolean;
};

export type QuoteValue = {
  Amount: number;
  LineHaulAmount: number;
  FuelAmount: number;
  AccessorialAmount: number;
  BonusAmount: number;
  RateComment: string;
  LineItems: QuoteLineItemValue[];
  ShowRate?: boolean;
}

export type QuoteLineItemValue = {
  RateDescriptionID: number;
  RateType: number;
  Name: string;
  Quantity: number;
  QuantityUoM: number;
  Rate: number;
  RateUoM: number;
  Amount: number;
  IsAutoRated: boolean;
}

export default class QuotePanel extends React.Component<Props, {}> {

  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <div className="card overflow-hidden h-100">
        <div className="card-body text-center">
          {this.props.loading && <LoadingPanel />}
          {this.props.showRate && <>
            <h5 className="card-title display-4 text-success font-weight-normal">
              {formatNumber(this.props.data.Amount, "c")}
            </h5>
            <h6 className="card-subtitle mb-2 text-muted">
              Rate based on availability and subject to change.
            </h6>
            <h6 className="card-subtitle mb-2 text-muted">
              Quotes are good for 20 minutes, please confirm coverage after that time.
            </h6>

            <br />
          </>}

          <button type="button" className="btn btn-primary" onClick={this.props.viewFit}>Preview Loaded Freight</button>
        </div>
      </div>
    )
  }
}
