import { useState } from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import CarrierAutoComplete from "views/AssetCarriers/CarrierAutoComplete";

export type CarrierExclusionViewModel = {
  CarrierID: number;
  ReasonForExclusion: string;
}

type Props = {
  customerId: number;
  close: () => void;
  save: (newData: CarrierExclusionViewModel) => void;
}

const CarrierExclusionPopup = (props: Props) => {
  const [carrierId, setCarrierId] = useState(0);
  const [reasonForExclusion, setReasonForExclusion] = useState('');

  return (
    <Window
      title="Add Carrier Exclusion"
      style={{ position: 'fixed' }}
      initialWidth={Math.min(850, window.innerWidth)}
      initialHeight={Math.min(350, window.innerHeight)}
      onClose={props.close}
    >
      <p>
        Excluding a carrier means that any quotes posted using this customer as the bill-to will not be visible to the carrier, and you will not be able to dispatch loads to this carrier.
      </p>
      <form className="k-form k-form-md">
        <div className="k-form-field">
          <label htmlFor="CarrierID">Exclude Carrier</label>
          <CarrierAutoComplete
            selectedCarrierID={carrierId}
            onSelectedCarrier={(e) => setCarrierId(e?.CarrierID || 0)}
          />
        </div>
        <div className="k-form-field">
          <label htmlFor="ReasonForExclusion">Reason For Exclusion</label>
          <Input
            maxLength={255}
            value={reasonForExclusion}
            onChange={(e) => setReasonForExclusion(e.value)}
          />
        </div>
      </form>
      <br />
      <WindowActionsBar>
        <Button type="button" className="ml-auto" onClick={props.close}>
          Cancel
        </Button>
        <Button
          type="button"
          themeColor="primary"
          onClick={() => props.save({ CarrierID: carrierId, ReasonForExclusion: reasonForExclusion })}
        >
          Add
        </Button>
      </WindowActionsBar>
    </Window>
  );
}

export default CarrierExclusionPopup;