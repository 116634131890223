import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import Moment from 'moment-timezone';
import { useState } from "react";
import { fetchApi } from "services/api";
import { JsonResponse } from "TypeGen/json-response";
import SimpleDatePicker from "../../../components/SimpleDatePicker";

type Props = {
  PurchaseOrderID: number;
  VendorPaymentTerms: number;
  CloseDialog: (refresh: boolean) => void;
};

const SetAsReadyToPost = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [billDate, setBillDate] = useState<Date | null>(Moment.utc(new Date()).tz("America/New_York").startOf('day').toDate());
  const [dueDate, setDueDate] = useState<Date | null>(Moment.utc(new Date()).tz("America/New_York").startOf('day').add(props.VendorPaymentTerms, 'day').toDate());

  const setReadyToPost = () => {
    setLoading(true);
    fetchApi(`/api/PurchaseOrder/ReadyToPost/${props.PurchaseOrderID}`, { billDate: Moment.utc(billDate).toDate(), dueDate: Moment.utc(dueDate).toDate() }, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          props.CloseDialog(true);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to set ready to post');
      });
  };

  return <Dialog title={`Set Ready To Post`} autoFocus={true} onClose={() => props.CloseDialog(false)} className="dialog-w525">
    <p><i>This step preps the purchase order for GP import.</i></p>
    <div>
      <label>GP Purchase Order/Bill Date:</label>
      <SimpleDatePicker
        required
        value={billDate}
        onChange={(e) => {
          setBillDate(e);
          setDueDate(Moment(e).add(props.VendorPaymentTerms, 'days').toDate());
        }}
      />
    </div>
    <div className="mt-4">
      <label>GP Purchase Order Due Date:</label>
      <SimpleDatePicker
        required
        value={dueDate}
        onChange={(e) => setDueDate(e)}
      />
      <i>Vendor payment terms is NET {props.VendorPaymentTerms}.</i>
    </div>
    <DialogActionsBar layout="end">
      <Button themeColor="primary" disabled={loading || billDate === null} onClick={setReadyToPost}>Set Ready To Post</Button>
    </DialogActionsBar>
  </Dialog>
}

export default SetAsReadyToPost;