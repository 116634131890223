import { useEffect, useState } from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { fetchApi } from '../../services/api';
import { ILink } from "../../types/link";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import Moment from 'moment-timezone';
import { Button } from "@progress/kendo-react-buttons";
import { openWindow } from "services/openWindow";
import { JsonResponse } from "TypeGen/json-response";

type Props = {
  vehicleId: number;
  close: () => void;
  refresh: () => void;
}

type ApiResponse = {
  IsReserved: boolean;
  VehicleNumber: string;
  ReservedByUserNumber: string;
  ReservedComment: string;
  ReservedDateTime: Date | null;
  ReservedExpiryDateTime: Date | null;
  ReservedForCustomerNumber: string;
  QuoteID: number | null;
  Links: ILink[];
}

const ReservePopup = ({ vehicleId, close, refresh }: Props) => {

  const [saving, setSaving] = useState(false);
  const [data, setData] = useState<ApiResponse>(null);
  const [selectedUser, setSelectedUser] = useState(0);
  const [minutes, setMinutes] = useState(20);
  const [users, setUsers] = useState<Array<{ ID: number, Name: string }>>([]);

  // Update internal data
  useEffect(() => {
    fetchApi(`/api/Asset/VehicleReservation/${vehicleId}`)
      .then((data: ApiResponse) => {
        setData(data);
      });
  }, [vehicleId]);

  useEffect(() => {
    fetchApi('/api/User/SylectusUsersDropdown')
    .then((data: { Users: Array<{ ID: number, Name: string }> }) => {
      setUsers(data.Users);
    });
  }, [setUsers]);

  const reserve = () => {
    setSaving(true);
    const payload = {
      VehicleID: vehicleId,
      Minutes: minutes,
      Comment: data.ReservedComment,
    }

    fetchApi('/api/Quote/Reserve', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          refresh();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to reserve.');
      });
  }

  const release = () => {
    setSaving(true);
    const payload = {
      VehicleID: vehicleId,
    }

    fetchApi('/api/Quote/Release', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          refresh();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to release.');
      });
  }

  const update = () => {
    setSaving(true);
    const payload = {
      VehicleID: vehicleId,
      Minutes: minutes,
      Comment: data.ReservedComment,
    }
    fetchApi('/api/Quote/UpdateReservation', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          close();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to update.');
      });
}

  const transfer = () => {
    setSaving(true);
    const payload = {
      VehicleID: vehicleId,
      SylectusUserCode: selectedUser,
    }

    fetchApi('/api/Quote/Transfer', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          refresh();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to transfer.');
      });
  }

  const dataView = () => {
    return <form className="k-form k-form-md">
      {data.IsReserved && (<p>
        Reserved by <b>{data.ReservedByUserNumber}</b> <b>{Moment.utc(data.ReservedDateTime).fromNow()}</b> expires <b>{Moment.utc(data.ReservedExpiryDateTime).fromNow()}</b><br />
      </p>)}
      {data.Links.find(x => x.Name == "Update") && <><label className="d-flex">
        <NumericTextBox
          value={minutes}
          min={1}
          max={60}
          width={250}
          label="Keep Reservation For More Minutes"
          placeholder="Minutes"
          onChange={(e) => setMinutes(e.target.value)}
        />&nbsp;
        <Button type="button" disabled={saving} themeColor="primary" className="align-self-end" onClick={update}>
          Update
        </Button>
        </label>
      </>}
      {data.Links.find(x => x.Name == "Transfer") && <><label className="d-flex">
        <DropDownList
          style={{ width: 250 }}
          data={users}
          label="Transfer To"
          defaultItem={{ ID: 0, Name: "Select User" }}
          value={users.find(x => x.ID == selectedUser)}
          textField="Name"
          onChange={(e) => setSelectedUser(e.target.value.ID)}
        />&nbsp;
        <Button type="button" disabled={saving || selectedUser === 0} themeColor="primary" className="align-self-end" onClick={transfer}>
          Transfer
        </Button>
        </label>
      </>}
      {data.Links.find(x => x.Name == "Reserve") && <>
        <NumericTextBox
          value={minutes}
          min={1}
          max={60}
          label="Reservation For Minutes"
          placeholder="Minutes"
          onChange={(e) => setMinutes(e.target.value)}
        />
        <br />
      </>}
      <div className="input-group flex-nowrap">
        <Input
          className={data.QuoteID ? 'k-dropdown-wrap-append flex-fill' : 'flex-fill'}
          disabled={data.Links.find(x => x.Name == "Reserve") === undefined && data.Links.find(x => x.Name == "Update") === undefined}
          placeholder="Reserve Comment"
          value={data.ReservedComment}
          onChange={(e) => setData({ ...data, ReservedComment: e.value })}
          maxLength={40}
        />
        {data.QuoteID && <div className="input-group-append">
          <button
            className="btn btn-outline-primary"
            onClick={(e) => {
              e.preventDefault();
              openWindow(`/Quote/Index/${data.QuoteID}`, 1000);
            }}
          >Open</button>
        </div>}
      </div>
      <br />
      {data.ReservedForCustomerNumber && <p>
        Reserved for <b>{data.ReservedForCustomerNumber}</b>
      </p>}
    </form>
  }

  const loadingView = () => {
    return <div className="k-pos-absolute k-top-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <Window
      title={`Reserve ${data?.VehicleNumber}`}
      style={{ position: 'fixed' }}
      initialWidth={Math.min(850, window.innerWidth)}
      initialHeight={Math.min(450, window.innerHeight)}
      onClose={close}
    >
      {data === null ? loadingView() : dataView()}
      <WindowActionsBar>
        <Button disabled={saving} className="ml-auto" onClick={close}>
          Cancel
        </Button>
        {data?.Links.find(x => x.Name == "Release") && <Button themeColor="success" disabled={saving} onClick={release}>
          Release
        </Button>}
        {data?.Links.find(x => x.Name == "Reserve") && <Button themeColor="primary" disabled={saving} onClick={reserve}>
          Reserve
        </Button>}
      </WindowActionsBar>
    </Window>
}

export default ReservePopup;