import { useEffect, useCallback, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, FilterDescriptor, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Title } from '../../utils/title';
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from '@progress/kendo-react-buttons';
import { useParams } from 'react-router-dom';
import { debounce } from 'ts-debounce';
import PhoneCellAndExtension from '../../components/cells/PhoneCellAndExtension';
import EmailCell from '../../components/cells/EmailCell';
import { CarrierContactCell, CarrierContactViewModel } from './CarrierContactGrid';
import { CarrierNavBar } from './CarrierNavBar';
import AddCarrierContactDialog from './AddCarrierContactDialog';
import YesNoCell from 'components/cells/YesNoCell';
import { plusIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  carrierId: string;
};

type CarrierContactResult = {
  Data: CarrierContactViewModel[],
  Total: number,
}

const resetDataState = {
  skip: 0,
  take: 50,
  sort: [{
    field: "FullName",
    dir: "asc"
  }],
} as GridState;

const defaultAndFilter = {
  logic: 'and', filters: [
    { field: 'Active', operator: 'eq', value: true }
  ]
} as CompositeFilterDescriptor;

const CarrierContacts = () => {
  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState(true);
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: { ...defaultAndFilter } });

  const [carrierContacts, setCarrierContacts] = useState<CarrierContactResult>();
  const [showAddContactDialog, setShowAddContactDialog] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilter = useCallback(
    debounce((func: () => void) => {
      func();
    }, 750), []);

  const refresh = useCallback(() => {
    if (query) {
      setLoading(true);
      const data = {
        CarrierID: carrierIdParam
      };

      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchApi(`/api/Asset/CarrierContacts?${queryStr}`, data, 'POST')
        .then(data => {
          setLoading(false);
          setCarrierContacts(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
  }, [carrierIdParam, dataState, query]);

  useEffect(() => {
    refresh();
  }, [carrierIdParam, refresh]);

  return (
    <div className="container-fluid">
      <CarrierNavBar id={carrierIdParam} currentTab="Contacts" />
      <Title string="Contacts" />
      {showAddContactDialog && <AddCarrierContactDialog CarrierId={Number(carrierIdParam)}
        CloseDialog={() => setShowAddContactDialog(false)}
      />}
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Grid
        {...dataState}
        data={carrierContacts?.Data}
        total={carrierContacts?.Total}
        scrollable='none'
        sortable
        pageable
        filterable
        onDataStateChange={(e) => { setQuery(false); setDataState(e.dataState); debounceFilter(() => { setQuery(true) }); }}
      >
        <GridToolbar>
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => setShowAddContactDialog(true)}
          > Contact
          </Button>
          <Button
            className="ml-1"
            themeColor={((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active').value === true ? 'primary' : 'warning'}
            onClick={() => {
              let activeFilter = ((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active');
              activeFilter.value = !(activeFilter.value as boolean);
              setDataState({ ...dataState });
            }}
          >{((dataState.filter as CompositeFilterDescriptor).filters as FilterDescriptor[]).find(x => x.field === 'Active').value === true ? 'Active' : 'Retired'}</Button>
        </GridToolbar>
        <Column field="FullName" title="Name" cell={CarrierContactCell} />
        <Column field="EMailAddress" title="E-Mail" cell={EmailCell} />
        <Column field="PhoneNumber" title="Phone" cell={PhoneCellAndExtension} />
        <Column field="SendTruckAvailability" title="Receive Scorecard" cell={YesNoCell} />
      </Grid>
    </div>
  )
}

export default CarrierContacts;