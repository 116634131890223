export enum SourceType {
  None,
  ActiveAero,
  XPO,
  Sylectus,
  Expeditors,
  RyderLMS,
  Coyote,
  Caterpillar,
  ShawMA,
  Dakkota,
  Pepsi,
}
