import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectHandle } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';

type Props = {
  RateMasterID: number;
  disabled?: boolean;
  className?: string;
};

type TierSummary = {
  TierGroups: TierGroup[];
  SupportedStates: string[];
};

type TierGroup = {
  TierID: number;
  ModifiedByFullName: string;
  ModifiedDateTime: Date;

  From: string[];
  To: string[];

  Comments: string;

  inEdit?: boolean | string;
  isNew?: boolean;
  Delete?: boolean;
};

type TierGroupSelectedOption = {
  TierID: number;
  IsFrom: boolean;
  IndexInArray: number;
  // Value: string;
}

interface EditCommandCellProps extends GridCellProps {
  edit: (item: TierGroup) => void;
  remove: (item: TierGroup) => void;
  add: (item: TierGroup) => void;
  discard: () => void;
  update: (item: TierGroup) => void;
  cancel: (item: TierGroup) => void;
  editField: string;
}

const CommentCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  //let dataItem: TierGroup = props.dataItem;
  var value = props.dataItem[props.field] as string;

  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
      {props.dataItem.inEdit ? <TextBox
        value={value ?? ''}
        onChange={handleChange}
      /> :
        <span>{value}</span>
      }
    </td>
  );
}

const validateZipInput = (value: string) => {
  //const zipRegex = /^(?:\d{5}|[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d)$/; //This one will validate against the full value
  //USA: USA: 3, 4, or 5 digits (e.g., 123, 1234, 12345)
  //CAN: 3 or 6 alternating alphanumeric characters (e.g., A1A, A1A1A1)
  const zipRegex = /^(?:\d{3,5}|[A-Za-z]\d[A-Za-z](?:\d[A-Za-z]\d)?)$/;
  return zipRegex.test(value);
};

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: TierGroup = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

const Tier = (props: Props) => {
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);

  const [showZipRangeDialog, setShowZipRangeDialog] = useState<TierGroupSelectedOption | null>(null);

  const editField: string = "inEdit";
  const [field, setField] = useState<string>("");
  const [tierSummary, setTierSummary] = useState<TierSummary>();
  const [tierGroups, setTierGroups] = useState<TierGroup[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<TierGroup[]>([]);

  const MultiSelectCell = (props: GridCellProps) => {
    let dataItem: TierGroup = props.dataItem;
    var values = props.dataItem[props.field] as string[];
    const anchor = useRef<MultiSelectHandle | null>(null);
    const [showPopover, setShowPopover] = useState(false);
    const [zipRangeValue, setZipRangeValue] = useState<string[]>(["", ""]);

    const handleChange = (e: MultiSelectChangeEvent) => {
      if (props.onChange) {
        if ((e.value as string[]).some(x => x === "+ Zip Range")) {
          setShowZipRangeDialog({
            IsFrom: props.field === 'From',
            TierID: dataItem.TierID,
            IndexInArray: -1,
            //Value: ''
          });
        } else {
          setShowZipRangeDialog(null);
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.value,
          });
        }
      }
    };

    useEffect(() => {
      setShowPopover(showZipRangeDialog?.TierID === dataItem?.TierID
        && ((showZipRangeDialog.IsFrom === true && props.field === 'From') || showZipRangeDialog.IsFrom === false && props.field === 'To'));
    }, [dataItem?.TierID, props.field]);

    return (
      <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
        {dataItem.inEdit ? <>
          <MultiSelect
            data={tierSummary.SupportedStates}
            value={values}
            onChange={handleChange} ref={anchor}
          />
          <Popover show={showPopover} anchor={anchor.current && anchor.current.element} position={'bottom'} title={'Enter Zip Range'}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextBox
                value={zipRangeValue[0]}
                valid={validateZipInput(zipRangeValue[0])}
                maxLength={6}
                onChange={(e) => {
                  const rawValue = String(e.value);
                  const alphanumericValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
                  setZipRangeValue([alphanumericValue.toUpperCase(), zipRangeValue[1]]);
                }}
                placeholder={"e.g., 48180"}
              />
              -
              <TextBox
                value={zipRangeValue[1]}
                valid={validateZipInput(zipRangeValue[1])}
                maxLength={6}
                onChange={(e) => {
                  const rawValue = String(e.value);
                  const alphanumericValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
                  setZipRangeValue([zipRangeValue[0], alphanumericValue.toUpperCase()]);
                }}
                placeholder={"e.g., 48180"}
              />
            </div>
            <PopoverActionsBar>
              <Button onClick={() => {


                const tier = tierGroups.find(x => x.TierID == showZipRangeDialog.TierID);

                var tierInputValue = `${zipRangeValue[0]}-${zipRangeValue[1]}`;
                //array.splice(1, 0, newString);
                if (showZipRangeDialog.IndexInArray === -1) {
                  if (showZipRangeDialog.IsFrom) {
                    tier.From.push(tierInputValue);
                  } else {
                    tier.To.push(tierInputValue);
                  }
                } else {
                  const arrIdx = showZipRangeDialog.IndexInArray;
                  if (showZipRangeDialog.IsFrom) {
                    tier.From[arrIdx] = tierInputValue;
                  } else {
                    tier.To[arrIdx] = tierInputValue;
                  }
                }

                setTierGroups([...tierGroups]);

                let tierSummaryResult = tierSummary.SupportedStates.map(x => x);
                tierSummaryResult.splice(1, 0, tierInputValue);

                setTierSummary({ ...tierSummary, SupportedStates: [...tierSummaryResult] });

                setShowZipRangeDialog(null)


              }} themeColor={'primary'} fillMode={'flat'}>Insert</Button>
              <Button onClick={() => setShowZipRangeDialog(null)} fillMode={'flat'}>Cancel</Button>
            </PopoverActionsBar>
          </Popover>
        </> :
          <div style={{ maxWidth: '35px', display: 'flex', gap: '4px' }}>
            {values.map((x, key) => (
              <span key={key} className="badge badge-dark p-2 font-weight-normal" style={{ fontSize: '12px' }}>{x}</span>
            ))}
          </div>
        }
      </td>
    );
  };

  const EditCommandCell = (_props: EditCommandCellProps) => {
    const inEdit = _props.dataItem[_props.editField];
    let dataItem: TierGroup = _props.dataItem;
    const isNewItem = dataItem.isNew;
    const disabled = dataItem.From.length === 0 || dataItem.To.length === 0;

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disabled}
          onClick={() =>
            isNewItem ? _props.add(dataItem) : _props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? _props.discard() : _props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
          onClick={() => _props.edit(dataItem)}>Edit</Button>
        <Button themeColor={"secondary"}
          disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
          onClick={async () => {
            if (!await confirm(`Remove ?`))
              return;
            _props.remove(dataItem);
          }}>Delete</Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const enterEdit = (dataItem: TierGroup) => {
    if (tierGroups.some(x => x.inEdit === true || props.disabled === true))
      return;

    setTierGroups(
      tierGroups.map((item) =>
        item.TierID === dataItem.TierID ? { ...item, inEdit: true } : item
      )
    );
  };

  const remove = (dataItem: TierGroup) => {
    updateTiers({ ...dataItem, Delete: true });
  };

  const add = (dataItem: TierGroup) => {
    updateTiers(dataItem);
  };

  const update = (dataItem: TierGroup) => {
    updateTiers(dataItem);
  };

  const cancel = (dataItem: TierGroup) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.TierID === dataItem.TierID
    );
    const newData = tierGroups.map((item) =>
      item.TierID === originalItem.TierID ? cloneObject(originalItem) : item
    );

    setTierGroups(newData);
    setField('');
    setShowZipRangeDialog(null);
  };

  const generateId = () =>
    tierGroups.reduce((acc, current) => Math.max(acc, current.TierID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, TierID: generateId(), isNew: true,
      From: [], To: [],
    } as TierGroup;
    setTierGroups([newDataItem, ...tierGroups]);
  };

  const discard = () => {
    const newData = [...tierGroups];
    newData.splice(0, 1);
    setTierGroups(newData);
  };

  const refresh = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Quote/GetTiers/${props.RateMasterID}`)
      .then((response: TierSummary) => {
        setTierSummary(response);
        setTierGroups(response.TierGroups);
        setCSIDataCopy(cloneObject(response.TierGroups)) //deep copy/clone   
        setLoading(false);
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [props.RateMasterID, alert]);


  const cloneObject = (obj: any): any => {
    return JSON.parse(JSON.stringify(obj));
  }

  const updateTiers = (dataItem: TierGroup) => {
    setLoading(true);

    const data = { ...dataItem }
    fetchApi(`api/Quote/Tier/${props.RateMasterID}`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else {
          setField('');
          refresh();
        }
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    refresh();
  }, [props.RateMasterID, refresh]);

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = tierGroups.map((item) =>
      item.TierID === event.dataItem.TierID
        ? { ...item, [field]: event.value }
        : item
    );

    setField(field);

    setTierGroups(newData);
  }

  const SetTierGroupSelectedOption = async (tierGroupSelectedOption: TierGroupSelectedOption) => {
    setShowZipRangeDialog(tierGroupSelectedOption);
  }

  const dataView = () => {
    return (<div className={`${props.className ?? ''}`} style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <h4 className="text-left">Tiers</h4>
      <Grid
        style={{
          maxHeight: `${window.innerHeight * .35}px`,
        }}
        onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
        data={tierGroups}
        onItemChange={itemChange}
        editField={editField}
      >
        <GridToolbar>
          <Button themeColor={'primary'}
            disabled={tierGroups.some(x => x.inEdit === true) || props.disabled === true}
            title="Add new tier"
            onClick={addNew}
          >
            Add new tier
          </Button>
        </GridToolbar>
        <Column field="TierID" title="Tier" editable={false} />
        <Column field="From" title="From" cell={MultiSelectCell} />
        <Column field="To" title="To" cell={MultiSelectCell} />
        <Column field="Comments" title="Comment" cell={CommentCell} />
        <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} />
        <Column cell={MyEditCommandCell} />
      </Grid>
      <ConfirmationDialog />
    </div >);
  };

  return dataView();
}
export default Tier;
