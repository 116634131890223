import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { createContext, useCallback, useContext, useState } from 'react';

type Props = {
    children: JSX.Element
}

type UseAlertProps = {
    alert: (message: string) => Promise<unknown>;
}

const AlertContext = createContext<UseAlertProps>({
    alert: () => Promise.resolve()
})

const AlertProvider: React.FC<Props> = props => {
    const [promise, setPromise] = useState<{
        resolve: (value: unknown) => void;
    } | null>(null);

    const [message, setMessage] = useState('');

    const alert = useCallback((message: string) => new Promise((resolve) => {
        setMessage(message);
        setPromise({ resolve });
    }), []);

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    return <AlertContext.Provider value={{ alert }}>
        {promise !== null && <Dialog minWidth="350px">
            <p style={{ margin: '25px', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                <span style={{ fontSize: 'larger' }}>{message}</span>
            </p>
            <DialogActionsBar layout={'end'}>
                <Button themeColor={'primary'} onClick={handleConfirm}>OK</Button>
            </DialogActionsBar>
        </Dialog>}
        {props.children}
    </AlertContext.Provider>;
};

function useAlert() {
    const context = useContext(AlertContext)
    return context
}

export { AlertProvider };
export default useAlert;