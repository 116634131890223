import { useResizeDetector } from 'react-resize-detector';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import * as React from 'react';

type Props = {
  locations: Location[];
  style?: React.CSSProperties
};

type Location = {
  text?: string;
  lnglat: TrimbleMaps.LngLat;
}

const TrimbleMap = (props: Props) => {

  const map = React.useRef<TrimbleMaps.Map>(null);
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [mapStyleLoaded, setMapStyleLoaded] = React.useState(false);

  const onResize = React.useCallback(() => {
    if (map.current) {
      map.current.resize();
    }
  }, []);

  const { ref } = useResizeDetector({ onResize });

  const renderMap = React.useCallback((locations: Location[]) => {
    // Map style isn't loaded yet
    if (!map.current.isStyleLoaded()) {
      // Retry after a second
      setTimeout(() => {
        renderMap(locations);
      }, 100);
      return;
    }

    // Draw all markers
    const bounds = new TrimbleMaps.LngLatBounds();
    locations.forEach((location) => {
      new TrimbleMaps.Marker()
        .setLngLat(location.lnglat)
        .setPopup(location.text ? new TrimbleMaps.Popup().setText(location.text) : undefined)
        .addTo(map.current);
      bounds.extend(location.lnglat);
    });

    map.current.fitBounds(bounds, {
      padding: 50
    });
  }, [map]);

  React.useEffect(() => {
    if (map.current && mapLoaded && mapStyleLoaded) {
      if (props.locations.length > 1) {
        renderMap(props.locations);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapLoaded, mapStyleLoaded, JSON.stringify(props.locations), renderMap])

  React.useEffect(() => {
    const style = localStorage["darkmode"] === 'false' ? TrimbleMaps.Common.Style.TRANSPORTATION : TrimbleMaps.Common.Style.TRANSPORTATION_DARK;
    map.current = new TrimbleMaps.Map({
      container: "component-map",
      style: style,
      center: [-83.3083,42.2079],
      zoom: 12,
      scrollZoom: false,
      trackResize: false,
      attributionControl: false,
    });

    var navControl = new TrimbleMaps.NavigationControl();
    map.current.addControl(navControl, 'top-left');
    map.current.setDarkMode(style === TrimbleMaps.Common.Style.TRANSPORTATION_DARK);

    map.current.on('load', () => {
      setMapLoaded(true);
      map.current.setWeatherRadarVisibility(true);
    });

    map.current.on('style.load', () => {
      setMapStyleLoaded(true);
    })

    return () => {
      map.current.remove();
    }
  }, [renderMap]);

  return <div ref={ref} id="component-map" style={{ width: '100%', height: '100%', ...props.style }} />;
}

export default TrimbleMap;
