import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';

type Props = {
    DriverID: number,
    IsGridInFocus?: boolean,
    TABRefreshIteration?: number
}

export type CardChangeLogViewItem = {
    ActivityDateTime: Date,
    UserName: string,
    DriverNumber: string,
    TransactionType: number,
    TransactionTypeValue: string,
    TransactionCode: string,
    TransactionAmount: number
}

const AmountCell = (props: GridCellProps) => {
    if (!props.field)
        return null;
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "c")}
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
        </td>
    );
};

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const CardChangeLogGrid = (props: Props) => {
    const [driverID, setDriverID] = useState(0);
    const [tabRefreshIteration, setTABRefreshIteration] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cardChangeLog, setCardChangeLog] = useState<CardChangeLogViewItem[]>();

    const Refresh = useCallback((): boolean => {
        return props.TABRefreshIteration != null && props.TABRefreshIteration !== tabRefreshIteration;
    }, [props.TABRefreshIteration, tabRefreshIteration]);

    useEffect(() => {
        if (props.IsGridInFocus == null || props.IsGridInFocus === false)
            return;
        if (!Refresh()) {
            if (driverID === props.DriverID)
                return;
        } else {
            setTABRefreshIteration(props.TABRefreshIteration);
        }

        setDriverID(props.DriverID);
        setLoading(true);
        fetchApi(`/api/Asset/GetDriverCardTransactionLog`, { DriverID: props.DriverID }, 'POST')
            .then((response: CardChangeLogViewItem[]) => {
                setCardChangeLog(response);
                setLoading(false);
            });
    }, [props.DriverID, props.IsGridInFocus, driverID, Refresh, props.TABRefreshIteration]);

    return <div className="container-fluid">
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <Grid data={cardChangeLog} style={{ maxHeight: '600px' }}>
            <Column field="ActivityDateTime" title="Date" cell={DateCell} />
            <Column field="UserName" title="UserID" />
            <Column field="DriverNumber" title="Driver ID" />
            <Column field="TransactionTypeValue" title="Transaction Type" />
            <Column field="TransactionCode" title="Change/Code" />
            <Column field="TransactionAmount" title="Amount" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
        </Grid>
    </div>
}

export default CardChangeLogGrid;