import { AutoComplete, AutoCompleteProps, ListItemProps } from '@progress/kendo-react-dropdowns';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { ISingleSearchLocation, singleSearch } from '../../services/pcmiler';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { SvgIcon } from "@progress/kendo-react-common";
import { exclamationCircleIcon } from '@progress/kendo-svg-icons';

type Props = {
    value?: string,
    onSelected: (location: ISingleSearchLocation | null) => void;
    onSelectUnlisted?: () => void;
    autoCompleteProps?: AutoCompleteProps;
}

const SingleLocationAutoComplete = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [locationDropDownItems, setLocationDropDownItems] = useState<ISingleSearchLocation[]>([]);
    const [locationAutoCompleteValue, setLocationAutoCompleteValue] = useState(props.value);
    const _requestHash = useRef<string>('');

    useEffect(() => {
        setLocationAutoCompleteValue(props.value);
    }, [props.value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: string, requestHash: string) => {
            if (val.length > 2) {
                setLoading(true);
                singleSearch(val)
                    .then((response) => {
                        const locationData: ISingleSearchLocation[] = response.Locations;
                        if (_requestHash.current === requestHash) {
                            setLocationDropDownItems(locationData);
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        if (_requestHash.current === requestHash) {
                            setLocationDropDownItems([]);
                        }
                        setLoading(false);
                    });
            }
        }, 750), []);

    const itemRenderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const location = itemProps.dataItem as ISingleSearchLocation;
        const itemChildren = <span>{location.ShortString}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        const noData = (
          <h4 style={{ fontSize: "1em" }}>
            <SvgIcon icon={exclamationCircleIcon} size="xxlarge" />
            <br />
            Location Not Found
            <br />
            {props.onSelectUnlisted && <a href="#" onClick={(e) => {
                e.preventDefault();
                props.onSelectUnlisted();
            }}>Enter Manually</a>}
          </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
      };

    return <AutoComplete
        {...props.autoCompleteProps}
        placeholder={(loading ? 'loading...' : props.autoCompleteProps?.placeholder)}
        loading={loading}
        data={locationDropDownItems}
        itemRender={itemRenderLocation}
        value={locationAutoCompleteValue}
        listNoDataRender={listNoDataRender}
        onChange={(e) => {
            if (typeof e.value === 'string') {
                setLocationDropDownItems([]);
                setLocationAutoCompleteValue(e.value);

                if (isNullOrWhiteSpace(e.value)) //handles the clearing on input
                    props.onSelected(null);

                const requestHash = Math.random().toString(36).substring(7);
                _requestHash.current = requestHash;
                debounceSearchInput(e.value, requestHash);
            } else {
                const location = e.value as ISingleSearchLocation;
                setLocationAutoCompleteValue(`${location.Address.Zip} ${location.Address.City}, ${location.Address.State}`);
                props.onSelected(location)
            }
        }}
    />
}

export default SingleLocationAutoComplete;
