import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Moment from 'moment-timezone';
import LoadingPanel from "../../../components/LoadingPanel";
import { fetchApi } from "../../../services/api";
import { Title } from "../../../utils/title";
import { Window } from "@progress/kendo-react-dialogs";
import realFormatter from "../../../services/formatting/number";
import RouteMap from "../../../components/RouteMap";
import { useBoolean } from "usehooks-ts";
import { ILink } from "types/link";
import { Link } from "react-router-dom";
import { dateWindowFormatter } from "utils/utils";

type RouteComponentParams = {
  orderId: string;
};

type OrderPlanningDetails = {
  OrderID: number;
  OrderNumber: number;
  OrderStatusName: string;
  DispatchStatusName: string;
  BilledVehicleTypeName: string;
  TotalPieces: number;
  TotalWeight: number;
  HazMat: boolean;
  DockLevel: boolean;
  VehiclesCarriers: VehiclesCarriers;
  Stops: OrderPlanningStop[];
  Links: ILink[];
}

enum VehiclesCarriers {
  Vehicles = 0,
  Carriers = 1
}

type OrderPlanningStop = {
  OrderStopID: number;
  Sequence: number;
  Event: number;
  CustomerName: string;
  Location: string;
  Latitude: number;
  Longitude: number;
  ScheduledDateTime: Date;
  LatestDateTime: Date | null;
  Verb: string;
  Distance: number;
}

type PlanningContextType = {
  vehiclesCarriers: VehiclesCarriers;
  setVehiclesCarriers: (vehiclesCarriers: VehiclesCarriers) => void;
  toggleMap: () => void;
}

export const PlanningContext = createContext<PlanningContextType>({
  vehiclesCarriers: undefined,
  setVehiclesCarriers: () => {},
  toggleMap: () => {},
});

type Props = {
  children?: React.ReactNode
};

const OrderPlanning: React.FC<Props> = props => {
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const { value: showMap, toggle: toggleMap } = useBoolean(false);
  const [details, setDetails] = useState<OrderPlanningDetails>();
  const [vehiclesCarriers, setVehiclesCarriers] = useState<VehiclesCarriers>();
  const history = useHistory();

  const refreshOrderDetails = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/PlanningDetails/${orderIdParam}`)
      .then((response: OrderPlanningDetails) => {
        setDetails(response);
        setVehiclesCarriers(response.VehiclesCarriers);
        setLoading(false);

        const plannedLink = response.Links.find(x => x.Name === "Planned");
        if (plannedLink) history.push(plannedLink.Link);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam, history]);

  useEffect(() => {
    refreshOrderDetails();
  }, [orderIdParam, refreshOrderDetails]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return (
    <div className="container-xl">
      <Title string={`${details.OrderNumber} - Planning`} />
      {showMap && <Window stage="FULLSCREEN" style={{ width: '100%' }} onClose={() => toggleMap()}>
          <RouteMap lnglats={details.Stops.reverse().map(x => [x.Longitude, x.Latitude])} />
      </Window>}
      {loading && <LoadingPanel />}
      <div className="card my-2">
        <div className="card-body">
          <h5 className="card-title text-center">
            <Link to={`/Order/${details.OrderID}`}>Order Planning # {details.OrderNumber}</Link>
          </h5>
          <div className="row">
            <div className="col-12 col-md-3 py-1">
              Status: <b>{details.OrderStatusName}</b>
            </div>
            <div className="col-12 col-md-3 py-1">
              Dispatch: <b>{details.DispatchStatusName}</b>
            </div>
            <div className="col-12 col-md-3 py-1">
              Requested Vehicle: <b>{details.BilledVehicleTypeName}</b>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3 py-1">
              Pieces: <b>{realFormatter(details.TotalPieces)}</b>
            </div>
            <div className="col-12 col-md-3 py-1">
              Weight: <b>{realFormatter(details.TotalWeight)}</b>
            </div>
            <div className="col-12 col-md-3 py-1">
              HazMat: <b>{details.HazMat ? "Yes" : "No"}</b>
            </div>
            <div className="col-12 col-md-3 py-1">
              Dock Level: <b>{details.DockLevel ? "Yes" : "No"}</b>
            </div>
          </div>
        </div>
        <table className="table table-hover table-striped mb-0">
          <thead>
            <tr>
              <th>Event</th>
              <th>Scheduled (EST)</th>
              <th>Customer</th>
              <th>Location</th>
              <th>Miles</th>
            </tr>
          </thead>
          <tbody>
            {details?.Stops.map(stop => (
              <tr key={stop.OrderStopID}>
                <td>{stop.Event == 1 ? 'Pickup' : 'Delivery'}</td>
                <td>{dateWindowFormatter(stop.ScheduledDateTime, stop.LatestDateTime)} {stop.Verb}</td>
                <td>{stop.CustomerName}</td>
                <td>{stop.Location}</td>
                <td>{realFormatter(stop.Distance)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PlanningContext.Provider value={{ vehiclesCarriers, setVehiclesCarriers, toggleMap }}>
        {props.children}
      </PlanningContext.Provider>
    </div>
  );
}

export default OrderPlanning;