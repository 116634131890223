import CenterDivPanel from './CenterDivPanel';

const LoadingPanel = () => {
  return (
    <CenterDivPanel>
      <div className="spinner-grow text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </CenterDivPanel>
  );
}
export default LoadingPanel;
