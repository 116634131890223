import { GridCellProps } from "@progress/kendo-react-grid";
import { Alert } from '..';
import { memo } from 'react';

const OriginCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as Alert;
  const link = data.Links.find(x => x.Name === 'Origin');
  return <td>
    <a style={{ color: '#007bff' }} target='_blank' href={link.Link}>{data.Origin}</a>
  </td>;
}

export default memo(OriginCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);