import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import LoadingPanel from 'components/LoadingPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { IDName } from '../../types/idname';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
  DriverID: number;
  CloseDialog: () => void;
};

type DriverPayRateMasterSummary = {
  DriverNumber: string;
  DriverPayRateMasters: DriverPayRateMasterViewModel[];
  PayRateLinehaulList: IDName[];
  PayRateAccessorialList: IDName[];
}

type DriverPayRateMasterViewModel = {

  PayRateMasterID: number;
  ModifiedByFullName: string;
  ModifiedDateTime: Date;
  StartDate: Date;
  EndDate: Date;

  PayRateLinehaulID: number;
  PayRateLinehaulName: string;
  PayRateAccessorialID: number;
  PayRateAccessorialName: string;

  SupercededByFullName: string;
  Comment: string; //Instruction

  inEdit?: boolean | string;
  isNew?: boolean;
  Supercede?: boolean;
  IsExpired: boolean;
};

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: DriverPayRateMasterViewModel = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

const PayRateTableSelectionHeaderCell = (props: GridHeaderCellProps) => {
  return (
    <a className="k-link" onClick={props.onClick}>
      <div>Pay Rate Linehaul</div>
      <div>Pay Rate Accessorial</div>
      {props.children}
    </a>
  );
};

const InstructionTypeCell = (props: any) => {
  if (!props.field)
    return null;

  let dataItem: DriverPayRateMasterViewModel = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field, //props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.ID,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={props.PayRateLinehaulList}
        textField="Name"
        dataItemKey="ID"
        value={props.PayRateLinehaulList.find((x: IDName) => x.ID == dataItem.PayRateLinehaulID)}
        onChange={(e) => handleChange(e, 'PayRateLinehaulID')}
      /> :
        <div>{dataItem.PayRateLinehaulName}</div>
      }

      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={props.PayRateAccessorialList}
        textField="Name"
        dataItemKey="ID"
        value={props.PayRateAccessorialList.find((x: IDName) => x.ID == dataItem.PayRateAccessorialID)}
        onChange={(e) => handleChange(e, 'PayRateAccessorialID')}
      /> :
        <div>{dataItem.PayRateAccessorialName}</div>
      }
    </td>
  );
};

const CommentCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  let dataItem: DriverPayRateMasterViewModel = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ?
        <TextBox
          value={dataItem.Comment}
          onChange={handleChange}
          //placeholder="Comments"
          style={{ width: '100%' }}
        /> :
        <span>{dataItem.Comment}</span>
      }
    </td>
  );
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: DriverPayRateMasterViewModel) => void;
  //remove: (item: DriverPayRateMasterViewModel) => void;
  add: (item: DriverPayRateMasterViewModel) => void;
  discard: () => void;
  update: (item: DriverPayRateMasterViewModel) => void;
  cancel: (item: DriverPayRateMasterViewModel) => void;
  editField: string;
}

const DateCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: Date) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: null,
        value: e,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {props.dataItem.inEdit ?
        <SimpleDatePicker
          value={Moment.utc(props.dataItem[props.field]).toDate()}
          onChange={handleChange}
        />
        :
        <div className={props.field === 'EndDate' && props.dataItem.IsExpired ? 'text-warning' : ''}>
          {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
        </div>
      }
    </td>
  );
};

const SetDriverPayRateMaster = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const editField: string = "inEdit";
  const [CSIDataCopy, setCSIDataCopy] = useState<DriverPayRateMasterViewModel[]>([]);
  const [DriverPayRateMasters, setDriverPayRateMasters] = useState<DriverPayRateMasterViewModel[]>([]);
  const [driverNumber, setDriverNumber] = useState('');
  const [payRateLinehaulList, setPayRateLinehaulList] = useState<IDName[]>([]);
  const [payRateAccessorialList, setPayRateAccessorialList] = useState<IDName[]>([]);
  const [hideExpiredPayRateStructures, setHideExpiredPayRateStructures] = useState(true);
  const { alert } = useAlert();

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: DriverPayRateMasterViewModel = props.dataItem;
    const isNewItem = dataItem.isNew;

    return inEdit ? (
      <td className="k-command-cell">
        <button
          disabled={dataItem.StartDate == null || dataItem.EndDate == null}
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </button>
      </td>
    ) : (
      <td className="k-command-cell">
        <button
          disabled={DriverPayRateMasters.some(x => x.inEdit === true || x.isNew === true)}
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
          onClick={() => props.edit(dataItem)}
        >
          Edit
        </button>
        {/*<button*/}
        {/*    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"*/}
        {/*    onClick={() =>*/}
        {/*        window.confirm("Confirm deleting: " + dataItem.PayRateMasterID) && props.remove(dataItem)*/}
        {/*    }*/}
        {/*>*/}
        {/*    Delete*/}
        {/*</button>*/}
      </td>
    );
  };

  const refresh = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Settlements/GetDriverPayRateMasters/${props.DriverID}`)
      .then((summary: DriverPayRateMasterSummary) => {
        setLoading(false);

        summary.DriverPayRateMasters.forEach(obj => {
          obj.StartDate = Moment.utc(obj.StartDate).toDate();
          obj.EndDate = Moment.utc(obj.EndDate).toDate();
        });

        setDriverPayRateMasters(summary.DriverPayRateMasters);
        setCSIDataCopy(summary.DriverPayRateMasters);

        setDriverNumber(summary.DriverNumber);
        setPayRateLinehaulList(summary.PayRateLinehaulList);
        setPayRateAccessorialList(summary.PayRateAccessorialList);
      })
      .catch(async err => {
        setLoading(false);
        await alert(err);
      });
  }, [props.DriverID, alert]);

  useEffect(() => {
    refresh();
  }, [props.DriverID, refresh]);

  const enterEdit = (dataItem: DriverPayRateMasterViewModel) => {
    if (dataItem.inEdit === true)
      return;

    setDriverPayRateMasters(
      DriverPayRateMasters.map((item) =>
        item.PayRateMasterID === dataItem.PayRateMasterID ? { ...item, inEdit: true } : item
      )
    );
  };

  const updateDriverPayRateMasters = (dataItem: DriverPayRateMasterViewModel) => {
    setLoading(true);
    const data = { ...dataItem }
    fetchApi(`/api/Settlements/DriverPayRateMaster/${props.DriverID}`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async () => {
        await alert('An error occurred while saving.');
        setLoading(false);
      });
  }

  const add = (dataItem: DriverPayRateMasterViewModel) => {
    updateDriverPayRateMasters(dataItem);
  };

  const update = (dataItem: DriverPayRateMasterViewModel) => {
    updateDriverPayRateMasters(dataItem);
  };

  const discard = () => {
    const newData = [...DriverPayRateMasters];
    newData.splice(0, 1);
    setDriverPayRateMasters(newData);
  };

  const cancel = (dataItem: DriverPayRateMasterViewModel) => {
    const originalItem = CSIDataCopy.find(
      //const originalItem = DriverPayRateMasters.find(
      (p) => p.PayRateMasterID === dataItem.PayRateMasterID
    );
    const newData = DriverPayRateMasters.map((item) =>
      item.PayRateMasterID === originalItem.PayRateMasterID ? originalItem : item
    );

    setDriverPayRateMasters(newData);
  };

  const generateId = () =>
    DriverPayRateMasters.reduce((acc, current) => Math.max(acc, current.PayRateMasterID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, PayRateMasterID: generateId(), isNew: true,
      Comment: '', PayRateLinehaulID: 0, PayRateAccessorialID: 0,
      StartDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      EndDate: Moment.tz("12/31/2999 00:00", "America/New_York").toDate(),
      IsExpired: false
    } as DriverPayRateMasterViewModel;
    setDriverPayRateMasters([newDataItem, ...DriverPayRateMasters]);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const dataItem = event.dataItem as DriverPayRateMasterViewModel;
    const newData = DriverPayRateMasters.map((item) =>
      item.PayRateMasterID === dataItem.PayRateMasterID
        ? { ...item, [event.field || ""]: event.value }
        : item
    );

    setDriverPayRateMasters(newData);
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      //remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  return (<>
    <Dialog title={`Set Driver ${driverNumber} Pay Rate Structure`} onClose={props.CloseDialog} className="dialog-w1140">
      <div className="container-fluid">
        {loading && <LoadingPanel />}
        <Grid
          data={hideExpiredPayRateStructures ? DriverPayRateMasters.filter(x => x.IsExpired === false) : DriverPayRateMasters}
          onItemChange={itemChange}
          editField={editField}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => { enterEdit(e.dataItem); }}
        >
          <GridToolbar>
            <Button
              themeColor="primary"
              disabled={DriverPayRateMasters.some(x => x.inEdit === true || x.isNew === true)}
              title="Add new"
              onClick={addNew}
            >
              Add new
            </Button>
            <span>
              <Checkbox
                className="ml-2"
                value={hideExpiredPayRateStructures}
                onChange={(e) => setHideExpiredPayRateStructures(e.value)}
                label={"Hide Expired Pay Rate Structures"}
              />
            </span>
          </GridToolbar>
          <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} />
          <Column field="StartDate" title="Effective From" cell={DateCell} width={175} />
          <Column field="EndDate" title="Effective To" cell={DateCell} width={175} />
          <Column field="PayRateLinehaulID" title="Entity Type" headerCell={PayRateTableSelectionHeaderCell} cell={(props) => <InstructionTypeCell {...props}
            PayRateLinehaulList={payRateLinehaulList} PayRateAccessorialList={payRateAccessorialList} />} />
          <Column field="Comment" title="Comment" cell={CommentCell} />
          <Column cell={MyEditCommandCell} width={150} />
        </Grid>
      </div>
    </Dialog>
  </>
  )
}

export default SetDriverPayRateMaster;