import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Loader } from '@progress/kendo-react-indicators';
import Moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import CustomerInput from '../../components/CustomerInput';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { ISingleSearchLocation } from '../../services/pcmiler';
import { ILink } from '../../types/link';
import { isNullOrWhiteSpace } from '../../utils/utils';
import SingleLocationAutoComplete from '../PCMiler/SingleLocationAutoComplete';

type Props = {
  GetSplitInfoLink: ILink;
  onCancel: () => void;
  onSave: () => void;
};

type SplitInfo = {
  OrderID: number,
  OrderStopID: number,
  MinDropDateTime: Date;
  SplitDropDateTime: Date;
  MaxPickUpDateTime: Date;
  SplitPickUpDateTime: Date;
  SplitCustomerID: number;
  SplitCustomerName: string;
  SplitCustomerNumber: string;
  SplitCity: string;
  SplitState: string;
  SplitZipCode: string;
  AddressLine1: string;
  Links: ILink[];
}

const EnterSplitStop = (props: Props) => {
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [saveSplitInfoLink, setSaveSplitInfoLink] = useState<ILink>();
  const [undoSplitInfoLink, setUndoSplitInfoLink] = useState<ILink>();

  const [instructions, setInstructions] = useState<Array<{ Display: number, Instruction: string }>>([]);

  const [minDropDateTime, setMinDropDateTime] = useState<Date>();
  const [splitDropDateTime, setSplitDropDateTime] = useState<Date>();

  const [maxPickUpDateTime, setMaxPickUpDateTime] = useState<Date>();
  const [splitPickUpDateTime, setSplitPickUpDateTime] = useState<Date>();

  const isValidDropDateTime = splitDropDateTime > minDropDateTime && splitDropDateTime <= splitPickUpDateTime;
  const isValidPickUpDateTime = splitPickUpDateTime < maxPickUpDateTime && splitPickUpDateTime >= splitDropDateTime;

  const [saveButtonText, setSaveButtonText] = useState('Split');

  const [loading, setLoading] = useState(true);

  const [customerInputValue, setCustomerInputValue] = useState<{ CustomerID: number, CustomerNumber: string }>({ CustomerID: 0, CustomerNumber: '' });
  const [customer, setCustomer] = useState<{ CustomerID: number, CustomerNumber: string, AddressLine1: string, CustomerName: string }>();
  const [singleSearchLocation, setSingleSearchLocation] = useState<ISingleSearchLocation>();

  const splitOrder = () => {
    setLoading(true);

    const data = {
      SplitPickUpDateTime: splitPickUpDateTime,
      SplitDeliveryDateTime: splitDropDateTime,
      SplitCustomerID: customer?.CustomerID,
      SplitCity: singleSearchLocation?.Address.City,
      SplitState: singleSearchLocation?.Address.State,
      SplitZipCode: singleSearchLocation?.Address.Zip
    };

    fetchApi(saveSplitInfoLink.Link, data, saveSplitInfoLink.Method)
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          await alert(response.ErrorMessage);
        } else {
          props.onSave();
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to split or modify order');
      });
  };

  const removeSplitOrder = async () => {

    if (!await confirm(`Remove Split Order?`, `Remove Split Order?`))
      return;

    setLoading(true);

    fetchApi(undoSplitInfoLink.Link, {}, undoSplitInfoLink.Method)
      .then(async (response: JsonResponse) => {
        setLoading(false);
        if (response.Success === false) {
          await alert(response.ErrorMessage);
        } else
          props.onSave();
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to remove split order');
      });
  };

  useEffect(() => {
    setLoading(true);

    fetchApi(props.GetSplitInfoLink.Link, {}, props.GetSplitInfoLink.Method)
      .then((response: SplitInfo) => {
        setLoading(false);

        if (response.SplitCustomerNumber != null && response.SplitCustomerNumber !== "0") {
          setCustomerInputValue({ CustomerID: response.SplitCustomerID, CustomerNumber: response.SplitCustomerNumber });
          setCustomer({
            CustomerID: response.SplitCustomerID,
            CustomerNumber: response.SplitCustomerNumber,
            AddressLine1: response.AddressLine1,
            CustomerName: response.SplitCustomerName
          });
        }

        setSaveSplitInfoLink(response.Links.find(x => x.Name === 'Modify Split' || x.Name === 'Insert Split'));
        setUndoSplitInfoLink(response.Links.find(x => x.Name === 'Undo Split'));

        if (isNullOrWhiteSpace(response.SplitCity) === false) {
          setSaveButtonText('Update Split');

          setSingleSearchLocation({
            Address: { City: response.SplitCity, State: response.SplitState, Zip: response.SplitZipCode }
          } as ISingleSearchLocation)
        } else
          setSaveButtonText('Create Split');

        setMinDropDateTime(Moment.utc(response.MinDropDateTime).toDate());
        setSplitDropDateTime(Moment.utc(response.SplitDropDateTime).toDate());

        setMaxPickUpDateTime(Moment.utc(response.MaxPickUpDateTime).toDate());
        setSplitPickUpDateTime(Moment.utc(response.SplitPickUpDateTime).toDate());
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to get split info');
      });
  }, [props.GetSplitInfoLink, alert]);

  const instructionColor = (display: number) => {
    switch (display) {
      case 82:
        return { className: "text-danger" };
      case 71:
        return { className: "text-success" };
      case 89:
        return { className: "text-warning" };
      case 66:
        return { className: "text-primary" };
    }
    return null;
  }

  const renderInstructions = () => {
    if (instructions.length === 0 || customer === undefined) return null;
    return <>
      {instructions.map((instruction, index) => {
        return <div key={index} className="mt-2">
          <span {...instructionColor(instruction.Display)}>{customer?.CustomerNumber}: {instruction.Instruction}</span>
        </div >;
      })}
    </>
  }

  const getCustomerInstructions = useCallback(() => {
    if (customer?.CustomerID > 0) {
      fetchApi(`/api/Customer/SpecialInstructions/${customer.CustomerID}`)
        .then((response: Array<{ Type: number, Display: number, Instruction: string }>) => {
          const instructions = response
            .filter(x => x.Type === 83 || x.Type === 67)
            .map(function (x) { return { Display: x.Display, Instruction: x.Instruction } });

          const uniqueInstructions = Array.from(new Set(instructions.map(x => x.Instruction)));

          const distinctInstructionRecords = Array.from(uniqueInstructions, Instruction => {
            return instructions.find(x => x.Instruction === Instruction);
          });

          setInstructions(distinctInstructionRecords);

        });
    }
  }, [customer?.CustomerID]);

  useEffect(() => {
    getCustomerInstructions();
  }, [getCustomerInstructions, customer?.CustomerID]);

  return (<div className="k-card k-var--normal-background mb-2 px-2" style={{ overflow: 'visible' }}>
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}

    <div className='py-2'>
      <div className="form-row">
        <div className="col-auto" style={{ width: '85px' }}>
          <span className="badge border text-warning border-warning" style={{
            padding: "0.375rem 0.75rem",
            fontSize: ".75rem",
            lineHeight: "1.5"
          }}>Split Stop</span>
        </div>
        <div className="col">
          <div className="input-group">
            <CustomerInput
              CustomerID={customerInputValue.CustomerID}
              CustomerNumber={customerInputValue.CustomerNumber}
              placeholder={`Enter Split Stop Customer (Optional)`}
              onChange={(e) => {
                setCustomerInputValue({ CustomerID: e.CustomerID, CustomerNumber: e.CustomerNumber });
                if (e.CustomerID > 0) {
                  setSingleSearchLocation({
                    Address: {
                      City: e.City,
                      Zip: e.ZipCode,
                      State: e.State
                    }
                  } as ISingleSearchLocation);
                  setCustomer({
                    CustomerID: e.CustomerID,
                    CustomerNumber: e.CustomerNumber,
                    CustomerName: e.CustomerName,
                    AddressLine1: e.AddressLine1
                  });
                } else {
                  setCustomer(undefined);
                  setSingleSearchLocation(undefined);
                }
              }}
            />
          </div>
          {customer?.CustomerName && <div className="mt-2">
            <span>{customer?.CustomerName} - {customer?.AddressLine1}</span>
          </div>}
        </div>
      </div>
      <div className="form-row mt-2">
        <div className="col-auto" style={{ width: '85px' }}></div>
        <div className="col">
          <SingleLocationAutoComplete
            value={(singleSearchLocation && (singleSearchLocation?.Address.Zip || singleSearchLocation?.Address.City)) ? `${singleSearchLocation?.Address.Zip} ${singleSearchLocation?.Address.City}, ${singleSearchLocation?.Address.State}` : ''}
            autoCompleteProps={{ clearButton: true, placeholder: 'Enter Split City or Zip' }}
            onSelectUnlisted={() => {
              setCustomer(undefined);
              setCustomerInputValue({ CustomerID: 0, CustomerNumber: '' });
            }}
            onSelected={(e) => {
              setSingleSearchLocation(e);
              setCustomer(undefined);
              setCustomerInputValue({ CustomerID: 0, CustomerNumber: '' });
            }}
          />
          {renderInstructions()}
        </div>
      </div>
      <div className="form-row mt-2">
        <div className="col-auto" style={{ width: '85px' }}></div>
        <div className="col-auto">
          <DateTimePicker
            required={loading ? false : true}
            disabled={loading}
            min={minDropDateTime}
            valid={loading ? true : isValidDropDateTime}
            label={`Split Drop Date/Time (EST)`}
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={splitDropDateTime}
            onChange={(e) => setSplitDropDateTime(e.value)}
            width="100%"
          />
        </div>
        <div className="col-auto">
          <DateTimePicker
            required={loading ? false : true}
            disabled={loading}
            max={maxPickUpDateTime}
            valid={loading ? true : isValidPickUpDateTime}
            label={`Split Pick-Up Date/Time (EST)`}
            format="MM/dd/yyyy HH:mm"
            formatPlaceholder="formatPattern"
            value={splitPickUpDateTime}
            onChange={(e) => setSplitPickUpDateTime(e.value)}
            width="100%"
          />
        </div>
        <div className="col align-self-end">
          <div style={{ display: 'flex', gap: '0.7rem' }} className="justify-content-end">
            {undoSplitInfoLink && <Button themeColor={'error'}
              disabled={loading}
              onClick={() => removeSplitOrder()}>Remove Split</Button>}

            <Button themeColor={'primary'}
              disabled={loading || isValidPickUpDateTime === false || isValidDropDateTime === false ||
                (customer?.CustomerNumber === "0" || singleSearchLocation == undefined)}
              onClick={() => splitOrder()}>{saveButtonText}</Button>

            <Button themeColor={'secondary'}
              onClick={() => props.onCancel()}>Cancel</Button>
          </div>
        </div>
      </div>
      {loading === false && (isValidDropDateTime === false || isValidPickUpDateTime === false) && <div className="form-row mt-1">
        <div className="col-md-11 offset-md-1 text-danger">
          * Split drop must be before pick-up date/time and within the current and next stop date/time window
        </div>
      </div>}

    </div>
    <ConfirmationDialog />
  </div>);
}

export default EnterSplitStop;
