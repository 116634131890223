import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

const Description = (props: GridCellProps) => {
  const handleChange = (e: InputChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  }

  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return dataItem.inEdit
    ? <td>
        <Input value={dataValue} maxLength={30} onChange={handleChange} />
      </td>
    : <td style={{ maxWidth: '1vw' }}>
        {dataValue}
      </td>
}

export default Description;
