import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IndividualLoadBoardSettings } from 'TypeGen/CompanySettings/individual-load-board-settings';
import { LoadBoardAutoDecline } from 'TypeGen/CompanySettings/load-board-auto-decline';

type Props = {
  id: string;
  title: string;
  settings: IndividualLoadBoardSettings;
  onChange: (settings: IndividualLoadBoardSettings) => void;
}

export default class LoadBoardSettings extends React.Component<Props> {

  private autoDeclineOptions = [{ ID: 0, Name: 'Off' }, { ID: 1, Name: 'Audit' }, { ID: 2, Name: 'On' }];
  private afterHoursFrom = [
    { Value: '13:00:00', Name: '1pm' },
    { Value: '14:00:00', Name: '2pm' },
    { Value: '15:00:00', Name: '3pm' },
    { Value: '16:00:00', Name: '4pm' },
    { Value: '17:00:00', Name: '5pm' },
    { Value: '18:00:00', Name: '6pm' },
    { Value: '19:00:00', Name: '7pm' },
    { Value: '20:00:00', Name: '8pm' },
    { Value: '21:00:00', Name: '9pm' },
    { Value: '22:00:00', Name: '10pm' },
    { Value: '23:00:00', Name: '11pm' },
    { Value: '00:00:00', Name: '12am' }
  ];

  private afterHoursTo = [
    { Value: '01:00:00', Name: '1am' },
    { Value: '02:00:00', Name: '2am' },
    { Value: '03:00:00', Name: '3am' },
    { Value: '04:00:00', Name: '4am' },
    { Value: '05:00:00', Name: '5am' },
    { Value: '06:00:00', Name: '6am' },
    { Value: '07:00:00', Name: '7am' },
    { Value: '08:00:00', Name: '8am' },
    { Value: '09:00:00', Name: '9am' },
    { Value: '10:00:00', Name: '10am' },
    { Value: '11:00:00', Name: '11am' },
    { Value: '12:00:00', Name: '12pm' }
  ];

  public render() {
    return <div className="mb-2">
      <legend>{this.props.title}</legend>
      <Checkbox
        label="Active"
        checked={this.props.settings.QueryShipments}
        onChange={(e) =>  this.setSettings({ QueryShipments: e.value })}
      />
      {this.props.settings.QueryShipments && <div className="k-form-field">
        <label>Auto Decline:</label>
        <DropDownList
          textField="Name"
          data={this.autoDeclineOptions}
          onChange={(e) => {
            this.setSettings({ AutoDeclineEnabled: e.target.value.ID });
          }}
          value={this.autoDeclineOptions.find(x => x.ID === this.props.settings.AutoDeclineEnabled)}
        />
      </div>}
      {this.props.settings.AutoDeclineEnabled != LoadBoardAutoDecline.Off && <>
        <br />
        <Checkbox
          label="Only Auto Decline After Hours"
          checked={this.props.settings.OnlyAutoDeclineAfterHours}
          onChange={(e) =>  this.setSettings({ OnlyAutoDeclineAfterHours: e.value })}
        />
      </>}
      {this.props.settings.AutoDeclineEnabled != LoadBoardAutoDecline.Off && this.props.settings.OnlyAutoDeclineAfterHours && <React.Fragment>
        <div className="k-form-field">
          <label>After hours From:</label>
          <DropDownList
            textField="Name"
            data={this.afterHoursFrom}
            onChange={(e) => {
              this.setSettings({ AfterHoursFrom: e.target.value.Value });
            }}
            value={this.afterHoursFrom.find(x => x.Value === this.props.settings.AfterHoursFrom)}
          />
        </div>
        <div className="k-form-field">
          <label>After hours To:</label>
          <DropDownList
            textField="Name"
            data={this.afterHoursTo}
            onChange={(e) => {
              this.setSettings({ AfterHoursTo: e.target.value.Value });
            }}
            value={this.afterHoursTo.find(x => x.Value === this.props.settings.AfterHoursTo)}
          />
        </div>
        <br />
        <Checkbox
          label="Including Weekends?"
          value={this.props.settings.AfterHoursWeekends}
          onChange={(e) =>  this.setSettings({ AfterHoursWeekends: e.value })}
        />
      </React.Fragment>}
    </div>;
  }

  private setSettings(value: Partial<IndividualLoadBoardSettings>) {
    const settings = Object.assign({}, this.props.settings, value);
    this.props.onChange(settings);
  }
}
