import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { DriverNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridCellProps } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import { DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { AssetOfferHistoryView } from "TypeGen/Assets/Offers/asset-offer-history-view";
import { quoteIDCell } from "views/Quote/Recent";
import { formatNumber } from "@progress/kendo-intl";

type RouteComponentParams = {
    driverId: string;
};

type TurndownsType = {
    Data: AssetOfferHistoryView[],
    Total: number,
}

const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "QuoteID",
        dir: "desc"
    }],
} as DataSourceRequestState;

export const AssetOfferStatusCell = (props: GridCellProps) => {
  switch (props.dataItem.Status) {
    case 0:
      return <td title='Offered'>
        <Chip
          size='small'
          themeColor="info"
          text={formatNumber(props.dataItem.AllInRate, 'c')}
        />
      </td>;
    case 1:
    case 3:
      return <td title={props.dataItem.Status === 1 ? 'Declined' : 'Expired'}>
        <Chip
          size='small'
          themeColor="error"
          text={formatNumber(props.dataItem.AllInRate, 'c')}
        />
      </td>;
    case 2:
      return <td title='Accepted'>
        <Chip
          size='small'
          themeColor="success"
          text={formatNumber(props.dataItem.AllInRate, 'c')}
        />
      </td>;
    case 5:
      return <td title='Withdrawn'>
        <Chip
          size='small'
          themeColor="base"
          text={formatNumber(props.dataItem.AllInRate, 'c')}
        />
      </td>;
    default:
      return <td>N/A</td>;
  }
}

const DriverOffers = () => {
    const { driverId: driverIdParam } = useParams<RouteComponentParams>();

    return (<>
        <DriverNavBar id={driverIdParam} currentTab="Offers" />
        <Title string="Offers" />
        <DriverOffersGrid driverId={parseInt(driverIdParam)} />
    </>);
}

type DriverOffersGridProps = {
    driverId: number;
}

export const DriverOffersGrid = ({ driverId }: DriverOffersGridProps) => {

    const [loading, setLoading] = useState(true);
    const [turndowns, setTurndowns] = useState<TurndownsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({
        logic: 'and', filters: []
    });

    const refresh = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/DriverOffers/${driverId}?${queryStr}`, {}, 'POST')
            .then(data => {
                setLoading(false);
                setTurndowns(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [driverId, dataState]);

    useEffect(() => {
        refresh();
    }, [driverId, refresh]);

    return (<>
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "QuoteID",
                        label: "Quote #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "OfferDateTime",
                        label: "Offer Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "OfferedByUserName",
                        label: "Offered By",
                        filter: TextFilter,
                        operators: Operators.text,
                    },

                    {
                        name: "AllInRate",
                        label: "All In Rate",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "BillingCustomerNumber",
                        label: "Customer",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "FromCityState",
                        label: "From",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "ToCityState",
                        label: "To",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "Distance",
                        label: "Miles",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "CreatedByUserName",
                        label: "Created By",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "Total",
                        label: "Charges",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "SourceName",
                        label: "Source",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "VehicleNumber",
                        label: "Vehicle #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
            </label>
        </div>
        {turndowns && <Grid
            {...dataState}
            data={turndowns.Data}
            total={turndowns.Total}
            sortable
            pageable
            dataItemKey="QuoteOfferID"
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="QuoteID" title="Quote #" cell={quoteIDCell()} />
            <Column field="OfferDateTime" title="Offer Date" filter="date" cell={DateCell} />
            <Column field="OfferedByUserName" title="Offered By" />
            <Column field="AllInRate" title="All In Rate" filter="numeric" cell={AssetOfferStatusCell} />
            <Column field="RPM" filter="numeric" format="{0:c}" />
            <Column field="BillingCustomerNumber" title="Customer" />
            <Column field="FromCityState" title="From" />
            <Column field="ToCityState" title="To" />
            <Column field="Distance" title="Miles" filter="numeric" format="{0:n0} mi" />
            <Column field="Total" title="Charges" filter="numeric" format="{0:c}" />
            <Column field="SourceName" title="Source" />
            <Column field="VehicleNumber" media="(min-width: 768px)" title="Vehicle" />
        </Grid>}
    </>);
}

export default DriverOffers;