import * as React from 'react';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { ComboBox, DropDownList, DropDownListChangeEvent, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon } from '@progress/kendo-svg-icons';

type Props = {
  Link: ILink;
  CloseDialog: () => void;
}

type State = {
  data: OrderNotification[];
  EmailAddresses: Array<{ Name: string, Email: string }>;
  Commands: ILink[];
  OrderNotifications: OrderNotification[];
}

type OrderNotificationsResponse = {
  EmailAddresses: Array<{ Name: string, Email: string }>;
  OrderNotifications: OrderNotification[];
  Commands: ILink[];
}

type OrderNotification = {
  EmailOrderNotificationID: number;
  CustomerEmail: string;

  // Notification Rules
  SendPositionNotificationMinuteInterval: number;
  SendArrivedShipperNotification: boolean;
  SendLoadedNotification: boolean;
  SendArrivedConsigneeNotification: boolean;
  SendEmptiedNotification: boolean;
  SendArrivedBorderNotification: boolean;
  SendDepartedBorderNotification: boolean;

  // For Grid
  inEdit: boolean|undefined;
  emailAddresses: Array<{ Name: string, Email: string }>;
}

export class SetupNotifications extends React.Component<Props, State> {

  CommandCell: typeof React.Component;
  EmailDropDownCell: typeof React.Component;
  PositionDropDownCell: typeof React.Component;
  CheckboxCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      EmailAddresses: [],
      Commands: [],
      OrderNotifications: []
    };

    this.itemChange = this.itemChange.bind(this);

    function MyCommandCell(
      { edit, remove, add, update, discard, cancel, editField }: { 
        edit: (dataItem: OrderNotification) => void,
        remove: (dataItem: OrderNotification) => void,
        add: (dataItem: OrderNotification) => void,
        update: (dataItem: OrderNotification) => void,
        discard: (dataItem: OrderNotification) => void,
        cancel: (dataItem: OrderNotification) => void,
        editField: string
      }) {
      return class extends React.Component<GridCellProps> {
        render() {
          const { dataItem } = this.props;
          const inEdit = dataItem[editField];
          const isNewItem = dataItem.EmailOrderNotificationID === 0;

          return inEdit ? (
            <td className="k-command-cell">
              <Button
                className="k-grid-save-command"
                onClick={() => isNewItem ? add(dataItem) : update(dataItem)}
              >
                {isNewItem ? 'Add' : 'Update'}
              </Button>
              <Button
                className="k-grid-cancel-command"
                onClick={() => isNewItem ? discard(dataItem) : cancel(dataItem)}
              >
                {isNewItem ? 'Discard' : 'Cancel'}
              </Button>
            </td>
          ) : (
            <td className="k-command-cell">
              <Button
                themeColor="primary"
                className="k-grid-edit-command"
                onClick={() => edit(dataItem)}
              >
                Edit
              </Button>
              <Button
                className="k-grid-remove-command"
                onClick={() => window.confirm('Confirm deleting: ' + this.props.dataItem.CustomerEmail) &&
                  remove(dataItem)
                }
              >
                Remove
              </Button>
            </td>
          );
        }
      }
    };
    
    this.CommandCell = MyCommandCell({
      edit: this.enterEdit,
      remove: this.remove,

      add: this.add,
      discard: this.discard,

      update: this.update,
      cancel: this.cancel,

      editField: "inEdit"
  });

    class EmailDropDownCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const data = this.props.dataItem as OrderNotification;

        if (!data.inEdit) {
          return (
            <td>
              {data.CustomerEmail.startsWith("cnv_") || data.CustomerEmail.startsWith("msg_") ? <a
                target="_blank"
                style={{ color: '#007bff' }}
                href={`https://app.frontapp.com/open/${data.CustomerEmail}`}
              >
                {data.CustomerEmail}
              </a> : data.CustomerEmail}
            </td>
          );
        }

        const emails = data.emailAddresses.map(x => x.Email);
        return (
          <td>
            <ComboBox
              style={{ width: "100%" }}
              onChange={this.handleChange.bind(this)}
              allowCustom={true}
              placeholder={"someone@company.com"}
              value={data.CustomerEmail}
              data={emails}
              clearButton={false}
            />
          </td>
        );
      }

      private handleChange(e: ComboBoxChangeEvent) {
        if (this.props.onChange) {
          this.props.onChange({
            dataItem: this.props.dataItem,
            dataIndex: this.props.dataIndex,
            field: this.props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value ? e.target.value : null
          });
        }
      }
    }
    this.EmailDropDownCell = EmailDropDownCell;

    class PositionDropDownCell extends React.Component<GridCellProps> {

      private checkCallOptions = [
        { text: 'None', value: 0 },
        { text: '15 Mins', value: 15 },
        { text: '30 Mins', value: 30 },
        { text: '1 Hour', value: 60 },
        { text: '2 Hours', value: 120 },
        { text: '4 Hours', value: 240 }
      ]

      public render() {
          if (!this.props.field) return null;

          const value = this.props.dataItem[this.props.field];

          if (!this.props.dataItem.inEdit) {
              return (
                  <td>
                    {(value === null) ? '' : this.intervalLabel(this.props.dataItem[this.props.field])}
                  </td>
              );
          }

          return (
              <td>
                  <DropDownList
                      style={{ width: "100px" }}
                      onChange={this.handleChange.bind(this)}
                      value={this.checkCallOptions.find(x => x.value == value)}
                      data={this.checkCallOptions}
                      textField="text"
                  />
              </td>
          );
      }
      private handleChange(e: DropDownListChangeEvent) {
        if (this.props.onChange) {
          this.props.onChange({
              dataItem: this.props.dataItem,
              dataIndex: this.props.dataIndex,
              field: this.props.field,
              syntheticEvent: e.syntheticEvent,
              value: e.target.value.value
          });
        }
      }

      private intervalLabel(minuteInterval: number) {
        switch(minuteInterval)
        {

          case 0:
            return 'None';
          case 60:
            return '1 Hour'
          case 120:
            return '2 Hours';
          case 240:
            return '4 Hours';
          default:
            return minuteInterval + ' Mins'
        }
      }
    }
    this.PositionDropDownCell = PositionDropDownCell;

    class CheckboxCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];

        if (!this.props.dataItem.inEdit) {
          return (
            <td className="text-center">
              {(value === null || !this.props.dataItem[this.props.field]) ? '' : <SvgIcon icon={checkIcon} />}
            </td>
          );
        }

        return (
          <td>
            <input
              type="checkbox"
              checked={this.props.dataItem[this.props.field]}
              onChange={this.handleChange.bind(this)}
            />
          </td>
        );
      }

      private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onChange) {
          this.props.onChange({
            dataItem: this.props.dataItem,
            dataIndex: this.props.dataIndex,
            field: this.props.field,
            syntheticEvent: e,
            value: e.target.checked
          });
        }
      }
    }
    this.CheckboxCell = CheckboxCell;
  }

  public componentWillMount() {
    fetchApi(this.props.Link.Link)
      .then((response: OrderNotificationsResponse) => {
        const data = response.OrderNotifications.slice(0);
        this.setState({
          data,
          EmailAddresses: response.EmailAddresses,
          Commands: response.Commands,
          OrderNotifications: response.OrderNotifications,
        });
      })
  }

  public render() {
    return (
      <Dialog title="Setup Notifications" onClose={this.props.CloseDialog}>
        <Grid
          data={this.state.data}
          onItemChange={this.itemChange}
          editField="inEdit"
        >
          <GridToolbar>
            <Button
              themeColor="primary"
              title="Add new"
              onClick={this.addNew}
            >Add new
            </Button>
            {this.state.data.filter(p => p.inEdit).length > 0 && (
              <Button
                title="Cancel current changes"
                onClick={this.cancelCurrentChanges}
              >Cancel current changes
              </Button>
            )}
          </GridToolbar>
          <Column field="CustomerEmail" title="Email / Thread" cell={this.EmailDropDownCell} />
          <Column field="SendPositionNotificationMinuteInterval" title="Check Calls" cell={this.PositionDropDownCell} />
          <Column field="SendArrivedShipperNotification" title="Arrived Shipper" editor="boolean" cell={this.CheckboxCell} />
          <Column field="SendLoadedNotification" title="Loaded" editor="boolean" cell={this.CheckboxCell} />
          <Column field="SendArrivedConsigneeNotification" title="Arrived Consignee" editor="boolean" cell={this.CheckboxCell} />
          <Column field="SendEmptiedNotification" title="Emptied" editor="boolean" cell={this.CheckboxCell} />
          <Column field="SendArrivedBorderNotification" title="Arrived Border" editor="boolean" cell={this.CheckboxCell} />
          <Column field="SendDepartedBorderNotification" title="Departed Border" editor="boolean" cell={this.CheckboxCell} />
          <Column field="CreatedByUserName" title="By" editable={false} />
          <Column cell={this.CommandCell} width="160px" />
        </Grid>
      </Dialog>
    );
  }

  private enterEdit = (dataItem: OrderNotification) => {
    this.setState({
      data: this.state.data.map(item =>
        item.EmailOrderNotificationID === dataItem.EmailOrderNotificationID ?
        { ...item, inEdit: true, emailAddresses: this.state.EmailAddresses } : item
      )
  });
  }

  private remove = (dataItem: OrderNotification) => {
    const data = [ ...this.state.data ];
    const OrderNotifications = [ ...this.state.OrderNotifications ];
    this.removeItem(data, dataItem);
    this.removeItem(OrderNotifications, dataItem);

    this.setState({ data, OrderNotifications });

    // Delete on server
    if (dataItem.EmailOrderNotificationID) {
      const command = this.state.Commands.find(x => x.Name === 'Delete') as ILink;
      fetchApi(command.Link, { EmailOrderNotificationID: dataItem.EmailOrderNotificationID }, command.Method);
    }
  }

  private add = (dataItem: OrderNotification) => {

    // TODO: Loading?

    const command = this.state.Commands.find(x => x.Name === 'Add') as ILink;
    fetchApi(command.Link, dataItem, command.Method)
      .then((response: { EmailOrderNotificationID: number }) => {

        dataItem.inEdit = undefined;
        dataItem.EmailOrderNotificationID = response.EmailOrderNotificationID;

        const OrderNotifications = this.state.data.slice();
        OrderNotifications.unshift(dataItem);
        this.setState({
          data: [ ...this.state.data ],
          OrderNotifications
        });
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to add!');
      });
  }

  private discard = (dataItem: OrderNotification) => {
    const data = [ ...this.state.data ];
    this.removeItem(data, dataItem);

    this.setState({ data });
  }

  private update = (dataItem: OrderNotification) => {
    const data = [ ...this.state.data ];
    const OrderNotifications = [ ...this.state.OrderNotifications ];
    
    const updatedItem = { ...dataItem, inEdit: undefined } as OrderNotification;

    this.updateItem(data, updatedItem);
    this.updateItem(OrderNotifications, updatedItem);

    this.setState({ data });

    // Update on Server
    const command = this.state.Commands.find(x => x.Name === 'Update') as ILink;
    fetchApi(command.Link, updatedItem, command.Method)
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to update!');
      });
  }

  private cancel = (dataItem: OrderNotification) => {
    const originalItem = this.state.OrderNotifications.find(p => p.EmailOrderNotificationID === dataItem.EmailOrderNotificationID);
    const data = this.state.data.map(item => item.EmailOrderNotificationID === originalItem.EmailOrderNotificationID ? originalItem : item);

    this.setState({ data });
  }

  private updateItem = (data: OrderNotification[], item: OrderNotification) => {
    let index = data.findIndex(p => p === item || (item.EmailOrderNotificationID && p.EmailOrderNotificationID === item.EmailOrderNotificationID));
    if (index >= 0) {
      data[index] = { ...item };
    }
  }

  private itemChange = (event: GridItemChangeEvent) => {
    const data = this.state.data.map(item =>
      item.EmailOrderNotificationID === event.dataItem.EmailOrderNotificationID ?
      { ...item, [event.field]: event.value } : item
    );

    this.setState({ data });
  }

  private addNew = () => {

    // Prevent adding two at once...
    if (this.state.data.find(x => x.EmailOrderNotificationID === 0)) return;

    const newDataItem = {
      EmailOrderNotificationID: 0,
      CustomerEmail: '',
      SendPositionNotificationMinuteInterval: 0,
      SendArrivedShipperNotification: true,
      SendArrivedConsigneeNotification: true,
      SendLoadedNotification: true,
      SendEmptiedNotification: true,
      SendArrivedBorderNotification: false,
      SendDepartedBorderNotification: false,
      inEdit: true,
      emailAddresses: this.state.EmailAddresses,
    };

    this.setState({
        data: [ newDataItem, ...this.state.data ]
    });
  }

  private cancelCurrentChanges = () => {
    this.setState({ data: [ ...this.state.OrderNotifications ] });
  }

  private removeItem(data: OrderNotification[], item: OrderNotification) {
    let index = data.findIndex(p => p === item || item.EmailOrderNotificationID && p.EmailOrderNotificationID === item.EmailOrderNotificationID);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }
}
