import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridDetailRowProps, GridEvent, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { fetchApi } from '../../../services/api';
import { openWindowPerc } from '../../../services/openWindow';
import { TotalChargesCell, TotalChargesHeaderCell } from '../TotalChargesCell';
import { FilterGroupByOption, InvoiceBatchSummary, InvoiceCustomerSummary, InvoiceGatewayContext, PostDataFilter, PostDataState, UpdateStatusResponse } from './InvoiceGateway';
import InvoiceGatewayCommunicationPartnerComboBox from './InvoiceGatewayCommunicationPartnerComboBox';
import InvoiceGatewayCustomerMultiColumnComboBox from './InvoiceGatewayCustomerMultiColumnComboBox';
import InvoiceGatewayInvoiceDetailComponent from './InvoiceGatewayInvoiceDetailComponent';

type props = {
  Filter: PostDataFilter,
  InvoiceBatchSummary: InvoiceBatchSummary
};

interface InvoiceCustomerSummaryCommandCellProps extends GridCellProps {
  sendBatch: (item: InvoiceCustomerSummary) => void;
}

const InvoiceCustomerSummaryCommandCell = (props: InvoiceCustomerSummaryCommandCellProps) => {
  let dataItem: InvoiceCustomerSummary = props.dataItem;

  return (
    <td className="k-command-cell">
      <Button themeColor={"secondary"} onClick={() => props.sendBatch(dataItem)}>
        Send Customer Batch
      </Button>
    </td>
  );
};

const CustomerNameCell = (props: GridCellProps) => {
  const invoiceGatewayContext = useContext(InvoiceGatewayContext);

  if (!props.field)
    return null;

  let dataItem: InvoiceCustomerSummary = props.dataItem;

  //var invoiceGatewayPartner = invoiceGatewayContext.invoiceGatewayPartners?.find(x => x.CustomerID === dataItem.CustomerID);
  //<Tooltip position={'bottom'}>
  //  <div title={`${invoiceGatewayPartner.TransferMethodValue} | ${invoiceGatewayPartner.CommunicationPartner}`}>
  //    {invoiceGatewayPartner.Name} ({invoiceGatewayPartner.CustomerNumber})
  //  </div>
  //</Tooltip>
  //return (
  //  <td colSpan={props.colSpan} style={props.style}>
  //    <Tooltip position={'bottom'}>
  //      <div title={`${dataItem.TransferMethodValue}`}>
  //        {dataItem.CustomerName}
  //      </div>
  //    </Tooltip>
  //  </td>
  //);
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>
        <a href="#" style={{ color: "#338aff" }} tabIndex={-1} onClick={(e) => {
          e.preventDefault();
          openWindowPerc(`/Customers/Customer/${dataItem.CustomerID}`, .75, .75);
        }}>{dataItem.CustomerName} ({dataItem.CustomerNumber})</a>
      </div>
    </td>
  );
};


const InvoiceGatewayCustomerSummaryDetailComponent = (props: props) => {
  const invoiceGatewayContext = useContext(InvoiceGatewayContext);
  const [loading, setLoading] = useState(false);
  const render = useRef(true);

  const DataState_Take = 50;
  const initialPropsPostDataState = {
    skip: 0,
    take: DataState_Take,
    filters: { ...props.Filter, PostBatchID: props.InvoiceBatchSummary.InvoicePostBatchID, GroupBy: FilterGroupByOption.PostBatchAndCustomer }
  } as PostDataState

  const defaultPostDataState = {
    skip: 0,
    take: DataState_Take,
    filters: { ...props.Filter, PostBatchID: props.InvoiceBatchSummary.InvoicePostBatchID, GroupBy: FilterGroupByOption.PostBatchAndCustomer, CustomerID: null }
  } as PostDataState

  const [postDataState, setPostDataState] = useState<PostDataState>({ ...initialPropsPostDataState });

  const totalRecords = useRef(0);


  const [invoiceCustomerSummary, setInvoiceCustomerSummary] = useState<InvoiceCustomerSummary[]>([]);

  const fetchMain = useCallback((append: boolean, _postDataState: PostDataState = null) => {
    setLoading(true);


    //console.log(3);

    const dataGridState = _postDataState == null ? { ...postDataState } : { ..._postDataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      //document.querySelector('.InvoiceGatway-andFilter .k-grid-content').scrollTop = 0;
      setPostDataState(dataGridState);
    }

    //sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(dataGridState));

    fetchApi(`/api/Billing/GetInvoiceGatewayInvoiceBatches`, dataGridState, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          if (Data.length === 1 && (dataGridState.filters.OrderNumber > 0 || dataGridState.filters.PostBatchID > 0))
            Data[0].Expanded = true;
          setInvoiceCustomerSummary(Data);
        } else {
          setInvoiceCustomerSummary(prevData => prevData.concat(Data));
        }
        setLoading(false);
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      });
  }, [postDataState]);

  useEffect(() => {
    //console.log(postDataState);
    if (render.current) {
      render.current = false;
    } else {
      return;
    }
    fetchMain(false);
  }, [fetchMain, postDataState]);


  //useMessage('refresh', (send, payload) => {
  //  fetchMain(false);
  //});

  const search = useDebouncedCallback(useCallback((o: PostDataState) => {
    fetchMain(false, o);
  }, [fetchMain]), 750);

  const SaveAndFetchPostDataState = (o: PostDataState) => {
    invoiceCustomerSummary.filter(x => x.Expanded === true).forEach((item, index) => { item.Expanded = false });
    setInvoiceCustomerSummary([...invoiceCustomerSummary]);

    setPostDataState(o);
    search(o);
  }

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (invoiceCustomerSummary.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      postDataState.skip = postDataState.skip + postDataState.take;

      fetchMain(true);
    }
  }

  const setBatchAsInProgress = (dataItem: InvoiceCustomerSummary) => {
    setLoading(true);

    const isExpanded = dataItem.Expanded;
    if (isExpanded)
      expandGrid(dataItem, false);

    const data = {
      //InvoiceIDs: dataItem.Details.map(x => x.InvoiceID),
      PostBatchNumber: dataItem.InvoicePostBatchID,
      CustomerID: dataItem.CustomerID,
      LocationID: dataItem.LocationID,
      APICredID: dataItem.APICredID,
      Status: 19, //processing (generic), server will perform the switch  //todo add enum
      InvoiceStatusRecordType: postDataState.filters?.InvoiceStatusRecordType,
      InvoiceEmailRecord: dataItem.InvoiceEmailRecord
    };

    fetchApi(`/api/billing/UpdateInvoiceGatewayCustomerBatchStatus`, data, 'POST')
      .then((resp: UpdateStatusResponse[]) => {

        if (isExpanded)
          expandGrid(dataItem, true);

        // setInvoiceCustomerSummary([...invoiceCustomerSummary]);
      }).catch(async e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      }).finally(() => setLoading(false));
  }

  const expandChange = (event: GridExpandChangeEvent) => {

    let _eventDataInvoiceSummary = event.dataItem as InvoiceCustomerSummary;
    expandGrid(_eventDataInvoiceSummary, event.value);
    //_eventDataInvoiceSummary.Expanded = event.value;
    //setInvoiceCustomerSummary([...invoiceCustomerSummary]);

    //if (!event.value || event.dataItem.Details) {
    //  return;
    //}

    //fetchInvoiceDetails(_eventDataInvoiceSummary.CustomerID, _eventDataInvoiceSummary.InvoicePostBatchID);

  };

  const expandGrid = (dataItem: InvoiceCustomerSummary, expand: boolean) => {
    dataItem.Expanded = expand;
    setInvoiceCustomerSummary([...invoiceCustomerSummary]);
  }

  const detail = useCallback((props: GridDetailRowProps) => {
    const dataItem: InvoiceCustomerSummary = props.dataItem;
    return (
      <InvoiceGatewayInvoiceDetailComponent
        Filter={{ ...postDataState.filters }}
        InvoiceCustomerSummary={dataItem}

      />
    );
  }, [postDataState.filters]);

  return (<div className="" style={{ position: "relative" }}>
    {loading && <CenterDivPanel>
      <div className="k-loading-image" />
    </CenterDivPanel>}
    {/*<Title string="Invoice Gateway" />*/}
    {/*<h5 className="text-left">Invoice Gateway Customer Batches</h5>*/}
    {/*<InvoiceGatewayContext.Provider value={{*/}
    {/*  MarkAsAccepted: (e) => { alert(e.CustomerName) }*/}
    {/*}}>*/}
    <Grid
      //className='InvoiceGatway-andFilter'
      style={{
        maxHeight: `700px`,
      }}
      data={invoiceCustomerSummary}
      total={totalRecords.current}
      onScroll={scrollHandler}
      resizable={true}
      detail={detail}
      expandField="Expanded"
      onExpandChange={expandChange}
    >
      <GridToolbar size="small">

        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          title="Refresh"
          size="small"
          onClick={() => fetchMain(false)}
        />

        <InvoiceGatewayCustomerMultiColumnComboBox
          preSelectedInvoiceGatewayPartner={{ CustomerId: postDataState.filters.CustomerID, UsesInvoiceEmailModule: postDataState.filters.UsesInvoiceEmailModule }}
          selectedInvoiceGatewayPartner={(e, f) => SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, CustomerID: e, UsesInvoiceEmailModule: f } })}
          invoiceGatewayPartners={invoiceGatewayContext.invoiceGatewayPartners}
        />
        <InvoiceGatewayCommunicationPartnerComboBox
          preSelectedId={postDataState.filters.CommunicationID}
          selectedId={(e) => SaveAndFetchPostDataState({ ...postDataState, filters: { ...postDataState.filters, CommunicationID: e } })}
          invoiceGatewayCommunicationPartners={invoiceGatewayContext.invoiceGatewayCommunicationPartners}
        />

        <Button
          title="Clear All Filters and Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={() => {
            SaveAndFetchPostDataState({ ...defaultPostDataState });
          }}
        />
      </GridToolbar>
      <Column field="CustomerName" title="Customer" cell={CustomerNameCell} />
      {/*<Column field="InvoicePostBatchID" title="Post Batch Number" headerCell={TotalChargesHeaderCell} className="text-right" />*/}
      <Column field="TransferMethodValue" title="Transfer Method" />
      <Column field="TotalCharges" title="Total Charges" headerCell={TotalChargesHeaderCell} cell={TotalChargesCell} />
      <Column field="InvoiceCount" title="Total Invoices" headerCell={TotalChargesHeaderCell} className="text-right" />
      <Column field="InvoiceProcessedCount" title="Invoices Sent" headerCell={TotalChargesHeaderCell} className="text-right" />
      <Column field="InvoiceFailureCount" title="Invoices w/Errors" headerCell={TotalChargesHeaderCell} className="text-right" />
      <Column width={155} cell={(props: GridCellProps) => <InvoiceCustomerSummaryCommandCell {...props} sendBatch={(e) => { setBatchAsInProgress(e); }} />} />
    </Grid>
    {/*</InvoiceGatewayContext.Provider>*/}
    {
      totalRecords.current > 0 &&
      <div className="k-pager k-pager-md k-grid-pager">
        <div style={{ marginLeft: "auto", marginRight: 0 }}>
          {postDataState.skip + postDataState.take > totalRecords.current ? totalRecords.current : postDataState.skip + postDataState.take} of {totalRecords.current} items
        </div>
      </div>
    }
  </div>);
}

export default InvoiceGatewayCustomerSummaryDetailComponent;
