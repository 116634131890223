import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { EditableField } from "../../components/EditableField";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import { AddressViewModel } from '../../types/address';
import { EditableAddress } from "../../components/EditableAddress";
import { BrokerNavBar } from "./BrokerNavBar";

type RouteComponentParams = {
    brokerId: string;
};

type BrokerProfileType = {
    Broker: BrokerType
}

type BrokerType = {
    BrokerName: string;
    PrimaryPhoneNumber: string;
    SecondaryPhoneNumber: string;
    Fax: string;
    WebsiteUrl: string;

    Address: AddressViewModel;
    AuditInfo: AuditInfoViewModel;
}

const BrokerProfile = () => {

    const { brokerId: brokerIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState<BrokerProfileType>();

    const fetchProfile = useCallback(() => {
        async function fetchData() {
            setLoading(true);
            await fetchApi(`/api/Customer/CustomsBroker/${brokerIdParam}`)
                .then(data => {
                    setLoading(false);
                    setProfile(data);

                })
                .catch(err => {
                    alert(err);
                    setLoading(false);
                });
        }
        fetchData();
    }, [brokerIdParam]);


    useEffect(() => {
        fetchProfile();
    }, [brokerIdParam, fetchProfile]);


    const saveProfile = (value: Partial<BrokerType>): Promise<void> => {
        return fetchApi(`/api/Customer/CustomsBroker/${brokerIdParam}`, updateProfileState(value), 'PUT');
    }

    const saveAsyncProfile = async (value: Partial<BrokerType>): Promise<void> => {
        const newProfile: BrokerType = Object.assign({}, profile.Broker, value);
        return fetchApi(`/api/Customer/CustomsBroker/${brokerIdParam}`, newProfile, 'PUT');
    }

    const updateProfileState = (value: Partial<BrokerType>): BrokerType => {
        const newProfile: BrokerType = Object.assign({}, profile.Broker, value);
        setProfile({ ...profile, Broker: newProfile });
        return newProfile;
    }

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <div className="row m-2">
        <div className="col-md-5">
            <div className="form-group row">
                <label htmlFor="BrokerName" className="col-sm-2 col-form-label">Broker Name</label>
                <div className="col-sm-10">
                    <EditableField
                        required
                        dataType="string"
                        inputProps={{ maxLength: 30 }}
                        data={profile.Broker.BrokerName}
                        save={(data) => saveProfile({ BrokerName: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="Address" className="col-sm-2 col-form-label">Address</label>
                <div className="col-sm-10">
                    <EditableAddress
                        title="Address"
                        data={profile.Broker.Address}
                        save={(data) => saveProfile({ Address: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="PrimaryPhoneNumber" className="col-sm-2 col-form-label">Primary Phone Number</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="phone"
                        data={profile.Broker.PrimaryPhoneNumber ?? ""}
                        save={(data) => saveProfile({ PrimaryPhoneNumber: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="SecondaryPhoneNumber" className="col-sm-2 col-form-label">Secondary Phone Number</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="phone"
                        data={profile.Broker.SecondaryPhoneNumber ?? ""}
                        save={(data) => saveProfile({ SecondaryPhoneNumber: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="Fax" className="col-sm-2 col-form-label">Fax Number</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="phone"
                        data={profile.Broker.Fax ?? ""}
                        save={(data) => saveProfile({ Fax: data })}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="WebsiteUrl" className="col-sm-2 col-form-label">Web Address</label>
                <div className="col-sm-10">
                    <EditableField
                        dataType="string"
                        inputProps={{ maxLength: 300, placeholder: 'https://...' }}
                        data={profile.Broker.WebsiteUrl}
                        save={(data) => saveProfile({ WebsiteUrl: data })}
                    />
                </div>
            </div>
        </div>
        <div className="col-md-7">
            <br />
            <AuditInfo audit={profile.Broker.AuditInfo} />
            <br />
        </div>
    </div>

    return (<>
        <BrokerNavBar id={brokerIdParam} currentTab="Profile" />
        <Title string={profile?.Broker?.BrokerName} />
        {loading || !profile ? loadingView : dataView()}
    </>);
}

export default BrokerProfile;