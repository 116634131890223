import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';
import { VehicleValue } from '../Vehicles';
import PlannedOrdersVehicle from 'views/Track/PlannedOrdersVehicle';

const VehicleNumber = (props: GridCellProps) => {
    const [showPlannedOrdersVehicle, setShowPlannedOrdersVehicle] = React.useState(false);

    const dataItem = props.dataItem as VehicleValue;

    return (
        <td>
            {showPlannedOrdersVehicle && <PlannedOrdersVehicle vehicleId={dataItem.VehicleID} onClose={() => setShowPlannedOrdersVehicle(false)} />}
            {dataItem.Links.find((x: ILink) => x.Name === 'VehicleProfile') ? <a
                href="#"
                style={{ color: '#007bff' }}
                onClick={(e) => {
                    openWindow(dataItem.Links.filter((x: ILink) => x.Name === 'VehicleProfile')[0].Link);
                    e.preventDefault();
                }}
            >{dataItem.VehicleNumber}{dataItem.IsTeam ? '-T' : ''}
            </a> : dataItem.VehicleNumber} &nbsp;
            {dataItem.Links.find((x: ILink) => x.Name === 'VehiclePlanned') && <a
                href="#"
                style={{ color: '#007bff' }}
                onClick={(e) => {
                    setShowPlannedOrdersVehicle(true);
                    e.preventDefault();
                }}
            >
                Planned
            </a>}
        </td>
    );
}
export default React.memo(VehicleNumber, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);