import * as React from 'react';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { formatNumber } from '@progress/kendo-intl';
import { fetchApi } from '../../services/api';
import Checkbox from '../../components/Checkbox';
import { CarrierTierValue } from "TypeGen/carrier-tier-value";
import { CarrierStatus } from 'TypeGen/carrier-status';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { AutoComplete, AutoCompleteChangeEvent, DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { carrierOfferExpiryMinutes } from './OfferMultiCarrier';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { debounce } from 'ts-debounce';
import { SvgIcon } from "@progress/kendo-react-common";
import { starIcon } from '@progress/kendo-svg-icons';

interface Props {
  quoteID: number;
  orderStatus: number | null;
  rate: number;
  margin: number;
  note: string;
  refresh: () => void;
  onClose: () => void;
}

interface State {
  loading: boolean;
  selectedCarrier: AssetCarrierViewModel | null;
  searchOptions: AssetCarrierViewModel[];
  searchLoading: boolean;
  ValidationRequestHash: string;
  note: string;
  confirmed: boolean;
  margin: number;
  expiresInMinutes: number;
}

export class OfferCarrier extends React.Component<Props, State> {

  private CarrierSearchInput: React.RefObject<AutoComplete>;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      selectedCarrier: null,
      searchOptions: [],
      searchLoading: false,
      ValidationRequestHash: '',
      note: props.note,
      confirmed: props.orderStatus == 100,
      margin: props.margin,
      expiresInMinutes: 15
    };

    this.CarrierSearchInput = React.createRef();

    this.getRate = this.getRate.bind(this);
    this.offerCarrier = this.offerCarrier.bind(this);
    this.offerLoadBoard = this.offerLoadBoard.bind(this);
  }

  public componentDidMount() {
    if (this.CarrierSearchInput.current) {
      this.CarrierSearchInput.current.focus();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.note != prevProps.note) {
      this.setState({ note: this.props.note });
    }
  }

  render() {
    return <Dialog title="Offer Carriers" className='dialog-w800' onClose={this.props.onClose}>
      <div className="form-group">
        <label htmlFor="offer-carrier-name">Carrier Name</label>
        <AutoComplete
          ref={this.CarrierSearchInput}
          data={this.state.searchOptions}
          value={this.state.selectedCarrier?.CarrierName}
          loading={this.state.searchLoading}
          placeholder='Search for carrier...'
          itemRender={this.carrierItemRender}
          onChange={(event: AutoCompleteChangeEvent) => {
            // Picked from auto complete
            if (typeof event.target.value === 'object') {
              const carrierObj = event.target.value as AssetCarrierViewModel;
              this.setState({ selectedCarrier: carrierObj });
            } else {
              this.setState({ selectedCarrier: null });

              // Update Autocomplete Suggestions
              const searchTerm = event.target.value;
              if (searchTerm.length > 2) {
                this.updateCarrierAutocompleteDebounced(searchTerm);
              }
            }
          }}
        />
      </div>
      {this.props.orderStatus == 100 && <div className="form-check">
        <Checkbox
          id={"confirmed"}
          className="form-check-input"
          defaultValue={this.state.confirmed}
          value={this.state.confirmed.toString()}
          handleCheckboxChange={() => this.setState({ confirmed: !this.state.confirmed }) } />
        <label className="form-check-label" htmlFor="confirmed">Confirmed Load</label>
        <br /><br />
      </div>}
      <div className="form-group mb-0">
        <label htmlFor="margin">% of Revenue&nbsp;
          <NumericTextBox
            id="margin"
            format="p"
            value={this.state.margin}
            onChange={(e) => this.setState({ margin: e.value })}
            min={0}
            max={1}
            step={0.01}
          /> can pay <b>{formatNumber(this.getRate(), "c0")}</b>
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="offerNote">Note</label>
        <textarea
          className="form-control"
          id="offerNote"
          rows={3}
          maxLength={240}
          placeholder="This note will be sent to the carrier with the request for quote... (Optional)"
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
        />
      </div>
      <div className="form-group mb-0">
        <label>Posting Expires In</label>
        <DropDownList
          style={{ width: '100%' }}
          value={carrierOfferExpiryMinutes.find(x => x.value == this.state.expiresInMinutes)}
          data={carrierOfferExpiryMinutes}
          textField="text"
          onChange={(e) => this.setState({ expiresInMinutes: e.target.value.value })}
        />
      </div>
      <DialogActionsBar>
        {this.state.selectedCarrier === null && <Button disabled={this.state.loading} themeColor="primary" onClick={this.offerLoadBoard}>Load Board</Button>}
        <Button disabled={this.state.loading} themeColor="primary" onClick={this.offerCarrier}>Offer Carrier</Button>
      </DialogActionsBar>
    </Dialog>;
  }

  private updateCarrierAutocompleteDebounced = debounce(this.updateCarrierAutocomplete, 350);

  private updateCarrierAutocomplete(searchTerm: string) {
      const requestHash = Math.random().toString(36).substring(7);
      this.setState({ searchLoading: true, ValidationRequestHash: requestHash });

      const dataGridState = {
        skip: 0,
        take: 20,
        sort: [{
          field: "CarrierName",
          dir: "asc"
        }],
        filter: {
          logic: 'and', filters: [
            { field: 'CarrierName', operator: 'contains', value: searchTerm },
            { field: 'CarrierStatus', operator: 'eq', value: CarrierStatus.Active },
            { field: "Active", operator: "eq", value: true }
          ]
        }
      } as DataSourceRequestState;
      const queryStr = toDataSourceRequestString(dataGridState);
      fetchApi(`/api/Asset/GetAssetCarriers?${queryStr}`, {}, 'POST')
        .then(({ Data }: { Data: AssetCarrierViewModel[] }) => {
          if (this.state.ValidationRequestHash === requestHash) {
            this.setState({ searchLoading: false, searchOptions: Data });
          }
        });
  }

  private carrierItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <>
      {itemProps.dataItem.CarrierName}
      {itemProps.dataItem.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
      : itemProps.dataItem.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
      : itemProps.dataItem.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" /> : null}
    </>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  private getRate() {
    const rate = this.props.rate * this.state.margin;
    return Math.floor(rate / 5) * 5;
  }

  private offerCarrier()
  {
    const data = {
      QuoteID: this.props.quoteID,
      CarrierID: this.state.selectedCarrier?.CarrierID,
      BookItRate: this.getRate(),
      Confirmed: this.state.confirmed,
      Note: this.state.note,
      ExpiresInMinutes: this.state.expiresInMinutes
    };

    if (data.CarrierID === 0) {
      alert("Please select a carrier first");
      return;
    }

    if (data.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    this.setState({ loading: true });

    fetchApi("/api/Quote/OfferCarrier", data, 'POST')
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unable to offer carrier!");
      })
      .finally(() => this.setState({ loading: false }));
  }

  private offerLoadBoard()
  {
    const data = {
      QuoteID: this.props.quoteID,
      BookItRate: this.getRate(),
      Confirmed: this.state.confirmed,
      Note: this.state.note,
      ExpiresInMinutes: this.state.expiresInMinutes
    };

    if (data.QuoteID === 0) {
      alert("Please load/create quote first!");
      return;
    }

    this.setState({ loading: true });

    fetchApi("/api/Quote/OfferCarrier", data, 'POST')
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert("Unable to offer load board!");
      })
      .finally(() => this.setState({ loading: false }));
  }
}
