import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { TrailerNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

type RouteComponentParams = {
    trailerId: string;
};

type NotesType = {
    Notes: NoteType[],
    Links: ILink[];
}

type NoteType = {
    NoteID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;
    Note: string;
}

const TrailerNotes = () => {

    const { trailerId: trailerIdParam } = useParams<RouteComponentParams>();
    const [loading, setLoading] = useState(true);
    const [newNote, setNewNote] = useState<string>('');
    const [notes, setNotes] = useState<NotesType>();

    const refresh = useCallback(() => {
        setLoading(true);
        fetchApi(`/api/Asset/TrailerNotes/${trailerIdParam}`)
            .then(data => {
                    setLoading(false);
                    setNotes(data);
            })
            .catch(err => {
                    alert(err);
                    setLoading(false);
            });
    }, [trailerIdParam]);

    useEffect(() => {
        refresh();
    }, [trailerIdParam, refresh]);

    const saveNote = () => {
        setLoading(true);
        const data = {
            Note: newNote
        }
        fetchApi(`/api/Asset/AddTrailerNote/${trailerIdParam}`, data, 'POST')
            .then(() => {
                setLoading(false);
                setNewNote('');
                refresh();
            })
            .catch(() => {
                alert('An error occurred while saving the note.');
                setLoading(false);
            });
    }

    if (loading || !notes) return <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    return (<>
        <TrailerNavBar id={trailerIdParam} currentTab="Notes" />
        <Title string="Notes" />
        <form onSubmit={saveNote}>
            <TextArea
                required
                value={newNote}
                style={{ width: "100%" }}
                className="my-2"
                placeholder="New Trailer Note"
                onChange={(e) => setNewNote(e.value)}
            />
            <Button type="submit" themeColor="primary">Save Note</Button>
        </form>
        {notes.Notes.length === 0 && <h1 className="text-center">No Notes.</h1>}
        {notes.Notes.map(note => <div key={note.NoteID} className="media m-2 border-bottom">
            <div className="media-body">
                <h5 className="mt-0">{note.ModifiedByFullName}</h5>
                {note.Note}
            </div>
            <p><small>{Moment.utc(note.ModifiedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}</small></p>
        </div>)}
    </>);
}

export default TrailerNotes;