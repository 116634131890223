import * as React from 'react';
import { useParams } from 'react-router';
import SendSatMessageDialog from './SendSatMessageDialog';

type RouteComponentParams = {
    vehicleId: string;
};

const SendSatMessageRoute = () => {
    const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();

    return (<>
        <SendSatMessageDialog VehicleID={Number(vehicleIdParam)} />
    </>);
}

export default SendSatMessageRoute;