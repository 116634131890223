import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn as Column, GridFooterCellProps, GridItemChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { formatNumber } from '@progress/kendo-intl';
import ChargeType from "../Planning/Cells/ChargeType";
import Description from "../Planning/Cells/Description";
import Quantity from "../Planning/Cells/Quantity";
import QuantityUoMCell from "../Planning/Cells/QuantityUoM";
import Rate from "../Planning/Cells/Rate";
import RateUoMCell from "../Planning/Cells/RateUoM";
import PayCommandCell from "../Planning/Cells/PayCommandCell";
import { RateLineItem as RateLineItemBase } from "TypeGen/Rate/rate-line-item";
import { CalculateRateResult } from "TypeGen/Rate/calculate-rate-result";
import { RateUnitOfMeasure } from "TypeGen/rate-unit-of-measure";
import { QuantityUnitOfMeasure } from "TypeGen/quantity-unit-of-measure";
import { ViewModel as ChargeTypeViewModel } from 'TypeGen/Order/ChargeTypes/view-model';
import { fetchApi } from "services/api";
import { useEffect, useState } from "react";
import { RateDescriptionType } from "TypeGen/rate-description-type";
import { bankersRounding } from "utils/bankersRounding";
import { plusIcon } from "@progress/kendo-svg-icons";

type PayRateLineItem = {
  // For Editing
  inEdit?: boolean;
  index?: number;
} & RateLineItemBase

type Props = {
  editable?: boolean | undefined;
  showComment?: boolean | undefined;
  data: CalculateRateResult;
  onChange?: (data: CalculateRateResult) => void;
  className?: string | undefined;
}

const RateEditable = ({ data, onChange, className, editable = true, showComment = true }: Props) => {

  const [chargeTypes, setChargeTypes] = useState<ChargeTypeViewModel[]>([])

  const getChargeTypes = () => {
    fetchApi('/api/Order/GetInvoiceChargeTypes')
      .then((response: ChargeTypeViewModel[]) => {
        setChargeTypes(response);
      });
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const newLineItems = data.LineItems
      .map((item, index) => index === event.dataItem.index
        ? { ...item, [event.field]: event.value }
        : item);

    // Adjust the QuantityUoM and RateUoM if Rate changed to Fuel Surcharge Per Mile
    if (event.field === 'RateCode' && event.value === 5000) {
      newLineItems[event.dataItem.index].QuantityUoM = QuantityUnitOfMeasure.Miles;
      newLineItems[event.dataItem.index].RateUoM = RateUnitOfMeasure.Dollars;
    }

    // Adjust the QuantityUoM and RateUoM if Rate changed to Fuel Surcharge %
    if (event.field === 'RateCode' && event.value === 5001) {
      newLineItems[event.dataItem.index].QuantityUoM = QuantityUnitOfMeasure.Dollars;
      newLineItems[event.dataItem.index].RateUoM = RateUnitOfMeasure.Percent;
    }

    // Fixup the Amount
    const newLineItem = newLineItems[event.dataItem.index];
    newLineItem.Amount = bankersRounding(newLineItem.Rate * newLineItem.Quantity, 2);

    // Fixup the Total
    const newTotal = newLineItems.reduce((total, item) => total + item.Amount, 0);

    onChange({ ...data, Total: newTotal, LineItems: newLineItems });
  }

  const itemRemove = ({ index }: PayRateLineItem) => {
    const newLineItems = data.LineItems.filter((_, idx) => idx !== index);

    // Fixup the Total
    const newTotal = newLineItems.reduce((total, item) => total + item.Amount, 0);

    onChange({ ...data, Total: newTotal, LineItems: newLineItems });
  }

  const allInEdit: Array<PayRateLineItem> = data.LineItems.map((item, index) =>
    Object.assign({ inEdit: editable, index }, item)
  );

  const CommandCell = (props: GridCellProps) => (
    <PayCommandCell
      {...props}
      remove={itemRemove}
    />
  );

  const PayFooter = (props: GridFooterCellProps) => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {formatNumber(data.Total, 'C')}
      </td>
    );
  };

  const DescriptionFooter = (props: GridFooterCellProps, inEdit: boolean) => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {inEdit && <Button
          icon="add"
          svgIcon={plusIcon}
          themeColor="primary"
          onClick={() => onChange({
            ...data,
            LineItems: [
              ...data.LineItems,
              {
                RateCode: 0,
                RateType: RateDescriptionType.Flat,
                Rate: 0,
                RateUoM: RateUnitOfMeasure.Dollars,
                Quantity: 1,
                QuantityUoM: QuantityUnitOfMeasure.Flat,
                Description: "",
                Amount: 0,
              }
            ]
          })}
        >
          Add Line Item
        </Button>}
      </td>
    );
  };

  useEffect(() => {
    getChargeTypes();
  }, []);

  return <Grid
    data={allInEdit}
    editField="inEdit"
    onItemChange={itemChange}
    scrollable="none"
    className={className}
  >
    {showComment && <GridToolbar>
      <Button fillMode="link" disabled>{data.Comment}</Button>
    </GridToolbar>}
    <Column field="RateCode" title="Charge Type" editor="text" cell={(props) => ChargeType(props, chargeTypes)} footerCell={(props) => DescriptionFooter(props, editable)} />
    <Column field="Description" editor="text" cell={Description} />
    <Column field="Quantity" editor="numeric" width={120} cell={Quantity} />
    <Column field="QuantityUoM" title="Quantity UoM" width={140} cell={QuantityUoMCell} />
    <Column field="Rate" editor="numeric" width={120} cell={Rate} />
    <Column field="RateUoM" title="Rate UoM" width={140} cell={RateUoMCell} />
    <Column field="Amount" format="{0:c}" editable={false} width={80} footerCell={PayFooter} />
    {editable && <Column cell={CommandCell} width={30} />}
  </Grid>
}

export default RateEditable;