import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { TextArea } from '@progress/kendo-react-inputs';
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { useEffect, useState } from "react";
import { NewDriverPerformanceNote } from "./Performance";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Documents, { DocumentEntityType } from "views/Documents/Documents";

type Props = {
  loading: boolean;
  performanceNote: NewDriverPerformanceNote;
  noteTypes: IDNameViewModel[];
  save: (note: NewDriverPerformanceNote) => void;
  cancel: () => void;
};

const NoteEditUI: React.FC<Props> = ({ loading, performanceNote, noteTypes, cancel, save }) => {
  const [note, setNote] = useState<NewDriverPerformanceNote>(performanceNote);

  useEffect(() => {
    setNote({
      DriverNoteID: performanceNote.DriverNoteID,
      Note: performanceNote.Note,
      OccurredDate: performanceNote.OccurredDate,
      NoteType: performanceNote.NoteType
    });
  }, [performanceNote]);

  return <Dialog
    title="Performance Note"
    className="dialog-w800"
    onClose={cancel}
  >
    <form className="k-form k-form-md p-0">
      <FieldWrapper>
        <TextArea
          autoFocus
          placeholder="Note"
          disabled={note.DriverNoteID > 0}
          value={note.Note}
          onChange={e => setNote({ ...note, Note: e.target.value })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DropDownList
          disabled={note.DriverNoteID > 0}
          label="Category"
          data={noteTypes}
          textField="Name"
          dataItemKey="ID"
          value={noteTypes.find(x => x.ID == note.NoteType)}
          onChange={e => setNote({ ...note, NoteType: e.target.value.ID })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DatePicker
          disabled={note.DriverNoteID > 0}
          label="Occurred Date (optional)"
          format="MM/dd/yyyy"
          formatPlaceholder="formatPattern"
          value={note.OccurredDate}
          onChange={e => setNote({ ...note, OccurredDate: e.value })}
        />
      </FieldWrapper>
    </form>
    <br />
    {note.DriverNoteID > 0 && <Documents
      EntityType={DocumentEntityType.DriverNote}
      EntityId={note.DriverNoteID}
    />}
    <DialogActionsBar>
      <Button disabled={loading} onClick={cancel}>
        {note.DriverNoteID == 0 ? 'Cancel' : 'Close'}
      </Button>
      {note.DriverNoteID == 0 && <Button
        disabled={loading}
        themeColor={'success'}
        onClick={() => save(note)}
      >
        Create
      </Button>}
    </DialogActionsBar>
  </Dialog>
}

export default NoteEditUI;