import * as React from 'react';
import { fetchApi, fetchApiFormData } from '../../services/api';
import { Button } from '@progress/kendo-react-buttons';
import LoadingPanel from '../../components/LoadingPanel';
import { DriverValue, SelectedRowValues } from './AssetPanel';
import { Upload as KendoUpload, UploadFileInfo } from '@progress/kendo-react-upload';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Checkbox, TextBox, InputPrefix, TextArea } from '@progress/kendo-react-inputs';
import { JsonResponse } from 'TypeGen/json-response';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';

interface Props {
    SelectedRowValues: SelectedRowValues,
    SelectedDrivers: DriverValue[] | null,
    SelectedVehicles: AssetVehicleViewModel[] | null,
    CloseDialog: () => void;
}

interface State {
    notes: string;
    Filter_To_Value: "Drivers" | "Owners";
    Filter_Channel_Value: string[];
    Filter_Button_Value: string;

    makeRoboCall: boolean;
    sendTextMessage: boolean;
    sendEmail: boolean;
    sendOTMessage: boolean;
    ccTextOwner: boolean;

    isLoading: boolean;
    userPhoneExtension: string;

    requestHasVehicle: boolean;
    requestHasDriver: boolean;
    requestHasOwner: boolean;

    sentToEntityButtonLabel: string;
    ccOwnerLabel: string;
    files: UploadFileInfo[];
}

const to = [
    {
        id: "Drivers",
        name: "To: Drivers"
    },
    {
        id: "Owners",
        name: "To: Owners"
    }
]

const driverChannels = [
    {
        id: "RoboCall",
        name: "Robo Call"
    },
    {
        id: "TextMessage",
        name: "Text Message"
    },
    {
        id: "Email",
        name: "Email"
    },
    {
        id: "SatMessage",
        name: "Satellite Message"
    }
]

const ownerChannels = [
    {
        id: "TextMessage",
        name: "Text Message"
    },
    {
        id: "Email",
        name: "Email"
    }
]

// Reminder: Update AssetDriver.cs as well
const templates = [
    {
        id: "",
        name: "Select a Template (Optional)",
    },
    {
        id: "MaintenanceReport",
        name: "Maintenance Report Reminder",
        description: "Your vehicle maintenance report for last month has not been received.  Please submit as soon as possible to the safety department to avoid being placed out of service."
    },
    {
        id: "HazMatCert",
        name: "Hazmat Certification Reminder",
        description: "Your hazmat certification is about to expire, please submit your new hazmat certification to the safety department as soon as possible to avoid being placed out of service."
    },
    {
        id: "SubmitPaperwork",
        name: "Paperwork Reminder",
        description: "Please submit missing paperwork for your trips.  Check Load One Driver app for more details."
    },
    {
        id: "License",
        name: "Driver's License Reminder",
        description: "Your drivers license is about to expire.  Please submit your new drivers license to the safety department as soon as possible to avoid being placed out of service."
    },
    {
        id: "PhysicalExam",
        name: "Physical Exam Reminder",
        description: "Your physical exam is about to expire.  Please submit your new physical to the safety department as soon as possible to avoid being placed out of service."
    },
    {
        id: "FASTCard",
        name: "FAST Card Reminder",
        description: "Your fast card is about to expire.  Please submit your new fast card to the safety department as soon as possible to avoid being placed out of service."
    }
]

export class MakeRoboCall extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let hasVehicle = (this.props.SelectedDrivers.length > 0 && this.props.SelectedDrivers.some((e) => e.VehicleID > 0) || this.props.SelectedVehicles.length > 0) || this.props.SelectedRowValues.VehicleID > 0;
        let hasDriver = (this.props.SelectedVehicles.length > 0 && this.props.SelectedVehicles.some((e) => e.DriverID > 0) || this.props.SelectedDrivers.length > 0) || this.props.SelectedRowValues.DriverID > 0;
        let hasOwner = (
            this.props.SelectedDrivers.length > 0 && this.props.SelectedDrivers.some((e) => e.OwnerID > 0) ||
            this.props.SelectedVehicles.length > 0 && this.props.SelectedVehicles.some((e) => e.OwnerID > 0) ||
            this.props.SelectedRowValues.OwnerID > 0
        );

        this.state = {
            notes: '',
            Filter_To_Value: 'Drivers',
            Filter_Channel_Value: [],
            Filter_Button_Value: '',

            makeRoboCall: false,
            sendTextMessage: false,
            sendEmail: false,
            sendOTMessage: hasVehicle && !hasDriver,
            ccTextOwner: false,

            isLoading: false,
            userPhoneExtension: '',

            requestHasVehicle: hasVehicle,
            requestHasDriver: hasDriver,
            requestHasOwner: hasOwner,

            sentToEntityButtonLabel: '',
            ccOwnerLabel: '',
            files: [],
        }

        this.sendCall = this.sendCall.bind(this);
    }

    public componentWillMount() {
        if (this.props.SelectedDrivers.length > 1 || this.props.SelectedVehicles.length > 1) {
            this.setState({
                sentToEntityButtonLabel: "All Selected (" + parseInt(this.props.SelectedDrivers.length + this.props.SelectedVehicles.length + "") + ")",
                ccOwnerLabel: "(s)"
            });
        } else {
            if (this.props.SelectedRowValues.DriverNumber.trim().length > 0 && this.props.SelectedRowValues.VehicleNumber.trim().length > 0) {
                this.setState({
                    sentToEntityButtonLabel: this.props.SelectedRowValues.DriverNumber + "/" + this.props.SelectedRowValues.VehicleNumber,
                    ccOwnerLabel: " (" + this.props.SelectedRowValues.OwnerName + ")"
                });
            } else {
                let sentToEntityButtonLabel: string = "";
                if (this.props.SelectedRowValues.DriverNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.DriverNumber;
                if (this.props.SelectedRowValues.VehicleNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.VehicleNumber;
                this.setState({
                    sentToEntityButtonLabel: sentToEntityButtonLabel,
                    ccOwnerLabel: " (" + this.props.SelectedRowValues.OwnerName + ")"
                });
            }
        }
    }

    public render() {
        const channels = this.state.Filter_To_Value === "Drivers" ? driverChannels : ownerChannels;
        return <Dialog title="Send Message" onClose={this.props.CloseDialog} className="dialog-w800">
                {this.state.isLoading && <LoadingPanel />}
                <DropDownList
                    style={{ width: "100%" }}
                    data={to}
                    dataItemKey='id'
                    textField="name"
                    value={to.find((e) => e.id === this.state.Filter_To_Value)}
                    onChange={(e) => this.setState({
                        Filter_To_Value: e.value.id,
                        Filter_Channel_Value: [],
                        makeRoboCall: false,
                        sendTextMessage: false,
                        sendEmail: false,
                        sendOTMessage: false,
                        ccTextOwner: false
                    })}
                />
                <MultiSelect
                    required
                    placeholder="Please select a channel..."
                    style={{ width: "100%" }}
                    className='mt-2'
                    data={channels}
                    value={channels.filter((e) => this.state.Filter_Channel_Value.includes(e.id))}
                    onChange={(e) => {
                        this.setState({
                            Filter_Channel_Value: e.value.map((e: any) => e.id),
                            makeRoboCall: e.value.some((e: any) => e.id === 'RoboCall'),
                            sendTextMessage: e.value.some((e: any) => e.id === 'TextMessage'),
                            sendEmail: e.value.some((e: any) => e.id === 'Email'),
                            sendOTMessage: e.value.some((e: any) => e.id === 'SatMessage')
                        });
                    }}
                    dataItemKey='id'
                    textField="name"
                />
                <DropDownList
                    style={{ width: "100%" }}
                    className='mt-2'
                    data={templates}
                    value={templates.find((e) => e.id === this.state.Filter_Button_Value)}
                    onChange={(e) => this.setState({ Filter_Button_Value: e.value.id })}
                    dataItemKey='id'
                    textField="name"
                />
                {this.state.Filter_Button_Value && <p className="mt-1 mb-0">
                    {templates.find((e) => e.id === this.state.Filter_Button_Value).description}
                </p>}

                <TextArea placeholder={this.state.Filter_Button_Value ? "Additional message..." : "Message..."} className='mt-2' onChange={(e) => this.setState({ notes: e.value })} />
                {(this.state.requestHasOwner && this.state.Filter_To_Value === 'Drivers') && <FieldWrapper className='float-right mt-1'>
                    <Checkbox label={`Send Copy to Owner${this.state.ccOwnerLabel} via Text Message`} checked={this.state.ccTextOwner} onChange={(e) => this.setState({ ccTextOwner: e.value })} />
                </FieldWrapper>}

                <TextBox
                    style={{ width: 200 }}
                    className='mt-2'
                    maxLength={8}
                    value={this.state.userPhoneExtension}
                    onChange={(e) => this.setState({ userPhoneExtension: e.value.toString() })}
                    prefix={() => (<InputPrefix>
                        <Button disabled>Phone Ext.</Button>
                    </InputPrefix>)}
                />

                {this.state.sendEmail && <KendoUpload
                    className='mt-2'
                    batch={false}
                    multiple={true}
                    autoUpload={false}
                    files={this.state.files}
                    showActionButtons={false}
                    onAdd={(e) => this.setState({ files: e.newState })}
                    onRemove={(e) => this.setState({ files: e.newState })}
                />}

                <DialogActionsBar layout="end">
                    <Button onClick={this.props.CloseDialog}>Cancel</Button>
                    <Button themeColor="primary" onClick={this.sendCall} disabled=
                        {this.state.isLoading || ((!this.state.makeRoboCall && !this.state.sendTextMessage && !this.state.sendEmail && !this.state.sendOTMessage) ||
                            (this.state.Filter_Button_Value.length === 0 && this.state.notes.trim().length === 0))
                        }>Send to {this.state.sentToEntityButtonLabel}</Button>
                </DialogActionsBar>
            </Dialog>;
    }

    private sendCall() {

        this.setState({
            isLoading: true
        });

        //let notes: string = ;
        let buttonClicked: string = this.state.Filter_Button_Value.length > 0 ? this.state.Filter_Button_Value : null;

        if (this.state.notes.length > 0 || buttonClicked != null) {

            let vehicleIds: number[] = [];
            let driverIds: number[] = [];
            let ownerIds: number[] = [];
            if (this.props.SelectedDrivers.length > 0) {
                this.props.SelectedDrivers.forEach(function (item) {
                    driverIds.push(item.DriverID);
                    if (item.OwnerID > 0) ownerIds.push(item.OwnerID);
                    if (item.VehicleID > 0) vehicleIds.push(item.VehicleID);
                });
            } else if (this.props.SelectedVehicles.length > 0) {
                this.props.SelectedVehicles.forEach(function (item) {
                    vehicleIds.push(item.VehicleID);
                    if (item.OwnerID > 0) ownerIds.push(item.OwnerID);
                    if (item.DriverID > 0) driverIds.push(item.DriverID);
                });
            } else {
                if (this.props.SelectedRowValues.DriverID > 0)
                    driverIds.push(this.props.SelectedRowValues.DriverID);
                if (this.props.SelectedRowValues.VehicleID > 0)
                    vehicleIds.push(this.props.SelectedRowValues.VehicleID);
                if (this.props.SelectedRowValues.OwnerID > 0)
                    ownerIds.push(this.props.SelectedRowValues.OwnerID);
            }

            if (this.state.makeRoboCall) {
                const data = {
                    AssetDriverMessage: buttonClicked,
                    AdditionalNotes: this.state.notes,
                    DriverIDs: driverIds,
                    UserPhoneExtension: this.state.userPhoneExtension
                };
                fetchApi('/api/Asset/CallAssetDriver', data, 'POST').then(() => {
                    this.props.CloseDialog();
                }).catch(() => {
                    this.setState({
                        isLoading: false
                    });
                    alert('Unable to make robo call!');
                });
            }

            if (this.state.sendTextMessage || this.state.ccTextOwner || this.state.sendEmail) {
                var formData = new FormData();
                if (this.state.Filter_To_Value === 'Drivers') {
                    formData.append('FormDriverIDs', driverIds.join(','));

                    if (this.state.ccTextOwner) {
                        formData.append('FormVehicleIDs', vehicleIds.join(','));
                    }
                }
                if (this.state.Filter_To_Value === 'Owners') {
                    formData.append('FormOwnerIDs', ownerIds.join(','));
                }
                formData.append('AssetDriverMessage', buttonClicked);
                formData.append('AdditionalNotes', this.state.notes);
                formData.append('CCOwnerViaTextMessage', this.state.ccTextOwner ? 'true' : 'false');
                formData.append('TextMessageDriver', this.state.sendTextMessage && this.state.Filter_To_Value === 'Drivers' ? 'true' : 'false');
                formData.append('TextMessageOwner', this.state.sendTextMessage && this.state.Filter_To_Value === 'Owners' ? 'true' : 'false');
                formData.append('EmailDriver', this.state.sendEmail && this.state.Filter_To_Value === 'Drivers' ? 'true' : 'false');
                formData.append('EmailOwner', this.state.sendEmail && this.state.Filter_To_Value === 'Owners' ? 'true' : 'false');
                formData.append('GetOwnersBasedOnDriver', this.props.SelectedRowValues.IsDriverRow ? 'true' : 'false');
                formData.append('UserPhoneExtension', this.state.userPhoneExtension);
                for (let file of this.state.files) {
                    formData.append('Attachments', file.getRawFile());
                }
                fetchApiFormData('/api/Asset/SMSAssetDriver', formData, 'POST')
                    .then((response: JsonResponse) => {
                        if (!response.Success) alert(response.ErrorMessage);
                        this.props.CloseDialog();
                    }).catch((e) => {
                        this.setState({ isLoading: false });
                        // If not problem details
                        if (!e?.status) alert(e);
                    });
            }

            if (this.state.sendOTMessage) {
                const data = {
                    AssetDriverMessage: buttonClicked,
                    AdditionalNotes: this.state.notes,
                    VehicleIDs: vehicleIds,
                    UserPhoneExtension: this.state.userPhoneExtension
                };
                fetchApi('/api/Asset/OTAssetDriver', data, 'POST').then(() => {
                    this.props.CloseDialog();
                }).catch(() => {
                    this.setState({
                        isLoading: false
                    });
                    alert('Unable to send Sat. Message!');
                });
            }

        } else {
            this.setState({
                isLoading: false
            });
        }

    }

    public componentDidMount() {
        this.setState({
            isLoading: true
        });

        fetchApi('/api/User/ContactInfo')
            .then((response: any) => {
                this.setState({ isLoading: false, userPhoneExtension: response.phoneNumberExtension });
            })
    }
}
