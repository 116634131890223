import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class VehicleNumberStatus extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    switch (this.props.dataItem.Status) {
      case 'In Service':
          return <td className='inservice' title={this.props.dataItem.Status}>{this.props.dataItem.VehicleNumber}</td>;
      case 'On A Load':
          return <td className='onaload' title={this.props.dataItem.Status}>{this.props.dataItem.VehicleNumber}</td>;
      case 'Out Of Service':
          return <td className='outofservice' title={this.props.dataItem.Status}>{this.props.dataItem.VehicleNumber}</td>;
      case 'Unconfirmed In Service':
          return <td className='unconfirmedinservice' title={this.props.dataItem.Status}>{this.props.dataItem.VehicleNumber}</td>;
    }
    return <td title={this.props.dataItem.Status}>{this.props.dataItem.VehicleNumber}</td>;
  }
}
