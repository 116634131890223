import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from '@progress/kendo-react-labels';
import { useState } from 'react';
import CenterDivPanel from "../../components/CenterDivPanel";
import { fetchApi } from '../../services/api';
import { JsonResponse } from "TypeGen/json-response";
import OrderStatusNoteInput from "components/OrderStatusNoteInput";

type Props = {
    orderId: number;
    statusNote: string;
    onSave: (statusNote: string) => void;
    onClose: () => void;
}

const OrderStatusNotePopup = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [statusNote, setStatusNote] = useState(props.statusNote);

    const _save = () => {
        setLoading(true);

        const data = {
            StatusNote: statusNote,
        }

        fetchApi(`/api/Track/OrderStatusNote/${props.orderId}`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    props.onSave(statusNote);
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                // If not problem details
                if (!e?.status) alert(e);
            })
            .finally(() => setLoading(false));
    }

    return <>
        <Dialog
            className="dialog-w800"
            title={`Update Order Status Note`}
            onClose={props.onClose}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <div className="k-form k-form-md p-0">
                <FieldWrapper>
                    <Label>Status Note</Label>
                    <OrderStatusNoteInput autoFocus value={statusNote} onChange={setStatusNote} />
                </FieldWrapper>
            </div>

            <DialogActionsBar layout={"end"}>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}>
                    Save
                </Button>
            </DialogActionsBar>
        </Dialog>
    </>

}
export default OrderStatusNotePopup;