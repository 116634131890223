import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import Moment from 'moment-timezone';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomerInput, { CustomerInputValue } from '../../components/CustomerInput';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { JsonResponse } from '../../TypeGen/json-response';

type BaseProps = {
  rateMasterDescription: string;
  onClose: () => void;
};

type WithRateMasterID = BaseProps & {
  rateMasterID: number;
  rateFuelMasterID?: never; // This makes sure rateFuelMasterID is not used together with rateMasterID
};

type WithRateFuelMasterID = BaseProps & {
  rateMasterID?: never; // This ensures rateMasterID is not used together with rateFuelMasterID
  rateFuelMasterID: number;
};

type Props = WithRateMasterID | WithRateFuelMasterID;

const CopyRateMasterDialog = (props: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { alert } = useAlert();
  const [ratingToCustomer, setRatingCustomer] = useState<CustomerInputValue>();

  const today = Moment.utc().tz("America/New_York").startOf('day').toDate();
  const tomorrow = Moment.utc().tz("America/New_York").startOf('day').add(1, 'days').toDate();
  const maxDate = Moment([2999, 11, 31]).utc().tz("America/New_York").endOf('day').toDate();

  const [startDate, setStartDate] = useState<Date>(today);
  const [expiryDate, setExpiryDate] = useState<Date>(maxDate);

  const _save = async () => {
    if (Moment(startDate).startOf('day') < Moment(today).startOf('day') || Moment(startDate).startOf('day') >= Moment(expiryDate).startOf('day')) {
      await alert('Invalid date range');
      return;
    } else if (ratingToCustomer == null || ratingToCustomer.CustomerID === 0) {
      await alert('Customer ID must be populated');
      return;
    }

    setLoading(true);

    let data = {};
    let endpoint = "";
    if (props.rateMasterID > 0) {
      endpoint = "/api/Quote/CopyRateMaster"
      data = {
        RateMasterID: props.rateMasterID,
        CopyToRatingCustomerID: ratingToCustomer.CustomerID,
        StartDate: startDate,
        EndDate: expiryDate
      }
    } else {
      endpoint = "/api/Quote/CopyRateFuelMaster"
      data = {
        RateFuelMasterID: props.rateFuelMasterID,
        CopyToRatingCustomerID: ratingToCustomer.CustomerID,
        StartDate: startDate,
        EndDate: expiryDate
      }
    }

    fetchApi(endpoint, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else {
          props.onClose();
          history.push(`/Customers/Customer/${ratingToCustomer.CustomerID}/RateStructures`);
        }
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  return <Dialog title={props.rateMasterDescription} onClose={() => props.onClose()}>
    <div className="container">
      <div className="row">
        <div className="col">
          <div>
            <label className="mb-0">Copy To Rating Customer</label>
            <div className="input-group">
              <CustomerInput
                placeholder="Rating Customer"
                CustomerID={ratingToCustomer?.CustomerID}
                CustomerNumber={ratingToCustomer?.CustomerName}
                onChange={(e) => {
                  setRatingCustomer(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div>
            <label className="mb-0">Start Date</label>
            <div className="input-group">
              <SimpleDatePicker
                value={startDate}
                onChange={(e) => setStartDate(Moment(e).utc().tz("America/New_York").startOf('day').toDate())}
                min={today}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div>
            <label className="mb-0">Expiry Date</label>
            <div className="input-group">
              <SimpleDatePicker
                value={expiryDate}
                onChange={(e) => setExpiryDate(Moment(e).utc().tz("America/New_York").endOf('day').toDate())}
                min={tomorrow}
                max={maxDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogActionsBar layout={'end'}>
      <Button themeColor='primary' disabled={loading} onClick={async () => await _save()} autoFocus={true}>
        Copy Table
      </Button>
      <Button themeColor='base' onClick={() => props.onClose()}>
        Cancel
      </Button>
    </DialogActionsBar>
  </Dialog>
}

export default CopyRateMasterDialog;
