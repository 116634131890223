import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { debounce } from 'ts-debounce';
import Moment from 'moment-timezone';
import { SalesPeopleViewModel } from '.';

type DisplaySalesPeopleViewModel = {
    TerritoryID: number;
    FullName: string;
    Active?: boolean;
}

type Props = {
    onSelectedSalesperson: (salesperson: SalesPeopleViewModel) => void;
    selectedSalespersonID?: number;
    setFocus?: boolean;
    includeRecentlyRetiredSalespersons?: boolean;
} & AutoCompleteProps;

export type SalespersonAutoCompleteViewModel = SalesPeopleViewModel & { DisplayValue: string; };

export const GetSalespersonAutoCompleteDisplayValue = (salesperson: DisplaySalesPeopleViewModel, includeActiveLabel?: boolean): string => {
    if (includeActiveLabel)
        return `${salesperson.TerritoryID} (${salesperson.FullName}) ${salesperson.Active ? 'Active' : 'Retired'}`;
    return `${salesperson.TerritoryID} (${salesperson.FullName})`;
}

const SalespersonAutoComplete: React.FC<Props> = ({
    onSelectedSalesperson,
    selectedSalespersonID,
    includeRecentlyRetiredSalespersons,
    setFocus,
    ...autoCompleteProps
}) => {
    const inputAC = useRef<AutoComplete>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [salespersonDropDownListing, setSalespersonDropDownListing] = useState<SalespersonAutoCompleteViewModel[]>([]);
    const [salespersonAutoCompleteValue, setSalespersonAutoCompleteValue] = useState('');
    const _selectedSalesperson = useRef<number>(0);
    const _requestHash = useRef('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: string, requestHash: string) => {
            if (val.length > 2 && _selectedSalesperson.current === 0) {
                getSalespersonListing(generateDataStateByName(val), requestHash);
            }
        }, 750), []);

    const generateDataStateByName = (val: string): GridState => {
        let gs = {
            skip: 0,
            take: 12,
            sort: [{
                field: "FullName",
                dir: "asc"
            }],
            filter: {
                logic: 'and', filters: [
                    {
                        logic: 'or', filters: [
                            { field: 'Territory', operator: 'contains', value: val },
                            { field: 'TerritoryID', operator: 'eq', value: val },
                            { field: 'FullName', operator: 'contains', value: val }
                        ] as any
                    }
                ] as any
            }
        } as GridState;


        if (isNaN(Number(val))) {
            (gs.filter.filters as CompositeFilterDescriptor[])[0].filters = ((gs.filter.filters as CompositeFilterDescriptor[])[0].filters as FilterDescriptor[]).filter(x => x.field !== 'TerritoryID');
        }

        if (includeRecentlyRetiredSalespersons) {
            gs.filter.filters.unshift({
                logic: 'or', filters: [
                    { field: 'active', operator: 'eq', value: true },
                    {
                        logic: 'and', filters: [
                            { field: 'active', operator: 'eq', value: false },
                            { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(4, "weeks").toDate() }
                        ]
                    }
                ]
            });
        } else {
            gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
        }

        return gs;
    }

    const generateDataStateByID = (val: number): GridState => {
        return {
            skip: 0,
            take: 1,
            sort: null,
            filter: {
                logic: 'and', filters: [
                    { field: 'SalespersonID', operator: 'eq', value: val }
                ] as any
            }
        } as GridState;
    }

    const getSalespersonListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Customer/GetSalespeople?${queryStr}`, {}, 'POST')
            .then(({ Data }) => {
                let salespersonData: SalespersonAutoCompleteViewModel[] = Data;
                setLoading(false);
                if (_requestHash.current === requestHash) {
                    salespersonData.forEach(x => {
                        x.DisplayValue = GetSalespersonAutoCompleteDisplayValue(x, includeRecentlyRetiredSalespersons);
                    });
                    setSalespersonDropDownListing(salespersonData);
                    if (populateLabel)
                        setSalespersonAutoCompleteValue(GetSalespersonAutoCompleteDisplayValue(salespersonData[0], includeRecentlyRetiredSalespersons));
                }
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            })
    }, [includeRecentlyRetiredSalespersons]);

    const onSalespersonListingClose = (event: AutoCompleteCloseEvent) => {
        const value = event.target.value;
        const selectedSalesperson = salespersonDropDownListing.find(x => x.DisplayValue === value);
        setSalespersonAutoCompleteValue(selectedSalesperson != null ? value : '');
        _selectedSalesperson.current = selectedSalesperson?.SalespersonID;
        onSelectedSalesperson(selectedSalesperson ?? null);
    };

    useEffect(() => {
        if (selectedSalespersonID !== _selectedSalesperson.current) {
            if (selectedSalespersonID > 0) {
                _selectedSalesperson.current = selectedSalespersonID;
                _requestHash.current = Math.random().toString(36).substring(7);
                getSalespersonListing(generateDataStateByID(selectedSalespersonID), _requestHash.current, true);
            } else {
                _selectedSalesperson.current = 0;
                setSalespersonAutoCompleteValue('');
            }
            if (setFocus) {
                inputAC.current.focus();
            }
        }
    }, [selectedSalespersonID, getSalespersonListing, setFocus]);

    return <AutoComplete
        ref={inputAC}
        {...autoCompleteProps}
        placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Salesperson')}
        loading={loading}
        data={salespersonDropDownListing}
        value={salespersonAutoCompleteValue}
        textField="DisplayValue"
        onChange={(e) => {
            _selectedSalesperson.current = 0;
            if (!e.value)
                onSelectedSalesperson(null);
            setSalespersonDropDownListing([]);
            setSalespersonAutoCompleteValue(e.value);
            _requestHash.current = Math.random().toString(36).substring(7);
            debounceSearchInput(e.value, _requestHash.current);
        }}
        onClose={(e) => onSalespersonListingClose(e)}
    />
}

export default SalespersonAutoComplete;
