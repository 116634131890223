import { ViewModel } from "TypeGen/ShiftTurnover/view-model";
import { TurnoverNoteViewModel } from "TypeGen/ShiftTurnover/turnover-note-view-model";
import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import DateCell from "components/cells/DateCell";
import { GridLoadingPanel } from "components/GridLoadingPanel";
import TripSummary from "views/Track/TripSummary";
import { Title } from "utils/title";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { ApiLink } from "TypeGen/api-link";
import { DateInput, DateInputProps, DateRangePicker, SelectionRange } from "@progress/kendo-react-dateinputs";
import Moment from 'moment-timezone';
import { arrowRotateCwIcon, filterClearIcon } from "@progress/kendo-svg-icons";

const DateRangeStartDate = Moment().subtract(2, "days").startOf('day').toDate();
const DateRangeEndDate = Moment().add(1, "days").startOf('day').toDate();

const CustomDateInput = (props: DateInputProps) => {
  return (
    <DateInput {...props} label={undefined} width={92} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
  );
};

enum NoteType {
  General = 1,
  Billing = 2,
}

const ShiftTurnover = () => {

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<NoteType>(NoteType.General);
  const [notes, setNotes] = useState<TurnoverNoteViewModel[]>([]);
  const [serviceTeams, setServiceTeams] = useState<IDNameViewModel[]>([]);
  const [showTripSummary, setShowTripSummary] = useState(0);

  // Filters
  const [filterByDateRange, setFilterByDateRange] = useState<SelectionRange>({
    start: DateRangeStartDate,
    end: DateRangeEndDate,
  });
  const [filterAcknowledged, setFilterAcknowledged] = useState<boolean | null>(false);
  const [filterMine, setFilterMine] = useState<boolean | null>(false);
  const [filterServiceTeams, setFilterServiceTeams] = useState<number[]>([]);

  const reset = () => {
    setFilterByDateRange({
      start: DateRangeStartDate,
      end: DateRangeEndDate,
    });
    setFilterAcknowledged(false);
    setFilterMine(false);
    setFilterServiceTeams([]);
  }

  const fetchData = useCallback(() => {
    if (!filterByDateRange || !filterByDateRange.start || !filterByDateRange.end) {
      return;
    }

    setLoading(true);
    fetchApi('/api/Order/ShiftTurnover', {
      FromDate: filterByDateRange.start,
      ToDate: filterByDateRange.end,
      Type: type,
      Acknowledged: filterAcknowledged,
      Mine: filterMine,
      ServiceTeamID: filterServiceTeams
    }, 'POST')
      .then((response: ViewModel) => {
        setNotes(response.Notes);
        setServiceTeams(response.ServiceTeams);
      })
      .finally(() => setLoading(false));
  }, [filterByDateRange, type, filterAcknowledged, filterMine, filterServiceTeams, ]);

  const mark = (link: ApiLink) => {
    fetchApi(link.Link, {}, link.Method)
      .then(() => fetchData());
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <>
    <Title string="Shift Turnover" />
    <br />
    {loading && <GridLoadingPanel />}
    <Grid data={notes}>
      <GridToolbar>
        <Button
          title="Reset Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={reset}
        />
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={fetchData}
        />
        <ButtonGroup>
          <Button togglable
            selected={type === NoteType.General}
            onClick={() => setType(NoteType.General)}
          >
            General
          </Button>
          <Button togglable
            selected={type === NoteType.Billing}
            onClick={() => setType(NoteType.Billing)}
          >
            Billing
          </Button>
        </ButtonGroup>
        <Button
          togglable={true}
          selected={filterAcknowledged}
          onClick={() => setFilterAcknowledged(!filterAcknowledged)}
        >
          {filterAcknowledged ? "Acknowledged" : "Unacknowledged"}
        </Button>
        <Button
          togglable={true}
          selected={filterMine}
          onClick={() => setFilterMine(!filterMine)}
        >
          {filterMine ? "Mine" : "All"}
        </Button>
        <DateRangePicker
          style={{ width: 195, gap: "0.3em" }}
          startDateInput={CustomDateInput}
          endDateInput={CustomDateInput}
          value={filterByDateRange}
          onChange={(e) => {
            setFilterByDateRange(e.value);
            if (e.value && e.value.start && e.value.end) {
              fetchData();
            }
          }}
        />
        <ButtonGroup>
          {serviceTeams.map((teamId) => <Button
            key={teamId.ID}
            togglable={true}
            selected={filterServiceTeams.includes(teamId.ID)}
            onClick={() => {
              if (filterServiceTeams.includes(teamId.ID)) {
                setFilterServiceTeams(filterServiceTeams.filter((id) => id !== teamId.ID));
              } else {
                setFilterServiceTeams([...filterServiceTeams, teamId.ID]);
              }
            }}
          >
            {teamId.Name}
          </Button>)}
        </ButtonGroup>
      </GridToolbar>
      <Column field="OrderNumber" width={110} title="Order #" cell={(props) => <td onClick={() => setShowTripSummary(props.dataItem.OrderID)} style={{ cursor: "pointer", color: "#007bff" }}>{props.dataItem.OrderNumber}</td>} />
      <Column field="Note" />
      <Column field="ModifiedByFullName" title="Created By" width={150} />
      <Column field="ModifiedDateTime" title="Created" cell={DateCell} width={120} />
      <Column field="AcknowledgedByFullName" title="Acknowledged By" width={150} />
      <Column field="AcknowledgedDateTime" title="Acknowledged" cell={DateCell} width={120} />
      <Column title="Acknowledge" width={134} cell={(props) => {
        const acknowledge = props.dataItem.Links.find((x: ApiLink) => x.Name === "Acknowledge");
        const unacknowledge = props.dataItem.Links.find((x: ApiLink) => x.Name === "Unacknowledge");
        return <td>
          {acknowledge && <Button size="small" themeColor="success" title="Acknowledge" onClick={() => mark(acknowledge)}>Acknowledge</Button>}
          {unacknowledge && <Button size="small" themeColor="error" title="Unacknowledge" onClick={() => mark(unacknowledge)}>Unacknowledge</Button>}
        </td>;
      }} />
    </Grid>
    {showTripSummary > 0 && <TripSummary orderId={showTripSummary} close={() => setShowTripSummary(0)} />}
  </>;
}

export default ShiftTurnover;
