import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from 'react';
import { ApiLink } from "TypeGen/api-link";
import { RightAlignHeaderCell } from '../../components/cells/RightAlignHeaderCell';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import JsonPretty from "components/JsonPretty";

type Props = {
  Link: ApiLink;
  CloseDialog: () => void;
}

interface InvoiceInfo {
  InvoiceNumber: string;
  TotalCharges: number;
  FreightBillCorrectionReasonValue: string;
  FreightBillCorrectionNote: string;
  Changes: string;
  CustomerName: string;
  CustomerNumber: string;
}

const BillingUserMetricInvoiceListingDialog = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceInfo[]>();

  useEffect(() => {
    setLoading(true);
    fetchApi(props.Link.Link, {}, props.Link.Method)
      .then((response: any) => {
        setInvoices(response.Invoices);
      })
      .finally(() => setLoading(false));
  }, [props.Link]);

  const ChangesJsonPrettyCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: InvoiceInfo = props.dataItem;

    return (
      <td colSpan={props.colSpan} style={props.style} className="text-right">
        <div>
          <JsonPretty json={dataItem.Changes} />
        </div>
      </td>
    );
  };

  return <div className="container-fluid">
    <Dialog title="Invoices" onClose={props.CloseDialog} className="dialog-w11\/12" height={600}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Grid
        data={invoices}
        sortable={{
          allowUnsort: true,
          mode: "multiple"
        }}
        className="mt-2"
      >
        <Column field="InvoiceNumber" title="Invoice Number" />
        <Column field="CustomerName" title="Customer Name" />
        <Column field="CustomerNumber" title="Customer Number" />
        {props.Link.Name === 'UserCreditMemoInvoices' && <Column field="FreightBillCorrectionReasonValue" title="FBC Reason" />}
        {props.Link.Name === 'UserCreditMemoInvoices' && <Column field="FreightBillCorrectionNote" title="FBC Note" />}
        {props.Link.Name === 'UserBillToUpdatedInvoices' && <Column field="Changes" title="Changes" cell={ChangesJsonPrettyCell} />}
        <Column field="TotalCharges" title="Total Charges" className="text-right" headerCell={RightAlignHeaderCell} />
      </Grid>
      <DialogActionsBar>
        <Button onClick={props.CloseDialog}>Close</Button>
      </DialogActionsBar>
    </Dialog>
  </div>
}

export default BillingUserMetricInvoiceListingDialog;
