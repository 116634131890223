import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';

type State = {
  loading: boolean;
  data: Array<{ ServiceTeamID: number, Count: number }>;
}

type Props = {
  filter: { Name: string, Filter: string };
}

export default class TripCountByServiceTeam extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi(`/api/Report/TripCountByServiceTeam/${this.props.filter.Filter}`)
      .then((response: Array<{ ServiceTeamID: number, Count: number }>) => {
        this.setState({
          loading: false,
          data: response,
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
            <p>
              <span className="report-card-title">{this.props.filter.Name} Trip Count by Service Team</span>
              <br />
              {this.state.data.reduce((a, b) => { return a + b.Count }, 0)} Trips
            </p>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Service Team</th>
                  <th style={{ textAlign: 'right' }}>Count</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((serviceTeam, index) => {
                  return <tr key={index}>
                    <td>{this.serviceTeamName(serviceTeam.ServiceTeamID)}</td>
                    <td style={{ textAlign: 'right' }}>{serviceTeam.Count}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
  }

  private serviceTeamName(serviceTeamID: number): string {
    switch(serviceTeamID) {
      case 1:
        return "TruckLoad";
      case 2:
        return "Brokerage";
      case 3:
        return "Dedicated";
      case 4:
        return "Expedite";
      case 5:
        return "TL Brokerage";
      case 6:
        return "Specialized";
      case 7:
        return "Not Committed";
      default:
        return "N/A";
    }
  }
}
