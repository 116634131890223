import { GridCellProps } from "@progress/kendo-react-grid";

export const StatusCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const status = (status: number) => {
    switch (status) {
      case 90:
        return <span className="badge badge-danger p-1">Rejected</span>;
      case 100:
        return <span className="badge badge-secondary p-1">Created</span>;
      case 110:
        return <span className="badge badge-info p-1">Approved</span>;
      case 115:
        return <span className="badge badge-warning p-1">Ready To Post</span>;
      case 120:
        return <span className="badge badge-primary p-1">Invoiced</span>;
      default:
        return 'N/A';
    }
  }

  return (
    <td>
      {status(props.dataItem[props.field])}
    </td>
  );
}
