import { formatNumber } from '@progress/kendo-intl';
import { Push } from "@progress/kendo-react-animation";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { getter } from "@progress/kendo-react-common";
import { getSelectedState, Grid, GridColumn as Column, GridDetailRowProps, GridExpandChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import { fetchApi } from '../../services/api';
import { Title } from '../../utils/title';

type ReadyToPostInvoiceSummary = {
  InvoiceGroups: InvoiceGroup[];
}

type InvoiceGroup = {
  UserID: number;
  UserName: string;
  InvoiceCount: number;
  Invoices: Invoice[];

  Expanded: boolean;
  Selected: boolean;
};

type Invoice = {
  InvoiceNumber: string;
  BillToName: string;
  Amount: number;
  PrintDateTime: Date;
}

const DATA_ITEM_KEY: string = "UserID";
const SELECTED_FIELD: string = "Selected";
const idGetter = getter(DATA_ITEM_KEY);

const PostInvoiceBatch = () => {
  const [loading, setLoading] = useState(false);
  const [businessUnitId, setBusinessUnitId] = useState(2610);
  const [details, setDetails] = useState<ReadyToPostInvoiceSummary>();
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});
  const selectedUserInvoiceGroups = Object.entries(selectedState).filter(([, value]) => {
    return value === true;
  });
  const [message, setMessage] = useState('');

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {} as any;

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const saveChanges = () => {
    setLoading(true);

    const data = {
      businessUnitId,
      SelectedUserInvoiceGroups: selectedUserInvoiceGroups.map(([key]) => key)
    };

    fetchApi(`/api/Billing/PostInvoiceBatch`, data, 'POST')
      .then((message: string) => {
        setSelectedState({});
        setMessage(message);
        refreshBatchDetails();
      })
      .catch(e => {
        if (!e?.status)
          alert('Error: Please see admin');
        setLoading(false);
      });
  };

  const refreshBatchDetails = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Billing/ReadyToPostInvoices/${businessUnitId}`)
      .then((response: ReadyToPostInvoiceSummary) => {
        setDetails(response);
        setLoading(false);
      })
      .catch(e => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
        setLoading(false);
      });
  }, [businessUnitId]);

  useEffect(() => {
    refreshBatchDetails();
  }, [refreshBatchDetails]);


  const expandChange = (event: GridExpandChangeEvent) => {
    event.dataItem.Expanded = !event.dataItem.Expanded;

    var invoiceGroup = details.InvoiceGroups.find(x => x.UserID === event.dataItem.UserID);
    invoiceGroup.Expanded = event.dataItem.Expanded;

    setDetails({ ...details });
  };

  const detailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem as InvoiceGroup;
    return (
      <section>
        <div className="card my-1">
          <div className="card-body">
            <h5 className="card-title text-left">Invoices</h5>
            <table className="table table-hover table-striped mb-0">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Bill To</th>
                  <th>Amount</th>
                  <th>Ready Date</th>
                </tr>
              </thead>
              <tbody>
                {dataItem.Invoices.map((invoice, index) => (
                  <tr key={index}>
                    <td>{invoice.InvoiceNumber}</td>
                    <td>{invoice.BillToName}</td>
                    <td>{formatNumber(invoice.Amount, "c")}</td>
                    <td>{Moment.utc(invoice.PrintDateTime).tz("America/New_York").format("MM/DD/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Title string="Post Invoice Batch" />
      <h4 className="text-left mt-3">Post Invoice Batch</h4>
      <Push style={{ zIndex: 0, width: '100%' }}>
        {message && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
          <Notification style={{ padding: "8px", width: '100%' }} type={{ style: "success", icon: true }}>
            <div>{message}</div>
          </Notification>
        </NotificationGroup>}
      </Push>
      <div>
        <div className="mb-3 d-flex k-justify-content-end">
          <ButtonGroup>
            <Button onClick={() => setBusinessUnitId(2610)} selected={businessUnitId === 2610} togglable={true}>Load One</Button>
            <Button onClick={() => setBusinessUnitId(2612)} selected={businessUnitId === 2612} togglable={true} themeColor={businessUnitId === 2612 ? 'warning' : 'base'}>ARC</Button>
          </ButtonGroup>
        </div>
        <Grid
          data={details?.InvoiceGroups.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          detail={detailComponent}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          expandField="Expanded"
          onExpandChange={expandChange}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >
          <Column field="UserName" title="User ID" />
          <Column field="InvoiceCount" title="Invoice Count" />

          <Column
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              details?.InvoiceGroups.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
        </Grid>
        <div className="mt-3 d-flex k-justify-content-end">
          <Button themeColor="primary" disabled={loading || selectedUserInvoiceGroups.length === 0} onClick={() => saveChanges()}>Generate {businessUnitId === 2610 ? 'Load One' : 'ARC'} Invoice Post Batch</Button>
        </div>
      </div>
    </>
  );
};

export default PostInvoiceBatch;
