import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const AvailableCell = (props: GridCellProps) => {
  if (!props.field) return null;

  return (
    <td>
      <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${props.dataItem.VehicleID}/Positions`}>{props.dataItem.AvailableCityInfo}</Link>
      {props.dataItem.AvailableDateTime && <div style={{ fontSize: '11px' }}>{moment.utc(props.dataItem.AvailableDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>}
    </td>
  );
}

export default React.memo(AvailableCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
