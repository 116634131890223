import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { useCallback, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { isNullOrWhiteSpace } from '../../utils/utils';

type DeductionSummary = {
    DeductionTypes: DeductionType[];
};

export interface DeductionType {
    PayDeductionTypeID: number;
    Name: string;
    COA: string;
    Active: boolean;

    inEdit?: boolean | string;
    isNew?: boolean;
    Delete?: boolean;
}

interface EditCommandCellProps extends GridCellProps {
    edit: (item: DeductionType) => void;
    remove: (item: DeductionType) => void;
    add: (item: DeductionType) => void;
    discard: () => void;
    update: (item: DeductionType) => void;
    cancel: (item: DeductionType) => void;
    editField: string;
}

const InputCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    var value = props.dataItem[props.field] as string;

    const handleChange = (e: TextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
            {props.dataItem.inEdit ? <TextBox
                value={value}
                onChange={handleChange}
            /> :
                <span>{value}</span>
            }
        </td>
    );
}

const LabelCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    var value = props.dataItem[props.field] as string;

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
            {props.dataItem.isNew === true ? <span>-</span> : <span>{value}</span>}
        </td>
    );
}

const BooleanDropDownCell = (props: GridCellProps) => {

    const localizedData = [
        { text: "true", value: true },
        { text: "false", value: false }
    ];

    const handleChange = (e: DropDownListChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value,
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];

    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{ width: "100px" }}
                    onChange={handleChange}
                    value={localizedData.find((c) => c.value === dataValue)}
                    data={localizedData}
                    textField="text"
                />
            ) : (
                dataValue.toString()
            )}
        </td>
    );
};

const DeductionCOAs = () => {
    const { alert } = useAlert();
    const { ConfirmationDialog, confirm } = useConfirm({});
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [deductionTypes, setDeductionTypes] = useState<DeductionType[]>([]);
    const [CSIDataCopy, setCSIDataCopy] = useState<DeductionType[]>([]);

    const EditCommandCell = (_props: EditCommandCellProps) => {
        const inEdit = _props.dataItem[_props.editField];
        let dataItem: DeductionType = _props.dataItem;
        const isNewItem = dataItem.isNew;

        const disabled = isNullOrWhiteSpace(dataItem.Name) || isNullOrWhiteSpace(dataItem.COA);

        return inEdit ? (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={disabled}
                    onClick={() =>
                        isNewItem ? _props.add(dataItem) : _props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </Button>
                <Button themeColor={"secondary"}
                    onClick={() =>
                        isNewItem ? _props.discard() : _props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </Button>
            </td>
        ) : (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={deductionTypes.some(x => x.inEdit === true)}
                    onClick={() => _props.edit(dataItem)}>Edit</Button>
                {/*<Button themeColor={"secondary"}*/}
                {/*    disabled={deductionTypes.some(x => x.inEdit === true)}*/}
                {/*    onClick={async () => {*/}
                {/*        if (!await confirm(`Remove ?`))*/}
                {/*            return;*/}
                {/*        _props.remove(dataItem);*/}
                {/*    }}>Delete</Button>*/}
            </td>
        );
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const enterEdit = (dataItem: DeductionType) => {
        if (deductionTypes.some(x => x.inEdit === true))
            return;

        setDeductionTypes(
            deductionTypes.map((item) =>
                item.PayDeductionTypeID === dataItem.PayDeductionTypeID ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem: DeductionType) => {
        updateDeductionTypes({ ...dataItem, Delete: true });
    };

    const add = (dataItem: DeductionType) => {
        updateDeductionTypes(dataItem);
    };

    const update = (dataItem: DeductionType) => {
        updateDeductionTypes(dataItem);
    };

    const cancel = (dataItem: DeductionType) => {
        const originalItem = CSIDataCopy.find(
            (p) => p.PayDeductionTypeID === dataItem.PayDeductionTypeID
        );
        const newData = deductionTypes.map((item) =>
            item.PayDeductionTypeID === originalItem.PayDeductionTypeID ? cloneObject(originalItem) : item
        );

        setDeductionTypes(newData);
    };

    const generateId = () =>
        deductionTypes.reduce((acc, current) => Math.max(acc, current.PayDeductionTypeID), 0) + 1;

    const addNew = () => {
        const newDataItem = {
            inEdit: true, PayDeductionTypeID: generateId(), isNew: true, Active: true, COA: '', Name: '', Delete: false
        } as DeductionType;
        setDeductionTypes([newDataItem, ...deductionTypes]);
    };

    const discard = () => {
        const newData = [...deductionTypes];
        newData.splice(0, 1);
        setDeductionTypes(newData);
    };

    const refresh = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/Settlements/GetDeductionTypes`)
            .then((response: DeductionSummary) => {
                setDeductionTypes(response.DeductionTypes);
                setCSIDataCopy(cloneObject(response.DeductionTypes)) //deep copy/clone   
                setLoading(false);
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }, [alert]);

    const cloneObject = (obj: any): any => {
        return JSON.parse(JSON.stringify(obj));
    }

    const updateDeductionTypes = (dataItem: DeductionType) => {
        setLoading(true);

        const data = { ...dataItem }
        fetchApi(`api/Settlements/DeductionType`, data, 'POST')
            .then(async (response: JsonResponse) => {
                if (response.Success === false) {
                    setLoading(false);
                    await alert(`Error: ${response.ErrorMessage}`);
                }
                else {
                    refresh();
                }
            })
            .catch(async e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) await alert('An error occurred while saving.');
            });
    }

    useEffect(() => {
        refresh();
    }, [refresh]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = deductionTypes.map((item) =>
            item.PayDeductionTypeID === event.dataItem.PayDeductionTypeID
                ? { ...item, [field]: event.value }
                : item
        );
        setDeductionTypes(newData);
    }

    const dataView = () => {
        return (<div className="mt-4" style={{ position: "relative" }}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <h4 className="text-left">Deduction COAs</h4>
            <Grid
                style={{
                    maxHeight: `${window.innerHeight * .80}px`,
                }}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
                data={deductionTypes}
                onItemChange={itemChange}
                editField={editField}
            //dataItemKey={"PayDeductionTypeID"}
            >
                <GridToolbar>
                    <Button themeColor={'primary'}
                        disabled={deductionTypes.some(x => x.inEdit === true)}
                        title="Add new COA"
                        onClick={addNew}
                    >
                        Add New Deduction COA
                    </Button>
                </GridToolbar>
                <Column field="PayDeductionTypeID" title="ID" editable={false} cell={LabelCell} />
                <Column field="Name" title="Deduction Name" cell={InputCell} />
                <Column field="COA" title="Chart of Account" cell={InputCell} />
                <Column field="Active" title="Active" cell={BooleanDropDownCell} />
                <Column cell={MyEditCommandCell} width={160} />
            </Grid>

            <ConfirmationDialog />
        </div>);
    };

    return dataView();
}



export default DeductionCOAs;
