import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SalesPeopleViewModel } from '.';
import CenterDivPanel from '../../components/CenterDivPanel';
import { EditableAddress } from '../../components/EditableAddress';
import { fetchApi } from '../../services/api';
import { AddressViewModel } from '../../TypeGen/Driver/Profile/address-view-model';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
  CloseDialog: () => void;
}

const AddSalespersonDialog = (props: Props) => {
  const [salesperson, setSalesperson] = useState<SalesPeopleViewModel>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressViewModel>({ AddressLine1: '', AddressLine2: '', City: '', State: '', ZipCode: '', Coordinates: { Latitude: 0, Longitude: 0 } });

  const history = useHistory();

  const hasAddress = address.AddressLine1.length > 0 && ((address.City.length > 0 && address.State.length > 0) || address.ZipCode.length > 0);
  const canSave = hasAddress && isNullOrWhiteSpace(salesperson?.FirstName) === false && isNullOrWhiteSpace(salesperson?.LastName) === false &&
    salesperson?.TerritoryID > 0 && isNullOrWhiteSpace(salesperson?.EmailAddress) === false;

  const _save = () => {
    if (canSave === false)
      return;

    setLoading(true);

    const data = {
      ...salesperson,
      Address: address
    }

    fetchApi('/api/Customer/AddSalesperson', data, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          var link = response.Links.find(l => l.Name === 'SalespersonProfile');
          if (link) history.push(link.Link);
          props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      }
      ).catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to create salesperson');
      });
  }

  return <div className="container-fluid">
    <Dialog title={'Create Salesperson Profile'} onClose={props.CloseDialog} width={400} height={750}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div className="k-form k-form-md p-0">
        <div>
          <FieldWrapper>
            <Input
              required
              autoFocus
              maxLength={20}
              disabled={loading}
              value={salesperson?.FirstName}
              label="First Name"
              onChange={(e) => setSalesperson({ ...salesperson, FirstName: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              maxLength={25}
              disabled={loading}
              value={salesperson?.LastName}
              label="Last Name"
              onChange={(e) => setSalesperson({ ...salesperson, LastName: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <NumericTextBox
              required
              disabled={loading}
              value={salesperson?.TerritoryID}
              label="Territory No."
              onChange={(e) => setSalesperson({ ...salesperson, TerritoryID: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              type="email"
              maxLength={80}
              disabled={loading}
              label="E-Mail"
              value={salesperson?.EmailAddress}
              onChange={(e) => setSalesperson({ ...salesperson, EmailAddress: e.value })}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>Salesperson Address</Label>
            <EditableAddress
              data={address}
              title="Salesperson Address"
              save={async (e) => setAddress(e)}
            />
          </FieldWrapper>
        </div>
      </div>

      <DialogActionsBar>
        <Button onClick={props.CloseDialog}>Cancel</Button>
        <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
          Create Salesperson
        </Button>
      </DialogActionsBar>
    </Dialog>
  </div>
}

export default AddSalespersonDialog;

