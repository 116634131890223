import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatNumber } from '@progress/kendo-intl';

// Rate = Linehaul + Accessorial
export default function vehiclePayCell(distance: number, rate: number, fuel: number, bonus: number) {
  return class extends React.Component<GridCellProps> {
    render() {
      const color = this.props.dataItem.LinehaulPercentage == 0.70 
        ? 'green' : this.props.dataItem.LinehaulPercentage == 0.67 
        ? 'orange' : undefined;
      const payAmount = this.props.dataItem.QuoteOfferAllInRate 
        ? this.props.dataItem.QuoteOfferAllInRate
        : Math.max((rate * this.props.dataItem.LinehaulPercentage) + (distance * this.props.dataItem.LinehaulRPM), distance * this.props.dataItem.LinehaulRPMMin) + fuel * this.props.dataItem.FuelPercentage + bonus;
      return (
        <td style={{ color }}>
          {formatNumber(payAmount, "c")}
        </td>
      );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
      return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
  };
}
