import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { fetchApi } from '../../services/api';
import { ILink } from "../../types/link";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import Moment from 'moment-timezone';
import { Button } from "@progress/kendo-react-buttons";
import { JsonResponse } from "TypeGen/json-response";

type Props = {
  vehicleId: number;
  close: () => void;
  refresh?: () => void;
}

type ApiResponse = {
  VehicleNumber: string;
  LastAvailablePad: number;
  LastAvailablePadByUserNumber: string | null;
  LastAvailablePadDateTime: Date | null;
  Links: ILink[];
}

const LastAvailablePopup = ({ vehicleId, close, refresh }: Props) => {

  const [saving, setSaving] = useState(false);
  const [data, setData] = useState<ApiResponse>(null);
  const [hours, setHours] = useState(0);

  // Update internal data
  useEffect(() => {
    fetchApi(`/api/Asset/SetLastAvailable/${vehicleId}`)
      .then((data: ApiResponse) => {
        setData(data);
        setHours(data.LastAvailablePad);
      });
  }, [vehicleId]);

  const save = () => {
    setSaving(true);
    const payload = {
      VehicleID: vehicleId,
      AddHours: hours,
    }

    fetchApi('/api/Asset/SetLastAvailable', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          refresh && refresh();
          close();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to save.');
      });
  }

  const dataView = () => {
    return <form className="k-form k-form-md">
      <NumericTextBox
        value={hours}
        min={0}
        max={168}
        width={250}
        label="Hours to Add"
        onChange={(e) => setHours(e.target.value)}
      />
      {data.LastAvailablePadByUserNumber && <p className="mt-2">
        Last Updated By <b>{data.LastAvailablePadByUserNumber}</b> at <b>{Moment.utc(data.LastAvailablePadDateTime).local().format('MM/DD/YYYY HH:mm')}</b>.
      </p>}
    </form>
  }

  const loadingView = () => {
    return <div className="k-pos-absolute k-top-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <Dialog
      title={`Last Available Hours Adjustment - ${data?.VehicleNumber}`}
      onClose={close}
    >
      {data === null ? loadingView() : dataView()}
      <DialogActionsBar layout="end">
        <Button disabled={saving} className="ml-auto" onClick={close}>
          Cancel
        </Button>
        {data?.Links.find(x => x.Name == "Save") && <Button themeColor="primary" disabled={saving} onClick={save}>
          Save
        </Button>}
      </DialogActionsBar>
    </Dialog>
}

export default LastAvailablePopup;