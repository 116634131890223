import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { SourceType } from '../sourceType';
import { Input } from '@progress/kendo-react-inputs';
import { JsonResponse } from 'TypeGen/json-response';

type Props = { shipmentId: string; };

type State = {
  loading: boolean;
  decline: GetDeclineResponse;
  response?: ActiveAeroResponse | XPOResponse | SylectusResponse | ExpeditorsResponse | RyderLMSResponse | CoyoteResponse | CaterpillarResponse | ShawMAResponse | PepsiResponse;
}

type GetDeclineResponse = {
  ShipmentID: string;
  SourceType: SourceType;
}

class ActiveAeroResponse {
  ShipmentID: string;
  DeclineReasonID: number;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
    this.DeclineReasonID = 0;
  }
}

class XPOResponse {
  ShipmentID: string;
  DeclineReasonID: number;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
    this.DeclineReasonID = -1;
  }
}

class SylectusResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class ExpeditorsResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class RyderLMSResponse {
  ShipmentID: string;
  Note: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class CoyoteResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class CaterpillarResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class ShawMAResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class PepsiResponse {
  ShipmentID: string;

  constructor(shipmentID: string) {
    this.ShipmentID = shipmentID;
  }
}

class LoadBoardDecline extends React.Component<Props, State> {

  private readonly ActiveAeroDeclineReasons = [
    {ID: 0, Text: "No Units Available"},
    {ID: 1, Text: "No Authority"},
    {ID: 2, Text: "Other"},
  ];

  private readonly XPODeclineReasons = [{ID: -1, Text: "None"},
    {ID: 1, Text: "Air-Hub system doesn't operate"},
    {ID: 2, Text: "Air-Freight too large-NFO"},
    {ID: 3, Text: "Air-Freight too large-OTC"},
    {ID: 4, Text: "Air-Freight ready too late"},
    {ID: 5, Text: "No vans available"},
    {ID: 6, Text: "Can't make before close"},
    {ID: 7, Text: "Can't meet protect"},
    {ID: 8, Text: "No tractor-trailer available"},
    {ID: 9, Text: "No 24' available"},
    {ID: 10, Text: "No authority"},
    {ID: 11, Text: "No 12' trucks available"},
    {ID: 12, Text: "No dock high"},
    {ID: 13, Text: "No 24/102"},
    {ID: 14, Text: "No drivers available"},
    {ID: 15, Text: "No hazardous material authorized"},
    {ID: 16, Text: "No 16' trucks available"},
    {ID: 17, Text: "No air-ride available"},
    {ID: 18, Text: "No 18' trucks available"},
    {ID: 19, Text: "No Special Authorized"},
    {ID: 20, Text: "Tight Transit Window"},
    {ID: 21, Text: "Too Many Deadhead Miles"},
    {ID: 22, Text: "No Ops. Support to Update System"},
    {ID: 23, Text: "Not My Geographical Strength"},
    {ID: 24, Text: "Truck Utilized For Other Customer"},
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      decline: {
        ShipmentID: props.shipmentId,
        SourceType: SourceType.None
      }
    }

    this.fetch = this.fetch.bind(this);
    this.decline = this.decline.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <LoadingPanel />
    }

    if (this.state.decline.SourceType === SourceType.ActiveAero) {
      return <React.Fragment>
        <h2>Decline Active Aero</h2>

        <p>Decline Reason:</p>
        <DropDownList
          style={{ width: '100%'}}
          data={this.ActiveAeroDeclineReasons}
          textField="Text"
          value={this.ActiveAeroDeclineReasons.filter(x => x.ID === (this.state.response as ActiveAeroResponse).DeclineReasonID)[0]}
          onChange={(e) => this.setState({ response: { ...this.state.response, DeclineReasonID: e.target.value.ID }})}
        />

        <br /><br />

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.XPO) {
      return <React.Fragment>
        <h2>Decline XPO/NLM</h2>

        <p>Decline Reason:</p>
        <DropDownList
          style={{ width: '100%'}}
          data={this.XPODeclineReasons}
          textField="Text"
          value={this.XPODeclineReasons.filter(x => x.ID === (this.state.response as XPOResponse).DeclineReasonID)[0]}
          onChange={(e) => this.setState({ response: { ...this.state.response, DeclineReasonID: e.target.value.ID }})}
        />

        <br /><br />

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.Sylectus) {
      return <React.Fragment>
        <h2>Decline Sylectus</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.Expeditors) {
      return <React.Fragment>
        <h2>Decline Expeditors</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.RyderLMS) {
      return <React.Fragment>
        <h2>Decline Ryder</h2>

        <div className="form-group row">
          <label className="col-md-4 col-form-label">Comment:</label>
          <div className="col-md-8">
            <Input
              value={(this.state.response as RyderLMSResponse).Note}
              onChange={(e) => this.setState({ response: { ...this.state.response, Note: e.target.value.toString() }})}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline To Bid
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.Coyote) {
      return <React.Fragment>
        <h2>Decline Coyote</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.Caterpillar) {
      return <React.Fragment>
        <h2>Decline Caterpillar</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.ShawMA) {
      return <React.Fragment>
        <h2>Decline Shaw</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

    if (this.state.decline.SourceType === SourceType.Pepsi) {
      return <React.Fragment>
        <h2>Decline Pepsi</h2>

        <button type="button" className="btn btn-danger" onClick={this.decline}>
          Decline Shipment
        </button>
      </React.Fragment>
    }

  }

  private fetch() {
    fetchApi('/api/LoadBoard/Decline', { ShipmentID: this.props.shipmentId }, 'POST')
      .then((response: GetDeclineResponse) => {
        this.setState({ loading: false, decline: response, response: this.getDefaultResponse(response.SourceType, response.ShipmentID) });
      })
  }

  private decline() {
    this.setState({ loading: true });
    fetchApi(`/api/LoadBoard/${this.getAction(this.state.decline.SourceType)}`, this.state.response, 'POST')
      .then((response: JsonResponse) => {
        this.setState({ loading: false });
        if (response.Success === false) {
          alert(response.ErrorMessage);
          if ((response.ErrorMessage.indexOf("is no longer active") !== -1)
              || (response.ErrorMessage.indexOf("Carrier has already submitted a bid for this RFQ Id") !== -1)
              || (response.ErrorMessage.indexOf("This shipment has timed out or is no longer available for bidding.") !== -1)) {

                // Refresh Grid
              //this.props.refreshHistory();
          }
        } else {
          // Refresh Grid
          //this.props.refreshHistory();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        alert("Unhandled error occurred!");
      });
  }

  private getAction(sourceType: SourceType)
  {
    switch(sourceType)
    {
      case SourceType.ActiveAero:
        return "DeclineActiveAero";
      case SourceType.XPO:
        return "DeclineXPO";
      case SourceType.Sylectus:
        return "DeclineSylectus";
      case SourceType.Expeditors:
        return "DeclineExpeditors";
      case SourceType.RyderLMS:
        return "DeclineRyderLMS";
      case SourceType.Coyote:
        return "DeclineCoyote";
      case SourceType.Caterpillar:
        return "DeclineCaterpillar";
      case SourceType.ShawMA:
        return "DeclineShawMA";
      case SourceType.Pepsi:
        return "DeclinePepsi";
      default:
        throw new Error(`Invalid SourceType: ${sourceType}`);
    }
  }

  private getDefaultResponse(sourceType: SourceType, ShipmentID: string)
    : ActiveAeroResponse | XPOResponse | SylectusResponse | ExpeditorsResponse | RyderLMSResponse | CoyoteResponse | CaterpillarResponse | ShawMAResponse | PepsiResponse
  {
    switch(sourceType)
    {
      case SourceType.ActiveAero:
        return new ActiveAeroResponse(ShipmentID);
      case SourceType.XPO:
        return new XPOResponse(ShipmentID);
      case SourceType.Sylectus:
        return new SylectusResponse(ShipmentID);
      case SourceType.Expeditors:
        return new ExpeditorsResponse(ShipmentID);
      case SourceType.RyderLMS:
        return new RyderLMSResponse(ShipmentID);
      case SourceType.Coyote:
        return new CoyoteResponse(ShipmentID);
      case SourceType.Caterpillar:
        return new CaterpillarResponse(ShipmentID);
      case SourceType.ShawMA:
        return new ShawMAResponse(ShipmentID);
      case SourceType.Pepsi:
        return new PepsiResponse(ShipmentID);
      default:
        throw new Error(`Invalid SourceType: ${sourceType}`);
    }
  }
}

export default LoadBoardDecline;
