import * as React from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { Form, FormHandle, Field } from '@progress/kendo-react-form';
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps, DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { openWindow } from '../services/openWindow';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../services/api';
import Documents, { DocumentEntityType } from '../views/Documents/Documents';
import BodyPortal from './BodyPortal';
import PhoneCell from './cells/PhoneCell';
import { Button } from '@progress/kendo-react-buttons';
import CopyCustomerDialog from 'views/Customers/CopyCustomerDialog';
import { SvgIcon } from "@progress/kendo-react-common";
import { searchIcon } from '@progress/kendo-svg-icons';

type CustomerAutocomplete = {
    CustomerID: number;
    CustomerNumber: string;
    CustomerName: string;
    AddressLine1: string;
    CityName: string;
    State: string;
    ZipCode: string;
}

export type CustomerInputValue = {
    CustomerID: number;
    CustomerNumber: string;
    CustomerName?: string;
    City?: string;
    State?: string;
    ZipCode?: string;
    AddressLine1?: string;
}

type CustomerSearchValue = {
    CustomerID: number;
    CustomerNumber: string;
    CustomerName: string;
    AddressLine1: string;
    City: string;
    State: string;
    ZipCode: string;
    PhoneNumber: string;
    PhoneNumberExtension: string;
}

type Props = {
    CustomerID: number;
    CustomerNumber: string;
    placeholder?: string;
    onChange: (response: CustomerInputValue) => void;
    onCreditHold?: boolean;
    focus?: boolean;
    prependGroup?: boolean;
    disabled?: boolean;

    // For EDI stop notes
    StopNote?: string;
    City?: string;
    State?: string;
    ZipCode?: string;
}

type State = {
    data: CustomerAutocomplete[];
    searchData: CustomerSearchValue[];
    isLoadingAutoComplete: boolean;
    isLoadingSearchData: boolean;
    customerSearch: boolean;
    customerSearchData: {
        CustomerName: string;
        City: string;
        State: string;
        AddressLine1: string;
        PhoneNumber: string;
        Country: string;
        ZipCode: string;
        CustomerNumber: string;
    };
    ValidationRequestHash: string;
    showCopyCustomerDialog: boolean;
    showDocuments: boolean;
}

export default class CustomerInput extends React.Component<Props, State> {

    private autocomplete: React.RefObject<AutoComplete>;
    private searchForm: React.RefObject<FormHandle>;

    constructor(props: Props) {
        super(props);

        this.autocomplete = React.createRef();
        this.searchForm = React.createRef();

        this.openCustomer = this.openCustomer.bind(this);
        this.createCustomer = this.createCustomer.bind(this);
        this.openCustomerSearch = this.openCustomerSearch.bind(this);
        this.updateCustomerAutocomplete = this.updateCustomerAutocomplete.bind(this);

        this.selectCustomer = this.selectCustomer.bind(this);

        this.state = {
            data: [],
            searchData: [],
            isLoadingAutoComplete: false,
            isLoadingSearchData: false,
            customerSearch: false,
            customerSearchData: {
                CustomerName: '',
                City: '',
                State: '',
                AddressLine1: '',
                PhoneNumber: '',
                Country: '',
                ZipCode: '',
                CustomerNumber: '',
            },
            ValidationRequestHash: '',
            showCopyCustomerDialog: false,
            showDocuments: false,
        }
    }

    public componentDidMount() {
        if (this.props.focus && this.autocomplete.current) {
            this.autocomplete.current.focus();
        }
    }

    public render() {
        return <>
            {this.state.customerSearch && this.customerSearchWindow()}
            <AutoComplete
                ref={this.autocomplete}
                data={this.state.data}
                value={this.props.CustomerNumber}
                style={this.props.prependGroup ? { flex: 1, width: '100%', borderRadius: 0 } : { flex: 1, width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                loading={this.state.isLoadingAutoComplete}
                placeholder={this.props.placeholder || 'Customer'}
                disabled={this.props.disabled ?? false}
                itemRender={this.itemRenderCustomer}
                onChange={(event: AutoCompleteChangeEvent) => {
                    // Picked from auto complete
                    if (typeof event.target.value === 'object') {
                        const customerObj = event.target.value as any;
                        this.props.onChange({
                            CustomerID: customerObj.CustomerID,
                            CustomerNumber: customerObj.CustomerNumber,
                            CustomerName: customerObj.CustomerName,
                            City: customerObj.CityName,
                            State: customerObj.State,
                            ZipCode: customerObj.ZipCode,
                            AddressLine1: customerObj.AddressLine1
                        });
                    } else {
                        this.props.onChange({
                            CustomerID: 0,
                            CustomerNumber: event.target.value
                        });

                        // Update Autocomplete Suggestions
                        if (event.target.value.length > 2) {
                            this.setState({ ValidationRequestHash: '' }, () => {
                                this.updateCustomerAutocompleteDebounced(event.target.value);
                            });
                        }
                    }
                }}
            />
            {!this.props.CustomerID && <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={this.openCustomerSearch}>
                    <SvgIcon icon={searchIcon} />
                </button>
            </div>}
            {this.props.CustomerID > 0 && <div className="input-group-append">
                <button onDoubleClick={this.openCustomer} className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabIndex={-1}></button>
                <div className="dropdown-menu">
                    <button type="button" className="dropdown-item" onClick={this.openCustomer}>Open</button>
                    <button type="button" className="dropdown-item" onClick={() => this.setState({ showDocuments: true })}>Documents</button>
                </div>
            </div>}
            {this.props.CustomerID > 0 && this.state.showDocuments && <BodyPortal><Documents
                IsWindow
                EntityType={DocumentEntityType.Customer}
                EntityId={this.props.CustomerID}
                EntityNumber={this.props.CustomerNumber}
                CloseDocumentModal={() => {
                    this.setState({ showDocuments: false });
                }}
            /></BodyPortal>}
        </>
    }

    private itemRenderCustomer = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = <span>
            {itemProps.dataItem.CustomerNumber} - {itemProps.dataItem.CustomerName} - {itemProps.dataItem.CityName}, {itemProps.dataItem.State} {itemProps.dataItem.ZipCode}
        </span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    private customerSearchWindow() {
        return <Window
            title="Customer Search"
            style={{ position: 'fixed' }}
            initialWidth={Math.min(850, window.innerWidth)}
            initialHeight={Math.min(550, window.innerHeight)}
            onClose={() => this.setState({ customerSearch: false, searchData: [] })}
        >
            {this.state.showCopyCustomerDialog && <CopyCustomerDialog
                StopNote={this.props.StopNote}
                City={this.props.City}
                State={this.props.State}
                ZipCode={this.props.ZipCode}
                CloseDialog={(newCustomerId) => {
                    if (newCustomerId > 0) {
                        const data = {
                            CustomerID: newCustomerId,
                        }
                        fetchApi("/api/Customer/Search", data, "POST")
                            .then((response: { Customers: CustomerSearchValue[] }) => {
                                this.selectCustomer(response.Customers[0]);
                            });
                    } else {
                        this.setState({ showCopyCustomerDialog: false });
                    }
                }}
            />}
            {this.props.StopNote && <div className="alert alert-info" role="alert">{this.props.StopNote}</div>}
            <Form
                ref={this.searchForm}
                onSubmit={(values, event) => this.searchCustomers(values)}
                initialValues={this.state.customerSearchData}
                render={(formRenderProps) => (
                    <form onSubmit={formRenderProps.onSubmit} className="k-form k-form-md p-0">
                        <div className="row">
                            <div className="col-md-4">
                                <Field name="CustomerName" component={Input} label="Name" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="City" component={Input} label="City" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="State" component={Input} label="State" maxLength={2} style={{ width: '100%' }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Field name="AddressLine1" component={Input} label="Address" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="PhoneNumber" component={MaskedTextBox} label="Phone" mask="(000) 000-0000" width={'100%'} />
                            </div>
                            <div className="col-md-4">
                                <Field name="Country" component={DropDownList} label="Country" data={["USA", "CAN", "MEX"]} style={{ width: '100%' }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Field name="ZipCode" component={Input} label="Zip Code" style={{ width: '100%' }} />
                            </div>
                            <div className="col-md-4">
                                <Field name="CustomerNumber" component={Input} label="My Code" style={{ width: '100%' }} />
                            </div>
                        </div>
                        <br />
                        <div className="text-center">
                            <Button themeColor="primary" disabled={!formRenderProps.allowSubmit}>Search</Button>
                            &nbsp;&nbsp;
                            <Button themeColor="primary" onClick={this.createCustomer}>Add Customer</Button>
                            &nbsp;&nbsp;
                            <Button type="button" onClick={() => {
                                this.setState({
                                    customerSearchData: {
                                        CustomerName: '',
                                        City: '',
                                        State: '',
                                        AddressLine1: '',
                                        PhoneNumber: '',
                                        Country: '',
                                        ZipCode: '',
                                        CustomerNumber: '',
                                    }
                                }, this.searchForm.current.resetForm);
                            }}>Reset</Button>
                        </div>
                    </form>
                )} />
            <br />
            {this.state.isLoadingSearchData && <span
                className="k-i-loading k-icon"
                style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
            />}
            <Grid
                data={this.state.searchData}
                dataItemKey="CustomerID"
                onRowClick={(e) => this.selectCustomer(e.dataItem)}
            >
                <Column field="CustomerNumber" title="My Code" cell={(props) => <td style={{ color: '#007bff' }} role="button">{props.dataItem.CustomerNumber}</td>} />
                <Column field="CustomerName" title="Name" />
                <Column field="AddressLine1" title="Address" />
                <Column field="City" />
                <Column field="State" />
                <Column field="ZipCode" title="Zip" />
                <Column field="PhoneNumber" title="Phone" cell={PhoneCell} />
            </Grid>
        </Window>
    }

    private openCustomer() {
        openWindow(`/Customers/Customer/${this.props.CustomerID}`);
    }

    private createCustomer() {
        this.setState({ showCopyCustomerDialog: true });
    }

    private openCustomerSearch(e: any) {
        const stopNote = this.props.StopNote;
        if (stopNote) {
            const customerNameIndex = stopNote.indexOf('CUST:');
            const addressIndex = stopNote.indexOf('ADDR:');
            const phoneNumberIndex = stopNote.indexOf('PH:');
            const customerNumberIndex = stopNote.indexOf('CUST. CODE:');
            const customerSearchData = {
                'CustomerName': customerNameIndex > -1 ? stopNote.substring(customerNameIndex + 5, customerNameIndex + 6).trim() : '',
                'City': this.props.City,
                'State': this.props.State,
                'AddressLine1': addressIndex > -1 ? stopNote.substring(addressIndex + 5, addressIndex + 6).trim() : '',
                'PhoneNumber': phoneNumberIndex > -1 ? stopNote.substring(phoneNumberIndex + 3, phoneNumberIndex + 4).trim() : '',
                'Country': '',
                'ZipCode': this.props.ZipCode,
                'CustomerNumber': customerNumberIndex > -1 ? stopNote.substring(customerNumberIndex + 11, stopNote.length).split(' ')[0] : '',
            }
            this.setState({
                customerSearch: true,
                customerSearchData,
            }, () => this.searchCustomers(customerSearchData));
        } else {
            this.setState({ customerSearch: true });
        }
    }

    private updateCustomerAutocompleteDebounced = debounce(this.updateCustomerAutocomplete, 400);

    private updateCustomerAutocomplete(value: string) {
        const requestHash = Math.random().toString(36).substring(7);
        this.setState({ isLoadingAutoComplete: true, ValidationRequestHash: requestHash });
        const data = {
            Term: value.trim(),
            OnCreditHold: this.props.onCreditHold
        }
        fetchApi("/api/Customer/Autocomplete", data, "POST")
            .then((options: CustomerAutocomplete[]) => {
                if (this.state.ValidationRequestHash === requestHash) {

                    // If we have a customer number, but no customer ID, try to find the customer ID from the autocomplete list
                    if (!this.props.CustomerID && this.props.CustomerNumber) {
                        const newCustomer = options.find(x => x.CustomerNumber.toUpperCase() === this.props.CustomerNumber.toUpperCase());
                        if (newCustomer) {
                            this.props.onChange({
                                CustomerID: newCustomer.CustomerID,
                                CustomerNumber: newCustomer.CustomerNumber,
                                CustomerName: newCustomer.CustomerName,
                                City: newCustomer.CityName,
                                State: newCustomer.State,
                                ZipCode: newCustomer.ZipCode,
                                AddressLine1: newCustomer.AddressLine1
                            });
                        }
                    }
                    this.setState({ isLoadingAutoComplete: false, data: options });
                }
            });
    }

    private searchCustomers(values: { [name: string]: any; }) {
        this.setState({ isLoadingSearchData: true });
        const data = {
            ...values,
            OnCreditHold: this.props.onCreditHold,
        }
        fetchApi("/api/Customer/Search", data, "POST")
            .then((response: { Customers: CustomerSearchValue[] }) => {
                this.setState({ isLoadingSearchData: false, searchData: response.Customers });
            })
    }

    private selectCustomer(dataItem: CustomerInputValue) {
        this.setState({
            searchData: [],
            customerSearch: false,
        });
        this.props.onChange({
            CustomerID: dataItem.CustomerID,
            CustomerNumber: dataItem.CustomerNumber,
            CustomerName: dataItem.CustomerName,
            City: dataItem.City,
            State: dataItem.State,
            ZipCode: dataItem.ZipCode,
            AddressLine1: dataItem.AddressLine1
        });
    }
}