import AssetPanel from '../Assets/AssetPanel';

const AssetTrailers = () => {
  return <AssetPanel
    searchTerm=""
    tabIndex={3}
  />
}

export default AssetTrailers;

