import * as React from 'react';
import { fetchApi } from '../../services/api';
import { Button } from '@progress/kendo-react-buttons';
import LoadingPanel from '../../components/LoadingPanel';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { TextArea } from '@progress/kendo-react-inputs';

interface Props {
    SelectedCarrier: AssetCarrierViewModel;
    SelectedCarriers: AssetCarrierViewModel[];
    AllCarriersSelected: boolean;
    CloseDialog: () => void;
}

interface State {
    notes: string;
    filterButtonValue: string;
    sendEmailMessage: boolean;
    isLoading: boolean;
    sentToEntityButtonLabel: string;
}

const filterButton = {
    width: "190px",
} as React.CSSProperties;

export class SendCarrierMessage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            notes: '',
            filterButtonValue: 'SubmitPaperwork',
            sendEmailMessage: false,
            isLoading: false,
            sentToEntityButtonLabel: ''
        }

        this.sendCall = this.sendCall.bind(this);
    }

    public componentDidMount() {
        if (this.props.AllCarriersSelected)
            this.setState({
                sentToEntityButtonLabel: "All Selected Carriers"
            });
        else if (this.props.SelectedCarriers.length > 1) {
            this.setState({
                sentToEntityButtonLabel: "All Selected (" + parseInt(this.props.SelectedCarriers.length + "") + ") Carriers"
            });
        } else {
            this.setState({
                sentToEntityButtonLabel: this.props.SelectedCarrier.CarrierName
            });
        }
    }

    public render() {
        const boldText = {
            fontWeight: 500
        } as React.CSSProperties;
        return (
            <Dialog title="Send E-Mail Message" onClose={this.props.CloseDialog} width={1024}>
                {this.state.isLoading && <LoadingPanel />}
                <div className="container">
                    <div className="row">
                        <div className="col-sm" style={{ textAlign: "center" }}>
                            <Button themeColor="primary" value="SubmitPaperwork" style={filterButton}
                                title="Please submit missing paperwork for your trips.">Paperwork Reminder</Button>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-lg" style={{ textAlign: "center" }}>
                            <span style={boldText}>Template Selected:</span> Paperwork Reminder<br /><br />
                            <span style={boldText}>Subject:</span> This is a Load1 request for missing paperwork
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-lg" style={{ textAlign: "center" }}>
                            <span style={boldText}>Body:</span> Please submit paperwork for the following trip(s) that Load1 requires to process your payment:
                            <br/><i>(Note: Trip number details will be automatically inserted into the email)</i>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-lg" style={{ textAlign: "center" }}>
                            <TextArea placeholder="You can additional text for the email here." style={{ width: "80%" }} onChange={(e) => this.setState({ notes: e.value })} />
                        </div>
                    </div>
                </div>
                <DialogActionsBar layout="end">
                    <Button onClick={this.props.CloseDialog}>Cancel</Button>
                    <Button themeColor="primary" onClick={this.sendCall}>Email {this.state.sentToEntityButtonLabel}</Button>
                </DialogActionsBar>
            </Dialog>);
    }

    private sendCall() {

        this.setState({
            isLoading: true
        });

        //let notes: string = ;
        const buttonClicked: string = this.state.filterButtonValue.length > 0 ? this.state.filterButtonValue : null;

        //if (this.state.notes.length > 0 || buttonClicked !== null) {

        //    let vehicleIds: number[] = [];
        //    let driverIds: number[] = [];
        //    if (this.props.SelectedDrivers.length > 0) {
        //        this.props.SelectedDrivers.forEach(function (item) {
        //            driverIds.push(item.DriverID);
        //            if (item.VehicleID > 0)
        //                vehicleIds.push(item.VehicleID);
        //        });
        //    } else if (this.props.SelectedVehicles.length > 0) {
        //        this.props.SelectedVehicles.forEach(function (item) {
        //            vehicleIds.push(item.VehicleID);
        //            if (item.DriverID > 0)
        //                driverIds.push(item.DriverID);
        //        });
        //    } else {
        //        if (this.props.SelectedRowValues.DriverID > 0)
        //            driverIds.push(this.props.SelectedRowValues.DriverID);
        //        if (this.props.SelectedRowValues.VehicleID > 0)
        //            vehicleIds.push(this.props.SelectedRowValues.VehicleID);
        //    }




        const data = {
            CarrierMessageType: buttonClicked,
            AdditionalNotes: this.state.notes,
            AllCarriersSelected: this.props.AllCarriersSelected,
            CarrierIds: this.props.SelectedCarriers.length > 0 ? this.props.SelectedCarriers.map(x => x.CarrierID) : [this.props.SelectedCarrier.CarrierID]
        };

        //    if (this.state.sendOTMessage) {
        fetchApi('/api/Asset/EmailAssetCarrier', data, 'POST').then(() => {
            this.props.CloseDialog();
        }).catch(() => {
            this.setState({
                isLoading: false
            });
        });
        //    }

        //} else {
        //    this.setState({
        //        isLoading: false
        //    });
        //}

    }
}
