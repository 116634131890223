import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Trip } from '.';
import { openWindowPerc } from '../../services/openWindow';
import { ILink } from '../../types/link';
import SplitOrderSummary from '../Order/SplitOrderSummary';

const ReferenceCell = (props: GridCellProps) => {
  const [showSplitSummary, setShowSplitSummary] = React.useState(false);

  const trip = props.dataItem as Trip;

  const openFront = (threadId: string) => {
    if (threadId.startsWith("cnv_") || threadId.startsWith("msg_")) {
      const win = window.open(`https://app.frontapp.com/open/${threadId}`, '_blank');
      win.focus();
    }
  }

  const getServiceTeamName = (serviceTeamId: number) => {
    switch (serviceTeamId) {
      case 1:
        return "Truckload";
      case 2:
        return "Brokerage";
      case 3:
        return "Dedicated";
      case 4:
        return "Expedite";
      case 5:
        return "Truckload Brokerage";
      case 6:
        return "Specialized";
      case 7:
        return "Not Committed";
      default:
        return "";
    }
  }

  return (
    <td>
      {showSplitSummary && <SplitOrderSummary OrderID={trip.OrderID} onClose={() => setShowSplitSummary(false)} />}
      <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'NewLoad').Link);
          e.preventDefault();
        }}
      >
        {trip.OrderNumber}
      </a>
      {trip.SplitLabel &&
        <React.Fragment>
          &nbsp;
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              window.isTMS ? setShowSplitSummary(true) : openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'OrderSplit').Link);
              e.preventDefault();
            }}
          >
            {trip.SplitLabel}
          </a>
        </React.Fragment>
      }

      &nbsp;&nbsp;&nbsp;
      {trip.Links.find((x: ILink) => x.Name === 'TripProfile') && <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'TripProfile').Link);
          e.preventDefault();
        }}
      >
        {trip.TripNumber}
      </a>}

      {trip.HasEventNotification && <React.Fragment>
        &nbsp;&nbsp;&nbsp;<a
          href="#"
          style={{ color: 'red' }}
          onClick={(e) => {
            openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'EventNotification').Link);
            e.preventDefault();
          }}
        >
          S-EN
        </a>
      </React.Fragment>}
      <br />
      {window.isTMS ? <>{trip.ReferenceNumber1}&nbsp;&nbsp;</> : <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openWindowPerc(trip.Links.find((x: ILink) => x.Name === 'OrderNotes').Link);
          e.preventDefault();
        }}
      >
        {trip.ReferenceNumber1}&nbsp;&nbsp;
      </a>}
      {trip.ReferenceNumber2 && <a
        href="#"
        style={{ color: '#007bff' }}
        onClick={(e) => {
          openFront(trip.ReferenceNumber2);
          e.preventDefault();
        }}
      >
        {trip.ReferenceNumber2}&nbsp;&nbsp;
      </a>}
      {trip.ReferenceNumber3?.toUpperCase()}
      <br />
      {getServiceTeamName(trip.ServiceTeamID)}
      {trip.IsCritical && <>
        &nbsp;&nbsp;<span style={{ color: "red" }}>CRITICAL</span>
      </>}
      {trip.HazMat && <>
        &nbsp;&nbsp;<span style={{ color: "red" }}>HAZMAT</span>
      </>}
    </td>
  );
}
export default React.memo(ReferenceCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);