import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';
import { Link } from 'react-router-dom';

export default class CustomerCell extends React.Component<GridCellProps> {
    render() {
        if (!this.props.field) return null;

        const customerLink = this.props.dataItem.Links.find((x: ILink) => x.Name === 'CustomerProfile');
        return (
            <td>
                {customerLink && <Link
                    to={customerLink.Link}
                    style={{ color: '#007bff' }}
                >
                    {this.props.dataItem.CustomerNumber}
                </Link>}
            </td>
        );
    }

    public shouldComponentUpdate(nextProps: GridCellProps) {
        return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
    }
}
