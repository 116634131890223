import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker, ToggleButton, ToggleButtonProps } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { useState } from 'react';
import Moment from 'moment-timezone';
import { AssetHardwareType } from 'TypeGen/Assets/asset-hardware-type';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { hardwareTypes } from './Hardware';
import { JsonResponse } from 'TypeGen/json-response';
import { VehicleHardwareViewModel } from 'TypeGen/Assets/Hardware/vehicle-hardware-view-model';
import { set } from 'lodash';
import SimpleDatePicker from 'components/SimpleDatePicker';

type Props = {
  VehicleID: number;
  EditData: VehicleHardwareViewModel | null;
  CloseDialog: (refresh: boolean) => void;
}

const AddHardwareDialog = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hardwareType, setHardwareType] = useState<AssetHardwareType | null>(props.EditData ? props.EditData.HardwareType : null);
  const [hardwareNumber, setHardwareNumber] = useState<string>(props.EditData?.HardwareNumber ?? '');
  const [installedDateTime, setInstalledDateTime] = useState<Date | null>(props.EditData?.InstalledDateTime ? Moment.utc(props.EditData.InstalledDateTime).toDate() : null);
  const [removedDateTime, setRemovedDateTime] = useState<Date | null>(props.EditData?.RemovedDateTime ? Moment.utc(props.EditData.RemovedDateTime).toDate() : null);
  const [note, setNote] = useState<string>(props.EditData?.Note ?? '');
  const [disableIntegration, setDisableIntegration] = useState<boolean>(props.EditData?.DisableIntegration ?? false);

  const save = () => {
    if (hardwareType === null || installedDateTime === null)
    {
      alert('Please fill in all required fields');
      return;
    }

    setLoading(true);

    const data = {
      AssetHardwareID: props.EditData ? props.EditData.AssetHardwareID : null,
      AssetID: props.VehicleID,
      HardwareType: hardwareType,
      HardwareNumber: hardwareNumber,
      InstalledDateTime: installedDateTime,
      RemovedDateTime: removedDateTime,
      Note: note,
      DisableIntegration: disableIntegration,
    }

    fetchApi('/api/Asset/UpdateHardware', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success)
        {
          props.CloseDialog(true);
        }
        else
        {
          setLoading(false);
          alert(response.ErrorMessage);
        }
      }).catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to save hardware');
      });
  }

  return <Dialog title={props.EditData ? "Update Hardware" : "Add Hardware"} onClose={() => props.CloseDialog(false)} className='dialog-w525'>
    {loading && <LoadingPanel />}
    <div className="row">
      <label className="col-md-4 col-form-label">Type:</label>
      <div className="col-md-8">
        <DropDownList
          required
          disabled={!!props.EditData}
          data={hardwareTypes}
          value={hardwareTypes.find(x => x.ID === hardwareType)}
          onChange={(e) => setHardwareType(e.value.ID)}
          textField='Name'
          style={{ width: "100%" }}
        />
      </div>
    </div>
    <div className="row pt-2">
      <label className="col-md-4 col-form-label">Serial:</label>
      <div className="col-md-8">
        <Input
          maxLength={50}
          value={hardwareNumber}
          onChange={(e) => setHardwareNumber(e.value)}
          style={{ width: "100%" }}
        />
      </div>
    </div>
    {(hardwareType === AssetHardwareType.Omnitracs || hardwareType === AssetHardwareType.TriStateSamsara || hardwareType === AssetHardwareType.LoadOneSamsara) && <div className="row pt-2">
      <label className="col-md-4 col-form-label">Disable Integration:</label>
      <div className="col-md-8">
        <Checkbox
          label="Allows App Tracking"
          checked={disableIntegration}
          onChange={(e) => setDisableIntegration(e.value)}
        />
      </div>
    </div>}
    <div className="row pt-2">
      <label className="col-md-4 col-form-label">Provided On:</label>
      <div className="col-md-8">
        <SimpleDatePicker
          required
          value={installedDateTime}
          onChange={(e) => setInstalledDateTime(e)}
        />
      </div>
    </div>
    {props.EditData && <div className="row pt-2">
      <label className="col-md-4 col-form-label">Returned On:</label>
      <div className="col-md-8">
        <SimpleDatePicker
          value={removedDateTime}
          onChange={(e) => setRemovedDateTime(e)}
        />
      </div>
    </div>}
    <div className="row pt-2">
      <div className="col-md-12">
        <TextArea
          maxLength={280}
          placeholder='Notes...'
          style={{ width: '100%'}}
          value={note}
          onChange={(e) => setNote(e.value)}
        />
      </div>
    </div>
    <DialogActionsBar layout="end">
      <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
      <Button
        themeColor="primary"
        disabled={loading}
        onClick={save}
      >
        {props.EditData ? "Update" : "Add"} Hardware
      </Button>
    </DialogActionsBar>
  </Dialog>;
}

export default AddHardwareDialog;