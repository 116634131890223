import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../services/api';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { StateCodes } from '../../utils/states';
import { IDName } from '../../types/idname';
import Moment from 'moment-timezone';
import {
    ExpansionPanel,
    ExpansionPanelContent,
    ExpansionPanelActionEvent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { AddressViewModel } from '../../types/address';
import { JsonResponse } from 'TypeGen/json-response';
import { AssetServiceStatusViewModel } from 'TypeGen/Assets/ServiceStatuses/asset-service-status-view-model';

type Props = {
    CloseDialog: (dataSaved: boolean) => void;
    VehicleID: number;
    AssetServiceStatusID?: number;
}

type LoadInfo = {
    LoadNumber: number;

    ShipDateTime: Date;
    ShipCity: string;
    ShipState: string;
    ShipCityStateValue: string;

    DeliveryDateTime: Date;
    DeliveryCity: string;
    DeliveryState: string;
    DeliveryCityStateValue: string;

    Expanded: boolean;
}

const dateTimeUtcNow = Moment.utc().startOf('minute');
const ServiceStatusDialog = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [showInServiceMode, setShowInServiceMode] = useState(false);
    const [elementListDisplayOrder, setElementListDisplayOrder] = useState<JSX.Element[]>([]);
    const [newRecordMustBeAfterLastServiceLabel, setNewRecordMustBeAfterLastServiceLabel] = useState<JSX.Element>();
    const [addOrUpdateVehicleServiceStatusLabel, setAddOrUpdateVehicleServiceStatusLabel] = useState<JSX.Element>();
    const [addOrUpdateMinServiceStatusDate, setAddOrUpdateMinServiceStatusDate] = useState<Date>(dateTimeUtcNow.clone().subtract(5, 'years').toDate()); //const beginningOfTime = moment([1]).startOf('year');    
    const [addOrUpdateMaxServiceStatusDate, setAddOrUpdateMaxServiceStatusDate] = useState<Date>(dateTimeUtcNow.clone().add(5, 'years').toDate());

    const [plannedLoads, setPlannedLoads] = useState<LoadInfo[]>(null);
    const [currentTrip, setCurrentTrip] = useState<LoadInfo>(null);
    const [expandPlannedLoads, setExpandPlannedLoads] = useState(false);

    //Form Data
    const [serviceStatusDateTime, setServiceStatusDateTime] = useState<Date>(dateTimeUtcNow.clone().toDate());
    const [address, setAddress] = useState<AddressViewModel>({ Name: '', AddressLine1: '', AddressLine2: '', City: '', State: '', ZipCode: '', Coordinates: { Latitude: 0, Longitude: 0 } });
    const [comments, setComments] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [serviceStatusCodeList, setServiceStatusCodeList] = useState<IDName[]>(null);
    const [serviceStatusCode, setServiceStatusCode] = useState(-1); //to be saved serviceStatusCode       


    const isEditMode = (props.AssetServiceStatusID > 0);

    const isAssetServiceStatusDateTimeValid = serviceStatusDateTime < addOrUpdateMaxServiceStatusDate && serviceStatusDateTime > addOrUpdateMinServiceStatusDate;
    const hasAddress = (address.City.length > 0 && address.State.length > 0) || address.ZipCode.length > 0;
    const canSave = (hasAddress && isAssetServiceStatusDateTimeValid && ((showInServiceMode === false && serviceStatusCode > 1) || showInServiceMode));

    const compareMoments = (firstMoment: Moment.Moment, secondMoment: Moment.Moment): string => {
        return `(${Moment.utc(firstMoment).from(secondMoment)})`;
        /*const diffInMilliseconds = firstMoment.diff(secondMoment);
        const diffDuration = Moment.duration(diffInMilliseconds);

        const diffInDays = Math.abs(Math.floor(diffDuration.asDays())); //const diffInDays = Math.floor(diffDuration.asDays()); to grab total days
        const diffInHours = Math.abs(Math.floor(diffDuration.asHours())); //const diffInHours = Math.floor(diffDuration.asHours()); to grab total hours
        const nonAbsMinDiff = Math.floor(diffDuration.asMinutes());
        const diffInMinutes = Math.abs(nonAbsMinDiff); //const diffInMinutes = Math.floor(diffDuration.asMinutes());  to grab total minutes

        const ago = nonAbsMinDiff > 0 ? 'from now' : 'ago';
        if (diffInDays >= 1) {
            return `(${diffInDays} day${diffInDays > 1 ? 's' : ''} ${ago})`;
        } else if (diffInHours >= 1) {
            return `(${diffInHours} hour${diffInHours > 1 ? 's' : ''} ${ago})`;
        } else {
            return `(${diffInMinutes} hour${diffInMinutes > 1 ? 's' : ''} ${ago})`;
        }*/

        //const diffInDays = diffDuration.days(); //const diffInDays = Math.floor(diffDuration.asDays()); to grab total days
        //const diffInHours = diffDuration.hours(); //const diffInHours = Math.floor(diffDuration.asHours()); to grab total hours
        //const diffInMinutes = diffDuration.minutes(); //const diffInMinutes = Math.floor(diffDuration.asMinutes());  to grab total minutes
        //if (diffInDays > 0) {
        //    return `[in ${diffInDays} day(s) ${diffInHours} hour(s) ${diffInMinutes} minute(s)]`;
        //} else if (diffInHours > 0) {
        //    return diffInHours + ' hour(s) ' + diffInMinutes + ' minute(s)';
        //} else {
        //    return diffInMinutes + ' minute(s)';
        //}
    }

    useEffect(() => {
        setLoading(true);
        //fetchApi(`/api/Asset/GetServiceStatus/${props.VehicleID}`)
        fetchApi(`/api/Asset/GetServiceStatus`, { vehicleID: props.VehicleID, AssetServiceStatusID: props.AssetServiceStatusID }, 'POST')
            .then((response) => {
                elementListDisplayOrder.length = 0;
                //Note: VehicleAssetService, means we're grabbing the info from the vehicle table (current and next)
                //Note: AssetService, means we're grabbing it from the asset record/history, if available                
                const currentVehicleAssetServiceStatusDateTime = Moment.utc(response.CurrentVehicleAssetStatusDateTime).startOf('minute');
                const nextVehicleAssetServiceStatusDateTime = Moment.utc(response.NextVehicleAssetStatusDateTime).startOf('minute');

                const currentVehicleServiceStatusElementDesc = <h4>Current Vehicle Service Status: <span style={{ color: response.CurrentVehicleServiceStatusColor }}>{response.CurrentVehicleServiceStatusValue}</span></h4>;
                elementListDisplayOrder.push(currentVehicleServiceStatusElementDesc);
                //elementListDisplayOrder.push(<hr style={{ borderWidth: 'thin', borderStyle: 'dotted' }}></hr>);

                const previousAssetServiceStatusDateTime = response.PreviousAssetServiceStatus ? Moment.utc(response.PreviousAssetServiceStatus.StatusDateTime).startOf('minute') : null;
                const nextAssetServiceStatusDateTime = response.NextAssetServiceStatus ? Moment.utc(response.NextAssetServiceStatus.StatusDateTime).startOf('minute') : null;
                const latestAssetServiceStatusDateTime = response.LatestAssetServiceStatus ? Moment.utc(response.LatestAssetServiceStatus.StatusDateTime).startOf('minute') : null;

                const currentVehicleAssetServiceStatusElementDesc = <h6 className="mb-1">{currentVehicleAssetServiceStatusDateTime > dateTimeUtcNow ? 'Next' : 'Went'} <span style={{ color: response.CurrentVehicleAssetServiceStatusColor }}>{response.CurrentVehicleAssetServiceStatusValue.toLowerCase()}</span>
                    &nbsp;on {currentVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST {compareMoments(currentVehicleAssetServiceStatusDateTime, dateTimeUtcNow)}
                </h6>;

                const nextStatusJsxElementDescription = <h6 className="mb-1">{nextVehicleAssetServiceStatusDateTime > dateTimeUtcNow ? 'Next' : 'Went'} <span style={{ color: response.NextVehicleAssetServiceStatusColor }}>{response.NextVehicleAssetServiceStatusValue.toLowerCase()}</span>
                    &nbsp;on {nextVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST {compareMoments(nextVehicleAssetServiceStatusDateTime, dateTimeUtcNow)}
                </h6>;

                let currentAfterOnLoadJsxElementDescription: JSX.Element;
                let nextAfterOnLoadJsxElementDescription: JSX.Element;
                //if (response.CurrentVehicleServiceStatus === 0) {
                if (dateTimeUtcNow > currentVehicleAssetServiceStatusDateTime && dateTimeUtcNow > nextVehicleAssetServiceStatusDateTime) {//&& currentVehicleAssetServiceStatusDateTime > next) {
                    if (response.CurrentVehicleServiceStatus === 0) { //on a load
                        // font-italic
                        currentAfterOnLoadJsxElementDescription = <h6 className="mb-1">
                            * Since there are no future service status records, the vehicle will
                            show <span style={{ color: response.CurrentVehicleAssetServiceStatusColor }}>{response.CurrentVehicleAssetServiceStatusValue.toLowerCase()}</span> once
                            all planned loads are complete</h6>;
                    }
                    //setNextVehicleAssetServiceLabel(<h4>Next Vehicle Status: <span style={{ color: response.CurrentVehicleAssetServiceStatusColor }}>{response.CurrentVehicleAssetServiceStatusValue}</span></h4>);
                }

                if (currentVehicleAssetServiceStatusDateTime > nextVehicleAssetServiceStatusDateTime) {
                    elementListDisplayOrder.push(currentVehicleAssetServiceStatusElementDesc);
                    if (currentAfterOnLoadJsxElementDescription)
                        elementListDisplayOrder.push(currentAfterOnLoadJsxElementDescription);
                    elementListDisplayOrder.push(nextStatusJsxElementDescription);
                    if (nextAfterOnLoadJsxElementDescription)
                        elementListDisplayOrder.push(nextAfterOnLoadJsxElementDescription);
                } else {
                    elementListDisplayOrder.push(nextStatusJsxElementDescription);
                    if (nextAfterOnLoadJsxElementDescription)
                        elementListDisplayOrder.push(nextAfterOnLoadJsxElementDescription);
                    elementListDisplayOrder.push(currentVehicleAssetServiceStatusElementDesc);
                    if (currentAfterOnLoadJsxElementDescription)
                        elementListDisplayOrder.push(currentAfterOnLoadJsxElementDescription);
                }

                setServiceStatusCodeList(response.ServiceStatusCodeList);
                setCurrentTrip(response.CurrentTrip);
                setPlannedLoads(response.PlannedLoads);
                setShowInServiceMode(response.ShowInServiceInNewEntrySection);

                if (isEditMode) {
                    //UPDATE PROFILE
                    var currentAssetService = response.CurrentAssetServiceStatus as AssetServiceStatusViewModel;
                    const selectedAssetService_StatusDT = Moment.utc(currentAssetService.StatusDateTime);
                    setServiceStatusDateTime(selectedAssetService_StatusDT.tz("America/New_York").toDate());
                    setAddress({
                        City: currentAssetService.City, State: currentAssetService.State,
                        Name: '', AddressLine1: '', AddressLine2: '', ZipCode: '', Coordinates: { Latitude: 0, Longitude: 0 }
                    });
                    setComments(currentAssetService.Comments);
                    setIsConfirmed(currentAssetService.IsConfirmed);
                    setServiceStatusCode(currentAssetService.Status);
                    //Determines if INS or OOS new entry should display (e.g. if 1=IN SERVICE, show OOS entry/display form)
                    setShowInServiceMode(currentAssetService.Status > 1 ? false : true);
                    setAddOrUpdateVehicleServiceStatusLabel(<span style={{ color: currentAssetService.StatusColor }}>
                        {currentAssetService.StatusValue}</span>);

                    if (previousAssetServiceStatusDateTime)
                        setAddOrUpdateMinServiceStatusDate(previousAssetServiceStatusDateTime.clone().tz("America/New_York").toDate());
                    else //since no prior asset service record exists, get the min from the vehicle record
                        setAddOrUpdateMinServiceStatusDate(Moment.min([currentVehicleAssetServiceStatusDateTime, nextVehicleAssetServiceStatusDateTime]).add(1, 'minute').tz("America/New_York").toDate());

                    if (nextAssetServiceStatusDateTime) {
                        if (nextAssetServiceStatusDateTime > selectedAssetService_StatusDT)
                            setAddOrUpdateMaxServiceStatusDate(nextAssetServiceStatusDateTime.clone().tz("America/New_York").toDate());
                    }
                } else {
                    //NEW PROFILE    
                    setAddOrUpdateVehicleServiceStatusLabel(<span style={{ color: response.NewVehicleAssetServiceStatusColor }}>{response.NewVehicleAssetServiceStatusValue}</span>);

                    setAddress({
                        City: response.VehicleLastAvailableCity, State: response.VehicleLastAvailableState,
                        Name: '', AddressLine1: '', AddressLine2: '', ZipCode: '', Coordinates: { Latitude: 0, Longitude: 0 }
                    });

                    const newRecAfterPrefix = "* The new status record date/time must be after the ";

                    setServiceStatusDateTime(dateTimeUtcNow.clone().tz("America/New_York").toDate());
                    if (latestAssetServiceStatusDateTime) {
                        if (latestAssetServiceStatusDateTime >= dateTimeUtcNow)
                            setServiceStatusDateTime(latestAssetServiceStatusDateTime.clone().add(1, 'minute').tz("America/New_York").toDate());
                        setAddOrUpdateMinServiceStatusDate(latestAssetServiceStatusDateTime.clone().tz("America/New_York").toDate());
                        setNewRecordMustBeAfterLastServiceLabel(<h6 className="mb-1">{newRecAfterPrefix}<span style={{ color: response.LatestAssetServiceStatus.StatusColor }}>{response.LatestAssetServiceStatus.StatusValue.toLowerCase()}</span> date/time of {latestAssetServiceStatusDateTime.clone().tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST {compareMoments(latestAssetServiceStatusDateTime, dateTimeUtcNow)}</h6>);
                    } else if (nextVehicleAssetServiceStatusDateTime >= currentVehicleAssetServiceStatusDateTime) {
                        if (nextVehicleAssetServiceStatusDateTime >= dateTimeUtcNow)
                            setServiceStatusDateTime(nextVehicleAssetServiceStatusDateTime.clone().add(1, 'minute').tz("America/New_York").toDate());
                        setAddOrUpdateMinServiceStatusDate(nextVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").toDate());
                        setNewRecordMustBeAfterLastServiceLabel(<h6 className="mb-1">{newRecAfterPrefix}<span style={{ color: response.NextVehicleAssetServiceStatusColor }}>{response.NextVehicleAssetServiceStatusValue.toLowerCase()}</span> date/time of {nextVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST {compareMoments(nextVehicleAssetServiceStatusDateTime, dateTimeUtcNow)}</h6>);
                    } else {
                        if (currentVehicleAssetServiceStatusDateTime >= dateTimeUtcNow)
                            setServiceStatusDateTime(currentVehicleAssetServiceStatusDateTime.clone().add(1, 'minute').tz("America/New_York").toDate());
                        setAddOrUpdateMinServiceStatusDate(currentVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").toDate());
                        setNewRecordMustBeAfterLastServiceLabel(<h6 className="mb-1">{newRecAfterPrefix}<span style={{ color: response.CurrentVehicleAssetServiceStatusColor }}>{response.CurrentVehicleAssetServiceStatusValue.toLowerCase()}</span> date/time of {currentVehicleAssetServiceStatusDateTime.clone().tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST {compareMoments(currentVehicleAssetServiceStatusDateTime, dateTimeUtcNow)}</h6>);
                    }
                }

                setElementListDisplayOrder(elementListDisplayOrder);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });

    }, [props.VehicleID, isEditMode, elementListDisplayOrder, props.AssetServiceStatusID]);

    const _save = () => {
        if (canSave === false)
            return;

        setLoading(true);

        const data = {
            StatusDateTime: serviceStatusDateTime,
            Address: address,
            Comments: comments,
            StatusCode: serviceStatusCode > -1 ? serviceStatusCode : 1, //1-In Service
            IsConfirmed: isConfirmed,
            VehicleID: props.VehicleID,
            AssetServiceStatusID: props.AssetServiceStatusID
        }

        fetchApi(`/api/Asset/${props.AssetServiceStatusID > 0 ? 'UpdateServiceStatus' : 'AddServiceStatus'}`, data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    props.CloseDialog(true);
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch(() => {
                setLoading(false);
                alert('Unable to save service status');
            });
    }

    return <>
        {/*<Window title={<span>{isEditMode ? 'Update' : 'New'} {addOrUpdateVehicleServiceStatusLabel} Status Record</span>}*/}
        <Window title={<span>{isEditMode ? 'Update' : 'New'} Service Status Record</span>}
            onClose={() => props.CloseDialog(false)}
            initialWidth={Math.min(800, window.innerWidth)}
            initialHeight={window.innerHeight / 1.1}
        >
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="k-form k-form-md p-0">
                <div>
                    {elementListDisplayOrder.map((x, i) => { return (<span key={i}>{x}</span>); })}
                    <div className="mt-2">
                        {(currentTrip || plannedLoads) && <div className="k-var--normal-background k-card p-2">
                            {currentTrip != null &&
                                <FieldWrapper>
                                    <label>Current Trip</label>
                                    <ExpansionPanel
                                        style={{ borderRadius: '9px' }}
                                        title={`${currentTrip.LoadNumber}`}
                                        subtitle={`${Moment.utc(currentTrip.ShipDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST - 
                                        ${Moment.utc(currentTrip.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST`}
                                        expanded={currentTrip.Expanded}
                                        tabIndex={0}
                                        key={0}
                                        onAction={(event: ExpansionPanelActionEvent) => {
                                            setCurrentTrip({ ...currentTrip, Expanded: !event.expanded });
                                        }}
                                    >
                                        <Reveal>
                                            {currentTrip.Expanded && (
                                                <ExpansionPanelContent>
                                                    <div className="row">
                                                        <div className="col">{currentTrip.LoadNumber}</div>
                                                        <div className="col">{Moment.utc(currentTrip.ShipDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
                                                        <div className="col">{currentTrip.ShipCityStateValue}</div>
                                                        <div className="col">{Moment.utc(currentTrip.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
                                                        <div className="col">{currentTrip.DeliveryCityStateValue}</div>
                                                    </div>
                                                </ExpansionPanelContent>
                                            )}
                                        </Reveal>
                                    </ExpansionPanel>
                                </FieldWrapper>
                            }
                            {plannedLoads != null &&
                                <FieldWrapper>
                                    <label>Planned Loads</label>
                                    <ExpansionPanel
                                        style={{ borderRadius: '9px' }}
                                        title={`${plannedLoads.length} planned load(s)`}
                                        subtitle={`${Moment.utc(plannedLoads[0].ShipDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST - 
                                        ${Moment.utc(plannedLoads[plannedLoads.length - 1].DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST`}
                                        expanded={expandPlannedLoads}
                                        tabIndex={0}
                                        key={0}
                                        onAction={(event: ExpansionPanelActionEvent) => {
                                            setExpandPlannedLoads(!event.expanded);
                                        }}
                                    >
                                        <Reveal>
                                            {expandPlannedLoads && (
                                                <ExpansionPanelContent>
                                                    {plannedLoads.map((plannedLoad) => (
                                                        <div className="row">
                                                            <div className="col">{plannedLoad.LoadNumber}</div>
                                                            <div className="col">{Moment.utc(plannedLoad.ShipDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
                                                            <div className="col">{plannedLoad.ShipCityStateValue}</div>
                                                            <div className="col">{Moment.utc(plannedLoad.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</div>
                                                            <div className="col">{plannedLoad.DeliveryCityStateValue}</div>
                                                        </div>
                                                    ))}
                                                </ExpansionPanelContent>
                                            )}
                                        </Reveal>
                                    </ExpansionPanel>
                                </FieldWrapper>
                            }
                            <br />
                        </div>}
                    </div>

                    <hr style={{ borderWidth: 'thin', borderStyle: 'dotted' }}></hr>
                    <h4 className='fw-light'>{isEditMode ? 'Update' : 'New'} {addOrUpdateVehicleServiceStatusLabel} Status Record</h4>
                    {newRecordMustBeAfterLastServiceLabel}
                    <div className="k-var--normal-background k-card p-2">
                        <FieldWrapper>
                            <DateTimePicker
                                required
                                valid={isAssetServiceStatusDateTimeValid}
                                label={`${showInServiceMode ? 'In' : 'Out of'}  Service On (EST)`}
                                format="MM/dd/yyyy HH:mm"
                                formatPlaceholder="formatPattern"
                                value={serviceStatusDateTime}
                                onChange={(e) => setServiceStatusDateTime(e.value)}
                                width="100%"
                            />
                        </FieldWrapper>
                        {showInServiceMode && <FieldWrapper>
                            <label className="mr-3">{`Automatically mark vehicle as 'Unconfirmed' or 'Confirmed, In Service' at ${Moment.utc(serviceStatusDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST?`}</label>
                            <ButtonGroup>
                                <Button style={{ color: "rgb(230, 230, 0)", fontSize: "small" }} togglable={true} selected={isConfirmed === false} onClick={() => setIsConfirmed(!isConfirmed)}>Unconfirmed</Button>
                                <Button style={{ color: "rgb(0, 255, 0)", fontSize: "small" }} togglable={true} selected={isConfirmed} onClick={() => setIsConfirmed(!isConfirmed)}>Confirmed, In Service</Button>
                            </ButtonGroup>
                            {/*<label className="mr-3">{`Automatically mark vehicle as 'Confirmed In Service' at the specified date/time above (${Moment.utc(serviceStatusDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST})?`}</label>*/}
                            {/*<ButtonGroup>*/}
                            {/*    <Button togglable={true} selected={isConfirmed === false} onClick={() => setIsConfirmed(!isConfirmed)}>No</Button>*/}
                            {/*    <Button togglable={true} selected={isConfirmed} onClick={() => setIsConfirmed(!isConfirmed)}>Yes</Button>*/}
                            {/*</ButtonGroup>*/}
                        </FieldWrapper>}
                        {showInServiceMode === false &&
                            <FieldWrapper>
                                <DropDownList
                                    required
                                    disabled={loading}
                                    data={serviceStatusCodeList?.filter(x => x.ID > 1)}
                                    label="Service Status Reason"
                                    textField="Name"
                                    dataItemKey="ID"
                                    value={serviceStatusCodeList?.find(x => x.ID == serviceStatusCode)}
                                    onChange={(e) => setServiceStatusCode((e.value as IDName).ID)}
                                />
                            </FieldWrapper>
                        }
                        <FieldWrapper>
                            <Input
                                required={hasAddress === false}
                                disabled={loading}
                                maxLength={50}
                                label="City"
                                value={address.City}
                                onChange={(e) => setAddress({ ...address, City: e.value })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <DropDownList
                                required={hasAddress === false}
                                disabled={loading}
                                data={StateCodes}
                                label="State"
                                textField="Name"
                                dataItemKey="ID"
                                value={StateCodes.find(x => x.ID === address.State)}
                                onChange={(e) => setAddress({ ...address, State: e.target.value.ID })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Input
                                required={hasAddress === false}
                                disabled={loading}
                                maxLength={7}
                                label="Zip Code"
                                value={address.ZipCode}
                                onChange={(e) => setAddress({ ...address, ZipCode: e.value })}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Input
                                disabled={loading}
                                maxLength={100}
                                label="Comments"
                                value={comments}
                                onChange={(e) => setComments(e.value)}
                            />
                        </FieldWrapper>
                    </div>
                </div>
            </div>

            <WindowActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
                    {isEditMode ? 'Update' : `Add`} Service Status Record
                </Button>
            </WindowActionsBar>
        </Window>
    </>;
}

export default ServiceStatusDialog;

