import * as React from 'react';
import { fetchApi } from '../../../services/api';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Coordinate } from 'TypeGen/coordinate';

type Props = {
  shipmentId: string;
}

type State = {
  loading: boolean;
  routePath: Coordinate[];
}

type GetMapResponse = {
  ShipmentID: string;
  RoutePath: Coordinate[];
}

export default class LoadBoardMap extends React.Component<Props, State> {

  private map: TrimbleMaps.Map;
  private routingLayer: TrimbleMaps.Route;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      routePath: [],
    }

    this.fetch = this.fetch.bind(this);
    this.setupMap = this.setupMap.bind(this);
    this.renderMap = this.renderMap.bind(this);
  }

  public componentDidMount() {
    this.setupMap();
  }

  public componentWillUnmount() {
    this.map.remove();
  }

  render() {
    return <div id={`loadboard-map-${this.props.shipmentId}`} style={{ width: '100%', height: '500px' }} />;
  }

  private fetch() {
    fetchApi('/api/LoadBoard/Map', { ShipmentID: this.props.shipmentId }, 'POST')
      .then((response: GetMapResponse) => {
        this.setState({ loading: false, routePath: response.RoutePath }, this.renderMap);
      })
  }

  private setupMap() {
    const style = localStorage["darkmode"] === 'false' ? TrimbleMaps.Common.Style.TRANSPORTATION : TrimbleMaps.Common.Style.TRANSPORTATION_DARK;
    this.map = new TrimbleMaps.Map({
      container: `loadboard-map-${this.props.shipmentId}`,
      style: style,
      center: [-83.3083,42.2079],
      zoom: 12,
      scrollZoom: false,
      attributionControl: false,
    });

    var navControl = new TrimbleMaps.NavigationControl();
    this.map.addControl(navControl, 'top-left');
    this.map.setDarkMode(style === TrimbleMaps.Common.Style.TRANSPORTATION_DARK);

    this.map.on('load', () => {
      this.map.setWeatherRadarVisibility(true);

      this.fetch();
    });
  }

  private renderMap() {
    var coords = this.state.routePath.map(x => new TrimbleMaps.LngLat(x.Longitude, x.Latitude));
    this.routingLayer = new TrimbleMaps.Route({
      routeId: "QuoteRoute",
      stops: coords,
      bordersOpen: false,
      highwayOnly: false,
      tollDiscourage: true
    });
    this.routingLayer.addTo(this.map);
  }
}
