import { combineReducers, Store, ReducersMapObject } from 'redux';
import { configureStore as createStore } from '@reduxjs/toolkit';
import { ApplicationState, reducers } from './store';

export default function configureStore() {
    const allReducers = buildRootReducer(reducers);
    const store = createStore({
        reducer: allReducers,
        middleware: getDefaultMiddleware =>
          getDefaultMiddleware({
            serializableCheck: false,
          }),
    }) as Store<ApplicationState>;

    return store;
}

function buildRootReducer(allReducers: ReducersMapObject) {
    return combineReducers<ApplicationState>(allReducers);
}
