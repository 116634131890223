import { DataSourceRequestState, toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Button, ButtonGroup, FloatingActionButton } from '@progress/kendo-react-buttons';
import { DateInput, DateInputProps, SelectionRange } from '@progress/kendo-react-dateinputs';
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnProps, GridDataStateChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { Badge, BadgeContainer, Loader } from "@progress/kendo-react-indicators";
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { JsonResponse } from 'TypeGen/json-response';
import { useDebouncedCallback } from 'use-debounce';
import DateOnlyCell from '../../components/cells/DateOnlyCell';
import YesNoCell from '../../components/cells/YesNoCell';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDateRangePicker from '../../components/SimpleDateRangePicker';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';
import { Title } from '../../utils/title';
import { isNullOrWhiteSpace } from '../../utils/utils';
import TrailerAutoComplete from '../AssetTrailers/TrailerAutoComplete';
import VehicleAutoComplete from '../AssetVehicles/VehicleAutoComplete';
import Documents, { DocumentEntityType } from '../Documents/Documents';
import ColumnMenu from '../LoadBoard/BidHistory/ColumnMenu';
import { IDCell } from './cells/IDCell';
import { StatusCell } from './cells/StatusCell';
import EmailForm from './dialogs/EmailForm';
import RejectPurchaseOrderDialog from './dialogs/RejectPurchaseOrderDialog';
import RequestApproval from './dialogs/RequestApproval';
import SetAsReadyToPost from './dialogs/SetAsReadyToPost';
import { EnterLineItem } from './EnterLineItem';
import './index.css';
import PurchaseOrderVendorAutoComplete from './PurchaseOrderVendorAutoComplete';
import { arrowRotateCwIcon, downloadIcon, filterClearIcon, plusIcon, trashIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  purchaseOrderNumber: string;
};

export enum PurchaseOrderStatus {
  Rejected = 90,
  Created = 100,
  Approved = 110,
  ReadyToPost = 115,
  Invoiced = 120,
  Cancelled = 170,
}

type PurchaseOrderGet = {
  PurchaseOrderID: number;
  PurchaseOrderNumber: number;
  Status: PurchaseOrderStatus;
  VendorName: string;
  PurchaseOrderVendorID: number | null;
  Description: string;
  WebsiteLink: string;
  ForStock: boolean | null,
  CustomerChargeBack: boolean,
  OwnerChargeBack: boolean,
  VehicleID: number,
  TrailerID: number,
  VendorPaymentTerms: number,
  Version: number;
  DocumentCount: number;
  Timeline: PurchaseOrderTimelineItem[];
  LineItems: PurchaseOrderLineItem[];
  Links: ILink[];
}

type PurchaseOrderTimelineItem = {
  Date: Date | null;
  Status: string;
  By: string;
  Completed: boolean;
}

type PurchaseOrderUpdate = {
  PurchaseOrderID: number,
  VendorName: string,
  PurchaseOrderVendorID: number | null,
  Description: string,
  WebsiteLink: string,
  ForStock: boolean | null,
  CustomerChargeBack: boolean,
  OwnerChargeBack: boolean,
  VehicleID: number | null,
  TrailerID: number | null,
  LineItems: PurchaseOrderLineItem[];
}

export type PurchaseOrderLineItem = {
  PurchaseOrderLineItemID: number;
  Description: string;
  UnitPrice: number;
  Quantity: number;
  GLCode: number;
}

type PurchaseOrderHistoryViewItem = {
  PurchaseOrderID: number;
  PurchaseOrderNumber: number;
  Status: PurchaseOrderStatus;
  VendorName: string;
  Description: string;
  WebsiteLink: string;
  TotalQuantity: number;
  Total: number;
  ForStock: boolean | null;
  CreatedByName: string;
  CreatedDateTime: Date;
}

const CustomDateInput = (props: DateInputProps) => {
  return (
    <DateInput {...props} label={undefined} width={92} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
  );
};

const PurchaseOrders = () => {
  const _exportTemplate = useRef<ExcelExport | null>(null);
  const { purchaseOrderNumber: purchaseOrderNumberParam } = useParams<RouteComponentParams>();
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderHistoryViewItem[]>([]);
  const [total, setTotal] = useState(0);
  const [filterStatus, setFilterStatus] = useState<PurchaseOrderStatus>();
  const [mine, setMine] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showRequestApprovalDialog, setShowRequestApprovalDialog] = useState(false);
  const [showReadyToPostDialog, setShowReadyToPostDialog] = useState(false);
  const [dataState, setDataState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 50,
    sort: [{
      field: "PurchaseOrderID",
      dir: "desc"
    }],
    filter: { logic: 'and', filters: [] }
  });
  const history = useHistory();

  // Current PO
  const [purchaseOrderId, setPurchaseOrderId] = React.useState(0);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = React.useState(0);
  const [status, setStatus] = React.useState<PurchaseOrderStatus>();
  const [vendorName, setVendorName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [websiteLink, setWebsiteLink] = React.useState('');
  const [forStock, setForStock] = React.useState<boolean>(null);
  const [customerChargeBack, setCustomerChargeBack] = React.useState(false);
  const [ownerChargeBack, setOwnerChargeBack] = React.useState(false);
  const [vehicleId, setVehicleId] = useState<number | undefined | null>(null);
  const [trailerId, setTrailerId] = useState<number | undefined | null>(null);
  const [version, setVersion] = React.useState(0);
  const [vendorPaymentTerms, setVendorPaymentTerms] = React.useState(30);
  const [documentCount, setDocumentCount] = React.useState(0);
  const [timeline, setTimeline] = React.useState<PurchaseOrderTimelineItem[]>([]);
  const [lineItems, setLineItems] = React.useState<PurchaseOrderLineItem[]>([]);
  const [links, setLinks] = React.useState<ILink[]>([]);
  const [purchaseOrderVendorID, setPurchaseOrderVendorID] = React.useState<number>(null);
  const [showRejectPODialog, setShowRejectPODialog] = useState(false);

  const [dateRangeValue, setDateRangeValue] = useState<SelectionRange>({
    start: Moment.utc(new Date()).tz("America/New_York").subtract(12, 'months').startOf('day').toDate(),
    end: Moment.utc(new Date()).tz("America/New_York").endOf('day').toDate(),
  });
  const [dateRangeValueFilter, setDateRangeValueFilter] = useState<SelectionRange>(dateRangeValue);
  const debounceDateRangeValueFilter = useDebouncedCallback((value: any) => { setDateRangeValueFilter(value); }, 750);

  const [updateRequiredShowUpdateButtonOnly, setUpdateRequiredShowUpdateButtonOnly] = React.useState(false);

  const showVendorDropDown = purchaseOrderVendorID > 0 || status >= PurchaseOrderStatus.Approved;

  const hasSatisfiedBasicInputRequirements = lineItems.every(x => x.Quantity !== 0 && x.UnitPrice !== 0 && isNullOrWhiteSpace(x.Description) === false)
    && isNullOrWhiteSpace(vendorName) === false

  const hasSatisfiedApprovalInputRequirements = hasSatisfiedBasicInputRequirements && lineItems.every(x => x.GLCode > 0)
    && purchaseOrderVendorID > 0 && isNullOrWhiteSpace(description) === false && isNullOrWhiteSpace(websiteLink) === false

  //const [purposeDescription, setPurposeDescription] = React.useState('');
  //const [purposeDescriptionFilter, setPurposeDescriptionFilter] = React.useState('');
  //const debouncePurposeDescriptionFilter = useDebouncedCallback((value: any) => { setPurposeDescriptionFilter(value); }, 750);

  const create = (createCount: number = 1) => {
    setLoading(true);
    fetchApi('/api/PurchaseOrder/Create', { CreateCount: createCount }, 'POST')
      .then((response: { PurchaseOrderID: number, PurchaseOrderNumber: number }) => {
        history.push(`/PurchaseOrder/Index/${response.PurchaseOrderNumber}`);
        refreshList();
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const copy = () => {
    setLoading(true);
    fetchApi(`/api/PurchaseOrder/Copy/${purchaseOrderNumber}`, {}, 'POST')
      .then((response: { PurchaseOrderNumber: number }) => {
        history.push(`/PurchaseOrder/Index/${response.PurchaseOrderNumber}`);
        refreshList();
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const update = () => {
    const data = {
      PurchaseOrderID: purchaseOrderId,
      VendorName: vendorName,
      PurchaseOrderVendorID: purchaseOrderVendorID,
      Description: description,
      WebsiteLink: websiteLink,
      ForStock: forStock,
      CustomerChargeBack: customerChargeBack,
      OwnerChargeBack: ownerChargeBack,
      VehicleID: vehicleId,
      TrailerID: trailerId,
      Version: version,
      LineItems: lineItems,
    } as PurchaseOrderUpdate;
    setLoading(true);
    fetchApi('/api/PurchaseOrder/Update', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          get(purchaseOrderNumber);
          refreshList();
        } else {
          setLoading(false);
          alert(response.ErrorMessage);
        }
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const invoiced = () => {
    const data = {
      PurchaseOrderID: purchaseOrderId,
    };
    setLoading(true);
    fetchApi('/api/PurchaseOrder/MarkInvoiced', data, 'POST')
      .then(() => {
        get(purchaseOrderNumber);
        refreshList();
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const cancelled = () => {
    const data = {
      PurchaseOrderID: purchaseOrderId,
    };
    setLoading(true);
    fetchApi('/api/PurchaseOrder/MarkCancelled', data, 'POST')
      .then(() => {
        get(purchaseOrderNumber);
        refreshList();
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const approvePO = () => {
    const data = {
      PurchaseOrderID: purchaseOrderId,
      Version: version
    };
    setLoading(true);
    fetchApi(links.find(x => x.Name == "ApprovePurchaseOrder").Link, data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          get(purchaseOrderNumber);
          refreshList();
        } else {
          setLoading(false);
          alert(response.ErrorMessage);
        }
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  const rejectPO = (rejectNote: string) => {
    const data = {
      PurchaseOrderID: purchaseOrderId,
      Version: version,
      Comment: rejectNote
    };
    setLoading(true);
    fetchApi(links.find(x => x.Name == "RejectPurchaseOrder").Link, data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          get(purchaseOrderNumber);
          refreshList();
        } else {
          setLoading(false);
          alert(response.ErrorMessage);
        }
      }).catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  //todo:duedate
  const get = (purchaseOrderNumber: number) => {
    setLoading(true);
    fetchApi(`/api/PurchaseOrder/Get/${purchaseOrderNumber}`, {}, 'GET')
      .then((response: PurchaseOrderGet) => {
        setLoading(false);
        setPurchaseOrderId(response.PurchaseOrderID);
        setPurchaseOrderNumber(response.PurchaseOrderNumber);
        setStatus(response.Status);
        setVendorName(response.VendorName); //deprecated, use setPurchaseOrderVendorID
        setPurchaseOrderVendorID(response.PurchaseOrderVendorID);
        setDescription(response.Description);
        setWebsiteLink(response.WebsiteLink);
        setForStock(response.ForStock);
        setCustomerChargeBack(response.CustomerChargeBack);
        setOwnerChargeBack(response.OwnerChargeBack);
        setVehicleId(response.VehicleID);
        setTrailerId(response.TrailerID);
        setVendorPaymentTerms(response.VendorPaymentTerms)
        setVersion(response.Version);
        setDocumentCount(response.DocumentCount);
        setTimeline(response.Timeline);
        setLineItems(response.LineItems);
        setLinks(response.Links);
        setUpdateRequiredShowUpdateButtonOnly(false);
      });
  }

  const statusBadge = (status: PurchaseOrderStatus) => {
    switch (status) {
      case 90:
      case 170:
        return <span className="badge badge-danger p-1">{statusName(status)}</span>;
      case 100:
        return <span className="badge badge-secondary p-1">{statusName(status)}</span>;
      case 110:
        return <span className="badge badge-info p-1">{statusName(status)}</span>;
      case 115:
        return <span className="badge badge-warning p-1">{statusName(status)}</span>;
      case 120:
        return <span className="badge badge-primary p-1">{statusName(status)}</span>;
      default:
        return <span className="badge badge-secondary p-1">N/A</span>;
    }
  }

  const statusName = (status: PurchaseOrderStatus) => {
    switch (status) {
      case 90:
        return "Rejected";
      case 100:
        return "Created";
      case 110:
        return "Approved";
      case 115:
        return "Ready To Post";
      case 120:
        return "Invoiced";
      case 170:
        return "Cancelled";
      default:
        return "N/A";
    }
  }

  const refreshList = useCallback(() => {
    const queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state 
    const hasGroups = dataState.group && dataState.group.length;

    if (dateRangeValueFilter.start && dateRangeValueFilter.end && dateRangeValueFilter.start <= dateRangeValueFilter.end) {
      fetchApi(`/api/PurchaseOrder/History_Read?${queryStr}`, { Status: filterStatus, Mine: mine, DateRange: dateRangeValueFilter }, 'POST') //, PurposeDescription: purposeDescriptionFilter 
        .then(({ Data, Total }) => {
          const data = hasGroups ? translateDataSourceResultGroups(Data) : Data;
          setPurchaseOrders(data);
          setTotal(Total);
        });
    }
  }, [filterStatus, mine, dataState, dateRangeValueFilter]) //purposeDescriptionFilter 

  useEffect(() => {
    refreshList();
  }, [refreshList, filterStatus, mine, dataState]);

  const excelExportTemplate = () => {
    if (_exportTemplate.current !== null) {
      _exportTemplate.current.save();
    }
  };

  const addLineItem = () => {
    setLineItems(oldLineItems => [...oldLineItems, {
      PurchaseOrderLineItemID: 0,
      Description: '',
      UnitPrice: 0,
      Quantity: 1,
      GLCode: 0
    }]);

  }

  const removeLineItem = () => {
    setLineItems(oldLineItems => oldLineItems.filter((element, index) => index < oldLineItems.length - 1));
  }

  const updateLineItem = (newLineItem: PurchaseOrderLineItem, index: number) => {
    setLineItems(oldLineItems => [
      ...oldLineItems.slice(0, index),
      newLineItem,
      ...oldLineItems.slice(index + 1)
    ]);

    setUpdateRequiredShowUpdateButtonOnly(true);
  }

  useEffect(() => {
    if (purchaseOrderNumberParam) {
      get(parseInt(purchaseOrderNumberParam));
    } else {
      setPurchaseOrderId(0);
      setPurchaseOrderNumber(0);
    }
  }, [purchaseOrderNumberParam]);

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  }

  const columnProps = (field: string): Partial<GridColumnProps> => {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: isColumnActive(field, dataState) ? 'active' : ''
    };
  }

  const isColumnActive = (field: string, dataState: any) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  return <div className="container-fluid" style={{ position: "relative" }}>
    <Title string={purchaseOrderId ? `PO #${purchaseOrderNumber}` : 'Purchase Orders'} />
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    {!purchaseOrderId && <br />}
    {purchaseOrderId > 0 && <>
      {showDocumentDialog &&
        <Documents
          IsWindow
          EntityType={DocumentEntityType.PurchaseOrder}
          EntityId={purchaseOrderId}
          EntityNumber={`PO # ${purchaseOrderNumber}`}
          CloseDocumentModal={() => setShowDocumentDialog(false)}
        />
      }
      {showEmailDialog &&
        <EmailForm
          PurchaseOrderID={purchaseOrderId}
          CloseDialog={() => setShowEmailDialog(false)}
        />
      }
      {showRequestApprovalDialog &&
        <RequestApproval
          PurchaseOrderID={purchaseOrderId}
          CloseDialog={(refresh) => {
            if (refresh) get(purchaseOrderNumber);
            setShowRequestApprovalDialog(false);
          }}
        />
      }
      {showReadyToPostDialog &&
        <SetAsReadyToPost
          PurchaseOrderID={purchaseOrderId}
          VendorPaymentTerms={vendorPaymentTerms}
          CloseDialog={(refresh) => {
            if (refresh) get(purchaseOrderNumber);
            setShowReadyToPostDialog(false);
          }}
        />
      }
      {showRejectPODialog &&
        <RejectPurchaseOrderDialog
          CloseDialog={(rejectNote: string) => {
            if (isNullOrWhiteSpace(rejectNote) === false)
              rejectPO(rejectNote);
            setShowRejectPODialog(false);
          }}
        />
      }
      <br />
      <div className="form-row">
        <div className="col-md-6">
          <h2>Purchase Order # {purchaseOrderNumber} {statusBadge(status)}</h2>
        </div>
        <div className="col-md-6 text-right">
          {updateRequiredShowUpdateButtonOnly ? <> {links.find(x => x.Name == "Update")
            ? <Button themeColor="success" className="mr-2" disabled={loading} onClick={update}>Unsaved Changes, Update PO?</Button>
            : <Button themeColor="error" className="mr-2" disabled>Update PO</Button>}
          </> : <>{links.find(x => x.Name == "Print") && <a className="btn k-button mr-2" href={`/api/PurchaseOrder/PurchaseOrderForm/${purchaseOrderId}`}>Print</a>}
            {links.find(x => x.Name == "Email") && <Button className="mr-2" onClick={() => setShowEmailDialog(true)}>Email</Button>}


            {links.find(x => x.Name == "ApprovePurchaseOrder") && <Button themeColor="success" className="mr-2" disabled={loading || hasSatisfiedBasicInputRequirements === false} onClick={() => approvePO()}>Approve P.O.</Button>}
            {links.find(x => x.Name == "RejectPurchaseOrder") && <Button themeColor="error" className="mr-2" disabled={loading || hasSatisfiedBasicInputRequirements === false} onClick={() => setShowRejectPODialog(true)}>Reject P.O.</Button>}


            {links.find(x => x.Name == "RequestApproval") && <Button className="mr-2" disabled={loading || hasSatisfiedBasicInputRequirements === false} onClick={() => setShowRequestApprovalDialog(true)}>Request Approval</Button>}
            <BadgeContainer className="mr-2">
              <Button onClick={() => setShowDocumentDialog(true)}>Documents</Button>
              {documentCount > 0 && <Badge>{documentCount}</Badge>}
            </BadgeContainer>
            <Button className="mr-2" disabled={loading} onClick={copy}>Duplicate</Button>
            {links.find(x => x.Name == "ReadyToPost") && <Button className="mr-2" disabled={loading || hasSatisfiedApprovalInputRequirements === false} onClick={() => setShowReadyToPostDialog(true)}>Set Ready To Post</Button>}
            {links.find(x => x.Name == "MarkInvoiced") && <Button className="mr-2" disabled={loading || hasSatisfiedApprovalInputRequirements === false} onClick={() => window.confirm("Are you sure want to manually set this purchase order as invoiced?  This action will exclude this PO from the GP import") && invoiced()}>Mark Invoiced</Button>}
            {links.find(x => x.Name == "MarkCancelled") && <Button className="mr-2" disabled={loading} onClick={() => window.confirm("Are you sure want to cancel this purchase order?") && cancelled()} icon="trash" svgIcon={trashIcon} />}
          </>}
        </div>
      </div>
      <ul className="po-timeline">
        {timeline.map((item, index) => {
          return <li key={index} className={`li ${item.Completed ? (status === 90 || status == 170) ? 'danger' : 'success' : ''}`}>
            <div className="timestamp">
              {item.Completed ? <span className="author">{item.By}</span> : <span className="author">&nbsp;</span>}
              {item.Completed ? <span className="date">
                {Moment.utc(item.Date).tz("America/New_York").format("MM/DD/YYYY")}
              </span> : <span className="date">&nbsp;</span>}
            </div>
            <div className="status">
              <h4>{item.Status}</h4>
            </div>
          </li>
        })}
      </ul>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            {showVendorDropDown && <><label className="col-form-label">GP Vendor</label>
              <PurchaseOrderVendorAutoComplete
                required
                placeholder={'Select GP Vendor'}
                selectedPurchaseOrderVendorID={purchaseOrderVendorID}
                style={{ width: '100%' }}
                onSelectedPurchaseOrderVendor={(e) => {
                  setPurchaseOrderVendorID(e?.PurchaseOrderVendorID);
                  if (e?.PurchaseOrderVendorID > 0)
                    setVendorName(e?.VendorName);
                  setUpdateRequiredShowUpdateButtonOnly(true);
                }}
              /></>}
            <><label className="col-form-label">Vendor (freeform)</label>
              <Input
                required
                value={vendorName}
                maxLength={100}
                placeholder="Vendor Name"
                onChange={(e) => { setVendorName(e.target.value.toString()); setUpdateRequiredShowUpdateButtonOnly(true); }}
                style={{ width: "100%" }}
              /></>
          </div>
          <div className="form-group">
            <label className="col-form-label">Reference Number Field</label>
            <div className="input-group">
              <Input
                required={status >= PurchaseOrderStatus.Approved && status <= PurchaseOrderStatus.Invoiced}
                value={websiteLink}
                maxLength={100}
                className="form-control"
                placeholder="Reference Number"
                onChange={(e) => { setWebsiteLink(e.target.value.toString()); setUpdateRequiredShowUpdateButtonOnly(true); }}
              />
            </div>
          </div>
          <div className="form-group">
            <Checkbox
              value={forStock}
              onChange={(e) => { setForStock(e.value); setUpdateRequiredShowUpdateButtonOnly(true); }}
              label="For Stock"
            />
            &nbsp;&nbsp;
            <Checkbox
              value={customerChargeBack}
              onChange={(e) => {
                setCustomerChargeBack(e.value);
                setOwnerChargeBack(false);
                setUpdateRequiredShowUpdateButtonOnly(true);
              }}
              label="Customer Charge Back"
            />
            &nbsp;&nbsp;
            <Checkbox
              value={ownerChargeBack}
              onChange={(e) => {
                setOwnerChargeBack(e.value);
                setCustomerChargeBack(false);
                setUpdateRequiredShowUpdateButtonOnly(true);
              }}
              label="Owner Charge Back"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Purpose</label>
            <TextArea
              required={status >= PurchaseOrderStatus.Approved && status <= PurchaseOrderStatus.Invoiced}
              value={description}
              maxLength={280}
              placeholder="Describe Purpose"
              onChange={(e) => { setDescription(e.value); setUpdateRequiredShowUpdateButtonOnly(true); }}
              style={{ width: "100%" }}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">Vehicle #</label>
            <div className="input-group">
              <VehicleAutoComplete
                includeRecentlyRetiredVehicles
                selectedVehicleID={vehicleId}
                onSelectedVehicle={(e) => {
                  setVehicleId(e?.VehicleID);
                  setUpdateRequiredShowUpdateButtonOnly(true);
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-form-label">Trailer #</label>
            <div className="input-group">
              <TrailerAutoComplete
                includeRecentlyRetiredTrailers
                selectedTrailerID={trailerId}
                onSelectedTrailer={(e) => {
                  setTrailerId(e?.TrailerID);
                  setUpdateRequiredShowUpdateButtonOnly(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {lineItems.map((lineItem, index) => <EnterLineItem key={index} index={index} status={status} lineItem={lineItem} onChange={updateLineItem} />)}
      <div className="form-row mb-3">
        <div className="col-md-6">
          <Button className="btn-secondary" themeColor="primary" onClick={addLineItem}>Add Line Item</Button>
          &nbsp;&nbsp;{lineItems.length > 1 && <Button className="btn btn-secondary" onClick={removeLineItem}>Remove Line Item</Button>}
        </div>
        <div className="col-md-6 text-right font-weight-bold">
          Total: {formatNumber(lineItems.reduce((a, { Quantity, UnitPrice }) => a + (Quantity * UnitPrice), 0), "c")}
        </div>
      </div>
    </>}
    <ExcelExport fileName={`Purchase Orders Template.xlsx`} data={purchaseOrders} ref={_exportTemplate}>
      <ExportColumn field="PurchaseOrderNumber" locked={true} width={80} title="PO #" headerCellOptions={{ background: '#fbff01', color: '#000', bold: true, textAlign: 'right' }} />
      <ExportColumn field="Empty" title="Date" cellOptions={{ format: "yyyy-mm-dd" }} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="VendorName" title="Vendor" width={150} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="WebsiteLink" title="Reference" headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="Empty" title="Unit Price" cellOptions={{ format: "$#,##0.00" }} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true, textAlign: 'right' }} />
      <ExportColumn field="Empty" title="Units Ordered" cellOptions={{ format: "#,##0" }} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true, textAlign: 'right' }} />
      <ExportColumn field="Empty" title="Total" cellOptions={{ format: "$#,##0.00" }} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true, textAlign: 'right' }} />
      <ExportColumn field="Empty" title="Stock" headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="Empty" title="Truck #" headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="Empty" title="Taken By" headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
      <ExportColumn field="Description" title="Notes" width={200} headerCellOptions={{ background: '#fbff01', color: '#000', bold: true }} />
    </ExcelExport>
    <Grid
      scrollable={'none'}
      sortable={{ allowUnsort: true }}
      pageable
      onDataStateChange={onDataStateChange}
      onRowDoubleClick={(event) => {
        history.push(`/PurchaseOrder/Index/${event.dataItem.PurchaseOrderNumber}`);
      }}
      data={purchaseOrders}
      total={total}
      {...dataState}
    >
      <GridToolbar>
        <Button
          icon="filter-clear"
          svgIcon={filterClearIcon}
          title="Clear Filters and Sort"
          onClick={() => {
            setDateRangeValue({
              start: Moment.utc(new Date()).tz("America/New_York").subtract(12, 'months').startOf('day').toDate(),
              end: Moment.utc(new Date()).tz("America/New_York").endOf('day').toDate(),
            });
            setDateRangeValueFilter({
              start: Moment.utc(new Date()).tz("America/New_York").subtract(12, 'months').startOf('day').toDate(),
              end: Moment.utc(new Date()).tz("America/New_York").endOf('day').toDate(),
            });
            setDataState({
              skip: 0,
              take: 50,
              sort: [{
                field: "PurchaseOrderID",
                dir: "desc"
              }],
              filter: { logic: 'and', filters: [] }
            });
          }}
        />
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          onClick={refreshList}
        />
        <Button togglable
          selected={mine == true}
          onClick={() => setMine(mine ? undefined : true)}
        >
          Mine
        </Button>
        <ButtonGroup>
          <Button togglable
            selected={filterStatus === PurchaseOrderStatus.Created}
            onClick={() => setFilterStatus(filterStatus == PurchaseOrderStatus.Created ? null : PurchaseOrderStatus.Created)}
          >
            Created
          </Button>
          <Button togglable
            selected={filterStatus === PurchaseOrderStatus.Rejected}
            onClick={() => setFilterStatus(filterStatus == PurchaseOrderStatus.Rejected ? null : PurchaseOrderStatus.Rejected)}
          >
            Rejected
          </Button>
          <Button togglable
            selected={filterStatus === PurchaseOrderStatus.Approved}
            onClick={() => setFilterStatus(filterStatus == PurchaseOrderStatus.Approved ? null : PurchaseOrderStatus.Approved)}
          >
            Approved
          </Button>
          <Button togglable
            selected={filterStatus === PurchaseOrderStatus.ReadyToPost}
            onClick={() => setFilterStatus(filterStatus == PurchaseOrderStatus.ReadyToPost ? null : PurchaseOrderStatus.ReadyToPost)}
          >
            Ready To Post
          </Button>
          <Button togglable
            selected={filterStatus === PurchaseOrderStatus.Invoiced}
            onClick={() => setFilterStatus(filterStatus == PurchaseOrderStatus.Invoiced ? null : PurchaseOrderStatus.Invoiced)}
          >
            Invoiced
          </Button>
        </ButtonGroup>
        <span>
          <label className="mt-1">Date Range:</label>
          <SimpleDateRangePicker
            value={dateRangeValue}
            valid={dateRangeValue.start && dateRangeValue.end && dateRangeValue.start <= dateRangeValue.end}
            onChange={(e) => {
              setDateRangeValue(e);
              debounceDateRangeValueFilter(e);
            }}
          />
        </span>
        {/*<Input*/}
        {/*  value={purposeDescription}*/}
        {/*  size={125}*/}
        {/*  placeholder="Search Purpose"*/}
        {/*  onChange={(e) => {*/}
        {/*    setPurposeDescription(e.value);*/}
        {/*    debouncePurposeDescriptionFilter(e.value);*/}
        {/*  }}*/}
        {/*/>*/}
        <ButtonGroup>
          <Button
            title="Export Excel"
            icon="download"
            svgIcon={downloadIcon}
            onClick={excelExportTemplate}
          >Template</Button>
        </ButtonGroup>
      </GridToolbar>
      <GridNoRecords>
        No Purchase Orders Found
      </GridNoRecords>
      <Column {...columnProps("PurchaseOrderNumber")} title="PO #" filter="numeric" cell={IDCell} />
      <Column {...columnProps("VendorName")} title="Vendor" />
      <Column {...columnProps("WebsiteLink")} title="Reference" />
      <Column {...columnProps("CreatedDateTime")} title="Created" filter="date" cell={DateOnlyCell} />
      <Column {...columnProps("TotalQuantity")} title="Quantity" filter="numeric" format="{0:n0}" />
      <Column {...columnProps("Total")} title="Total" filter="numeric" format="{0:c}" />
      <Column {...columnProps("Description")} title="Purpose" />
      <Column {...columnProps("ForStock")} title="For Stock" cell={YesNoCell} filter="boolean" />
      <Column {...columnProps("CreatedByName")} title="Created By" />
      <Column field="Status" cell={StatusCell} />
    </Grid>
    <br />
    <FloatingActionButton
      icon="plus"
      svgIcon={plusIcon}
      items={[
        { text: 'Create 1', count: 1, icon: "plus" },
        { text: 'Create 10', count: 10, icon: "plus" }
      ]}
      text="Add POs"
      disabled={loading}
      onItemClick={(e) => create(e.itemProps.count)}
    />
  </div>

}

export default PurchaseOrders;
