import './ColumnMenu.css';
import * as React from 'react';
import { GridColumnMenuSort, GridColumnMenuFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";

export default class ColumnMenu extends React.Component<GridColumnMenuProps> {
  render() {
      return (
      <div>
          <GridColumnMenuSort column={this.props.column} {...this.props} />
          <GridColumnMenuFilter column={this.props.column} {...this.props} expanded filterOperators={this.props.filterOperators} />
      </div>);
  }
}
