import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
  distance: number;
  updateDistance: (newDistance: number) => void;
}

interface State {
  visible: boolean;
  distance: number;
}

export class AdjustDistance extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      distance: props.distance,
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.updateDistance = this.updateDistance.bind(this);
    this.setDistance = this.setDistance.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.distance !== this.props.distance && prevProps.distance === this.state.distance) {
      this.setState({
        distance: this.props.distance
      });
    }
  }

  toggleDialog(e: any) {
    e.preventDefault();

    this.setState({
      visible: !this.state.visible
    });
  }

  closeDialog() {
    this.setState({
      visible: false
    });
  }

  updateDistance(e: any) {
    this.setState({
      distance: e.target.value
    });
  }

  setDistance() {
    this.props.updateDistance(this.state.distance);

    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <li className="nav-item ml-0">
        <a href="#" className="nav-link border-0" onClick={this.toggleDialog}>Search Radius ({formatNumber(this.props.distance, "n0")})</a>
        {this.state.visible && <Dialog title={"Vehicle Search Radius"} onClose={this.closeDialog}>
          <label>
            <NumericTextBox
              width={150}
              value={this.state.distance}
              onChange={this.updateDistance}
              spinners={false}
          /> Miles
          </label>
          <DialogActionsBar>
            <Button themeColor="primary" onClick={this.setDistance}>Done</Button>
          </DialogActionsBar>
        </Dialog>}
      </li>
    );
  }
}
