import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import CustomerInput from "components/CustomerInput";
import { useState } from "react";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { RevenueType1s } from "../../../utils/utils";

type Props = {
  Terms: number;
  BillToCustomer: IDNameViewModel;
  RevenueType1: number;
  onClose: () => void;
  save: (customerId: number, terms: number, updateSalespeople: boolean, revenueType1: number) => void;
};

const TermsList = [
  { ID: 1, Name: "Pre-Paid" },
  { ID: 2, Name: "Collect" },
  { ID: 3, Name: "3rd Party" },
];

const revenueTypes1 = RevenueType1s;

const BillTo = ({ Terms, BillToCustomer, RevenueType1, onClose, save }: Props) => {
  const [terms, setTerms] = useState(Terms);
  const [billToCustomer, setBillToCustomer] = useState(BillToCustomer);
  const [revenueType1, setRevenueType1] = useState(RevenueType1);
  const [updateSalesPeople, setUpdateSalesPeople] = useState(true);

  return <Dialog title={`Update Invoice`} onClose={onClose}>
    <div className="form-group">
      <div className="input-group">
        <DropDownList
          label="Terms"
          data={TermsList}
          textField="Name"
          value={TermsList.find((t) => t.ID === terms)}
          onChange={(e) => setTerms(e.target.value.ID)}
        />
      </div>
    </div>

    <div className="row align-items-center">
      <div className="col-auto">
        <div style={{ width: "200px" }}>
          <label className="mb-0">Bill-To</label>
          <div className="input-group">
            <CustomerInput
              onCreditHold={false}
              placeholder="Bill To Company"
              CustomerID={billToCustomer.ID}
              CustomerNumber={billToCustomer.Name}
              onChange={(e) => {
                setBillToCustomer({ ID: e.CustomerID, Name: e.CustomerNumber });
              }}
            />
          </div>
        </div>
      </div>
      {billToCustomer.ID > 0 && BillToCustomer.ID !== billToCustomer.ID && <div className="col-auto pl-0">
        <div className="mt-3">
          <Checkbox
            checked={updateSalesPeople}
            onChange={() => setUpdateSalesPeople(!updateSalesPeople)}
            label={"Update Salespeople"}
          />
        </div>
      </div>}
    </div>

    <div className="form-group mt-3">
      <div className="input-group">
        <DropDownList
          label="Revenue Allocation"
          style={{ minWidth: "125px" }}
          data={revenueTypes1}
          textField="Name"
          value={revenueTypes1.find((t) => t.ID === revenueType1)}
          onChange={(e) => setRevenueType1(e.target.value.ID)}
        />
      </div>
    </div>

    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button themeColor="primary" disabled={billToCustomer.ID === 0} onClick={() => save(billToCustomer.ID, terms, updateSalesPeople, revenueType1)}>Save</Button>
    </DialogActionsBar>
  </Dialog>
}

export default BillTo;