import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridDetailRow, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import { VehicleTypes } from '../AssetVehicles/Profile';
import CarrierList from './CarrierList';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { plusIcon, xIcon } from '@progress/kendo-svg-icons';

type Props = {};

type State = {
  newGroupName: string;
  dialogOpen: boolean;
  newVehicleTypeID: number | null;
  groups: GroupType[];
}

type GroupType = {
  GroupID: number;
  Name: string;
  Expanded: boolean;
}

class GroupDetail extends GridDetailRow {
  render() {
    return <CarrierList dataItem={this.props.dataItem} />
  }
}

export default class CarrierGroups extends React.Component<Props, State> {

  RemoveCommandCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.state = {
      newGroupName: '',
      dialogOpen: false,
      newVehicleTypeID: null,
      groups: []
    }

    const remove = this.remove.bind(this);

    class RemoveCommandCell extends React.Component<GridCellProps> {
      public render() {
        return <td>
            <Button
              themeColor="error"
              svgIcon={xIcon}
              onClick={() => window.confirm(`Remove group '${this.props.dataItem.Name}'?`) && remove(this.props.dataItem)}
            >
              Group
            </Button>
          </td>
      }
    }

    this.RemoveCommandCell = RemoveCommandCell;

    this.add = this.add.bind(this);
    this.expandChange = this.expandChange.bind(this);
  }

  public componentDidMount() {
    this.fetchGroups();
  }

  render() {
    return <React.Fragment>
      {this.state.dialogOpen && <Dialog
        title="Create Group"
        onClose={() => this.setState({ dialogOpen: false })}
        className='dialog-w800'
      >
        <div className="form-group">
          <label htmlFor="new-group-name">Group Name</label>
          <input
            autoFocus
            id="new-group-name"
            className="form-control"
            type="text"
            placeholder="Enter new group name..."
            value={this.state.newGroupName}
            onChange={(e) => this.setState({ newGroupName: e.target.value })}
          />
        </div>
        <div className="form-group">
          <select
            id="new-group-vehicle-type"
            className="form-control"
            value={this.state.newVehicleTypeID ?? 0}
            onChange={(e) => this.setState({ newVehicleTypeID: parseInt(e.target.value) })}
          >
            <option value={null}>Select a vehicle type...</option>
            {VehicleTypes.map((x) => <option key={x.ID} value={x.ID}>{x.Name}</option>)}
          </select>
        </div>
        <DialogActionsBar layout={'end'}>
          <Button themeColor={'primary'} onClick={this.add}>Create Group</Button>
        </DialogActionsBar>
      </Dialog>}
      <Grid
        style={{ height: '550px' }}
        scrollable='none'
        data={this.state.groups}
        detail={GroupDetail}
        expandField="Expanded"
        onExpandChange={this.expandChange}
      >
        <GridToolbar>
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => this.setState({ dialogOpen: true })}
          >
            Group
          </Button>
        </GridToolbar>
        <Column field="Name" />
        <Column field="VehicleTypeName" title="Vehicle Type" />
        <Column cell={this.RemoveCommandCell} width="120px" />
    </Grid>
    </React.Fragment>
  }

  private fetchGroups() {
    fetchApi('/api/CarrierGroups/GetGroups')
    .then((groups: GroupType[]) => {
      this.setState({ groups });
    })
    .catch(() => {
      alert("Unable to load carrier groups!");
    });
  }

  private expandChange(event: GridExpandChangeEvent) {

    this.setState(prevState => ({
      groups: prevState.groups.map(group =>
        group.GroupID === event.dataItem.GroupID ?
          { ...group, Expanded: !group.Expanded } :
          group
      )
    }));

    if (!event.value || event.dataItem.details) {
        return;
    }
  }

  private remove(dataItem: GroupType) {
    const data = {
      GroupID: dataItem.GroupID
    };
    fetchApi('/api/CarrierGroups/RemoveGroup', data, 'post')
      .then(() => {
        this.fetchGroups();
      })
      .catch(() => {
        alert("Unable to remove group!");
      });
  }

  private add() {
    const data = {
      GroupName: this.state.newGroupName,
      VehicleTypeID: this.state.newVehicleTypeID
    }

    if (!data.GroupName) {
      alert("Please enter a group name!");
      return;
    }

    fetchApi('/api/CarrierGroups/AddGroup', data, 'post')
      .then(() => {
        this.setState({ newGroupName: '', dialogOpen: false });
        this.fetchGroups();
      })
      .catch(() => {
        alert("Unable to create new group!");
      });
  }
}
