import * as React from 'react';
import { weightUnitOfMeasureAbbreviation, weightDisplayValue } from 'utils/utils';

type Props = {
    value: number;
    type: 0|1;
    onChange: (value: { value: number, type: 0|1 }) => void;
};

export default class WeightInput extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
  }

  public render() {
    const value = weightDisplayValue(this.props.value, this.props.type);
    const weight = value > 0 ? value : '';
    return <React.Fragment>
      <input
        className="form-control"
        type="number"
        min={0}
        value={weight}
        placeholder="Weight"
        onFocus={(e) => e.target.select()}
        onChange={(e) => this.onChangeValue(e.target.value)}
      />
      <div className="input-group-append">
        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{weightUnitOfMeasureAbbreviation(this.props.type)}</button>
        <div className="dropdown-menu">
          <button type="button" className="dropdown-item" onClick={() => this.onChangeType(0)}>Pounds</button>
          <button type="button" className="dropdown-item" onClick={() => this.onChangeType(1)}>Kilograms</button>
        </div>
      </div>
    </React.Fragment>
  }

  private onChangeValue(value: string) {
    let parsedValue = parseInt(value) || 0;
    if (this.props.type === 1) {
      parsedValue = parsedValue * 2.20462;
    }
    this.props.onChange({ value: parsedValue, type: this.props.type });
  }

  private onChangeType(type: 0|1) {
    this.props.onChange({ value: this.props.value, type });
  }
}
