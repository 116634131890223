import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridCellProps, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Title } from '../../utils/title';
import { ILink } from '../../types/link';
import './style.css';
import { BooleanFilter, Filter, FilterChangeEvent, Operators, TextFilter } from '@progress/kendo-react-data-tools';
import OwnerNumberCell from './OwnerNumberCell';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '@progress/kendo-react-buttons';
import AddOwnerDialog from '../AssetOwners/AddOwnerDialog';
import { plusIcon } from '@progress/kendo-svg-icons';
import { GridLoadingPanel } from 'components/GridLoadingPanel';

export type OwnerViewModel = {
    OwnerID: number;
    OwnerNumber: string;
    OwnerFullName: string;
    FirstName: string;
    LastName: string;
    CompanyName: string;
    VendorID: string;
    EMailAddress: string;
    Active: boolean;
    FederalTaxpayerID: string;
    Hash: string;
    Links: ILink[];
}

const OwnerGrid = () => {
    const resetDataState = {
        skip: 0,
        take: 50,
        sort: [{
            field: "CompanyName",
            dir: "asc"
        }]
    } as GridState;

    const defaultAndFilter = {
        logic: 'and', filters: [
            { field: 'active', operator: 'eq', value: true },
        ]
    } as CompositeFilterDescriptor;

    const sessionStorageAndFilter = sessionStorage.getItem("OwnerGrid-andFilter");
    let andFilter: CompositeFilterDescriptor = null;
    if (sessionStorageAndFilter) {
        andFilter = JSON.parse(sessionStorageAndFilter);
    } else {
        andFilter = { ...defaultAndFilter };
    }
    const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
    const [loading, setLoading] = useState(false);
    const [owners, setOwners] = useState<OwnerViewModel[]>([]);
    const [showAddOwnerDialog, setShowAddOwnerDialog] = useState(false);
    const totalRecords = useRef(0);
    const DataState_Take = 50;

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const fetchRecords = useCallback((append: boolean) => {
        sessionStorage.setItem("OwnerGrid-andFilter", JSON.stringify(dataState.filter));
        setLoading(true);
        const dataGridState = { ...dataState };
        if (!append) {
            dataGridState.take = DataState_Take;
            dataGridState.skip = 0;
            window.scrollTo(0, 0);
            document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
        }
        const queryStr = `${toDataSourceRequestString(dataGridState)}`;
        fetchApi(`/api/Asset/GetAssetOwners?${queryStr}`, {}, 'POST')
            .then(({ Data, Total }) => {
                totalRecords.current = Total;
                if (!append) {
                    setOwners(Data);
                } else {
                    setOwners(prevData => prevData.concat(Data));
                }
                setLoading(false);
                handleResize(null);
            }).catch(() => {
                setLoading(false);
            });
    }, [dataState]);

    const handleResize = (e: UIEvent) => {
        if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
            ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
        }
    }

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
            if (owners.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
                return;

            dataState.skip = dataState.skip + dataState.take;
            fetchRecords(true);
        }
    }

    const OwnerEmailAddressCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        let dataItem: OwnerViewModel = props.dataItem;
        //props.dataItem[props.field]
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <a
                    href={"mailto:" + dataItem.EMailAddress}
                    style={{ color: '#007bff' }}
                >{dataItem.EMailAddress}
                </a>
            </td>
        );
    };

    const search = useDebouncedCallback(useCallback(() => {
        fetchRecords(false);
    }, [fetchRecords]), 750);

    useEffect(() => {
        search();
    }, [dataState.filter, dataState.sort, search]);

    const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
        setDataState(changeEvent.dataState);
    }

    const onFilterChange = (event: FilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter });
    };

    const onGridFilterChange = (event: GridFilterChangeEvent) => {
        setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
    };

    const onGridSortChange = (event: GridSortChangeEvent) => {
        setDataState({ ...dataState, sort: event.sort });
    };

    return <React.Fragment>
            <Title string="Owner Listing" />
            {showAddOwnerDialog && <AddOwnerDialog
                CloseDialog={() => setShowAddOwnerDialog(false)}
            />}
            <div>
                <Filter
                    className="kendoFilterControl"
                    value={dataState.filter}
                    onChange={onFilterChange}
                    fields={[
                        {
                            name: "OwnerNumber",
                            label: "Owner ID",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "OwnerFullName",
                            label: "Owner Name",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "active",
                            label: "Active",
                            filter: BooleanFilter,
                            operators: Operators.boolean
                        },
                        {
                            name: "CompanyName",
                            label: "Company Name",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "VendorID",
                            label: "Vendor ID",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "EMailAddress",
                            label: "EMail Address",
                            filter: TextFilter,
                            operators: Operators.text,
                        },
                        {
                            name: "FederalTaxpayerID",
                            label: "Fed. TaxPayer ID",
                            filter: TextFilter,
                            operators: Operators.text,

                        }
                    ]}
                />
            </div>
            {loading && <GridLoadingPanel />}
            <Grid
                {...dataState}
                onDataStateChange={dataStateChange}
                data={owners}
                resizable={true}
                reorderable={true}
                sortable={true}
                total={totalRecords.current}
                onScroll={scrollHandler}
                filterable={true}
                onFilterChange={(e) => onGridFilterChange(e)}
                onSortChange={(e) => onGridSortChange(e)}
            >
                <GridToolbar>
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        themeColor="primary"
                        onClick={() => setShowAddOwnerDialog(true)}
                    > Owner
                    </Button>
                </GridToolbar>
                <Column field="OwnerNumber" title="Owner ID" cell={OwnerNumberCell} />
                <Column field="OwnerFullName" title="Name" />
                <Column field="CompanyName" title="Company" />
                <Column field="VendorID" title="Vendor ID" media="(min-width: 768px)" />
                <Column field="EMailAddress" title="E-Mail" media="(min-width: 768px)" cell={OwnerEmailAddressCell} />
            </Grid>
            {
                totalRecords.current > 0 &&
                <div className="k-pager k-pager-md k-grid-pagert">
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
                    </div>
                </div>


            }
        </React.Fragment>
}

export default OwnerGrid;