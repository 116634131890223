import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridSortChangeEvent, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SortDescriptor, orderBy, FilterDescriptor, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import DateCell from '../../components/cells/DateCell';
import YesNoCell from '../../components/cells/YesNoCell';
import SecondsCell from './SecondsCell';
import CallerCell from './CallerCell';
import CalleeCell from './CalleeCell';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Moment from 'moment-timezone';
import { Button } from '@progress/kendo-react-buttons';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';

type Props = {};

type State = {
  data: CallLog[];
  users: User[];
  totals: Total[];
  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
  andFilter: FilterDescriptor[];
  loading: boolean;
  isMoreRecords: boolean;

  // Filters
  UserID: string;
  From?: Date;
  To?: Date;
}

type CallLog = {
  CalleeName: string;
  CalleeNumber: string;
  CalleeNumberType: number;
  CallerName: string;
  CallerNumber: string;
  CallerNumberType: number;
  DateTime: Date;
  Direction: string;
  Duration: number;
  IsRecorded: boolean;
  Result: string;
}

type Total = {
  Label: string;
  Data: string;
}

type User = {
  ID: string;
  Name: string;
}

export default class Zoom extends React.Component<Props, State> {

  private export: React.RefObject<ExcelExport>;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      totals: [],
      users: [],
      sort: [{field: "DateTime", dir: "desc"}],
      filter: { logic: 'and', filters: [] },
      andFilter: [],
      loading: false,
      isMoreRecords: false,

      UserID: '',
    };

    this.export = React.createRef();

    this.reset = this.reset.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  public componentDidMount() {
    this.fetchData();

    fetchApi('/api/Zoom/Users')
    .then((response: { Users: User[] }) => {
      this.setState({ users: response.Users });
    });
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.loading && <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
        />}
        {this.state.isMoreRecords && <>
          <br />
          <div className="alert alert-warning" role="alert">There are more records not shown, this will affect summary data.</div>
        </>}
        <ExcelExport ref={this.export}>
          <Grid
              filter={this.state.filter}
              onFilterChange={this.onFilterChange}
              sortable={{ allowUnsort: true }}
              onSortChange={this.onSortChange}
              scrollable={'none'}
              data={orderBy(filterBy(this.state.data, this.state.filter), this.state.sort)}
              sort={this.state.sort}
            >
            <GridToolbar>
            <ComboBox
              placeholder={"Select User"}
              data={this.state.users}
              textField="Name"
              onChange={this.selectUser}
            />
            <DatePicker
              title="From"
              value={Moment(this.state.From).toDate()}
              onChange={(e) => this.setState({ From: e.value }, this.fetchData)}
            />
            <DatePicker
              title="To"
              value={Moment(this.state.To).toDate()}
              onChange={(e) => this.setState({ To: e.value }, this.fetchData)}
            />
            <Button
              title="Reset Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={this.reset}
            />
            <Button
              title="Refresh"
              icon="refresh"
              svgIcon={arrowRotateCwIcon}
              onClick={this.fetchData}
            />
            <Button
              onClick={() => this.export.current.save(this.state.data)}
            >Excel
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Direction', 'eq', 'Inbound')}
              onClick={() => this.filterAnd('Direction', 'eq', 'Inbound')}
            >Inbound
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Direction', 'eq', 'Outbound')}
              onClick={() => this.filterAnd('Direction', 'eq', 'Outbound')}
            >Outbound
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Result', 'eq', 'Call connected')}
              onClick={() => this.filterAnd('Result', 'eq', 'Call connected')}
            >Connected
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('IsRecorded')}
              onClick={() => this.filterAnd('IsRecorded')}
            >Recorded
            </Button>
            </GridToolbar>
            {this.state.totals.length > 0 && <GridToolbar>
              {this.state.totals.map((total) => <span>{total.Label}: <b>{total.Data}</b></span>)}
            </GridToolbar>}
            <Column field="Direction" />
            <Column field="CallerNumber" cell={CallerCell} title="From" />
            <Column field="CalleeNumber" cell={CalleeCell} title="To" />
            <Column field="DateTime" title="Time" cell={DateCell} filter="date" />
            <Column field="Result" />
            <Column field="Duration" cell={SecondsCell} filter="numeric" />
            <Column field="IsRecorded" title="Recorded" cell={YesNoCell} filter="boolean" />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }

  private reset() {
    this.setState({ sort: [{field: "DateTime", dir: "desc"}] });
  }

  private onFilterChange(event: GridFilterChangeEvent) {
    this.setState({
      filter: event.filter
    });
  }

  private onSortChange(changeEvent: GridSortChangeEvent) {
    this.setState({ sort: changeEvent.sort });
  }

  private selectUser(e: ComboBoxChangeEvent) {
    this.setState({ UserID: e.value?.ID ?? '' }, this.fetchData);
  }

  private hasAndFilter(field: string, operator: string = 'eq', value: any = true): boolean {
    return this.state.andFilter
      .filter(x => x.field === field && x.operator === operator && x.value === value)
      .length > 0;
  }

  private filterAnd(field: string, operator: string = 'eq', value: any = true) {
    const enabled = this.hasAndFilter(field, operator, value);
    const existingFilter = this.state.andFilter.filter(x => x.field !== field);
    let newStatusFilter = [...existingFilter, { field: field, operator: operator, value: value }] as FilterDescriptor[];
    if (enabled) {
      newStatusFilter = existingFilter;
    }
    const newFilter = {
      logic: 'and',
      filters: newStatusFilter
    } as CompositeFilterDescriptor;
    this.setState({
      filter: newFilter,
      andFilter: newStatusFilter,
    });
  }

  private fetchData() {
    this.setState({ loading: true });

    fetchApi('/api/Zoom/CallLog', { 
      UserId: this.state.UserID,
      From: this.state.From,
      To: this.state.To
    }, 'POST')
      .then((response: { CallLogs: CallLog[], Totals: Total[], From: Date, To: Date, IsMoreRecords: boolean }) => {
        this.setState({ 
          data: response.CallLogs,
          totals: response.Totals,
          From: response.From,
          To: response.To,
          isMoreRecords: response.IsMoreRecords,
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }
}
