import { DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DateInput, DateInputProps, SelectionRange } from '@progress/kendo-react-dateinputs';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridHeaderCellProps, GridItemChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ViewModel as WorkloadHistoryRecord } from 'TypeGen/AccountsPayable/WorkloadHistory/view-model';
import { useDebouncedCallback } from 'use-debounce';
import { Title } from 'utils/title';
import { CarrierNavBar } from 'views/AssetCarriers/CarrierNavBar';
import { DriverTurndownsGrid } from 'views/AssetDrivers/Turndowns';
import TrailerAutoComplete from 'views/AssetTrailers/TrailerAutoComplete';
import { VehicleTurndownsGrid } from 'views/AssetVehicles/Turndowns';
import TripSummary from 'views/Track/TripSummary';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDateRangePicker from '../../components/SimpleDateRangePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import CarrierAutoComplete from '../AssetCarriers/CarrierAutoComplete';
import DriverAutoComplete from '../AssetDrivers/DriverAutoComplete';
import OwnerAutoComplete from '../AssetOwners/OwnerAutoComplete';
import VehicleAutoComplete from '../AssetVehicles/VehicleAutoComplete';
import PayPeriodDropDownList from '../Settlements/PayPeriodDropDownList';
import TripPay from '../Trip/Pay';
import TripProfile from '../Trip/Profile';
import { filterClearIcon } from '@progress/kendo-svg-icons';

const WorkloadHistoryContext = createContext<{
  ShowPayWindow: (tripId: number) => void,
  ShowTripSummary: (orderId: number) => void,
}>({
  ShowPayWindow: null,
  ShowTripSummary: null,
})

export const useWorkloadHistory = () => useContext(WorkloadHistoryContext);

const AssetCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.VehicleID > 0 ?
        <>
          <div>{dataItem.DriverNumber}</div>
          <div>{dataItem.VehicleNumber}</div>
          <div>{dataItem.TrailerNumber}</div>
        </> : <div>{dataItem.CarrierName}</div>}
    </td>
  );
};


const OrderNumberCell = (props: GridCellProps) => {
  const context = useContext(WorkloadHistoryContext);

  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div><a href="#" style={{ color: '#007bff' }} onClick={(e) => { e.preventDefault(); context.ShowPayWindow(dataItem.TripID); }}>{dataItem.InvoiceNumber}</a></div>
      <div>{dataItem.OrderID > 0 ? <a href="#" style={{ color: '#007bff' }} onClick={(e) => { e.preventDefault(); context.ShowTripSummary(dataItem.OrderID); }}>{dataItem.OrderNumber}</a> : 'Dead Head'}</div>
      <div>{dataItem.OrderStatusName}</div>
    </td>
  );
};

const PickupLocationCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{dataItem.PickupCustomerName}</div>
      <div>{dataItem.PickupCityName}, {dataItem.PickupCityState}</div>
      <div>{Moment.utc(dataItem.PickupDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST</div>
    </td>
  );
};

const DeliveryLocationCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{dataItem.DeliveryCustomerName}</div>
      <div>{dataItem.DeliveryCityName}, {dataItem.DeliveryCityState}</div>
      <div>{Moment.utc(dataItem.DeliveryDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} EST</div>
    </td>
  );
};

const DistanceCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div>{formatNumber(dataItem.LoadedMiles, 'n0')} mi</div>
      <div>{formatNumber(dataItem.DeadHeadMiles, 'n0')} mi</div>
    </td>
  );
};

const HoursCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>

      <div>{formatNumber(dataItem.TripHours, 'n1')} hrs</div>
      <div>{formatNumber(dataItem.DrivingHours, 'n1')} hrs</div>
    </td>
  );
};


const SplitTitleHeaderCell = (props: GridHeaderCellProps) => {
  const splitVal = props.title.split("/");

  return (<a className="k-link" onClick={props.onClick}>
    {splitVal.map((title, index) => (
      <div key={index}>{title}</div>
    ))}
    {props.children}
  </a>);
};

const SplitTitleHeaderCellRight = (props: GridHeaderCellProps) => {
  const splitVal = props.title.split("/");

  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    {splitVal.map((title, index) => (
      <div key={index}>{title}</div>
    ))}
    {props.children}
  </a>);
};

const AmountCell = (props: GridCellProps) => {
  let dataItem: WorkloadHistoryRecord = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      <div>
        <span>{formatNumber(dataItem.PayTotal, "c2")}</span>
        <div>{dataItem.PayStatusName}</div>
      </div>
    </td>
  );
};

const CustomDateInput = (props: DateInputProps) => {
  return (
    <DateInput {...props} label={undefined} width={92} formatPlaceholder={{ year: 'yyyy', month: 'MM', day: 'dd' }} />
  );
};

type RouteComponentParams = {
  vehicleId?: string;
  driverId?: string;
  carrierId?: string;
};

const PickupDateRangeStartDate = Moment().subtract(7, "days").startOf('day').toDate();
const PickupDateRangeEndDate = Moment().startOf('day').toDate();
const WorkloadHistory = () => {

  const { vehicleId: vehicleIdParam } = useParams<RouteComponentParams>();
  const { driverId: driverIdParam } = useParams<RouteComponentParams>();
  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const initialRender = useRef(true);

  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "PickupDateTime", dir: "desc"
    }],
    filter: {
      logic: 'and', filters: [
        { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
        { field: 'PickupDateTime', operator: 'gte', value: PickupDateRangeStartDate },
        { field: 'PickupDateTime', operator: 'lte', value: PickupDateRangeEndDate }
      ]
    }
  } as GridState;

  const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
  const totalRecords = useRef(0);
  const DataState_Take = 50;

  const { alert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<WorkloadHistoryRecord[]>([]);

  const [showPayWindow, setShowPayWindow] = useState(0);
  const [showTripSummary, setShowTripSummary] = useState(0);

  const [filterByVehicleId, setFilterByVehicleId] = useState<number | undefined | null>(null);
  const [filterByDriverId, setFilterByDriverId] = useState<number | undefined | null>(null);
  const [filterByTrailerId, setFilterByTrailerId] = useState<number | undefined | null>(null);
  const [filterByOwnerId, setFilterByOwnerId] = useState<number | undefined | null>(null);
  const [filterByCarrierId, setFilterByCarrierId] = useState<number | undefined | null>(null);
  const [filterByPayPeriod, setFilterByPayPeriod] = useState<number>(0);
  const [filterByPickupDateRange, setFilterByPickupDateRange] = useState<SelectionRange>({
    start: PickupDateRangeStartDate,
    end: PickupDateRangeEndDate,
  });

  const debounceDateRangeValueFilter = useDebouncedCallback((value: SelectionRange) => {
    removeFilter('PickupDateTime');
    addDateRangeFilter('PickupDateTime', value);
  }, 750);

  const fetchRecords = useCallback((append: boolean) => {

    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;

      document.querySelector('.WorkloadHistory-andFilter .k-grid-content').scrollTop = 0;
    }

    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/Asset/WorkloadHistory?${queryStr}`, {}, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setData(Data);
        } else {
          setData(prevData => prevData.concat(Data));
        }
        setLoading(false);
        //  handleResize(null); //need this if using hierarchy filters
      }).catch(async e => {
        setLoading(false);
        if (!e?.status)
          await alert('Error fetching data.');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
      });
  }, [alert, dataState]);

  useEffect(() => {
    if (initialRender.current) {
      if (vehicleIdParam == null && driverIdParam == null && carrierIdParam == null)
        fetchRecords(false);
      else {
        if (vehicleIdParam != null) {
          (dataState.filter.filters as FilterDescriptor[]).push({ field: 'VehicleID', operator: 'eq', value: Number(vehicleIdParam) });
          setFilterByVehicleId(Number(vehicleIdParam));
        } else if (driverIdParam != null) {
          (dataState.filter.filters as FilterDescriptor[]).push({ field: 'DriverID', operator: 'eq', value: Number(driverIdParam) });
          setFilterByDriverId(Number(driverIdParam));
        } else if (carrierIdParam != null) {
          (dataState.filter.filters as FilterDescriptor[]).push({ field: 'CarrierID', operator: 'eq', value: Number(carrierIdParam) });
          setFilterByCarrierId(Number(carrierIdParam));
        }
        fetchRecords(false);
      }
    } else {
      fetchRecords(false);
    }
    initialRender.current = false;
  }, [fetchRecords, vehicleIdParam, driverIdParam, carrierIdParam, dataState.filter.filters]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (data.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item.PayID === event.dataItem.PayID
        ? { ...item, [field]: event.value }
        : item
    );
    setData(newData);
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...resetDataState.filter } });
  };

  const addDateRangeFilter = (field: string, dateRange: SelectionRange) => {
    (dataState.filter.filters as FilterDescriptor[]).push({ field: field, operator: 'gte', value: dateRange.start });
    (dataState.filter.filters as FilterDescriptor[]).push({ field: field, operator: 'lte', value: dateRange.end });
    setDataState({ ...dataState });
  };

  const updateOrAddFilter = (filterDescriptor: FilterDescriptor) => {
    var hasFilter = (dataState.filter.filters as FilterDescriptor[]).some(x => x.field === filterDescriptor.field);

    if (hasFilter) {
      const updatedFilters = (dataState.filter.filters as FilterDescriptor[]).map((obj) =>
        obj.field === filterDescriptor.field ? filterDescriptor : obj
      );
      setDataState({ ...dataState, filter: { ...dataState.filter, filters: updatedFilters } });
    } else {
      (dataState.filter.filters as FilterDescriptor[]).push(filterDescriptor);
      setDataState({ ...dataState });
    }
  };

  const removeFilter = (field: string) => {
    var hasFilter = (dataState.filter.filters as FilterDescriptor[]).some(x => x.field === field);
    if (hasFilter) {
      dataState.filter.filters = (dataState.filter.filters as FilterDescriptor[]).filter(x => x.field !== field);
      setDataState({ ...dataState });
    }
  }

  const TripPayPopup = (tripId: number) => {
    return <TripProfile tripId={tripId} isDialog={true} onClose={() => setShowPayWindow(0)}>
      <TripPay tripId={tripId} />
    </TripProfile>
  }

  const renderSummary = () => {
    const totalEmpty = data.reduce((a, b) => a + b.DeadHeadMiles, 0);
    const totalLoaded = data.reduce((a, b) => a + b.LoadedMiles, 0);
    const totalPay = data.reduce((a, b) => a + b.PayTotal, 0);
    const totalTripTime = data.reduce((a, b) => a + b.TripHours, 0);
    const totalDrivingTime = data.reduce((a, b) => a + b.DrivingHours, 0);
    return <>
      <div className='col'>
        Trip Count: {formatNumber(data.length, 'n0')}<br />
        Total Empty: {formatNumber(totalEmpty, 'n0')} mi<br />
        Total Loaded: {formatNumber(totalLoaded, 'n0')} mi<br />
        Total Pay: {formatNumber(totalPay, 'c2')}<br />
        Total Trip Time: {formatNumber(totalTripTime, 'n1')} hrs<br />
        Total Driving Time: {formatNumber(totalDrivingTime, 'n1')} hrs<br />
      </div>
      <div className='col'>
        <br />
        Avg Empty: {formatNumber(totalEmpty / data.length, 'n1')} mi/trip<br />
        Avg Loaded: {formatNumber(totalLoaded / data.length, 'n1')} mi/trip<br />
        Avg Pay: {formatNumber(totalPay / data.length, 'c2')}/trip<br />
        Avg Trip Time: {formatNumber(totalTripTime / data.length, 'n1')} hrs/trip<br />
        Avg Driving Time: {formatNumber(totalDrivingTime / data.length, 'n1')} hrs/trip<br />
      </div>
      <div className='col' style={{ textAlign: 'right' }}>
        {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
      </div>
    </>
  }

  const dataView = () => {
    return <div className="mt-2" style={{ position: "relative" }}>
      <WorkloadHistoryContext.Provider value={{
        ShowPayWindow: (tripId: number) => { setShowPayWindow(tripId) },
        ShowTripSummary: (orderId: number) => { setShowTripSummary(orderId) },
      }}>
        <Grid
          className='WorkloadHistory-andFilter'
          style={{
            maxHeight: `${window.innerHeight * .80}px`,
          }}
          data={data}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={totalRecords.current}
          onScroll={scrollHandler}
          resizable={true}
          onFilterChange={(e) => onGridFilterChange(e)}
          onItemChange={itemChange}
        >
          {!carrierIdParam && <GridToolbar>
            <VehicleAutoComplete
              includeRecentlyRetiredVehicles
              selectedVehicleID={filterByVehicleId}
              style={{ width: '200px' }}
              onSelectedVehicle={(e) => {
                if (e?.VehicleID > 0) {
                  updateOrAddFilter({ field: 'VehicleID', operator: 'eq', value: e?.VehicleID });
                } else {
                  removeFilter('VehicleID');
                }
                setFilterByVehicleId(e?.VehicleID)
              }}
            />
            <DriverAutoComplete
              includeRecentlyRetiredDrivers
              selectedDriverID={filterByDriverId}
              style={{ width: '200px' }}
              onSelectedDriver={(e) => {
                if (e?.DriverID > 0) {
                  updateOrAddFilter({ field: 'DriverID', operator: 'eq', value: e?.DriverID });
                } else {
                  removeFilter('DriverID');
                }
                setFilterByDriverId(e?.DriverID);
              }}
            />
            <TrailerAutoComplete
              includeRecentlyRetiredTrailers
              selectedTrailerID={filterByTrailerId}
              style={{ width: '200px' }}
              onSelectedTrailer={(e) => {
                if (e?.TrailerID > 0) {
                  updateOrAddFilter({ field: 'TrailerID1', operator: 'eq', value: e?.TrailerID });
                } else {
                  removeFilter('TrailerID1');
                }
                setFilterByTrailerId(e?.TrailerID)
              }}
            />
            <OwnerAutoComplete
              selectedOwnerID={filterByOwnerId}
              style={{ width: '200px' }}
              onSelectedOwner={(e) => {
                if (e?.OwnerID > 0) {
                  updateOrAddFilter({ field: 'OwnerID', operator: 'eq', value: e?.OwnerID });
                } else {
                  removeFilter('OwnerID');
                }
                setFilterByOwnerId(e?.OwnerID)
              }}
            />
            <CarrierAutoComplete
              selectedCarrierID={filterByCarrierId}
              style={{ width: '200px' }}
              onSelectedCarrier={(e) => {
                if (e?.CarrierID > 0) {
                  updateOrAddFilter({ field: 'CarrierID', operator: 'eq', value: e?.CarrierID });
                } else {
                  removeFilter('CarrierID');
                }
                setFilterByCarrierId(e?.CarrierID)
              }}
            />
            <PayPeriodDropDownList
              style={{ width: 250 }}
              onSelected={(e) => {
                setFilterByPayPeriod(e.PayPeriodID);
                if (e.PayPeriodID > 0) {
                  updateOrAddFilter({ field: 'PayPeriodID', operator: 'eq', value: e.PayPeriodID });
                } else {
                  removeFilter('PayPeriodID');
                }
              }}
              selectedPayPeriodID={filterByPayPeriod}
              includeClosedPayPeriods={true}
              includeOpenPayPeriods={true}
              defaultPayPeriodItem={{ PayPeriodID: 0, Label: 'Any Pay Period' }}
            />
            <Button
              title="Clear All Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={() => {
                setFilterByVehicleId(null);
                setFilterByDriverId(null);
                setFilterByTrailerId(null);
                setFilterByOwnerId(null);
                setFilterByCarrierId(null);
                setFilterByPayPeriod(0);

                //Adding in case they ever want us to reset date range on clear filters
                //setFilterByPickupDateRange({
                //  start: PickupDateRangeStartDate,
                //  end: PickupDateRangeEndDate,
                //});

                setDataState(resetDataState);
              }}
            />
          </GridToolbar>}
          <GridToolbar>
            <span>
              <label className="mt-1">Pick-Up Date Range:</label>
              <SimpleDateRangePicker
                value={filterByPickupDateRange}
                valid={filterByPickupDateRange.start && filterByPickupDateRange.end && filterByPickupDateRange.start <= filterByPickupDateRange.end}
                onChange={(e) => {
                  setFilterByPickupDateRange(e);
                  if (e && e.start && e.end) {
                    debounceDateRangeValueFilter(e);
                  }
                }}
              />
            </span>
          </GridToolbar>
          <Column field="DriverID" title="Driver/Carrier" cell={AssetCell} headerCell={SplitTitleHeaderCell} />
          <Column field="StopCount" title="Stops" width={50} filterable={false} />
          <Column field="InvoiceNumber" title="Trip #/Order #" width={110} cell={OrderNumberCell} headerCell={SplitTitleHeaderCell} />
          <Column field="PickupCityName" title="From" cell={PickupLocationCell} />
          <Column field="DeliveryCityName" title="To" cell={DeliveryLocationCell} />
          <Column field="LoadedMiles" title="Loaded/Empty" cell={DistanceCell} width={90} filterable={false} headerCell={SplitTitleHeaderCell} />
          <Column field="TripHours" title="Trip Time/Driving Time" cell={HoursCell} width={100} filterable={false} headerCell={SplitTitleHeaderCell} />
          <Column field="Amount" title="Pay/Status" cell={AmountCell} width={100} filterable={false} headerCell={SplitTitleHeaderCellRight} />
        </Grid>
      </WorkloadHistoryContext.Provider>
      {totalRecords.current > 0 && <div className="k-pager k-pager-md k-grid-pagert">{renderSummary()}</div>}
      {filterByVehicleId > 0 && <>
        <h4>Vehicle Turndowns</h4>
        <VehicleTurndownsGrid vehicleId={filterByVehicleId} />
      </>}
      {filterByDriverId > 0 && <>
        <h4>Driver Turndowns</h4>
        <DriverTurndownsGrid driverId={filterByDriverId} />
      </>}
      {showPayWindow > 0 && TripPayPopup(showPayWindow)}
      {showTripSummary > 0 && <TripSummary orderId={showTripSummary} close={() => setShowTripSummary(0)} />}
    </div>;
  };

  return <>
    {carrierIdParam && <CarrierNavBar id={carrierIdParam} currentTab="WorkloadHistory" />}
    <Title string="Workload History" />
    {loading && <CenterDivPanel>
      <Loader type="converging-spinner" />
    </CenterDivPanel>}
    {dataView()}
  </>;
}

export default WorkloadHistory;
