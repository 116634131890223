import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { OrderNotification } from "../SetupNotifications";

type EditFormProps = {
  cancelEdit: () => void;
  onSubmit: (item: OrderNotification) => void;
  item: OrderNotification;
}

const checkCallOptions = [
  { text: 'None', value: 0 },
  { text: '15 Mins', value: 15 },
  { text: '30 Mins', value: 30 },
  { text: '1 Hour', value: 60 },
  { text: '2 Hours', value: 120 },
  { text: '4 Hours', value: 240 }
]

const CustomDropDownList = (fieldRenderProps: FieldRenderProps) => {
  const { value, ...others } = fieldRenderProps;
  return (
    <DropDownList
      {...others}
      value={fieldRenderProps.data.find((x: any) => x[fieldRenderProps.dataItemKey] === value)}
      onChange={(e) => {
        fieldRenderProps.onChange({
          target: {
            value: e.target.value[fieldRenderProps.dataItemKey],
          },
        });
      }}
    />
  );
};

const EditNotifications = (props: EditFormProps) => {
    const { cancelEdit, onSubmit, item, ...other } = props;
    const isNewItem = item.CustomerID === 0;
    return (
      <Form
        ignoreModified={isNewItem}
        initialValues={item}
        onSubmit={(data) => onSubmit(data as OrderNotification)}
        render={(renderProps) => (
          <Dialog
            title={`${item.CustomerContactName} Notifications`}
            onClose={cancelEdit}
            className="dialog-w800"
          >
            <FormElement>
              {isNewItem && <FieldWrapper>
                <Label editorId="CustomerContactID">
                  Contact
                </Label>
                <Field
                  required
                  id="CustomerContactID"
                  name="CustomerContactID"
                  component={CustomDropDownList}
                  data={item.contacts}
                  textField="Name"
                  dataItemKey="ID"
                />
              </FieldWrapper>}
              <FieldWrapper>
                <Label editorId="SendPositionNotificationMinuteInterval">
                  Check Call Interval
                </Label>
                <Field
                  required={item.Consolidated}
                  id="SendPositionNotificationMinuteInterval"
                  name="SendPositionNotificationMinuteInterval"
                  component={CustomDropDownList}
                  data={checkCallOptions}
                  textField="text"
                  dataItemKey="value"
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  name="Consolidated"
                  component={Checkbox}
                  label="Consolidate All Position Update Emails by Check Call Interval"
                />
              </FieldWrapper>
              <span className="k-form-separator" />
              <div className="row">
                <div className="col-md-6">
                  <FieldWrapper>
                    <Field
                      name="SendArrivedShipperNotification"
                      component={Checkbox}
                      label="Email When Arrived Shipper"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="SendLoadedNotification"
                      component={Checkbox}
                      label="Email When Loaded"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="SendArrivedConsigneeNotification"
                      component={Checkbox}
                      label="Email When Arrived Consignee"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="SendEmptiedNotification"
                      component={Checkbox}
                      label="Email When Emptied"
                    />
                  </FieldWrapper>
                </div>
                <div className="col-md-6">
                  <FieldWrapper>
                    <Field
                      name="SendArrivedBorderNotification"
                      component={Checkbox}
                      label="Email When Arrived Border"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="SendDepartedBorderNotification"
                      component={Checkbox}
                      label="Email When Departed Border"
                    />
                  </FieldWrapper>
                </div>
              </div>
              <span className="k-form-separator" />
              <div className="row">
                <div className="col-md-6">
                  <FieldWrapper>
                    <Label editorId="SendPickupWaitTimeMinutesNotification">
                      Email If Pickup Wait Time Exceeds (In Minutes)
                    </Label>
                    <Field
                      id="SendPickupWaitTimeMinutesNotification"
                      name="SendPickupWaitTimeMinutesNotification"
                      component={NumericTextBox}
                      placeholder="0 for no notification"
                      min={0}
                      max={1440}
                    />
                  </FieldWrapper>
                </div>
                <div className="col-md-6">
                  <FieldWrapper>
                    <Label editorId="SendDeliveryWaitTimeMinutesNotification">
                      Email If Delivery Wait Time Exceeds (In Minutes)
                    </Label>
                    <Field
                      id="SendDeliveryWaitTimeMinutesNotification"
                      name="SendDeliveryWaitTimeMinutesNotification"
                      component={NumericTextBox}
                      placeholder="0 for no notification"
                      min={0}
                      max={1440}
                    />
                  </FieldWrapper>
                </div>
              </div>
              <span className="k-form-separator" />
              <div className="row">
                <div className="col-md-6">
                  <FieldWrapper>
                    <Field
                      name="OnlyIfAuthCompany"
                      component={Checkbox}
                      label="If Auth Company"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="OnlyIfAuthContact"
                      component={Checkbox}
                      label="Only If Auth Contact"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="OnlyIfBillTo"
                      component={Checkbox}
                      label="If Bill To Company"
                    />
                  </FieldWrapper>
                </div>
                <div className="col-md-6">
                  <FieldWrapper>
                    <Field
                      name="OnlyIfConsignee"
                      component={Checkbox}
                      label="If Consignee Company"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="OnlyIfShipper"
                      component={Checkbox}
                      label="If Shipper Company"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="OnlyIfLogistics"
                      component={Checkbox}
                      label="If Logistics Company"
                    />
                  </FieldWrapper>
                </div>
              </div>
            </FormElement>
            <DialogActionsBar layout="end">
              <Button
                onClick={cancelEdit}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                themeColor={isNewItem ? 'success' : 'primary'}
                disabled={!renderProps.allowSubmit}
                onClick={renderProps.onSubmit}
              >
                {isNewItem ? 'Create' : 'Update'}
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {...other}
      />
    );
};
export default EditNotifications;
