import { CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridHeaderCellProps, GridToolbar } from '@progress/kendo-react-grid';
import Moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataSourceCustomerResult } from 'TypeGen/Customer/List/data-source-customer-result';
import { useDebouncedCallback } from 'use-debounce';
import { fetchApi } from '../../services/api';
import { Title } from '../../utils/title';
import { InvoiceEmailReviewDialog } from '../Billing/EmailInvoices/DetailRow/InvoiceEmailReviewDialog';
import Documents, { DocumentEntityType } from '../Documents/Documents';
import CopyCustomerDialog from './CopyCustomerDialog';
import customerActionCell from './CustomerActionPopup';
import CustomerCell from './CustomerCell';
import CustomerNameCell from './CustomerNameCell';
import { SetupNotifications } from './SetupNotifications';
import { filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { GridLoadingPanel } from 'components/GridLoadingPanel';

export enum MenuOption {
  CreditHold,
  Documents,
  InvoiceEmailSettings,
  SetupNotifications
}

const CustomerGrid = () => {

  const resetDataState = {
    skip: 0,
    take: 50
  } as GridState;

  const defaultAndFilter = {
    logic: 'and',
    filters: [
      { logic: 'and', filters: [{ field: 'Active', operator: 'eq', value: true }] },
    ]
  } as CompositeFilterDescriptor;

  const SESSION_STORAGE_NAME = "CustomerListingFilterV2";
  const sessionStorageAndFilter = sessionStorage.getItem(SESSION_STORAGE_NAME);
  let ssaf = { ...defaultAndFilter };
  let sa_showRatingCustomers = false;
  if (sessionStorageAndFilter) {
    ssaf = JSON.parse(sessionStorageAndFilter.split('||')[0]);
    sa_showRatingCustomers = sessionStorageAndFilter.split('||')[1] === "true";
  }

  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: ssaf });
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<DataSourceCustomerResult[]>([]);
  const [selectedCustomerSingle, setSelectedCustomerSingle] = useState<DataSourceCustomerResult>(null);
  const [showCopyCustomerDialog, setShowCopyCustomerDialog] = useState(false);
  const [customerInvoiceEmailSettingsDialogIsOpen, setcustomerInvoiceEmailSettingsDialogIsOpen] = useState(false);
  const [documentModalIsOpen, setDocumentModalIsOpen] = useState(false);
  const [setupNotificationsModalIsOpen, setSetupNotificationsModalIsOpen] = useState(false);
  const [showRatingCustomers, setShowRatingCustomers] = useState(sa_showRatingCustomers);
  const totalRecords = useRef(0);
  const DataState_Take = 50;
  const history = useHistory();

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleResize = (e: UIEvent) => {
    if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
      ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 150) + "px";
    }
  }

  const loadDialogScreen = (SelectedRowValues: DataSourceCustomerResult, SelectedMenuOption: MenuOption) => {

    /*
    let data: CustomerValue[] = null;
    const selectedCustomerIds: CustomerValue[] = customers.filter(x => x.Selected === true);
    if (selectedCustomerIds.length > 0) {
        data = selectedCustomerIds;
    } else {
        data = customers.filter(x => x.CustomerID === SelectedRowValues.CustomerID);
    }
    */

    setSelectedCustomerSingle(SelectedRowValues);

    switch (SelectedMenuOption) {
      case MenuOption.Documents:
        setDocumentModalIsOpen(true);
        break;
      case MenuOption.InvoiceEmailSettings:
        setcustomerInvoiceEmailSettingsDialogIsOpen(true);
        break;
      case MenuOption.SetupNotifications:
        setSetupNotificationsModalIsOpen(true);
        break;
    }
  }

  const fetchRecords = useCallback((append: boolean = false) => {

    sessionStorage.setItem(SESSION_STORAGE_NAME, `${JSON.stringify(dataState.filter)}||${showRatingCustomers}`);

    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }

    let queryStr = `${toDataSourceRequestString(dataGridState)}`;
    if (showRatingCustomers)
      queryStr += '&showRatingCustomers=true';

    fetchApi(`/api/Customer/List?${queryStr}`)
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setCustomers(Data);
        } else {
          setCustomers(prevData => prevData.concat(Data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataState, showRatingCustomers]);

  const search = useDebouncedCallback(useCallback(() => {
    fetchRecords(false);
  }, [fetchRecords]), 750);

  useEffect(() => {
    handleResize(null);
  }, []);

  useEffect(() => {
    search();
  }, [dataState.filter, dataState.sort, search, showRatingCustomers]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (customers.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const reset = () => {
    dataStateChange({
      dataState: {
        skip: 0,
        take: 50,
        filter: {
          logic: 'and',
          filters: [{ logic: 'and', filters: [{ field: "Active", operator: "eq", value: true }] }]
        } as CompositeFilterDescriptor
      }
    } as GridDataStateChangeEvent);
  }

  const hasCompositeFilterDescriptor = (obj: CompositeFilterDescriptor) => {
    return (obj) ? (obj.filters as CompositeFilterDescriptor[]).filter(x => x.logic != null && x.filters.length > 0).length > 0 : false;
  }

  const getCompositeFilterDescriptor = (obj: CompositeFilterDescriptor) => {
    return (obj.filters as CompositeFilterDescriptor[]).filter(x => x.logic != null && x.filters.length > 0);
  }

  const getFilterDescriptors = (obj: CompositeFilterDescriptor) => {
    return (obj.filters as FilterDescriptor[]).filter(x => x.field != null);
  }

  const hasCompositeFilterDescriptorFilter = (field: string, compositeFilterLogicGroup: string) => { //Set the inner filter value for the AND or OR (i.e. compositeFilterLogicGroup) filter list
    if (hasCompositeFilterDescriptor(dataState.filter)) {
      return getFilterDescriptors(getCompositeFilterDescriptor(dataState.filter).filter(y => y.logic == compositeFilterLogicGroup)[0]).filter(x => x.field === field).length > 0;
    }
    return false;
  }

  const setCompositeFilterDescriptorFilterValue = (field: string, value: any, compositeFilterLogicGroup: string) => { //Get the inner filter value for the AND or OR (i.e. compositeFilterLogicGroup) filter list
    getFilterDescriptors(getCompositeFilterDescriptor(dataState.filter).filter(y => y.logic == compositeFilterLogicGroup)[0]).filter(x => x.field === field)[0].value = value; //0 because the root will only have a single OR and AND.  so two max; 1 each[0]

    fetchRecords();
  }

  const getCompositeFilterDescriptorFilterValue = (field: string, compositeFilterLogicGroup: string) => { //Set the inner filter value for the AND or OR (i.e. compositeFilterLogicGroup) filter list
    if (hasCompositeFilterDescriptorFilter(field, compositeFilterLogicGroup)) {
      return getFilterDescriptors(getCompositeFilterDescriptor(dataState.filter).filter(y => y.logic == compositeFilterLogicGroup)[0]).filter(x => x.field === field)[0].value;
    }
    return "";
  }

  const addCompositeFilter = (field: string, value: any, operator: string, compositeFilterLogicGroup: string) => { //rename to group
    ((dataState.filter.filters as CompositeFilterDescriptor[]).filter(x => x.logic == compositeFilterLogicGroup)[0].filters as FilterDescriptor[]).push(
      { field: field, operator: operator, value: value } as FilterDescriptor //operator = eq
    );
  }

  const filterViaCompositeFilter = (field: string, value: any, operator: string, compositeFilterLogicGroup: string) => { //used for filters that won't always exist i.e. active will always exist as a filter.  MostRecentDateTime wont
    if (hasCompositeFilterDescriptorFilter(field, compositeFilterLogicGroup)) { //remove
      var groupLogicFilter = ((dataState.filter.filters as CompositeFilterDescriptor[]).filter(x => x.logic == compositeFilterLogicGroup)[0].filters as FilterDescriptor[]);
      switch (field) {
        case "MostRecentTradeDate":
          ((dataState.filter.filters as CompositeFilterDescriptor[]).filter(x => x.logic == compositeFilterLogicGroup)[0].filters as FilterDescriptor[]) = groupLogicFilter.filter(x => x.field != field);
          groupLogicFilter = ((dataState.filter.filters as CompositeFilterDescriptor[]).filter(x => x.logic == compositeFilterLogicGroup)[0].filters as FilterDescriptor[]);
          ((dataState.filter.filters as CompositeFilterDescriptor[]).filter(x => x.logic == compositeFilterLogicGroup)[0].filters as FilterDescriptor[]) = groupLogicFilter.filter(x => x.field != "OnCreditHold");
          break;
      }
      fetchRecords();
    } else { //Add
      addCompositeFilter(field, value, operator, compositeFilterLogicGroup);
      switch (field) {
        case "MostRecentTradeDate":
          addCompositeFilter("OnCreditHold", false, "eq", compositeFilterLogicGroup);
          break;
        default:
          addCompositeFilter(field, value, operator, compositeFilterLogicGroup);
          break;
      }
      fetchRecords();
    }
  }

  // Add the filters to the existing filter list, but not in a specific AND | OR group.  These will be standalone and self managed by Kendo/Grid
  const filterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter });
    search();
  }

  const NumberHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
      <div>{props.title}</div>
      {props.children}
    </a>);
  };

  return <React.Fragment>
    <Title string="Customers" />
    <br />
    {loading && <GridLoadingPanel />}
    <Grid
      {...dataState}
      onDataStateChange={dataStateChange}
      filterable
      onFilterChange={filterChange}

      //do not remove
      //selectedField="Selected"
      //onSelectionChange={this.selectionChange}
      //onHeaderSelectionChange={this.headerSelectionChange}

      resizable={true}
      reorderable={true}
      sortable={{
        allowUnsort: true,
        mode: 'multiple'
      }}
      data={customers}
      total={totalRecords.current}
      onScroll={scrollHandler}
    >
      <GridToolbar>
        <Button
          title="Clear All Filters and Sort"
          icon="filter-clear"
          svgIcon={filterClearIcon}
          onClick={reset}
        />
        <Button
          icon="plus"
          svgIcon={plusIcon}
          themeColor="primary"
          onClick={() => setShowCopyCustomerDialog(true)}
        > Customer
        </Button>
        &nbsp;&nbsp;
        <label style={{ fontSize: "12px", marginTop: "6px" }}>Status:</label>&nbsp;&nbsp;
        <Button
          togglable
          selected={!getCompositeFilterDescriptorFilterValue('Active', 'and')}
          onClick={() => setCompositeFilterDescriptorFilterValue('Active', !getCompositeFilterDescriptorFilterValue('Active', 'and'), 'and')}
        >{getCompositeFilterDescriptorFilterValue('Active', 'and') == true ? 'Active' : 'Retired'}
        </Button>
        &nbsp;&nbsp;
        <label style={{ fontSize: "12px", marginTop: "6px" }}>Filters:</label>&nbsp;&nbsp;
        <Button
          togglable
          selected={hasCompositeFilterDescriptorFilter('MostRecentTradeDate', 'and')}
          onClick={() => filterViaCompositeFilter('MostRecentTradeDate', Moment().subtract(6, "months").toDate(), 'lte', 'and')}
          title="Show customers that haven't traded in over 6 months"
        >Dormant</Button>
        <Button
          togglable
          selected={showRatingCustomers}
          onClick={() => setShowRatingCustomers(!showRatingCustomers)}
          title="Show rating customers"
        >Rating Customer</Button>
      </GridToolbar>
      <Column field="CustomerNumber" title="ID" cell={CustomerCell} />
      <Column field="CustomerName" title="Name" cell={CustomerNameCell} />
      <Column field="AddressLine1" title="Address" media="(min-width: 768px)" />
      <Column field="City" title="City" media="(min-width: 768px)" />
      <Column field="State" title="State" media="(min-width: 768px)" />
      <Column field="ZipCode" title="Zip" media="(min-width: 992px)" />
      <Column field="Salesperson1Name" title="Salesperson 1" media="(min-width: 992px)" />
      <Column field="Salesperson2Name" title="Salesperson 2" media="(min-width: 1200px)" />
      <Column field="Salesperson3Name" title="Salesperson 3" media="(min-width: 1200px)" />
      {showRatingCustomers && <Column field="NumberOfRatingTables" title="# of Rating Tables" className='text-right' headerCell={NumberHeaderCell} sortable={false} filterable={false} />}
      <Column title="Action" cell={customerActionCell(customers.filter(x => x.Selected), loadDialogScreen)} sortable={false} filterable={false} width={85} />
    </Grid>
    {
      totalRecords.current > 0 &&
      <div className="k-pager k-pager-md k-grid-pagert">
        <div style={{ marginLeft: "auto", marginRight: 0 }}>
          {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {formatNumber(totalRecords.current, 'n')} customers
        </div>
      </div>
    }

    {showCopyCustomerDialog && <CopyCustomerDialog
      CloseDialog={(newCustomerId) => {
        if (newCustomerId > 0) history.push(`/Customers/Customer/${newCustomerId}`);
        setShowCopyCustomerDialog(false);
      }}
    />}
    {customerInvoiceEmailSettingsDialogIsOpen &&
      <InvoiceEmailReviewDialog
        CustomerID={selectedCustomerSingle.CustomerID}
        CustomerName={selectedCustomerSingle.CustomerName}
        CloseDialog={() => {
          setcustomerInvoiceEmailSettingsDialogIsOpen(false);
        }}
      />}
    {documentModalIsOpen &&
      <Documents
        IsWindow
        EntityType={DocumentEntityType.Customer}
        EntityId={selectedCustomerSingle.CustomerID}
        EntityNumber={selectedCustomerSingle.CustomerNumber}
        CloseDocumentModal={(numberOfDocuments: number) => {
          setCustomers(customers.map(x => x.CustomerID === selectedCustomerSingle.CustomerID ? { ...x, HasDocuments: numberOfDocuments > 0 } : x));
          setDocumentModalIsOpen(false);
        }}
      />}
    {setupNotificationsModalIsOpen &&
      <SetupNotifications
        CustomerID={selectedCustomerSingle.CustomerID}
        CloseDialog={() => {
          setSetupNotificationsModalIsOpen(false);
        }}
      />}
  </React.Fragment>
}

export default CustomerGrid;