import * as React from 'react';
import Moment from 'moment-timezone';
import { GridCellProps } from "@progress/kendo-react-grid";
import './Countdown.css';

const CountdownCell = (props: GridCellProps) => {
  const [, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!props.field || props.rowType !== 'data') return null;
  if (!props.dataItem[props.field]) return <td />;

  const msDuration = Moment.utc(props.dataItem[props.field]).diff(Moment());
  if (msDuration <= 0) {
    return <td className="countdown-critical">Expired</td>
  }
  const duration = Moment.duration(msDuration);

  if (duration.asDays() > 1) {
    const formattedDuration = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    return <td>{formattedDuration}</td>;
  }

  const formattedDuration = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  if (duration.asMinutes() > 10) {
    return <td>{formattedDuration}</td>;
  }

  if (duration.asMinutes() > 5) {
    return <td className="countdown-warning">{formattedDuration}</td>;
  }

  return <td className="countdown-critical">{formattedDuration}</td>;
}

export default CountdownCell;
