import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import { useCallback, useState } from 'react';

export type usePromptProps = {
  title?: string;
  labels?: {
    confirm: string;
    cancel: string;
  };
  themeColor?: "primary" | "success" | "warning" | "error" | "secondary" | "tertiary" | "info" | "dark" | "light" | "inverse" | "base";
};

const usePrompt = ({
  title = "Please enter",
  labels = {
    confirm: "OK",
    cancel: "Cancel",
  },
  themeColor = "primary",
}: usePromptProps) => {
  const [promise, setPromise] = useState<{
    resolve: (value: string) => void;
  } | null>(null);
  const [_title, setTitle] = useState(title);

  const prompt = (title?: string): Promise<string> => new Promise<string>((resolve) => {
    setTitle(title ?? _title);
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };

  const handleCancel = () => {
    promise?.resolve(null);
    handleClose();
  };

  const PromptDialog = () => {
    const [_message, setMessage] = useState('');
    const handleChange = useCallback((e: any) => {
      setMessage(e.target.value);
    }, []);

    const handleConfirm = () => {
      promise?.resolve(_message);
      handleClose();
    };

    return (promise !== null ?
      <Dialog title={_title} onClose={handleCancel} minWidth="450px">
        <TextArea
          rows={3}
          value={_message}
          onChange={handleChange}
        />
        <DialogActionsBar layout={'end'}>
          <Button themeColor={themeColor} onClick={handleConfirm} autoFocus={true}>
            {labels.confirm}
          </Button>
          <Button themeColor='base' onClick={handleCancel}>
            {labels.cancel}
          </Button>
        </DialogActionsBar>
      </Dialog> : null);
  };

  return {
    PromptDialog,
    prompt
  };
};

export default usePrompt;
