import { GridColumnMenuWrapper, GridHeaderCellProps } from '@progress/kendo-react-grid';

export const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return <span className="k-cell-inner">
        <span className={`k-link justify-content-end ${!props.columnMenuWrapperProps.sortable ? '!k-cursor-default' : ''}`} onClick={props.onClick}>
            <span className='k-column-title'>{props.title || props.field || '\u00A0'}</span>
            {props.children}
        </span>
        {props.columnMenuWrapperProps.columnMenu && <GridColumnMenuWrapper {...props.columnMenuWrapperProps}  /> }
    </span>
};
