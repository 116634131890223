import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';

type State = {
  loading: boolean;
  dailyChart: number[];
  marginTotal: number;
  dailyRevenue: any[];
}

type Props = {
  margin?: boolean;
  filter: { Name: string, Filter: string };
}

export default class DailyRevenueBrokerage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      dailyChart: [],
      marginTotal: 0,
      dailyRevenue: [],
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi(`/api/Report/RevenueTodayBrokerage/${this.props.filter.Filter}`)
      .then((response: Array<{ ServiceTeamID: number, TotalCharges: number, TotalExpenses: number, GrossMargin: number, RevenuePercentage: number }>) => {
        this.setState({
          loading: false,
          dailyChart: response.map(x => x.TotalCharges),
          marginTotal: response.reduce((a, b) => { return a + b.GrossMargin }, 0),
          dailyRevenue: response
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    return <React.Fragment>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
          <p>
            <span className="report-card-title">{this.props.filter.Name} Daily Brokerage</span>
            <br />
            {formatNumber(this.state.dailyChart.reduce((a, b) => { return a + b }, 0), "c0")} Total Revenue
            {this.props.margin && <><br />{formatNumber(this.state.marginTotal, "c0")} Gross Margin</>}
          </p>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Service Team</th>
                <th style={{ textAlign: 'right' }}>{this.props.margin ? '% ' : ''}Revenue</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dailyRevenue.map((revenue, index) => {
                return <tr key={index}>
                  <td>{this.serviceTeamName(revenue.ServiceTeamID)}</td>
                  {this.props.margin
                    ? <td style={{ textAlign: 'right' }}>{formatNumber(revenue.RevenuePercentage, { style: 'percent' })}</td>
                    : <td style={{ textAlign: 'right' }}>{formatNumber(revenue.TotalCharges, "c0")}</td>}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
  }

  private serviceTeamName(serviceTeamID: number): string {
    switch(serviceTeamID) {
      case 1:
        return "TruckLoad";
      case 2:
        return "Brokerage";
      case 3:
        return "Dedicated";
      case 4:
        return "Expedite";
      case 5:
        return "TL Brokerage";
      case 6:
        return "Specialized";
      case 7:
        return "Not Committed";
      case 8:
        return "Crossdock";
      default:
        return "N/A";
    }
  }
}
