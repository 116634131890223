import { createContext, useRef, useState, useContext, Fragment } from "react";
import {
    NormalizedDragEvent,
    useDraggable,
    useDroppable,
} from "@progress/kendo-react-common";
import { GridRowProps } from "@progress/kendo-react-grid";

interface DraggableRowProps extends GridRowProps {
    elementProps: any;
    dataItems: any[];
    dataItemKey: string;
}

type ContextProps = {
    reorder: (dataItem: any, direction: "before" | "after" | null) => void;
    dragStart: (dataItem: any) => void;
};

export const ReorderContext = createContext<ContextProps>({
    reorder: () => { },
    dragStart: () => { },
});

export const DraggableGridRow = (props: DraggableRowProps) => {
    const [dropped, setDropped] = useState(false);
    const [dragged, setDragged] = useState(false);
    const [direction, setDirection] = useState<"before" | "after" | null>(
        null
    );
    const [initial, setInitial] = useState({ x: 0, y: 0 });
    const { dragStart, reorder } = useContext(ReorderContext);

    const element = useRef<HTMLTableRowElement>(null);

    const handlePress = (event: NormalizedDragEvent) => {
        setInitial({
            x: event.clientX - event.offsetX,
            y: event.clientY - event.offsetY,
        });
    };

    const handleDragStart = (event: NormalizedDragEvent) => {
        // if (props.dataItem.isDraggable !== false) {
        if (
            !event.originalEvent.target ||
            !(event.originalEvent.target as HTMLElement).dataset.dragHandle
        ) {
            return;
        }
        setDragged(true);
        dragStart(props.dataItem); //this is good and will have the one you are trying to move
        //}
    };

    const handleDrag = (event: NormalizedDragEvent) => {
        if (!element.current || !dragged) {
            return;
        }
        element.current.style.transform = `translateY(${event.clientY - initial.y + event.scrollY
            }px)`;
    };

    const handleDragEnd = () => {
        setDragged(false);
        setDropped(false);
        setInitial({ x: 0, y: 0 });
    };

    const handleRelease = () => {
        if (!element.current) {
            return;
        }
        element.current.style.transform = null;
    };

    const handleDragEnter = () => {
        setDropped(true);
        setDirection(null);
    };

    const handleDragOver = (event: NormalizedDragEvent) => {
        if (!element.current) {
            return;
        }
        const rect = element.current.getBoundingClientRect();
        //console.log(rect.top + " " + rect.height / 2 + " " + event.pageY + " || " + (rect.top + rect.height / 2 <= event.pageY ? "after" : "before"));
        setDirection(
            rect.top + rect.height / 2 <= event.pageY ? "after" : "before"
        );
    };

    const handleDragLeave = () => {
        setDropped(false);
        setDirection(null);
    };

    const handleDrop = () => {
        reorder(props.dataItem, direction);
        setDropped(false);
        setDirection(null);
    };

    useDraggable(
        element,
        {
            onPress: handlePress,
            onDragStart: handleDragStart,
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
            onRelease: handleRelease,
        },
        { autoScroll: dragged }
    );

    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop,
    });

    return (
        <Fragment>
            {dropped && direction === "before" && (
                <tr
                    style={{
                        outlineStyle: "solid",
                        outlineWidth: 1,
                        outlineColor: "lightblue",
                    }}
                />
            )}
            <tr
                {...props.elementProps}
                ref={element}
                style={{
                    userSelect: "none",
                    pointerEvents: dragged ? "none" : undefined,
                    opacity: dragged ? "0.8" : undefined,
                }}
            />
            {dropped && direction === "after" && (
                <tr
                    style={{
                        outlineStyle: "solid",
                        outlineWidth: 1,
                        outlineColor: "lightblue",
                    }}
                />
            )}
        </Fragment>
    );
};