import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon, xIcon } from "@progress/kendo-svg-icons";
import { useEffect, useState } from "react";
import { IDNameViewModel } from "TypeGen/id-name-view-model";

interface Props<T> {
  data: T;
  options: T[];
  readOnly?: boolean;
  required?: boolean;
  save: (data: T) => Promise<void>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  renderDisplay?: (data: T) => JSX.Element | string;
  renderEditable?: (data: T, saving: boolean, onChange: (data: T) => void) => JSX.Element;
}

export const EditableIDName = <T extends IDNameViewModel>(props: Props<IDNameViewModel>) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(props.data);

  // Update internal data
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const stopEditing = () => {
    setData(props.data);
    setEditing(false);
  }

  const startEditing = () => {
    if (props.readOnly) return;
    setEditing(true);
  }

  const save = async () => {
    setSaving(true);
    await props.save(data);
    setSaving(false);
    setEditing(false);
  }

  const saveForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  }

  const captureEscape = (e: any) => {
    if (e.key === "Escape") {
      stopEditing();
    }
  }

  const formattedData = () => {
    return props.options.find(x => x.ID === data.ID)?.Name ?? "N/A";
  }

  const renderDisplay = () => {
    return <span onClick={startEditing} className={`${props.readOnly ? 'disabled' : 'editable-field'} text-left btn btn-block`}>
      {props.renderDisplay ? props.renderDisplay(props.data) : formattedData()}
    </span>
  }

  const renderInputField = () => {
    return <DropDownList
        disabled={saving}
        className="k-dropdown-wrap-append flex-fill w-auto"
        value={props.options.find(x => x.ID === data.ID)}
        textField="Name"
        dataItemKey="ID"
        data={props.options}
        onChange={(e) => setData(e.target.value as T)}
      />;
  }

  const renderEditable = () => {
    return <form className="input-group" onKeyDown={captureEscape} onSubmit={saveForm}>
      {props.renderEditable ? props.renderEditable(data, saving, setData) : renderInputField()}
      <div className="input-group-append">
        <button className="btn btn-outline-primary" disabled={saving} type="submit">
          {saving ? <Loader size="small" /> : <SvgIcon icon={checkIcon} />}
        </button>
        <button className="btn btn-outline-warning" disabled={saving} type="button" onClick={stopEditing}>
          <SvgIcon icon={xIcon} />
        </button>
      </div>
    </form>;
  };

  return <>
    {editing ? renderEditable() : renderDisplay()}
  </>;
}