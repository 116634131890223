import { GridCellProps } from "@progress/kendo-react-grid";
import * as React from 'react';
import { Document } from './Documents';

export default function documentActionCell(LoadDialogScreen: (document: Document) => void) {
  return class ActionPopup extends React.Component<GridCellProps> {
    render() {
      return (
        <td>
          <button type="button" className="btn btn-sm btn-primary" onClick={(e) => {
            LoadDialogScreen(this.props.dataItem);
            e.preventDefault();
            this.forceUpdate();
          }}>View/Edit</button>
        </td>
      );
    }
  }
}
