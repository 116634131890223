import AssetPanel from '../Assets/AssetPanel';

const AssetCarriers = () => {
  return <AssetPanel
    searchTerm=""
    tabIndex={5}
  />
}

export default AssetCarriers;
