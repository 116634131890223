import { GridCellProps } from "@progress/kendo-react-grid";
import { Link } from 'react-router-dom';

export const IDCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  return (
    <td>
      <Link
        to={`/PurchaseOrder/Index/${props.dataItem.PurchaseOrderNumber}`}
        onClick={() => window.scrollTo(0, 0)}
        style={{ color: '#007bff' }}
      >
        {props.dataItem[props.field]}
      </Link>
    </td>
  );
}
