import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class VehicleType extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    return (
      <td title={this.props.dataItem.VehicleDescription}>
        {this.props.dataItem.VehicleType}
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
