import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { RateMasterFormat } from '../../TypeGen/Rate/rate-master-format';
import { isNullOrWhiteSpace } from '../../utils/utils';
import CopyRateMasterDialog from './CopyRateMasterDialog';
import VehicleRateBreakStructure from './VehicleRateBreakStructure';

type Props = {
  CustomerID?: number;
  RateMasterFormat: RateMasterFormat;
  CloseDialog?: () => void;
};

type RateMasterSummary = {
  Title: string;
  //RatingCustomerID: number;
  RateMasters: RateMasterViewModel[];
}

export type RateMasterViewModel = {

  RateMasterID: number;
  RateFormatName: string;
  RateMasterFormat: RateMasterFormat

  ModifiedByFullName: string;
  ModifiedDateTime: Date;
  StartDate: Date;
  EndDate: Date;

  Name: string;
  Comment: string; //Instruction

  inEdit?: boolean | string;
  isNew?: boolean;
  Supercede?: boolean;
  IsExpired: boolean;
};

export const RateMasterFormatName = (rateMasterFormat: RateMasterFormat) => {
  //switch (rateMasterFormat) {
  //  case RateMasterFormat.TierZipToZip:
  //    return "Tier (Zip To Zip)";
  //  case RateMasterFormat.Tier:
  //    return "Tier (State To State)";
  //}
  return RateMasterFormat[rateMasterFormat];
}

export const usesTierComponent = (rateMasterFormat: RateMasterFormat): boolean => {
  return (
    rateMasterFormat === RateMasterFormat.Tier
  );
}

export const usesMileageBreakComponent = (rateMasterFormat: RateMasterFormat): boolean => {
  return (
    rateMasterFormat === RateMasterFormat.Tier ||
    rateMasterFormat === RateMasterFormat.Expedite
  );
}

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: RateMasterViewModel = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

const textBoxCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: TextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  var value = props.dataItem[props.field] as string;
  let dataItem: RateMasterViewModel = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ?
        <TextBox
          value={value}
          onChange={handleChange}
          style={{ width: '100%' }}
        /> :
        <span>{value}</span>
      }
    </td>
  );
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: RateMasterViewModel) => void;
  //remove: (item: RateMasterViewModel) => void;
  add: (item: RateMasterViewModel) => void;
  discard: () => void;
  update: (item: RateMasterViewModel) => void;
  cancel: (item: RateMasterViewModel) => void;
  editField: string;
}


const EditContext = createContext({} as any);
const DateCellContextProvider = (props: GridCellProps) => {
  const { localizedData } = useContext(EditContext);
  return DateCell(props, localizedData);
};

const DateCell = (props: GridCellProps, data: any) => {
  if (!props.field)
    return null;

  const handleChange = (e: Date) => {
    if (props.field === "StartDate") {
      e = Moment.utc(e).tz("America/New_York").toDate();
    } else {
      e = Moment.utc(e).tz("America/New_York").endOf('day').toDate();
    }

    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: null,
        value: e,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {props.dataItem.inEdit ?
        <SimpleDatePicker
          value={Moment.utc(props.dataItem[props.field]).tz("America/New_York").toDate()}
          onChange={handleChange}
        />
        :
        <div className={props.field === 'EndDate' && props.dataItem.IsExpired ? 'text-warning' : ''}>
          {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
        </div>
      }
    </td>
  );
};

const RateMaster = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const editField: string = "inEdit";
  const [CSIDataCopy, setCSIDataCopy] = useState<RateMasterViewModel[]>([]);
  const [RateMasters, setRateMasters] = useState<RateMasterViewModel[]>([]);
  const [hideExpiredPayRateStructures, setHideExpiredPayRateStructures] = useState(true);
  const [title, setTitle] = useState('');
  const { alert } = useAlert();
  const [selectedRateMaster, setSelectedRateMaster] = useState<RateMasterViewModel>();
  const [rateMasterToCopy, setRateMasterToCopy] = useState(0);

  const RateMasterIDCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: RateMasterViewModel = props.dataItem;

    return dataItem.inEdit ? (
      <td colSpan={props.colSpan} style={props.style}>
        <span className="ml-2">{dataItem.isNew ? '-' : dataItem.RateMasterID}</span>
      </td>
    ) : (
      <td colSpan={props.colSpan} style={props.style}>
        <Button fillMode="flat" size="small" themeColor="primary" onClick={() => setSelectedRateMaster(dataItem)}>{dataItem.RateMasterID}</Button>
      </td>
    );
  };

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: RateMasterViewModel = props.dataItem;
    const isNewItem = dataItem.isNew;

    const disabled = dataItem.StartDate == null || dataItem.EndDate == null || isNullOrWhiteSpace(dataItem.Name)

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disabled}
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={RateMasters.some(x => x.inEdit === true)}
          onClick={() => props.edit(dataItem)}
        >
          Edit
        </Button>
        <Button themeColor={"primary"}
          disabled={RateMasters.some(x => x.inEdit === true)}
          onClick={() => setRateMasterToCopy(dataItem.RateMasterID)}
        >
          Copy
        </Button>
      </td>
    );
  };

  const refresh = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Quote/GetRateMasters/${props.CustomerID ?? 0}/${props.RateMasterFormat ?? RateMasterFormat.Expedite}`)
      .then((summary: RateMasterSummary) => {
        setLoading(false);

        summary.RateMasters.forEach(obj => {
          obj.StartDate = Moment.utc(obj.StartDate).tz("America/New_York").toDate();
          obj.EndDate = Moment.utc(obj.EndDate).tz("America/New_York").toDate();
        });

        setRateMasters(summary.RateMasters);
        setCSIDataCopy(summary.RateMasters);

        setTitle(summary.Title);
      })
      .catch(async err => {
        setLoading(false);
        await alert(err);
      });
  }, [props.CustomerID, props.RateMasterFormat, alert]);

  useEffect(() => {
    refresh();
  }, [props.CustomerID, refresh]);

  const enterEdit = (dataItem: RateMasterViewModel) => {
    if (RateMasters.some(x => x.inEdit === true))
      return;

    setRateMasters(
      RateMasters.map((item) =>
        item.RateMasterID === dataItem.RateMasterID ? { ...item, inEdit: true } : item
      )
    );
  };

  const updateRateMasters = (dataItem: RateMasterViewModel) => {
    setLoading(true);
    const data = { ...dataItem }
    fetchApi(`/api/Quote/RateMaster/${props.CustomerID ?? 0}/${props.RateMasterFormat}`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const add = (dataItem: RateMasterViewModel) => {
    updateRateMasters(dataItem);
  };

  const update = (dataItem: RateMasterViewModel) => {
    updateRateMasters(dataItem);
  };

  const discard = () => {
    const newData = [...RateMasters];
    newData.splice(0, 1);
    setRateMasters(newData);
  };

  const cancel = (dataItem: RateMasterViewModel) => {
    const originalItem = CSIDataCopy.find(
      //const originalItem = RateMasters.find(
      (p) => p.RateMasterID === dataItem.RateMasterID
    );
    const newData = RateMasters.map((item) =>
      item.RateMasterID === originalItem.RateMasterID ? originalItem : item
    );

    setRateMasters(newData);
  };

  const generateId = () =>
    RateMasters.reduce((acc, current) => Math.max(acc, current.RateMasterID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      RateMasterID: generateId(),
      isNew: true,
      Comment: '',
      StartDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      EndDate: Moment([2999, 11, 31]).utc().tz("America/New_York").endOf('day').toDate(),
      IsExpired: false,
      RateMasterFormat: props.RateMasterFormat,
      RateFormatName: RateMasterFormatName(props.RateMasterFormat),
      Name: RateMasterFormatName(props.RateMasterFormat)
    } as RateMasterViewModel;
    setRateMasters([newDataItem, ...RateMasters]);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const dataItem = event.dataItem as RateMasterViewModel;
    const newData = RateMasters.map((item) =>
      item.RateMasterID === dataItem.RateMasterID
        ? { ...item, [event.field || ""]: event.value }
        : item
    );

    setRateMasters(newData);
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      //remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const dataView = () => {
    return (<>
      <div className="container-fluid mt-4" style={{ position: "relative" }}>
        {loading && <CenterDivPanel>
          <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <h4 className="text-left">{title} {RateMasterFormatName(props.RateMasterFormat)} Rate Structure</h4>
        <Grid
          data={hideExpiredPayRateStructures ? RateMasters.filter(x => x.IsExpired === false) : RateMasters}
          onItemChange={itemChange}
          editField={editField}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => { enterEdit(e.dataItem); }}
        >
          <GridToolbar>
            <Button themeColor={"primary"}
              disabled={RateMasters.some(x => x.inEdit === true)}
              title="Add new"
              onClick={addNew}
            >
              Add New {`${RateMasterFormatName(props.RateMasterFormat)}`} Rate Structure
            </Button>
            <span>
              <Checkbox
                disabled={RateMasters.some(x => x.inEdit === true)}
                className="ml-2"
                value={hideExpiredPayRateStructures}
                onChange={(e) => setHideExpiredPayRateStructures(e.value)}
                label={"Hide Expired Rate Structures"}
              />
            </span>
          </GridToolbar>
          <Column field="RateMasterID" title="Rate Code" editable={false} cell={RateMasterIDCell} />
          <Column field="RateFormatName" title="Rate Structure" editable={false} />
          <Column field="Name" title="Name" cell={textBoxCell} />
          <Column field="StartDate" title="Effective From" cell={DateCellContextProvider} width={175} />
          <Column field="EndDate" title="Effective To" cell={DateCellContextProvider} width={175} />
          <Column field="Comment" title="Comment" cell={textBoxCell} />
          <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
          <Column cell={MyEditCommandCell} width={160} />
        </Grid>

        {selectedRateMaster &&
          <Dialog title={`${selectedRateMaster.Name.toUpperCase()} (${selectedRateMaster.RateMasterID})`} className='dialog-w11/12' onClose={() => setSelectedRateMaster(null)}>
            <VehicleRateBreakStructure RateMasterID={selectedRateMaster.RateMasterID} />
          </Dialog>}
        {rateMasterToCopy > 0 && <CopyRateMasterDialog rateMasterID={rateMasterToCopy} rateMasterDescription={`${title} ${RateMasterFormatName(props.RateMasterFormat)} Rate Structure`} onClose={() => setRateMasterToCopy(0)} />}

      </div>
    </>);
  };

  if (props.CloseDialog) {
    return <Dialog title={`Set ${title} Rate Structure`} onClose={props.CloseDialog} className='dialog-w11/12'>
      {dataView()}
    </Dialog>
  }

  return dataView();
}

export default RateMaster;
