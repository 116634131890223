import { useParams } from 'react-router';
import { RateMasterFormat } from '../../TypeGen/Rate/rate-master-format';
import { Title } from '../../utils/title';
import { CustomerNavBar } from '../Customers/NavBar';
import RateFuelMaster from './RateFuelMaster';
import RateMaster from './RateMaster';

type RouteComponentParams = {
  customerId: string;
};

const RateMasterViewer = () => {
  const { customerId: customerIdParam } = useParams<RouteComponentParams>();
  const customerId = customerIdParam ? Number(customerIdParam) : 0;

  const dataView = () => {
    return (<div className="container-fluid">
      {customerId > 0 && <><CustomerNavBar id={customerId} currentTab="RateStructures" /><Title string="Customer Rate Structures" /></>}
      {customerId === 0 && <Title string="Tariff Rate Structures" />}

      <RateFuelMaster CustomerID={customerId} />
      <RateMaster CustomerID={customerId} RateMasterFormat={RateMasterFormat.Tier} />
      <RateMaster CustomerID={customerId} RateMasterFormat={RateMasterFormat.Expedite} />
      <RateMaster CustomerID={customerId} RateMasterFormat={RateMasterFormat.Accessorial} />
    </div>);
  };

  return dataView();
}

export default RateMasterViewer;
