import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";

export default class PhoneCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field || this.props.rowType !== 'data') return null;

    return (
        <td>
            <a href={`tel:${this.props.dataItem[this.props.field]}`} className="text-primary">{this.props.dataItem[this.props.field]}</a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return this.props.dataItem[this.props.field] !== nextProps.dataItem[this.props.field];
  }
}
