import * as React from 'react';
import * as Sentry from '@sentry/browser';

type Props = {
    children?: React.ReactNode;
}

type State = {
    error: any;
    eventId: string;
}

export default class SentryErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { error: null, eventId: '' };
    }

    componentDidCatch(error: any, errorInfo: any) {
      this.setState({ error });
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo);
          const eventId = Sentry.captureException(error);
          this.setState({eventId});
      });
    }

    render() {
        if (this.state.error) {
            return (
            <React.Fragment>
                <div className="text-center">
                    <h1>Oops, we crashed.</h1>
                    <p>You can help us out by sending feedback, or leave us to figure out what happened on our own.</p>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    >
                        Go Home
                    </button>
                    &nbsp;&nbsp;
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                    >
                        Send Feedback
                    </button>
                </div>
            </React.Fragment>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}
