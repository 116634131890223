import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { formatNumber } from '@progress/kendo-intl';
import { Push } from "@progress/kendo-react-animation";
import { Button, SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import Moment from 'moment-timezone';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { downloadBlobAsFile, fetchApi, fetchByteData } from '../../services/api';
import { openWindowPerc } from '../../services/openWindow';
import { IDName } from '../../types/idname';
import { Title } from '../../utils/title';
import { isNullOrWhiteSpace, PayDivisions } from '../../utils/utils';
import { DeductionType } from './DeductionCOAs';
import PayPeriodGrandTotalSummary from './PayPeriodGrandTotalSummary';
import PaySheetViewer from './PaySheetViewer';
import { ScheduledDeduction, textBoxCell } from './ScheduledDeductions';
import TimerComponent, { TimerAction } from './TimerComponent';

export interface Deduction {
  PayPeriodID: number;

  PeriodEndDate: Date;
  PaperworkDeadline: Date;
  PayDate: Date;

  Name: string;
  Label: string;
  Division: number;
  PeriodClosed: boolean;
  ModifiedDateTime: Date;
  CanGenerate: boolean;

  inEdit?: boolean | string;
  isNew?: boolean;
  Delete?: boolean;
}

const ScheduledDeductionsContext = createContext<{
  DeductionTypes: DeductionType[],
}>({
  DeductionTypes: []
})

interface EditCommandCellProps extends GridCellProps {
  edit: (item: Deduction) => void;
  remove: (item: Deduction) => void;
  add: (item: Deduction) => void;
  discard: () => void;
  update: (item: Deduction) => void;
  cancel: (item: Deduction) => void;
  editField: string;
}

const UnEditableLabelCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  var value = props.dataItem[props.field] as string;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
      {props.dataItem.isNew === true ? <span>-</span> : <span>{value}</span>}
    </td>
  );
}

const DateCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const handleChange = (e: Date) => {
    if (props.onChange) {

      let dateValue = e;
      if (props.field === 'PeriodEndDate' || props.field === 'PaperworkDeadline') {
        dateValue = Moment.utc(e).tz("America/New_York").endOf('day').toDate();
      }

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: null,
        value: dateValue,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {props.dataItem.inEdit ?
        <SimpleDatePicker
          value={Moment.utc(props.dataItem[props.field]).toDate()}
          onChange={handleChange}
        />
        :
        <div>
          {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
        </div>
      }
    </td>
  );
};

const StatusCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: Deduction = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
      {dataItem.PeriodClosed ? 'Closed' : 'Open'}
    </td>
  );
}

const DivisionCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: Deduction = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent, value: number, field: string) => {
    if (props.onChange) {

      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: field, //props.field,
        syntheticEvent: e.syntheticEvent,
        value: value,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={PayDivisions}
        textField="Name"
        dataItemKey="ID"
        value={PayDivisions.find((x: IDName) => x.ID == dataItem.Division)}
        onChange={(e) => handleChange(e, e.target.value.ID, 'Division')}
      /> :
        <div>{PayDivisions.find((x: IDName) => x.ID == dataItem.Division).Name}</div>
      }
    </td>
  );
}

const AmountCell = (props: GridCellProps) => {
  let dataItem: ScheduledDeduction = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      <span>{formatNumber(dataItem.TotalAmount, "c2")}</span>
    </td>
  );
};

const dateTimeUtcNow = Moment.utc().startOf('minute');
const GenerateDeductions = () => {
  const notInitialRender = useRef(false);

  useEffect(() => {
    notInitialRender.current = true;
  }, [notInitialRender])

  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "PeriodEndDate",
      dir: "desc"
    }, {
      field: "Division",
      dir: "asc"
    }]
  } as GridState;

  //const defaultAndFilter = {
  //    logic: 'and', filters: []
  //} as CompositeFilterDescriptor;

  const defaultAndFilter = {
    logic: 'and', filters: [
      { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
    ]
  } as CompositeFilterDescriptor;

  const sessionStorageAndFilter = sessionStorage.getItem("GenerateDeductions-andFilter");
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
  const totalRecords = useRef(0);

  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  const [messageContainer, setMessageContainer] = useState<{ message: string, style: string }>({ message: '', style: 'none' });
  const editField: string = "inEdit";
  const [scheduledDeductions, setScheduledDeductions] = useState<Deduction[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<Deduction[]>([]);
  const [deductionTypes, setDeductionTypes] = useState<DeductionType[]>([]);
  const [showPaySheetViewer, setShowPaySheetViewer] = useState<{ PayPeriodID: number, Division: number }>(null);
  const [showGrandTotalsReportViewer, setShowGrandTotalsReportViewer] = useState(0);
  const [filterOnlyOpenPPCheckbox, setFilterOnlyOpenPPCheckbox] = useState<boolean>(true);
  const [action, setAction] = useState<TimerAction>(TimerAction.Stop);
  const [displayMissingFuturePayPeriodMessage, setDisplayMissingFuturePayPeriodMessage] = useState(false);

  const onSplitButtonItemClick = async (option: string, dataItem: Deduction, commandCellProps: EditCommandCellProps) => {
    switch (option) {
      case "Edit":
        commandCellProps.edit(dataItem);
        break;
      case "View Generated Deductions":
      case "View Posted Deductions":
        //setShowPendingDeductionViewer(dataItem.PayPeriodID);        
        openWindowPerc(`/Settlements/PendingDeductions?PayPeriodID=${dataItem.PayPeriodID}`, .9, .9);
        break;
      case "E-Mail All Pay Sheets":
        if (!await confirm(`E-Mail All Pay Sheets ${dataItem.Name}?`, 'Confirm'))
          return;
        emailAllPaySheets(dataItem);
        break;
      case "Download All Pay Sheets":
        downloadPaySheets(dataItem);
        break;
      case "View Grand Totals Report":
        setShowGrandTotalsReportViewer(dataItem.PayPeriodID);
        break;
      case "Generate Deductions":
        if (!await confirm(`Generate deductions for ${dataItem.Name}?`, 'Confirm'))
          return;
        generateDeductions(dataItem);
        break;
      default:
        const lowerCaseOption = option.toLowerCase(); // Convert to lower case for case-insensitive matching
        if (lowerCaseOption.includes("view pay sheets ")) {
          setShowPaySheetViewer({ PayPeriodID: dataItem.PayPeriodID, Division: dataItem.Division });
        }
        break;
    }
  }

  const CustomItemRender = (props: any) => {
    return (
      <span className="text-warning">
        {props.item.text}
      </span>
    );
  };

  const EditCommandCell = (_props: EditCommandCellProps) => {
    const inEdit = _props.dataItem[_props.editField];
    let dataItem: Deduction = _props.dataItem;
    const isNewItem = dataItem.isNew;

    const disableSave = isNullOrWhiteSpace(dataItem.Name) || dataItem.Division < 2
      || dataItem.PeriodEndDate == null || dataItem.PayDate == null || dataItem.PaperworkDeadline == null;

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disableSave}
          onClick={() =>
            isNewItem ? _props.add(dataItem) : _props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? _props.discard() : _props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        {dataItem.PeriodClosed === false && dataItem.CanGenerate &&
          <SplitButton text={`View Pay Sheets | ${dataItem.Name}`} themeColor={'warning'} onButtonClick={(e) => {
            onSplitButtonItemClick(e.target.props.text, dataItem, _props)
          }} onItemClick={(e) => onSplitButtonItemClick(e.item.text, dataItem, _props)}>
            <SplitButtonItem text={'Generate Deductions'} render={CustomItemRender} />
            <SplitButtonItem text={'View Generated Deductions'} />
            <SplitButtonItem text={'View Grand Totals Report'} />
            <SplitButtonItem text={'Download All Pay Sheets'} />
            {(dataItem.PeriodClosed || dataItem.CanGenerate) && < SplitButtonItem text={'E-Mail All Pay Sheets'} />}
            <SplitButtonItem text={'Edit'} />
          </SplitButton>}

        {dataItem.PeriodClosed === false && dataItem.CanGenerate === false &&
          <Button themeColor={"primary"}
            disabled={scheduledDeductions.some(x => x.inEdit === true)}
            onClick={() => _props.edit(dataItem)}>Edit</Button>}

        {dataItem.PeriodClosed == true &&
          <SplitButton text={`View Pay Sheets | ${dataItem.Name}`} onButtonClick={(e) => {
            onSplitButtonItemClick(e.target.props.text, dataItem, _props)
          }} onItemClick={(e) => onSplitButtonItemClick(e.item.text, dataItem, _props)}>
            <SplitButtonItem text={'View Posted Deductions'} />
            <SplitButtonItem text={'View Grand Totals Report'} />
            <SplitButtonItem text={'Download All Pay Sheets'} />
            {(dataItem.PeriodClosed || dataItem.CanGenerate) && < SplitButtonItem text={'E-Mail All Pay Sheets'} />}
          </SplitButton>}
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const enterEdit = (dataItem: Deduction) => {
    if (scheduledDeductions.some(x => x.inEdit === true))
      return;

    setScheduledDeductions(
      scheduledDeductions.map((item) =>
        item.PayPeriodID === dataItem.PayPeriodID ? { ...item, inEdit: true } : item
      )
    );
  };

  const remove = (dataItem: Deduction) => {
    updateScheduledDeduction({ ...dataItem, Delete: true });
  };

  const add = (dataItem: Deduction) => {
    updateScheduledDeduction(dataItem);
  };

  const update = (dataItem: Deduction) => {
    updateScheduledDeduction(dataItem);
  };

  const cancel = (dataItem: Deduction) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.PayPeriodID === dataItem.PayPeriodID
    );
    const newData = scheduledDeductions.map((item) =>
      item.PayPeriodID === originalItem.PayPeriodID ? cloneObject(originalItem) : item
    );

    setScheduledDeductions(newData);
  };

  const generateId = () =>
    scheduledDeductions.reduce((acc, current) => Math.max(acc, current.PayPeriodID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, isNew: true, Delete: false,
      PayPeriodID: generateId(),
      Division: 0,
      PeriodClosed: false,
      PeriodEndDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      PayDate: Moment.utc().tz("America/New_York").startOf('day').toDate(),
      PaperworkDeadline: Moment.utc().tz("America/New_York").tz("America/New_York").startOf('day').toDate(),
    } as Deduction;
    setScheduledDeductions([newDataItem, ...scheduledDeductions]);
  };

  const discard = () => {
    const newData = [...scheduledDeductions];
    newData.splice(0, 1);
    setScheduledDeductions(newData);
  };

  const fetchRecords = useCallback((append: boolean) => {
    sessionStorage.setItem("GenerateDeductions-andFilter", JSON.stringify(dataState.filter));
    setLoading(true);
    //const dataGridState = { ...dataState };
    if (!append) {
      dataState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }
    let queryStr = `${toDataSourceRequestString(dataState)}`;
    if (filterOnlyOpenPPCheckbox) {
      queryStr += `&ShowCurrentPP=${filterOnlyOpenPPCheckbox}`
    }
    fetchApi(`/api/Settlements/GetPayPeriodsDataSourceResult?${queryStr}`, {}, 'POST')
      .then(({ Data, Total, DisplayMissingFuturePayPeriodMessage }) => {
        totalRecords.current = Total;
        setDisplayMissingFuturePayPeriodMessage(DisplayMissingFuturePayPeriodMessage);
        if (!append) {
          setScheduledDeductions(Data);
          setCSIDataCopy(Data);
        } else {
          setScheduledDeductions(prevData => prevData.concat(Data));
          setCSIDataCopy(prevData => prevData.concat(Data));
        }
        setLoading(false);
        //  handleResize(null); //need this if using hierarchy filters
      }).catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [alert, dataState, filterOnlyOpenPPCheckbox]);

  useEffect(() => {
    fetchRecords(false);
  }, [fetchRecords]);

  useEffect(() => {
    fetchApi('/api/settlements/getdeductiontypes')
      .then((response: any) => {
        setDeductionTypes((response.DeductionTypes as DeductionType[]).filter(x => x.Active === true));
      });
  }, []);

  const emailAllPaySheets = (dataItem: Deduction) => {
    setLoading(true);

    fetchApi(`api/Settlements/EmailAllPaySheets/${dataItem.PayPeriodID}`)
      .then(() => {
        setLoading(false);
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const downloadPaySheets = (dataItem: Deduction) => {
    setLoading(true);
    setAction(TimerAction.Restart);
    setMessageContainer({ message: "The requested file is being generated.  This process could take several minutes to complete.  Please wait...", style: 'info' });
    fetchByteData(`/api/Settlements/DownloadPaySheets/${dataItem.PayPeriodID}`)
      .then((resp) => {
        setAction(TimerAction.Stop);
        setLoading(false);
        downloadBlobAsFile(resp.blob, resp.filename);
        setMessageContainer({ message: "Your file is now ready!", style: 'success' });
      })
      .catch(async e => {
        setAction(TimerAction.Stop);
        setLoading(false);
        // If not problem details      
        setMessageContainer({ message: '', style: '' });
        if (!e?.status) await alert('An error occurred.');
      });
  }

  //need this if using hierarchy filters
  //const handleResize = (e: UIEvent) => {
  //    if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
  //        ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
  //    }
  //}

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (scheduledDeductions.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const cloneObject = (obj: any): any => {
    return JSON.parse(JSON.stringify(obj));
  }

  const updateObject = (updatedObject: Deduction, payDeductionID: number) => {
    const updatedObjects = scheduledDeductions.map((obj) =>
      obj.PayPeriodID === payDeductionID ? updatedObject : obj
    );
    setScheduledDeductions(updatedObjects);
  };

  const updateScheduledDeduction = (dataItem: Deduction) => {
    setLoading(true);

    const data = { ...dataItem }
    fetchApi(`api/Settlements/PayPeriod`, data, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else {
          data.isNew = false; data.inEdit = false;
          updateObject(data, data.PayPeriodID);
          setLoading(false);
        }
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const generateDeductions = (dataItem: Deduction) => {
    setLoading(true);

    const data = { ...dataItem }
    fetchApi(`api/Settlements/GenerateDeductions/${dataItem.PayPeriodID}`, {}, 'POST')
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else {
          data.isNew = false; data.inEdit = false;
          updateObject(data, data.PayPeriodID);
          setLoading(false);
        }
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = scheduledDeductions.map((item) =>
      item.PayPeriodID === event.dataItem.PayPeriodID
        ? { ...item, [field]: event.value }
        : item
    );
    setScheduledDeductions(newData);
  }

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
  };

  const dataView = () => {
    return (<div className="mt-3" style={{ position: "relative" }}>
      <Title string="Pay Periods" />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Push style={{ zIndex: 0, width: '100%' }}>
        {messageContainer.message.length > 0 && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
          <Notification style={{ padding: "8px", width: '100%' }} type={{ style: messageContainer.style as any, icon: true }}>
            <div>{messageContainer.message} <span style={{ float: 'right' }}><TimerComponent action={action} /> second(s) </span></div>
          </Notification>
        </NotificationGroup>}
      </Push>
      <h4 className="text-left">Pay Periods</h4>
      {displayMissingFuturePayPeriodMessage && <div className="alert alert-warning" role="alert">
        <h5 className="alert-heading mb-0">Heads up! It looks like you haven't set any future pay periods, or there are only a few left. Please add more to keep things running smoothly.</h5>
      </div>}
      <ScheduledDeductionsContext.Provider value={{ DeductionTypes: deductionTypes ?? [] }}>
        <Grid
          style={{
            maxHeight: `${window.innerHeight * .80}px`,
          }}
          data={scheduledDeductions}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={totalRecords.current}
          onScroll={scrollHandler}
          //filterable={true}
          resizable={true}
          onFilterChange={(e) => onGridFilterChange(e)}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
          onItemChange={itemChange}
          editField={editField}
        //dataItemKey={"PayPeriodID"}
        >
          <GridToolbar>
            <Button themeColor={'primary'}
              disabled={scheduledDeductions.some(x => x.inEdit === true)}
              title="Add new deduction"
              onClick={addNew}
            >
              Add New Pay Period
            </Button>
            <span>
              <Checkbox
                className="ml-2"
                value={filterOnlyOpenPPCheckbox}
                onChange={(e) => { setFilterOnlyOpenPPCheckbox(e.value); }}
                label={"Display recently closed and current open pay periods only"}
              />
            </span>
          </GridToolbar>
          <Column field="PayPeriodID" title="ID" editable={false} cell={UnEditableLabelCell} />
          <Column field="Name" title="Name" cell={textBoxCell} />
          <Column field="Division" title="Division" cell={DivisionCell} filterable={false} />
          <Column field="PeriodEndDate" title="Trips Up To" cell={DateCell} minResizableWidth={160} width={160} />
          <Column field="PaperworkDeadline" title="Paperwork Checked In By" cell={DateCell} minResizableWidth={160} width={160} />
          <Column field="PayDate" title="Pay Date" cell={DateCell} minResizableWidth={160} width={160} />
          <Column field="PeriodClosed" title="Status" editable={false} cell={StatusCell} />
          <Column cell={MyEditCommandCell} width={215} filterable={false} />
        </Grid>
      </ScheduledDeductionsContext.Provider>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
      <ConfirmationDialog />
      {showPaySheetViewer && <PaySheetViewer PayPeriodID={showPaySheetViewer?.PayPeriodID} Division={showPaySheetViewer?.Division} onDialogClose={() => setShowPaySheetViewer(null)} />}
      {/*{showPendingDeductionViewer > 0 && <PendingDeductions PayPeriodID={showPendingDeductionViewer} onDialogClose={() => setShowPendingDeductionViewer(0)} />}*/}
      {showGrandTotalsReportViewer > 0 && <PayPeriodGrandTotalSummary PayPeriodID={showGrandTotalsReportViewer} onDialogClose={() => setShowGrandTotalsReportViewer(0)} />}
    </div>);
  };

  return dataView();
}

export default GenerateDeductions;
