import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { CarrierNavBar } from "./CarrierNavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { Grid, GridColumn as Column, GridNoRecords, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import DateCell from "../../components/cells/DateCell";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { arrowRotateCwIcon, plusIcon } from "@progress/kendo-svg-icons";
import { ApiLink } from "TypeGen/api-link";
import AppTracking from "views/Track/AppTracking";
import { openWindow } from "services/openWindow";
import { Link } from "react-router-dom";
import { formatPhone } from "utils/utils";

type RouteComponentParams = {
  carrierId: string;
};

type CarrierVehicle = {
  CarrierVehicleID: number;
  VehicleNumber: string;
  PhoneNumber: string;
  Active: boolean;
  ServiceStatus: string;
  VehicleTypeName: string;
  AvailableDateTime: Date | null;
  AvailableLatitude: number | null;
  AvailableLongitude: number | null;
  AvailableLocation: string;
  AvailabilityNote: string;
}

const CarrierVehicles = () => {

  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState<CarrierVehicle[]>();
  const [links, setLinks] = useState<ApiLink[]>();
  const [trackingLink, setTrackingLink] = useState<ApiLink>();
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'VehicleNumber', dir: 'desc' }]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/CarrierVehicles/${carrierIdParam}`)
      .then((data: { Vehicles: CarrierVehicle[], Links: ApiLink[] }) => {
        setLoading(false);
        setVehicles(data.Vehicles);
        setLinks(data.Links);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [carrierIdParam]);

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setSort(event.sort);
  };

  useEffect(() => {
    refresh();
  }, [carrierIdParam, refresh]);

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <Grid
      sortable
      scrollable="none"
      sort={sort}
      data={orderBy(vehicles, sort)}
      onSortChange={onGridSortChange}
      dataItemKey="CarrierVehicleID"
    >
      <GridNoRecords>
        {loading == false && <span>No Vehicles Added</span>}
      </GridNoRecords>
      <GridToolbar>
        <Button
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          title="Refresh"
          onClick={refresh}
        />
        <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => setTrackingLink(links.find((x) => x.Name === 'AppTrackingSetup'))}
        > Vehicle
        </Button>
      </GridToolbar>
      <Column field="PhoneNumber" title="Cell #" cell={(props) => {
        return <td>
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              setTrackingLink(props.dataItem.Links.find((x: ApiLink) => x.Name === 'AppTracking'));
              e.preventDefault();
            }}
          >
            {formatPhone(props.dataItem.PhoneNumber)}
          </a>
        </td>
      }} />
      <Column field="VehicleNumber" title="Unit #" cell={(props) => {
        return <td>
          <Link
            style={{ color: '#007bff' }}
            to={`/Assets/Carrier/${carrierIdParam}/Vehicle/${props.dataItem.CarrierVehicleID}`}
          >
            {props.dataItem.VehicleNumber}
          </Link>
        </td>
      }} />
      <Column field="VehicleTypeName" title="Type" />
      <Column field="ServiceStatus" title="Status" />
      <Column field="AvailableDateTime" title="Available" cell={DateCell} />
      <Column field="AvailableLocation" title="Location" cell={(props) => {
        return <td>
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              e.preventDefault();
              openWindow(`https://maps.google.com/maps?q=loc:${props.dataItem.AvailableLatitude}+${props.dataItem.AvailableLongitude}`);
            }}
          >
            {props.dataItem.AvailableLocation}
          </a>
        </td>
      }} />
      <Column field="AvailabilityNote" title="Note" />
    </Grid>

  return (<>
    <CarrierNavBar id={carrierIdParam} currentTab="Vehicles" />
    <Title string="Vehicles" />
    <br />
    {trackingLink && <AppTracking
      CloseDialog={(shouldRefresh) => {
        if (shouldRefresh) refresh();
        setTrackingLink(null);
      }}
      Link={trackingLink}
    />}
    {loading || !vehicles ? loadingView : dataView()}
  </>);
}

export default CarrierVehicles;
