import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import * as React from 'react';
import PriceChartCell from './PriceChart';
import CountChartCell from './CountChart';
import ColorPercentCell from './ColorPercent';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import CustomerInput from '../../components/CustomerInput';
import { arrowRotateCwIcon } from '@progress/kendo-svg-icons';

type Props = {};

type State = {
    data: LaneData[];
    loading: boolean;
    sort: SortDescriptor[];

    // Request
    PeriodView: 0 | 1;
    AuthorizationCustomerID: number;
    AuthorizationCustomerNumber: string;
}

type LaneData = {
    FromLocationName: string;
    ToLocationName: string;
    Chart: LaneChartPoint[];
    Volatility: number;
    Change: number;
    Distance: number;
    VehicleTypeID: number;
    VehicleTypeName: string;
    LatestPrice: number;
    Price: number;
    Count: number;
}

export type LaneChartPoint = {
    Position: number;
    Price: number;
    Count: number;
}

export default class LaneReports extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            sort: [],
            loading: true,

            PeriodView: 0,
            AuthorizationCustomerID: 0,
            AuthorizationCustomerNumber: '',
        }

        this.refreshData = this.refreshData.bind(this);
    }

    public componentDidMount() {
        this.refreshData();
    }

    public render() {
        const data = orderBy(this.state.data, this.state.sort);
        return <React.Fragment>
            <nav className="navbar navbar-dark bg-dark">
              <a className="navbar-brand" href="#">Lane Report</a>
              <ul className="nav navbar-nav navbar-right">
                <Button
                    title="Refresh"
                    icon="refresh"
                    svgIcon={arrowRotateCwIcon}
                    onClick={this.refreshData}
                />
              </ul>
            </nav>
            <br />
            <div className="form-group col-md-2">
                <div className="input-group">
                    <CustomerInput
                        placeholder='Authorization Company'
                        CustomerID={this.state.AuthorizationCustomerID}
                        CustomerNumber={this.state.AuthorizationCustomerNumber}
                        onChange={(e) => {

                            // Clear
                            const update = this.state.AuthorizationCustomerID > 0 && e.CustomerID == 0 || e.CustomerID > 0;

                            // Update
                            this.setState({ AuthorizationCustomerID: e.CustomerID, AuthorizationCustomerNumber: e.CustomerNumber }, () => {
                                if (update) {
                                    this.refreshData();
                                }
                            });
                        }}
                    />
                </div>
            </div>
            {this.state.loading && <LoadingPanel />}
            <Grid
                data={data}
                sortable={{ allowUnsort: true, mode: "multiple" }}
                sort={this.state.sort}
                onSortChange={(e) => this.setState({ sort: e.sort })}
            >
                <GridToolbar>
                    <ButtonGroup>
                        <Button togglable
                            disabled={this.state.loading}
                            selected={this.state.PeriodView == 0}
                            onClick={() => this.setState({ PeriodView: 0 }, this.refreshData)}
                        >
                            Weekly
                        </Button>
                        <Button togglable
                            disabled={this.state.loading}
                            selected={this.state.PeriodView == 1}
                            onClick={() => this.setState({ PeriodView: 1 }, this.refreshData)}
                        >
                            Monthly
                        </Button>
                    </ButtonGroup>
                </GridToolbar>
                <Column field="FromLocationName" title="From" />
                <Column field="ToLocationName" title="To" />
                <Column field="Chart" sortable={false} title="Price" width={105} cell={PriceChartCell} />
                <Column field="Chart" sortable={false} title="Volume" width={105} cell={CountChartCell} />
                <Column field="Distance" format="{0:n0}" />
                <Column field="Count" />
                <Column field="VehicleTypeName" title="Vehicle" />
                <Column field="Volatility" format="{0:n2}" />
                <Column field="Price" title="Avg Price" format="{0:c}" />
                <Column field="LatestPrice" title="Current Price" format="{0:c}" />
                <Column field="Change" title="Price Change" format="{0:p}" cell={ColorPercentCell} />
            </Grid>
        </React.Fragment>
    }

    public refreshData() {
        this.setState({ loading: true });
        const data = {
            PeriodView: this.state.PeriodView,
            AuthorizationCustomerID: this.state.AuthorizationCustomerID
        };
        fetchApi('/api/LaneReport/Lanes', data, 'POST')
            .then((data: LaneData[]) => {
                this.setState({ data, loading: false });
            });
    }
}