import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { IDName } from '../../types/idname';
import { Loader } from "@progress/kendo-react-indicators";
import { EditableField } from "../../components/EditableField";
import AuditInfo, { AuditInfoViewModel } from "../../components/AuditInfo";
import { CustomerContactNavBar } from "./CustomerContactNavBar";

type RouteComponentParams = {
  customerContactId: string;
};

type CustomerProfileType = {
  CustomerContact: CustomerContactType,
  Users: IDName[];
  CustomerContactTypeList: IDName[];
}

export type CustomerContactType = {
  FirstName: string;
  LastName: string;
  Active: boolean;
  EmailAddress: string;
  Title: string;
  ContactType: number;
  EmailInvoices: boolean;
  PhoneNumber: string;
  PhoneNumberExtension: string;
  CellNumber: string;
  CustomerID: number;

  AuditInfo: AuditInfoViewModel;
}

const CustomerContactProfile = () => {
  const { customerContactId: customerContactIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<CustomerProfileType>();

  const fetchProfile = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      await fetchApi(`/api/Customer/CustomerContact/${customerContactIdParam}`)
        .then(data => {
          setLoading(false);
          setProfile(data);

        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }
    fetchData();
  }, [customerContactIdParam]);


  useEffect(() => {
    fetchProfile();
  }, [customerContactIdParam, fetchProfile]);


  const saveProfile = (value: Partial<CustomerContactType>): Promise<void> => {
    return fetchApi(`/api/Customer/CustomerContact/${customerContactIdParam}`, updateProfileState(value), 'PUT');
  }

  const saveAsyncProfile = async (value: Partial<CustomerContactType>): Promise<void> => {
    const newProfile: CustomerContactType = Object.assign({}, profile.CustomerContact, value);
    return fetchApi(`/api/Customer/CustomerContact/${customerContactIdParam}`, newProfile, 'PUT');
  }

  const updateProfileState = (value: Partial<CustomerContactType>): CustomerContactType => {
    const newProfile: CustomerContactType = Object.assign({}, profile.CustomerContact, value);
    setProfile({ ...profile, CustomerContact: newProfile });
    return newProfile;
  }

  const loadingView = <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  const dataView = () => <div className="row m-2">
    <div className="col-md-7">
      <div className="form-group row">
        <label htmlFor="CustomerName" className="col-sm-2 col-form-label">First Name</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ maxLength: 20 }}
            data={profile.CustomerContact.FirstName}
            save={(data) => saveProfile({ FirstName: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="CustomerName" className="col-sm-2 col-form-label">Last Name</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ maxLength: 20 }}
            data={profile.CustomerContact.LastName}
            save={(data) => saveProfile({ LastName: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="Title" className="col-sm-2 col-form-label">Title</label>
        <div className="col-sm-10">
          <EditableField
            dataType="string"
            inputProps={{ maxLength: 50 }}
            data={profile.CustomerContact.Title}
            save={(data) => saveProfile({ Title: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="EmailAddress" className="col-sm-2 col-form-label">Email Address</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="string"
            inputProps={{ type: 'email', maxLength: 80 }}
            data={profile.CustomerContact.EmailAddress}
            save={(data) => saveProfile({ EmailAddress: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="LocationType" className="col-sm-2 col-form-label">Contact Type</label>
        <div className="col-sm-10">
          <EditableField
            data={profile.CustomerContact.ContactType}
            renderDisplay={(data) => profile.CustomerContactTypeList.find(x => x.ID == data).Name}
            renderEditable={(data, saving, onChange) => <select disabled={saving} className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
              {profile.CustomerContactTypeList.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
            </select>}
            save={(data) => saveProfile({ ContactType: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="PhoneNumber" className="col-sm-2 col-form-label">Phone Number</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="phone"
            data={profile.CustomerContact.PhoneNumber}
            save={(data) => saveProfile({ PhoneNumber: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="PhoneNumberExtension" className="col-sm-2 col-form-label">Phone Ext</label>
        <div className="col-sm-10">
          <EditableField
            dataType="string"
            inputProps={{ maxLength: 4 }}
            data={profile.CustomerContact.PhoneNumberExtension}
            save={(data) => saveProfile({ PhoneNumberExtension: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="CellNumber" className="col-sm-2 col-form-label">Cell Number</label>
        <div className="col-sm-10">
          <EditableField
            required
            dataType="phone"
            data={profile.CustomerContact.CellNumber}
            save={(data) => saveProfile({ CellNumber: data })}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="EmailInvoices" className="col-sm-2 col-form-label">Email Invoices</label>
        <div className="col-sm-10">
          <EditableField
            dataType="boolean"
            data={profile.CustomerContact.EmailInvoices}
            save={(data) => saveProfile({ EmailInvoices: data })}
          />
        </div>
      </div>
    </div>
    <div className="col-md-5">
      <br />
      <AuditInfo audit={profile.CustomerContact.AuditInfo} />
      <br />
      {/*<h2>Sales Information</h2>*/}
      {/*<h2>General Information</h2>*/}
      {/*<h2>Billing Information</h2>*/}
    </div>
  </div>

  return (<>
    <CustomerContactNavBar id={customerContactIdParam} currentTab="Profile" />
    {/*<Title string={profile?.CustomerContact?.CustomerNumber} />*/}
    {loading || !profile ? loadingView : dataView()}
  </>);
}

export default CustomerContactProfile;