import { GridCellProps } from '@progress/kendo-react-grid';
import Moment from 'moment-timezone';
import * as React from 'react';
import { Trip } from '.';
import Positions from './TripPositions';

const PositionCell = (props: GridCellProps) => {
  const [showPositions, setShowPositions] = React.useState(false);
  const trip = props.dataItem as Trip;
  return <td>
    {showPositions && <Positions tripId={trip.TripID} close={() => setShowPositions(false)} />}
    {trip.PositionDateTime ? <>
      <a
        href="#"
        style={{ color: (trip.NeedsCheckCall && trip.OrderStopStatus != 115 ? 'red' : '#007bff') }}
        onClick={(e) => {
          setShowPositions(true);
          e.preventDefault();
        }}
      >
        {Moment.utc(trip.PositionDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}
      </a><br />
      <span
        style={{ color: (trip.PositionRepeatCount >= 7 ? 'red' : '#007bff') }}
      >
        {trip.PositionLocation}
        {trip.PositionRepeatCount > 1 && ` (${trip.PositionRepeatCount}x)`}
      </span>
    </> : trip.TripID ? <a href="#"
        style={{ color: (trip.NeedsCheckCall && trip.OrderStopStatus != 115 ? 'red' : '#007bff') }}
        onClick={(e) => {
          setShowPositions(true);
          e.preventDefault();
        }}
      >
        No Position
      </a> : null}
    {trip.CarrierThreadID && <>
      <br />
      <a
        style={{ color: '#007bff' }}
        href="#" onClick={(e) => {
          const win = window.open(`https://app.frontapp.com/open/${trip.CarrierThreadID}`, '_blank');
          win.focus();
        }}
      >
        {trip.CarrierThreadLastEmailedDateTime ? 'Emailed ' + Moment.utc(trip.CarrierThreadLastEmailedDateTime).fromNow() : 'Email Carrier'}
      </a>
    </>}
  </td>
}

export default React.memo(PositionCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
