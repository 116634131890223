import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { CarrierNavBar } from "./CarrierNavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Assets/Carrier/PayHistory/view-model";
import { PayHistoryViewModel } from "TypeGen/Assets/Carrier/PayHistory/pay-history-view-model";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

type RouteComponentParams = {
  carrierId: string;
};

const CarrierPayHistory = () => {
  const { carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [rates, setRates] = useState<PayHistoryViewModel[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Asset/CarrierPayHistory/${carrierIdParam}`)
      .then((data: ViewModel) => {
        setLoading(false);
        setRates(data.Rates);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load carrier pay history.');
      });
  }, [carrierIdParam]);

  useEffect(() => {
    refresh();
  }, [carrierIdParam, refresh]);

  if (loading) return <div className="k-pos-absolute k-top-center mt-5">
    <Loader type="converging-spinner" />
  </div>;

  return (<>
    <CarrierNavBar id={carrierIdParam} currentTab="PayHistory" />
    <Title string="Carrier Pay History" />
    <br />
    {rates.length === 0
      ? <h4 className="text-center">No Carrier Pay History Data.</h4>
      : <Grid data={rates} scrollable="none">
        <Column field="YYYYMM" title="YYYY-MM" />
        <Column field="HistoryCount" title="Trip Count" />
        <Column field="TotalLinehaul" title="Paid Linehaul" format="{0:c2}" />
        <Column field="TotalAccessorials" title="Paid Accessorials" format="{0:c2}" />
        <Column field="TotalFuel" title="Paid Fuel" format="{0:c2}" />
        <Column field="TotalPay" title="Paid Total" format="{0:c2}" />
        <Column field="TotalMiles" title="Total Distance" format="{0:n0} mi" />
      </Grid>}
  </>);
}

export default CarrierPayHistory;