import { useEffect, useState } from "react";
import Moment from "moment-timezone";

const CurrentLocalTimeDisplay = (iana: string) => {
    const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return Moment(time).tz(iana).format("MM/DD/YYYY HH:mm:ss");
}

export default CurrentLocalTimeDisplay;