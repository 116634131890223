import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { fetchApi } from '../../services/api';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { isNullOrWhiteSpace } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import { CarrierContactType } from './CarrierContactProfile';
import { JsonResponse } from 'TypeGen/json-response';
import { CreatingField } from 'components/CreatingField';

type Props = {
    CarrierId: number;
    CloseDialog: () => void;
}

const AddCarrierContactDialog = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [contact, setContact] = useState<CarrierContactType>(null);

    const history = useHistory();

    const canSave = (isNullOrWhiteSpace(contact?.FirstName) === false && isNullOrWhiteSpace(contact?.LastName) === false);

    const _save = () => {
        if (canSave === false)
            return;

        setLoading(true);

        const data = {
            ...contact, CarrierID: props.CarrierId
        } as CarrierContactType


        fetchApi('/api/Asset/AddCarrierContact', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    var link = response.Links.find(l => l.Name === 'CarrierContactProfile');
                    if (link) history.push(link.Link);
                    props.CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch((e) => {
                setLoading(false);
                // If not problem details
                if (!e?.status) alert('Unable to create contact');
            });
    }

    return <Dialog title={'Create Carrier Contact'} onClose={props.CloseDialog} width={400} height={600}>
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <div className="k-form k-form-md p-0">
            <div>
                <FieldWrapper>
                    <Input
                        required
                        maxLength={20}
                        autoFocus
                        disabled={loading}
                        value={contact?.FirstName}
                        label="First Name"
                        onChange={(e) => setContact({ ...contact, FirstName: e.value })}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Input
                        required
                        maxLength={20}
                        disabled={loading}
                        value={contact?.LastName}
                        label="Last Name"
                        onChange={(e) => setContact({ ...contact, LastName: e.value })}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Input
                        type="email"
                        maxLength={80}
                        disabled={loading}
                        label="E-Mail"
                        value={contact?.EmailAddress}
                        onChange={(e) => setContact({ ...contact, EmailAddress: e.value })}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Input
                        maxLength={20}
                        disabled={loading}
                        value={contact?.Title}
                        label="Title"
                        onChange={(e) => setContact({ ...contact, Title: e.value })}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <div className="row">
                        <div className="col-8 pr-1">
                            <Label>Office Number</Label>
                            <CreatingField
                                dataType='intlphone'
                                data={contact?.PhoneNumber}
                                onChange={(e) => setContact({ ...contact, PhoneNumber: e })}
                            />
                        </div>
                        <div className="col-4 pl-1">
                            <Label>Extension</Label>
                            <Input
                                maxLength={4}
                                value={contact?.PhoneNumberExtension}
                                disabled={loading}
                                onChange={(e) => setContact({ ...contact, PhoneNumberExtension: e.value })}
                            />
                        </div>
                    </div>
                </FieldWrapper>
            </div>
        </div>
        <DialogActionsBar>
            <Button onClick={props.CloseDialog}>Cancel</Button>
            <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
                Create Contact
            </Button>
        </DialogActionsBar>
    </Dialog>
}

export default AddCarrierContactDialog;

