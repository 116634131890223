import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent  } from '@progress/kendo-react-inputs';
import { formatNumber } from '@progress/kendo-intl';
import { useCallback } from 'react';

const Quantity = (props: GridCellProps) => {
  const handleChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value || 0
      });
    }
  }

  const getFormat = useCallback(() => {
    switch(props.dataItem.QuantityUoM) {
      case 2:
        return 'N1';
      case 3:
        return 'C';
      default:
        return 'N2';
    }
  }, [props.dataItem.QuantityUoM])

  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  const format = getFormat();

  return (
    <td>
      {dataItem.inEdit ? <NumericTextBox
        value={dataValue}
        onChange={handleChange}
        format={format}
        spinners={false}
      /> : formatNumber(dataValue, format)}
    </td>
  );
}

export default Quantity;
