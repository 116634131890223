import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { Push } from "@progress/kendo-react-animation";
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Error } from '@progress/kendo-react-labels';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import React, { useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from "../../services/api";
import { IDName } from "../../types/idname";
import { isNullOrWhiteSpaceValidator } from '../../utils/utils';

type Props = {
    CloseDialog?: (newMessageCreated: boolean) => void;
    VehicleID?: number;
    QuoteReply?: string;
}

type GetFleetVehiclesResponse = {
    Vehicles: IDName[];
}

const messageMacros = [
    { ID: 0, Name: "Free Form Message" },
];

const messagePriorities = [
    { ID: 1, Name: "Normal" },
    { ID: 2, Name: "Important" },
    { ID: 3, Name: "Sleepy" },
    { ID: 4, Name: "Important Sleepy" },
    { ID: 5, Name: "Extended Sleepy" },
];


const SendSatMessageDialog = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [messageVehiclesFilter, setMessageVehiclesFilter] = useState<FilterDescriptor>();
    const [messageVehicleId, setMessageVehicleId] = useState<{ value: number, error: string }>({ value: -1, error: '' });
    const [vehicles, setVehicles] = useState<IDName[]>([]);
    const [messageMacro, setMessageMacro] = useState(0);
    const [messagePriority, setMessagePriority] = useState(1);
    const [message, setMessage] = useState<{ value: string, error: string }>({ value: '', error: '' });
    const [messageConfirmReceipt, setMessageConfirmReceipt] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const getUrl = () => {
        switch (messageMacro) {
            case 0:
                return '/api/Asset/SendFreeFormMessage';
        }
    }

    useEffect(() => {
        if (props.VehicleID) {
            setMessageVehicleId({ value: props.VehicleID, error: '' })
        }
    }, [props.VehicleID]);

    useEffect(() => {
        setLoading(true);
        fetchApi("/api/Asset/FleetMessages")
            .then((response: GetFleetVehiclesResponse) => {
                setLoading(false);
                setVehicles(response.Vehicles);
            });
    }, []);

    const send = () => {
        setSuccessMessage('');
        const messageVehicleIdError = messageVehicleId.value < 1 ? 'Please select a vehicle.' : '';
        const messageError = isNullOrWhiteSpaceValidator(message.value, 'Value cannot be empty.');
        if (messageVehicleIdError || messageError) {
            setMessageVehicleId({ ...messageVehicleId, error: messageVehicleIdError });
            setMessage({ ...message, error: messageError });
            return;
        }

        setLoading(true);
        const data = {
            VehicleID: messageVehicleId.value,
            Message: message.value,
            ConfirmReceipt: messageConfirmReceipt,
            Priority: messagePriority,
        };
        fetchApi(getUrl(), data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    // Reset Form
                    setMessageVehicleId({ value: 0, error: '' });
                    setMessageMacro(0);
                    setMessage({ value: '', error: '' });
                    setMessageConfirmReceipt(false);
                    setMessagePriority(1);

                    if (props.CloseDialog)
                        props.CloseDialog(true);
                    else
                        setSuccessMessage('Message was successfully sent');
                } else {
                    setMessage({ ...message, error: response.ErrorMessage });
                }
            })
            .catch(() => {
                setLoading(false);
                setMessage({ ...message, error: 'Error sending message.' });
            });
    }

    const priorityRender = (span: React.ReactElement<HTMLSpanElement>, value: IDName) => {
        const itemChildren = <>Priority: {value.Name}</>;
        return React.cloneElement(span, span.props, itemChildren);
    }

    const dataView = () => {
        if (loading) {
            return <LoadingPanel />;
        }

        return (<>
            <DropDownList
                data={filterBy(vehicles.filter(x => x.ID > 0), messageVehiclesFilter)}
                className="w-100"
                textField="Name"
                filterable
                valid={!messageVehicleId.error}
                defaultValue={{ ID: 0, Name: 'Select Vehicle' }}
                onFilterChange={(e) => setMessageVehiclesFilter(e.filter)}
                value={vehicles.find(x => x.ID === messageVehicleId.value)}
                onChange={(e) => setMessageVehicleId({ value: e.target.value.ID, error: '' })}
            />
            <Error>{messageVehicleId.error}</Error>
            <DropDownList
                data={messageMacros}
                className="mt-3 w-100"
                textField="Name"
                value={messageMacros.find(x => x.ID === messageMacro)}
                onChange={(e) => setMessageMacro(e.target.value.ID)}
            />
            <DropDownList
                data={messagePriorities}
                className="mt-3 w-100"
                textField="Name"
                valueRender={priorityRender}
                value={messagePriorities.find(x => x.ID === messagePriority)}
                onChange={(e) => setMessagePriority(e.target.value.ID)}
            />
            {props.QuoteReply && <blockquote className="blockquote mt-3">{props.QuoteReply}</blockquote>}
            <TextArea
                autoFocus
                className="mt-3 w-100"
                rows={8}
                valid={!message.error}
                value={message.value}
                onChange={(e) => setMessage({ value: e.value, error: '' })}
                placeholder="Type Message..."
            />
            <Error>{message.error}</Error>
            <Checkbox
                label="Confirm Receipt?"
                checked={messageConfirmReceipt}
                onChange={(e) => setMessageConfirmReceipt(e.value)}
            />
        </>);
    }

    if (props.CloseDialog) {
        return <Dialog
            title={props.QuoteReply ? 'Reply to Message' : 'Send Message'}
            onClose={() => {
                props.CloseDialog(false);
            }}
            className="dialog-w800"
        >
            {dataView()}
            <DialogActionsBar>
                <Button onClick={() => props.CloseDialog(false)}>Cancel</Button>
                <Button themeColor="primary" onClick={send}>Send Message</Button>
            </DialogActionsBar>
        </Dialog>
    }

    return <div>
        <Push style={{ zIndex: 0, width: '100%' }}>
            {successMessage.length > 0 && <NotificationGroup style={{ position: "relative", alignItems: "normal", zIndex: 0, width: '100%' }}>
                <Notification style={{ padding: "8px", width: '100%' }} type={{ style: 'success', icon: true }}>
                    <div>{successMessage}</div>
                </Notification>
            </NotificationGroup>}
        </Push>
        {dataView()}
        <div className="mt-3">
            <Button themeColor="primary" onClick={send}>Send Message</Button>
        </div>
    </div>
}

export default SendSatMessageDialog;