import { useCallback, useContext, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Order/Schedule/view-model";
import { OrderContext } from "./Details";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import SimpleDatePicker from "components/SimpleDatePicker";
import { Checkbox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { OccursInterval } from "TypeGen/Order/Schedule/occurs-interval";
import { JsonResponse } from "TypeGen/json-response";

type RouteComponentParams = {
  orderId: string;
}

const occurs = [
  { text: 'Daily', value: OccursInterval.Daily },
  { text: 'Weekly', value: OccursInterval.Weekly },
  { text: 'Monthly', value: OccursInterval.Monthly },
  { text: 'Bi-Weekly', value: OccursInterval.BiWeekly }
];

const Schedule = () => {
    const { orderId: orderIdParam } = useParams<RouteComponentParams>();
    const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
    const [loading, setLoading] = useState(true);
    const [schedule, setSchedule] = useState<ViewModel>();

    const refreshSchedule = useCallback(() => {
      setLoading(true);
      fetchApi(`/api/Order/Schedule/${orderIdParam}`)
        .then((data: ViewModel) => {
          setLoading(false);
          setSchedule(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
      }, [orderIdParam]);

    useEffect(() => {
      setActiveStopIds([]);
      setDeleteStopIds([]);
      refreshSchedule();
    }, [refreshSchedule, setActiveStopIds, setDeleteStopIds]);

    const save = () => {
      setLoading(true);
      
      const data = {
        EffectiveFromDate: schedule.EffectiveFromDate,
        EffectiveToDate: schedule.EffectiveToDate,
        Occurs: schedule.Occurs,
        ScheduleDaily: schedule.ScheduleDaily,
        ScheduleWeekly: schedule.ScheduleWeekly,
        ScheduleMonthly: schedule.ScheduleMonthly,
        ScheduleBiWeekly: schedule.ScheduleBiWeekly,
        LeadDays: schedule.LeadDays,
        Note: schedule.Note
      };

      const command = schedule.Links.find(l => l.Name === 'Save');
      fetchApi(command.Link, data, command.Method)
        .then((response: JsonResponse) => {
          if (response.Success) {
            refreshSchedule();
          } else {
            alert(response.ErrorMessage);
          }
        })
        .catch(e => {
          // If not problem details
          if (!e?.status) alert('Unable to save schedule');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const updateSchedule = (updateValue: Partial<ViewModel>) => {
      setSchedule({ ...schedule, ...updateValue });
    }

    const renderOccurs = (occurs: OccursInterval) => {
      switch (occurs) {
        case OccursInterval.Weekly:
          return moment.weekdays().map((dayOfWeekName, dayOfWeek) => {
            const checked = schedule.ScheduleWeekly[dayOfWeek] !== "0";
            return <Checkbox
              className="mr-2"
              key={dayOfWeek}
              label={dayOfWeekName}
              checked={checked}
              onChange={(e) => {
                const scheduleWeekly = [...schedule.ScheduleWeekly];
                scheduleWeekly[dayOfWeek] = e.value ? "1" : "0";
                updateSchedule({ ScheduleWeekly: scheduleWeekly.join("") });
              }} />;
          });
        default:
          throw new Error(`Not implemented: ${occurs}`);
      }
    }

    if (loading || !schedule) {
      return <div className="d-flex justify-content-center mt-5">
        <Loader type="converging-spinner" />
      </div>;
    }

    return (<div className='py-2'>
      <Title string="Schedule" />
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Effective From</label>
        </div>
        <div className="form-group col-md-5">
          <SimpleDatePicker
            inputProps={{ placeholder: "From Date" }}
            value={moment.utc(schedule.EffectiveFromDate).toDate()}
            onChange={(e) => updateSchedule({ EffectiveFromDate: e.toISOString() })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Effective To</label>
        </div>
        <div className="form-group col-md-5">
          <SimpleDatePicker
            inputProps={{ placeholder: "To Date" }}
            value={moment.utc(schedule.EffectiveToDate).toDate()}
            onChange={(e) => updateSchedule({ EffectiveToDate: e.toISOString() })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Build Lead Days</label>
        </div>
        <div className="form-group col-md-5">
          <NumericTextBox
            min={1}
            max={31}
            width={145}
            value={schedule.LeadDays}
            onChange={(e) => updateSchedule({ LeadDays: e.value })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Occurs</label>
        </div>
        <div className="form-group col-md-5">
          <DropDownList
            disabled
            value={occurs.find(o => o.value === schedule.Occurs)}
            textField="text"
            data={occurs}
            onChange={(e) => updateSchedule({ Occurs: e.value.value })}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-10 offset-md-2">
          {renderOccurs(schedule.Occurs)}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Note</label>
        </div>
        <div className="form-group col-md-5">
          <TextArea
            value={schedule.Note}
            onChange={(e) => updateSchedule({ Note: e.value })}
          />
        </div>
      </div>
      <div className="text-center mt-3">
        {schedule.Links.find(l => l.Name === 'Save') && <Button themeColor="primary" onClick={save} disabled={loading}>Save Schedule</Button>}
      </div>
    </div>);
}

export default Schedule;
