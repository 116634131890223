import { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchApi } from '../../services/api';
import { Label } from '@progress/kendo-react-labels';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { JsonResponse } from "TypeGen/json-response";

type Props = {
  vehicleIds: number[];
  close: () => void;
  refresh: () => void;
}

const HideFromDispatchDialog = ({ vehicleIds, close, refresh }: Props) => {

  const [saving, setSaving] = useState(false);
  const [reason, setReason] = useState('');

  const save = () => {
    setSaving(true);
    const payload = {
      VehicleIDs: vehicleIds,
      HideFromDispatch: true,
      Reason: reason,
    }

    fetchApi('/api/Asset/HideFromDispatch', payload, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaving(false);
          refresh && refresh();
          close();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setSaving(false);
        // If not problem details
        if (!e?.status) alert('Unable to hide vehicle(s) from dispatch!');
      });
  }

  return <Dialog
      title={`Hide Vehicle${vehicleIds.length > 1 ? 's' : ''} From Dispatch`}
      onClose={close}
    >
      <form className="k-form k-form-md">
        <Label>Reason for hiding from dispatch</Label>
        <Input
          required
          autoFocus
          value={reason}
          maxLength={60}
          onChange={(e) => setReason(e.value)}
        />
      </form>
      <DialogActionsBar layout="end">
        <Button disabled={saving} className="ml-auto" onClick={close}>
          Cancel
        </Button>
        <Button themeColor="error" disabled={saving} onClick={save}>
          Hide from Dispatch
        </Button>
      </DialogActionsBar>
    </Dialog>
}

export default HideFromDispatchDialog;