import * as React from 'react';
import { Title } from '../../utils/title';
import { fetchApi } from '../../services/api';
import Moment from 'moment';
import { debounce } from 'ts-debounce';
import LoadingPanel from '../../components/LoadingPanel';
import EnterStop from './EnterStop';
import { TransitValue, TransitLocationValue } from './StopTransitData';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import EnterRoutingRate from './EnterRoutingRate';
import MapPanel, { MapReport, MapReportLines } from './Map';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import RecentPanel from '../Quote/Recent';
import { openWindow } from '../../services/openWindow';
import { QuoteLineItemValue, QuotePanelStateValue, QuoteValue } from './QuotePanel';
import { ChargeType, SmartbidValue, WinPercentageChartValue } from '../Quote/Quote';
import CustomerInput from '../../components/CustomerInput';
import { ILink } from '../../types/link';
import { QuoteStopType } from 'TypeGen/Quote/quote-stop-type';
import { QuoteStopVerb } from 'TypeGen/Quote/quote-stop-verb';
import { FrontReply } from 'views/Quote/FrontReply';
import { BlindBidQuoteDetailRow } from './BlindBidQuoteDetailRow';
import { bankersRounding } from 'utils/bankersRounding';
import { accessorialsFullDriverPay, accessorialsNoDriverPay, DuplicateQuote } from 'views/Quote';
import DateCell from 'components/cells/DateCell';
import TheadIDCell from 'views/Quote/Recent/ThreadIDCell';
import { noop } from 'lodash';

export type EnterStopViewModel = {
  QuoteStopID?: number;
  Sequence: number;
  Location: string | any;
  StopType: QuoteStopType;
  CustomerID: number;
  CustomerNumber: string;
  StopVerb: QuoteStopVerb;
  DateTime: string;
}

export type EnterRoutingRateViewModel = {
  VehicleTypeID: number;
  PerMile: number;
  Flat: number;
}

export type CustomerAutocomplete = {
  CustomerID: number;
  CustomerNumber: string;
  CustomerName: string;
  AddressLine1: string;
  CityName: string;
  State: string;
  ZipCode: string;
  OnCreditHold: boolean;
}

export type State = {
  isLoading: boolean;
  ShowFrontReply: boolean;
  Stops: EnterStopViewModel[];

  Locations: EnterStopViewModel[];
  TransitData: TransitValue | null;
  TransitDataIsLoading: boolean;
  PendingChangeTypes: string[];
  DeadheadMiles: number;
  OverrideDistance: number;
  RemainingHoursofService: Date;

  Flat: number;
  PerMile: number;
  VehicleTypeID: number;

  RoutingTypeID: number;
  RoutingOptimizationTypeID: number;
  BorderOptionTypeID: number;
  Borders: boolean;

  BillingSearchOptions: any[];
  BillingSearchIsLoading: boolean;
  BillingCustomerID: number,
  BillingCustomerNumber: string,
  //RoutingClassOverrideTypeID: number;

  UseFlatRate: boolean;

  ValidationRequestHash: string;
  ValidationRequestHashTransit: string;
  ValidationRequestHashRate: string;
  ValidationRequestHashFuelRate: string;

  mapMileageReportLines: MapReportLines[];
  mapDrawRoute: boolean;
  mapClearRoute: boolean;

  data: null | RatingCalculationHeader;
  //QuoteData: QuoteValue | null;
  QuoteDataIsLoading: boolean;
  IsQuoteSaving: boolean;
  DuplicateQuotes: DuplicateQuote[];
  SmartbidDataUsed: boolean;
  SmartbidRateUpDown: number | null;
  SmartbidWinPercentagePrevious: number | null;
  SmartbidWinPercentageCurrent: number | null;
  ThreadID: string;
  RouteName: string;
  QuoteID: number;

  fuelRate: number;
  QuotePanelStateValue: QuotePanelStateValue;

  Links: ILink[];
}

type Props = {};

export type RatingCalculationHeader = {
  Index: number
  Locations: EnterStopViewModel[];
  MPH: number;
  SingleETA: Date;
  TeamETA: Date;
  TotalMiles: number;
  TotalTolls: number;
  LastTransitLocation: TransitLocationValue;
  MapReportLines: MapReportLines[];
  TransitValue: TransitValue;
  BillingCustomerNumber: string;
  BillingCustomerID: number;
}

export default class BlindBidQuote extends React.Component<Props, State> {

  private recentPanel: React.RefObject<RecentPanel>;

  private routingOptionTypes = [
    { value: 0, text: 'Practical' },
    { value: 1, text: 'Shortest' },
    { value: 2, text: 'Fastest' },
    { value: 4, text: '53\'' },
    { value: 5, text: 'National' },
    { value: 6, text: '53\' + National' },
  ];

  private routingOptimizationTypes = [
    { value: 0, text: 'Stop Optimization: None' },
    { value: 1, text: 'Optimize All Stops' },
    { value: 2, text: 'Optimize Intermediate Stops' },
  ];

  private borderOptionTypes = [
    { value: 0, text: 'Borders Closed' },
    { value: 1, text: 'Borders Open' }
  ];

  constructor(props: Props) {
    super(props);

    this.recentPanel = React.createRef();

    this.addStop = this.addStop.bind(this);
    this.removeStop = this.removeStop.bind(this);
    this.handleRoutingOptionTypeChange = this.handleRoutingOptionTypeChange.bind(this);
    this.handleRoutingOptimizationTypeChange = this.handleRoutingOptimizationTypeChange.bind(this);
    this.handleBorderOptionTypeChange = this.handleBorderOptionTypeChange.bind(this);
    this.updateQuote = this.updateQuote.bind(this);
    this.createQuote = this.createQuote.bind(this);
    this.openCustomer = this.openCustomer.bind(this);
    this.clickQuote = this.clickQuote.bind(this);
    this.fetchAll = this.fetchAll.bind(this);
    this.setAllInRate = this.setAllInRate.bind(this);
    this.fetchWinPercentage = this.fetchWinPercentage.bind(this);
    this.fetchWinPercentageChart = this.fetchWinPercentageChart.bind(this);

    this.state = this.emptyState();
  }

  private emptyState(): State {
    if (this.recentPanel.current)
      this.recentPanel.current.clearState();
    return {
      Locations: [],
      Stops: [{
        Sequence: 1,
        StopType: QuoteStopType.Pickup,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(2, "hours").format("MM/DD/YYYY HH:mm"),
      }, {
        Sequence: 2,
        StopType: QuoteStopType.Delivery,
        StopVerb: QuoteStopVerb.Protect,
        CustomerID: 0,
        CustomerNumber: '',
        Location: '',
        DateTime: Moment().add(4, "hours").format("MM/DD/YYYY HH:mm"),
      }],
      isLoading: false,
      ShowFrontReply: false,
      TransitData: null,
      TransitDataIsLoading: false,
      Flat: 0,
      PerMile: 0,
      VehicleTypeID: 1,
      PendingChangeTypes: [],
      DeadheadMiles: 0,
      OverrideDistance: 0,
      RemainingHoursofService: new Date(0, 0, 0, 11, 0, 0),
      RoutingTypeID: 0,
      RoutingOptimizationTypeID: 0,
      BorderOptionTypeID: 0,
      //RoutingClassOverrideTypeID: 0,
      Borders: false,
      UseFlatRate: false,
      data: null,

      Links: [],

      BillingSearchIsLoading: false,
      BillingSearchOptions: [],
      BillingCustomerID: 0,
      BillingCustomerNumber: '',
      ValidationRequestHash: '',
      ValidationRequestHashTransit: '',
      ValidationRequestHashRate: '',
      ValidationRequestHashFuelRate: '',
      mapMileageReportLines: null,
      mapDrawRoute: false,
      mapClearRoute: true,
      QuoteDataIsLoading: false,
      IsQuoteSaving: false,
      DuplicateQuotes: [],
      SmartbidDataUsed: false,
      SmartbidRateUpDown: null,
      SmartbidWinPercentagePrevious: null,
      SmartbidWinPercentageCurrent: null,
      ThreadID: '',
      RouteName: '',
      QuoteID: 0,
      QuotePanelStateValue: null,
      fuelRate: 0
    };
  };

  public componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const frontIdParam = urlParams.get('frontId');
    if (frontIdParam != null) {
      this.setState({ ThreadID: frontIdParam });

      fetchApi(`/api/BlindBidQuote/BillToFromFront/${frontIdParam}`)
        .then((data: { BillingCustomerID: number, BillingCustomerNumber: string }) => {
          if (data.BillingCustomerID > 0) {
            this.setState({
              BillingCustomerID: data.BillingCustomerID,
              BillingCustomerNumber: data.BillingCustomerNumber
            });
          }
        })
        .catch(() => { });
    }
  }

  public render() {
    return <React.Fragment>
      <Title string={this.state.BillingCustomerID > 0 ? this.state.BillingCustomerNumber : 'Blind Bid Quote'} />
      {this.state.isLoading && <LoadingPanel />}
      {this.state.ShowFrontReply && <FrontReply
        QuoteID={this.state.QuoteID}
        ThreadID={this.state.ThreadID}
        Refresh={() => this.setState({ ShowFrontReply: false })}
        CloseDialog={() => this.setState({ ShowFrontReply: false })}
      />}
      {this.state.DuplicateQuotes.length > 0 && <Window title="Possible Duplicates" onClose={() => this.setState({ DuplicateQuotes: [] })} width={800}>
          <Grid
            data={this.state.DuplicateQuotes}
            scrollable='none'
            onRowClick={(e) => window.location.href = `/Quote/Index/${e.dataItem.QuoteID}`}
          >
            <Column field="QuoteID" title="Quote #" cell={(props) => <td style={{ color: '#007bff' }} role="button">{props.dataItem.QuoteID}</td>} />
            <Column field="ThreadID" title="Front" cell={TheadIDCell} />
            <Column field="VehicleTypeName" title="Vehicle Type" />
            <Column field="TotalCharges" title="Total Charges" format='{0:c}' />
            <Column field="CreatedDateTime" title="Created Date" cell={DateCell} />
            <Column field="CreatedByUserName" title="Created By" />
            <Column field="SourceName" title="Source" />
          </Grid>
          <WindowActionsBar>
            <Button onClick={() => this.setState({ DuplicateQuotes: [] })}>Close</Button>
            <Button themeColor='warning' disabled={this.state.IsQuoteSaving} onClick={noop}>
              Create Quote Anyway
            </Button>
          </WindowActionsBar>
        </Window>}
      <br />
      {<div className="form-row">
        <div className="form-group col-3">
          <div className="input-group">
            <CustomerInput
              focus
              onCreditHold={false}
              placeholder='Billing Company'
              CustomerID={this.state.BillingCustomerID}
              CustomerNumber={this.state.BillingCustomerNumber}
              onChange={(e) => {
                this.updateQuote({ BillingCustomerID: e.CustomerID, BillingCustomerNumber: e.CustomerNumber }, "CUSTOMER");
              }}
            />
          </div>
        </div>
        <div className="col-auto">
          <input
            type="text"
            maxLength={15}
            size={15}
            className="form-control"
            placeholder="Front ID"
            value={this.state.ThreadID}
            onFocus={(e) => e.target.select()}
            onChange={(e) => this.updateQuote({ ThreadID: e.target.value }, "THREAD")}
          />
        </div>
        <div className="col-auto">
          <input
            type="text"
            className="form-control"
            placeholder="Route Name/Info"
            maxLength={50}
            size={50}
            value={this.state.RouteName}
            onChange={(e) => this.updateQuote({ RouteName: e.target.value }, "ROUTENAME")}
          />
        </div>
        <div className="col-auto">
          <Button className="btn btn-secondary" themeColor="primary" onClick={() => { this.setState({ ...this.emptyState() }); }}>Reset Calculation</Button>
        </div>
      </div>}
      <div className="form-row">
        <div className="col-md-9">
          {this.state.Stops.map((stop, index) => {
            return <React.Fragment key={index}>
              <EnterStop
                stop={stop}
                index={index}
                timezone={this.state.Locations[index] ? this.state.Locations[index].Location.TimeZone : ""}
                onChange={(data, changeType) => this.updateStop(data, changeType, index)}
              />
            </React.Fragment>
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <ButtonGroup>
            <Button themeColor="primary" onClick={this.addStop}>Add Stop</Button>
            <Button disabled={this.state.Stops.length <= 2} onClick={this.removeStop}>Remove</Button>
          </ButtonGroup>
        </div>
      </div>
      <br />
      <div className="form-row">
        <div className="col-md-12">
            <EnterRoutingRate
              data={{ VehicleTypeID: this.state.VehicleTypeID, PerMile: this.state.PerMile, Flat: this.state.Flat }}
              onChange={(data) => {
                this.setState(prevState => ({
                  VehicleTypeID: data.VehicleTypeID,
                  PerMile: data.PerMile,
                  Flat: data.Flat,
                  SmartbidDataUsed: false,
                  PendingChangeTypes: [...prevState.PendingChangeTypes, prevState.VehicleTypeID !== data.VehicleTypeID ? "VEHICLETYPE" : "RATE"]
                }), () => {
                  this.setAllInRate();
                  this.updateHeaderGridDebounced();
                  this.updatePanelsDebounced();
                });
              }}
              disabledInputs={this.state.TransitData == null || this.state.QuotePanelStateValue == null}
            />
        </div>
      </div>
      <div className="form-row mt-4">
        <div className="col-md-7">
          {this.state.TransitDataIsLoading && <LoadingPanel />}
          {this.state.data && <BlindBidQuoteDetailRow
            quoteId={this.state.QuoteID}
            dataItem={this.state.data}
            createQuote={this.createQuote}
            quotePanelStateValue={this.state.QuotePanelStateValue}
            getSmartbidData={this.fetchWinPercentage}
            getWinPercentageData={this.fetchWinPercentageChart}
            useSmartbidRate={(rate: number, rateUpDown: number, currrentPercentage: number, newPercentage: number) => {
              var linehaul = bankersRounding(rate * this.state.TransitData.Distance);
              var fuel = linehaul * this.state.fuelRate;
              // Reuse Fuel Surcharge Per Mile if it exists
              const existingFuels = this.state.QuotePanelStateValue.quoteData.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
              if (existingFuels.length !== 0) {
                fuel = existingFuels[0].Amount;
              } else if (this.state.fuelRate < 0.01) {
                alert("Please fix fuel rate!");
                return;
              }
              const accessorials = this.state.QuotePanelStateValue.quoteData.LineItems
                .filter(x => x.RateType === 65)
                .reduce((a, b) => a + b.Amount, 0);
              this.setState({
                PerMile: 0,
                Flat: linehaul + fuel + accessorials,
                SmartbidDataUsed: true,
                SmartbidRateUpDown: rateUpDown,
                SmartbidWinPercentagePrevious: currrentPercentage,
                SmartbidWinPercentageCurrent: newPercentage
              }, () => {
                this.setAllInRate();
              });
            }}
          />}
        </div>
        <div className="col-md-5">
          <div className="form-row">
            <div className="col-md-4">
              <DropDownList
                defaultValue={-1}
                value={this.routingOptimizationTypes.find(x => x.value == this.state.RoutingOptimizationTypeID)}
                popupSettings={{ animate: false }}
                data={this.routingOptimizationTypes}
                textField="text"
                disabled={this.state.TransitDataIsLoading}
                onChange={this.handleRoutingOptimizationTypeChange}
              />
            </div>
            <div className="col-md-4">
              <DropDownList
                defaultValue={-1}
                value={this.routingOptionTypes.find(x => x.value == this.state.RoutingTypeID)}
                popupSettings={{ animate: false }}
                data={this.routingOptionTypes}
                textField="text"
                disabled={this.state.TransitDataIsLoading}
                onChange={this.handleRoutingOptionTypeChange}
              />
            </div>
            <div className="col-md-4">
              <DropDownList
                defaultValue={-1}
                value={this.borderOptionTypes.find(x => x.value == this.state.BorderOptionTypeID)}
                popupSettings={{ animate: false }}
                data={this.borderOptionTypes}
                textField="text"
                disabled={this.state.TransitDataIsLoading}
                onChange={this.handleBorderOptionTypeChange}
              />
            </div>
          </div>
          <br />
          <div className="form-row">
            <div className="col-md-12">
              <MapPanel
                clearRoute={this.state.mapClearRoute}
                drawRoute={this.state.mapDrawRoute}
                locations={this.state.Locations.map(x => x.Location)}
                bordersOpen={this.state.BorderOptionTypeID != 0}
                routeOptimization={this.state.RoutingOptimizationTypeID}
                routingType={this.state.RoutingTypeID <= TrimbleMaps.Common.RouteType.FASTEST ? this.state.RoutingTypeID : TrimbleMaps.Common.RouteType.PRACTICAL}
                classOverride={this.state.RoutingTypeID > TrimbleMaps.Common.RouteType.FASTEST ? this.state.RoutingTypeID - 3 : TrimbleMaps.Common.ClassOverride.NONE}
                onReport={(report) => this.handleMapReport(report)}
              />
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="form-row">
        <div className="col-md-12 p-1">
          <RecentPanel
            blindBid
            ref={this.recentPanel}
            quoteID={this.state.QuoteID}
            billingCustomerID={this.state.BillingCustomerID}
            locations={this.state.Locations}
          />
        </div>
      </div>
    </React.Fragment>
  }

  private getRecentQuotes() {
    if (this.recentPanel.current) {
      this.recentPanel.current.getRecentQuotes();
    }
  }

  private fetchWinPercentage(linehaul: number): Promise<SmartbidValue | null> {
    if (this.state.VehicleTypeID === 0) {
      return Promise.resolve(null);
    } else if (this.state.TransitData === null || this.state.TransitData.Distance === 0) {
      return Promise.resolve(null);
    }
    const existingFuels = this.state.QuotePanelStateValue.quoteData.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
    const data = {
      BillingCustomerID: this.state.BillingCustomerID,
      VehicleTypeID: this.state.VehicleTypeID,
      DateTime: this.state.Locations[0].DateTime,
      FromZip: this.state.Locations[0].Location.Zip,
      ToZip: this.state.Locations[this.state.Locations.length - 1].Location.Zip,
      Distance: this.state.TransitData.Distance,
      TotalWeight: 0,
      TotalPieces: 0,
      RatePerMile: linehaul / this.state.TransitData.Distance,
      FlatFuelSurcharge: existingFuels.length > 0 ? existingFuels[0].Amount : null,
      FuelRate: this.state.fuelRate
    }
    return fetchApi("/api/Quote/SmartbidRate", { Quote: data }, "POST")
      .then((data: SmartbidValue) => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.resolve(null);
      });
  }

  private fetchWinPercentageChart(linehaul: number) {
    if (this.state.VehicleTypeID === 0) {
      alert("Please enter a rate first!");
      return Promise.resolve(null);
    } else if (this.state.TransitData === null || this.state.TransitData.Distance === 0) {
      alert("No distance available to calculate win percentage!");
      return Promise.resolve(null);
    }
    const existingFuels = this.state.QuotePanelStateValue.quoteData.LineItems.filter(x => x.RateDescriptionID === 5000 && x.Rate > 0);
    const data = {
      BillingCustomerID: this.state.BillingCustomerID,
      VehicleTypeID: this.state.VehicleTypeID,
      DateTime: this.state.Locations[0].DateTime,
      FromZip: this.state.Locations[0].Location.Zip,
      ToZip: this.state.Locations[this.state.Locations.length - 1].Location.Zip,
      Distance: this.state.TransitData.Distance,
      TotalWeight: 0,
      TotalPieces: 0,
      RatePerMile: linehaul / this.state.TransitData.Distance,
      FlatFuelSurcharge: existingFuels.length > 0 ? existingFuels[0].Amount : null,
      FuelRate: this.state.fuelRate
    }
    return fetchApi('/api/Quote/WinPercentageChart', { Quote: data }, 'POST')
      .then((data: WinPercentageChartValue) => {
        return Promise.resolve(data);
      })
      .catch(() => {
        alert("Error loading Win Percentage Chart");
        return Promise.resolve(null);
      });
  }

  private createQuote(lineItems: QuoteLineItemValue[], rateComment: string, tariff: boolean) {

    if (this.state.IsQuoteSaving) {
      return;
    }

    let alertMessage: string = "";
    if (this.state.BillingCustomerID === null) {
      alertMessage += "Please select a bill-to\n";
    }

    if (lineItems.length == 0) {
      alertMessage += "Please enter a per mile or flat rate for vehicle size\n";
    }

    if (alertMessage.length > 0) {
      alert(alertMessage);
      return;
    }

    this.setState({ isLoading: true, IsQuoteSaving: true });

    const totalCharges = lineItems.reduce((a, b) => a + b.Amount, 0);

    const data = {
      ThreadID: this.state.ThreadID,
      RouteName: this.state.RouteName,
      BlindBid: true,
      Source: 1,
      BillingCustomerID: this.state.BillingCustomerID,
      VehicleTypeID: this.state.VehicleTypeID,
      Stops: this.state.data.MapReportLines.map((x, index) => {
        return {
          StopType: index == 0 ? QuoteStopType.Pickup : QuoteStopType.Delivery,
          StopVerb: QuoteStopVerb.Protect,
          Location: {
            City: x.Stop.Address.City,
            State: x.Stop.Address.State,
            Zip: x.Stop.Address.Zip,
            Coordinates: {
              Latitude: x.Stop.Coords.Lat,
              Longitude: x.Stop.Coords.Lon
            }
          },
          DateTime: Moment(this.state.Stops[index].DateTime).format("YYYY-MM-DD[T]HH:mm:ss")
        }
      }),
      LineItems: lineItems,
      Total: totalCharges,
      RateComment: rateComment,
      SmartbidUsed: tariff ? false : this.state.SmartbidDataUsed,
      SmartbidRateUpDown: this.state.SmartbidRateUpDown,
      SmartbidWinPercentagePrevious: this.state.SmartbidWinPercentagePrevious,
      SmartbidWinPercentageCurrent: this.state.SmartbidWinPercentageCurrent,
      Freight: this.state.data.MapReportLines.map((x, index) => {
        return {
          PickupStopSequence: index,
          DeliveryStopSequence: index + 1,
          Weight: 0,
          WeightUnitOfMeasure: 0,
          Pieces: 0,
          Length: 0,
          Width: 0,
          Height: 0,
          DimsUnitOfMeasure: 0,
          Stackable: false,
          Rotatable: false
        }
      }).filter(x => x.PickupStopSequence > 0),
      Force: true // Don't check for duplicate quotes
    };

    fetchApi('/api/Quote/Save', data, 'POST')
      .then((response: {
        QuoteID: number,
        QuoteStopIDs: number[],
        QuoteFreightIDs: number[],
        CreatedByUserName: string,
        DuplicateQuotes: DuplicateQuote[]
      }) => {
        if (response.DuplicateQuotes.length > 0) {
          this.setState({ IsQuoteSaving: false, DuplicateQuotes: response.DuplicateQuotes });
          return;
        }
        this.setState({
          isLoading: false,
          IsQuoteSaving: false,
          QuoteID: response.QuoteID
        }, () => {
          this.getRecentQuotes();
          this.setState({ ShowFrontReply: true });
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false, IsQuoteSaving: false });
        // If not problem details
        if (!e?.status) alert("Unable to create quote!")
      });
  }

  private addStop() {
    let stops = this.state.Stops;
    stops.push({
      Sequence: stops.length + 1,
      StopType: QuoteStopType.Delivery,
      StopVerb: QuoteStopVerb.Protect,
      CustomerID: 0,
      CustomerNumber: '',
      Location: '',
      DateTime: Moment().add(1, "days").format("MM/DD/YYYY HH:mm"),
    });
    this.setState(prevState => ({
      Stops: stops,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "ADD_STOP"]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private removeStop() {
    let stops = this.state.Stops;
    stops.pop()
    this.setState(prevState => ({
      Stops: stops,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "REMOVE_STOP"]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private updateQuote(data: any, changeType: string) {
    this.setState(prevState => ({
      ...data,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType],
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private handleMapReport(MapMileage: MapReport) {
    this.setState({
      mapDrawRoute: false,
      mapMileageReportLines: MapMileage.ReportLines
    }, () => {
      this.fetchTransitDebounced();
    });
  }

  private updateHeaderGridDebounced = debounce(this.updateHeaderGrid, 500);
  private updateHeaderGrid() {
    if (this.state.TransitData != null) {
      let lastTransitValueLocation = this.state.TransitData.Locations[this.state.TransitData.Locations.length - 1];
      var data = {
        Locations: this.state.Locations.map(x => x),
        MPH: this.state.TransitData.MPH,
        LastTransitLocation: lastTransitValueLocation,
        SingleETA: lastTransitValueLocation.SuggestedDateTime,
        TeamETA: lastTransitValueLocation.SuggestedDateTimeTeam,
        MapReportLines: this.state.mapMileageReportLines,
        TransitValue: this.state.TransitData,
        TotalTolls: this.state.TransitData.Tolls,
        BillingCustomerNumber: this.state.BillingCustomerNumber,
        BillingCustomerID: this.state.BillingCustomerID,
        TotalMiles: Math.round(this.state.TransitData.Distance)  // Could total MapReportLines.LMiles
      } as RatingCalculationHeader;
      this.setState({ data: data }, () => { });
    }
  }

  private updateStop(data: EnterStopViewModel, changeType: string, index: number) {
    const stops = this.state.Stops;
    const locations = this.state.Locations;
    stops[index] = data;
    // These are normally updated by Validate
    if ((changeType === "FIRST_STOP_DATETIME" || changeType === "STOP_DATETIME") && locations[index]) {
      locations[index].DateTime = data.DateTime ? Moment(data.DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined;
    }
    this.setState(prevState => ({
      Stops: stops,
      Locations: locations,
      PendingChangeTypes: [...prevState.PendingChangeTypes, changeType]
    }), () => {
      this.updatePanelsDebounced();
    });
  }

  private handleBorderOptionTypeChange(e: any) {
    const borderOptionTypeID = e.target.value.value;
    this.setState(prevState => ({
      BorderOptionTypeID: borderOptionTypeID,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "BORDEROPTIONTYPE"]
    }), () => { this.updatePanelsDebounced() });
  }

  private handleRoutingOptionTypeChange(e: any) {
    const routingOptionTypeId = e.target.value.value;
    this.setState(prevState => ({
      RoutingTypeID: routingOptionTypeId,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "ROUTINGOPTIONTYPE"]
    }), () => this.updatePanelsDebounced());
  }

  private handleRoutingOptimizationTypeChange(e: any) {
    const routingOptimizationTypeId = e.target.value.value;
    this.setState(prevState => ({
      RoutingOptimizationTypeID: routingOptimizationTypeId,
      PendingChangeTypes: [...prevState.PendingChangeTypes, "ROUTINGOPTIMIZATIONTYPE"]
    }), () => this.updatePanelsDebounced());
  }

  private openCustomer() {
    openWindow(`/Customers/Customer/${this.state.BillingCustomerID}`);
  }

  private updatePanelsDebounced = debounce(this.updatePanels, 400);
  private fetchTransitDebounced = debounce(this.fetchTransit, 400);

  private updatePanels() {
    const data = {
      Stops: this.state.Stops,
      BillingCustomerNumber: this.state.BillingCustomerNumber
    }

    // What needs updating?
    const updatePanels = this.requireUpdate(this.state.PendingChangeTypes);
    if (updatePanels.length === 0) return;

    const validationHash = Math.random().toString(36).substring(7);
    const validationHashTransit = updatePanels.find(x => x === "TRANSIT") ? validationHash : this.state.ValidationRequestHashTransit;
    const validationHashFuelRate = updatePanels.find(x => x === "FUELRATE") ? validationHash : this.state.ValidationRequestHashFuelRate;

    this.setState({
      ValidationRequestHash: validationHash,
      ValidationRequestHashTransit: validationHashTransit,
      ValidationRequestHashFuelRate: validationHashFuelRate,
      PendingChangeTypes: []
    });

    fetchApi("/api/BlindBidQuote/Validate", data, 'POST')
      .then((response: { Stops: Array<{ Location: any }>, Links: ILink[], BillingCustomerID: number }) => {
        if (this.state.ValidationRequestHash !== validationHash) {
          return;
        }

        this.setState({
          mapClearRoute: false,
          mapDrawRoute: response.Stops.length > 1,
          TransitDataIsLoading: response.Stops.length > 1,
          Links: response.Links,
          BillingCustomerID: response.BillingCustomerID,
          Locations: response.Stops.map((x, index) => {
            return {
              Sequence: this.state.Stops[index].Sequence,
              StopType: this.state.Stops[index].StopType,
              StopVerb: this.state.Stops[index].StopVerb,
              CustomerID: 0,
              CustomerNumber: '',
              Location: x.Location,
              DateTime: this.state.Stops[index].DateTime ? Moment(this.state.Stops[index].DateTime).format("YYYY-MM-DD[T]HH:mm:ss") : undefined,
            }
          })
        });

        if ((updatePanels.find(x => x === "FUELRATE") || this.state.fuelRate === 0) && this.state.BillingCustomerID && this.state.Locations[0]) {
          const data = {
            CustomerID: this.state.BillingCustomerID,
            Date: this.state.Locations[0].DateTime,
            VehicleTypeID: this.state.VehicleTypeID
          }
          fetchApi('/api/Quote/GetFuelRate', data, 'POST')
            .then((fuelRate: number) => {
              if (this.state.ValidationRequestHashFuelRate === validationHashFuelRate) {
                this.setState({ fuelRate });
              }
            });
        }
      })
      .catch((err) => console.error(err));
  }

  private fetchTransit() {
    if ((this.state.mapMileageReportLines != null && (this.state.Links.find((x) => x.Name === 'Transit') || this.state.TransitData === null))
      && (this.state.VehicleTypeID > 0)) {

      const validationHashTransit = Math.random().toString(36).substring(7);

      this.setState({
        ValidationRequestHashTransit: validationHashTransit,
        TransitDataIsLoading: true
      });

      const data = {
        Locations: this.state.Locations.map(({ Location, DateTime }) => ({ Location, DateTime })), //Only used for first pu DT and nothing more, since the map can reorder the stops
        DeadheadMiles: this.state.DeadheadMiles,
        RemainingHoursofService: Moment(this.state.RemainingHoursofService).format("HH:mm"),
        VehicleTypeIDs: this.state.VehicleTypeID,
        MileageReportData: this.state.mapMileageReportLines,
        Weight: 0
      }
      fetchApi("/api/BlindBidQuote/Transit", data, 'POST')
        .then((data) => {
          if (this.state.ValidationRequestHashTransit === validationHashTransit) {
            this.setState({ TransitData: data }, () => {
              this.fetchAllDebounced(); //TransitDataIsLoading set to false in here
              this.updateHeaderGridDebounced();
            });
          }
        })
        .catch(() => {
          if (this.state.ValidationRequestHashTransit === validationHashTransit) {
            this.setState({ TransitData: null, TransitDataIsLoading: false });
          }
        });
    } else {
      this.setState({ TransitData: null, TransitDataIsLoading: false });
    }
  }

  private requireUpdate(pendingChangeTypes: string[]): string[] {
    var updatePanels = new Set<string>();

    pendingChangeTypes.forEach((changeType) => {
      switch (changeType) {
        case "CUSTOMER":
        case "FIRST_STOP_DATETIME":
          updatePanels.add("TRANSIT");
          updatePanels.add("FUELRATE");
          break;
        case "ADD_STOP":
        case "STOP_DATETIME":
        case "DEADHEADMILES":
        case "REMAININGHOURSOFSERVICE":
        case "FIRST_STOP_LOCATION":
        case "STOP_LOCATION":
        case "REMOVE_STOP":
        case "ROUTINGOPTIMIZATIONTYPE":
        case "ROUTINGOPTIONTYPE":
        case "BORDEROPTIONTYPE":
        case "VEHICLETYPE":
          updatePanels.add("TRANSIT");
          break;
        case "ROUTENAME":
        case "THREAD":
        case "RATE":
          break;
        default:
          throw new Error(`Unhandled update: ${changeType}`);
      }
    });

    var updatePanelsArray: string[] = [];
    updatePanels.forEach(x => updatePanelsArray.push(x))
    return updatePanelsArray;
  }

  private clickQuote(quoteID: number) {
    window.open(`/Quote/Index/${quoteID}`, "_blank");
  }

  private loadQuoteData(): Promise<any> {
    const data = {
      Stops: this.state.Locations.map((x) => { return { StopType: x.StopType, DateTime: x.DateTime, City: x.Location.City, State: x.Location.State, Zip: x.Location.Zip } }),
      Weight: 0,
      VehicleTypeID: this.state.VehicleTypeID,
      BillingCustomerNumber: this.state.BillingCustomerID !== null && this.state.BillingCustomerID !== 0 ? this.state.BillingCustomerNumber : "0",
      HazMat: false,
      LiftGate: false,
    };
    return fetchApi('/api/Quote/Rate', data, 'POST')
      .then((data: QuoteValue) => {
        data.Amount = data.LineItems.reduce((a, b) => +a + +b.Amount, 0);
        data.LineHaulAmount = data.LineItems.filter(x => x.RateType == 70 || x.RateType == 76).reduce((a, b) => +a + +b.Amount, 0);
        data.FuelAmount = data.LineItems.filter(x => x.RateType == 85).reduce((a, b) => +a + +b.Amount, 0);
        data.AccessorialAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsNoDriverPay.indexOf(x.RateDescriptionID) == -1 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) == -1).reduce((a, b) => +a + +b.Amount, 0);
        data.BonusAmount = data.LineItems.filter(x => x.RateType == 65 && accessorialsFullDriverPay.indexOf(x.RateDescriptionID) != -1).reduce((a, b) => +a + +b.Amount, 0);
        return { quoteData: data }; //allInRateData: data.LineItems //enable to borrow from rates
        //this.setState({quoteData: data, AllInRateData: data.LineItems, isLoading: false })
      })
      .catch(() => {
        return { quoteData: null }
        //this.setState({quoteData: null, AllInRateData: null, isLoading: false });

      });
  }

  private fetchAllDebounced = debounce(this.fetchAll, 500);
  private fetchAll() {
    this.loadQuoteData()
      .then(value => {
        this.setState({
          QuotePanelStateValue: {
            allInRateData: null,
            quoteData: value.quoteData
          } as QuotePanelStateValue,
          TransitDataIsLoading: false
        }, () => {
          this.setAllInRate();
        })
      });
  }

  private getAllInRate(): number {
    let allInRate = this.state.Flat;
    if (this.state.PerMile > 0) {
      allInRate = Math.round(this.state.TransitData.Distance) * this.state.PerMile;
    }
    return allInRate;
  }

  private setAllInRate() {
    var allInRate = this.getAllInRate();

    if (allInRate < 1) {
      return;
    }

    var allInRateData = this.state.QuotePanelStateValue.allInRateData ?? [];
    var linehaul = allInRate / (1 + this.state.fuelRate)
    var fuel = allInRate - linehaul;

    // Read current fuel rates if any and just update with the same style
    let existingFuel: QuoteLineItemValue = null;
    if (this.state.QuotePanelStateValue.quoteData && this.state.QuotePanelStateValue.quoteData.Amount > 0) {
      let exFuel = this.state.QuotePanelStateValue.quoteData.LineItems.filter(x => [5000, 5001, 5003].includes(x.RateDescriptionID));
      existingFuel = exFuel.length > 0 ? exFuel[0] : null;
    }

    // Fuel not found, just make it one big linehaul
    if (existingFuel === null) {
      allInRateData = [{
        RateDescriptionID: 50,
        Name: "Flat Line Haul Fee",
        RateType: 70,
        IsAutoRated: false,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: 1,
        RateUoM: 8,
        Amount: allInRate
      }];
    } else {
      // Special case for existing Fuel Surcharge Per Mile
      if (existingFuel.RateDescriptionID === 5000) {
        fuel = existingFuel.Amount;
        linehaul = allInRate - fuel;
      }

      let fuelRate = {
        Quantity: 1,
        QuantityUoM: existingFuel.QuantityUoM,
        Rate: fuel,
        RateUoM: existingFuel.RateUoM,
        Amount: fuel,
        IsAutoRated: false,
        Name: existingFuel.Name,
        RateDescriptionID: existingFuel.RateDescriptionID,
        RateType: existingFuel.RateType
      } as QuoteLineItemValue;

      switch (existingFuel.RateDescriptionID) {
        case 5000:
          fuelRate.Quantity = existingFuel.Quantity;
          fuelRate.Rate = existingFuel.Rate;
          break;
        case 5001:
          fuelRate.Quantity = this.state.fuelRate > 0 ? (fuel / this.state.fuelRate) : 0;
          fuelRate.Rate = this.state.fuelRate;
          break;
        case 5003:
          break;
      }

      allInRateData = [{
        RateDescriptionID: 50,
        Name: "Flat Line Haul Fee",
        RateType: 70,
        IsAutoRated: false,
        Quantity: 1,
        QuantityUoM: 1,
        Rate: linehaul,
        RateUoM: 8,
        Amount: linehaul
      }, fuelRate];
    }
    this.setState({ QuotePanelStateValue: { ...this.state.QuotePanelStateValue, allInRateData } });
  }

}

