import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';
import YesNoCell from '../../../components/cells/YesNoCell';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { JsonResponse } from 'TypeGen/json-response';

type EFSMoneyCodeHistoryViewItem = {
    ActiveDate: Date,
    Code: string,
    Who: string,
    Amount: number,
    AmountUsed: number,
    Status: string,
    IssuedTo: string,
    Notes: string,
    ContractId: number,
    NumUses: number,
    FeeAmount: number,
    DeductFees: boolean,
    Voided: boolean
}

const AmountCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "c")}
        </td>
    );
};

const NumericCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(props.dataItem[props.field], "n")}
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
        </td>
    );
};



const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const MoneyCodeHistoryGrid = () => {
    const [loading, setLoading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [moneyCode, setMoneyCode] = useState('');
    const [moneyCodeHistory, setMoneyCodeHistory] = useState<EFSMoneyCodeHistoryViewItem[]>();

    const _fetchMoneyCodeHistory = () => {
        setLoading(true);
        fetchApi(`/api/Asset/GetEFSMoneyCodes`)
            .then((response: EFSMoneyCodeHistoryViewItem[]) => {
                setMoneyCodeHistory(response);
                setLoading(false);
            });
    }

    useEffect(() => {
        _fetchMoneyCodeHistory();
    }, []);

    const _voidMoneyCode = () => {
        setLoading(true);

        const data = {
            MoneyCode: moneyCode
        }
        fetchApi(`/api/Asset/VoidEFSMoneyCode`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success)
                    _fetchMoneyCodeHistory();
                else {
                    alert(`Error: ${response.ErrorMessage}`);
                    setLoading(false);
                }
            }).catch((response: number) => {
                if (response === 401)
                    alert('Permissions error.  Please see admin to be added.  Once added, logout from Load One App.');
                else
                    alert(`Error: ${response}`);
                setLoading(false);
            });
    }

    const VoidedCommandCell = (props: GridCellProps) => {
        if (!props.field)
            return null;

        const dataItem: EFSMoneyCodeHistoryViewItem = props.dataItem;

        return (
            <td className="k-command-cell">
                {dataItem.Voided ? "Voided" : dataItem.Amount === dataItem.AmountUsed ? "Used/Applied" :
                    <button className="btn btn-warning" onClick={() => { setMoneyCode(props.dataItem.Code); setShowConfirmDialog(true); }}>
                        Void Money Code
                    </button>}
            </td >
        );
    };

    return <div className="container-fluid">
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <Grid data={moneyCodeHistory}
            resizable={true}
        >
            <Column field="ActiveDate" title="Active Date" cell={DateCell} />
            <Column field="Code" title="Code" />
            <Column field="Who" title="Who" />
            <Column field="IssuedTo" title="Issued To" />
            <Column field="Amount" title="Amount" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
            <Column field="AmountUsed" title="Amount Used" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
            <Column field="FeeAmount" title="Fee" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
            <Column field="Status" title="Status" />
            <Column field="ContractId" title="Contract ID" />
            <Column field="NumUses" title="# of Uses" headerCell={RightAlignHeaderCell} cell={NumericCell} className="text-right" />
            <Column field="DeductFees" title="Deduct Fees" cell={YesNoCell} />
            <Column field="Notes" title="Reason" />
            <Column field="Voided" title="Action/Result" cell={VoidedCommandCell} width="200px" />
        </Grid>
        {showConfirmDialog && <Dialog title={"Confirm"} onClose={() => setShowConfirmDialog(false)} width={350} height={175}>
            <div>
                Void Money Code {moneyCode}?
            </div>
            <DialogActionsBar>
                <Button onClick={() => setShowConfirmDialog(false)}>
                    Cancel
                </Button>
                <Button themeColor="primary" onClick={() => { setShowConfirmDialog(false); _voidMoneyCode(); }}>
                    OK
                </Button>
            </DialogActionsBar>
        </Dialog>}
    </div>
}

export default MoneyCodeHistoryGrid;

