import { Calendar, CalendarChangeEvent, ToggleButton } from '@progress/kendo-react-dateinputs';
import { InputClearValue, InputSuffix, TextBox, TextBoxChangeEvent, TextBoxHandle, TextBoxProps } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { AsyncFocusBlur } from '@progress/kendo-react-common';
import Moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { SvgIcon } from "@progress/kendo-react-common";
import { calendarIcon, xIcon } from "@progress/kendo-svg-icons";

interface SimpleDatePickerProps {
  required?: boolean;
  onChange: (value: Date | null) => void;
  value: Date | null;
  inputProps?: TextBoxProps;

  //wip
  min?: Date;
  max?: Date;
}

const currentFormat = "MM/DD/YYYY";

const getFormattedDate = (value: string): Moment.Moment | null => {
  const formats = ["MM/DD/YYYY", "M/D/YYYY", "M/DD/YYYY", "MM/D/YYYY", "MMDDYYYY"];

  if (Moment(value, formats, true).isValid()) {
    return Moment(value, currentFormat);
  }

  if (value?.length === 6 && Moment(`0${value[0]}0${value[1]}${value.substring(2)}`, "MMDDYYYY", true).isValid())
    return Moment(`0${value[0]}/0${value[1]}/${value.substring(2)}`, currentFormat, true);

  return null;
};

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({ onChange, value, inputProps, min, max, required }) => {
  const anchor = React.useRef<TextBoxHandle | null>(null);
  const [inputValue, setInputValue] = useState<string>(value != null ? Moment(value).format(currentFormat) : '');
  const [calendarValue, setCalendarValue] = useState<Date | null>(value ?? null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleChange = (e: TextBoxChangeEvent) => {
    const val = e.value as string;
    if (/^[0-9/]*$/.test(val)) {
      setInputValue(val);
      const formattedDate = getFormattedDate(val);
      if (formattedDate != null) {
        setCalendarValue(formattedDate.toDate());
        onChange(formattedDate.toDate());
      } else {
        onChange(null);
      }
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const formattedDate = getFormattedDate(e.target.value);
    setInputValue(formattedDate != null ? Moment(formattedDate).format(currentFormat) : value != null ? Moment(value).format(currentFormat) : '');
    if (popupVisible) setPopupVisible(false);
  }

  const handleDateSelect = (e: CalendarChangeEvent) => {
    const calendarValue = e.target.value;

    setCalendarValue(calendarValue);
    setInputValue(Moment(calendarValue).format(currentFormat));
    setPopupVisible(!popupVisible);
    onChange(calendarValue);
  }

  React.useEffect(() => {
    if (anchor?.current?.element != document.activeElement) {
      setCalendarValue(value);
      setInputValue(value != null ? Moment(value).format(currentFormat) : '');
    }
  }, [value]);

  return <AsyncFocusBlur
    onFocus={() => {
      anchor?.current?.element?.select();
      setPopupVisible(true);
    }}
    onBlur={handleBlur}
  >
    {({ onFocus, onBlur }) => (<>
      <TextBox
        {...inputProps}
        style={{ minWidth: 145 }}
        valid={((inputValue?.length === 0 && !required) || getFormattedDate(inputValue) != null) && inputProps?.valid}
        value={inputValue}
        onChange={handleChange}
        onFocus={onFocus}
        ref={anchor}
        onBlur={onBlur}
        maxLength={10}
        suffix={() => (<>
          {inputValue?.length > 0 &&
            <InputClearValue onClick={() => { setInputValue(''); onChange(null); anchor.current?.element?.focus(); }}>
              <SvgIcon icon={xIcon} />
            </InputClearValue>
          }
          <InputSuffix>
            <ToggleButton
              type="button"
              tabIndex={-1}
              icon="calendar"
              svgIcon={calendarIcon}
              rounded={null}
              title="Toggle calendar"
              className="k-input-button"
              onClick={() => {
                setPopupVisible(!popupVisible);
                // On popup close, focus the input
                if (popupVisible) anchor?.current?.element?.focus();
              }}
              aria-label="Toggle calendar"
            />
          </InputSuffix>
          <Popup
            anchor={anchor?.current?.element}
            show={popupVisible}
            popupClass={"popup-content"}
            collision={{
              horizontal: "fit",
              vertical: "flip",
            }}
            animate={false}
          >
            <Calendar
              smoothScroll={false}
              onChange={handleDateSelect}
              value={calendarValue}
              min={min ?? new Date(1753, 1, 1)}
              max={max ?? new Date(2999, 12, 31)}
            />
          </Popup>
        </>)}
      />
    </>)}
  </AsyncFocusBlur>;
};

export default SimpleDatePicker;