import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { FreightEditViewModel } from "TypeGen/Order/freight-edit-view-model";

type Props = {
  orderFreight: FreightEditViewModel;
  setOrderFreight: React.Dispatch<React.SetStateAction<FreightEditViewModel>>;
};

const FreightEditUI: React.FC<Props> = ({ orderFreight, setOrderFreight }) => {
  const pieces = orderFreight.Pieces > 0 ? orderFreight.Pieces : '';
  const weight = orderFreight.Weight > 0 ? orderFreight.Weight : '';
  const length = orderFreight.Length > 0 ? orderFreight.Length : '';
  const width = orderFreight.Width > 0 ? orderFreight.Width : '';
  const height = orderFreight.Height > 0 ? orderFreight.Height : '';
  return <>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Pieces</label>
      </div>
      <div className="form-group col-md-5">
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            placeholder="Pieces"
            value={pieces}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setOrderFreight({ ...orderFreight, Pieces: parseInt(e.target.value || '0') })}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {orderFreight.PiecesUnitOfMeasures.find(x => x.ID === orderFreight.PiecesUnitOfMeasure)?.Name}
            </button>
            <div className="dropdown-menu">
              {orderFreight.PiecesUnitOfMeasures.map(x => <button key={x.ID} type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, PiecesUnitOfMeasure: x.ID })}>{x.Name}</button>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Weight</label>
      </div>
      <div className="form-group col-md-5">
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={0}
            value={weight}
            placeholder="Weight"
            onFocus={(e) => e.target.select()}
            onChange={(e) => setOrderFreight({ ...orderFreight, Weight: parseFloat(e.target.value || '0') })}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {orderFreight.WeightUnitOfMeasure === 1 ? 'LBS' : 'KGS'}
            </button>
            <div className="dropdown-menu">
              <button type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, WeightUnitOfMeasure: 1 })}>Pounds</button>
              <button type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, WeightUnitOfMeasure: 2 })}>Kilograms</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Dimensions</label>
      </div>
      <div className="form-group col-md-10">
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            placeholder="L"
            value={length}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setOrderFreight({ ...orderFreight, Length: parseInt(e.target.value || '0') })}
          />
          <div className="input-group-prepend">
              <span className="input-group-text">X</span>
          </div>
          <input
            type="number"
            className="form-control"
            placeholder="W"
            value={width}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setOrderFreight({ ...orderFreight, Width: parseInt(e.target.value || '0') })}
          />
          <div className="input-group-prepend">
              <span className="input-group-text">X</span>
          </div>
          <input
            type="number"
            className="form-control"
            placeholder="H"
            value={height}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setOrderFreight({ ...orderFreight, Height: parseInt(e.target.value || '0') })}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary dropdown-toggle" style={{ borderRadius: 0 }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {orderFreight.DimsUnitOfMeasure === 2 ? 'IN' : 'FT'}
            </button>
            <span>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, DimsUnitOfMeasure: 2 })}>Inches</button>
                <button type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, DimsUnitOfMeasure: 1 })}>Feet</button>
              </div>
            </span>
            <span>
              <button className="btn btn-outline-secondary dropdown-toggle" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {orderFreight.Stackable ? 'Stackable' : 'Not Stackable'}
              </button>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item" onClick={() => setOrderFreight({ ...orderFreight, Stackable: true })}>Stackable</button>
                <button type="button" className="dropdown-item" onClick={() =>setOrderFreight({ ...orderFreight, Stackable: false })}>Not Stackable</button>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Commodity</label>
      </div>
      <div className="form-group col-md-5">
        <DropDownList
          value={orderFreight.Commodities.find(x => x.ID == orderFreight.CommodityID)}
          data={orderFreight.Commodities}
          textField="Name"
          className="w-100"
          onChange={(e) => setOrderFreight({ ...orderFreight, CommodityID: e.target.value.ID })}
        />
        <Input
          placeholder="Commodity Alt. Description"
          value={orderFreight.CommodityAltDescription}
          onChange={(e) => setOrderFreight({ ...orderFreight, CommodityAltDescription: e.value })}
          className="mt-2 w-100"
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Bill of Lading</label>
      </div>
      <div className="form-group col-md-5">
        <Input
          value={orderFreight.BillOfLadingNumber}
          onChange={(e) => setOrderFreight({ ...orderFreight, BillOfLadingNumber: e.value })}
          style={{ width: "100%" }}
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Freight Value</label>
      </div>
      <div className="form-group col-md-5">
        <div className="input-group">
          <NumericTextBox
            className="form-control"
            min={0}
            value={orderFreight.FreightValue}
            placeholder="$0.00"
            format="c2"
            spinners={false}
            onChange={(e) => setOrderFreight({ ...orderFreight, FreightValue: e.value })}
          />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">POD Signed By</label>
      </div>
      <div className="form-group col-md-5">
        <Input
          value={orderFreight.ProofOfDeliverySignedBy}
          onChange={(e) => setOrderFreight({ ...orderFreight, ProofOfDeliverySignedBy: e.value })}
          style={{ width: "100%" }}
        />
      </div>
    </div>
    {orderFreight.PickupStopOptions.length > 0 && <>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Pickup Stop</label>
        </div>
        <div className="form-group col-md-5">
          <DropDownList
            value={orderFreight.PickupStopOptions.find(x => x.ID == orderFreight.PickupStopID)}
            data={orderFreight.PickupStopOptions}
            textField="Name"
            className="w-100"
            onChange={(e) => setOrderFreight({ ...orderFreight, PickupStopID: e.target.value.ID })}
          />
        </div>
      </div>
    </>}
    {orderFreight.DeliveryStopOptions.length > 0 && <>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Delivery Stop</label>
        </div>
        <div className="form-group col-md-5">
          <DropDownList
            value={orderFreight.DeliveryStopOptions.find(x => x.ID == orderFreight.DeliveryStopID)}
            data={orderFreight.DeliveryStopOptions}
            textField="Name"
            className="w-100"
            onChange={(e) => setOrderFreight({ ...orderFreight, DeliveryStopID: e.target.value.ID })}
          />
        </div>
      </div>
    </>}
  </>
}

export default FreightEditUI;