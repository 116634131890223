import * as React from 'react';
import Moment from 'moment';
import { formatNumber } from '@progress/kendo-intl';
import { EnterStopViewModel } from '.';
import { Label } from "@progress/kendo-react-labels";
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';

type State = {
  show: boolean;
  showTeam: boolean;
}

type Props = {
  last: boolean;
  stop: EnterStopViewModel;
  index: number;
  location: TransitLocationValue;
  distance: number;
  tolls: number;
  onChange: (newStop: EnterStopViewModel, changeType: string) => void;
};

export type TransitValue = {
  Distance: number;
  Tolls: number;

  Locations: TransitLocationValue[];
}

export type TransitLocationValue = {
  Distance: number;
  Tolls: number;
  SuggestedDateTime: Date;
  TransitTime: TransitTimeResult;
  SuggestedDateTimeTeam: Date;
  TransitTimeTeam: TransitTimeResult;
}

type TransitTimeResult = {
  DrivingTime: string;
  WaitTime: string;
  SleepTime: string;
  Total: string;
  HtmlFormatted: string;
}

class StopTransitData extends React.Component<Props, State> {

  private popupAnchor: React.RefObject<Button>;
  private popupAnchorTeam: React.RefObject<Button>;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
      showTeam: false,
    }

    this.popupAnchor = React.createRef();
    this.popupAnchorTeam = React.createRef();

    this.setSingle = this.setSingle.bind(this);
    this.setTeam = this.setTeam.bind(this);
  }

  render() {
    return (
      <React.Fragment>
      <div className="form-row">
        <div className="col-md-4"  style={{ paddingTop: '.375rem', fontWeight: (this.props.last && this.props.index == 1) ? 'bold' : undefined }}>
          {formatNumber(this.props.location.Distance, "n0")} Miles <span title="Toll Cost">({formatNumber(this.props.location.Tolls, "c")})</span>
        </div>
        <div className="col-md-4">
          <Button
            ref={this.popupAnchor}
            fillMode="link"
            themeColor="primary"
            onClick={this.setSingle}
            onMouseEnter={() => this.setState({ show: true })}
            onMouseLeave={() => this.setState({ show: false })}>
            Single: <span
              style={{ color: Moment.utc(this.props.location.SuggestedDateTime).isAfter(Moment.utc(this.props.stop.DateTime)) ? 'red' : undefined }}
            >
              {Moment.utc(this.props.location.SuggestedDateTime).format("MM/DD/YYYY HH:mm")}
            </span>
            {this.popupAnchor.current && <Popup
              anchor={this.popupAnchor.current.element}
              show={this.state.show}
              className={'wrapper'}
              popupClass={'inner-wrapper'}
            >
              <span className="px-2" dangerouslySetInnerHTML={{ __html: this.props.location.TransitTime.HtmlFormatted }} />
            </Popup>}
          </Button>
        </div>
        <div className="col-md-4">
          <Button
            ref={this.popupAnchorTeam}
            fillMode="link"
            themeColor="primary"
            onClick={this.setTeam}
            onMouseEnter={() => this.setState({ showTeam: true })}
            onMouseLeave={() => this.setState({ showTeam: false })}>
            Team: <span
              style={{ color: Moment.utc(this.props.location.SuggestedDateTimeTeam).isAfter(Moment.utc(this.props.stop.DateTime)) ? 'red' : undefined }}
            >
              {Moment.utc(this.props.location.SuggestedDateTimeTeam).format("MM/DD/YYYY HH:mm")}
            </span>
            {this.popupAnchorTeam.current && <Popup
              anchor={this.popupAnchorTeam.current.element}
              show={this.state.showTeam}
              className={'wrapper'}
              popupClass={'inner-wrapper'}
            >
              <span className="px-2" dangerouslySetInnerHTML={{ __html: this.props.location.TransitTimeTeam.HtmlFormatted }} />
            </Popup>}
          </Button>
        </div>
      </div>
      {this.props.last && this.props.index > 1 && <div className="form-row">
        <div className="col-md-12">
          <Label className='font-weight-bold'>
            Total: {formatNumber(this.props.distance, "n0")} Miles <span title="Total Toll Cost">({formatNumber(this.props.tolls, "c")})</span>
          </Label>
        </div>
      </div>}
      </React.Fragment>
    );
  }

  private setSingle() {
    var newDateTime = Moment.utc(this.props.location.SuggestedDateTime).format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      const newStop = Object.assign({}, this.props.stop, { DateTime: newDateTime });
      this.props.onChange(newStop, "STOP_DATETIME");
    }
  }

  private setTeam() {
    var newDateTime = Moment.utc(this.props.location.SuggestedDateTimeTeam).format("MM/DD/YYYY HH:mm");
    if (this.props.stop.DateTime !== newDateTime) {
      const newStop = Object.assign({}, this.props.stop, { DateTime: newDateTime });
      this.props.onChange(newStop, "STOP_DATETIME");
    }
  }
}

export default StopTransitData;
