import { useCallback, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { DriverNavBar } from "./NavBar";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { CompositeFilterDescriptor, DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DateFilter, Filter, NumericFilter, Operators, TextFilter } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import { VehicleToDriverHistoryView } from "TypeGen/Assets/VehicleToDriver/vehicle-to-driver-history-view";
import DateCell from "components/cells/DateCell";
import { Link } from "react-router-dom";

type RouteComponentParams = {
    driverId: string;
};

type TurndownsType = {
    Data: VehicleToDriverHistoryView[],
    Total: number,
}

const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
        field: "VehicleToDriverID",
        dir: "desc"
    }],
} as DataSourceRequestState;

const DriverMovementHistory = () => {
    const { driverId: driverIdParam } = useParams<RouteComponentParams>();

    return (<>
        <DriverNavBar id={driverIdParam} currentTab="MovementHistory" />
        <Title string="Driver Movement History" />
        <DriverMovementHistoryGrid driverId={parseInt(driverIdParam)} />
    </>);
}

type DriverMovementHistoryGridProps = {
    driverId: number;
}

export const DriverMovementHistoryGrid = ({ driverId }: DriverMovementHistoryGridProps) => {

    const [loading, setLoading] = useState(true);
    const [vehicleToDriverHistory, setVehicleToDriverHistory] = useState<TurndownsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({
        logic: 'and', filters: []
    });

    const refresh = useCallback(() => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/VehicleToDriverHistory/${driverId}?${queryStr}`, {}, 'POST')
            .then(data => {
                setLoading(false);
                setVehicleToDriverHistory(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [driverId, dataState]);

    useEffect(() => {
        refresh();
    }, [driverId, refresh]);

    return (<>
        {loading && <div className="k-pos-absolute k-top-center mt-5">
            <Loader type="converging-spinner" />
        </div>}
        <div>
            <Filter
                className="kendoFilterControl"
                value={filter}
                onChange={(e) => {
                    setFilter(e.filter);
                }}
                fields={[
                    {
                        name: "VehicleNumber",
                        label: "Vehicle #",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "VehicleTypeName",
                        label: "Vehicle Type",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "AssignDateTime",
                        label: "Assigned Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "AssignByUserNumber",
                        label: "Assigned By",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "DriverNum",
                        label: "Driver #",
                        filter: NumericFilter,
                        operators: Operators.numeric,
                    },
                    {
                        name: "RemoveDateTime",
                        label: "Removed Date",
                        filter: DateFilter,
                        operators: Operators.date,
                    },
                    {
                        name: "RemoveByUserNumber",
                        label: "Removed By",
                        filter: TextFilter,
                        operators: Operators.text,
                    },
                    {
                        name: "Comment",
                        label: "Comment",
                        filter: TextFilter,
                        operators: Operators.text,
                    }
                ]}
            />
            <label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>
                <Button
                    themeColor="primary"
                    onClick={() => setDataState({ ...dataState, filter: filter })}
                >Search
                </Button>
            </label>
        </div>
        {vehicleToDriverHistory && <Grid
            {...dataState}
            data={vehicleToDriverHistory.Data}
            total={vehicleToDriverHistory.Total}
            sortable
            pageable
            onDataStateChange={(e) => setDataState(e.dataState)}
        >
            <Column field="VehicleNumber" title="Vehicle #" cell={(e) => <td>
                <Link style={{ color: "#007bff" }} to={`/Assets/Vehicle/${e.dataItem.VehicleID}`}>{e.dataItem.VehicleNumber}</Link>
            </td>} />
            <Column field="VehicleTypeName" title="Vehicle Type" />
            <Column field="AssignDateTime" title="Assigned" cell={DateCell} />
            <Column field="AssignByUserNumber" title="Assigned By" />
            <Column field="DriverNum" title="Driver #" />
            <Column field="RemoveDateTime" title="Removed" cell={DateCell} />
            <Column field="RemoveByUserNumber" title="Removed By" />
            <Column field="Comment" />
        </Grid>}
    </>);
}

export default DriverMovementHistory;