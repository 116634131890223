import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Rating } from "@progress/kendo-react-inputs";
import useAlert from "./useAlert";
import { useState } from "react";
import { fetchApi } from "services/api";

type Props = {
  onClose: () => void;
}

const Feedback = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [speed, setSpeed] = useState<number>();
  const [functionality, setFunctionality] = useState<number>();
  const [easeOfUse, setEaseOfUse] = useState<number>();
  const [support, setSupport] = useState<number>();
  const [overallSatisfaction, setOverallSatisfaction] = useState<number>();
  const [feedback, setFeedback] = useState("");
  const [anonymous, setAnonymous] = useState(false);
  const { alert } = useAlert();

  const dismiss = () => {
    fetchApi("/api/User/FeedbackExit", {}, 'POST')
      .finally(() => {
        props.onClose();
      });
  }

  const save = () => {
    setLoading(true);

    const payload = {
      Speed: speed,
      Functionality: functionality,
      EaseOfUse: easeOfUse,
      Support: support,
      OverallSatisfaction: overallSatisfaction,
      Feedback: feedback,
      Anonymous: anonymous,
    }

    fetchApi("/api/User/FeedbackSave", payload, 'POST')
      .then(async () => {
        setLoading(false);
        await alert("Thank you for your feedback!");
        props.onClose();
      })
      .catch(async (e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('Unable to save feedback.');
      });
  }

  return <>
    <Dialog title="Feedback" onClose={dismiss} className='dialog-w800'>
      <p className="mb-2">We'd love to hear any feedback you have about Transcend One. This feedback will be shared with the management team and evaluated.</p>
      <div className="text-muted mb-2">Please rate the following:</div>
      <div className="row">
        <div className="col-md-6">
          <Rating label="Speed" value={speed} onChange={(e) => setSpeed(e.value)} />
          <Rating label="Functionality" value={functionality} onChange={(e) => setFunctionality(e.value)} />
          <Rating label="Ease of Use" value={easeOfUse} onChange={(e) => setEaseOfUse(e.value)} />
        </div>
        <div className="col-md-6">
          <Rating label="Support" value={support} onChange={(e) => setSupport(e.value)} />
          <Rating label="Overall Satisfaction" value={overallSatisfaction} onChange={(e) => setOverallSatisfaction(e.value)} />
        </div>
      </div>
      <TextArea autoFocus className="mt-2" placeholder="Please provide your comments or ideas here to make Transcend One better!" value={feedback} onChange={(e) => setFeedback(e.value)} />
      <Checkbox className="mt-2" label="Leave feedback anonymously" value={anonymous} onChange={(e) => setAnonymous(e.value)} />
      <DialogActionsBar layout="end">
        <Button onClick={dismiss}>Cancel</Button>
        <Button disabled={loading} themeColor="primary" onClick={save}>Send Feedback</Button>
      </DialogActionsBar>
    </Dialog>
  </>;
}

export default Feedback;