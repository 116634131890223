import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, InputPrefix, InputSuffix } from '@progress/kendo-react-inputs';
import CustomerContactInput from "components/CustomerContactInput";
import CustomerInput from "components/CustomerInput";
import SimpleDatePicker from 'components/SimpleDatePicker';
import SimpleTimePicker from 'components/SimpleTimePicker';
import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { fetchApi } from 'services/api';
import { IDNameViewModel } from 'TypeGen/id-name-view-model';
import { StopEditViewModel } from "TypeGen/Order/stop-edit-view-model";
import { StopType } from 'TypeGen/stop-type';
import { StopVerb } from "TypeGen/stop-verb";
import { IDName } from 'types/idname';
import SingleLocationAutoComplete from "views/PCMiler/SingleLocationAutoComplete";

type Props = {
  locationLabel?: string;
  orderStop: StopEditViewModel;
  setOrderStop: React.Dispatch<React.SetStateAction<StopEditViewModel>>;
  reasonsLate?: IDNameViewModel[];
  resolveStopNote?: string;
};

const stopVerbs = [
  { text: "Protect", value: StopVerb.PROTECT },
  { text: "Ready Now", value: StopVerb.READY_NOW },
  { text: "Deliver Direct", value: StopVerb.DELIVER_DIRECT },
  { text: "ASAP", value: StopVerb.ASAP },
  { text: "Open", value: StopVerb.OPEN },
  { text: "Close", value: StopVerb.CLOSE },
  { text: "Hot", value: StopVerb.HOT },
  { text: "Window", value: StopVerb.WINDOW },
];

const StopEditUI: React.FC<Props> = ({ orderStop, setOrderStop, reasonsLate, ...props }) => {
  const [instructions, setInstructions] = useState<Array<{ Display: number, Instruction: string }>>([]);
  const [originalScheduledDateTime,] = React.useState(orderStop.ScheduledDateTime);

  const getCustomerInstructions = useCallback(() => {
    if (orderStop?.CustomerID > 0) {
      fetchApi(`/api/Customer/SpecialInstructions/${orderStop.CustomerID}`)
        .then((response: Array<{ Type: number, Display: number, Instruction: string }>) => {
          const instructions = response
            .filter(x => (orderStop.StopType == StopType.Pickup && x.Type === 83) || (orderStop.StopType == StopType.Delivery && x.Type === 67))
            .map(function (x) { return { Display: x.Display, Instruction: x.Instruction } });

          const uniqueInstructions = Array.from(new Set(instructions.map(x => x.Instruction)));

          const distinctInstructionRecords = Array.from(uniqueInstructions, Instruction => {
            return instructions.find(x => x.Instruction === Instruction);
          });

          setInstructions(distinctInstructionRecords);

        });
    }
  }, [orderStop?.CustomerID, orderStop?.StopType]);

  useEffect(() => {
    getCustomerInstructions();
  }, [getCustomerInstructions, orderStop?.CustomerID]);

  const setStopVerb = (stopVerb: StopVerb) => {
    setOrderStop({
      ...orderStop,
      StopVerb: stopVerb,
      EarliestStopDateTime: stopVerb === StopVerb.WINDOW ? orderStop.ScheduledDateTime : undefined,
      LatestStopDateTime: stopVerb === StopVerb.WINDOW ? orderStop.ScheduledDateTime : undefined
    });
  }

  const fetchAdjustedScheduledDateTimeDiff = () => {
    const _originalScheduledDateTime = moment(originalScheduledDateTime);
    //orderStop.ScheduledDateTime
    const scheduledDateTime = moment(dateTime);

    const duration = moment.duration(scheduledDateTime.diff(_originalScheduledDateTime));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days} day(s) ${hours} hour(s) ${minutes} minute(s)`;
  }

  const updateDate = (date: Date | null) => {
    if (date == null) return;
    updateDateTime(moment(`${date.toDateString()} ${new Date(orderStop.ScheduledDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateTime = (time: Date | null) => {
    if (time == null) return;
    updateDateTime(moment(`${new Date(orderStop.ScheduledDateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateDateTime = (value: moment.Moment) => {
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (orderStop.ScheduledDateTime !== newDateTime) {
      setOrderStop({
        ...orderStop,
        ScheduledDateTime: newDateTime,
        EarliestStopDateTime: (orderStop.StopVerb !== StopVerb.WINDOW || moment(newDateTime).isSameOrBefore(orderStop.LatestStopDateTime)) ? orderStop.LatestStopDateTime : newDateTime,
        LatestStopDateTime: (orderStop.StopVerb !== StopVerb.WINDOW || moment(newDateTime).isSameOrAfter(orderStop.EarliestStopDateTime)) ? orderStop.EarliestStopDateTime : newDateTime,
        AdjustSubsequentStops: orderStop.ShowAdjustSubsequentStopsOption === false ? null : orderStop.AdjustSubsequentStops == null ? false : orderStop.AdjustSubsequentStops
      });
    }
  }

  const updateEarliestDate = (date: Date | null) => {
    if (date == null) return;
    updateEarliestDateTime(moment(`${date.toDateString()} ${new Date(orderStop.EarliestStopDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateEarliestTime = (time: Date | null) => {
    if (time == null) return;
    updateEarliestDateTime(moment(`${new Date(orderStop.EarliestStopDateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateEarliestDateTime = (value: moment.Moment) => {
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (orderStop.EarliestStopDateTime !== newDateTime) {
      setOrderStop({
        ...orderStop,
        EarliestStopDateTime: newDateTime
      });
    }
  }

  const updateSubsequentStops = (value: boolean) => {
    setOrderStop({
      ...orderStop,
      AdjustSubsequentStops: value
    });
  }

  const updateLatestDate = (date: Date | null) => {
    if (date == null) return;
    updateLatestDateTime(moment(`${date.toDateString()} ${new Date(orderStop.LatestStopDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateLatestTime = (time: Date | null) => {
    if (time == null) return;
    updateLatestDateTime(moment(`${new Date(orderStop.LatestStopDateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`));
  }

  const updateLatestDateTime = (value: moment.Moment) => {
    const newDateTime = value.format("MM/DD/YYYY HH:mm");
    if (orderStop.LatestStopDateTime !== newDateTime) {
      setOrderStop({
        ...orderStop,
        LatestStopDateTime: newDateTime
      });
    }
  }

  const renderTimeInputGroup = () => {
    return <InputSuffix>
      <DropDownList
        style={{ width: 128 }}
        data={stopVerbs}
        value={stopVerbs.find(x => x.value === orderStop.StopVerb)}
        textField='text'
        onChange={(e) => setStopVerb(e.target.value.value)}
      />
    </InputSuffix>;
  }

  const populateCustomerContacts = useCallback((customerId: number) => {
    if (!customerId) return;
    fetchApi('/api/Quote/CustomerContacts', { CustomerID: customerId }, 'POST')
      .then((response: { PerferredContactID: number, Contacts: IDName[] }) => {
        setOrderStop(prev => ({
          ...prev,
          CustomerContacts: response.Contacts,
          CustomerContactID: prev.CustomerContactID || response.PerferredContactID || 0
        }));
      });
  }, [setOrderStop]);

  const instructionColor = (display: number) => {
    switch (display) {
      case 82:
        return { className: "text-danger" };
      case 71:
        return { className: "text-success" };
      case 89:
        return { className: "text-warning" };
      case 66:
        return { className: "text-primary" };
    }
    return null;
  }

  const renderInstructions = () => {
    if (instructions.length === 0 || !orderStop.CustomerID) return null;
    return <p>
      {instructions.map((instruction, index) => {
        return <React.Fragment key={index}>
          <span {...instructionColor(instruction.Display)}>{orderStop.CustomerNumber}: {instruction.Instruction}</span>
          <br />
        </React.Fragment>;
      })}
    </p>
  }

  const dateTime = moment(orderStop.ScheduledDateTime);
  const latestDateTime = orderStop.StopVerb === StopVerb.WINDOW ? moment(orderStop.LatestStopDateTime) : undefined;
  const earliestDateTime = orderStop.StopVerb === StopVerb.WINDOW ? moment(orderStop.EarliestStopDateTime) : undefined;

  return <>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">{props.locationLabel ?? "Location"}</label>
      </div>
      <div className="form-group col-md-10">
        <div className="input-group">
          <CustomerInput
            placeholder='Company'
            CustomerID={orderStop.CustomerID}
            CustomerNumber={orderStop.CustomerNumber}
            StopNote={props.resolveStopNote}
            City={orderStop.City}
            State={orderStop.State}
            ZipCode={orderStop.ZipCode}
            onChange={(e) => {
              setOrderStop({
                ...orderStop,
                City: e.City,
                State: e.State,
                ZipCode: e.ZipCode,
                CustomerID: e.CustomerID,
                CustomerNumber: e.CustomerNumber,
                CustomerContactID: null,
                CustomerContacts: [],
              });
              populateCustomerContacts(e.CustomerID);
            }}
          />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="col-md-10 offset-md-2">
        <div className="form-group">
          <SingleLocationAutoComplete
            value={(orderStop.ZipCode || orderStop.City) && `${orderStop.ZipCode} ${orderStop.City}, ${orderStop.State}`}
            autoCompleteProps={{ clearButton: true, placeholder: 'Enter City or Zip' }}
            onSelected={(e) => setOrderStop({
              ...orderStop,
              City: e?.Address.City,
              State: e?.Address.State,
              ZipCode: e?.Address.Zip,
              CustomerID: null,
              CustomerNumber: '',
              CustomerContactID: null,
            })}
          />
        </div>
        {renderInstructions()}
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Scheduled</label>
      </div>
      <div className="form-group col-md-5">
        <SimpleDatePicker
          inputProps={{
            prefix: () => <InputPrefix>
              <Button disabled>EST</Button>
            </InputPrefix>
          }}
          value={dateTime.toDate()}
          onChange={updateDate}
        />
      </div>
      <div className="form-group col-md-5">
        <SimpleTimePicker
          value={dateTime.toDate()}
          onChange={updateTime}
          inputProps={{
            suffix: renderTimeInputGroup
          }}
        />
      </div>
    </div>

    {orderStop.AdjustSubsequentStops != null && <div className="form-row">
      <div className="form-group col-md-10 offset-md-2">
        <Checkbox
          value={orderStop.AdjustSubsequentStops}
          onChange={(e) => updateSubsequentStops(e.value)}
          label={`Adjust all subsequent stops by ${fetchAdjustedScheduledDateTimeDiff()}`}
        />
      </div>
    </div>}

    {reasonsLate && <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Reason</label>
      </div>
      <div className="col-md-10">
        <DropDownList
          required
          valid={orderStop.ReasonLate > 0}
          defaultValue={0}
          value={reasonsLate.find(x => x.ID == orderStop.ReasonLate)}
          data={reasonsLate}
          textField="Name"
          style={{ width: '100%' }}
          onChange={(e) => setOrderStop({
            ...orderStop,
            ReasonLate: e.target.value.ID
          })}
        />
      </div>
    </div>}
    {orderStop.StopVerb === StopVerb.WINDOW && <>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Earliest</label>
        </div>
        <div className="form-group col-md-5">
          <SimpleDatePicker
            value={earliestDateTime?.toDate()}
            onChange={updateEarliestDate}
            inputProps={{
              valid: dateTime.isSameOrAfter(earliestDateTime, 'day'),
              prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
              </InputPrefix>
            }}
          />
        </div>
        <div className="form-group col-md-5">
          <SimpleTimePicker
            value={earliestDateTime?.toDate()}
            onChange={updateEarliestTime}
            inputProps={{ valid: dateTime.isSameOrAfter(earliestDateTime, 'minute') }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 text-right">
          <label className="col-form-label">Latest</label>
        </div>
        <div className="form-group col-md-5">
          <SimpleDatePicker
            value={latestDateTime?.toDate()}
            onChange={updateLatestDate}
            inputProps={{
              valid: dateTime.isSameOrBefore(latestDateTime, 'day'),
              prefix: () => <InputPrefix>
                <Button disabled>EST</Button>
              </InputPrefix>
            }}
          />
        </div>
        <div className="form-group col-md-5">
          <SimpleTimePicker
            value={latestDateTime?.toDate()}
            onChange={updateLatestTime}
            inputProps={{ valid: dateTime.isSameOrBefore(latestDateTime, 'minute') }}
          />
        </div>
      </div>
    </>}
    <div className="form-row">
      <div className="form-group col-md-2 text-right">
        <label className="col-form-label">Contact</label>
      </div>
      <div className="form-group col-md-10">
        <div className="input-group">
          <CustomerContactInput
            CustomerID={orderStop.CustomerID}
            CustomerContactID={orderStop.CustomerContactID}
            CustomerContacts={orderStop.CustomerContacts}
            onChange={(e) => {
              if (e.CustomerID) {
                setOrderStop({
                  ...orderStop,
                  CustomerID: e.CustomerID,
                  CustomerNumber: e.CustomerNumber,
                  CustomerContactID: e.CustomerContactID,
                  CustomerContacts: []
                });
                populateCustomerContacts(e.CustomerID);
              } else {
                setOrderStop({
                  ...orderStop,
                  CustomerContactID: e.CustomerContactID
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  </>
}

export default StopEditUI;