import { useEffect, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridCellProps, GridDataStateChangeEvent, GridEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import CenterDivPanel from '../../components/CenterDivPanel';
import { Title } from '../../utils/title';
import { Loader } from "@progress/kendo-react-indicators";
import { ILink } from '../../types/link';
import { BooleanFilter, Filter, FilterChangeEvent, Operators, TextFilter } from '@progress/kendo-react-data-tools';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import EmailCell from '../../components/cells/EmailCell';
import PhoneCellAndExtension from '../../components/cells/PhoneCellAndExtension';

export type CarrierContactViewModel = {
  CarrierContactID: number;
  FullName: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  PhoneNumberExtension: string;
  SendTruckAvailability: boolean;
  EMailAddress: string;
  Active: boolean;
  CarrierName: string;
  Hash: string;
  Links: ILink[];
}

export const CarrierContactCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: CarrierContactViewModel = props.dataItem;
  const carrierContactProfile = dataItem.Links.find((x: ILink) => x.Name === 'CarrierContactProfile');
  return (
    <td colSpan={props.colSpan} style={props.style}>
      {carrierContactProfile &&
        <Link
          to={carrierContactProfile.Link}
          style={{ color: '#007bff' }}
        >
          {dataItem.FullName || "N/A"}
        </Link>}
    </td>
  );
};

const CarrierContactGrid = () => {
  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "FullName",
      dir: "asc"
    }]
  } as GridState;

  const defaultAndFilter = {
    logic: 'and', filters: [
      { field: 'Active', operator: 'eq', value: true },
    ]
  } as CompositeFilterDescriptor;

  const sessionStorageAndFilter = sessionStorage.getItem("CarrierContactGrid-andFilter");
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });
  const [loading, setLoading] = useState(false);
  const [carrierContacts, setCarrierContacts] = useState<CarrierContactViewModel[]>([]);
  //const [showAddContactDialog, setShowAddContactDialog] = useState(false);
  //const buttonAnchor = useRef<Button>(null);
  const totalRecords = useRef(0);
  const DataState_Take = 50;

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const fetchRecords = useCallback((append: boolean) => {
    sessionStorage.setItem("CarrierContactGrid-andFilter", JSON.stringify(dataState.filter));
    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }

    //Todo - Add as a top filter
    const data = {
      CompanyActive: true
    };
    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/Asset/CarrierContacts?${queryStr}`, data, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setCarrierContacts(Data);
        } else {
          setCarrierContacts(prevData => prevData.concat(Data));
        }
        setLoading(false);
        handleResize(null);
      }).catch(() => {
        setLoading(false);
      });
  }, [dataState]);

  const search = useDebouncedCallback(useCallback(() => {
    fetchRecords(false);
  }, [fetchRecords]), 750);

  useEffect(() => {
    search();
  }, [dataState.filter, dataState.sort, search]);

  //useEffect(() => {
  //    fetchRecords(false);
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);

  const handleResize = (e: UIEvent) => {
    if ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement != null) {
      ((document.getElementsByClassName("k-grid")[0]) as HTMLDivElement).style.height = (document.documentElement.clientHeight - 250) + "px";
    }
  }

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (carrierContacts.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const CarrierCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: CarrierContactViewModel = props.dataItem;
    var carrierProfile = dataItem.Links.find((x: ILink) => x.Name === 'CarrierProfile');
    //props.dataItem[props.field]
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {carrierProfile &&
          <Link
            to={carrierProfile.Link}
            style={{ color: '#007bff' }}
          >
            {dataItem.CarrierName}
          </Link>}
      </td>
    );
  };

  const dataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }

  const onFilterChange = (event: FilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter });
  };

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
  };

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setDataState({ ...dataState, sort: event.sort });
  };

  return (
    //<div className="container-fluid" onKeyDown={handleKey}>
    <div className="container-fluid">
      <Title string="CarrierContact Listing" />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div>
        <Filter
          className="kendoFilterControl"
          value={dataState.filter}
          onChange={onFilterChange}
          fields={[
            {
              name: "Active",
              label: "Active",
              filter: BooleanFilter,
              operators: Operators.boolean
            },
            {
              name: "FullName",
              label: "Name",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "EMailAddress",
              label: "E-Mail",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "CarrierName",
              label: "Carrier Name",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "PhoneNumber",
              label: "Phone Number",
              filter: TextFilter,
              operators: Operators.text,
            },
            {
              name: "SendTruckAvailability",
              label: "Receive Scorecard",
              filter: BooleanFilter,
              operators: Operators.boolean,
            }
          ]}
        />
        {/*<label className="ml-2" style={{ verticalAlign: "bottom", marginBottom: "10px" }}>*/}
        {/*    <Button themeColor="primary"*/}
        {/*        ref={buttonAnchor}*/}
        {/*        onClick={() => {*/}
        {/*            fetchRecords(false);*/}
        {/*        }}*/}
        {/*    >Search*/}
        {/*    </Button>*/}
        {/*</label>*/}
      </div>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        data={carrierContacts}
        resizable={true}
        reorderable={true}
        sortable={true}
        total={totalRecords.current}
        onScroll={scrollHandler}
        filterable={true}
        onFilterChange={(e) => onGridFilterChange(e)}
        onSortChange={(e) => onGridSortChange(e)}
      >
        <Column field="FullName" title="Name" cell={CarrierContactCell} />
        <Column field="EMailAddress" title="E-Mail" cell={EmailCell} />
        <Column field="PhoneNumber" title="Phone" cell={PhoneCellAndExtension} />
        <Column field="CarrierNumber" title="Carrier" cell={CarrierCell} filterable={false} />
      </Grid>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
    </div>
  )
}

export default CarrierContactGrid;