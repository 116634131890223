import { Loader } from '@progress/kendo-react-indicators';
import { Label } from '@progress/kendo-react-labels';
import { useCallback, useEffect, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import KendoWindow from '../../components/KendoWindow';
import useAlert from '../../components/useAlert';
import { Title } from "../../utils/title";
import PayPeriodDropDownList from './PayPeriodDropDownList';

type Props = {
  PayPeriodID: number;
  onDialogClose?: () => void;
};

const PayPeriodGrandTotalSummary = (props: Props) => {
  const { alert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState('');
  const [filterByPayPeriod, setFilterByPayPeriod] = useState(props.PayPeriodID);

  const refresh = useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/Settlements/PayPeriodGrandTotalSummary/${filterByPayPeriod}`);
        if (!response.ok) {
          const errorData = await response.text();
          await alert(`Error: ${errorData || 'Please see admin'}`);
          return;
        }
        const data = await response.text();
        setHtml(data);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        const errorMessage = e.message || 'Unknown error occurred';
        await alert(errorMessage);
      }
    };

    fetchData();
  }, [filterByPayPeriod, alert]);

  useEffect(() => {
    refresh();
  }, [refresh, props.PayPeriodID]);

  const dataView = () => {
    return (<div className="mt-4">
      <Title string={`Pay Sheet Grand Totals`} />
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div className={props.onDialogClose ? "container-fluid" : "container-xl"}>
        <div>
          <div className="row">
            <div className="col-md-auto px-1">
              <Label className="mr-2">Pay Period:</Label>
              <PayPeriodDropDownList
                style={{ width: 250 }}
                onSelected={(e) => {
                  setFilterByPayPeriod(e.PayPeriodID);
                }}
                selectedPayPeriodID={filterByPayPeriod}
                includeClosedPayPeriods={true}
                includeOpenPayPeriods={true}
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="px-2" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>);
  };

  if (props.onDialogClose) {
    return <KendoWindow
      initialWidth={675} initialHeight={window.innerHeight * .95}
      title="Pay Sheet Grand Totals"
      resizable
      onClose={props.onDialogClose}
    >
      {dataView()}
    </KendoWindow >
  }

  return dataView();
}

export default PayPeriodGrandTotalSummary;
