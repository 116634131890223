import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { EditableAddress } from '../../../components/EditableAddress';
import { fetchApi } from '../../../services/api';
import { AddressViewModel } from '../../../types/address';
import { isNullOrWhiteSpaceValidator } from '../../../utils/utils';
import DriverAutoComplete from '../../AssetDrivers/DriverAutoComplete';

type Props = {
  CloseDialog: () => void;
}

const CopyDriverDialog = (props: Props) => {
  const [createType, setCreateType] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  // Copy
  const [driver, setDriver] = useState<{ value: number, error: string }>({ value: 0, error: '' });

  // Create
  const [driverNumber, setDriverNumber] = useState({ value: '', error: '' });
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [address, setAddress] = useState<{ value: AddressViewModel, error: string }>({
    value: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      State: '',
      ZipCode: '',
      Coordinates: { Latitude: 0, Longitude: 0 }
    }, error: ''
  });
  const [hiredDate, setHiredDate] = useState<{ value: Date | null, error: string }>({ value: null, error: '' });
  const [hiredComment, setHiredComment] = useState({ value: '', error: '' });

  useEffect(() => {
    if (createType == 1)
      setHiredDate({ value: null, error: '' });
    else
      setHiredDate({ value: new Date(), error: '' });
  }, [createType]);

  const _save = () => {

    const driverError = driver.value === 0 && createType === 0 ? 'Please select a driver.' : '';
    const driverNumberError = isNullOrWhiteSpaceValidator(driverNumber.value, 'Please enter a new Driver ID.');
    const firstNameError = createType === 1 ? isNullOrWhiteSpaceValidator(firstName.value, 'Please enter a first name.') : '';
    const lastNameError = createType === 1 ? isNullOrWhiteSpaceValidator(lastName.value, 'Please enter a last name.') : '';
    const addressError = createType === 1 ? isNullOrWhiteSpaceValidator(address.value.AddressLine1, 'Please enter an address.') : '';
    const hiredDateError = !hiredDate.value ? 'Please select a hired date.' : '';
    const hiredCommentError = isNullOrWhiteSpaceValidator(hiredComment.value, 'Please enter a hired comment.');

    if (driverError || driverNumberError || firstNameError || lastNameError || addressError || hiredDateError || hiredCommentError) {
      setDriver({ ...driver, error: driverError });
      setDriverNumber({ ...driverNumber, error: driverNumberError });
      setFirstName({ ...firstName, error: firstNameError });
      setLastName({ ...lastName, error: lastNameError });
      setAddress({ ...address, error: addressError });
      setHiredDate({ ...hiredDate, error: hiredDateError });
      setHiredComment({ ...hiredComment, error: hiredCommentError });
      return;
    }

    setLoading(true);

    const data = {
      CopyDriverID: createType === 0 ? driver.value : null,
      DriverNumber: driverNumber.value,
      FirstName: firstName.value,
      LastName: lastName.value,
      Address: address.value,
      HiredDate: hiredDate.value,
      HiredComment: hiredComment.value,
    }

    fetchApi('/api/Asset/CopyDriver', data, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          var link = response.Links.find(l => l.Name === 'DriverProfile');
          if (link) history.push(link.Link);
          props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      }
      ).catch(() => {
        setLoading(false);
        alert('Unable to copy driver');
      });
  }

  const options = [
    { label: "Copy Driver", value: 0 },
    { label: "Create Driver", value: 1 },
  ];

  return <div className="container-fluid">
    <Dialog title={`${createType === 0 ? 'Copy' : 'Create'} Driver Profile`} onClose={props.CloseDialog} width={400} height={625}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div className="k-form k-form-md p-0">
        <RadioGroup
          value={createType}
          onChange={(e) => setCreateType(e.value)}
          layout="horizontal"
          data={options}
        />
        {createType === 0 && <FieldWrapper>
          <Label>Copy Driver</Label>
          <DriverAutoComplete
            selectedDriverID={driver.value}
            placeholder="Type a driver id to copy"
            includeRecentlyRetiredDrivers={true}
            onSelectedDriver={(e) => setDriver({ value: e?.DriverID || 0, error: '' })}
          />
          <Error>{driver.error}</Error>
        </FieldWrapper>}
        <FieldWrapper>
          <Label>{createType === 0 ? 'New' : ''} Driver ID</Label>
          <Input
            maxLength={10}
            disabled={loading}
            value={driverNumber.value}
            onChange={(e) => setDriverNumber({ value: e.value || '', error: '' })}
          />
          <Error>{driverNumber.error}</Error>
        </FieldWrapper>
        {createType === 1 && <>
          <FieldWrapper>
            <Label>First Name</Label>
            <Input
              maxLength={30}
              disabled={loading}
              value={firstName.value}
              onChange={(e) => setFirstName({ value: e.value || '', error: '' })}
            />
            <Error>{firstName.error}</Error>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Last Name</Label>
            <Input
              maxLength={30}
              disabled={loading}
              value={lastName.value}
              onChange={(e) => setLastName({ value: e.value || '', error: '' })}
            />
            <Error>{lastName.error}</Error>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Home Address</Label>
            <EditableAddress
              data={address.value}
              title="Home Address"
              save={async (e) => setAddress({ value: e, error: '' })}
            />
            <Error>{address.error}</Error>
          </FieldWrapper>
        </>}
        <FieldWrapper>
          <Label>Hired Date</Label>
          <DatePicker
            value={hiredDate.value}
            onChange={(e) => setHiredDate({ value: e.value || null, error: '' })}
          />
          <Error>{hiredDate.error}</Error>
        </FieldWrapper>
        <FieldWrapper>
          <Label>Hired Comment</Label>
          <Input
            maxLength={200}
            disabled={loading}
            value={hiredComment.value}
            onChange={(e) => setHiredComment({ value: e.value || '', error: '' })}
          />
          <Error>{hiredComment.error}</Error>
        </FieldWrapper>
      </div>

      <DialogActionsBar>
        <Button onClick={props.CloseDialog}>Cancel</Button>
        <Button themeColor="primary" onClick={_save}>{createType === 0 ? 'Copy' : 'Create'} Driver</Button>
      </DialogActionsBar>
    </Dialog>
  </div>
}

export default CopyDriverDialog;

