import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {  useState } from "react";
import Moment from "moment-timezone";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";

type Props = {
  Loading: boolean;
  FirstScheduleDateTime: string;
  onClose: () => void;
  save: (firstScheduleDateTime: Date | null, CopyCount: number) => void;
};

const Copy = ({ Loading, FirstScheduleDateTime, onClose, save }: Props) => {
  const [firstScheduleDateTime, setFirstScheduleDateTime] = useState<Date | null>(Moment.utc(FirstScheduleDateTime).toDate());
  const [copyCount, setCopyCount] = useState<number>(1);

  return <Dialog title={`Copy Order`} onClose={onClose} className="dialog-w525">
    <NumericTextBox
      min={1}
      max={10}
      value={copyCount}
      onChange={(e) => setCopyCount(e.value)}
      width="100%"
      label="Copy Count"
    />
    <DateTimePicker
      label="First Scheduled Pickup"
      value={firstScheduleDateTime}
      onChange={(e) => setFirstScheduleDateTime(e.value)}
      valid={!firstScheduleDateTime ? false : true}
      format="MM/dd/yyyy HH:mm"
      formatPlaceholder="formatPattern"
      width="100%"
    />
    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button themeColor="primary" onClick={() => save(firstScheduleDateTime, copyCount)} disabled={Loading}>Copy</Button>
    </DialogActionsBar>
  </Dialog>
}

export default Copy;