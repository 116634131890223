import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import SimpleDatePicker from "components/SimpleDatePicker";
import { Checkbox } from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";

type Props = {
  loading: boolean;
  onClose: () => void;
  save: (bolIn: boolean, podIn: boolean, date: Date) => void;
};

export const CheckInPaperwork = ({ loading, onClose, save }: Props) => {
  const [podIn, setPodIn] = useState(true);
  const [bolIn, setBolIn] = useState(true);
  const [date, setDate] = useState(new Date());

  return <Dialog title={`Check In Paperwork`} onClose={onClose} className="dialog-w525">
    <FieldWrapper className="mb-1">
      <Checkbox
        value={podIn}
        onChange={(e) => setPodIn(e.value)}
        label="Mark Bill Of Lading In?"
      />
    </FieldWrapper>
    <FieldWrapper className="mb-1">
      <Checkbox
        value={bolIn}
        onChange={(e) => setBolIn(e.value)}
        label="Mark Proof Of Delivery In?"
      />
    </FieldWrapper>
    <FieldWrapper className="mb-1">
      <SimpleDatePicker
        inputProps={{ className: "w-100" }}
        value={date}
        onChange={(e) => setDate(e)}
      />
    </FieldWrapper>
    <DialogActionsBar layout="end">
      <Button onClick={onClose}>Close</Button>
      <Button
        themeColor="primary"
        disabled={loading}
        onClick={() => save(bolIn, podIn, date)}
      >
        Check In
      </Button>
    </DialogActionsBar>
  </Dialog>
}
