import * as React from 'react';
import Moment from 'moment';
import { formatNumber } from '@progress/kendo-intl';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import './Vehicles.css';

type Props = {
  shipmentId: string;
}

type State = {
  loading: boolean;
  vehicles: VehiclesViewModel[]
}

type GetVehiclesResponse = {
  ShipmentID: string;
  Vehicles: VehiclesViewModel[];
}

type VehiclesViewModel = {
    VehicleID: number;
    VehicleNumber: string;

    ComplianceDays: number;
    IsReserved: boolean;
    IsPlanned: boolean;

    Status: string;
    LastAvailable: number;
    LastLoad: number;
    VehicleType: string;
    PayLoad: number;
    BoxLength: number;
    BoxWidth: number;
    BoxHeight: number;
    DoorHeight: number;
    DoorWidth: number;
    DockHigh: boolean;
    LiftGate: boolean;
    Team: boolean;
    VehicleFastApproved: boolean;

    Position: string;
    PositionDateTime: Date;
    Distance: number;

    DispatchNote: string;
    AvailabilityNote: string;

    DriverNumber: string;
    DriverCellPhone1: number;
    DriverHazMatCertified: boolean;
    DriverTSACertified: boolean;
    DriverFastApproved: boolean;
    DriverHome: string;
    HoursOfService: string;
    HoursOfServiceDutyStatus: string;
}

export default class LoadBoardVehicles extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      vehicles: [],
    }

    this.fetch = this.fetch.bind(this);
  }

  public componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
        return <LoadingPanel />
    }

    if (this.state.vehicles.length == 0) {
      return <h3 className="text-center">No Vehicles Found!</h3>
    }

    return this.state.vehicles.map((vehicle, index) => {
      return <div className="form-horizontal" key={index}>
          <div className="sylectus-vehicle">

              <div className="row id-row">
                  <div className="col-md-2 id-col">
                    <div className={vehicle.Status.replace(/ /g,'').toLowerCase()} title={vehicle.Status}>{vehicle.VehicleNumber} ({vehicle.ComplianceDays})</div>
                  </div>
                  <div className="col-md-4 id-col">{vehicle.Position} (<span className="PositionDateTime">{Moment.utc(vehicle.PositionDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</span>)</div>
                  <div className="col-md-6">
                      <button
                        className={`btn ${vehicle.IsReserved ? "btn-danger" : "btn-success"} btn-sm float-right`}
                        onClick={(e) => window.open(`https://www7.sylectus.com/III54_reservetruck.asp?vabcode=${vehicle.VehicleID}`, 'LoadBoard', 'height=400,width=700')}
                      >
                        Reserve
                      </button>
                  </div>
              </div>

              <div className="row equal-height-cards">

                  <div className="col-md-4">
                      <div className="card">
                          <div className="card-header">
                              General
                          </div>
                          <div className="card-body">
                              <div className="form-inline">
                                  <label className="col-sm-4 form-control-label">Driver ID</label>
                                  <div className="col-sm-8">
                                      <p className="form-control-plaintext">{vehicle.DriverNumber} <span title={vehicle.HoursOfServiceDutyStatus}>[{vehicle.HoursOfService}]</span></p>
                                  </div>
                              </div>
                              <div className="form-inline">
                                  <label className="col-sm-4 form-control-label">Driver Cell</label>
                                  <div className="col-sm-8">
                                      <a href={`tel:${vehicle.DriverCellPhone1}`} className="form-control-plaintext">{vehicle.DriverCellPhone1}</a>
                                  </div>
                              </div>
                              <div className="form-inline">
                                  <label className="col-sm-4 form-control-label">Vehicle Type</label>
                                  <div className="col-sm-8">
                                      <p className="form-control-plaintext">{vehicle.VehicleType} ({vehicle.PayLoad} lbs)</p>
                                  </div>
                              </div>
                              {vehicle.DispatchNote &&
                                  <div className="form-inline">
                                      <label className="col-sm-4 form-control-label">Dispatch Note</label>
                                      <div className="col-sm-8">
                                          <p className="form-control-plaintext">{vehicle.DispatchNote}</p>
                                      </div>
                                  </div>
                              }
                              {vehicle.AvailabilityNote &&
                                  <div className="form-inline">
                                      <label className="col-sm-4 form-control-label">Availability Note</label>
                                      <div className="col-sm-8">
                                          <p className="form-control-plaintext">{vehicle.AvailabilityNote}</p>
                                      </div>
                                  </div>
                              }
                              <div className="form-inline">
                                  <label className="col-sm-4 form-control-label">Distance</label>
                                  <div className="col-sm-8">
                                      <p className="form-control-plaintext">{formatNumber(vehicle.Distance, "n0")} miles</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-md-4">
                      <div className="card">
                          <div className="card-header">
                              Dimensions
                          </div>
                          <div className="card-body">
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Box Length</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.BoxLength} in / {formatNumber(vehicle.BoxLength / 12, "n1")} ft </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Door Width</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DoorHeight} in / {formatNumber(vehicle.DoorHeight / 12, "n1")} ft </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Box Width</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.BoxWidth} in / {formatNumber(vehicle.BoxWidth / 12, "n1")} ft </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Door Height</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DoorWidth} in / {formatNumber(vehicle.DoorWidth / 12, "n1")} ft </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Box Height</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.BoxHeight} in / {formatNumber(vehicle.BoxHeight / 12, "n1")} ft </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Dock High</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{(vehicle.DockHigh ? "Yes" : "No")}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-md-4">
                      <div className="card">
                          <div className="card-header">
                              Other
                          </div>
                          <div className="card-body">
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Lift Gate</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{(vehicle.LiftGate ? "Yes" : "No")}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Team</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{(vehicle.Team ? "Yes" : "No")}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Last Available</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.LastAvailable} hrs ago</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Last Load</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.LastLoad} hrs ago</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Vehicle FAST Approved</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.VehicleFastApproved ? "Yes" : "No"}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Driver FAST Approved</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DriverFastApproved ? "Yes" : "No"}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Planned</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.IsPlanned ? <span className='badge badge-warning'>Yes</span> : "No"}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Haz. Certified</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DriverHazMatCertified ? "Yes" : "No"}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">Driver Home</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DriverHome}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-sm-6">
                                      <div className="form-inline">
                                          <label className="col-sm-6 form-control-label">TSA Certified</label>
                                          <div className="col-sm-6">
                                              <p className="form-control-plaintext">{vehicle.DriverTSACertified ? "Yes" : "No"}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    });
  }

  private fetch() {
    fetchApi('/api/LoadBoard/Vehicles', { ShipmentID: this.props.shipmentId }, 'POST')
      .then((response: GetVehiclesResponse) => {
        this.setState({ loading: false, vehicles: response.Vehicles });
      })
  }
}
