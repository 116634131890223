import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { AssetCarrierViewModel } from '../../TypeGen/Assets/Carriers/List/asset-carrier-view-model';

type Props = {
    onSelectedCarrier: (carrier: AssetCarrierViewModel) => void;
    selectedCarrierID?: number;
    setFocus?: boolean;
} & AutoCompleteProps;

export type CarrierAutoCompleteViewModel = AssetCarrierViewModel & { DisplayValue: string; };

export const GetCarrierAutoCompleteDisplayValue = (carrier: AssetCarrierViewModel): string => {
    const displayValue = `${carrier.CarrierName}`;
    //can add additional text here for the label
    return displayValue;
}

const CarrierAutoComplete: React.FC<Props> = ({
    onSelectedCarrier,
    selectedCarrierID,
    setFocus,
    ...autoCompleteProps
}) => {
    const inputAC = useRef<AutoComplete>(null);
    const [loading, setLoading] = useState(false);
    const [carrierDropDownListing, setCarrierDropDownListing] = useState<CarrierAutoCompleteViewModel[]>([]);
    const [carrierAutoCompleteValue, setCarrierAutoCompleteValue] = useState('');
    const _selectedCarrier = useRef(0);
    const [preSelectedCarrierInitialized, setPreSelectedCarrierInitialized] = useState(false);
    const _requestHash = useRef('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: string, requestHash: string) => {
            if (val.length > 2 && _selectedCarrier.current === 0) {
                getCarrierListing(generateDataStateByName(val), requestHash);
            }
        }, 750), []);

    const generateDataStateByName = (searchTerm: string): GridState => {
        return {
            skip: 0,
            take: 12,
            sort: [{
                field: "CarrierName",
                dir: "asc"
            }],
            filter: {
                logic: 'and', filters: [
                    { field: 'CarrierName', operator: 'contains', value: searchTerm },
                    { field: 'IsPreferred', operator: 'eq', value: true },
                    { field: "Active", operator: "eq", value: true }
                ]
            }
        } as GridState;
    }

    const generateDataStateByID = (val: number): GridState => {
        return {
            skip: 0,
            take: 1,
            sort: null,
            filter: {
                logic: 'and', filters: [
                    { field: 'CarrierID', operator: 'eq', value: val }
                ] as any
            }
        } as GridState;
    }

    const getCarrierListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Asset/GetAssetCarriers?${queryStr}`, {}, 'POST')
            .then(({ Data }) => {
                let carrierData: CarrierAutoCompleteViewModel[] = Data;
                setLoading(false);
                if (_requestHash.current === requestHash) {
                    carrierData.forEach(x => {
                        x.DisplayValue = GetCarrierAutoCompleteDisplayValue(x);
                    });
                    setCarrierDropDownListing(carrierData);

                    if (populateLabel) {
                        setCarrierAutoCompleteValue(carrierData[0].CarrierName);
                        setPreSelectedCarrierInitialized(true);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            })
    }, []);

    const onCarrierListingClose = (event: AutoCompleteCloseEvent) => {
        const value = event.target.value;
        const selectedCarrier = carrierDropDownListing.find(x => x.DisplayValue === value);

        setCarrierAutoCompleteValue(selectedCarrier != null ? selectedCarrier.CarrierName : '');
        _selectedCarrier.current = selectedCarrier?.CarrierID;
        onSelectedCarrier(selectedCarrier ?? null);
    };

    useEffect(() => {
        if (selectedCarrierID !== _selectedCarrier.current) {
            if (selectedCarrierID > 0) {
                _selectedCarrier.current = selectedCarrierID;
                _requestHash.current = Math.random().toString(36).substring(7);
                getCarrierListing(generateDataStateByID(selectedCarrierID), _requestHash.current, true);
            } else {
                _selectedCarrier.current = 0;
                setCarrierAutoCompleteValue('');
            }
            if (setFocus) {
                inputAC.current.focus();
            }
        }
    }, [selectedCarrierID, getCarrierListing, setFocus]);

    useEffect(() => {
        if (preSelectedCarrierInitialized) {
            inputAC.current.focus();
            (inputAC.current.element.querySelector('input') as HTMLInputElement).select();
        }
    }, [preSelectedCarrierInitialized])

    return <AutoComplete
        ref={inputAC}
        {...autoCompleteProps}
        placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Carrier')}
        loading={loading}
        data={carrierDropDownListing}
        value={carrierAutoCompleteValue}
        textField="DisplayValue"
        onChange={(e) => {
            _selectedCarrier.current = 0;
            if (!e.value) onSelectedCarrier(null);
            setCarrierDropDownListing([]);
            setCarrierAutoCompleteValue(e.value);
            _requestHash.current = Math.random().toString(36).substring(7);
            debounceSearchInput(e.value, _requestHash.current);
        }}
        onClose={(e) => onCarrierListingClose(e)}
    />
}

export default CarrierAutoComplete;
