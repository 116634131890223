import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "services/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { set } from "lodash";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { JsonResponse } from "TypeGen/json-response";

type Props = {
  PurchaseOrderID: number;
  CloseDialog: (refresh: boolean) => void;
};

type ApplicationUser = {
  ID: string;
  Name: string;
}

const RequestApproval = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<ApplicationUser[]>();
  const [userFilter, setUserFilter] = useState<FilterDescriptor>();
  const [requestedApprovalByUserId, setRequestedApprovalByUserId] = useState<string>();

  const sendEmail = () => {
    setLoading(true);
    fetchApi(`/api/PurchaseOrder/RequestApproval/${props.PurchaseOrderID}`, { RequestedApprovalByUserID: requestedApprovalByUserId }, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          props.CloseDialog(true);
          setRequestedApprovalByUserId(undefined);
          alert('Approval Requested');
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable request approval');
      });
  };

  const refreshUsers = useCallback(() => {
    setLoading(true);

    fetchApi('/api/User/UsersDropdown')
      .then((response: { Users: ApplicationUser[] }) => {
        setUsers(response.Users);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert('Unable to load users');
      });
  }, []);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  return <Dialog title={`Request Approval`} onClose={() => props.CloseDialog(false)} className="dialog-w525">
    <p>This will send a request for approval to the selected user, and after 24 hours if no action is taken it will email their manager.</p>
    <DropDownList
      data={filterBy(users, userFilter)}
      value={users?.find(r => r.ID === requestedApprovalByUserId)}
      textField="Name"
      dataItemKey="ID"
      style={{ width: '100%' }}
      filterable
      onFilterChange={(e) => setUserFilter(e.filter)}
      onChange={(e) => setRequestedApprovalByUserId(e.target.value.ID)}
    />
    <DialogActionsBar layout="end">
      <Button themeColor="primary" disabled={!requestedApprovalByUserId || loading} onClick={sendEmail}>Request Approval</Button>
    </DialogActionsBar>
  </Dialog>
}

export default RequestApproval;