import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchApi } from '../../services/api';
import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { debounce } from 'ts-debounce';
import { DriverValue } from '../Assets/AssetPanel';
import Moment from 'moment-timezone';
import { isNullOrWhiteSpace } from '../../utils/utils';

type Props = {
  onSelectedDriver: (driver: DriverValue) => void;
  selectedDriverID?: number;
  setFocus?: boolean;
  queryUnseatedDriversOnly?: boolean;
  includeRecentlyRetiredDrivers?: boolean;
  hideVehicleNumberAssignment?: boolean;
} & AutoCompleteProps;

export type DriverAutoCompleteViewModel = DriverValue & { DisplayValue: string; };

export const GetDriverAutoCompleteDisplayValue = (driver: DriverValue, includeActiveLabel?: boolean, hideVehicleNumberAssignment?: boolean): string => {
  let customLabel = "";
  if (includeActiveLabel === true)
    customLabel += ` ${driver.Active ? 'Active' : 'Retired'}`;
  if (hideVehicleNumberAssignment === false && isNullOrWhiteSpace(driver.VehicleNumber) === false)
    customLabel += ` ${driver.VehicleNumber}`;

  return `${driver.DriverNumber} (${driver.DriverFullName})${customLabel}`;
}

const DriverAutoComplete: React.FC<Props> = ({
  onSelectedDriver,
  selectedDriverID,
  queryUnseatedDriversOnly,
  includeRecentlyRetiredDrivers,
  hideVehicleNumberAssignment = false,
  setFocus,
  ...autoCompleteProps
}) => {
  const inputAC = useRef<AutoComplete>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [driverDropDownListing, setDriverDropDownListing] = useState<DriverAutoCompleteViewModel[]>([]);
  const [driverAutoCompleteValue, setDriverAutoCompleteValue] = useState('');
  const _selectedDriver = useRef<number>(0);
  const _requestHash = useRef('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((val: string, requestHash: string) => {
      if (val.length > 2 && _selectedDriver.current === 0) {
        getDriverListing(generateDataStateByName(val), requestHash);
      }
    }, 750), []);

  const generateDataStateByName = (val: string): GridState => {
    let gs = {
      skip: 0,
      take: 12,
      sort: [{
        field: "DriverNumber",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          {
            logic: 'or', filters: [
              { field: 'DriverNumber', operator: 'contains', value: val },
              { field: 'DriverFullName', operator: 'contains', value: val }
            ]
          }
        ]
      }
    } as GridState;

    if (queryUnseatedDriversOnly)
      gs.filter.filters.unshift({ field: 'VehicleID', operator: 'isnull' });

    if (includeRecentlyRetiredDrivers) {
      gs.filter.filters.unshift({
        logic: 'or', filters: [
          { field: 'active', operator: 'eq', value: true },
          {
            logic: 'and', filters: [
              { field: 'active', operator: 'eq', value: false },
              { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(6, "months").toDate() }
            ]
          }
        ]
      });
    } else {
      gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
    }
    return gs;
  }

  const generateDataStateByID = (val: number): GridState => {
    return {
      skip: 0,
      take: 1,
      sort: null,
      filter: {
        logic: 'and', filters: [
          { field: 'DriverID', operator: 'eq', value: val }
        ] as any
      }
    } as GridState;
  }

  const getDriverListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
    setLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/Asset/GetAssetDriversDataState?${queryStr}`, {}, 'POST')
      .then(({ Data }) => {
        let driverData: DriverAutoCompleteViewModel[] = Data;
        setLoading(false);
        if (_requestHash.current === requestHash) {
          driverData.forEach(x => {
            x.DisplayValue = GetDriverAutoCompleteDisplayValue(x, includeRecentlyRetiredDrivers, hideVehicleNumberAssignment);
          });
          setDriverDropDownListing(driverData);
          if (populateLabel)
            setDriverAutoCompleteValue(GetDriverAutoCompleteDisplayValue(driverData[0], false, false));
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
  }, [includeRecentlyRetiredDrivers, hideVehicleNumberAssignment]);

  const onDriverListingClose = (event: AutoCompleteCloseEvent) => {
    const value = event.target.value;
    const selectedDriver = driverDropDownListing.find(x => x.DisplayValue === value);
    setDriverAutoCompleteValue(selectedDriver != null ? GetDriverAutoCompleteDisplayValue(selectedDriver) : '');
    _selectedDriver.current = selectedDriver?.DriverID;
    onSelectedDriver(selectedDriver ?? null);
  };

  useEffect(() => {
    if (selectedDriverID !== _selectedDriver.current) {
      if (selectedDriverID > 0) {
        _selectedDriver.current = selectedDriverID;
        _requestHash.current = Math.random().toString(36).substring(7);
        getDriverListing(generateDataStateByID(selectedDriverID), _requestHash.current, true);
      } else {
        _selectedDriver.current = 0;
        setDriverAutoCompleteValue('');
      }
      if (setFocus) {
        inputAC.current.focus();
      }
    }
  }, [selectedDriverID, getDriverListing, setFocus]);

  return <AutoComplete
    ref={inputAC}
    {...autoCompleteProps}
    placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Driver')}
    loading={loading}
    data={driverDropDownListing}
    value={driverAutoCompleteValue}
    textField="DisplayValue"
    onChange={(e) => {
      _selectedDriver.current = 0;
      if (!e.value) onSelectedDriver(null);
      setDriverDropDownListing([]);
      setDriverAutoCompleteValue(e.value);
      _requestHash.current = Math.random().toString(36).substring(7);
      debounceSearchInput(e.value, _requestHash.current);
    }}
    onClose={(e) => onDriverListingClose(e)}
  />
}

export default DriverAutoComplete;
