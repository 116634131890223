export interface ISingleSearchResponse {
  Err: number;
  Locations: ISingleSearchLocation[];
}

export interface ISingleSearchLocation {
  Address: { City: string, State: string, Zip: string };
  Coords: { Lat: string, Lon: string };
  ShortString: string;
}

export const singleSearch = (searchTerm: string): Promise<ISingleSearchResponse> => {
  const key = '87A1A88FC4D1F14CB0FAC557C2C792FF';
  return fetch(`https://singlesearch.alk.com/NA/api/search?query=${searchTerm}&maxResults=10&excludeResultsFor=Street,RouteNumber,RouteAlpha,POI,POIStreet,POIType,CrossStreet,LatLon,CustomPlace&authToken=${key}`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      throw new Error(`${response.status}`);
    });
};
