import { IControl, Point, PointLike, Popup, Route } from "@trimblemaps/trimblemaps-js";
import * as DOM from '../../utils/dom';
import { fetchApi } from "services/api";
import { Geometry } from "@trimblemaps/trimblemaps-js/geojson";
import "./TrimbleMapBookedControl.css";

export type BookedControlOptions = {
  coordinates: { Latitude: number, Longitude: number };
  vehicleTypeId?: number;
  route?: Route;
}

class TrimbleMapBookedControl implements IControl {
  _map: TrimbleMaps.Map;
  _container: HTMLElement;
  _menu: HTMLElement;
  _button: HTMLButtonElement;
  _applyButton: HTMLButtonElement;
  _showing: boolean;
  _radius: number = 100;
  options: BookedControlOptions;

  constructor(options: BookedControlOptions) {
    this.options = options;

    this._onClick = this._onClick.bind(this);
  }

  onAdd(map: TrimbleMaps.Map): HTMLElement {
    this._map = map;
    this._container = DOM.create('div', 'trimblemaps-ctrl trimblemaps-ctrl-group trimblemaps-menu-ctrl', map.getContainer());

    // Add button
    const button = this._button = DOM.create('button', 'trimblemaps-ctrl-icon trimblemaps-loadmap-button', this._container) as HTMLButtonElement;
    button.type = 'button';
    DOM.create('span', `trimblemaps-ctrl-icon`, button).setAttribute('aria-hidden', 'true');
    if (this._button.firstElementChild) this._button.firstElementChild.setAttribute('title', "Recently Booked");
    this._button.addEventListener('click', this._onClick);

    // Add container
    this._menu = DOM.create('div', 'trimblemaps-menu-container trimblemaps-menu-loadmap', this._container);
    DOM.create('div', 'trimblemaps-menu-arrow', this._menu);
    DOM.create('h5', 'trimblemaps-menu-title', this._menu).textContent = 'Booked - 7 Days';
    DOM.create('div', 'trimblemaps-menu-divider', this._menu);
    const menuContent = DOM.create('div', 'trimblemaps-menu-content pt-2 px-2', this._menu);
    const radiusSelect = DOM.create('select', 'trimblemaps-menu-select custom-select w-100', menuContent) as HTMLSelectElement;
    {
      const option = DOM.create('option', null, radiusSelect) as HTMLOptionElement;
      option.textContent = '100 mile radius';
      option.value = '100';
      option.selected = option.value === this._radius.toString();
    }
    {
      const option = DOM.create('option', null, radiusSelect) as HTMLOptionElement;
      option.textContent = '200 mile radius';
      option.value = '200';
      option.selected = option.value === this._radius.toString();
    }
    {
      const option = DOM.create('option', null, radiusSelect) as HTMLOptionElement;
      option.textContent = '300 mile radius';
      option.value = '300';
    }
    {
      const option = DOM.create('option', null, radiusSelect) as HTMLOptionElement;
      option.textContent = 'All booked';
      option.value = '0';
    }

    radiusSelect.addEventListener('change', () => {
      this._radius = parseInt(radiusSelect.value);
      this._cleanUp();
      this._onApply();
    });

    this._applyButton = DOM.create('button', 'trimblemaps-menu-button btn btn-link w-100', menuContent) as HTMLButtonElement;
    this._applyButton.textContent = 'Apply';
    this._applyButton.addEventListener('click', this._onToggle);

    this._map.on('click', (e: TrimbleMaps.MapMouseEvent) => {
      if (!this._showing) return;

      // Set `bbox` as 5px reactangle area around clicked point.
      const bbox = [
          [e.point.x - 5, e.point.y - 5] as PointLike,
          [e.point.x + 5, e.point.y + 5] as PointLike
      ] as [Point, Point];
      // Find points intersecting the bounding box.
      const unclusteredPoints = map.queryRenderedFeatures(bbox, {
          layers: ['unclusteredPoints']
      });
      if (unclusteredPoints.length) {
        const unclusteredPoint = unclusteredPoints[0];

        // Create a popup at the clicked location
        new Popup()
          .setLngLat((unclusteredPoint.geometry as any).coordinates)
          .setText(unclusteredPoint.properties.label)
          .addTo(map);

        return;
      }

      const clusteredPoints = map.queryRenderedFeatures(bbox, {
          layers: ['clusteredPoints']
      });
      if (clusteredPoints.length) {
        map.easeTo({
          center: (clusteredPoints[0].geometry as any).coordinates,
          zoom: map.getZoom() + 1
        });

        return;
      }
    });

    // Remove any existing layers
    this._cleanUp();

    return this._container;
  }

  onRemove() {
    if (!this._container) return;

    this._cleanUp();
    this._container.remove();
    this._map = (undefined as any);
  }

  _cleanUp() {
    this._map.getLayer('unclusteredPoints') && this._map.removeLayer('unclusteredPoints');
    this._map.getLayer('clusterCount') && this._map.removeLayer('clusterCount');
    this._map.getLayer('clusteredPoints') && this._map.removeLayer('clusteredPoints');
    this._map.getSource('bookedPoints') && this._map.removeSource('bookedPoints');
  }

  _onClick = () => {

    // Skip if no vehicle type
    if (!this.options.vehicleTypeId) return;

    // Open/close menu
    this._menu.classList.toggle('trimblemaps-menu-container-open');
  }

  _onToggle = () => {
    if (this._showing) {
      this._showing = false;
      this._button.classList.remove('trimblemaps-loadmap-button-visible');
      this._applyButton.textContent = 'Apply';
      this._cleanUp();

      // Reframe route layer
      this.options.route?.frame();
    } else {
      this._showing = true;
      this._button.classList.add('trimblemaps-loadmap-button-visible');
      this._applyButton.textContent = 'Clear';
      this._onApply();
    }
  }

  _onApply = () => {
    if (!this._showing) return;

    fetchApi('/api/Quote/RecentBooked', {
      VehicleTypeID: this.options.vehicleTypeId,
      ...this.options.coordinates,
      Radius: this._radius,
    }, 'POST')
    .then((data: Array<{ Latitude: number, Longitude: number, Label: string }>) => {
      const features = data.map((d) => ({
        type: 'Feature' as 'Feature',
        properties: {
          label: d.Label
        },
        geometry: {
          type: 'Point',
          coordinates: [d.Longitude, d.Latitude]
        } as Geometry
      }));

      // Use cluster properties on datasource to group data
      this._map.addSource('bookedPoints', {
        type: 'geojson',
        cluster: true,
        clusterRadius: 40,
        clusterMaxZoom: 14,
        data: {
          type: 'FeatureCollection',
          features: features
        }
      });

      this._map.addLayer({
        id: 'clusteredPoints',
        type: 'circle',
        source: 'bookedPoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15, 5,
            20, 50,
            25
          ],
          'circle-color': '#088000',
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 3
        }
      });

      // Show count for clustered points
      this._map.addLayer({
        id: 'clusterCount',
        type: 'symbol',
        source: 'bookedPoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          'text-font': ['Roboto Regular'],
          'text-size': 12
        },
        paint: {
          'text-color': '#FFF'
        }
      });

      // Use filter to show unclustered points
      this._map.addLayer({
        id: 'unclusteredPoints',
        type: 'circle',
        source: 'bookedPoints',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-radius': 10,
          'circle-color': '#088000',
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 2
        }
      });
    });
  }
}

export default TrimbleMapBookedControl;