import Moment from 'moment-timezone';
import { GridCellProps } from "@progress/kendo-react-grid";

const LocalDateOnlyCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const date = props.dataItem[props.field];

  if (!date) return <td>N/A</td>;

  return (
    <td>
      {Moment(date).format("MM/DD/YYYY")}
    </td>
  );
}

export default LocalDateOnlyCell;
