import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchApi } from "../../services/api";
import { ILink } from '../../types/link';
import { JsonResponse } from "TypeGen/json-response";
import { SvgIcon } from "@progress/kendo-react-common";
import { chevronRightIcon, hyperlinkOpenIcon } from "@progress/kendo-svg-icons";

type Props = {
    id: string | number;
    currentTab: "Profile" | "Notes" | "Devices" | "Logs";
}

export const OwnerNavBar = (props: Props) => {

    const [number, setNumber] = useState("");
    const [links, setLinks] = useState<ILink[]>([]);
    const [showRetirePopup, setShowRetirePopup] = useState<boolean>(false);

    const refresh = (ownerId: string | number) => {
        setLinks([]);
        fetchApi(`/api/Asset/OwnerNavBar/${ownerId}`)
            .then((data: { Number: string, Links: ILink[] }) => {
                setNumber(data.Number);
                setLinks(data.Links);
            });
    }

    const reactivate = () => {
        if (!window.confirm("Are you sure you want to reactivate this owner?")) {
            return;
        }

        fetchApi(`/api/Asset/ReactivateOwner/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to reactivate");
            });
    }

    const portal = () => {
        fetchApi(`/api/Asset/ImpersonateOwner/${props.id}`, {}, 'POST')
        .then((response: JsonResponse) => {
            if (response.Success) {
                window.open(response.Links[0].Link, '_blank');
            } else {
                alert(response.ErrorMessage);
            }
        })
        .catch(() => {
            alert("Unable to login fleet portal");
        });
    }

    const retire = () => {
        if (!window.confirm('Retire Owner: Are you sure?')) {
            return;
        }

        fetchApi(`/api/Asset/RetireOwner/${props.id}`, {}, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    refresh(props.id);
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert("Unable to retire");
            });
    }

    useEffect(() => {
        refresh(props.id);
    }, [props.id]);

    return <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mt-3">
            <span className="navbar-brand">
                <Link to="/Assets/Owners">Owners</Link>
                <span className="text-muted">
                    <SvgIcon icon={chevronRightIcon} />
                    {number}
                </span>
            </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#driverNav" aria-controls="driverNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="driverNav">
                <ul className="navbar-nav">
                    {links.find((x: ILink) => x.Name === 'Owner') && <li className={`nav-item ${props.currentTab === 'Profile' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Owner/${props.id}`}>Profile</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Devices') && <li className={`nav-item ${props.currentTab === 'Devices' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Owner/${props.id}/Devices`}>Devices</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Notes') && <li className={`nav-item ${props.currentTab === 'Notes' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/Owner/${props.id}/Notes`}>Notes</Link>
                    </li>}
                    {links.find((x: ILink) => x.Name === 'Logs') && <li className={`nav-item ${props.currentTab === 'Logs' ? "active" : ""}`}>
                        <Link className="nav-link" to={`/Assets/ResourceLogs/${props.id}/4`}>Logs</Link>
                    </li>}
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    {links.find((x: ILink) => x.Name === 'Impersonate') && <Button icon="hyperlink-open" svgIcon={hyperlinkOpenIcon} themeColor="secondary" className="mr-2" onClick={portal}>Portal</Button>}
                    {links.find((x: ILink) => x.Name === 'Retire') && <Button onClick={retire} themeColor="error">Retire</Button>}
                    {links.find((x: ILink) => x.Name === 'ReHire') && <Button onClick={reactivate} themeColor="primary">Reactivate</Button>}
                </ul>
            </div>
        </nav>
    </>;
}