import * as React from 'react';
import { AutoComplete, AutoCompleteChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { EnterStopViewModel } from '.';
import { singleSearch, ISingleSearchLocation } from '../../services/pcmiler';
import moment from 'moment';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import SimpleTimePicker from '../../components/SimpleTimePicker';
import timezoneFormatter from '../../services/formatting/timezone';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

type State = {
    isLoading: boolean;
    validationRequestHash: string,
    options: ISingleSearchLocation[];
}

type Props = {
    stop: EnterStopViewModel;
    index: number;
    timezone: string;
    onChange: (newStop: EnterStopViewModel, changeType: string) => void;
};

class EnterStop extends React.Component<Props, State> {

    private timeout: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            validationRequestHash: '',
            options: [],
        }

        this.updateDate = this.updateDate.bind(this);
        this.updateTime = this.updateTime.bind(this);
        this.updateDateTime = this.updateDateTime.bind(this);
    }

    itemRenderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
        return React.cloneElement(li, li.props, itemChildren);
    }

    render() {
        const dateTime = moment(this.props.stop.DateTime);

        return (
            <div className="form-row" key={this.props.index}>
                <div className="form-group col-md-4">
                    <div className="input-group">
                        <AutoComplete
                            data={this.state.options}
                            value={this.props.stop.Location}
                            style={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            loading={this.state.isLoading}
                            placeholder='Enter Location'
                            itemRender={this.itemRenderLocation}
                            onChange={(event: AutoCompleteChangeEvent) => {

                                // Update Location
                                let location = event.target.value;
                                if (typeof event.target.value === 'object') {
                                    const place = event.target.value as any;
                                    location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                                }
                                this.updateStop({ Location: location }, (this.props.index == 0) ? "FIRST_STOP_LOCATION" : "STOP_LOCATION")

                                // Update Autocomplete Suggestions
                                if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                                    clearTimeout(this.timeout);
                                    this.timeout = setTimeout(() => {
                                        const requestHash = Math.random().toString(36).substring(7);
                                        this.setState({
                                            isLoading: true,
                                            validationRequestHash: requestHash
                                        });
                                        singleSearch(event.target.value)
                                            .then((response) => {
                                                if (this.state.validationRequestHash === requestHash) {
                                                    this.setState({ isLoading: false, options: response.Locations });
                                                }
                                            })
                                            .catch(() => {
                                                if (this.state.validationRequestHash === requestHash) {
                                                    this.setState({ isLoading: false, options: [] });
                                                }
                                            })
                                    }, 350);
                                }
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">{timezoneFormatter(this.props.timezone)}</span>
                        </div>
                    </div>
                </div>
                {this.props.index == 0 &&
                    <React.Fragment>
                        <div className="form-group col-md-3">
                            <SimpleDatePicker
                                inputProps={{
                                prefix: () => <InputPrefix>
                                    <Button disabled>EST</Button>
                                </InputPrefix>
                                }}
                                value={dateTime.toDate()}
                                onChange={this.updateDate}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <SimpleTimePicker
                                value={dateTime.toDate()}
                                onChange={this.updateTime}
                            />
                        </div>
                    </React.Fragment>}
            </div>
        );
    }

    private updateStop(value: Partial<EnterStopViewModel>, changeType: string) {
        const newStop = Object.assign({}, this.props.stop, value);
        this.props.onChange(newStop, changeType);
    }

    private updateDate(date: Date | null) {
        if (date == null) return;
        this.updateDateTime(date ? moment(`${date.toDateString()} ${new Date(this.props.stop.DateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
    }

    private updateTime(time: Date | null) {
        if (time == null) return;
        this.updateDateTime(time ? moment(`${new Date(this.props.stop.DateTime).toDateString()} ${time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`) : null);
    }

    private updateDateTime(value: moment.Moment | null) {
        if (value == null) {
          this.updateStop({ DateTime: undefined }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
          return;
        }
        const newDateTime = value.format("MM/DD/YYYY HH:mm");
        if (this.props.stop.DateTime !== newDateTime) {
            this.updateStop({ DateTime: newDateTime }, (this.props.index == 0) ? "FIRST_STOP_DATETIME" : "STOP_DATETIME");
        }
    }
}

export default EnterStop;
