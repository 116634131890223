import * as React from 'react';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { DriverValue, SelectedRowValues } from '../AssetPanel';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
    SelectedRowValues: SelectedRowValues,
    SelectedDrivers: DriverValue[] | null
    CloseDialog: () => void;
}

interface State {
    isLoading: boolean;
    DocumentType: number;
    sentToEntityButtonLabel: string;
}

export class DriverRequestSignature extends React.Component<Props, State> {

    private documentTypes = [
        { value: 0, text: 'Select Document Type' },
        { value: 1, text: 'FMCSA Drug and Alcohol Clearinghouse Consent' },
        { value: 3, text: 'Annual Driver\'s Certificate of Violations' },
      ]

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            DocumentType: 0,
            sentToEntityButtonLabel: ''
        }

        this.request = this.request.bind(this);
    }

    public componentWillMount() {
        if (this.props.SelectedDrivers.length > 1) {
            this.setState({
                sentToEntityButtonLabel: "All Selected (" + this.props.SelectedDrivers.length + ")",
            });
        } else {
            if (this.props.SelectedRowValues.DriverNumber.trim().length > 0 && this.props.SelectedRowValues.VehicleNumber.trim().length > 0) {
                this.setState({
                    sentToEntityButtonLabel: this.props.SelectedRowValues.DriverNumber + "/" + this.props.SelectedRowValues.VehicleNumber,
                });
            } else {
                let sentToEntityButtonLabel: string = "";
                if (this.props.SelectedRowValues.DriverNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.DriverNumber;
                if (this.props.SelectedRowValues.VehicleNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.VehicleNumber;
                this.setState({
                    sentToEntityButtonLabel: sentToEntityButtonLabel
                });
            }
        }
    }

    public render() {
        return (
            <Dialog title="Request Driver(s) Signature" onClose={this.props.CloseDialog} width={1024}>
                {this.state.isLoading && <LoadingPanel />}
                <DropDownList
                    style={{ width: '100%' }}
                    defaultValue={0}
                    value={this.documentTypes.find(x => x.value == this.state.DocumentType)}
                    data={this.documentTypes}
                    textField="text"
                    onChange={(e) => this.setState({ DocumentType: e.target.value.value })}
                />
                <DialogActionsBar layout="end">
                    <Button
                        onClick={(e) => {
                            this.props.CloseDialog();
                            e.preventDefault();
                        }}>Cancel</Button>
                    <Button themeColor="primary" onClick={this.request} disabled={this.state.DocumentType === 0}>
                        Send to {this.state.sentToEntityButtonLabel}
                    </Button>
                </DialogActionsBar>
            </Dialog>
        );
    }

    private request() {

        if (this.state.isLoading) return;

        this.setState({
            isLoading: true
        });

        let driverIds: number[] = [];
        if (this.props.SelectedDrivers.length > 0) {
            this.props.SelectedDrivers.forEach(function (item) {
                driverIds.push(item.DriverID);
            });
        }else {
            if (this.props.SelectedRowValues.DriverID > 0)
                driverIds.push(this.props.SelectedRowValues.DriverID);
        }

        const data = {
            DriverIDs: driverIds,
            DocumentType: this.state.DocumentType,
        };

        fetchApi('/api/Asset/DriverRequestSignature', data, 'POST')
            .then(() => {
                this.props.CloseDialog();
            }).catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    }
}
