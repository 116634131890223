import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { Trip } from '.';
import CustomerProfile from '../Customers/Profile';

const CustomerCell = (props: GridCellProps) => {
    const [showCustomerProfileDialog, setShowCustomerProfileDialog] = React.useState(false);

    if (!props.field || props.rowType !== 'data') return null;

    const dataItem = props.dataItem as Trip;

    return (
        <td>
            {showCustomerProfileDialog && <CustomerProfile customerId={Number(dataItem.AuthorizationCustomerID)} isDialog={true} onClose={() => setShowCustomerProfileDialog(false)} />}
            <a
                href="#"
                style={{ color: '#007bff' }}
                onClick={(e) => {
                    setShowCustomerProfileDialog(true);
                    e.preventDefault();
                }}
            >
                {dataItem.AuthorizationCustomerNumber}
            </a>
            <br />
            <a href={`tel:${dataItem.CarrierPhone}`}>{dataItem.CarrierPhone}</a>
        </td>
    );
}

export default React.memo(CustomerCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
