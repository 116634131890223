import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from '@progress/kendo-react-labels';
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { fetchApi } from "services/api";
import { OrderContext } from "./Details";
import { CreatingField } from "components/CreatingField";
import { EditableField } from "components/EditableField";
import CarrierAutoComplete from "views/AssetCarriers/CarrierAutoComplete";
import { IDNameViewModel } from "TypeGen/id-name-view-model";
import { ApiLink } from "TypeGen/api-link";
import { JsonResponse } from "TypeGen/json-response";
import { ViewModel } from "TypeGen/Order/ServiceException/view-model";
import { ServiceException } from "TypeGen/Order/ServiceException/service-exception";
import { ServiceExceptionNote } from "TypeGen/Order/ServiceException/service-exception-note";
import { AuditInfoViewModel } from "TypeGen/Order/ServiceException/audit-info-view-model";
import NoteEditUI from "./Notes/Edit";

type RouteComponentParams = {
  orderId: string;
  orderServiceExceptionId: string;
};

type Props = {
  orderId?: number;
  orderServiceExceptionId?: number;
  onClose?: () => void;
  children?: React.ReactNode
};

const OrderServiceException: React.FC<Props> = props => {
  const { orderId: orderIdParam, orderServiceExceptionId: orderServiceExceptionIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [orderServiceExceptionId, setOrderServiceExceptionId] = useState(0);
  const [orderServiceException, setOrderServiceException] = useState<ServiceException>();
  const [notes, setNotes] = useState<ServiceExceptionNote[]>([]);
  const [note, setNote] = useState<ServiceExceptionNote>();
  const [failureReasons, setFailureReasons] = useState<IDNameViewModel[]>([]);
  const [auditInfo, setAuditInfo] = useState<AuditInfoViewModel>();
  const [links, setLinks] = useState<ApiLink[]>([]);

  const refreshOrderServiceException = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/ServiceException/${orderId}/${orderServiceExceptionId}`)
      .then((response: ViewModel) => {
        setOrderServiceException(response.ServiceException);
        setNotes(response.Notes);
        setFailureReasons(response.FailureReasons);
        setAuditInfo(response.AuditInfo);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderId, orderServiceExceptionId]);

  useEffect(() => {
    if (props.orderId > 0) {
      setOrderId(props.orderId);
      setOrderServiceExceptionId(props.orderServiceExceptionId);
    }
    else {
      setOrderId(Number(orderIdParam));
      setOrderServiceExceptionId(Number(orderServiceExceptionIdParam));
    }
  }, [orderIdParam, orderServiceExceptionIdParam, props.orderId, props.orderServiceExceptionId]);

  useEffect(() => {
    setActiveStopIds([]);
    setDeleteStopIds([]);
    if (orderId > 0) {
        refreshOrderServiceException();
    }
  }, [orderId, refreshOrderServiceException, setActiveStopIds, setDeleteStopIds]);

  const save = () => {
    setLoading(true);
    const command = links.find(l => l.Name === 'Save');
    fetchApi(command.Link, orderServiceException, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setSaved(true);
          setTimeout(() => setSaved(false), 2000);
          refreshOrderServiceException();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to save order service exception');
      })
      .finally(() => setLoading(false));
  }

  const newNote = () => {
    setNote({
      OrderNoteID: 0,
      CreatedDateTime: '',
      CreatedByFullName: '',
      Note: '',
    });
  }

  const addNote = (note: ServiceExceptionNote) => {
    const link = links.find(l => l.Name === 'AddNote');
    setLoading(true);
    fetchApi(link.Link, note, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          setNote(undefined);
          refreshOrderServiceException();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  if (loading || !orderServiceException) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Carrier Resposible</Label>
        <div className="input-group">
          <CarrierAutoComplete
            disabled
            selectedCarrierID={orderServiceException.CarrierID}
            onSelectedCarrier={(e) => {
              setOrderServiceException({
                ...orderServiceException,
                CarrierID: e.CarrierID
              });
            }}
          />
        </div>
      </div>
      <div className="form-group col-md-4">
      <Label>Type</Label>
        <CreatingField
          data={orderServiceException.FailureReasonID}
          renderEditable={(data, onChange) => <select className="custom-select" value={data} onChange={(e) => onChange(parseInt(e.target.value))}>
            {failureReasons.map(({ ID, Name }) => <option key={ID} value={ID}>{Name}</option>)}
          </select>}
          onChange={(data) => {
            setOrderServiceException({
              ...orderServiceException,
              FailureReasonID: data
            });
          }}
        />
      </div>
      <div className="form-group col-md-4">
        <Label>Exclude From Reporting</Label>
        <CreatingField
          dataType="boolean"
          data={orderServiceException.ExcludeFromReporting}
          onChange={(e) => {
            setOrderServiceException({
              ...orderServiceException,
              ExcludeFromReporting: e
            });
          }}
        />
      </div>
    </div>
    <div className="row">
      <div className="form-group col-md-4">
        <Label>Created</Label>
        <EditableField
          readOnly
          dataType="string"
          data={`${auditInfo.CreatedByUserName} - ${Moment.utc(auditInfo.CreatedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}`}
          save={() => Promise.resolve()}
        />
        <p className="form-control-plaintext"></p>
      </div>
      {auditInfo.ModifiedDateTime && <div className="form-group col-md-4">
        <Label>Updated</Label>
        <EditableField
          readOnly
          dataType="string"
          data={`${auditInfo.ModifiedByUserName} - ${Moment.utc(auditInfo.ModifiedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm:ss')}`}
          save={() => Promise.resolve()}
        />
      </div>}
    </div>
    {note && <NoteEditUI
      loading={loading}
      orderNote={note}
      cancel={() => setNote(undefined)}
      noteType="Service Exception"
      save={addNote}
    />}
    {notes.length === 0
      ? <h4 className="text-center">No Service Exception Notes.</h4>
      : <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Note</th>
            <th>Modified By</th>
            <th>Modified Date</th>
          </tr>
        </thead>
        <tbody>
          {notes.map(note => {
            return <tr key={note.OrderNoteID}>
              <td>
                {note.Note}
              </td>
              <td>{note.CreatedByFullName}</td>
              <td>{Moment.utc(note.CreatedDateTime).local().format('MM/DD/YYYY HH:mm')}</td>
            </tr>
          })}
        </tbody>
      </table>}
    <div className="text-center mt-3">
      <Button onClick={newNote} disabled={loading} className='mr-2'>Add Note</Button>
      <Button themeColor={saved ? "success" : "primary"} onClick={save} disabled={loading}>Save Service Exception</Button>
    </div>
  </div>;
}

export default OrderServiceException;