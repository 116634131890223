import * as React from 'react';
import { openWindow } from '../../services/openWindow';
import { OfferValue } from './Vehicles';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';

type Props = {
  quoteID: number;
  driver: OfferValue;
};

type State = {}

export default class DriverOfferButton extends React.Component<Props, State> {
  public render() {

    if (this.props.driver.Status == 0) {
      return <Button size="small" themeColor="info" onClick={() => {
        openWindow(`/Assets/Driver/${this.props.driver.ID}/Turndowns`);
      }}>
        Offered
      </Button>
    }

    if (this.props.driver.Status == 1) {
      return <Button size="small" themeColor="error" onClick={() => {
        openWindow(`/Assets/Driver/${this.props.driver.ID}/Turndowns`);
      }}>
        Declined
      </Button>
    }

    if (this.props.driver.Status == 3) {
      return <Button size="small" themeColor="warning" onClick={() => {
        openWindow(`/Assets/Driver/${this.props.driver.ID}/Turndowns`);
      }}>
        Expired
      </Button>
    }

    if (this.props.driver.Status == 2) {
      return  <Button size="small" themeColor="success" onClick={() => {
        openWindow("/Assets/Driver/" + this.props.driver.ID);
      }}>
        {formatNumber(this.props.driver.AllInRate, "c")}
      </Button>
    }
  }
}
