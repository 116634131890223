import { Popup } from '@progress/kendo-react-popup';
import { GridCellProps } from "@progress/kendo-react-grid";
import * as React from 'react';
import { SelectedCarrierValue } from './CarrierGrid';
import { MenuOption } from './CarrierGrid';
import OutsideAlerter from '../../components/OutsideAlerter';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { Button } from '@progress/kendo-react-buttons';
import { gearIcon } from '@progress/kendo-svg-icons';

export default function carrierActionPopupCell(
    CarrierData: AssetCarrierViewModel[],
    LoadDialogScreen: (SelectedCarrier: SelectedCarrierValue, SelectedMenuOption: MenuOption) => void,
    LoadSendMessageDialogScreen: (CarrierID: number) => void) {
    return class ActionPopup extends React.Component<GridCellProps> {

        private showMenu: boolean;
        private selectedCarriers = CarrierData.filter(x => x.Selected);
        private anchor: React.RefObject<Button>;

        constructor(props: GridCellProps) {
            super(props);

            this.anchor = React.createRef();

            this.showMenu = false;
        }

        private getSelectedCarrierValue(carrier: AssetCarrierViewModel): SelectedCarrierValue {
            return {
                CarrierID: carrier.CarrierID,
                CarrierName: carrier.CarrierName,
                Phone1: carrier.Phone1,
            } as SelectedCarrierValue
        }

        render() {
            const dataItem = this.props.dataItem as AssetCarrierViewModel;
            return (
                <td>
                    <Button
                        togglable
                        selected={this.showMenu}
                        size="small"
                        svgIcon={gearIcon}
                        onClick={(e) => {
                            this.showMenu = !this.showMenu;
                            this.forceUpdate();
                            e.preventDefault();
                        }}
                        ref={this.anchor}
                    />
                    <div>
                        {this.anchor.current && <Popup anchor={this.anchor.current.element} show={this.showMenu} popupClass={'popup-content'}>
                            <OutsideAlerter toggle={() => { this.showMenu = !this.showMenu; this.forceUpdate(); }} style={{ display: 'contents' }}>
                                <a
                                    href="#"
                                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                                    onClick={(e) => {
                                        this.showMenu = false;
                                        LoadSendMessageDialogScreen(dataItem.CarrierID);
                                        e.preventDefault();
                                        this.forceUpdate();
                                    }}
                                >Send E-Mail Message</a>
                                {this.selectedCarriers.length < 2 && <a
                                    href="#"
                                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                                    onClick={(e) => {
                                        this.showMenu = false;
                                        LoadDialogScreen(this.getSelectedCarrierValue(dataItem), MenuOption.SupportedVehicles);
                                        e.preventDefault();
                                        this.forceUpdate();
                                    }}
                                >Update Supported Vehicle Types</a>}
                                {this.selectedCarriers.length < 2 && <a
                                    href="#"
                                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                                    onClick={(e) => {
                                        this.showMenu = false;
                                        LoadDialogScreen(this.getSelectedCarrierValue(dataItem), MenuOption.Documents);
                                        e.preventDefault();
                                        this.forceUpdate();
                                    }}
                                >Documents</a>}
                                {this.selectedCarriers.length < 2 && <a
                                    href="#"
                                    style={{ color: '#007bff', whiteSpace: 'nowrap' }}
                                    onClick={(e) => {
                                        this.showMenu = false;
                                        LoadDialogScreen(this.getSelectedCarrierValue(dataItem), MenuOption.BrokerageCarrierAgreement);
                                        e.preventDefault();
                                        this.forceUpdate();
                                    }}
                                >Send Document</a>}
                            </OutsideAlerter>
                        </Popup>}
                    </div>
                </td>
            );
        }
    }
}
