import { GridCellProps } from "@progress/kendo-react-grid";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon } from "@progress/kendo-svg-icons";
import { CarrierSuggestion } from "../Suggestions";

const CarrierNameCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  const dataItem: CarrierSuggestion = props.dataItem;
  return (
    <td role="button">
      <span style={{ pointerEvents: 'none' }}>{dataItem.CarrierName}</span>
      {dataItem.HasAcceptedOffer && <SvgIcon icon={checkIcon} color="green" />}
    </td>
  );
};

export default CarrierNameCell;