import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';

export type VehicleViewModel = {
  VehicleID: number;
  Active: boolean;
  VehicleNumber: string;
  VehicleTypeName: string;
  Driver1Number: string;
  LastLocationCityState: string;

  Links: ILink[];
}

type Props = {
  onSelectedVehicle: (vehicle: VehicleViewModel) => void;
  selectedVehicleID?: number;
  setFocus?: boolean;
  includeRecentlyRetiredVehicles?: boolean;
  formatDisplay?: (vehicle: VehicleViewModel) => string;
} & AutoCompleteProps

export type VehicleAutoCompleteViewModel = VehicleViewModel & { DisplayValue: string; };

export const GetVehicleAutoCompleteDisplayValue = (vehicle: VehicleViewModel, includeActiveLabel: boolean): string => {
  //todo - ability to show service status and or driver seated on unit
  const displayValue = `${vehicle.VehicleNumber}`.toUpperCase();
  if (includeActiveLabel)
    return `${displayValue} - ${vehicle.Active ? 'Active' : 'Retired'}`;
  return displayValue;
}

const VehicleAutoComplete: React.FC<Props> = ({
  onSelectedVehicle,
  selectedVehicleID,
  includeRecentlyRetiredVehicles,
  setFocus,
  formatDisplay,
  ...autoCompleteProps
}) => {
  const inputAC = useRef<AutoComplete>(null);
  const [loading, setLoading] = useState(false);
  const [vehicleDropDownListing, setVehicleDropDownListing] = useState<VehicleAutoCompleteViewModel[]>([]);
  const [vehicleAutoCompleteValue, setVehicleAutoCompleteValue] = useState('');
  const _selectedVehicle = useRef(0);
  const [preSelectedVehicleInitialized, setPreSelectedVehicleInitialized] = useState(false);
  const _requestHash = useRef('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(
    debounce((val: string, requestHash: string) => {
      if (val.length > 2 && _selectedVehicle.current === 0) {
        getVehicleListing(generateDataStateByName(val), requestHash);
      }
    }, 750), []);

  const generateDataStateByName = (val: string): GridState => {
    let gs = {
      skip: 0,
      take: 12,
      sort: [{
        field: "VehicleNumber",
        dir: "asc"
      }],
      filter: {
        logic: 'and', filters: [
          {
            logic: 'or', filters: [
              { field: 'VehicleNumber', operator: 'contains', value: val }
            ] as any
          }
        ] as any
      }
    } as GridState;

    if (includeRecentlyRetiredVehicles) {
      gs.filter.filters.unshift({
        logic: 'or', filters: [
          { field: 'active', operator: 'eq', value: true },
          {
            logic: 'and', filters: [
              { field: 'active', operator: 'eq', value: false },
              { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(12, "months").toDate() }
            ]
          }
        ]
      });
    } else {
      gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
    }

    return gs;
  }

  const generateDataStateByID = (val: number): GridState => {
    return {
      skip: 0,
      take: 1,
      sort: null,
      filter: {
        logic: 'and', filters: [
          { field: 'VehicleID', operator: 'eq', value: val }
        ] as any
      }
    } as GridState;
  }

  const getVehicleListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
    setLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    fetchApi(`/api/Asset/GetAssetVehiclesDataState?${queryStr}`, {}, 'POST')
      .then(({ Data }) => {
        let vehicleData: VehicleAutoCompleteViewModel[] = Data;
        setLoading(false);
        if (_requestHash.current === requestHash) {
          vehicleData.forEach(x => {
            x.DisplayValue = formatDisplay ? formatDisplay(x) : GetVehicleAutoCompleteDisplayValue(x, includeRecentlyRetiredVehicles);
          });
          setVehicleDropDownListing(vehicleData);

          if (populateLabel) {
            setVehicleAutoCompleteValue(formatDisplay ? formatDisplay(vehicleData[0]) : vehicleData[0].VehicleNumber);
            setPreSelectedVehicleInitialized(true);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
  }, [includeRecentlyRetiredVehicles, formatDisplay]);

  const onVehicleListingClose = (event: AutoCompleteCloseEvent) => {
    const value = event.target.value;
    const selectedVehicle = vehicleDropDownListing.find(x => x.DisplayValue === value);
    //setVehicleAutoCompleteValue(selectedVehicle != null ? value : '');
    setVehicleAutoCompleteValue(selectedVehicle != null ? formatDisplay ? formatDisplay(selectedVehicle) : selectedVehicle.VehicleNumber : '');
    _selectedVehicle.current = selectedVehicle?.VehicleID;
    onSelectedVehicle(selectedVehicle ?? null);
  };

  useEffect(() => {
    if (selectedVehicleID !== _selectedVehicle.current) {
      if (selectedVehicleID > 0) {
        _selectedVehicle.current = selectedVehicleID;
        _requestHash.current = Math.random().toString(36).substring(7);
        getVehicleListing(generateDataStateByID(selectedVehicleID), _requestHash.current, true);
      } else {
        _selectedVehicle.current = 0;
        setVehicleAutoCompleteValue('');
      }
      if (setFocus) {
        inputAC.current.focus();
      }
    }
  }, [selectedVehicleID, getVehicleListing, setFocus]);

  useEffect(() => {
    if (preSelectedVehicleInitialized) {
      inputAC.current.focus();
      (inputAC.current.element.querySelector('input') as HTMLInputElement).select();
    }
  }, [preSelectedVehicleInitialized])

  return <AutoComplete
    ref={inputAC}
    {...autoCompleteProps}
    placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Select Vehicle')}
    loading={loading}
    data={vehicleDropDownListing}
    value={vehicleAutoCompleteValue}
    textField="DisplayValue"
    onChange={(e) => {
      _selectedVehicle.current = 0;
      if (!e.value) onSelectedVehicle(null);
      setVehicleDropDownListing([]);
      setVehicleAutoCompleteValue(e.value);
      _requestHash.current = Math.random().toString(36).substring(7);
      debounceSearchInput(e.value, _requestHash.current);
    }}
    onClose={(e) => onVehicleListingClose(e)}
  />
}

export default VehicleAutoComplete;
