import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { fetchApi } from '../../../services/api';
import { isNullOrWhiteSpace } from '../../../utils/utils';

type Props = {
  CloseDialog: () => void;
}

const AddVendorDialog = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [vendorName, setVendorName] = useState('');
  const [addressline1, setAddressline1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vendorID, setVendorID] = useState('');

  const history = useHistory();

  const hasAddress = addressline1.length > 0 && city.length > 0 && state.length > 0;
  const canSave = (hasAddress && isNullOrWhiteSpace(vendorName) === false && isNullOrWhiteSpace(vendorID) === false);

  const _save = () => {
    if (canSave === false)
      return;

    setLoading(true);

    const data = {
      vendorName,
      addressline1,
      city,
      state,
      zipCode,
      phoneNumber,
      vendorID
    }

    fetchApi('/api/PurchaseOrder/AddVendor', data, 'POST')
      .then((response: JsonResponse) => {
        setLoading(false);
        if (response.Success) {
          var link = response.Links.find(l => l.Name === 'VendorProfile');
          if (link) history.push(link.Link);
          props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      }
      ).catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  return <div className="container-fluid">
    <Dialog title={'Create Vendor Profile'} onClose={props.CloseDialog} width={400} height={625}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <div className="k-form k-form-md p-0">
        <div>
          <FieldWrapper>
            <Input
              required
              maxLength={40}
              autoFocus
              disabled={loading}
              value={vendorName}
              label="Vendor Name"
              onChange={(e) => setVendorName(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              maxLength={30}
              disabled={loading}
              value={addressline1}
              label="Address Line 1"
              onChange={(e) => setAddressline1(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              maxLength={50}
              disabled={loading}
              value={city}
              label="City"
              onChange={(e) => setCity(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              maxLength={2}
              disabled={loading}
              value={state}
              label="State"
              onChange={(e) => setState(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              maxLength={10}
              disabled={loading}
              value={zipCode}
              label="Zip"
              onChange={(e) => setZipCode(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <MaskedTextBox
              mask="(000) 000-0000"
              value={phoneNumber}
              disabled={loading}
              label="Phone Number"
              onChange={(e) => setPhoneNumber(e.value || '')}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Input
              required
              maxLength={30}
              disabled={loading}
              value={vendorID}
              label="Vendor ID"
              onChange={(e) => setVendorID(e.value || '')}
            />
          </FieldWrapper>
        </div>
      </div>

      <DialogActionsBar>
        <Button onClick={props.CloseDialog}>Cancel</Button>
        <Button themeColor="primary" disabled={canSave === false || loading} onClick={_save}>
          Create Vendor
        </Button>
      </DialogActionsBar>
    </Dialog>
  </div>
}

export default AddVendorDialog;

