import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Loader } from "@progress/kendo-react-indicators";
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { fetchApi } from '../../../services/api';
import { isNullOrWhiteSpaceValidator } from '../../../utils/utils';

type Props = {
    CloseDialog: () => void;
}

const CopyTrailerDialog = (props: Props) => {
    const [createType, setCreateType] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    // Copy
    const [copyTrailerNumber, setCopyTrailerNumber] = useState({ value: '', error: '' });

    // Create
    const [trailerNumber, setTrailerNumber] = useState({ value: '', error: '' });

    const _save = () => {

        const copyTrailerNumberError = createType === 0 ? isNullOrWhiteSpaceValidator(copyTrailerNumber.value, 'Enter a Trailer ID to copy.') : '';
        const trailerNumberError = isNullOrWhiteSpaceValidator(trailerNumber.value, 'Please enter a new Trailer ID.');

        if (copyTrailerNumberError || trailerNumberError) {
            setCopyTrailerNumber({ ...copyTrailerNumber, error: copyTrailerNumberError });
            setTrailerNumber({ ...trailerNumber, error: trailerNumberError });
            return;
        }

        setLoading(true);

        const data = {
            CopyTrailerNumber: createType === 0 ? copyTrailerNumber.value : null,
            TrailerNumber: trailerNumber.value,
      }

        fetchApi('/api/Asset/CopyTrailer', data, 'POST')
            .then((response: JsonResponse) => {
                setLoading(false);
                if (response.Success) {
                    var link = response.Links.find(l => l.Name === 'TrailerProfile');
                    if (link) history.push(link.Link);
                    props.CloseDialog();
                } else {
                    alert(response.ErrorMessage);
                }
            }
            ).catch(() => {
                setLoading(false);
                alert('Unable to copy trailer');
            });
    }

    const options = [
        { label: "Copy Trailer", value: 0 },
        { label: "Create Trailer", value: 1 },
      ];

    return <div className="container-fluid">
        <Dialog title={`${createType === 0 ? 'Copy' : 'Create'} Trailer Profile`} onClose={props.CloseDialog} width={400} height={600}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="k-form k-form-md p-0">
                <RadioGroup
                    value={createType}
                    onChange={(e) => setCreateType(e.value)}
                    layout="horizontal"
                    data={options}
                />
                {createType === 0 && <FieldWrapper>
                    <Label>Copy Trailer ID</Label>
                    <Input
                        maxLength={12}
                        disabled={loading}
                        value={copyTrailerNumber.value}
                        onChange={(e) => setCopyTrailerNumber({ value: e.value || '', error: '' })}
                    />
                    <Error>{copyTrailerNumber.error}</Error>
                </FieldWrapper>}
                <FieldWrapper>
                    <Label>{createType === 0 ? 'New' : ''} Trailer ID</Label>
                    <Input
                        maxLength={12}
                        disabled={loading}
                        value={trailerNumber.value}
                        onChange={(e) => setTrailerNumber({ value: e.value || '', error: '' })}
                    />
                    <Error>{trailerNumber.error}</Error>
                </FieldWrapper>
            </div>

            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" onClick={_save}>
                    {createType === 0 ? 'Copy' : 'Create'} Trailer
                </Button>
            </DialogActionsBar>
        </Dialog>
    </div>
}

export default CopyTrailerDialog;

