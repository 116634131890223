import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import SimpleDatePicker from '../../components/SimpleDatePicker';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
    CustomerID?: number
};

type FuelPumpRateViewModel = {
    RateLineItemID: number;

    ModifiedByFullName: string;
    ModifiedDateTime: Date;

    RateDate: Date;
    BillPumpRate: number;
    PayPumpRate: number;

    inEdit?: boolean | string;
    isNew?: boolean;
    Delete?: boolean;
};

interface EditCommandCellProps extends GridCellProps {
    edit: (item: FuelPumpRateViewModel) => void;
    remove: (item: FuelPumpRateViewModel) => void;
    add: (item: FuelPumpRateViewModel) => void;
    discard: () => void;
    update: (item: FuelPumpRateViewModel) => void;
    cancel: (item: FuelPumpRateViewModel) => void;
    editField: string;
}

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const RateCell = (props: GridCellProps) => {
    const handleChange = (e: NumericTextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
            });
        }
    };

    let dataItem: FuelPumpRateViewModel = props.dataItem;
    var value = props.dataItem[props.field] as number;

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {dataItem.inEdit ? <NumericTextBox format="n3" step={0.001} value={value} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
                <span>{formatNumber(value, "n3")}</span>}
        </td>
    );
};

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: FuelPumpRateViewModel = props.dataItem;
    return (
        <td colSpan={props.colSpan} style={props.style}>
            <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const handleChange = (e: Date) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: null,
                value: e,
            });
        }
    };

    return (
        <td colSpan={props.colSpan} style={props.style}>
            {props.dataItem.inEdit ?
                <SimpleDatePicker
                    value={Moment.utc(props.dataItem[props.field]).toDate()}
                    onChange={handleChange}
                />
                :
                <div className={props.field === 'EndDate' && props.dataItem.IsExpired ? 'text-warning' : ''}>
                    {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY")}
                </div>
            }
        </td>
    );
};

const FuelPumpRate = (props: Props) => {
    const { alert } = useAlert();
    const { ConfirmationDialog, confirm } = useConfirm({});
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [fuelPumpPointRateBreaks, setFuelPumpRates] = useState<FuelPumpRateViewModel[]>([]);
    const [CSIDataCopy, setCSIDataCopy] = useState<FuelPumpRateViewModel[]>([]);
    const [daysToSaveRate, setDaysToSaveRate] = useState('for 7 days');

    const EditCommandCell = (props: EditCommandCellProps) => {
        const inEdit = props.dataItem[props.editField];
        let dataItem: FuelPumpRateViewModel = props.dataItem;
        const isNewItem = dataItem.isNew;

        const disabled = dataItem.RateDate == null || dataItem.BillPumpRate == null || dataItem.PayPumpRate == null;

        return inEdit ? (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={disabled}
                    onClick={() =>
                        isNewItem ? props.add(dataItem) : props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </Button>
                <Button themeColor={"secondary"}
                    onClick={() =>
                        isNewItem ? props.discard() : props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </Button>
                {isNewItem && <div className="mt-2">
                    <DropDownList
                        data={['for 7 days', 'for 6 days', 'for 5 days', 'for 4 days', 'for 3 days', 'for 2 days', 'for 1 day']}
                        value={daysToSaveRate}
                        onChange={(e) => setDaysToSaveRate(e.value)}
                    />
                </div>}
            </td>
        ) : (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                    onClick={() => props.edit(dataItem)}>Edit</Button>
                <Button themeColor={"secondary"}
                    disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                    onClick={async () => {
                        if (!await confirm(`Remove?`))
                            return;
                        props.remove(dataItem);
                    }}>Delete</Button>
            </td>
        );
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const enterEdit = (dataItem: FuelPumpRateViewModel) => {
        if (fuelPumpPointRateBreaks.some(x => x.inEdit === true))
            return;

        setFuelPumpRates(
            fuelPumpPointRateBreaks.map((item) =>
                item.RateLineItemID === dataItem.RateLineItemID ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem: FuelPumpRateViewModel) => {
        updateAccessorialRateStructure({ ...dataItem, Delete: true });
    };

    const add = (dataItem: FuelPumpRateViewModel) => {
        updateAccessorialRateStructure(dataItem);
    };

    const update = (dataItem: FuelPumpRateViewModel) => {
        updateAccessorialRateStructure(dataItem);
    };

    const cancel = (dataItem: FuelPumpRateViewModel) => {
        const originalItem = CSIDataCopy.find(
            (p) => p.RateLineItemID === dataItem.RateLineItemID
        );
        const newData = fuelPumpPointRateBreaks.map((item) =>
            item.RateLineItemID === originalItem.RateLineItemID ? originalItem : item
        );

        setFuelPumpRates(newData);
    };

    const generateId = () =>
        fuelPumpPointRateBreaks.reduce((acc, current) => Math.max(acc, current.RateLineItemID), 0) + 1;

    const addNew = () => {
        const initialDateToUse = fuelPumpPointRateBreaks.length > 0 ?
            fuelPumpPointRateBreaks.reduce((max, obj) => (obj.RateDate > max ? obj.RateDate : max), Moment(fuelPumpPointRateBreaks[0].RateDate).add(1, 'day').toDate()) :
            Moment.utc().tz("America/New_York").startOf('day').toDate();

        const newDataItem = {
            inEdit: true, RateLineItemID: generateId(), isNew: true,
            BillPumpRate: 0, PayPumpRate: 0, RateDate: initialDateToUse
        } as FuelPumpRateViewModel;
        setFuelPumpRates([newDataItem, ...fuelPumpPointRateBreaks]);
    };

    const discard = () => {
        const newData = [...fuelPumpPointRateBreaks];
        newData.splice(0, 1);
        setFuelPumpRates(newData);
    };

    const refresh = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/Quote/GetFuelPumpRates/${props.CustomerID ?? 0}`)
            .then((response: any) => {
                setFuelPumpRates(response.FuelPumpRates);
                setCSIDataCopy(response.FuelPumpRates)

                setLoading(false);
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }, [props.CustomerID, alert]);

    const updateAccessorialRateStructure = (dataItem: FuelPumpRateViewModel) => {
        setLoading(true);

        const data = { ...dataItem, DaysToSaveRate: parseInt(daysToSaveRate.match(/\d+/)[0]) }
        fetchApi(`api/Quote/FuelPumpRates/${props.CustomerID ?? 0}`, data, 'POST')
            .then(async (response: JsonResponse) => {
                if (response.Success === false) {
                    setLoading(false);
                    await alert(`Error: ${response.ErrorMessage}`);
                }
                else
                    refresh();
            })
            .catch(async e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) await alert('An error occurred while saving.');
            });
    }

    useEffect(() => {
        refresh();
    }, [props.CustomerID, refresh]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = fuelPumpPointRateBreaks.map((item) =>
            item.RateLineItemID === event.dataItem.RateLineItemID
                ? { ...item, [field]: event.value }
                : item
        );

        setFuelPumpRates(newData);
    }

    const dataView = () => {
        return (<div className="container-fluid mt-2" style={{ position: "relative" }}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            {/*<h4 className="text-left">Fuel Pump Rates</h4>*/}
            <Grid
                style={{
                    height: `${window.innerHeight * .80}px`,
                }}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
                data={fuelPumpPointRateBreaks}
                onItemChange={itemChange}
            >
                <GridToolbar>
                    <Button themeColor={"primary"}
                        disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                        title="Add new"
                        onClick={addNew}
                    >
                        Add new
                    </Button>
                </GridToolbar>
                <Column field="RateDate" title="Date" cell={DateCell} width={160} />
                <Column field="BillPumpRate" title="Bill Pump Rate" cell={RateCell} headerCell={RightAlignHeaderCell} />
                <Column field="PayPumpRate" title="Pay Pump Rate" cell={RateCell} headerCell={RightAlignHeaderCell} />
                <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
                <Column cell={MyEditCommandCell} width={160} />
            </Grid>
            <ConfirmationDialog />
        </div>);
    };

    return dataView();
}

export default FuelPumpRate;
