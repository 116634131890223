import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';

type State = {
  loading: boolean;
  conversionPercentage: any[];
}

type Props = {
  filter: { Name: string, Filter: string };
}

export default class ConversionPercentage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      conversionPercentage: [],
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi(`/api/Report/ConversionPercentage/${this.props.filter.Filter}`)
      .then((response: Array<{ TimePeriod: string, ConversionPercentage: number }>) => {
        this.setState({
          loading: false,
          conversionPercentage: response
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    return <React.Fragment>
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
          <p>
            <span className="report-card-title">Quotes to Orders - {this.props.filter.Name}</span>
          </p>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Time Period</th>
                <th style={{ textAlign: 'right' }}>%</th>
              </tr>
            </thead>
            <tbody>
              {this.state.conversionPercentage.map((conversionPercentage, index) => {
                return <tr key={index}>
                  <td>{conversionPercentage.TimePeriod}</td>
                  <td style={{ textAlign: 'right' }}>{formatNumber(conversionPercentage.ConversionPercentage, "p")}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
  }
}
