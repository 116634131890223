import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { useCallback, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { IDName } from '../../types/idname';
import { ILink } from '../../types/link';
import { VehicleRateBreak } from './VehicleRateBreakStructure';

type Props = {
  RateFuelMasterID: number
};

type RateMasterSummaryResponse = {
  SupportedVehicleRateBreaks: IDName[],
  Links: ILink[]
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: VehicleRateBreak) => void;
  remove: (item: VehicleRateBreak) => void;
  add: (item: VehicleRateBreak) => void;
  discard: () => void;
  update: (item: VehicleRateBreak) => void;
  cancel: (item: VehicleRateBreak) => void;
  editField: string;
}

const vehicleBreakHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    <span>{props.title}</span>
    {props.children}
  </a>);
};

const VehicleBreakCell = (props: GridCellProps) => {
  let dataItem: VehicleRateBreak = props.dataItem;
  const dataItemVehicleRateBreak = dataItem.RateBreaks.find(x => x.RateBreakID === Number(props.field));

  const handleChange = (e: NumericTextBoxChangeEvent) => {
    dataItemVehicleRateBreak.Rate = e.target.value;
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: 'RateBreaks',
        syntheticEvent: e.syntheticEvent,
        value: dataItem.RateBreaks
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit ? <NumericTextBox format="n3" value={dataItemVehicleRateBreak.Rate} onChange={handleChange} min={0} step={0.001} inputStyle={{ textAlign: "right" }} /> :
        <span>{formatNumber(dataItemVehicleRateBreak.Rate, "n3")}</span>
      }
    </td>
  );
};

const RateCell = (props: GridCellProps) => {
  let dataItem: VehicleRateBreak = props.dataItem;

  const handleChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit ? <NumericTextBox format="n3" value={props.dataItem[props.field]} onChange={handleChange} min={0} step={0.25} inputStyle={{ textAlign: "right" }} /> :
        <span>{formatNumber(props.dataItem[props.field], "n3")}</span>
      }
    </td>
  );
};

const FuelVehicleRateBreakStructure = (props: Props) => {
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  //const [isTierComponentInEditMode, setIsTierComponentInEditMode] = useState(false);
  const editField: string = "inEdit";
  const [vehicleRateBreaks, setVehicleRateBreaks] = useState<VehicleRateBreak[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<VehicleRateBreak[]>([]);
  const [rateMasterSummaryResponse, setRateMasterSummaryResponse] = useState<RateMasterSummaryResponse>();

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: VehicleRateBreak = props.dataItem;
    const isNewItem = dataItem.isNew;

    const disabled = ((dataItem.PumpRateMin > 0 && dataItem.PumpRateMax > 0) == false || dataItem.RateBreaks.some(x => x.Rate == null));

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disabled}
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
          onClick={() => props.edit(dataItem)}>Edit</Button>
        <Button themeColor={"secondary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
          onClick={async () => {
            if (!await confirm(`Remove [${dataItem.PumpRateMin}/${dataItem.PumpRateMax}]?`))
              return;
            props.remove(dataItem);
          }}>Delete</Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const enterEdit = (dataItem: VehicleRateBreak) => {
    if (vehicleRateBreaks.some(x => x.inEdit === true))
      return;

    setVehicleRateBreaks(
      vehicleRateBreaks.map((item) =>
        item.RateLineItemID === dataItem.RateLineItemID ? { ...item, inEdit: true } : item
      )
    );
  };

  const remove = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure({ ...dataItem, Delete: true });
  };

  const add = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure(dataItem);
  };

  const update = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure(dataItem);
  };

  const cancel = (dataItem: VehicleRateBreak) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.RateLineItemID === dataItem.RateLineItemID
    );
    const newData = vehicleRateBreaks.map((item) =>
      item.RateLineItemID === originalItem.RateLineItemID ? cloneObject(originalItem) : item
    );

    setVehicleRateBreaks(newData);
  };

  const generateId = () =>
    vehicleRateBreaks.reduce((acc, current) => Math.max(acc, current.RateLineItemID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, RateLineItemID: generateId(), isNew: true,
      RateBreaks: rateMasterSummaryResponse.SupportedVehicleRateBreaks.map(x => {
        return { RateBreakID: x.ID, Rate: 0 };
      })
    } as VehicleRateBreak;
    setVehicleRateBreaks([newDataItem, ...vehicleRateBreaks]);
  };

  const discard = () => {
    const newData = [...vehicleRateBreaks];
    newData.splice(0, 1);
    setVehicleRateBreaks(newData);
  };

  const getRateMaster = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Quote/GetRateFuelMasterSummary/${props.RateFuelMasterID}`)
      .then((response: RateMasterSummaryResponse) => {
        setRateMasterSummaryResponse(response);
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [props.RateFuelMasterID, alert]);


  const cloneObject = (obj: any): any => {
    return JSON.parse(JSON.stringify(obj));
  }

  const refresh = useCallback(() => {
    setLoading(true);

    const link = rateMasterSummaryResponse.Links.find(x => x.Method === 'GET');

    fetchApi(link.Link)
      .then((response: any) => {
        setVehicleRateBreaks(response.VehicleRateBreaks);
        setCSIDataCopy(cloneObject(response.VehicleRateBreaks)) //deep copy/clone

        setLoading(false);
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [rateMasterSummaryResponse?.Links, alert]);

  const updateAccessorialRateStructure = (dataItem: VehicleRateBreak) => {
    setLoading(true);

    const link = rateMasterSummaryResponse.Links.find(x => x.Method === 'POST');
    const data = { ...dataItem }
    fetchApi(link.Link, data, link.Method)
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  useEffect(() => {
    getRateMaster();
  }, [props.RateFuelMasterID, getRateMaster]);

  useEffect(() => {
    if (rateMasterSummaryResponse) {
      refresh();
    }
  }, [rateMasterSummaryResponse, refresh]);

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = vehicleRateBreaks.map((item) =>
      item.RateLineItemID === event.dataItem.RateLineItemID
        ? { ...item, [field]: event.value }
        : item
    );

    setVehicleRateBreaks(newData);
  }

  const dataView = () => {
    return (<div className="container-fluid mt-2" style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}

      {rateMasterSummaryResponse && <h4 className="text-left">Fuel Break Point Rate Structure</h4>}
      <Grid
        style={{
          maxHeight: `${window.innerHeight * .80}px`,
        }}
        onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
        data={vehicleRateBreaks}
        onItemChange={itemChange}
      >
        <GridToolbar>
          <Button themeColor={"primary"}
            disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
            title="Add new"
            onClick={addNew}
          >
            Add new
          </Button>
        </GridToolbar>
        <Column field="PumpRateMin" title="Min" cell={RateCell} />
        <Column field="PumpRateMax" title="Max" cell={RateCell} />
        {rateMasterSummaryResponse && rateMasterSummaryResponse.SupportedVehicleRateBreaks.map((x, key) => {
          return <Column key={key} title={`${x.Name}`} field={`${x.ID}`} editable={false} cell={VehicleBreakCell} headerCell={vehicleBreakHeaderCell} />
        })}
        <Column cell={MyEditCommandCell} width={160} />
      </Grid>
      <ConfirmationDialog />
    </div>);
  };

  return dataView();
}


export default FuelVehicleRateBreakStructure;
