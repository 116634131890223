import * as React from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { Form, FormHandle, Field } from '@progress/kendo-react-form';
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { openWindow } from '../services/openWindow';
import { fetchApi } from '../services/api';
import PhoneCell from './cells/PhoneCell';
import { IDName } from '../types/idname';
import EmailCell from './cells/EmailCell';
import { Button } from '@progress/kendo-react-buttons';
import AddCustomerContactDialog from 'views/Customers/AddCustomerContactDialog';
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon, searchIcon } from '@progress/kendo-svg-icons';

export type CustomerContactInputValue = {
    // Core
    CustomerContactID: number;

    // Update Customer
    CustomerID?: number;
    CustomerNumber?: string;
}

type CustomerContactSearchValue = {
    CustomerID: number;
    CustomerContactID: number;
    CustomerNumber: string;
    CustomerName: string;
    AddressLine1: string;
    City: string;
    State: string;
    ZipCode: string;
    CustomerPhoneNumber: string;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    CustomerContactPhoneNumber: string;
}

type Props = {
    CustomerContacts: IDName[];
    CustomerID: number;
    CustomerContactID: number;
    placeholder?: string;
    onChange: (response: CustomerContactInputValue) => void;
    prependGroup?: boolean;
}

const CustomerContactInput = (props: Props) => {

  const searchForm = React.useRef<FormHandle>(null);

  const [searchData, setSearchData] = React.useState<CustomerContactSearchValue[]>([]);
  const [isLoadingSearchData, setIsLoadingSearchData] = React.useState(false);
  const [customerSearch, setCustomerSearch] = React.useState(false);
  const [showAddCustomerContactDialog, setShowAddCustomerContactDialog] = React.useState(false);

  const openCustomerContact = () => {
    if (props.CustomerContactID) {
      window.open(`/Customers/Customer/Contact/${props.CustomerContactID}`);
    } else {
      window.open(`/Customers/Customer/${props.CustomerID}/Contacts`);
    }
  }

  const customerSearchWindow = () => {
    return <Window
      title="Customer Contact Search"
      style={{ position: 'fixed' }}
      initialWidth={Math.min(850, window.innerWidth)}
      initialHeight={Math.min(550, window.innerHeight)}
      onClose={() => {
        setCustomerSearch(false);
        setSearchData([]);
      }}
      >
        <Form
          ref={searchForm}
          onSubmit={(values, event) => searchCustomerContacts(values)}
          render={(formRenderProps) => (
            <form onSubmit={formRenderProps.onSubmit} className="k-form k-form-md p-0">
              <div className="row">
                <div className="col-md-6">
                  <Field name="FirstName" component={Input} label="First Name" style={{ width: '100%' }} autoFocus />
                </div>
                <div className="col-md-6">
                  <Field name="LastName" component={Input} label="Last Name" style={{ width: '100%' }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Field name="CustomerContactPhoneNumber" component={MaskedTextBox} label="Contact Phone" mask="(000) 000-0000" width={'100%'} />
                </div>
                <div className="col-md-6">
                  <Field name="EmailAddress" component={Input} label="Contact Email" style={{ width: '100%' }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Field name="CustomerPhoneNumber" component={MaskedTextBox} label="Customer Phone" mask="(000) 000-0000" width={'100%'} />
                </div>
              </div>
              <br />
              <div className="text-center">
                <Button themeColor="primary" disabled={!formRenderProps.allowSubmit}>Search</Button>
                &nbsp;&nbsp;
                <Button onClick={() => searchForm.current.resetForm()}>Reset</Button>
              </div>
            </form>
          )}
        />
        <br />
        {isLoadingSearchData && <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
        />}
        <Grid
          data={searchData}
          dataItemKey="CustomerContactID"
          onRowClick={(e) => selectCustomerContacts(e.dataItem)}
        >
          <Column field="CustomerNumber" title="Customer" cell={(e) => <td  style={{ color: '#007bff' }} role="button">{e.dataItem.CustomerNumber} - {e.dataItem.CustomerName}</td>} />
          <Column field="AddressLine1" title="Address" cell={(e) => <td>{e.dataItem.AddressLine1} {e.dataItem.City}, {e.dataItem.State} {e.dataItem.ZipCode}</td>} />
          <Column field="FirstName" title="Name" cell={(e) =><td>{e.dataItem.FirstName} {e.dataItem.LastName}</td>} />
          <Column field="EmailAddress" title="Email" cell={EmailCell} />
          <Column field="CustomerContactPhoneNumber" title="Contact Phone" cell={PhoneCell} />
          <Column field="CustomerPhoneNumber" title="Customer Phone" cell={PhoneCell} />
        </Grid>
      </Window>
  }

  const openCustomerContactSearch = () => {
    setCustomerSearch(true);
  }

  const searchCustomerContacts = (values: { [name: string]: any; }) => {
    setIsLoadingSearchData(true);
    fetchApi("/api/Customer/ContactSearch", values, "POST")
      .then((response: { CustomerContacts: CustomerContactSearchValue[] }) => {
        setSearchData(response.CustomerContacts);
        setIsLoadingSearchData(false);
      });
  }

  const selectCustomerContacts = (dataItem: CustomerContactInputValue) => {
    setSearchData([]);
    setCustomerSearch(false);
    props.onChange({
      CustomerContactID: dataItem.CustomerContactID,
      CustomerID: dataItem.CustomerID,
      CustomerNumber: dataItem.CustomerNumber,
    });
  }

  return <>
    {customerSearch && customerSearchWindow()}
    {showAddCustomerContactDialog && <AddCustomerContactDialog
      CustomerId={props.CustomerID}
      CloseDialog={(newCustomerContactId) => {
        if (newCustomerContactId > 0) {
          const data = {
            CustomerContactID: newCustomerContactId,
          }
          fetchApi("/api/Customer/ContactSearch", data, "POST")
            .then((response: { CustomerContacts: CustomerContactSearchValue[] }) => {
              selectCustomerContacts(response.CustomerContacts[0]);
              setShowAddCustomerContactDialog(false);
            });
        } else {
          setShowAddCustomerContactDialog(false);
        }
      }}
    />}
    <select
      className="custom-select"
      value={props.CustomerContactID}
      onChange={(e) => props.onChange({ CustomerContactID: parseInt(e.target.value) })}
    >
      <option value="0">Select Contact</option>
      {props.CustomerContacts.map((contact, index) => {
        return <option key={index} value={contact.ID}>{contact.Name}</option>
      })}
    </select>
    {!props.CustomerID && !props.CustomerContactID && <div className="input-group-append">
      <button className="btn btn-outline-secondary" type="button" onClick={openCustomerContactSearch}>
        <SvgIcon icon={searchIcon} />
      </button>
    </div>}
    {props.CustomerID > 0 && window.isTMS && <div className="input-group-append">
      <button className="btn btn-outline-secondary" type="button" onClick={() => setShowAddCustomerContactDialog(true)}>
        <SvgIcon icon={plusIcon} />
      </button>
    </div>}
    {props.CustomerID > 0 && <div className="input-group-append">
      <button className="btn btn-outline-secondary" type="button" onClick={openCustomerContact}>Open</button>
    </div>}
  </>
}

export default CustomerContactInput;