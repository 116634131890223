import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../../services/openWindow';
import { ILink } from '../../../types/link';
import { Popup } from '@progress/kendo-react-popup';

export default class HoursOfService extends React.Component<GridCellProps> {

  private popupAnchor: any = null;
  private show: boolean = false;

  render() {
    if (!this.props.field) return null;

    return (
      <td>
        <div className="container">
          <div className="row">
            <div className="col-xs-4">
              {this.props.dataItem.Links.filter((x: ILink) => x.Name === 'HOSProfile').length > 0 ? <a
                href="#"
                style={{ color: '#007bff' }}
                title={this.props.dataItem.HoursOfServiceDutyStatus}
                onClick={(e) => {
                  openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'HOSProfile')[0].Link);
                  e.preventDefault();
                }}
              >
                {this.props.dataItem.HoursOfService}
              </a> : null}
            </div>
            <div className="col-xs-8">
              {(this.props.dataItem.HoursOfService.length > 0 && this.props.dataItem.TransitTimeTooltip) &&
                (this.props.dataItem.HasHoursRemaining ?
                  <span className="hos-green-circle" style={{ cursor: "pointer" }} ref={(link) => { this.popupAnchor = link; }} onMouseEnter={() => { this.show = true; this.forceUpdate(); }} onMouseLeave={() => { this.show = false; this.forceUpdate(); }}></span>
                  :
                  <span className="hos-red-circle" style={{ cursor: "pointer" }} ref={(link) => { this.popupAnchor = link; }} onMouseEnter={() => { this.show = true; this.forceUpdate(); }} onMouseLeave={() => { this.show = false; this.forceUpdate(); }}></span>
                )}
              {<Popup
                anchor={this.popupAnchor}
                show={this.show}
                className={'wrapper'}
                popupClass={'inner-wrapper'}
              >
                <span className="px-2" dangerouslySetInnerHTML={{ __html: this.props.dataItem.TransitTimeTooltip }} />
              </Popup>}
            </div>
          </div>
        </div>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
