import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import * as React from 'react';
import TrimbleMapBookedControl from './TrimbleMapBookedControl';

type Props = {
  locations: any[];
  vehicleTypeId?: number;
  version?: number | null;
  style?: React.CSSProperties
};

const MapPanel = (props: Props) => {

  const map = React.useRef<TrimbleMaps.Map>(null);
  const routingLayer = React.useRef<TrimbleMaps.Route>(null);
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [mapStyleLoaded, setMapStyleLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!props.vehicleTypeId || !map.current) return;

    const bookedControl = new TrimbleMapBookedControl({
      vehicleTypeId: props.vehicleTypeId,
      coordinates: props.locations[props.locations.length - 1].Coordinates,
      route: routingLayer.current
    });
    map.current?.addControl(bookedControl);
    return () => {
      map.current?.removeControl(bookedControl);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, routingLayer.current, props.vehicleTypeId, JSON.stringify(props.locations[props.locations.length - 1].Coordinates)]);

  const renderMap = React.useCallback((locations: any[], version: number | null) => {
    if (!routingLayer.current) {
      routingLayer.current = new TrimbleMaps.Route({
        routeId: "QuoteRoute",
        bordersOpen: false,
        highwayOnly: false,
        tollDiscourage: true,
        dataset: version ? `PCM${version}` : "",
      });
    }

    // Map style isn't loaded yet
    if (!map.current.isStyleLoaded()) {
      // Retry after a second
      setTimeout(() => {
        renderMap(locations, version);
      }, 100);
      return;
    }

    if (!map.current.getLayer("route-layer-QuoteRoute")) {
      routingLayer.current.addTo(map.current);
    }

    // Add/Update Route
    var coords = locations.map(x => new TrimbleMaps.LngLat(x.Coordinates.Longitude, x.Coordinates.Latitude));
    routingLayer.current.update({
      stops: coords
    });
  }, [map, routingLayer]);

  React.useEffect(() => {
    if (map.current && mapLoaded && mapStyleLoaded) {
      if (props.locations.length > 1) {
        renderMap(props.locations, props.version);
      } else if (routingLayer.current) {
        routingLayer.current.remove();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapLoaded, mapStyleLoaded, JSON.stringify(props.locations), props.version, renderMap])

  React.useEffect(() => {
    const style = localStorage["darkmode"] === 'false' ? TrimbleMaps.Common.Style.TRANSPORTATION : TrimbleMaps.Common.Style.TRANSPORTATION_DARK;
    map.current = new TrimbleMaps.Map({
      container: "quote-map",
      style: style,
      bounds: new TrimbleMaps.LngLatBounds(new TrimbleMaps.LngLat(props.locations[0].Coordinates.Longitude, props.locations[0].Coordinates.Latitude), new TrimbleMaps.LngLat(props.locations[1].Coordinates.Longitude, props.locations[1].Coordinates.Latitude)),
      scrollZoom: false,
      attributionControl: false,
    });

    map.current.addControl(new TrimbleMaps.NavigationControl());
    map.current.addControl(new TrimbleMaps.ScaleControl({ unit: 'imperial' }));
    map.current.addControl(new TrimbleMaps.FullscreenControl());
    map.current.setDarkMode(style === TrimbleMaps.Common.Style.TRANSPORTATION_DARK);

    map.current.on('load', () => {
      setMapLoaded(true);
      map.current.setWeatherRadarVisibility(true);
      map.current.setPlacesVisibility(true);
    });

    map.current.on('style.load', () => {
      setMapStyleLoaded(true);
    })

    return () => {
      map.current.remove();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderMap]);

  return <div id="quote-map" style={{ width: '100%', height: '100%', ...props.style }} />;
}

export default MapPanel;
