
import { useCallback, useContext, useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { ViewModel } from "TypeGen/Order/Integrations/view-model";
import { OrderContext } from "./Details";
import { dateFormatter } from "utils/utils";
import JsonPretty from "components/JsonPretty";

type RouteComponentParams = {
  orderId: string;
}

const Integrations = () => {
    const { orderId: orderIdParam } = useParams<RouteComponentParams>();
    const { setActiveStopIds, setDeleteStopIds } = useContext(OrderContext);
    const [loading, setLoading] = useState(true);
    const [integrations, setIntegrations] = useState<ViewModel>();

    const refreshIntegrations = useCallback(() => {
      setLoading(true);
      fetchApi(`/api/Order/Integrations/${orderIdParam}`)
        .then((data: ViewModel) => {
          setIntegrations(data);
        })
        .catch(err => {
          alert(err);
        })
        .finally(() => {
          setLoading(false);
        });
      }, [orderIdParam]);

    useEffect(() => {
      setActiveStopIds([]);
      setDeleteStopIds([]);
      refreshIntegrations();
    }, [refreshIntegrations, setActiveStopIds, setDeleteStopIds]);

    if (loading || !integrations) {
      return <div className="d-flex justify-content-center mt-5">
        <Loader type="converging-spinner" />
      </div>;
    }

    return (<div className='py-2'>
      <Title string="Integrations" />
      {integrations.Integrations.length === 0 && <h1>No integrations found</h1>}
      {integrations.Integrations.map((integration) => {
        return <div key={integration.APITrackingID} className="card">
          <div className="card-body">
            <h5 className="card-title">{integration.Name}{integration.APIRefId && <span className="badge bg-secondary ml-2">{integration.APIRefId}</span>}</h5>
            {integration.APIJSONValue && <JsonPretty json={integration.APIJSONValue} />}
            <h6 className="card-subtitle mt-2 text-muted">{dateFormatter(integration.CreatedDateTime)}</h6>
          </div>
        </div>
      })}
    </div>);
}

export default Integrations;
