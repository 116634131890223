import { Link } from 'react-router-dom';

export const getSplitLabel = (backwardOrderID: number, forwardOrderID: number): JSX.Element => {
  if (backwardOrderID + forwardOrderID === 0)
    return;

  const style = { fontWeight: 500, letterSpacing: '1.25px' };

  if (backwardOrderID > 0 && forwardOrderID > 0)
    return (<span style={style}>(B,F)</span>);

  if (forwardOrderID > 0)
    return (<span style={style}>(F)</span>);

  return (<span style={style}>(B)</span>);
}

export const getSplitBadgeOrder = (backwardOrderID: number, forwardOrderID: number): JSX.Element => {
  if (backwardOrderID + forwardOrderID === 0)
    return;
  return getSplitBadge();
}

export const getSplitBadgeOrderStop = (isSplitLocation: boolean): JSX.Element => {
  if (isSplitLocation)
    return getSplitBadge();
  return;
}

const getSplitBadge = (): JSX.Element => {
  return (<span className="badge badge-warning">Split Stop</span>);
}

export const getSplitTitle = (backwardOrderID: number, forwardOrderID: number): string => {
  if (backwardOrderID + forwardOrderID === 0)
    return "";

  if (backwardOrderID > 0 && forwardOrderID > 0)
    return "Intermediate Split Order";
  if (forwardOrderID > 0)
    return "Forward Split Order";
  return "Backward Split Order"
}

export const getSplitLink = (backwardOrderID: number, forwardOrderID: number, onClick: () => void): JSX.Element => {
  return (<Link to="#" onClick={() => onClick()}>{getSplitLabel(backwardOrderID, forwardOrderID)}</Link>);
}