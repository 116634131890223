import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { EFSInfoPromptsViewItem } from './DriverCardMaintenance';

type Props = {
    InfoPrompts: EFSInfoPromptsViewItem[]
    //CloseDialog: () => void;
}

const GetInfoPromptMappingValue = (Id: string): string => {
    switch (Id) {
        case "BDAY": return "Birthday";
        case "BLID": return "Billing ID";
        case "CNTN": return "Control Number";
        case "CRDR": return "Card Description";
        case "DLIC": return "Driver's License #";
        case "DLST": return "Driver's License State";
        case "DRID": return "Driver ID";
        case "EXPT": return "Expense Type";
        case "FSTI": return "First Initial";
        case "GLCD": return "GL Code";
        case "HBRD": return "Hubometer";
        case "HRRD": return "Reefer Hour Reading";
        case "LCST": return "License State";
        case "LICN": return "License #";
        case "LSTN": return "Last Name";
        case "NAME": return "Driver Name";
        case "ODRD": return "Odometer";
        case "OINV": return "Original Invoice";
        case "PONB": return "Purchase Order #";
        case "PPIN": return "Personal Identifier";
        case "RTMP": return "Reefer Temperature";
        case "SSUB": return "Sub Fleet Identifier";
        case "TLOC": return "Terminal Location";
        case "TRIP": return "Trip #";
        case "TRLR": return "Trailer #";
        case "UNIT": return "Unit #";
    }
    return Id;
}

const GetValidationTypeValue = (Id: string): string => {
    switch (Id) {
        case "ALPHABETIC": return "Alphabetic";
        case "ALPHA_NUMERIC": return "Alphanumeric";
        case "NUMERIC": return "Numeric";
        case "REPORT_ONLY": return "Display Only";
        case "EXACT_MATCH": return "Exact Match";
        case "ACCRUAL_CHECK": return "Accrual Check";
    }
    return Id;
}

const GetPopulatedMatchValue = (dataItem: EFSInfoPromptsViewItem): string => {
    if (dataItem.MatchValue) return dataItem.MatchValue;
    if (dataItem.ReportValue) return dataItem.ReportValue;
    //if (dataItem.NumericMatchValue) return dataItem.NumericMatchValue;
    if (dataItem.Value) return dataItem.Value;
}

const InfoIdCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSInfoPromptsViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {GetInfoPromptMappingValue(dataItem.InfoId)}
        </td>
    );
};

const ValidationTypeCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSInfoPromptsViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {GetValidationTypeValue(dataItem.ValidationType)}
        </td>
    );
};

const MatchingValueCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSInfoPromptsViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {GetPopulatedMatchValue(dataItem)}
        </td>
    );
};

const InfoPromptsGrid = (props: Props) => {
    return <div className="container-fluid">
        <Grid data={props.InfoPrompts} style={{ maxHeight: '600px' }}>
            <Column field="InfoId" title="ID" cell={InfoIdCell} />
            <Column field="PolicySource" title="Policy" />
            <Column field="ValidationType" title="Validation Type" cell={ValidationTypeCell} />
            <Column field="MatchValue" title="Value" cell={MatchingValueCell} />
        </Grid>
    </div >

}

export default InfoPromptsGrid;

