import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { AutoComplete, AutoCompleteChangeEvent, ComboBox, DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Input, MaskedTextBox, NumericTextBox, RadioButton } from "@progress/kendo-react-inputs";
import Moment from 'moment-timezone';
import { useDebouncedCallback } from "use-debounce";
import LoadingPanel from "../../../components/LoadingPanel";
import { fetchApi } from "../../../services/api";
import { singleSearch, ISingleSearchLocation } from '../../../services/pcmiler';
import { ILink } from "../../../types/link";
import PayEditable from "./PayEditable";
import { CalculatePayRateResult } from "TypeGen/Pay/calculate-pay-rate-result";
import { VehicleTypeOption } from "TypeGen/Order/Planning/Carrier/vehicle-type-option";
import { PayLineItem } from "TypeGen/Order/Planning/Carrier/pay-line-item";
import { DispatchStatusType } from "TypeGen/Order/dispatch-status-type";
import { JsonResponse } from "TypeGen/json-response";

type OrderPlanningCarrierDetails = {
  CarrierName: string;
  BilledVehicleTypeID: number;
  DispatchStatus: DispatchStatusType;
  DeadHeadStartDateTime: Date;
  BrokeringApprovalRequired: boolean;
  CarrierAgreementEmails: string[];
  PreselectedCarrierAgreementEmail: string;
  LocationOptions: LocationOption[];
  VehicleTypes: VehicleTypeOption[];
  Pay: CalculatePayRateResult;
  ExistingPay: CalculatePayRateResult | null;
  Errors: string[];
  Warnings: string[];
  Links: ILink[];
}

type LocationOption = {
  // For Display Only
  Label: string;
  Name: string;
  DeadHeadMiles: number;

  // Selected
  CityID?: number;
  CustomerID?: number;

  // Manually Entered
  City: string;
  State: string;
  Zip: string;
}

type RouteComponentParams = {
  orderId: string;
  carrierId: string;
};

const firstCheckCallRequiredOptions = [
  { text: "Not Required", value: 0 },
  { text: "1 Hour after first pick-up", value: 60 },
  { text: "2 Hours after first pick-up", value: 120 },
  { text: "3 Hours after first pick-up", value: 180 },
  { text: "4 Hours after first pick-up", value: 240 },
  { text: "8 Hours after first pick-up", value: 480 },
  { text: "12 Hours after first pick-up", value: 720 },
]

const checkCallRequestEveryOptions = [
  { text: '15 Minutes', value: 15 },
  { text: '0.5 Hours', value: 30 },
  { text: '1 Hour', value: 60 },
  { text: '1.5 Hours', value: 90 },
  { text: '2 Hours', value: 120 },
  { text: '2.5 Hours', value: 150 },
  { text: '3 Hours', value: 180 },
  { text: '3.5 Hours', value: 210 },
  { text: '4 Hours', value: 240 },
  { text: '4.5 Hours', value: 270 },
  { text: '5 Hours', value: 300 },
  { text: '5.5 Hours', value: 330 },
  { text: '6 Hours', value: 360 },
  { text: '6.5 Hours', value: 390 },
  { text: '7 Hours', value: 420 },
  { text: '7.5 Hours', value: 450 },
  { text: '8 Hours', value: 480 },
  { text: '8.5 Hours', value: 510 },
  { text: '9 Hours', value: 540 },
  { text: '9.5 Hours', value: 570 },
  { text: '10 Hours', value: 600 },
  { text: '10.5 Hours', value: 630 },
  { text: '11 Hours', value: 660 },
  { text: '11.5 Hours', value: 690 },
  { text: '12 Hours', value: 720 },
  { text: '18 Hours', value: 1080 },
  { text: '24 Hours', value: 1440 },
  { text: '48 Hours', value: 2880 },
  { text: 'Not Required', value: 0 },
]

const yesNoOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
]

const OrderPlanningCarrier = () => {
  const history = useHistory();
  const { orderId: orderIdParam, carrierId: carrierIdParam } = useParams<RouteComponentParams>();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<OrderPlanningCarrierDetails>();

  // Location AutoComplete
  const locationInputRef = useRef<AutoComplete>(null);
  const [locationOptions, setLocationOptions] = useState<ISingleSearchLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<ISingleSearchLocation>(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationValue, setLocationValue] = useState('');

  // Options
  const [locationOption, setLocationOption] = useState<LocationOption>();
  const [deadHeadStartDateTime, setDeadHeadStartDateTime] = useState<Date>();
  const [vehicleType, setVehicleType] = useState(0);
  const [overrideVehicleSpeed, setOverrideVehicleSpeed] = useState(undefined);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [trailerNumber, setTrailerNumber] = useState("");
  const [firstCheckCallRequired, setFirstCheckCallRequired] = useState(0);
  const [checkCallRequiredEvery, setCheckCallRequiredEvery] = useState(60);
  const [brokeringApproved, setBrokeringApproved] = useState(false);
  const [carrierAgreementEMail, setCarrierAgreementEMail] = useState('');
  const [appTrackingPhoneNumber, setAppTrackingPhoneNumber] = useState('');
  const [carrierThreadID, setCarrierThreadID] = useState('');
  const [useExistingPay, setUseExistingPay] = useState<"true" | "false">("false");

  const refreshOrderDetails = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/PlanningDetailsCarrier/${orderIdParam}/${carrierIdParam}`)
      .then((response: OrderPlanningCarrierDetails) => {
        setLocationOption(response.LocationOptions[0]);
        setDeadHeadStartDateTime(Moment.utc(response.DeadHeadStartDateTime).toDate());
        setVehicleType(response.BilledVehicleTypeID);
        setDetails(response);
        setBrokeringApproved(response.BrokeringApprovalRequired ? false : true);
        setCarrierAgreementEMail(response.PreselectedCarrierAgreementEmail);
        if (response.ExistingPay) setUseExistingPay("true");
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam, carrierIdParam]);

  const dispatchOrder = (dispatchStatus: DispatchStatusType) => {

    if (details.BrokeringApprovalRequired && !brokeringApproved) {
      alert('Brokering approval required.');
      return;
    }

    setLoading(true);

    const payload = {
      DispatchStatus: dispatchStatus,
      Location: locationOption,
      DeadHeadStartDateTime: deadHeadStartDateTime,
      VehicleTypeID: vehicleType,
      OverrideVehicleSpeed: overrideVehicleSpeed,
      TrackingNumber: trackingNumber,
      VehicleNumber: vehicleNumber,
      TrailerNumber: trailerNumber,
      FirstCheckCallRequired: firstCheckCallRequired,
      CheckCallRequiredEvery: checkCallRequiredEvery,
      CarrierAgreementEMail: carrierAgreementEMail,
      AppTrackingPhoneNumber: appTrackingPhoneNumber,
      CarrierThreadID: carrierThreadID,
      PayLineItems: (useExistingPay == 'false' || details.ExistingPay == null) ? details.Pay.LineItems.map(x => {
        return {
          RateDescriptionID: x.RateDescriptionID,
          Description: x.Description,
          Rate: x.Rate,
          RateUoM: x.RateUoM,
          Quantity: x.Quantity,
          QuantityUoM: x.QuantityUoM
        } as PayLineItem
      }
      ) : undefined
    };

    const command = details.Links.find(x => x.Name === 'Dispatch' || x.Name === 'Plan');
    fetchApi(command.Link, payload, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          history.push(response.Links.find(x => x.Name === "Track").Link);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to dispatch');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const unPlanOrder = () => {
    setLoading(true);

    const command = details.Links.find(x => x.Name === 'UnPlan');
    fetchApi(command.Link, {}, command.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          history.push(response.Links.find(x => x.Name === "Track").Link);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(e => {
        // If not problem details
        if (!e?.status) alert('Unable to un-plan.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const equipmentValueRender = (span: React.ReactElement<HTMLSpanElement>, value: VehicleTypeOption) => {
    const itemChildren = <>{value?.Name} ({value?.MPH})</>;
    return React.cloneElement(span, span.props, itemChildren);
  }

  const equipmentItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
      <span>
        <>{li.props.children} <span style={{ opacity: 0.75 }}>({itemProps.dataItem?.MPH})</span></>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const renderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = <span>{itemProps.dataItem.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const updateLocationsDebounced = useDebouncedCallback((searchTerm: string) => {
    setLocationLoading(true);
    singleSearch(searchTerm)
      .then((response) => {
        setLocationLoading(false);
        setLocationOptions(response.Locations);
      })
      .catch(() => {
        setLocationLoading(false);
        setLocationOptions([]);
      })
  }, 350);

  // TODO: ETA Warning

  useEffect(() => {
    refreshOrderDetails();
  }, [orderIdParam, carrierIdParam, refreshOrderDetails]);

  if (loading && !details) {
    return <LoadingPanel />;
  }

  return <div className="card my-2">
    <div className="card-body">
      <h5 className="card-title text-center">Plan to {details.CarrierName}</h5>
      {(useExistingPay === "false" && details.Pay.Total === 0) && <div className="alert alert-danger">
        Please set carrier pay rate below before planning.
      </div>}
      {details.Errors.map((error, index) => <div className="alert alert-danger" key={index}>
        {error}
      </div>)}
      {details.Warnings.map((warning, index) => <div className="alert alert-warning" key={index}>
        {warning}
      </div>)}
      {details.LocationOptions.map((x, index) => <div className="form-group row" key={index}>
        <label className="col-form-label form-check-label col-md-3" htmlFor={`location${index}`}>{x.Label}</label>
        <div className="col-md-9 mt-1 pl-4">
          <input
            className="form-check-input"
            type="radio"
            name="locations"
            id={`location${index}`}
            defaultChecked={index === 0}
            onChange={() => {
              setLocationOption(x);
            }}
          />
          <span className="align-middle">
            {x.Name}
          </span>
        </div>
      </div>)}
      <div className="form-group row">
        <label className="col-form-label form-check-label col-md-3" htmlFor="location">Another City, State</label>
        <div className="col-md-9 mt-1 pl-4">
          <input
            className="form-check-input"
            type="radio"
            name="locations"
            id="location"
            onChange={(e) => {
              if (selectedLocation) {
                setLocationOption({
                  Name: '',
                  Label: 'Another City, State',
                  DeadHeadMiles: 0,
                  City: selectedLocation.Address.City,
                  State: selectedLocation.Address.State,
                  Zip: selectedLocation.Address.Zip,
                });
              } else {
                e.preventDefault();
                locationInputRef.current?.focus();
              }
            }}
            checked={locationOption?.Label === "Another City, State"}
          />
          <AutoComplete
            ref={locationInputRef}
            data={locationOptions}
            value={locationValue}
            loading={locationLoading}
            placeholder={`Enter Location`}
            itemRender={renderLocation}
            onChange={(event: AutoCompleteChangeEvent) => {

              // Update Location
              let location = event.target.value;
              if (typeof event.target.value === 'object') {
                const place = event.target.value as ISingleSearchLocation;
                location = place.Address.Zip + ' ' + place.Address.City + ', ' + place.Address.State;
                setLocationOption({
                  Name: '',
                  Label: 'Another City, State',
                  DeadHeadMiles: 0,
                  City: place.Address.City,
                  State: place.Address.State,
                  Zip: place.Address.Zip,
                });

                // This value is stored so they can select a different option and this option again
                setSelectedLocation(place);
              } else if (!event.target.value) {
                // Clear Selected Location
                setSelectedLocation(null);
              }

              setLocationValue(location);

              // Update Autocomplete Suggestions
              if (typeof event.target.value !== 'object' && event.target.value.length > 2) {
                updateLocationsDebounced(event.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="deadHeadStartTime" className="col-form-label col-md-3">Dead Head Start Time</label>
        <div className="col-md-9">
          <DateTimePicker
            required
            width={210}
            format="MM/dd/yyyy HH:mm"
            id="deadHeadStartTime"
            value={deadHeadStartDateTime}
            onChange={(e) => setDeadHeadStartDateTime(e.value)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="trackingNumber" className="col-form-label col-md-3">Equipment</label>
        <div className="col-md-9">
          <DropDownList
            style={{ width: 210 }}
            fillMode="outline"
            onChange={x => {
              setVehicleType(x.value.VehicleTypeID);
            }}
            value={details.VehicleTypes.find(x => x.VehicleTypeID === vehicleType)}
            data={details.VehicleTypes}
            textField="Name"
            valueRender={equipmentValueRender}
            itemRender={equipmentItemRender}
          />
          <span className="ml-2">
            Override Speed:
            <NumericTextBox
              className="ml-1"
              width={150}
              min={0}
              max={1000}
              format="n0"
              placeholder="MPH"
              value={overrideVehicleSpeed}
              onChange={x => setOverrideVehicleSpeed(x.value)}
            />
          </span>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="trackingNumber" className="col-form-label col-md-3">Tracking No</label>
        <div className="col-md-9">
          <Input
            id="trackingNumber"
            style={{ width: 210 }}
            maxLength={30}
            onChange={x => setTrackingNumber(x.value)}
            value={trackingNumber}
            placeholder="Tracking No"
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="vehicleNumber" className="col-form-label col-md-3">Vehicle Number</label>
        <div className="col-md-9">
          <Input
            id="vehicleNumber"
            style={{ width: 210 }}
            maxLength={15}
            onChange={x => setVehicleNumber(x.value)}
            value={vehicleNumber}
            placeholder="Vehicle Number"
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="trailerNumber" className="col-form-label col-md-3">Trailer Number</label>
        <div className="col-md-9">
          <Input
            id="trailerNumber"
            style={{ width: 210 }}
            maxLength={15}
            onChange={x => setTrailerNumber(x.value)}
            value={trailerNumber}
            placeholder="Trailer Number"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-3">First Check Call Required</label>
        <div className="col-md-9">
          <DropDownList
            style={{ width: 210 }}
            fillMode="outline"
            onChange={x => setFirstCheckCallRequired(x.value.value)}
            value={firstCheckCallRequiredOptions.find(x => x.value === firstCheckCallRequired)}
            data={firstCheckCallRequiredOptions}
            textField="text"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-3">Check Calls Required Every</label>
        <div className="col-md-9">
          <DropDownList
            style={{ width: 210 }}
            fillMode="outline"
            onChange={x => setCheckCallRequiredEvery(x.value.value)}
            value={checkCallRequestEveryOptions.find(x => x.value === checkCallRequiredEvery)}
            data={checkCallRequestEveryOptions}
            textField="text"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-3">Carrier Agreement Email</label>
        <div className="col-md-9">
          <ComboBox
            clearButton={!!carrierAgreementEMail}
            allowCustom
            placeholder="person@company.com (optional)"
            data={details.CarrierAgreementEmails}
            value={carrierAgreementEMail}
            onChange={(e) => {
              setCarrierAgreementEMail(e.target.value || '');
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-3">App Tracking Phone</label>
        <div className="col-md-9">
          <MaskedTextBox
            placeholder="Driver's Cell Phone (optional)"
            validationMessage="Please enter a valid phone number!"
            mask="(000) 000-0000"
            width={210}
            value={appTrackingPhoneNumber}
            onChange={(e) => e.value === '(___) ___-____' ? setAppTrackingPhoneNumber('') : setAppTrackingPhoneNumber(e.value)}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-3">Carrier Front ID</label>
        <div className="col-md-9">
          <Input
            style={{ width: 210 }}
            maxLength={17}
            onChange={x => setCarrierThreadID(x.value)}
            value={carrierThreadID}
            placeholder="cnv_123456"
          />
        </div>
      </div>
      {details.BrokeringApprovalRequired && <div className="form-group row">
        <label className="col-form-label col-md-3">Brokering Approved?</label>
        <div className="col-md-9">
          <DropDownList
            style={{ width: 210 }}
            fillMode="outline"
            valid={brokeringApproved}
            onChange={x => setBrokeringApproved(x.value.value)}
            value={yesNoOptions.find(x => x.value === brokeringApproved)}
            data={yesNoOptions}
            textField="text"
          />
        </div>
      </div>}
      {details.ExistingPay && <>
        <RadioButton
          className="my-2"
          name="existingPay"
          value="true"
          checked={useExistingPay === "true"}
          label="Use Existing Pay"
          onChange={(e) => setUseExistingPay(e.value)}
        />
        <PayEditable
          editable={false}
          data={details.ExistingPay}
        />
        <br />
        <RadioButton
          className="my-2"
          name="existingPay"
          value="false"
          checked={useExistingPay === "false"}
          label="Set New Pay"
          onChange={(e) => setUseExistingPay(e.value)}
        />
      </>}
      <PayEditable
        data={details.Pay}
        onChange={(pay) => {
          setDetails({ ...details, Pay: pay });
          setUseExistingPay("false");
        }}
      />
      <div className="text-center mt-3">
        <Button themeColor="secondary" onClick={() => history.push(`/Order/Planning/${orderIdParam}`)}>
          Back
        </Button>
        {details.Links.find(x => x.Name === 'UnPlan') && <Button
          themeColor="error"
          className="ml-2"
          onClick={unPlanOrder}
          disabled={loading}
        >
          Un-Plan Order
        </Button>}
        {details.Links.find(x => x.Name === 'Plan') && <DropDownButton
          themeColor="primary"
          buttonClass="ml-2"
          disabled={details.Errors.length > 0 || (useExistingPay === "false" && details.Pay.Total === 0) || loading}
          onItemClick={(e) => dispatchOrder(e.itemIndex === 0 ? DispatchStatusType.PlannedReady : DispatchStatusType.PlannedOnHold)}
          text="Plan Order"
        >
          <DropDownButtonItem text="Auto Dispatch" disabled={details.DispatchStatus === DispatchStatusType.PlannedReady} />
          <DropDownButtonItem text="On-Hold Dispatch" disabled={details.DispatchStatus === DispatchStatusType.PlannedOnHold} />
        </DropDownButton>}
        {details.Links.find(x => x.Name === 'Dispatch') && <Button
          themeColor="success"
          className="ml-2"
          disabled={details.Errors.length > 0 || (useExistingPay === "false" && details.Pay.Total === 0) || loading}
          onClick={() => dispatchOrder(DispatchStatusType.Started)}
        >
          Dispatch Order
        </Button>}
      </div>
    </div>
  </div>
}

export default OrderPlanningCarrier;