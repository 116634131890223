import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { isNullOrWhiteSpace } from "../../../utils/utils";

type Props = {
  CloseDialog: (note: string) => void;
};

const RejectPurchaseOrderDialog = (props: Props) => {
  const [newNote, setNewNote] = useState<string>('');

  return <Dialog title={`Reject P.O.`} onClose={() => props.CloseDialog('')} className="dialog-w525">
    <div>
      <TextArea
        required
        value={newNote}
        style={{ width: "100%" }}
        className="mt-2"
        placeholder="Reject Reason"
        onChange={(e) => setNewNote(e.value)}
      />
    </div>
    <DialogActionsBar layout="end">
      <Button themeColor="primary" disabled={isNullOrWhiteSpace(newNote)} onClick={() => props.CloseDialog(newNote)}>Reject P.O.</Button>
      <Button onClick={() => props.CloseDialog('')}>Cancel</Button>
    </DialogActionsBar>
  </Dialog>
}

export default RejectPurchaseOrderDialog;