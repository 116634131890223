import './OrderAuditLog.css';
import * as React from 'react';
import { ILink } from '../../types/link';
import { fetchApi } from '../../services/api';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridToolbar, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import {
  CompositeFilterDescriptor,
  filterBy
} from '@progress/kendo-data-query';
import Moment from 'moment';
import { openWindow } from '../../services/openWindow';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

//Parent passing params
type Props = {
  Link: ILink;
  CloseDialog: () => void;
}

type State = {
  data: OrderAuditLog[];
  Commands: ILink[];
  OrderAuditLogs: OrderAuditLog[];
  Filter_Button_Value: number[];
  Filter_SearchBox: string;
  Filter_LastNumberOfDays: number;
}

type OrderAuditLogResponse = {
  //User: string,
  //OrderId: number,
  //OrderNumber: number,
  //OrderStatusLabel: string,
  LastNumberOfDays: number,
  OrderAuditLogRequest: OrderAuditLog,
  OrderAuditLogs: OrderAuditLog[];
  Commands: ILink[];
}

type OrderAuditLog = {
  OrderAuditLogID: number;

  OrderNumber: number;
  OrderStatusLabel: string;
  LastModifiedDT: string;
  LastModifiedUser: string;
  Notes: string;
  LogStatus: number;
  OrderStatus: number;
  OrderId: number;
  Links: ILink[];
  // For Grid
  inEdit: boolean | undefined;
  ccBillingNotes: boolean | undefined;
}

export class OrderAuditLogGrid extends React.Component<Props, State> {

  CommandCell: typeof React.Component;
  GenericLabelCell: typeof React.Component;
  DateTimeCell: typeof React.Component;
  ProNumberCell: typeof React.Component;
  OrderLogStatusCell: typeof React.Component;
  OrderStatusCell: typeof React.Component;
  TextareaCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      Commands: [],
      OrderAuditLogs: [],
      Filter_Button_Value: [],
      Filter_SearchBox: "",
      Filter_LastNumberOfDays: 30
    };

    this.enterInsert = this.enterInsert.bind(this);
    this.itemChange = this.itemChange.bind(this);

    const enterEdit = this.enterEdit.bind(this);
    const save = this.save.bind(this);
    const cancel = this.cancel.bind(this);
    const remove = this.remove.bind(this);

    //const toggleButtonFilter = this.toggleButtonFilter.bind(this);

    class MyCommandCell extends React.Component<GridCellProps> {
      public render() {
        return !this.props.dataItem.inEdit ?
          (<td className="k-command-cell">
            <Button
              themeColor="primary"
              className="k-grid-edit-command"
              onClick={(e) => enterEdit(e, this.props.dataItem)}
            > Edit</Button>
          </td>
          ) : (
            <td className="k-command-cell">
              <Button
                className="k-grid-save-command"
                onClick={(e) => save(e, this.props.dataItem)}
              > {this.props.dataItem.OrderAuditLogID ? 'Update' : 'Add'}</Button>
              <Button
                className="k-grid-cancel-command"
                onClick={(e) => cancel(this.props.dataItem)}
                style={{ display: this.props.dataItem.OrderAuditLogID ? '' : 'none' }}
              >Cancel</Button>
              {/**<Button
                className="k-grid-cancel-command"
                onClick={(e) => cancel(this.props.dataItem)}
              >{this.props.dataItem.OrderAuditLogID ? 'Cancel' : 'Discard'}</Button>**/}
            </td>
          );
      }
    };
    this.CommandCell = MyCommandCell;

    class ProNumberCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];
        return (
          <td>
            {(this.props.dataItem.Links.find((x: ILink) => x.Name === 'NewLoad') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                openWindow((this.props.dataItem.Links.find((x: ILink) => x.Name === 'NewLoad') as ILink).Link);
                e.preventDefault();
              }}
            >{value}
            </a>) || value}
          </td>
        );
      }
    }
    this.ProNumberCell = ProNumberCell;

    class OrderStatusCell extends React.Component<GridCellProps> {
      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];
        return (
          <td>
            {this.props.dataItem.Links.find((x: ILink) => x.Name === 'BillAnOrder') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                openWindow((this.props.dataItem.Links.find((x: ILink) => x.Name === 'BillAnOrder') as ILink).Link);
                e.preventDefault();
              }}
            >{value}
            </a> || value}
          </td>
        );
      }
    }
    this.OrderStatusCell = OrderStatusCell;

    class OrderLogStatusCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value: number = this.props.dataItem[this.props.field];
        if (!this.props.dataItem.inEdit) {
          return (
            <td>
              <div style={{ textAlign: "center" }}>
                {this.GetButtons(value, true)}
                {/* make false to disallow Edit */}
              </div>
            </td>
          );
        }

        return (
          <td>
            <div style={{ textAlign: "center" }}>
              {this.GetButtons(value, true)}
            </div>
          </td>
        );

      }

      private GetButtons(logstatus: number, inEdit: boolean) {
        switch (logstatus) {
          case 1: //Completed
            return (
              <div>
                <span className="greeninactivebutton greenbutton" title="Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="greyactivebutton greybutton" title="In-Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="redinactivebutton redbutton" title="Completed Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>
              </div>
            );
          case 2: //Cancelled
            return (
              <div>
                <span className="greeninactivebutton greenbutton" title="Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="greyinactivebutton greybutton" title="In-Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="redactivebutton redbutton" title="Completed Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>
              </div>
            );
          case 0: //In-Progress
            return (
              <div>
                <span className="greenactivebutton greenbutton" title="Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="greyinactivebutton greybutton" title="In-Active Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="redinactivebutton redbutton" title="Completed Log" onClick={(e) => enterEdit(e, this.props.dataItem)}></span>
              </div>
            );
          default:
            return null;
        }
      }
    }
    this.OrderLogStatusCell = OrderLogStatusCell;

    class DateTimeCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];

        return (
          <td>
            {Moment(value).format("MM/DD/YYYY HH:mm")}
          </td>
        );
      }
    }
    this.DateTimeCell = DateTimeCell;

    class GenericLabelCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];

        return (
          <td> {
            (value === null) ? '' : this.props.dataItem[this.props.field].toString()}
          </td>
        );
      }
    }
    this.GenericLabelCell = GenericLabelCell;

    class TextareaCell extends React.Component<GridCellProps> {

      public render() {
        if (!this.props.field) return null;

        const value = this.props.dataItem[this.props.field];

        if (!this.props.dataItem.inEdit) {
          return (
            <td onClick={(e) => enterEdit(e, this.props.dataItem)}> {
              (value === null) ? '' : this.props.dataItem[this.props.field].toString()}
            </td>
          );
        }

        return (
          <td>
            <textarea
              className="k-textarea k-textarea-note"
              onBlur={this.handleChange.bind(this)}
              onFocus={this.handleChange2.bind(this)}
              placeholder={"enter notes..."}
              value={value}
            // onFocus={(e) => e.target.value = this.props.dataItem.Notes}
            /><br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                style={{ marginTop: "2px" }}
                type="checkbox"
                defaultChecked={this.setCcBillingNoteState(this.props.dataItem)}
                onChange={(e) => this.props.dataItem.ccBillingNotes = e.target.checked}
              />
              <label className="form-check-label">cc: billing notes</label>
            </div>
          </td>
        );
      }

      private setCcBillingNoteState(dataItem: any) {
        let di = (dataItem as OrderAuditLog);
        if (di.OrderAuditLogID) {
          di.ccBillingNotes = false;
          return false;
        } else {
          di.ccBillingNotes = true;
          return true;
        }
      }

      private handleChange(e: any) {
        if (this.props.onChange) {
          this.props.onChange({
            dataItem: this.props.dataItem,
            dataIndex: this.props.dataIndex,
            field: this.props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      }

      private handleChange2(e: any) {
        if (this.props.onChange) {
          this.props.onChange({
            dataItem: this.props.dataItem,
            dataIndex: this.props.dataIndex,
            field: this.props.field,
            syntheticEvent: e.syntheticEvent,
          });
        }
      }
    }
    this.TextareaCell = TextareaCell;
  }

  public componentDidMount() {
    this.InternalDidMount(true);
  }

  public InternalDidMount(startup: boolean) {
    fetchApi(this.props.Link.Link, { LastNumberOfDays: this.state.Filter_LastNumberOfDays }, "POST")
      .then((response: OrderAuditLogResponse) => {
        const data = response.OrderAuditLogs.slice(0);

        this.setState({
          data,
          Commands: response.Commands,
          OrderAuditLogs: response.OrderAuditLogs,
          Filter_LastNumberOfDays: response.LastNumberOfDays
        });

        if (startup) {
          this.toggleButtonFilter(null, 0);
          this.toggleButtonFilter(null, 1);
        }

        let originalItem = this.state.OrderAuditLogs.find(p => p.OrderNumber === response.OrderAuditLogRequest.OrderNumber);
        if (typeof originalItem === "undefined") {
          this.enterInsert(response.OrderAuditLogRequest.OrderNumber, response.OrderAuditLogRequest.OrderId, response.OrderAuditLogRequest.LastModifiedUser, response.OrderAuditLogRequest.OrderStatusLabel, response.OrderAuditLogRequest.Links);
        }

        this.filterDataBasedOnButtonToggle();
      })
      .catch(error => {
        console.error('Fetch Error =\n', error);
      });
  }

  private filterDropDownOnChange(e: DropDownListChangeEvent) {
    this.setState({ Filter_LastNumberOfDays: Number(e.target.value.value) }, () => this.InternalDidMount(false));
  }

  sizes = [
    { text: '60', value: '60' },
    { text: '90', value: '90' },
    { text: '120', value: '120' }
  ];
  public render() {
    return (
      <Dialog title="Order Audit Logs" onClose={this.props.CloseDialog} width="90%">
        <Grid
          resizable={true}
          style={{ maxHeight: '700px' }}
          data={this.state.data}
          onItemChange={this.itemChange}
          editField="inEdit"
        >
          <GridToolbar>
            <label className="col-form-label col-form-label-lg">Filters:&nbsp;&nbsp;&nbsp;</label>
            <span className={this.toggleButtonClass(0)} title="Active Log" onClick={(e) => this.toggleButtonFilter(e, 0)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={this.toggleButtonClass(1)} title="In-Active Log" onClick={(e) => this.toggleButtonFilter(e, 1)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className={this.toggleButtonClass(2)} title="Completed Log" onClick={(e) => this.toggleButtonFilter(e, 2)}></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input className="k-textbox k-textbox-search" placeholder="Search... " value={this.state.Filter_SearchBox} onChange={(e) => this.toggleButtonFilter(e, -1)} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="form-check form-check-inline">
              <label className="form-check-label">Last</label>&nbsp;
              <DropDownList
                className="form-check-input dropdownlist-OrderAuditLog"
                data={this.sizes}
                textField="text"
                defaultItem={{ text: '30', value: '30' }}
                onChange={(e) => this.filterDropDownOnChange(e)}
              />
              <label className="form-check-label">Days</label>
            </div>
          </GridToolbar>
          <Column field="OrderNumber" title="Pro No." cell={this.ProNumberCell} />
          <Column field="OrderStatusLabel" title="Order Status" cell={this.OrderStatusCell} />
          <Column field="LastModifiedDT" title="Last Modified" cell={this.DateTimeCell} />
          <Column field="LastModifiedUser" title="Modified By" cell={this.GenericLabelCell} />
          <Column field="Notes" title="Notes" width="350px" cell={this.TextareaCell} />
          <Column field="LogStatus" title=" " cell={this.OrderLogStatusCell} />
          <Column cell={this.CommandCell} width="160px" />
        </Grid>
      </Dialog>
    );
  }

  private enterInsert(orderNumber: number, orderId: number, user: string, orderStatusLabel: string, links: ILink[]) {
    let dataItem = {
      OrderAuditLogID: 0,
      OrderNumber: orderNumber,
      OrderStatusLabel: orderStatusLabel,
      LastModifiedDT: Moment().format(),
      LastModifiedUser: user,
      Notes: "",
      LogStatus: 0,
      OrderStatus: 0,
      OrderId: orderId,
      Links: links,
      // For Grid
      inEdit: true,
      ccBillingNotes: true
    };

    const newDataItems = this.state.data.slice();
    newDataItems.unshift(Object.assign({}, dataItem));

    const newOrderAuditLogs = this.state.OrderAuditLogs.slice();
    newOrderAuditLogs.unshift(Object.assign({}, dataItem));

    this.setState({
      data: newDataItems,
      OrderAuditLogs: newOrderAuditLogs
    });
  }

  private hasClassName(className: string, lookUp: string) {
    return (className.search(new RegExp(lookUp, "i")) > -1);
  }

  private toggleButtonClass(option: number) {
    let className = "";
    switch (option) {
      case 0:
        className = "green";
        break;
      case 1:
        className = "grey";
        break;
      default:
        className = "red";
        break;
    }
    let isSelected = (this.state.Filter_Button_Value.findIndex(p => p === option) > -1);
    let status = (isSelected) ? "active" : "inactive";
    return className + status + "button";
  }

  private toggleButtonFilter(e: any, option: number) {
    if (option > -1) { //-1 = Text Search || Text Search is blanked out // || e.target.value.trim().length === 0
      var filterList = this.state.Filter_Button_Value.slice();

      var fi = filterList.findIndex(p => p === option);
      if (fi > -1) {
        filterList.splice(fi, 1);
      } else {
        filterList.unshift(option);
      }
      this.setState({
        Filter_Button_Value: filterList
      }, this.filterDataBasedOnButtonToggle);
    } else {
      this.setState({ Filter_SearchBox: e.target.value }, this.filterDataBasedOnButtonToggle);
      //if (e.target.value && e.target.value.trim().length >= 7 && Number.isInteger(e.target.value.trim())) {
      //  this.setState({ Filter_SearchBox: e.target.value.trim() }, this.filterDataBasedOnButtonToggle);
      //} else if (e.target.value.trim().length === 0) {
      //  this.setState({ Filter_SearchBox: "" }, this.filterDataBasedOnButtonToggle);
      //} else
      //  this.setState({ Filter_SearchBox: e.target.value.trim() });
    }
  }

  private filterDataBasedOnButtonToggle() {
    const queryFilters = [];

    //if (this.state.Filter_SearchBox.length >= 7 && !isNaN(Number(this.state.Filter_SearchBox))) {
    //queryFilters.push({ logic: "and", filters: [{ field: "OrderNumber", operator: "eq", value: parseInt(this.state.Filter_SearchBox) }] });
    if (this.state.Filter_SearchBox.length > 2) {
      queryFilters.push({ logic: "and", filters: [{ field: "Notes", operator: "contains", value: this.state.Filter_SearchBox }] });
      if (!isNaN(Number(this.state.Filter_SearchBox)))
        queryFilters.push({ logic: "and", filters: [{ field: "OrderNumber", operator: "eq", value: Number(this.state.Filter_SearchBox) }] });
    } else {
      if (this.state.Filter_Button_Value.findIndex(p => p === 0) > -1)
        queryFilters.push({ logic: "and", filters: [{ field: "LogStatus", operator: "eq", value: 0 }] });
      if (this.state.Filter_Button_Value.findIndex(p => p === 1) > -1)
        queryFilters.push({ logic: "and", filters: [{ field: "LogStatus", operator: "eq", value: 1 }] });
      if (this.state.Filter_Button_Value.findIndex(p => p === 2) > -1)
        queryFilters.push({ logic: "and", filters: [{ field: "LogStatus", operator: "eq", value: 2 }] });
      //if (queryFilters !== undefined && queryFilters.length > 0)
      //queryFilters.push({ logic: "and", filters: [{ field: "OrderNumber", operator: "eq", value: this.props.OrderNumber }] }); //Always show props requesting order number
    }

    var parentFilter = {
      logic: "or",
      filters: queryFilters
    } as CompositeFilterDescriptor;


    if (queryFilters === undefined || queryFilters.length == 0) {
      this.setState({ data: this.state.OrderAuditLogs });
    } else {
      //parentFilter.filters = [];
      // parentFilter.filters.push({ logic: "and", filters: [{ field: "OrderNumber", operator: "eq", value: this.props.OrderNumber }] });
      var filteredList = filterBy(this.state.OrderAuditLogs, parentFilter);
      this.setState({ data: filteredList });
    }
  }

  private enterEdit(e: any, dataItem: OrderAuditLog) {
    let targetClassName = e.target.className;
    this.update(this.state.data, dataItem).then((data) => {
      data.inEdit = true;

      if (this.hasClassName(targetClassName, "greenbutton")) {
        data.LogStatus = 0; //inprogress
      } else if (this.hasClassName(targetClassName, "greybutton")) {
        data.LogStatus = 1; //completed
      } else if (this.hasClassName(targetClassName, "redbutton")) {
        data.LogStatus = 2; //cancelled
      }

      let index = this.state.OrderAuditLogs.findIndex((p) => p.OrderAuditLogID === data.OrderAuditLogID);
      this.state.OrderAuditLogs[index].inEdit = true;

      this.setState({
        data: this.state.data.slice(), //updating state to show record as inEditMode
        OrderAuditLogs: this.state.OrderAuditLogs.slice()
      });
    });
  }

  //TODO: Fix coding.  Needs to be enhanced.  Don't use default setup.
  private cancel(dataItem: OrderAuditLog) {
    if (dataItem.OrderAuditLogID) {
      let originalItem = this.state.OrderAuditLogs.find(p => p.OrderAuditLogID === dataItem.OrderAuditLogID);
      (originalItem as OrderAuditLog).inEdit = false;
      this.update(this.state.data, originalItem as OrderAuditLog);
    } else {
      this.update(this.state.data, dataItem, !dataItem.OrderAuditLogID);
    }
    this.setState({
      data: this.state.data.slice()
    });
  }

  private remove(dataItem: OrderAuditLog) {
    dataItem.inEdit = undefined;
    this.update(this.state.data, dataItem, true);
    this.update(this.state.OrderAuditLogs, dataItem, true);
    this.setState({
      data: this.state.data.slice()
    });
    if (dataItem.OrderAuditLogID) {
      const command = this.state.Commands.find(x => x.Name === 'Delete') as ILink;
      fetchApi(command.Link, dataItem, command.Method);
    }
  }

  private itemChange(event: GridItemChangeEvent) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.data.slice();
    this.update(updatedData, event.dataItem).then((item) => {
      (item as any)[name] = value;
      this.setState({
        data: updatedData
      });
    });
  }

  private async save(e: any, dataItem: OrderAuditLog) {
    if (dataItem.Notes.trim().length === 0) {
      let eTarget = e.target as HTMLElement;
      //From button to parent TR to child textarea
      let closestAncestor = ((eTarget.closest('tr') as HTMLElement).querySelector('textarea') as HTMLElement);
      closestAncestor.setAttribute("placeholder", "Note field cannot be blank....");
    } else {
      let index = 0;
      dataItem.inEdit = undefined;

      if (dataItem.OrderAuditLogID) {
        const command = this.state.Commands.find(x => x.Name === 'Update') as ILink;
        await fetchApi(command.Link, dataItem, command.Method).then((response) => {
          let dataItemResponse: OrderAuditLog = response;
          index = this.state.data.findIndex((p) => p.OrderAuditLogID === dataItemResponse.OrderAuditLogID);
          this.state.data[index] = Object.assign({}, dataItemResponse);
          index = this.state.OrderAuditLogs.findIndex((p) => p.OrderAuditLogID === dataItemResponse.OrderAuditLogID);
          this.state.OrderAuditLogs[index] = Object.assign({}, dataItemResponse);
          this.setState({
            data: this.state.data.slice(),
            OrderAuditLogs: this.state.OrderAuditLogs.slice()
          }, this.filterDataBasedOnButtonToggle);
        }).catch(error => {
          console.error('Fetch Error =\n', error);
        });
      } else {
        const command = this.state.Commands.find(x => x.Name === 'Add') as ILink;
        await fetchApi(command.Link, dataItem, command.Method).then((response) => {
          let dataItemResponse: OrderAuditLog = response;
          this.state.data[0] = Object.assign({}, dataItemResponse);
          this.state.OrderAuditLogs[0] = Object.assign({}, dataItemResponse);
          //this.state.OrderAuditLogs.unshift(Object.assign({}, dataItemResponse));
          this.setState({
            data: this.state.data.slice(),
            OrderAuditLogs: this.state.OrderAuditLogs.slice()
          }, this.filterDataBasedOnButtonToggle);
        }).catch(error => {
          console.error('Fetch Error =\n', error);
        });
      }
    }
  }

  private async update(data: OrderAuditLog[], item: OrderAuditLog, remove: boolean = false) {
    let updated;
    let index = data.findIndex((p) => p === item || item.OrderAuditLogID !== 0 && p.OrderAuditLogID === item.OrderAuditLogID);
    updated = Object.assign({}, item);
    data[index] = updated;
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
}
