import {
    Card,
    CardBody, CardTitle
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { fetchApi } from "../../services/api";
import { SecurityGroupViewModel } from "../../TypeGen/AccountSettings/security-group-view-model";
import SecurityGroupUsers from "./SecurityGroupUsers";

interface Props {
  IsAdmin: boolean,
  SecurityGroups?: SecurityGroupViewModel[] | null
}

const SecurityGroups: React.FC<Props> = ({ IsAdmin, SecurityGroups = null }) => {
  const [loading, setLoading] = useState(true);
  const [securityGroups, setSecurityGroups] = useState<SecurityGroupViewModel[]>();
  const [securityGroup, setSecurityGroup] = useState<null | SecurityGroupViewModel>();
  const [inViewAllSecurityGroupsMode, setInViewAllSecurityGroupsMode] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (SecurityGroups === null) {
      setInViewAllSecurityGroupsMode(true);
      fetchApi(`/api/Account/SecurityGroups`)
        .then((data: { SecurityGroups: SecurityGroupViewModel[] }) => {
          setLoading(false);
          setSecurityGroups(data.SecurityGroups);
        })
        .catch(e => {
          setLoading(false);
          if (!e?.status)
            alert('Error fetching data.');
          else if (e.status !== 404) {
            alert(e?.detail);
          }
        });
    } else {
      setSecurityGroups(SecurityGroups);
      setLoading(false);
    }
  }, [SecurityGroups]);

  return (
    <div>
      {loading ? <div></div> : securityGroups?.length > 0 ? <div>
        {securityGroups.map((_, index) => {
          if (index % 3 === 0) {
            return (
              <div className="row mt-2" key={`row-${index}`}>
                {securityGroups.slice(index, index + 3).map((group, subIndex) => (
                  <div className="col-4" style={{}} key={`col-${index + '-' + subIndex}`}>
                    <Card
                      style={{ height: '100%', cursor: (IsAdmin || inViewAllSecurityGroupsMode == false) ? 'pointer' : 'default' }}
                      onClick={() => (IsAdmin || inViewAllSecurityGroupsMode == false) && setSecurityGroup(group)}
                    >
                      <CardBody>
                        <CardTitle style={{ fontSize: '15px' }}>{group.Name}</CardTitle>
                        <p>
                          {group.Description}
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </div>
            );
          }
          return null;
        })}
      </div> : <div>None</div>}

      {securityGroup && <SecurityGroupUsers groupId={securityGroup.GroupID} groupName={securityGroup.Name}
        CloseDialog={() => setSecurityGroup(null)} />}

    </div>);
}

export default SecurityGroups;