import * as React from 'react';
import { fetchApi } from '../../../services/api';
import { Button } from '@progress/kendo-react-buttons';
import LoadingPanel from '../../../components/LoadingPanel';
import { SelectedCarrierValue } from '../CarrierGrid';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { JsonResponse } from 'TypeGen/json-response';
import { IDNameViewModel } from 'TypeGen/id-name-view-model';
import { DocumentType } from 'TypeGen/Documents/document-type';

interface Props {
  SelectedCarrier: SelectedCarrierValue | null,
  CloseDialog: () => void;
}

interface State {
  isLoading: boolean;
  emails: string[];
  DocumentTypeID: number;
  Email: string;
  MCNumber: string;
}

const DocumentTypes: IDNameViewModel[] = [
  { ID: DocumentType.CarrierBrokerageAgreement, Name: "Brokerage Carrier Agreement" },
  { ID: DocumentType.CarrierProfile, Name: "Carrier Profile" }
]

export class SendBrokerageCarrierAgreementDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      emails: [],
      DocumentTypeID: -1,
      Email: '',
      MCNumber: ''
    }

    this.get = this.get.bind(this);
    this.send = this.send.bind(this);
  }

  public componentDidMount() {
    this.get();
  }

  public render() {
    return (
      <Dialog title="Send Carrier Document" onClose={this.props.CloseDialog} width={500}>
        {this.state.isLoading && <LoadingPanel />}
        <div className="form-group row">
          <label className="col-md-4 col-form-label">Document Type</label>
          <div className="col-md-8">
            <DropDownList
              required
              data={DocumentTypes}
              textField="Name"
              dataItemKey='ID'
              value={DocumentTypes.find(x => x.ID === this.state.DocumentTypeID)}
              onChange={(e) => this.setState({ DocumentTypeID: e.target.value.ID })}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-md-4 col-form-label">Signer Email</label>
          <div className="col-md-8">
            <ComboBox
              onChange={(e) => this.setState({ Email: e.value })}
              allowCustom={true}
              placeholder={"someone@company.com"}
              value={this.state.Email}
              data={this.state.emails}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-md-4 col-form-label">Carrier MC #</label>
          <div className="col-md-8">
            <Input
              required
              value={this.state.MCNumber}
              onChange={(e) => this.setState({ MCNumber: e.value })}
              placeholder="MC #"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <DialogActionsBar layout="end">
          <Button themeColor="primary" onClick={this.send}>Request Signature</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  private get() {

    // Send to unknown Carrier
    if (this.props.SelectedCarrier === null) return;

    this.setState({ isLoading: true });

    fetchApi(`/api/Asset/BrokerageCarrierAgreement/${this.props.SelectedCarrier.CarrierID}`)
      .then((response) => {
        this.setState({
          emails: response.Emails,
          MCNumber: response.MCNumber || '',
          isLoading: false
        });
      });
  }

  private send() {

    if (this.state.DocumentTypeID === -1) {
        alert("Please select a document type!");
        return;
    }

    if (!this.state.Email) {
        alert("Please select/enter a valid email!");
        return;
    }

    if (!this.state.MCNumber) {
        alert("Please enter a Motor Carrier number!");
        return;
    }

    if (this.state.isLoading) {
        return;
    }

    this.setState({ isLoading: true });

    const data = {
        CarrierID: this.props.SelectedCarrier?.CarrierID,
        MCNumber: this.state.MCNumber,
        EMailAddress: this.state.Email.trim(),
    };

    var url = this.state.DocumentTypeID === DocumentType.CarrierBrokerageAgreement ? `/api/Asset/BrokerageCarrierAgreement`
      : this.state.DocumentTypeID === DocumentType.CarrierProfile ? `/api/Asset/CarrierProfile` : '';
    fetchApi(url, data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.setState({ isLoading: false }, () => {
            this.props.CloseDialog();
          })
        } else {
          this.setState({ isLoading: false });
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // If not problem details
        if (!e?.status) alert("Unable to Send Carrier Document!");
      });
  }
}
