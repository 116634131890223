import { Title } from '../../utils/title';
import TimeAgo from 'react-timeago';
import Moment from 'moment';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { DateRangePicker, SelectionRange } from '@progress/kendo-react-dateinputs';
import { fetchApi } from '../../services/api';
import LoadingPanel from '../../components/LoadingPanel';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ILink } from '../../types/link';
import { openWindow } from '../../services/openWindow';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { JsonResponse } from 'TypeGen/json-response';

type Params = {
  defectId?: string;
};

type DefectListingViewModel = {
  DefectId: number;
  AssetType: AssetType;
  AssetNumber: string;
  DefectName: string;
  InspectionDateTime: Date;
  Status: DefectStatus;
  ReportedNote: string;
}

type DefectViewModel = {
  DefectId: number;
  AssetType: AssetType;
  AssetNumber: string;
  InspectedByDriverNumber: string;
  DefectName: string;
  InspectionDateTime: Date;
  Status: DefectStatus;
  ReportedNote: string;
  Vendor: string;
  CorrectionNote: string;
  CorrectedDateTime?: Date;
  ApprovedDateTime?: Date;

  Links: ILink[];
}

enum AssetType {
  Vehicle,
  Trailer
}

enum DefectStatus {
  Reported,
  Corrected,
  Approved
}

const AssetInspections = () => {

  const { defectId: defectIdParam } = useParams<Params>();

  const defectFilter = [
    { ID: 0, Name: "Reported" },
    { ID: 1, Name: "Corrected" },
    { ID: 2, Name: "Approved" }
  ] as Array<{ ID: number; Name: string; }>;

  const [IsLoadingListing, setIsLoadingListing] = useState(false);
  const [IsLoadingDetails, setIsLoadingDetails] = useState(false);
  const [IsDirty, setIsDirty] = useState(false);
  const [DateSelection, setDateSelection] = useState<SelectionRange>({ start: Moment().add(-1, "month").startOf('day').toDate(), end: Moment().startOf('day').toDate() });
  const [DefectStatusFilter, setDefectStatusFilter] = useState([defectFilter[0]]);
  const [Defects, setDefects] = useState<DefectListingViewModel[]>([]);
  const [Defect, setDefect] = useState<DefectViewModel>(null);

  /*public componentWillReceiveProps(nextProps: Props) {
      // This method runs when incoming props (e.g., route params) change

      let defectId = parseInt(nextProps.match.params.defectId) || 0;
      if (defectId > 0) {
          this.get(defectId);
      } else {
          // Click Assets -> Inspections when one is loaded
          this.setState({ Defect: null });
      }
  }*/

  const getAssetTypeName = (assetType: AssetType) => {
    switch (assetType) {
      case AssetType.Trailer:
        return "Trailer";
      case AssetType.Vehicle:
        return "Vehicle";
      default:
        return "";
    }
  }

  const getStatusName = (defectStatus: DefectStatus) => {
    switch (defectStatus) {
      case DefectStatus.Reported:
        return "Reported";
      case DefectStatus.Corrected:
        return "Corrected";
      case DefectStatus.Approved:
        return "Approved";
      default:
        return "";
    }
  }

  const getStatusColor = (defectStatus: DefectStatus) => {
    switch (defectStatus) {
      case DefectStatus.Reported:
        return "bg-danger";
      case DefectStatus.Corrected:
        return "bg-warning";
      case DefectStatus.Approved:
        return "bg-success";
      default:
        return "";
    }
  }

  const getStatusPercent = (defectStatus: DefectStatus) => {
    switch (defectStatus) {
      case DefectStatus.Reported:
        return "33%";
      case DefectStatus.Corrected:
        return "66%";
      case DefectStatus.Approved:
        return "100%";
      default:
        return "";
    }
  }

  const updateDefect = (value: Partial<DefectViewModel>) => {
    const newDefect = Object.assign({}, Defect, value);
    setDefect(newDefect)
    setIsDirty(true);
  }

  const search = useDebouncedCallback(useCallback(() => {
    // Mid Selecting Dates
    if (!DateSelection.start || !DateSelection.end) {
      return;
    }

    const data = {
      DefectStatusFilter: DefectStatusFilter.map(x => x.ID),
      FromDate: DateSelection.start,
      ToDate: Moment(DateSelection.end).add(1, 'day'),
    };

    setIsLoadingListing(true);
    setDefects([]);
    fetchApi('/api/Asset/DefectListing', data, 'POST')
      .then((response: { Defects: DefectListingViewModel[] }) => {
        setDefects(response.Defects);
      })
      .finally(() => {
        setIsLoadingListing(false);
      });
  }, [DateSelection, DefectStatusFilter]), 250);

  const get = useCallback((defectId: number) => {
    setIsLoadingDetails(true);
    setDefect(null);
    fetchApi(`/api/Asset/Defect/${defectId}`)
      .then((response: DefectViewModel) => {
        setDefect(response);
        setIsDirty(false);
      })
      .finally(() => {
        setIsLoadingDetails(false);
      });
  }, []);

  const update = (IsCorrected: boolean) => {
    setIsLoadingDetails(true);
    const data = {
      DefectID: Defect.DefectId,
      IsCorrected,
      Vendor: Defect.Vendor,
      CorrectionNote: Defect.CorrectionNote,
    }
    fetchApi(`/api/Asset/UpdateDefect`, data, 'post')
      .then((response: JsonResponse) => {
        if (response.Success) {
          setIsDirty(false);
          if (IsCorrected) {
            updateDefect({ Status: DefectStatus.Corrected });
            search();
          }
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => {
        alert('Unable to save defect!');
      })
      .finally(() => {
        setIsLoadingDetails(false);
      });
  }

  useEffect(() => {
    var defectId = parseInt(defectIdParam) || 0;
    if (defectId > 0) {
      get(defectId);
    } else {
      setDefect(null);
    }
  }, [search, defectIdParam, get]);

  useEffect(() => {
    search();
  }, [search, DefectStatusFilter, DateSelection]);

  return <div className="row">
    <Title string="Asset Inspections" />
    <div className="col-lg-4">
      <br />
      <MultiSelect
        label="Status"
        style={{ width: '100%' }}
        data={defectFilter}
        value={DefectStatusFilter}
        onChange={(e) => setDefectStatusFilter(e.target.value)}
        textField="Name"
        dataItemKey="ID"        
      />
      <br />
      <DateRangePicker
        format="MM/dd/yyyy"
        value={DateSelection}
        focusedDate={Moment().add(-1, "month").toDate()}
        onChange={(e) => setDateSelection(e.value)}
      />
      <br /><br />
      {IsLoadingListing && <LoadingPanel />}
      {!IsLoadingListing && Defects.length === 0 && <h2>No defects found.</h2>}
      {!IsLoadingListing && Defects && <div className="list-group">
        {Defects.map((defect, index) => {
          const active = Defect && (Defect.DefectId === defect.DefectId);
          return <Link to={`/Assets/Inspections/${defect.DefectId}`} className={`list-group-item list-group-item-action flex-column align-items-start${active ? ' active' : ''}`} key={index}>
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{getStatusName(defect.Status)} - {defect.DefectName}</h5>
              <small><TimeAgo date={Moment.utc(defect.InspectionDateTime).toDate()} /></small>
            </div>
            <p className="mb-1 text-truncate">{defect.ReportedNote}</p>
            <small>{getAssetTypeName(defect.AssetType)} # {defect.AssetNumber}</small>
          </Link>
        })}
      </div>}
    </div>
    <div className="col-lg-8">
      <br />
      {IsLoadingDetails && <LoadingPanel />}
      {!IsLoadingDetails && Defect && <div className="card">
        <div className="card-body">
          <div className="progress" style={{ height: '30px' }}>
            <div className={`progress-bar ${getStatusColor(Defect.Status)}`} role="progressbar" style={{ width: getStatusPercent(Defect.Status), fontSize: 20 }} aria-valuemin={0} aria-valuemax={100}>
              {getStatusName(Defect.Status)}
              {Defect.Status == DefectStatus.Corrected && " (Awaiting Driver Approval)"}
            </div>
          </div>
          <br />
          <h5 className="card-title">{Defect.DefectName}</h5>
          <p>{Defect.ReportedNote}</p>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Inspected By</label>
            <div className="col-sm-3">
              <a
                href="#"
                style={{ color: '#007bff' }}
                className="form-control-plaintext"
                onClick={(e) => {
                  e.preventDefault();
                  openWindow(Defect.Links.find((x: ILink) => x.Name === 'DriverProfile').Link)
                }}
              >
                {Defect.InspectedByDriverNumber}
              </a>
            </div>
            <label className="col-sm-3 col-form-label">Inspected Date</label>
            <div className="col-sm-3">
              <p className="form-control-plaintext">
                {Moment.utc(Defect.InspectionDateTime).local().format("MM/DD/YYYY HH:mm")}
              </p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{getAssetTypeName(Defect.AssetType)}</label>
            <div className="col-sm-3">
              <a
                href="#"
                style={{ color: '#007bff' }}
                className="form-control-plaintext"
                onClick={(e) => {
                  e.preventDefault();
                  openWindow(Defect.Links.find((x: ILink) => x.Name === 'AssetProfile').Link)
                }}
              >
                {Defect.AssetNumber}
              </a>
            </div>
            {Defect.CorrectedDateTime && <>
              <label className="col-sm-3 col-form-label">Corrected Date</label>
              <div className="col-sm-3">
                <p className="form-control-plaintext">
                  {Moment.utc(Defect.CorrectedDateTime).local().format("MM/DD/YYYY HH:mm")}
                </p>
              </div>
            </>}
          </div>
          {Defect.ApprovedDateTime && <div className="form-group row">
            <label className="col-sm-3 offset-md-6 col-form-label">Approved Date</label>
            <div className="col-sm-3">
              <p className="form-control-plaintext">
                {Moment.utc(Defect.ApprovedDateTime).local().format("MM/DD/YYYY HH:mm")}
              </p>
            </div>
          </div>}
          <div className="form-group row">
            <label className="col-md-3 col-form-label">Vendor</label>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Mechanic, etc"
                value={Defect.Vendor}
                onChange={(e) => updateDefect({ Vendor: e.target.value })}
                maxLength={100}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-md-3 col-form-label">Correction Notes</label>
            <div className="col-md-6">
              <textarea
                className="form-control"
                placeholder="Mechanic Notes, etc"
                value={Defect.CorrectionNote}
                onChange={(event) => {
                  updateDefect({
                    CorrectionNote: event.target.value
                  });
                }}
              />
            </div>
          </div>
          {Defect.Status === DefectStatus.Reported && <>
            <button
              className="btn btn-primary"
              disabled={!IsDirty}
              onClick={(e) => update(false)}
            >
              Save
            </button>
            &nbsp;
            <button
              className="btn btn-warning"
              onClick={(e) => update(true)}
            >
              Save + Correct
            </button>
          </>}
        </div>
      </div>}
    </div>
  </div>;
}

export default AssetInspections;