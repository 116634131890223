import { useEffect, useState } from "react";
import { Title } from '../../utils/title';
import { useParams } from "react-router";
import Moment from 'moment-timezone';
import { fetchApi } from "../../services/api";
import { Loader } from "@progress/kendo-react-indicators";
import { DriverNavBar } from "../AssetDrivers/NavBar";
import { OwnerNavBar } from "../AssetOwners/NavBar";
import { TrailerNavBar } from "../AssetTrailers/NavBar";
import { VehicleNavBar } from "../AssetVehicles/NavBar";
import { ViewModel } from "TypeGen/Assets/ResourceLogs/view-model";
import { CarrierNavBar } from "../AssetCarriers/CarrierNavBar";
import JsonPretty from "components/JsonPretty";

export enum ResourceType {
    Carrier = 1,
    Vehicle = 2,
    Driver = 3,
    Owner = 4,
    Trailer = 5
}

type RouteComponentParams = {
    resourceCode: string;
    resourceType: string;
}

const ResourceLogs = () => {

    const { resourceCode: resourceCodeParam, resourceType: resourceTypeParam } = useParams<RouteComponentParams>();

    const [loading, setLoading] = useState(true);
    const [logs, setLogs] = useState<ViewModel>();

    useEffect(() => {
        setLoading(true);
        fetchApi(`/api/Asset/ResourceLogs/${resourceCodeParam}/${resourceTypeParam}`)
            .then(data => {
                setLoading(false);
                setLogs(data);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    }, [resourceCodeParam, resourceTypeParam]);

    const loadingView = <div className="k-pos-absolute k-top-center mt-5">
        <Loader type="converging-spinner" />
    </div>;

    const dataView = () => <>
        {logs.Logs.length === 0 && <h1 className="text-center">No Logs.</h1>}
        {logs.Logs.map(log => <div key={log.ResourceLogID} className="media m-2 border-bottom">
            <div className="media-body">
                <h5 className="mt-0">{log.TypeName} - {log.ModifiedByFullName}</h5>
                <div className="row">
                    <div className="col-6"><label className="font-weight-bold">Old:</label> {log.OldData.length ? <JsonPretty json={log.OldData} /> : "N/A"}</div>
                    <div className="col-6"><label className="font-weight-bold">New:</label> {log.NewData.length ? <JsonPretty json={log.NewData} /> : "N/A"}</div>
                </div>
            </div>
            <p><small>{Moment.utc(log.ModifiedDateTime).tz("America/New_York").format('MM/DD/YYYY HH:mm')}</small></p>
        </div>)}
    </>

    return (<>
        {resourceTypeParam === '1' && <CarrierNavBar id={resourceCodeParam} currentTab="Logs" />}
        {resourceTypeParam === '2' && <VehicleNavBar id={resourceCodeParam} currentTab="Logs" />}
        {resourceTypeParam === '3' && <DriverNavBar id={resourceCodeParam} currentTab="Logs" />}
        {resourceTypeParam === '4' && <OwnerNavBar id={resourceCodeParam} currentTab="Logs" />}
        {resourceTypeParam === '5' && <TrailerNavBar id={resourceCodeParam} currentTab="Logs" />}
        <Title string="Logs" />
        {loading || !logs ? loadingView : dataView()}
    </>);
}

export default ResourceLogs;
