import { useState } from "react";
import CustomerInput, { CustomerInputValue } from "components/CustomerInput";
import { Title } from "utils/title";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { fetchApi } from "services/api";
import useConfirm from "components/useConfirm";
import LoadingPanel from "components/LoadingPanel";

const defaultCustomer: CustomerInputValue = {
  CustomerID: 0,
  CustomerNumber: ''
}

const CustomersMerge = () => {

  const [fromCustomer, setFromCustomer] = useState<CustomerInputValue>(defaultCustomer);
  const [toCustomer, setToCustomer] = useState<CustomerInputValue>(defaultCustomer);
  const [retireFromCustomer, setRetireFromCustomer] = useState<boolean>(true);
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [isMerging, setIsMerging] = useState(false);

  const mergeCustomers = async () => {

    if (await confirm('Are you sure you want to merge these customers?') === false) {
      return;
    }

    setIsMerging(true);
    const payload = {
      FromCustomerID: fromCustomer?.CustomerID,
      ToCustomerID: toCustomer?.CustomerID,
      RetireFromCustomer: retireFromCustomer
    }

    fetchApi('/api/Customer/Merge', payload, 'POST')
      .then(() => {
        setToCustomer(defaultCustomer);
        setFromCustomer(defaultCustomer);
        setRetireFromCustomer(true);

        alert('Customers merged successfully!');
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to merge customers');
      })
      .finally(() => setIsMerging(false));
  }

  return <div className="container mt-2">
    <Title string="Merge Customers" />
    <ConfirmationDialog />
    {isMerging && <LoadingPanel />}
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">Warning!</h4>
      Merging customers is a permanent action and cannot be undone. Please be sure you want to merge these customers before proceeding.
    </div>
    <div className="form-group row">
      <label htmlFor="FromCustomer" className="col-sm-3 col-form-label">From Customer (Discarded)</label>
      <div className="col-sm-9 input-group">
        <CustomerInput
          focus
          placeholder="Search for a customer to merge from..."
          CustomerID={fromCustomer?.CustomerID}
          CustomerNumber={fromCustomer?.CustomerNumber}
          onChange={(customer) => setFromCustomer(customer)}
        />
      </div>
      {fromCustomer?.CustomerName && <div className="mt-2 col-sm-9 offset-sm-3">
        <span>{fromCustomer?.CustomerName} - {fromCustomer?.AddressLine1} {fromCustomer?.City}, {fromCustomer?.State} {fromCustomer?.ZipCode}</span>
      </div>}
    </div>

    <div className="form-group row">
      <label htmlFor="ToCustomer" className="col-sm-3 col-form-label">To Customer (Retained)</label>
      <div className="col-sm-9 input-group">
        <CustomerInput
          placeholder="Search for a customer to merge into..."
          CustomerID={toCustomer?.CustomerID}
          CustomerNumber={toCustomer?.CustomerNumber}
          onChange={(customer) => setToCustomer(customer)}
        />
      </div>
      {toCustomer?.CustomerName && <div className="mt-2 col-sm-9 offset-sm-3">
        <span>{toCustomer?.CustomerName} - {toCustomer?.AddressLine1} {toCustomer?.City}, {toCustomer?.State} {toCustomer?.ZipCode}</span>
      </div>}
    </div>

    <Checkbox
      label="Retire From Customer"
      checked={retireFromCustomer}
      onChange={(e) => setRetireFromCustomer(e.value)}
    />

    <div className="text-center mt-3">
      <Button
        themeColor="error"
        disabled={isMerging || fromCustomer.CustomerID == 0 || toCustomer.CustomerID == 0}
        onClick={mergeCustomers}
      >
        Merge Customers
      </Button>
    </div>
  </div>
}

export default CustomersMerge;