import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { JsonResponse } from 'TypeGen/json-response';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { RateMasterFormat } from '../../TypeGen/Rate/rate-master-format';
import { IDName } from '../../types/idname';
import { ILink } from '../../types/link';
import { RateMasterFormatName, usesMileageBreakComponent, usesTierComponent } from './RateMaster';
import Tier from './Tier';

type Props = {
  RateMasterID: number
};

export type VehicleRateBreak = {
  RateBreaks: {
    RateBreakID: number;
    Rate: number;
  }[];
  RateDescriptionID: number;
  RateDescriptionName: string;
  RateLineItemID: number;
  ModifiedByFullName: string;
  ModifiedDateTime: Date;

  Tier: number;
  MileageBreak: number;
  PumpRateMin: number;
  PumpRateMax: number;

  inEdit?: boolean | string;
  isNew?: boolean;
  Delete?: boolean;
};

type RateMasterSummaryResponse = {
  RateMasterFormat: RateMasterFormat,
  SupportedVehicleRateBreaks: IDName[],
  Links: ILink[]
};

interface EditCommandCellProps extends GridCellProps {
  edit: (item: VehicleRateBreak) => void;
  remove: (item: VehicleRateBreak) => void;
  add: (item: VehicleRateBreak) => void;
  discard: () => void;
  update: (item: VehicleRateBreak) => void;
  cancel: (item: VehicleRateBreak) => void;
  editField: string;
}

const VehicleRateBreakContext = createContext<{ RateDescriptionList: IDName[] }>({ RateDescriptionList: [] });

const AccessorialCell = (props: GridCellProps) => {
  const context = useContext(VehicleRateBreakContext);

  let dataItem: VehicleRateBreak = props.dataItem;

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.ID,
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {dataItem.inEdit ? <DropDownList
        className="my-1"
        data={context.RateDescriptionList}
        textField="Name"
        dataItemKey="ID"
        value={context.RateDescriptionList.find((x: IDName) => x.ID === dataItem.RateDescriptionID)}
        onChange={handleChange}
      /> :
        <div>{context.RateDescriptionList.find((x: IDName) => x.ID === dataItem.RateDescriptionID).Name}</div>
      }
    </td>
  );
};

const MileageBreakCell = (props: GridCellProps) => {
  let dataItem: VehicleRateBreak = props.dataItem;

  const handleChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit ? <NumericTextBox format="n0" value={dataItem.MileageBreak} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
        <span>{formatNumber(dataItem.MileageBreak, "n0")}</span>
      }
    </td>
  );
};

const TierBreakCell = (props: GridCellProps) => {
  let dataItem: VehicleRateBreak = props.dataItem;

  const handleChange = (e: NumericTextBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit ? <NumericTextBox format="n0" value={dataItem.Tier} onChange={handleChange} min={1} max={20} inputStyle={{ textAlign: "right" }} /> :
        <span>{formatNumber(dataItem.Tier, "n0")}</span>
      }
    </td>
  );
};

const vehicleBreakHeaderCell = (props: GridHeaderCellProps) => {
  return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
    <span>{props.title}</span>
    {props.children}
  </a>);
};

const VehicleBreakCell = (props: GridCellProps) => {
  let dataItem: VehicleRateBreak = props.dataItem;
  const dataItemVehicleRateBreak = dataItem.RateBreaks.find(x => x.RateBreakID === Number(props.field));

  const handleChange = (e: NumericTextBoxChangeEvent) => {
    dataItemVehicleRateBreak.Rate = e.target.value;
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: 'RateBreaks',
        syntheticEvent: e.syntheticEvent,
        value: dataItem.RateBreaks
      });
    }
  };

  return (
    <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
      {dataItem.inEdit ? <NumericTextBox format="n2" value={dataItemVehicleRateBreak.Rate} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
        <span>{formatNumber(dataItemVehicleRateBreak.Rate, "n2")}</span>
      }
    </td>
  );
};

const LastModifiedByCell = (props: GridCellProps) => {
  if (!props.field)
    return null;

  let dataItem: VehicleRateBreak = props.dataItem;
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
    </td>
  );
};

const VehicleRateBreakStructure = (props: Props) => {
  const { alert } = useAlert();
  const { ConfirmationDialog, confirm } = useConfirm({});
  const [loading, setLoading] = useState(true);
  //const [isTierComponentInEditMode, setIsTierComponentInEditMode] = useState(false);
  const editField: string = "inEdit";
  const [rateDescriptionList, setRateDescriptionList] = useState<IDName[]>([]);
  const [vehicleRateBreaks, setVehicleRateBreaks] = useState<VehicleRateBreak[]>([]);
  const [CSIDataCopy, setCSIDataCopy] = useState<VehicleRateBreak[]>([]);
  const [rateMasterSummaryResponse, setRateMasterSummaryResponse] = useState<RateMasterSummaryResponse>();

  const EditCommandCell = (props: EditCommandCellProps) => {
    const inEdit = props.dataItem[props.editField];
    let dataItem: VehicleRateBreak = props.dataItem;
    const isNewItem = dataItem.isNew;

    let disabled = false;
    switch (rateMasterSummaryResponse.RateMasterFormat) {
      case RateMasterFormat.Accessorial:
        disabled = dataItem.RateBreaks.some(x => x.Rate == null);
        break;
      case RateMasterFormat.Expedite:
        disabled = dataItem.MileageBreak === 0 || dataItem.MileageBreak == null || dataItem.RateBreaks.some(x => x.Rate == null);
        break;
      case RateMasterFormat.Tier:
        disabled = dataItem.Tier === 0 || dataItem.Tier == null || dataItem.MileageBreak === 0 || dataItem.MileageBreak == null || dataItem.RateBreaks.some(x => x.Rate == null);
        break;
      default:
        disabled = true;
        break;
    }

    return inEdit ? (
      <td className="k-command-cell">
        <Button themeColor={"primary"}
          disabled={disabled}
          onClick={() =>
            isNewItem ? props.add(dataItem) : props.update(dataItem)
          }
        >
          {isNewItem ? "Save" : "Update"}
        </Button>
        <Button themeColor={"secondary"}
          onClick={() =>
            isNewItem ? props.discard() : props.cancel(dataItem)
          }
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button themeColor={"primary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
          onClick={() => props.edit(dataItem)}>Edit</Button>
        <Button themeColor={"secondary"} disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
          onClick={async () => {
            if (!await confirm(`Remove ${rateDescriptionList.find(x => x.ID === dataItem.RateDescriptionID).Name}?`))
              return;
            props.remove(dataItem);
          }}>Delete</Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const enterEdit = (dataItem: VehicleRateBreak) => {
    if (vehicleRateBreaks.some(x => x.inEdit === true))
      return;

    setVehicleRateBreaks(
      vehicleRateBreaks.map((item) =>
        item.RateLineItemID === dataItem.RateLineItemID ? { ...item, inEdit: true } : item
      )
    );
  };

  const remove = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure({ ...dataItem, Delete: true });
  };

  const add = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure(dataItem);
  };

  const update = (dataItem: VehicleRateBreak) => {
    updateAccessorialRateStructure(dataItem);
  };

  const cancel = (dataItem: VehicleRateBreak) => {
    const originalItem = CSIDataCopy.find(
      (p) => p.RateLineItemID === dataItem.RateLineItemID
    );
    const newData = vehicleRateBreaks.map((item) =>
      item.RateLineItemID === originalItem.RateLineItemID ? cloneObject(originalItem) : item
    );

    setVehicleRateBreaks(newData);
  };

  const generateId = () =>
    vehicleRateBreaks.reduce((acc, current) => Math.max(acc, current.RateLineItemID), 0) + 1;

  const addNew = () => {
    const newDataItem = {
      inEdit: true, RateLineItemID: generateId(), isNew: true,
      RateBreaks: rateMasterSummaryResponse.SupportedVehicleRateBreaks.map(x => {
        return { RateBreakID: x.ID, Rate: 0 };
      })
    } as VehicleRateBreak;
    setVehicleRateBreaks([newDataItem, ...vehicleRateBreaks]);
  };

  const discard = () => {
    const newData = [...vehicleRateBreaks];
    newData.splice(0, 1);
    setVehicleRateBreaks(newData);
  };

  const getRateMaster = useCallback(() => {
    setLoading(true);

    fetchApi(`/api/Quote/GetRateMasterSummary/${props.RateMasterID}`)
      .then((response: RateMasterSummaryResponse) => {
        setRateMasterSummaryResponse(response);
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [props.RateMasterID, alert]);


  const cloneObject = (obj: any): any => {
    return JSON.parse(JSON.stringify(obj));
  }

  const refresh = useCallback(() => {
    setLoading(true);

    const link = rateMasterSummaryResponse.Links.find(x => x.Method === 'GET');

    fetchApi(link.Link)
      .then((response: any) => {
        setRateDescriptionList(response.RateDescriptionList);
        setVehicleRateBreaks(response.VehicleRateBreaks);
        setCSIDataCopy(cloneObject(response.VehicleRateBreaks)) //deep copy/clone

        setLoading(false);
      })
      .catch(async e => {
        if (!e?.status)
          await alert('Error: Please see admin');
        else if (e.status !== 404) {
          await alert(e?.detail);
        }
        setLoading(false);
      });
  }, [rateMasterSummaryResponse?.Links, alert]);

  const updateAccessorialRateStructure = (dataItem: VehicleRateBreak) => {
    setLoading(true);

    const link = rateMasterSummaryResponse.Links.find(x => x.Method === 'POST');
    const data = { ...dataItem }
    fetchApi(link.Link, data, link.Method)
      .then(async (response: JsonResponse) => {
        if (response.Success === false) {
          setLoading(false);
          await alert(`Error: ${response.ErrorMessage}`);
        }
        else
          refresh();
      })
      .catch(async e => {
        setLoading(false);
        // If not problem details
        if (!e?.status) await alert('An error occurred while saving.');
      });
  }

  useEffect(() => {
    getRateMaster();
  }, [props.RateMasterID, getRateMaster]);

  useEffect(() => {
    if (rateMasterSummaryResponse) {
      refresh();
    }
  }, [rateMasterSummaryResponse, refresh]);

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = vehicleRateBreaks.map((item) =>
      item.RateLineItemID === event.dataItem.RateLineItemID
        ? { ...item, [field]: event.value }
        : item
    );

    setVehicleRateBreaks(newData);
  }

  const dataView = () => {
    return (<div className="container-fluid mt-2" style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}

      {rateMasterSummaryResponse
        && usesTierComponent(rateMasterSummaryResponse.RateMasterFormat)
        && <Tier RateMasterID={props.RateMasterID} className="mb-4" />
      }

      {rateMasterSummaryResponse && <h4 className="text-left">{RateMasterFormatName(rateMasterSummaryResponse.RateMasterFormat)} Rate Structure</h4>}
      <VehicleRateBreakContext.Provider value={{ RateDescriptionList: rateDescriptionList ?? [] }}>
        <Grid
          style={{
            maxHeight: `${rateMasterSummaryResponse
              && usesTierComponent(rateMasterSummaryResponse.RateMasterFormat)
              ? window.innerHeight * .50 : window.innerHeight * .80}px`,
          }}
          onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
          data={vehicleRateBreaks}
          onItemChange={itemChange}
        >
          <GridToolbar>
            <Button themeColor={"primary"}
              disabled={vehicleRateBreaks.some(x => x.inEdit === true)}
              title="Add new"
              onClick={addNew}
            >
              Add new
            </Button>
          </GridToolbar>
          <Column field="RateDescriptionID" title="Charge Type" cell={AccessorialCell} />

          {rateMasterSummaryResponse
            && usesTierComponent(rateMasterSummaryResponse.RateMasterFormat)
            && <Column field="Tier" title="Tier" cell={TierBreakCell} />
          }

          {rateMasterSummaryResponse
            && usesMileageBreakComponent(rateMasterSummaryResponse.RateMasterFormat)
            && <Column field="MileageBreak" title="Mileage Break" cell={MileageBreakCell} />
          }

          {rateMasterSummaryResponse && rateMasterSummaryResponse.SupportedVehicleRateBreaks.map((x, key) => {
            return <Column key={key} title={`${x.Name}`} field={`${x.ID}`} editable={false} cell={VehicleBreakCell} headerCell={vehicleBreakHeaderCell} />
          })}
          <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
          <Column cell={MyEditCommandCell} width={160} />
        </Grid>
      </VehicleRateBreakContext.Provider>
      <ConfirmationDialog />
    </div>);
  };

  return dataView();
}


export default VehicleRateBreakStructure;
