import * as React from 'react';
import Moment from 'moment-timezone';
import { formatNumber } from '@progress/kendo-intl';
import { TransitValue } from './StopTransitData';
import { QuoteValue } from './Quote';
import timezoneFormatter from '../../services/formatting/timezone';
import timezoneFormatterIANA from '../../services/formatting/timezone-iana';
import { EnterFreightViewModel, EnterStopViewModel } from '.';
import { getQuoteStopType } from './GetStopType';
import { getStopVerb } from './GetStopVerb';
import { dimsDisplayValue, dimsUnitOfMeasureAbbreviation, getVehicleTypeIDName, weightDisplayValue, weightUnitOfMeasureAbbreviation } from '../../utils/utils';
import { VehicleValue } from './Vehicles';

type Props = {
  QuoteID: number;
  BillingCustomerNumber: string;
  TransitData: TransitValue|null;
  QuoteData: QuoteValue|null;
  Stops: EnterStopViewModel[];
  Locations: EnterStopViewModel[];
  Freight: EnterFreightViewModel[];
  VehicleTypeID: number;
  Vehicle: VehicleValue | null;
  CloseDialog: () => void;
};

const styles = {
  container: {
    backgroundColor: '#175985',
    color: 'white',
    padding: 5,
  },
  tableLabel: {
    border: '1pt solid'
  },
  tableData: {
    border: '1pt solid'
  }
}

export default class QuoteEmailTemplate extends React.PureComponent<Props> {

  getTransportType(vehicleTypeID: number) {
    switch (vehicleTypeID) {
      case 2:
      case 3:
      case 15:
        return "ROAD";
      case 6:
      case 20:
        return "AIR";
      default:
        return "";
    }
  }

  render() {
    if (this.props.BillingCustomerNumber == "VOLVO") {
      return <div id="QuoteEmailTemplate" style={{ backgroundColor: 'white' }}>
        <table style={{ color: 'black', width: '250pt' }}>
          <tr>
            <th style={styles.tableLabel}>Pro Number:</th>
            <td style={styles.tableData}></td>
          </tr>
          <tr>
            <th style={styles.tableLabel}>Unit Number:</th>
            <td style={styles.tableData}>0</td>
          </tr>
          <tr>
            <th style={styles.tableLabel}>Carrier:</th>
            <td style={styles.tableData}>LONE</td>
          </tr>
          <tr>
            <th style={styles.tableLabel}>Vehicle Type</th>
            <td style={styles.tableData}>{getVehicleTypeIDName(this.props.VehicleTypeID)}</td>
          </tr>
          <tr>
            <th style={styles.tableLabel}>Solo Driver/Team:</th>
            <td style={styles.tableData}></td>
          </tr>
          <tr>
            <th style={{...styles.tableLabel, ...{color: 'red' }}}>Mileage:</th>
            <td style={styles.tableData}>{formatNumber(this.props.TransitData.Distance, "n0")}</td>
          </tr>
          <tr>
            <th style={{...styles.tableLabel, ...{color: 'red' }}}>Pick Up Date/Time:</th>
            <td style={styles.tableData}>{Moment(this.props.Stops[0].DateTime).tz(timezoneFormatterIANA(this.props.Locations[0] && this.props.Locations[0].Location.TimeZone)).format("MM/DD HHmm")}</td>
          </tr>
          <tr>
            <th style={{...styles.tableLabel, ...{color: 'red' }}}>Delivery Date/Time:</th>
            <td style={styles.tableData}>{Moment(this.props.Stops[this.props.Stops.length-1].DateTime).tz(timezoneFormatterIANA(this.props.Locations[this.props.Stops.length-1] && this.props.Locations[this.props.Stops.length-1].Location.TimeZone)).format("MM/DD HHmm")}</td>
          </tr>
          <tr>
            <th style={{...styles.tableLabel, ...{color: 'red' }}}>All In Rate:</th>
            <td style={styles.tableData}>{formatNumber(this.props.QuoteData.Amount, "c")}</td>
          </tr>
          <tr>
            <th style={{...styles.tableLabel, ...{color: 'red' }}}>Transport mode:</th>
            <td style={styles.tableData}>{this.getTransportType(this.props.VehicleTypeID)}</td>
          </tr>
        </table>
        <br />&nbsp;
      </div>
    }

    return <div id="QuoteEmailTemplate" style={{ backgroundColor: 'white' }}>
          <img width={150} src="https://app.load1.com/images/l1logo.png" alt="" />
          <br />&nbsp;
          <table style={styles.container}>
            <tbody>
              {this.props.QuoteData && <tr>
                <td>
                  <h3>Quote No. {this.props.QuoteID}: Our all-in rate is: {formatNumber(this.props.QuoteData.Amount, "c")}</h3>
                </td>
              </tr>}
              {this.props.Vehicle && <tr>
                <td>
                  <h4>Reserved {getVehicleTypeIDName(this.props.VehicleTypeID)} ({this.props.Vehicle.BoxLength}x{this.props.Vehicle.BoxWidth}x{this.props.Vehicle.BoxHeight}) {formatNumber(this.props.Vehicle.Distance, "n0")} miles out in {this.props.Vehicle.Position}</h4>
                </td>
              </tr>}
              <tr>
                <td>
                  <h4>Stops</h4>
                </td>
              </tr>
              {this.props.Stops.map((stop, index) => {
                return <tr key={index}>
                  <td>
                    <b>{getQuoteStopType(stop.StopType)}</b><br />
                    {stop.Location}<br />
                    {Moment(stop.DateTime).tz(timezoneFormatterIANA(this.props.Locations[index] && this.props.Locations[index].Location.TimeZone)).format("MM/DD/YYYY HH:mm")} {timezoneFormatter(this.props.Locations[index] && this.props.Locations[index].Location.TimeZone)} ({getStopVerb(stop.StopVerb)})
                  </td>
                </tr>
              })}
              {this.props.TransitData && <tr>
                <td>{formatNumber(this.props.TransitData.Distance, "n0")} Miles</td>
              </tr>}
              <tr>
                <td>
                  <h4>Freight ({getVehicleTypeIDName(this.props.VehicleTypeID)})</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ color: 'white' }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'left', width: '100px' }}>Pieces</th>
                        <th style={{ textAlign: 'left', width: '100px' }}>Weight</th>
                        <th style={{ textAlign: 'left', width: '100px' }}>Dims (LxWxH)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.Freight.map((freight, index) => {
                        return <tr key={index}>
                          <td>{freight.Pieces}</td>
                          <td>{weightDisplayValue(freight.Weight, freight.WeightUnitOfMeasure)} {weightUnitOfMeasureAbbreviation(freight.WeightUnitOfMeasure)}</td>
                          <td>{dimsDisplayValue(freight.Length, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Width, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Height, freight.DimsUnitOfMeasure)} {dimsUnitOfMeasureAbbreviation(freight.DimsUnitOfMeasure)}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <i>
                      Please let us know if you would like to book this option.<br />
                      Thank you for choosing Load One and please consider us your go to partner for critical shipping.
                    </i>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  }

  public componentDidMount() {
    window.getSelection().selectAllChildren(document.getElementById("QuoteEmailTemplate") as HTMLDivElement);
    document.execCommand('copy');

    this.props.CloseDialog();
  }
}
