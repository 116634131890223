import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import allianceCarrierRating from '../Quote/AllianceVehicles/CarrierRating';
import CarrierIDCell from '../Assets/CarrierIDCell';
import CarrierReviews from '../CarrierReviews';
import { ILink } from '../../types/link';
import CarrierTierCell from './CarrierTierCell';
import { Title } from '../../utils/title';
import { JsonResponse } from 'TypeGen/json-response';
import { SvgIcon } from "@progress/kendo-react-common";
import { arrowRotateCwIcon, chevronLeftIcon, chevronRightIcon, plusIcon } from '@progress/kendo-svg-icons';
import { Button } from '@progress/kendo-react-buttons';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import CarrierAutoComplete from 'views/AssetCarriers/CarrierAutoComplete';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ApiCommand } from 'TypeGen/api-command';

export const TierValues = [
    { text: 'Platinum', value: 10 },
    { text: 'Gold', value: 20 },
    { text: 'Silver', value: 30 },
    { text: 'N/A', value: 0 }
]

type Props = {};

type State = {
    period: number;
    periodName: string;
    data: CarrierRanking[];
    links: ApiCommand[];
    loading: boolean;
    sort: SortDescriptor[];

    AddMissingCarrier: boolean;
    MissingCarrierID: number;
    MissingCarrierTier: number;

    CarrierReviewPhone: string;
    CarrierReviewName: string;
}

type CarrierRanking = {
    CarrierID: number;
    CarrierName: string;
    CarrierPhone: string;
    TripCount: number;
    Revenue: number;
    CarrierTier: number;
    PercentPickupsOnTime: number;
    PercentDeliveriesOnTime: number;
    PercentPositionUpdates: number;
    CombinedScore: number;

    CarrierScore: number;
    CarrierReviewCount: number;
    Hash: string;
    Links: ILink[];
}

export default class CarrierRankings extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            period: 0,
            periodName: '',
            data: [],
            links: [],
            sort: [],
            loading: true,

            AddMissingCarrier: false,
            MissingCarrierID: 0,
            MissingCarrierTier: 10,

            CarrierReviewName: '',
            CarrierReviewPhone: '',
        }

        this.refreshData = this.refreshData.bind(this);
        this.openReviews = this.openReviews.bind(this);
        this.setCarrierTier = this.setCarrierTier.bind(this);
    }

    public componentDidMount() {
        this.refreshData();
    }

    public render() {
        const data = orderBy(this.state.data, this.state.sort);
        return <React.Fragment>
            <Title string="Carrier Rankings" />
            <br />
            <nav className="navbar navbar-dark bg-dark">
              <a className="navbar-brand" href="#">
                  Carrier Rankings - {this.state.periodName}
                  <SvgIcon
                    icon={chevronLeftIcon}
                    color='#007bff'
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(prevState => ({ period: prevState.period-1 }), this.refreshData);
                    }}
                  />
                  <SvgIcon
                    icon={chevronRightIcon}
                    color='#007bff'
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState(prevState => ({ period: prevState.period+1 }), this.refreshData);
                    }}
                  />
              </a>
              <ul className="nav navbar-nav navbar-right text-light">
                <Button
                  title="Refresh"
                  icon="refresh"
                  svgIcon={arrowRotateCwIcon}
                  onClick={this.refreshData}
                />
              </ul>
            </nav>
            <p className='p-2'>
                Notes:<br />
                - Data is based on the previous calendar month's data.<br />
                - Weighted 30% on PU On-Time % / 30% on DR On-Time % / 35% on Position % / 5% on Review Rating.<br />
                - Minimum 4 Trips / $6,000 Revenue required to be included in the rankings.<br /><br />
                - Platinum = Atleast 15 Trips / $15,000 Revenue / 92% Combined Score.<br />
                - Gold = Atleast 10 Trips / $10,000 Revenue / 80% Combined Score.<br />
                - Silver = Atleast 60% Combined Score.<br />
            </p>
            {this.state.CarrierReviewPhone && <CarrierReviews
                CloseDialog={() => this.setState({ CarrierReviewPhone: '' })}
                Name={this.state.CarrierReviewName} PhoneNumber={this.state.CarrierReviewPhone} />}
            {this.state.AddMissingCarrier && <Dialog
                title="Add Missing Carrier Tier"
                className='dialog-w525'
                onClose={() => this.setState({ AddMissingCarrier: false })}
            >
                <CarrierAutoComplete
                    selectedCarrierID={this.state.MissingCarrierID}
                    onSelectedCarrier={(e) => this.setState({ MissingCarrierID: e?.CarrierID || 0 })}
                />
                <DropDownList
                    className='mt-2'
                    data={TierValues}
                    value={TierValues.find(c => c.value === this.state.MissingCarrierTier)}
                    textField="text"
                    dataItemKey="value"
                    onChange={(e) => this.setState({ MissingCarrierTier: e.target.value.value })}
                    style={{ width: '100%' }}
                />
                <DialogActionsBar layout="end">
                    <Button onClick={() => this.setState({ AddMissingCarrier: false })}>Cancel</Button>
                    <Button
                        themeColor="primary"
                        disabled={this.state.MissingCarrierID === 0}
                        onClick={this.setCarrierTier}
                    >
                        Set Tier
                    </Button>
                </DialogActionsBar>
            </Dialog>}
            {this.state.loading && <GridLoadingPanel />}
            <Grid
                data={data}
                scrollable='none'
                sortable={{ allowUnsort: true, mode: "multiple" }}
                sort={this.state.sort}
                onSortChange={(e) => this.setState({ sort: e.sort })}
                onItemChange={(e) => {
                    if (e.field === 'CarrierTier') {
                      const link = e.dataItem.Links.find((x: ILink) => x.Name === 'SetCarrierTier');
                      fetchApi(link.Link, { Tier: e.value }, 'POST')
                        .then((response: JsonResponse) => {
                          if (response.Success) {
                            const newData = this.state.data.map((item) => (e.dataItem.CarrierID !== item.CarrierID) ? item : ({
                                ...item,
                                [e.field]: e.value,
                            }));
                            this.setState({ data: newData });
                          } else {
                            alert(response.ErrorMessage);
                          }
                        })
                        .catch(() => {
                          alert('Failed to update carrier tier');
                        });
                    }
                }}
            >
                <GridToolbar>
                    <Button
                        title="Refresh"
                        icon="refresh"
                        svgIcon={arrowRotateCwIcon}
                        onClick={this.refreshData}
                    />
                    <Button
                        icon="plus"
                        svgIcon={plusIcon}
                        onClick={() => this.setState({ AddMissingCarrier: true })}
                    >
                        Add Missing Carrier Tier
                    </Button>
                </GridToolbar>
                <Column field="Rank" width={44} filter="numeric" cell={CarrierIDCell} />
                <Column field="CarrierName" title="Carrier Name" />
                <Column field="TripCount" title="Trip Count" filter="numeric" format="{0:n0}" />
                <Column field="Revenue" filter="numeric" format="{0:c}" />
                <Column field="PercentPickupsOnTime" title="PU On-Time %" filter="numeric" format="{0:p}" />
                <Column field="PercentDeliveriesOnTime" title="DR On-Time %" filter="numeric" format="{0:p}" />
                <Column field="PercentPositionUpdates" title="Position %" filter="numeric" format="{0:p}" />
                <Column field="PercentAppAccepted" title="App Accepted %" filter="numeric" format="{0:p}" />
                <Column field="CombinedScore" title="Combined %" filter="numeric" format="{0:p2}" />
                <Column field="CarrierTier" title="Tier" cell={allianceCarrierRating(this.openReviews)} />
                <Column field="CarrierTier" title="Edit" cell={CarrierTierCell} />
            </Grid>
        </React.Fragment>
    }

    private openReviews(carrier: Pick<CarrierRanking, 'CarrierName' | 'CarrierPhone'>) {
      this.setState({ CarrierReviewName: carrier.CarrierName, CarrierReviewPhone: carrier.CarrierPhone })
    }

    private refreshData() {
        this.setState({ loading: true });
        const data = {
            period: this.state.period,
        }
        fetchApi('/api/CarrierRating/CarrierRankings', data, 'POST')
            .then((data: { PeriodName: string, Rankings: CarrierRanking[], Links: ApiCommand[] }) => {
                this.setState({ periodName: data.PeriodName, data: data.Rankings, links: data.Links, loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
                alert('Failed to load carrier rankings!');
            });
    }

    private setCarrierTier() {
        const link = this.state.links.find((x: ILink) => x.Name === 'SetCarrierTier');
        fetchApi(link.Link, { CarrierID: this.state.MissingCarrierID, Tier: this.state.MissingCarrierTier }, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    this.setState({ AddMissingCarrier: false });
                    this.refreshData();
                } else {
                    alert(response.ErrorMessage);
                }
            })
            .catch(() => {
                alert('Failed to update carrier tier');
            });
    }
}