import { CompositeFilterDescriptor, DataSourceRequestState, State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridEvent, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Title } from 'utils/title';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import { fetchApi } from '../../services/api';
import { openWindowPerc } from '../../services/openWindow';
import { ApiLink } from '../../TypeGen/api-link';
import { ILink } from '../../types/link';
import AddVendorDialog from './dialogs/AddVendorDialog';
import { plusIcon } from '@progress/kendo-svg-icons';

export interface VendorViewModel {
  PurchaseOrderVendorID: number,
  VendorName: string;
  AddressLine1: string;
  City: string;
  State: string;
  ZipCode: string;
  PhoneNumber: string;
  VendorID: string;
  Active: boolean;
  Links: ApiLink[];
}

const PurchaseOrderVendorIDCell = (props: GridCellProps) => {
  let dataItem: VendorViewModel = props.dataItem;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      <Link style={{ color: "#007bff" }} to="#" onClick={(e) => {
        e.preventDefault();
        openWindowPerc(`/PurchaseOrder/Vendor/${dataItem.PurchaseOrderVendorID}`, .5, .7);
      }}>{dataItem.PurchaseOrderVendorID}</Link>
    </td>
  );
};

const PurchaseOrderVendorListing = () => {
  const render = useRef(true);
  const [loading, setLoading] = useState(true);
  const { alert } = useAlert();
  const [showAddVendorDialog, setShowAddVendorDialog] = useState(false);

  const defaultAndFilter = {
    logic: 'and', filters: [
      { field: 'Active', operator: 'eq', value: true },
    ]
  } as CompositeFilterDescriptor;

  //const sessionStorageAndFilter = sessionStorage.getItem("PurchaseOrderVendorsFilterV1");
  let sessionStorageAndFilter = null;
  let andFilter: CompositeFilterDescriptor = null;
  if (sessionStorageAndFilter) {
    andFilter = JSON.parse(sessionStorageAndFilter);
  } else {
    andFilter = { ...defaultAndFilter };
  }
  const resetDataState = {
    skip: 0,
    take: 50,
    sort: [{
      field: "VendorName",
      dir: "asc"
    }]
  } as GridState;
  const [dataState, setDataState] = useState<DataSourceRequestState>({ ...resetDataState, filter: andFilter });

  const totalRecords = useRef(0);
  const DataState_Take = 50;
  const [vendors, setVendors] = useState<VendorViewModel[]>([]);


  const fetchRecords = useCallback((append: boolean) => {
    sessionStorage.setItem("PurchaseOrderVendorsFilterV1", JSON.stringify(dataState.filter));
    setLoading(true);
    const dataGridState = { ...dataState };
    if (!append) {
      dataGridState.take = DataState_Take;
      dataGridState.skip = 0;
      window.scrollTo(0, 0);
      document.getElementsByClassName("k-grid-content")[0].scrollTop = 0;
    }

    const data = {};
    const queryStr = `${toDataSourceRequestString(dataGridState)}`;
    fetchApi(`/api/PurchaseOrder/GetVendors?${queryStr}`, data, 'POST')
      .then(({ Data, Total }) => {
        totalRecords.current = Total;
        if (!append) {
          setVendors(Data);
        } else {
          setVendors(prevData => prevData.concat(Data));
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  }, [dataState]);

  const search = useDebouncedCallback(useCallback(() => {
    fetchRecords(false);
  }, [fetchRecords]), 750);

  useEffect(() => {
    search();
  }, [dataState.filter, dataState.sort, search]);

  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (e.target.scrollTop >= (e.target.scrollHeight - (e.target.clientHeight)) - 1) {
      if (vendors.length === totalRecords.current)  //When auto-scrolling, ignore when we've hit max records
        return;

      dataState.skip = dataState.skip + dataState.take;
      fetchRecords(true);
    }
  }

  const onGridFilterChange = (event: GridFilterChangeEvent) => {
    setDataState({ ...dataState, filter: event.filter ?? { ...defaultAndFilter } });
  };

  const onGridSortChange = (event: GridSortChangeEvent) => {
    setDataState({ ...dataState, sort: event.sort });
  };

  const dataView = () => {
    return (<div className="mt-3" style={{ position: "relative" }}>
      {loading && <CenterDivPanel>
        <Loader type="converging-spinner" />
      </CenterDivPanel>}
      <Title string="Vendors" />
      <h4 className="text-left">Vendors</h4>

      <Grid
        {...dataState}
        className='PurchaseOrderVendorListing-andFilter'
        style={{ maxHeight: `${window.innerHeight * .80}px` }}
        data={vendors}
        total={totalRecords.current}
        onScroll={scrollHandler}
        filterable={true}
        onFilterChange={(e) => onGridFilterChange(e)}
        onSortChange={(e) => onGridSortChange(e)}
        resizable={true}
        reorderable={true}
        sortable={true}
      >
        <GridToolbar>
          <Button
            icon="plus"
            svgIcon={plusIcon}
            themeColor="primary"
            onClick={() => setShowAddVendorDialog(true)}
          > Vendor
          </Button>
        </GridToolbar>
        <Column field="PurchaseOrderVendorID" title="ID" filter="numeric" cell={PurchaseOrderVendorIDCell} />
        <Column field="VendorName" title="Name" />
        <Column field="City" title="City" />
        <Column field="State" title="State" />
        <Column field="VendorID" title="VendorID" />
      </Grid>
      {
        totalRecords.current > 0 &&
        <div className="k-pager k-pager-md k-grid-pagert">
          <div style={{ marginLeft: "auto", marginRight: 0 }}>
            {dataState.skip + dataState.take > totalRecords.current ? totalRecords.current : dataState.skip + dataState.take} of {totalRecords.current} items
          </div>
        </div>
      }
      {showAddVendorDialog && <AddVendorDialog CloseDialog={() => setShowAddVendorDialog(false)} />}
    </div>);
  };

  return dataView();
}

export default PurchaseOrderVendorListing;
