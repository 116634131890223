import * as React from 'react';

import { Title } from '../../utils/title';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useParams, Link } from 'react-router-dom';
import { formatNumber } from '@progress/kendo-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchApi } from 'services/api';
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import { Grid, GridCellProps, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn as ExportColumn } from '@progress/kendo-react-excel-export';
import { GridLoadingPanel } from 'components/GridLoadingPanel';
import { IDName } from 'types/idname';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import PopupNavBar from 'components/Layout/PopupNavBar';
import { SvgIcon } from "@progress/kendo-react-common";
import { arrowRotateCwIcon, caretAltUpIcon, caretAltDownIcon, downloadIcon } from '@progress/kendo-svg-icons';

type RouteComponentParams = {
  salespersonId: string;
}

type Response = {
  Salesperson: IDName;
  Cards: Card[];
  RevenueByDay: number[];
  OrderCountByDay: number[];
  Customers: Customer[];
}

type Card = {
  Title: string;
  FormattedValue: string;
  SubFormattedValue1: string;
  SubFormattedValue2: string;
  SubFormattedValue3: string;
  PercentageChange: number | null;
  Change: string;
}

type Customer = {
  CustomerID: number;
  CustomerName: string;
  TotalCharges: number;
  SingleCharges: number;
  SplitCharges: number;
  OrderCount: number;
  SprinterRPM: number;
  SprinterAllRPM: number;
  StraightRPM: number;
  StraightAllRPM: number;
  PercentageChange: number | null;
  CSVLink: string;
}

const CustomerCell = (props: GridCellProps, callback: (link: string) => void) => {
  if (!props.field || props.rowType !== 'data') return null;
  const dataItem = props.dataItem as Customer;
  return <td>
    <a
      href="#"
      style={{ color: '#007bff' }}
      onClick={(e) => {
        callback(dataItem.CSVLink);
        e.preventDefault();
      }}
    >
      {dataItem.CustomerName}
    </a>
  </td>;
}

const RPMCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;
  const rpm = props.dataItem[props.field];
  return <td>{rpm ? formatNumber(rpm, 'c') : 'N/A'}</td>;
}

const SalesReports = () => {

  const { salespersonId: salespersonIdParam } = useParams<RouteComponentParams>();
  const _export = useRef<ExcelExport | null>(null);
  const { status } = useThemeSwitcher();
  const [loading, setLoading] = useState(true);
  const [customerDateFilter, setCustomerDateFilter] = useState(0);
  const [salesperson, setSalesperson] = useState<IDName>({ ID: 0, Name: '' });
  const [cards, setCards] = useState<Card[]>([]);
  const [dailyRevenue, setDailyRevenue] = useState<number[]>([]);
  const [dailyOrderCount, setDailyOrderCount] = useState<number[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const refresh = useCallback(() => {
    setLoading(true);
    const data = {
      CustomerDateFilter: customerDateFilter
    };
    fetchApi(`/api/Customer/SalespersonDashboard/${salespersonIdParam}`, data, 'POST')
      .then((response: Response) => {
        setSalesperson(response.Salesperson);
        setCards(response.Cards);
        setDailyRevenue(response.RevenueByDay);
        setDailyOrderCount(response.OrderCountByDay);
        setCustomers(response.Customers);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Error fetching data');
      })
      .finally(() => setLoading(false));
  }, [salespersonIdParam, customerDateFilter]);

  const download = (link: string) => {
    window.location.href = link;
  };

  const periodName = (period: number) => {
    switch (period) {
      case 0: return 'This Month';
      case 1: return 'This Quarter';
      case 2: return 'This Year';
      case 3: return 'Top By Quarter';
      case 4: return 'Unrealized';
      default: return '';
    }
  }

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (status !== 'loaded') {
    return <div>Loading...</div>;
  }

  return <React.Fragment>
    <PopupNavBar />
    <Title string="Sales Dashboard" />
    <nav className="navbar navbar-dark bg-dark">
      <Link to="/" className="navbar-brand">{salesperson.Name} {loading && <Loader type="pulsing" className="p-0" />}</Link>
      <ul className="nav navbar-nav navbar-right">
        <Button
          title="Refresh"
          icon="refresh"
          svgIcon={arrowRotateCwIcon}
          disabled={loading}
          onClick={refresh}
        />
      </ul>
    </nav>
    <div className="container-fluid">
      <div className="row">
        {cards.map((card, index) => {
          return <div key={index} className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-3">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="text-muted fw-normal mt-0">{card.Title}</h5>
                <h3 className="mt-3 mb-3">{card.FormattedValue}</h3>
                {card.SubFormattedValue1 && <p className="mb-1">{card.SubFormattedValue1}</p>}
                {card.SubFormattedValue2 && <p className="mb-1">{card.SubFormattedValue2}</p>}
                {card.SubFormattedValue3 && <p className="mb-1">{card.SubFormattedValue3}</p>}
                <p className="mb-0 text-muted">
                  {card.PercentageChange !== null && <span className={`mr-2 ${(card.PercentageChange > 0 ? "text-success" : card.PercentageChange < 0 ? "text-danger" : "")}`}>
                    <SvgIcon icon={card.PercentageChange > 0 ? caretAltUpIcon : card.PercentageChange < 0 ? caretAltDownIcon : undefined} /> {formatNumber(card.PercentageChange, 'p2')}
                  </span>}
                  <span className="text-nowrap">{card.Change}</span>
                </p>
              </div>
            </div>
          </div>
        })}
      </div>
      <br />
      <Chart transitions={false}>
        <ChartTooltip />
        <ChartTitle text="This Month's Revenue" />
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={dailyRevenue.map((_, index) => index + 1)}
            title={{ text: "Day of Month" }}
            axisCrossingValue={[0, dailyRevenue.length]}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem name="Revenue" type="area" axis="revenue" color="#28a745" data={dailyRevenue} tooltip={{ format: "{0:C0}" }} />
          <ChartSeriesItem name="Order Count" type="line" axis="count" color="#dc3545" data={dailyOrderCount} tooltip={{ format: "{0}" }} />
        </ChartSeries>
        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: "Revenue" }} name="revenue" color="#28a745" labels={{ format: "c0" }} />
          <ChartValueAxisItem title={{ text: "Order Count" }} name="count" color="#dc3545" labels={{ format: "n0" }} />
        </ChartValueAxis>
      </Chart>
      <br />
      {loading && <GridLoadingPanel />}
      <ExcelExport ref={_export} fileName={`${salesperson.Name} ${periodName(customerDateFilter)} Dashboard.xlsx`}>
        <ExportColumn field="CustomerName" title="Customer" />
        <ExportColumn field="TotalCharges" title="Total Charges" cellOptions={{ format: "$#,##0.00" }} />
        {customerDateFilter == 3 && <ExportColumn field="SingleCharges" title="Single" cellOptions={{ format: "$#,##0.00" }} />}
        {customerDateFilter == 3 && <ExportColumn field="SplitCharges" title="Split" cellOptions={{ format: "$#,##0.00" }} />}
        <ExportColumn field="OrderCount" title="Order Count" cellOptions={{ format: "#,##0" }} />
        {customerDateFilter == 0 && <ExportColumn field="PercentageChange" title="Last Month" cellOptions={{ format: "0%" }} />}
        <ExportColumn field="SprinterRPM" title="Sprinter >350mi" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="SprinterAllRPM" title="Sprinter +Fuel" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="StraightRPM" title="Straight >350mi" cellOptions={{ format: "$#,##0.00" }} />
        <ExportColumn field="StraightAllRPM" title="Straight +Fuel" cellOptions={{ format: "$#,##0.00" }} />
      </ExcelExport>
      <Grid data={customers}>
        <GridToolbar>
          <ButtonGroup>
            <Button onClick={() => setCustomerDateFilter(2)} selected={customerDateFilter === 2} togglable>{periodName(2)}</Button>
            <Button onClick={() => setCustomerDateFilter(1)} selected={customerDateFilter === 1} togglable>{periodName(1)}</Button>
            <Button onClick={() => setCustomerDateFilter(0)} selected={customerDateFilter === 0} togglable>{periodName(0)}</Button>
            <Button onClick={() => setCustomerDateFilter(3)} selected={customerDateFilter === 3} togglable>{periodName(3)}</Button>
            <Button onClick={() => setCustomerDateFilter(4)} selected={customerDateFilter === 4} togglable>{periodName(4)}</Button>
          </ButtonGroup>
          <Button
            svgIcon={downloadIcon}
            onClick={() => _export.current.save(customers)}
          >Excel
          </Button>
        </GridToolbar>
        <GridColumn field="CustomerName" title="Customer" cell={(props) => CustomerCell(props, download)} />
        <GridColumn field="TotalCharges" title="Total Charges" format="{0:c}" />
        {customerDateFilter == 3 && <GridColumn field="SingleCharges" title="Single" format="{0:c}" />}
        {customerDateFilter == 3 && <GridColumn field="SplitCharges" title="Split" format="{0:c}" />}
        <GridColumn field="OrderCount" title="Order Count" />
        {customerDateFilter == 0 && <GridColumn field="PercentageChange" title="Last Month" format="{0:p}" cell={(props) => <td className={props.dataItem.PercentageChange > 0 ? 'text-success' : props.dataItem.PercentageChange < 0 ? 'text-danger' : ''}>{props.dataItem.PercentageChange !== null ? formatNumber(props.dataItem.PercentageChange, 'p') : "N/A"}</td>} />}
        <GridColumn field="SprinterRPM" title="Sprinter >350mi" cell={RPMCell} />
        <GridColumn field="SprinterAllRPM" title="Sprinter +Fuel" cell={RPMCell} />
        <GridColumn field="StraightRPM" title="Straight >350mi" cell={RPMCell} />
        <GridColumn field="StraightAllRPM" title="Straight +Fuel" cell={RPMCell} />
      </Grid>
    </div>
  </React.Fragment>
}

export default SalesReports;
