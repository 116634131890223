import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { TripDriver } from '.';
import { openWindowPerc } from '../../services/openWindow';
import { ILink } from '../../types/link';
import DriverProfile from '../AssetDrivers/Profile';
import AppTracking from './AppTracking';

const HOSCell = (props: GridCellProps) => {

  const [trackingLink, setTrackingLink] = React.useState<ILink>(null);
  const [showDriverProfileDialog, setShowDriverProfileDialog] = React.useState<TripDriver>(null);

  if (!props.field || props.rowType !== 'data') return null;

  const GetColor = (minuteLeft: number) => {
    return minuteLeft < 60 ? 'badge badge-danger' : minuteLeft < 120 ? 'badge badge-warning' : 'badge badge-info';
  }

  return (
    <td>
      {trackingLink && <AppTracking
        CloseDialog={() => setTrackingLink(null)}
        Link={trackingLink} />}
      {showDriverProfileDialog && <DriverProfile driverId={showDriverProfileDialog.DriverID} isDialog={true} onClose={() => setShowDriverProfileDialog(null)} />}
      {props.dataItem.Drivers.map((driver: TripDriver, index: number) => {
        return (<React.Fragment key={index}>
          <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              window.isTMS ? setShowDriverProfileDialog(driver) : openWindowPerc((driver.Links.find((x: ILink) => x.Name === 'Profile') as ILink).Link);
              e.preventDefault();
            }}>
            {driver.DriverNumber}
          </a>

          <br />
          <a className={driver.DoNotCall || props.dataItem.DrivingBreakDoNotDisturb ? 'display-on-hover' : ''} href={`tel:${driver.DriverPhone}`}><span>{driver.DriverPhone}</span></a>
          <br />
          {driver.Links.find((x: ILink) => x.Name === 'HOSProfile') && <React.Fragment><a
            href="#"
            className={GetColor(driver.MinutesLeft)}
            title={`${driver.Status}`}
            onClick={(e) => {
              openWindowPerc((driver.Links.find((x: ILink) => x.Name === 'HOSProfile') as ILink).Link);
              e.preventDefault();
            }}
          >
            {driver.Formatted}
          </a><br /></React.Fragment>}
        </React.Fragment>)
      })}
      {props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingSetup') && <><a
        href="#"
        className="track-app-setup"
        onClick={(e) => {
          setTrackingLink(props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingSetup') as ILink);
          e.preventDefault();
        }}
      >
        Track By App
      </a><br /></>}
      {props.dataItem.Links.find((x: ILink) => x.Name === 'AppTracking') && <><a
        href="#"
        className="track-app-tracked"
        onClick={(e) => {
          setTrackingLink(props.dataItem.Links.find((x: ILink) => x.Name === 'AppTracking') as ILink);
          e.preventDefault();
        }}
      >
        App Tracked
      </a><br /></>}
      {props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingStale') && <><a
        href="#"
        className="track-app-stale"
        onClick={(e) => {
          setTrackingLink(props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingStale') as ILink);
          e.preventDefault();
        }}
      >
        App &gt;{props.dataItem.DriverAppCarrierHours} Hrs
      </a><br /></>}
      {props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingRequested') && <><a
        href="#"
        className="track-app-requested"
        onClick={(e) => {
          setTrackingLink(props.dataItem.Links.find((x: ILink) => x.Name === 'AppTrackingRequested') as ILink);
          e.preventDefault();
        }}
      >
        App Requested
      </a><br /></>}
      {props.dataItem.DriverPhone && <React.Fragment>
        <a href={`tel:${props.dataItem.DriverPhone}`}>{props.dataItem.DriverPhone}</a>
        {props.dataItem.ExternalUrl && <br />}
      </React.Fragment>}
      {props.dataItem.ExternalUrl && <>
        <a
          href={props.dataItem.ExternalUrl}
          target="_blank"
          rel="noopener noreferrer"
          className='track-external-url'
        >
          {props.dataItem.ExternalUrl}
        </a>
      </>}
    </td>
  );
}

export default React.memo(HOSCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
