import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import TotalCashRequestedNotification from './TotalCashRequestedNotification';

type Props = {
    DriverID: number,
    DriverNumber: string,
    DriverName: string,
    CardNumber: string,
    CloseDialog: () => void;
}

type CurrentCashAmountViewItem = {
    CardNumber: string,
    OneTimeCash: number,
    RecurringCash: number,
    ContractLimit: number,
    CashAvailable: number
}

const AdvanceCashDialog = (props: Props) => {
    const [amount, setAmount] = useState({ value: 0, error: '' });
    const [issuedTo, setIssuedTo] = useState({ value: `${props.DriverName} (${props.DriverNumber})` || '', error: '' });
    const [referenceNumber, setReferenceNumber] = useState({ value: '', error: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const [notificationLoaded, setNotificationLoaded] = useState<boolean>(false);
    const [showGenericDialog, setShowGenericDialog] = useState(false);
    const [advanceCashConfirmation, setAdvanceCashConfirmation] = useState('');
    const [currentCashAmount, setCurrentCashAmount] = useState<CurrentCashAmountViewItem[]>([]);

    useEffect(() => {
        setLoading(true);
        fetchApi(`/api/Asset/GetCurrentEFSCashAmount/${props.CardNumber}`)
            .then((response: CurrentCashAmountViewItem[]) => {
                setCurrentCashAmount(response);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to fetch data');
            });
    }, [props.CardNumber]);

    const _save = () => {
        const amountError = amount.value === 0 ? 'Must contain a valid dollar amount.' : '';
        const issuedToError = !issuedTo.value ? "'Issue To' cannot be empty." : '';
        const referenceNumberError = !referenceNumber.value ? 'Reference number for cash advance cannot be empty.' : '';

        if (issuedToError || amountError || referenceNumberError) {
            setAmount({ ...amount, error: amountError });
            setIssuedTo({ ...issuedTo, error: issuedToError });
            setReferenceNumber({ ...referenceNumber, error: referenceNumberError });
            return;
        }

        setLoading(true);

        const data = {
            CardNumber: props.CardNumber,
            Amount: amount.value,
            ReferenceNumber: referenceNumber.value,
            DriverID: props.DriverID
        }
        fetchApi(`/api/Asset/AdvanceCashEFS`, data, 'POST')
            .then((response: string) => {
                setAdvanceCashConfirmation(response);
                setLoading(false);
                setShowGenericDialog(true);
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to advance cash');
            });
    }

    return <div className="container-fluid">
        <Dialog title={"Advance Cash"} onClose={props.CloseDialog} className="dialog-w525" height={500}>
            {(loading || notificationLoaded === false) && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <div className="row">
                <div className="col">
                    <form className="k-form k-form-md p-0">
                        <FieldWrapper>
                            <Label>Card Number</Label>
                            <Input
                                disabled={true}
                                maxLength={30}
                                readOnly={true}
                                value={props.CardNumber}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Issued To</Label>
                            <Input
                                disabled={loading || notificationLoaded === false}
                                maxLength={30}
                                readOnly={true}
                                value={`${props.DriverName} (${props.DriverNumber})`}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <label>Amount</label>
                            <NumericTextBox
                                disabled={loading || notificationLoaded === false}
                                value={amount.value}
                                onChange={(e) => setAmount({ value: e.value || 0, error: '' })}
                                format="c2"
                                min={currentCashAmount.length > 0 ? currentCashAmount[0].CashAvailable * -1 : 0}
                                step={25}
                            />
                            <Error>{amount.error}</Error>
                        </FieldWrapper>
                        <FieldWrapper>
                            <Label>Reference Number</Label>
                            <Input
                                disabled={loading || notificationLoaded === false}
                                maxLength={24}
                                value={referenceNumber.value}
                                onChange={(e) => setReferenceNumber({ value: e.value as string || '', error: '' })}
                            />
                            <Error>{referenceNumber.error}</Error>
                        </FieldWrapper>
                    </form>
                </div>
                <div className="col">
                    {currentCashAmount.length > 0 && <Card>
                        <CardBody>
                            <CardTitle>Available Cash</CardTitle>
                            <div className="row"><div className="col pr-0">Policy/Card Cash:</div><div className="col text-right pl-0">{formatNumber(currentCashAmount[0].RecurringCash, "c")}</div></div>
                            <div className="row"><div className="col pr-0">One Time Cash:</div><div className="col text-right pl-0">{formatNumber(currentCashAmount[0].OneTimeCash, "c")}</div></div>
                            <div className="row"><div className="col pr-0">Total Cash:</div><div className="col text-right pl-0">{formatNumber(currentCashAmount[0].OneTimeCash + currentCashAmount[0].CashAvailable, "c")}</div></div>
                            <div className="row"><div className="col pr-0">Contract Limit:</div><div className="col text-right pl-0">{formatNumber(currentCashAmount[0].ContractLimit, "c")}</div></div>
                            <div className="row"><div className="col pr-0">Amount Available:</div><div className="col text-right pl-0">{formatNumber(currentCashAmount[0].CashAvailable, "c")}</div></div>
                        </CardBody>
                    </Card>}
                    <div className="row mt-1">
                        <TotalCashRequestedNotification DriverID={props.DriverID}
                            className="col-12 d-flex flex-column flex-grow"
                            Loaded={() => { setNotificationLoaded(true); }} />
                    </div>
                </div>
            </div>
            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" onClick={_save} disabled={loading || notificationLoaded === false || amount.value === 0}>
                    {amount.value >= 0 ? 'Advance Cash' : 'Deduct Cash'}
                </Button>
            </DialogActionsBar>
        </Dialog>
        {showGenericDialog && <Dialog title={"Advance Cash Receipt"} onClose={props.CloseDialog} width={315} height={300}>
            <p style={{ margin: "15px", textAlign: "center", fontSize: "small" }}>
                {`${advanceCashConfirmation}`}
            </p>
            <p style={{ margin: "15px", textAlign: "center", fontSize: "smaller" }} className="font-italic">
                *This number is a receipt used for reconciliation purposes and does not need to be given to the driver.
            </p>
            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Close</Button>
            </DialogActionsBar>
        </Dialog>}
    </div>
}

export default AdvanceCashDialog;
