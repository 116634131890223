import { createContext, useContext, useState } from "react";
import { Title } from "utils/title";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { fetchApi } from "services/api";
import Moment from 'moment-timezone';
import LoadingPanel from "components/LoadingPanel";
import SingleLocationAutoComplete from "views/PCMiler/SingleLocationAutoComplete";
import { ISingleSearchLocation } from "services/pcmiler";
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { VehicleTypes } from "views/AssetVehicles/Profile";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Link, useHistory } from "react-router-dom";
import { Window } from "@progress/kendo-react-dialogs";
import TrimbleMap from "components/TrimbleMap";
import { LngLat } from "@trimblemaps/trimblemaps-js";
import { paths } from "routes";

const VehicleTypesAll = [{ ID: 0, Name: 'ANY SIZE' }].concat(VehicleTypes);

type Props = {
  children?: React.ReactNode;
}

type BackhaulContextType = {
  data: any[];
}

export const BackhaulContext = createContext<BackhaulContextType>({
  data: [],
});

export const CustomersBackhaulMap = () => {
  const history = useHistory();
  const { data } = useContext(BackhaulContext);
  return <Window stage="FULLSCREEN" style={{ width: '100%' }} onClose={history.goBack}>
      <TrimbleMap locations={data.map(x => { return { lnglat: new LngLat(x.Longitude, x.Latitude), text: x.CustomerName }})} />
  </Window>
}

const CustomersBackhaul = (props: Props) => {
  const history = useHistory();
  const [data, setData] = useState<any[]>([])
  const [location, setLocation] = useState<ISingleSearchLocation>(null);
  const [radius, setRadius] = useState(50);
  const [vehicleTypeId, setVehicleTypeId] = useState(0);
  const [eventType, setEventType] = useState<1|2>(1);
  const [fromDate, setFromDate] = useState<Date | null>(Moment().subtract(2, 'months').toDate());
  const [loading, setLoading] = useState(false);
  const [missingData, setMissingData] = useState(false);

  const searchCustomers = async () => {

    if (!location) {
      alert('Please select a location');
      return;
    }

    setLoading(true);
    const payload = {
      Coordinates: {
        Latitude: parseFloat(location.Coords.Lat),
        Longitude: parseFloat(location.Coords.Lon)
      },
      Radius: radius,
      VehicleTypeID: vehicleTypeId,
      FromDate: fromDate,
      EventType: eventType
    }

    fetchApi('/api/Customer/Backhaul', payload, 'POST')
      .then((response: { Data: any[], MissingData: boolean }) => {
        setData(response.Data);
        setMissingData(response.MissingData);
      })
      .catch((e) => {
        // If not problem details
        if (!e?.status) alert('Unable to search customers');
      })
      .finally(() => setLoading(false));
  }

  return <div>
    <Title string="Backhaul Assistant" />
    {loading && <LoadingPanel />}
    <br />
    {missingData && <div className="alert alert-info" role="alert">
      Please adjust the radius smaller or change the vehicle type to find more customers.
    </div>}
    <BackhaulContext.Provider value={{ data }}>
        {props.children}
    </BackhaulContext.Provider>
    <Grid data={data} scrollable="none">
      <GridToolbar>
        <SingleLocationAutoComplete
          value={(location?.Address.Zip || location?.Address.City) && `${location?.Address.Zip} ${location?.Address.City}, ${location?.Address.State}`}
          autoCompleteProps={{ clearButton: true, placeholder: 'Enter City or Zip', style: { width: 300 } }}
          onSelected={(e) => {
            setLocation(e);
          }}
        />
        <NumericTextBox value={radius} onChange={(e) => setRadius(e.value)} format="0 miles" min={1} max={500} step={5} spinners={false} />
        <ButtonGroup>
          <Button togglable onClick={() => setEventType(1)} selected={eventType === 1}>Pickups</Button>
          <Button togglable onClick={() => setEventType(2)} selected={eventType === 2}>Deliveries</Button>
        </ButtonGroup>
        As Of <DatePicker
          value={fromDate}
          onChange={(e) => setFromDate(e.value)}
        />
        <DropDownList
          style={{ width: 200 }}
          data={VehicleTypesAll}
          textField="Name"
          dataItemKey="ID"
          value={VehicleTypesAll.find(x => x.ID === vehicleTypeId)}
          onChange={(e) => setVehicleTypeId(e.value.ID)}
        />
        <Button themeColor="primary" onClick={searchCustomers}>Search</Button>
        {data.length > 0 && <Button onClick={() => history.push(paths.CustomersBackhaulMap)}>Map</Button>}
      </GridToolbar>
      <GridNoRecords>
        <span>No customers found</span>
      </GridNoRecords>
      <Column field="CustomerNumber" title="Customer #" cell={(e) => <td><Link style={{ color: "#007bff" }} to={`/Customers/Customer/${e.dataItem.CustomerID}`}>{e.dataItem.CustomerNumber}</Link></td>} />
      <Column field="CustomerName" title="Name" />
      <Column field="SalespersonName" title="Salesperson" />
      <Column field="AddressLine1" title="Address" />
      <Column field="CityStateZip" title="City, State" />
      <Column field="PhoneNumber" title="Phone" />
      <Column field="StopCount" title="Stops" />
      <Column field="Distance" format={"{0} mi"} title="Distance" />
    </Grid>
  </div>
}

export default CustomersBackhaul;