export const StateCodes: Array<{ ID: string, Name: string }> = [
  { ID: "AB", Name: "AB - ALBERTA" },
  { ID: "AG", Name: "AG - AGUASCALIENTES" },
  { ID: "AK", Name: "AK - ALASKA" },
  { ID: "AL", Name: "AL - ALABAMA" },
  { ID: "AR", Name: "AR - ARKANSAS" },
  { ID: "AZ", Name: "AZ - ARIZONA" },
  { ID: "BC", Name: "BC - BRITISH COLUMBIA" },
  { ID: "BJ", Name: "BJ - BAJA CALIFORNIA" },
  { ID: "BS", Name: "BS - BAJA CALIFORNIA SUR" },
  { ID: "CA", Name: "CA - CALIFORNIA" },
  { ID: "CH", Name: "CH - CHIAPAS" },
  { ID: "CI", Name: "CI - CHIHUAHUA" },
  { ID: "CL", Name: "CL - COLIMA" },
  { ID: "CO", Name: "CO - COLORADO" },
  { ID: "CP", Name: "CP - CAMPECHE" },
  { ID: "CT", Name: "CT - CONNECTICUT" },
  { ID: "CU", Name: "CU - COAHUILA DE ZARAGOZA" },
  { ID: "DC", Name: "DC - WASHINGTON DC" },
  { ID: "DE", Name: "DE - DELAWARE" },
  { ID: "DF", Name: "DF - DISTRITO FEDERAL" },
  { ID: "DG", Name: "DG - DURANGO" },
  { ID: "EM", Name: "EM - MEXICO (ESTADO)" },
  { ID: "FL", Name: "FL - FLORIDA" },
  { ID: "GA", Name: "GA - GEORGIA" },
  { ID: "GJ", Name: "GJ - GUANAJUATO" },
  { ID: "GR", Name: "GR - GUERRERO" },
  { ID: "GU", Name: "GU - GUAM" },
  { ID: "HG", Name: "HG - HIDALGO" },
  { ID: "HI", Name: "HI - HAWAII" },
  { ID: "IA", Name: "IA - IOWA" },
  { ID: "ID", Name: "ID - IDAHO" },
  { ID: "IL", Name: "IL - ILLINOIS" },
  { ID: "IN", Name: "IN - INDIANA" },
  { ID: "JA", Name: "JA - JALISCO" },
  { ID: "KS", Name: "KS - KANSAS" },
  { ID: "KY", Name: "KY - KENTUCKY" },
  { ID: "LA", Name: "LA - LOUISIANA" },
  { ID: "MA", Name: "MA - MASSACHUSETTS" },
  { ID: "MB", Name: "MB - MANITOBA" },
  { ID: "MD", Name: "MD - MARYLAND" },
  { ID: "ME", Name: "ME - MAINE" },
  { ID: "MH", Name: "MH - MICHOACAN DE OCAMPO" },
  { ID: "MI", Name: "MI - MICHIGAN" },
  { ID: "MN", Name: "MN - MINNESOTA" },
  { ID: "MO", Name: "MO - MISSOURI" },
  { ID: "MR", Name: "MR - MORELOS" },
  { ID: "MS", Name: "MS - MISSISSIPPI" },
  { ID: "MT", Name: "MT - MONTANA" },
  { ID: "MX", Name: "MX - MEXICO" },
  { ID: "NA", Name: "NA - NAYARIT" },
  { ID: "NB", Name: "NB - NEW BRUNSWICK" },
  { ID: "NC", Name: "NC - NORTH CAROLINA" },
  { ID: "ND", Name: "ND - NORTH DAKOTA" },
  { ID: "NE", Name: "NE - NEBRASKA" },
  { ID: "NH", Name: "NH - NEW HAMPSHIRE" },
  { ID: "NJ", Name: "NJ - NEW JERSEY" },
  { ID: "NL", Name: "NL - NEWFOUNDLAND" },
  { ID: "NM", Name: "NM - NEW MEXICO" },
  { ID: "NS", Name: "NS - NOVA SCOTIA" },
  { ID: "NT", Name: "NT - NW TERRITORIES" },
  { ID: "NU", Name: "NU - NUNAVUT" },
  { ID: "NV", Name: "NV - NEVADA" },
  { ID: "NX", Name: "NX - NUEVO LEON" },
  { ID: "NY", Name: "NY - NEW YORK" },
  { ID: "OA", Name: "OA - OAXACA" },
  { ID: "OH", Name: "OH - OHIO" },
  { ID: "OK", Name: "OK - OKLAHOMA" },
  { ID: "ON", Name: "ON - ONTARIO" },
  { ID: "OR", Name: "OR - OREGON" },
  { ID: "PA", Name: "PA - PENNSYLVANIA" },
  { ID: "PE", Name: "PE - PRINCE EDWARD I" },
  { ID: "PR", Name: "PR - PUERTO RICO" },
  { ID: "PU", Name: "PU - PUEBLA" },
  { ID: "QA", Name: "QA - QUERETARO ARTEAGA" },
  { ID: "QC", Name: "QC - QUEBEC" },
  { ID: "QR", Name: "QR - QUINTANA ROO" },
  { ID: "RI", Name: "RI - RHODE ISLAND" },
  { ID: "SC", Name: "SC - SOUTH CAROLINA" },
  { ID: "SD", Name: "SD - SOUTH DAKOTA" },
  { ID: "SI", Name: "SI - SINALOA" },
  { ID: "SK", Name: "SK - SASKATCHEWAN" },
  { ID: "SL", Name: "SL - SAN LUIS POTOSI" },
  { ID: "SO", Name: "SO - SONORA" },
  { ID: "TA", Name: "TA - TABASCO" },
  { ID: "TL", Name: "TL - TLAXCALA" },
  { ID: "TM", Name: "TM - TAMAULIPAS" },
  { ID: "TN", Name: "TN - TENNESSEE" },
  { ID: "TX", Name: "TX - TEXAS" },
  { ID: "UT", Name: "UT - UTAH" },
  { ID: "VA", Name: "VA - VIRGINIA" },
  { ID: "VT", Name: "VT - VERMONT" },
  { ID: "VZ", Name: "VZ - VERACRUZ" },
  { ID: "WA", Name: "WA - WASHINGTON" },
  { ID: "WI", Name: "WI - WISCONSIN" },
  { ID: "WV", Name: "WV - WEST VIRGINIA" },
  { ID: "WY", Name: "WY - WYOMING" },
  { ID: "XX", Name: "XX - NON-PCMILER CITY" },
  { ID: "YC", Name: "YC - YUCATAN" },
  { ID: "YK", Name: "YK - YUKON TERRITORY" },
  { ID: "YT", Name: "YT - YUKON TERRITORY" },
  { ID: "ZT", Name: "ZT - ZACATECAS" }
];