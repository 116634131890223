import * as React from 'react';
import Moment from 'moment-timezone';
import { formatNumber } from '@progress/kendo-intl';
import { TransitValue } from './StopTransitData';
import timezoneFormatter from '../../services/formatting/timezone';
import timezoneFormatterIANA from '../../services/formatting/timezone-iana';
import { EnterFreightViewModel, EnterStopViewModel } from '.';
import { getQuoteStopType } from './GetStopType';
import { getStopVerb } from './GetStopVerb';
import { dimsDisplayValue, dimsUnitOfMeasureAbbreviation, getVehicleTypeIDName, weightDisplayValue, weightUnitOfMeasureAbbreviation } from '../../utils/utils';
import { OfferValue } from './Vehicles';

type Props = {
  QuoteID: number;
  Offer: OfferValue;
  TransitData: TransitValue|null;
  Stops: EnterStopViewModel[];
  Locations: EnterStopViewModel[];
  Freight: EnterFreightViewModel[];
  VehicleTypeID: number;
  CloseDialog: () => void;
};

const styles = {
  container: {
    backgroundColor: '#175985',
    color: 'white',
    padding: 5,
  },
  tableLabel: {
    border: '1pt solid'
  },
  tableData: {
    border: '1pt solid'
  }
}

export default class QuoteEmailTemplateCarrier extends React.PureComponent<Props> {
  render() {
    return <div id="QuoteEmailTemplateCarrier" style={{ backgroundColor: 'white' }}>
          <img width={150} src="https://app.load1.com/images/l1logo.png" alt="" />
          <br />&nbsp;
          <table style={styles.container}>
            <tbody>
              <tr>
                <td>
                  <h3>Quote No. {this.props.QuoteID}: Your all-in bid was: {formatNumber(this.props.Offer.AllInRate, "c")}</h3>
                </td>
              </tr>
              {this.props.Offer.Note && <>
                <tr>
                  <td>
                    <h4>Notes</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{this.props.Offer.Note}</b>
                  </td>
                </tr>
              </>}
              {this.props.Offer.AlternativePickup &&
                <tr>
                  <td>
                    <b>Alt Pickup: {Moment.utc(this.props.Offer.AlternativePickup).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</b>
                  </td>
                </tr>}
              {this.props.Offer.AlternativeDelivery &&
                <tr>
                  <td>
                    <b>Alt Delivery: {Moment.utc(this.props.Offer.AlternativeDelivery).tz("America/New_York").format("MM/DD/YYYY HH:mm")}</b>
                  </td>
                </tr>}
              <tr>
                <td>
                  <h4>Stops</h4>
                </td>
              </tr>
              {this.props.Stops.map((stop, index) => {
                return <tr key={index}>
                  <td>
                    <b>{getQuoteStopType(stop.StopType)}</b><br />
                    {stop.Location}<br />
                    {Moment(stop.DateTime).tz(timezoneFormatterIANA(this.props.Locations[index] && this.props.Locations[index].Location.TimeZone)).format("MM/DD/YYYY HH:mm")} {timezoneFormatter(this.props.Locations[index] && this.props.Locations[index].Location.TimeZone)} ({getStopVerb(stop.StopVerb)})
                  </td>
                </tr>
              })}
              {this.props.TransitData && <tr>
                <td>{formatNumber(this.props.TransitData.Distance, "n0")} Miles</td>
              </tr>}
              <tr>
                <td>
                  <h4>Freight ({getVehicleTypeIDName(this.props.VehicleTypeID)})</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <table style={{ color: 'white' }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'left', width: '100px' }}>Pieces</th>
                        <th style={{ textAlign: 'left', width: '100px' }}>Weight</th>
                        <th style={{ textAlign: 'left', width: '100px' }}>Dims (LxWxH)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.Freight.map((freight, index) => {
                        return <tr key={index}>
                          <td>{freight.Pieces}</td>
                          <td>{weightDisplayValue(freight.Weight, freight.WeightUnitOfMeasure)} {weightUnitOfMeasureAbbreviation(freight.WeightUnitOfMeasure)}</td>
                          <td>{dimsDisplayValue(freight.Length, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Width, freight.DimsUnitOfMeasure)} x {dimsDisplayValue(freight.Height, freight.DimsUnitOfMeasure)} {dimsUnitOfMeasureAbbreviation(freight.DimsUnitOfMeasure)}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  }

  public componentDidMount() {
    window.getSelection().selectAllChildren(document.getElementById("QuoteEmailTemplateCarrier") as HTMLDivElement);
    document.execCommand('copy');

    this.props.CloseDialog();
  }
}
