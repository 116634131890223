import { Button } from '@progress/kendo-react-buttons';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import { useState } from 'react';
import realFormatter from 'services/formatting/number';
import { IDNameViewModel } from 'TypeGen/id-name-view-model';
import { FreightDetail } from 'TypeGen/Order/Load/freight-detail';
import { RequestFreightDetail } from 'TypeGen/Order/Load/request-freight-detail';

type Props = {
  piecesUnitOfMeasure: IDNameViewModel[];
  weightUnitOfMeasure: IDNameViewModel[];
  dimsUnitOfMeasure: IDNameViewModel[];
  data: FreightDetail;
  request: RequestFreightDetail;
  updateRequest: (request: Partial<RequestFreightDetail>) => void;
}

const LoadFreight = ({ data, request, updateRequest, ...props }: Props) => {

  const piecesUoM = (piecesUoM: number) => props.piecesUnitOfMeasure.find(x => x.ID === piecesUoM).Name;
  const weightUoM = (weightUoM: number) => props.weightUnitOfMeasure.find(x => x.ID === weightUoM).Name;
  const dimsUoM = (dimsUoM: number) => props.dimsUnitOfMeasure.find(x => x.ID === dimsUoM).Name;

  const [collapsed, setCollapsed] = useState(true);

  return <>
    <div className="form-group row">
      <label className="col-md-4 col-form-label py-0">
        Pieces
        <button
          className="btn btn-link"
          onClick={() => updateRequest({ Pieces: data.Pieces, PiecesUnitOfMeasure: data.PiecesUnitOfMeasure })}
        >
          {realFormatter(data.Pieces)} {piecesUoM(data.PiecesUnitOfMeasure)}
        </button>
      </label>
      <div className="col-md-8">
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={0}
            value={request.Pieces > 0 ? request.Pieces : ''}
            placeholder="Pieces"
            onFocus={(e) => e.target.select()}
            onChange={(e) => updateRequest({ Pieces: parseInt(e.target.value) || 0 })}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{piecesUoM(request.PiecesUnitOfMeasure)}</button>
            <div className="dropdown-menu">
              {props.piecesUnitOfMeasure.map(x => <button type="button" className="dropdown-item" onClick={() => updateRequest({ PiecesUnitOfMeasure: x.ID })}>{x.Name}</button>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-md-4 col-form-label py-0">
        Weight
        <button
          className="btn btn-link"
          onClick={() => updateRequest({ Weight: data.Weight, WeightUnitOfMeasure: data.WeightUnitOfMeasure })}
        >
          {realFormatter(data.Weight)} {weightUoM(data.WeightUnitOfMeasure)}
        </button>
      </label>
      <div className="col-md-8">
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={0}
            value={request.Weight > 0 ? request.Weight : ''}
            placeholder="Weight"
            onFocus={(e) => e.target.select()}
            onChange={(e) => updateRequest({ Weight: parseInt(e.target.value) || 0 })}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{weightUoM(request.WeightUnitOfMeasure)}</button>
            <div className="dropdown-menu">
              {props.weightUnitOfMeasure.map(x => <button type="button" className="dropdown-item" onClick={() => updateRequest({ WeightUnitOfMeasure: x.ID })}>{x.Name}</button>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-md-4 col-form-label">Bill of Lading</label>
      <div className="col-md-8">
        <Input
          required
          value={request.BillOfLading}
          onChange={(e) => updateRequest({ BillOfLading: e.target.value.toString() })}
          style={{ width: "100%" }}
        />
      </div>
    </div>
    {collapsed ? <div className="d-flex justify-content-center mb-2">
      <Button icon="arrow-chevron-down" svgIcon={chevronDownIcon} onClick={() => setCollapsed(false)}>Additional Freight Details</Button>
    </div> : <>
      <div className="form-group row">
        <label className="col-md-4 col-form-label py-0">
          Dims (LxWxH)
          <button
            className="btn btn-link"
            onClick={() => updateRequest({ Length: data.Length, Width: data.Width, Height: data.Height, DimsUnitOfMeasure: data.DimsUnitOfMeasure })}
          >
            {realFormatter(data.Length)}x{realFormatter(data.Width)}x{realFormatter(data.Height)} {dimsUoM(data.DimsUnitOfMeasure)}
          </button>
        </label>
        <div className="col-md-8">
          <div className="input-group">
            <input
              type="number"
              className="form-control"
              placeholder="L"
              value={request.Length > 0 ? request.Length : ''}
              onFocus={(e) => e.target.select()}
              onChange={(e) => updateRequest({ Length: parseInt(e.target.value) || 0 })}
            />
            <div className="input-group-prepend">
                <span className="input-group-text">X</span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder="W"
              value={request.Width > 0 ? request.Width : ''}
              onFocus={(e) => e.target.select()}
              onChange={(e) => updateRequest({ Width: parseInt(e.target.value) || 0 })}
            />
            <div className="input-group-prepend">
                <span className="input-group-text">X</span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder="H"
              value={request.Height > 0 ? request.Height : ''}
              onFocus={(e) => e.target.select()}
              onChange={(e) => updateRequest({ Height: parseInt(e.target.value) || 0 })}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary dropdown-toggle" style={{ borderRadius: 0 }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{dimsUoM(request.DimsUnitOfMeasure)}</button>
              <span>
                <div className="dropdown-menu">
                  {props.dimsUnitOfMeasure.map(x => <button type="button" className="dropdown-item" onClick={() => updateRequest({ DimsUnitOfMeasure: x.ID })}>{x.Name}</button>)}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row">
          <label className="col-md-4 col-form-label">In Bond No.</label>
          <div className="col-md-8">
            <Input
              value={request.InBondNumber}
              onChange={(e) => updateRequest({ InBondNumber: e.target.value.toString() })}
              style={{ width: "100%" }}
            />
          </div>
      </div>
      <div className="form-group row">
        <label className="col-md-4 col-form-label">Freight Value</label>
        <div className="col-md-8">
          <NumericTextBox
            min={0}
            spinners={false}
            format="c2"
            value={request.FreightValue}
            onChange={(e) => updateRequest({ FreightValue: e.value })}
            width="100%"
          />
        </div>
      </div>
    </>}
  </>
}
export default LoadFreight;
