import * as React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridSortChangeEvent, GridFilterChangeEvent } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SortDescriptor, orderBy, FilterDescriptor, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { fetchApi } from '../../services/api';
import DateCell from '../../components/cells/DateCell';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Moment from 'moment-timezone';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';

type Props = {};

type State = {
  data: CallLog[];
  sort: SortDescriptor[];
  filter: CompositeFilterDescriptor;
  andFilter: FilterDescriptor[];
  loading: boolean;
  isMoreRecords: boolean;

  // Filters
  CategoryType: string;
  From?: Date;
  To?: Date;
}

type CallLog = {
  CalleeName: string;
  CalleeNumber: string;
  CalleeNumberType: number;
  CallerName: string;
  CallerNumber: string;
  CallerNumberType: number;
  DateTime: Date;
  Direction: string;
  Duration: number;
  IsRecorded: boolean;
  Result: string;
}

export default class Zoom extends React.Component<Props, State> {

  private export: React.RefObject<ExcelExport>;

  private CategoryTypes = [
    { id: "all", text: "All" },
    { id: "account_allow_list", text: "Approved account" },
    { id: "account_blocked", text: "Blocked account" },
    { id: "auto_receptionist", text: "Auto receptionist" },
    { id: "backup_routing_rule", text: "Internal directory routing rules" },
    { id: "barge_monitor_whisper", text: "Call monitoring" },
    { id: "call_forward", text: "Call forwarding" },
    { id: "call_log", text: "Call logs" },
    { id: "call_queue", text: "Call queues" },
    { id: "call_setting", text: "Call setting" },
    { id: "calling_plan", text: "Calling plan" },
    { id: "company_info", text: "Company information" },
    { id: "contact_center", text: "Contact center" },
    { id: "contact_center_domain", text: "Domain of the contact center" },
    { id: "csv_import", text: "CSV file imports" },
    { id: "custom_vm_greeting", text: "Custom voicemail greeting" },
    { id: "delegation", text: "Call delegation" },
    { id: "device", text: "Zoom Phone device" },
    { id: "dial_by_name", text: "Dial-by-name directory" },
    { id: "emergency_address", text: "Emergency address" },
    { id: "emergency_calling", text: "Emergency calling" },
    { id: "emergency_service", text: "Emergency service" },
    { id: "extension", text: "Extension number" },
    { id: "extension_line_keys", text: "Extension line keys" },
    { id: "extension_template", text: "Extension template" },
    { id: "external_contacts", text: "External contacts" },
    { id: "holiday_hours", text: "Holiday hours" },
    { id: "location", text: "Location" },
    { id: "multiple_site", text: "Multiple sites" },
    { id: "outbound_number", text: "Outbound number" },
    { id: "phone_number", text: "Phone number" },
    { id: "policy", text: "Policy" },
    { id: "provision_template", text: "Provision templates" },
    { id: "recording", text: "Call recordings" },
    { id: "shared_line_group", text: "Shared line groups" },
    { id: "sip_group", text: "SIP group" },
    { id: "sip_trunk_group", text: "SIP trunk group" },
    { id: "spam", text: "Spam" },
    { id: "user", text: "User" },
    { id: "user_blocked", text: "Blocked user" },
    { id: "voicemail", text: "Voicemail" },
    { id: "zoom_phone_role", text: "Zoom Phone roles" },
    { id: "zoom_room", text: "Zoom Rooms PBX Support" },
  ]

  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      sort: [{field: "Time", dir: "desc"}],
      filter: { logic: 'and', filters: [] },
      andFilter: [],
      loading: false,
      isMoreRecords: false,

      CategoryType: 'all',
    };

    this.export = React.createRef();

    this.reset = this.reset.bind(this);
    this.selectCategoryType = this.selectCategoryType.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    return (
      <React.Fragment>
        {this.state.loading && <span
          className="k-i-loading k-icon"
          style={{ fontSize: 64, position: 'absolute', left: '50%', top: '5%', zIndex: 999 }}
        />}
        {this.state.isMoreRecords && <>
          <br />
          <div className="alert alert-warning" role="alert">There are more records not shown.</div>
        </>}
        <ExcelExport ref={this.export}>
          <Grid
              filter={this.state.filter}
              onFilterChange={this.onFilterChange}
              sortable={{ allowUnsort: true }}
              onSortChange={this.onSortChange}
              scrollable={'none'}
              data={orderBy(filterBy(this.state.data, this.state.filter), this.state.sort)}
              sort={this.state.sort}
            >
            <GridToolbar>
            <DropDownList
                data={this.CategoryTypes}
                textField="text"
                value={this.CategoryTypes.find(x => x.id == this.state.CategoryType)}
                onChange={this.selectCategoryType}
            />
            <DatePicker
              title="From"
              value={Moment(this.state.From).toDate()}
              onChange={(e) => this.setState({ From: e.value }, this.fetchData)}
            />
            <DatePicker
              title="To"
              value={Moment(this.state.To).toDate()}
              onChange={(e) => this.setState({ To: e.value }, this.fetchData)}
            />
            <Button
              title="Reset Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={this.reset}
            />
            <Button
              title="Refresh"
              icon="refresh"
              svgIcon={arrowRotateCwIcon}
              onClick={this.fetchData}
            />
            <Button
              onClick={() => this.export.current.save(this.state.data)}
            >Excel
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Action', 'eq', 'UPDATE')}
              onClick={() => this.filterAnd('Action', 'eq', 'UPDATE')}
            >Update
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Action', 'eq', 'OPERATE')}
              onClick={() => this.filterAnd('Action', 'eq', 'OPERATE')}
            >Operate
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Action', 'eq', 'DOWNLOAD')}
              onClick={() => this.filterAnd('Action', 'eq', 'DOWNLOAD')}
            >Download
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Action', 'eq', 'ADD')}
              onClick={() => this.filterAnd('Action', 'eq', 'ADD')}
            >Add
            </Button>
            <Button
              togglable
              selected={this.hasAndFilter('Action', 'eq', 'DELETE')}
              onClick={() => this.filterAnd('Action', 'eq', 'DELETE')}
            >Delete
            </Button>
            </GridToolbar>
            <Column field="Time" cell={DateCell} filter="date" />
            <Column field="Operator" />
            <Column field="CategoryType" title="Category" />
            <Column field="Action" />
            <Column field="OperationDetail" title="Operation Detail" />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
  private selectCategoryType(e: DropDownListChangeEvent) {
    this.setState({ CategoryType: e.value.id }, this.fetchData);
  }

  private reset() {
    this.setState({ sort: [{field: "DateTime", dir: "desc"}] });
  }

  private onFilterChange(event: GridFilterChangeEvent) {
    this.setState({
      filter: event.filter
    });
  }

  private onSortChange(changeEvent: GridSortChangeEvent) {
    this.setState({ sort: changeEvent.sort });
  }

  private hasAndFilter(field: string, operator: string = 'eq', value: any = true): boolean {
    return this.state.andFilter
      .filter(x => x.field === field && x.operator === operator && x.value === value)
      .length > 0;
  }

  private filterAnd(field: string, operator: string = 'eq', value: any = true) {
    const enabled = this.hasAndFilter(field, operator, value);
    const existingFilter = this.state.andFilter.filter(x => x.field !== field);
    let newStatusFilter = [...existingFilter, { field: field, operator: operator, value: value }] as FilterDescriptor[];
    if (enabled) {
      newStatusFilter = existingFilter;
    }
    const newFilter = {
      logic: 'and',
      filters: newStatusFilter
    } as CompositeFilterDescriptor;
    this.setState({
      filter: newFilter,
      andFilter: newStatusFilter,
    });
  }

  private fetchData() {
    this.setState({ loading: true });

    fetchApi('/api/Zoom/PhoneOperationLog', { 
      CategoryType: this.state.CategoryType,
      From: this.state.From,
      To: this.state.To
    }, 'POST')
      .then((response: { Logs: CallLog[], From: Date, To: Date, IsMoreRecords: boolean }) => {
        this.setState({ 
          data: response.Logs,
          From: response.From,
          To: response.To,
          isMoreRecords: response.IsMoreRecords,
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
  }
}
