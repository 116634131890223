import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Upload as KendoUpload, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import * as React from 'react';
import { useEffect, useState } from 'react';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from '../../services/api';
import { kendoUploadOnStatusChangeEventHandler } from '../../utils/kendo';
import { Title } from '../../utils/title';
import { JsonResponse } from 'TypeGen/json-response';

type PayPeriodViewModel = {
    PayPeriodID: number;
    PeriodEndDate: Date;
    Name: string;
    Label: string;
};

const UploadAmortizedVehicleChargebacks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [payPeriods, setPayPeriods] = useState<PayPeriodViewModel[]>([]);
    const [payPeriodID, setPayPeriodID] = useState(0);

    useEffect(() => {
        ((document.getElementsByClassName("k-upload-button")[0] as HTMLElement).getElementsByTagName("span")[0] as HTMLElement).innerText = "Select file...";
        ((document.getElementsByClassName("k-dropzone")[0] as HTMLElement).getElementsByClassName("k-dropzone-hint")[0] as HTMLElement).innerText = "Drop file here to upload";

        fetch();
    }, []);

    const fetch = () => {
        setIsLoading(true);

        fetchApi('/api/Settlements/IsAdmin')
            .then((response: JsonResponse) => {
                setIsAdmin(response.Success);
                if (response.Success) {
                    const data = {
                        IsOpen: true,
                        PayrollClass: 2 //Driver
                    };
                    fetchApi('/api/Settlements/GetPayPeriods', data, 'POST')
                        .then((response: any) => {
                            const payperiodViewModels = response as PayPeriodViewModel[];
                            if (payperiodViewModels.length > 0) {
                                setPayPeriods(payperiodViewModels);
                                setPayPeriodID(payperiodViewModels[0].PayPeriodID);
                            }
                            setIsLoading(false);
                        });
                } else
                    setIsLoading(false);
            })
    }

    const onStatusChange = (e: UploadOnStatusChangeEvent) => {
        setIsLoading(false);
        kendoUploadOnStatusChangeEventHandler(e);
    }

    return (
        <>
            <Title string="Upload Amortized Vehicle Chargebacks" />
            {isLoading && <LoadingPanel />}
            <div className="container" style={{
                marginTop: "30px", padding: "10px 10px 10px 10px", border: "1px solid #BFBFBF", borderRadius: "5px"
            }}>
                <div className="row">
                    <div className="col">
                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                            <span className="k-window-title k-dialog-title">Upload Amortized Vehicle Chargebacks</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <div className="k-window-content k-dialog-content">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group row">
                                        <label className="col-md-2 col-form-label" style={{ whiteSpace: "nowrap" }}>Pay Period:</label>
                                        <div className="col-md-10">
                                            <DropDownList
                                                disabled={!isAdmin}
                                                required
                                                textField="Label"
                                                dataItemKey="PayPeriodID"
                                                data={payPeriods}
                                                defaultValue={payPeriods.length > 0 ? payPeriods[0] : null}
                                                style={{ width: "100%" }}
                                                onChange={(e) => setPayPeriodID((e.target.value as PayPeriodViewModel).PayPeriodID)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">
                                    <KendoUpload
                                        disabled={payPeriodID === 0 || !isAdmin}
                                        batch={false}
                                        multiple={false}
                                        autoUpload={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        restrictions={{
                                            allowedExtensions: ['.csv']
                                        }}
                                        saveUrl={`/api/Settlements/Upload/AmortizedVehicleChargebacks?payperiodid=${payPeriodID}`}
                                        saveMethod="POST"
                                        onBeforeUpload={() => setIsLoading(true)}
                                        onStatusChange={onStatusChange}
                                    />
                                    <p className="p-1">
                                        File type supported: .csv
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadAmortizedVehicleChargebacks;