import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps } from "@progress/kendo-react-grid";
import { trashIcon } from "@progress/kendo-svg-icons";

type Props = {
  remove: (dataItem: any) => void;
} & GridCellProps;

const PayCommandCell = (props: Props) => {
  const { dataItem } = props;

  return <td className="k-command-cell">
      <Button
        icon="trash"
        svgIcon={trashIcon}
        themeColor="error"
        fillMode="outline"
        onClick={() =>
          dataItem.Description ? window.confirm(`Confirm removing ${dataItem.Description}?`) && props.remove(dataItem) : props.remove(dataItem)
        }
      />
    </td>
};

export default PayCommandCell;