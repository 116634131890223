import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { EFSCardCashHistoryViewItem } from './DriverCardMaintenance';
import { formatNumber } from '@progress/kendo-intl';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from '@progress/kendo-react-indicators';

type Props = {
    DriverID: number,
    IsGridInFocus?: boolean,
    TABRefreshIteration?: number
}

const GetCashIdMappingValue = (Id: string): string => {
    switch (Id) {
        case "BBAL": return "Beginning Balance";
        case "CLRF": return "Cash Load/Remove Fee";
        case "CRED": return "Credit";
        case "Card": return "Balance Due To Card Transfer"; //per their doc
        case "CARD": return "Balance Due To Card Transfer";
        case "DATD": return "ATM Denial Fee";
        case "DATW": return "ATM Withdrawl Fee";
        case "DCGL": return "Card Load Fee";
        case "DLRF": return "Cash Load/Remove Fee";
        case "DREC": return "Regular Check Fee";
        case "DTCU": return "Network Use Fee";
        case "LOAD": return "Load Funds";
        case "RMVE": return "Remove Funds";
        case "WDRW": return "withdrawal";
    }
    return Id;
}

const InfoIdCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    let dataItem: EFSCardCashHistoryViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {GetCashIdMappingValue(dataItem.CashId)}
        </td>
    );
};

const AmountCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    const dataItem: EFSCardCashHistoryViewItem = props.dataItem;
    //props.dataItem[props.field]
    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {formatNumber(dataItem.Amount, "c")}
        </td>
    );
};

const DateCell = (props: GridCellProps) => {
    if (!props.field)
        return null;

    return (
        <td colSpan={props.colSpan} style={props.style} className={props.className}>
            {Moment.utc(props.dataItem[props.field]).tz("America/New_York").format("MM/DD/YYYY HH:mm")}
        </td>
    );
};

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const CashHistoryGrid = (props: Props) => {
    const [driverID, setDriverID] = useState(0);
    const [tabRefreshIteration, setTABRefreshIteration] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cardCashHistory, setCardCashHistory] = useState<EFSCardCashHistoryViewItem[]>();

    const Refresh = useCallback((): boolean => {
        return props.TABRefreshIteration != null && props.TABRefreshIteration !== tabRefreshIteration;
    }, [props.TABRefreshIteration, tabRefreshIteration]);

    useEffect(() => {
        if (props.IsGridInFocus == null || props.IsGridInFocus === false)
            return;
        if (!Refresh()) {
            if (driverID === props.DriverID)
                return;
        } else {
            setTABRefreshIteration(props.TABRefreshIteration);
        }

        setDriverID(props.DriverID);
        setLoading(true);
        fetchApi(`/api/Asset/GetEFSCardCashHistory`, { DriverID: props.DriverID }, 'POST')
            .then((response: EFSCardCashHistoryViewItem[]) => {
                setCardCashHistory(response);
                setLoading(false);
            }).catch(() => {
                alert('Error');
                setLoading(false);
            });
    }, [props.DriverID, props.IsGridInFocus, driverID, Refresh, props.TABRefreshIteration]);

    return <div className="container-fluid">
        {loading && <CenterDivPanel>
            <Loader type="converging-spinner" />
        </CenterDivPanel>}
        <Grid data={cardCashHistory} style={{ maxHeight: '600px' }}>
            <Column field="InfoId" title="ID" cell={InfoIdCell} />
            <Column field="Date" title="Date" cell={DateCell} />
            <Column field="Name" title="Name" />
            <Column field="RefNumber" title="Reference Number" />
            <Column field="Amount" title="Amount" headerCell={RightAlignHeaderCell} cell={AmountCell} className="text-right" />
        </Grid>
    </div>
}

export default CashHistoryGrid;

