import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { formatPhone } from '../../../utils/utils';

export default class DriverCellPhone1 extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;
    return (
      <td>
        <a href={`tel:${this.props.dataItem.DriverCellPhone1}`}>{formatPhone(this.props.dataItem.DriverCellPhone1)}</a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
