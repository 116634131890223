import { Popup } from '@progress/kendo-react-popup';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import { OpenShipment } from '.';
import OutsideAlerter from '../../components/OutsideAlerter';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { caretAltDownIcon, xIcon } from '@progress/kendo-svg-icons';

type Props = {
    dataItem: OpenShipment;
    reserve?: (shipment: OpenShipment) => void;
    release?: (shipment: OpenShipment) => void;
    decline?: (shipment: OpenShipment) => void;
}

const ActionPopup = (props:Props) => {

    const anchor = React.useRef<Button>(null);
    const history = useHistory();

    const [showPopup, setShowPopup] = React.useState<boolean>(false);

    const createQuote = () => {
        const data = {
            ShipmentID: props.dataItem.ShipmentID
        }
        fetchApi("/api/LoadBoard/AddQuote", data, 'POST')
            .then((response: { QuoteID: number }) => {
                if (props.reserve && !props.dataItem.ReservedByUserID) {
                    props.reserve(props.dataItem);
                }
                history.push(`/Quote/Index/${response.QuoteID}`);
            })
            .catch(() => {
                alert("Unable to create quote!")
            });
    }

    return (
        <React.Fragment>
            <ButtonGroup>
                {props.decline && <Button themeColor="error" icon="close" svgIcon={xIcon}
                    onClick={(e) => {
                        props.decline(props.dataItem);
                        e.preventDefault();
                    }}
                />}
                <Button
                    togglable
                    selected={showPopup}
                    icon="arrow-60-down"
                    svgIcon={caretAltDownIcon}
                    themeColor={props.dataItem.ReservedByUserID ? "success" : undefined}
                    onClick={(e) => {
                        setShowPopup(prevProps => !prevProps);
                        e.preventDefault();
                    }}
                    ref={anchor}
                />
            </ButtonGroup>
            <div>
                <Popup anchor={anchor.current?.element} show={showPopup} popupClass={'popup-content'}>
                    <OutsideAlerter toggle={() => setShowPopup(false)} style={{ display: 'contents' }}>
                        <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                createQuote();
                                setShowPopup(false);
                                e.preventDefault();
                            }}
                        >Create/View Quote</a>
                        {(props.reserve && props.decline) && (props.dataItem.ReservedByUserID ? <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                props.release(props.dataItem);
                                setShowPopup(false);
                                e.preventDefault();
                            }}
                        >Release</a> : <a
                            href="#"
                            style={{ color: '#007bff' }}
                            onClick={(e) => {
                                props.reserve(props.dataItem);
                                setShowPopup(false)
                                e.preventDefault();
                            }}
                        >Reserve</a>)}
                    </OutsideAlerter>
                </Popup>
            </div>
        </React.Fragment>
    );
}

export default ActionPopup;
