import { formatNumber } from '@progress/kendo-intl';
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody, CardSubtitle
} from "@progress/kendo-react-layout";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ViewModel } from "TypeGen/Order/Images/view-model";
import { useDebouncedCallback } from 'use-debounce';
import { fetchApi } from "../../../services/api";
import { JsonResponse } from "../../../TypeGen/json-response";
import { ChatResponseAnalyzedPage } from "../../../TypeGen/Order/Images/chat-response-analyzed-page";
import { ChatResponseAnalyzedPageDocType } from "../../../TypeGen/Order/Images/chat-response-analyzed-page-doc-type";
import { OrderImage } from "../../../TypeGen/Order/Images/order-image";

//type Props = {
//  orderId: number
//};
//const ImageViewer: React.FC<Props> = ({ orderId }) =>

type RouteComponentParams = {
  orderId: string;
};

const DisplayClassifiedLabel = (docType: ChatResponseAnalyzedPageDocType, Confidence: number) => {
  switch (docType) {
    case ChatResponseAnalyzedPageDocType.BillOfLading:
    case ChatResponseAnalyzedPageDocType.CustomsOrBorder:
    case ChatResponseAnalyzedPageDocType.PackList:
    case ChatResponseAnalyzedPageDocType.CartaDePorte:
    case ChatResponseAnalyzedPageDocType.CommercialInvoice:
    case ChatResponseAnalyzedPageDocType.DeliveryOrder:
    case ChatResponseAnalyzedPageDocType.PurchaseOrder:
      //if (Confidence >= 0.70)
      return (<h4><span className="badge badge-success">ACCEPTED</span></h4>);
    //return (<span className="badge badge-warning">REJECTED (LOW CONFIDENCE)</span>);    
    //return (<span className="badge badge-info">ACCEPTED/OTHER</span>);
    default:
      return (<h4><span className="badge badge-warning">REJECTED</span></h4>);
  }
}

const ImageViewer = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { orderId: orderIdParam } = useParams<RouteComponentParams>();
  const [details, setDetails] = useState<ViewModel>();
  const [visibleImages, setVisibleImages] = useState<OrderImage[]>([]);
  const [allImages, setAllImages] = useState<OrderImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [visibleImagesWithAIInfo, setVisibleImagesWithAIInfo] = useState<{ ImageID: number, AiPageInfo: ChatResponseAnalyzedPage }[]>([]);

  const sliceImage = useDebouncedCallback(useCallback(() => {
    setVisibleImages((prevImages) =>
      allImages.slice(0, prevImages.length + 1)
    );
  }, [allImages]), 300);

  const checkScrollPosition = useCallback(() => {
    //const scrollPosition = window.scrollY + window.innerHeight;
    //const pageHeight = document.body.offsetHeight;

    const container = containerRef.current; // Use containerRef.current
    if (!container) return;
    const scrollPosition = container.scrollTop + container.clientHeight; // Updated
    const pageHeight = container.scrollHeight; // Updated

    console.log(scrollPosition + " " + pageHeight);
    if (scrollPosition >= pageHeight - 5) {
      sliceImage();
    }
  }, [sliceImage]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', checkScrollPosition);
    return () => {
      container.removeEventListener('scroll', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  //useEffect(() => {
  //  window.addEventListener('scroll', checkScrollPosition);
  //  return () => {
  //    window.removeEventListener('scroll', checkScrollPosition);
  //  };
  //}, [checkScrollPosition]);

  useEffect(() => {
    fetchApi(`/api/Order/Images/${orderIdParam}`)
      .then((response: ViewModel) => {
        //response.Images = response.Images.map((item: OrderImage) => ({ ...item, inEdit: true, Edited: false }));
        setDetails(response);
        setAllImages(response.Images);
        setVisibleImages(response.Images.slice(0, 3)); // Initially display the first 3 images
      }).catch((e) => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      }).finally(() => setLoading(false));
  }, [orderIdParam]);

  useEffect(() => {
    const latestChange = visibleImages.find((img) => (img as OrderImage & { Edited: boolean }).Edited === true);
    if (latestChange) {
      (latestChange as OrderImage & { Edited: boolean }).Edited = false;
      fetchApi(`/api/Image/Update/${latestChange.ImageID}`, latestChange, 'PUT')
        .then((response: JsonResponse) => {
          if (response.Success) {
            fetchApi(`/api/Order/Image/${latestChange.ImageID}`)
              .then((response: any) => {

                setVisibleImages(prevImages =>
                  prevImages.map(img =>
                    img.ImageID === latestChange.ImageID ? { ...response.Image } : img
                  )
                );

              }).catch((e) => {
                if (!e?.status)
                  alert('Error: Please see admin');
                else if (e.status !== 404) {
                  alert(e?.detail);
                }
              }).finally(() => setLoading(false));
          } else {
            alert(response.ErrorMessage);
          }
        }).catch((e) => {
          if (!e?.status)
            alert('Error: Please see admin');
          else if (e.status !== 404) {
            alert(e?.detail);
          }
        }).finally(() => setLoading(false));
    }
  }, [visibleImages]);

  const analyzeWithAI = (imageId: number) => {
    setLoading(true);
    fetchApi(`/api/Order/AnalyzeImageWithAI/${imageId}`)
      .then((response: any) => {
        setVisibleImagesWithAIInfo(prevPageInfos => [...prevPageInfos, { ImageID: imageId, AiPageInfo: response.PageInfo }]);
      }).catch((e) => {
        if (!e?.status)
          alert('Error: Please see admin');
        else if (e.status !== 404) {
          alert(e?.detail);
        }
      }).finally(() => setLoading(false));
  };

  const getPageInfoTable = (pageInfo: ChatResponseAnalyzedPage): JSX.Element => {
    return (
      <table className="table table-bordered" style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>Type:</td>
            <td>{ChatResponseAnalyzedPageDocType[pageInfo.InferredDocumentType]}</td>
          </tr>
          {pageInfo.Pro && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Pro #</td>
              <td>{pageInfo.Pro}</td>
            </tr>
          )}
          {pageInfo.OrderNumber && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Order #</td>
              <td>{pageInfo.OrderNumber}</td>
            </tr>
          )}
          {pageInfo.Bol && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Bol #</td>
              <td>{pageInfo.Bol}</td>
            </tr>
          )}
          {pageInfo.ReferenceNumber && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Ref #</td>
              <td>{pageInfo.ReferenceNumber}</td>
            </tr>
          )}
          {pageInfo.Shipper && (
            <>
              <tr>
                <td style={{ fontWeight: "bold" }}>Shipper:</td>
                <td>{pageInfo.Shipper}</td>
              </tr>
              {pageInfo.ShipperAddress && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Shipper Address:</td>
                  <td>{pageInfo.ShipperAddress}</td>
                </tr>
              )}
            </>
          )}
          {pageInfo.Consignee && (
            <>
              <tr>
                <td style={{ fontWeight: "bold" }}>Consignee:</td>
                <td>{pageInfo.Consignee}</td>
              </tr>
              {pageInfo.ConsigneeAddress && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Consignee Address:</td>
                  <td>{pageInfo.ConsigneeAddress}</td>
                </tr>
              )}
            </>
          )}
          {pageInfo.Origin && (
            <>
              <tr>
                <td style={{ fontWeight: "bold" }}>Origin:</td>
                <td>{pageInfo.Origin}</td>
              </tr>
              {pageInfo.OriginAddress && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Origin Address:</td>
                  <td>{pageInfo.OriginAddress}</td>
                </tr>
              )}
            </>
          )}
          {pageInfo.Destination && (
            <>
              <tr>
                <td style={{ fontWeight: "bold" }}>Destination:</td>
                <td>{pageInfo.Destination}</td>
              </tr>
              {pageInfo.DestinationAddress && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Destination Address:</td>
                  <td>{pageInfo.DestinationAddress}</td>
                </tr>
              )}
            </>
          )}
          {pageInfo.BillTo && (
            <>
              <tr>
                <td style={{ fontWeight: "bold" }}>BillTo:</td>
                <td>{pageInfo.BillTo}</td>
              </tr>
              {pageInfo.BillToAddress && (
                <tr>
                  <td style={{ fontWeight: "bold" }}>Bill-To Address:</td>
                  <td>{pageInfo.BillToAddress}</td>
                </tr>
              )}
            </>
          )}
          {pageInfo.InvoiceNumber && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Invoice #</td>
              <td>{pageInfo.InvoiceNumber}</td>
            </tr>
          )}
          {pageInfo.InvoiceTotal > 0 && (
            <tr>
              <td style={{ fontWeight: "bold" }}>Invoice Total:</td>
              <td>{formatNumber(pageInfo.InvoiceTotal, "c")}</td>
            </tr>
          )}
          <tr>
            <td style={{ fontWeight: "bold" }}>Has Handwriting/Signature:</td>
            <td>{pageInfo.HasHandWrittenText ? "True" : "False"}</td>
          </tr>
          <tr>
            <td colSpan={2}>{DisplayClassifiedLabel(pageInfo.InferredDocumentType, 0)}</td>
          </tr>
        </tbody>
      </table>
    );
  };



  //img-fluid
  // className = "custom-image"
  //  style = {{
  //    overflowY: 'auto',
  //      overflowX: 'hidden',
  //        height: '95vh'
  //  }
  //}
  //, position: 'fixed'
  return (
    <div className="mt-3 ml-3" style={{ overflowX: 'hidden', height: '97vh', position: 'fixed' }} ref={containerRef} >
      {
        visibleImages.map((image, index) => (
          <div key={`image-${index}`}>
            <div className="row mb-3 flex-nowrap">
              <div className="col-8 text-right">
                <img src={`/Image/View/${image.ImageID}`} className="custom-image" />
              </div>
              <div className="col-4 pl-0" style={{ maxWidth: '275px' }}>
                <div className="row flex-nowrap">
                  <div className="col">
                    <Card>
                      <CardBody style={{ fontSize: '12px' }}>
                        <CardSubtitle>
                          <DropDownList
                            disabled={uploading}
                            iconClassName="arrow-60-down"
                            svgIcon={caretAltDownIcon}
                            data={details?.PaperworkTypes}
                            value={details?.PaperworkTypes.find(x => x.ID == image.TypeOfPW)}
                            textField="Name"
                            dataItemKey="ID"
                            style={{ fontSize: '12px' }}
                            onChange={(e) => {
                              setVisibleImages(prevImages =>
                                prevImages.map(img =>
                                  img.ImageID === image.ImageID ? { ...img, TypeOfPW: e.value.ID, Edited: true } : img
                                )
                              );
                              setAllImages(prevImages =>
                                prevImages.map(img =>
                                  img.ImageID === image.ImageID ? { ...img, TypeOfPW: e.value.ID } : img
                                )
                              );
                            }}
                          />
                        </CardSubtitle>
                        {/*<CardSubtitle>*/}
                        {/*</CardSubtitle>*/}
                        <label className="mr-1">Print w/Invoice:</label>
                        <Switch
                          size='small'
                          onChange={(e) => {
                            setVisibleImages(prevImages =>
                              prevImages.map(img =>
                                img.ImageID === image.ImageID ? { ...img, PrintFlag: e.value, Edited: true } : img
                              )
                            );
                            setAllImages(prevImages =>
                              prevImages.map(img =>
                                img.ImageID === image.ImageID ? { ...img, PrintFlag: e.value } : img
                              )
                            );
                          }}
                          checked={image.PrintFlag}
                        />
                        <p className="mb-2">{`Indexed On: ${Moment.utc(image.ImageDate).format("MM/DD/YYYY")}`}</p>
                        <p className="mb-2">{`Source: ${image.ImageSourceName}`}</p>
                        <p className="mb-2">{`Modified By: ${image.ModifiedByUserNumber}`}</p>
                        <p className="mb-2">{`Modified On: ${Moment.utc(image.ModifiedDateTime).format("MM/DD/YYYY")}`}</p>
                        <p className="mb-0">{image.ImageID}-{image.PageNum}</p>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row flex-nowrap mt-4">
                  <div className="col">
                    {visibleImagesWithAIInfo.find(x => x.ImageID == image.ImageID)

                      ? getPageInfoTable(visibleImagesWithAIInfo.find(x => x.ImageID == image.ImageID).AiPageInfo)
                      : <Button
                        themeColor="warning"
                        disabled={loading}
                        onClick={() => analyzeWithAI(image.ImageID)}
                      >
                        Analyze with AI
                      </Button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div >

  );
};

export default ImageViewer;