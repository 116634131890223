import * as React from 'react';
import { fetchApi } from '../../../services/api';
import LoadingPanel from '../../../components/LoadingPanel';
import { SelectedRowValues } from '../AssetPanel';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { AssetVehicleViewModel } from 'TypeGen/Assets/Vehicles/List/asset-vehicle-view-model';

interface Props {
    SelectedRowValues: SelectedRowValues,
    SelectedVehicles: AssetVehicleViewModel[] | null
    CloseDialog: () => void;
}

interface State {
    isLoading: boolean;
    DocumentType: number;
    sentToEntityButtonLabel: string;
}

export class VehicleRequestSignature extends React.Component<Props, State> {

    private documentTypes = [
        { value: 0, text: 'Select Document Type' },
        { value: 2, text: 'Vehicle Lease Agreement' },
        { value: 4, text: 'Cargo Van Addendum Agreement' },
      ]

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            DocumentType: 0,
            sentToEntityButtonLabel: ''
        }

        this.request = this.request.bind(this);
    }

    public componentWillMount() {
        if (this.props.SelectedVehicles.length > 1) {
            this.setState({
                sentToEntityButtonLabel: "All Selected (" + this.props.SelectedVehicles.length + ")",
            });
        } else {
            if (this.props.SelectedRowValues.DriverNumber.trim().length > 0 && this.props.SelectedRowValues.VehicleNumber.trim().length > 0) {
                this.setState({
                    sentToEntityButtonLabel: this.props.SelectedRowValues.DriverNumber + "/" + this.props.SelectedRowValues.VehicleNumber,
                });
            } else {
                let sentToEntityButtonLabel: string = "";
                if (this.props.SelectedRowValues.DriverNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.DriverNumber;
                if (this.props.SelectedRowValues.VehicleNumber.trim().length > 0)
                    sentToEntityButtonLabel += this.props.SelectedRowValues.VehicleNumber;
                this.setState({
                    sentToEntityButtonLabel: sentToEntityButtonLabel
                });
            }
        }
    }

    public render() {
        return (
            <Dialog title="Request Owner(s) Signature" onClose={this.props.CloseDialog} width={1024}>
                {this.state.isLoading && <LoadingPanel />}
                <DropDownList
                    style={{ width: '100%' }}
                    defaultValue={0}
                    value={this.documentTypes.find(x => x.value == this.state.DocumentType)}
                    data={this.documentTypes}
                    textField="text"
                    onChange={(e) => this.setState({ DocumentType: e.target.value.value })}
                />
                <DialogActionsBar layout="end">
                    <Button onClick={this.props.CloseDialog}>Cancel</Button>
                    <Button themeColor="primary" onClick={this.request} disabled={this.state.DocumentType === 0}>
                        Send to {this.state.sentToEntityButtonLabel}
                    </Button>
                </DialogActionsBar>
            </Dialog>
        );
    }

    private request() {

        if (this.state.isLoading) return;

        this.setState({
            isLoading: true
        });

        let vehicleIds: number[] = [];
        if (this.props.SelectedVehicles.length > 0) {
            this.props.SelectedVehicles.forEach(function (item) {
              vehicleIds.push(item.VehicleID);
            });
        }else {
            if (this.props.SelectedRowValues.VehicleID > 0)
            vehicleIds.push(this.props.SelectedRowValues.VehicleID);
        }

        const data = {
            VehicleIDs: vehicleIds,
            DocumentType: this.state.DocumentType,
        };

        fetchApi('/api/Asset/VehicleRequestSignature', data, 'POST')
            .then(() => {
                this.props.CloseDialog();
            }).catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    }
}
