import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import * as React from 'react';
import Checkbox from '../../components/Checkbox';
import { fetchApi } from '../../services/api';
import { openWindowPerc } from '../../services/openWindow';
import { ILink } from '../../types/link';
import { SvgIcon } from "@progress/kendo-react-common";
import { plusIcon } from '@progress/kendo-svg-icons';

interface Props {
  Link: ILink;
  CloseDialog: () => void;
  SetupNotifications?: (SetupLink: ILink) => void;
  OrderAuditLogs?: (orderAuditLink: ILink) => void;
}

interface State {
  customEmailAddress: string;
  email: ComposeEmailResponse;
  note: string;
}

interface ComposeEmailResponse {
  EmailAddresses: Array<{ Name: string, Email: string }>;
  Subject: string;
  Body: string;
  Links: ILink[];
  Commands: ILink[];
  VehicleNumber: string;
}

export class ComposeEmail extends React.Component<Props, State> {

  private selectedCheckboxes: Set<string>;

  constructor(props: Props) {
    super(props);

    this.state = {
      customEmailAddress: '',
      email: {
        EmailAddresses: [],
        Subject: 'Loading...',
        Body: 'Loading...',
        Links: [],
        Commands: [],
        VehicleNumber: ''
      },
      note: '',
    }

    this.selectedCheckboxes = new Set();

    this.sendEmail = this.sendEmail.bind(this);
    this.addEmail = this.addEmail.bind(this);
  }

  public componentWillMount() {
    fetchApi(this.props.Link.Link)
      .then((response: ComposeEmailResponse) => {
        this.setState({ email: response });
      })
  }

  public render() {
    return (
      <Dialog
        width={1024}
        height="90%"
        title={`${this.state.email.Subject} ${this.state.email.VehicleNumber ? ` - Vehicle ID: ${this.state.email.VehicleNumber}` : ""}`}
        onClose={this.props.CloseDialog}
      >
        <div className="row">
          <div className="col-sm-8">
            <div dangerouslySetInnerHTML={{ __html: this.state.email.Body }} />

            <TextArea style={{ width: '100%', height: '100px' }} rows={4} placeholder="Notes to include in status update email&hellip;" onChange={(value) => {
              this.setState({ note: value.value });
            }} />
          </div>
          <div className="col-sm-4">
            {this.state.email.Links.find((x: ILink) => x.Name === 'NewLoad') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                openWindowPerc((this.state.email.Links.find((x: ILink) => x.Name === 'NewLoad') as ILink).Link);
                e.preventDefault();
              }}
            >Order
            </a>}
            &nbsp;&nbsp;&nbsp;
            {this.state.email.Links.find((x: ILink) => x.Name === 'TravelOrder') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                openWindowPerc((this.state.email.Links.find((x: ILink) => x.Name === 'TravelOrder') as ILink).Link);
                e.preventDefault();
              }}
            >Trip
            </a>}
            &nbsp;&nbsp;&nbsp;
            {this.state.email.Links.find((x: ILink) => x.Name === 'UploadAnImage') && <a
              target="_blank"
              style={{ color: '#007bff' }}
              href={(this.state.email.Links.find((x: ILink) => x.Name === 'UploadAnImage') as ILink).Link}
            >Documents
            </a>}
            <br />
            {this.props.SetupNotifications && this.state.email.Links.find((x: ILink) => x.Name === 'OrderNotifications') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                this.props.SetupNotifications((this.state.email.Links.find((x: ILink) => x.Name === 'OrderNotifications') as ILink));
                e.preventDefault();
              }}
            > Setup Notifications
            </a>}
            <br />
            {this.props.OrderAuditLogs && this.state.email.Links.find((x: ILink) => x.Name === 'OrderAuditLogs') && <a
              href="#"
              style={{ color: '#007bff' }}
              onClick={(e) => {
                this.props.OrderAuditLogs((this.state.email.Links.find((x: ILink) => x.Name === 'OrderAuditLogs') as ILink));
                e.preventDefault();
              }}
            > Audit Logs
            </a>}
            <h4>Send To:</h4>
            {this.state.email.EmailAddresses.map((address, index) => {
              return (<div className="k-form-field" key={index}>
                <Checkbox id={"contact" + index} className="k-checkbox k-checkbox-md k-rounded-md" defaultValue={this.selectedCheckboxes.has(address.Email)} value={address.Email} handleCheckboxChange={this.toggleCheckbox} />
                <label className="k-checkbox-label" htmlFor={"contact" + index}>{address.Name}<br />{address.Email}</label>
              </div>)
            })}
            <div className="input-group mb-3" style={{ marginTop: '5px' }}>
              <input type="email" className="form-control" placeholder="Email Address" value={this.state.customEmailAddress} onChange={(value) => {
                this.setState({ customEmailAddress: value.target.value });
              }} />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button" onClick={this.addEmail}>
                  <SvgIcon icon={plusIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar layout="end">
          <Button onClick={this.props.CloseDialog}>Cancel</Button>
          <Button themeColor="primary" onClick={this.sendEmail}>Send</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  private sendEmail() {

    // TODO: Prevent double clicking..

    // Fix for IE
    var selectedEmails: string[] = [];
    this.selectedCheckboxes.forEach(x => selectedEmails.push(x))

    const command = this.state.email.Commands.find(x => x.Name === 'Send') as ILink;
    const dataItem = {
      EmailAddresses: selectedEmails,
      Note: this.state.note
    };

    // No emails provided
    if (dataItem.EmailAddresses.length === 0) {
      return;
    }

    fetchApi(command.Link, dataItem, command.Method).then(() => {
      this.props.CloseDialog();
    })
  }

  private addEmail() {
    if (!this.state.customEmailAddress || this.selectedCheckboxes.has(this.state.customEmailAddress)) {
      return;
    }

    this.toggleCheckbox(this.state.customEmailAddress);
    this.setState({
      email: {
        ...this.state.email,
        EmailAddresses: [...this.state.email.EmailAddresses, { Name: '', Email: this.state.customEmailAddress }]
      },
      customEmailAddress: '',
    })
  }

  private toggleCheckbox = (label: string) => {
    if (this.selectedCheckboxes.has(label)) {
      this.selectedCheckboxes.delete(label);
    } else {
      this.selectedCheckboxes.add(label);
    }
  }
}
