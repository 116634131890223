import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IDNameViewModel } from "TypeGen/id-name-view-model";

type Props = {
  orderNumbers: IDNameViewModel[];
} & GridCellProps;

const OrderCell = ({ orderNumbers, ...props }: Props) => {

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.ID
      });
    }
  }
  
  const { dataItem } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return <td>
      {dataItem.inEdit ? <DropDownList
        onChange={handleChange}
        value={orderNumbers.find(c => c.ID === dataValue)}
        data={orderNumbers}
        textField="Name"
      /> : orderNumbers.find(c => c.ID === dataValue)?.Name}
    </td>
};

export default OrderCell;