import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';
import CenterDivPanel from '../../../components/CenterDivPanel';
import { Loader } from "@progress/kendo-react-indicators";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import MoneyCodeHistoryGrid from './MoneyCodeHistoryGrid';
import DriverAutoComplete from '../../AssetDrivers/DriverAutoComplete';
import { Button } from '@progress/kendo-react-buttons';
import TotalCashRequestedNotification from './TotalCashRequestedNotification';

type Props = {
    DriverID?: number,
    DriverNumber: string,
    DriverName: string,
    CloseDialog: () => void;
}

type ContractViewItem = {
    ContractId: number,
    Description: string,
    Status: string
}

type MoneyCodeConfirmationViewItem = {
    Code: number,
    ID: number,
}

const IssueMoneyCodeDialog = (props: Props) => {
    const [dropdownContract, setDropdownContract] = useState<ContractViewItem>();
    const [contacts, setContracts] = useState<ContractViewItem[]>();
    const [amount, setAmount] = useState({ value: 0, error: '' });

    const [driverError, setDriverError] = useState('');
    const [driver, setDriver] = useState<number>(props.DriverID ?? null);

    const [reason, setReason] = useState({ value: '', error: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionNotification, setTransactionNotification] = useState({ Loaded: false, HasNotification: false });
    const [showGenericDialog, setShowGenericDialog] = useState(false);
    const [showMoneyCodeHistoryDialog, setShowMoneyCodeHistoryDialog] = useState(false);
    const [moneyCodeConfirmation, setMoneyCodeConfirmation] = useState<MoneyCodeConfirmationViewItem>();

    const getContracts = () => {
        setLoading(true);
        fetchApi(`/api/Asset/GetEFSContracts`)
            .then((response: ContractViewItem[]) => {
                const moneyCodeContractDefaultOption = 591559;

                response = response.filter(x => x.Status === 'ACTIVE').map(x => ({ ContractId: x.ContractId, Description: `${x.ContractId} - ${x.Description}`, Status: x.Status }));
                setContracts(response);
                setDropdownContract(response.find(x => x.ContractId === moneyCodeContractDefaultOption) ?? response[0]);
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to fetch contracts');
            });
    }

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _save = () => {

        const amountError = amount.value <= 25 ? 'Must contain a valid dollar amount greater than $25.' : '';
        const reasonError = !reason.value ? 'Reason for money code cannot be empty.' : '';

        if (amountError || reasonError) {
            setAmount({ ...amount, error: amountError });
            setReason({ ...reason, error: reasonError });
            return;
        }

        if (!driver) {
            setDriverError('Please select a driver.');
            return;
        }

        setLoading(true);

        const data = {
            DriverID: driver,
            ContractID: dropdownContract.ContractId,
            Amount: amount.value,
            //IssuedTo: issuedTo.value,
            Notes: reason.value
        }
        fetchApi(`/api/Asset/IssueMoneyCodeEFS`, data, 'POST')
            .then((response: MoneyCodeConfirmationViewItem) => {
                setMoneyCodeConfirmation(response);
                setLoading(false);
                setShowGenericDialog(true);
            }).catch(e => {
                setLoading(false);
                if (!e?.status) alert('Unable to issue money code');
            });
    }

    return <div className="container-fluid">
        <Dialog title={"Issue Money Code"} onClose={props.CloseDialog} width={400} height={transactionNotification.HasNotification ? 600 : 500}>
            {(loading || transactionNotification.Loaded === false) && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}
            <form className="k-form k-form-md p-0">
                <FieldWrapper>
                    <TotalCashRequestedNotification
                        DriverID={props.DriverID}
                        ShowCardNoteNotification={true}
                        Loaded={(hasNotification: boolean) => { setTransactionNotification({ Loaded: true, HasNotification: hasNotification }); }} />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Amount</Label>
                    <NumericTextBox
                        value={amount.value}
                        disabled={loading || transactionNotification.Loaded === false}
                        onChange={(e) => setAmount({ value: e.value || 0, error: '' })}
                        format="c2"
                        min={0}
                        step={25}
                    />
                    <Error>{amount.error}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Driver</Label>
                    <DriverAutoComplete
                        style={{ width: '200px' }}
                        selectedDriverID={driver}
                        onSelectedDriver={(e) => setDriver(e?.DriverID)}
                        clearButton={props.DriverID == null || props.DriverID === 0}
                        readonly={props.DriverID > 0}
                    />
                    <Error>{driverError}</Error>
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Contract</Label>
                    <ComboBox
                        style={{ width: "100%" }}
                        data={contacts}
                        dataItemKey="ContractId"
                        textField="Description"
                        disabled={loading || transactionNotification.Loaded === false}
                        value={dropdownContract}
                        clearButton={false}
                        onChange={(e) => { setDropdownContract(e.value); }} />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>Reason</Label>
                    <Input
                        maxLength={30}
                        disabled={loading || transactionNotification.Loaded === false}
                        value={reason.value}
                        onChange={(e) => setReason({ value: e.value as string || '', error: '' })}
                    />
                    <Error>{reason.error}</Error>
                </FieldWrapper>
                <FieldWrapper className="text-center mt-2">
                    <Button type="button" fillMode="flat" themeColor="primary" onClick={() => setShowMoneyCodeHistoryDialog(true)}>View Money Code History</Button>
                </FieldWrapper>
            </form>

            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Cancel</Button>
                <Button themeColor="primary" onClick={_save} disabled={loading || transactionNotification.Loaded === false}>Issue Money Code</Button>
            </DialogActionsBar>
        </Dialog>
        {showGenericDialog && <Dialog title={"Money Code Receipt"} onClose={props.CloseDialog} width={315} height={325}>
            <p style={{ margin: "15px", textAlign: "center", fontSize: "small" }}>
                {`Money Code: ${moneyCodeConfirmation.Code}`}
            </p>
            <p style={{ margin: "15px", textAlign: "center", fontSize: "smaller" }} className="font-italic">
                *If equipped, a fleet message with money code will be automatically sent to the driver.
            </p>
            <p style={{ margin: "15px", textAlign: "center", fontSize: "smaller" }} className="font-italic">
                Note: Money code is to be written on front of check.
            </p>
            <DialogActionsBar>
                <Button onClick={props.CloseDialog}>Close</Button>
            </DialogActionsBar>
        </Dialog>}
        {showMoneyCodeHistoryDialog && <Dialog title={"Money Code History"} onClose={() => setShowMoneyCodeHistoryDialog(false)}
            width={window.innerWidth / 1.05}
            height={window.innerHeight / 1.5}>
            <div>
                <MoneyCodeHistoryGrid />
            </div>
            <DialogActionsBar>
                <Button onClick={() => setShowMoneyCodeHistoryDialog(false)}>Close</Button>
            </DialogActionsBar>
        </Dialog>}
    </div>
}

export default IssueMoneyCodeDialog;

