import { GridCellProps } from "@progress/kendo-react-grid";
import { SvgIcon } from "@progress/kendo-react-common";
import * as React from 'react';
import { AssetCarrierViewModel } from "TypeGen/Assets/Carriers/List/asset-carrier-view-model";
import { CarrierTierValue } from "TypeGen/carrier-tier-value";
import { SelectedCarrierValue } from './CarrierGrid';
import { starIcon, starOutlineIcon } from "@progress/kendo-svg-icons";

export default function carrierTierCell(LoadCarrierReviewScreen: (SelectedCarrier: SelectedCarrierValue) => void) {
  return class ActionPopup extends React.Component<GridCellProps> {

    private getSelectedCarrierValue(carrier: AssetCarrierViewModel): SelectedCarrierValue{
      return {
        CarrierID: carrier.CarrierID,
        CarrierName: carrier.CarrierName,
        Phone1: carrier.Phone1,
      } as SelectedCarrierValue
    }

    render() {
      let dataItem = this.props.dataItem as AssetCarrierViewModel;
      return <td style={{ cursor: "pointer" }} onClick={() => LoadCarrierReviewScreen(this.getSelectedCarrierValue(dataItem))}>
          {this.props.dataItem.CarrierTier === CarrierTierValue.Platinum ? <SvgIcon icon={starIcon} svgStyle={{ fill: "url(#platinum-carrier-gradient)" }} />
              : this.props.dataItem.CarrierTier === CarrierTierValue.Gold ? <SvgIcon icon={starIcon} color="#ecb73d" />
              : this.props.dataItem.CarrierTier === CarrierTierValue.Silver ? <SvgIcon icon={starIcon} color="#9B9B9B" />
              : <SvgIcon icon={starOutlineIcon} color="#9B9B9B" />}
        </td>
    }
  }
}
