import * as React from 'react';
import { Title } from '../../../utils/title';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar, GridDataStateChangeEvent, GridFilterCell, GridColumnMenuFilter, GridColumnProps, GridExpandChangeEvent, GridCell } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import ColumnMenu from './ColumnMenu';
import LoadBoardDetailRow from '../DetailRow';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import * as LoadBoardHistoryState from '../../../store/LoadBoardHistory';
import MyCommandCell from '../MyCommandCell';
import YesNoCell from '../../../components/cells/YesNoCell';
import DateCell from '../../../components/cells/DateCell';
import { Button } from '@progress/kendo-react-buttons';
import { CustomFilterUIDropdown } from 'components/CustomFilterUIDropDown';
import { arrowRotateCwIcon, filterClearIcon } from '@progress/kendo-svg-icons';

const connector = connect(
  (state: ApplicationState) => state.loadBoardHistory,
  LoadBoardHistoryState.actionCreators
)

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type State = {}

class LoadBoardBidHistory extends React.Component<Props, State> {

  private export: React.RefObject<ExcelExport>;
  private BidTypeFilterCell: typeof GridFilterCell;
  private CommandCell: typeof React.Component;

  constructor(props: Props) {
    super(props);

    this.CommandCell = MyCommandCell(null, null, null);

    this.export = React.createRef();

    this.reset = this.reset.bind(this);
    this.dataStateChange = this.dataStateChange.bind(this);
    this.expandChange = this.expandChange.bind(this);
  }

  private columnProps(field: string): Partial<GridColumnProps> {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.props.dataState) ? 'active' : ''
    };
  }

  private isColumnActive(field: string, dataState: any) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  public componentDidMount() {
    this.props.refreshHistory();
  }

  public render() {
    return (
      <React.Fragment>
        <Title string="Load Board History" />
        <ExcelExport ref={this.export}>
          <Grid sortable={{ allowUnsort: true }}
              pageable={{ pageSizes: [20, 50, 100, 500] }}
              scrollable={'none'}
              total={this.props.total}
              data={this.props.data}
              onDataStateChange={this.dataStateChange}
              detail={LoadBoardDetailRow}
              expandField="expanded"
              onExpandChange={this.expandChange}
              {...this.props.dataState}
            >
            <GridToolbar>
            <Button
              title="Clear Filters and Sort"
              icon="filter-clear"
              svgIcon={filterClearIcon}
              onClick={this.reset}
            />
            <Button
              title="Refresh"
              icon="refresh"
              svgIcon={arrowRotateCwIcon}
              onClick={this.props.refreshHistory}
            />
            <Button
              togglable
              selected={this.hasFilter('Reference', 'startswith', 'AA-')}
              onClick={() => this.filter('Reference', 'startswith', 'AA-')}
            >AA
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([
                { field: 'Reference', operator: 'startswith', value: 'XPO-'},
                { field: "IsSpecialized", operator: "eq", value: false }
              ])}
              onClick={() => this.filterArray([
                { field: 'Reference', operator: 'startswith', value: 'XPO-'},
                { field: "IsSpecialized", operator: "eq", value: false }
              ])}
            >XPO
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([
                { field: 'Reference', operator: 'startswith', value: 'XPO-'},
                { field: "IsSpecialized", operator: "eq", value: true }
              ])}
              onClick={() => this.filterArray([
                { field: 'Reference', operator: 'startswith', value: 'XPO-'},
                { field: "IsSpecialized", operator: "eq", value: true }
              ])}
            >XPO-Specialized
            </Button>
            <Button
              togglable
              selected={this.hasFilter('Reference', 'startswith', 'SYL-')}
              onClick={() => this.filter('Reference', 'startswith', 'SYL-')}
            >Sylectus
            </Button>
            <Button
              togglable
              selected={this.hasFilter('Reference', 'startswith', 'ECCO-')}
              onClick={() => this.filter('Reference', 'startswith', 'ECCO-')}
            >ECCO
            </Button>
            <Button
              togglable
              selected={this.hasFilter('Reference', 'startswith', 'RYDR-')}
              onClick={() => this.filter('Reference', 'startswith', 'RYDR-')}
            >Ryder
            </Button>
            <Button
              togglable
              selected={this.hasFilter('Reference', 'startswith', 'CYT-')}
              onClick={() => this.filter('Reference', 'startswith', 'CYT-')}
            >Coyote
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'CAT-'}])}
              onClick={() => this.filterArray([{ field: 'Reference', operator: 'startswith', value: 'CAT-'}])}
            >Caterpillar
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'SHAW-'}])}
              onClick={() => this.filterArray([{ field: 'Reference', operator: 'startswith', value: 'SHAW-'}])}
            >Shaw
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'DAKK-' }])}
              onClick={() => this.filterArray([{ field: 'Reference', operator: 'startswith', value: 'DAKK-' }])}
            >Dakkota
            </Button>
            <Button
              togglable
              selected={this.hasFilterArray([{ field: 'Reference', operator: 'startswith', value: 'PEPSI-'}])}
              onClick={() => this.filterArray([{ field: 'Reference', operator: 'startswith', value: 'PEPSI-'}])}
            >Pepsi
            </Button>
            <Button onClick={() => this.export.current.save(this.props.data)}>Excel</Button>
            </GridToolbar>
            <Column {...this.columnProps("Reference")} title="Reference" groupable={false} />
            <Column {...this.columnProps("Status")} title="Status" columnMenu={(props) =>
                <GridColumnMenuFilter
                    {...props}
                    expanded
                    filterUI={(props) =>
                        <CustomFilterUIDropdown
                          {...props}
                          data={['Available', 'Removed', 'Expired']}
                          operator='eq'
                          defaultItem={' - Select - '}
                        />
                    }
                />
              }
            />
            <Column {...this.columnProps("Customer")} title="Customer" />
            <Column {...this.columnProps("PickupLocation")} title="Pickup" />
            <Column {...this.columnProps("PickupTime")}  title="Pickup Time" filter="date" cell={DateCell} groupable={false} />
            <Column {...this.columnProps("DeliveryLocation")} title="Delivery" />
            <Column {...this.columnProps("DeliveryTime")} title="Delivery Time" filter="date" cell={DateCell} groupable={false} />
            <Column {...this.columnProps("Pieces")} filter="numeric" />
            <Column {...this.columnProps("Weight")} filter="numeric" format="{0:n}" />
            <Column {...this.columnProps("Dimensions")} title="LxWxH" />
            <Column {...this.columnProps("VehicleType")} title="Vehicle Type" />
            <Column {...this.columnProps("BidType")} title="Bid Type" columnMenu={(props) =>
                <GridColumnMenuFilter
                    {...props}
                    expanded
                    filterUI={(props) =>
                        <CustomFilterUIDropdown
                          {...props}
                          data={['Manual Decline', 'Manual Bid', 'Automatic Decline', 'Automatic Bid', 'Audit Decline', 'Audit Bid']}
                          operator='eq'
                          defaultItem={' - Select - '}
                        />
                    }
                />
              }
            />
            <Column {...this.columnProps("Amount")} filter="numeric" format="{0:c}" groupable={false} />
            <Column {...this.columnProps("Note")} width="150px" groupable={false} />
            <Column {...this.columnProps("UserName")} title="User" />
            <Column {...this.columnProps("BidDateTime")} title="Bid Time" filter="date" cell={DateCell} groupable={false} />
            <Column {...this.columnProps("Successful")} title="Success" filter="boolean" cell={YesNoCell} />
            <Column cell={this.CommandCell} />
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }

  private hasFilter(field: string, operator: string, value: any): boolean {
    return (this.props.dataState.filter.filters as Array<FilterDescriptor>)
      .filter(x => x.field === field && x.operator === operator && x.value === value)
      .length > 0;
  }

  private hasFilterArray(filters: Array<FilterDescriptor>): boolean {
    return filters.every(f => (this.props.dataState.filter.filters as Array<FilterDescriptor>)
      .filter(x => x.field === f.field && x.operator === f.operator && x.value === f.value)
      .length > 0);
  }

  private filter(field: string, operator: string, value: any) {
    if (this.hasFilter(field, operator, value)) {
      this.dataStateChange({ dataState: {
        skip: 0,
        take: this.props.dataState.take,
        sort: this.props.dataState.sort,
        filter: { logic: 'and', filters: [{field: "Successful", operator: "eq", value: true}] }
      } } as GridDataStateChangeEvent);
    } else {
      this.dataStateChange({ dataState: {
        skip: 0,
        take: this.props.dataState.take,
        sort: this.props.dataState.sort,
        filter: { logic: 'and', filters: [{field, operator, value}] }
      } } as GridDataStateChangeEvent);
    }
  }

  private filterArray(filters: Array<FilterDescriptor>) {
    if (this.hasFilterArray(filters)) {
      this.dataStateChange({ dataState: {
        skip: 0,
        take: this.props.dataState.take,
        sort: this.props.dataState.sort,
        filter: { logic: 'and', filters: [{field: "Successful", operator: "eq", value: true}] }
      } } as GridDataStateChangeEvent);
    } else {
      this.dataStateChange({ dataState: {
        skip: 0,
        take: this.props.dataState.take,
        sort: this.props.dataState.sort,
        filter: { logic: 'and', filters }
      } } as GridDataStateChangeEvent);
    }
  }

  private reset() {
    this.dataStateChange({ dataState: {
      skip: 0,
      take: 20,
      sort: [{field: "BidDateTime", dir: "desc"}],
      filter: { logic: 'and', filters: [{field: "Successful", operator: "eq", value: true}] }
    } } as GridDataStateChangeEvent);
  }

  private dataStateChange(changeEvent: GridDataStateChangeEvent) {
    this.props.requestHistory(changeEvent.dataState);
  }

  private expandChange(e: GridExpandChangeEvent) {
    this.props.expandChange(e.dataItem);
  }
}

export default connector(LoadBoardBidHistory);
