import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { openWindow } from '../../services/openWindow';
import { ILink } from '../../types/link';

export default class CarrierIDCell extends React.Component<GridCellProps> {
  render() {
    if (!this.props.field) return null;

    const data = this.props.dataItem[this.props.field];

    return (
      <td>
        <a
          href="#"
          style={{ color: '#007bff' }}
          title="Carrier Profile"
          onClick={(e) => {
            openWindow(this.props.dataItem.Links.filter((x: ILink) => x.Name === 'CarrierProfile')[0].Link, 1000);
            e.preventDefault();
          }}
        >{data || 'N/A'}
        </a>
      </td>
    );
  }

  public shouldComponentUpdate(nextProps: GridCellProps) {
    return nextProps.dataItem.Hash !== this.props.dataItem.Hash;
  }
}
