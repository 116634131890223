import { filterBy } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchApi } from '../../../services/api';

interface Props {
  invoiceGatewayCommunicationPartners?: InvoiceGatewayCommunicationPartner[];
  selectedId: (Id?: string) => void;
  preSelectedId?: string;

  returnedInvoiceGatewayCommunicationPartners?: (invoiceGatewayCommunicationPartners: InvoiceGatewayCommunicationPartner[]) => void;
}

export interface InvoiceGatewayCommunicationPartner {
  CommunicationID: string;
  TradingPartnerID: number;
  APIID: number;
  CommunicationPartner: string;
}

const InvoiceGatewayCommunicationPartnerComboBox = (props: Props) => {
  const [invoiceGatewayCommunicationPartners, setInvoiceGatewayCommunicationPartners] = useState<InvoiceGatewayCommunicationPartner[] | undefined>(props.invoiceGatewayCommunicationPartners);
  const [invoiceGatewayCommunicationPartnersSubset, setInvoiceGatewayCommunicationPartnersSubset] = useState<InvoiceGatewayCommunicationPartner[] | undefined>(props.invoiceGatewayCommunicationPartners);
  const [invoiceGatewayCommunicationPartner, setInvoiceGatewayCommunicationPartner] = useState<InvoiceGatewayCommunicationPartner | null>(null);

  useEffect(() => {
    if (!props.invoiceGatewayCommunicationPartners) {
      fetchApi(`/api/Billing/InvoiceGatewayCommunicationPartners`)
        .then((response: any) => {
          setInvoiceGatewayCommunicationPartners(response.InvoiceGatewayCommunicationPartners);
          setInvoiceGatewayCommunicationPartnersSubset(response.InvoiceGatewayCommunicationPartners);
        })
        .catch(e => {
          if (!e?.status) {
            alert('Error: Please see admin');
          } else if (e.status !== 404) {
            alert(e?.detail);
          }
        });
    }
  }, [props.invoiceGatewayCommunicationPartners]);

  useEffect(() => {
    if (invoiceGatewayCommunicationPartners && props.preSelectedId) {
      setInvoiceGatewayCommunicationPartner(invoiceGatewayCommunicationPartners.find(x => x.CommunicationID === props.preSelectedId));
    } else {
      setInvoiceGatewayCommunicationPartner(null);
    }
    if (invoiceGatewayCommunicationPartners && props.returnedInvoiceGatewayCommunicationPartners)
      props.returnedInvoiceGatewayCommunicationPartners(invoiceGatewayCommunicationPartners);
  }, [props, invoiceGatewayCommunicationPartners, props.preSelectedId]);

  const filterData = (filter: any) => {
    const data = invoiceGatewayCommunicationPartners?.slice() || [];
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    //event.filter.field = "DataFilterByContents";    

    const newData =
      event.filter.value.length > 1
        ? filterData(event.filter)
        : invoiceGatewayCommunicationPartners?.slice() || [];

    setInvoiceGatewayCommunicationPartnersSubset(newData);
  };

  return (
    <ComboBox
      style={{ width: "275px" }}
      popupSettings={{ height: 300 }}
      placeholder="Communication Partner"
      data={invoiceGatewayCommunicationPartnersSubset || []}
      value={invoiceGatewayCommunicationPartner}
      dataItemKey="CommunicationID"
      textField="CommunicationPartner"
      filterable={true}
      onFilterChange={filterChange}
      onChange={(e) => {
        setInvoiceGatewayCommunicationPartner(e.value);
        props.selectedId(e.value?.CommunicationID);
      }}
    />
  );
};

export default InvoiceGatewayCommunicationPartnerComboBox;