import { StopType } from "TypeGen/stop-type";
import { QuoteStopType } from "TypeGen/Quote/quote-stop-type";

export const getStopType = (stopType: StopType) => {
  switch(stopType)
  {
    case StopType.Pickup:
      return "Pickup";
    case StopType.Delivery:
      return "Delivery";
    default:
      const exhaustiveCheck: never = stopType;
  }
}

export const getQuoteStopType = (stopType: QuoteStopType) => {
  switch(stopType)
  {
    case QuoteStopType.Pickup:
      return "Pickup";
    case QuoteStopType.Delivery:
      return "Delivery";
    default:
      const exhaustiveCheck: never = stopType;
  }
}
