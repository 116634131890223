import { formatNumber } from '@progress/kendo-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps, GridItemChangeEvent, GridRowDoubleClickEvent, GridToolbar } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import CenterDivPanel from '../../components/CenterDivPanel';
import useAlert from '../../components/useAlert';
import useConfirm from '../../components/useConfirm';
import { fetchApi } from '../../services/api';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
    RateFuelMasterID: number
};

type FuelPumpPointRateBreakViewModel = {
    RateDescriptionID: number;
    RateDescriptionName: string;
    RateLineItemID: number;
    ModifiedByFullName: string;
    ModifiedDateTime: Date;

    MinPumpRate: number;
    MaxPumpRate: number;
    Surcharge: number;

    inEdit?: boolean | string;
    isNew?: boolean;
    Delete?: boolean;
};

interface EditCommandCellProps extends GridCellProps {
    edit: (item: FuelPumpPointRateBreakViewModel) => void;
    remove: (item: FuelPumpPointRateBreakViewModel) => void;
    add: (item: FuelPumpPointRateBreakViewModel) => void;
    discard: () => void;
    update: (item: FuelPumpPointRateBreakViewModel) => void;
    cancel: (item: FuelPumpPointRateBreakViewModel) => void;
    editField: string;
}

const RightAlignHeaderCell = (props: GridHeaderCellProps) => {
    return (<a className="k-link" onClick={props.onClick} style={{ textAlign: "right" }}>
        <span>{props.title}</span>
        {props.children}
    </a>);
};

const RateCell = (props: GridCellProps) => {
    const handleChange = (e: NumericTextBoxChangeEvent) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
            });
        }
    };

    let dataItem: FuelPumpPointRateBreakViewModel = props.dataItem;
    var value = props.dataItem[props.field] as number;

    return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {dataItem.inEdit ? <NumericTextBox format="n3" step={0.001} value={value} onChange={handleChange} min={0} inputStyle={{ textAlign: "right" }} /> :
                <span>{formatNumber(value, "n3")}</span>}
        </td>
    );
};

const LastModifiedByCell = (props: GridCellProps) => {
    if (!props.field)
      return null;

    let dataItem: FuelPumpPointRateBreakViewModel = props.dataItem;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        <span>{Moment.utc(dataItem.ModifiedDateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm")} - {dataItem.ModifiedByFullName}</span>
      </td>
    );
};

const FuelPumpPointRateBreak = (props: Props) => {
    const { alert } = useAlert();
    const { ConfirmationDialog, confirm } = useConfirm({});
    const [loading, setLoading] = useState(true);
    const editField: string = "inEdit";
    const [fuelPumpPointRateBreaks, setFuelPumpPointRateBreaks] = useState<FuelPumpPointRateBreakViewModel[]>([]);
    const [CSIDataCopy, setCSIDataCopy] = useState<FuelPumpPointRateBreakViewModel[]>([]);

    const EditCommandCell = (props: EditCommandCellProps) => {
        const inEdit = props.dataItem[props.editField];
        let dataItem: FuelPumpPointRateBreakViewModel = props.dataItem;
        const isNewItem = dataItem.isNew;

        const disabled = dataItem.MaxPumpRate == null || dataItem.MinPumpRate == null || dataItem.Surcharge == null;

        return inEdit ? (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={disabled}
                    onClick={() =>
                        isNewItem ? props.add(dataItem) : props.update(dataItem)
                    }
                >
                    {isNewItem ? "Save" : "Update"}
                </Button>
                <Button themeColor={"secondary"}
                    onClick={() =>
                        isNewItem ? props.discard() : props.cancel(dataItem)
                    }
                >
                    {isNewItem ? "Discard" : "Cancel"}
                </Button>
            </td>
        ) : (
            <td className="k-command-cell">
                <Button themeColor={"primary"}
                    disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                    onClick={() => props.edit(dataItem)}>Edit</Button>
                <Button themeColor={"secondary"}
                    disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                    onClick={async () => {
                        if (!await confirm(`Remove?`))
                            return;
                        props.remove(dataItem);
                    }}>Delete</Button>
            </td>
        );
    };

    const MyEditCommandCell = (props: GridCellProps) => (
        <EditCommandCell {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const enterEdit = (dataItem: FuelPumpPointRateBreakViewModel) => {
        if (fuelPumpPointRateBreaks.some(x => x.inEdit === true))
            return;

        setFuelPumpPointRateBreaks(
            fuelPumpPointRateBreaks.map((item) =>
                item.RateLineItemID === dataItem.RateLineItemID ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem: FuelPumpPointRateBreakViewModel) => {
        updateAccessorialRateStructure({ ...dataItem, Delete: true });
    };

    const add = (dataItem: FuelPumpPointRateBreakViewModel) => {
        updateAccessorialRateStructure(dataItem);
    };

    const update = (dataItem: FuelPumpPointRateBreakViewModel) => {
        updateAccessorialRateStructure(dataItem);
    };

    const cancel = (dataItem: FuelPumpPointRateBreakViewModel) => {
        const originalItem = CSIDataCopy.find(
            (p) => p.RateLineItemID === dataItem.RateLineItemID
        );
        const newData = fuelPumpPointRateBreaks.map((item) =>
            item.RateLineItemID === originalItem.RateLineItemID ? originalItem : item
        );

        setFuelPumpPointRateBreaks(newData);
    };

    const generateId = () =>
        fuelPumpPointRateBreaks.reduce((acc, current) => Math.max(acc, current.RateLineItemID), 0) + 1;

    const addNew = () => {
        const newDataItem = {
            inEdit: true, RateLineItemID: generateId(), isNew: true,
            MaxPumpRate: 0, MinPumpRate: 0, RateDescriptionID: 0, RateDescriptionName: '', Surcharge: 0
        } as FuelPumpPointRateBreakViewModel;
        setFuelPumpPointRateBreaks([newDataItem, ...fuelPumpPointRateBreaks]);
    };

    const discard = () => {
        const newData = [...fuelPumpPointRateBreaks];
        newData.splice(0, 1);
        setFuelPumpPointRateBreaks(newData);
    };

    const refresh = useCallback(() => {
        setLoading(true);

        fetchApi(`/api/Quote/GetFuelPumpPointRateBreaks/${props.RateFuelMasterID}`)
            .then((response: any) => {
                setFuelPumpPointRateBreaks(response.FuelPumpPointRateBreaks);
                setCSIDataCopy(response.FuelPumpPointRateBreaks)

                setLoading(false);
            })
            .catch(async e => {
                setLoading(false);
                // If not problem details
                if (!e?.status) await alert('An error occurred while saving.');
            });
    }, [props.RateFuelMasterID, alert]);

    const updateAccessorialRateStructure = (dataItem: FuelPumpPointRateBreakViewModel) => {
        setLoading(true);

        const data = { ...dataItem }
        fetchApi(`api/Quote/FuelPumpPointRateBreak/${props.RateFuelMasterID}`, data, 'POST')
            .then(async (response: JsonResponse) => {
                if (response.Success === false) {
                    setLoading(false);
                    await alert(`Error: ${response.ErrorMessage}`);
                }
                else
                    refresh();
            })
            .catch(async e => {
                if (!e?.status)
                    await alert('Error: Please see admin');
                else if (e.status !== 404) {
                    await alert(e?.detail);
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        refresh();
    }, [props.RateFuelMasterID, refresh]);

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = fuelPumpPointRateBreaks.map((item) =>
            item.RateLineItemID === event.dataItem.RateLineItemID
                ? { ...item, [field]: event.value }
                : item
        );

        setFuelPumpPointRateBreaks(newData);
    }

    const dataView = () => {
        return (<div className="container-fluid mt-2" style={{ position: "relative" }}>
            {loading && <CenterDivPanel>
                <Loader type="converging-spinner" />
            </CenterDivPanel>}

            <h4 className="text-left">Fuel Break Points</h4>
            <Grid
                style={{
                    height: `${window.innerHeight * .80}px`,
                }}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => enterEdit(e.dataItem)}
                data={fuelPumpPointRateBreaks}
                onItemChange={itemChange}
            >
                <GridToolbar>
                    <Button themeColor={"primary"}
                        disabled={fuelPumpPointRateBreaks.some(x => x.inEdit === true)}
                        title="Add new"
                        onClick={addNew}
                    >
                        Add new
                    </Button>
                </GridToolbar>
                <Column field="RateDescriptionName" title="Rate Desc." />
                <Column field="MinPumpRate" title="Min. Pump Rate" cell={RateCell} headerCell={RightAlignHeaderCell} />
                <Column field="MaxPumpRate" title="Max. Pump Rate" cell={RateCell} headerCell={RightAlignHeaderCell} />
                <Column field="Surcharge" title="Surcharge" cell={RateCell} headerCell={RightAlignHeaderCell} />
                <Column field="ModifiedByFullName" title="Modified By" cell={LastModifiedByCell} width={250} />
                <Column cell={MyEditCommandCell} width={160} />
            </Grid>
            <ConfirmationDialog />
        </div>);
    };

    return dataView();
}

export default FuelPumpPointRateBreak;
