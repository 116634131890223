import { formatNumber } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn as ExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn as Column, GridCustomFooterCellProps, GridToolbar } from "@progress/kendo-react-grid";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { GridLoadingPanel } from "components/GridLoadingPanel";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { fetchApi } from "services/api";

type ReportResponse = {
  Records: OwnerInfo[];
}

type OwnerInfo = {
  Year: number;
  Code: string;
  Name: string;
  CompanyName: string;
  AddressLine1: string;
  City: string;
  State: string;
  Zip: string;
  FederalTaxpayerID: string;
  SecurityNumber: string;
  VendorID: string;
  PayTotal: number;
  DeductionTotal: number;
  NetPay: number;
}

const Owner1099Report = () => {
  const _excelExport = useRef<ExcelExport | null>(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OwnerInfo[]>([]);
  const [filterByYear, setFilterByYear] = useState<string>((moment().year() - 1).toString());

  useEffect(() => {
    if (!filterByYear || filterByYear.indexOf('_') !== -1) return;
    setLoading(true);
    setData([]);
    const payload = {
      Year: filterByYear
    };
    fetchApi('/api/Settlements/Owner1099Report', payload, 'POST')
      .then((response: ReportResponse) => {
        setData(response.Records);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterByYear]);

  const excelExport = () => {
    if (_excelExport.current) _excelExport.current.save(data);
  };

  const SumFooterCell = (props: GridCustomFooterCellProps) => {
    switch(props.field)
    {
      case "PayTotal":
        return <td colSpan={props.colSpan} style={props.style}>
          {formatNumber(data.reduce((a: number, b: OwnerInfo) => a + b.PayTotal, 0), 'c')}
        </td>;
      case "DeductionTotal":
        return <td colSpan={props.colSpan} style={props.style}>
          {formatNumber(data.reduce((a: number, b: OwnerInfo) => a + b.DeductionTotal, 0), 'c')}
        </td>;
      case "NetPay":
        return <td colSpan={props.colSpan} style={props.style}>
          {formatNumber(data.reduce((a: number, b: OwnerInfo) => a + b.NetPay, 0), 'c')}
        </td>;
    }
  }

  return <>
    <ExcelExport ref={_excelExport} data={data} fileName={`${filterByYear} - Owner 1099 Report`}>
      <ExportColumn field="Year" width={35} />
      <ExportColumn field="Code" width={100} />
      <ExportColumn field="Name" />
      <ExportColumn field="CompanyName" title="Company Name" />
      <ExportColumn field="AddressLine1" title="Address" />
      <ExportColumn field="City" title="City" />
      <ExportColumn field="State" title="State" />
      <ExportColumn field="Zip" title="Zip" />
      <ExportColumn field="FederalTaxpayerID" title="Federal Taxpayer ID" />
      <ExportColumn field="SecurityNumber" title="SSN" />
      <ExportColumn field="VendorID" title="Vendor ID" width={110} />
      <ExportColumn field="PayTotal" title="Pay Total" cellOptions={{ format: '$#,##0.00' }} />
      <ExportColumn field="DeductionTotal" title="Deduction Total" cellOptions={{ format: '$#,##0.00' }} />
      <ExportColumn field="NetPay" title="Net Pay" cellOptions={{ format: '$#,##0.00' }} />
    </ExcelExport>
    {loading && <GridLoadingPanel />}
    <Grid
      data={data}
      total={data.length}
      sortable={false}
      scrollable="none"
    >
      <GridToolbar>
        <MaskedTextBox
          mask="0000"
          value={filterByYear}
          onChange={(e) => {
            setFilterByYear(e.value);
          }}
        />
        <Button svgIcon={fileExcelIcon} onClick={excelExport}>Excel</Button>
      </GridToolbar>
      <Column field="Code" />
      <Column field="Name" />
      <Column field="CompanyName" title="Company Name" />
      <Column field="AddressLine1" title="Address" />
      <Column field="City" />
      <Column field="State" />
      <Column field="Zip" />
      <Column field="FederalTaxpayerID" title="Federal Taxpayer ID" />
      <Column field="SecurityNumber" title="SSN" />
      <Column field="VendorID" title="Vendor ID" />
      <Column field="PayTotal" title="Pay Total" format="{0:c}" footerCell={SumFooterCell} />
      <Column field="DeductionTotal" title="Deduction Total" format="{0:c}" footerCell={SumFooterCell} />
      <Column field="NetPay" title="Net Pay" format="{0:c}" footerCell={SumFooterCell} />
    </Grid>
  </>;
}

export default Owner1099Report;