import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';
import { NumericTextBox, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {
  Amount?: number;
  CloseDialog: () => void;
  Refresh: () => void;
  QuoteID: number;
  ThreadID: string;
  SelectedCarrierOfferID?: number;
}

type State = {
  Message: string;
  VehicleTypeName: string;
  BidAmount: number;
  Sending: boolean;
}

export class FrontReply extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      Message: '',
      VehicleTypeName: '',
      BidAmount: 0,
      Sending: false,
    }

    this.mark = this.mark.bind(this);
    this.reply = this.reply.bind(this);
  }

  public componentDidMount() {
    const payload = {
      QuoteID: this.props.QuoteID,
      SelectedCarrierOfferID: this.props.SelectedCarrierOfferID
    }
    fetchApi(`/api/Quote/FrontReplyMessage`, payload, 'POST')
      .then((response: { VehicleTypeName: string, Total: number, Message: string }) => {

        // Use provided amount if available
        if (this.props.Amount && this.props.Amount > 0) {
          response.Total = this.props.Amount;
        }
        this.setState({ VehicleTypeName: response.VehicleTypeName, BidAmount: response.Total, Message: response.Message });
      })
  }

  public render() {
    return (
      <Dialog title="Reply To Customer" onClose={this.props.CloseDialog} className='dialog-w525'>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">We can cover this with a {this.state.VehicleTypeName} unit for</span>
          </div>
          <NumericTextBox
            className="form-control"
            value={this.state.BidAmount}
            format='c'
            onChange={(e) => this.setState({ BidAmount: e.value })}
          />
            <div className="input-group-append">
            <span className="input-group-text">all in</span>
          </div>
        </div>
        <br />
        <TextArea
          autoFocus
          rows={3}
          placeholder='Additional message to the customer'
          value={this.state.Message}
          onChange={(e) => this.setState({ Message: e.target.value })}
        />
        <DialogActionsBar>
          <Button onClick={this.mark} disabled={this.state.Sending}>Mark Customer Quoted</Button>
          <Button themeColor="primary" onClick={this.reply} disabled={this.state.Sending}>Send Front Reply</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  private mark() {
    this.setState({ Sending: true });
    const data = {
      QuoteID: this.props.QuoteID,
      BidAmount: this.state.BidAmount,
    };
    fetchApi('/api/Quote/CustomerQuoted', data, 'POST')
      .then((response: JsonResponse) => {
        if (response.Success) {
          this.props.CloseDialog();
        }
        else {
          this.setState({ Sending: false });
          alert(response.ErrorMessage);
        }
        this.props.Refresh();
      })
      .catch(() => {
        this.setState({ Sending: false })
        alert('Unable to mark customer quoted!');
      });
  }

  private reply() {
    if (this.props.ThreadID.startsWith("cnv_") === false && this.props.ThreadID.startsWith("msg_") === false)
    {
      alert("Please save quote with a valid Front ID first.");
      return;
    }
    this.setState({ Sending: true });
    const data = {
      QuoteID: this.props.QuoteID,
      BidAmount: this.state.BidAmount,
      Message: `We can cover this with a ${this.state.VehicleTypeName} unit for ${formatNumber(this.state.BidAmount, 'c')} all in\n${this.state.Message}`,
    };
    fetchApi('/api/Quote/ReplyWithRate', data, 'POST')
      .then(() => {
        this.props.Refresh();
      })
      .catch(() => {
        this.setState({ Sending: false });
        alert('Unable to send reply!');
      });
  }
}
