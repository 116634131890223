import { State as GridState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteCloseEvent, AutoCompleteProps } from '@progress/kendo-react-dropdowns';
import Moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'ts-debounce';
import { fetchApi } from '../../services/api';
import { ILink } from '../../types/link';

export type CustomerViewModel = {
    CustomerID: number;
    CustomerNumber: string;
    CustomerName: string;
    AddressLine1: string;
    City: string;
    State: string;
    Zip: string;
    Active: boolean;
    Hash: string;
    Links: ILink[];
}

type DisplayCustomerViewModel = {
    CustomerNumber: string;
    CustomerName: string;
    City: string;
    State: string;
    Zip: string;
    Active?: boolean;
}

type Props = {
    onSelectedCustomer: (customer: CustomerViewModel) => void;
    selectedCustomerID?: number;
    setFocus?: boolean;
    includeRecentlyRetiredCustomers?: boolean;
} & AutoCompleteProps

export type CustomerAutoCompleteViewModel = CustomerViewModel & { DisplayValue: string; };

export const GetCustomerAutoCompleteDisplayValue = (customer: DisplayCustomerViewModel, includeActiveLabel?: boolean): string => {
    const displayValue = `${customer.CustomerNumber} - ${customer.CustomerName} ${customer.City}, ${customer.State} ${customer.Zip}`.toUpperCase();
    if (includeActiveLabel) return `${displayValue} - ${customer.Active ? 'Active' : 'Retired'}`;
    return displayValue;
}

/**
 * @deprecated use <CustomerInput /> instead as you can't find LOAD1 easily
 */
const CustomerAutoComplete: React.FC<Props> = ({
    onSelectedCustomer,
    selectedCustomerID,
    includeRecentlyRetiredCustomers,
    setFocus,
    ...autoCompleteProps
}) => {
    const inputAC = useRef<AutoComplete>(null);
    const [loading, setLoading] = useState(false);
    const [customerDropDownListing, setCustomerDropDownListing] = useState<CustomerAutoCompleteViewModel[]>([]);
    const [customerAutoCompleteValue, setCustomerAutoCompleteValue] = useState('');
    const _selectedCustomer = useRef(0);
    const _requestHash = useRef('');
    //const [preSelectedCustomerInitialized, setPreSelectedCustomerInitialized] = useState(false);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearchInput = useCallback(
        debounce((val: string, requestHash: string) => {
            if (val.length > 2 && _selectedCustomer.current === 0) {
                getCustomerListing(generateDataStateByName(val), requestHash);
            }
        }, 750), []);

    const generateDataStateByName = (val: string): GridState => {
        let gs = {
            skip: 0,
            take: 12,
            sort: [{
                field: "CustomerName",
                dir: "asc"
            }],
            filter: {
                logic: 'and', filters: [
                    {
                        logic: 'or', filters: [
                            { field: 'CustomerNumber', operator: 'contains', value: val },
                            { field: 'CustomerName', operator: 'contains', value: val }
                        ] as any
                    }
                ] as any
            }
        } as GridState;

        if (includeRecentlyRetiredCustomers) {
            gs.filter.filters.unshift({
                logic: 'or', filters: [
                    { field: 'active', operator: 'eq', value: true },
                    {
                        logic: 'and', filters: [
                            { field: 'active', operator: 'eq', value: false },
                            { field: 'RetiredDate', operator: 'gt', value: Moment().subtract(4, "weeks").toDate() }
                        ]
                    }
                ]
            });
        } else {
            gs.filter.filters.unshift({ field: 'active', operator: 'eq', value: true });
        }

        return gs;
    }

    const generateDataStateByID = (val: number): GridState => {
        return {
            skip: 0,
            take: 1,
            sort: null,
            filter: {
                logic: 'and', filters: [
                    { field: 'CustomerID', operator: 'eq', value: val }
                ] as any
            }
        } as GridState;
    }

    const getCustomerListing = useCallback((dataState: GridState, requestHash: string, populateLabel: boolean = false) => {
        setLoading(true);
        const queryStr = `${toDataSourceRequestString(dataState)}`;
        fetchApi(`/api/Customer/GetCustomers?${queryStr}`, {}, 'POST')
            .then(({ Data }) => {
                let customerData: CustomerAutoCompleteViewModel[] = Data;
                setLoading(false);
                if (_requestHash.current === requestHash) {
                    customerData.forEach(x => {
                        x.DisplayValue = GetCustomerAutoCompleteDisplayValue(x, includeRecentlyRetiredCustomers);
                    });
                    setCustomerDropDownListing(customerData);

                    if (populateLabel) {
                        setCustomerAutoCompleteValue(customerData[0].CustomerNumber);
                        //setPreSelectedCustomerInitialized(true);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
            })
    }, [includeRecentlyRetiredCustomers]);

    const onCustomerListingClose = (event: AutoCompleteCloseEvent) => {
        const value = event.target.value;
        const selectedCustomer = customerDropDownListing.find(x => x.DisplayValue === value);        
        setCustomerAutoCompleteValue(selectedCustomer != null ? selectedCustomer.CustomerNumber : '');
        _selectedCustomer.current = selectedCustomer?.CustomerID;
        onSelectedCustomer(selectedCustomer ?? null);
    };

    useEffect(() => {
        if (selectedCustomerID !== _selectedCustomer.current) {
            if (selectedCustomerID > 0) {
                _selectedCustomer.current = selectedCustomerID;
                _requestHash.current = Math.random().toString(36).substring(7);
                getCustomerListing(generateDataStateByID(selectedCustomerID), _requestHash.current, true);
            } else {
                _selectedCustomer.current = 0;
                setCustomerAutoCompleteValue('');
            }
            if (setFocus) {
                inputAC.current.focus();
            }
        }
    }, [selectedCustomerID, getCustomerListing, setFocus]);

    //useEffect(() => {
    //    if (preSelectedCustomerInitialized) {
    //        inputAC.current.focus();
    //        (inputAC.current.element.querySelector('input') as HTMLInputElement).select();
    //    }
    //}, [preSelectedCustomerInitialized])

    return <AutoComplete
        ref={inputAC}
        {...autoCompleteProps}
        placeholder={(loading ? 'loading...' : autoCompleteProps?.placeholder ?? 'Search Customer')}
        loading={loading}        
        data={customerDropDownListing}
        value={customerAutoCompleteValue}
        textField="DisplayValue"
        onChange={(e) => {
            _selectedCustomer.current = 0;
            if (!e.value) onSelectedCustomer(null);
            setCustomerDropDownListing([]);
            setCustomerAutoCompleteValue(e.value);
            _requestHash.current = Math.random().toString(36).substring(7);
            debounceSearchInput(e.value, _requestHash.current);
        }}
        onClose={(e) => onCustomerListingClose(e)}
    />
}

export default CustomerAutoComplete;
