import * as React from 'react';
import { fetchApi } from '../../services/api';
import { formatNumber } from '@progress/kendo-intl';
import { RadialGauge } from '@progress/kendo-react-gauges';

type State = {
  loading: boolean;
  data: { Count: number, Revenue: number };
}

type Props = {
  filter: { Name: string, Filter: string };
}

export default class TripCountNextMonday extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: { Count: 0, Revenue: 0 },
    }
  }

  public componentDidMount() {
    this.refreshData();
  }

  public refreshData() {
    fetchApi(`/api/Report/TripCountNextMonday/${this.props.filter.Filter}`)
      .then((response: { Count: number, Revenue: number }) => {
        this.setState({
          loading: false,
          data: response,
        });
      });
  }

  public render() {
    if (this.state.loading) {
      return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card"></div>;
    }

    const max = Math.max(500, this.state.data.Count);

    return <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 report-card">
            <p>
              <span className="report-card-title">Next Monday's Trip Count - {this.props.filter.Name}</span>
              <br />
              {this.state.data.Count} Trips / {formatNumber(this.state.data.Revenue, "c0")} Total Revenue
            </p>
            <RadialGauge
              transitions={false}
              pointer={{ value: this.state.data.Count }}
              scale={{
                max: max,
                labels: {
                  format: "{0}",
                },
                ranges: [
                  { from: 0, to: 201, color: "#dc3545" },
                  { from: 201, to: 301, color: "#ffc107" },
                  { from: 301, to: max, color: "#198754" }
                ]
              }}
            />
          </div>
  }
}
