import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute } from "react-router-dom-v5-compat";
import { Layout, CustomerLayout } from './components/Layout';

import CarrierUpdate from './views/CarrierUpdate';
import CarrierOffer from './views/CarrierOffer';
import LoadTenderResponse from './views/EDI/LoadTenderResponse';
import UpdateEDIEntityIdentification from './views/EDI/UpdateEDIEntityIdentification';
import Reports from './views/Reports';
import OpsReports from './views/Reports/operations';
import OpsReports2 from './views/Reports/operations2';
import SalesReports from './views/Reports/sales';
import LaneReports from './views/LaneReports';
import CarrierRankings from './views/CarrierRankings';
import BlindBidQuote from './views/BlindBidQuote';
import CarrierLoadBoard from './views/CarrierLoadBoard';

import Home from './views/Home';
import Quote from './views/Quote';
import QuoteHistory from './views/QuoteHistory';
import CarrierGroups from './views/CarrierGroups';
import LoadBoard from './views/LoadBoard';
import LoadBoardBidHistory from './views/LoadBoard/BidHistory';
import OrderTransactionLogs from './views/Order/TransactionLogs';
import OrderServiceException from './views/Order/ServiceException';
import OrderIntegrations from './views/Order/Integrations';
import OrderDetails from './views/Order/Details';
import OrderAttributes from './views/Order/Details/Attributes';
import OrderStop from './views/Order/Stop';
import OrderFreight from './views/Order/Freight';
import OrderAddPair from './views/Order/AddPair';
import OrderStopDelete from './views/Order/Stop/Delete';
import OrderStopSplit from './views/Order/Stop/Split';
import OrderRate from './views/Order/Rate';
import OrderSchedule from './views/Order/Schedule';
import OrderPlanning from './views/Order/Planning';
import OrderPlanningSuggestions from './views/Order/Planning/Suggestions';
import OrderPlanningVehicle from './views/Order/Planning/Vehicle';
import OrderPlanningCarrier from './views/Order/Planning/Carrier';
import OrderImages from './views/Order/Images';
import ImageViewer from './views/Order/Images/ImageViewer';
import ScheduledOrders from './views/Order/ScheduledOrders';
import ShiftTurnover from './views/ShiftTurnover';
import TripProfile from './views/Trip/Profile';
import TripPay from './views/Trip/Pay';
import TripTransactionLogs from './views/Trip/TransactionLogs';
import TripPaySwitchAsset from './views/Trip/Pay/SwitchAsset';
import DeadHeadCreate from './views/Trip/DeadHead/Create';
import PayTripCreate from './views/Trip/PayTrip/Create';
import Track from './views/Track';
import TrackFlights from './views/TrackFlights';
import TrackPositions from './views/Track/Positions';
import AssetsDrivers from './views/AssetDrivers';
import ResourceLogs from './views/Assets/ResourceLogs';
import OwnerProfile from './views/AssetOwners/Profile';
import OwnerNotes from './views/AssetOwners/Notes';
import OwnerDevices from './views/AssetOwners/Devices';
import DriverProfile from './views/AssetDrivers/Profile';
import DriverNotes from './views/AssetDrivers/Notes';
import DriverPerformance from 'views/AssetDrivers/Performance';
import DriverDevices from './views/AssetDrivers/Devices';
import DriverHoursOfSerivce from './views/AssetDrivers/HoursOfService';
import DriverTurndowns from './views/AssetDrivers/Turndowns';
import DriverOffers from './views/AssetDrivers/Offers';
import DriverMovementHistory from 'views/AssetDrivers/VehicleToDriverHistory';
import DriverAccidents from 'views/Assets/DriverAccidents';
import DriverAccident from 'views/Assets/DriverAccident';
import VehicleProfile from './views/AssetVehicles/Profile';
import VehiclePositions from './views/AssetVehicles/Positions';
import VehicleTurndowns from './views/AssetVehicles/Turndowns';
import VehicleOffers from './views/AssetVehicles/Offers';
import VehicleHardware from 'views/AssetVehicles/Hardware';
import VehicleNotes from './views/AssetVehicles/Notes';
import AssetsVehicles from './views/AssetVehicles';
import VehicleFleetMessage from './views/FleetMessages/SendSatMessageRoute';
import AssetsCarriers from './views/AssetCarriers';
import CarrierProfile from './views/AssetCarriers/CarrierProfile';
import CarrierContactGrid from './views/AssetCarriers/CarrierContactGrid';
import CarrierContacts from './views/AssetCarriers/CarrierContacts';
import CarrierNotes from './views/AssetCarriers/Notes';
import CarrierBids from './views/AssetCarriers/Bids';
import CarrierDevices from './views/AssetCarriers/Devices';
import CarrierScorecard from './views/AssetCarriers/Scorecard';
import CarrierServiceExceptions from './views/AssetCarriers/ServiceExceptions';
import CarrierPayHistory from './views/AssetCarriers/PayHistory';
import CarrierContactProfile from './views/AssetCarriers/CarrierContactProfile';
import AssetTrailers from './views/AssetTrailers';
import AssetOwners from './views/AssetOwners';
import TrailerProfile from './views/AssetTrailers/Profile';
import TrailerPositions from './views/AssetTrailers/Positions';
import TrailerNotes from './views/AssetTrailers/Notes';
import AssetInspections from './views/AssetInspections';
import DriverChat from './views/DriverChat';
import ComplianceDates from 'views/Assets/ComplianceDates';
import UploadBestpassTollReport from './views/Assets/UploadBestpassTollReport';
import DriverMetrics from './views/Assets/DriverMetrics';
import AgentNotes from './views/AgentNotes';
import CompanySettings from './views/CompanySettings';
import AccountNotifications from './views/AccountNotifications';
import AccountSettings from './views/AccountSettings';
import SylectusLogin from './views/AccountSettings/SylectusLogin';
import ZoomCallLogs from './views/Zoom/CallLogs';
import ZoomOperationalLogs from './views/Zoom/OperationLogs';
import Customers from './views/Customers';
import CustomerProfile from './views/Customers/Profile';
import CustomerLogs from './views/Customers/CustomerLogs';
import CustomerNotes from './views/Customers/Notes';
import CustomerDevices from './views/Customers/Devices';
import CustomerSpecialInstructions from './views/Customers/CustomerSpecialInstructions';
import CustomerSalesHistory from './views/Customers/SalesHistory';
import CustomerContacts from './views/Customers/CustomerContacts';
import CustomerContactGrid from './views/Customers/CustomerContactGrid';
import CustomerContactProfile from './views/Customers/CustomerContactProfile';
import FleetMessages from './views/FleetMessages';
import Salespeople from './views/Salespeople';
import SalespersonProfile from './views/Salespeople/SalespersonProfile';
import CustomsBrokers from './views/CustomsBrokers';
import CustomsBrokerProfile from './views/CustomsBrokers/BrokerProfile';
import EmailAliases from './views/EmailAliases';
import PurchaseOrders from './views/PurchaseOrders';
import PurchaseOrderVendors from './views/PurchaseOrders/PurchaseOrderVendorListing';
import PurchaseOrderVendorProfile from './views/PurchaseOrders/VendorProfile';
import PurchaseOrderGeneralLedgerAccounts from './views/PurchaseOrders/GeneralLedgerAccountListing';
import LoadMap from './views/LoadMap';
import Metrics from './views/Metrics';
import InvoiceListing from 'views/Billing/InvoiceListing';
import PostInvoiceBatch from 'views/Billing/PostInvoiceBatch';
import DownloadInvoiceBatch from 'views/Billing/DownloadInvoiceBatch';
import InvoiceGateway from 'views/Billing/InvoiceGateway/InvoiceGateway';
import BillingUserMetrics from './views/Billing/BillingUserMetrics';
import DownloadClassifiedEpayDocs from './views/Settlements/DownloadClassifiedEpayDocs';
import UploadVehicleFuelDeductions from './views/Settlements/UploadVehicleFuelDeductions';
import UploadDriverChargebacks from './views/Settlements/UploadDriverChargebacks';
import UploadEmailOwnerFuelReport from './views/Settlements/UploadEmailOwnerFuelReport';
import UploadAmortizedVehicleChargebacks from './views/Settlements/UploadAmortizedVehicleChargebacks';
import DriverCardLogs from './views/DriverCardLogs';
import QuoteCustomer from './views/QuoteCustomer';
import ServiceStatusGrid from './views/AssetVehicles/ServiceStatusGrid';
import RateMasterViewer from './views/Rating';
import PayRateLinehaul from 'views/Rating/PayRateLinehaul';
import PayRateAccessorial from 'views/Rating/PayRateAccessorial';
import CustomersMerge from 'views/Customers/Merge';
import CustomersBackhaul, { CustomersBackhaulMap } from 'views/Customers/Backhaul';
import DeductionCOAs from './views/Settlements/DeductionCOAs';
import ScheduledDeductions from './views/Settlements/ScheduledDeductions';
import GenerateDeductions from './views/Settlements/GenerateDeductions';
import PendingDeductions from './views/Settlements/PendingDeductions';
import PostPayBatch from './views/Settlements/PostPayBatch';
import PayListing from './views/Settlements/PayListing';
import Owner1099Report from 'views/Settlements/Owner1099Report';
import WorkloadHistory from 'views/Assets/WorkloadHistory';
import CarrierVehicles from 'views/AssetCarriers/Vehicles';
import CarrierVehicleProfile from 'views/AssetCarriers/VehicleProfile';
import CarrierAssureReportCard from 'views/AssetCarriers/CarrierAssureReportCard';
import TripAttributes from 'views/Trip/Details/Attributes';
import OrderNotes from './views/Order/Details/OrderNotes';
import UserReports from 'views/Reports/user';

const QuoteFlow = lazy(() => import('./views/QuoteFlow'));

// Tip: If adding a URL that ends with a JWT token ensure MapFallbackToFile is added to Startup.cs
export enum paths {
  CarrierUpdate = "/Track/CarrierUpdate/:key",
  CarrierOffer = "/Quote/CarrierOffer/:quoteId/:key",
  LoadTenderResponse = "/EDI/LoadTenderResponse/:orderId",
  UpdateEDIEntityIdentification = "/EDI/UpdateEDIEntityIdentification/:locationId/:customerId/:invoiceId?",
  Reports = "/CLevelReports",
  OpsReports = "/OpsReports",
  OpsReports2 = "/OpsReports2",
  SalesReports = "/SalesReports/:salespersonId?",
  UserReports = "/UserReports",
  LaneReports = "/LaneReports",
  CarrierRankings = "/CarrierRankings",
  LoadMap = "/LoadMap/Index",
  QuoteCustomer = "/c/Quote/:token",
  Quote = "/Quote/Index/:quoteId?",
  QuoteHistory = "/Quote/History",
  QuoteFlow = "/Quote/Flow",
  BlindBidQuote = "/Quote/BlindBidQuote",
  CarrierLoadBoard = "/Quote/LoadBoard",
  CarrierGroups = "/Carrier/Groups",
  LoadBoard = "/LoadBoard/Index",
  LoadBoardBidHistory = "/LoadBoard/BidHistory",
  Order = "/Order/:orderId(\\d+)",
  OrderAddPair = "/Order/:orderId/AddPair",
  OrderStop = "/Order/:orderId/Stop/:orderStopId",
  OrderFreight = "/Order/:orderId/Freight/:orderFreightId",
  OrderStopDelete = "/Order/:orderId/Stop/:orderStopId/Delete",
  OrderStopSplit = "/Order/:orderId/Stop/:orderStopId/Split",
  OrderStopAddPickup = "/Order/:orderId/Stop/:orderStopId/AddPickup",
  OrderStopAddDelivery = "/Order/:orderId/Stop/:orderStopId/AddDelivery",
  OrderRate = "/Order/:orderId/Rate/:sequence/:letter",
  OrderSchedule = "/Order/:orderId/Schedule",
  OrderPlanning = "/Order/Planning/:orderId",
  OrderPlanningVehicle = "/Order/Planning/:orderId/Vehicle/:vehicleId",
  OrderPlanningCarrier = "/Order/Planning/:orderId/Carrier/:carrierId",
  OrderImages = "/Order/:orderId/Images",
  ImageViewer = "/Image/:orderId/ImageViewer",
  OrderTransactionLogs = "/Order/:orderId/TransactionLogs",
  OrderServiceException = "/Order/:orderId/ServiceException/:orderServiceExceptionId",
  OrderIntegrations = "/Order/:orderId/Integrations",
  ScheduledOrders = "/ScheduledOrders",
  ShiftTurnover = "/ShiftTurnover",
  Trip = "/Trip/:tripId(\\d+)",
  TripTransactionLogs = "/Trip/:tripId/TransactionLogs",
  TripPay = "/Trip/:tripId/Pay/:sequence/:letter",
  TripPaySwitchAsset = "/Trip/:tripId/Pay/:sequence/:letter/SwitchAsset",
  DeadHeadCreate = "/DeadHead/Create",
  PayTripCreate = "/PayTrip/Create",
  Track = "/Track/Index/:searchTerm?",
  TrackFlights = "/Track/Flights",
  TrackPositions = "/Track/Positions",
  ResourceLogs = "/Assets/ResourceLogs/:resourceCode/:resourceType",
  AssetsDrivers = "/Assets/Drivers/:searchTerm?",
  AssetOwners = "/Assets/Owners",
  OwnerProfile = "/Assets/Owner/:ownerId",
  OwnerNotes = "/Assets/Owner/:ownerId/Notes",
  OwnerDevices = "/Assets/Owner/:ownerId/Devices",
  DriverProfile = "/Assets/Driver/:driverId",
  DriverNotes = "/Assets/Driver/:driverId/Notes",
  DriverPerformance = "/Assets/Driver/:driverId/Performance",
  DriverDevices = "/Assets/Driver/:driverId/Devices",
  DriverHoursOfService = "/Assets/Driver/:driverId/HoursOfService",
  DriverTurndowns = "/Assets/Driver/:driverId/Turndowns",
  DriverOffers = "/Assets/Driver/:driverId/Offers",
  DriverMovementHistory = "/Assets/Driver/:driverId/MovementHistory",
  DriverWorkloadHistory = "/Assets/Driver/:driverId/WorkloadHistory",
  DriverAccidents = "/Assets/DriverAccidents",
  DriverAccident = "/Assets/DriverAccident/:driverAccidentId",
  VehicleProfile = "/Assets/Vehicle/:vehicleId",
  VehiclePositions = "/Assets/Vehicle/:vehicleId/Positions",
  VehicleTurndowns = "/Assets/Vehicle/:vehicleId/Turndowns",
  VehicleOffers = "/Assets/Vehicle/:vehicleId/Offers",
  VehicleHardware = "/Assets/Vehicle/:vehicleId/Hardware",
  VehicleNotes = "/Assets/Vehicle/:vehicleId/Notes",
  VehicleWorkloadHistory = "/Assets/Vehicle/:vehicleId/WorkloadHistory",
  AssetsVehicles = "/Assets/Vehicles/:searchTerm?",
  ServiceStatus = "/Assets/Vehicle/:vehicleId/ServiceStatuses",
  VehicleFleetMessage = "/Assets/Vehicle/:vehicleId/FleetMessage",
  AssetsCarriers = "/Assets/Carriers",
  CarrierProfile = "/Assets/Carrier/:carrierId",
  CarrierContacts = "/Assets/Carrier/:carrierId/Contacts",
  CarrierNotes = "/Assets/Carrier/:carrierId/Notes",
  CarrierBids = "/Assets/Carrier/:carrierId/Bids",
  CarrierDevices = "/Assets/Carrier/:carrierId/Devices",
  CarrierScorecard = "/Assets/Carrier/:carrierId/Scorecard",
  CarrierServiceExceptions = "/Assets/Carrier/:carrierId/ServiceExceptions",
  CarrierPayHistory = "/Assets/Carrier/:carrierId/PayHistory",
  CarrierWorkloadHistory = "/Assets/Carrier/:carrierId/WorkloadHistory",
  CarrierVehicles = "/Assets/Carrier/:carrierId/Vehicles",
  CarrierVehicleProfile = "/Assets/Carrier/:carrierId/Vehicle/:vehicleId",
  CarrierAssureReportCard = "/Assets/Carrier/:carrierId/CarrierAssureReportCard",
  CarrierContactsGrid = "/Assets/Carriers/Contacts",
  CarrierContactProfile = "/Assets/Carrier/Contact/:carrierContactId",
  AssetTrailers = "/Assets/Trailers",
  TrailerProfile = "/Assets/Trailer/:trailerId",
  TrailerPositions = "/Assets/Trailer/:trailerId/Positions",
  TrailerNotes = "/Assets/Trailer/:trailerId/Notes",
  AssetInspections = "/Assets/Inspections/:defectId?",
  WorkloadHistory = "/Assets/WorkloadHistory",
  DriverChat = "/Driver/Chat/:sessionId?",
  ComplianceDates = "/Assets/ComplianceDates",
  UploadBestpassTollReport = "/Assets/UploadBestpassTollReport",
  DriverMetrics = "/Assets/DriverMetrics",
  Customers = "/Customers",
  CustomerProfile = "/Customers/Customer/:customerId",
  CustomerLogs = "/Customers/Customer/:customerId/Logs",
  CustomerNotes = "/Customers/Customer/:customerId/Notes",
  CustomerDevices = "/Customers/Customer/:customerId/Devices",
  CustomerSalesHistory = "/Customers/Customer/:customerId/SalesHistory",
  CustomerRateStructures = "/Customers/Customer/:customerId/RateStructures",
  CustomerSpecialInstructions = "/Customers/Customer/:customerId/SpecialInstructions",
  CustomerContacts = "/Customers/Customer/:customerId/Contacts",
  CustomerContactsGrid = "/Customers/Contacts",
  CustomerContactProfile = "/Customers/Customer/Contact/:customerContactId",
  FleetMessages = "/FleetMessages",
  Salespeople = "/Salespeople",
  SalespersonProfile = "/Salespeople/Salesperson/:salespersonId",
  CustomsBrokers = "/CustomsBrokers",
  CustomsBrokerProfile = "/CustomsBrokers/Broker/:brokerId",
  EmailAliases = "/Customers/EmailAliases",
  PurchaseOrder = "/PurchaseOrder/Index/:purchaseOrderNumber?",
  PurchaseOrderVendors = "/PurchaseOrder/Vendors",
  PurchaseOrderVendorProfile = "/PurchaseOrder/Vendor/:vendorId",
  PurchaseOrderGeneralLedgerAccounts = "/PurchaseOrder/GeneralLedgerAccounts",
  AgentNotes = "/AgentNotes",
  CompanySettings = "/Company/Settings",
  AccountNotifications = "/Account/Notifications",
  AccountSettings = "/Account/Settings",
  SylectusLogin = "/Account/SylectusLogin",
  ZoomCallLogs = "/Zoom/CallLogs",
  ZoomOperationalLogs = "/Zoom/OperationalLogs",
  Metrics = "/Metric",
  InvoiceListing = "/Billing/Index/0",
  PostInvoiceBatch = "/Billing/Index/1",
  DownloadInvoiceBatch = "/Billing/Index/2",
  InvoiceGateway = "/Billing/Index/3",
  BillingUserMetrics = "/Billing/BillingUserMetrics",
  RateMasterViewer = "/Rating/Index",
  PayRateLinehaul = "/Rating/PayRateLinehaul",
  PayRateAccessorial = "/Rating/PayRateAccessorial",
  CustomersMerge = "/Customers/Merge",
  CustomersBackhaul = "/Customers/Backhaul",
  CustomersBackhaulMap = "/Customers/Backhaul/Map",
  DownloadClassifiedEpayDocs = "/Settlements/DownloadClassifiedEpayDocs",
  UploadVehicleFuelDeductions = "/Settlements/UploadVehicleFuelDeductions",
  UploadDriverChargebacks = "/Settlements/UploadDriverChargebacks",
  UploadEmailOwnerFuelReport = "/Settlements/UploadEmailOwnerFuelReport",
  UploadAmortizedVehicleChargebacks = "/Settlements/UploadAmortizedVehicleChargebacks",
  Owner1099Report = "/Settlements/Owner1099Report",
  DriverCardLogs = "/DriverCardLogs/Index",
  DeductionCOAs = "/Settlements/DeductionCOAs",
  ScheduledDeductions = "/Settlements/ScheduledDeductions",
  GenerateDeductions = "/Settlements/GenerateDeductions",
  PendingDeductions = "/Settlements/PendingDeductions",
  PostPayBatch = "/Settlements/PostPayBatch",
  PayListing = "/Settlements/PayListing",
  Home = "/", // Must be last...
};

export const routes = <Switch>
  <Route path={paths.CarrierUpdate} component={CarrierUpdate} />
  <Route path={paths.CarrierOffer} component={CarrierOffer} />
  <Route path={paths.LoadTenderResponse} component={LoadTenderResponse} />
  <Route path={paths.UpdateEDIEntityIdentification} component={UpdateEDIEntityIdentification} />
  <Route path={paths.Reports} component={Reports} />
  <Route path={paths.OpsReports} component={OpsReports} />
  <Route path={paths.OpsReports2} component={OpsReports2} />
  <Route path={paths.SalesReports} component={SalesReports} />
  <Route path={paths.UserReports} component={UserReports} />
  <Route path={paths.LaneReports} component={LaneReports} />
  <Route path={paths.LoadMap} component={LoadMap} />
  <Route path={paths.QuoteFlow} component={() => <Suspense fallback={<>Loading...</>}><QuoteFlow /></Suspense>} />
  <Route path="/c">
    <CustomerLayout>
      <Route exact path={paths.QuoteCustomer} component={QuoteCustomer} />
    </CustomerLayout>
  </Route>
  <Layout>
    <Route path={paths.Quote} component={Quote} />
    <Route path={paths.QuoteHistory} component={QuoteHistory} />
    <Route path={paths.BlindBidQuote} component={BlindBidQuote} />
    <Route path={paths.CarrierLoadBoard} component={CarrierLoadBoard} />
    <Route path={paths.CarrierGroups} component={CarrierGroups} />
    <Route path={paths.LoadBoard} component={LoadBoard} />
    <Route path={paths.LoadBoardBidHistory} component={LoadBoardBidHistory} />
    <Route path={paths.OrderPlanning}>
      <OrderPlanning>
        <Route exact path={paths.OrderPlanning} component={OrderPlanningSuggestions} />
        <Route path={paths.OrderPlanningVehicle} component={OrderPlanningVehicle} />
        <Route path={paths.OrderPlanningCarrier} component={OrderPlanningCarrier} />
      </OrderPlanning>
    </Route>
    <Route path={[paths.OrderRate, paths.Order]}>
      <OrderDetails>
        <Route exact path={paths.Order} component={OrderAttributes} />
        <Route exact path={paths.Order} component={OrderNotes} />
        <Route exact path={paths.OrderStop} component={OrderStop} />
        <Route exact path={paths.OrderFreight} component={OrderFreight} />
        <Route exact path={paths.OrderAddPair} component={OrderAddPair} />
        <Route exact path={paths.OrderStopAddPickup} component={OrderAddPair} />
        <Route exact path={paths.OrderStopAddDelivery} component={OrderAddPair} />
        <Route exact path={paths.OrderStopDelete} component={OrderStopDelete} />
        <Route exact path={paths.OrderStopSplit} component={OrderStopSplit} />
        <Route exact path={paths.OrderRate} component={OrderRate} />
        <Route exact path={paths.OrderRate} component={OrderNotes} />
        <Route exact path={paths.OrderSchedule} component={OrderSchedule} />
        <Route exact path={paths.OrderImages} component={OrderImages} />
        <Route exact path={paths.OrderTransactionLogs} component={OrderTransactionLogs} />
        <Route exact path={paths.OrderServiceException} component={OrderServiceException} />
        <Route exact path={paths.OrderIntegrations} component={OrderIntegrations} />
      </OrderDetails>
    </Route>
    <Route path={paths.ScheduledOrders} component={ScheduledOrders} />
    <Route path={paths.ShiftTurnover} component={ShiftTurnover} />
    <Route path={paths.Trip}>
      <TripProfile>
        <Route exact path={paths.Trip} component={TripAttributes} />
        <Route exact path={paths.TripPay} component={TripPay} />
        <Route exact path={paths.TripTransactionLogs} component={TripTransactionLogs} />
        <Route exact path={paths.TripPaySwitchAsset} component={TripPaySwitchAsset} />
      </TripProfile>
    </Route>
    <Route path={paths.ImageViewer} component={ImageViewer} />
    <Route path={paths.DeadHeadCreate} component={DeadHeadCreate} />
    <Route path={paths.PayTripCreate} component={PayTripCreate} />
    <Route path={paths.Track} component={Track} />
    <Route path={paths.TrackFlights} component={TrackFlights} />
    <Route path={paths.TrackPositions} component={TrackPositions} />
    <Route path={paths.ResourceLogs} component={ResourceLogs} />
    <Route path={paths.AssetsDrivers} component={AssetsDrivers} />
    <Route exact path={paths.OwnerProfile} component={OwnerProfile} />
    <Route path={paths.OwnerNotes} component={OwnerNotes} />
    <Route path={paths.OwnerDevices} component={OwnerDevices} />
    <Route exact path={paths.DriverProfile} component={DriverProfile} />
    <Route path={paths.DriverNotes} component={DriverNotes} />
    <Route path={paths.DriverPerformance} component={DriverPerformance} />
    <Route path={paths.DriverDevices} component={DriverDevices} />
    <Route path={paths.DriverHoursOfService} component={DriverHoursOfSerivce} />
    <Route path={paths.DriverTurndowns} component={DriverTurndowns} />
    <Route path={paths.DriverOffers} component={DriverOffers} />
    <Route path={paths.DriverMovementHistory} component={DriverMovementHistory} />
    <Route path={paths.DriverWorkloadHistory} component={WorkloadHistory} />
    <Route path={paths.DriverAccidents} component={DriverAccidents} />
    <Route path={paths.DriverAccident} component={DriverAccident} />
    <Route exact path={paths.VehicleProfile} component={VehicleProfile} />
    <Route path={paths.VehiclePositions} component={VehiclePositions} />
    <Route path={paths.VehicleTurndowns} component={VehicleTurndowns} />
    <Route path={paths.VehicleOffers} component={VehicleOffers} />
    <Route path={paths.VehicleHardware} component={VehicleHardware} />
    <Route path={paths.VehicleNotes} component={VehicleNotes} />
    <Route path={paths.AssetsVehicles} component={AssetsVehicles} />
    <Route path={paths.ServiceStatus} component={ServiceStatusGrid} />
    <Route path={paths.VehicleFleetMessage} component={VehicleFleetMessage} />
    <Route exact path={paths.AssetsCarriers} component={AssetsCarriers} />
    <Route path={paths.CarrierRankings} component={CarrierRankings} />
    <Route path={paths.AssetTrailers} component={AssetTrailers} />
    <Route path={paths.AssetOwners} component={AssetOwners} />
    <Route exact path={paths.TrailerProfile} component={TrailerProfile} />
    <Route path={paths.TrailerPositions} component={TrailerPositions} />
    <Route path={paths.TrailerNotes} component={TrailerNotes} />
    <Route path={paths.AssetInspections} component={AssetInspections} />
    <Route path={paths.WorkloadHistory} component={WorkloadHistory} />
    <Route path={paths.VehicleWorkloadHistory} component={WorkloadHistory} />
    <Route path={paths.DriverChat} component={DriverChat} />
    <Route path={paths.ComplianceDates} component={ComplianceDates} />
    <Route path={paths.UploadBestpassTollReport} component={UploadBestpassTollReport} />
    <Route path={paths.DriverMetrics} component={DriverMetrics} />
    <Route exact path={paths.Customers} component={Customers} />
    <Route exact path={paths.CustomerProfile} component={CustomerProfile} />
    <Route path={paths.CustomerLogs} component={CustomerLogs} />
    <Route path={paths.CustomerNotes} component={CustomerNotes} />
    <Route path={paths.CustomerDevices} component={CustomerDevices} />
    <Route path={paths.CustomerRateStructures} component={RateMasterViewer} />
    <Route path={paths.PayRateLinehaul} component={PayRateLinehaul} />
    <Route path={paths.PayRateAccessorial} component={PayRateAccessorial} />
    <Route path={paths.CustomersMerge} component={CustomersMerge} />
    <Route path={paths.CustomersBackhaul}>
      <CustomersBackhaul>
        <Route exact path={paths.CustomersBackhaulMap} component={CustomersBackhaulMap} />
      </CustomersBackhaul>
    </Route>
    <Route path={paths.CustomerSpecialInstructions} component={CustomerSpecialInstructions} />
    <Route path={paths.CustomerSalesHistory} component={CustomerSalesHistory} />
    <Route path={paths.CustomerContacts} component={CustomerContacts} />
    <Route path={paths.CustomerContactsGrid} component={CustomerContactGrid} />
    <Route exact path={paths.CustomerContactProfile} component={CustomerContactProfile} />
    <Route path={paths.FleetMessages} component={FleetMessages} />
    <Route exact path={paths.Salespeople} component={Salespeople} />
    <Route exact path={paths.SalespersonProfile} component={SalespersonProfile} />
    <Route exact path={paths.CustomsBrokers} component={CustomsBrokers} />
    <Route exact path={paths.CustomsBrokerProfile} component={CustomsBrokerProfile} />
    <Route path={paths.EmailAliases} component={EmailAliases} />
    <Route path={paths.PurchaseOrder} component={PurchaseOrders} />
    <Route path={paths.PurchaseOrderVendors} component={PurchaseOrderVendors} />
    <Route exact path={paths.PurchaseOrderVendorProfile} component={PurchaseOrderVendorProfile} />
    <Route path={paths.PurchaseOrderGeneralLedgerAccounts} component={PurchaseOrderGeneralLedgerAccounts} />
    <Route path={paths.AgentNotes} component={AgentNotes} />
    <Route path={paths.CompanySettings} component={CompanySettings} />
    <Route path={paths.AccountNotifications} component={AccountNotifications} />
    <Route path={paths.AccountSettings} component={AccountSettings} />
    <Route path={paths.SylectusLogin} component={SylectusLogin} />
    <Route path={paths.ZoomCallLogs} component={ZoomCallLogs} />
    <Route path={paths.ZoomOperationalLogs} component={ZoomOperationalLogs} />
    <Route exact path={paths.Metrics} component={Metrics} />
    <Route path={paths.InvoiceListing} component={InvoiceListing} />
    <Route path={paths.PostInvoiceBatch} component={PostInvoiceBatch} />
    <Route path={paths.DownloadInvoiceBatch} component={DownloadInvoiceBatch} />
    <Route path={paths.InvoiceGateway} component={InvoiceGateway} />
    <Route path={paths.BillingUserMetrics} component={BillingUserMetrics} />
    <Route path={paths.RateMasterViewer} component={RateMasterViewer} />
    <Route path={paths.DownloadClassifiedEpayDocs} component={DownloadClassifiedEpayDocs} />
    <Route path={paths.UploadVehicleFuelDeductions} component={UploadVehicleFuelDeductions} />
    <Route path={paths.UploadDriverChargebacks} component={UploadDriverChargebacks} />
    <Route path={paths.UploadEmailOwnerFuelReport} component={UploadEmailOwnerFuelReport} />
    <Route path={paths.UploadAmortizedVehicleChargebacks} component={UploadAmortizedVehicleChargebacks} />
    <Route path={paths.DeductionCOAs} component={DeductionCOAs} />
    <Route path={paths.ScheduledDeductions} component={ScheduledDeductions} />
    <Route path={paths.GenerateDeductions} component={GenerateDeductions} />
    <Route path={paths.PendingDeductions} component={PendingDeductions} />
    <Route path={paths.PostPayBatch} component={PostPayBatch} />
    <Route path={paths.PayListing} component={PayListing} />
    <Route path={paths.Owner1099Report} component={Owner1099Report} />
    <Route path={paths.DriverCardLogs} component={DriverCardLogs} />
    <Route exact path={paths.CarrierProfile} component={CarrierProfile} />
    <Route path={paths.CarrierContacts} component={CarrierContacts} />
    <Route path={paths.CarrierNotes} component={CarrierNotes} />
    <Route path={paths.CarrierBids} component={CarrierBids} />
    <Route path={paths.CarrierDevices} component={CarrierDevices} />
    <Route path={paths.CarrierScorecard} component={CarrierScorecard} />
    <Route path={paths.CarrierServiceExceptions} component={CarrierServiceExceptions} />
    <Route path={paths.CarrierPayHistory} component={CarrierPayHistory} />
    <Route path={paths.CarrierWorkloadHistory} component={WorkloadHistory} />
    <Route path={paths.CarrierVehicles} component={CarrierVehicles} />
    <Route path={paths.CarrierVehicleProfile} component={CarrierVehicleProfile} />
    <Route path={paths.CarrierAssureReportCard} component={CarrierAssureReportCard} />
    <Route path={paths.CarrierContactsGrid} component={CarrierContactGrid} />
    <Route exact path={paths.CarrierContactProfile} component={CarrierContactProfile} />
    <CompatRoute path={paths.Home} component={Home} />
  </Layout>
</Switch>;