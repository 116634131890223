import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import Moment from 'moment-timezone';
import { openWindowPerc } from '../../services/openWindow';
import { ILink } from '../../types/link';

const IconCell = (props: GridCellProps) => {
  const attribute = props.dataItem.IsRunningLate ? 'Late' : props.dataItem.ReasonLate ? 'Modified' : '';
  const title = props.dataItem.IsRunningLate ? 'Running Late' : props.dataItem.ReasonLate;
  return (
    <td style={{ textAlign: 'center' }}>
      <a href="#" onClick={(e) => { e.preventDefault(); openWindowPerc(props.dataItem.Links.find((x: ILink) => x.Name === 'Share').Link); }}>
        {props.dataItem.OrderStopStatus === 110 && props.dataItem.OrderStopEvent === 1 &&
          <img src={`/images/EnrouteToPickup${attribute}.png`} width="40px" alt="Enroute to Pickup" title={title} />}
        {props.dataItem.OrderStopStatus === 110 && props.dataItem.OrderStopEvent !== 1 &&
          <img src={`/images/EnrouteToDelivery${attribute}.png`} width="40px" alt="Enroute to Delivery" title={title} />}
        {props.dataItem.OrderStopStatus === 115 && props.dataItem.OrderStopEvent === 1 &&
          <img src={`/images/ArrivedAtPickup${attribute}.png`} width="25px" alt="Arrived at Pickup" title={title} />}
        {props.dataItem.OrderStopStatus === 115 && props.dataItem.OrderStopEvent !== 1 &&
          <img src={`/images/ArrivedAtDelivery${attribute}.png`} width="25px" alt="Arrived at Delivery" title={title} />}
        {props.dataItem.OrderStopStatus !== 115 && props.dataItem.VehicleSpeed > 0 && <span><br />{props.dataItem.VehicleSpeed} mph</span>}
      </a>
    </td>
  );
}

export default React.memo(IconCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);

