import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { ViewModel } from "TypeGen/Order/FreightEdit/view-model";
import { Command as AddOrderFreight } from "TypeGen/Order/FreightAdd/command";
import { Command as EditOrderFreight } from "TypeGen/Order/FreightEdit/command";
import { FreightEditViewModel } from "TypeGen/Order/freight-edit-view-model";
import { OrderFreightStop } from "TypeGen/Order/FreightEdit/order-freight-stop";
import { OrderContext } from "../Details";
import { fetchApi } from "services/api";
import { getStopVerb } from "views/Quote/GetStopVerb";
import { ILink } from "types/link";
import FreightEditUI from "./Edit";
import { JsonResponse } from "TypeGen/json-response";

type RouteComponentParams = {
  orderId: string;
  orderFreightId: string;
};

type Props = {
  children?: React.ReactNode
};

const OrderFreight: React.FC<Props> = props => {
  const { orderId: orderIdParam, orderFreightId: orderFreightIdParam } = useParams<RouteComponentParams>();
  const { setActiveStopIds, setDeleteStopIds, refresh: refreshOrder } = useContext(OrderContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orderFreight, setOrderFreight] = useState<FreightEditViewModel>();
  const [pickup, setPickup] = useState<OrderFreightStop>();
  const [delivery, setDelivery] = useState<OrderFreightStop>();
  const [links, setLinks] = useState<ILink[]>([]);

  const refreshOrderFreight = useCallback(() => {
    setLoading(true);
    fetchApi(`/api/Order/FreightEdit/${orderIdParam}/${orderFreightIdParam}`)
      .then((response: ViewModel) => {
        setActiveStopIds([response.Pickup.OrderStopID, response.Delivery.OrderStopID]);
        setDeleteStopIds([]);
        setOrderFreight(response.FreightEdit);
        setPickup(response.Pickup);
        setDelivery(response.Delivery);
        setLinks(response.Links);
        setLoading(false);
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      });
  }, [orderIdParam, orderFreightIdParam, setActiveStopIds, setDeleteStopIds]);

  useEffect(() => {
    refreshOrderFreight();
  }, [orderFreightIdParam, refreshOrderFreight]);

  const editFreight = () => {
    const link = links.find(x => x.Name === 'Edit');
    fetchApi(link.Link, {
      PickupStopID: orderFreight.PickupStopID,
      DeliveryStopID: orderFreight.DeliveryStopID,
      Pieces: orderFreight.Pieces,
      PiecesUnitOfMeasure: orderFreight.PiecesUnitOfMeasure,
      Weight: orderFreight.Weight,
      WeightUnitOfMeasure: orderFreight.WeightUnitOfMeasure,
      Length: orderFreight.Length,
      Width: orderFreight.Width,
      Height: orderFreight.Height,
      DimsUnitOfMeasure: orderFreight.DimsUnitOfMeasure,
      Stackable: orderFreight.Stackable,
      CommodityID: orderFreight.CommodityID,
      CommodityAltDescription: orderFreight.CommodityAltDescription,
      BillOfLadingNumber: orderFreight.BillOfLadingNumber,
      FreightValue: orderFreight.FreightValue,
      ProofOfDeliverySignedBy: orderFreight.ProofOfDeliverySignedBy,
    } as EditOrderFreight, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          refreshOrder();
          history.push(`/Order/${orderIdParam}`);
        } else {
          alert(response.ErrorMessage);
        }
      });
  }

  const deleteFreight = () => {
    const link = links.find(x => x.Name === 'Delete');
    fetchApi(link.Link, {}, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          refreshOrder();
          history.push(`/Order/${orderIdParam}`);
        } else {
          alert(response.ErrorMessage);
        }
      });
  }

  const addFreight = () => {
    const link = links.find(x => x.Name === 'Add');
    fetchApi(link.Link, {
      PickupStopID: orderFreight.PickupStopID,
      DeliveryStopID: orderFreight.DeliveryStopID,
      Pieces: orderFreight.Pieces,
      PiecesUnitOfMeasure: orderFreight.PiecesUnitOfMeasure,
      Weight: orderFreight.Weight,
      WeightUnitOfMeasure: orderFreight.WeightUnitOfMeasure,
      Length: orderFreight.Length,
      Width: orderFreight.Width,
      Height: orderFreight.Height,
      DimsUnitOfMeasure: orderFreight.DimsUnitOfMeasure,
      Stackable: orderFreight.Stackable,
      CommodityID: orderFreight.CommodityID,
      CommodityAltDescription: orderFreight.CommodityAltDescription,
      BillOfLadingNumber: orderFreight.BillOfLadingNumber,
      FreightValue: orderFreight.FreightValue,
      ProofOfDeliverySignedBy: orderFreight.ProofOfDeliverySignedBy,
    } as AddOrderFreight, link.Method)
      .then((response: JsonResponse) => {
        if (response.Success) {
          refreshOrder();
          history.push(`/Order/${orderIdParam}`);
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch((e) => {
        setLoading(false);
        // If not problem details
        if (!e?.status) alert(e);
      });
  }

  if (loading && !orderFreight) {
    return <div className="d-flex justify-content-center mt-5">
      <Loader type="converging-spinner" />
    </div>;
  }

  return <div className='py-2'>
    <div className="row">
      <div className="col-md-7">
        <FreightEditUI orderFreight={orderFreight} setOrderFreight={setOrderFreight} />
      </div>
      <div className="col-md-5">
        <div
          className="card mb-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/Order/${orderIdParam}/Stop/${pickup.OrderStopID}`);
          }}
          onMouseEnter={() => {
            setActiveStopIds([pickup.OrderStopID]);
          }}
          onMouseLeave={() => {
            setActiveStopIds([pickup.OrderStopID, delivery.OrderStopID]);
          }}
        >
          <div className="card-body">
            <h5 className="card-title">Pickup</h5>
            {pickup.LocationName && <>
              {pickup.LocationName}
              <br />
            </>}
            {pickup.AddressLine1 && <>
              {pickup.AddressLine1}
              <br />
            </>}
            {pickup.City}, {pickup.State} {pickup.ZipCode}
            <br />
            {moment.utc(pickup.ScheduledDateTime).tz('America/New_York').format('MM/DD/YYYY HH:mm')} {getStopVerb(pickup.StopVerb)}
          </div>
        </div>
        <div
          className="card mb-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/Order/${orderIdParam}/Stop/${delivery.OrderStopID}`);
          }}
          onMouseEnter={() => {
            setActiveStopIds([delivery.OrderStopID]);
          }}
          onMouseLeave={() => {
            setActiveStopIds([pickup.OrderStopID, delivery.OrderStopID]);
          }}
        >
          <div className="card-body">
            <h5 className="card-title">Delivery</h5>
            {delivery.LocationName && <>
              {delivery.LocationName}
              <br />
            </>}
            {delivery.AddressLine1 && <>
              {delivery.AddressLine1}
              <br />
            </>}
            {delivery.City}, {delivery.State} {delivery.ZipCode}
            <br />
            {moment.utc(delivery.ScheduledDateTime).tz('America/New_York').format('MM/DD/YYYY HH:mm')} {getStopVerb(delivery.StopVerb)}
          </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-3">
      <Button themeColor="primary" onClick={editFreight} disabled={loading}>Update</Button>
      <Button themeColor="success" className="ml-2" onClick={addFreight} disabled={loading}>Add As New</Button>
      {links.find(x => x.Name == 'Delete') && <Button themeColor="error" className="ml-2" onClick={deleteFreight} disabled={loading}>Delete Freight</Button>}
    </div>
  </div>
}

export default OrderFreight;