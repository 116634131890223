import * as React from 'react';

type Props = {
  id: string,
  value: string,
  className?: string,
  title?: string,
  defaultValue?: boolean;
  handleCheckboxChange: (value: string, checked: boolean) => void,
}

type State = {
  isChecked: boolean;
}

class Checkbox extends React.Component<Props, State> {

  private textInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isChecked: this.props.defaultValue || false,
    }

    this.textInput = React.createRef();

    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    const { handleCheckboxChange, defaultValue, ...otherProps } = this.props;
    return (<input
              ref={this.textInput}
              type="checkbox"
              checked={this.state.isChecked}
              onChange={this.handleChange}
              {...otherProps}
            />);
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isInputChecked = this.textInput.current.checked;
    this.setState({ isChecked: isInputChecked });

    this.props.handleCheckboxChange(this.props.value || '', isInputChecked);
  };

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.defaultValue !== undefined && nextProps.defaultValue != this.props.defaultValue) {
      this.setState({ isChecked: nextProps.defaultValue });
    }
  }
}

export default Checkbox;
