import * as React from 'react';
import { fetchApi } from '../../services/api';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import CoyoteOffer from './CoyoteOffer';
import { JsonResponse } from 'TypeGen/json-response';

type Props = {};

type State = {
    selectedTab: number;
    pending: ShipmentOffer[];
    accepted: ShipmentOffer[];
    countered: ShipmentOffer[];
    declined: ShipmentOffer[];
    history: ShipmentOffer[];
 };

export type ShipmentOffer = {
    OfferId: string;
    OfferStatus: number;
    ShipmentID: string;
    LoadId: number;
    OfferAmount: number;
    CounteredAmount: number;
    VehicleType: string;
    Pickup_CityName: string;
    Pickup_StateCode: string;
    Pickup_ZipCode: string;
    Pickup_ScheduledDateTime: Date;
    Delivery_CityName: string;
    Delivery_StateCode: string;
    Delivery_ZipCode: string;
    Delivery_ScheduledDateTime: Date;
 };

export default class CoyoteOffers extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedTab: 2,
            pending: [],
            accepted: [],
            countered: [],
            declined: [],
            history: []
        }

        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.clear = this.clear.bind(this);
        this.accept = this.accept.bind(this);
        this.decline = this.decline.bind(this);
    }

    public componentDidMount() {
        fetchApi('/api/LoadBoard/CoyoteOffers')
            .then((response: { Pending: any[], Accepted: any[], Countered: any[], Declined: any[], History: any[] }) => {
                this.setState({ 
                    pending: response.Pending,
                    accepted: response.Accepted,
                    countered: response.Countered,
                    declined: response.Declined,
                    history: response.History,
                })
            })
            .catch(() => {
                alert('Unable to fetch Coyote My Offers');
            })
    }

    public render() {
        return <React.Fragment>
            <TabStrip selected={this.state.selectedTab} onSelect={this.handleTabSelect} animation={false}>
                <TabStripTab title={`Pending (${this.state.pending.length})`}>
                    {this.state.pending.map((offer, index) => {
                        return <CoyoteOffer key={index} offer={offer} clear={this.clear} accept={this.accept} decline={this.decline} />
                    })}
                </TabStripTab>
                <TabStripTab title={`Accepted (${this.state.accepted.length})`}>
                    {this.state.accepted.map((offer, index) => {
                        return <CoyoteOffer key={index} offer={offer} clear={this.clear} accept={this.accept} decline={this.decline} />
                    })}
                </TabStripTab>
                <TabStripTab title={`Countered (${this.state.countered.length})`}>
                    {this.state.countered.map((offer, index) => {
                        return <CoyoteOffer key={index} offer={offer} clear={this.clear} accept={this.accept} decline={this.decline} />
                    })}
                </TabStripTab>
                <TabStripTab title={`Declined (${this.state.declined.length})`}>
                    {this.state.declined.map((offer, index) => {
                        return <CoyoteOffer key={index} offer={offer} clear={this.clear} accept={this.accept} decline={this.decline} />
                    })}
                </TabStripTab>
                <TabStripTab title={`History (${this.state.history.length})`}>
                    {this.state.history.map((offer, index) => {
                        return <CoyoteOffer key={index} offer={offer} clear={this.clear} accept={this.accept} decline={this.decline} />
                    })}
                </TabStripTab>
            </TabStrip>
        </React.Fragment>
    }

    private handleTabSelect(e: TabStripSelectEventArguments) {
        this.setState({ selectedTab: e.selected });
    }

    private clear(offerId: string) {
        const data = {
            OfferID: offerId
        }
        fetchApi('/api/LoadBoard/CoyoteClearOffer', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    // Remove from history
                    this.setState((prevState) => ({ 
                        history: prevState.history.filter(x => x.OfferId !== offerId)
                    }));
                } else {
                    alert(response.ErrorMessage);
                }
            });
    }

    private accept(shipmentId: string, offerId: string, amount: number) {
        const data = {
            ShipmentID: shipmentId,
            SpotBid: amount,
            BookIt: true,
            OfferID: offerId
        }
        fetchApi(`/api/LoadBoard/BidCoyote`, data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    // Remove from countered
                    this.setState((prevState) => ({ 
                        countered: prevState.countered.filter(x => x.ShipmentID !== shipmentId)
                    }));
                } else {
                    alert(response.ErrorMessage);
                }
            });
    }

    private decline(offerId: string) {
        const data = {
            OfferID: offerId
        }
        fetchApi('/api/LoadBoard/CoyoteDeclineOffer', data, 'POST')
            .then((response: JsonResponse) => {
                if (response.Success) {
                    // Remove from countered
                    this.setState((prevState) => ({ 
                        countered: prevState.countered.filter(x => x.OfferId !== offerId)
                    }));
                } else {
                    alert(response.ErrorMessage);
                }
            });
    }
}