import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import * as React from 'react';
import { AssetCarrierViewModel } from 'TypeGen/Assets/Carriers/List/asset-carrier-view-model';
import { fetchApi } from '../../services/api';
import { SelectedCarrierValue } from './CarrierGrid';

interface Props {
  SelectedCarrier: SelectedCarrierValue,
  CarrierValues: AssetCarrierViewModel[],
  CloseDialog: () => void;
}

interface State {
  isLoading: boolean;
  selectAll: boolean;
  vehicleTypes: number[];
}

export class CarrierSupportedVehiclesDialog extends React.Component<Props, State> {

  private vehicleTypes = [
    { value: 1, text: 'CARGO VAN', details: '(L96xW48xH48) 2000 lbs. 50 mph' },
    { value: 2, text: 'SMALL STRAIGHT', details: '(L216xW84xH84) 10000 lbs. 45 mph' },
    { value: 3, text: 'LARGE STRAIGHT', details: '(L288xW96xH96) 14000 lbs. 45 mph' },
    { value: 4, text: 'TRACTOR', details: '(L636xW102xH110) 44000 lbs. 45 mph' },
    { value: 5, text: 'FLAT BED', details: '(L636xW102xH102) 47000 lbs. 45 mph' },
    { value: 6, text: 'AIR FREIGHT', details: '(L636xW102xH110) 50000 lbs. 500 mph' },
    { value: 7, text: 'REEFER', details: '(L636xW102xH110) 50000 lbs. 45 mph' },
    { value: 8, text: 'SPECIALIZED', details: '(L636xW102xH110) 50000 lbs. 45 mph' },
    { value: 9, text: 'FLATBED CURTAIN', details: '(L636xW102xH102) 44000 lbs. 45 mph' },
    { value: 10, text: 'FLATBED W/TARPS', details: '(L576xW102xH102) 47000 lbs. 45 mph' },
    { value: 11, text: 'STEP CURTAIN', details: '(L636xW102xH118) 43000 lbs. 45 mph' },
    { value: 12, text: 'OPEN STEP', details: '(L636xW102xH122) 47000 lbs. 45 mph' },
    { value: 13, text: 'OVERSIZE FLAT', details: '(L636xW102xH102) 47000 lbs. 45 mph' },
    { value: 14, text: 'OVERSIZE STEP', details: '(L636xW102xH122) 47000 lbs. 45 mph' },
    { value: 15, text: 'SPRINTER', details: '(L96xW48xH70) 3000 lbs. 50 mph' },
    { value: 16, text: '53\'', details: '(L636xW102xH110) 44000 lbs. 45 mph' },
    { value: 17, text: 'DOUBLE DROP RGN', details: '(L636xW102xH138) 38000 lbs. 45 mph' },
    { value: 18, text: 'FLAT OR STEP RETRACTABLE', details: '(L636xW102xH110) 44000 lbs. 45 mph' },
    { value: 19, text: 'DOUBLE DROP CURTAINSIDE', details: '(L636xW102xH138) 38000 lbs. 45 mph' },
    { value: 20, text: 'AIR CHARTER', details: '(L0xW0xH0) 0 lbs. 45 mph' },
    { value: 21, text: 'CONTAINER', details: '(L102xW40xH40) 40000 lbs. 45 mph' },
    { value: 22, text: 'LTL', details: '(L0xW0xH0) 0 lbs. 45 mph' },
    { value: 23, text: 'HOTSHOT', details: '(L40xW96xH0) 16500 lbs. 45 mph' },
    { value: 24, text: 'HAND CARRY', details: '(L636xW102xH110) 50000 lbs. 500 mph' }
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      selectAll: false,
      vehicleTypes: []
    }

    this.getCarrierVehicleTypes = this.getCarrierVehicleTypes.bind(this);
    this.updateCarrierVehicleTypes = this.updateCarrierVehicleTypes.bind(this);
    this.isVehicleChecked = this.isVehicleChecked.bind(this);
    this.vehicleTypeOnChange = this.vehicleTypeOnChange.bind(this);
  }

  public componentDidMount() {
    this.getCarrierVehicleTypes();
  }

  public render() {
    return (
      <Dialog title="Supported Vehicle Types" onClose={this.props.CloseDialog} width={1024}>
        <div className="container">
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                  <Tooltip anchorElement="target" parentTitle={true} openDelay={850}>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <label className="checkbox-inline float-md-left mb-4" style={{ fontSize: "14px", cursor: "pointer" }} >
                            <input type="checkbox" style={{ verticalAlign: "middle", cursor: "pointer" }}
                              checked={this.state.selectAll} onChange={(e: any) => {
                                if (this.state.selectAll === false)
                                  this.setState({ vehicleTypes: this.vehicleTypes.map((vehicle) => vehicle.value) });
                                else
                                  this.setState({ vehicleTypes: [] });

                                this.setState({ selectAll: !this.state.selectAll });
                              }} />
                            &nbsp;SELECT ALL
                          </label>
                        </div>
                      </div>
                      {this.rowBuilder()}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar layout="end">
          <Button onClick={this.props.CloseDialog}>Cancel</Button>
          <Button themeColor="primary" onClick={this.updateCarrierVehicleTypes}>Save</Button>
        </DialogActionsBar>
      </Dialog>
    );
  }

  private isVehicleChecked(vehicle: any): boolean {
    return this.state.vehicleTypes.indexOf(vehicle.value) > -1
  }

  private vehicleTypeOnChange(e: any, vehicle: any) {
    let vehTypes = this.state.vehicleTypes;
    if (!e.target.checked)
      vehTypes.splice(vehTypes.indexOf(vehicle.value), 1);
    else
      vehTypes.push(vehicle.value);
    this.setState({ vehicleTypes: vehTypes });
  }

  private cellBuilder(vehicle: any, index: number): JSX.Element {
    return <React.Fragment key={vehicle.value}>
      <div className="col-3">
        <label className="checkbox-inline float-md-left" title={vehicle.details} style={{ fontSize: "14px", cursor: "pointer" }} >
          <input type="checkbox" style={{ verticalAlign: "middle", cursor: "pointer" }} checked={this.isVehicleChecked(vehicle)} onChange={(e: any) => {
            this.vehicleTypeOnChange(e, vehicle);
          }} />
          &nbsp;{vehicle.text}
        </label>
      </div>
    </React.Fragment>
  }

  private rowBuilder(): JSX.Element[] {
    let rows: JSX.Element[] = [];
    let cells: JSX.Element[] = [];
    let keyIndex: number = 0;
    this.vehicleTypes.forEach((vehicle, index) => {
      keyIndex = index
      if (keyIndex > 0 && keyIndex % 4 == 0) {
        rows.push(
          <div className="row" key={keyIndex}>
            {cells}
          </div>);
        cells = [];
        cells.push(this.cellBuilder(vehicle, keyIndex));
      } else {
        cells.push(this.cellBuilder(vehicle, keyIndex));
      }
    });
    if (cells.length > 0) {
      rows.push(
        <div className="row" key={keyIndex + 1}>
          {cells}
        </div>);
    }
    return rows;
  }

  private getCarrierVehicleTypes() {
    this.setState({ isLoading: true });

    var carrierPhone = this.props.CarrierValues.filter(x => x.CarrierID === this.props.SelectedCarrier.CarrierID)[0].Phone1.replace(/\D/g, '').slice(-10);

    fetchApi(`/api/Asset/GetCarrierVehicleTypes/${carrierPhone}`)
      .then((response) => {
        this.setState({
          vehicleTypes: response.length > 0 ? response : this.vehicleTypes.map((vehicle) => vehicle.value),
          selectAll: response.length === this.vehicleTypes.length || response.length === 0,
          isLoading: false
        });
      });
  }

  private updateCarrierVehicleTypes() {
    this.setState({ isLoading: true });

    var carrierPhone = this.props.CarrierValues.filter(x => x.CarrierID === this.props.SelectedCarrier.CarrierID)[0].Phone1.replace(/\D/g, '').slice(-10);

    const data = {
      VehicleTypes: this.state.vehicleTypes
    };

    fetchApi(`/api/Asset/UpdateCarrierVehicleTypes/${carrierPhone}`, data, 'POST')
      .then(() => {
        this.setState({ isLoading: false }, () => {
          this.props.CloseDialog();
        })
      });
  }
}
