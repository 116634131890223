import { GridCellProps } from "@progress/kendo-react-grid";
import { FleetMessage } from '.';
import { memo } from 'react';
import { openWindow } from "../../services/openWindow";

const TripNumberCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const data = props.dataItem as FleetMessage;

  const tripLink = data.Links.find(x => x.Name === 'TravelOrder');

  return (
      <td>
          {tripLink && <a
            href="#"
            style={{ color: '#007bff' }}
            onClick={(e) => {
              openWindow(tripLink.Link);
              e.preventDefault();
            }}
          >{data.TripNumber}
          </a>}
      </td>
  );
}

export default memo(TripNumberCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);
