import Moment from 'moment-timezone';
import { GridCellProps } from "@progress/kendo-react-grid";

const DateOnlyCell = (props: GridCellProps) => {
  if (!props.field || props.rowType !== 'data') return null;

  const date = props.dataItem[props.field];

  if (!date) return <td>N/A</td>;

  return (
    <td>
      {Moment.utc(date).tz("America/New_York").format("MM/DD/YYYY")}
    </td>
  );
}

export default DateOnlyCell;
